/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getTheRestaurantApi } from 'src/api/letseatmanager/theRestaurant/getTheRestaurantApi';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Tabs } from 'src/components/Tabs';
import { RolePermissions } from 'src/constants/RolePermission';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { RestaurantAppsSettings } from 'src/scenes/letseatmanager/theRestaurant/RestaurantAppsSettings';
import { RestaurantDeliverySettings } from 'src/scenes/letseatmanager/theRestaurant/RestaurantDeliverySettings';
import { RestaurantEcommerceSettings } from 'src/scenes/letseatmanager/theRestaurant/RestaurantEcommerceSettings';
import { RestaurantGeneralSettings } from 'src/scenes/letseatmanager/theRestaurant/RestaurantGeneralSettings';
import { RestaurantOrdersSettings } from 'src/scenes/letseatmanager/theRestaurant/RestaurantOrdersSettings';
import { RestaurantPosSettings } from 'src/scenes/letseatmanager/theRestaurant/RestaurantPosSettings';
import { useNotification } from 'src/services/notification/useNotification';
import { appColors } from 'src/theme/AppColors';
import type { RestaurantId } from 'src/types/Id';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function TheRestaurantPage(): React.ReactElement {
    const classes = useStyles();
    const notification = useNotification();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const appOrdersEnabled = useSelector((state) => state.app.restaurant?.appOrdersEnabled);
    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);

    const [isDirtyForm, setIsDirtyForm] = useState(false);
    const [tabs, setTabs] = useState([
        { label: translate('General'), value: '1' },
        { label: translate('Ecommerce'), value: '2' },
        { label: translate('Orders'), value: '3' },
        { label: translate('Delivery'), value: '4' },
    ]);

    useEffect(() => {
        if (appOrdersEnabled) {
            setTabs((prevTabs) => [...prevTabs, { label: translate('Apps'), value: (prevTabs.length + 1).toString() }]);
        }

        if (posEnabled) {
            setTabs((prevTabs) => [...prevTabs, { label: translate('Pos'), value: (prevTabs.length + 2).toString() }]);
        }
    }, []);

    const [loading, restaurant, load] = useLoadApi(getTheRestaurantApi, { restaurantId }, { initialValue: {}, dependencies: [restaurantId] });

    useEffect(() => {
        const restaurantSettingsChangedWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.RESTAURANT_SETTINGS_CHANGED, handleUpdateRestaurantSetting);
        const linkedInventoryBetweenRestaurantsWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.RESTAURANT_INVENTORY_LINKED, handleUpdateRestaurantSetting);
        const unlinkedInventoryBetweenRestaurantsWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.RESTAURANT_INVENTORY_UNLINKED, handleUpdateRestaurantSetting);
        const restaurantRemovedCourseWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.RESTAURANT_COURSE_REMOVED, handleUpdateRestaurantSetting);
        const restaurantAddedCourseWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.RESTAURANT_COURSE_ADDED, handleUpdateRestaurantSetting);

        return () => {
            restaurantSettingsChangedWebSocketEvent.remove();
            linkedInventoryBetweenRestaurantsWebSocketEvent.remove();
            unlinkedInventoryBetweenRestaurantsWebSocketEvent.remove();
            restaurantRemovedCourseWebSocketEvent.remove();
            restaurantAddedCourseWebSocketEvent.remove();
        };
    }, []);

    const handleUpdateRestaurantSetting = (
        webSocketEventType: WebSocketEvent<{
            restaurantId: RestaurantId;
            restaurantName: string;
        }>
    ) => {
        notification({ message: translate('Restaurant @restaurantName changed', { restaurantName: webSocketEventType.data?.restaurantName }) });
        load();
    };

    return (
        <SecuredAndSubscribedPage title={translate('The Restaurant')} rolePermission={RolePermissions.RESTAURANT_PAGE} className={(classes as any).container}>
            <Tabs
                tabs={tabs}
                onChangeTab={() => {
                    if (isDirtyForm) {
                        const stayOnTab = !window.confirm(translate('Are you sure you want to change this tab? You have unsaved changes.'));
                        if (stayOnTab) return true;
                    }
                    load();
                }}
            >
                <RestaurantGeneralSettings loading={loading} restaurant={restaurant} onChangeRestaurant={load} setIsDirtyForm={setIsDirtyForm} />
                <RestaurantEcommerceSettings loading={loading} restaurant={restaurant} onChangeRestaurant={load} setIsDirtyForm={setIsDirtyForm} />
                <RestaurantOrdersSettings loading={loading} restaurant={restaurant} onChangeRestaurant={load} setIsDirtyForm={setIsDirtyForm} />
                <RestaurantDeliverySettings loading={loading} restaurant={restaurant} onChangeRestaurant={load} setIsDirtyForm={setIsDirtyForm} />
                {appOrdersEnabled && <RestaurantAppsSettings loading={loading} restaurant={restaurant} onChangeRestaurant={load} setIsDirtyForm={setIsDirtyForm} />}
                {posEnabled && <RestaurantPosSettings loading={loading} restaurant={restaurant} onChangeRestaurant={load} setIsDirtyForm={setIsDirtyForm} />}
            </Tabs>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
    },
    formContent: {
        padding: theme.spacing(3),
    },
    deleteButton: {
        color: appColors.text.dangerLight,
    },
    section: {
        width: '100%',
        borderRadius: 5,
        margin: '20px auto',
        border: `1px solid ${theme.palette.secondary.dark}`,
    },
    sectionTitleContainer: {
        backgroundColor: theme.palette.secondary.light,
        marginTop: 20,
    },
    sectionTitle: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
    },
    subtitlesubtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    cashRegisterCustomReasonItem: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        minWidth: 140,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #676060B2',
        borderRadius: 12,
        padding: '0 10px',
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
        color: theme.palette.secondary.contrastText,
        marginLeft: 10,
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    paymentMethod: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    secondaryButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        padding: '5px 10px',
    },
    kioskUserContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    kioskInfo: {
        display: 'flex',
        flexDirection: 'column',
    },
    integrationStoreStatusClosed: {
        fontFamily: theme.typography.semiBold,
        color: '#fa6684',
        fontSize: 13,
    },
    integrationStoreStatusOpen: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 13,
    },
}));
