/**
 * @prettier
 */
import moment from 'moment';
import * as React from 'react';
import { getMenuItemTaxesReportApi } from 'src/api/letseatmanager/restaurantDashboard/getMenuItemTaxesReportApi';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { toShortId } from 'src/utils/uuid/toShortId';

export function TaxesItemTable({ showAsMaster }: Props): React.ReactElement {
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const restaurants = useSelector((state) => state.app.restaurants);
    const restaurantTaxManagementEnabled = useSelector((state) => state.app.restaurant.restaurantTaxManagementEnabled);
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [loading, reports] = useLoadApi(
        getMenuItemTaxesReportApi,
        {
            restaurantIds,
            fromDate: moment(period?.from).toISOString(),
            toDate: moment(period?.to).toISOString(),
            report: showAsMaster ? 'asBrand' : 'perRestaurant',
        },
        { initialValue: [], dependencies: [period, restaurantIds, showAsMaster] }
    );

    const columns = [
        {
            name: 'orderId',
            label: translate('Ticket Id'),
            options: {
                filter: true,
            },
        },
        {
            name: 'date',
            label: translate('Date'),
            options: {
                filter: true,
            },
        },
        {
            name: 'menuItemName',
            label: translate('Product'),
            options: {
                filter: true,
            },
        },
        {
            name: 'subtotal',
            label: translate('Subtotal'),
            options: {
                filter: true,
            },
        },
        {
            name: 'itemTaxes',
            label: translate('Taxes'),
            options: {
                filter: true,
                display: restaurantTaxManagementEnabled,
            },
        },
        {
            name: 'totalItemTax',
            label: translate('Total Tax'),
            options: {
                filter: true,
                display: restaurantTaxManagementEnabled,
            },
        },
        {
            name: 'total',
            label: translate('Total'),
        },
        {
            name: 'menuCategory',
            label: translate('Category'),
        },
    ];

    if (showAsMaster) {
        columns.unshift({
            name: 'restaurantName',
            label: translate('Restaurant Name'),
            options: {
                filter: true,
            },
        });
    }

    const menuItemsTaxesReports = reports.flatMap((report) => {
        const restaurant = restaurants.find((currentRestaurant) => currentRestaurant.restaurantId === report.restaurantId);
        return report.menuItemTaxesReport.map((taxesByMenuItem) => ({
            restaurantName: showAsMaster && restaurant ? restaurant.name : '',
            orderId: toShortId(taxesByMenuItem.orderId),
            date: taxesByMenuItem.date,
            menuItemName: taxesByMenuItem.name,
            subtotal: formatAsCurrencyNumber(taxesByMenuItem.subtotal),
            itemTaxes: taxesByMenuItem.itemTaxes,
            totalItemTax: formatAsCurrencyNumber(taxesByMenuItem.totalItemTax),
            total: formatAsCurrencyNumber(taxesByMenuItem.total),
            menuCategory: taxesByMenuItem.menuCategory,
        }));
    });

    return (
        <ReportSection loading={loading}>
            <Table title={translate('Sales by ticket')} data={menuItemsTaxesReports} columns={columns} />
        </ReportSection>
    );
}

type Props = {
    showAsMaster: boolean;
};
