/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import { PrinterBrand, PrinterBrands } from 'src/constants/PrinterBrand';
import { PrinterPaperSize } from 'src/constants/PrinterPaperSize';
import type { InstructionPrinter } from 'src/services/printer/printers/interfaces/InstructionPrinter';
import { getPrinterCharactersPerRow } from 'src/services/printer/printers/utils/getPrinterCharactersPerRow';
import type { PrintColumn, PrinterInstruction } from 'src/services/printer/types/PrinterInstruction';
import type { PrinterVm } from 'src/types/PrinterVm';
import { isStarPrinter } from 'src/utils/printer/isStarPrinter';
import { normalizeToAsciiCharacters } from 'src/utils/string/normalizeToAsciiCharacters';
import { repeatCharacter } from 'src/utils/string/repeatCharacter';

export class WebUsbPrinter {
    static async print(printerInstructions: Array<PrinterInstruction>, printer: PrinterVm): Promise<void> {
        const webUsbPrinter = new WebUsbPrinterImplementation(printer);

        // Add all printer instructions
        for (const printerInstruction of printerInstructions) {
            try {
                (webUsbPrinter as any)[printerInstruction.instructionType](...printerInstruction.params);
            } catch (e: any) {
                console.log(`instructionType ${printerInstruction.instructionType} not implemented by WebUsbPrinterImplementation`);
                alert(`instructionType ${printerInstruction.instructionType} not implemented by WebUsbPrinterImplementation`);
            }
        }

        const openCashbox = printerInstructions.some((pi) => pi.instructionType === 'openCashbox');
        await webUsbPrinter.print(openCashbox);
    }

    static async getPrinterSerialNumber(printerBrand: PrinterBrand): Promise<string> {
        return WebUsbPrinterImplementation.getPrinterSerialNumber(printerBrand);
    }
}

class WebUsbPrinterImplementation implements InstructionPrinter {
    #printer: PrinterVm;
    #printerConfig: PrinterConfig;
    #dataToPrint: Array<number> = [];
    #largerFont: boolean = false;
    #encoder = new TextEncoder();
    static #printQueue: Record<string, Promise<any>> = {}; // have one printQueue per printer serial number to make sure that printers prints in parallel

    constructor(printer: PrinterVm) {
        this.#printer = {
            ...printer,
            printerBrand: printer.printerBrand ?? PrinterBrands.EPSON,
        };

        this.#printerConfig = WebUsbPrinterImplementation.#getPrinterConfig({
            printerBrand: this.#printer.printerBrand!,
            printerPaperSize: this.#printer.printerPaperSize,
        });
    }

    static async getPrinterSerialNumber(printerBrand: PrinterBrand): Promise<string> {
        const _navigator: any = navigator;
        const usbPrinter = await _navigator.usb.requestDevice({ filters: [{ vendorId: WebUsbPrinterImplementation.#getVendorId(printerBrand) }] });
        return usbPrinter.serialNumber;
    }

    async print(openCashbox: boolean): Promise<void> {
        const prevQueue = WebUsbPrinterImplementation.#printQueue[this.#printer.serialNumber!] ?? Promise.resolve();
        const printPromise = new Promise<void>(async (resolve, reject) => {
            try {
                await prevQueue;
                if (!this.#isPrinterBrandSupportedForUsb()) resolve();

                const _navigator: any = navigator;
                const vendorId = this.#printerConfig.VENDOR_ID;

                const usbDevices = await _navigator.usb.getDevices();
                console.log('USB DEVICES = ', usbDevices);
                let usbPrinter = usbDevices.find((usbDevice: { vendorId: number; serialNumber: string }) => {
                    let isPrinter = usbDevice.vendorId === vendorId;
                    if (this.#printer.serialNumber) isPrinter = usbDevice.serialNumber === this.#printer.serialNumber;
                    return isPrinter;
                });

                if (!usbPrinter) {
                    const filter: { filters: Array<{ vendorId: number } | { serialNumber: string }> } = { filters: [{ vendorId }] };
                    if (this.#printer.serialNumber) filter.filters.push({ serialNumber: this.#printer.serialNumber });
                    usbPrinter = await _navigator.usb.requestDevice(filter);
                }

                console.log('USB PRINTER DEVICE = ', usbPrinter);

                await usbPrinter.open();
                await usbPrinter.selectConfiguration(1);
                await usbPrinter.claimInterface(0);

                const endpoint = usbPrinter.configuration.interfaces[0].alternate.endpoints[0].endpointNumber;

                const transformedData = new Uint8Array([...this.#dataToPrint, ...this.#printerConfig.CUT_PARTIAL_PAPER]).buffer;
                await usbPrinter.transferOut(endpoint, transformedData);

                if (openCashbox) {
                    const transformedData = new Uint8Array([...this.#printerConfig.OPEN_CASH_DRAWER, ...this.#printerConfig.OPEN_CASH_DRAWER_OPTION_2]).buffer;
                    await usbPrinter.transferOut(endpoint, transformedData);
                }
                if (WebUsbPrinterImplementation.#printQueue[this.#printer.serialNumber!] === printPromise) {
                    WebUsbPrinterImplementation.#printQueue[this.#printer.serialNumber!] = Promise.resolve();
                }
                resolve();
            } catch (e: any) {
                if (WebUsbPrinterImplementation.#printQueue[this.#printer.serialNumber!] === printPromise) {
                    WebUsbPrinterImplementation.#printQueue[this.#printer.serialNumber!] = Promise.resolve();
                }
                reject(e);
            }
        });
        WebUsbPrinterImplementation.#printQueue[this.#printer.serialNumber!] = printPromise;
        await printPromise;
    }

    setLargerFont(): void {
        if (isStarPrinter(this.#printer.printerBrand!)) return;

        this.#largerFont = this.#printer.printerBrand !== PrinterBrands.STAR;
        this.#printerConfig.CHARACTER_PER_ROW = 24;
    }

    setRegularFont(): void {
        if (isStarPrinter(this.#printer.printerBrand!)) return;

        this.#largerFont = false;
        this.#printerConfig.CHARACTER_PER_ROW = getPrinterCharactersPerRow({ printerBrand: this.#printer.printerBrand!, printerPaperSize: this.#printer.printerPaperSize });
    }

    addText(text: string): void {
        const textEncoded = [...this.#printerConfig.HORIZONTAL_LEFT, ...this.#encodeText(text + '\n')];
        this.#dataToPrint = [...this.#dataToPrint, ...textEncoded];
    }

    addBoldText(text: string): void {
        const textEncoded = [
            ...(this.#largerFont ? this.#printerConfig.HORIZONTAL_LEFT_LARGER_FONT_SIZE : this.#printerConfig.HORIZONTAL_LEFT),
            ...(this.#largerFont ? this.#printerConfig.TXT_BOLD_ON_LARGER_FONT_SIZE : this.#printerConfig.TXT_BOLD_ON),
            ...this.#encodeText(text + '\n'),
            ...(this.#largerFont ? this.#printerConfig.TXT_BOLD_OFF_LARGER_FONT_SIZE : this.#printerConfig.TXT_BOLD_OFF),
        ];
        this.#dataToPrint = [...this.#dataToPrint, ...textEncoded];
    }

    addCenteredText(text: string): void {
        if (isStarPrinter(this.#printer.printerBrand!)) {
            this.addText(text);
            return;
        }

        const textEncoded = [
            ...(this.#largerFont ? this.#printerConfig.HORIZONTAL_CENTER_LARGER_FONT_SIZE : this.#printerConfig.HORIZONTAL_CENTER),
            ...this.#encodeText(text),
            ...(this.#largerFont ? this.#printerConfig.HORIZONTAL_LEFT_LARGER_FONT_SIZE : this.#printerConfig.HORIZONTAL_LEFT),
            ...this.#encodeText('\n'),
        ];
        this.#dataToPrint = [...this.#dataToPrint, ...textEncoded];
    }

    addCenteredBoldText(text: string): void {
        if (isStarPrinter(this.#printer.printerBrand!)) {
            this.addBoldText(text);
            return;
        }

        const textEncoded = [
            ...(this.#largerFont ? this.#printerConfig.HORIZONTAL_CENTER_LARGER_FONT_SIZE : this.#printerConfig.HORIZONTAL_CENTER),
            ...(this.#largerFont ? this.#printerConfig.TXT_BOLD_ON_LARGER_FONT_SIZE : this.#printerConfig.TXT_BOLD_ON),
            ...this.#encodeText(text),
            ...(this.#largerFont ? this.#printerConfig.TXT_BOLD_OFF_LARGER_FONT_SIZE : this.#printerConfig.TXT_BOLD_OFF),
            ...(this.#largerFont ? this.#printerConfig.HORIZONTAL_LEFT_LARGER_FONT_SIZE : this.#printerConfig.HORIZONTAL_LEFT),

            ...this.#encodeText('\n'),
        ];
        this.#dataToPrint = [...this.#dataToPrint, ...textEncoded];
    }

    addRightText(text: string): void {
        if (isStarPrinter(this.#printer.printerBrand!)) {
            this.addText(text);
            return;
        }
        const textEncoded = [
            ...(this.#largerFont ? this.#printerConfig.HORIZONTAL_RIGHT_LARGER_FONT_SIZE : this.#printerConfig.HORIZONTAL_RIGHT),
            ...this.#encodeText(text + '\n'),
            ...(this.#largerFont ? this.#printerConfig.HORIZONTAL_LEFT_LARGER_FONT_SIZE : this.#printerConfig.HORIZONTAL_LEFT),
        ];
        this.#dataToPrint = [...this.#dataToPrint, ...textEncoded];
    }

    addBoldRightText(text: string): void {
        if (isStarPrinter(this.#printer.printerBrand!)) {
            this.addBoldText(text);
            return;
        }
        const textEncoded = [
            ...(this.#largerFont ? this.#printerConfig.HORIZONTAL_RIGHT_LARGER_FONT_SIZE : this.#printerConfig.HORIZONTAL_RIGHT),
            ...(this.#largerFont ? this.#printerConfig.TXT_BOLD_ON_LARGER_FONT_SIZE : this.#printerConfig.TXT_BOLD_ON),
            ...this.#encodeText(text + '\n'),
            ...(this.#largerFont ? this.#printerConfig.TXT_BOLD_OFF_LARGER_FONT_SIZE : this.#printerConfig.TXT_BOLD_OFF),
            ...(this.#largerFont ? this.#printerConfig.HORIZONTAL_LEFT_LARGER_FONT_SIZE : this.#printerConfig.HORIZONTAL_LEFT),
        ];
        this.#dataToPrint = [...this.#dataToPrint, ...textEncoded];
    }

    addSeparatedTexts(leftText: string, rightText: string): void {
        const spaceBetweenTexts = this.#printerConfig.CHARACTER_PER_ROW - leftText.length - rightText.length;
        const textsSeparator = repeatCharacter(' ', spaceBetweenTexts);
        const textResult = `${leftText}${textsSeparator}${rightText}`;

        const textEncoded = [...this.#encodeText(textResult + '\n')];
        this.#dataToPrint = [...this.#dataToPrint, ...textEncoded];
    }

    addSeparatedBoldLeftTexts(leftText: string, rightText: string): void {
        const spaceBetweenTexts = this.#printerConfig.CHARACTER_PER_ROW - leftText.length - rightText.length;
        const textsSeparator = repeatCharacter(' ', spaceBetweenTexts);
        const separatedRightText = `${textsSeparator}${rightText}`;

        const textEncoded = [
            ...(this.#largerFont ? this.#printerConfig.TXT_BOLD_ON_LARGER_FONT_SIZE : this.#printerConfig.TXT_BOLD_ON),
            ...this.#encodeText(leftText),
            ...(this.#largerFont ? this.#printerConfig.TXT_BOLD_OFF_LARGER_FONT_SIZE : this.#printerConfig.TXT_BOLD_OFF),
            ...this.#encodeText(separatedRightText),
            ...this.#encodeText('\n'),
        ];

        this.#dataToPrint = [...this.#dataToPrint, ...textEncoded];
    }

    addBoldSeparatedTexts(leftText: string, rightText: string): void {
        const spaceBetweenTexts = this.#printerConfig.CHARACTER_PER_ROW - leftText.length - rightText.length;
        const textsSeparator = repeatCharacter(' ', spaceBetweenTexts);
        const textResult = `${leftText}${textsSeparator}${rightText}`;

        const textEncoded = [
            ...(this.#largerFont ? this.#printerConfig.HORIZONTAL_LEFT_LARGER_FONT_SIZE : this.#printerConfig.HORIZONTAL_LEFT),
            ...(this.#largerFont ? this.#printerConfig.TXT_BOLD_ON_LARGER_FONT_SIZE : this.#printerConfig.TXT_BOLD_ON),
            ...this.#encodeText(textResult),
            ...(this.#largerFont ? this.#printerConfig.TXT_BOLD_OFF_LARGER_FONT_SIZE : this.#printerConfig.TXT_BOLD_OFF),
            ...this.#encodeText('\n'),
        ];

        this.#dataToPrint = [...this.#dataToPrint, ...textEncoded];
    }

    addColumns(columns: Array<PrintColumn>): void {
        this.#addRowTextColumnInfo(columns);
        const nextColumnsInfo = this.#getNextColumnsInfo(columns);
        const someColumnHasMissingInfo = nextColumnsInfo.some((column) => !!column.text);
        if (someColumnHasMissingInfo) this.addColumns(nextColumnsInfo);
    }

    addBoldColumns(columns: Array<PrintColumn>): void {
        this.addColumns(columns.map((column) => ({ ...column, fontWeight: 'bold' })));
    }

    addLogoImage(url: string): void {
        // TODO: implement if possible
    }

    addQrCode(url: string): void {
        // TODO: implement if possible
    }

    addNewLine(): void {
        const textEncoded = [...this.#encodeText('\n')];
        this.#dataToPrint = [...this.#dataToPrint, ...textEncoded];
    }

    addCenteredUnderLine(): void {
        const underline = repeatCharacter('_', this.#printerConfig.CHARACTER_PER_ROW - 10);

        const textEncoded = [
            ...this.#printerConfig.HORIZONTAL_CENTER,
            ...this.#printerConfig.TXT_BOLD_ON,
            ...this.#encodeText(underline),
            ...this.#printerConfig.TXT_BOLD_OFF,
            ...this.#encodeText('\n'),
        ];
        this.#dataToPrint = [...this.#dataToPrint, ...textEncoded];
    }

    addLineSeparator(): void {
        const textEncoded = [...this.#encodeText(repeatCharacter('-', this.#printerConfig.CHARACTER_PER_ROW) + '\n')];
        this.#dataToPrint = [...this.#dataToPrint, ...textEncoded];
    }

    openCashbox(): void {
        // Ignored since its handled in the print method instead
    }

    #encodeText(text: string): Uint8Array {
        // normalize spanish characters to ascii characters since it causes some issues on some printers,
        // in future try to set the character encoding on printers to add support for spanish characters
        return this.#encoder.encode(normalizeToAsciiCharacters(text));
    }

    #isPrinterBrandSupportedForUsb(): boolean {
        const printerBrandsSupportedForUsb: Array<PrinterBrand> = [PrinterBrands.STAR, PrinterBrands.EPSON, PrinterBrands.GHIA, PrinterBrands.PIDEDIRECTO];
        return printerBrandsSupportedForUsb.includes(this.#printer.printerBrand!);
    }

    #parseColumnWidth(percentageColumnWidth: string): number {
        return Number(percentageColumnWidth.replace(/\%/g, '')) / 100;
    }

    #getCharactersPerColumn(column: PrintColumn): number {
        const CHARACTER_PER_ROW = this.#printerConfig.CHARACTER_PER_ROW;
        const charactersPerRow = BigNumber(CHARACTER_PER_ROW).multipliedBy(column.percentageWidth).toNumber();
        return Math.floor(charactersPerRow);
    }

    #addRowTextColumnInfo(columns: Array<PrintColumn>): void {
        let columnsData: Array<never> | Array<number> = [];
        for (const column of columns) {
            columnsData = [...columnsData, ...this.#getTextRowFromColumn(column)];
        }
        this.#dataToPrint = [
            ...(this.#largerFont ? this.#printerConfig.HORIZONTAL_LEFT_LARGER_FONT_SIZE : this.#printerConfig.HORIZONTAL_LEFT),
            ...this.#dataToPrint,
            ...columnsData,
            ...this.#encodeText('\n'),
        ];
    }

    #getNextColumnsInfo(columns: Array<PrintColumn>): Array<PrintColumn> {
        const newColumns: Array<PrintColumn> = [];
        for (const column of columns) {
            const charactersPerColumn = this.#getCharactersPerColumn(column);
            const cutOffPoint = this.#isTextColumnLongerThanColumn(column) ? charactersPerColumn - 1 : charactersPerColumn;

            newColumns.push({
                ...column,
                text: column.text?.slice(cutOffPoint) ?? '',
            });
        }

        return newColumns;
    }

    #getTextRowFromColumn(column: PrintColumn): Uint8Array | Array<number> {
        const charactersPerColumn = this.#getCharactersPerColumn(column);

        let columnText;

        if (!this.#isTextColumnLongerThanColumn(column)) {
            columnText = this.#encodeText(column.text + repeatCharacter(' ', charactersPerColumn - column.text.length));
        } else {
            columnText = this.#encodeText(`${column.text?.slice(0, charactersPerColumn - 1)} `);
        }

        if (column.fontWeight !== 'bold') {
            return columnText;
        }

        return [
            ...(this.#largerFont ? this.#printerConfig.TXT_BOLD_ON_LARGER_FONT_SIZE : this.#printerConfig.TXT_BOLD_ON),
            ...columnText,
            ...(this.#largerFont ? this.#printerConfig.TXT_BOLD_OFF_LARGER_FONT_SIZE : this.#printerConfig.TXT_BOLD_OFF),
        ];
    }

    #isTextColumnLongerThanColumn(column: PrintColumn): boolean {
        const charactersPerColumn = this.#getCharactersPerColumn(column);
        return column.text.length > charactersPerColumn;
    }

    static #getPrinterConfig({ printerBrand, printerPaperSize }: GetPrinterConfigParams): PrinterConfig {
        const defaultConfig: PrinterConfig = {
            VENDOR_ID: WebUsbPrinterImplementation.#getVendorId(printerBrand),
            CHARACTER_PER_ROW: getPrinterCharactersPerRow({ printerBrand, printerPaperSize }),
            CUT_PARTIAL_PAPER: [0x1b, 0x64, 0x05, 0x1b, 0x69],
            TXT_BOLD_OFF: [0x1b, 0x21, 0x00],
            TXT_BOLD_OFF_LARGER_FONT_SIZE: [0x1b, 0x21, 0x20],
            TXT_BOLD_ON: [0x1b, 0x21, 0x08],
            TXT_BOLD_ON_LARGER_FONT_SIZE: [0x1b, 0x21, 0x28],
            HORIZONTAL_CENTER: [0x1b, 0x61, 0x01],
            HORIZONTAL_CENTER_LARGER_FONT_SIZE: [0x1b, 0x61, 0x21],
            HORIZONTAL_LEFT: [0x1b, 0x61, 0x00],
            HORIZONTAL_LEFT_LARGER_FONT_SIZE: [0x1b, 0x61, 0x20],
            HORIZONTAL_RIGHT: [0x1b, 0x61, 0x22],
            HORIZONTAL_RIGHT_LARGER_FONT_SIZE: [0x1b, 0x61, 0x22],
            SET_GRAPHIC_DATA: [0x1d, 0x28, 0x04c, 0x139, 0x07, 0x30, 0x43, 0x48, 0x20, 0x20, 0x01, 0x00, 0xff, 0x00, 0xff, 0x49],
            PRINT_GRAPHIC_DATA: [0x1d, 0x28, 0x04c, 0x06, 0x00, 0x30, 0x45, 0x20, 0x20, 0x01, 0x00],
            SET_BACKGROUND_BLACK: [0x1d, 0x42, 0x01],
            SET_BACKGROUND_NORMAL: [0x1d, 0x42, 0x00],
            OPEN_CASH_DRAWER: [0x1b, 0x70, 0x00, 0x64, 0xc8],
            OPEN_CASH_DRAWER_OPTION_2: [0x1b, 0x70, 0x00, 0x64, 0xc8],
        };

        if (printerBrand === PrinterBrands.STAR) {
            return {
                ...defaultConfig,
                CUT_PARTIAL_PAPER: [0x1b, 0x64, 0x02],
                TXT_BOLD_OFF: [0x1b, 0x46],
                TXT_BOLD_ON: [0x1b, 0x45],
            };
        }

        return defaultConfig;
    }

    static #getVendorId(printerBrand: PrinterBrand): number {
        switch (printerBrand) {
            case PrinterBrands.STAR:
                return 0x0519;
            case PrinterBrands.XPRINTER:
                return 0x1fc9;
            case PrinterBrands.EPSON:
                return 0x04b8;
            case PrinterBrands.GHIA:
                return 0x4b43;
            case PrinterBrands.PIDEDIRECTO:
                return 0x1fc9;
            default:
                return 0x00;
        }
    }
}

type PrinterConfig = {
    VENDOR_ID: number;
    CHARACTER_PER_ROW: number;
    CUT_PARTIAL_PAPER: Array<number>;
    TXT_BOLD_OFF: Array<number>;
    TXT_BOLD_ON: Array<number>;
    HORIZONTAL_CENTER: Array<number>;
    HORIZONTAL_LEFT: Array<number>;
    HORIZONTAL_RIGHT: Array<number>;
    SET_GRAPHIC_DATA: Array<number>;
    PRINT_GRAPHIC_DATA: Array<number>;
    SET_BACKGROUND_BLACK: Array<number>;
    SET_BACKGROUND_NORMAL: Array<number>;
    OPEN_CASH_DRAWER: Array<number>;
    OPEN_CASH_DRAWER_OPTION_2: Array<number>;
    TXT_BOLD_OFF_LARGER_FONT_SIZE: Array<number>;
    TXT_BOLD_ON_LARGER_FONT_SIZE: Array<number>;
    HORIZONTAL_CENTER_LARGER_FONT_SIZE: Array<number>;
    HORIZONTAL_LEFT_LARGER_FONT_SIZE: Array<number>;
    HORIZONTAL_RIGHT_LARGER_FONT_SIZE: Array<number>;
};

type GetPrinterConfigParams = {
    printerBrand: PrinterBrand;
    printerPaperSize: PrinterPaperSize;
};
