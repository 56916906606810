/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState } from 'react';
import { changeMenuItemApi } from 'src/api/letseatmanager/menuItem/changeMenuItemApi';
import { Button } from 'src/components/Button';
import { DraggableList } from 'src/components/DraggableList';
import { DraggableListItem } from 'src/components/DraggableListItem';
import { translate } from 'src/i18n/translate';
import { ChemistryIcon } from 'src/icons/ChemistryIcon';
import { MenuItemModifierGroupsEmptyState } from 'src/scenes/letseatmanager/menu/menuItem/MenuItemModifierGroupsEmptyState';
import { ModifierGroupInMenuItem } from 'src/scenes/letseatmanager/menu/menuItem/ModifierGroupInMenuItem';
import { SelectModifierGroupsDialog } from 'src/scenes/letseatmanager/menu/menuItem/SelectModifierGroupsDialog';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import { useLoadMenuItems } from 'src/services/menuItem/useLoadMenuItems';
import { useModifierGroups } from 'src/services/modifierGroup/useModifierGroups';
import { ModifierGroupId } from 'src/types/Id';
import type { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import { ModifierGroupVm } from 'src/types/ModifierGroupVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function MenuItem({ menuItem }: Props): React.ReactElement | null {
    const classes = useStyles();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();

    const [addModifierGroupDialogState, setAddModifierGroupDialogState] = useState({ open: false, menuItem: undefined });

    const { modifierGroups } = useModifierGroups();
    const refreshMenuItems = useLoadMenuItems();

    const hasModifierGroups = !!menuItem.modifierGroupIds?.length;

    const openAddModifierGroupDialog = (menuItem: any) => setAddModifierGroupDialogState({ open: true, menuItem });

    const closeAddModifierGroupDialog = () => setAddModifierGroupDialogState({ open: false, menuItem: undefined });

    const changeMenuItem = async (modifierGroupIds: any) => {
        const response = await changeMenuItemApi({
            ...menuItem,
            modifierGroupIds: modifierGroupIds,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        refreshMenuItems();
    };

    if (!hasModifierGroups) {
        return (
            <div className={classes.container}>
                <SelectModifierGroupsDialog
                    onClose={closeAddModifierGroupDialog}
                    open={addModifierGroupDialogState.open}
                    defaultSelectedModifierGroups={menuItem.modifierGroupIds}
                    onSuccess={changeMenuItem}
                    excludeModifierGroupsByMenuItemId={menuItem.menuItemId}
                />
                <h1 className={classes.titleEmptyState}>{menuItem.name}</h1>
                <div className={classes.iconContainer}>
                    <ChemistryIcon />
                </div>
                <p className={classes.text}>{translate('On this section you can manage the different modifier groups in your product')}</p>
                <Button outlined onClick={openAddModifierGroupDialog} disabled={isMenuEditionDisabled}>
                    {translate('Add modifier group +')}
                </Button>
            </div>
        );
    }

    return (
        <Grid item className={classes.menuItemModifierGroups}>
            <SelectModifierGroupsDialog
                onClose={closeAddModifierGroupDialog}
                open={addModifierGroupDialogState.open}
                defaultSelectedModifierGroups={menuItem.modifierGroupIds}
                onSuccess={changeMenuItem}
                excludeModifierGroupsByMenuItemId={menuItem.menuItemId}
            />
            <div className={classes.listHeader}>
                <h1 className={classes.title}>{menuItem.name}</h1>
                {menuItem.isProduct && (
                    <Button outlined onClick={openAddModifierGroupDialog} disabled={isMenuEditionDisabled}>
                        {translate('Add modifier group +')}
                    </Button>
                )}
            </div>
            {hasModifierGroups && (
                <DraggableList onDragEnd={changeMenuItem}>
                    {menuItem.modifierGroupIds?.map((modifierGroupId: ModifierGroupId) => {
                        const modifierGroup = modifierGroups?.find((modifierGroup: ModifierGroupVm) => modifierGroup.modifierGroupId === modifierGroupId);
                        if (!modifierGroup) return null;

                        return (
                            <DraggableListItem key={modifierGroupId} value={modifierGroupId} iconColor={'#6C7076'} classes={{ container: classes.draggableItemContainer }}>
                                <ModifierGroupInMenuItem modifierGroup={modifierGroup} menuItemId={menuItem.menuItemId} />
                            </DraggableListItem>
                        );
                    }) ?? null}
                </DraggableList>
            )}
            {!hasModifierGroups && <MenuItemModifierGroupsEmptyState />}
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    menuItemModifierGroups: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 24,
    },
    title: {
        color: theme.palette.text.primary,
        fontSize: 18,
        fontWeight: 600,
        margin: 0,
        fontFamily: theme.typography.semiBold,
    },
    titleEmptyState: {
        color: theme.palette.text.primary,
        fontSize: 18,
        fontWeight: 600,
        fontFamily: theme.typography.semiBold,
        width: '100%',
    },
    listHeader: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    draggableItemContainer: {
        alignItems: 'center',
        marginBottom: 2,
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    iconContainer: {
        width: 80,
        height: 80,
        borderRadius: 80,
        backgroundColor: `${theme.palette.surface.brand}63`,
        color: theme.palette.text.brand,
        marginBottom: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '80%',
        textAlign: 'center',
        margin: '20px 0',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
}));

type Props = {
    menuItem: ManagerMenuItemVm;
};
