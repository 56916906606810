/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect } from 'react';
import { app2 } from 'src/app2';
import { PasswordRequiredContent } from 'src/components/PasswordRequiredContent';
import { translate } from 'src/i18n/translate';
import Companies from 'src/scenes/letseatadmin/company/Companies';
import type { CustomerId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';
import { useParams } from 'src/utils/react/useParams';
import { useSelector } from 'src/utils/react/useSelector';

export function CompaniesPage(): React.ReactElement {
    const { customerId } = useParams<{
        customerId: CustomerId;
    }>();
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Companies'));
    }, []);

    return (
        <PasswordRequiredContent customPassword={'4253'}>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Companies customerId={customerId} disabled={viewUser} />
                </Grid>
            </Grid>
        </PasswordRequiredContent>
    );
}
