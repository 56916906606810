/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { DeliveryProvider } from 'src/constants/DeliveryProviders';
import { translate } from 'src/i18n/translate';

export function ChangeDeliveryProviderDialog({ open, onClose, onChangeDeliveryProvider, deliveryProvider }: Props): React.ReactElement | null {
    const classes = useStyles();
    const form = useForm();
    const { watch } = form;

    const deliveryTime = watch('deliveryTime');

    const handleChangeCashDeliveryProvider = () => {
        if (!deliveryProvider?.deliveryProvider) return;

        if (BigNumber(deliveryTime ?? 0).isLessThan(10)) {
            form.setError('deliveryTime', { message: translate('Waiting time must be greater than or equal to 10 seconds') });
            return;
        }

        onChangeDeliveryProvider?.(deliveryProvider.deliveryProvider, deliveryTime ?? deliveryProvider?.deliveryTime);
        onClose?.();
    };

    if (!open || !deliveryProvider) return null;

    return (
        <Dialog title={translate('Modify supplier')} onClose={onClose} open={open}>
            <Form form={form} className={classes.form}>
                <FormNumberField name={'deliveryTime'} label={translate('Waiting time (seconds)')} defaultValue={deliveryProvider.deliveryTime} />
                <DialogActions>
                    <Button outlined onClick={onClose}>
                        {translate('Cancel')}
                    </Button>
                    <Button onClick={handleChangeCashDeliveryProvider}> {translate('Save')}</Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
}));

type Props = {
    open: boolean;
    onClose?: any;
    deliveryProvider?: {
        deliveryProvider?: DeliveryProvider;
        deliveryTime?: number;
    };
    onChangeDeliveryProvider?: any;
};
