/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { changePaymentLinkApi } from 'src/api/letseatmanager/paymentLink/changePaymentLinkApi';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { PaymentLinkId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangePaymentLinkDialog({ open, onClose, onPaymentLinkChanged, prevAmount, paymentLinkId }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    const restaurantId = useSelector((state) => state.app.restaurantId);

    useEffect(() => {
        if (prevAmount) form.reset({ amount: prevAmount });
    }, [prevAmount]);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        const response = await changePaymentLinkApi({
            amount: form.amount,
            restaurantId,
            paymentLinkId,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onPaymentLinkChanged();
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Change Payment Link').toUpperCase()}</DialogTitle>
                <DialogContent>
                    <Grid item xs={12}>
                        <FormNumberField name='amount' label={translate('Amount')} required min={0} InputProps={{ autoFocus: true }} />
                    </Grid>
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    onClose: any;
    onPaymentLinkChanged: any;
    prevAmount: string;
    paymentLinkId: PaymentLinkId;
};
