/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Alert } from 'src/components/Alert';
import { translate } from 'src/i18n/translate';
import { WarningIcon } from 'src/icons/WarningIcon';
import { WithdrawCashRegisterDialog } from 'src/scenes/letseatmanager/posCashRegister/WithdrawCashRegisterDialog';
import { useFetchCashRegister } from 'src/services/cashRegister/useFetchCashRegister';
import { useConfirmDialog } from 'src/services/dialog/useConfirmDialog';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useSelector } from 'src/utils/react/useSelector';

export function ReachedMaximumCashInCashRegisterAlert(): React.ReactElement | null {
    const confirmDialog = useConfirmDialog();
    const { fetchOpenedCashRegister } = useFetchCashRegister();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [withdrawCashRegisterDialogState, setWithdrawCashRegisterDialogState] = useState<{ open: boolean; handleClose: any }>({ open: false, handleClose: undefined });
    const [open, setOpen] = useState(false);

    const openedCashRegister = useSelector((state) => state.pos.openedCashRegister);
    const maximumCashAllowedInCashRegister = useSelector((state) => state.app.restaurant?.maximumCashAllowedInCashRegister);
    const maximumCashAllowedInCashRegisterEnabled = useSelector((state) => state.app.restaurant?.maximumCashAllowedInCashRegisterEnabled);

    const twentyPercentOverage = BigNumber(maximumCashAllowedInCashRegister ?? 0)
        .times(20)
        .dividedBy(100)
        .toFixed(2);

    const isReachedMaximumCashAllowed = BigNumber(openedCashRegister?.currentCashExpectedAmount ?? 0).isGreaterThanOrEqualTo(maximumCashAllowedInCashRegister ?? 0);
    const isTwentyPercentOverage = BigNumber(openedCashRegister?.currentCashExpectedAmount ?? 0).isGreaterThanOrEqualTo(
        BigNumber(maximumCashAllowedInCashRegister).plus(twentyPercentOverage).toNumber()
    );

    useEffect(() => {
        if (isReachedMaximumCashAllowed && maximumCashAllowedInCashRegisterEnabled) {
            setOpen(true);
        }

        if (!isReachedMaximumCashAllowed) {
            setOpen(false);
        }
    }, [isReachedMaximumCashAllowed, maximumCashAllowedInCashRegisterEnabled, openedCashRegister]);

    const closeAlert = () => setOpen(false);

    const handleWithdraw = async () => {
        if (isTwentyPercentOverage) {
            await openRequiredWithdrawNotification();
            return;
        }

        await openWithdrawNotification();
    };

    const openWithdrawNotification = async () => {
        const withdraw = await confirmDialog({
            title: translate('Cash on cash register exceeded'),
            content: translate('You have reached @cash in cash. Withdraw cash to maintain the limit.', { cash: formatAsCurrencyNumber(openedCashRegister.currentCashExpectedAmount) }),
            buttonText: translate('Withdraw now'),
            cancelButtonText: translate('Later'),
            variant: 'warning',
        });

        if (!withdraw) {
            closeAlert();
            return;
        }

        setWithdrawCashRegisterDialogState({
            open: true,
            handleClose: handleCloseWithdrawDialog,
        });
    };

    const openRequiredWithdrawNotification = async () => {
        const withdraw = await confirmDialog({
            title: translate('It is essential to withdraw cash at the cash register'),
            content: translate('You have reached @cash in cash. Withdraw cash to maintain the limit.', { cash: formatAsCurrencyNumber(openedCashRegister.currentCashExpectedAmount) }),
            buttonText: translate('Withdraw'),
            variant: 'warning',
        });

        if (!withdraw) return;

        setWithdrawCashRegisterDialogState({
            open: true,
            handleClose: undefined,
        });
    };

    const handleCloseWithdrawDialog = () => setWithdrawCashRegisterDialogState({ open: false, handleClose: undefined });

    const handleWithdrawSuccess = () => {
        fetchOpenedCashRegister();
        handleCloseWithdrawDialog();
    };

    if (!open || !openedCashRegister) return null;

    return (
        <>
            <WithdrawCashRegisterDialog
                open={withdrawCashRegisterDialogState.open}
                onSuccess={handleWithdrawSuccess}
                handleClose={withdrawCashRegisterDialogState.handleClose}
                cashRegisterId={openedCashRegister?.cashRegisterId}
            />
            <Alert
                type={'warning'}
                icon={<WarningIcon color={'#A15209'} />}
                title={translate('Cash limit has been reached.')}
                actionText={translate('Withdraw')}
                text={translate('Withdraw excess to maintain proper control.')}
                onActionClick={handleWithdraw}
                onClose={isTwentyPercentOverage ? undefined : closeAlert}
            />
        </>
    );
}

type Props = {
    open?: boolean;
    onClose?: any;
    syncMenuErrors?: Array<string>;
};
