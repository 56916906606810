/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { UnableToCloseCashRegisterPosBusinessDayReason } from 'src/constants/UnableToCloseCashRegisterPosBusinessDayReason';
import { CashRegisterId, CashRegisterPosBusinessDayId, PosBusinessDayId } from 'src/types/Id';

export async function isAbleToCloseCashRegisterPosBusinessDayApi(request: IsAbleToCloseCashRegisterPosBusinessDayApiRequest): ApiSauceResponse<IsAbleToCloseCashRegisterPosBusinessDayApiResponse> {
    return letseatmanagerApiMethod('pos/isAbleToCloseCashRegisterPosBusinessDayApi', request);
}

export type IsAbleToCloseCashRegisterPosBusinessDayApiRequest = {
    posBusinessDayId: PosBusinessDayId;
    cashRegisterId?: CashRegisterId;
    cashRegisterPosBusinessDayId?: CashRegisterPosBusinessDayId;
};

type IsAbleToCloseCashRegisterPosBusinessDayApiResponse = {
    isAbleToCloseCashRegisterPosBusinessDay: boolean;
    unableToCloseCashRegisterPosBusinessDayReason?: UnableToCloseCashRegisterPosBusinessDayReason;
};
