/**
 * @prettier
 */
import * as React from 'react';

export function ChartDonutFilledIcon({ color, title }: Props): React.ReactElement {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            className='icon icon-tabler icon-tabler-chart-donut-filled'
            width='44'
            height='44'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke={color ?? '#2c3e50'}
            fill='none'
            strokeLinecap='round'
            strokeLinejoin='round'
        >
            <title>{title}</title>
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path
                d='M9.883 2.207a1.9 1.9 0 0 1 2.087 1.522l.025 .167l.005 .104v4a1 1 0 0 1 -.641 .933l-.107 .035a3.1 3.1 0 1 0 3.73 3.953l.05 -.173a1 1 0 0 1 .855 -.742l.113 -.006h3.8a2 2 0 0 1 2 2a1 1 0 0 1 -.026 .226a10 10 0 1 1 -12.27 -11.933l.27 -.067l.11 -.02z'
                strokeWidth='0'
                fill={color ?? 'currentColor'}
            />
            <path
                d='M14.775 2.526a.996 .996 0 0 1 .22 -.026l.122 .007l.112 .02l.103 .03a10 10 0 0 1 6.003 5.817l.108 .294a1 1 0 0 1 -.824 1.325l-.119 .007h-4.5a1 1 0 0 1 -.76 -.35a8 8 0 0 0 -.89 -.89a1 1 0 0 1 -.342 -.636l-.008 -.124v-4.495l.006 -.118c.005 -.042 .012 -.08 .02 -.116l.03 -.103a.998 .998 0 0 1 .168 -.299l.071 -.08c.03 -.028 .058 -.052 .087 -.075l.09 -.063l.088 -.05l.103 -.043l.112 -.032z'
                strokeWidth='0'
                fill={color ?? 'currentColor'}
            />
        </svg>
    );
}

type Props = {
    color?: string;
    title?: string;
};
