/**
 * @prettier
 */
export const SmsPrios = {
    AUTHENTICATION: 'AUTHENTICATION',
    HIGH_PRIO: 'HIGH_PRIO',
    LOW_PRIO: 'LOW_PRIO',
} as const;

export type SmsPrio = typeof SmsPrios[keyof typeof SmsPrios];
