/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { SectionTitle } from 'src/components/title/SectionTitle';
import { PosBusinessDaySections } from 'src/constants/PosBusinessDaySections';
import { translate } from 'src/i18n/translate';

export function PosBusinessDayRestaurantInfoSettings({ disabled }: Props): React.ReactElement {
    return (
        <section>
            <SectionTitle title={translate('Restaurant Information')} />
            <Grid container xs={12}>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={PosBusinessDaySections.RESTAURANT_NAME} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.RESTAURANT_NAME}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={PosBusinessDaySections.BUSINESS_NAME} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.BUSINESS_NAME}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={PosBusinessDaySections.RFC} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.RFC}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={PosBusinessDaySections.RESTAURANT_STREET} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.RESTAURANT_STREET}`)} />
                </Grid>
            </Grid>
        </section>
    );
}

type Props = {
    disabled: boolean;
};
