/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesforceRestaurantType } from 'src/constants/SalesforceRestaurantType';
import type { BrandId, RestaurantId, Zone } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export async function getRestaurantBillingApi(request: GetRestaurantBillingApiRequest): ApiSauceResponse<RestaurantBillingVm> {
    return letseatadminApiMethod('restaurantBilling/getRestaurantBillingApi', request);
}

type GetRestaurantBillingApiRequest = {
    from: Date;
    to: Date;
    restaurantId: RestaurantId;
};

export type RestaurantBillingVm = {
    restaurantId: RestaurantId;
    brandId?: BrandId;
    brandName?: string;
    restaurantName: string;
    restaurantZone?: Zone;
    completed: number;
    webCard: number;
    webCash: number;
    webCardOnDelivery: number;
    whiteLabelAppCard: number;
    whiteLabelAppCash: number;
    whiteLabelAppCardOnDelivery: number;
    marketplaceAppCard: number;
    marketplaceAppCash: number;
    marketplaceAppCardOnDelivery: number;
    posCard: number;
    posCash: number;
    posCardOnDelivery: number;
    marketplaceServiceFeeCash: number;
    marketplaceServiceFeeCard: number;
    marketplaceServiceFeeCardOnDelivery: number;
    cashback: number;
    manualOrders: number;
    manualOrdersProtected: number;
    directoProtectionAmount: number;
    invoicedRestaurantDeliveryCost: number;
    deliveryCostPaidByRestaurant: number;
    restaurantPromoCodeCostApp: number;
    restaurantPromoCodeBalance: number;
    paymentLink: number;
    paymentLinks: number;
    commissionWebCard: number;
    commissionWebCash: number;
    commissionWebCardOnDelivery: number;
    commissionAppCard: number;
    commissionAppCash: number;
    commissionAppCardOnDelivery: number;
    commissionManualOrders: number;
    commissionRappiCargoWebCard: number;
    commissionRappiCargoWebCash: number;
    commissionRappiCargoAppCard: number;
    commissionRappiCargoAppCash: number;
    commissionRappiCargoAppCardOnDelivery: number;
    commissionRappiCargoManualOrder: number;
    commissionRappiCargoPosCard: number;
    commissionRappiCargoPosCash: number;
    commissionUberDaasWebCard: number;
    commissionUberDaasWebCash: number;
    commissionUberDaasAppCard: number;
    commissionUberDaasAppCash: number;
    commissionUberDaasAppCardOnDelivery: number;
    commissionUberDaasManualOrder: number;
    commissionUberDaasPosCard: number;
    commissionUberDaasPosCash: number;
    commissionForWebCardOrders: number;
    commissionForWebCashOrders: number;
    commissionForWebCardOnDeliveryOrders: number;
    commissionForWhiteLabelAppCardOrders: number;
    commissionForWhiteLabelAppCashOrders: number;
    commissionForWhiteLabelAppCardOnDeliveryOrders: number;
    commissionForMarketPlaceAppCardOrders: number;
    commissionForMarketPlaceAppCashOrders: number;
    commissionForMarketPlaceAppCardOnDeliveryOrders: number;
    commissionForManualOrders: number;
    commissionForRappiCargoWebCard: number;
    commissionForRappiCargoWebCash: number;
    commissionForRappiCargoAppCard: number;
    commissionForRappiCargoAppCash: number;
    commissionForRappiCargoManualOrder: number;
    commissionForRappiCargoPaymentLink: number;
    commissionForRappiCargoPosCard: number;
    commissionForRappiCargoPosCash: number;
    commissionForUberDaasWebCard: number;
    commissionForUberDaasWebCash: number;
    commissionForUberDaasAppCard: number;
    commissionForUberDaasAppCash: number;
    commissionForUberDaasManualOrder: number;
    commissionForUberDaasPaymentLink: number;
    commissionForUberDaasPosCard: number;
    commissionForUberDaasPosCash: number;
    rappiCargoWebCard: number;
    rappiCargoWebCash: number;
    rappiCargoAppCard: number;
    rappiCargoAppCash: number;
    rappiCargoManualOrder: number;
    rappiCargoPaymentLink: number;
    rappiCargoPosCard: number;
    rappiCargoPosCash: number;
    rappiCargoBringgCard: number;
    rappiCargoBringgCash: number;
    uberDaasWebCard: number;
    uberDaasWebCash: number;
    uberDaasAppCard: number;
    uberDaasAppCash: number;
    uberDaasManualOrder: number;
    uberDaasPaymentLink: number;
    uberDaasPosCard: number;
    uberDaasPosCash: number;
    uberDaasBringgCard: number;
    uberDaasBringgCash: number;
    total: number;
    commission: number;
    iva: number;
    subscriptionPayPerUsePayment: number;
    subscriptionPayPerUsePayments: number;
    manualEarning: number;
    manualEarnings: number;
    debtAtBeginningOfPeriodSubscriptionPayPerUsePayment: number;
    debtAtBeginningOfPeriodManualEarning: number;
    debt: number;
    debtSubscriptionPayPerUsePayment: number;
    debtManualEarning: number;
    salesforceRestaurantType: SalesforceRestaurantType;
    subscriptionPayPerUsePaymentInvoice: number;
    manualEarningInvoice: number;
    deposit: number;
    orders: Array<OrderVm>;
};
