// this is an auto generated file. This will be overwritten

export const version = /* GraphQL */ `
    query Version {
        version
    }
`;
export const nodeversion = /* GraphQL */ `
    query Nodeversion {
        nodeversion
    }
`;
export const findDeliveryManager = /* GraphQL */ `
    query FindDeliveryManager($orderId: ID!) {
        findDeliveryManager(orderId: $orderId) {
            orderId
            awsExecutionArn
            deliveryManagerStatus
            triedToRequestUberDirectDeliveryAt
            triedToRequestRappiCargoDeliveryAt
            error
            deliveryProviders
            dynamicDeliveryManager
            cyclicalDeliveryManager
            createdAt
            startAt
            startedAt
            restartAt
            restartedAt
            lastRunAt
            cancelledAt
            failedAt
            completedAt
        }
    }
`;
export const findOngoingDeliveryManagers = /* GraphQL */ `
    query FindOngoingDeliveryManagers {
        findOngoingDeliveryManagers {
            orderId
            awsExecutionArn
            deliveryManagerStatus
            triedToRequestUberDirectDeliveryAt
            triedToRequestRappiCargoDeliveryAt
            error
            deliveryProviders
            dynamicDeliveryManager
            cyclicalDeliveryManager
            createdAt
            startAt
            startedAt
            restartAt
            restartedAt
            lastRunAt
            cancelledAt
            failedAt
            completedAt
        }
    }
`;
export const findOngoingDelivery = /* GraphQL */ `
    query FindOngoingDelivery($driverId: ID!) {
        findOngoingDelivery(driverId: $driverId) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const findOrderDeliveries = /* GraphQL */ `
    query FindOrderDeliveries($orderId: ID!) {
        findOrderDeliveries(orderId: $orderId) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            cancelReason
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const findDriverPosition = /* GraphQL */ `
    query FindDriverPosition($driverId: ID!) {
        findDriverPosition(driverId: $driverId) {
            driverId
            position {
                type
                coordinates
            }
            positionedAt
        }
    }
`;
