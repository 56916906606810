/**
 * @prettier
 */
import { App } from 'src/constants/App';
import { PaymentMethod, PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { CustomPaymentMethod, RappiCargoDeliveryId, UberDaasDeliveryOrderId } from 'src/types/Id';
import { isOnlineApp } from 'src/utils/app/isOnlineApp';

export function translatePaymentMethod({ paymentMethod, rappiCargoDeliveryId, uberDaasDeliveryOrderId, hasPaymentMethodChangedToCard, app, addEmoji }: Params): string {
    if (rappiCargoDeliveryId || uberDaasDeliveryOrderId) {
        if (isOnlineApp(app)) return translate('Online payment');
        return translate('Credit Card');
    }

    switch (paymentMethod) {
        case PaymentMethods.CASH: {
            return translate('Cash');
        }
        case PaymentMethods.CREDIT_CARD:
        case PaymentMethods.PAYROLL: {
            if (hasPaymentMethodChangedToCard) return translate('Credit Card (changed)');
            if (isOnlineApp(app)) return translate('Online payment');
            return translate('Credit Card');
        }
        case PaymentMethods.CARD_ON_DELIVERY: {
            return translate('Pay with card on delivery');
        }
        case PaymentMethods.PAYMENT_LINK:
            return translate('Payment Link');
        case PaymentMethods.PENDING: {
            return translate('Pending');
        }
        case PaymentMethods.TRANSFER: {
            return translate(PaymentMethods.TRANSFER);
        }
        default: {
            return paymentMethod ?? '';
        }
    }
}

type Params = {
    paymentMethod?: CustomPaymentMethod | PaymentMethod;
    rappiCargoDeliveryId?: RappiCargoDeliveryId;
    uberDaasDeliveryOrderId?: UberDaasDeliveryOrderId;
    app?: App;
    hasPaymentMethodChangedToCard?: boolean;
    addEmoji?: boolean;
};
