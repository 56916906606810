/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Input } from '@pidedirecto/ui';
import * as React from 'react';
import { memo } from 'react';
import { translate } from 'src/i18n/translate';
import { usePosCustomerActions, usePosCustomerStore } from 'src/modules/pos/customer/posCustomerStore';

export const PosCustomerLastNameInput: React.ComponentType<any> = memo<any>(function PosCustomerLastNameInput(): React.ReactElement {
    const classes = useStyles();

    const lastName = usePosCustomerStore((state) => state.lastName.value);

    const setCustomerLastName = usePosCustomerActions((actions) => actions.setCustomerLastName);

    const onChangeLastName = (lastName: string) => {
        setCustomerLastName(lastName);
    };

    return <Input classes={{ container: classes.input }} name='lastName' label={translate('Last Name')} value={lastName} onChange={onChangeLastName} />;
});

const useStyles = makeStyles((theme) => ({
    input: {
        width: '100%',
    },
}));
