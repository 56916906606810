/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { RemoveAlertIcon } from 'src/icons/RemoveAlertIcon';

export function RemoveDialog({ open, title, children, subtitle, cancelButtonText, removeButtonText, onSubmit, onClose, disabled }: Props): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const onAccept = async () => {
        setLoading(true);
        const preventClosing = await onSubmit();
        setLoading(false);
        if (!preventClosing) handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} loading={loading || disabled}>
            <div className={classes.dialogTitle}>
                <RemoveAlertIcon className={classes.deleteIcon} />
                {title}
            </div>
            {subtitle && (
                <div className={classes.dialogContent}>
                    <Text>{subtitle}</Text>
                </div>
            )}
            {children}
            <DialogActions className={classes.buttonsContainer}>
                <Button secondary onClick={handleClose} disabled={loading}>
                    {cancelButtonText ?? (disabled || loading ? translate('Close') : translate('No, I want to keep it'))}
                </Button>
                <Button onClick={onAccept} disabled={disabled || loading} classes={{ button: classes.acceptButton }}>
                    {removeButtonText ?? translate('Yes, I want to delete it')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 24,
        color: theme.palette.text.primary,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(1),
        fontFamily: theme.typography.semiBold,
    },
    dialogContent: {
        textAlign: 'center',
        paddingLeft: 64,
        paddingRight: 64,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 495,
        alignItems: 'center',
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        width: '100%',
        justifyContent: 'center',
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    noMargin: {
        margin: '0 !important',
    },
    acceptButton: {
        backgroundColor: theme.palette.text.danger,
        color: 'white',
        fontFamily: theme.typography.regular,
        fontSize: 12,
        borderRadius: 6,
        height: 42,
        width: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        margin: '0 !important',
        '&:hover': {
            backgroundColor: `${theme.palette.text.danger}b5`,
        },
    },
    deleteIcon: {
        width: 90,
        height: 90,
        marginBottom: 20,
        display: 'block',
        margin: '0 auto',
    },
}));

type Props = {
    open: boolean;
    title?: string;
    subtitle?: string;
    children?: React.ReactNode;
    cancelButtonText?: string;
    removeButtonText?: string;
    onSubmit: any;
    onClose: () => void;
    disabled?: boolean;
};
