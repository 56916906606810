/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import { appColors } from 'src/theme/AppColors';

export function getGiftColor(credits: string, removed: string): string {
    if (removed) {
        return appColors.table.red;
    } else if (BigNumber(credits).isGreaterThan(0)) {
        return appColors.table.green;
    } else if (BigNumber(credits).isGreaterThan(0)) {
        return appColors.table.red;
    } else {
        return appColors.table.yellow;
    }
}
