/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuId } from 'src/types/Id';

export async function deprecatedMoveMenuLeftApi(request: MoveMenuLeftApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menu/moveMenuLeftApi', request);
}

type MoveMenuLeftApiRequest = {
    menuId: MenuId;
};
