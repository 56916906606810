/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderId, RestaurantZoneId } from 'src/types/Id';
import type { RestaurantZoneVm } from 'src/types/RestaurantZoneVm';

export async function joinTablesApi(request: JoinTablesApiRequest): ApiSauceResponse<RestaurantZoneVm> {
    return letseatmanagerApiMethod('restaurantZone/joinTablesApi', request);
}

type JoinTablesApiRequest = {
    restaurantZoneId: RestaurantZoneId;
    orderId: OrderId;
    selectedTableNumber: string;
    tableNumbersToJoin: Array<string>;
};
