/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BuyOrderId, ManagerUserId } from 'src/types/Id';

export async function removeBuyOrderApi(request: CancelBuyOrderApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('buyOrder/cancelBuyOrderApi', request);
}

export type CancelBuyOrderApiRequest = {
    buyOrderId: BuyOrderId;
    managerUserId: ManagerUserId;
};
