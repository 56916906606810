/**
 * @prettier
 */
import moment from 'moment';
import * as React from 'react';
import { HeatMapCanvas } from 'src/components/charts/HeatMapCanvas';
import { translate } from 'src/i18n/translate';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';

export function HeatMapDayReport({ loading, heatMapReportData }: Props): React.ReactElement | null {
    const { heatMapData, maxValueForHeatMap } = heatMapReportData;
    const ordersHeatMap =
        heatMapData?.hour.map((item: any, index: any) => ({
            id: `${moment().hour(parseInt(heatMapData.hour[index])).minutes(0).format('HH:mm')}`,
            data: [
                { x: translate('Monday'), y: heatMapData.monday[index] },
                { x: translate('Tuesday'), y: heatMapData.tuesday[index] },
                { x: translate('Wednesday'), y: heatMapData.wednesday[index] },
                { x: translate('Thursday'), y: heatMapData.thursday[index] },
                { x: translate('Friday'), y: heatMapData.friday[index] },
                { x: translate('Saturday'), y: heatMapData.saturday[index] },
                { x: translate('Sunday'), y: heatMapData.sunday[index] },
            ],
        })) || [];
    const hasHeatMapData = maxValueForHeatMap > 0;

    if (!hasHeatMapData) return null;

    return (
        <ReportSection title={translate('Sales per day and hour')} loading={loading}>
            <HeatMapCanvas data={ordersHeatMap} maxValue={maxValueForHeatMap} />
        </ReportSection>
    );
}

type Props = {
    loading: boolean;
    heatMapReportData: any;
};
