/**
 * @prettier
 */
import moment from 'moment-timezone';
import type { TimeZone } from 'src/constants/TimeZone';

export function daysFromToday(date: string | Date, timeZone: TimeZone): number {
    const today = moment.tz(timeZone).startOf('day');
    return today.diff(moment.tz(date, timeZone).startOf('day'), 'days');
}
