/**
 * @prettier
 */
import { useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { ContextualMenu } from 'src/components/ContextualMenu';
import { ContextualMenuItem } from 'src/components/ContextualMenuItem';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { AddCircleOutlineIcon } from 'src/icons/AddCircleOutlineIcon';

export function CreateBuyOrderToolbarContextMenu(): React.ReactElement {
    const history = useHistory();
    const [contextualMenuOpened, setContextualMenuOpened] = useState(false);
    const [buttonElement, setButtonElement] = useState(null);

    const handleOpenContextualMenu = (e: any) => {
        setButtonElement(e.currentTarget);
        setContextualMenuOpened(!contextualMenuOpened);
    };

    const goToSelectBuyOrderCreationMethod = () => {
        history.push({
            pathname: RoutePaths.BUY_ORDER_REGISTER_METHOD,
            search: history.location.search,
        });
    };

    const goToRegisterEntryOfMerchandise = () => {
        history.push(
            {
                pathname: RoutePaths.INVENTORY_CREATE_BUY_ORDER,
                search: history.location.search,
            },
            { entryOfMerchandise: true }
        );
    };

    return (
        <>
            <ContextualMenu customButton={<CreateToolbarButton tooltip={translate('Add Order')} onClick={handleOpenContextualMenu} />}>
                <ContextualMenuItem onClick={() => goToSelectBuyOrderCreationMethod()}>
                    <AddCircleOutlineIcon title={'Create Buy Order'} />
                    {translate('Buy Order')}
                </ContextualMenuItem>
                <SecuredContent rolePermission={RolePermissions.CREATE_MERCHANDISE_ENTRY}>
                    <ContextualMenuItem onClick={() => goToRegisterEntryOfMerchandise()}>
                        <AddCircleOutlineIcon title={'Create Merchandise Income'} />
                        {translate('Merchandise Income')}
                    </ContextualMenuItem>
                </SecuredContent>
            </ContextualMenu>
        </>
    );
}
