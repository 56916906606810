/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useContext } from 'react';
import * as React from 'react';
import { CompactPicker, TwitterPicker } from 'react-color';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';

// TODO: rename title to label as in other form components also give it same style
export function FormColorPicker({ name, title, defaultValue = '#1a9aa0', disabled }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const matchMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);

    const handleChange = (color: any, event: any) => {
        return [event.hex, color];
    };

    return (
        <FormControlLabel
            label={undefined}
            style={{ marginLeft: 0 }}
            control={
                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue}
                    render={({ onChange, value, name }) => (
                        <div className={classes.container}>
                            <p className={classes.label}>{title}</p>
                            {!matchMediaQuery && (
                                <CompactPicker
                                    /* @ts-ignore */
                                    name={name}
                                    color={value}
                                    onChange={(e) => onChange(e.hex)}
                                />
                            )}
                            {matchMediaQuery && (
                                <TwitterPicker
                                    /* @ts-ignore */
                                    name={name}
                                    color={value}
                                    onChange={(e) => onChange(e.hex)}
                                    width='100%'
                                />
                            )}
                        </div>
                    )}
                />
            }
            disabled={isSubmitting || disabled || formContext.disabled}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: 'fit-content',
        marginBottom: 20,
        padding: '0 12px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
        },
    },
    label: {
        fontFamily: theme.typography.medium,
    },
}));

export type Props = {
    name: string;
    title?: string;
    defaultValue?: string;
    disabled?: boolean;
};
