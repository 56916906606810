/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { UploadIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createMenuFromImportedMenuApi } from 'src/api/letseatmanager/menu/createMenuFromImportedMenuApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { useNotification } from 'src/services/notification/useNotification';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { convertCsvToImportedMenu, CsvMenuItem } from 'src/utils/csv/convertCsvToImportedMenu';
import { useSelector } from 'src/utils/react/useSelector';

export function ImportMenuFromCsvFileDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const notification = useNotification();
    const {
        formState: { isSubmitting },
    } = form;

    const [importing, setImporting] = useState(false);
    const [importedMenu, setImportedMenu] = useState<Array<CsvMenuItem>>([]);
    const [fileName, setFileName] = useState(null);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const importCsv = () => {
        if (importing) return;
        inputRef.current?.click();
    };

    const handleUploadCsv = async (event: any) => {
        if (event?.target?.files.length > 0) {
            setImporting(true);
            setFileName(event.target.files[0]?.name);
            const resultFromImporting = await convertCsvToImportedMenu(event.target.files[0]);
            setImportedMenu(resultFromImporting);
        }
        setImporting(false);
    };

    const handleClose = () => {
        if (isSubmitting) return;
        setImportedMenu([]);
        setFileName(null);
        onClose();
    };

    const onSubmit = async () => {
        if (!importedMenu) return;

        const response = await createMenuFromImportedMenuApi({ restaurantId, importedMenu });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        notification({ message: translate('Menu imported successfully') });
        onSuccess?.();
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Import Menu From CSV File')}>
            <Form onSubmit={onSubmit} form={form} className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Button secondary classes={{ button: classes.importMenuButton }} onClick={importCsv}>
                            <UploadIcon size={50} title={'upload-icon'} />
                            <span className={classes.text}>{translate('Select CSV File')}</span>
                        </Button>
                        <input style={{ display: 'none' }} type='file' ref={inputRef} onChange={handleUploadCsv} accept='.csv' data-testid='csvFileInput' />
                        {fileName && <Text> {fileName}</Text>}
                    </Grid>
                </Grid>

                <DialogActions className={classes.buttonsContainer}>
                    <Button secondary onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button primary type='submit' disabled={isSubmitting || !fileName}>
                        {isSubmitting ? translate('Importing') : translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    importMenuButton: {
        width: '100%',
        height: 150,
        backgroundColor: theme.palette.secondary.light,
        border: `1px solid ${theme.palette.secondary.dark}`,
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15,
        cursor: 'pointer',
        flexDirection: 'column',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        margin: 10,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess?: any;
};
