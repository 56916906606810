/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import type { SalesByHour } from 'src/api/letseatmanager/restaurantDashboard/getByDayRestaurantStatisticsApi';
import { BarChartCanvas } from 'src/components/charts/BarChartCanvas';
import { translate } from 'src/i18n/translate';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function SalesByHourGraph({ loading, salesByHourReportData }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const salesByHour =
        salesByHourReportData
            .map((itemHT) => ({
                hourTime: `${moment().hour(parseInt(itemHT.hourTime)).minutes(0).format('HH:mm')}`,
                total: itemHT.total,
            }))
            .filter((item) => item.total > 0) || [];
    const hasSalesByHourData = salesByHourReportData.length > 0;

    if (!hasSalesByHourData) return null;

    return (
        <ReportSection title={translate('Sales details per hour')} loading={loading}>
            <BarChartCanvas
                data={salesByHour}
                keys={['total']}
                indexBy={'hourTime'}
                tooltip={(tooltipData: { indexValue: string; value: string }) => (
                    <div className={classes.tooltip}>
                        <span className={classes.tooltipText}>{translate(`Hour: @date`, { date: tooltipData.indexValue })}</span>
                        <span className={classes.tooltipText}>{translate(`Total: @total`, { total: formatAsCurrencyNumber(tooltipData.value) })}</span>
                    </div>
                )}
            />
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    graphContainer: {
        width: '100%',
        height: '55vh',
        marginBottom: '15%',
    },
    heatMapContainer: {
        width: '100%',
        height: '60vh',
    },
    tooltip: {
        color: theme.palette.text.secondary,
        padding: 5,
        textTransform: 'capitalize',
        borderRadius: '10px',
        backgroundColor: 'whitesmoke',
    },
    tooltipText: {
        textTransform: 'uppercase',
        display: 'flex',
        flexDirection: 'column',
    },
}));

type Props = {
    loading: boolean;
    salesByHourReportData: Array<SalesByHour>;
};
