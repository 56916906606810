/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { AddTemporaryPreparationTimeDialog } from 'src/scenes/letseatmanager/preparationTime/AddTemporaryPreparationTimeDialog';
import { useSelector } from 'src/utils/react/useSelector';

export function AddTemporaryPreparationTimeButton(): React.ReactElement | null {
    const classes = useStyles();

    const restaurant = useSelector((state) => state.app.restaurant);

    const [addTemporaryPreparationTimeDialogState, setAddTemporaryPreparationTimeDialogState] = useState({ open: false });

    const openAddTemporaryPreparationTimeDialog = () => setAddTemporaryPreparationTimeDialogState({ open: true });

    const closeAddTemporaryPreparationTimeDialog = () => setAddTemporaryPreparationTimeDialogState({ open: false });

    if (!restaurant?.addTemporaryPreparationTimeEnabled) return null;

    return (
        <>
            <AddTemporaryPreparationTimeDialog open={addTemporaryPreparationTimeDialogState.open} onClose={closeAddTemporaryPreparationTimeDialog} />
            <Button secondary onClick={openAddTemporaryPreparationTimeDialog} classes={{ button: classes.button }}>
                {translate('Rush Hour')}
            </Button>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        width: 'max-content',
    },
}));
