/**
 * @prettier
 */
export const DriverAvailabilities = {
    NOT_REGISTERED: 'NOT_REGISTERED',
    BANNED: 'BANNED',
    REMOVED: 'REMOVED',
    NOT_VERIFIED: 'NOT_VERIFIED',
    SIGNED_OUT: 'SIGNED_OUT',
    OFFLINE: 'OFFLINE',
    ONLINE: 'ONLINE',
} as const;

export type DriverAvailability = typeof DriverAvailabilities[keyof typeof DriverAvailabilities];
