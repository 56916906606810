/**
 * @prettier
 */
import type { FaqVm } from 'src/api/letseatadmin/faq/getFaqApi';
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { FaqId } from 'src/types/Id';

export async function changeFaqApi(request: ChangeFaqApiRequest): ApiSauceResponse<FaqVm> {
    return letseatadminApiMethod('faq/changeFaqApi', request);
}

export type ChangeFaqApiRequest = {
    faqId: FaqId;
    question: string;
    answer: string;
    imageUrl?: string;
    position?: number;
    articleUrl?: string;
};
