/**
 * @prettier
 */
import { ListItemText } from '@material-ui/core';
import * as React from 'react';
import { app2 } from 'src/app2';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { useAction } from 'src/utils/react/useAction';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';
import { useUserIsAnAvailableWaiter } from 'src/utils/react/useUserIsAnAvailableWaiter';

export function AssignWaiterContextualMenuOption({ disabled, order, openAssignWaiterDialog }: Props): React.ReactElement {
    const [userIsAnAvailableWaiter] = useUserIsAnAvailableWaiter();
    const [userHasRolePermission] = useUserHasRolePermission();

    const openVerifyPosPasswordDialog = useAction(app2.actions.openVerifyPosPasswordDialog);

    const handleAssignWaiter = async () => {
        if (disabled) return;

        if (!userHasRolePermission(RolePermissions.ASSIGN_ORDERS)) {
            return openVerifyPosPasswordDialog({
                onAuthorized: () => {
                    openAssignWaiterDialog();
                },
                onUnauthorized: () => {
                    alert(translate('Unauthorized to access.'));
                },
            });
        }
        openAssignWaiterDialog();
    };

    return <ListItemText onClick={handleAssignWaiter}>{userIsAnAvailableWaiter(order.servedBy) ? translate('Reassign waiter') : translate('Assign waiter')}</ListItemText>;
}

type Props = {
    disabled?: boolean;
    order: OrderVm;
    openAssignWaiterDialog: () => void;
};
