/**
 * @prettier
 */
import type { OfficeDeliveryItemVm } from 'src/scenes/letseatadmin/OfficeDeliveryPage';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';

export function sendDeliveryItemLabelsMessageToMobileApp(deliveryItemsLabels: Array<OfficeDeliveryItemVm>, fileName: string) {
    if (isMobileApp()) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: 'CREATE_AND_DOWNLOAD_DELIVERY_ITEM_LABELS',
                data: { deliveryItemsLabels, fileName },
            })
        );
    }
}
