/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { memo, useEffect } from 'react';
import * as React from 'react';
import { Accordion } from 'src/components/Accordion';
import { posReducer } from 'src/reducers/posReducer';
import type { PosCourseOrderDetails } from 'src/types/PosCourseOrderDetails';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export const CourseOrderDetailsAccordion: React.ComponentType<Props> = memo<Props>(({ children, course }: Props) => {
    const classes = useStyles();

    const courseIdSelected = useSelector((state) => state.pos.courseIdSelected);
    const courseOrderDetailsAccordionOpened = useSelector((state) => state.pos.courseOrderDetailsAccordionOpened);

    const setCourseIdSelected = useAction(posReducer.actions.setCourseIdSelected);
    const setCourseOrderDetailsAccordionOpened = useAction(posReducer.actions.setCourseOrderDetailsAccordionOpened);

    const isCourseSelected = courseIdSelected === course.courseId;
    const displayCustomerAccordion = !!isCourseSelected && !!courseOrderDetailsAccordionOpened;

    useEffect(() => {
        if (isCourseSelected) setCourseOrderDetailsAccordionOpened(true);
    }, [isCourseSelected]);

    const onClickCustomerOrderDetailItem = () => {
        if (isCourseSelected) {
            return setCourseOrderDetailsAccordionOpened(!courseOrderDetailsAccordionOpened);
        }

        setCourseIdSelected(course.courseId);
        setCourseOrderDetailsAccordionOpened(true);
    };

    return (
        <div className={classes.container}>
            <div
                onClick={onClickCustomerOrderDetailItem}
                className={classNames(
                    classes.customerAccordion,
                    displayCustomerAccordion ? classes.customerAccordionOpened : classes.customerAccordionClosed,
                    isCourseSelected ? classes.customerAccordionSelected : ''
                )}
            >
                <span>{course.name}</span>
                {!displayCustomerAccordion && <ExpandMore aria-label={'expandMoreIcon'} />}
                {displayCustomerAccordion && <ExpandLess aria-label={'expandLessIcon'} />}
            </div>
            <Accordion open={displayCustomerAccordion}>
                <Card classes={{ root: classes.cardStyle }}>{children}</Card>
            </Accordion>
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    customerAccordion: {
        width: '100%',
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        padding: '8px 18px',
        borderRadius: '10px 10px 0 0',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 15,
        transition: 'border-radius 0.25s linear',
    },
    customerAccordionSelected: {
        backgroundColor: '#06B7A2',
        color: '#FFF',
    },
    customerAccordionOpened: {
        borderRadius: '10px 10px 0 0',
    },
    customerAccordionClosed: {
        borderRadius: '10px',
        color: '#324054',
        border: '1px solid #E6E6E6',
    },
    containerResume: {
        fontSize: 13,
        fontFamily: theme.typography.regular,
        color: '#87888B',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 10px',
    },
    totalText: {
        color: '#2E3748',
    },
    line: {
        width: '95%',
        height: 1,
        borderTop: '1px dashed #87888B',
        margin: '12px auto',
    },
    cardStyle: {
        border: '1px solid #E6E6E6',
        borderRadius: '0 0 10px 10px',
        boxShadow: 'none',
    },
}));

type Props = {
    course: PosCourseOrderDetails;
    children?: React.ReactNode;
};
