/**
 * @prettier
 */
import { createActions, createReducer } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators }: any = createActions(
    {
        openDialog: ['restaurantDetails', 'showPrivateInfo'],
        closeDialog: [],
        changeRestaurantDetails: ['form'],
        changeRestaurantDetailsSuccess: [],
        changeRestaurantDetailsFailure: ['errorMessage'],
    },
    { prefix: 'ChangeRestaurantDetailsDialogReducer/' }
);

export const ChangeRestaurantDetailsDialogTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    open: false,
    restaurantDetails: {} as any,
    errorMessage: null,
    showPrivateInfo: false,
} as const;

/* ------------- Reducers ------------- */
export const openDialog = (state: any, { restaurantDetails, showPrivateInfo }: any): any => {
    return {
        ...state,
        open: true,
        showPrivateInfo: showPrivateInfo,
        restaurantDetails,
    };
};

export const closeDialog = (state: any): any => {
    return INITIAL_STATE;
};

export const changeRestaurantDetails = (state: any): any => {
    return {
        ...state,
        errorMessage: null,
    };
};

export const changeRestaurantDetailsSuccess = (state: any): any => {
    return {
        ...state,
    };
};

export const changeRestaurantDetailsFailure = (state: any, { errorMessage }: any): any => {
    return {
        ...state,
        errorMessage,
    };
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer: any = createReducer(INITIAL_STATE, {
    [Types.OPEN_DIALOG]: openDialog,
    [Types.CLOSE_DIALOG]: closeDialog,
    [Types.CHANGE_RESTAURANT_DETAILS]: changeRestaurantDetails,
    [Types.CHANGE_RESTAURANT_DETAILS_SUCCESS]: changeRestaurantDetailsSuccess,
    [Types.CHANGE_RESTAURANT_DETAILS_FAILURE]: changeRestaurantDetailsFailure,
});
