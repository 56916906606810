/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { hideMenuItemApi } from 'src/api/letseatmanager/menuItem/hideMenuItemApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormDateTimePicker } from 'src/components/form/FormDateTimePicker';
import { FormRadio } from 'src/components/form/FormRadio';
import { FormRadioGroup } from 'src/components/form/FormRadioGroup';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function HideMenuItemDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { isSubmitting },
        control,
    } = form;

    const open = useSelector((state) => state.app2.hideMenuItemDialog.open);
    const menuItem = useSelector((state) => state.app2.hideMenuItemDialog.menuItem);
    const onSuccess = useSelector((state) => state.app2.hideMenuItemDialog.onSuccess);
    const closeHideMenuItemDialog = useAction(app2.actions.closeHideMenuItemDialog);
    const [loading, setLoading] = useState(false);

    const FormOptions = Object.freeze({
        JUST_TODAY: 'JUST_TODAY',
        SET_A_DAY: 'SET_A_DAY',
        UNDEFINED: 'UNDEFINED',
    });

    const option = useWatch({ name: 'option', control, defaultValue: FormOptions.JUST_TODAY });

    const handleClose = () => {
        if (loading && isSubmitting) return;
        closeHideMenuItemDialog();
    };

    const handleSubmit = async (form: any) => {
        let hiddenUntil;
        if (form.option === FormOptions.JUST_TODAY) {
            const today = new Date();
            let tomorrow = moment(today).startOf('day');
            tomorrow = tomorrow.clone().add(1, 'days');
            hiddenUntil = tomorrow.toDate();
        } else if (form.option === FormOptions.SET_A_DAY) {
            hiddenUntil = form.date;
        } else if (form.option === FormOptions.UNDEFINED) {
            hiddenUntil = undefined;
        } else {
            hiddenUntil = undefined;
        }
        setLoading(true);
        const response = await hideMenuItemApi({ menuItemId: menuItem.menuItemId, hiddenUntil: hiddenUntil });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        onSuccess();
        handleClose();
    };

    return (
        <Dialog title={translate('Hide @menuItem', { menuItem: menuItem?.name ?? '' })} open={open} onClose={handleClose}>
            <Form onSubmit={handleSubmit} form={form}>
                <Grid container spacing={3}>
                    <Grid item>
                        <h3>{translate('Your customers are going to miss it. How long do you want this product to be unavailable?')}</h3>
                        <FormRadioGroup name='option' defaultValue={FormOptions.JUST_TODAY}>
                            <Grid item xs={12}>
                                <FormRadio label={translate('Just today (tomorrow will be available)')} value={FormOptions.JUST_TODAY} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormRadio label={translate('Set a date')} value={FormOptions.SET_A_DAY} />
                                {option === FormOptions.SET_A_DAY && (
                                    <Grid className={classes.dates} container direction='row' spacing={3} alignItems='center'>
                                        <Grid item>
                                            <FormDateTimePicker name='date' label={translate('Fecha')} />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <FormRadio label={translate('Undefined')} value={FormOptions.UNDEFINED} />
                            </Grid>
                        </FormRadioGroup>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {loading ? translate('Hiding...') : translate('Hide')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    inputDescription: {
        height: '15vh',
        alignItems: 'flex-start',
    },
    inputDescriptionError: {
        border: '2px solid red',
    },
    dates: {
        marginLeft: 20,
    },
}));
