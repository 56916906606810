/**
 * @prettier
 */
export const TerminalPaymentStatuses = {
    WAITING_FOR_PAYMENT: 'WAITING_FOR_PAYMENT',
    PAID: 'PAID',
    CANCELLED: 'CANCELLED',
} as const;

export type TerminalPaymentStatus = typeof TerminalPaymentStatuses[keyof typeof TerminalPaymentStatuses];
