/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { DropDown } from 'src/components/DropDown';
import { DropDownItem } from 'src/components/DropDownItem';
import { translate } from 'src/i18n/translate';
import { useMenuCategories } from 'src/services/menuCategory/useMenuCategories';
import type { MenuItemId } from 'src/types/Id';

export function MenuCategoriesUsingMenuItemDropDown({ menuItemId }: Props): React.ReactElement | null {
    const classes = useStyles();

    const { menuCategories } = useMenuCategories();

    const menuCategoriesUsingMenuItem = menuCategories.filter((menuCategory) => menuCategory.menuItemIds.includes(menuItemId));

    if (!menuCategoriesUsingMenuItem.length) return null;

    return (
        <DropDown label={translate('Categories')} labelAsPlaceholder classes={{ button: classes.button, dropdown: classes.dropdown }}>
            {menuCategoriesUsingMenuItem.map((menuCategory) => (
                <DropDownItem key={menuCategory.menuCategoryId} value={menuCategory.menuCategoryId} className={classes.dropdownItem}>
                    {menuCategory.name}
                </DropDownItem>
            ))}
        </DropDown>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        color: 'white',
        backgroundColor: theme.palette.surface.brandContrast,
        height: 24,
        minHeight: 20,
        padding: '2px 30px 2px 8px',
        borderRadius: 8,
        border: 0,
        fontSize: 12,
        '&:hover': {
            backgroundColor: `${theme.palette.surface.brandContrast}a1`,
            color: 'white',
            border: 0,
        },
    },
    dropdown: {
        backgroundColor: theme.palette.surface.primary,
        color: theme.palette.text.primary,
    },
    dropdownItem: {
        color: theme.palette.text.primary,
        fontSize: 14,
        marginLeft: 10,
        padding: '8px 8px 8px 18px',
    },
}));

type Props = {
    menuItemId: MenuItemId;
};
