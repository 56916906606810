/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export async function getKitchenOrdersApi(request: GetKitchenOrdersApiRequest): ApiSauceResponse<GetKitchenOrdersApiResponse> {
    return letseatmanagerApiMethod('order/getKitchenOrdersApi', request);
}

type GetKitchenOrdersApiRequest = {
    restaurantId: RestaurantId;
};

export type GetKitchenOrdersApiResponse = {
    currentPreparedKitchenOrders: Array<OrderVm>;
    currentPreparingKitchenOrders: Array<OrderVm>;
    kitchenOrdersSummary: KitchenOrdersSummaryVm;
};

export type KitchenOrdersSummaryVm = {
    totalOrders: number;
    categories: Array<CategorySummary>;
};

export type CategorySummary = {
    categoryName: string;
    orderItems: Array<{
        name: string;
        quantity: string;
    }>;
};
