/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { useAction } from 'src/utils/react/useAction';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function OrderCardRejectNewOrderButton({ order, disabled, onChange }: Props): React.ReactElement {
    const classes = useStyles();

    const [userHasRolePermission] = useUserHasRolePermission();

    const openVerifyPosPasswordDialog = useAction(app2.actions.openVerifyPosPasswordDialog);
    const openRejectOrderDialog = useAction(app2.actions.openRejectOrderDialog);

    const handleClickRejectOrder = async () => {
        if (!userHasRolePermission(RolePermissions.REJECT_ORDERS)) {
            return openVerifyPosPasswordDialog({
                onAuthorized: () => {
                    openRejectOrderDialog({
                        orderId: order.orderId,
                        onReject: (order: OrderVm) => {
                            onChange?.(order);
                        },
                    });
                },
                onUnauthorized: () => alert(translate('Unauthorized to access.')),
            });
        }
        openRejectOrderDialog({
            orderId: order.orderId,
            onReject: (order: OrderVm) => {
                onChange?.(order);
            },
        });
    };

    return (
        <SecuredContent rolePermission={RolePermissions.REJECT_ORDERS}>
            <Button secondary disabled={disabled} onClick={handleClickRejectOrder} classes={{ button: classes.button }}>
                {translate('Reject')}
            </Button>
        </SecuredContent>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        width: '100%',
    },
}));

type Props = {
    order: OrderVm;
    disabled: boolean;
    onChange?: any;
};
