/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { memo, useEffect, useState } from 'react';
import * as React from 'react';
import { Accordion } from 'src/components/Accordion';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { ArrowDownIcon } from 'src/icons/ArrowDownIcon';
import { posReducer } from 'src/reducers/posReducer';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export const CustomerOrderDetailsAccordion: React.ComponentType<Props> = memo<Props>(({ children, customerOrderDetails }: Props) => {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [customerOrderDetailsOpened, setCustomerOrderDetailsOpened] = useState<boolean>(false);

    const customerNumberSelected = useSelector((state) => state.pos.customerNumberSelected);

    const setCustomerNumberSelected = useAction(posReducer.actions.setCustomerNumberSelected);

    const isCustomerSelected = customerNumberSelected === customerOrderDetails.customerNumber;
    const displayCustomerAccordion = !!isCustomerSelected && !!customerOrderDetailsOpened;

    useEffect(() => {
        if (isCustomerSelected) setCustomerOrderDetailsOpened(true);
    }, [isCustomerSelected]);

    const onClickCustomerOrderDetailItem = () => {
        if (isCustomerSelected) {
            return setCustomerOrderDetailsOpened(!customerOrderDetailsOpened);
        }

        setCustomerNumberSelected(customerOrderDetails.customerNumber);
        setCustomerOrderDetailsOpened(true);
    };

    return (
        <div className={classes.container}>
            <div
                onClick={onClickCustomerOrderDetailItem}
                className={classNames(
                    classes.customerAccordion,
                    displayCustomerAccordion ? classes.customerAccordionOpened : classes.customerAccordionClosed,
                    isCustomerSelected ? classes.customerAccordionSelected : ''
                )}
            >
                <Text
                    className={classNames(
                        displayCustomerAccordion ? classes.customerAccordionOpened : classes.customerAccordionTextClosed,
                        isCustomerSelected ? classes.customerAccordionTextSelected : ''
                    )}
                >
                    {translate('Customer @customerNumber', { customerNumber: customerOrderDetails.customerNumber })}
                </Text>
                {!displayCustomerAccordion && <ArrowDownIcon title={translate('arrowDown Icon')} color={isCustomerSelected ? '#FFF' : '#000'} />}
                {displayCustomerAccordion && <ArrowDownIcon title={translate('arrowDown Icon')} className={classes.upsideDown} color='#FFF' />}
            </div>
            <Accordion open={displayCustomerAccordion}>
                <Card classes={{ root: classes.cardStyle }}>
                    {children}
                    <div className={classes.containerResume}>
                        <span>{translate('Articles Subtotal')}</span>
                        <span>{formatAsCurrencyNumber(customerOrderDetails.payment?.subtotal ?? 0)}</span>
                    </div>
                    <div className={classes.line}></div>
                    <div className={classNames(classes.containerResume, classes.totalText)}>
                        <span>{translate('Total')}</span>
                        <span>{formatAsCurrencyNumber(customerOrderDetails.payment?.total ?? 0)}</span>
                    </div>
                </Card>
            </Accordion>
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    customerAccordion: {
        width: '100%',
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        padding: '8px 18px',
        borderRadius: '10px 10px 0 0',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 15,
        transition: 'border-radius 0.25s linear',
    },
    customerAccordionSelected: {
        backgroundColor: theme.palette.surface.brandContrast,
    },
    customerAccordionOpened: {
        borderRadius: '10px 10px 0 0',
    },
    customerAccordionClosed: {
        borderRadius: '10px',
        border: '1px solid #E6E6E6',
    },
    customerAccordionTextClosed: {
        color: theme.palette.text.primary,
    },
    customerAccordionTextSelected: {
        color: theme.palette.text.invert,
    },
    containerResume: {
        fontSize: 14,
        fontFamily: theme.typography.regular,
        color: theme.palette.text.secondary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 12px',
    },
    totalText: {
        color: theme.palette.text.primary,
    },
    line: {
        width: '100%',
        height: 1,
        borderTop: `1px dashed ${theme.palette.border.primary}`,
    },
    cardStyle: {
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: '0 0 8px 8px',
        boxShadow: 'none',
    },
    upsideDown: {
        transform: 'rotate(180deg)',
    },
}));

type Props = {
    customerOrderDetails: PosCustomerOrderDetails;
    children?: React.ReactNode;
};
