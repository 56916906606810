/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button, Chip } from '@pidedirecto/ui';
import { EditIcon, TrashCanIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import { useMenus } from 'src/services/menu/useMenus';
import type { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import { classNames } from 'src/utils/react/classNames';

export function MenuCategoryListItem({ menuCategory, onChangeMenuCategory, onRemoveMenuCategory, onClick }: Props): React.ReactElement {
    const classes = useStyles();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();
    const { menus } = useMenus();

    const menusWithMenuCategory = menus.filter((menu) => menu.menuCategoryIds.includes(menuCategory.menuCategoryId));

    const handleEdit = (e: any) => {
        e.stopPropagation();
        onChangeMenuCategory(menuCategory);
    };

    const handleRemove = (e: any) => {
        e.stopPropagation();
        onRemoveMenuCategory(menuCategory);
    };

    return (
        <div className={classNames(classes.container)} onClick={() => onClick?.(menuCategory)}>
            <div className={classes.infoContainer}>
                <span className={classes.name}>{menuCategory.name}</span>
                <div className={classes.buttonsContainer}>
                    <Button variant='icon' onClick={handleEdit} disabled={isMenuEditionDisabled}>
                        <EditIcon title={translate(`Edit @menuCategoryName`, { menuCategoryName: menuCategory.name })} color={'#6C7076'} size={20} />
                    </Button>
                    <Button variant='icon' onClick={handleRemove} disabled={isMenuEditionDisabled}>
                        <TrashCanIcon title={translate(`Remove @menuCategoryName`, { menuCategoryName: menuCategory.name })} color={'#E37878'} size={20} />
                    </Button>
                </div>
            </div>
            <div className={classes.chipsContainer}>
                {menusWithMenuCategory.map((menu) => (
                    <Chip classes={{ chip: classes.chipContainer }} key={menu.menuId}>
                        {menu.name}
                    </Chip>
                ))}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        gap: 10,
    },
    name: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    modifiers: {
        fontFamily: theme.typography.regular,
        fontSize: 13,
        color: '#9a9b9b',
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        gap: 5,
    },
    iconButton: {
        padding: 10,
        flexShrink: 0,
        height: 'fit-content',
    },
    chipsContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        width: '100%',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    chipContainer: {
        whiteSpace: 'nowrap',
    },
}));

type Props = {
    menuCategory: MenuCategoryVm;
    onChangeMenuCategory: any;
    onRemoveMenuCategory: any;
    onClick?: any;
};
