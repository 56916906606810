/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { ContextualMenu } from 'src/components/ContextualMenu';
import { ContextualMenuItem } from 'src/components/ContextualMenuItem';
import { translate } from 'src/i18n/translate';
import { EditIcon } from 'src/icons/EditIcon';
import { TrashcanIcon } from 'src/icons/TrashcanIcon';
import type { RestaurantTaxVm } from 'src/types/RestaurantTaxVm';

export function RestaurantTaxListItem({ tax, onChangeTax, onRemoveTax, onClick }: Props): React.ReactElement {
    const classes = useStyles();

    const handleClick = () => {
        onClick?.(tax);
    };

    const handleChangeTax = () => {
        onChangeTax?.(tax);
    };

    const handleRemoveTax = () => {
        onRemoveTax?.(tax);
    };

    return (
        <div className={classes.container} onClick={handleClick}>
            <div className={classes.taxInfoContainer}>
                <div className={classes.nameContainer}>
                    <span className={classes.name}>{tax.name}</span>
                    <span className={classes.name}>{tax.description}</span>
                    <span className={classes.name}>{tax.taxRate * 100}%</span>
                    <span className={classes.name}>{tax.taxType}</span>
                    <span className={classes.name}>
                        {tax.menuItemIds?.length} {translate('Products')}
                    </span>
                </div>
            </div>
            <div className={classes.sectionContainer}>
                <ContextualMenu>
                    <ContextualMenuItem onClick={handleChangeTax}>
                        <EditIcon color={'#6C7076'} />
                        {translate('Edit')}
                    </ContextualMenuItem>

                    <ContextualMenuItem onClick={handleRemoveTax}>
                        <TrashcanIcon color={'#E37878'} />
                        {translate('Remove')}
                    </ContextualMenuItem>
                </ContextualMenu>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    taxInfoContainer: {
        width: '100%',
    },
    sectionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    nameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    name: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        flex: '1 1 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: 'auto',
        },
        maxWidth: '20%',
        minWidth: '20%',
        textOverflow: 'ellipsis',
    },
}));

type Props = {
    tax: RestaurantTaxVm;
    onChangeTax: any;
    onRemoveTax: any;
    onClick?: any;
};
