/**
 * @prettier
 */
import type { PrinterInstruction } from 'src/services/printer/types/PrinterInstruction';
import type { PrinterVm } from 'src/types/PrinterVm';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';

export function sendPrintPrinterInstructionsMessageToMobileApp(printInstructions: Array<PrinterInstruction>, printer: PrinterVm) {
    if (isMobileApp()) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: 'PRINT_PRINTER_INSTRUCTIONS',
                data: { printInstructions, printer },
            })
        );
    }
}
