/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { RestaurantEmployeeDayShiftVm } from 'src/types/RestaurantEmployeeDayShiftVm';

export async function findCurrentRestaurantEmployeesClockedInApi(request: FindCurrentRestaurantEmployeesClockedInApiRequest): ApiSauceResponse<Array<RestaurantEmployeeDayShiftVm>> {
    return letseatmanagerApiMethod('restaurantEmployeeDayShift/findCurrentRestaurantEmployeesClockedInApi', request);
}

type FindCurrentRestaurantEmployeesClockedInApiRequest = {
    restaurantId: RestaurantId;
};
