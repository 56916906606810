/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import { FilterIcon, RefreshIcon } from '@pidedirecto/ui/icons';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { createNextSubscriptionPaymentApi } from 'src/api/letseatadmin/subscription/createNextSubscriptionPaymentApi';
import type { SubscriptionVm } from 'src/api/letseatadmin/subscription/findSubscriptionsApi';
import { removeSubscriptionApi } from 'src/api/letseatadmin/subscription/removeSubscriptionApi';
import { updateSubscriptionAfterPaymentApi } from 'src/api/letseatadmin/subscription/updateSubscriptionAfterPaymentApi';
import { createManualInvoiceForSubscriptionApi } from 'src/api/letseatmanager/subscription/createManualInvoiceForSubscriptionApi';
import { createManualInvoiceForSubscriptionInvoicedDeliveriesApi } from 'src/api/letseatmanager/subscription/createManualInvoiceForSubscriptionInvoicedDeliveriesApi';
import { createManualInvoicesForSubscriptionRestaurantBillingCommissionsApi } from 'src/api/letseatmanager/subscription/createManualInvoicesForSubscriptionRestaurantBillingCommissionsApi';
import { Chip } from 'src/components/Chip';
import type { FilterState } from 'src/components/input/FromToPaymentDateFilter';
import { ChangeBillingToolbarButton } from 'src/components/mui-datatables/ChangeBillingToolbarButton';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CreateInvoiceButton } from 'src/components/mui-datatables/CreateInvoiceButton';
import { CreatePaymentToolbarButton } from 'src/components/mui-datatables/CreatePaymentToolbarButton';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { Table } from 'src/components/Table';
import { locale } from 'src/config/configureI18n';
import { SubscriptionPaymentErrorTypes } from 'src/constants/SubscriptionPaymentErrorType';
import { SubscriptionPaymentMethods } from 'src/constants/SubscriptionPaymentMethod';
import { SubscriptionPlans } from 'src/constants/SubscriptionPlan';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { ChangeSubscriptionDialog } from 'src/scenes/letseatadmin/subscription/ChangeSubscriptionDialog';
import { EditBillingDialog } from 'src/scenes/letseatadmin/subscription/EditBillingDialog';
import { FiltersSubscriptionDialog } from 'src/scenes/letseatadmin/subscription/FiltersSubscriptionDialog';
import type { SubscriptionId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { countdownDays } from 'src/utils/date/countdownDays';
import { formatDateString } from 'src/utils/date/formatDateString';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { overdueDays } from 'src/utils/date/overdueDays';
import { useAction } from 'src/utils/react/useAction';
import { translatePaymentRejectReasonCompact } from 'src/utils/translate/translatePaymentRejectReasonCompact';
import { translateSubscriptionPaymentErrorType } from 'src/utils/translate/translateSubscriptionPaymentErrorType';
import { openSubscriptionPage } from 'src/utils/window/openSubscriptionPage';

export function SubscriptionsTable({ onLoad, subscriptions, onRefresh, filter, onCreateSubscription, noDateInvoiceConcept, onChangeFilter }: Props): React.ReactElement {
    const classes = useStyles();

    const [changeSubscriptionDialogState, setChangeSubscriptionDialogState] = useState<{ open: boolean; subscriptionId: SubscriptionId | undefined }>({ open: false, subscriptionId: undefined });
    const [editBillingDialogState, setEditBillingDialogState] = useState<{ open: boolean; subscriptionId: SubscriptionId | undefined }>({ open: false, subscriptionId: undefined });
    const [filtersSubscriptionDialogState, setFiltersSubscriptionDialogState] = useState(false);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const translateSubscriptionPaymentMethod = (subscription: SubscriptionVm) => {
        if (subscription.subscriptionPaymentMethod === SubscriptionPaymentMethods.CARD && subscription.cardIds) {
            return 'Domiciliado';
        } else {
            return translate(subscription.subscriptionPaymentMethod);
        }
    };

    const createNextSubscriptionPayments = async (subscriptionIds: Array<SubscriptionId>) => {
        onLoad(true);
        const result: Record<string, any> = {};
        let counter = 0;
        for (const subscriptionId of subscriptionIds) {
            counter++;
            showSnackbar({ message: `Creating payments ${counter} of ${subscriptionIds.length} (DONT CLOSE OR LEAVE PAGE)`, autoHideDuration: null });
            const response = await createNextSubscriptionPaymentApi({ subscriptionId });
            if (!response.ok) {
                onLoad(false);
                alertKnownErrorOrSomethingWentWrong(response);
                result['Skipped'] = subscriptionIds.length - counter;
                break;
            }
            if (response.data.paymentErrorType) {
                const errorType = translateSubscriptionPaymentErrorType(response.data.paymentErrorType);
                result[errorType] = (result[errorType] ?? 0) + 1;
                continue;
            }
            result['Success'] = (result['Success'] ?? 0) + 1;
        }
        showSnackbar({ message: `Creating invoice DONE (YOU MAY LEAVE PAGE)` });
        alert(
            'Result:\n' +
                Object.entries(result)
                    .map(([error, count]: [any, any]) => `${error}: ${String(count)}`)
                    .join('\n')
        );
        await onRefresh();
    };

    const removeSubscriptions = async (subscriptionIds: Array<SubscriptionId>) => {
        onLoad(true);
        for (const subscriptionId of subscriptionIds) {
            const response = await removeSubscriptionApi({ subscriptionId });
            if (!response.ok) {
                onLoad(false);
                alertKnownErrorOrSomethingWentWrong(response);
                await onRefresh();
                return;
            }
        }
        await onRefresh();
    };

    const handleCancelSubscription = async (selectedSubscriptionIds: Array<SubscriptionId>) => {
        const password = window.prompt('Please enter password to remove Subscription', 'Password');
        if (password === '4343') {
            const remove = window.confirm('Are you sure you want to delete this subscirption(s)?');
            if (remove) {
                onLoad(true);
                await removeSubscriptions(selectedSubscriptionIds);
                onLoad(false);
                await onRefresh();
            }
        } else {
            alert('Ask your seller for the password to delete subscription');
        }
    };

    const updateSubscriptionAfterPayment = async (subscriptionIds: Array<SubscriptionId>) => {
        onLoad(true);
        const result: Record<string, any> = {};
        let counter = 0;
        for (const subscriptionId of subscriptionIds) {
            counter++;
            showSnackbar({ message: `Updating payments ${counter} of ${subscriptionIds.length} (DONT CLOSE OR LEAVE PAGE)`, autoHideDuration: null });
            const response = await updateSubscriptionAfterPaymentApi({ subscriptionId });
            if (!response.ok) {
                onLoad(false);
                alertKnownErrorOrSomethingWentWrong(response);
                result['Skipped'] = subscriptionIds.length - counter;
                break;
            }
            result['Success'] = (result['Success'] ?? 0) + 1;
        }
        alert(
            'Result:\n' +
                Object.entries(result)
                    .map(([error, count]: [any, any]) => `${error}: ${String(count)}`)
                    .join('\n')
        );
        await onRefresh();
    };

    const createManualInvoicesForSubscriptions = async (subscriptionIds: Array<SubscriptionId>) => {
        onLoad(true);
        const result: Record<string, number> = {
            Success: 0,
            Error: 0,
        };

        let counter = 0;
        for (const subscriptionId of subscriptionIds) {
            counter++;
            showSnackbar({ message: `Creating invoice ${counter} of ${subscriptionIds.length} (DONT CLOSE OR LEAVE PAGE)`, autoHideDuration: null });
            const response = await createManualInvoiceForSubscriptionApi({
                subscriptionId,
                from: moment(filter.from).format('YYYY-MM-DD'),
                to: moment(filter.to).format('YYYY-MM-DD'),
                noDateInvoiceConcept: noDateInvoiceConcept,
            });

            if (!response.ok) {
                result['Error'] = result['Error'] + 1;
                alertKnownErrorOrSomethingWentWrong(response);
            } else if (response.data?.errorMessage) {
                result[response.data?.errorMessage] = (result[response.data?.errorMessage] ?? 0) + 1;
            } else {
                result['Success'] = result['Success'] + 1;
            }
        }
        showSnackbar({ message: `Creating invoice DONE (YOU MAY LEAVE PAGE)` });
        alert(
            'Result:\n' +
                Object.entries(result)
                    .map(([error, count]: [any, any]) => `${error}: ${String(count)}`)
                    .join('\n')
        );
        await onRefresh();
    };

    const createManualInvoicesForSubscriptionInvoicedDeliveries = async (subscriptionIds: Array<SubscriptionId>) => {
        onLoad(true);
        const result: Record<string, number> = {
            Success: 0,
            Error: 0,
        } as const;
        let counter = 0;
        for (const subscriptionId of subscriptionIds) {
            counter++;
            showSnackbar({ message: `Creating invoice ${counter} of ${subscriptionIds.length} (DONT CLOSE OR LEAVE PAGE)`, autoHideDuration: null });
            const response = await createManualInvoiceForSubscriptionInvoicedDeliveriesApi({
                subscriptionId,
                from: moment(filter.from).format('YYYY-MM-DD'),
                to: moment(filter.to).format('YYYY-MM-DD'),
                noDateInvoiceConcept: noDateInvoiceConcept,
            });

            if (!response.ok) {
                result['Error'] = result['Error'] + 1;
                alertKnownErrorOrSomethingWentWrong(response);
            } else if (response.data?.errorMessage) {
                result[response.data?.errorMessage] = (result[response.data?.errorMessage] ?? 0) + 1;
            } else {
                result['Success'] = result['Success'] + 1;
            }
        }
        showSnackbar({ message: `Creating invoice DONE (YOU MAY LEAVE PAGE)` });
        alert(
            'Result:\n' +
                Object.entries(result)
                    .map(([error, count]: [any, any]) => `${error}: ${String(count)}`)
                    .join('\n')
        );
        await onRefresh();
    };

    const createManualInvoicesForSubscriptionRestaurantBillingCommissions = async (subscriptionIds: Array<SubscriptionId>) => {
        onLoad(true);
        const result: Record<string, number> = {
            Success: 0,
            Error: 0,
        } as const;
        let counter = 0;
        for (const subscriptionId of subscriptionIds) {
            counter++;
            showSnackbar({ message: `Creating invoice ${counter} of ${subscriptionIds.length} (DONT CLOSE OR LEAVE PAGE)`, autoHideDuration: null });
            const response = await createManualInvoicesForSubscriptionRestaurantBillingCommissionsApi({
                subscriptionId,
                from: moment(filter.from).format('YYYY-MM-DD'),
                to: moment(filter.to).format('YYYY-MM-DD'),
                noDateInvoiceConcept: noDateInvoiceConcept,
            });

            if (!response.ok) {
                result['Error'] = result['Error'] + 1;
                alertKnownErrorOrSomethingWentWrong(response);
            } else if (response.data?.errorMessage) {
                result[response.data?.errorMessage] = (result[response.data?.errorMessage] ?? 0) + 1;
            } else {
                result['Success'] = result['Success'] + 1;
            }
        }
        showSnackbar({ message: `Creating invoice DONE (YOU MAY LEAVE PAGE)` });
        alert(
            'Result:\n' +
                Object.entries(result)
                    .map(([error, count]: [any, any]) => `${error}: ${String(count)}`)
                    .join('\n')
        );
        await onRefresh();
    };

    const openFiltersSubscriptionDialog = () => {
        setFiltersSubscriptionDialogState(true);
    };

    const closeFiltersSubscriptionDialog = () => {
        setFiltersSubscriptionDialogState(false);
    };

    return (
        <>
            <FiltersSubscriptionDialog open={filtersSubscriptionDialogState} filter={filter} onChangeFilter={onChangeFilter} onClose={closeFiltersSubscriptionDialog} />
            <ChangeSubscriptionDialog
                open={changeSubscriptionDialogState.open}
                subscriptionId={changeSubscriptionDialogState.subscriptionId}
                onClose={() => setChangeSubscriptionDialogState({ open: false, subscriptionId: undefined })}
                onChangeSubscription={onRefresh}
            />
            <EditBillingDialog
                open={editBillingDialogState.open}
                subscriptionId={editBillingDialogState.subscriptionId}
                onClose={() => setEditBillingDialogState({ open: false, subscriptionId: undefined })}
                onChangeSubscription={onRefresh}
            />
            <Table
                className={classes.table}
                classes={{ container: classes.containerToolbarSection }}
                data={subscriptions.map((subscription) => {
                    const countdown = countdownDays(subscription.nextPaymentDate, new Date(), TimeZones.AMERICA_MONTERREY);
                    const overdue = overdueDays(subscription.nextPaymentDate, new Date(), TimeZones.AMERICA_MONTERREY);

                    return {
                        subscriptionId: subscription.subscriptionId,
                        restaurantId: subscription.restaurantId,
                        salesforceRestaurantType: subscription.salesforceRestaurantType,
                        restaurantIds: subscription.restaurantIds,
                        cities: subscription.cities,
                        brandName: subscription.brandName,
                        restaurantNames: subscription.restaurantNames,
                        branchOffice: subscription.restaurantNames.length,
                        businessName: subscription.businessName,
                        rfc: subscription.rfc,
                        subscriptionPaymentMethod: translateSubscriptionPaymentMethod(subscription),
                        financeReference: subscription.financeReference,
                        manualInvoiceDate: formatDateString(subscription.manualInvoiceDate),
                        manualInvoiceFacturamaInvoiceFolio: subscription.manualInvoiceFacturamaInvoiceFolio,
                        manualInvoiceError: subscription.manualInvoiceError,
                        invoicedRestaurantDeliveryCost: subscription.invoicedRestaurantDeliveryCost,
                        status: subscription.status,
                        webOrders: subscription.webOrders ? 'Yes' : 'No',
                        manualOrders: subscription.manualOrders ? 'Yes' : 'No',
                        posOrders: subscription.posOrders ? 'Yes' : 'No',
                        plan: subscription.plan,
                        paymentAmount: subscription.paymentAmount,
                        previousPaymentDate: formatDateString(subscription.previousPaymentDate, TimeZones.AMERICA_MONTERREY),
                        previousPaymentAmount: subscription.previousPaymentAmount,
                        nextPaymentDate: formatDateString(subscription.nextPaymentDate, TimeZones.AMERICA_MONTERREY),
                        nextPaymentAmount:
                            subscription.plan === SubscriptionPlans.PAY_PER_USE
                                ? `${subscription.payPerUseRange1PaymentAmount ?? 0}, ${subscription.payPerUseRange2PaymentAmount ?? 0}, ${subscription.payPerUseRangeMaxPaymentAmount ?? 0}`
                                : subscription.paymentAmount,
                        // @ts-ignore
                        currency: locale === 'es' ? 'MXN' : '$',
                        cutServiceDate: formatDateString(subscription.cutServiceDate, TimeZones.AMERICA_MONTERREY),
                        clientType: subscription.clientType,
                        startServiceDate: formatDateString(subscription.startServiceDate, TimeZones.AMERICA_MONTERREY),
                        endServiceDate: formatDateString(subscription.endServiceDate, TimeZones.AMERICA_MONTERREY),
                        countdown: subscription.nextPaymentDate ? countdown : '?',
                        overdue: subscription.nextPaymentDate ? overdue : '?',
                        paymentErrorType: translateSubscriptionPaymentErrorType(subscription.paymentErrorType, [SubscriptionPaymentErrorTypes.FUTURE_PAYMENT_DATE]),
                        paymentRejectReason: translatePaymentRejectReasonCompact(subscription.paymentRejectReason),
                        contactName: subscription.contactName,
                        contactPhone: subscription.contactPhone,
                        contactEmail: subscription.contactEmail,
                        createdAt: formatDateTimeString(subscription.createdAt),
                        modifiedAt: formatDateTimeString(subscription.modifiedAt),
                    };
                })}
                columns={[
                    {
                        name: 'subscriptionId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'restaurantId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'restaurantIds',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'cities',
                        label: translate('City'),
                        options: {
                            filter: true,
                            customBodyRender: (value, tableMeta, updateValue) => <div>{value.join(',')}</div>,
                        },
                    },
                    {
                        name: 'brandName',
                        label: translate('Brand'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'salesforceRestaurantType',
                        label: translate('Salesforce'),
                        options: {
                            filter: false,
                            customBodyRender: (value) => <div>{value && <Chip>{value}</Chip>}</div>,
                        },
                    },
                    {
                        name: 'restaurantNames',
                        label: translate('Restaurants'),
                        options: {
                            filter: false,
                            customBodyRender: (value, tableMeta, updateValue) => <div>{value.join(',')}</div>,
                        },
                    },
                    {
                        name: 'branchOffice',
                        label: translate('# of Branch Office'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'businessName',
                        label: translate('Business Name'),
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'rfc',
                        label: translate('RFC'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'subscriptionPaymentMethod',
                        label: translate('Payment Method'),
                        options: {
                            filter: true,
                        },
                    },
                    {
                        name: 'financeReference',
                        label: translate('Elevate reference'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'manualInvoiceDate',
                        label: translate('Last Invoice Created'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'manualInvoiceFacturamaInvoiceFolio',
                        label: translate('Last Invoice Folio'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'manualInvoiceError',
                        label: translate('Invoice Error'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'invoicedRestaurantDeliveryCost',
                        label: translate('Invoiced Deliveries'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'status',
                        label: translate('Status'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'manualOrders',
                        label: translate('Manual Orders'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'webOrders',
                        label: translate('Web Orders'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'posOrders',
                        label: translate('AmbitOne'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'plan',
                        label: translate('Plan'),
                        options: {
                            filter: true,
                        },
                    },
                    {
                        name: 'paymentAmount',
                        label: translate('Amount'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'previousPaymentDate',
                        label: translate('Last Payment Date'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'previousPaymentAmount',
                        label: translate('Last Payment Amount'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'nextPaymentDate',
                        label: translate('Next Payment Date'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'nextPaymentAmount',
                        label: translate('Next Payment Amount'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'currency',
                        label: translate('Currency'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'cutServiceDate',
                        label: translate('Cutoff Date'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'customerType',
                        label: translate('Customer Type'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'startServiceDate',
                        label: translate('Start Service Date'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'endServiceDate',
                        label: translate('End Service Date'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'countdown',
                        label: translate('Countdown'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'overdue',
                        label: translate('Overdue'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'paymentErrorType',
                        label: translate('Payment Error'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'paymentRejectReason',
                        label: translate('Payment Reject Reason'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'contactName',
                        label: translate('Contact Name'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'contactPhone',
                        label: translate('Contact Phone'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'contactEmail',
                        label: translate('Contact Email'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'createdAt',
                        label: translate('Created'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'modifiedAt',
                        label: translate('Modified'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'multiple',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    onDownload: (buildHead, buildBody, columns, data) => {
                        return '\uFEFF' + buildHead(columns) + buildBody(data);
                    },
                    customToolbar: () => (
                        <>
                            <Button classes={{ button: classes.button }} variant='icon' onClick={openFiltersSubscriptionDialog}>
                                <FilterIcon size={18} />
                            </Button>
                            <Button classes={{ button: classes.button }} variant='icon' onClick={onRefresh}>
                                <RefreshIcon />
                            </Button>
                            <CreateToolbarButton onClick={onCreateSubscription} />
                        </>
                    ),
                    customToolbarSelect: (selectedRows) => {
                        const selectedSubscriptions: Array<SubscriptionVm> = selectedRows.data.map((row) => subscriptions[row.dataIndex]);
                        const selectedSubscriptionIds = selectedSubscriptions.map((s) => s.subscriptionId);
                        return (
                            <div className={classes.toolbar}>
                                <CreateInvoiceButton
                                    label={translate('Subscription')}
                                    tooltip={translate('Invoice Subscription Amount')}
                                    onClick={async () => {
                                        const createInvoice = window.confirm(
                                            selectedSubscriptionIds.length === 1
                                                ? 'Are you sure you want to invoice the selected Subscription Amount'
                                                : `Are you sure you want to invoices the selected ${selectedSubscriptionIds.length} Subscription Amount`
                                        );
                                        if (createInvoice) {
                                            await createManualInvoicesForSubscriptions(selectedSubscriptionIds);
                                        }
                                    }}
                                />
                                <CreateInvoiceButton
                                    label={translate('Deliveries')}
                                    tooltip={translate('Invoice Deliveries')}
                                    onClick={async () => {
                                        const createInvoice = window.confirm(
                                            selectedSubscriptionIds.length === 1
                                                ? 'Are you sure you want to invoice the selected deliveries'
                                                : `Are you sure you want to invoices the selected ${selectedSubscriptionIds.length} deliveries`
                                        );
                                        if (createInvoice) {
                                            await createManualInvoicesForSubscriptionInvoicedDeliveries(selectedSubscriptionIds);
                                        }
                                    }}
                                />
                                <CreateInvoiceButton
                                    label={translate('Commissions')}
                                    tooltip={translate('Invoice Restaurant Billing Commissions')}
                                    onClick={async () => {
                                        const createInvoice = window.confirm(
                                            selectedSubscriptionIds.length === 1
                                                ? 'Are you sure you want to invoice the selected Restaurant Billing Commissions'
                                                : `Are you sure you want to invoices the selected ${selectedSubscriptionIds.length} Restaurant Billing Commissions`
                                        );
                                        if (createInvoice) {
                                            await createManualInvoicesForSubscriptionRestaurantBillingCommissions(selectedSubscriptionIds);
                                        }
                                    }}
                                />
                                <CreatePaymentToolbarButton
                                    tooltip={translate('Update Spei Internal Subscription Payment')}
                                    onClick={async () => {
                                        const updateSubscription = window.confirm(
                                            selectedSubscriptionIds.length === 1
                                                ? 'Are you sure you want to update the Subscription'
                                                : `Are you sure you want update the selected  ${selectedSubscriptionIds.length} Subscriptions`
                                        );
                                        if (updateSubscription) {
                                            await updateSubscriptionAfterPayment(selectedSubscriptionIds);
                                        }
                                    }}
                                />
                                <CreatePaymentToolbarButton
                                    onClick={async () => {
                                        const createPayment = window.confirm(
                                            selectedSubscriptionIds.length === 1
                                                ? 'Are you sure you want to pay the selected subscriptions'
                                                : `Are you sure you want to pay the selected ${selectedSubscriptionIds.length} subscriptions`
                                        );
                                        if (createPayment) {
                                            await createNextSubscriptionPayments(selectedSubscriptionIds);
                                        }
                                    }}
                                />
                                {selectedSubscriptionIds.length === 1 && (
                                    <ChangeBillingToolbarButton
                                        onClick={() => {
                                            if (selectedSubscriptionIds.length === 1) setEditBillingDialogState({ open: true, subscriptionId: selectedSubscriptionIds[0] });
                                        }}
                                    />
                                )}
                                {selectedSubscriptionIds.length === 1 && (
                                    <ChangeToolbarButton
                                        onClick={() => {
                                            if (selectedSubscriptionIds.length === 1) setChangeSubscriptionDialogState({ open: true, subscriptionId: selectedSubscriptionIds[0] });
                                        }}
                                    />
                                )}
                                <RemoveToolbarButton
                                    onClick={async () => {
                                        await handleCancelSubscription(selectedSubscriptionIds);
                                    }}
                                />
                            </div>
                        );
                    },
                    onRowClick: (
                        rowData: Array<string>,
                        rowMeta: {
                            dataIndex: number;
                        }
                    ) => {
                        const subscriptionId = subscriptions[rowMeta.dataIndex].subscriptionId;
                        openSubscriptionPage(subscriptionId);
                    },
                }}
            />
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    containerToolbarSection: {
        position: 'relative',
        width: '100%',
        '& .MuiPaper-root': {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 0,
            '& .MuiTableCell-root': {
                fontFamily: theme.typography.regular,
                fontSize: 14,
                padding: '16px 16px',
            },
            boxShadow: '0px 0px',
            '& .MuiToolbar-root': {
                overflowY: 'auto',
                display: 'flex',
                flexWrap: 'wrap',
                gap: 8,
                backgroundColor: theme.palette.surface.primary,
                paddingRight: 0,
                paddingLeft: 0,
                '& button': {
                    backgroundColor: theme.palette.surface.primary,
                    color: theme.palette.surface.brandContrast,
                    display: 'inline-flex',
                    verticalAlign: 'middle',
                    borderRadius: 8,
                    marginRight: 4,
                    border: `1px solid ${theme.palette.border.primary}`,
                },
                '& button:hover': {
                    backgroundColor: theme.palette.surface.brandContrast,
                    color: theme.palette.text.invert,
                    border: `1px solid ${theme.palette.surface.brandContrast}`,
                },
            },
            '& .MuiTableFooter-root': {
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderTop: 0,
                borderCollapse: 'separate',
                '& p, div': {
                    fontFamily: theme.typography.regular,
                    fontSize: 13,
                },
                '& .MuiToolbar-root': {
                    backgroundColor: 'white',

                    '& .MuiButtonBase-root': {
                        backgroundColor: 'white',
                        borderRadius: 5,
                        border: `0px`,
                    },
                    '& .MuiButtonBase-root:hover': {
                        backgroundColor: 'white',
                    },
                },
                '& td': {
                    border: 0,
                },
                '& td:hover': {
                    backgroundColor: 'white',
                },
            },
            '& h6': {
                fontFamily: theme.typography.medium,
            },
        },
        '& .MuiPaper-root > div:nth-child(2)': {
            marginTop: 12,
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 12,
        },
        '& .MuiPaper-root > div:nth-child(3)': {
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderBottom: 0,
            '& th': {
                fontFamily: theme.typography.semiBold,
                backgroundColor: theme.palette.secondary.light,
                border: 0,
                fontSize: 15,
            },
            '& td, .MuiTypography-root': {
                fontFamily: theme.typography.regular,
                border: 0,
                fontSize: 13,
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiPaper-root': {
                '& .MuiToolbar-root': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    padding: '20px 0px',

                    '& .MuiButtonBase-root & button': {
                        width: 'calc(85vw / 7)',
                        height: 'calc(85vw / 7)',
                    },

                    '& div:first-child': {
                        display: 'block',
                        marginRight: 24,
                    },

                    '& div:nth-child(2)': {
                        '& > .MuiButtonBase-root:first-child': {
                            marginLeft: '0 !important',
                        },
                    },
                },
                '& .MuiTable-root': {
                    width: '100%',
                    margin: '0px auto',

                    '& .MuiTableFooter-root': {
                        backgroundColor: 'white',
                        '& .MuiToolbar-root > p:nth-child(2)': {
                            display: 'none',
                        },
                    },
                },
            },
            '& .MuiPaper-root > div:nth-child(3)': {
                width: '100%',
                margin: '0px auto',
                backgroundColor: 'white ',
            },
        },
    },
    toolbar: {
        display: 'block',
        paddingRight: theme.spacing(3),
        overflowY: 'scroll',
    },
    button: {
        verticalAlign: 'center',
        display: 'inline-flex',
        width: 50,
        height: 50,
        backgroundColor: 'red',
    },
}));

type Props = {
    onLoad: any;
    subscriptions: Array<SubscriptionVm>;
    onRefresh: any;
    onCreateSubscription: any;
    onChangeFilter: any;
    filter: FilterState;
    noDateInvoiceConcept: boolean;
};
