/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions } from '@pidedirecto/ui';
import { Form, FormIntegerField } from '@pidedirecto/ui/form';
import { useForm } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { useState } from 'react';
import { changeOrderPreparationTimeApi } from 'src/api/letseatmanager/order/changeOrderPreparationTimeApi';
import { translate } from 'src/i18n/translate';
import { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function ChangePreparationTimeDialog({ open, onClose, onSuccess, order }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        onClose();
    };

    const onSubmit = async (form: FormType) => {
        setLoading(true);
        const response = await changeOrderPreparationTimeApi({ orderId: order.orderId, preparationTime: form.preparationTime });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess(order);
        onClose();
    };

    return (
        <Dialog
            title={translate(`Assign preparation time to order #@shortOrderId`, { shortOrderId: order.shortOrderId })}
            classes={{ dialog: classes.dialog, title: classes.title }}
            open={open}
            onClose={handleClose}
        >
            <Form form={form} onSubmit={onSubmit} classes={{ form: classes.dialog }}>
                <Grid container spacing={5} direction='column' justify='center' className={classes.container}>
                    <Grid item xs={12}>
                        <FormIntegerField label={translate('Preparation Time (minutes)')} name='preparationTime' />
                    </Grid>

                    <DialogActions className={classes.buttonsContainer}>
                        <Grid item xs={6}>
                            <Button variant='secondary' onClick={handleClose} classes={{ button: classes.button }}>
                                <span className={classes.text}>{translate('Cancel')}</span>
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button type='submit' classes={{ button: classes.button }} disabled={loading}>
                                <span className={classes.text}>{translate('Assign')}</span>
                            </Button>
                        </Grid>
                    </DialogActions>
                </Grid>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        overflow: 'hidden',
    },
    title: {
        color: '#221F2B',
    },
    container: {
        padding: 24,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    dialog: {
        borderRadius: '12px',
    },
    text: {
        fontFamily: theme.typography.semiBold,
        fontSize: 17,
        textTransform: 'none',
    },
    buttonsContainer: {
        display: 'flex',
        gap: 5,
        padding: 24,
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    input: {
        width: '100%',
        minWidth: '-webkit-fill-available',
    },
    button: {
        minWidth: 150,
    },
}));

type Props = {
    open: boolean;
    order: OrderVm;
    onClose: () => void;
    onSuccess: (order: OrderVm) => void;
};

type FormType = {
    preparationTime: string;
};
