/**
 * @prettier
 */
import { PrinterContents } from 'src/constants/PrinterContent';
import { PrintTypes } from 'src/constants/PrintType';
import { translate } from 'src/i18n/translate';
import { getDeprecatedPrintersInLocalStorage } from 'src/localStorage/getDeprecatedPrintersInLocalStorage';
import { useNotification } from 'src/services/notification/useNotification';
import { printerInstructionsBuilder } from 'src/services/printer/prints/utils/printerInstructionsBuilder';
import { printerDeprecatedToPrinter } from 'src/services/printer/utils/printerDeprecatedToPrinter';
import { usePrintContent } from 'src/services/printer/utils/usePrintContent';
import { DeviceGroupId } from 'src/types/Id';
import { PrinterVm } from 'src/types/PrinterVm';
import { useSelector } from 'src/utils/react/useSelector';

export function useOpenCashRegister(): () => Promise<void> {
    let allPrinters: Array<PrinterVm> = [];

    const notification = useNotification();

    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);
    const deviceGroup = useSelector((state) => state.app.deviceGroup);
    const printers = useSelector((state) => state.app.printers);

    const deprecatedPrinters = getDeprecatedPrintersInLocalStorage().map(printerDeprecatedToPrinter);

    const { printContent, printContents } = usePrintContent();

    if (newDeviceManagementEnabled) {
        allPrinters = printers?.filter((printer: PrinterVm) =>
            printer.deviceGroups?.some((deviceGroupToSearch: { deviceGroupId: DeviceGroupId; deviceGroupName: string }) => deviceGroupToSearch.deviceGroupId === deviceGroup?.deviceGroupId)
        );
    }

    if (!newDeviceManagementEnabled) {
        allPrinters = deprecatedPrinters;
    }

    const getOpenCashRegisterInstructions = () => {
        const builder = printerInstructionsBuilder();

        builder.openCashbox();

        return builder.build();
    };

    const openCashRegister = async () => {
        if (!allPrinters?.length) {
            notification({ message: translate('Unable to open the cash register, check your connection') });
            return;
        }

        if (allPrinters.length === 1) {
            printContents([
                {
                    printer: allPrinters[0],
                    print: {
                        printType: PrintTypes.TICKET,
                        printerInstructions: getOpenCashRegisterInstructions(),
                    },
                },
            ]);
            return;
        }

        const ticketPrinters = allPrinters?.filter((printer: PrinterVm) => {
            return printer.printerContent !== PrinterContents.COMMAND;
        });

        if (!ticketPrinters?.length) {
            printContent({
                printType: PrintTypes.TICKET,
                printerInstructions: getOpenCashRegisterInstructions(),
            });
            return;
        }

        const contentsToPrint = ticketPrinters.map((printer) => ({
            printer: printer,
            print: {
                printType: PrintTypes.TICKET,
                printerInstructions: getOpenCashRegisterInstructions(),
            },
        }));

        printContents(contentsToPrint);
    };

    return openCashRegister;
}
