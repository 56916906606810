/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import { SelfRegistrationStatus, SelfRegistrationStatuses } from 'src/constants/SelfRegistrationStatus';
import { appColors } from 'src/theme/AppColors';

export function getRemainDaysColor(createdAt: Date, plan: SelfRegistrationStatus, remainingDays: string): string {
    if (
        (BigNumber(remainingDays).isGreaterThanOrEqualTo(2) && BigNumber(remainingDays).isLessThanOrEqualTo(5)) ||
        (plan === SelfRegistrationStatuses.STARTED && moment().startOf('day').diff(moment(createdAt).startOf('day'), 'days') >= 5)
    ) {
        return appColors.table.orange;
    } else if (BigNumber(remainingDays).isLessThanOrEqualTo(1)) {
        return appColors.table.red;
    } else {
        return appColors.table.transparent;
    }
}
