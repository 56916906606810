/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { EmailAddress, PinCode, RestaurantId, UserRoleId } from 'src/types/Id';
import type { UserVm } from 'src/types/UserVm';

export async function createRestaurantUserApi(request: CreateRestaurantUserApiRequest): ApiSauceResponse<UserVm> {
    return letseatmanagerApiMethod('userManagement/createRestaurantUserApi', request);
}

type CreateRestaurantUserApiRequest = {
    email: EmailAddress;
    pinCode?: PinCode;
    username: string;
    userRoleId: UserRoleId;
    name: string;
    restaurantIds: Array<RestaurantId>;
};
