/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState } from 'react';
import { app2 } from 'src/app2';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { useAction } from 'src/utils/react/useAction';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function RefundSuppliesOfCancelledOrderDialog({ open, order, onSuccess, onClose }: Props): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [userHasRolePermission] = useUserHasRolePermission();

    const openVerifyPosPasswordDialog = useAction(app2.actions.openVerifyPosPasswordDialog);
    const openRejectOrderDialog = useAction(app2.actions.openRejectOrderDialog);

    const handleExited = () => {
        if (loading) return;

        setLoading(false);
        onClose();
    };

    const onSubmit = async (refundSupplies: boolean) => {
        setLoading(true);
        if (!userHasRolePermission(RolePermissions.CANCEL_ORDERS)) {
            onClose();
            return openVerifyPosPasswordDialog({
                onAuthorized: () => {
                    openRejectOrderDialog({
                        orderId: order?.orderId,
                        onReject: (order: OrderVm) => {
                            onSuccess?.(order);
                        },
                        refundSupplies,
                    });
                },
                onUnauthorized: () => alert(translate('Unauthorized to access.')),
            });
        }
        openRejectOrderDialog({
            orderId: order?.orderId,
            onReject: (order: OrderVm) => {
                onSuccess?.(order);
            },
            refundSupplies,
        });
        setLoading(false);

        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' className={classes.form} open={open} onClose={handleExited}>
            <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Supplies used in the order')}</DialogTitle>
            <DialogContent className={classes.bodyText}>{translate('Supplies used in this order can be returned to inventory or marked as lost')}</DialogContent>
            <DialogActions className={classes.buttonsContainer}>
                <Button onClick={() => onSubmit(false)} disabled={loading}>
                    <div className={classes.acceptButton}>{translate('Mark as lost')}</div>
                </Button>
                <Button color='primary' onClick={() => onSubmit(true)} disabled={loading}>
                    <div className={classes.acceptButton}>{translate('Return the supplies')}</div>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.primary.main,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    bodyText: {
        fontFamily: theme.typography.medium,
        textAlign: 'center',
        color: theme.palette.secondary.contrastText,
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
}));

type Props = {
    open: boolean;
    order: OrderVm;
    onClose: any;
    onSuccess?: any;
};
