/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { KitchenId } from 'src/types/Id';

export async function tribalExportKitchensBillingApi(request: ExportKitchensBillingApiRequest): ApiSauceResponse<ExportKitchenssBillingResultVm> {
    return letseatadminApiMethod('kitchenBilling/tribalExportKitchensBillingApi', request);
}

type ExportKitchensBillingApiRequest = {
    from: string;
    to: string;
    paymentDate: string;
    kitchenIds: Array<KitchenId>;
};

export type ExportKitchenssBillingResultVm = {
    tribalExportFormat: string;
    exported: number;
    banned: number;
    removed: number;
    accountNotExported: number;
    nothingToTransfer: number;
};
