/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { Loader } from '@pidedirecto/ui';
import gql from 'graphql-tag';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { assignDriverToOrder } from 'src/api/appsync/mutations';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormDriverMobileNumberAutocomplete } from 'src/components/form/FormDriverMobileNumberAutocomplete';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormRestaurantDriverMobileNumberAutocomplete } from 'src/components/form/FormRestaurantDriverMobileNumberAutocomplete';
import { appsyncClient } from 'src/config/appsyncClient';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { OrderId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrongError } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrongError';
import { useSelector } from 'src/utils/react/useSelector';

export function AssignDriverDialog({ open, onClose, orderId, onSuccess, disabled }: Props): React.ReactElement {
    const form = useForm();

    const internalUser = useSelector((state) => state.authentication.internalUser);

    const {
        formState: { isSubmitting },
    } = form;
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        try {
            await appsyncClient.mutate({
                mutation: gql(assignDriverToOrder),
                variables: {
                    orderId: orderId,
                    driverId: form.driverId,
                },
            });
        } catch (e: any) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrongError(e);
            return;
        }
        setLoading(false);
        onSuccess?.();
        onClose();
    };

    return (
        <Dialog title={translate('Assign a Driver')} open={open} onClose={handleClose}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                {loading && <Loader size={40} loading={true} />}
                {!loading && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {!internalUser && <FormRestaurantDriverMobileNumberAutocomplete name='driverId' label={translate('Drivers')} placeholder={translate('All drivers')} required />}
                            {internalUser && <FormDriverMobileNumberAutocomplete name='driverId' label={translate('Drivers')} placeholder={translate('All drivers')} required />}
                        </Grid>
                    </Grid>
                )}
                <FormFixErrorHint />
                <DialogActions>
                    <Button onClick={handleClose} secondary type={'button'} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button primary type='submit' disabled={loading || isSubmitting || disabled}>
                        {isSubmitting ? translate('Assigning a driver') : translate('Assign')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    onClose: any;
    onSuccess?: any;
    orderId?: OrderId;
    disabled?: boolean;
};
