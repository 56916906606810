/**
 * @prettier
 */
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { useLoadPosMenus } from 'src/services/menu/useLoadPosMenus';
import { useChargeOrder } from 'src/services/pos/useChargeOrder';
import type { OrderVm } from 'src/types/OrderVm';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function ChargeOrderButton({ order }: Props): React.ReactElement | null {
    const { handlePay } = useChargeOrder();
    const history = useHistory();
    const loadPosMenus = useLoadPosMenus();

    const posMenu = useSelector((state) => state.pos.posMenu);

    const shouldRenderChargeButton = isPendingOrder(order.orderStatus);

    const onHandlePay = async () => {
        if (!posMenu) {
            await loadPosMenus();
        }
        history.push({
            pathname: RoutePaths.POS,
            search: history.location.search,
        });
        handlePay(order);
    };

    if (!shouldRenderChargeButton) return null;

    return (
        <SecuredContent rolePermission={RolePermissions.CHARGE_ORDERS}>
            <Button secondary onClick={onHandlePay}>
                {translate('Charge')}
            </Button>
        </SecuredContent>
    );
}

type Props = {
    order: OrderVm;
};
