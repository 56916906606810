/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, Dialog, DialogActions, DialogContent, Text } from '@pidedirecto/ui';
import { Form, FormSwitch, FormTextField } from '@pidedirecto/ui/form';
import { useForm, useWatch } from '@pidedirecto/ui/hooks';
import { useState } from 'react';
import * as React from 'react';
import { createRestaurantPaymentMethodApi } from 'src/api/letseatmanager/restaurantPaymentMethod/createRestaurantPaymentMethodApi';
import { FormChannelsMultiselectableAutocomplete } from 'src/components/form/FormChannelsMultiselectableAutocomplete';
import { FormMexicanPaymentMethodCodeSelect } from 'src/components/form/FormMexicanPaymentMethodCodeSelect';
import { FormOrderTypesMultiselectableAutocomplete } from 'src/components/form/FormOrderTypesMultiselectableAutocomplete';
import { FormPaymentMethodTypeSelect } from 'src/components/form/FormPaymentMethodTypeSelect';
import { CustomPaymentMethodIcon, CustomPaymentMethodIcons } from 'src/constants/CustomPaymentMethodIcons';
import { MexicanPaymentMethodCode, MexicanPaymentMethodCodes } from 'src/constants/MexicanPaymentMethodCode';
import { OrderType, OrderTypes } from 'src/constants/OrderType';
import { PaymentMethod, PaymentMethods } from 'src/constants/PaymentMethod';
import { RestaurantPaymentMethodApp, RestaurantPaymentMethodApps } from 'src/constants/RestaurantPaymentMethodApps';
import { RestaurantPaymentMethodTypes } from 'src/constants/RestaurantPaymentMethodType';
import { translate } from 'src/i18n/translate';
import { PaymentMethodIcon } from 'src/scenes/letseatmanager/theRestaurant/PaymentMethodIcon';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isMexico } from 'src/utils/country/isMexico';
import { isCashMexicanPaymentMethodCode } from 'src/utils/mexicanPaymentMethodCode/isCashMexicanPaymentMethodCode';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateRestaurantPaymentDialog({ open, onClose, onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();
    const form = useForm();
    const { control } = form;

    const [loading, setLoading] = useState(false);
    const [iconSelected, setIconSelected] = useState<CustomPaymentMethodIcon | undefined>(undefined);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantCountry = useSelector((state) => state.app.restaurant.country);

    const paymentReferenceEnabled = useWatch({ name: 'paymentReferenceEnabled', control });

    const handleSubmit = async (form: FormType) => {
        if (!iconSelected) return;
        setLoading(true);
        const response = await createRestaurantPaymentMethodApi({
            restaurantId,
            name: form.name,
            restaurantPaymentMethodType: RestaurantPaymentMethodTypes.RESTAURANT,
            paymentReferenceEnabled: form.paymentReferenceEnabled,
            paymentMethod: isMexico(restaurantCountry) ? getPaymentMethodByMexicanPaymentMethod(form) : form.paymentMethod,
            mexicanPaymentMethodCode: isMexico(restaurantCountry) ? form.mexicanPaymentMethodCode : undefined,
            channels: getRestaurantPaymentChannels(form),
            orderTypes: form?.orderTypes && form?.orderTypes?.length > 0 ? form?.orderTypes : Object.values(OrderTypes),
            icon: iconSelected,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
        onClose?.();
    };

    const getPaymentMethodByMexicanPaymentMethod = (form: FormType) => {
        if (isCashMexicanPaymentMethodCode(form.mexicanPaymentMethodCode)) {
            return PaymentMethods.CASH;
        }
        return PaymentMethods.CREDIT_CARD;
    };

    const getRestaurantPaymentChannels = (form: FormType) => {
        if (paymentReferenceEnabled) return [RestaurantPaymentMethodApps.PIDEDIRECTOPOS];

        if (form?.channels && form?.channels?.length) return form?.channels;

        const filteredRestaurantChannels = Object.values(RestaurantPaymentMethodApps).filter(
            (restaurantChannel: RestaurantPaymentMethodApp) => restaurantChannel !== RestaurantPaymentMethodApps.PIDEDIRECTOKIOSK
        );

        return filteredRestaurantChannels;
    };

    const onSelectIcon = (customIcon: CustomPaymentMethodIcon) => {
        if (iconSelected === customIcon) {
            setIconSelected(undefined);
            return;
        }
        setIconSelected(customIcon);
    };

    if (!open) return null;

    return (
        <Dialog open={open} onClose={onClose} title={translate('Create Payment Method')}>
            <DialogContent className={classes.dialogContent}>
                <Form form={form} onSubmit={handleSubmit}>
                    <div className={classes.formContainer}>
                        <FormTextField name='name' label={translate('Name')} required />
                        {!isMexico(restaurantCountry) && (
                            <FormPaymentMethodTypeSelect
                                name={'paymentMethod'}
                                label={translate('Payment type')}
                                defaultValue={PaymentMethods.CASH}
                                filter={[PaymentMethods.CREDIT_CARD, PaymentMethods.CASH]}
                                required
                            />
                        )}
                        {isMexico(restaurantCountry) && (
                            <FormMexicanPaymentMethodCodeSelect
                                name={'mexicanPaymentMethodCode'}
                                label={translate('Payment method for billing')}
                                disabled={loading}
                                required
                                defaultValue={MexicanPaymentMethodCodes.CASH}
                            />
                        )}
                        <FormSwitch disabled={loading} name='paymentReferenceEnabled' label={translate('Request payment reference')} />
                        {!paymentReferenceEnabled && (
                            <FormChannelsMultiselectableAutocomplete
                                name={'channels'}
                                label={translate('Channels where you accept payments with this method')}
                                exclude={[RestaurantPaymentMethodApps.PIDEDIRECTOKIOSK]}
                            />
                        )}
                        {paymentReferenceEnabled && (
                            <div className={classes.uniqueChannelContainer}>
                                <Text className={classes.titleOrderTypeSection}>{translate('Type of channels in which they accept payments with reference')}</Text>
                                <span className={classes.titleOrderTypeOption}>{translate(RestaurantPaymentMethodApps.PIDEDIRECTOPOS)}</span>
                            </div>
                        )}
                        <FormOrderTypesMultiselectableAutocomplete name={'orderTypes'} label={translate('Order types where you accept payments with this method')} />
                        <div className={classes.selectIconContainer}>
                            <p className={classes.label}>{translate('Icon for method')}</p>
                            <Card classes={{ cardContent: classes.iconsContainer }}>
                                {Object.keys(CustomPaymentMethodIcons).map((customPaymentMethodIcon) => (
                                    <Button
                                        variant={'secondary'}
                                        classes={{ button: classNames(iconSelected === CustomPaymentMethodIcons[customPaymentMethodIcon as keyof typeof CustomPaymentMethodIcons]) }}
                                        onClick={() => onSelectIcon(CustomPaymentMethodIcons[customPaymentMethodIcon as keyof typeof CustomPaymentMethodIcons])}
                                        key={`${CustomPaymentMethodIcons[customPaymentMethodIcon as keyof typeof CustomPaymentMethodIcons]}-key`}
                                    >
                                        <PaymentMethodIcon icon={CustomPaymentMethodIcons[customPaymentMethodIcon as keyof typeof CustomPaymentMethodIcons]} />
                                    </Button>
                                ))}
                            </Card>
                        </div>
                        <DialogActions className={classes.buttonsContainer}>
                            <Button variant='outline' onClick={onClose}>
                                {translate('Cancel')}
                            </Button>
                            <Button type='submit' disabled={loading}>
                                {translate('Save')}
                            </Button>
                        </DialogActions>
                    </div>
                </Form>
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        padding: 12,
        width: '100%',
    },
    dialogContent: {
        width: '100%',
        maxHeight: '95%',
        padding: 0,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    buttonsContainer: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    updateButton: {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 150,
        textTransform: 'none',
    },
    cancelButton: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 150,
        textTransform: 'none',
    },
    containerOptionDisabled: {
        backgroundColor: '#F5F5F5',
        color: '#9BA1AA',
    },
    bannedText: {
        color: '#E32F45',
    },
    optionContainer: {
        backgroundColor: 'transparent !important',
        '&:active': {
            backgroundColor: `${theme.palette.primary.light} !important`,
        },
        '&:hover': {
            backgroundColor: `${theme.palette.primary.light} !important`,
        },
    },
    helperText: {
        color: '#87888B',
        marginLeft: 4,
    },
    chipsContainer: {
        marginTop: 12,
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 12,
        height: 128,
        border: '1px solid #D9D9D9',
        borderRadius: 8,
        padding: 10,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    chipRemoveIcon: {
        backgroundColor: 'transparent',
        color: 'black',
        border: 'none',
        padding: '0px 0px',
        marginLeft: 8,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    chipContainer: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #676060B2',
        borderRadius: 6,
        gap: 5,
        backgroundColor: '#D9D9D933',
        height: 26,
    },
    chip: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 6,
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    iconButton: {
        width: 25,
        height: 25,
        borderRadius: 28,
        minHeight: 25,
        border: 'none',
        '&:focus': {
            outline: 'none',
        },
    },
    selectIconContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 12,
    },
    label: {
        fontFamily: theme.typography.regular,
        color: theme.palette.text.secondary,
        fontSize: 14,
    },
    iconsContainer: {
        width: '100%',
        maxWidth: '550px',
        display: 'flex',
        flexDirection: 'row',
        gap: 12,
        borderRadius: 4,
        overflowX: 'scroll',
    },
    iconButtonSelected: {
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'theme.palette.primary.main',
        borderRadius: '100%',
        width: 50,
        maxHeight: 50,
        '&:hover': {
            borderStyle: 'solid',
            borderWidth: '2px',
            borderColor: 'theme.palette.primary.main',
        },
    },
    restaurantIconButton: {
        display: 'flex',
        borderRadius: '100%',
        width: 50,
        maxHeight: 50,
        color: '#616B79',
        boxShadow: `0px 2px 2px #00000033`,
    },
    titleOrderTypeSection: {
        display: 'block',
        marginBottom: 16,
        fontFamily: theme.typography.regular,
        fontWeight: 400,
        fontSize: 14,
        color: theme.palette.text.secondary,
    },
    titleOrderTypeOption: {
        fontFamily: theme.typography.regular,
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.secondary,
        border: `1px solid ${theme.palette.surface.secondary}`,
        backgroundColor: theme.palette.surface.tertiary,
        padding: '6px 12px',
        borderRadius: 4,
    },
    uniqueChannelContainer: {
        marginBottom: 16,
    },
}));

type Props = {
    open: boolean;
    onClose?: () => void;
    onSuccess?: () => void;
};

type FormType = {
    name: string;
    mexicanPaymentMethodCode: MexicanPaymentMethodCode;
    paymentMethod: PaymentMethod;
    paymentReferenceEnabled?: boolean;
    channels?: Array<RestaurantPaymentMethodApp>;
    orderTypes?: Array<OrderType>;
};
