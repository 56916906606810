/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState } from 'react';
import { DownloadPideDirectoAdminAppApkDialog } from 'src/components/app/DownloadPideDirectoAdminAppApkDialog';
import { Environments } from 'src/constants/Environment';
import { envENVIRONMENT } from 'src/env/envENVIRONMENT';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function EnvironmentLabel(): React.ReactElement | null {
    const classes = useStyles();

    const [downloadPideDirectoAdminAppApkDialogOpen, setDownloadPideDirectoAdminAppApkDialogOpen] = useState(false);
    const developerMode = useSelector((state) => state.app.developerMode);

    if (isProductionEnvironment() && !developerMode) {
        return null;
    }

    return (
        <div className={classes.container}>
            <DownloadPideDirectoAdminAppApkDialog open={downloadPideDirectoAdminAppApkDialogOpen} onClose={() => setDownloadPideDirectoAdminAppApkDialogOpen(false)} />
            <div className={classNames(classes.span, getClassForEnvironment(classes))} onClick={() => setDownloadPideDirectoAdminAppApkDialogOpen(true)}>
                {envENVIRONMENT()}
            </div>
        </div>
    );
}

function getClassForEnvironment(classes: any) {
    if (envENVIRONMENT() === Environments.PROD) return classes.red;
    if (envENVIRONMENT() === Environments.TEST) return classes.red;
    if (envENVIRONMENT() === Environments.DEV) return classes.yellow;
    if (envENVIRONMENT() === Environments.LOCALHOST) return classes.green;
    return classes.yellow;
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        paddingLeft: 2,
        paddingRight: 2,
        borderTopRightRadius: 4,
    },
    span: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        paddingLeft: 2,
        paddingRight: 2,
        borderTopRightRadius: 4,
        cursor: 'pointer',
    },
    red: {
        backgroundColor: '#f44336',
        color: 'white',
    },
    yellow: {
        backgroundColor: '#d9c300',
        color: 'white',
    },
    green: {
        backgroundColor: '#4caf50',
        color: 'white',
    },
}));
