/**
 * @prettier
 */
import { PrinterBrand, PrinterBrands } from 'src/constants/PrinterBrand';
import { PrinterPaperSize, PrinterPaperSizes } from 'src/constants/PrinterPaperSize';

export function getPrinterCharactersPerRow({ printerBrand, printerPaperSize }: Params): number {
    if (printerPaperSize === PrinterPaperSizes['58_MM']) return 32;

    switch (printerBrand) {
        case PrinterBrands.IMIN:
        case PrinterBrands.GHIA:
        case PrinterBrands.XPRINTER:
            return 48;
        case PrinterBrands.STAR:
            return 42;
        case PrinterBrands.EPSON:
            return 42;
        default:
            return 42;
    }
}

type Params = {
    printerBrand?: PrinterBrand;
    printerPaperSize: PrinterPaperSize;
};
