/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import type { CartItemVm } from 'src/types/CartItemVm';
import { calculateOrderItemSubtotalUnitPrice } from 'src/utils/pos/calculateOrderItemSubtotalUnitPrice';

export function calculateOrderItemSubtotalPrice(orderItem: CartItemVm): BigNumber {
    return calculateOrderItemSubtotalUnitPrice(orderItem).multipliedBy(orderItem.quantity);
}
