/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import { PosBusinessDayVm } from 'src/types/PosBusinessDayVm';

export async function findPosBusinessDayApi(request: FindPosBusinessDayApiRequest): ApiSauceResponse<PosBusinessDayVm | undefined> {
    return letseatmanagerApiMethod('pos/findPosBusinessDayApi', request);
}

export type FindPosBusinessDayApiRequest = {
    restaurantId: RestaurantId;
};
