/**
 * @prettier
 */
export const RestaurantTypes = {
    RESTAURANT: 'RESTAURANT',
    PICKUP_STATION: 'PICKUP_STATION',
    KITCHEN: 'KITCHEN',
} as const;

export type RestaurantType = typeof RestaurantTypes[keyof typeof RestaurantTypes];
