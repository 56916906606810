/**
 * @prettier
 */
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ReceiptIcon from '@material-ui/icons/Receipt';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

type Props = {
    tooltip?: string;
    disabled?: boolean;
    onClick: any;
};

export function InvoiceToolbarButton({ tooltip, onClick, disabled }: Props): React.ReactElement {
    return (
        <Tooltip title={tooltip || translate('Refresh')}>
            <IconButton onClick={onClick} disabled={disabled}>
                <ReceiptIcon />
            </IconButton>
        </Tooltip>
    );
}
