/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { Dialog } from 'src/components/Dialog';
import { DeliveryOrdersMapInfo } from 'src/components/dialog/order/deliveryOrdersMapDialog/DeliveryOrdersMapInfo';
import { DeliveryOrdersMap } from 'src/components/googlemaps/DeliveryOrdersMap';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function DeliveryOrdersMapDialog({ open, onClose }: Props): React.ReactElement {
    const classes = useStyles();

    const [shouldShowMap, setShouldShowMap] = useState(false);

    const currentOrders = useSelector((state) => state.app2.currentOrders);

    const deliveryOrders = currentOrders.filter((order) => isDeliveryOrder(order.orderType));

    useEffect(() => {
        if (open) setShouldShowMap(true);
    }, [open]);

    useEffect(() => {
        if (!deliveryOrders.length && open) {
            onClose();
        }
    }, [deliveryOrders, open]);

    return useMemo(
        () => (
            <Dialog open={open} onClose={onClose} keepMounted classes={{ dialog: classes.dialog }} position={'right'}>
                <DeliveryOrdersMapInfo onClose={onClose} />
                {shouldShowMap && <DeliveryOrdersMap stopUpdatingPositions={!open} />}
            </Dialog>
        ),
        [open, shouldShowMap, onClose]
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        height: '100vh',
        maxHeight: '100vh',
        width: '80vw',
        padding: 0,
        overflow: 'hidden',
    },
    errorDialog: {
        height: '100vh',
        maxHeight: '100vh',
        width: '80vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
};
