/**
 * @prettier
 */
import { usePosCustomerActions } from 'src/modules/pos/customer/posCustomerStore';

export function useClearCustomerInfo() {
    const clearCustomerInfo = usePosCustomerActions((actions) => actions.clearCustomerInfo);

    return clearCustomerInfo;
}
