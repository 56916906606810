/**
 * @prettier
 */
import * as React from 'react';

export function MoneyIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M9.25 3.5V14.5M6.5 11.9168L7.30575 12.5209C8.37917 13.3267 10.1199 13.3267 11.1943 12.5209C12.2686 11.7152 12.2686 10.4098 11.1943 9.60408C10.658 9.20075 9.954 9 9.25 9C8.58542 9 7.92083 8.79833 7.41392 8.39592C6.40008 7.59017 6.40008 6.28483 7.41392 5.47908C8.42775 4.67333 10.0723 4.67333 11.0861 5.47908L11.4665 5.78158M17.5 9C17.5 10.0834 17.2866 11.1562 16.872 12.1571C16.4574 13.1581 15.8497 14.0675 15.0836 14.8336C14.3175 15.5997 13.4081 16.2074 12.4071 16.622C11.4062 17.0366 10.3334 17.25 9.25 17.25C8.16659 17.25 7.0938 17.0366 6.09286 16.622C5.09193 16.2074 4.18245 15.5997 3.41637 14.8336C2.65029 14.0675 2.0426 13.1581 1.62799 12.1571C1.21339 11.1562 1 10.0834 1 9C1 6.81196 1.86919 4.71354 3.41637 3.16637C4.96354 1.61919 7.06196 0.75 9.25 0.75C11.438 0.75 13.5365 1.61919 15.0836 3.16637C16.6308 4.71354 17.5 6.81196 17.5 9Z'
                stroke={color ?? 'currentColor'}
                strokeWidth='1.3'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    color?: string;
};
