/**
 * @prettier
 */
import { RoutePaths } from 'src/constants/RoutePath';
import type { CustomerId } from 'src/types/Id';
import { RegExps } from 'src/utils/RegExps';

export function openCustomerPageInNewTab(customerId: CustomerId): void {
    window.open(RoutePaths.ADMIN_CUSTOMER.replace(`:customerId(${RegExps.uuid})`, customerId), '_blank');
}
