/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RappiStoreId, RestaurantId } from 'src/types/Id';

export async function requestRappiIntegrationApi(request: RequestRappiIntegrationApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurantIntegration/requestRappiIntegrationApi', request);
}

export type RequestRappiIntegrationApiRequest = {
    restaurantId: RestaurantId;
    rappiStoreId: RappiStoreId;
};
