/**
 * @prettier
 */
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormNumberStringField } from 'src/components/form/FormNumberStringField';
import { FormSupplyAutocomplete } from 'src/components/form/FormSupplyAutocomplete';
import { FormUnitOfMeasurementSelect } from 'src/components/form/FormUnitOfMeasurementSelect';
import type { UnitOfMeasurement } from 'src/constants/UnitOfMeasurement';
import { translate } from 'src/i18n/translate';
import type { SupplyId } from 'src/types/Id';
import { SupplyVm } from 'src/types/SupplyVm';
import { useSelector } from 'src/utils/react/useSelector';

export function BuyOrderSupply({ idx, supply, getSupply, onRemove, onChange, disabled }: Props): React.ReactElement {
    const classes = useStyles();
    const { watch, setValue } = useFormContext();

    const inventoryLinkedToRestaurantId = useSelector((state) => state.app.restaurant?.inventoryLinkedToRestaurantId);

    const quantity = watch(`supplies.${idx}.quantity`);
    const price = watch(`supplies.${idx}.price`);
    const supplyId = watch(`supplies.${idx}.supplyId`);

    useEffect(() => {
        const total = quantity * price;
        setValue(`supplies.${idx}.total`, total);
        onChange(total);
    }, [quantity, price]);

    const buyUnitsSupply = getSupply(supplyId)?.buyUnits.map((unit) => unit.unit);
    const defaultBuyUnit = buyUnitsSupply?.length === 1 ? setValue(`supplies.${idx}.unitOfMeasurement`, buyUnitsSupply[0]) : undefined;

    return (
        /* @ts-ignore */
        <Grid container spacing={2} justifyContent='flex-start' alignItems='center' className={classes.item}>
            <Grid item xs={1} className={classes.item}>
                <Typography variant='h6'>{idx + 1}</Typography>
            </Grid>
            <Grid item xs={11} sm={5} md={3}>
                <FormSupplyAutocomplete name={`supplies.${idx}.supplyId`} label={translate('Supply')} required defaultValue={supply.supplyId} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <FormUnitOfMeasurementSelect
                    name={`supplies.${idx}.unitOfMeasurement`}
                    label={translate('Unit of Measurement')}
                    filterByUnits={buyUnitsSupply}
                    required
                    defaultValue={(defaultBuyUnit as any) || supply.buyUnit || supply.unitOfMeasurement}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={1}>
                <FormNumberStringField classes={{ input: classes.input }} name={`supplies.${idx}.quantity`} label={translate('Quantity')} required defaultValue={supply.quantity} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <FormCurrencyNumberStringField classes={{ input: classes.input }} name={`supplies.${idx}.price`} label={translate('Unit Price')} required defaultValue={supply.price} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <FormCurrencyNumberStringField classes={{ input: classes.input }} disabled name={`supplies.${idx}.total`} label={translate('Total')} required />
            </Grid>
            {!inventoryLinkedToRestaurantId && (
                <Grid item xs={12} sm={6} md={1}>
                    <Button outlined disabled={disabled} onClick={() => onRemove(idx)} classes={{ button: classes.removeSupplyButton }}>
                        {translate('Remove')}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        height: '42px',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 25,
        width: '100%',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    removeSupplyButton: {
        marginTop: 20,
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        borderRadius: 8,
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.error.dark,
            borderColor: theme.palette.error.dark,
        },
        textTransform: 'none',
    },

    item: {
        marginTop: '10px',
    },
}));

export type Props = {
    idx: number;
    supply: {
        supplyId: SupplyId;
        unitOfMeasurement: UnitOfMeasurement;
        buyUnit: UnitOfMeasurement;
        quantity: number;
        price: string;
    };
    onChange: any;
    onRemove: any;
    getSupply: (supplyId: SupplyId) => SupplyVm | undefined;
    disabled?: boolean;
};
