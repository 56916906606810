/**
 * @prettier
 */
import { SnackbarOrigin } from '@material-ui/core';
import { createSlice } from 'redux-starter-kit';
import { AppContextVm, RestaurantContext } from 'src/api/letseatmanager/getAppContextApi';
import type { BrandContextVm } from 'src/api/letseatmanager/getBrandContextApi';
import type { BusinessContextVm } from 'src/api/letseatmanager/getBusinessContextApi';
import type { BuildType } from 'src/constants/BuildType';
import { SalesType, SalesTypes } from 'src/constants/SalesTypes';
import type { BrandVm } from 'src/types/BrandVm';
import type { DeviceGroupVm } from 'src/types/DeviceGroupVm';
import type { DeviceVm } from 'src/types/DeviceVm';
import type { BrandId, BusinessId, MenuId, QpayTerminalDeviceId, RestaurantId } from 'src/types/Id';
import type { PaymentTerminalVm } from 'src/types/PaymentTerminalVm';
import type { PrinterVm } from 'src/types/PrinterVm';
import type { RestaurantVm } from 'src/types/RestaurantVm';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';
import { isWebSoundPermissionAllowed } from 'src/utils/sound/isSoundEnabled';

export const appReducer = createSlice<AppState, Actions>({
    name: 'appReducer',
    initialState: {
        loading: false,
        showDeveloperModeAvailableLabel: false,
        developerMode: false,
        restaurantId: undefined,
        pideDirectoAdminAppVersion: undefined,
        appBuildType: undefined,
        qpayPosTerminalDeviceId: undefined,
        restaurant: {
            name: '',
            timeZone: 'Etc/UTC',
            currencyFormat: {},
        } as any,
        brand: undefined,
        brandContext: undefined,
        business: undefined,
        businessContext: undefined,
        progressBar: {
            visible: false,
            variant: 'indeterminate',
        },
        snackbar: {
            isOpen: false,
            title: '',
            message: '',
            actionText: null,
            vertical: 'bottom',
            horizontal: 'center',
            autoHideDuration: 4000,
            onActionClick: null,
            onClose: null,
            extendedAction: null,
            dontCloseOnClickAway: undefined,
        },
        soundMuted: isMobileApp() ? false : !isWebSoundPermissionAllowed(),
        restaurants: [],
        brands: [],
        restaurantIds: [],
        userRestaurantIds: [],
        brandOpened: false,
        isCopyingMenu: false,
        isRemovingMenu: false,
        businessOpened: false,
        errorMessage: null,
        deviceGroup: undefined,
        printers: [],
        devices: [],
        paymentTerminals: [],
        salesTypeFilter: SalesTypes.GROSS_SALES,
    },
    reducers: {
        reset: (state: AppState) => {
            state.restaurants = [];
            state.restaurantIds = [];
            state.brands = [];
            state.restaurantId = undefined;
            state.loading = false;
            state.brandOpened = false;
            state.isCopyingMenu = false;
            state.isRemovingMenu = false;
            state.brand = undefined;
            state.brandContext = undefined;
            state.business = undefined;
            state.businessContext = undefined;
            state.errorMessage = null;
            state.restaurant = {
                name: '',
                timeZone: 'Etc/UTC',
                currencyFormat: {},
            };
            state.deviceGroup = undefined;
            state.deviceGroups = [];
            state.printers = [];
            state.devices = [];
            state.paymentTerminals = [];
        },

        initApp: (state: AppState) => {
            state.loading = true;
        },

        setShowDeveloperModeAvailableLabel: (state: AppState, { payload: showDeveloperModeAvailableLabel }: { payload: boolean }) => {
            state.showDeveloperModeAvailableLabel = showDeveloperModeAvailableLabel;
        },

        setDeveloperMode: (state: AppState, { payload: developerMode }: { payload: boolean }) => {
            state.developerMode = developerMode;
        },

        openRestaurant: (
            state: AppState,
            {
                payload: { restaurant, restaurants, brands, business, deviceGroup, deviceGroups, printers, devices, paymentTerminals },
            }: {
                payload: {
                    restaurant: RestaurantVm;
                    restaurantId: RestaurantId;
                    restaurants: any;
                    brands?: Array<Brand>;
                    business?: Business;
                    deviceGroup: DeviceGroupVm;
                    deviceGroups: Array<DeviceGroupVm>;
                    printers: Array<PrinterVm>;
                    devices: Array<DeviceVm>;
                    paymentTerminals: Array<PaymentTerminalVm>;
                };
            }
        ) => {
            state.restaurantId = restaurant.restaurantId;
            state.restaurant = restaurant;
            state.errorMessage = null;
            state.loading = false;
            state.restaurants = restaurants;
            state.brandOpened = false;
            state.isCopyingMenu = restaurant.isCopyingMenu ?? false;
            state.isRemovingMenu = restaurant.isRemovingMenu ?? false;
            state.brand = undefined;
            state.brandContext = undefined;
            state.businessOpened = false;
            state.business = undefined;
            state.businessContext = undefined;
            state.brands = brands ?? [];
            state.business = business;
            state.deviceGroup = deviceGroup;
            state.printers = printers ?? [];
            state.devices = devices ?? [];
            state.deviceGroups = deviceGroups ?? [];
            state.paymentTerminals = paymentTerminals ?? [];
        },

        openBrand: (state: AppState, { payload: { brand, brandContext } }: { payload: { brand?: BrandVm; brandContext: BrandContextVm } }) => {
            state.loading = false;
            state.brandOpened = true;
            state.businessOpened = false;
            state.brand = brand;
            state.brandContext = brandContext;
            state.business = undefined;
            state.businessContext = undefined;
        },

        openBusiness: (state: AppState, { payload: businessContext }: { payload: BusinessContextVm }) => {
            state.loading = false;
            state.brandOpened = false;
            state.brand = undefined;
            state.brandContext = undefined;
            state.businessOpened = true;
            state.businessContext = businessContext;
        },

        openRestaurantFailure: (state: AppState, { payload: { errorMessage } }: { payload: { errorMessage: string } }) => {
            state.errorMessage = errorMessage;
            state.loading = false;
        },

        reloadRestaurant: (state: AppState) => {
            state.loading = true;
        },

        reloadRestaurantSuccess: (state: AppState, { payload: { appContext } }: { payload: { appContext: AppContextVm } }) => {
            state.restaurant = appContext.restaurant;
            state.restaurantId = appContext.restaurant.restaurantId;
            state.restaurants = appContext.restaurants;
            state.errorMessage = null;
            state.loading = false;
        },

        reloadRestaurantFailure: (state: AppState, { payload: { errorMessage } }: { payload: { errorMessage: string } }) => {
            state.errorMessage = errorMessage;
            state.loading = false;
        },

        closeRestaurant: (state: AppState) => {
            state.loading = true;
        },

        showProgressBar: (state: AppState, { payload: { visible, variant } }: { payload: { visible: boolean; variant: string } }) => {
            state.progressBar.variant = variant;
            state.progressBar.visible = visible;
        },

        showSnackbar: (
            state: AppState,
            {
                payload: config,
            }: {
                payload: {
                    message: string;
                    actionText: string | null | undefined;
                    vertical: SnackbarOrigin['vertical'];
                    horizontal: SnackbarOrigin['horizontal'];
                    autoHideDuration: number | null | undefined;
                    onActionClick: any | null | undefined;
                    onClose: any | null | undefined;
                    title?: string;
                    dontCloseOnClickAway?: boolean;
                };
            }
        ) => {
            state.snackbar = {
                ...state.snackbar,
                ...config,
                vertical: config.vertical ? config.vertical : 'bottom',
                horizontal: config.horizontal ? config.horizontal : 'center',
                isOpen: true,
            };
        },

        hideSnackbar: (state: AppState) => {
            state.snackbar.isOpen = false;
            state.snackbar.message = '';
            state.snackbar.actionText = null;
            state.snackbar.vertical = 'bottom';
            state.snackbar.horizontal = 'center';
            state.snackbar.dontCloseOnClickAway = undefined;
        },

        setSoundMuted: (state: AppState, { payload: soundMuted }: { payload: boolean }) => {
            state.soundMuted = soundMuted;
        },

        toggleSound: (state: AppState) => {
            state.soundMuted = !state.soundMuted;
        },

        setRestaurants: (state: AppState, { payload: restaurants }: { payload: Array<RestaurantContext> }) => {
            state.restaurants = restaurants;
        },
        setPideDirectoAdminAppVersion: (state: AppState, { payload: appVersion }: { payload: string }) => {
            state.pideDirectoAdminAppVersion = appVersion;
        },
        setAppBuildType: (state: AppState, { payload: appBuildType }: { payload: BuildType }) => {
            state.appBuildType = appBuildType;
        },
        askForNotificationPermission: (state: AppState) => {
            state.loading = true;
        },

        setRestaurantMenuIds: (state: AppState, { payload: menuIds }: { payload: Array<MenuId> }) => {
            state.restaurant.menuIds = menuIds;
        },

        setUserRestaurantIds: (state: AppState, { payload: restaurantIds }: { payload: Array<RestaurantId> }) => {
            state.userRestaurantIds = restaurantIds;
        },

        setRestaurantIds: (state: AppState, { payload: restaurantIds }: { payload: Array<RestaurantId> }) => {
            state.restaurantIds = restaurantIds;
        },
        setDeviceGroup: (state: AppState, { payload: deviceGroup }: { payload: DeviceGroupVm }) => {
            state.deviceGroup = deviceGroup;
        },
        setDeviceGroups: (state: AppState, { payload: deviceGroups }: { payload: Array<DeviceGroupVm> }) => {
            state.deviceGroups = deviceGroups;
        },
        setPrinters: (state: AppState, { payload: printers }: { payload: Array<PrinterVm> }) => {
            state.printers = printers;
        },
        setDevices: (state: AppState, { payload: devices }: { payload: Array<DeviceVm> }) => {
            state.devices = devices;
        },
        setQpayPosTerminalDeviceId: (state: AppState, { payload: qpayPosTerminalDeviceId }: { payload: QpayTerminalDeviceId }) => {
            state.qpayPosTerminalDeviceId = qpayPosTerminalDeviceId;
        },
        setPaymentTerminals: (state: AppState, { payload: paymentTerminals }: { payload: Array<PaymentTerminalVm> }) => {
            state.paymentTerminals = paymentTerminals;
        },
        setSalesTypeFilter: (state: AppState, { payload: salesTypeFilter }: { payload: SalesType }) => {
            state.salesTypeFilter = salesTypeFilter;
        },
    },
});

export type AppState = {
    loading: boolean;
    showDeveloperModeAvailableLabel: boolean;
    developerMode: boolean;
    restaurantId: RestaurantId | undefined;
    brandIdOpened?: BrandId;
    restaurant: any;
    pideDirectoAdminAppVersion?: string;
    appBuildType?: BuildType;
    qpayPosTerminalDeviceId?: QpayTerminalDeviceId;
    brand?: BrandVm;
    brandContext?: BrandContextVm;
    business?: Business;
    businessContext?: BusinessContextVm;
    progressBar: {
        visible: boolean;
        variant: string | null | undefined;
    };
    snackbar: {
        isOpen: boolean;
        title: string;
        message: string;
        actionText: string | null | undefined;
        vertical: SnackbarOrigin['vertical'];
        horizontal: SnackbarOrigin['horizontal'];
        autoHideDuration: number | null | undefined;
        onActionClick: any | null | undefined;
        onClose: any | null | undefined;
        extendedAction: any | null | undefined;
        dontCloseOnClickAway?: boolean;
    };
    soundMuted: boolean;
    brandOpened: boolean;
    isCopyingMenu: boolean;
    isRemovingMenu: boolean;
    businessOpened: boolean;
    restaurants: Array<RestaurantContext>;
    brands: Array<Brand>;
    restaurantIds: Array<RestaurantId>;
    userRestaurantIds: Array<RestaurantId>;
    errorMessage: string | null | undefined;
    deviceGroup?: DeviceGroupVm;
    deviceGroups?: Array<DeviceGroupVm>;
    printers: Array<PrinterVm>;
    devices: Array<DeviceVm>;
    paymentTerminals?: Array<PaymentTerminalVm>;
    salesTypeFilter: SalesType;
};

type Brand = {
    brandId: BrandId;
    name: string;
    restaurantIds: Array<RestaurantId>;
};

type Business = {
    businessId: BusinessId;
    name: string;
    restaurantIds: Array<RestaurantId>;
};

export type Actions = {
    reset: any;
    initApp: any;
    setDeveloperMode: any;
    setShowDeveloperModeAvailableLabel: any;
    openRestaurant: any;
    openBrand: any;
    openBusiness: any;
    openRestaurantFailure: any;
    reloadRestaurant: any;
    reloadRestaurantSuccess: any;
    reloadRestaurantFailure: any;
    closeRestaurant: any;
    showProgressBar: any;
    showSnackbar: any;
    hideSnackbar: any;
    setSoundMuted: any;
    toggleSound: any;
    setRestaurants: any;
    setPideDirectoAdminAppVersion: any;
    setAppBuildType: any;
    setQpayPosTerminalDeviceId: any;
    askForNotificationPermission: any;
    setRestaurantMenuIds: any;
    setUserRestaurantIds: any;
    setRestaurantIds: any;
    setDeviceGroup: any;
    setDeviceGroups: any;
    setPrinters: any;
    setDevices: any;
    setPaymentTerminals: any;
    setSalesTypeFilter: any;
};
