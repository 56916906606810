/**
 * @prettier
 */
import { savePosOrderApi } from 'src/api/letseatmanager/pos/savePosOrderApi';
import { updatePendingOrderApi } from 'src/api/letseatmanager/pos/updatePendingOrderApi';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { getPosOrdersOfflineInLocalStorage, PosOrderOffline } from 'src/localStorage/getPosOrdersOfflineInLocalStorage';
import { setPosOrdersOfflineInLocalStorage } from 'src/localStorage/setPosOrdersOfflineInLocalStorage';

export async function tryToSavePosOrdersCreatedOffline() {
    const posOrdersOffline = getPosOrdersOfflineInLocalStorage();
    for (const posOrder of posOrdersOffline) {
        if (posOrder.orderStatus === OrderStatuses.PENDING && posOrder.orderId) {
            await updateOrder(posOrder);
            return;
        }
        await saveOrder(posOrder);
    }
}

async function saveOrder(request: PosOrderOffline) {
    const response = await savePosOrderApi(request as any);
    if (!response.ok) return;
    savedPosOrderOffline(request);
}

async function updateOrder(request: PosOrderOffline) {
    const response = await updatePendingOrderApi(request as any);
    if (!response.ok) return;
    savedPosOrderOffline(request);
}

function savedPosOrderOffline(request: PosOrderOffline) {
    const posOrdersOffline = getPosOrdersOfflineInLocalStorage();
    setPosOrdersOfflineInLocalStorage(posOrdersOffline.filter((posOrderOffline) => posOrderOffline.temporalOrderId !== request.temporalOrderId));
}
