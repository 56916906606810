/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { InventoryConsumptionReportVm } from 'src/types/InventoryConsumptionReportVm';

export async function getInventoryConsumptionsReportApi(request: GetInventoryConsumptionReportApiRequest): ApiSauceResponse<InventoryConsumptionReportVm> {
    return pidedirectoReportsMethod('/getInventoryConsumptionReportApi', request);
}

type GetInventoryConsumptionReportApiRequest = {
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
};
