/**
 * @prettier
 */
import { CartItemVm } from 'src/types/CartItemVm';
import { OrderItemVm } from 'src/types/OrderVm';
import { isItemSoldByWeight } from 'src/utils/orderItem/isItemSoldByWeight';

export function splitGroupedItems(orderItems: Array<OrderItemVm | CartItemVm>): Array<OrderItemVm | CartItemVm> {
    return orderItems.flatMap((orderItem) => {
        if (isItemSoldByWeight(orderItem) || orderItem.quantity === 1) {
            return orderItem;
        }

        return Array.from({ length: orderItem.quantity }, () => ({ ...orderItem, quantity: 1 }));
    });
}
