/**
 * @prettier
 */
import { useState } from 'react';
import * as React from 'react';
import { removeMenuItemFromMenuCategoryApi } from 'src/api/letseatmanager/menu/removeMenuItemFromMenuCategoryApi';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import { translate } from 'src/i18n/translate';
import type { MenuCategoryId } from 'src/types/Id';
import type { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function RemoveProductFromMenuCategoryDialog({ open, menuItem, menuCategoryId, onSuccess, onClose }: Props): React.ReactElement {
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const handleSubmit = async () => {
        setLoading(true);
        if (!menuItem || !menuCategoryId) return;
        const response = await removeMenuItemFromMenuCategoryApi({
            menuItemId: menuItem?.menuItemId,
            menuCategoryId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
        handleClose();
    };

    return (
        <RemoveDialog
            open={open}
            title={translate('Delete Product')}
            subtitle={translate('You are about to delete the @menuItemName product, are you sure you want to continue?', { menuItemName: menuItem?.name })}
            onSubmit={handleSubmit}
            onClose={handleClose}
            disabled={loading}
        />
    );
}

type Props = {
    open: boolean;
    menuItem?: ManagerMenuItemVm;
    menuCategoryId?: MenuCategoryId;
    onSuccess?: any;
    onClose: any;
};
