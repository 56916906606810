/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { useState } from 'react';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { changeDriverBannerApi } from 'src/api/letseatadmin/driver/changeDriverBannerApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCityAutocomplete } from 'src/components/form/FormCityAutocomplete';
import { FormImageUpload } from 'src/components/form/FormImageUpload';
import { FormTextField } from 'src/components/form/FormTextField';
import { DriverBannerImageTypes } from 'src/constants/DriverBannerImageType';
import { translate } from 'src/i18n/translate';
import defaultBannerDriver from 'src/images/defaultBannerDriver.svg';
import type { DriverBannerVm } from 'src/types/DriverBannerVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function ChangeDriverBannerDialog({ open, onClose, bannerDriver, onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();
    const form = useForm();
    const { control } = form;

    const [customDesign, setCustomDesign] = useState(bannerDriver?.imageType === DriverBannerImageTypes.CUSTOM);
    const [loading, setLoading] = useState(false);

    const text = useWatch<string>({ name: 'text', control });
    const imageUrl = useWatch<string>({ name: 'imageUrl', control });

    const handleUpdateBannerDriver = async (form: any) => {
        setLoading(true);
        if (!bannerDriver) return;

        const response = await changeDriverBannerApi({
            driverBannerId: bannerDriver?.driverBannerId,
            text: form?.text,
            imageUrl: form?.imageUrl,
            linkUrl: form?.linkUrl,
            imageType: customDesign ? DriverBannerImageTypes.CUSTOM : DriverBannerImageTypes.DEFAULT,
            enabled: bannerDriver?.enabled ?? true,
            city: form?.city,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setLoading(false);
            return;
        }
        setLoading(false);
        onSuccess?.();
        onClose?.();
    };

    const handleCustomDesignBannerDriver = (e: any) => {
        setCustomDesign(e.target.checked);
    };

    if (!open) return null;

    return (
        <Dialog open={open} onClose={onClose} title={translate('Edit Banner')}>
            <Form form={form} onSubmit={handleUpdateBannerDriver}>
                <Grid container className={classes.container}>
                    <Grid item xs={12}>
                        <div className={classes.previewContainer}>
                            <div className={classes.previewBanner}>
                                {bannerDriver?.imageUrl && <img className={classes.bannerImg} src={`${customDesign ? bannerDriver?.imageUrl : defaultBannerDriver}`} alt={`Banner to show drivers`} />}{' '}
                                {!bannerDriver?.imageUrl && <img className={classes.bannerImg} src={`${!customDesign && !imageUrl ? defaultBannerDriver : imageUrl}`} alt={`Banner to show drivers`} />}{' '}
                                <div className={classes.textContainer}>
                                    <span className={classes.textBanner}>{text ?? bannerDriver?.text}</span>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.inputsContainer}>
                            <FormTextField name={'text'} label={translate('Text')} defaultValue={bannerDriver?.text} maxLength={50} />
                            <FormTextField name={'linkUrl'} label={translate('Link')} defaultValue={bannerDriver?.linkUrl} />
                            <FormCityAutocomplete name={'city'} label={translate('City')} defaultValue={bannerDriver?.city} />
                            <div>
                                <Switch checked={customDesign} onChange={(e) => handleCustomDesignBannerDriver(e)} inputProps={{ 'aria-label': 'controlled' }} />
                                <span>{translate('Personalized design')}</span>
                            </div>
                            {customDesign && (
                                <div className={classes.imageUrlContainer}>
                                    <FormImageUpload
                                        name={'imageUrl'}
                                        label={translate('Url Image')}
                                        defaultValue={bannerDriver?.imageUrl}
                                        classes={{ imagePreview: classes.imagePreview, label: classes.label }}
                                    />
                                </div>
                            )}
                        </div>
                    </Grid>
                </Grid>
                <DialogActions className={classes.buttonContainer}>
                    <Button secondary onClick={onClose} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button disabled={loading} type={'submit'}>
                        {translate('Save changes')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    previewBanner: {
        width: '350px',
        height: '150px',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    textContainer: {
        width: '80%',
        height: '150px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    bannerImg: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderRadius: '12px',
    },
    textBanner: {
        zIndex: 1,
        color: '#ffffff',
        fontSize: '18px',
        fontFamily: theme.typography.bold,
    },
    dialog: {
        width: '80%',
        height: '70%',
    },
    previewContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 12,
    },
    inputsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        gap: 12,
    },
    imagePreview: {
        display: 'none',
    },
    imageUrlContainer: {
        paddingLeft: 10,
    },
    label: {
        fontFamily: theme.typography.regular,
        color: theme.palette.secondary.contrastText,
        fontSize: 14,
        marginBottom: 8,
        display: 'flex',
    },
    buttonContainer: {
        justifyContent: 'flex-end',
        gap: '12px',
    },
}));

type Props = {
    open?: any;
    onClose?: any;
    onSuccess?: any;
    bannerDriver?: DriverBannerVm;
};
