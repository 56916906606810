/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BusinessVm } from 'src/types/BusinessVm';
import type { BrandId, BusinessId } from 'src/types/Id';

export async function changeBusinessApi(request: ChangeBusinessApiRequest): ApiSauceResponse<BusinessVm> {
    return letseatadminApiMethod('business/changeBusinessApi', request);
}

type ChangeBusinessApiRequest = {
    businessId: BusinessId;
    name: string;
    brandIds: Array<BrandId>;
    street?: string;
    phoneNumber?: string;
    email?: string;
};
