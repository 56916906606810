/**
 * @prettier
 */
import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'src/utils/react/useSelector';
import { setRestaurantIdsInMobileApp } from 'src/utils/reactNative/setRestaurantIdsInMobileApp';
import { signedInInMobileApp } from 'src/utils/reactNative/signedInInMobileApp';
import { signedOutInMobileApp } from 'src/utils/reactNative/signedOutInMobileApp';

export function PideDirectoAdminAppConfig(): React.ReactElement | null {
    const restaurant = useSelector((state) => state.app.restaurant);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const signedIn = useSelector((state) => state.authentication.signedIn);

    useEffect(() => {
        setRestaurantIdsInMobileApp(restaurantIds);
    }, [restaurantIds]);

    useEffect(() => {
        if (signedIn) signedInInMobileApp(restaurant);
        else signedOutInMobileApp();
    }, [signedIn, restaurant]);

    return null;
}
