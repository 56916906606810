/**
 * @prettier
 */
import { PrinterContents } from 'src/constants/PrinterContent';
import { PrintTypes } from 'src/constants/PrintType';
import { getDeprecatedPrintersInLocalStorage } from 'src/localStorage/getDeprecatedPrintersInLocalStorage';
import { createCashRegisterTransactionPrint } from 'src/services/printer/prints/createCashRegisterTransactionPrint';
import { printerDeprecatedToPrinter } from 'src/services/printer/utils/printerDeprecatedToPrinter';
import { usePrintContent } from 'src/services/printer/utils/usePrintContent';
import { CashRegisterTransactionVm } from 'src/types/CashRegisterTransactionVm';
import { DeviceGroupId } from 'src/types/Id';
import { PrinterVm } from 'src/types/PrinterVm';
import { useSelector } from 'src/utils/react/useSelector';

export function usePrintCashRegisterTransaction(): (cashRegisterTransaction: CashRegisterTransactionVm, options?: PrintCashRegisterTransactionOptions) => Promise<void> {
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);
    const restaurant = useSelector((state) => state.app.restaurant);
    const deviceGroup = useSelector((state) => state.app.deviceGroup);
    const printers = useSelector((state) => state.app.printers);
    let allPrinters: Array<PrinterVm>;
    const printersDeprecated = getDeprecatedPrintersInLocalStorage().map(printerDeprecatedToPrinter);

    const { printContent, printContents } = usePrintContent();

    if (newDeviceManagementEnabled) {
        allPrinters = printers?.filter((printer: PrinterVm) =>
            printer.deviceGroups?.some((deviceGroupToSearch: { deviceGroupId: DeviceGroupId; deviceGroupName: string }) => deviceGroupToSearch.deviceGroupId === deviceGroup?.deviceGroupId)
        );
    } else {
        allPrinters = printersDeprecated;
    }

    const printCashRegisterTransaction = async (cashRegisterTransaction: CashRegisterTransactionVm, options?: PrintCashRegisterTransactionOptions) => {
        if (allPrinters?.length === 0) {
            alert('No active printers to select');
            return;
        } else if (allPrinters.length === 1 && options?.forceToPrint) {
            await printContents([
                {
                    printer: allPrinters[0],
                    print: {
                        printType: PrintTypes.CASH_REGISTER_TRANSACTION_TICKET,
                        printerInstructions: createCashRegisterTransactionPrint({ cashRegisterTransaction, restaurant, openCashBox: options?.openCashBox }),
                    },
                },
            ]);
            return;
        }

        const ticketPrinters = allPrinters?.filter((printer: PrinterVm) => {
            return printer.printerContent !== PrinterContents.COMMAND;
        });

        if (ticketPrinters.length === 1) {
            await printContents([
                {
                    printer: allPrinters[0],
                    print: {
                        printType: PrintTypes.CASH_REGISTER_TRANSACTION_TICKET,
                        printerInstructions: createCashRegisterTransactionPrint({ cashRegisterTransaction, restaurant, openCashBox: options?.openCashBox }),
                    },
                },
            ]);
            return;
        }

        await printContent({
            printType: PrintTypes.CASH_REGISTER_TRANSACTION_TICKET,
            printerInstructions: createCashRegisterTransactionPrint({ cashRegisterTransaction, restaurant, openCashBox: options?.openCashBox }),
        });
    };
    return printCashRegisterTransaction;
}

type PrintCashRegisterTransactionOptions = {
    forceToPrint: boolean;
    openCashBox: boolean;
};
