/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import * as React from 'react';
import { getCashRegisterReportApi } from 'src/api/letseatmanager/cashRegister/getCashRegisterReportApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { CashRegisterCancelledOrdersReport } from 'src/scenes/letseatmanager/posReports/cashRegisterReport/CashRegisterCancelledOrdersReport';
import { CashRegisterExpectedAmountsReport } from 'src/scenes/letseatmanager/posReports/cashRegisterReport/CashRegisterExpectedAmountsReport';
import { CashRegisterHistoricalSalesReport } from 'src/scenes/letseatmanager/posReports/cashRegisterReport/CashRegisterHistoricalSalesReport';
import { CashRegisterMaximumSalesReport } from 'src/scenes/letseatmanager/posReports/cashRegisterReport/CashRegisterMaximumSalesReport';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { sum } from 'src/utils/reduce/sum';

export function CashRegisterReport(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, cashRegisterReport] = useLoadApi(
        getCashRegisterReportApi,
        {
            restaurantIds,
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).toISOString(),
            salesType,
        },
        { dependencies: [restaurantIds, period, salesType] }
    );

    const getTotalSales = () => {
        const cashRegisterReportTotalSales = cashRegisterReport?.historicalCashRegisterSales.map((historicalCashRegisterSale) => historicalCashRegisterSale.total);
        if (!cashRegisterReportTotalSales) return '0';
        return cashRegisterReportTotalSales.reduce(sum, BigNumber(0)).toString();
    };

    const getTotalOrders = () => {
        const cashRegisterReportTotalOrders = cashRegisterReport?.historicalCashRegisterSales.map((historicalCashRegisterSale) => historicalCashRegisterSale.orderVolume);
        if (!cashRegisterReportTotalOrders) return '0';
        return cashRegisterReportTotalOrders.reduce(sum, BigNumber(0)).toString();
    };

    const getTotalDiscounts = () => {
        const cashRegisterReportTotalDiscounts = cashRegisterReport?.discountsByCashRegister.map((discountsByCashRegister) => discountsByCashRegister.posDiscount);
        if (!cashRegisterReportTotalDiscounts) return '0';
        return cashRegisterReportTotalDiscounts.reduce(sum, BigNumber(0)).toString();
    };

    const isEmptyReport = () => {
        if (!cashRegisterReport) return true;
        return (
            cashRegisterReport.discountsByCashRegister.length === 0 &&
            cashRegisterReport.cancelledOrdersByCashRegister.length === 0 &&
            cashRegisterReport.cashRegisterReportByCashRegister.length === 0 &&
            cashRegisterReport.cashRegisterSalesByPaymentMethod.length === 0 &&
            cashRegisterReport.historicalCashRegisterSales.length === 0
        );
    };

    if (isEmptyReport()) {
        return (
            <div className={classes.container}>
                <UpdatingContentProgress loading={loading} top />
                <ReportSectionEmptyState />
            </div>
        );
    }

    return (
        <section className={classes.container}>
            <UpdatingContentProgress loading={loading} top />
            <article className={classes.kpiHeading}>
                <KpiCard
                    classes={{
                        container: classes.headingKpiContainer,
                        title: classes.headingKpiTitleKpi,
                        body: classes.headingKpiBody,
                        color: classes.headingKpiColor,
                        legend: classes.headingKpiLegendContainer,
                    }}
                    legend={formatAsCurrencyNumber(getTotalSales())}
                    title={translate('Total Sales')}
                />
                <KpiCard
                    classes={{
                        container: classes.headingKpiContainer,
                        title: classes.headingKpiTitleKpi,
                        body: classes.headingKpiBody,
                        color: classes.headingKpiColor,
                        legend: classes.headingKpiLegendContainer,
                    }}
                    legend={getTotalOrders()}
                    title={translate('Total Orders')}
                />
                <KpiCard
                    classes={{
                        container: classes.headingKpiContainer,
                        title: classes.headingKpiTitleKpi,
                        body: classes.headingKpiBody,
                        color: classes.headingKpiColor,
                        legend: classes.headingKpiLegendContainer,
                    }}
                    legend={formatAsCurrencyNumber(getTotalDiscounts())}
                    title={translate('Total discounts')}
                />
            </article>
            <div className={classes.reportsContainer}>
                <CashRegisterExpectedAmountsReport cashRegisterReport={cashRegisterReport} />
                <CashRegisterCancelledOrdersReport cashRegisterReport={cashRegisterReport} />
                <CashRegisterHistoricalSalesReport cashRegisterReport={cashRegisterReport} />
                <CashRegisterMaximumSalesReport cashRegisterReport={cashRegisterReport} />
            </div>
        </section>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        paddingBottom: 100,
    },
    kpiHeading: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        flexWrap: 'nowrap',
        [theme.breakpoints.between('xs', 'sm')]: {
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            flexWrap: 'nowrap',
        },
    },
    headingKpiContainer: {
        backgroundColor: 'transparent',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '8px',
        margin: 5,
        marginRight: '5%',
        marginBottom: '2%',
    },
    headingKpiTitleKpi: {
        fontFamily: theme.typography.regular,
        fontSize: 16,
        color: theme.palette.text.contrast,
        width: '100%',
        textAlign: 'center',
    },
    headingKpiBody: {
        fontSize: 28,
        fontFamily: theme.typography.semiBold,
    },
    headingKpiColor: {
        width: 12,
        height: 12,
        borderRadius: 2,
    },
    headingKpiLegendContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    reportsContainer: {
        width: '100%',
        display: 'grid',
        gap: 24,
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '1fr 1fr',
        },
    },
}));
