/**
 * @prettier
 */
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useState } from 'react';
import * as React from 'react';
import { FiltersLiveDrivers } from 'src/scenes/letseatadmin/liveDrivers/FiltersLiveDrivers';
import { ResumeSectionInLiveMap } from 'src/scenes/letseatadmin/liveDrivers/ResumeSectionInLiveMap';

export function FiltersAndResumeLiveMap(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(true);

    const handleOpen = () => {
        setOpen((value) => !value);
    };

    const onlyMediumScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const displayResumeSection = open || onlyMediumScreen;

    return (
        <React.Fragment>
            <Grid className={classes.filtersContainer}>
                <FiltersLiveDrivers handleOpen={handleOpen} open={open} />
            </Grid>
            {displayResumeSection && (
                // @ts-ignore
                <Grid item xs={12} md={3} className={classes.driversInfoContainerDesktop} order={{ xs: 2, sm: 1 }}>
                    <ResumeSectionInLiveMap />
                </Grid>
            )}
        </React.Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
    filtersContainer: {
        maxHeight: '10vh',
        width: '101%',
    },
    driversInfoContainerDesktop: {
        display: 'block',
    },
}));
