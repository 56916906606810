/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { FacturamaPaymentForm } from 'src/constants/FacturamaPaymentForm';
import { FacturamaPaymentMethod } from 'src/constants/FacturamaPaymentMethod';
import type { SubscriptionId } from 'src/types/Id';

export async function getFixedSubscriptionValuesApi(request: GetFixedSubscriptionValuesApiRequest): ApiSauceResponse<FixedSubscriptionValues> {
    return letseatadminApiMethod('subscription/getFixedSubscriptionValuesApi', request);
}

export type GetFixedSubscriptionValuesApiRequest = {
    subscriptionId: SubscriptionId;
};

export type FixedSubscriptionValues = {
    subscriptionId: SubscriptionId;
    fixedInvoiceValuesSubscription?: FixedInvoiceValues;
    fixedInvoiceValuesCommission?: FixedInvoiceValues;
    fixedInvoiceValuesApplication?: FixedInvoiceValues;
    fixedInvoiceValuesDeliveries?: FixedInvoiceValues;
};

type FixedInvoiceValues = {
    fixedPaymentAmount?: string;
    fixedInvoiceDescription?: string;
    fixedPaymentMethod?: FacturamaPaymentMethod;
    fixedPaymentForm?: FacturamaPaymentForm;
};
