/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function changeDidiIntegrationCommissionPercentageApi(request: ChangeDidiIntegrationCommissionPercentageApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurantIntegration/changeRestaurantDidiFoodSalesCommissionPercentageApi', request);
}

export type ChangeDidiIntegrationCommissionPercentageApiRequest = {
    restaurantId: RestaurantId;
    didiFoodSalesCommissionPercentage?: number;
};
