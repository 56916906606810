/**
 * @prettier
 */
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { formatAsNumberNew } from 'src/utils/number/formatAsNumberNew';

export function TableRowCalculation({ minus, hidden, calculation, amount, name }: Props): React.ReactElement | null {
    if (BigNumber(amount ?? 0).isZero() && hidden) return null;
    const amountBigNumber = BigNumber(amount);
    return (
        <TableRow>
            <TableCell>{name}</TableCell>
            <TableCell align='right'>{calculation}</TableCell>
            {minus ? (
                <TableCell align='right'>{formatAsNumberNew(BigNumber(amount).negated().toNumber(), { fractionDigits: 2 })}</TableCell>
            ) : (
                <TableCell align='right'>{formatAsNumberNew(amountBigNumber.toNumber(), { fractionDigits: 2 })}</TableCell>
            )}
        </TableRow>
    );
}

type Props = {
    minus?: boolean;
    hidden?: boolean;
    name: string;
    calculation?: string;
    amount: number;
};
