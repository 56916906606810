/**
 * @prettier
 */
import { usePosCustomerActions } from 'src/modules/pos/customer/posCustomerStore';

export function useSetCustomerMobileNumber() {
    const setCustomerMobileNumber = usePosCustomerActions((actions) => actions.setCustomerMobileNumber);

    return setCustomerMobileNumber;
}
