/**
 * @prettier
 */
import * as React from 'react';

export function DeleteRoundedIcon(): React.ReactElement {
    return (
        <svg width='16' height='16' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M4.9375 4.9375L7.5625 7.5625M7.5625 4.9375L4.9375 7.5625M11.5 6.25C11.5 6.93944 11.3642 7.62213 11.1004 8.25909C10.8365 8.89605 10.4498 9.4748 9.96231 9.96231C9.4748 10.4498 8.89605 10.8365 8.25909 11.1004C7.62213 11.3642 6.93944 11.5 6.25 11.5C5.56056 11.5 4.87787 11.3642 4.24091 11.1004C3.60395 10.8365 3.0252 10.4498 2.53769 9.96231C2.05018 9.4748 1.66347 8.89605 1.39963 8.25909C1.1358 7.62213 1 6.93944 1 6.25C1 4.85761 1.55312 3.52226 2.53769 2.53769C3.52226 1.55312 4.85761 1 6.25 1C7.64239 1 8.97774 1.55312 9.96231 2.53769C10.9469 3.52226 11.5 4.85761 11.5 6.25Z'
                stroke='#B1B7BC'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}
