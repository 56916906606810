/**
 * @prettier
 */
import { useSelector as _useSelector, shallowEqual } from 'react-redux';
import type { StateVm } from 'src/types/StateVm';

// TODO: remove NonNullable<T> return type and replace with T
export function useSelector<T>(selector: (state: StateVm) => T, equalityFn?: (a: T, b: T) => boolean): NonNullable<T> {
    return _useSelector(selector, equalityFn || shallowEqual) as any;
}
