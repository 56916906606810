/**
 * @prettier
 */
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { app2 } from 'src/app2';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';

export function StatisticsOldPage(): React.ReactElement {
    const [loading, setLoading] = useState(false);
    const [stats, setStats] = useState(statsInitialValue);

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Statistics Old'));
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.admin.statisticsOld.fetch();
        setLoading(false);
        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        setStats(response.data.stats);
    };

    return (
        <div style={{ position: 'relative' }}>
            <div>
                <br />
                <UpdatingContentProgress loading={loading} />
                <Card style={{ padding: 24 }}>
                    <Typography display='block' variant='caption' gutterBottom>
                        Customer Stats
                    </Typography>
                    <Grid container direction='row' spacing={3} alignItems='center'>
                        <Grid item>
                            <Typography display='block' variant='caption' gutterBottom>
                                Downloads: {stats.downloads || ''}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Registered: {stats.registeredCustomers || ''}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Paying: {stats.payingCustomers || ''}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography display='block' variant='caption' gutterBottom>
                                Credit Card: {stats.creditCardCustomers || ''}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Push Allowed: {stats.pushNotificationsAllowed || ''}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Push Denied: {stats.pushNotificationsDenied || ''}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography display='block' variant='caption' gutterBottom>
                                Active: {stats.activeCustomers || ''} ({stats.activeCustomersThisMonth || ''} this month)
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Inactive: {stats.inactiveCustomers || ''} ({stats.inactiveCustomersThisMonth || ''} this month)
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Active last six month: {stats.activeCustomersInLastSixMonths.join(' | ')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <br />
                    <Typography display='block' variant='caption' gutterBottom>
                        Order Stats
                    </Typography>
                    <Grid container direction='row' spacing={3} alignItems='center'>
                        <Grid item>
                            <Typography display='block' variant='caption' gutterBottom>
                                New/Accepted/Completed
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Total: {stats.orders || ''}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Today: {stats.ordersToday || ''}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                This week: {stats.ordersInThisWeek.join(' | ')}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Last week: {stats.ordersInLastWeek.join(' | ')}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Last six weeks: {stats.ordersInLastSixWeeks.join(' | ')}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Last six months: {stats.ordersInLastSixMonths.join(' | ')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography display='block' variant='caption' gutterBottom>
                                Rejected by Restaurant
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Total: {stats.ordersRejectedByRestaurant || ''}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Today: {stats.ordersRejectedByRestaurantToday || ''}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                This week: {stats.ordersRejectedByRestaurantInThisWeek.join(' | ')}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Last week: {stats.ordersRejectedByRestaurantInLastWeek.join(' | ')}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Last six weeks: {stats.ordersRejectedByRestaurantInLastSixWeeks.join(' | ')}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Last six months: {stats.ordersRejectedByRestaurantInLastSixMonths.join(' | ')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography display='block' variant='caption' gutterBottom>
                                Payment Failed
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Total: {stats.ordersWithPaymentFailed || ''}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Today: {stats.ordersWithPaymentFailedToday || ''}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                This week: {stats.ordersWithPaymentFailedInThisWeek.join(' | ')}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Last week: {stats.ordersWithPaymentFailedInLastWeek.join(' | ')}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Last six weeks: {stats.ordersWithPaymentFailedInLastSixWeeks.join(' | ')}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Last six months: {stats.ordersWithPaymentFailedInLastSixMonths.join(' | ')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography display='block' variant='caption' gutterBottom>
                                Cancelled
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Total: {stats.ordersCancelled || ''}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Today: {stats.ordersCancelledToday || ''}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                This week: {stats.ordersCancelledInThisWeek.join(' | ')}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Last week: {stats.ordersCancelledInLastWeek.join(' | ')}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Last six weeks: {stats.ordersCancelledInLastSixWeeks.join(' | ')}
                            </Typography>
                            <Typography display='block' variant='caption' gutterBottom>
                                Last six months: {stats.ordersCancelledInLastSixMonths.join(' | ')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
                <br />
            </div>
        </div>
    );
}

const statsInitialValue: Stats = {
    activeCustomersInLastSixMonths: [],
    ordersInLastWeek: [],
    ordersInThisWeek: [],
    ordersInLastSixWeeks: [],
    ordersInLastSixMonths: [],
    ordersWithPaymentFailedInLastWeek: [],
    ordersWithPaymentFailedInThisWeek: [],
    ordersWithPaymentFailedInLastSixWeeks: [],
    ordersWithPaymentFailedInLastSixMonths: [],
    ordersRejectedByRestaurantInLastWeek: [],
    ordersRejectedByRestaurantInThisWeek: [],
    ordersRejectedByRestaurantInLastSixWeeks: [],
    ordersRejectedByRestaurantInLastSixMonths: [],
    ordersCancelledInLastWeek: [],
    ordersCancelledInThisWeek: [],
    ordersCancelledInLastSixWeeks: [],
    ordersCancelledInLastSixMonths: [],
};

type Stats = {
    downloads?: number;
    registeredCustomers?: number;
    payingCustomers?: number;
    activeCustomers?: number;
    activeCustomersThisMonth?: number;
    inactiveCustomers?: number;
    inactiveCustomersThisMonth?: number;
    activeCustomersInLastSixMonths: Array<number>;
    creditCardCustomers?: number;
    pushNotificationsAllowed?: number;
    pushNotificationsDenied?: number;
    orders?: number;
    eatHere?: number;
    takeAway?: number;
    ordersToday?: number;
    ordersInLastWeek: Array<number>;
    ordersInThisWeek: Array<number>;
    ordersInLastSixWeeks: Array<number>;
    ordersInLastSixMonths: Array<number>;
    ordersWithPaymentFailed?: number;
    ordersWithPaymentFailedToday?: number;
    ordersWithPaymentFailedInLastWeek: Array<number>;
    ordersWithPaymentFailedInThisWeek: Array<number>;
    ordersWithPaymentFailedInLastSixWeeks: Array<number>;
    ordersWithPaymentFailedInLastSixMonths: Array<number>;
    ordersRejectedByRestaurant?: number;
    ordersRejectedByRestaurantToday?: number;
    ordersRejectedByRestaurantInLastWeek: Array<number>;
    ordersRejectedByRestaurantInThisWeek: Array<number>;
    ordersRejectedByRestaurantInLastSixWeeks: Array<number>;
    ordersRejectedByRestaurantInLastSixMonths: Array<number>;
    ordersCancelled?: number;
    ordersCancelledToday?: number;
    ordersCancelledInLastWeek: Array<number>;
    ordersCancelledInThisWeek: Array<number>;
    ordersCancelledInLastSixWeeks: Array<number>;
    ordersCancelledInLastSixMonths: Array<number>;
};
