/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import { translate } from 'src/i18n/translate';
import { useHasPaymentTerminalsAvailable } from 'src/services/device/useHasPaymentTerminalsAvailable';
import { useNotification } from 'src/services/notification/useNotification';
import { usePayWithPaymentTerminal } from 'src/services/paymentTerminal/usePayWithPaymentTerminal';
import type { CartItemVm } from 'src/types/CartItemVm';
import { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import { OrderItemVm, type OrderVm } from 'src/types/OrderVm';
import type { PosPayment } from 'src/types/PosPayment';
import type { PosTipVm } from 'src/types/PosTipVm';
import { calculateTipAmount } from 'src/utils/order/calculateTipAmount';
import { mapOrderPaymentToPosPayment } from 'src/utils/order/mapOrderPaymentToPosPayment';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { createPaymentDistribution, CreatePaymentDistributionRequest } from 'src/utils/pos/createPaymentDistribution';
import { useSelector } from 'src/utils/react/useSelector';
import { emptyStringToUndefined } from 'src/utils/string/emptyStringToUndefined';
import { newUuid } from 'src/utils/uuid/newUuid';

export function useAddTipToPosOrder(): Result {
    const notification = useNotification();
    const { payWithPaymentTerminal } = usePayWithPaymentTerminal();
    const hasPaymentTerminalsAvailable = useHasPaymentTerminalsAvailable();

    const useQpayTerminalEnabled = useSelector((state) => state.app.restaurant.useQpayTerminalEnabled);

    const payWithTerminalAvailable = useQpayTerminalEnabled || hasPaymentTerminalsAvailable;

    const addTipToPosOrder = async (tip: PosTipVm, order: OrderVm) => {
        const hasPaymentWithCard = order.payments?.some((payment: OrderPaymentVm) => isCardPayment(payment.paymentMethod) && !payment.customPaymentMethod);

        if (!hasPaymentWithCard && isCardPayment(tip.paymentMethod) && !tip.customPaymentMethod) {
            notification({ message: translate('Cannot add tip with card when not paying with credit card') });
            return;
        }

        const orderItemsMapped: Array<CartItemVm> = order?.orderItems?.map((item: OrderItemVm) => ({
            menuItemId: item.menuItemId,
            menuId: item.menuId,
            menuCategoryId: item.menuCategoryId,
            name: item.name,
            unitPrice: item.unitPrice,
            promoUnitPrice: item.promoUnitPrice,
            note: emptyStringToUndefined(item.note),
            modifierGroups: item.modifierGroups.map((modifierGroup) => ({
                name: modifierGroup.name,
                showModifierGroupNameInCommand: modifierGroup.showModifierGroupNameInCommand,
                freeModifiersQuantity: modifierGroup.freeModifiersQuantity,
                modifiers: modifierGroup.modifiers.map((modifier) => ({
                    name: modifier.name,
                    price: modifier.price,
                    quantity: modifier.quantity,
                })),
            })),
            orderItemKitchenStatus: item.orderItemKitchenStatus,
            quantity: item.quantity,
            addedAt: item.addedAt,
            salesUnit: item.salesUnit,
            isSoldByWeight: item.isSoldByWeight,
            itemsByCustomer: item.itemsByCustomer,
            cancellationReason: item.cancellationReason,
        }));

        let createPaymentDistributionRequest: CreatePaymentDistributionRequest = {
            orderItems: orderItemsMapped,
            country: order.country,
            payments: order.payments?.map(mapOrderPaymentToPosPayment),
            orderType: order.orderType,
        };

        if (order.posDiscount && order.posDiscountType) {
            createPaymentDistributionRequest = {
                ...createPaymentDistributionRequest,
                discount: {
                    discount: order.posDiscount,
                    discountPercentage: order.posDiscountPercentage,
                    discountType: order.posDiscountType,
                    notes: order.posDiscountNotes,
                },
            };
        }

        const paymentBeforeTips = createPaymentDistribution(createPaymentDistributionRequest);

        const tipAmount = calculateTipAmount(BigNumber(paymentBeforeTips.total).toNumber(), tip);

        let newPayment: PosPayment = { amount: tipAmount.toString(), paymentMethod: tip.paymentMethod, customPaymentMethod: tip.customPaymentMethod, isTipPayment: true, id: newUuid() as string };

        if (payWithTerminalAvailable && isCardPayment(tip.paymentMethod) && !tip.customPaymentMethod) {
            const paymentResponse = await payWithPaymentTerminal({ amount: tipAmount.toString() });
            if (!paymentResponse.paid) {
                return;
            }

            newPayment = {
                ...newPayment,
                paymentTerminalPaymentId: paymentResponse.paymentTerminalPaymentId!,
                paymentTerminalId: paymentResponse.paymentTerminalId!,
                paymentTerminalProvider: paymentResponse.paymentTerminalProvider!,
            };
        }

        return {
            tip: { ...tip, tipAmount: tipAmount.toString() },
            payment: newPayment,
        };
    };

    return addTipToPosOrder;
}

type Result = (
    arg1: PosTipVm,
    arg2: OrderVm
) => Promise<
    | {
          tip: PosTipVm;
          payment: PosPayment;
      }
    | undefined
>;
