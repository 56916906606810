/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { FacturamaInvoiceId, InvoiceId, RestaurantId } from 'src/types/Id';

export async function findOrderInvoicesApi(request: FindOrderInvoicesApiRequest): ApiSauceResponse<Array<OrderInvoiceVm>> {
    return letseatmanagerApiMethod('invoice/findOrderInvoicesApi', request);
}

type FindOrderInvoicesApiRequest = {
    restaurantId: RestaurantId;
    from: Date;
    to: Date;
};

export type OrderInvoiceVm = {
    invoiceId: InvoiceId;
    shortOrderId: string;
    businessName: string;
    amount: string;
    facturamaInvoiceId?: FacturamaInvoiceId;
    createdAt: Date;
};
