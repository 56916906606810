/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { ClosedEyeIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import type { MenuCategoryId, MenuId } from 'src/types/Id';
import { classNames } from 'src/utils/react/classNames';

export function Category({ category, onClick, categorySelected }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classNames(classes.container, categorySelected === category.menuCategoryId ? classes.categorySelected : '')} onClick={() => onClick(category.menuCategoryId)}>
            {category.hidden && <ClosedEyeIcon />}
            <div className={classes.name}>{category.name}</div>
            {categorySelected !== category.menuCategoryId && <KeyboardArrowRightIcon className={classes.icon} />}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 50,
        fontFamily: theme.typography.semiBold,
        color: theme.palette.text.brand,
        cursor: 'pointer',
    },
    categorySelected: {
        backgroundColor: theme.palette.surface.brand,
        color: theme.palette.text.brand,
    },
    icon: {
        color: theme.palette.icons.brand,
    },
    name: {
        width: '60%',
    },
}));

type Props = {
    menuId: MenuId;
    category: {
        menuCategoryId: MenuCategoryId;
        name?: string;
        hidden: boolean;
    };
    position: number;
    loading: boolean;
    onClick: any;
    categorySelected: MenuCategoryId;
};
