/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import type { InventoryAdjustmentsTableInfo } from 'src/types/InventoryAdjustmentsReportVm';
import { translateUnits } from 'src/utils/translate/translateUnits';

export function InventoryAdjustmentsReportTable({ loading, data, refresh }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Table
                loading={loading}
                data={data?.map((inventoryAdjustmentReport: InventoryAdjustmentsTableInfo) => {
                    return {
                        sku: inventoryAdjustmentReport.sku,
                        name: inventoryAdjustmentReport.name,
                        expectedQuantity: translateUnits(inventoryAdjustmentReport.expectedQuantity, inventoryAdjustmentReport.unitOfMeasurement),
                        quantity: translateUnits(inventoryAdjustmentReport.quantity, inventoryAdjustmentReport.unitOfMeasurement),
                        difference: translateUnits(inventoryAdjustmentReport.difference, inventoryAdjustmentReport.unitOfMeasurement),
                    };
                })}
                columns={[
                    {
                        name: 'sku',
                        label: translate('SKU'),
                        options: {
                            filter: false,
                            customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                        },
                    },
                    {
                        name: 'name',
                        label: translate('Supply'),
                        options: {
                            filter: true,
                            customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                        },
                    },
                    {
                        name: 'expectedQuantity',
                        label: translate('Expected Quantity'),
                        options: {
                            filter: false,
                            customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                        },
                    },
                    {
                        name: 'quantity',
                        label: translate('Quantity'),
                        options: {
                            filter: false,
                            customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                        },
                    },
                    {
                        name: 'difference',
                        label: translate('Difference'),
                        options: {
                            filter: false,
                            customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={refresh} />
                        </>
                    ),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
}));

export type Props = {
    loading: boolean;
    data: Array<InventoryAdjustmentsTableInfo>;
    refresh: any;
};
