/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { FormMultiselectableAutocomplete } from '@pidedirecto/ui/form';
import { useForm } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { Text } from 'src/components/Text';
import { OrderType, OrderTypes } from 'src/constants/OrderType';
import { translate } from 'src/i18n/translate';

export function FormOrderTypesMultiselectableAutocomplete({ name, label, defaultValue, disabled }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const { watch } = form;

    const orderTypes = watch(name, defaultValue ?? []);

    const restaurantOrderTypeOptions = Object.values(OrderTypes).map((orderType: OrderType) => ({
        label: translate(`OrderTypes.${orderType}`),
        value: orderType,
    }));

    return (
        <FormMultiselectableAutocomplete
            name={name}
            disabled={disabled}
            data={restaurantOrderTypeOptions}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            defaultValue={orderTypes ?? []}
            label={label}
            variant={'detailed'}
            helperText={translate('If left blank it will be all channels')}
            renderOption={(option) => (
                <div className={classes.containerOption}>
                    <Text>{option.label}</Text>
                </div>
            )}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    containerOption: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: Array<string>;
    disabled?: boolean;
};
