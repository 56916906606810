/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { changeOrderTypeApi } from 'src/api/letseatmanager/order/changeOrderTypeApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { SelectTableOfOrderDialog } from 'src/components/orderCard/SelectTableOfOrderDialog';
import { OrderTypes } from 'src/constants/OrderType';
import { PickupTimeTypes } from 'src/constants/PickupTimeType';
import { translate } from 'src/i18n/translate';
import { EatHereIcon } from 'src/icons/EatHereIcon';
import { TakeAwayBagIcon } from 'src/icons/TakeAwayBagIcon';
import { useClearCustomerInfo } from 'src/modules/pos/customer/useClearCustomerInfo';
import { useClearPos } from 'src/services/pos/useClearPos';
import { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangeOrderTypeContextualMenuItem(): React.ReactElement | null {
    const classes = useStyles();

    const clearPos = useClearPos();
    const clearCustomerInfo = useClearCustomerInfo();

    const [loading, setLoading] = useState(false);
    const [openSelectTableOrderDialog, setOpenSelectTableOrderDialog] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const currentOrders = useSelector((state) => state.app2.currentOrders);

    const updateOrder = useAction(app2.actions.updateOrder);

    const editableOrder = currentOrders?.find((order: OrderVm) => order.orderId === pendingOrder?.orderId);

    const handleTakeAwayOrder = async () => {
        if (!editableOrder) return;
        setLoading(true);
        const response = await changeOrderTypeApi({
            restaurantId,
            orderId: editableOrder.orderId,
            orderType: OrderTypes.TAKE_AWAY_ORDER,
            pickupTimeType: PickupTimeTypes.ASAP,
            pickupTime: new Date(),
        });
        setLoading(false);

        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        clearPos();
        updateOrder({ order: response.data });
    };

    const handleClick = (event: any) => {
        if (!editableOrder) return;

        if (isEatHereOrder(editableOrder.orderType)) return handleTakeAwayOrder();
        if (isTakeAwayOrder(editableOrder.orderType)) return setOpenSelectTableOrderDialog(true);
    };

    const getOrderTypeIcon = () => {
        if (!editableOrder) return;

        if (isTakeAwayOrder(editableOrder.orderType)) return <EatHereIcon width={15} height={15} color={'#C1C3C8'} title={translate('Eat here order')} />;

        return <TakeAwayBagIcon width={15} height={15} color={'#C1C3C8'} title={translate('Take away order')} />;
    };

    const getOrderTypeLabel = () => {
        if (!editableOrder) return;

        if (isTakeAwayOrder(editableOrder.orderType)) return translate(`Change to eat here`);

        return translate(`Change to take away`);
    };

    const handleCloseSelectTableOrderDialog = () => setOpenSelectTableOrderDialog(false);

    const onSuccessSelectTableOfOrder = (order: any) => {
        clearPos();
        clearCustomerInfo();
        updateOrder({ order });
    };

    if (!editableOrder) return null;

    return (
        <div>
            <SelectTableOfOrderDialog open={openSelectTableOrderDialog} onClose={handleCloseSelectTableOrderDialog} onSuccess={onSuccessSelectTableOfOrder} orderId={editableOrder?.orderId} />
            <Button classes={{ button: classes.button }} onClick={handleClick} disabled={loading}>
                {getOrderTypeIcon()}
                {getOrderTypeLabel()}
            </Button>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: 'transparent',
        color: '#616B79',
        borderColor: 'transparent',
        '&:focus': {
            border: 'transparent',
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));
