/**
 * @prettier
 */
import type { RestaurantId } from 'src/types/Id';
import { useSelector } from 'src/utils/react/useSelector';

export function useUserRestaurantIds(): Array<RestaurantId> {
    const userRestaurantIds = useSelector((state) => state.app.userRestaurantIds) ?? [];
    const pinCodeUser = useSelector((state) => state.authentication.pinCodeUserSignedIn);

    return pinCodeUser?.restaurantIds ?? userRestaurantIds;
}
