/**
 * @prettier
 */
import type { PromoCodeVm } from 'src/api/letseatmanager/types/PromoCodeVm';
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerId, RestaurantId } from 'src/types/Id';

export async function findPosPromoCodesApi(request: FindPosPromoCodesApiRequest): ApiSauceResponse<Array<PromoCodeVm>> {
    return letseatmanagerApiMethod('promoCode/findPosPromoCodesApi', request);
}

type FindPosPromoCodesApiRequest = {
    customerId: CustomerId;
    restaurantIds: Array<RestaurantId>;
};
