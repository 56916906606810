/**
 * @prettier
 */
import { PaymentRejectReason, PaymentRejectReasons } from 'src/constants/PaymentRejectReason';
import { translate } from 'src/i18n/translate';

export function translatePaymentRejectReasonCompact(paymentRejectReason?: PaymentRejectReason | null): string {
    if (!paymentRejectReason) {
        return '';
    }
    switch (paymentRejectReason) {
        case PaymentRejectReasons.INSUFFICIENT_FUNDS: {
            return translate('Insufficient funds');
        }
        case PaymentRejectReasons.COMMUNICATION_ERROR: {
            return translate('Communication with bank failed');
        }
        case PaymentRejectReasons.RESTRICTED_CARD: {
            return translate('Declined by bank');
        }
        case PaymentRejectReasons.INVALID_SECURITY_CODE: {
            return translate('Invalid security code');
        }
        case PaymentRejectReasons.UNCATEGORIZED:
        case PaymentRejectReasons.UNKNOWN:
        default: {
            return translate('Declined by bank');
        }
    }
}
