/**
 * @prettier
 */
import { Days } from 'src/constants/Day';
import { WeekSchedule, WeekSchedules } from 'src/constants/WeekSchedule';
import { WeekOpeningHours } from 'src/utils/hours/transformHoursToWeekOpeningHours';

export function transformWeekOpeningHoursToWeekSchedule(scheduleDays: WeekOpeningHours): WeekSchedule {
    const openScheduleDays = scheduleDays.filter((scheduleDay) => scheduleDay.openingHours.length > 0);
    const openDays = openScheduleDays.map((openScheduleDay) => openScheduleDay.day);

    if (isWeekendSchedule(openDays)) return WeekSchedules.WEEKEND;
    if (isWeekdaySchedule(openDays)) return WeekSchedules.WEEKDAY;
    if (isAlwaysSchedule(openDays)) return WeekSchedules.ALWAYS;

    return WeekSchedules.CUSTOM;
}

function isWeekendSchedule(days: Array<string>): boolean {
    return days.includes(Days.SATURDAY) && days.includes(Days.SUNDAY) && days.length === 2;
}

function isWeekdaySchedule(days: Array<string>): boolean {
    return days.includes(Days.MONDAY) && days.includes(Days.TUESDAY) && days.includes(Days.WEDNESDAY) && days.includes(Days.THURSDAY) && days.includes(Days.FRIDAY) && days.length === 5;
}

function isAlwaysSchedule(days: Array<string>): boolean {
    return days.length === 7;
}
