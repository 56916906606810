/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { SECONDS } from 'src/constants/TimeUnit';
import type { OrderVm } from 'src/types/OrderVm';
import { isCancelledOrder } from 'src/utils/order/isCancelledOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isNewOrder } from 'src/utils/order/isNewOrder';
import { isRejectedOrder } from 'src/utils/order/isRejectedOrder';
import { isReturnedOrder } from 'src/utils/order/isReturnedOrder';
import { classNames } from 'src/utils/react/classNames';
import { useInterval } from 'src/utils/react/useInterval';

export function OrderStatusHighlight({ order, children }: Props): React.ReactElement {
    const classes = useStyles();

    const [highlighted, setHighlighted] = useState(true);

    const orderIsArrivedAtClient = moment(order.arrivedAtClientAt).add(5, 'minutes').isBefore(moment()) && highlighted;
    const orderIsPickedUp = orderIsArrivedAtClient && !isCompletedOrder(order.orderStatus) && !isRejectedOrder(order.orderStatus) && !isCancelledOrder(order.orderStatus);

    useInterval(() => toggleHighlight(), 1 * SECONDS, [highlighted]);

    const toggleHighlight = () => {
        if (isReturnedOrder(order.orderStatus)) {
            setHighlighted(false);
            return;
        }
        if (isNewOrder(order.orderStatus)) setHighlighted(!highlighted);
        if (order.arrivedAtClientAt) setHighlighted(!highlighted);
    };

    return (
        <Paper
            className={classNames(classes.paper, {
                [classes.rejected]: isRejectedOrder(order.orderStatus) || isCancelledOrder(order.orderStatus),
                [classes.highlighted]: highlighted && isNewOrder(order.orderStatus),
                [classes.notPickedUp]: highlighted && orderIsPickedUp,
            })}
            elevation={0}
        >
            {children}
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 24,
        borderRadius: 12,
        border: '1px solid #d9d9d9',
        width: '100%',
        '& .MuiCollapse-container': {
            width: '100%',
        },
    },
    rejected: {
        backgroundColor: '#F1F6F9',
    },
    highlighted: {
        backgroundColor: '#fafad2',
    },
    notPickedUp: {
        backgroundColor: '#ffbcc1',
    },
}));

type Props = {
    order: OrderVm;
    children?: React.ReactNode;
};
