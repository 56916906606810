/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Accordion, Button } from '@pidedirecto/ui';
import { ArrowDownIcon, ArrowUpIcon, TrashCanIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { MenuItemInMenu } from 'src/scenes/letseatmanager/menu/menu/MenuItemInMenu';
import { RemoveMenuCategoryFromMenu } from 'src/scenes/letseatmanager/menu/menu/RemoveMenuCategoryFromMenu';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import { useLoadMenus } from 'src/services/menu/useLoadMenus';
import { useMenuItems } from 'src/services/menuItem/useMenuItems';
import type { MenuId } from 'src/types/Id';
import type { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import { transformImageUrl } from 'src/utils/image/transformImageUrl';

export function MenuCategoryInMenu({ menuId, menuCategory }: Props): React.ReactElement | null {
    const classes = useStyles();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();

    const [showProducts, setShowProducts] = useState(false);
    const [removeMenuCategoryFromMenuDialogState, setRemoveMenuCategoryFromMenuDialogState] = useState({ open: false });

    const { products } = useMenuItems();
    const refreshMenus = useLoadMenus();

    const openRemoveMenuCategoryFromMenuDialog = () => setRemoveMenuCategoryFromMenuDialogState({ open: true });

    const closeRemoveMenuCategoryFromMenuDialog = () => setRemoveMenuCategoryFromMenuDialogState({ open: false });

    const toggleProductsVisibility = () => setShowProducts(!showProducts);

    return (
        <div className={classes.container}>
            <RemoveMenuCategoryFromMenu
                open={removeMenuCategoryFromMenuDialogState.open}
                menuId={menuId}
                menuCategoryId={menuCategory.menuCategoryId}
                onClose={closeRemoveMenuCategoryFromMenuDialog}
                onSuccess={refreshMenus}
            />
            <div className={classes.menuCategory}>
                <div className={classes.groupContainer}>
                    {menuCategory?.imageUrl && <img className={classes.image} src={transformImageUrl(menuCategory?.imageUrl, 320, 320)} alt={`${menuCategory?.name} image`} />}
                    {!menuCategory?.imageUrl && <div className={classes.noImage}></div>}
                    <span className={classes.text}>{menuCategory?.name}</span>
                </div>
                <div className={classes.groupContainer}>
                    <Button variant='icon' onClick={toggleProductsVisibility}>
                        {showProducts && <ArrowUpIcon title={'arrow up'} size={20} />}
                        {!showProducts && <ArrowDownIcon title={'arrow down'} size={20} />}
                    </Button>
                    <Button variant='icon' onClick={openRemoveMenuCategoryFromMenuDialog} disabled={isMenuEditionDisabled}>
                        <TrashCanIcon title={'remove'} color={'#E37878'} size={20} />
                    </Button>
                </div>
            </div>
            <Accordion open={showProducts} classes={{ accordion: classes.accordion }}>
                <ul className={classes.menuItemsList}>
                    {menuCategory.menuItemIds.map((menuItemId, idx) => {
                        const menuItem = products.find((menuItem) => menuItem.menuItemId === menuItemId);
                        const isLastItem = idx === menuCategory.menuItemIds.length - 1;
                        if (!menuItem) return null;

                        return (
                            <li style={{ padding: '5px 0' }} className={!isLastItem ? classes.menuItemBordered : ''} key={menuItemId}>
                                <MenuItemInMenu menuCategoryId={menuCategory.menuCategoryId} menuItem={menuItem} menuId={menuId} />
                            </li>
                        );
                    })}
                </ul>
            </Accordion>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    menuCategory: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    groupContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    image: {
        width: 40,
        height: 40,
        borderRadius: 10,
        objectFit: 'cover',
        flexShrink: 0,
    },
    noImage: {
        width: 40,
        height: 40,
        borderRadius: 10,
        backgroundColor: '#E5E8ED',
        flexShrink: 0,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    menuItemsList: {
        listStyle: 'none',
        paddingLeft: 10,
    },
    menuItemBordered: {
        borderBottom: '1px solid #d9d9d9',
    },
    accordion: {
        overflow: 'visible',
    },
}));

type Props = {
    menuId: MenuId;
    menuCategory: MenuCategoryVm;
};
