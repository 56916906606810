/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { findPrintersApi } from 'src/api/letseatmanager/printer/findPrintersApi';
import { SectionList } from 'src/components/SectionList';
import { SectionListItem } from 'src/components/SectionListItem';
import { translate } from 'src/i18n/translate';
import { ChangePrinterSection } from 'src/scenes/letseatmanager/device/printers/ChangePrinterSection';
import { PrinterListItem } from 'src/scenes/letseatmanager/device/printers/PrinterListItem';
import { PrintersEmptyState } from 'src/scenes/letseatmanager/device/printers/PrintersEmptyState';
import { PrinterId } from 'src/types/Id';
import { PrinterVm } from 'src/types/PrinterVm';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function Printers({ openCreatePrinterSection }: Props): React.ReactElement {
    const classes = useStyles();

    const [changePrinterSectionState, setChangePrinterSectionState] = useState<{ printerId: PrinterId | undefined }>({ printerId: undefined });

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loadingPrinters, printers, refreshPrinters] = useLoadApi(findPrintersApi, { restaurantId: restaurantId }, { initialValue: { printers: [] } });

    const [filteredPrinters, setFilteredPrinters] = useState<Array<PrinterVm>>([]);

    const removePrinter = (printerId: any) => {
        setFilteredPrinters((filteredPrinters) => filteredPrinters.filter((printer) => printer.printerId !== printerId));
    };

    useEffect(() => {
        setFilteredPrinters(printers);
    }, [printers]);

    if (loadingPrinters) {
        return (
            <div className={classes.circularContainer}>
                <Loader size={20} loading={true} />
            </div>
        );
    }

    if (!loadingPrinters && !printers.length) return <PrintersEmptyState onClickStart={openCreatePrinterSection} />;

    return (
        <div className={classes.container}>
            <div className={classes.sectionListContainer}>
                <SectionList title={translate('Printers')} onCreate={openCreatePrinterSection} classes={{ container: classes.sectionList }}>
                    {filteredPrinters.map((printer) => {
                        return (
                            <SectionListItem key={printer.printerId} value={printer.printerId}>
                                <PrinterListItem
                                    printer={printer}
                                    onRemovePrinter={() => removePrinter(printer.printerId)}
                                    onSelectPrinter={() => setChangePrinterSectionState({ printerId: printer.printerId })}
                                />
                            </SectionListItem>
                        );
                    })}
                </SectionList>
            </div>
            <div className={classes.changePrinterSection}>
                <ChangePrinterSection printerId={changePrinterSectionState.printerId} onPrinterChanged={refreshPrinters} />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 40,
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    circularContainer: {
        minHeight: '70vh',
        height: '100%',
        display: 'grid',
        placeItems: 'center',
    },
    sectionListContainer: {
        maxWidth: 600,
        animation: '$appear 700ms ease',
    },
    sectionList: {
        [theme.breakpoints.up('sm')]: {
            minWidth: 540,
        },
        overflow: 'visible',
    },
    changePrinterSection: {},

    '@keyframes appear': {
        '0%': {
            opacity: 0,
            transform: 'translateY(100px)',
        },
        '100%': {
            opacity: 1,
            transform: 'translateY(0px)',
        },
    },
}));

type Props = {
    openCreatePrinterSection: any;
};
