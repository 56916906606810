/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { BarChartCanvas } from 'src/components/charts/BarChartCanvas';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';

export function AverageTimeConversionPercentageReport({ averageTimeConversionPercentageReport }: Props): React.ReactElement {
    const classes = useStyles();

    const ReportSteps = {
        addedFirstItemToCart: 'Added first item to cart',
        createdOrder: 'Created order',
        openedCartDetails: 'Opened cart details',
        signedInToPidedirecto: 'Signed in to Pidedirecto',
        totalTime: 'Total time',
    } as const;

    const data = Object.keys(averageTimeConversionPercentageReport).map((key) => ({
        id: translate(ReportSteps[key as keyof typeof ReportSteps]),
        value: averageTimeConversionPercentageReport[key as keyof typeof ReportSteps],
    }));

    return (
        <div className={classes.container}>
            <Text style={{ fontSize: 16 }} variant='semiBold'>
                {translate('Average time per step')}
            </Text>
            <div className={classes.chartContainer}>
                <BarChartCanvas
                    data={data.reverse()}
                    indexBy={'id'}
                    layout={'horizontal'}
                    keys={['value']}
                    labelTextColor={'#FFFFFF'}
                    classes={{ container: classes.barChart }}
                    margin={{ top: 0, left: 100, bottom: 20 }}
                    tickRotation={0}
                    tooltip={({ value }: { value: string }) => {
                        return (
                            <div className={classes.tooltip}>
                                <Text>
                                    {translate('Time')}: <strong>{value} min</strong>
                                </Text>
                            </div>
                        );
                    }}
                />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        backgroundColor: 'white',
        borderRadius: 12,
        padding: 24,
        border: `1px solid ${theme.palette.border.primary}`,
        width: '100%',
    },
    chartContainer: {
        height: 450,
    },
    barChart: {
        height: '100%',
    },
    tooltip: {
        padding: 10,
        borderRadius: 4,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
    },
    tooltipTitle: {
        fontFamily: theme.typography.bold,
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    averageTimeConversionPercentageReport: {
        addedFirstItemToCart: string;
        signedInToPidedirecto: string;
        openedCartDetails: string;
        createdOrder: string;
        totalTime: string;
    };
};
