/**
 * @prettier
 */
import hash from 'object-hash';
import type { OrderItemVm } from 'src/types/OrderVm';
import { removeNulls } from 'src/utils/removeNulls';

export function createOrderItemKey(posItem: OrderItemVm): string {
    const { quantity, addedAt, orderItemKitchenStatus, ...keyData } = posItem;
    return hash(removeNulls(keyData));
}
