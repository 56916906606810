/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { changeRestaurantTaxesSortingApi } from 'src/api/letseatmanager/taxes/changeRestaurantTaxesSortingApi';
import { findRestaurantTaxesApi } from 'src/api/letseatmanager/taxes/findRestaurantTaxesApi';
import { removeRestaurantTaxApi } from 'src/api/letseatmanager/taxes/removeRestaurantTaxApi';
import { Button } from 'src/components/Button';
import { DraggableList } from 'src/components/DraggableList';
import { DraggableListItem } from 'src/components/DraggableListItem';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ChangeRestaurantTaxDialog } from 'src/scenes/letseatmanager/taxes/ChangeRestaurantTaxDialog';
import { CreateRestaurantTaxDialog } from 'src/scenes/letseatmanager/taxes/CreateRestaurantTaxDialog';
import { RestaurantTaxListItem } from 'src/scenes/letseatmanager/taxes/RestaurantTaxListItem';
import { useConfirmDialog } from 'src/services/dialog/useConfirmDialog';
import { RestaurantTaxId } from 'src/types/Id';
import type { RestaurantTaxVm } from 'src/types/RestaurantTaxVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { classNames } from 'src/utils/react/classNames';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantTaxesPage(): React.ReactElement {
    const classes = useStyles();
    const confirmDialog = useConfirmDialog();

    const [loading, setLoading] = useState(false);
    const [createTaxDialogState, setCreateRestaurantTaxDialogState] = useState({ open: false });
    const [changeTaxDialogState, setChangeRestaurantTaxDialogState] = useState<{ open: boolean; restaurantTaxId: RestaurantTaxId | undefined }>({ open: false, restaurantTaxId: undefined });

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loadingRestaurantTaxes, restaurantTaxes, refreshRestaurantTaxes] = useLoadApi(findRestaurantTaxesApi, { restaurantId }, { initialValue: [] });

    const removeTax = async (restaurantTaxId: RestaurantTaxId) => {
        const tax = restaurantTaxes.find((tax) => tax.restaurantTaxId === restaurantTaxId);

        const rejectOrder = await confirmDialog({
            content: translate('Are you sure you want to remove the tax @taxName?', { taxName: tax?.name }),
            buttonText: translate('Continue'),
            cancelButtonText: translate('Cancel'),
        });

        if (!rejectOrder) return;

        setLoading(true);
        const response = await removeRestaurantTaxApi({
            restaurantTaxId: restaurantTaxId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        refreshRestaurantTaxes();
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading || loadingRestaurantTaxes} bottom />
            <CreateRestaurantTaxDialog open={createTaxDialogState.open} onClose={() => setCreateRestaurantTaxDialogState({ open: false })} onSuccess={() => refreshRestaurantTaxes()} />
            <ChangeRestaurantTaxDialog
                open={changeTaxDialogState.open}
                restaurantTaxId={changeTaxDialogState.restaurantTaxId}
                onClose={() => setChangeRestaurantTaxDialogState({ open: false, restaurantTaxId: undefined })}
                onChangeTax={() => refreshRestaurantTaxes()}
            />

            <section className={classes.taxesContainer}>
                <div className={classes.header}>
                    <h2 className={classes.title}>{translate('Taxes')}</h2>
                    <div className={classes.taxOptionsContainer}>
                        <Button classes={{ button: classes.button }} onClick={() => setCreateRestaurantTaxDialogState({ open: true })}>
                            {translate('+')}
                        </Button>
                    </div>
                </div>
                <DraggableList onDragEnd={(restaurantTaxIds: Array<RestaurantTaxId>) => changeRestaurantTaxesSortingApi({ restaurantId, restaurantTaxIds })}>
                    <div className={classes.draggableListContainer}>
                        <div className={classes.draggableListHeader}>
                            <span className={classes.headerItem}>{translate('Name')}</span>
                            <span className={classes.headerItem}>{translate('Description')}</span>
                            <span className={classes.headerItem}>{translate('Rate')}</span>
                            <span className={classes.headerItem}>{translate('Type')}</span>
                            <span className={classes.headerItem}>{translate('Products')}</span>
                        </div>
                        {restaurantTaxes?.map((tax: RestaurantTaxVm) => {
                            return (
                                <DraggableListItem key={tax.restaurantTaxId} value={tax.restaurantTaxId} iconColor={'#6C7076'} classes={{ container: classNames(classes.draggableItemContainer) }}>
                                    <RestaurantTaxListItem
                                        tax={tax}
                                        onChangeTax={() => setChangeRestaurantTaxDialogState({ open: true, restaurantTaxId: tax.restaurantTaxId })}
                                        onRemoveTax={() => removeTax(tax.restaurantTaxId)}
                                    />
                                </DraggableListItem>
                            );
                        })}
                    </div>
                </DraggableList>
            </section>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
        marginTop: 20,
        gap: 60,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    taxesContainer: {
        width: '70%',
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: 20,
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.primary.main,
        fontSize: 20,
        margin: 0,
    },
    draggableItemContainer: {
        alignItems: 'center',
        backgroundColor: 'white',
    },
    selectedDraggableItemContainer: {
        backgroundColor: '#EBFFF9',
        boxShadow: '0px 0px',
        cursor: 'pointer',
        borderBottom: '2px solid #63D6B3',
        transition: 'backgroundColor 0.3s ease',
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.regular,
        fontSize: 16,
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        padding: '5px 15px',
    },
    taxOptionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    draggableListHeader: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
        padding: '25px 1px',
        borderBottom: '1px solid #E5E5E5',
        backgroundColor: '#F7F7F7',
        fontFamily: theme.typography.medium,
        fontSize: 16,
        borderRadius: '8px 8px 0 0',
        border: '1px solid #E5E5E5',
    },
    draggableListContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        borderRadius: 8,
        border: '1px solid #E5E5E5',
        overflow: 'hidden',
    },
    headerItem: {
        display: 'flex',
        justifyContent: 'center',
    },
}));
