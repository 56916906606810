/**
 * @prettier
 */
import { countAnsiEscapeSequenceStylingCharacters } from 'src/utils/string/countAnsiEscapeSequenceStylingCharacters';

export function padAround<T extends string | undefined | null>(value: T, maxLength: number, fillString: string = ' '): T {
    if (value === undefined || value === null) {
        return value;
    }

    // Solve issue with ansi styling messing padding up
    maxLength = maxLength + countAnsiEscapeSequenceStylingCharacters(value);

    return value.padStart(value.length + Math.floor((maxLength - value.length) / 2), fillString).padEnd(maxLength, fillString) as any;
}
