/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { changeRestaurantZoneApi } from 'src/api/letseatmanager/pos/changeRestaurantZoneApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangeRestaurantZoneDialog(): React.ReactElement {
    const form = useForm();

    const [loading, setLoading] = useState(false);

    const open = useSelector((state) => state.pos.changeRestaurantZoneDialog.open);
    const restaurantZone = useSelector((state) => state.pos.restaurantZone);
    const onSuccess = useSelector((state) => state.pos.changeRestaurantZoneDialog.onSuccess);

    const close = useAction(posReducer.actions.closeChangeRestaurantZoneDialog);

    useEffect(() => {
        if (restaurantZone) form.reset({ name: restaurantZone?.name });
    }, [restaurantZone]);

    const handleSubmit = async (form: any) => {
        setLoading(true);
        const response = await changeRestaurantZoneApi({
            restaurantZoneId: restaurantZone?.restaurantZoneId,
            name: form.name,
        });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        onSuccess(response.data);
    };

    return (
        <Dialog open={open} onClose={close} title={translate('Change Restaurant Zone')}>
            <Form onSubmit={handleSubmit} form={form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name='name' disabled={loading} label={translate('Name')} required />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button secondary onClick={close} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {loading ? translate('Changing') : translate('Change')}{' '}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}
