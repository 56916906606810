/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { memo } from 'react';
import { useEffect } from 'react';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { translate } from 'src/i18n/translate';
import { useClearCustomerInfo } from 'src/modules/pos/customer/useClearCustomerInfo';
import { posReducer } from 'src/reducers/posReducer';
import { EnableRestaurantPaymentsDialogDeprecated } from 'src/scenes/letseatmanager/pos/posPayment/EnableRestaurantPaymentsDialogDeprecated';
import { PosPaymentOptions } from 'src/scenes/letseatmanager/pos/posPayment/PosPaymentOptions';
import { PosPaymentResume } from 'src/scenes/letseatmanager/pos/posPayment/PosPaymentResume';
import { useSetPosPaymentMethod } from 'src/services/pos/posPaymentMethods/useSetPosPaymentMethod';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export const PosPayment: React.ComponentType<any> = memo<any>(() => {
    const classes = useStyles();

    const clearCustomerInfo = useClearCustomerInfo();
    const setPosPaymentMethod = useSetPosPaymentMethod();

    const restaurantPaymentMethodsEnabled = useSelector((state) => state.pos.context?.restaurantPaymentMethodsEnabled);
    const openMobilePaymentResume = useSelector((state) => state.pos.openMobilePaymentResume);

    const closePosPayment = useAction(posReducer.actions.closePosPayment);

    useEffect(() => {
        return () => {
            setPosPaymentMethod({ paymentMethod: undefined, customPaymentMethod: undefined });
        };
    });

    const handleClosePosPayment = () => {
        closePosPayment();
        clearCustomerInfo();
    };

    return (
        <div className={classes.container}>
            {!restaurantPaymentMethodsEnabled && <EnableRestaurantPaymentsDialogDeprecated />}
            {!openMobilePaymentResume && <GoBackButton label={translate('Return')} goBack={handleClosePosPayment} classes={{ button: classes.goBackButton }} />}
            <PosPaymentOptions />
            <PosPaymentResume />
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    hidden: {
        display: 'none',
    },
    container: {
        width: '100%',
        height: '90%',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            height: '100vh',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
    },
    orderResumeTextBold: {
        fontFamily: theme.typography.medium,
        fontSize: 16,
        color: '#2E3748',
    },
    paymentsCounter: {
        top: '-10px',
        color: 'white',
        right: '-10px',
        width: 24,
        height: 24,
        display: 'grid',
        position: 'absolute',
        fontSize: 14,
        placeItems: 'center',
        borderRadius: '50%',
        backgroundColor: '#333333',
    },
    secondaryButton: {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.primary.main}`,
        width: '100%',
        textTransform: 'none',
        color: theme.palette.primary.main,
        marginRight: '6px',
        margin: '20px 0',
        marginBottom: 0,
        borderRadius: 10,
        padding: '8px 0',
        fontSize: 14,
        maxHeight: 50,
        fontFamily: theme.typography.semiBold,
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main}20`,
        },
    },
    disabled: {
        backgroundColor: '#DDDDDD',
        width: '100%',
        textTransform: 'none',
        color: `white !important`,
        margin: '20px 0',
        borderRadius: 10,
        padding: '8px 0',
        fontSize: 18,
        fontFamily: theme.typography.semiBold,
        pointerEvents: 'none',
    },

    goBackButton: {
        alignSelf: 'start',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    customerInfoContainer: {
        width: '100%',
        marginTop: 20,
    },

    seeOrderResumeButton: {
        position: 'relative',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    customerInfoTitle: {
        fontFamily: theme.typography.regular,
        fontSize: 18,
        marginBottom: 12,
        color: '#2E3748',
    },
    paymentsContainer: {
        maxHeight: 280,
        overflowY: 'auto',
        marginBottom: 16,
    },
    numericPad: {
        width: '100%',
        height: '80%',
    },
    totalTicketButton: {
        width: '100%',
    },
    largerButton: {
        fontSize: 15,
        height: 52,
    },
}));
