/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import * as React from 'react';
import { Dialog } from 'src/components/Dialog';
import { NumericPad } from 'src/components/NumericPad';
import { translate } from 'src/i18n/translate';
import { classNames } from 'src/utils/react/classNames';

export function NumberOfCustomersDialog({ open, handleClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    const [error, setError] = useState(false);

    const handleSubmit = (numberOfCustomers: any) => {
        if (!Boolean(numberOfCustomers) || numberOfCustomers === '0') {
            return setError(true);
        }
        setError(false);
        onSuccess?.(parseInt(numberOfCustomers));
        handleClose();
    };

    return (
        <Dialog title={translate('Number of Customers')} open={open} onClose={handleClose}>
            <p className={classes.bodyText}>{translate('How many guests are at the table?')}</p>
            <NumericPad onSubmit={handleSubmit} />
            {error && <p className={classNames(classes.bodyText, classes.errorText)}>{translate('Value may not be empty or be equal to 0')}</p>}
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    bodyText: {
        fontFamily: theme.typography.medium,
        textAlign: 'center',
        color: theme.palette.secondary.contrastText,
        padding: 0,
        margin: 0,
    },

    errorText: {
        color: 'red',
    },

    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },

    cancelButton: {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 10,
        height: 40,
        width: 150,
        textTransform: 'none',
    },
}));

type Props = {
    open: boolean;
    handleClose: any;
    onSuccess?: any;
};
