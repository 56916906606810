/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CashRegisterTransactionVm } from 'src/types/CashRegisterTransactionVm';
import { formatTimeString } from 'src/utils/date/formatTimeString';
import { classNames } from 'src/utils/react/classNames';
import { translateCashRegisterDepositReasons } from 'src/utils/translate/translateCashRegisterDepositReasons';
import { translateCashRegisterWithdrawReasons } from 'src/utils/translate/translateCashRegisterWithdrawReasons';

export function PosCashRegisterTransactionCard({ cashRegisterTransaction }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const isDeposit = BigNumber(cashRegisterTransaction?.amount ?? 0).isPositive();
    const movement = isDeposit ? translate('Deposited') : translate('Withdrawed');

    return (
        <article className={classes.card}>
            <div className={classNames(classes.verticalLine, isDeposit ? classes.depositBanner : classes.withdrawBanner)} />
            <section className={classes.descriptionContainer}>
                <div className={classes.infoContainer}>
                    <div className={classes.headerText}>{translate('Movement')}</div>
                    <div className={classes.contentText}>{movement}</div>
                </div>
                <div className={classes.infoContainer}>
                    <div className={classes.headerText}>{translate('Amount')}</div>
                    <div className={classes.contentText}>{formatAsCurrencyNumber(cashRegisterTransaction.amount)}</div>
                </div>
                <div className={classes.infoContainer}>
                    <div className={classes.headerText}>{translate('Reason')}</div>
                    <div className={classes.contentText}>
                        {isDeposit ? translateCashRegisterDepositReasons(cashRegisterTransaction.reason) : translateCashRegisterWithdrawReasons(cashRegisterTransaction.reason)}
                    </div>
                </div>
                <div className={classes.infoContainer}>
                    <div className={classes.headerText}>{translate('Hour')}</div>
                    <div className={classes.contentText}>{formatTimeString(cashRegisterTransaction.createdAt)}</div>
                </div>
                {cashRegisterTransaction.username && (
                    <div className={classes.infoContainer}>
                        <div className={classes.headerText}>{translate('Responsible')}</div>
                        <div className={classes.contentText}>{cashRegisterTransaction.username}</div>
                    </div>
                )}
            </section>
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 8,
        display: 'flex',
        border: '1px solid #D9D9D9',
        padding: '10px',
        fontFamily: theme.typography.regular,
    },
    verticalLine: {
        width: 5,
        marginRight: 10,
    },
    depositBanner: {
        backgroundColor: '#CBF7E9',
    },
    withdrawBanner: {
        backgroundColor: '#C9E2FF',
    },
    contentText: {
        color: '#324054',
        fontSize: 16,
    },
    headerText: {
        color: '#616B79',
        fontSize: 12,
    },
    descriptionContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
    },
    infoContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: 5,
    },
}));

type Props = {
    cashRegisterTransaction: CashRegisterTransactionVm;
};
