/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button, DropDown, DropDownItem } from '@pidedirecto/ui';
import { EllipsisVerticalIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { translate } from 'src/i18n/translate';
import { CopyRestaurantMenuDialog } from 'src/scenes/letseatmanager/menu/menu/CopyRestaurantMenuDialog';
import { ImportMenuFromCsvFileDialog } from 'src/scenes/letseatmanager/menu/menu/ImportMenuFromCsvFileDialog';
import { ImportRestaurantMenuDialog } from 'src/scenes/letseatmanager/menu/menu/ImportRestaurantMenuDialog';
import { ManageMenuCategoryFamiliesDialog } from 'src/scenes/letseatmanager/menu/menu/ManageMenuCategoryFamiliesDialog';
import { useLoadRestaurantMenu } from 'src/services/restaurant/useLoadRestaurantMenu';
import { useSelector } from 'src/utils/react/useSelector';

export function AdvancedMenuOptions(): React.ReactElement | null {
    const classes = useStyles();

    const refreshMenus = useLoadRestaurantMenu();

    const [copyRestaurantMenuDialogState, setCopyRestaurantMenuDialogState] = useState({ open: false });
    const [importRestaurantMenuDialogState, setImportRestaurantMenuDialogState] = useState({ open: false });
    const [importMenuFromCsvFileDialogState, setImportMenuFromCsvFileDialogState] = useState({ open: false });
    const [manageMenuCategoryFamiliesDialogState, setManageMenuCategoryFamiliesDialogState] = useState({ open: false });

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const menuCategoryFamiliesEnabled = useSelector((state) => state.app.restaurant?.menuCategoryFamiliesEnabled);

    const openCopyRestaurantMenuDialog = () => setCopyRestaurantMenuDialogState({ open: true });

    const closeCopyRestaurantMenuDialog = () => setCopyRestaurantMenuDialogState({ open: false });

    const openImportRestaurantMenuDialog = () => setImportRestaurantMenuDialogState({ open: true });

    const closeImportRestaurantMenuDialog = () => setImportRestaurantMenuDialogState({ open: false });

    const openImportMenuFromCsvFileDialog = () => setImportMenuFromCsvFileDialogState({ open: true });

    const closeImportMenuFromCsvFileDialog = () => setImportMenuFromCsvFileDialogState({ open: false });

    const openManageMenuCategoryFamiliesDialog = () => setManageMenuCategoryFamiliesDialogState({ open: true });

    const closeManageMenuCategoryFamiliesDialog = () => setManageMenuCategoryFamiliesDialogState({ open: false });

    const handleRefreshMenus = () => refreshMenus();

    if (!internalUser) return null;

    return (
        <div className={classes.container}>
            <CopyRestaurantMenuDialog open={copyRestaurantMenuDialogState.open} onClose={closeCopyRestaurantMenuDialog} onSuccess={handleRefreshMenus} />
            <ImportRestaurantMenuDialog open={importRestaurantMenuDialogState.open} onClose={closeImportRestaurantMenuDialog} />
            <ImportMenuFromCsvFileDialog open={importMenuFromCsvFileDialogState.open} onClose={closeImportMenuFromCsvFileDialog} onSuccess={handleRefreshMenus} />
            <ManageMenuCategoryFamiliesDialog open={manageMenuCategoryFamiliesDialogState.open} onClose={closeManageMenuCategoryFamiliesDialog} />
            <DropDown content={<EllipsisVerticalIcon title={translate('Advanced options')} />} variant='icon' classes={{ dropdown: classes.dropdown }}>
                {menuCategoryFamiliesEnabled && (
                    <DropDownItem>
                        <Button variant='text' classes={{ button: classes.button }} onClick={openManageMenuCategoryFamiliesDialog}>
                            {translate('Manage menu families')}
                        </Button>
                    </DropDownItem>
                )}
                <DropDownItem>
                    <Button variant='text' classes={{ button: classes.button }} onClick={openCopyRestaurantMenuDialog}>
                        {translate('Copy Menu from other Restaurant')}
                    </Button>
                </DropDownItem>
                <DropDownItem>
                    <Button variant='text' classes={{ button: classes.button }} onClick={openImportRestaurantMenuDialog}>
                        {translate('Import Menu from external URL')}
                    </Button>
                </DropDownItem>
                <DropDownItem>
                    <Button variant='text' classes={{ button: classes.button }} onClick={openImportMenuFromCsvFileDialog}>
                        {translate('Import Menu From CSV File')}
                    </Button>
                </DropDownItem>
            </DropDown>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    dropdown: {
        maxHeight: 'unset',
        width: 250,
    },
    button: {
        justifyContent: 'flex-start',
        width: '100%',
        minHeight: 'unset',
        padding: 0,
        '&:focus': {
            outline: 0,
        },
    },
}));
