/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { ReleaseNotesVm } from 'src/types/ReleaseNotesVm';

export async function createReleaseNotesApi(request: CreateReleaseNotesApiRequest): ApiSauceResponse<ReleaseNotesVm> {
    return letseatadminApiMethod('releaseNotes/createReleaseNotesApi', request);
}

type CreateReleaseNotesApiRequest = {
    title: string;
    releaseNotesUrl: string;
};
