/**
 * @prettier
 */
import { RolePermissions } from 'src/constants/RolePermission';
import { useUserHasConfiguredRolePermissions } from 'src/services/managerUser/useUserHasConfiguredRolePermissions';
import { OrderVm } from 'src/types/OrderVm';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function useIsOrderVisibleForUser(): Service {
    const userHasConfiguredRolePermissions = useUserHasConfiguredRolePermissions();
    const [userHasRolePermission] = useUserHasRolePermission();

    const managerUserId = useSelector((state) => state.authentication.managerUserId);
    const pinCodeUserSignedInManagerUserId = useSelector((state) => state.authentication.pinCodeUserSignedIn?.managerUserId);

    const isOrderVisibleForUser = (order: OrderVm) => {
        if ((!userHasRolePermission(RolePermissions.VIEW_ONLY_ASSIGNED_ORDERS) && userHasConfiguredRolePermissions) || !userHasConfiguredRolePermissions) return true;
        return !order.servedBy || order.servedBy === (pinCodeUserSignedInManagerUserId ?? managerUserId);
    };

    return isOrderVisibleForUser;
}

type Service = (order: OrderVm) => boolean;
