/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, Text } from '@pidedirecto/ui';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function UnableToClosePosBusinessDayWithPendingOrdersDialog({ open, onClose }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onClose} title={translate('Close Cash Register')}>
            <Text weight='medium'>{translate('Unable to close the cash register with pending orders')}</Text>
            <DialogActions className={classes.buttonContainer}>
                <Button onClick={onClose}>{translate('Accept')}</Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

type Props = {
    open: boolean;
    onClose: () => void;
};
