/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { CircleCheckIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { UrugayanReturnedOrderStatus } from 'src/components/orderCard/UrugayanReturnedOrderStatus';
import { KitchenStatuses } from 'src/constants/KitchenStatus';
import { OrderRejectReasonExtra } from 'src/constants/OrderRejectReasonExtra';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { translate } from 'src/i18n/translate';
import { PotIcon } from 'src/icons/PotIcon';
import type { OrderVm } from 'src/types/OrderVm';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isNewOrder } from 'src/utils/order/isNewOrder';
import { isRejectedOrder } from 'src/utils/order/isRejectedOrder';
import { isRoomServiceOrder } from 'src/utils/order/isRoomServiceOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { orderIsInternalDeliveryOrder } from 'src/utils/order/orderIsInternalDeliveryOrder';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { translateOrderStatus } from 'src/utils/translate/translateOrderStatus';

export function OrderCardStatusInfo({ order, updateLoadingOrder, onError, onChange, disabled }: Props): React.ReactElement | null {
    const classes = useStyles();

    const markReadyForPickupButtonEnabled = useSelector((state) => state.app.restaurant.markReadyForPickupButtonEnabled);
    const externalDelivery = useSelector((state) => state.app.restaurant.externalDelivery);

    const restaurantUser = useSelector((state) => state.authentication.restaurantUser);
    const isOrderPrepared = order.kitchenStatus === KitchenStatuses.PREPARED;
    const isOrderPreparing = order.kitchenStatus === KitchenStatuses.PREPARING;
    const isOrderFinished = [OrderStatuses.REJECTED, OrderStatuses.COMPLETE, OrderStatuses.CANCELLED, OrderStatuses.RETURNED].includes(order.orderStatus as any);
    const isInternalDeliveryOrder = orderIsInternalDeliveryOrder(order);
    const displayKitchenIcon = order.kitchenStatus && !isOrderFinished;

    const getStatusColor = () => {
        switch (order.orderStatus) {
            case OrderStatuses.REJECTED:
                return '#E82D5A';
            case OrderStatuses.CANCELLED:
            case OrderStatuses.RETURNED:
                return '#E82D5A';
            case OrderStatuses.COMPLETE:
                return '#5268F4';
            case OrderStatuses.PENDING:
                return '#EC9C23';
            default:
                return '#6A7575';
        }
    };

    const getOrderStatusButtonLabel = () => {
        if (isDeliveryOrder(order.orderType) && !order.deliveryStatus) return translate('Looking for driver');
        if (order.arrivedAtClientAt) return translate(`OrderStatuses.${OrderStatuses.ARRIVED_AT_CLIENT}`);
        if ((isInternalDeliveryOrder || externalDelivery) && order.deliveryStatus) return translate(`DeliveryStatuses.${order.deliveryStatus}`);
        if (isEatHereOrder(order.orderType) && order.markedReadyForPickupAt) return translate('Order Ready');
        if (isEatHereOrder(order.orderType) && !order.markedReadyForPickupAt) return translate(`OrderStatuses.${OrderStatuses.ACCEPTED}`);
        if (isTakeAwayOrder(order.orderType) && !order.markedReadyForPickupAt) return translate(`OrderStatuses.${OrderStatuses.ACCEPTED}`);
        if (isRoomServiceOrder(order.orderType) && !order.markedReadyForPickupAt) return translate(`OrderStatuses.${OrderStatuses.ACCEPTED}`);
        if ((!isInternalDeliveryOrder && order.markedReadyForPickupAt) || !markReadyForPickupButtonEnabled) return translateOrderStatus(OrderStatuses.COMPLETED, order.orderType);
        return translate('Order Ready');
    };

    const getOrderStatusLabel = () => {
        if (!isRejectedOrder(order.orderStatus)) return translate(`OrderStatuses.${order.orderStatus}`);

        let rejectedOrderLabel = '';
        if (order.orderRejectReason) {
            rejectedOrderLabel = translate('. Reason: ') + translate(`OrderRejectReasons.${order.orderRejectReason ?? ''}`);
        }

        const showRejectOrderReasonExtras = !restaurantUser && order?.orderRejectReasonExtras?.length;
        const rejectedOrderReasonExtras = `${translate('Extras:')} ${
            order?.orderRejectReasonExtras?.map((orderRejectReasonExtra: OrderRejectReasonExtra) => translate(`OrderRejectReasonExtras.${orderRejectReasonExtra}`))?.join(', ') ?? ''
        }`;

        return `${translate(`OrderStatuses.${order.orderStatus}`)}${rejectedOrderLabel} ${showRejectOrderReasonExtras ? rejectedOrderReasonExtras : ''}`;
    };

    if (isNewOrder(order.orderStatus)) return null;

    if (isAcceptedOrder(order.orderStatus)) {
        return (
            <div className={classes.orderStatusContainer} style={{ flexDirection: 'column' }}>
                <span className={classes.orderStatus}>{getOrderStatusButtonLabel()}</span>
                <div className={classes.separator}></div>
            </div>
        );
    }

    return (
        <>
            {order.invoiceUruwareCreditNoteId && (
                <>
                    <UrugayanReturnedOrderStatus />
                    <div className={classes.separator}></div>
                </>
            )}
            {!order.invoiceUruwareCreditNoteId && (
                <div className={classNames({ [classes.orderStatusContainer]: !displayKitchenIcon }, { [classes.orderKitchenStatusContainer]: displayKitchenIcon })}>
                    <div className={classes.orderStatusDetails} style={{ color: getStatusColor() }}>
                        {isCompletedOrder(order.orderStatus) && <CircleCheckIcon />}
                        <span className={classes.orderStatus} style={{ color: getStatusColor() }}>
                            {getOrderStatusLabel()}
                        </span>
                    </div>
                    {displayKitchenIcon && (
                        <div className={classNames(isOrderPreparing ? classes.warningText : classes.orderPrepared)}>
                            <PotIcon color={isOrderPrepared ? '#03D09F' : '#EC9C23'} />
                            <span>{translate(`KitchenStatuses.${order.kitchenStatus ?? ''}`)}</span>
                        </div>
                    )}
                </div>
            )}
            <div className={classes.separator}></div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    separator: {
        height: '0px',
        width: '100%',
        border: '1px solid #F3F3F3',
        margin: '12px 0px',
    },
    buttonAccept: {
        width: '100%',
        height: 42,
        margin: '0 auto',
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main}aa`,
        },
    },
    orderStatusContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
    },
    orderKitchenStatusContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    orderStatus: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    orderPrepared: {
        color: '#03D09F',
    },
    warningText: {
        fontFamily: theme.typography.medium,
        fontSize: 14,
        color: theme.palette.warning.light,
    },
    orderStatusDetails: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 6,
    },
}));

type Props = {
    order: OrderVm;
    updateLoadingOrder: any;
    onError?: (arg1: string) => any;
    onChange?: (arg1: OrderVm) => any;
    disabled: boolean;
};
