/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RecipeId } from 'src/types/Id';

export async function removeRecipeApi(request: RemoveRecipeApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('recipe/removeRecipeApi', request);
}

export type RemoveRecipeApiRequest = {
    recipeId: RecipeId;
};
