/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { createUruguayanOrderInvoiceApi } from 'src/api/letseatmanager/invoice/createUruguayanOrderInvoiceApi';
import { findRestaurantInvoicesLegalInfoApi } from 'src/api/letseatmanager/invoice/findRestaurantInvoicesLegalInfoApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { DeprecatedFormFiscalEntitySelect } from 'src/components/form/DeprecatedFormFiscalEntitySelect';
import { DeprecatedFormRestaurantInvoiceBusinessNameAutoComplete } from 'src/components/form/DeprecatedFormRestaurantInvoiceBusinessNameAutoComplete';
import { DeprecatedFormRestaurantInvoiceFiscalIdAutocomplete } from 'src/components/form/DeprecatedFormRestaurantInvoiceFiscalIdAutocomplete';
import { Form } from 'src/components/form/Form';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormTextField } from 'src/components/form/FormTextField';
import { FiscalEntities, FiscalEntity } from 'src/constants/FiscalEntity';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import type { EmailAddress } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateUruguayanOrderInvoiceDialog({ open, order, onClose, onSuccess, defaultFiscalEntity }: Props): React.ReactElement {
    const classes = useStyles();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const form = useForm();
    const [printOrderTicket] = usePrintOrderTicket();

    const fiscalEntity = useWatch<FiscalEntity>({ name: 'fiscalEntity', control: form.control, defaultValue: defaultFiscalEntity ?? FiscalEntities.FISIC });

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    useEffect(() => {
        if (fiscalEntity === FiscalEntities.BUSINESS && order.customerRuc) loadInvoiceLegalInfo(order.customerRuc);
    }, [fiscalEntity]);

    const loadInvoiceLegalInfo = async (customerRuc: string) => {
        form.setValue('ruc', customerRuc);
        setLoading(true);
        const response = await findRestaurantInvoicesLegalInfoApi({ restaurantId, customerRuc: customerRuc });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        onSelectInvoiceLegalInfoAutocomplete(response.data);
    };

    const handleSubmit = async (form: FormValues) => {
        if (!order.paymentMethod) return;
        setLoading(true);
        const response = await createUruguayanOrderInvoiceApi({
            orderId: order.orderId,
            fiscalEntity: form.fiscalEntity,
            email: form.email,
            ruc: form.ruc,
            businessName: form.businessName,
            address: form.address,
            city: form.city,
        });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        onSuccess?.();
        handleClose();

        if (response.data) {
            const updatedOrder = response.data;
            await printOrderTicket(updatedOrder);
        }
    };

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const onSelectInvoiceLegalInfoAutocomplete = (invoiceLegalInfo: any) => {
        form.setValue('ruc', invoiceLegalInfo.fiscalId);
        form.setValue('businessName', invoiceLegalInfo.businessName);
        form.setValue('address', invoiceLegalInfo.address);
        form.setValue('city', invoiceLegalInfo.city);
    };

    return (
        <Dialog title={translate('Create order invoice')} open={open} onClose={handleClose}>
            <Form onSubmit={handleSubmit} form={form} className={(classes as any).form}>
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <DeprecatedFormFiscalEntitySelect
                                required
                                name='fiscalEntity'
                                label={translate('Select the Fiscal Entity')}
                                disabled={false}
                                defaultValue={defaultFiscalEntity ?? FiscalEntities.FISIC}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormEmailField name='email' label={translate('Email')} disabled={loading} />
                        </Grid>
                        {fiscalEntity === FiscalEntities.BUSINESS && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <DeprecatedFormRestaurantInvoiceFiscalIdAutocomplete
                                        name='ruc'
                                        required
                                        label={translate('RUC')}
                                        onSelect={onSelectInvoiceLegalInfoAutocomplete}
                                        disabled={loading}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DeprecatedFormRestaurantInvoiceBusinessNameAutoComplete
                                        name='businessName'
                                        required
                                        label={translate('Business Name')}
                                        onSelect={onSelectInvoiceLegalInfoAutocomplete}
                                        disabled={loading}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormTextField name='address' label={translate('Address')} required disabled={loading} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormTextField name='city' label={translate('City')} required disabled={loading} />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </div>
                <DialogActions className={classes.buttonsContainer}>
                    <Button secondary onClick={handleClose} disabled={loading} larger={posInterfaceLargerEnabled} classes={{ button: classes.button }}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading} larger={posInterfaceLargerEnabled} classes={{ button: classes.button }}>
                        {loading ? translate('Creating') : translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    buttonsContainer: {
        margin: '10px 0px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    button: {
        width: '50%',
    },
}));

type Props = {
    open: boolean;
    order: OrderVm;
    defaultFiscalEntity?: FiscalEntity;
    onClose: any;
    onSuccess?: any;
};

type FormValues = {
    fiscalEntity: FiscalEntity;
    email?: EmailAddress;
    ruc?: string;
    businessName?: string;
    address?: string;
    city?: string;
};
