/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { ClosedEyeIcon } from 'src/icons/ClosedEyeIcon';
import { NotesCheckIcon } from 'src/icons/NotesCheckIcon';
import { SettingsIcon } from 'src/icons/SettingsIcon';
import InventoryEmptyStateImage from 'src/images/inventoryEmptyStateImage.png';
import pideDirectoLogo from 'src/images/pidedirecto-logo.png';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { isMexico } from 'src/utils/country/isMexico';
import { useSelector } from 'src/utils/react/useSelector';

export function InventoryEmptyState(): React.ReactElement {
    const classes = useStyles();
    const restaurantCountry = useSelector((state) => state.app.restaurant.country);
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    return (
        <div className={classes.container}>
            <div className={classes.infoContainer}>
                <h1 className={classes.title}>{translate('Sync your inventory and your sales in one place')}</h1>
                <div className={classes.row}>
                    <img src={pideDirectoLogo} alt='PideDirecto logo' className={classes.logo} />
                </div>
                {isMexico(restaurantCountry) && (
                    <div className={classes.row}>
                        <span className={classes.textBold} style={{ marginRight: 5 }}>
                            {formatAsCurrencyNumber(100)}
                        </span>
                        <span className={classes.text}>/ {translate('month')}</span>
                    </div>
                )}
                <a className={classes.button}>{translate('Start trial')}</a>
                <ul className={classes.benefitsList}>
                    <li className={classes.benefit}>
                        <NotesCheckIcon />
                        <span className={classes.text} style={{ marginLeft: 10 }}>
                            {translate('Discount your sales automatically')}
                        </span>
                    </li>
                    <li className={classes.benefit}>
                        <NotesCheckIcon />
                        <span className={classes.text} style={{ marginLeft: 10 }}>
                            {translate('Track your inventory')}
                        </span>
                    </li>
                    <li className={classes.benefit} style={{ alignItems: 'flex-start' }}>
                        <SettingsIcon className={classes.icon} />
                        <span className={classes.text} style={{ marginLeft: 10 }}>
                            {translate('The inputs are discounted with the sales of the platforms that you manage')}
                        </span>
                    </li>
                    <li className={classes.benefit} style={{ alignItems: 'flex-start' }}>
                        <ClosedEyeIcon className={classes.icon} />
                        <span className={classes.text} style={{ marginLeft: 10 }}>
                            {translate('Disable products out of stock')}
                        </span>
                    </li>
                </ul>
            </div>
            <div className={classes.imageContainer}>
                <img src={InventoryEmptyStateImage} className={classes.image} alt='Iventory empty state' />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box',
        padding: '0 10%',
        marginTop: '2vh',
        justifyContent: 'space-between',
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 30,
        marginBottom: 5,
        maxWidth: '80%',
    },
    logo: {
        width: 25,
        marginRight: 15,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 30,
    },
    benefit: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 30,
        maxWidth: '60%',
        flexShrink: 0,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 18,
        margin: 0,
    },
    textBold: {
        fontFamily: theme.typography.bold,
        fontSize: 30,
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        borderRadius: 8,
        height: 60,
        width: '100%',
        maxWidth: '60%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        textDecoration: 'none',
        '& visited': {
            color: 'white',
        },
    },
    benefitsList: {
        listStyleType: 'none',
        marginTop: 30,
        padding: 0,
    },
    icon: {
        flexShrink: 0,
        marginTop: 3,
    },
    image: {
        flexShrink: 0,
        height: 300,
        marginBottom: 50,
        objectFit: 'cover',
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '40%',
    },
}));
