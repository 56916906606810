/**
 * @prettier
 */
import { FormSelect } from '@pidedirecto/ui/form';
import { useLoadApi } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { useEffect } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { translate } from 'src/i18n/translate';
import { setCompanyNameOptionsInLocalStorage } from 'src/localStorage/setCompanyNameOptionsInLocalStorage';

export function FormCompanySelect({ name, label, placeholder, helperText, defaultValue, disabled, required }: Props): React.ReactElement {
    const [loading, options] = useLoadApi(letseatmanagerApiDeprecated.admin.getCompanyNameOptions, {}, { initialValue: [] });

    useEffect(() => {
        if (options.length) {
            setCompanyNameOptionsInLocalStorage(options);
        }
    }, [options]);

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            disabled={disabled || loading}
            required={{
                value: !!required,
                message: translate('This field is required'),
            }}
            options={options}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?: boolean;
};
