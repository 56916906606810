/**
 * @prettier
 */
import { IconButton, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import PhoneIcon from '@material-ui/icons/Phone';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { liveDrivers } from 'src/reducers/liveDriversReducer';
import { fullName } from 'src/utils/customer/fullName';
import { toHumanizedOrderId } from 'src/utils/order/toHumanizedOrderId';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { openDriverPageInNewTab } from 'src/utils/window/openDriverPageInNewTab';
import { openOrderPageInNewTab } from 'src/utils/window/openOrderPageInNewTab';

export function DriverInfo(): React.ReactElement | null {
    const classes = useStyles();

    const showDriverInfo = useSelector((state) => state.liveDrivers.showDriverInfo);
    const driverInfo = useSelector((state) => state.liveDrivers.driverInfo);

    const clearDriverInfo = useAction(liveDrivers.actions.clearDriverInfo);

    if (!showDriverInfo) return null;

    return (
        <div className={classes.container}>
            <IconButton onClick={clearDriverInfo} className={classes.closeButton}>
                <CloseIcon className={classes.icon} />
            </IconButton>
            <div className={classes.nameContainer} onClick={() => openDriverPageInNewTab(driverInfo.driverId)}>
                <h1 className={classes.name}>{fullName(driverInfo)}</h1>
                <LinkIcon className={classes.icon} />
            </div>
            <a className={classes.mobileNumber} href={`tel:${driverInfo.mobileNumber}`}>
                <span style={{ marginRight: 10 }}>{driverInfo.mobileNumber}</span>
                <PhoneIcon />
            </a>

            <p className={classes.text} style={{ marginBottom: 2 }}>
                {translate('Number of orders: @numOrders', { numOrders: driverInfo.orderIds.length })}
            </p>
            <div className={classes.ordersContainer}>
                {driverInfo.orderIds.map((orderId) => (
                    <Button key={orderId} onClick={() => openOrderPageInNewTab(orderId)} className={classes.orderButton}>
                        #{toHumanizedOrderId(orderId)}
                    </Button>
                ))}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        backgroundColor: 'white',
        borderRadius: 10,
        bottom: '5vh',
        right: '5vw',
        width: '35vw',
        minHeight: '20vh',
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        paddingTop: 10,
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    nameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        width: 'fit-content',
    },
    name: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        margin: 0,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    mobileNumber: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        marginRight: 10,
        textDecoration: 'none',
        color: 'black',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    ordersContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
    },
    orderButton: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
    },
    icon: {
        marginLeft: 10,
    },
}));
