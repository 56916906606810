/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderReturnedType } from 'src/constants/OrderReturnedType';
import type { OrderId } from 'src/types/Id';
import type { OrderItemVm, OrderVm } from 'src/types/OrderVm';

export async function returnOrderApi(request: ReturnOrderApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('order/returnOrderApi', request);
}

export type ReturnOrderApiRequest = {
    orderId: OrderId;
    orderReturnedType: OrderReturnedType;
    orderReturnedReason?: string;
    orderItems?: Array<OrderItemVm>;
    returnedOrderItems?: Array<OrderItemVm>;
};
