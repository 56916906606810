/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { SectionTitle } from 'src/components/title/SectionTitle';
import { PosBusinessDaySections } from 'src/constants/PosBusinessDaySections';
import { translate } from 'src/i18n/translate';

export function PaymentReceivedInfoSettings({ disabled }: Props): React.ReactElement {
    return (
        <section>
            <SectionTitle title={translate('Payments received Information')} />
            <Grid container xs={12}>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={PosBusinessDaySections.PAYMENTS_RECEIVED} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.PAYMENTS_RECEIVED}`)} />
                </Grid>
            </Grid>
        </section>
    );
}

type Props = {
    disabled: boolean;
};
