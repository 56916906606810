/**
 * @prettier
 */
import { Divider, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changePosBusinessDaySectionsApi } from 'src/api/letseatmanager/restaurant/changePosBusinessDaySectionsApi';
import { Alert } from 'src/components/Alert';
import { Form } from 'src/components/form/Form';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { PosBusinessDaySections } from 'src/constants/PosBusinessDaySections';
import { translate } from 'src/i18n/translate';
import { CampaignIcon } from 'src/icons/CampaignIcon';
import { CashRegisterCountingInfoSettings } from 'src/scenes/letseatmanager/customizePrinters/posBusinessDay/settings/CashRegisterCountingInfoSettings';
import { CashRegisterMovementsInfoSettings } from 'src/scenes/letseatmanager/customizePrinters/posBusinessDay/settings/CashRegisterMovementsInfoSettings';
import { PaymentReceivedInfoSettings } from 'src/scenes/letseatmanager/customizePrinters/posBusinessDay/settings/PaymentReceivedInfoSettings';
import { PosBusinessDayGeneralInfoSettings } from 'src/scenes/letseatmanager/customizePrinters/posBusinessDay/settings/PosBusinessDayGeneralInfoSettings';
import { PosBusinessDayRestaurantInfoSettings } from 'src/scenes/letseatmanager/customizePrinters/posBusinessDay/settings/PosBusinessDayRestaurantInfoSettings';
import { PosBusinessDaySalesDetailInfoSettings } from 'src/scenes/letseatmanager/customizePrinters/posBusinessDay/settings/PosBusinessDaySalesDetailInfoSettings';
import { PrintPosBusinessDayReportTestButton } from 'src/scenes/letseatmanager/customizePrinters/posBusinessDay/settings/PrintPosBusinessDayReportTestButton';
import { ReactComponentPrinter } from 'src/services/printer/printers/ReactComponentPrinter';
import { createPosBusinessDayReportPrint } from 'src/services/printer/prints/createPosBusinessDayReportPrint';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getPosBusinessDayReportDemo } from 'src/utils/posBusinessDay/getPosBusinessDayReportDemo';
import { useSelector } from 'src/utils/react/useSelector';
import { getActivePosBusinessDaySections } from 'src/utils/ticketAndCommand/getActivePosBusinessDaySections';
import { mapPosBusinessDaySectionsToFormPosBusinessDaySections } from 'src/utils/ticketAndCommand/mapPosBusinessDaySectionsToFormPosBusinessDaySections';

export function PosBusinessDaySettings(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const { reloadRestaurant } = useReloadRestaurant();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurant = useSelector((state) => state.app.restaurant);

    const form = useForm({
        defaultValues: mapPosBusinessDaySectionsToFormPosBusinessDaySections(restaurant?.posBusinessDaySections),
    });

    const {
        watch,
        formState: { isDirty },
        reset,
    } = form;

    const formPosBusinessDaySections = watch(Object.keys(PosBusinessDaySections));

    const activePosBusinessDaySections = getActivePosBusinessDaySections(formPosBusinessDaySections);

    useEffect(() => {
        reset(mapPosBusinessDaySectionsToFormPosBusinessDaySections(restaurant?.posBusinessDaySections));
    }, [restaurant]);

    const handleSubmit = async () => {
        setLoading(true);
        const response = await changePosBusinessDaySectionsApi({
            restaurantId,
            posBusinessDaySections: activePosBusinessDaySections,
        });
        await reloadRestaurant(restaurantId);
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
        }
    };

    const handleCloseAlert = () => {
        reset(mapPosBusinessDaySectionsToFormPosBusinessDaySections(restaurant?.posBusinessDaySections));
    };

    return (
        <div className={classes.ticketTab}>
            {isDirty && (
                <Alert
                    icon={<CampaignIcon title={'campaign'} />}
                    onClose={handleCloseAlert}
                    title={translate('You have changes without updating')}
                    actionText={translate('Update')}
                    onActionClick={handleSubmit}
                />
            )}
            <section className={classes.header}>
                {loading && <UpdatingContentProgress loading={loading} />}
                <div>
                    <h1 className={classes.title}>{translate('Customize Pos Business Day Report Ticket')}</h1>
                    <div className={classes.subtitle}>{translate('Adds or removes information displayed when printing')}</div>
                </div>
                <PrintPosBusinessDayReportTestButton disabled={loading || isDirty} />
            </section>
            <section className={classes.main}>
                <Form className={classes.form} form={form}>
                    <PosBusinessDayRestaurantInfoSettings disabled={loading} />
                    <PosBusinessDayGeneralInfoSettings disabled={loading} />
                    <PaymentReceivedInfoSettings disabled={loading} />
                    <CashRegisterMovementsInfoSettings disabled={loading} />
                    <PosBusinessDaySalesDetailInfoSettings disabled={loading} />
                    <CashRegisterCountingInfoSettings disabled={loading} />
                </Form>
                <Divider variant='middle' classes={{ vertical: classes.divider }} orientation={'vertical'} />
                <div className={classes.ticketPreview}>
                    {ReactComponentPrinter.print(
                        createPosBusinessDayReportPrint({
                            posBusinessDay: getPosBusinessDayReportDemo(restaurant),
                            restaurant: { ...restaurant, posBusinessDaySections: activePosBusinessDaySections },
                        })
                    )}
                </div>
            </section>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    ticketTab: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: 80,
        [theme.breakpoints.down('md')]: {
            padding: '0 20px',
        },
    },
    ticketPreview: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        maxWidth: 420,
    },
    ticket: {
        maxWidth: 400,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px 25px',
        width: '90%',
        background: theme.palette.surface.primary,
        boxShadow: '-2px 4px 4px rgba(0,0,0,0.15), -4px 0px 20px rgba(0, 0, 0, 0.05)',
        color: 'black',
        fontSize: 12,
        marginTop: 40,
        fontFamily: theme.typography.regular,
        [theme.breakpoints.up('md')]: {
            fontSize: 14,
        },
    },
    title: {
        fontFamily: theme.typography.bold,
        color: '#616B79',
        fontSize: 20,
    },
    subtitle: {
        fontFamily: theme.typography.regular,
        color: '#616B79',
        fontSize: 16,
    },
    divider: {
        backgroundColor: theme.palette.surface.brand,
        width: 2,
        height: 'auto',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 20,
        },
    },
    main: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    form: {
        width: '100%',
    },
}));
