/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PosBusinessDayId } from 'src/types/Id';
import { OpenedCashRegisterPosBusinessDayVm } from 'src/types/OpenedCashRegisterPosBusinessDayVm';

export async function findOpenedCashRegistersPosBusinessDayByPosBusinessDayApi(
    request: FindOpenedCashRegistersPosBusinessDayByPosBusinessDayApiRequest
): ApiSauceResponse<findOpenedCashRegistersPosBusinessDayByPosBusinessDayApiResponse> {
    return letseatmanagerApiMethod('pos/findOpenedCashRegistersPosBusinessDayByPosBusinessDayApi', request);
}

export type FindOpenedCashRegistersPosBusinessDayByPosBusinessDayApiRequest = {
    posBusinessDayId: PosBusinessDayId;
};

type findOpenedCashRegistersPosBusinessDayByPosBusinessDayApiResponse = Array<OpenedCashRegisterPosBusinessDayVm>;
