/**
 * @prettier
 */
import { useEffect } from 'react';
import { useLoadMenuCategories } from 'src/services/menuCategory/useLoadMenuCategories';
import type { MenuCategoryVm as DeprecatedMenuCategoryVm } from 'src/types/DeprecatedMenuCategoryVm';
import type { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import { useSelector } from 'src/utils/react/useSelector';

export function useMenuCategories(): UseMenuCategories {
    const menuCategories = useSelector((state) => state.menu.menuCategories);
    const deprecatedMenuCategories = useSelector((state) => state.theMenu.menuCategories);
    const menuCategoriesLoaded = useSelector((state) => state.menu.menuCategoriesLoaded);
    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant.newMenuManagementEnabled);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const loadMenuCategories = useLoadMenuCategories();

    useEffect(() => {
        if (!menuCategories?.length && !menuCategoriesLoaded && newMenuManagementEnabled && restaurantId) {
            loadMenuCategories();
        }
    }, [newMenuManagementEnabled, menuCategories, menuCategoriesLoaded, restaurantId]);

    return {
        menuCategories,
        deprecatedMenuCategories: deprecatedMenuCategories ?? [],
    };
}

type UseMenuCategories = {
    menuCategories: Array<MenuCategoryVm>;
    deprecatedMenuCategories: Array<DeprecatedMenuCategoryVm>;
};
