/**
 * @prettier
 */
export const PaymentStatuses = {
    UNPAID: 'UNPAID',
    PAID: 'PAID',
    PAYED: 'PAYED', // TODO: Migrate to PAID
    REFUNDED: 'REFUNDED',
    FAILED: 'FAILED',
} as const;

export type PaymentStatus = typeof PaymentStatuses[keyof typeof PaymentStatuses];
