/**
 * @prettier
 */
import { translate } from 'src/i18n/translate';
import type { InstructionPrinter } from 'src/services/printer/printers/interfaces/InstructionPrinter';
import type { PrintColumn, PrinterInstruction } from 'src/services/printer/types/PrinterInstruction';
import type { PrinterVm } from 'src/types/PrinterVm';
import { lineWrapText } from 'src/utils/string/lineWrapText';
import { normalizeToAsciiCharacters } from 'src/utils/string/normalizeToAsciiCharacters';
import { padAround } from 'src/utils/string/padAround';
import { padBetween } from 'src/utils/string/padBetween';
import { padEnd } from 'src/utils/string/padEnd';
import { padStart } from 'src/utils/string/padStart';
import { pretty } from 'src/utils/string/pretty';

export class StringPrinter {
    static print(printerInstructions: Array<PrinterInstruction>, printer: PrinterVm): string {
        const stringPrinter = new StringPrinterImplementation(printer);

        // Add all printer instructions
        for (const printerInstruction of printerInstructions) {
            try {
                (stringPrinter as any)[printerInstruction.instructionType](...printerInstruction.params);
            } catch (e: any) {
                console.log(`instructionType ${printerInstruction.instructionType} not implemented by StringPrinterImplementation`);
                console.log(`Failed to print params=${pretty(printerInstruction.params)}`, e);
            }
        }

        const openCashbox = printerInstructions.some((pi) => pi.instructionType === 'openCashbox');
        return stringPrinter.print(openCashbox);
    }
}

class StringPrinterImplementation implements InstructionPrinter {
    #printer;
    #payload = '';

    constructor(printer: PrinterVm) {
        this.#printer = printer;
    }

    print(openCashbox: boolean): string {
        let header = '';
        header += `${padAround(` PRINTER `, CHARACTER_PER_ROW, '*')}\n`;
        header += `Printer Id: ${this.#printer.printerId ?? ''}\n`;
        header += `Printer Name: ${this.#printer.deviceName ?? ''}\n`;
        header += `Printer Content: ${this.#printer.printerContent ?? ''}\n`;
        header += `Printer Type: ${this.#printer.printerType ?? ''}\n`;
        header += `Printer Paper Size: ${this.#printer.printerPaperSize ?? ''}\n`;
        header += `${padAround(` PRINTED CONTENT `, CHARACTER_PER_ROW, '*')}\n`;

        this.#payload = `${header}${this.#payload}`;

        if (openCashbox) this.addCenteredText(`--- ${translate('opens your cash drawer')} ---`);
        this.#payload += '*'.repeat(CHARACTER_PER_ROW);

        return this.#payload;
    }

    addText(text: string): void {
        this.#payload += normalizeCharacters(`${lineWrapText(text, CHARACTER_PER_ROW)}\n`);
    }

    setLargerFont(): void {}

    setRegularFont(): void {}

    addBoldText(text: string): void {
        this.#payload += normalizeCharacters(`${bold(lineWrapText(text, CHARACTER_PER_ROW))}\n`);
    }

    addCenteredText(text: string): void {
        this.#payload += normalizeCharacters(
            `${
                lineWrapText(text, CHARACTER_PER_ROW)
                    .split('\n')
                    .map((t) => padAround(t, CHARACTER_PER_ROW))
                    .join('\n') ?? ''
            }\n`
        );
    }

    addCenteredBoldText(text: string): void {
        this.#payload += normalizeCharacters(
            `${
                lineWrapText(text, CHARACTER_PER_ROW)
                    .split('\n')
                    .map((t) => padAround(bold(t), CHARACTER_PER_ROW))
                    .join('\n') ?? ''
            }\n`
        );
    }

    addRightText(text: string): void {
        this.#payload += normalizeCharacters(
            `${
                lineWrapText(text, CHARACTER_PER_ROW)
                    .split('\n')
                    .map((t) => padStart(t, CHARACTER_PER_ROW))
                    .join('\n') ?? ''
            }\n`
        );
    }

    addBoldRightText(text: string): void {
        this.#payload += normalizeCharacters(
            `${
                lineWrapText(text, CHARACTER_PER_ROW)
                    .split('\n')
                    .map((t) => padStart(bold(t), CHARACTER_PER_ROW))
                    .join('\n') ?? ''
            }\n`
        );
    }

    addSeparatedTexts(leftText: string, rightText: string): void {
        this.#payload += normalizeCharacters(`${padBetween(leftText, rightText, CHARACTER_PER_ROW) ?? ''}\n`);
    }

    addSeparatedBoldLeftTexts(leftText: string, rightText: string): void {
        this.#payload += normalizeCharacters(`${padBetween(bold(leftText), rightText, CHARACTER_PER_ROW) ?? ''}\n`);
    }

    addBoldSeparatedTexts(leftText: string, rightText: string): void {
        this.#payload += normalizeCharacters(`${padBetween(bold(leftText), bold(rightText), CHARACTER_PER_ROW) ?? ''}\n`);
    }

    addColumns(columns: Array<PrintColumn>): void {
        this.#payload += normalizeCharacters(`${padColumns(columns, CHARACTER_PER_ROW) ?? ''}\n`);
    }

    addBoldColumns(columns: Array<PrintColumn>): void {
        const boldColumns = columns.map((c) => ({ ...c, text: bold(c.text) }));
        this.#payload += normalizeCharacters(`${padColumns(boldColumns, CHARACTER_PER_ROW) ?? ''}\n`);
    }

    addLogoImage(url: string): void {
        this.#payload += `Logo: ${url}\n`;
    }

    addQrCode(url: string): void {
        this.#payload += `QR Code: ${url}\n`;
    }

    addNewLine(): void {
        this.#payload += `\n`;
    }

    addCenteredUnderLine(): void {
        this.#payload += normalizeCharacters(`${padAround('_'.repeat(CHARACTER_PER_ROW - 10), CHARACTER_PER_ROW)}\n`);
    }

    addLineSeparator(): void {
        this.#payload += normalizeCharacters(`${'-'.repeat(CHARACTER_PER_ROW)}\n`);
    }

    openCashbox(): void {
        // Ignored since its handled in the print method instead
    }
}

function normalizeCharacters(text: string): string {
    // normalize spanish characters to ascii characters since it causes some issues on some printers,
    // in future try to set the character encoding on printers to add support for spanish characters
    return normalizeToAsciiCharacters(text);
}

export function padColumns(columns: Array<PrintColumn>, maxLength: number, fillString: string = ' '): string | undefined {
    let remainingPadding = maxLength;
    const result = columns.reduce((result, column, index) => {
        const last = index === columns.length - 1;
        if (last) {
            return `${result}${column.textAlign === 'left' ? padEnd(column.text, remainingPadding) : padStart(column.text, remainingPadding)}`;
        }

        const padding = Math.ceil(maxLength * column.percentageWidth);
        remainingPadding = remainingPadding - padding;
        return `${result}${column.textAlign === 'left' ? padEnd(column.text, padding) : padStart(column.text, padding)}`;
    }, '');
    return result;
}

function bold<T extends string | undefined>(text: T): T {
    if (text === undefined || text === null) return text;
    return `\u001b[1m${text}\u001b[0m` as any;
}

const CHARACTER_PER_ROW = 42;
