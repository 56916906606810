/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import { useMemo } from 'react';
import { PaymentMethod, PaymentMethods } from 'src/constants/PaymentMethod';
import { usePosPaymentMethods } from 'src/services/pos/posPaymentMethods/usePosPaymentMethods';
import { usePosPaymentMethodsDeprecated } from 'src/services/pos/posPaymentMethods/usePosPaymentMethodsDeprecated';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { useSelector } from 'src/utils/react/useSelector';
import { requirePaymentMethod } from 'src/utils/require/requirePaymentMethod';
import { requireValue } from 'src/utils/require/requireValue';

export function useOrderPaymentMethod(): PaymentMethod {
    const posPaymentMethodsDeprecated = usePosPaymentMethodsDeprecated();
    const posPaymentMethods = usePosPaymentMethods();

    const total = useSelector((state) => state.pos.payment?.total);
    const payments = useSelector((state) => state.pos.payments);
    const totalPaid = useSelector((state) => state.pos.totalPaid);
    const useLetsEatCredits = useSelector((state) => state.pos.useLetsEatCredits);
    const restaurantPaymentMethodsEnabled = useSelector((state) => state.pos.context?.restaurantPaymentMethodsEnabled);

    const hasPaymentMissing = BigNumber(totalPaid).isLessThan(total);

    const orderPaymentMethod = useMemo(() => {
        if (hasPaymentMissing) return PaymentMethods.PENDING;
        if (payments.length === 1 && Object.keys(PaymentMethods).includes(payments[0]?.paymentMethod) && !payments[0].customPaymentMethod) return requirePaymentMethod(payments[0].paymentMethod);
        if (payments.length === 1) {
            if (restaurantPaymentMethodsEnabled) {
                const posPaymentMethod = posPaymentMethods.find((customPaymentMethod) => customPaymentMethod.name === payments[0].customPaymentMethod);
                return requireValue(posPaymentMethod?.paymentMethod);
            }
            const posPaymentMethod = posPaymentMethodsDeprecated.find((customPaymentMethod) => customPaymentMethod.name === payments[0].customPaymentMethod);
            return requireValue(posPaymentMethod?.paymentMethod);
        }

        if (payments.some((posPayment) => isCardPayment(posPayment.paymentMethod))) return PaymentMethods.CREDIT_CARD;

        if (!hasPaymentMissing && useLetsEatCredits) return PaymentMethods.CREDIT_CARD;

        return PaymentMethods.CASH;
    }, [total, payments, totalPaid, useLetsEatCredits]);

    return orderPaymentMethod;
}
