/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentTerminalPaymentFailedReason } from 'src/constants/PaymentTerminalPaymentFailedReason';
import type { PaymentTerminalPaymentStatus } from 'src/constants/PaymentTerminalPaymentStatus';
import type { PaymentTerminalProvider } from 'src/constants/PaymentTerminalProvider';
import type { PaymentTerminalId, PaymentTerminalPaymentId } from 'src/types/Id';

export async function pollPaymentTerminalPaymentApi(request: Request): ApiSauceResponse<PollPaymentTerminalPaymentApiResponse> {
    return letseatmanagerApiMethod('paymentTerminalPayment/pollPaymentTerminalPaymentApi', request);
}

type Request = {
    paymentTerminalPaymentId: PaymentTerminalPaymentId;
};

export type PollPaymentTerminalPaymentApiResponse = {
    paymentTerminalPaymentStatus: PaymentTerminalPaymentStatus;
    paymentTerminalPaymentFailedReason?: PaymentTerminalPaymentFailedReason;
    message?: string;
    paymentTerminalId?: PaymentTerminalId;
    paymentTerminalProvider?: PaymentTerminalProvider;
};
