/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import { OrdersGlobalInvoiceVm } from 'src/types/OrdersGlobalInvoiceVm';

export async function getOrdersToInvoiceApi(request: GetOrdersToInvoiceApiRequest): ApiSauceResponse<OrdersGlobalInvoiceVm | undefined> {
    return letseatmanagerApiMethod('order/getOrdersToInvoiceApi', request);
}

export type GetOrdersToInvoiceApiRequest = {
    restaurantId: RestaurantId;
    from: Date;
    to: Date;
};
