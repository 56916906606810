/**
 * @prettier
 */

import { useMemo } from 'react';
import { usePosCartModifierGroups } from 'src/services/pos/posModifiersCart/usePosCartModifierGroups';
import { DeprecatedModifierGroupVm, DeprecatedModifierVm, ModifierGroupVm, ModifierVm } from 'src/types/PosMenuVm';

export function usePosCartModifierSections(): Array<ModifierSection> {
    const posCartModifierGroups = usePosCartModifierGroups();

    return useMemo(() => {
        return posCartModifierGroups.map((modifierGroup) => {
            return {
                key: modifierGroup.name,
                modifierGroup: modifierGroup,
                data: modifierGroup.modifiers,
            };
        });
    }, [posCartModifierGroups]);
}

export type ModifierSection = {
    key: string;
    modifierGroup: ModifierGroupVm | DeprecatedModifierGroupVm;
    data: Array<ModifierVm | DeprecatedModifierVm>;
};
