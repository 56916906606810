/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { RestaurantId } from 'src/types/Id';
import { OrderIncidentVm } from 'src/types/OrderIncidentVm';

export async function findOrderIncidentsByRestaurantApi(request: FindOrderIncidentsByRestaurantApiRequest): ApiSauceResponse<Array<OrderIncidentVm>> {
    return letseatadminApiMethod('orderIncident/findOrderIncidentsByRestaurantApi', request);
}

export type FindOrderIncidentsByRestaurantApiRequest = {
    restaurantId: RestaurantId;
    from: Date;
    to: Date;
};
