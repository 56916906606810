/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect } from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { CircleCloseIcon } from 'src/icons/CircleCloseIcon';
import { useClearCustomerInfo } from 'src/modules/pos/customer/useClearCustomerInfo';
import { posReducer } from 'src/reducers/posReducer';
import { PosPaymentMethodsSummary } from 'src/scenes/letseatmanager/pos/posPayment/PosPaymentMethodsSummary';
import { PosPaymentSummaryActions } from 'src/scenes/letseatmanager/pos/posPayment/PosPaymentSummaryActions';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { PosTip } from 'src/types/PosTip';
import { exchangeCurrencyAmount } from 'src/utils/order/exchangeCurrencyAmount';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useWatchWidth } from 'src/utils/react/useWatchWidth';

export function PosPaymentResume(): React.ReactElement {
    const classes = useStyles();
    const windowWidth = useWatchWidth();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const isLargeScreen = windowWidth >= 960;

    const clearCustomerInfo = useClearCustomerInfo();

    const posDiscount = useSelector((state) => state.pos.payment?.posDiscount);
    const openMobilePaymentResume = useSelector((state) => state.pos.openMobilePaymentResume);
    const { promoCodeDiscount } = useSelector((state) => state.pos.payment);
    const tips = useSelector((state) => state.pos.tips);
    const total = useSelector((state) => state.pos.payment?.total);
    const usedCustomerCredits = useSelector((state) => state.pos.usedCustomerCredits);
    const useLetsEatCredits = useSelector((state) => state.pos.useLetsEatCredits);
    const selectedCurrency = useSelector((state) => state.pos.selectedCurrency);

    const closePosPayment = useAction(posReducer.actions.closePosPayment);
    const setOpenMobilePaymentResume = useAction(posReducer.actions.setOpenMobilePaymentResume);

    const totalTips = tips?.reduce((acc: BigNumber, tip: PosTip) => acc.plus(BigNumber(tip.tipAmount ?? 0)), BigNumber(0));

    const totalWithoutPosDiscountAndTip = BigNumber(total)
        .plus(posDiscount ?? 0)
        .plus(useLetsEatCredits ? usedCustomerCredits : 0)
        .plus(promoCodeDiscount ?? 0)
        .minus(totalTips ?? 0)
        .toString();

    useEffect(() => {
        if (!isLargeScreen) {
            setOpenMobilePaymentResume(false);
        }
    }, [isLargeScreen]);

    const handleClose = () => {
        if (openMobilePaymentResume && !isLargeScreen) {
            setOpenMobilePaymentResume(false);
            return;
        }

        closePosPayment();
        clearCustomerInfo();
    };

    return (
        <div className={classNames(classes.orderResume, !openMobilePaymentResume && !isLargeScreen ? classes.hidden : '')}>
            <div className={classes.topSection}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
                    <h1 className={classes.totalTitle}>{translate('Total')}</h1>
                    <h2 className={classes.total}>{formatAsCurrencyNumber(exchangeCurrencyAmount(totalWithoutPosDiscountAndTip, selectedCurrency))}</h2>
                </div>
                <Button icon classes={{ button: (classes as any).backButton }} onClick={handleClose}>
                    <CircleCloseIcon title={translate('Close payment summary')} color={'#87888B'} size={32} />
                </Button>
            </div>
            <PosPaymentMethodsSummary />
            <PosPaymentSummaryActions />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    orderResume: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        zIndex: 98,
        borderRadius: 16,
        height: 'calc(100vh - 98px)',
        top: 98,
        backgroundColor: theme.palette.surface.primary,
        width: '100%',
        padding: '5px 15px',
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            padding: '20px 28px',
            height: 'calc(100vh - 70px)',
            top: 70,
        },
        [theme.breakpoints.up('md')]: {
            position: 'relative',
            width: '30%',
            height: '75vh',
            boxShadow: '0px 7px 8px rgba(0, 0, 0, 0.25)',
        },
        [theme.breakpoints.up('lg')]: {
            width: '25%',
        },
    },
    hidden: {
        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },
    },
    topSection: {
        width: '100%',
        display: 'flex',
        flexShrink: 1,
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    totalTitle: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        marginBottom: 0,
        textAlign: 'center',
        color: '#8C97AB',
    },
    total: {
        fontFamily: theme.typography.bold,
        fontSize: 24,
        color: '#2E3748',
        width: '100%',
        textAlign: 'center',
        margin: 0,
    },
}));
