/**
 * @prettier
 */
import { App, Apps } from 'src/constants/App';
import type { DidiFoodOrderId, RappiOrderId, UberEatsOrderId } from 'src/types/Id';
import { last5Characters } from 'src/utils/string/last5Characters';

export function formatShortExternalOrderId(order: { app?: App; rappiOrderId?: RappiOrderId; uberEatsOrderId?: UberEatsOrderId; didiFoodOrderId?: DidiFoodOrderId }): string | undefined {
    if (order.app === Apps.RAPPI && order.rappiOrderId) return `#${last5Characters(order.rappiOrderId)}`;
    if (order.app === Apps.DIDI_FOOD && order.didiFoodOrderId) return `#${last5Characters(order.didiFoodOrderId)}`;
    if (order.app === Apps.UBER_EATS && order.uberEatsOrderId) return `#${last5Characters(order.uberEatsOrderId)}`;
}
