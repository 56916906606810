/**
 * @prettier
 */
import { usePosCustomerActions } from 'src/modules/pos/customer/posCustomerStore';

export function useSetCustomerNote() {
    const setCustomerNote = usePosCustomerActions((actions) => actions.setCustomerNote);

    return setCustomerNote;
}
