/**
 * @prettier
 */
import * as React from 'react';

export function WithdrawalsIcon({ title }: Props): React.ReactElement {
    return (
        <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <circle cx='20' cy='20' r='20' fill='#F4D0E5' />
            <path
                d='M13.2429 13V13.6225C13.2429 13.7877 13.1774 13.946 13.0609 14.0627C12.9443 14.1795 12.7863 14.2451 12.6215 14.2451H12M12 14.2451V13.9338C12 13.4183 12.4176 13 12.9322 13H26.9148M12 14.2451V21.7156M26.9148 13V13.6225C26.9148 13.9662 27.1932 14.2451 27.5363 14.2451H28.1577M26.9148 13H27.2255C27.7401 13 28.1577 13.4183 28.1577 13.9338V22.0269C28.1577 22.5424 27.7401 22.9607 27.2255 22.9607H26.9148M12 21.7156V22.0269C12 22.2746 12.0982 22.5121 12.273 22.6872C12.4478 22.8623 12.6849 22.9607 12.9322 22.9607H13.2429M12 21.7156H12.6215C12.7863 21.7156 12.9443 21.7812 13.0609 21.898C13.1774 22.0147 13.2429 22.1731 13.2429 22.3382V22.9607M26.9148 22.9607V22.3382C26.9148 22.1731 26.9803 22.0147 27.0968 21.898C27.2134 21.7812 27.3714 21.7156 27.5363 21.7156H28.1577M26.9148 22.9607H25.5957M13.2429 22.9607H22.0261M25.876 24.9803L23.8563 27M23.8563 27L21.8366 24.9803M23.8563 27V20.9409M22.5647 17.9804C22.5647 18.6408 22.3028 19.2742 21.8366 19.7412C21.3704 20.2082 20.7381 20.4705 20.0789 20.4705C19.4196 20.4705 18.7873 20.2082 18.3211 19.7412C17.855 19.2742 17.5931 18.6408 17.5931 17.9804C17.5931 17.3199 17.855 16.6865 18.3211 16.2195C18.7873 15.7525 19.4196 15.4902 20.0789 15.4902C20.7381 15.4902 21.3704 15.7525 21.8366 16.2195C22.3028 16.6865 22.5647 17.3199 22.5647 17.9804ZM25.0505 17.9804H25.0571V17.987H25.0505V17.9804ZM15.1073 17.9804H15.1139V17.987H15.1073V17.9804Z'
                stroke='#9E0059'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    title?: string;
};
