/**
 * @prettier
 */
export const ExternalDeliveryProviders = {
    UBER_EATS: 'UBER_EATS',
    UBER_DIRECT: 'UBER_DIRECT',
    UBER_DAAS: 'UBER_DAAS',
    RESTAURANT: 'RESTAURANT',
    RAPPI_CARGO: 'RAPPI_CARGO',
} as const;

export type ExternalDeliveryProvider = typeof ExternalDeliveryProviders[keyof typeof ExternalDeliveryProviders];
