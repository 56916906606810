/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentLinkStatus } from 'src/constants/PaymentLinkStatus';
import type { OrderId, PaymentLinkId, RestaurantId } from 'src/types/Id';

export async function createPaymentLinkApi(request: CreatePaymentLinkApiRequest): ApiSauceResponse<PaymentLinkVm> {
    return letseatmanagerApiMethod('paymentLink/createPaymentLinkApi', request);
}

export type CreatePaymentLinkApiRequest = {
    amount: number;
    restaurantId: RestaurantId;
    orderId?: OrderId;
};

export type PaymentLinkVm = {
    paymentLinkId: PaymentLinkId;
    restaurantId: RestaurantId;
    orderId?: OrderId;
    amount: number;
    status: PaymentLinkStatus;
    url: string;
    createdAt: Date;
    paidAt?: Date;
};
