/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect } from 'react';
import { Button } from 'src/components/Button';
import { Currency } from 'src/constants/Currency';
import { posReducer } from 'src/reducers/posReducer';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import { PosPayment } from 'src/types/PosPayment';
import { RestaurantCurrency } from 'src/types/RestaurantCurrency';
import { exchangeCurrencyAmount } from 'src/utils/order/exchangeCurrencyAmount';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PosCurrencies(): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const mainCurrency = useSelector((state) => state.app.restaurant?.currencyFormat?.currency);
    const selectedCurrency = useSelector((state) => state.pos.selectedCurrency);
    const currencies = useSelector((state) => state.pos.context?.currencies);
    const payments = useSelector((state) => state.pos.payments);
    const acceptMultipleCurrenciesPaymentEnabled = useSelector((state) => state.pos.context?.acceptMultipleCurrenciesPaymentEnabled);
    const total = useSelector((state) => state.pos.payment?.total);
    const totalPaid = useSelector((state) => state.pos.totalPaid);
    const customers = useSelector((state) => state.pos.customers);
    const customerNumberSelected = useSelector((state) => state.pos.customerNumberSelected);

    const setSelectedCurrency = useAction(posReducer.actions.setSelectedCurrency);
    const setNumericPadValue = useAction(posReducer.actions.setNumericPadValue);

    const currenciesWithMainCurrency = [{ currency: mainCurrency, exchangeRate: '1' }, ...(currencies ?? [])];
    const hasCardPayment = payments?.some((payment: PosPayment) => isCardPayment(payment.paymentMethod));
    const selectedCustomer = customers?.find((customer: PosCustomerOrderDetails) => customer.customerNumber === customerNumberSelected);
    const missingCustomerPayment = BigNumber(selectedCustomer?.payment?.total ?? 0)
        .minus(selectedCustomer?.payment?.totalPaid ?? 0)
        .toNumber();
    const missingPayment = selectedCustomer ? missingCustomerPayment : BigNumber(total).minus(totalPaid).toString();

    useEffect(() => {
        if (mainCurrency) {
            setSelectedCurrency({
                currency: mainCurrency,
                exchangeRate: '1',
            });
        }
    }, [mainCurrency]);

    if (!currencies?.length || !acceptMultipleCurrenciesPaymentEnabled) return null;

    return (
        <div className={classes.container}>
            {currenciesWithMainCurrency?.map(
                (
                    currency:
                        | RestaurantCurrency
                        | {
                              currency: Currency;
                              exchangeRate: string;
                          }
                ) => {
                    const isSelectedCurrency = selectedCurrency?.currency === currency.currency;
                    const isMainCurrency = mainCurrency === currency.currency;
                    const disabledCurrency = hasCardPayment && !isMainCurrency;
                    const exchangeCurrencyMissingPayment = BigNumber(exchangeCurrencyAmount(missingPayment, currency)).isLessThan(0) ? '0' : exchangeCurrencyAmount(missingPayment, currency);

                    return (
                        <Button
                            key={currency.currency}
                            outlined
                            classes={{ button: classNames(classes.currency, { [classes.selectedCurrency]: isSelectedCurrency }) }}
                            onClick={() => {
                                setNumericPadValue(exchangeCurrencyMissingPayment);
                                setSelectedCurrency(currency);
                            }}
                            disabled={disabledCurrency}
                        >
                            <div className={classNames(classes.label, { [classes.selectedLabel]: isSelectedCurrency })}>{currency.currency}</div>
                            <div className={classNames(classes.exchange, { [classes.selectedExchange]: isSelectedCurrency })}>{formatAsCurrencyNumber(exchangeCurrencyMissingPayment)}</div>
                        </Button>
                    );
                }
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        gap: 10,
        fontFamily: theme.typography.regular,
        width: '100%',
        overflowX: 'scroll',
        scrollbarWidth: 'none',
        margin: '10px 0px',
        minHeight: 50,
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    currency: {
        display: 'inline-block',
        padding: '4px 0px',
        minWidth: '74px',
        border: `1px solid ${theme.palette.border.primary}`,
        flexDirection: 'column',
        gap: 0,
        borderRadius: 10,
        '&:focus': {
            outline: 'unset',
        },
        '&:hover': {
            border: `1px solid ${theme.palette.surface.brand}`,
        },
    },
    selectedCurrency: {
        backgroundColor: theme.palette.surface.brand,
        borderColor: theme.palette.surface.brand,
    },
    exchange: {
        color: theme.palette.text.secondary,
        fontSize: 12,
    },
    selectedExchange: {
        color: theme.palette.text.brand,
    },
    label: {
        color: theme.palette.text.primary,
        fontSize: 14,
    },
    selectedLabel: {
        color: theme.palette.text.brand,
        fontFamily: theme.typography.bold,
    },
}));
