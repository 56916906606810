/**
 * @prettier
 */
import { FormControlLabel, FormGroup, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Switch } from '@pidedirecto/ui';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { findSubscriptionsApi } from 'src/api/letseatadmin/subscription/findSubscriptionsApi';
import { FilterState, FromToPaymentDateFilter } from 'src/components/input/FromToPaymentDateFilter';
import { Page } from 'src/components/Page';
import { PasswordRequiredContent } from 'src/components/PasswordRequiredContent';
import { Tabs } from 'src/components/Tabs';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { SubscriptionPlan, SubscriptionPlans } from 'src/constants/SubscriptionPlan';
import { SubscriptionStatus, SubscriptionStatuses } from 'src/constants/SubscriptionStatus';
import { translate } from 'src/i18n/translate';
import { CreateSubscriptionDialog } from 'src/scenes/letseatadmin/subscription/CreateSubscriptionDialog';
import { SubscriptionsTable } from 'src/scenes/letseatadmin/subscriptions/SubscriptionsTable';
import { useUnzipLoadApi } from 'src/utils/react/useUnzipLoadApi';

export function SubscriptionsPage(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [noDateInvoiceConcept, setNoDateInvoiceConcept] = useState(false);
    const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<SubscriptionPlan | undefined>();
    const [selectedSubscriptionStatus, setSelectedSubscriptionStatus] = useState<SubscriptionStatus | undefined>(SubscriptionStatuses.PENDING);
    const [createSubscriptionDialogState, setCreateSubscriptionDialogState] = useState({ open: false });
    const [filter, setFilter] = useState<FilterState>({
        from: moment().subtract(1, 'week').startOf('isoWeek').toDate(),
        to: moment().subtract(1, 'week').endOf('isoWeek').toDate(),
        paymentDate: moment().isoWeekday(2).startOf('day').toDate(), // thursday
    });

    const [loadingSubscriptions, subscriptionsVm, load] = useUnzipLoadApi(
        findSubscriptionsApi,
        {
            from: moment(filter.from).format('YYYY-MM-DD'),
            to: moment(filter.to).format('YYYY-MM-DD'),
            status: selectedSubscriptionStatus,
            subscriptionPlan: selectedSubscriptionPlan,
        },
        { dependencies: [filter.from, filter.to, selectedSubscriptionStatus] }
    );

    const subscriptionTabNames: Array<SubscriptionStatus | SubscriptionPlan> = [
        SubscriptionStatuses.NEW,
        SubscriptionStatuses.PAID,
        SubscriptionStatuses.PENDING,
        SubscriptionStatuses.CANCELLED,
        SubscriptionStatuses.TEST,
        SubscriptionStatuses.INACTIVE,
        SubscriptionPlans.PAY_PER_USE,
    ];

    const tabs = subscriptionTabNames.map((subscriptionTabName: SubscriptionStatus | SubscriptionPlan) => ({
        value: subscriptionTabName,
        label: translate(`SubscriptionTabName.${subscriptionTabName}`),
        onClick: () => {
            if (subscriptionTabName === SubscriptionPlans.PAY_PER_USE) {
                setSelectedSubscriptionPlan(subscriptionTabName);
                return setSelectedSubscriptionStatus(undefined);
            }

            if (!!selectedSubscriptionPlan) {
                setSelectedSubscriptionPlan(undefined);
            }

            return setSelectedSubscriptionStatus(subscriptionTabName as SubscriptionStatus);
        },
    }));

    const handleClickNew = () => {
        setCreateSubscriptionDialogState({ open: true });
    };

    const toggleNoDateInvoiceConcept = () => {
        setNoDateInvoiceConcept(!noDateInvoiceConcept);
    };

    const onChangeFilter = (value: any) => setFilter(value);

    return (
        <PasswordRequiredContent customPassword={'7913'}>
            <Page title={translate('Subscriptions')} className={classes.container}>
                <UpdatingContentProgress loading={loadingSubscriptions ?? loading} />
                <CreateSubscriptionDialog open={createSubscriptionDialogState.open} onClose={() => setCreateSubscriptionDialogState({ open: false })} onSubscriptionCreated={load} />
                <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.filtersContainer}>
                        <FromToPaymentDateFilter filter={filter} onChangeFilter={setFilter} />
                    </Grid>
                    <Grid container direction='row' justify='flex-end'>
                        <FormGroup>
                            <FormControlLabel
                                style={{ display: 'flex', gap: 12 }}
                                control={<Switch onChange={toggleNoDateInvoiceConcept} checked={noDateInvoiceConcept} />}
                                label={translate('No date Invoices')}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Tabs defaultValue={selectedSubscriptionStatus} tabs={tabs}>
                            {subscriptionTabNames.map(() => (
                                <SubscriptionsTable
                                    onLoad={setLoading}
                                    subscriptions={subscriptionsVm?.subscriptions ?? []}
                                    onRefresh={load}
                                    onCreateSubscription={handleClickNew}
                                    filter={filter}
                                    onChangeFilter={onChangeFilter}
                                    noDateInvoiceConcept={noDateInvoiceConcept}
                                />
                            ))}
                        </Tabs>
                    </Grid>
                </Grid>
            </Page>
        </PasswordRequiredContent>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    filtersContainer: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
    tabsContainer: {
        height: '30px',
        marginBottom: '20px',
    },
}));
