/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { FiscalEntity } from 'src/constants/FiscalEntity';
import type { EmailAddress, OrderId } from 'src/types/Id';
import { OrderVm } from 'src/types/OrderVm';

export async function createUruguayanOrderInvoiceApi(request: CreateUruguayanOrderInvoiceApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('invoice/createUruguayanOrderInvoiceApi', request);
}

export type CreateUruguayanOrderInvoiceApiRequest = {
    orderId: OrderId;
    email?: EmailAddress;
    fiscalEntity: FiscalEntity;
    ruc?: string;
    businessName?: string;
    address?: string;
    city?: string;
};
