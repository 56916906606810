/**
 * @prettier
 */
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { app2 } from 'src/app2';
import type { RoutePath } from 'src/constants/RoutePath';
import { useAction } from 'src/utils/react/useAction';

export function AppMenuLink({ routePath, text, onClick, children, 'data-testid': dataTestid, className }: Props): React.ReactElement {
    const location = useLocation();

    const closeAppMenu = useAction(app2.actions.closeAppMenu);

    const handleClick = () => {
        closeAppMenu();
        onClick?.();
    };

    return (
        <ListItem
            classes={className as any}
            button
            component={ForwardRefNavLink}
            to={{
                pathname: routePath,
                search: location.search,
            }}
            onClick={handleClick}
            data-testid={dataTestid}
        >
            {children}
            <ListItemText primary={text} />
        </ListItem>
    );
}

// required for react-router-dom < 6.0.0
// see https://github.com/ReactTraining/react-router/issues/6056#issuecomment-435524678
const ForwardRefNavLink = React.forwardRef<HTMLAnchorElement, React.ComponentProps<NavLink>>((props, ref) => <NavLink innerRef={ref} {...props} />);

type Props = {
    routePath: RoutePath;
    text: string;
    className?: string;
    onClick?: any;
    ['data-testid']?: string;
    children?: React.ReactNode;
};
