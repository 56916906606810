/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { ClipboardsIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function MenuCategoriesInMenuEmptyEstate(): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.iconContainer}>
                <ClipboardsIcon title={'clipboard list'} size={30} />
            </div>
            <p className={classes.text}>{translate('On this section you can manage the different categories in your menus')}</p>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    iconContainer: {
        width: 120,
        height: 120,
        borderRadius: 120,
        backgroundColor: `${theme.palette.surface.brand}63`,
        color: theme.palette.text.brand,
        marginBottom: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '80%',
        textAlign: 'center',
        margin: '20px 0',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
}));
