/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { UberEatsStoreOfflineReason } from 'src/constants/UberEatsStoreOfflineReason';
import type { RestaurantId } from 'src/types/Id';

export async function closeUberEatsStoreApi(request: CloseUberEatsApiRequest): ApiSauceResponse<CloseUberEatsStoreApiResponse> {
    return letseatmanagerApiMethod('integration/closeUberEatsStoreApi', request);
}

type CloseUberEatsApiRequest = {
    restaurantId: RestaurantId;
};

type CloseUberEatsStoreApiResponse = {
    uberEatsStoreOnline?: boolean;
    uberEatsStoreOfflineReason?: UberEatsStoreOfflineReason;
    uberEatsRdOptionalDisabled?: boolean;
    uberEatsStorePausedByUber?: boolean;
};
