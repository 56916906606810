/**
 * @prettier
 */
import { Grid, makeStyles } from '@material-ui/core';
import { Select } from '@pidedirecto/ui';
import moment from 'moment-timezone';
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { getSupplyHistoryReportApi } from 'src/api/letseatmanager/inventory/getSupplyHistoryReportApi';
import { translate } from 'src/i18n/translate';
import { InventoryKpi } from 'src/scenes/letseatmanager/inventory/inventory/InventoryKpi';
import { SupplyConsumptionLineGraph } from 'src/scenes/letseatmanager/inventory/supplyHistoryReport/SupplyConsumptionLineGraph';
import { SupplyExistenceLineGraph } from 'src/scenes/letseatmanager/inventory/supplyHistoryReport/SupplyExistenceLineGraph';
import { SupplyPurchaseCostLineGraph } from 'src/scenes/letseatmanager/inventory/supplyHistoryReport/SupplyPurchaseCostLineGraph';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { SupplyHistoryTransactionsTable } from 'src/scenes/letseatmanager/supplyHistoryReport/SupplyHistoryTransactionsTable';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { SupplyHistoryReportVm } from 'src/types/SupplyHistoryReportVm';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { translateUnits } from 'src/utils/translate/translateUnits';

export function InventorySupplyHistoryReport(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const period = useSelector((state) => state.app2.filterReportsState.period);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [selectedSupply, setSelectedSupply] = useState<keyof SupplyHistoryReportVm>();

    const [loading, supplyHistoryReports] = useLoadApi(
        getSupplyHistoryReportApi,
        {
            restaurantIds: [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).add(1, 'day').toISOString(),
        },
        {
            initialValue: [],
            dependencies: [restaurantId, period?.from, period?.to],
        }
    );

    useEffect(() => {
        if (supplyHistoryReports) {
            Object.keys(supplyHistoryReports);
            setSelectedSupply(Object.keys(supplyHistoryReports)?.[0]);
        }
    }, [supplyHistoryReports]);

    const selectOptions = Object.keys(supplyHistoryReports).map((name) => ({
        label: name,
        value: name,
    }));

    const handleChangeSelectSupply = (supply: any) => {
        setSelectedSupply(supply);
    };

    return (
        <div className={classes.container}>
            <div className={classes.filterContainer}>
                <FilterReports />
                <Grid item xs={6} className={classes.supplyFilters}>
                    <Select value={selectedSupply} label={translate('Supply')} name='Supply' placeholder={translate('Supply')} options={selectOptions} onChange={handleChangeSelectSupply} />
                </Grid>
            </div>

            {selectedSupply && (
                <Grid container spacing={10} className={classes.lineGraphs}>
                    <Grid item xs={9}>
                        <SupplyExistenceLineGraph supplyDaysReport={supplyHistoryReports[selectedSupply]?.days} />
                    </Grid>
                    <Grid item xs={3} className={classes.centered}>
                        <InventoryKpi
                            kpi={`${translateUnits(supplyHistoryReports[selectedSupply]?.kpis.averageStockByDay, supplyHistoryReports[selectedSupply]?.kpis.unitOfMeasurement)}`}
                            label={translate('Average daily existence')}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <SupplyConsumptionLineGraph supplyDaysReport={supplyHistoryReports[selectedSupply]?.days} />
                    </Grid>
                    <Grid item xs={3} className={classes.centered}>
                        <InventoryKpi
                            kpi={`${translateUnits(supplyHistoryReports[selectedSupply]?.kpis.averageConsumptionByDay, supplyHistoryReports[selectedSupply]?.kpis.unitOfMeasurement)}`}
                            label={translate('Average daily consumption')}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <SupplyPurchaseCostLineGraph supplyDaysReport={supplyHistoryReports[selectedSupply]?.days} />
                    </Grid>
                    <Grid item xs={3} className={classes.centered}>
                        <InventoryKpi kpi={`${formatAsCurrencyNumber(supplyHistoryReports[selectedSupply]?.kpis.fixedCost)}`} label={translate('Average purchase cost in period')} />
                    </Grid>
                </Grid>
            )}
            {!selectedSupply && (
                <div className={classes.centered}>
                    <p className={classes.label}>{translate('Select a supply to see its history')}</p>
                </div>
            )}
            {selectedSupply && (
                <div>
                    <h2 className={classes.title}>{translate('Transactions')}</h2>
                    <SupplyHistoryTransactionsTable supplyTransactions={selectedSupply ? supplyHistoryReports[selectedSupply]?.transactions : []} loading={loading} />
                </div>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        paddingBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
    },
    centered: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    label: {
        fontSize: 14,
        color: theme.palette.text.primary,
        fontFamily: theme.typography.regular,
        whiteSpace: 'no-wrap' as any,
        marginBottom: 6,
        display: 'flex',
        alignItems: 'center',
        gap: 6,
    },
    lineGraphs: {
        marginTop: 35,
        marginBottom: 50,
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        marginBottom: 10,
        fontWeight: 500,
        color: theme.palette.text.primary,
    },
    select: {
        width: 200,
        marginTop: 10,
    },
    supplyFilters: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        gap: 10,
        minWidth: 300,
    },
    filterContainer: {
        paddingTop: 20,
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
