/**
 * @prettier
 */
import { Apps } from 'src/constants/App';
import { CardTypes } from 'src/constants/CardType';
import { CashRegisterDepositReasons } from 'src/constants/CashRegisterDepositReason';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { OrderTypes } from 'src/constants/OrderType';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { RewardTypes } from 'src/constants/RewardType';
import { OrderId, PromoCodeId } from 'src/types/Id';
import {
    CancelledOrdersReport,
    CashRegisterSummary,
    CashRegisterTransaction,
    CreditCardOrdersReport,
    DiscountsBreakdownReport,
    IntegrationOrdersReport,
    PaymentMethodReport,
    PosBusinessDayCashRegisterPosBusinessDaysSummary,
    PosBusinessDayPaymentMethodsBreakdownReport,
    PosBusinessDayPaymentsSummary,
    PosBusinessDayReportVm,
    PosBusinessDaySummary,
    PromoCodeReport,
    SalesByAppReport,
    SalesByItemReport,
    SalesByManagerUserReport,
    SalesByOrderTypeReport,
} from 'src/types/PosBusinessDayReportVm';
import type { RestaurantVm } from 'src/types/RestaurantVm';
import { newUuid } from 'src/utils/uuid/newUuid';
import { toShortId } from 'src/utils/uuid/toShortId';

export const posBusinessDaySummary: PosBusinessDaySummary = {
    openedBy: 'Usuario matutino',
    openedAt: new Date(),
    closedAt: new Date(),
    closedBy: 'Usuario vespertino',
    closedByDevice: 'POS Device',
    grossSales: '15000',
    totalDeliveryCosts: '300',
    totalDiscounts: '200',
    totalSales: '15000',
    initialOrderId: toShortId(newUuid()) as OrderId,
    finalOrderId: toShortId(newUuid()) as OrderId,
    orders: '120',
    averageTicket: '300',
    diners: '200',
    averageSalesByConsumer: '500',
    totalCancelledSales: '400',
    totalCancelledOrders: '10',
    totalUsedCredits: '0',
    totalCommission: '100',
    initialAmount: '1000',
};

export const posBusinessDayPaymentsSummary: PosBusinessDayPaymentsSummary = {
    totalCashReceived: { orders: '10', total: '10000' },
    totalCashChange: '100',
    totalPosAndKioskCreditCardReceived: { orders: '10', total: '10000' },
    totalOnlineCreditCardReceived: { orders: '10', total: '10000' },
    totalCustomPaymentMethodsReceived: [{ customPaymentMethod: 'Pago custom', orders: '10', total: '1000' }],
    totalIntegrationsReceived: { orders: '10', total: '10000' },
    grandTotal: {
        total: '15000',
        orders: '120',
        diners: '200',
        totalTips: '10',
        averageSalesByConsumer: '500',
        totalCommissions: '100',
        totalDiscounts: '20',
    },
};

export const posBusinessDayCashRegisterTransactionSummary = {
    initialAmount: '100',
    cashSales: '10000',
    deposits: '10100',
    withdraws: '10000',
    totalCashTips: '300',
    totalCreditCardTips: '500',
    totalCustomPaymentMethodsTips: '400',
    totalCash: '15000',
} as const;

export const cashRegisterTransactions: Array<CashRegisterTransaction> = [
    {
        managerUserName: 'Cajero',
        cashRegisterPosBusinessDayNumber: '01',
        createdAt: new Date(),
        amount: '300',
        reason: CashRegisterDepositReasons.CHANGE,
        comment: 'deuda pagada',
    },
];

export const paymentMethodReports: Array<PaymentMethodReport> = [{ paymentMethod: PaymentMethods.CASH, amount: 300, expectedAmount: 400 }];

export const cashRegistersSummaries: Array<CashRegisterSummary> = [
    {
        cashRegisterPosBusinessDayNumber: '01',
        closedAt: new Date(),
        closedBy: 'Cajero',
        totalSales: '10000',
        initialAmount: '100',
        diners: '100',
        amount: '10000',
        deposits: '300',
        withdraws: '-200',
        paymentMethodReports,
    },
];

export const posBusinessDayPaymentMethodsBreakdown: PosBusinessDayPaymentMethodsBreakdownReport = {
    posBusinessDayPaymentMethodBreakdown: [{ paymentMethod: PaymentMethods.CASH, app: Apps.PIDEDIRECTOPOS, total: '10000', totalTips: '500', salesPercentage: '0.6', orders: '120' }],
    integrationsBreakdown: [],
    grandTotal: {
        total: '15000',
        orders: '120',
        diners: '200',
        totalTips: '10',
        averageSalesByConsumer: '500',
        totalCommissions: '100',
        totalDiscounts: '20',
    },
};

export const creditCardOrders: CreditCardOrdersReport = {
    creditCardOrders: [{ orderId: toShortId(newUuid()) as OrderId, cardType: CardTypes.CREDIT, reference: '', total: '1000', tip: '100' }],
    grandTotal: {
        total: '1000',
        orders: '1',
        diners: '1',
        totalTips: '100',
        averageSalesByConsumer: '1000',
        totalCommissions: '0',
        totalDiscounts: '20',
    },
};

export const integrationOrdersReport: IntegrationOrdersReport = {
    integrationOrders: [
        {
            orderId: toShortId(newUuid()) as OrderId,
            integration: Apps.RAPPI,
            total: '400',
        },
    ],
    grandTotal: {
        total: '400',
        orders: '1',
        diners: '3',
        totalTips: '10',
        averageSalesByConsumer: '400',
        totalCommissions: '100',
        totalDiscounts: '20',
    },
};

export const salesByOrderTypeReport: SalesByOrderTypeReport = {
    salesByOrderType: [
        {
            orderType: OrderTypes.TAKE_AWAY_ORDER,
            orders: '10',
            diners: '30',
            averageSalesByConsumer: '300',
            salesPercentage: '0.1',
            total: '4000',
        },
    ],
    grandTotal: {
        total: '40000',
        orders: '10',
        diners: '30',
        averageSalesByConsumer: '300',
    },
};

export const salesByAppReport: SalesByAppReport = {
    salesByApp: [
        {
            app: Apps.PIDEDIRECTOPOS,
            orders: '40',
            diners: '80',
            averageSalesByConsumer: '300',
            salesPercentage: '0.5',
            total: '10000',
        },
    ],
    grandTotal: {
        total: '10000',
        orders: '40',
        diners: '80',
        averageSalesByConsumer: '300',
    },
};

export const salesByManagerUserReport: SalesByManagerUserReport = {
    salesByManagerUser: [
        {
            managerUser: 'Cajero matutino',
            orders: '20',
            diners: '30',
            averageSalesByConsumer: '250',
            total: '3500',
            totalTips: '235',
        },
    ],
    grandTotal: {
        total: '3500',
        orders: '20',
        diners: '30',
        averageSalesByConsumer: '250',
    },
};

export const discountsBreakdownReport: DiscountsBreakdownReport = {
    discounts: [
        {
            orderId: toShortId(newUuid()) as OrderId,
            discountReason: 'cliente frecuente',
            percentage: '0.3',
            discount: '100',
        },
    ],
    grandTotal: {
        total: '100',
        orders: '1',
        diners: '1',
    },
};

export const cancelledOrdersReport: CancelledOrdersReport = {
    cancelledOrders: [
        {
            orderId: toShortId(newUuid()) as OrderId,
            orderStatus: OrderStatuses.CANCELLED,
            total: '300',
        },
    ],
    grandTotal: {
        total: '300',
        orders: '1',
        diners: '2',
    },
};

export const salesByItemReport: SalesByItemReport = {
    salesByItem: [
        {
            name: 'Enchiladas',
            itemPrice: '120',
            orders: '10',
            percentageOfSales: '0.1',
            total: '1200',
        },
    ],
    grandTotal: {
        total: '1200',
        orders: '10',
        diners: '20',
        totalTips: '100',
        averageSalesByConsumer: '120',
        totalDiscounts: '20',
    },
};
export const promoCodeReport: Array<PromoCodeReport> = [
    {
        promoCodeId: toShortId(newUuid()) as PromoCodeId,
        code: 'Cortesia',
        rewardType: RewardTypes.DISCOUNT,
        usage: 12,
        percentage: '100',
        discount: '20',
        credits: '0',
        amount: '240',
    },
];

export const cashRegisterPosBusinessDaysSummary: Array<PosBusinessDayCashRegisterPosBusinessDaysSummary> = [
    {
        cashRegisterPosBusinessDayId: newUuid(),
        openedBy: 'Cajero',
        cashRegisterPosBusinessDayNumber: '01',
        deposits: '100',
        withdraws: '200',
        totalSales: '120',
        totalSold: '500',
        totalCommission: '50',
    },
];

export function getPosBusinessDayReportDemo(restaurant: RestaurantVm): PosBusinessDayReportVm {
    return {
        posBusinessDayReportId: newUuid(),
        restaurantId: newUuid(),
        restaurantInformation: {
            name: restaurant.name,
            businessName: restaurant.businessName,
            rfc: restaurant.rfc,
            street: restaurant.restaurantStreet ?? '',
            fiscalAddress: restaurant.fiscalAddress,
        },
        businessDaySummary: posBusinessDaySummary,
        businessDayPaymentsSummary: posBusinessDayPaymentsSummary,
        cashRegisterTransactionSummary: posBusinessDayCashRegisterTransactionSummary,
        paymentMethodsBreakdown: posBusinessDayPaymentMethodsBreakdown,
        cashRegisterTransactions,
        paymentMethodReports,
        cashRegistersSummaries,
        creditCardOrders,
        integrationOrdersReport,
        salesByOrderTypeReport,
        salesByAppReport,
        salesByManagerUserReport,
        discountsBreakdownReport,
        cancelledOrdersReport,
        salesByItemReport,
        promoCodeReport,
        orders: [],
        cashRegisterPosBusinessDaysSummary,
    };
}
