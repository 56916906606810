/**
 * @prettier
 */
import { Box } from '@material-ui/core';
import { ResponsiveLine } from '@nivo/line';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import * as React from 'react';
import { formatAsNumber } from 'src/utils/number/formatAsNumber';

export default function TimeLineBarChart({
    data,
    period,
    stacked = true,
    colors = { scheme: 'category10' },
    areaOpacity = 1,
    yAxisLegend = '',
    yAxisFormat,
    yAxisTooltipFormat,
    xAxisTooltipFormat,
    yScaleMin = 0,
    xAxisLegend = '',
    xAxisFormat = '',
}: Props): React.ReactElement | null {
    if (!data) {
        return null;
    }

    return (
        <Box width={1} height={320}>
            {/* @ts-ignore */}
            <ResponsiveLine
                data={data}
                // colors={{ datum: 'color' }}
                margin={{ top: 20, right: 0, bottom: 50, left: 60 }}
                // xScale={{ type: 'point' }}
                xScale={{
                    type: 'time',
                    format: '%Y-%m-%d',
                    precision: 'day',
                }}
                xFormat='time:%Y-%m-%d'
                yScale={{ type: 'linear', stacked, min: yScaleMin !== undefined ? yScaleMin : 0, max: 'auto' }}
                // axisTop={null}
                // axisRight={null}
                // axisBottom={{
                //     // orient: 'bottom',
                //     // tickSize: 5,
                //     // tickPadding: 5,
                //     // tickRotation: 0,
                //     legend: 'Date',
                //     legendOffset: 36,
                //     legendPosition: 'middle',
                // }}
                // margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                // xScale={{ type: 'point' }}
                // yScale={{ type: 'linear', stacked: true, min: 'auto', max: 'auto' }}
                curve='step'
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: xAxisLegend,
                    legendOffset: 36,
                    legendPosition: 'middle',
                    // format: '%b %d',
                    // format: '%b',
                    format: xAxisFormat ? xAxisFormat(period) : defaultXAxisFormat(period),
                    tickValues: defaultXAxisTickValues(period),
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: yAxisLegend,
                    legendOffset: -52,
                    legendPosition: 'middle',
                    format: yAxisFormat,
                }}
                enableSlices='x'
                sliceTooltip={({ slice }) => {
                    return (
                        <div
                            style={{
                                background: 'white',
                                padding: '9px 12px',
                                border: '1px solid #ccc',
                            }}
                        >
                            <table>
                                <tbody>
                                    <tr style={{ color: '#aaa', fontWeight: 'bold' }}>
                                        <td colSpan={slice.points.length + 1}>{xAxisTooltipFormat ? xAxisTooltipFormat(slice.points[0].data.xFormatted) : slice.points[0].data.xFormatted}</td>
                                    </tr>
                                    {slice.points.map((point) => (
                                        <tr key={point.id} style={{ color: point.serieColor, fontWeight: 'bold' }}>
                                            <td>{yAxisTooltipFormat ? yAxisTooltipFormat(point.data.yFormatted) : point.data.yFormatted}</td>
                                            <td>{slice.points.length && point.serieId}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    );
                }}
                colors={colors}
                lineWidth={0}
                enablePoints={false}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabel='y'
                pointLabelYOffset={-12}
                enableArea={true}
                areaOpacity={areaOpacity}
                useMesh={true}
                legends={[
                    {
                        anchor: 'top-left',
                        direction: 'column',
                        justify: false,
                        // translateX: -50,
                        translateY: -20,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 140,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        // effects: [
                        //     {
                        //         on: 'hover',
                        //         style: {
                        //             itemBackground: 'rgba(0, 0, 0, .03)',
                        //             itemOpacity: 1,
                        //         },
                        //     },
                        // ],
                    },
                ]}
            />
        </Box>
    );
}

export function pushData(
    data: any,
    date: any,
    countValue: {
        count: number;
        value: number;
    },
    period: PeriodScale,
    yAxis: YAxisScale,
    xAxis: XAxisScale
): void {
    if (period === PeriodScales.TWO_WEEKS) {
        const fourteenDaysAgo = moment().startOf('day').subtract(14, 'days');
        if (moment(date).isBefore(fourteenDaysAgo)) {
            return;
        }
    } else if (period === PeriodScales.THREE_MONTHS) {
        const threeMonthsAgo = moment().startOf('day').subtract(12, 'weeks');
        if (moment(date).isBefore(threeMonthsAgo)) {
            return;
        }
    } else if (period === PeriodScales.A_YEAR) {
        const oneYearAgo = moment().startOf('day').subtract(12, 'months');
        if (moment(date).isBefore(oneYearAgo)) {
            return;
        }
    }
    if (xAxis === XAxisScales.DAY) {
        if (yAxis === YAxisScales.ORDERS) {
            data.push({ x: date, y: countValue.count });
        } else if (yAxis === YAxisScales.AMOUNT) {
            data.push({ x: date, y: countValue.value });
        } else if (yAxis === YAxisScales.AVERAGE) {
            data.push({ x: date, y: BigNumber(countValue.value).dividedBy(countValue.count).decimalPlaces(0).toNumber() || 0 });
        }
    } else if (xAxis === XAxisScales.WEEK) {
        if (moment(date).startOf('isoWeek').isSame(moment(date), 'day')) {
            if (yAxis === YAxisScales.ORDERS) {
                data.push({ x: date, y: countValue.count });
            } else if (yAxis === YAxisScales.AMOUNT) {
                data.push({ x: date, y: countValue.value });
            } else if (yAxis === YAxisScales.AVERAGE) {
                data.push({ x: date, y: BigNumber(countValue.value).dividedBy(countValue.count).decimalPlaces(0).toNumber() || 0, count: countValue.count, value: countValue.value });
            }
            return;
        }
        if (!data.length) {
            return; // Ignore since week is not complete
        }
        const currentData = data[data.length - 1];
        if (yAxis === YAxisScales.ORDERS) {
            currentData.y = currentData.y + countValue.count;
        } else if (yAxis === YAxisScales.AMOUNT) {
            currentData.y = currentData.y + countValue.value;
        } else if (yAxis === YAxisScales.AVERAGE) {
            currentData.y =
                BigNumber((currentData as any).value)
                    .dividedBy((currentData as any).count)
                    .decimalPlaces(0)
                    .toNumber() || 0;
        }
    } else if (xAxis === XAxisScales.MONTH) {
        if (moment(date).startOf('month').isSame(moment(date), 'day')) {
            if (yAxis === YAxisScales.ORDERS) {
                data.push({ x: date, y: countValue.count });
            } else if (yAxis === YAxisScales.AMOUNT) {
                data.push({ x: date, y: countValue.value });
            } else if (yAxis === YAxisScales.AVERAGE) {
                data.push({ x: date, y: BigNumber(countValue.value).dividedBy(countValue.count).decimalPlaces(0).toNumber() || 0, count: countValue.count, value: countValue.value });
            }
            return;
        }
        if (!data.length) {
            return; // Ignore since month is not complete
        }
        const currentData = data[data.length - 1];
        if (yAxis === YAxisScales.ORDERS) {
            currentData.y = currentData.y + countValue.count;
        } else if (yAxis === YAxisScales.AMOUNT) {
            currentData.y = currentData.y + countValue.value;
        } else if (yAxis === YAxisScales.AVERAGE) {
            currentData.y =
                BigNumber((currentData as any).value)
                    .dividedBy((currentData as any).count)
                    .decimalPlaces(0)
                    .toNumber() || 0;
        }
    }
}

export function pushDataTotalValue(data: any, date: any, value: number, period: PeriodScale, xAxis: XAxisScale): void {
    if (period === PeriodScales.TWO_WEEKS) {
        const fourteenDaysAgo = moment().startOf('day').subtract(14, 'days');
        if (moment(date).isBefore(fourteenDaysAgo)) {
            return;
        }
    } else if (period === PeriodScales.THREE_MONTHS) {
        const threeMonthsAgo = moment().startOf('day').subtract(12, 'weeks');
        if (moment(date).isBefore(threeMonthsAgo)) {
            return;
        }
    } else if (period === PeriodScales.A_YEAR) {
        const oneYearAgo = moment().startOf('day').subtract(12, 'months');
        if (moment(date).isBefore(oneYearAgo)) {
            return;
        }
    }
    if (xAxis === XAxisScales.DAY) {
        data.push({ x: date, y: value });
    } else if (xAxis === XAxisScales.WEEK) {
        if (moment(date).startOf('isoWeek').isSame(moment(date), 'day')) {
            data.push({ x: date, y: value });
            return;
        }
        if (!data.length) {
            return; // Ignore since week is not complete
        }
        const currentData = data[data.length - 1];
        currentData.y = value;
    } else if (xAxis === XAxisScales.MONTH) {
        if (moment(date).startOf('month').isSame(moment(date), 'day')) {
            data.push({ x: date, y: value });
            return;
        }
        if (!data.length) {
            return; // Ignore since month is not complete
        }
        const currentData = data[data.length - 1];
        currentData.y = value;
    }
}

export function pushDataValue(data: any, date: any, value: number, period: PeriodScale, xAxis: XAxisScale): void {
    if (period === PeriodScales.TWO_WEEKS) {
        const fourteenDaysAgo = moment().startOf('day').subtract(14, 'days');
        if (moment(date).isBefore(fourteenDaysAgo)) {
            return;
        }
    } else if (period === PeriodScales.THREE_MONTHS) {
        const threeMonthsAgo = moment().startOf('day').subtract(12, 'weeks');
        if (moment(date).isBefore(threeMonthsAgo)) {
            return;
        }
    } else if (period === PeriodScales.A_YEAR) {
        const oneYearAgo = moment().startOf('day').subtract(12, 'months');
        if (moment(date).isBefore(oneYearAgo)) {
            return;
        }
    }
    if (xAxis === XAxisScales.DAY) {
        data.push({ x: date, y: value });
    } else if (xAxis === XAxisScales.WEEK) {
        if (moment(date).startOf('isoWeek').isSame(moment(date), 'day')) {
            data.push({ x: date, y: value });
            return;
        }
        if (!data.length) {
            return; // Ignore since week is not complete
        }
        const currentData = data[data.length - 1];
        currentData.y = currentData.y + value;
    } else if (xAxis === XAxisScales.MONTH) {
        if (moment(date).startOf('month').isSame(moment(date), 'day')) {
            data.push({ x: date, y: value });
            return;
        }
        if (!data.length) {
            return; // Ignore since month is not complete
        }
        const currentData = data[data.length - 1];
        currentData.y = currentData.y + value;
    }
}

export function pushDataValueChange(data: any, date: any, value: number, prev: number, period: PeriodScale, xAxis: XAxisScale): void {
    if (period === PeriodScales.TWO_WEEKS) {
        const fourteenDaysAgo = moment().startOf('day').subtract(15, 'days');
        if (moment(date).isBefore(fourteenDaysAgo)) {
            return;
        }
    } else if (period === PeriodScales.THREE_MONTHS) {
        const threeMonthsAgo = moment().startOf('day').subtract(13, 'weeks');
        if (moment(date).isBefore(threeMonthsAgo)) {
            return;
        }
    } else if (period === PeriodScales.A_YEAR) {
        const oneYearAgo = moment().startOf('day').subtract(13, 'months');
        if (moment(date).isBefore(oneYearAgo)) {
            return;
        }
    }
    if (xAxis === XAxisScales.DAY) {
        data.push({ x: date, y: BigNumber(value).minus(prev).toNumber() });
    } else if (xAxis === XAxisScales.WEEK) {
        if (moment(date).startOf('isoWeek').isSame(moment(date), 'day')) {
            data.push({ total: value, x: date, y: 0 });
            return;
        }
        if (!data.length) {
            return; // Ignore since week is not complete
        }
        const currentData = data[data.length - 1];
        currentData.total = value;
        const prevData = data[data.length - 2];
        if (!prevData) {
            return; // Ignore since no previous week
        }
        currentData.y = BigNumber(currentData.total).minus(prevData.total).toNumber();
    } else if (xAxis === XAxisScales.MONTH) {
        if (moment(date).startOf('month').isSame(moment(date), 'day')) {
            data.push({ total: value, x: date, y: 0 });
            return;
        }
        if (!data.length) {
            return; // Ignore since week is not complete
        }
        const currentData = data[data.length - 1];
        currentData.total = value;
        const prevData = data[data.length - 2];
        if (!prevData) {
            return; // Ignore since no previous week
        }
        currentData.y = BigNumber(currentData.total).minus(prevData.total).toNumber();
    }
}

export function pushDataPercentageValue(data: any, date: any, value: number, percentageOf: number, period: PeriodScale, xAxis: XAxisScale): void {
    if (period === PeriodScales.TWO_WEEKS) {
        const fourteenDaysAgo = moment().startOf('day').subtract(14, 'days');
        if (moment(date).isBefore(fourteenDaysAgo)) {
            return;
        }
    } else if (period === PeriodScales.THREE_MONTHS) {
        const threeMonthsAgo = moment().startOf('day').subtract(12, 'weeks');
        if (moment(date).isBefore(threeMonthsAgo)) {
            return;
        }
    } else if (period === PeriodScales.A_YEAR) {
        const oneYearAgo = moment().startOf('day').subtract(12, 'months');
        if (moment(date).isBefore(oneYearAgo)) {
            return;
        }
    }
    if (xAxis === XAxisScales.DAY) {
        data.push({ x: date, y: BigNumber(value).dividedBy(percentageOf).multipliedBy(100).decimalPlaces(1).toNumber() });
    } else if (xAxis === XAxisScales.WEEK) {
        if (moment(date).startOf('isoWeek').isSame(moment(date), 'day')) {
            data.push({ x: date, y: BigNumber(value).dividedBy(percentageOf).multipliedBy(100).decimalPlaces(1).toNumber() });
            return;
        }
        if (!data.length) {
            return; // Ignore since week is not complete
        }
        const currentData = data[data.length - 1];
        currentData.y = BigNumber(value).dividedBy(percentageOf).multipliedBy(100).decimalPlaces(1).toNumber();
    } else if (xAxis === XAxisScales.MONTH) {
        if (moment(date).startOf('month').isSame(moment(date), 'day')) {
            data.push({ x: date, y: BigNumber(value).dividedBy(percentageOf).multipliedBy(100).decimalPlaces(1).toNumber() });
            return;
        }
        if (!data.length) {
            return; // Ignore since month is not complete
        }
        const currentData = data[data.length - 1];
        currentData.y = BigNumber(value).dividedBy(percentageOf).multipliedBy(100).decimalPlaces(1).toNumber();
    }
}

export function yAxisLegend(yAxis: YAxisScale): string {
    if (yAxis === YAxisScales.ORDERS) {
        return 'Orders';
    }
    if (yAxis === YAxisScales.AVERAGE) {
        return 'Average';
    }
    return '';
}

export function yAxisFormat(yAxis: YAxisScale): (arg1: string) => string {
    return (value: string) => {
        if (yAxis === YAxisScales.ORDERS) {
            return formatAsNumber(`${value}`);
        }
        return formatAsCurrencyNumber(`${value}`);
    };
}

export function yAxisTooltipFormat(yAxis: YAxisScale): (arg1: string) => string {
    return (value: string) => {
        if (yAxis === YAxisScales.ORDERS) {
            return formatAsNumber(`${value}`);
        }
        return formatAsCurrencyNumber(`${value}`);
    };
}

export function defaultXAxisFormat(period: PeriodScale): string {
    if (period === PeriodScales.TWO_WEEKS) {
        return '%a %d';
    } else if (period === PeriodScales.THREE_MONTHS) {
        return '%b %d';
    }
    return '%B';
}

export function defaultXAxisTickValues(period: PeriodScale): string {
    if (period === PeriodScales.TWO_WEEKS) {
        return 'every day';
    } else if (period === PeriodScales.THREE_MONTHS) {
        return 'every monday';
    }
    return 'every month';
}

export function xAxisTooltipFormat(xAxis: XAxisScale): (arg1: string) => string {
    return (date: string) => {
        if (xAxis === XAxisScales.DAY) {
            return moment(date).format('MMM D dd');
        } else if (xAxis === XAxisScales.WEEK) {
            return moment(date).format('MMM [w]W');
        }
        return moment(date).format('MMM');
    };
}

export const PeriodScales = Object.freeze({
    A_YEAR: 'A_YEAR',
    THREE_MONTHS: 'THREE_MONTHS',
    TWO_WEEKS: 'TWO_WEEKS',
});

export type PeriodScale = typeof PeriodScales[keyof typeof PeriodScales];

export const XAxisScales = Object.freeze({
    DAY: 'DAY',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
});

export type XAxisScale = typeof XAxisScales[keyof typeof XAxisScales];

export const YAxisScales = Object.freeze({
    ORDERS: 'ORDERS',
    AMOUNT: 'AMOUNT',
    AVERAGE: 'AVERAGE',
});

export type YAxisScale = typeof YAxisScales[keyof typeof YAxisScales];

export const Color = Object.freeze({
    BLUE_LIGHT: 'rgb(166, 206, 227)',
    BLUE: 'rgb(31, 120, 180)',
    GREEN_LIGHT: 'rgb(178, 223, 138)',
    GREEN: 'rgb(51, 160, 44)',
    RED_LIGHT: 'rgb(251, 154, 153)',
    RED: 'rgb(227, 26, 28)',
    ORANGE_LIGHT: 'rgb(253, 191, 111)',
    ORANGE: 'rgb(255, 127, 0)',
    LETS_EAT: '#1A9AA0',
    PURPLE_LIGHT: 'rgb(202, 178, 214)',
    PURPLE: 'rgb(106, 61, 154)',
    YELLOW: 'rgb(255, 255, 153)',
    BROWN: 'rgb(177, 89, 40)',
    GREY: 'rgba(119,119,119,0.97)',
    GREY_LIGHT: 'rgba(168,168,168,0.97)',
});

type Props = {
    data?: Array<any>;
    period: PeriodScale;
    stacked?: boolean;
    colors?:
        | {
              scheme: string;
          }
        | Array<string>;
    areaOpacity?: number;
    yAxis?: YAxisScale;
    xAxis?: XAxisScale;
    yAxisLegend?: string;
    yAxisFormat?: (arg1?: any) => string;
    yAxisTooltipFormat?: (arg1?: any) => string;
    xAxisTooltipFormat?: (arg1?: any) => string;
    yScaleMin?: string | number;
    xAxisLegend?: string;
    xAxisFormat?: any;
};
