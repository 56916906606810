/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useEffect } from 'react';
import { app2 } from 'src/app2';
import { CancelledRejectedChart } from 'src/scenes/letseatadmin/statistics/order/CancelledRejectedChart';
import { NewAcceptedCompletedChart } from 'src/scenes/letseatadmin/statistics/order/NewAcceptedCompletedChart';
import { OrderTypeChart } from 'src/scenes/letseatadmin/statistics/order/OrderTypeChart';
import { PaymentFailedChart } from 'src/scenes/letseatadmin/statistics/order/PaymentFailedChart';
import { RejectedChart } from 'src/scenes/letseatadmin/statistics/order/RejectedChart';
import { RejectedNewOrAcceptedChart } from 'src/scenes/letseatadmin/statistics/order/RejectedNewOrAcceptedChart';
import type { PeriodScale, XAxisScale, YAxisScale } from 'src/scenes/letseatadmin/statistics/TimeLineBarChart';
import type { StatisticsVm } from 'src/scenes/letseatadmin/StatisticsPage';
import { useAction } from 'src/utils/react/useAction';

type Props = {
    statistics: StatisticsVm;
    period: PeriodScale;
    yAxis: YAxisScale;
    xAxis: XAxisScale;
};

export function OrderStatsPage({ statistics, period, yAxis, xAxis }: Props): React.ReactElement {
    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle('Statistics > Orders');
    }, []);

    return (
        <div style={{ padding: 24, overflow: 'visible' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        New / Accepted / Completed
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <NewAcceptedCompletedChart statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={4} />
                {/*<Grid item xs={12} lg={6}>*/}
                {/*    <NewChart statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} lg={6}>*/}
                {/*    <AcceptedChart statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} lg={6}>*/}
                {/*    <CompletedChart statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Cancelled / Rejected / Payments Failed
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CancelledRejectedChart statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={4} />
                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Rejected
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <RejectedChart statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={4} />
                <Grid item xs={12} lg={6}>
                    <RejectedNewOrAcceptedChart statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={4} />
                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Payments Failed
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PaymentFailedChart statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={4} />
                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Order Types
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <OrderTypeChart statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={4} />
            </Grid>
        </div>
    );
}
