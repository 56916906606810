/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { findRestaurantManualEarningsByRestaurantIdApi, RestaurantManualEarningsVm } from 'src/api/letseatadmin/restaurantManualEarning/findRestaurantManualEarningsByRestaurantIdApi';
import { removeRestaurantManualEarningApi } from 'src/api/letseatadmin/restaurantManualEarning/removeRestaurantManualEarningApi';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ChangeRestaurantManualEarningDialog } from 'src/scenes/letseatadmin/restaurantManualEarning/ChangeRestaurantManualEarningDialog';
import { CreateRestaurantManualEarningDialog } from 'src/scenes/letseatadmin/restaurantManualEarning/CreateRestaurantManualEarningDialog';
import type { RestaurantId, RestaurantManualEarningId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantManualEarnings({ restaurantId, filter }: Props): React.ReactElement {
    const classes = useStyles();
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState({ restaurantManualEarnings: [] } as RestaurantManualEarningsVm);
    const [createRestaurantManualEarningDialogState, setCreateRestaurantManualEarningDialogState] = useState({ open: false });
    const [changeRestaurantManualEarningDialogState, setChangeRestaurantManualEarningDialogState] = useState({ open: false, restaurantManualEarningId: undefined });

    useEffect(() => {
        load();
    }, [filter?.from, filter?.to]);

    const load = async () => {
        setLoading(true);
        const response = await findRestaurantManualEarningsByRestaurantIdApi({ restaurantId, filter });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setVm(response.data);
    };

    const removeRestaurantManualEarnings = async (restaurantManualEarningIds: Array<RestaurantManualEarningId>) => {
        setLoading(true);
        for (const restaurantManualEarningId of restaurantManualEarningIds) {
            const response = await removeRestaurantManualEarningApi({ restaurantManualEarningId });
            if (!response.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(response);
                await load();
                return;
            }
        }
        await load();
    };

    const handleClickNew = async () => {
        setCreateRestaurantManualEarningDialogState({ open: true });
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <CreateRestaurantManualEarningDialog
                open={createRestaurantManualEarningDialogState.open}
                onClose={() => setCreateRestaurantManualEarningDialogState({ open: false })}
                onRestaurantManualEarningCreated={load}
                restaurantId={restaurantId}
            />
            <ChangeRestaurantManualEarningDialog
                open={changeRestaurantManualEarningDialogState.open}
                restaurantManualEarningId={changeRestaurantManualEarningDialogState.restaurantManualEarningId}
                onClose={() => setChangeRestaurantManualEarningDialogState({ open: false, restaurantManualEarningId: undefined })}
                onChangeRestaurantManualEarning={load}
            />
            <Table
                className={classes.table}
                title={translate('Restaurant Manual Earning and Subscription Pay Per Use Costs')}
                data={
                    vm.restaurantManualEarnings &&
                    vm.restaurantManualEarnings.map((restaurantManualEarning) => {
                        return {
                            restaurantManualEarningId: restaurantManualEarning.restaurantManualEarningId,
                            title: restaurantManualEarning.title,
                            body: restaurantManualEarning.body,
                            comment: restaurantManualEarning.comment,
                            amount: restaurantManualEarning.amount,
                            receivedAt: formatDateTimeString(restaurantManualEarning.receivedAt),
                            modifiedAt: formatDateTimeString(restaurantManualEarning.modifiedAt),
                        };
                    })
                }
                columns={[
                    {
                        name: 'restaurantManualEarningId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'title',
                        label: translate('Title'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'body',
                        label: translate('Body'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'comment',
                        label: translate('Comment'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'amount',
                        label: translate('Amount'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'receivedAt',
                        label: translate('ReceivedAt'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'modifiedAt',
                        label: translate('ModifiedAt'),
                        options: {
                            filter: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: !viewUser ? 'multiple' : 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                            <CreateToolbarButton onClick={handleClickNew} />
                        </>
                    ),
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedRestaurantManualEarningIds = selectedRowsData.map((d: any) => d[0]);
                        return (
                            <div className={classes.toolbar}>
                                {selectedRestaurantManualEarningIds.length === 1 && (
                                    <ChangeToolbarButton
                                        onClick={() => {
                                            if (selectedRestaurantManualEarningIds.length === 1)
                                                setChangeRestaurantManualEarningDialogState({ open: true, restaurantManualEarningId: selectedRestaurantManualEarningIds[0] });
                                        }}
                                    />
                                )}
                                <RemoveToolbarButton
                                    onClick={async () => {
                                        const remove = window.confirm(
                                            selectedRestaurantManualEarningIds.length === 1
                                                ? 'Are you sure you want to remove the selected restaurantManualEarnings'
                                                : `Are you sure you want to remove the selected ${selectedRestaurantManualEarningIds.length} restaurantManualEarnings`
                                        );
                                        if (remove) {
                                            await removeRestaurantManualEarnings(selectedRestaurantManualEarningIds);
                                        }
                                    }}
                                />
                            </div>
                        );
                    },
                    // filterList: [[], [], [], [], ['MEXICO']],
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        width: '100%',
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

type Props = {
    restaurantId: RestaurantId;
    filter?: {
        from: Date;
        to: Date;
    };
};
