/**
 * @prettier
 */
import * as React from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { PaymentTerminalProvider, PaymentTerminalProviders } from 'src/constants/PaymentTerminalProvider';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function FormPaymentTerminalProvidersSelect({ defaultValue, name, required, label, placeholder, variant, helperText, disabled }: Props): React.ReactElement {
    const useQpayTerminalEnabled = useSelector((state) => state.app.restaurant.useQpayTerminalEnabled);
    const useOcaPaymentTerminalEnabled = useSelector((state) => state.app.restaurant.useOcaPaymentTerminalEnabled);
    const restaurant = useSelector((state) => state.app.restaurant);

    const restaurantTerminalProviders: Array<{
        label: string;
        value: any;
    }> = [];

    if (required) {
        restaurantTerminalProviders.push({ value: null, label: '' });
    }

    if (useQpayTerminalEnabled) {
        restaurantTerminalProviders.push({ value: PaymentTerminalProviders.QPAY, label: translate(`PaymentTerminalProviders.${PaymentTerminalProviders.QPAY}`) });
    }
    if (useOcaPaymentTerminalEnabled) {
        restaurantTerminalProviders.push({ value: PaymentTerminalProviders.OCA, label: translate(`PaymentTerminalProviders.${PaymentTerminalProviders.OCA}`) });
    }

    return (
        <FormSelect
            label={label ?? translate('Payment Terminal Provider')}
            placeholder={placeholder ?? translate('Select Terminal Provider')}
            name={name}
            options={restaurantTerminalProviders ?? []}
            defaultValue={defaultValue}
            required={required}
            helperText={helperText}
            disabled={disabled}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: PaymentTerminalProvider;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
};
