/**
 * @prettier
 */
export const CustomerTypes = {
    DOWNLOADED_APP: 'DOWNLOADED_APP', // WHITE never registered
    SIGNED_UP: 'SIGNED_UP', // WHITE never ordered
    SIGNED_UP_REJECTED: 'SIGNED_UP_REJECTED', // WHITE tried to order but was rejected
    SIGNED_UP_CANCELLED: 'SIGNED_UP_CANCELLED', // WHITE tried to order but cancelled
    INACTIVE: 'INACTIVE', // BLUE no orders last 30 days
    ACTIVE_1_TO_2: 'ACTIVE_1_TO_2', // RED 1 to 2 orders last 30 days
    ACTIVE_3_TO_5: 'ACTIVE_3_TO_5', // ORANGE 3 to 5 orders last 30 days
    ACTIVE_6_TO_9: 'ACTIVE_6_TO_9', // YELLOW 6 to 9 orders last 30 days
    ACTIVE_10_OR_MORE: 'ACTIVE_10_OR_MORE', // GREEN 10 + orders last 30 days
} as const;

export type CustomerType = typeof CustomerTypes[keyof typeof CustomerTypes];
