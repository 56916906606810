/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DetailSubscriptionPlanVm } from 'src/types/DetailSubscriptionPlanVm';
import type { RestaurantId } from 'src/types/Id';

export async function getRestaurantSelfRegistrationKpisApi(request: GetRestaurantSelfRegistrationKpisApiRequest): ApiSauceResponse<GetRestaurantSelfRegistrationKpisApiResponse> {
    return letseatmanagerApiMethod('restaurantSelfRegistration/getRestaurantSelfRegistrationKpisApi', request);
}

export type GetRestaurantSelfRegistrationKpisApiRequest = {
    restaurantId: RestaurantId;
};

export type GetRestaurantSelfRegistrationKpisApiResponse = {
    stats: {
        ordersCompleted: number;
        averageDeliveryTimeInMinutes: number;
        earnings: string;
        savings: string;
    };
    detailSubscriptionPlans: Array<DetailSubscriptionPlanVm>;
};
