/**
 * @prettier
 */
import distance from '@turf/distance';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';

export function distanceInKm(point1?: GeoJsonPoint, point2?: GeoJsonPoint): number | undefined {
    if (!point1 || !point2) {
        return;
    }
    return distance(point1, point2, { units: 'kilometers' });
}
