/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Loader } from '@pidedirecto/ui';
import { Auth } from 'aws-amplify';
import { Authenticator, ConfirmSignIn, ConfirmSignUp, ForgotPassword, Greetings, SignIn, SignUp, TOTPSetup, VerifyContact } from 'aws-amplify-react';
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { ensureManagerCognitoUserExistsInDevEnvironmentApi } from 'src/api/letseatmanager/authentication/ensureManagerCognitoUserExistsInDevEnvironmentApi';
import { Form } from 'src/components/form/Form';
import { FormPasswordField } from 'src/components/form/FormPasswordField';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormUserNameField } from 'src/components/form/FormUserNameField';
import { Environments } from 'src/constants/Environment';
import { envENVIRONMENT } from 'src/env/envENVIRONMENT';
import { translate } from 'src/i18n/translate';
import { PideDirectoSvgIcon } from 'src/icons/PideDirectoSvgIcon';
import { AwsCognitoUsername } from 'src/types/Id';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';
import { useSelector } from 'src/utils/react/useSelector';
import { openTermsAndConditionsPageInNewTab } from 'src/utils/window/openTermsAndConditionsPageInNewTab';

export function SignInPage(): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [loading, setloading] = useState(false);

    const signingIn = useSelector((state) => state.authentication.signingIn);
    const retrying = useSelector((state) => state.authentication.retrying);

    const onSubmit = async (form: any) => {
        try {
            setloading(true);
            if (!isProductionEnvironment() && envENVIRONMENT() !== Environments.TEST) {
                await ensureManagerCognitoUserExistsInDevEnvironmentApi({ username: form.username as AwsCognitoUsername });
            }

            await Auth.signIn(form.username.toLowerCase(), form.password);
            setloading(false);
        } catch (error: any) {
            setloading(false);
        }
    };

    if (signingIn) {
        return (
            <div className={classes.loadingContainer} data-testid='signingInProgressBar'>
                <Loader loading={true} size={40} text={translate('Signing In')} />
                {retrying && (
                    <Typography display='block' variant='body1' color='primary'>
                        {translate('Failed to sign in')}, {translate('Retrying').toLowerCase()}.
                    </Typography>
                )}
            </div>
        );
    }

    return (
        /* @ts-ignore */
        <Authenticator
            hide={[
                Greetings,
                SignIn,
                ConfirmSignIn,
                // RequireNewPassword,
                SignUp,
                ConfirmSignUp,
                VerifyContact,
                ForgotPassword,
                TOTPSetup,
            ]}
            theme={{
                button: { backgroundColor: '#00C8DC' },
                hint: { display: 'none' },
                sectionFooterSecondaryContent: { display: 'none' },
            }}
        >
            {/* Dont remove fragment <> below, its a fix for console errors, see https://github.com/aws-amplify/amplify-js/issues/234#issuecomment-541340399 */}
            <>
                <div className={classes.container}>
                    <div className={classes.signin}>
                        <PideDirectoSvgIcon title={'pidedirecto-logo'} width={80} height={80} />
                        <h1 className={classes.title}>{translate('Enter to PideDirecto')}</h1>

                        <Form className={classes.formContainer} form={form} onSubmit={onSubmit}>
                            <div className={classes.inputContainer}>
                                <div className={classes.input}>
                                    <FormUserNameField name={'username'} label={translate('Username')} placeholder={translate('Enter your username or email')} />
                                </div>
                                <div className={classes.input}>
                                    <FormPasswordField name={'password'} label={translate('Password')} placeholder={translate('Enter your password')} />
                                </div>
                                <FormSubmitButton disabled={loading} text={translate('Sign in')} classes={{ container: classes.containerButton }} />
                            </div>
                        </Form>
                        <div className={classes.termsContainer}>
                            <p>{translate('By logging in you accept our')}</p>
                            <span className={classes.conditionsContainer} onClick={openTermsAndConditionsPageInNewTab}>
                                {translate('Terms of use and privacy policy.')}
                            </span>
                        </div>
                    </div>
                </div>
            </>
        </Authenticator>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100vw',
        height: '100vh',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    signin: {
        minWidth: 500,
        width: '30%',
        height: '70%',
        backgroundColor: 'white',
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '540px',
        boxShadow: '0px 8px 20px -4px rgba(23, 24, 24, 0.12), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: '100%',
            borderRadius: 0,
            height: '100%',
            padding: 0,
        },
    },
    logo: {
        width: 25,
        height: 29,
    },
    appLogo: {
        width: 100,
        height: 100,
    },
    subHeading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
    },
    title: {
        color: theme.palette.secondary.contrastText,
        marginBottom: 0,
        marginTop: 0,
        fontFamily: theme.typography.semiBold,
        fontSize: 26,
    },
    formContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    inputContainer: {
        width: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        gap: 20,
        margin: '20px 0',
    },
    input: {
        width: '100%',
    },
    icon: {
        width: 30,
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerButton: {
        width: '100%',
    },
    button: {
        width: '80%',
        height: 45,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontWeight: 'bold',
        border: '0',
        outline: 'none',
        borderRadius: 5,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        marginTop: 10,
        boxShadow: '5px 5px 10px rgba(100, 214, 179, 0.4)',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#509984',
        },
    },
    termsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        marginTop: 10,
        marginBottom: 15,
        fontFamily: theme.typography.regular,
        fontSize: 12,
        textAlign: 'center',
    },
    conditionsContainer: {
        color: '#1a8074',
        textDecoration: 'underline',
        cursor: 'pointer',
        display: 'inline-block',
        textAlign: 'center',
    },
    downloadApp: {
        marginLeft: '10%',
        flexDirection: 'column',
        width: '10%',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50%',
        textAlign: 'center',
        border: 'none',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            paddingTop: '10%',
            marginLeft: '0%',
        },
    },
    downloadButton: {
        border: 'none',
    },
    loadingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
}));
