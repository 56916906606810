/**
 * @prettier
 */
import { createStore } from '@pidedirecto/ui/hooks';
import { isTableViewInOrdersPageInLocalStorage } from 'src/localStorage/isTableViewInOrdersPageInLocalStorage';
import { setIsTableViewInOrdersPageInLocalStorage } from 'src/localStorage/setIsTableViewInOrdersPageInLocalStorage';

export const [useOrderPageStore, useOrderPageActions] = createStore<OrderPageState, OrderPageActions>({
    initialState: {
        isTableView: isTableViewInOrdersPageInLocalStorage(),
    },
    actions: {
        showOrdersTableView: (state) => {
            state.isTableView = true;
            setIsTableViewInOrdersPageInLocalStorage(true);
        },
        showOrdersCardView: (state) => {
            state.isTableView = false;
            setIsTableViewInOrdersPageInLocalStorage(false);
        },
    },
});

export type OrderPageState = {
    isTableView: boolean;
};

export type OrderPageActions = {
    showOrdersTableView: () => void;
    showOrdersCardView: () => void;
};
