/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverId } from 'src/types/Id';

export async function changeMobileNumberToDriverApi(request: ChangeMobileNumberToDriverApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('driver/changeDriverMobileNumber', request);
}

type ChangeMobileNumberToDriverApiRequest = {
    driverId: DriverId;
    mobileNumber: string;
};
