/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { app2 } from 'src/app2';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { PinCodeUser } from 'src/types/PinCodeUser';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function VerifyPosPinCodeUserDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    const pinCodeUsers = useSelector((state) => state.authentication.pinCodeUsers);
    const open = useSelector((state) => state.app2.verifyPosPinCodeUserDialog.open);
    const managerUserId = useSelector((state) => state.app2.verifyPosPinCodeUserDialog.managerUserId);
    const onAuthorized = useSelector((state) => state.app2.verifyPosPinCodeUserDialog.onAuthorized);
    const onUnauthorized = useSelector((state) => state.app2.verifyPosPinCodeUserDialog.onUnauthorized);

    const closeVerifyPosPinCodeUserDialog = useAction(app2.actions.closeVerifyPosPinCodeUserDialog);

    const handleClose = () => {
        if (isSubmitting) return;
        closeVerifyPosPinCodeUserDialog();
    };

    const handleSubmit = async (form: any) => {
        const pinCodeUser = pinCodeUsers?.find((user: PinCodeUser) => user.managerUserId === managerUserId);

        if (pinCodeUser?.pinCode !== form.pinCode) {
            return onUnauthorized?.();
        }

        closeVerifyPosPinCodeUserDialog();
        onAuthorized?.();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose}>
            <Form onSubmit={handleSubmit} form={form} className={classes.form}>
                <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('User authorization')}</DialogTitle>
                <DialogContent style={{ overflow: 'hidden', width: '80%' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField classes={{ input: classes.input }} name='pinCode' label={translate('PIN code')} required maxLength={6} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.buttonsContainer}>
                    <Button onClick={handleClose}>
                        <div className={classes.cancelButton}>{translate('Cancel')}</div>
                    </Button>
                    <Button color='primary' type='submit' classes={{ root: classes.noMargin }}>
                        <div className={classes.acceptButton}>{translate('Authorize')}</div>
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    noMargin: {
        margin: '0 !important',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    input: {
        '-webkit-text-security': 'disc',
    },
}));
