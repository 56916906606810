/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { CompanyId, type RestaurantId } from 'src/types/Id';

export async function findCompaniesApi(request: FindCompaniesApiRequest): ApiSauceResponse<FindCompaniesApiResponse> {
    return letseatadminApiMethod('company/findCompaniesApi', request);
}

type FindCompaniesApiRequest = {
    restaurantId: RestaurantId;
};

type FindCompaniesApiResponse = {
    companies: Array<{
        companyId: CompanyId;
        name: string;
        address?: string;
    }>;
};
