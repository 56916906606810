/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Input } from 'src/components/Input';
import { Select } from 'src/components/Select';
import { DeliveryStatus, DeliveryStatuses } from 'src/constants/DeliveryStatus';
import { OrderStatus, OrderStatuses } from 'src/constants/OrderStatus';
import { TakeAwayOrderStatus, TakeAwayOrderStatuses } from 'src/constants/TakeAwayOrderStatus';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function OrdersPageFilter({ currentTab, defaultValues, onChange }: Props): React.ReactElement {
    const classes = useStyles();

    const markReadyForPickupButtonEnabled = useSelector((state) => state.app.restaurant.markReadyForPickupButtonEnabled);
    const displayPeriodByDateAndTimeEnabled = useSelector((state) => state.app.restaurant?.displayPeriodByDateAndTimeEnabled);

    const orderStatusOptions = [
        {
            value: OrderStatuses.ACCEPTED,
            label: translate('Ongoing'),
        },
        {
            value: OrderStatuses.COMPLETE,
            label: translate('Completed'),
        },
        {
            value: OrderStatuses.REJECTED,
            label: translate('Rejected'),
        },
        {
            value: OrderStatuses.CANCELLED,
            label: translate('Cancelled'),
        },
        {
            value: OrderStatuses.RETURNED,
            label: translate('Returned'),
        },
    ];

    const deliveryStatusOptions = [
        {
            value: DeliveryStatuses.REQUESTED,
            label: translate('Looking for driver'),
        },
        {
            value: DeliveryStatuses.ACCEPTED,
            label: translate('Driver assigned'),
        },
        {
            value: DeliveryStatuses.DELIVERED,
            label: translate('Completed'),
        },
        {
            value: DeliveryStatuses.RETURNED,
            label: translate('Returned'),
        },
        {
            value: OrderStatuses.REJECTED,
            label: translate('Rejected'),
        },
        {
            value: OrderStatuses.CANCELLED,
            label: translate('Cancelled'),
        },
    ];

    let takeAwayOrderStatusOptions = [
        {
            value: TakeAwayOrderStatuses.ACCEPTED,
            label: translate('Accepted'),
        },
        {
            value: TakeAwayOrderStatuses.READY_FOR_PICK_UP,
            label: translate('Ready for pickup'),
        },
        {
            value: TakeAwayOrderStatuses.COMPLETED,
            label: translate('Completed'),
        },
        {
            value: OrderStatuses.REJECTED,
            label: translate('Rejected'),
        },
        {
            value: OrderStatuses.CANCELLED,
            label: translate('Cancelled'),
        },
    ];

    const eatHereOrderStatusOptions = [
        {
            value: OrderStatuses.PENDING,
            label: translate('Opened'),
        },
        {
            value: OrderStatuses.COMPLETE,
            label: translate('Completed'),
        },
        {
            value: OrderStatuses.REJECTED,
            label: translate('Rejected'),
        },
        {
            value: OrderStatuses.CANCELLED,
            label: translate('Cancelled'),
        },
    ];

    const roomServiceOrderStatusOptions = [
        {
            value: OrderStatuses.NEW,
            label: translate('New'),
        },
        {
            value: OrderStatuses.ACCEPTED,
            label: translate('Accepted'),
        },
        {
            value: OrderStatuses.COMPLETE,
            label: translate('Completed'),
        },
        {
            value: OrderStatuses.REJECTED,
            label: translate('Rejected'),
        },
        {
            value: OrderStatuses.CANCELLED,
            label: translate('Cancelled'),
        },
    ];

    if (!markReadyForPickupButtonEnabled) {
        takeAwayOrderStatusOptions = takeAwayOrderStatusOptions.filter((takeAwayOrderStatusOption) => takeAwayOrderStatusOption.value !== TakeAwayOrderStatuses.READY_FOR_PICK_UP);
    }

    const handleFromChange = (from: Date) => {
        onChange({ from });
    };

    const handleToChange = (to: Date) => {
        onChange({ to });
    };

    const handleChangeOrderStatuses = (orderStatuses: OrderStatus) => {
        onChange({ orderStatuses });
    };

    const handleChangeDeliveryStatuses = (deliveryStatuses: DeliveryStatus) => {
        onChange({ deliveryStatuses });
    };

    const handleChangeTakeAwayOrderStatuses = (takeAwayOrderStatuses: TakeAwayOrderStatus) => {
        onChange({ takeAwayOrderStatuses });
    };

    const handleChangeEatHereOrderStatus = (eatHereOrderStatuses: OrderStatus) => {
        onChange({ eatHereOrderStatuses });
    };

    const handleChangeRoomServiceOrderStatus = (roomServiceOrderStatuses: OrderStatus) => {
        onChange({ roomServiceOrderStatuses });
    };

    return (
        <div className={classes.container}>
            <Input
                type={displayPeriodByDateAndTimeEnabled ? 'datetime-local' : 'date'}
                name='from'
                label={translate('From')}
                onChange={handleFromChange}
                value={defaultValues?.from}
                defaultValue={defaultValues?.from}
            />
            <Input
                type={displayPeriodByDateAndTimeEnabled ? 'datetime-local' : 'date'}
                name='to'
                label={translate('To')}
                onChange={handleToChange}
                value={defaultValues?.to}
                defaultValue={defaultValues?.from}
            />
            {currentTab === TABS.ALL && (
                <Select
                    label={translate('Status')}
                    name='orderStatus'
                    labelAsPlaceholder
                    onChange={handleChangeOrderStatuses}
                    options={orderStatusOptions}
                    defaultValue={defaultValues?.orderStatuses}
                    multiselectable
                    classes={{}}
                />
            )}
            {currentTab === TABS.DELIVERY && (
                <Select
                    label={translate('Status')}
                    name='deliveryStatus'
                    labelAsPlaceholder
                    onChange={handleChangeDeliveryStatuses}
                    options={deliveryStatusOptions}
                    defaultValue={defaultValues?.deliveryStatuses}
                    multiselectable
                />
            )}
            {currentTab === TABS.TAKE_AWAY && (
                <Select
                    label={translate('Status')}
                    name='takeAwayOrderStatus'
                    labelAsPlaceholder
                    onChange={handleChangeTakeAwayOrderStatuses}
                    multiselectable
                    options={takeAwayOrderStatusOptions}
                    defaultValue={defaultValues?.takeAwayOrderStatuses}
                />
            )}
            {currentTab === TABS.EAT_HERE && (
                <Select
                    label={translate('Status')}
                    name='orderStatus'
                    labelAsPlaceholder
                    onChange={handleChangeEatHereOrderStatus}
                    options={eatHereOrderStatusOptions}
                    defaultValue={defaultValues?.eatHereOrderStatuses}
                    multiselectable
                />
            )}
            {currentTab === TABS.ROOM_SERVICE && (
                <Select
                    label={translate('Status')}
                    name='orderStatus'
                    labelAsPlaceholder
                    onChange={handleChangeRoomServiceOrderStatus}
                    options={roomServiceOrderStatusOptions}
                    defaultValue={defaultValues?.orderStatuses}
                    multiselectable
                />
            )}
        </div>
    );
}

const TABS = {
    ALL: 'ALL',
    DELIVERY: 'DELIVERY',
    TAKE_AWAY: 'TAKE_AWAY',
    EAT_HERE: 'EAT_HERE',
    ROOM_SERVICE: 'ROOM_SERVICE',
} as const;

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
        },
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    select: {
        width: 'fit-content',
    },
}));

type Props = {
    currentTab: string;
    defaultValues?: {
        from?: Date;
        to?: Date;
        orderStatuses?: Array<OrderStatus>;
        eatHereOrderStatuses?: Array<OrderStatus>;
        takeAwayOrderStatuses?: Array<TakeAwayOrderStatus>;
        deliveryStatuses?: Array<DeliveryStatus>;
    };
    onChange: any;
};
