/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import type { OngoingDriverBonusesVm } from 'src/api/types/driverBonus/OngoingDriverBonusesVm';
import { app2 } from 'src/app2';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { RestaurantId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { formatAsNumber } from 'src/utils/number/formatAsNumber';
import { useAction } from 'src/utils/react/useAction';

export function OngoingDriverBonusesPage(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState({ driverBonuses: [] } as OngoingDriverBonusesVm);

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Ongoing Driver Bonuses'));
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.admin.getOngoingDriverBonuses();
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setVm(response.data);
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <MUIDataTable
                /* @ts-ignore */
                className={classes.table}
                data={
                    vm.driverBonuses &&
                    vm.driverBonuses.map((driverBonus) => ({
                        driverBonusId: driverBonus.driverBonusId,
                        driverName: driverBonus.driverName,
                        name: driverBonus.name,
                        description: driverBonus.description,
                        driverBonusType: driverBonus.driverBonusType,
                        driverBonusCategory: driverBonus.driverBonusCategory,
                        driverIncentiveType: driverBonus.driverIncentiveType,
                        earning: formatAsCurrencyNumber(driverBonus.earning),
                        collected: driverBonus.collected ? 'Collected' : '',
                        startedAt: formatDateTimeString(driverBonus.createdAt),
                        collectedAt: formatDateTimeString(driverBonus.collectedAt),
                        deliveries: formatAsNumber(driverBonus.deliveries),
                        deliveriesRequired: formatAsNumber(driverBonus.deliveriesRequired),
                        concurrent: driverBonus.concurrent ? 'Concurrent' : '',
                        city: translate(driverBonus.city),
                        zones: driverBonus.zones?.join(', '),
                        hours: driverBonus.hours,
                        minDrivingDistance: formatAsNumber(driverBonus.minDrivingDistance),
                        minOrderSubtotal: formatAsCurrencyNumber(driverBonus.minOrderSubtotal),
                        restaurants: driverBonus.restaurants?.map((restaurant: { name: string; restaurantId: RestaurantId }) => restaurant.name)?.join(', '),
                        excludedRestaurants: driverBonus.excludedRestaurants?.map((restaurant: { name: string; restaurantId: RestaurantId }) => restaurant.name)?.join(', '),
                        startsAt: formatDateTimeString(driverBonus.startsAt),
                        endsAt: formatDateTimeString(driverBonus.endsAt),
                    }))
                }
                columns={[
                    {
                        name: 'driverBonusId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'driverName',
                        label: translate('Driver'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'name',
                        label: translate('Name'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'description',
                        label: translate('Description'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'driverBonusType',
                        label: translate('Driver Bonus Type'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'driverBonusCategory',
                        label: translate('Driver Bonus Category'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'driverIncentiveType',
                        label: translate('Driver Incentive Type'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'earning',
                        label: translate('Earning'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'collected',
                        label: translate('Collected'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'startedAt',
                        label: translate('Started At'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'collectedAt',
                        label: translate('Collected At'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'deliveries',
                        label: translate('Deliveries'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'deliveriesRequired',
                        label: translate('Deliveries Required'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'concurrent',
                        label: translate('Concurrent'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'city',
                        label: translate('City'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'zones',
                        label: translate('Zones'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'hours',
                        label: translate('Hours'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'minDrivingDistance',
                        label: translate('Min Driving Distance'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'minOrderSubtotal',
                        label: translate('Min Order Subtotal'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'restaurants',
                        label: translate('Restaurants'),
                        options: {
                            filter: false,
                            customBodyRender: (restaurants) => restaurants?.split(', ')?.map((restaurant: any, i: any) => <div key={`${restaurant}-${i}`}>{restaurant}</div>),
                        },
                    },
                    {
                        name: 'excludedRestaurants',
                        label: translate('Excluded Restaurants'),
                        options: {
                            filter: false,
                            customBodyRender: (restaurants) => restaurants?.split(', ')?.map((restaurant: any, i: any) => <div key={`${restaurant}-${i}`}>{restaurant}</div>),
                        },
                    },
                    {
                        name: 'startsAt',
                        label: translate('Starts'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'endsAt',
                        label: translate('Ends'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                        </>
                    ),
                    onDownload: (buildHead, buildBody, columns, data) => {
                        return '\uFEFF' + buildHead(columns) + buildBody(data);
                    },
                    downloadOptions: { filename: 'ongoing driver bonuses data.csv' },
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));
