/**
 * @prettier
 */
import * as React from 'react';
import { DownloadCsvToolbarButton } from 'src/components/mui-datatables/DownloadCsvToolbarButton';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CashRegisterTransactionByTransactionReason } from 'src/types/CashRegisterTransactionsReportVm';
import { objectsToCsv } from 'src/utils/csv/objectsToCsv';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';
import { toShortId } from 'src/utils/uuid/toShortId';

export function CashRegisterTransactionReasonsTableReport({ totalSummaryTransactionsByTransactionReason }: Props): React.ReactElement {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const downloadReports = () => {
        const cashRegisterCancelledOrdersReport = totalSummaryTransactionsByTransactionReason.map((cashRegisterTransactionByTransactionReason) => ({
            reason: cashRegisterTransactionByTransactionReason.reason,
            id: toShortId(cashRegisterTransactionByTransactionReason.cashRegisterId),
            transactions: cashRegisterTransactionByTransactionReason.transactions,
            amount: formatAsCurrencyNumber(cashRegisterTransactionByTransactionReason.amount),
        }));
        const csv = objectsToCsv(cashRegisterCancelledOrdersReport);
        downloadTextInFile(csv, 'transactions-report.csv');
    };

    return (
        <Table
            data={totalSummaryTransactionsByTransactionReason.map((cashRegisterTransactionByTransactionReason) => ({
                cashRegisterTransactionReason: cashRegisterTransactionByTransactionReason.reason,
                shortCashRegisterId: toShortId(cashRegisterTransactionByTransactionReason.cashRegisterId),
                transactions: cashRegisterTransactionByTransactionReason.transactions,
                amount: formatAsCurrencyNumber(cashRegisterTransactionByTransactionReason.amount),
            }))}
            columns={[
                {
                    name: 'cashRegisterTransactionReason',
                    label: translate('Reason'),
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'shortCashRegisterId',
                    label: translate('Cash register id'),
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'transactions',
                    label: translate('Transactions'),
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'amount',
                    label: translate('Total'),
                    options: {
                        filter: false,
                    },
                },
            ]}
            options={{
                responsive: 'standard',
                selectableRows: 'none',
                filter: false,
                search: false,
                download: false,
                print: false,
                rowsPerPage: 10,
                customToolbar: () => (
                    <>
                        <DownloadCsvToolbarButton onClick={downloadReports} />
                    </>
                ),
            }}
        />
    );
}

type Props = {
    totalSummaryTransactionsByTransactionReason: Array<CashRegisterTransactionByTransactionReason>;
};
