/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { OwnFleetDriverVm } from 'src/types/OwnFleetDriverVm';

export async function findOwnFleetDriversByRestaurantIdApi(request: FindOwnFleetDriversByRestaurantIdApiRequest): ApiSauceResponse<FindOwnFleetDriversByRestaurantIdApiResponse> {
    return letseatmanagerApiMethod('ownFleet/findOwnFleetDriversByRestaurantIdApi', request);
}

type FindOwnFleetDriversByRestaurantIdApiRequest = {
    restaurantId: RestaurantId;
};

type FindOwnFleetDriversByRestaurantIdApiResponse = {
    drivers: Array<OwnFleetDriverVm>;
};
