/**
 * @prettier
 */
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { findRestaurantsWithInventoryApi } from 'src/api/letseatmanager/inventory/findRestaurantsWithInventoryApi';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { translate } from 'src/i18n/translate';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function FormRestaurantWithInventorySelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant }: Props): React.ReactElement {
    const { setError, clearErrors } = useFormContext();

    const [shouldShowEmptyRestaurantsError, setShouldShowEmptyRestaurantsError] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, restaurants] = useLoadApi(findRestaurantsWithInventoryApi, undefined, { initialValue: [] });

    const restaurantsFiltered = restaurants.filter((restaurant) => restaurant.restaurantId !== restaurantId);
    const options = required
        ? restaurantsFiltered.map((restaurant) => ({ label: restaurant.name, value: restaurant.restaurantId }))
        : [{ label: '', value: null }, ...restaurantsFiltered.map((restaurant) => ({ label: restaurant.name, value: restaurant.restaurantId }))];

    useEffect(() => {
        if (restaurantsFiltered.length === 0 && !loading && required) {
            setShouldShowEmptyRestaurantsError(true);
        }
        if (restaurantsFiltered.length > 0) setShouldShowEmptyRestaurantsError(false);
    }, [restaurantsFiltered, loading, required]);

    useEffect(() => {
        if (shouldShowEmptyRestaurantsError) setError(name, { message: translate('There is no restaurants with inventory enabled.') });
        else clearErrors(name);
    }, [shouldShowEmptyRestaurantsError]);

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled || loading}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
};
