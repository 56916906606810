/**
 * @prettier
 */
import * as React from 'react';
import { DownloadCsvToolbarButton } from 'src/components/mui-datatables/DownloadCsvToolbarButton';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CashRegisterTransactionByDay } from 'src/types/CashRegisterTransactionsReportVm';
import { objectsToCsv } from 'src/utils/csv/objectsToCsv';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';
import { toShortId } from 'src/utils/uuid/toShortId';

export function CashRegisterTransactionsByDayTableReport({ cashRegisterTransactionsByDay }: Props): React.ReactElement {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const downloadReports = () => {
        const cashRegisterCancelledOrdersReport = cashRegisterTransactionsByDay.map((cashRegisterTransactionByDay) => ({
            id: toShortId(cashRegisterTransactionByDay.cashRegisterId),
            amount: formatAsCurrencyNumber(cashRegisterTransactionByDay.amount),
            transactions: cashRegisterTransactionByDay.transactions,
            date: cashRegisterTransactionByDay.createdAt,
        }));
        const csv = objectsToCsv(cashRegisterCancelledOrdersReport);
        downloadTextInFile(csv, 'transactions-by-day.csv');
    };

    return (
        <Table
            data={cashRegisterTransactionsByDay.map((cashRegisterTransactionByDay) => ({
                shortCashRegisterId: toShortId(cashRegisterTransactionByDay.cashRegisterId),
                amount: formatAsCurrencyNumber(cashRegisterTransactionByDay.amount),
                transactions: cashRegisterTransactionByDay.transactions,
                createdAt: cashRegisterTransactionByDay.createdAt,
            }))}
            columns={[
                {
                    name: 'shortCashRegisterId',
                    label: translate('Cash register id'),
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'amount',
                    label: translate('Amount'),
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'transactions',
                    label: translate('Transactions'),
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'createdAt',
                    label: translate('Created at'),
                    options: {
                        filter: false,
                    },
                },
            ]}
            options={{
                responsive: 'standard',
                selectableRows: 'none',
                filter: false,
                search: false,
                download: false,
                print: false,
                rowsPerPage: 10,
                customToolbar: () => (
                    <>
                        <DownloadCsvToolbarButton onClick={downloadReports} />
                    </>
                ),
            }}
        />
    );
}

type Props = {
    cashRegisterTransactionsByDay: Array<CashRegisterTransactionByDay>;
};
