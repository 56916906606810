/**
 * @prettier
 */
export const MexicanPaymentMethodCodes = {
    CASH: '01',
    NOMINAL_CHECK: '02',
    ELECTRONIC_FUNDS_TRANSFER: '03',
    CREDIT_CARD: '04',
    ELECTRONIC_WALLET: '05',
    ELECTRONIC_MONEY: '06',
    DEBIT_CARD: '28',
    SERVICE_CARD: '29',
    TO_DEFINE: '99',
} as const;

export type MexicanPaymentMethodCode = typeof MexicanPaymentMethodCodes[keyof typeof MexicanPaymentMethodCodes];
