/**
 * @prettier
 */
export const WebSocketEventTypes = {
    HEARTBEAT: 'HEARTBEAT',
    SUBSCRIBE_TO_RESTAURANT_EVENTS: 'SUBSCRIBE_TO_RESTAURANT_EVENTS',
    PRINT_WITH_REMOTE_PRINTER: 'PRINT_WITH_REMOTE_PRINTER',
    MENU_CREATED: 'MENU_CREATED',
    MENU_CHANGED: 'MENU_CHANGED',
    MENU_REMOVED: 'MENU_REMOVED',
    MENU_COPIED: 'MENU_COPIED',
    POS_MENU_CHANGED: 'POS_MENU_CHANGED',
    MENU_CREATED_FROM_IMPORTED_MENU: 'MENU_CREATED_FROM_IMPORTED_MENU',
    RESTAURANT_MENU_IMPORTED_FROM_EXTERNAL_URL: 'RESTAURANT_MENU_IMPORTED_FROM_EXTERNAL_URL',
    MENU_CATEGORY_CREATED: 'MENU_CATEGORY_CREATED',
    MENU_CATEGORY_CHANGED: 'MENU_CATEGORY_CHANGED',
    MENU_CATEGORY_REMOVED: 'MENU_CATEGORY_REMOVED',
    MENU_ITEM_CREATED: 'MENU_ITEM_CREATED',
    MENU_ITEM_CHANGED: 'MENU_ITEM_CHANGED',
    MENU_ITEM_REMOVED: 'MENU_ITEM_REMOVED',
    MENU_PRODUCT_INFO_CHANGED: 'MENU_PRODUCT_INFO_CHANGED',
    MODIFIER_GROUP_CREATED: 'MODIFIER_GROUP_CREATED',
    MODIFIER_GROUP_CHANGED: 'MODIFIER_GROUP_CHANGED',
    MODIFIER_GROUP_REMOVED: 'MODIFIER_GROUP_REMOVED',
    RESTAURANT_SETTINGS_CHANGED: 'RESTAURANT_SETTINGS_CHANGED',
    RESTAURANT_INVENTORY_LINKED: 'RESTAURANT_INVENTORY_LINKED',
    RESTAURANT_INVENTORY_UNLINKED: 'RESTAURANT_INVENTORY_UNLINKED',
    RESTAURANT_COURSE_ADDED: 'RESTAURANT_COURSE_ADDED',
    RESTAURANT_COURSE_REMOVED: 'RESTAURANT_COURSE_REMOVED',
    PAYMENT_METHOD_REMOVED: 'PAYMENT_METHOD_REMOVED',
    RESTAURANT_PAYMENT_METHOD_ADDED: 'RESTAURANT_PAYMENT_METHOD_ADDED',
    RESTAURANT_PAYMENT_METHOD_REMOVED: 'RESTAURANT_PAYMENT_METHOD_REMOVED',
    PAYMENT_METHOD_ADDED: 'PAYMENT_METHOD_ADDED',
    CASH_REGISTER_NEW_DEPOSIT: 'CASH_REGISTER_NEW_DEPOSIT',
    CASH_REGISTER_NEW_WITHDRAW: 'CASH_REGISTER_NEW_WITHDRAW',
    CASH_REGISTER_OPENED: 'CASH_REGISTER_OPENED',
    CASH_REGISTER_CLOSED: 'CASH_REGISTER_CLOSED',
    CASH_REGISTER_POS_BUSINESS_DAY_OPENED: 'CASH_REGISTER_POS_BUSINESS_DAY_OPENED',
    CASH_REGISTER_POS_BUSINESS_DAY_CLOSED: 'CASH_REGISTER_POS_BUSINESS_DAY_CLOSED',
    ASSIGNED_CASH_REGISTER_POS_BUSINESS_DAY_FOR_EXTERNAL_MISSING_ORDERS: 'ASSIGNED_CASH_REGISTER_POS_BUSINESS_DAY_FOR_EXTERNAL_MISSING_ORDERS',
    POS_BUSINESS_DAY_OPENED: 'POS_BUSINESS_DAY_OPENED',
    POS_BUSINESS_DAY_CLOSED: 'POS_BUSINESS_DAY_CLOSED',
    POS_BUSINESS_DAY_AND_CASH_REGISTER_POS_BUSINESS_DAY_OPENED: 'POS_BUSINESS_DAY_AND_CASH_REGISTER_POS_BUSINESS_DAY_OPENED',
    MAXIMUM_CASH_IN_CASH_REGISTER_REACHED: 'MAXIMUM_CASH_IN_CASH_REGISTER_REACHED',
    PRINT_AUTO_ACCEPTED_ORDER: 'PRINT_AUTO_ACCEPTED_ORDER',
} as const;

export type WebSocketEventType = typeof WebSocketEventTypes[keyof typeof WebSocketEventTypes];
