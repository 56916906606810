/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function integrateRestaurantWithRappiApi(request: integrateRestaurantWithRappiApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('rappiIntegration/integrateRestaurantWithRappiApi', request);
}

export type integrateRestaurantWithRappiApiRequest = {
    restaurantId: RestaurantId;
};
