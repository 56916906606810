/**
 * @prettier
 */
import { history } from 'src/config/history';
import TheMenuReducer from 'src/scenes/letseatmanager/deprecatedMenu/TheMenuReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function useCloseRestaurant(): any {
    const isAdminUser = useSelector((state) => state.authentication.adminUser);

    const reset = useAction(TheMenuReducer.reset);

    const closeRestaurant = () => {
        if (isAdminUser) {
            history.push({ search: '' });
        }
        reset();
    };

    return { closeRestaurant };
}
