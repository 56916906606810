/**
 * @prettier
 */
import { envENVIRONMENT } from 'src/env/envENVIRONMENT';
import { requireBooleanString } from 'src/utils/require/requireBooleanString';

export function envZENDESK_ENABLED(): boolean {
    try {
        return JSON.parse(requireBooleanString(process.env.REACT_APP_ZENDESK_ENABLED));
    } catch (e: any) {
        throw Error(`REACT_APP_ZENDESK_ENABLED is not configured correctly in .env.${envENVIRONMENT().toLowerCase()}`);
    }
}
