/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { acceptOrderApi } from 'src/api/letseatmanager/order/acceptOrderApi';
import { Button } from 'src/components/Button';
import { ChangePreparationTimeDialog } from 'src/components/dialog/order/ChangePreparationTimeDialog';
import { translate } from 'src/i18n/translate';
import { useFetchCashRegister } from 'src/services/cashRegister/useFetchCashRegister';
import { useHasOpenedCashRegister } from 'src/services/cashRegister/useHasOpenedCashRegister';
import { useManageOpenCashRegister } from 'src/services/cashRegister/useManageOpenCashRegister';
import { useNotification } from 'src/services/notification/useNotification';
import { usePrintOrderCommand } from 'src/services/printer/usePrintOrderCommand';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import { SentryService } from 'src/services/SentryService';
import type { OrderVm } from 'src/types/OrderVm';
import { getErrorDescriptionFromResponse } from 'src/utils/error/getErrorDescriptionFromResponse';
import { isNewOrder } from 'src/utils/order/isNewOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function OrderCardAcceptNewOrderButton({ order, disabled, updateLoadingOrder, onError, onChange }: Props): React.ReactElement | null {
    const classes = useStyles();

    const notification = useNotification();

    const [printOrderTicket] = usePrintOrderTicket();
    const [printOrderCommand] = usePrintOrderCommand();

    const hasOpenedCashRegister = useHasOpenedCashRegister();
    const { fetchOpenedCashRegister } = useFetchCashRegister();
    const { manageOpenCashRegister } = useManageOpenCashRegister();

    const [loading, setLoading] = useState(false);
    const [changePreparationTimeDialogState, setChangePreparationTimeDialogState] = useState({ open: false });

    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);
    const addManuallyOrderPreparationTimeEnabled = useSelector((state) => state.app.restaurant?.addManuallyOrderPreparationTimeEnabled);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);
    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);
    const openedCashRegister = useSelector((state) => state.pos.openedCashRegister);
    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const openedCashRegisterPosBusinessDay = useSelector((state) => state.pos.openedCashRegisterPosBusinessDay);

    useEffect(() => {
        if (pinCodeUserSignedIn) {
            fetchOpenedCashRegister();
        }
    }, [pinCodeUserSignedIn]);

    async function handleAcceptOrder() {
        if (!posEnabled) {
            await acceptOrder();
            return;
        }

        if ((!hasOpenedCashRegister && !posMultipleCashRegistersEnabled) || (posMultipleCashRegistersEnabled && !openedCashRegister)) {
            notification({ message: translate('To accept an order you must open the cash register') });

            manageOpenCashRegister({ forceOpenCashRegister: true });
            return;
        }

        await acceptOrder();
    }

    const acceptOrder = async () => {
        const request = {
            orderId: order.orderId,
            cashRegisterId: openedCashRegister?.cashRegisterId,
            cashRegisterPosBusinessDayId: openedCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId,
            posBusinessDayId: openedPosBusinessDay?.posBusinessDayId,
        } as const;

        updateLoadingOrder(true);
        setLoading(true);
        const response = await acceptOrderApi(request);
        updateLoadingOrder(false);
        setLoading(false);
        if (!response.ok) {
            SentryService.logError('Failed to accept order', { acceptOrderRequest: request, response });
            onError?.(`Failed to accept order cause of ${getErrorDescriptionFromResponse(response)}`);
            return;
        }
        SentryService.logInfoBreadcrumb('Successfully accepted order', { acceptOrderRequest: request });
        if (addManuallyOrderPreparationTimeEnabled) {
            openChangePreparationTimeDialog();
            return;
        }
        await printOrderTicket(order);
        await printOrderCommand(order);
        const orderVm = response.data;
        onChange?.(orderVm);
    };

    const openChangePreparationTimeDialog = () => {
        setChangePreparationTimeDialogState({ open: true });
    };

    const closeChangePreparationTimeDialog = () => {
        setChangePreparationTimeDialogState({ open: false });
    };

    const changePreparationTimeDialogSuccess = async (orderVm: OrderVm) => {
        await printOrderTicket(order);
        await printOrderCommand(order);
        onChange?.(orderVm);
    };

    if (!isNewOrder(order.orderStatus)) return null;

    return (
        <>
            <ChangePreparationTimeDialog open={changePreparationTimeDialogState.open} onClose={closeChangePreparationTimeDialog} onSuccess={changePreparationTimeDialogSuccess} order={order} />
            <Button disabled={disabled || loading} onClick={handleAcceptOrder} classes={{ button: classes.button }}>
                {translate('Accept')}
            </Button>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        width: '100%',
    },
}));

type Props = {
    order: OrderVm;
    disabled: boolean;
    onChange?: any;
    onError?: any;
    updateLoadingOrder: any;
};
