/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { InventoryTransactionReason } from 'src/constants/InventoryTransactionReason';
import type { InventoryTransactionType } from 'src/constants/InventoryTransactionType';
import type { UnitOfMeasurement } from 'src/constants/UnitOfMeasurement';
import type { CompanyId, ManagerUserId, OrderId, RestaurantId, SupplyId } from 'src/types/Id';
import type { InventoryTransactionVm } from 'src/types/InventoryTransactionVm';

export async function createInventoryTransactionApi(request: CreateInventoryTransactionApiRequest): ApiSauceResponse<InventoryTransactionVm> {
    return letseatmanagerApiMethod('inventory/createInventoryTransactionApi', request);
}

export type CreateInventoryTransactionApiRequest = {
    restaurantId: RestaurantId;
    companyId?: CompanyId;
    type: InventoryTransactionType;
    reason: InventoryTransactionReason;
    priceAtPurchase?: string;
    supplyId: SupplyId;
    orderId?: OrderId;
    unitOfMeasurement: UnitOfMeasurement;
    managerUserId?: ManagerUserId;
    quantity: string;
    notes?: string;
};
