/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import React, { memo } from 'react';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { PosCartItem } from 'src/scenes/letseatmanager/pos/posMenu/PosCartItem';
import type { CartItemVm } from 'src/types/CartItemVm';
import { formatTimeString } from 'src/utils/date/formatTimeString';
import { groupOrderItemsAndCancelledItemsByAddedDate } from 'src/utils/pos/groupOrderItemsAndCancelledItemsByAddedDate';

export const GroupedPosCartItems: React.ComponentType<Props> = memo(({ orderItems, cancelledItems }): React.ReactElement | null => {
    const classes = useStyles();

    const orderItemsGrouped = groupOrderItemsAndCancelledItemsByAddedDate<CartItemVm>(orderItems ?? [], cancelledItems ?? []);

    if (!((orderItems?.length ?? 0) > 0) && !cancelledItems?.length) return null;

    return (
        <>
            {orderItemsGrouped.map((currentOrderItems, idx) => {
                const groupDate = currentOrderItems[0].removedAt ?? currentOrderItems[0].addedAt;

                if (currentOrderItems.length === 0) return null;

                return (
                    <article className={classes.groupContainer} key={`${currentOrderItems[0].menuItemId}-${idx}`}>
                        <Text className={classes.dateText}>{groupDate ? formatTimeString(groupDate) : translate('Now')}</Text>
                        {currentOrderItems.map((orderItem, idx) => {
                            const isTheLastItemInCart = idx + 1 === currentOrderItems.length;
                            const isCancelledItem = !!orderItem.removedAt;
                            return (
                                <React.Fragment key={`${orderItem.menuItemId}-${idx}`}>
                                    {isCancelledItem && <div className={classes.cancelledItemText}>{translate('Cancelled Item')}</div>}
                                    <PosCartItem key={orderItem.menuItemId} item={orderItem} />
                                    {!isTheLastItemInCart && <div className={classes.posCartItemDivider}></div>}
                                </React.Fragment>
                            );
                        })}
                        <div className={classes.line}></div>
                    </article>
                );
            })}
        </>
    );
});

const useStyles = makeStyles((theme) => ({
    groupContainer: {
        borderRadius: 10,
        width: '100%',
        padding: '8px 12px 0px',
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    cancelledItemText: {
        fontFamily: theme.typography.medium,
        fontSize: 13,
        color: '#F51933',
    },
    dateText: {
        fontFamily: theme.typography.light,
        fontSize: 12,
        textAlign: 'left',
    },
    posCartItemDivider: {
        width: '100%',
        marginBottom: 10,
    },
    line: {
        width: '100%',
        height: 1,
        borderTop: '1px dashed #87888B',
        margin: '5px auto',
    },
}));

type Props = {
    orderItems?: Array<CartItemVm>;
    cancelledItems?: Array<CartItemVm>;
};
