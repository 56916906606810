/**
 * @prettier
 */
import { OrderStatus, OrderStatuses } from 'src/constants/OrderStatus';
import { PaymentStatus, PaymentStatuses } from 'src/constants/PaymentStatus';
import { appColors } from 'src/theme/AppColors';

export function getOrderStatusColor(orderStatus: OrderStatus, paymentStatus: PaymentStatus): string {
    if (paymentStatus === PaymentStatuses.FAILED) {
        return appColors.table.red;
    }
    switch (orderStatus) {
        case OrderStatuses.REJECTED:
            return appColors.table.red;
        case OrderStatuses.CANCELLED:
            return appColors.table.red;
        case OrderStatuses.COMPLETE: // TODO: Remove OrderStatus.COMPLETE when changed to COMPLETED on server and db
        case OrderStatuses.COMPLETED:
            return appColors.table.green;
        default:
            return appColors.table.transparent;
    }
}
