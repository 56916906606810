/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClockIcon from '@material-ui/icons/AccessTime';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { useContext, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';
import { TransformedTimePicker, transformToOutput } from 'src/components/form/material-ui/TransformedTimePicker';
import { getError } from 'src/components/form/utils/getError';
import { translate } from 'src/i18n/translate';

export function FormTimePicker({ name, label, placeholder, helperText, defaultValue = null, minutesStep = 5, disabled, required, variant = 'inline', amPm, className }: Props): React.ReactElement {
    const classes = useStyles();
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>();

    return (
        <Controller
            render={({ onChange, value, ref, ...props }) => (
                <TransformedTimePicker
                    label={label}
                    placeholder={placeholder}
                    /* @ts-ignore */
                    name={name}
                    helperText={getError(errors, name)?.message ?? helperText}
                    disabled={isSubmitting || disabled || formContext.disabled}
                    error={!!getError(errors, name)}
                    // inputProps={restInput}
                    onChange={onChange}
                    // onBlur={onBlur}
                    value={value === '' ? null : value}
                    format='LT'
                    ampm={amPm}
                    autoOk
                    fullWidth
                    variant={variant}
                    minutesStep={minutesStep}
                    required={required}
                    {...props}
                    InputProps={{
                        inputRef: inputRef,
                        startAdornment: (
                            <InputAdornment position='start'>
                                <ClockIcon fontSize='small' className={classes.iconColor} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <>
                                {!disabled && (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                onChange(null);
                                            }}
                                            size='small'
                                        >
                                            <CloseIcon fontSize='small' />
                                        </IconButton>
                                    </InputAdornment>
                                )}
                            </>
                        ),
                    }}
                />
            )}
            control={control}
            name={name}
            defaultValue={transformToOutput(defaultValue)}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    iconColor: {
        color: theme.palette.primary.main,
    },
    input: {
        marginTop: 0,
        border: `1px solid #d9d9d9`,
        borderRadius: 5,
        paddingRight: 5,
        paddingLeft: 5,
        '& > div': {
            '&::before': {
                display: 'none',
            },
            '&::after': {
                display: 'none',
            },
        },
        '& input': {
            fontFamily: theme.typography.medium,
            fontSize: 14,
            color: '#2E3748',
            textAlign: 'center',
            width: '60%',
        },
    },
}));

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    minutesStep?: number;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: 'dialog' | 'inline' | 'static';
    amPm?: boolean;
    className?: '';
};
