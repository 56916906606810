/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { ChangeMenuItem } from 'src/scenes/letseatmanager/menu/menuItem/ChangeMenuItem';
import { ChangeMenuItemDialog } from 'src/scenes/letseatmanager/menu/menuItem/ChangeMenuItemDialog';
import type { MenuItemId } from 'src/types/Id';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export function ChangeMenuItemSection({ menuItemId, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    const isSmallScreen = useIsSmallScreen();

    if (isSmallScreen) {
        return <ChangeMenuItemDialog open={true} menuItemId={menuItemId} onClose={onClose} onSuccess={onSuccess} />;
    }

    return (
        <div className={classes.container}>
            <ChangeMenuItem menuItemId={menuItemId} onClose={onClose} onSuccess={onSuccess} />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '40%',
    },
}));

type Props = {
    menuItemId: MenuItemId | undefined;
    onClose: any;
    onSuccess?: any;
};
