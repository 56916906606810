/**
 * @prettier
 */
import type { PromoCodeVm } from 'src/api/letseatmanager/types/PromoCodeVm';
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerId, RestaurantId } from 'src/types/Id';

export async function findPosValidPromoCodeApi(request: FindValidPromoCodeApiRequest): ApiSauceResponse<PromoCodeVm> {
    return letseatmanagerApiMethod('promoCode/findPosValidPromoCodeApi', request);
}

type FindValidPromoCodeApiRequest = {
    restaurantId: RestaurantId;
    customerId: CustomerId;
    code: string;
};
