/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import type { SalesByCustomer } from 'src/api/letseatmanager/restaurantDashboard/getCustomerRestaurantStatisticsApi';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function CustomerSalesTable({ loading, customerSalesData }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const customerData =
        customerSalesData?.map((itemMenu: SalesByCustomer) => ({
            customerName: itemMenu.customerName,
            mobileNumber: itemMenu.mobileNumber,
            meanSales: formatAsCurrencyNumber(itemMenu.meanSales || '0'),
            orderVolume: itemMenu.orderVolume,
            total: formatAsCurrencyNumber(itemMenu.total || '0'),
        })) || [];

    const customerReviewColumns = [
        {
            name: 'customerName',
            label: translate('Name'),
            options: {
                filter: true,
            },
        },
        {
            name: 'mobileNumber',
            label: translate('Mobile Number'),
            options: {
                filter: false,
            },
        },
        {
            name: 'meanSales',
            label: translate('Venta promedio'),
            options: {
                filter: false,
            },
        },
        {
            name: 'orderVolume',
            label: translate('Orders'),
            options: {
                filter: false,
            },
        },
        {
            name: 'total',
            label: translate('Total'),
            options: {
                filter: false,
            },
        },
    ];

    return (
        <div>
            <ReportSection title={translate('Customer Sales')} loading={loading}>
                <div className={classes.splitContainer}>
                    <div className={classes.sectionHeadKpi}>
                        <KpiCard
                            classes={{
                                container: classes.headingKpiContainer,
                                title: classes.headingKpiTitleKpi,
                                body: classes.headingKpiBody,
                                color: classes.headingKpiColor,
                                legend: classes.headingKpiLegendContainer,
                            }}
                            legend={customerData[0]?.customerName}
                            title={translate('Best Customer')}
                        />
                        <KpiCard
                            classes={{
                                container: classes.headingKpiContainer,
                                title: classes.headingKpiTitleKpi,
                                body: classes.headingKpiBody,
                                color: classes.headingKpiColor,
                                legend: classes.headingKpiLegendContainer,
                            }}
                            legend={customerData[0]?.total}
                            title={translate('Total purchase')}
                        />
                    </div>
                    <div className={classes.container}>
                        <Table
                            className={(classes as any).table}
                            data={customerData}
                            columns={customerReviewColumns}
                            options={{
                                tableBodyMaxHeight: '400px',
                                responsive: 'standard',
                                selectableRows: 'none',
                                filter: false,
                                rowsPerPage: 100,
                                viewColumns: false,
                                print: false,
                            }}
                        />
                    </div>
                </div>
            </ReportSection>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    splitContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',

        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'row-reverse',
            flexWrap: 'nowrap',
        },
    },
    sectionHeadKpi: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'column',
            width: '20%',
        },
    },
    headingKpiContainer: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '8px',
        margin: 5,
        textAlign: 'center',
        marginRight: '5%',
        marginBottom: '2%',
    },
    headingKpiTitleKpi: {
        fontFamily: theme.typography.regular,
        fontSize: 16,
        color: theme.palette.text.contrast,
    },
    headingKpiBody: {
        fontSize: 28,
        fontFamily: theme.typography.semiBold,
    },
    headingKpiColor: {
        width: 12,
        height: 12,
        borderRadius: 2,
    },
    headingKpiLegendContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.between('sm', 'lg')]: {
            justifyContent: 'left',
            textAlign: 'left',
            marginLeft: 40,
        },
    },
}));

type Props = {
    loading: boolean;
    customerSalesData: Array<SalesByCustomer>;
};
