/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { findRestaurantGlobalInvoicesApi } from 'src/api/letseatmanager/invoice/findRestaurantGlobalInvoicesApi';
import { getInvoiceUriApi } from 'src/api/letseatmanager/invoice/getInvoiceUriApi';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { PdfFileIcon } from 'src/icons/PdfFileIcon';
import { XmlFileIcon } from 'src/icons/XmlFileIcon';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { GlobalInvoiceSummaryVm } from 'src/types/GlobalInvoiceSummaryVm';
import { InvoiceId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { classNames } from 'src/utils/react/classNames';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { toShortId } from 'src/utils/uuid/toShortId';

export function GlobalOrdersInvoicesHistory(): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [from, setFrom] = useState(moment().startOf('day').toDate());
    const [to, setTo] = useState(moment().endOf('day').toDate());
    const [downloading, setDownloading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, globalInvoicesSummary, load] = useLoadApi(
        findRestaurantGlobalInvoicesApi,
        { restaurantId, to, from },
        {
            initialValue: { invoices: [] },
            dependencies: [from, to],
        }
    );

    const processDocumentData = (uri: string, createdAt: Date, fileType: 'pdf' | 'xml') => {
        const link = document.createElement('a');
        link.setAttribute('href', uri);
        link.setAttribute('download', `${translate('Invoiced')}${moment(createdAt).format('l')}.${fileType}`);
        document.body?.appendChild(link);
        link.click();
        document.body?.removeChild(link);
    };

    const handleDownloadInvoicePdf = async (invoice: GlobalInvoiceSummaryVm) => {
        setDownloading(true);
        const response = await getInvoiceUriApi({ invoiceId: invoice?.invoiceId, documentType: 'pdf' });

        if (!response?.ok) {
            setDownloading(false);
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        processDocumentData(response.data?.uri, invoice.createdAt, 'pdf');
        setDownloading(false);
    };

    const handleDownloadInvoiceXml = async (invoice: GlobalInvoiceSummaryVm) => {
        setDownloading(true);
        const response = await getInvoiceUriApi({ invoiceId: invoice?.invoiceId, documentType: 'xml' });

        if (!response?.ok) {
            setDownloading(false);
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        processDocumentData(response.data?.uri, invoice.createdAt, 'xml');
        setDownloading(false);
    };

    const columns = [
        {
            name: 'invoiceId',
            label: translate('No. invoice'),
            options: {
                filter: true,
                customBodyRender: (invoiceId: InvoiceId) => {
                    const invoice = globalInvoicesSummary?.invoices?.find((invoice: GlobalInvoiceSummaryVm) => invoice.invoiceId === invoiceId);

                    if (!invoice) return null;

                    return (
                        <div className={classes.rowContent}>
                            <span className={classes.link}>{`#${toShortId(invoiceId)}`}</span>
                            <Button onClick={() => handleDownloadInvoiceXml(invoice)} disabled={downloading} icon>
                                <XmlFileIcon title={translate('Download xml file icon')} />
                            </Button>
                            <Button onClick={() => handleDownloadInvoicePdf(invoice)} disabled={downloading} icon>
                                <PdfFileIcon title={translate('Download pdf file icon')} />
                            </Button>
                        </div>
                    );
                },
            },
        },
        {
            name: 'amount',
            label: translate('Amount'),
            options: {
                filter: true,
            },
        },
        {
            name: 'createdAt',
            label: translate('Expedition'),
            options: {
                filter: true,
            },
        },
        {
            name: 'period',
            label: translate('Period'),
            options: {
                filter: true,
            },
        },
        {
            name: 'invoiceType',
            label: translate('Type'),
            options: {
                filter: true,
            },
        },
    ];

    const handleChangeFromDate = (date: any) => setFrom(moment(date)?.startOf('day').toDate());
    const handleChangeToDate = (date: any) => setTo(moment(date)?.startOf('day').toDate());

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} bottom />
            <div className={classNames(classes.separatedContent, classes.filterContent)}>
                <div className={classes.datesContainer}>
                    <Input name={'from'} type={'date'} label={translate('From')} onChange={handleChangeFromDate} />
                    <Input name={'to'} type={'date'} label={translate('To')} onChange={handleChangeToDate} />
                </div>
            </div>
            <Table
                data={globalInvoicesSummary?.invoices?.map((invoice: GlobalInvoiceSummaryVm) => {
                    return {
                        invoiceId: invoice.invoiceId,
                        amount: formatAsCurrencyNumber(invoice.amount),
                        createdAt: moment(invoice.createdAt).format('YYYY/MM/DD HH:mm'),
                        period: `${moment(invoice.period?.from).format('l')}-${moment(invoice.period?.to).format('l')}`,
                        invoiceType: translate(`InvoiceTypes.${invoice.invoiceType || ''}`),
                    };
                })}
                columns={columns}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    filter: false,
                    print: false,
                    viewColumns: false,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                        </>
                    ),
                }}
                className={classes.table}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        fontSize: 14,
        fontFamily: theme.typography.regular,
        color: '#232933',
    },
    separatedContent: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    filterContent: {
        paddingTop: 20,
    },

    datesContainer: {
        display: 'flex',
        justifyContent: 'start',
        flexDirection: 'column',
        gap: 10,
        margin: '10px 0',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            margin: 0,
        },
    },
    table: {
        margin: '24px 0',
        paddingBottom: 24,
        minHeight: '40vh',
        overflowY: 'scroll',
        overflowX: 'scroll',
    },
    chip: {
        height: 30,
        fontSize: 12,
        backgroundColor: '#CBF7E9',
        padding: '10px 20px',
        borderRadius: 8,
        color: '#024B49',
        fontFamily: theme.typography.semiBold,
    },
    link: {
        textDecoration: 'underline',
        color: '#024B49',
        marginRight: 10,
    },
    rowContent: {
        display: 'flex',
        alignItems: 'center',
    },
}));
