/**
 * @prettier
 */
import * as React from 'react';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { AutoCompleteInputProp, Variant } from 'src/components/form/FormTypes';
import { TransformedTextField } from 'src/components/form/material-ui/TransformedTextField';
import { getError } from 'src/components/form/utils/getError';
import ImageUploader from 'src/components/input/ImageUploader';
import { ImageTypes } from 'src/constants/ImageType';
import { translate } from 'src/i18n/translate';

export function FormUrlImageField({
    name,
    label,
    placeholder,
    helperText,
    defaultValue = null,
    autoComplete = 'off',
    disabled,
    required,
    variant,
    InputProps,
    inputProps,
    tooltip,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const inputRef = useRef<HTMLInputElement>();

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <>
                    <TransformedTextField
                        {...props}
                        value={value}
                        onChange={onChange}
                        label={label}
                        placeholder={placeholder}
                        helperText={getError(errors, name)?.message ?? helperText}
                        trim
                        disabled={isSubmitting || disabled}
                        required={required}
                        error={!!getError(errors, name)}
                        inputRef={inputRef}
                        InputProps={InputProps}
                        inputProps={{ autoComplete, ...inputProps }}
                        InputLabelProps={{ shrink: placeholder ? true : undefined }}
                        variant={variant}
                        tooltip={tooltip}
                        fullWidth
                    />
                    <ImageUploader imageType={ImageTypes.RESTAURANT} targetName={name} onUploadSuccess={(logoImageUrl) => onChange(logoImageUrl)} />
                </>
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
                pattern: {
                    value: /^https:\/\/.*/,
                    message: translate('Invalid url. Url must start with https://'),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    autoComplete?: AutoCompleteInputProp;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    inputProps?: any;
    tooltip?: string;
};
