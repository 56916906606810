/**
 * @prettier
 */

import { usePosCartModifiersActions } from 'src/services/pos/posModifiersCart/posCartModifiersStore';

export function useSetPosCartModifiersQuantity(): SetPosCartModifiersQuantity {
    const setPosCartModifiersQuantity = usePosCartModifiersActions((actions) => actions.setQuantity);

    return setPosCartModifiersQuantity;
}

type SetPosCartModifiersQuantity = (quantity: number) => void;
