/**
 * @prettier
 */
export const ManagerUserActions = {
    ORDER_CANCELLATION: 'ORDER_CANCELLATION',
    MANUAL_ASSIGNMENT: 'MANUAL_ASSIGNMENT',
    MANUAL_EARNING: 'MANUAL_EARNING',
} as const;

export type ManagerUserAction = typeof ManagerUserActions[keyof typeof ManagerUserActions];
