/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderVm } from 'src/types/OrderVm';

export async function findSuspiciousOrdersApi(request: FindSuspiciousOrdersApiRequest): ApiSauceResponse<Array<OrderVm>> {
    return letseatadminApiMethod('order/suspiciousOrders', request);
}

export type FindSuspiciousOrdersApiRequest = {
    from: Date;
    to: Date;
};
