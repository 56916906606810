/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { getSalesByItemByManagerUserReportApi } from 'src/api/letseatmanager/pos/getSalesByItemByManagerUserReportApi';
import { getSalesByManagerUserByItemReportApi } from 'src/api/letseatmanager/pos/getSalesByManagerUserByItemReportApi';
import { Chip } from 'src/components/Chip';
import { Select } from 'src/components/Select';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { classNames } from 'src/utils/react/classNames';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function SalesByUserReport(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [selectedFilterMode, setSelectedFilterMode] = useState<FilterMode>(FilterModes.PER_USER);
    const [selectedFilter, setSelectedFilter] = useState<Array<any>>([null]);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [isLoadingSalesDataByItem, { salesByManagerUserByItem, availableItems, salesByManagerUsers }, refreshSalesDataByItem] = useLoadApi(
        getSalesByManagerUserByItemReportApi,
        {
            restaurantIds: [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).toISOString(),
            salesType,
        },
        {
            initialValue: { salesByManagerUserByItem: [], availableItems: [], salesByManagerUsers: [] },
            dependencies: [restaurantId, period?.from, period?.to, salesType],
        }
    );

    const [isLoadingSalesDataByItemReport, { salesByItemByManagerUser, availableUsers, salesByItems }, refreshSalesDataByUser] = useLoadApi(
        getSalesByItemByManagerUserReportApi,
        {
            restaurantIds: [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).toISOString(),
            salesType,
        },
        {
            initialValue: { salesByItemByManagerUser: [], availableUsers: [], salesByItems: [] },
            dependencies: [restaurantId, period?.from, period?.to, salesType],
        }
    );

    const handleFilterModeChange = (mode: FilterMode) => () => {
        setSelectedFilterMode(mode);
        setSelectedFilter([null]);
        mode === FilterModes.PER_PRODUCT ? refreshSalesDataByItem() : refreshSalesDataByUser();
    };

    const filterInitialSalesData = (selectedValues: Array<any>) => {
        if (selectedValues[0] === null) {
            return selectedFilterMode === FilterModes.PER_USER ? salesByItems : salesByManagerUsers;
        }

        return selectedFilterMode === FilterModes.PER_USER ? salesByItemByManagerUser[selectedValues[0]] : salesByManagerUserByItem[selectedValues[0]];
    };

    const allOption = { value: null, label: translate('All') } as const;

    const isPerUser = selectedFilterMode === FilterModes.PER_USER;
    const isPerProduct = selectedFilterMode === FilterModes.PER_PRODUCT;

    const sharedTableColumns = [
        {
            name: 'soldItems',
            label: translate('Sold Items'),
            options: {
                filter: false,
            },
        },
        {
            name: 'orders',
            label: translate('Orders'),
            options: {
                filter: false,
            },
        },
        {
            name: 'totalSales',
            label: translate('Total Sales'),
            options: {
                filter: false,
                customBodyRender: (value: string) => formatAsCurrencyNumber(value),
            },
        },
        {
            name: 'totalCommission',
            label: translate('Total Commission'),
            options: {
                filter: false,
                customBodyRender: (value: string) => formatAsCurrencyNumber(value),
            },
        },
    ];

    const getTableColumns = () => {
        if (selectedFilterMode === FilterModes.PER_PRODUCT) {
            return [
                {
                    name: 'name',
                    label: translate('User'),
                    options: {
                        filter: false,
                    },
                },

                ...sharedTableColumns,
            ];
        }

        return [
            {
                name: 'item',
                label: translate('Product'),
                options: {
                    filter: false,
                },
            },

            ...sharedTableColumns,
        ];
    };

    return (
        <div className={classes.container}>
            <Table
                className={(classes as any).root}
                loading={isLoadingSalesDataByItem || isLoadingSalesDataByItemReport}
                title={
                    <>
                        <div className={classes.toolbarContainer}>
                            {Object.values(FilterModes).map((filterMode) => {
                                const isSelected = selectedFilterMode === filterMode;

                                return (
                                    <Chip key={filterMode} classes={{ chip: classNames(classes.filterChip, { [classes.selected]: isSelected }) }} onClick={handleFilterModeChange(filterMode)}>
                                        {translate(`FilterModes.${filterMode}`)}
                                    </Chip>
                                );
                            })}

                            {isPerUser && (
                                <Select
                                    onChange={setSelectedFilter}
                                    options={[allOption, ...availableUsers.map((item) => ({ value: item, label: item }))]}
                                    classes={{ button: classes.selectButton }}
                                    name='user'
                                />
                            )}
                            {isPerProduct && (
                                <Select
                                    onChange={setSelectedFilter}
                                    options={[allOption, ...availableItems.map((item) => ({ value: item, label: item }))]}
                                    classes={{ button: classes.selectButton }}
                                    name='product'
                                />
                            )}
                        </div>
                    </>
                }
                data={filterInitialSalesData(selectedFilter)}
                columns={getTableColumns()}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    search: true,
                    rowsPerPage: 100,
                    filter: false,
                    viewColumns: false,
                }}
            />
        </div>
    );
}

const FilterModes = {
    PER_PRODUCT: 'PER_PRODUCT',
    PER_USER: 'PER_USER',
} as const;

export type FilterMode = typeof FilterModes[keyof typeof FilterModes];

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
    },
    toolbarContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 12,
    },
    filterChip: {
        fontFamily: theme.typography.regular,
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: 8,
        padding: '20px',
        fontSize: 12,
        height: 42,
    },
    label: {
        fontFamily: theme.typography.regular,
    },
    selected: {
        border: `1px solid ${theme.palette.border.brand}`,
        color: theme.palette.text.brand,
        backgroundColor: theme.palette.surface.brand,
    },
    selectButton: {
        maxWidth: 200,
        color: '#616B79 !important',
        margin: '0px !important',
        '&:hover': {
            color: '#616B79 !important',
            border: `1px solid #8A95A5 !important`,
            backgroundColor: 'transparent !important',
        },
    },
}));
