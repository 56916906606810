/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { findPendingTableOrderApi } from 'src/api/letseatmanager/order/findPendingTableOrderApi';
import { posReducer } from 'src/reducers/posReducer';
import { PosMenuEmptyState } from 'src/scenes/letseatmanager/pos/posMenu/PosMenuEmptyState';
import { PosContext } from 'src/scenes/letseatmanager/pos/PosProvider';
import { useLoadPosMenus } from 'src/services/menu/useLoadPosMenus';
import { useAction } from 'src/utils/react/useAction';
import { useEditPendingOrder } from 'src/utils/react/useEditPendingOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function PosMenuLoader({ children }: Props): React.ReactElement {
    const classes = useStyles();

    const editPendingOrder = useEditPendingOrder();
    const loadPosMenus = useLoadPosMenus();
    const { menuOpenedAt } = useContext(PosContext);

    const [loadingTableOrder, setLoadingTableOrder] = useState(false);
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const posMenu = useSelector((state) => state.pos.posMenu);
    const menus = useSelector((state) => state.pos.posMenu?.menus);
    const table = useSelector((state) => state.pos.table);
    const orderOpenedFromTable = useSelector((state) => state.pos.orderOpenedFromTable);
    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant.newMenuManagementEnabled);

    const setPosTable = useAction(posReducer.actions.setPosTable);

    useEffect(() => {
        load();
    }, [restaurantId]);

    const load = async () => {
        await Promise.allSettled([refreshPosMenu(), loadTableOrderInfo()]);
    };

    const refreshPosMenu = async () => {
        if (newMenuManagementEnabled) {
            if (posMenu?.menus?.length) return;
            setLoading(true);
            await loadPosMenus();
            setLoading(false);
            return;
        }

        if (menuOpenedAt) return;
        setLoading(true);
        await loadPosMenus();
        setLoading(false);
    };

    const loadTableOrderInfo = async () => {
        if (!table) return;
        setLoadingTableOrder(true);
        const response = await findPendingTableOrderApi({
            restaurantId,
            restaurantZoneId: table?.restaurantZoneId,
            table: table?.table,
        });
        setLoadingTableOrder(false);
        if (!response.ok) {
            return;
        }

        setPosTable({
            table,
            orderOpenedFromTable,
        });
        editPendingOrder({ order: response.data });
    };

    if (!posMenu || loading || loadingTableOrder) {
        return (
            <div className={classes.container}>
                <Loader size={40} loading={true} />
            </div>
        );
    }

    if (!menus.length) {
        return (
            <div className={classes.container}>
                <PosMenuEmptyState />
            </div>
        );
    }

    return <div className={classes.container}>{children}</div>;
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}));

type Props = {
    children: React.ReactNode;
};
