/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { ModifierGroupId } from 'src/types/Id';
import type { ModifierGroupVm } from 'src/types/ModifierGroupVm';

export async function getModifierGroupApi(request: GetModifierGroupApiRequest): ApiSauceResponse<ModifierGroupVm> {
    return letseatmanagerApiMethod('modifierGroup/getModifierGroupApi', request);
}

export type GetModifierGroupApiRequest = {
    modifierGroupId: ModifierGroupId;
};
