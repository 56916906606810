/**
 * @prettier
 */
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { PosBusinessDaySections } from 'src/constants/PosBusinessDaySections';
import { translate } from 'src/i18n/translate';
import { printerInstructionsBuilder } from 'src/services/printer/prints/utils/printerInstructionsBuilder';
import type { PrinterInstruction } from 'src/services/printer/types/PrinterInstruction';
import type { CashRegisterShiftReportVm } from 'src/types/CashRegisterShiftReportVm';
import type { RestaurantVm } from 'src/types/RestaurantVm';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { isIntegrationApp } from 'src/utils/restaurant/isIntegrationApp';
import { translatePaymentMethod } from 'src/utils/translate/translatePaymentMethod';

export function createCashRegisterShiftReportPrint({ cashRegisterShiftReport, restaurant }: Params): Array<PrinterInstruction> {
    const builder = printerInstructionsBuilder();

    addRestaurantInfoToReport();
    addGeneralInfo();
    addPaymentsReceived();
    addCashRegisterTransactions();
    addPaymentMethodsBreakdown();
    addCreditCardBreakdown();
    addIntegrationsBreakDown();
    addSalesByOrderType();
    addSalesByChannel();
    addSalesByManagerUser();
    addSalesByProduct();
    addPromoCodeSummary();
    addCancellationsReport();
    addCashRegisterReport();
    builder.addCenteredText(translate('With technology from Ambit.la'));

    return builder.build();

    function addRestaurantInfoToReport(): void {
        const { restaurantInformation } = cashRegisterShiftReport;
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.RESTAURANT_NAME)) builder.addCenteredBoldText(restaurantInformation.name);

        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.BUSINESS_NAME) && restaurantInformation.businessName) {
            builder.addCenteredText(restaurant.businessName);
        }
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.RFC) && restaurantInformation.rfc) {
            builder.addCenteredText(restaurant.rfc);
        }
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.RESTAURANT_STREET) && restaurantInformation.fiscalAddress) {
            builder.addCenteredText(restaurantInformation.fiscalAddress);
        }
    }

    function addGeneralInfo(): void {
        builder.addLineSeparator();
        builder.addCenteredBoldText(translate('SHIFT REPORT'));
        builder.addLineSeparator();

        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.DATE_OF_PRINT)) builder.addCenteredBoldText(formatDateTimeString(new Date()));
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.OPENING_DATE)) {
            builder.addSeparatedTexts(translate('Opened'), formatDateTimeString(cashRegisterShiftReport.cashRegisterSummary?.openedAt));
            if (cashRegisterShiftReport.cashRegisterSummary?.openedBy) builder.addSeparatedTexts(translate('Opened by'), cashRegisterShiftReport.cashRegisterSummary?.openedBy);
        }
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.CLOSING_DATE)) {
            builder.addSeparatedTexts(translate('Closed'), formatDateTimeString(cashRegisterShiftReport.cashRegisterSummary?.closedAt));
            if (cashRegisterShiftReport.cashRegisterSummary?.closedBy) builder.addSeparatedTexts(translate('Closed by'), cashRegisterShiftReport.cashRegisterSummary?.closedBy);
        }
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.SALES)) {
            builder.addSeparatedTexts(translate('Gross sales'), formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterSummary?.grossSales, { country: restaurant.country as any }) ?? 0);
            builder.addSeparatedTexts(translate('Delivery costs'), formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterSummary?.totalDeliveryCosts, { country: restaurant.country as any }));
            builder.addSeparatedTexts(translate('Total discounts'), formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterSummary?.totalDiscounts, { country: restaurant.country as any }));
            builder.addSeparatedTexts(translate('Total Sales'), formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterSummary?.totalSales, { country: restaurant.country as any }));
            if (cashRegisterShiftReport.cashRegisterSummary?.initialOrderId) builder.addSeparatedTexts(translate('Initial Folio'), cashRegisterShiftReport.cashRegisterSummary?.initialOrderId);
            if (cashRegisterShiftReport.cashRegisterSummary?.finalOrderId) builder.addSeparatedTexts(translate('Final Folio'), cashRegisterShiftReport.cashRegisterSummary?.finalOrderId);
        }
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.TIP))
            builder.addSeparatedTexts(translate('Tip'), formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterSummary?.totalTip ?? 0, { country: restaurant.country as any }));
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.ORDERS) && cashRegisterShiftReport.cashRegisterSummary?.orders)
            builder.addSeparatedTexts(translate('Orders'), cashRegisterShiftReport.cashRegisterSummary?.orders);
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.ORDERS) && cashRegisterShiftReport.cashRegisterSummary?.averageTicket)
            builder.addSeparatedTexts(translate('Average Ticket'), formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterSummary?.averageTicket, { country: restaurant.country as any }));
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.DINNERS))
            builder.addSeparatedTexts(translate('Diners'), cashRegisterShiftReport.cashRegisterSummary?.diners?.toString() ?? 0);
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.DINNERS))
            builder.addSeparatedTexts(
                translate('Average consumption'),
                formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterSummary?.averageSalesByConsumer, { country: restaurant.country as any })
            );
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.CANCELLED_ORDERS))
            builder.addSeparatedTexts(translate('Cancelled orders'), cashRegisterShiftReport.cashRegisterSummary?.totalCancelledOrders);
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.CANCELLED_ORDERS))
            builder.addSeparatedTexts(translate('Cancelled sales'), formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterSummary?.totalCancelledSales, { country: restaurant.country as any }));
    }

    function addPaymentsReceived(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.PAYMENTS_RECEIVED)) return;
        builder.addLineSeparator();
        builder.addCenteredBoldText(translate('PAYMENTS RECEIVED'));
        builder.addLineSeparator();
        builder.addBoldSeparatedTexts(translate('Medium'), translate('Total'));

        builder.addSeparatedTexts(
            translate('Cash received in store'),
            formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterPaymentsSummary?.totalCashReceived?.total, { country: restaurant.country as any })
        );
        builder.addSeparatedTexts(translate('Cash change'), formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterPaymentsSummary?.totalCashChange, { country: restaurant.country as any }));
        builder.addSeparatedTexts(
            translate('Card in store'),
            formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterPaymentsSummary?.totalOnlineCreditCardReceived?.total, { country: restaurant.country as any })
        );
        builder.addSeparatedTexts(
            translate('Card online'),
            formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterPaymentsSummary?.totalOnlineCreditCardReceived?.total, { country: restaurant.country as any })
        );
        cashRegisterShiftReport.cashRegisterPaymentsSummary?.totalCustomPaymentMethodsReceived?.forEach((customPayment: any) => {
            builder.addSeparatedTexts(customPayment.customPaymentMethod, formatAsCurrencyNumber(customPayment?.total, { country: restaurant.country as any }));
        });
        builder.addSeparatedTexts(
            translate('Integrations'),
            formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterPaymentsSummary?.totalIntegrationsReceived?.total, { country: restaurant.country as any })
        );
        builder.addBoldSeparatedTexts(translate('Total'), formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterPaymentsSummary?.grandTotal?.total, { country: restaurant.country as any }));
    }

    function addCashRegisterTransactions(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.CASH_REGISTER_MOVEMENTS)) return;

        builder.addLineSeparator();
        builder.addCenteredBoldText(translate('CASH REGISTER MOVEMENTS'));
        builder.addLineSeparator();
        builder.addSeparatedTexts(translate('Initial Amount'), formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterTransactionSummary?.initialAmount, { country: restaurant.country as any }));
        builder.addSeparatedTexts(translate('Cash sales'), formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterTransactionSummary?.cashSales ?? 0, { country: restaurant.country as any }));
        builder.addSeparatedTexts(translate('Total deposits'), formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterTransactionSummary?.deposits ?? 0, { country: restaurant.country as any }));
        builder.addSeparatedTexts(translate('Total withdraws'), formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterTransactionSummary?.withdraws ?? 0, { country: restaurant.country as any }));
        builder.addSeparatedTexts(
            translate('Total cash tip'),
            formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterTransactionSummary?.totalCashTips ?? 0, { country: restaurant.country as any })
        );
        builder.addSeparatedTexts(
            translate('Total card tip'),
            formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterTransactionSummary?.totalCreditCardTips ?? 0, { country: restaurant.country as any })
        );
        builder.addSeparatedTexts(
            translate('Total custom tip'),
            formatAsCurrencyNumber(cashRegisterShiftReport.cashRegisterTransactionSummary?.totalCustomPaymentMethodsTips ?? 0, { country: restaurant.country as any })
        );
        builder.addSeparatedTexts(translate('Total Balance'), formatAsCurrencyNumber(cashRegisterShiftReport?.cashRegisterTransactionSummary?.totalCash ?? 0, { country: restaurant.country as any }));
        builder.addNewLine();

        if (!!cashRegisterShiftReport.cashRegisterTransactions?.length) {
            builder.addBoldText(translate('Cash register movements'));
            cashRegisterShiftReport.cashRegisterTransactions?.forEach((cashRegisterTransaction: any) => {
                builder.addSeparatedTexts(cashRegisterTransaction.reason, formatAsCurrencyNumber(cashRegisterTransaction.amount, { country: restaurant.country as any }));
            });
        }
    }

    function addPaymentMethodsBreakdown(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.SALES_BY_PAYMENT)) return;
        if (!cashRegisterShiftReport?.cashRegisterPaymentMethodsBreakdown?.posBusinessDayPaymentMethodBreakdown?.length) return;
        const posBusinessDayPaymentMethodsBreakdown = cashRegisterShiftReport?.cashRegisterPaymentMethodsBreakdown?.posBusinessDayPaymentMethodBreakdown;
        const integrationsBreakdown = cashRegisterShiftReport?.cashRegisterPaymentMethodsBreakdown?.integrationsBreakdown;

        if (!posBusinessDayPaymentMethodsBreakdown?.length || !integrationsBreakdown?.length) return;

        builder.addLineSeparator();
        builder.addCenteredBoldText(translate('Payment methods breakdown'));
        builder.addLineSeparator();

        builder.addColumns([
            {
                percentageWidth: 0.3,
                text: translate('Medium'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Orders'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Tip'),
            },
            {
                percentageWidth: 0.3,
                text: translate('Total'),
            },
        ]);

        posBusinessDayPaymentMethodsBreakdown?.forEach((paymentBreakdown: any) => {
            builder.addColumns([
                {
                    percentageWidth: 0.3,
                    text: translatePaymentMethod({ paymentMethod: paymentBreakdown.paymentMethod, app: paymentBreakdown.app }),
                },
                {
                    percentageWidth: 0.2,
                    text: paymentBreakdown.orders,
                },
                {
                    percentageWidth: 0.2,
                    text: formatAsCurrencyNumber(paymentBreakdown.totalTips, { country: restaurant.country as any }),
                },

                {
                    percentageWidth: 0.3,
                    text: formatAsCurrencyNumber(paymentBreakdown.total, { country: restaurant.country as any }),
                },
            ]);
        });

        if (integrationsBreakdown) {
            integrationsBreakdown?.forEach((integration: any) => {
                builder.addColumns([
                    {
                        percentageWidth: 0.3,
                        text: translatePaymentMethod({ paymentMethod: integration.paymentMethod, app: integration.app }),
                    },
                    {
                        percentageWidth: 0.2,
                        text: integration.orders,
                    },
                    {
                        percentageWidth: 0.2,
                        text: formatAsCurrencyNumber(integration.totalTips, { country: restaurant.country as any }),
                    },
                    {
                        percentageWidth: 0.3,
                        text: formatAsCurrencyNumber(integration.total, { country: restaurant.country as any }),
                    },
                ]);
            });
        }

        builder.addColumns([
            {
                percentageWidth: 0.3,
                text: translate('Total'),
            },
            {
                percentageWidth: 0.2,
                text: cashRegisterShiftReport?.cashRegisterPaymentMethodsBreakdown?.grandTotal.orders,
            },
            {
                percentageWidth: 0.2,
                text: formatAsCurrencyNumber(cashRegisterShiftReport?.cashRegisterPaymentMethodsBreakdown?.grandTotal.totalTips, { country: restaurant.country as any }),
            },
            {
                percentageWidth: 0.3,
                text: formatAsCurrencyNumber(cashRegisterShiftReport?.cashRegisterPaymentMethodsBreakdown?.grandTotal.total, { country: restaurant.country as any }),
            },
        ]);
    }

    function addCreditCardBreakdown(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.CARD_BREAKDOWN)) return;
        if (!cashRegisterShiftReport.creditCardOrders?.creditCardOrders?.length) return;

        const creditCardsBreakdown = cashRegisterShiftReport.creditCardOrders?.creditCardOrders;

        builder.addLineSeparator();
        builder.addCenteredBoldText(translate('Credit card orders breakdown'));
        builder.addLineSeparator();

        builder.addColumns([
            {
                percentageWidth: 0.25,
                text: translate('Type'),
            },
            {
                percentageWidth: 0.25,
                text: translate('Order Id'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Tip'),
            },
            {
                percentageWidth: 0.3,
                text: translate('Total'),
            },
        ]);

        creditCardsBreakdown?.forEach((cardReport: any) => {
            builder.addColumns([
                {
                    percentageWidth: 0.25,
                    text: translate(`CardTypes.${cardReport.cardType}`),
                },
                {
                    percentageWidth: 0.25,
                    text: `#${cardReport.orderId}`,
                },
                {
                    percentageWidth: 0.2,
                    text: formatAsCurrencyNumber(cardReport.totalTips, { country: restaurant.country as any }),
                },

                {
                    percentageWidth: 0.3,
                    text: formatAsCurrencyNumber(cardReport.total, { country: restaurant.country as any }),
                },
            ]);
        });

        builder.addColumns([
            {
                percentageWidth: 0.25,
                text: translate('Total'),
            },
            {
                percentageWidth: 0.25,
                text: cashRegisterShiftReport.creditCardOrders?.grandTotal.orders,
            },
            {
                percentageWidth: 0.2,
                text: formatAsCurrencyNumber(cashRegisterShiftReport.creditCardOrders?.grandTotal.totalTips, { country: restaurant.country as any }),
            },
            {
                percentageWidth: 0.3,
                text: formatAsCurrencyNumber(cashRegisterShiftReport.creditCardOrders?.grandTotal.total, { country: restaurant.country as any }),
            },
        ]);
    }

    function addIntegrationsBreakDown(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.INTEGRATION_BREAKDOWN)) return;
        if (!cashRegisterShiftReport.integrationOrdersReport?.integrationOrders?.length) return;

        const integrationOrdersBreakdown = cashRegisterShiftReport.integrationOrdersReport?.integrationOrders;

        builder.addLineSeparator();
        builder.addCenteredBoldText(translate('Integration orders breakdown'));
        builder.addLineSeparator();

        builder.addColumns([
            {
                percentageWidth: 0.35,
                text: translate('Integration'),
            },
            {
                percentageWidth: 0.3,
                text: translate('Order Id'),
            },
            {
                percentageWidth: 0.35,
                text: translate('Total'),
            },
        ]);

        integrationOrdersBreakdown?.forEach((integration: any) => {
            builder.addColumns([
                {
                    percentageWidth: 0.35,
                    text: translate(integration.integration),
                },
                {
                    percentageWidth: 0.3,
                    text: `#${integration.orderId}`,
                },
                {
                    percentageWidth: 0.35,
                    text: formatAsCurrencyNumber(integration.total, { country: restaurant.country as any }),
                },
            ]);
        });

        builder.addColumns([
            {
                percentageWidth: 0.35,
                text: translate('Total'),
            },
            {
                percentageWidth: 0.3,
                text: cashRegisterShiftReport.integrationOrdersReport?.grandTotal.orders,
            },
            {
                percentageWidth: 0.35,
                text: formatAsCurrencyNumber(cashRegisterShiftReport.integrationOrdersReport?.grandTotal.total, { country: restaurant.country as any }),
            },
        ]);
    }

    function addSalesByOrderType(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.SALES_BY_ORDER_TYPE)) return;
        const salesByOrderType = cashRegisterShiftReport.salesByOrderTypeReport?.salesByOrderType;
        if (!salesByOrderType?.length) return;

        builder.addLineSeparator();
        builder.addBoldText(translate('Sales By Order Type'));
        builder.addLineSeparator();

        builder.addColumns([
            {
                percentageWidth: 0.45,
                text: translate('Type'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Orders'),
            },
            {
                percentageWidth: 0.35,
                text: translate('Total'),
            },
        ]);

        salesByOrderType?.forEach((orderTypeSales: any) => {
            builder.addColumns([
                {
                    percentageWidth: 0.45,
                    text: translate(orderTypeSales.orderType),
                },
                {
                    percentageWidth: 0.2,
                    text: orderTypeSales.orders,
                },
                {
                    percentageWidth: 0.35,
                    text: formatAsCurrencyNumber(orderTypeSales.total, { country: restaurant.country as any }),
                },
            ]);
        });

        builder.addColumns([
            {
                percentageWidth: 0.45,
                text: translate('Total'),
            },
            {
                percentageWidth: 0.2,
                text: cashRegisterShiftReport.salesByOrderTypeReport?.grandTotal.orders,
            },
            {
                percentageWidth: 0.35,
                text: formatAsCurrencyNumber(cashRegisterShiftReport.salesByOrderTypeReport?.grandTotal.total, { country: restaurant.country as any }),
            },
        ]);
    }

    function addSalesByChannel(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.SALES_BY_CHANNEL)) return;
        const salesByChannel = cashRegisterShiftReport.salesByAppReport?.salesByApp;
        if (!salesByChannel?.length) return;

        builder.addLineSeparator();
        builder.addBoldText(translate('Sales By Channel'));
        builder.addLineSeparator();

        builder.addColumns([
            {
                percentageWidth: 0.45,
                text: translate('Channel'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Orders'),
            },
            {
                percentageWidth: 0.35,
                text: translate('Total'),
            },
        ]);

        salesByChannel?.forEach((channelSales: any) => {
            builder.addColumns([
                {
                    percentageWidth: 0.45,
                    text: translate(channelSales.app),
                },
                {
                    percentageWidth: 0.2,
                    text: channelSales.orders,
                },
                {
                    percentageWidth: 0.35,
                    text: formatAsCurrencyNumber(channelSales.total, { country: restaurant.country as any }),
                },
            ]);
        });

        builder.addColumns([
            {
                percentageWidth: 0.45,
                text: translate('Total'),
            },
            {
                percentageWidth: 0.2,
                text: cashRegisterShiftReport.salesByAppReport?.grandTotal.orders,
            },
            {
                percentageWidth: 0.35,
                text: formatAsCurrencyNumber(cashRegisterShiftReport.salesByAppReport?.grandTotal.total, { country: restaurant.country as any }),
            },
        ]);
    }

    function addSalesByManagerUser(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.SALES_BY_MANAGER_USER)) return;
        const salesByManagerUser = cashRegisterShiftReport.salesByManagerUserReport?.salesByManagerUser;
        if (!salesByManagerUser?.length) return;

        builder.addLineSeparator();
        builder.addBoldText(translate('Sales by User'));
        builder.addLineSeparator();

        builder.addColumns([
            {
                percentageWidth: 0.45,
                text: translate('Employee'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Orders'),
            },
            {
                percentageWidth: 0.35,
                text: translate('Total'),
            },
        ]);

        salesByManagerUser?.forEach((managerSales: any) => {
            builder.addColumns([
                {
                    percentageWidth: 0.45,
                    text: managerSales.managerUser,
                },
                {
                    percentageWidth: 0.2,
                    text: managerSales.orders,
                },
                {
                    percentageWidth: 0.35,
                    text: formatAsCurrencyNumber(managerSales.total, { country: restaurant.country as any }),
                },
            ]);
        });

        builder.addColumns([
            {
                percentageWidth: 0.45,
                text: translate('Total'),
            },
            {
                percentageWidth: 0.2,
                text: cashRegisterShiftReport.salesByManagerUserReport?.grandTotal?.orders,
            },
            {
                percentageWidth: 0.35,
                text: formatAsCurrencyNumber(cashRegisterShiftReport.salesByManagerUserReport?.grandTotal?.total, { country: restaurant.country as any }),
            },
        ]);
    }

    function addSalesByProduct(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.TOP_SALES_PRODUCT)) return;
        const salesByItem = cashRegisterShiftReport.salesByItemReport?.salesByItem;
        if (!salesByItem?.length) return;

        builder.addLineSeparator();
        builder.addBoldText(translate('Sales By Product'));
        builder.addLineSeparator();

        builder.addColumns([
            {
                percentageWidth: 0.4,
                text: translate('Name'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Price'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Orders'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Total'),
            },
        ]);

        salesByItem?.forEach((itemSales: any) => {
            builder.addColumns([
                {
                    percentageWidth: 0.4,
                    text: itemSales.name,
                },
                {
                    percentageWidth: 0.2,
                    text: formatAsCurrencyNumber(itemSales.itemPrice, { country: restaurant.country as any }),
                },
                {
                    percentageWidth: 0.2,
                    text: itemSales.orders,
                },
                {
                    percentageWidth: 0.2,
                    text: formatAsCurrencyNumber(itemSales.total, { country: restaurant.country as any }),
                },
            ]);
        });
    }

    function addPromoCodeSummary(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.PROMO_CODES_SUMMARY)) return;
        const promoCodeReport = cashRegisterShiftReport.promoCodeReport;
        if (!promoCodeReport?.length) return;

        builder.addLineSeparator();
        builder.addBoldText(translate('Promo Codes'));
        builder.addLineSeparator();

        builder.addColumns([
            {
                percentageWidth: 0.35,
                text: translate('Code'),
            },
            {
                percentageWidth: 0.3,
                text: translate('Usage'),
            },
            {
                percentageWidth: 0.35,
                text: translate('Total'),
            },
        ]);

        promoCodeReport?.forEach((managerSales: any) => {
            builder.addColumns([
                {
                    percentageWidth: 0.35,
                    text: managerSales.code,
                },
                {
                    percentageWidth: 0.3,
                    text: `${managerSales.usage}`,
                },
                {
                    percentageWidth: 0.35,
                    text: formatAsCurrencyNumber(managerSales.amount, { country: restaurant.country as any }),
                },
            ]);
        });

        builder.addColumns([
            {
                percentageWidth: 0.35,
                text: translate('Total'),
            },
            {
                percentageWidth: 0.3,
                text: cashRegisterShiftReport.salesByManagerUserReport?.grandTotal?.orders,
            },
            {
                percentageWidth: 0.35,
                text: formatAsCurrencyNumber(cashRegisterShiftReport.salesByManagerUserReport?.grandTotal?.total, { country: restaurant.country as any }),
            },
        ]);
    }

    function addCancellationsReport(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.CANCELLED_ORDERS)) return;
        const cancelledOrders = cashRegisterShiftReport.cancelledOrdersReport?.cancelledOrders;
        if (!cancelledOrders?.length) return;

        builder.addLineSeparator();
        builder.addBoldText(translate('Cancellations'));
        builder.addLineSeparator();

        builder.addSeparatedTexts(translate('Order Id'), translate('Total'));

        cancelledOrders?.forEach((cancelledOrder: any) => {
            builder.addSeparatedTexts(`#${cancelledOrder.orderId}`, cancelledOrder.total);
        });

        builder.addSeparatedTexts(translate('Total'), cashRegisterShiftReport.cancelledOrdersReport?.grandTotal?.total);
    }

    function addCashRegisterReport(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.CASH_REGISTER_COUNTING)) return;

        builder.addLineSeparator();
        builder.addCenteredBoldText(translate('SHIFT CASH REGISTER DETAILS'));
        builder.addLineSeparator();

        builder.addCenteredBoldText(cashRegisterShiftReport.cashRegisterSummary?.closedBy ?? '');
        let totalDifference = '0';
        let totalAmount = '0';
        let totalExpectedAmount = '0';

        addCashRegisterReportInfoHeader({
            paymentMethod: translate('Medium'),
            amount: translate('Amount'),
            expected: translate('Exp.'),
            difference: translate('Diff'),
        });

        cashRegisterShiftReport.cashRegisterReport?.forEach((paymentMethodReport: any) => {
            const difference = (parseFloat(paymentMethodReport.amount) - parseFloat(paymentMethodReport.expectedAmount)).toString();
            totalDifference = (parseFloat(totalDifference) + parseFloat(difference)).toString();
            totalAmount = (parseFloat(totalAmount) + parseFloat(paymentMethodReport.amount)).toString();
            totalExpectedAmount = (parseFloat(totalExpectedAmount) + parseFloat(paymentMethodReport.expectedAmount)).toString();
            let paymentMethod = translate(paymentMethodReport.customPaymentMethod ?? paymentMethodReport.paymentMethod);
            const paymentMethodApp = isIntegrationApp(paymentMethodReport.app!) ? translate(paymentMethodReport.app) : '';
            paymentMethod = !!paymentMethodApp ? `${paymentMethod} ${paymentMethodApp}` : paymentMethod;

            addCashRegisterReportInfo({
                paymentMethod,
                amount: formatAsCurrencyNumber(paymentMethodReport.amount ?? 0, { country: restaurant.country as any }),
                expected: formatAsCurrencyNumber(paymentMethodReport.expectedAmount ?? 0, { country: restaurant.country as any }),
                difference: formatAsCurrencyNumber(difference ?? 0, { country: restaurant.country as any }),
            });
        });

        addCashRegisterReportInfoTotal({
            paymentMethod: translate('Total'),
            amount: formatAsCurrencyNumber(totalAmount, { country: restaurant.country as any }),
            expected: formatAsCurrencyNumber(totalExpectedAmount, { country: restaurant.country as any }),
            difference: formatAsCurrencyNumber(totalDifference, { country: restaurant.country as any }),
        });
        builder.addLineSeparator();
    }

    function addCashRegisterReportInfoHeader(cashRegisterReport: CashRegisterReportRow): void {
        builder.addBoldColumns([
            {
                percentageWidth: 0.2,
                text: cashRegisterReport.paymentMethod,
                textAlign: 'left',
            },
            {
                percentageWidth: 0.26,
                text: cashRegisterReport.amount,
                textAlign: 'right',
            },
            {
                percentageWidth: 0.27,
                text: cashRegisterReport.expected,
                textAlign: 'right',
            },
            {
                percentageWidth: 0.27,
                text: cashRegisterReport.difference,
                textAlign: 'right',
            },
        ]);
    }

    function addCashRegisterReportInfo(cashRegisterReport: CashRegisterReportRow): void {
        builder.addColumns([
            {
                percentageWidth: 0.2,
                text: cashRegisterReport.paymentMethod,
                textAlign: 'left',
            },
            {
                percentageWidth: 0.26,
                text: cashRegisterReport.amount,
                textAlign: 'right',
            },
            {
                percentageWidth: 0.27,
                text: cashRegisterReport.expected,
                textAlign: 'right',
            },
            {
                percentageWidth: 0.27,
                text: cashRegisterReport.difference,
                textAlign: 'right',
            },
        ]);
    }

    function addCashRegisterReportInfoTotal(cashRegisterReport: CashRegisterReportRow): void {
        builder.addBoldColumns([
            {
                percentageWidth: 0.2,
                text: cashRegisterReport.paymentMethod,
                textAlign: 'left',
            },
            {
                percentageWidth: 0.26,
                text: cashRegisterReport.amount,
                textAlign: 'right',
            },
            {
                percentageWidth: 0.27,
                text: cashRegisterReport.expected,
                textAlign: 'right',
            },
            {
                percentageWidth: 0.27,
                text: cashRegisterReport.difference,
                textAlign: 'right',
            },
        ]);
    }
}

type Params = {
    cashRegisterShiftReport: CashRegisterShiftReportVm;
    restaurant: RestaurantVm;
};

type CashRegisterReportRow = {
    paymentMethod: string;
    amount: string;
    expected: string;
    difference: string;
};
