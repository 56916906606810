/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

export function NumericKey({ value, onPress }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.number} onClick={() => onPress(value)}>
            {value}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    number: {
        display: 'grid',
        placeItems: 'center',
        fontSize: 18,
        height: 45,
        width: 45,
        borderRadius: 25,
        backgroundColor: `${theme.palette.primary.main}15`,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.regular,
        [theme.breakpoints.up('sm')]: {
            border: `1px solid ${theme.palette.primary.main}`,
            height: 55,
            width: 80,
            borderRadius: 5,
        },
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main}30`,
        },
    },
}));

type Props = {
    value: string;
    onPress: any;
};
