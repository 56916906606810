/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import { PosPayment } from 'src/types/PosPayment';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PosPaymentTotalTicketItem(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const payTotalTicketSelected = useSelector((state) => state.pos.payTotalTicketSelected);
    const total = useSelector((state) => state.pos.payment?.total);
    const payments = useSelector((state) => state.pos.payments);
    const customers = useSelector((state) => state.pos.customers);

    const clearCustomerNumberSelected = useAction(posReducer.actions.clearCustomerNumberSelected);
    const setPayTotalTicketSelected = useAction(posReducer.actions.setPayTotalTicketSelected);
    const setNumericPadValue = useAction(posReducer.actions.setNumericPadValue);

    const hasPaymentByCustomer = payments?.some((payment: PosPayment) => payment.customerNumber);
    const someCustomerHasTips = customers?.some((customer: PosCustomerOrderDetails) => !!customer.tips?.length);
    const someCustomerHasDiscount = customers?.some((customer: PosCustomerOrderDetails) => customer.payment?.posDiscount);
    const payTotalTicketDisabled = hasPaymentByCustomer || someCustomerHasDiscount || someCustomerHasTips;

    useEffect(() => {
        if (payTotalTicketSelected) {
            setNumericPadValue(total);
        }
    }, [total]);

    const handleSelectPaymentCustomer = () => {
        if (payTotalTicketDisabled) return;
        clearCustomerNumberSelected();
        setNumericPadValue(total);
        if (!payTotalTicketSelected) setPayTotalTicketSelected(!payTotalTicketSelected);
    };

    return (
        <div className={classNames(classes.container, payTotalTicketSelected ? classes.active : payTotalTicketDisabled ? classes.disabled : '')} onClick={handleSelectPaymentCustomer}>
            <span>{translate('All order')}</span>
            <span>{formatAsCurrencyNumber(total ?? 0)}</span>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        fontFamily: theme.typography.regular,
        fontSize: 14,
        padding: '8px 18px',
        borderRadius: 10,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        border: `1px solid ${theme.palette.border.primary}`,
        margin: '5px 0',
    },

    active: {
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.text.brand}`,
        backgroundColor: `${theme.palette.surface.brand}70`,
    },
    disabled: {
        backgroundColor: theme.palette.surface.secondary,
    },
}));
