/**
 * @prettier
 */
export const OwnFleetDriverStatuses = {
    PENDING: 'PENDING',
    REJECTED: 'REJECTED',
    ACCEPTED: 'ACCEPTED',
} as const;

export type OwnFleetDriverStatus = typeof OwnFleetDriverStatuses[keyof typeof OwnFleetDriverStatuses];
