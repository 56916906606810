/**
 * @prettier
 */
export const NotificationMessageTypes = {
    EMAIL: 'EMAIL',
    SMS: 'SMS',
    PUSH: 'PUSH',
} as const;

export type NotificationMessageType = typeof NotificationMessageTypes[keyof typeof NotificationMessageTypes];
