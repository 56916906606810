/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { updateNumberOfCustomersApi } from 'src/api/letseatmanager/order/updateNumberOfCustomersApi';
import { NumericPad } from 'src/components/NumericPad';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { alertKnownErrorOrSomethingWentWrongError } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrongError';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangeNumberOfCustomersDialog({ open, handleClose, order }: Props): React.ReactElement {
    const classes = useStyles();

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const handleSubmit = async (numberOfCustomers: any) => {
        setError(false);
        if (loading) return;
        if (!order) return;

        if (!Boolean(numberOfCustomers) || BigNumber(numberOfCustomers).toNumber() === 0) {
            return setError(true);
        }

        if (!!order?.customers?.length && BigNumber(order?.customers?.length).isGreaterThan(numberOfCustomers)) {
            return alertKnownErrorOrSomethingWentWrongError(translate('It is not possible to reduce the number of diners when the order is divided by customers'));
        }

        const newCustomers = Array.from(
            {
                length: BigNumber(numberOfCustomers ?? 0)
                    .minus(order?.customers?.length ?? 0)
                    .toNumber(),
            },
            (value, index) => {
                const customers = order.customers?.length || 0;
                const customerNumber = index + 1 + customers;
                return {
                    customerNumber: customerNumber,
                    orderItems: [],
                    cancelledItems: [],
                    total: '0',
                    subtotal: '0',
                };
            }
        );

        setLoading(true);
        const response = await updateNumberOfCustomersApi({
            restaurantId,
            orderId: order?.orderId,
            numberOfCustomers: BigNumber(numberOfCustomers).toNumber(),
            newCustomers,
        });
        setLoading(false);

        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        handleClose();
    };

    return (
        <Dialog scroll='paper' open={open} style={{ minWidth: 300 }} onClose={handleClose} onExit={handleClose}>
            <DialogTitle classes={{ root: classes.dialogTitle }}>
                <div>{translate('Change Number of Customers')}</div>
            </DialogTitle>
            <DialogContent style={{ margin: '0 auto 0 auto' }}>
                <div className={classes.bodyText}>{translate('Number of Customers')}</div>
                <NumericPad onSubmit={handleSubmit} onChange={() => setError(false)} />
                {error && <p className={classNames(classes.bodyText, classes.errorText)}>{translate('Value is empty')}</p>}
            </DialogContent>
            <DialogActions className={classes.buttonsContainer}>
                <Button className={classes.cancelButton} variant={'outlined'} onClick={handleClose} disabled={loading}>
                    {translate('Cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.primary.main,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    bodyText: {
        fontFamily: theme.typography.medium,
        textAlign: 'center',
        color: theme.palette.secondary.contrastText,
    },

    errorText: {
        color: 'red',
    },

    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },

    cancelButton: {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 150,
        textTransform: 'none',
    },
}));

type Props = {
    open: boolean;
    handleClose: any;
    order?: OrderVm;
};
