/**
 * @prettier
 */
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MultipleEmailsInput } from 'src/components/input/MultipleEmailsInput';
import { translate } from 'src/i18n/translate';

export function FormRestaurantStatisticsReportEmails({ name, required, defaultValue, disabled }: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();

    return (
        <Controller
            name={name}
            render={({ value, onChange, ...props }) => (
                <MultipleEmailsInput emails={value} label={translate('Statistics report emails recipients')} disabled={isSubmitting} onChangeEmails={onChange} />
            )}
            control={control}
            defaultValue={defaultValue}
            required={required}
            disabled={disabled || isSubmitting}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
            }}
        />
    );
}

type Props = {
    name: string;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    defaultValue?: Array<string>;
    disabled?: boolean;
};
