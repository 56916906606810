/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { createSubscriptionApi } from 'src/api/letseatadmin/subscription/createSubscriptionApi';
import { Form } from 'src/components/form/Form';
import { FormBrandAutocomplete } from 'src/components/form/FormBrandAutocomplete';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormRestaurantAutocomplete } from 'src/components/form/FormRestaurantAutocomplete';
import { FormRestaurantsAutocomplete } from 'src/components/form/FormRestaurantsAutocomplete';
import { FormSubscriptionPlansSelect } from 'src/components/form/FormSubscriptionPlansSelect';
import { FormTextField } from 'src/components/form/FormTextField';
import { SubscriptionPlans } from 'src/constants/SubscriptionPlan';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { withoutIva } from 'src/utils/number/withoutIva';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateSubscriptionDialog({ open, onClose, onSubscriptionCreated }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
        control,
    } = form;

    const settingPayPerUseRange1OrdersLimit = useSelector((state) => state.app2.settings.payPerUseRange1OrdersLimit);
    const settingPayPerUseRange1PaymentAmount = useSelector((state) => state.app2.settings.payPerUseRange1PaymentAmount);
    const settingPayPerUseRange2OrdersLimit = useSelector((state) => state.app2.settings.payPerUseRange2OrdersLimit);
    const settingPayPerUseRange2PaymentAmount = useSelector((state) => state.app2.settings.payPerUseRange2PaymentAmount);
    const settingPayPerUseRangeMaxOrdersLimit = useSelector((state) => state.app2.settings.payPerUseRangeMaxOrdersLimit);
    const settingPayPerUseRangeMaxPaymentAmount = useSelector((state) => state.app2.settings.payPerUseRangeMaxPaymentAmount);

    const plan = useWatch({ name: 'plan', control });
    const payPerUseRange1PaymentAmount = useWatch<string>({ name: 'payPerUseRange1PaymentAmount', control });
    const payPerUseRange2PaymentAmount = useWatch<string>({ name: 'payPerUseRange2PaymentAmount', control });
    const payPerUseRangeMaxPaymentAmount = useWatch<string>({ name: 'payPerUseRangeMaxPaymentAmount', control });

    const isCommission = plan === SubscriptionPlans.COMMISSION;
    const isPayPerUse = plan === SubscriptionPlans.PAY_PER_USE;

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        const response = await createSubscriptionApi({
            restaurantId: form.restaurantId,
            restaurantIds: form.restaurantIds,
            brandId: form.brandId,
            plan: form.plan,
            managerMessage: form.managerMessage,
            comment: form.comment,
            invoiceDescription: form.invoiceDescription,
            payPerUseRange1PaymentAmount: form.payPerUseRange1PaymentAmount,
            payPerUseRange2PaymentAmount: form.payPerUseRange2PaymentAmount,
            payPerUseRangeMaxPaymentAmount: form.payPerUseRangeMaxPaymentAmount,
            paymentAmount: form.paymentAmount,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSubscriptionCreated();
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Create Subscription').toUpperCase()}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormBrandAutocomplete name='brandId' label={translate('Brand')} helperText={translate('Create and manage brands at https://admin.pidedirecto.mx/admin/brands')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormRestaurantAutocomplete name='restaurantId' label={translate('Main Restaurant')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormRestaurantsAutocomplete name='restaurantIds' label={translate('Restaurants')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSubscriptionPlansSelect name='plan' label={translate('Plan')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                name='managerMessage'
                                label={translate('Manager Message')}
                                helperText={translate('This text is will be shown right under the header in the subscriptions page for restaurants')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='comment' label={translate('Comment')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='invoiceDescription' label={translate('Invoice Description')} />
                        </Grid>
                        {isPayPerUse && (
                            <>
                                <Grid item xs={12}>
                                    <FormCurrencyNumberStringField
                                        name='payPerUseRange1PaymentAmount'
                                        label={translate('Range 1 Payment Amount')}
                                        placeholder={settingPayPerUseRange1PaymentAmount}
                                        helperText={translate(
                                            `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                            {
                                                amount: formatAsCurrencyNumber(payPerUseRange1PaymentAmount || settingPayPerUseRange1PaymentAmount),
                                                amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRange1PaymentAmount || settingPayPerUseRange1PaymentAmount)),
                                                orders: settingPayPerUseRange1OrdersLimit,
                                            }
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormCurrencyNumberStringField
                                        name='payPerUseRange2PaymentAmount'
                                        label={translate('Range 2 Payment Amount')}
                                        placeholder={settingPayPerUseRange2PaymentAmount}
                                        helperText={translate(
                                            `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                            {
                                                amount: formatAsCurrencyNumber(payPerUseRange2PaymentAmount || settingPayPerUseRange2PaymentAmount),
                                                amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRange2PaymentAmount || settingPayPerUseRange2PaymentAmount)),
                                                orders: settingPayPerUseRange2OrdersLimit,
                                            }
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormCurrencyNumberStringField
                                        name='payPerUseRangeMaxPaymentAmount'
                                        label={translate('Range Max Payment Amount')}
                                        placeholder={settingPayPerUseRangeMaxPaymentAmount}
                                        helperText={translate(
                                            `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                            {
                                                amount: formatAsCurrencyNumber(payPerUseRangeMaxPaymentAmount || settingPayPerUseRangeMaxPaymentAmount),
                                                amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRangeMaxPaymentAmount || settingPayPerUseRangeMaxPaymentAmount)),
                                                orders: settingPayPerUseRangeMaxOrdersLimit,
                                            }
                                        )}
                                    />
                                </Grid>
                            </>
                        )}
                        {!isPayPerUse && !isCommission && (
                            <Grid item xs={12}>
                                <FormCurrencyNumberStringField name='paymentAmount' label={translate('Amount')} required />
                            </Grid>
                        )}
                    </Grid>
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Creating').toUpperCase() : translate('Create').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    onClose: any;
    onSubscriptionCreated: any;
};
