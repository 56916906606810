/**
 * @prettier
 */
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import * as React from 'react';
import { AppMenuLink } from 'src/components/app/appMenu/AppMenuLink';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function KitchensAppMenu(): React.ReactElement | null {
    const adminUser = useSelector((state) => state.authentication.adminUser);
    const viewUser = useSelector((state) => state.authentication.viewUser);
    const kitchensUser = useSelector((state) => state.authentication.kitchensUser);

    if (!adminUser && !viewUser && !kitchensUser) return null;

    return (
        <List subheader={<ListSubheader disableSticky>{translate('Kitchen')}</ListSubheader>}>
            <AppMenuLink routePath={RoutePaths.DRIVER_DELIVERY_ITEMS_PAGE} text={translate('Delivery Items')} data-testid='driverAppMenu.deliveryItemsLink' />
        </List>
    );
}
