/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentLinkId, RestaurantId } from 'src/types/Id';

export async function removePaymentLinkApi(request: PaymentLinkRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('paymentLink/removePaymentLinkApi', request);
}

type PaymentLinkRequest = {
    paymentLinkId: PaymentLinkId;
    restaurantId: RestaurantId;
};
