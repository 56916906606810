/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PosBusinessDayId } from 'src/types/Id';

export async function sendEndOfDayReportEmailApi(request: SendPosBusinessDayReportEmailRequest): ApiSauceResponse<void> {
    return pidedirectoReportsMethod('/sendEndOfDayReportEmailApi', request);
}

export type SendPosBusinessDayReportEmailRequest = {
    posBusinessDayId: PosBusinessDayId;
};
