/**
 * @prettier
 */
import { CustomDiscountComment, CustomDiscountComments } from 'src/constants/CustomDiscountComment';
import { translate } from 'src/i18n/translate';

export function translateCustomDiscountComment(discountComment: CustomDiscountComment | string): string {
    const defaultDiscountComments = Object.values(CustomDiscountComments);
    if (!defaultDiscountComments.includes(discountComment as any)) {
        return discountComment;
    }

    return translate(`CustomDiscountComments.${discountComment}`);
}
