/**
 * @prettier
 */
import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function PosRouter(): React.ReactElement {
    const classes = useStyles();
    const location = useLocation();
    const posRestaurantZonesEnabled = useSelector((state) => state.pos.context?.posRestaurantZonesEnabled);

    return (
        <div className={classes.container}>
            <Typography
                className={classNames(classes.posButton, location.pathname === RoutePaths.POS ? classes.linkActive : '')}
                component={ForwardRefNavLink}
                to={{
                    pathname: RoutePaths.POS,
                    search: location.search,
                }}
            >
                {translate('Menu')}
            </Typography>
            <SecuredContent rolePermission={RolePermissions.CASH_REGISTER_PAGE}>
                <Typography
                    className={classNames(classes.posButton, location.pathname === RoutePaths.POS_CASH_REGISTERS ? classes.linkActive : '')}
                    component={ForwardRefNavLink}
                    to={{
                        pathname: RoutePaths.POS_CASH_REGISTERS,
                        search: location.search,
                    }}
                >
                    {translate('Cash registers')}
                </Typography>
            </SecuredContent>
            {posRestaurantZonesEnabled && (
                <SecuredContent rolePermission={RolePermissions.RESTAURANT_ZONES_PAGE}>
                    <Typography
                        className={classNames(classes.posButton, location.pathname === RoutePaths.POS_TABLES ? classes.linkActive : '')}
                        component={ForwardRefNavLink}
                        to={{
                            pathname: RoutePaths.POS_TABLES,
                            search: location.search,
                        }}
                    >
                        {translate('Tables')}
                    </Typography>
                </SecuredContent>
            )}
            <Typography
                className={classNames(classes.posButton, location.pathname === RoutePaths.ORDERS ? classes.linkActive : '')}
                component={ForwardRefNavLink}
                to={{
                    pathname: RoutePaths.ORDERS,
                    search: location.search,
                }}
            >
                {translate('Orders')}
            </Typography>
        </div>
    );
}

const ForwardRefNavLink = React.forwardRef<HTMLAnchorElement, React.ComponentProps<NavLink>>((props, ref) => <NavLink innerRef={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 24,
        marginBottom: 42,
        padding: '0 24px',
        paddingTop: 12,
        [theme.breakpoints.up('sm')]: {
            width: '75%',
            marginTop: 30,
        },
    },
    posButton: {
        color: theme.palette.text.contrast,
        fontFamily: theme.typography.regular,
        fontSize: 14,
        textTransform: 'none',
        textDecoration: 'none',
        paddingBottom: '8px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    linkActive: {
        borderBottom: `2px solid ${theme.palette.surface.brandContrast}`,
        color: theme.palette.text.brand,
    },
}));
