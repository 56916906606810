/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { RestaurantCurrency } from 'src/types/RestaurantCurrency';

export async function addCurrencyApi(request: AddCurrencyApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurant/addCurrencyApi', request);
}

export type AddCurrencyApiRequest = {
    restaurantId: RestaurantId;
    restaurantCurrency: RestaurantCurrency;
};
