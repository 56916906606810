/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import type { CartItemVm } from 'src/types/CartItemVm';
import { calculateOrderItemModifierGroupsPrice } from 'src/utils/pos/calculateOrderItemModifierGroupsPrice';

export function calculateOrderItemPrice(orderItem: CartItemVm): BigNumber {
    return BigNumber(orderItem.promoUnitPrice ?? orderItem.unitPrice)
        .plus(calculateOrderItemModifierGroupsPrice(orderItem.modifierGroups))
        .multipliedBy(orderItem.quantity);
}
