/**
 * @prettier
 */
import { PosState } from 'src/reducers/posReducer';
import type { MenuItemVm } from 'src/types/PosMenuVm';
import { createCartItemKey } from 'src/utils/pos/createCartItemKey';
import { mapOrderCoursesToPosCourses } from 'src/utils/pos/mapOrderCoursesToPosCourses';
import { mapOrderCustomersToPosCustomers } from 'src/utils/pos/mapOrderCustomersToPosCustomers';
import { mapOrderItemsToCartItems } from 'src/utils/pos/mapOrderItemsToCartItems';
import { mapOrderItemToCartItem } from 'src/utils/pos/mapOrderItemToCartItem';

export function setPendingOrderInfo(state: PosState) {
    if (state.items.length) return;

    const allItems = state.posMenu?.menuItems ?? [];

    for (const orderItem of state.pendingOrder?.orderItems ?? []) {
        const menuItem = allItems?.find((item: MenuItemVm) => item.menuItemId === orderItem.menuItemId);
        if (menuItem) {
            const item = mapOrderItemToCartItem(menuItem, orderItem);

            const posItem = { ...item, key: createCartItemKey(item) } as const;

            const existingPosItem = state.items.find((current) => current.key === posItem.key);

            if (existingPosItem) {
                existingPosItem.quantity = existingPosItem.quantity + posItem.quantity;
            } else {
                state.items.push(posItem);
            }
        }
    }

    state.items = mapOrderItemsToCartItems({ orderItems: state.pendingOrder?.orderItems ?? [], menuItems: allItems });

    state.cancelledItems = mapOrderItemsToCartItems({ orderItems: state.pendingOrder?.cancelledItems ?? [], menuItems: allItems });

    if (!!state.pendingOrder?.customers?.length && !state.customers?.length) {
        const customers = mapOrderCustomersToPosCustomers(state.pendingOrder?.customers, allItems, state.payments);

        state.customers = customers;

        state.customerNumberSelected = 1;
    }

    if (!!state.pendingOrder?.courses?.length && !state.ongoingCourses?.length) {
        state.ongoingCourses = mapOrderCoursesToPosCourses(state.pendingOrder?.courses, allItems, state.payments);

        state.courseIdSelected = state.pendingOrder?.courses?.[0].courseId;
        state.takeOrderByCourse = true;
    }
}
