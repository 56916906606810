/**
 * @prettier
 */
import * as React from 'react';
import { PosPaymentMethodDeprecated } from 'src/components/pos/PosPaymentMethodDeprecated';
import { PosPaymentMethods } from 'src/components/pos/PosPaymentMethods';
import { PaymentMethod } from 'src/constants/PaymentMethod';
import type { CustomPaymentMethod } from 'src/types/Id';
import { useSelector } from 'src/utils/react/useSelector';

export function PosPaymentMethodSelect({ disabled, onSelect, selectedPaymentMethod }: Props): React.ReactElement {
    const restaurantPaymentMethodsEnabled = useSelector((state) => state.pos.context?.restaurantPaymentMethodsEnabled);

    if (restaurantPaymentMethodsEnabled) return <PosPaymentMethods onSelect={onSelect} selectedPaymentMethod={selectedPaymentMethod} disabled={disabled} />;

    return <PosPaymentMethodDeprecated onSelect={onSelect} selectedPaymentMethod={selectedPaymentMethod} disabled={disabled} />;
}

type Props = {
    disabled?: boolean;
    onSelect: any;
    selectedPaymentMethod: PaymentMethod | CustomPaymentMethod | undefined;
};
