/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import type { RestaurantEmployeeDayShiftVm } from 'src/types/RestaurantEmployeeDayShiftVm';
import { classNames } from 'src/utils/react/classNames';
import { useClock } from 'src/utils/react/useClock';

export function PinCodeUserDaySummary({ employeeDayShift }: Props): React.ReactElement | null {
    const classes = useStyles();
    const [, date] = useClock();

    const workedHours = Math.abs(moment(employeeDayShift?.clockedInAt).diff(date, 'hours')).toString();

    if (!employeeDayShift) return null;

    return (
        <div className={classes.container}>
            <div className={classes.infoContainer}>
                <div className={classNames(classes.kpiContainer)}>
                    <p className={classes.kpiValue}>{workedHours ?? '0'}</p>
                    <p className={classes.kpiLabel}>{translate('Worked hours')}</p>
                </div>
                <div className={classNames(classes.kpiContainer)}>
                    <p className={classes.kpiValue}>{employeeDayShift.totalAttendedTables ?? 0}</p>
                    <p className={classes.kpiLabel}>{translate('Attended tables')}</p>
                </div>
                <div className={classNames(classes.kpiContainer)}>
                    <p className={classes.kpiValue}>{employeeDayShift.totalDiners ?? 0}</p>
                    <p className={classes.kpiLabel}>{translate('Diners')}</p>
                </div>
                <div className={classNames(classes.kpiContainer)}>
                    <p className={classes.kpiValue}>{employeeDayShift.averageTicket ?? 0}</p>
                    <p className={classes.kpiLabel}>{translate('Average ticket')}</p>
                </div>
                <div className={classNames(classes.kpiContainer)}>
                    <p className={classes.kpiValue}>{employeeDayShift.totalOrders ?? 0}</p>
                    <p className={classes.kpiLabel}>{translate('Orders')}</p>
                </div>
                <div className={classNames(classes.kpiContainer)}>
                    <p className={classes.kpiValue}>{employeeDayShift.totalTips ?? 0}</p>
                    <p className={classes.kpiLabel}>{translate('Tips')}</p>
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 60,
    },
    infoContainer: {
        display: 'grid',
        width: '90%',
        margin: 'auto',
        gridTemplateColumns: 'repeat(2, 1fr)',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
            rowGap: 10,
            columnGap: 20,
        },
    },
    kpiContainer: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 140,
        minHeight: 70,
        alignContent: 'center',
        justifyContent: 'start',
    },
    kpiValue: {
        fontFamily: theme.typography.semiBold,
        margin: 0,
        fontSize: 18,
        marginBottom: 5,
        color: theme.palette.text.primary,
        [theme.breakpoints.up('sm')]: {
            fontSize: 24,
        },
    },
    kpiLabel: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        margin: 0,
        color: theme.palette.text.secondary,
        [theme.breakpoints.up('sm')]: {
            fontSize: 16,
        },
    },
}));

type Props = {
    employeeDayShift?: RestaurantEmployeeDayShiftVm;
};
