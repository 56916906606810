/**
 * @prettier
 */
import { useEffect } from 'react';
import { app2 } from 'src/app2';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function useOnline<T>() {
    const adminUser = useSelector((state) => state.authentication.adminUser);
    const signedIn = useSelector((state) => state.authentication.signedIn);
    const setOnline = useAction(app2.actions.setOnline);
    const closeNoInternetConnectionDialog = useAction(app2.actions.closeNoInternetConnectionDialog);
    const openNoInternetConnectionDialog = useAction(app2.actions.openNoInternetConnectionDialog);

    useEffect(() => {
        if (adminUser || !signedIn) {
            window.addEventListener('online', internetConnected);
            window.addEventListener('offline', noInternetConnection);
        }
        return () => {
            if (adminUser || signedIn) {
                window.removeEventListener('online', internetConnected);
                window.removeEventListener('offline', noInternetConnection);
            }
        };
    }, [adminUser, signedIn]);

    const noInternetConnection = () => {
        setOnline(false);
        openNoInternetConnectionDialog();
    };
    const internetConnected = () => {
        setOnline(true);
        closeNoInternetConnectionDialog();
    };
}
