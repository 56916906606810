/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { DriverId, OrderId } from 'src/types/Id';

export async function getDriverPositionApi(request: GetDriverPositionApiRequest): ApiSauceResponse<DriverPositionVm> {
    return letseatmanagerApiMethod('driverPosition/getDriverPositionApi', request);
}

export type GetDriverPositionApiRequest = {
    orderId: OrderId;
};

export type DriverPositionVm = {
    driverId: DriverId;
    position?: GeoJsonPoint;
    positionedAt?: Date;
};
