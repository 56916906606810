/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { classNames } from 'src/utils/react/classNames';

export function DialogActions({ children, className }: Props): React.ReactElement {
    const classes = useStyles();

    return <div className={classNames(classes.container, className)}>{children}</div>;
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 10,
        alignItems: 'center',
        marginTop: 20,
        flexWrap: 'wrap',
        '& button': {
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 200,
            },
        },
    },
}));

type Props = {
    children?: React.ReactNode;
    className?: string;
};
