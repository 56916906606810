/**
 * @prettier
 */
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { findSubscriptionPayPerUsePaymentsApi } from 'src/api/letseatadmin/subscriptionPayPerUsePayments/findSubscriptionPayPerUsePaymentsApi';
import { Form } from 'src/components/form/Form';
import { FormDatePicker } from 'src/components/form/FormDatePicker';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { Page } from 'src/components/Page';
import { translate } from 'src/i18n/translate';
import { SubscriptionPayPerUsePaymentsTable } from 'src/scenes/letseatadmin/subscriptionPayPerUsePayments/SubscriptionPayPerUsePaymentsTable';
import { appLayout } from 'src/theme/AppLayout';
import { useLoadApi } from 'src/utils/react/useLoadApi';

export function SubscriptionPayPerUsePaymentsPage(): React.ReactElement {
    const form = useForm();
    const { control } = form;

    const defaultFrom = moment().startOf('isoWeek').toDate();
    const from = useWatch({ name: 'from', control, defaultValue: defaultFrom });
    const defaultTo = moment().endOf('isoWeek').toDate();
    const to = useWatch({ name: 'to', control, defaultValue: defaultTo });

    const [refresh, setRefresh] = useState(0);

    const [loadingSubscriptionPayPerUsePayments, { totalAmountLeftToInvoice, totalAmountInvoiced, payments }, refreshSubscriptionPayPerUsePayments] = useLoadApi(
        findSubscriptionPayPerUsePaymentsApi,
        { from: from.toString(), to: to.toString() },
        { initialValue: { totalAmountLeftToInvoice: 0, totalAmountInvoiced: 0, payments: [] }, dependencies: [from, to] }
    );

    const handlePressLastWeek = () => {
        form.setValue('from', moment(from).subtract(1, 'week').toDate());
        form.setValue('to', moment(to).subtract(1, 'week').toDate());
    };

    const handlePressNextWeek = () => {
        form.setValue('from', moment(from).add(1, 'week').toDate());
        form.setValue('to', moment(to).add(1, 'week').toDate());
    };

    return (
        <Page title={translate('Subscription Pay Per Use Payments')}>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Form form={form} onSubmit={refreshSubscriptionPayPerUsePayments} enabledForUserTypeView={true} style={appLayout.dialog.form}>
                        <Card style={{ padding: 24 }}>
                            <Grid container direction='row' spacing={3} alignItems='center'>
                                <Grid item>
                                    <FormDatePicker name='from' label={translate('From')} defaultValue={defaultFrom} />
                                    <Link onClick={handlePressLastWeek}>Last Week</Link>
                                </Grid>
                                <Grid item>
                                    <FormDatePicker name='to' label={translate('To')} defaultValue={defaultTo} />
                                    <Link onClick={handlePressNextWeek}>Next Week</Link>
                                </Grid>
                                <Grid item>
                                    <FormSubmitButton text={translate('REFRESH')} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Form>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h6'>Total Amount Left To Invoice: {formatAsCurrencyNumber(totalAmountLeftToInvoice)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h6'>Total Invoiced Amount: {formatAsCurrencyNumber(totalAmountInvoiced)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <SubscriptionPayPerUsePaymentsTable payments={payments as any} loading={loadingSubscriptionPayPerUsePayments} onClickRefresh={refreshSubscriptionPayPerUsePayments} />
                </Grid>
            </Grid>
        </Page>
    );
}
