/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { Country } from 'src/constants/Country';
import type { Currency } from 'src/constants/Currency';
import type { PaymentRejectReason } from 'src/constants/PaymentRejectReason';
import type { PaymentStatus } from 'src/constants/PaymentStatus';
import type { TimeZone } from 'src/constants/TimeZone';
import type { CreditCardId, CustomerId, OrderId, PaymentId, RestaurantId, SurveyId, TipId } from 'src/types/Id';

export async function getTipApi(request: GetTipApiRequest): ApiSauceResponse<TipVm> {
    return letseatadminApiMethod('tip/getTipApi', request);
}

export type GetTipApiRequest = {
    tipId: TipId;
};

export type TipVm = {
    tipId: TipId;
    customerId: CustomerId;
    restaurantId: RestaurantId;
    orderId: OrderId;
    surveyId: SurveyId;
    paymentId?: PaymentId;
    creditCardId?: CreditCardId;
    paymentStatus?: PaymentStatus;
    paymentRejectReason?: PaymentRejectReason;
    amount: string;
    country: Country;
    timeZone: TimeZone;
    currency: Currency;
    createdAt: Date;
    modifiedAt: Date;
};
