/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';

export function EllipseColorIcon({ color }: Props): React.ReactElement {
    const theme = useTheme();
    const classes = useStyles();

    return (
        <svg className={classes.icon} width='30' height='35' viewBox='0 0 30 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='8' cy='8' r='7.5' fill={color} stroke='white' />
        </svg>
    );
}

const useStyles = makeStyles((theme) => ({
    icon: {
        width: 40,
        maxHeight: 40,
        marginTop: '15px',
    },
}));

type Props = {
    color: string;
};
