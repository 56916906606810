/**
 * @prettier
 */
import type { DiscountType } from 'src/constants/DiscountType';
import type { KitchenStatus } from 'src/constants/KitchenStatus';
import type { OrderStatus } from 'src/constants/OrderStatus';
import type { OrderType } from 'src/constants/OrderType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { PickupTimeType } from 'src/constants/PickupTimeType';
import type { TipType } from 'src/constants/TipType';
import type { CourseOrderDetailsVm } from 'src/types/CourseOrderDetailsVm';
import type { CustomerOrderDetailsVm } from 'src/types/CustomerOrderDetailsVm';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type {
    CashRegisterId,
    CashRegisterPosBusinessDayId,
    CustomerId,
    DeliveryEstimateId,
    GooglePlaceId,
    ManagerUserId,
    OrderId,
    PosBusinessDayId,
    PromoCodeId,
    RestaurantId,
    RestaurantZoneId,
} from 'src/types/Id';
import type { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import type { OrderItemVm } from 'src/types/OrderVm';
import type { PosPaymentVm } from 'src/types/PosPaymentVm';
import type { PosTipVm } from 'src/types/PosTipVm';

export const KEY_NAME = 'posOrdersOffline';

export function getPosOrdersOfflineInLocalStorage(): Array<PosOrderOffline> {
    const item = window.localStorage.getItem('posOrdersOffline');
    return item ? JSON.parse(item) : [];
}

export type PosOrderOffline = {
    orderId?: OrderId;
    temporalOrderId: OrderId;
    restaurantId: RestaurantId;
    restaurantZoneId?: RestaurantZoneId;
    waiterId?: ManagerUserId;
    customerId?: CustomerId;
    promoCodeId?: PromoCodeId;
    cashRegisterId?: CashRegisterId;
    posBusinessDayId?: PosBusinessDayId;
    cashRegisterPosBusinessDayId?: CashRegisterPosBusinessDayId;
    orderType: OrderType;
    orderStatus?: OrderStatus;
    kitchenStatus?: 'PREPARING' | 'READY' | KitchenStatus;
    firstName?: string;
    lastName?: string;
    mobileNumber?: string;
    email?: string;
    customerNote?: string;
    address?: {
        location: GeoJsonPoint;
        street: string;
        number: string;
        neighborhood: string;
        postalCode?: string;
        other?: string;
        instructions?: string;
        googlePlaceId: GooglePlaceId;
        formattedAddress?: string;
        name?: string; // sent for debugging reasons
    };
    deliveryEstimateId?: DeliveryEstimateId;
    deliveryCost?: string;
    deliveryPaidByRestaurant?: boolean;
    deliveryPaidByPideDirecto?: boolean;
    paymentMethod: PaymentMethod;
    posPayments: Array<PosPaymentVm>;
    payments: Array<OrderPaymentVm>;
    partiallyPaidByCash?: boolean;
    partiallyPaidByCashAmount?: string;
    qpayCommission?: string;
    table?: string;
    notes?: string;
    numberOfCustomers?: number;
    pickupTimeType?: PickupTimeType;
    orderItems: Array<OrderItemVm>;
    cancelledItems: Array<OrderItemVm>;
    posDiscountPercentage?: string;
    posDiscount?: {
        discountType: DiscountType;
        discount: string;
        discountPercentage?: string;
        notes?: string;
    };
    posTip?: {
        tip: string;
        tipType: TipType;
    };
    tips?: Array<PosTipVm>;
    useLetsEatCredits?: boolean;
    usedCustomerCredits?: string;
    pickupTime?: Date;
    createdAt?: Date;
    completedAt?: Date;
    modifiedAt?: Date;
    customers?: Array<CustomerOrderDetailsVm>;
    courses?: Array<CourseOrderDetailsVm>;
};
