/**
 * @prettier
 */
import { countAnsiEscapeSequenceStylingCharacters } from 'src/utils/string/countAnsiEscapeSequenceStylingCharacters';
import { isString } from 'src/utils/string/isString';

export function padEnd<T extends string | undefined | null>(value: T, maxLength: number, fillString?: string): T {
    if (value === undefined || value === null || !isString(value)) {
        return value;
    }

    // Solve issue with ansi styling messing padding up
    maxLength = maxLength + countAnsiEscapeSequenceStylingCharacters(value);

    return value.padEnd(maxLength, fillString) as any;
}
