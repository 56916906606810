/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderId, RestaurantId } from 'src/types/Id';

export async function partialRefundOngoingOrderApi(request: RefundOngoingOrderApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('order/partialRefundOngoingOrderApi', request);
}

export type RefundOngoingOrderApiRequest = {
    restaurantId: RestaurantId;
    orderId: OrderId;
    amount: string;
};
