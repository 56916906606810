/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PosBusinessDayId } from 'src/types/Id';
import { PosBusinessDaySummaryVm } from 'src/types/PosBusinessDaySummaryVm';

export async function getPosBusinessDaySummaryApi(request: GetPosBusinessDaySummaryApiRequest): ApiSauceResponse<PosBusinessDaySummaryVm> {
    return letseatmanagerApiMethod('pos/getPosBusinessDaySummaryApi', request);
}

export type GetPosBusinessDaySummaryApiRequest = {
    posBusinessDayId: PosBusinessDayId;
};
