/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { MenuItemTaxesReport } from 'src/types/MenuItemTaxesReporVm';

export async function getMenuItemTaxesReportApi(request: GetMenuItemTaxesReportApiRequest): ApiSauceResponse<GetMenuItemTaxesReportApiResponse> {
    return pidedirectoReportsMethod('/getMenuItemTaxesReportApi', request);
}

export type GetMenuItemTaxesReportApiRequest = {
    report: string;
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
};

export type GetMenuItemTaxesReportApiResponse = Array<{
    restaurantId: RestaurantId;
    menuItemTaxesReport: Array<MenuItemTaxesReport>;
}>;
