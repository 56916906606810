/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormAutocompleteMultiple } from 'src/components/form/FormAutocompleteMultiple';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormRadio } from 'src/components/form/FormRadio';
import { FormRadioGroup } from 'src/components/form/FormRadioGroup';
import { FormTextField } from 'src/components/form/FormTextField';
import { Tooltip } from 'src/components/Tooltip';
import { history } from 'src/config/history';
import { Days } from 'src/constants/Day';
import { translate } from 'src/i18n/translate';
import type { BenefitPlanId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { sortDays } from 'src/utils/day/sortDays';
import { deleteUrlQueryParameter } from 'src/utils/history/deleteUrlQueryParameter';
import { getUrlQueryParameter } from 'src/utils/history/getUrlQueryParameter';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { combine, greaterThanZero, integer, required, timeRange } from 'src/utils/Validator';

export function ChangeBenefitPlanDialog({ onBenefitPlanChanged }: Props): React.ReactElement {
    const classes = useStyles();
    const location = useLocation();
    const form = useForm();

    const benefitPlanId: BenefitPlanId = getUrlQueryParameter(CHANGE_BENEFIT_PLAN);

    const distributeOncePerWeek = form.watch('distributeOncePerWeek');
    const days = form.watch('days');

    const [changeBenefitPlanDialogState, setChangeBenefitPlanDialogState] = useState({ open: false });

    const dayOptions = Object.keys(Days)?.map((day: string) => ({
        value: Days[day as keyof typeof Days],
        label: translate(`Days.${Days[day as keyof typeof Days]}`),
    }));

    const [loading, benefitPlan] = useLoadApi(letseatmanagerApiDeprecated.admin.getBenefitPlan, { benefitPlanId }, { requiredValues: [benefitPlanId] });

    useEffect(() => {
        if (benefitPlanId && benefitPlan) {
            setChangeBenefitPlanDialogState({ open: true });
        }

        return () => {
            setChangeBenefitPlanDialogState({ open: false });
        };
    }, [benefitPlanId, benefitPlan]);

    useEffect(() => {
        if (benefitPlan?.benefitPlanId) {
            form.reset({
                ...benefitPlan,
                days: benefitPlan.days.split(',') ?? [],
            });
        }
    }, [benefitPlan]);

    const handleCloseChangeBenefitPlanDialog = () => {
        if (form.formState?.isSubmitting || loading) return;
        setChangeBenefitPlanDialogState({ open: false });

        if (history?.length <= 2) {
            history.replace({
                path: location.pathname,
                search: deleteUrlQueryParameter(CHANGE_BENEFIT_PLAN),
            });
            return;
        }
        history.goBack();
    };

    const onSubmit = async (form: any) => {
        const response = await letseatmanagerApiDeprecated.admin.changeBenefitPlan({
            benefitPlanId: benefitPlanId,
            name: form.name,
            credits: form.credits,
            distributeOncePerWeek: form.distributeOncePerWeek,
            restrictedToDelivery: form.restrictedToDelivery,
            oneProductPerDay: form.oneProductPerDay,
            meals: parseInt(form.meals),
            days: sortDays(form.days).join(','),
            hours: form.hours,
            paused: form.paused === 'true',
        } as ChangeBenefitPlanRequest);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onBenefitPlanChanged();
        handleCloseChangeBenefitPlanDialog();
    };

    const handleRemoveBenefitPlan = async () => {
        const remove = await window.confirm(translate('Are you sure you want to remove this benefit plan?'));
        if (remove) {
            await removeBenefitPlan();
        }
    };

    const removeBenefitPlan = async () => {
        const benefitPlanId: BenefitPlanId = getUrlQueryParameter(CHANGE_BENEFIT_PLAN);
        const response = await letseatmanagerApiDeprecated.admin.removeBenefitPlan({ benefitPlanId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        onBenefitPlanChanged(true);
        if (history.length <= 2) {
            history.replace({
                path: location.pathname,
                search: deleteUrlQueryParameter(CHANGE_BENEFIT_PLAN),
            });
            return;
        }
        history.go(-2);
    };

    return (
        <Dialog open={changeBenefitPlanDialogState.open} onClose={handleCloseChangeBenefitPlanDialog} title={translate('Change Benefit Plan')} classes={{ dialog: classes.dialog }}>
            <Form onSubmit={onSubmit} form={form} className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name='name' label={translate('Name')} disabled={form.formState?.isSubmitting || loading} required validate={required} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField name='credits' label={translate('Credits')} disabled={form.formState?.isSubmitting || loading} required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox label={translate('Restrict Credits to Delivery')} name={'restrictedToDelivery'} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox label={translate('Receive all weekly credits on Saturday')} name={'distributeOncePerWeek'} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox
                            label={translate('Allow only one product per day')}
                            name={'oneProductPerDay'}
                            tooltipText={translate('Activating this will restrict you to only one product per day in lets eat kiosk')}
                        />
                    </Grid>
                    {!distributeOncePerWeek && (
                        <Grid item xs={12}>
                            <FormTextField
                                name='meals'
                                label={translate('Meals')}
                                helperText={`${translate('Max number of meals per week')}`}
                                placeholder={`${days?.length ?? 0}`}
                                disabled={form.formState?.isSubmitting || loading}
                                validate={combine(integer, greaterThanZero)}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={10}>
                        <FormAutocompleteMultiple name='days' label={translate('Days')} required options={dayOptions} />
                    </Grid>
                    {!distributeOncePerWeek && (
                        <Grid item xs={12}>
                            <FormTextField
                                name='hours'
                                label={translate('Hours')}
                                helperText={`${translate('Time of day when the employee can order, e.g. 11:00-13.00')}`}
                                placeholder={translate('24/7')}
                                disabled={form.formState?.isSubmitting}
                                validate={timeRange}
                            />
                        </Grid>
                    )}
                    <FormRadioGroup name='paused' row defaultValue={'false'} required>
                        <FormRadio label={translate('Active')} value={'false'} />
                        <FormRadio label={translate('Paused')} value={'true'} />
                    </FormRadioGroup>
                </Grid>
                <DialogActions>
                    <Button classes={{ button: classes.deleteButton }} onClick={handleRemoveBenefitPlan} outlined disabled={form.formState?.isSubmitting || loading}>
                        <Tooltip text={translate('Remove Benefit Plan')}>{translate('Remove')}</Tooltip>
                    </Button>
                    <Button classes={{ button: classes.button }} secondary onClick={handleCloseChangeBenefitPlanDialog} disabled={form.formState?.isSubmitting || loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button classes={{ button: classes.button }} type='submit' disabled={form.formState?.isSubmitting || loading}>
                        {form.formState?.isSubmitting ? translate('Changing') : translate('Change')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

export const CHANGE_BENEFIT_PLAN = 'change-benefit-plan';

const useStyles = makeStyles((theme) => ({
    dialog: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: '60vw',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    button: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '30%',
        },
    },
    deleteButton: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '30%',
        },
        marginRight: 'auto',
    },
}));

type ChangeBenefitPlanRequest = {
    benefitPlanId: BenefitPlanId;
    name?: string | null | undefined;
    credits?: string | null | undefined;
    distributeOncePerWeek?: boolean;
    restrictedToDelivery?: boolean;
    oneProductPerDay?: boolean;
    meals?: number | null | undefined;
    days?: string | null | undefined;
    hours?: string | null | undefined;
    paused?: boolean | null | undefined;
};

type Props = {
    onBenefitPlanChanged: any;
};
