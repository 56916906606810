/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Dialog } from 'src/components/Dialog';
import { CreateMenuItem } from 'src/scenes/letseatmanager/menu/menuItem/CreateMenuItem';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export function CreateMenuItemSection({ onClose, onSuccess, isProductSelected }: Props): React.ReactElement {
    const classes = useStyles();

    const isSmallScreen = useIsSmallScreen();

    if (isSmallScreen) {
        return (
            <Dialog open={true} onClose={onClose}>
                <CreateMenuItem onClose={onClose} onSuccess={onSuccess} isProductSelected={isProductSelected} />
            </Dialog>
        );
    }

    return (
        <div className={classes.createMenuItemSection}>
            <CreateMenuItem onClose={onClose} onSuccess={onSuccess} isProductSelected={isProductSelected} />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    createMenuItemSection: {
        width: '40%',
    },
}));

type Props = {
    onClose?: any;
    onSuccess?: any;
    isProductSelected?: boolean;
};
