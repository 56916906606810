/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RolePermission } from 'src/constants/RolePermission';
import type { AwsCognitoUsername, EmailAddress, ManagerUserId, RestaurantId } from 'src/types/Id';

export async function findRestaurantUsersApi(request: FindRestaurantUsersApiRequest): ApiSauceResponse<Array<UserVm>> {
    return letseatmanagerApiMethod('userManagement/findRestaurantUsersApi', request);
}

export type FindRestaurantUsersApiRequest = {
    restaurantIds: Array<RestaurantId>;
};

export type UserVm = {
    managerUserId: ManagerUserId;
    username: AwsCognitoUsername;
    email: EmailAddress;
    roleName: string;
    userRolePermissions?: Array<RolePermission>;
    name: string;
    ordersCancelled?: number;
    createdAt: Date;
};
