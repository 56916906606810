/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { memo } from 'react';
import * as React from 'react';
import { PosAddDiscountButton } from 'src/components/pos/posPayment/PosAddDiscountButton';
import { PosAddPromoCodeButton } from 'src/components/pos/posPayment/PosAddPromoCodeButton';
import { PosAddTipButton } from 'src/components/pos/posPayment/PosAddTipButton';
import { PosApplyCustomerCreditsButton } from 'src/components/pos/posPayment/PosApplyCustomerCreditsButton';
import { PosPaymentCompleteAndInvoiceButton } from 'src/components/pos/posPayment/PosPaymentCompleteAndInvoiceButton';
import { PosPaymentCompleteAndRucInvoiceButton } from 'src/components/pos/posPayment/PosPaymentCompleteAndRucInvoiceButton';
import { PosPaymentPrintOrderButton } from 'src/components/pos/posPayment/PosPaymentPrintOrderButton';
import { PosPaymentSavePendingOrderButton } from 'src/components/pos/posPayment/PosPaymentSavePendingOrderButton';
import { PosPaymentSubmitOrderButton } from 'src/components/pos/posPayment/PosPaymentSubmitOrderButton';
import { SwipeDrawer } from 'src/components/SwipeDrawer';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { useShouldRenderPosPaymentSecondaryButton } from 'src/services/pos/useShouldRenderPosPaymentSecondaryButton';
import { isUruguay } from 'src/utils/country/isUruguay';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { canRestaurantInvoice } from 'src/utils/restaurant/canRestaurantInvoice';

export const PosPaymentActions: React.ComponentType<any> = memo<any>(() => {
    const classes = useStyles();
    const shouldRenderSecondaryButton = useShouldRenderPosPaymentSecondaryButton();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();
    const restaurant = useSelector((state) => state.app.restaurant);

    const getPrincipalButtonsByCountry = (restaurant: any) => {
        if (isUruguay(restaurant.country)) {
            return (
                <div
                    className={classNames(
                        classes.buttonsContainer,
                        posInterfaceLargerEnabled || !shouldRenderSecondaryButton || canRestaurantInvoice(restaurant) ? classes.buttonsContainerLarger : ''
                    )}
                >
                    {!canRestaurantInvoice(restaurant) && <PosPaymentSubmitOrderButton />}
                    <PosPaymentCompleteAndInvoiceButton />
                </div>
            );
        }

        return (
            <div className={classNames(classes.buttonsContainer, posInterfaceLargerEnabled || !shouldRenderSecondaryButton ? classes.buttonsContainerLarger : '')}>
                <PosPaymentSavePendingOrderButton />
                <PosPaymentSubmitOrderButton />
            </div>
        );
    };

    return (
        <SwipeDrawer classes={{ puller: classes.puller }} visibleContent={getPrincipalButtonsByCountry(restaurant)} hideBackdrop>
            <div className={classNames(classes.buttonsContainer, posInterfaceLargerEnabled ? classes.buttonsContainerLarger : '')}>
                <PosAddTipButton />
                <PosAddDiscountButton />
                <PosPaymentCompleteAndRucInvoiceButton />
                <PosPaymentPrintOrderButton />
                <PosAddPromoCodeButton />
                <PosApplyCustomerCreditsButton />
                {isUruguay(restaurant.country) && canRestaurantInvoice(restaurant) && <PosPaymentSubmitOrderButton />}
            </div>
        </SwipeDrawer>
    );
});
const useStyles = makeStyles((theme) => ({
    puller: {
        height: 3,
        backgroundColor: theme.palette.surface.brand,
    },
    secondaryButton: {
        color: '#06B7A2',
        borderColor: '#06B7A2',
        backgroundColor: '',
        '&:hover': {
            borderColor: '#06B7A2',
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '&:focus': {
            borderColor: '#06B7A2',
        },
    },
    largerPrimaryButton: {
        order: -1,
    },
    buttonsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 5,
        width: '100%',
    },
    buttonsContainerLarger: {
        gridTemplateColumns: '1fr',
    },
    largerButton: {
        fontSize: 15,
        height: 52,
    },
}));
