/**
 * @prettier
 */
import * as React from 'react';
import { useState } from 'react';
import { Button } from 'src/components/Button';
import { PrintOrderCommandDialog } from 'src/components/dialog/order/PrintOrderCommandDialog';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';

export function PrintOrderCommandButton({ order, disabled }: Props): React.ReactElement {
    const [printOrderCommandDialogState, setPrintOrderCommandDialogState] = useState({ open: false });

    const openPrintOrderCommandDialog = () => setPrintOrderCommandDialogState({ open: true });

    const closePrintOrderCommandDialog = () => setPrintOrderCommandDialogState({ open: false });

    return (
        <SecuredContent rolePermission={RolePermissions.REPRINT_ORDER_COMMAND}>
            <PrintOrderCommandDialog open={printOrderCommandDialogState.open} order={order} onClose={closePrintOrderCommandDialog} />
            <Button secondary onClick={openPrintOrderCommandDialog} disabled={disabled}>
                {translate('Reprint Command')}
            </Button>
        </SecuredContent>
    );
}

type Props = {
    order: OrderVm;
    disabled?: boolean;
};
