/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { PrinterBrand } from 'src/constants/PrinterBrand';
import type { PrinterContent } from 'src/constants/PrinterContent';
import type { PrinterPaperSize } from 'src/constants/PrinterPaperSize';
import type { PrinterType } from 'src/constants/PrinterType';
import type { MenuCategoryId, PrinterId, RestaurantId } from 'src/types/Id';
import type { PrinterDeprecatedVm } from 'src/types/PrinterDeprecatedVm';

export async function changePrinterApi(request: ChangePrinterApiRequest): ApiSauceResponse<PrinterDeprecatedVm> {
    return letseatmanagerApiMethod('printerDeprecated/changePrinterApi', request);
}

export type ChangePrinterApiRequest = {
    printerId: PrinterId;
    restaurantId: RestaurantId;
    type: PrinterType;
    menuCategoryIds?: Array<MenuCategoryId>;
    printerContent?: PrinterContent;
    deviceName?: string;
    printerBrand?: PrinterBrand;
    externalPrinterId?: string;
    ipAddress?: string;
    port?: string;
    serialNumber?: string;
    channels?: Array<App>;
    printerPaperSize: PrinterPaperSize;
    disabled?: boolean;
    isTabletPrinter?: boolean;
};
