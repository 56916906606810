/**
 * @prettier
 */
export const DeviceBrands = {
    APPLE: 'APPLE',
    IMIN: 'IMIN',
    QPAY: 'QPAY',
    SAMSUNG: 'SAMSUNG',
    LG: 'LG',
} as const;

export type DeviceBrand = typeof DeviceBrands[keyof typeof DeviceBrands] | string;
