/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import type { PosPayment } from 'src/types/PosPayment';
import { PromotionVm } from 'src/types/PromotionVm';
import { createPaymentDistribution } from 'src/utils/pos/createPaymentDistribution';

export function refreshPosPaymentCustomerState({ customer, payments, promotions }: Params): void {
    if (!customer.orderItems?.length) {
        delete customer.payment;
        return;
    }

    const payment = createPaymentDistribution({
        orderItems: customer.orderItems,
        discount: customer.discount,
        tips: customer.tips,
        promotions: promotions ?? [],
    });

    const totalPaid: number = payments?.reduce((totalPaid: number, payment: PosPayment) => {
        const isCustomerPayment = payment.customerNumber === customer.customerNumber;
        if (isCustomerPayment) {
            return BigNumber(totalPaid ?? 0)
                .plus(payment.amount ?? 0)
                .toNumber();
        }
        return totalPaid;
    }, 0);

    customer.payment = {
        subtotal: payment.subtotal,
        productDiscount: payment.productDiscount,
        promotionsDiscount: payment.promotionsDiscount,
        posDiscount: payment.posDiscount,
        total: payment.total,
        totalPaid: totalPaid?.toString(),
    };
}

type Params = {
    customer: PosCustomerOrderDetails;
    payments: Array<PosPayment>;
    promotions?: Array<PromotionVm>;
};
