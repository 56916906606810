/**
 * @prettier
 */
import * as React from 'react';
import { useEffect } from 'react';
import { getMenuItemsRecipeOptionsApi } from 'src/api/letseatmanager/options/getMenuItemsRecipeOptionsApi';
import { FormAutocompleteMultiple } from 'src/components/form/FormAutocompleteMultiple';
import type { Variant } from 'src/components/form/FormTypes';
import TheMenuActions from 'src/scenes/letseatmanager/deprecatedMenu/TheMenuReducer';
import { useMenus } from 'src/services/menu/useMenus';
import { useMenuItems } from 'src/services/menuItem/useMenuItems';
import type { MenuId, RecipeId } from 'src/types/Id';
import { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function FormMenuItemsRecipeAutocomplete({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, recipeId }: Props): React.ReactElement {
    const { modifiers } = useMenuItems();
    const { deprecatedMenus } = useMenus();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant.newMenuManagementEnabled);

    const fetch = useAction(TheMenuActions.fetch);

    const [loading, options] = useLoadApi(
        getMenuItemsRecipeOptionsApi,
        {
            restaurantId,
            recipeId,
        },
        {
            initialValue: required ? [] : [{ label: '', value: null }],
        }
    );

    useEffect(() => {
        if (!newMenuManagementEnabled && deprecatedMenus.length === 0) fetch(restaurantId);
    }, [newMenuManagementEnabled]);

    const optionsMenuItemsOptions = options.concat(modifiers?.map((menuItem: ManagerMenuItemVm) => ({ value: menuItem.menuItemId, label: menuItem.name })) ?? []);

    return (
        <FormAutocompleteMultiple
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={optionsMenuItemsOptions}
            disabled={disabled || loading}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: Array<string>;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    menuId?: MenuId;
    recipeId?: RecipeId;
};
