/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SupplierId } from 'src/types/Id';
import type { SupplierVm } from 'src/types/SupplierVm';

export async function getSupplierApi(request: GetSupplierApiRequest): ApiSauceResponse<SupplierVm> {
    return letseatmanagerApiMethod('supplier/getSupplierApi', request);
}

export type GetSupplierApiRequest = {
    supplierId: SupplierId;
};
