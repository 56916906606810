/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useLoadApi } from '@pidedirecto/ui/hooks';
import { FilledClockIcon, MotorcycleIcon, PeopleIcon } from '@pidedirecto/ui/icons';
import moment from 'moment/moment';
import * as React from 'react';
import { getHeatMapDeliveryOrdersReportApi } from 'src/api/letseatmanager/restaurantDashboard/getHeatMapDeliveryOrdersReportApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { ReportIndicator } from 'src/scenes/letseatmanager/restaurantDashboard/ReportIndicator';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { classNames } from 'src/utils/react/classNames';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useSelector } from 'src/utils/react/useSelector';

export function HeatMapDeliveryOrdersReport(): React.ReactElement {
    const classes = useStyles();
    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const period = useSelector((state) => state.app2.filterReportsState.period);

    const [loading, report] = useLoadApi(
        getHeatMapDeliveryOrdersReportApi,
        {
            restaurantIds,
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period?.to)).toISOString(),
        },
        { dependencies: [restaurantIds, period] }
    );

    const getHeatmap = () => {
        if (!report?.orderHeatmapIframe) return '';
        const blob = new Blob([report?.orderHeatmapIframe], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        return url;
    };

    return (
        <div className={classes.reportContainer}>
            <FilterReports />
            {loading && <UpdatingContentProgress />}
            {report && (
                <div>
                    <div className={classes.kpiSection}>
                        <ReportIndicator
                            label={translate('Total orders')}
                            value={report?.orderHeatmapKpis.totalOrders ?? '0'}
                            Icon={
                                <div className={classNames(classes.iconContainer, classes.totalOrdersIcon)}>
                                    <MotorcycleIcon size={30} />
                                </div>
                            }
                        />
                        <ReportIndicator
                            label={translate('Total customers')}
                            value={report?.orderHeatmapKpis.totalCustomers ?? '0'}
                            Icon={
                                <div className={classNames(classes.iconContainer, classes.totalCustomersIcon)}>
                                    <PeopleIcon size={20} />
                                </div>
                            }
                        />
                        <ReportIndicator
                            label={translate('Average time')}
                            value={`${report?.orderHeatmapKpis.averageDeliveryTime ?? '0'} mins`}
                            Icon={
                                <div className={classNames(classes.iconContainer, classes.timeIcon)}>
                                    <FilledClockIcon size={20} />
                                </div>
                            }
                        />
                    </div>
                    <div className={classes.chartsContainer}>
                        <div className={classes.titleContainer}>
                            <h2 className={classes.title}>{translate('Order Heatmap')}</h2>
                        </div>
                        <iframe className={classes.heatmapContainer} src={getHeatmap()} />
                    </div>
                </div>
            )}
            {!report && !loading && <ReportSectionEmptyState />}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    reportContainer: {
        marginBottom: 80,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
    },
    kpiSection: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        gap: 12,
        margin: '24px 10px 24px 0px',
    },
    chartsContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    iconContainer: {
        width: 40,
        height: 40,
        borderRadius: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    totalOrdersIcon: {
        backgroundColor: theme.palette.surface.brand,
        color: theme.palette.surface.brandContrast,
    },
    totalCustomersIcon: {
        backgroundColor: '#D9EAF4',
        color: '#0899EA',
    },
    timeIcon: {
        backgroundColor: '#F9E1D0',
        color: '#FF8F40',
    },
    heatmapContainer: {
        width: '100%',
        minHeight: '70vh',
    },
}));
