/**
 * @prettier
 */
import * as React from 'react';
import { useContext, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';
import type { AutoCompleteInputProp, Variant } from 'src/components/form/FormTypes';
import { TransformedTextField, transformToOutput } from 'src/components/form/material-ui/TransformedTextField';
import { getError } from 'src/components/form/utils/getError';
import { translate } from 'src/i18n/translate';

export function FormTextField({
    name,
    label,
    placeholder,
    helperText,
    defaultValue = null,
    trim = true,
    upperCase,
    autoComplete = 'off',
    multiline,
    rowsMax,
    disabled,
    required,
    minLength,
    maxLength,
    pattern,
    validate,
    variant,
    InputProps,
    inputProps,
    startAdornment,
    endAdornment,
    classes,
    tooltip,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>();

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <TransformedTextField
                    {...props}
                    value={value}
                    onChange={onChange}
                    label={label}
                    placeholder={placeholder}
                    helperText={getError(errors, name)?.message ?? helperText}
                    trim={trim}
                    upperCase={upperCase}
                    disabled={isSubmitting || disabled || formContext.disabled}
                    required={required}
                    multiline={multiline}
                    rowsMax={rowsMax}
                    error={!!getError(errors, name)}
                    variant={variant}
                    inputRef={inputRef}
                    InputProps={InputProps}
                    inputProps={{ autoComplete, ...inputProps, style: { textTransform: upperCase ? 'uppercase' : 'none', ...inputProps?.style } }}
                    startAdornment={startAdornment}
                    endAdornment={endAdornment}
                    InputLabelProps={{ shrink: placeholder ? true : undefined }}
                    fullWidth
                    classesProp={classes}
                    tooltip={tooltip}
                />
            )}
            control={control}
            name={name}
            defaultValue={transformToOutput(defaultValue, trim, upperCase)}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
                minLength: {
                    value: (minLength as any)?.value ?? minLength,
                    message: (minLength as any)?.message ?? translate('Must be at least @number characters', { number: (minLength as any)?.value ?? minLength }),
                },
                maxLength: {
                    value: (maxLength as any)?.value ?? maxLength,
                    message: (maxLength as any)?.message ?? translate('Must be at most @number characters', { number: (maxLength as any)?.value ?? maxLength }),
                },
                pattern: {
                    value: (pattern as any)?.value ?? pattern,
                    message: (pattern as any)?.message ?? translate('This field is in incorrect format'),
                },
                validate,
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    trim?: boolean;
    upperCase?: boolean;
    autoComplete?: AutoCompleteInputProp;
    multiline?: boolean;
    rowsMax?: number;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    minLength?:
        | number
        | {
              value: number;
              message: string;
          };
    maxLength?:
        | number
        | {
              value: number;
              message: string;
          };
    pattern?:
        | RegExp
        | {
              value: RegExp;
              message: string;
          };
    validate?: any;
    variant?: Variant;
    InputProps?: any;
    inputProps?: any;
    startAdornment?: any;
    endAdornment?: any;
    classes?: any;
    tooltip?: string;
};
