/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { TreeMapCanvas } from 'src/components/charts/TreeMapCanvas';
import { translate } from 'src/i18n/translate';
import { GraphEmptyState } from 'src/scenes/letseatmanager/posReports/GraphEmptyState';
import type { CashRegisterTransactionsReportVm } from 'src/types/CashRegisterTransactionsReportVm';
import { sum } from 'src/utils/reduce/sum';

export function CashRegisterTotalTransactionsByManagerUserReport({ cashRegisterTransactionsReport }: Props): React.ReactElement {
    const classes = useStyles();

    const getCashRegisterTransactionsByManagerUser = () => {
        return cashRegisterTransactionsReport.totalSummaryTransactionsByManagerUser.map((cashRegisterTransaction) => ({
            id: cashRegisterTransaction.managerUser,
            value: BigNumber(cashRegisterTransaction.transactions).toString(),
        }));
    };

    const isEmptyReport = () => {
        const cashRegisterTransactions = cashRegisterTransactionsReport.totalSummaryTransactionsByManagerUser.map((cashRegisterTransaction) => cashRegisterTransaction.transactions);
        const totalTransactions = cashRegisterTransactions.reduce(sum, BigNumber(0));
        return totalTransactions.isZero();
    };

    if (isEmptyReport()) {
        return <GraphEmptyState title={translate('Total transactions by user')} />;
    }

    return (
        <article className={classes.container}>
            <h2 className={classes.title}>{translate('Total transactions by user')}</h2>
            <TreeMapCanvas data={getCashRegisterTransactionsByManagerUser()} />
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '60vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
    },
    tooltip: {
        padding: 10,
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipTitle: {
        fontFamily: theme.typography.bold,
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    cashRegisterTransactionsReport: CashRegisterTransactionsReportVm;
};
