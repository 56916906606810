/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentId } from 'src/types/Id';

export async function sendInvoiceApi(request: SendInvoiceApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('subscription/sendInvoiceApi', request);
}

export type SendInvoiceApiRequest = {
    paymentId: PaymentId;
};
