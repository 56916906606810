/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CashRegisterVm } from 'src/types/CashRegisterVm';
import type { RestaurantId } from 'src/types/Id';

export async function findOpenedCashRegisterApi(request: FindCashRegisterOpenedApiRequest): ApiSauceResponse<CashRegisterVm> {
    return letseatmanagerApiMethod('pos/findOpenedCashRegisterApi', request);
}

type FindCashRegisterOpenedApiRequest = {
    restaurantId: RestaurantId;
};
