/**
 * @prettier
 */
import type { OrderId } from 'src/types/Id';

export function toHumanizedOrderId(orderId: OrderId): string {
    if (!orderId) {
        return '';
    }
    return `${orderId.substring(0, 3).toUpperCase()} ${orderId.substring(3, 6).toUpperCase()}`;
}
