/**
 * @prettier
 */
import { usePosPaymentMethodsActions } from 'src/services/pos/posPaymentMethods/posPaymentMethodsStore';
import { DeprecatedRestaurantPaymentMethodVm } from 'src/types/DeprecatedRestaurantPaymentMethodVm';

/**
 * @deprecated
 */
export function useSetPosPaymentMethodsDeprecated(): (posPaymentMethods: Array<DeprecatedRestaurantPaymentMethodVm>) => void {
    const setPosPaymentMethodsDeprecated = usePosPaymentMethodsActions((actions) => actions.setPosPaymentMethodsDeprecated);

    return setPosPaymentMethodsDeprecated;
}
