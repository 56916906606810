/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { updatePaymentMethodApi } from 'src/api/letseatmanager/payment/updatePaymentMethodApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { ChangePosOrderPaymentMethodDialog } from 'src/components/dialog/order/ChangePosOrderPaymentMethodDialog';
import { DialogActions } from 'src/components/DialogActions';
import { PaymentMethodIcon } from 'src/components/icon/PaymentMethodIcon';
import { PaymentMethod, PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { useHasPaymentTerminalsAvailable } from 'src/services/device/useHasPaymentTerminalsAvailable';
import { usePayWithPaymentTerminal } from 'src/services/paymentTerminal/usePayWithPaymentTerminal';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isPosApp } from 'src/utils/app/isPosApp';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangeOrderPaymentMethodDialog({ open, order, customerNumber, onClose, onSuccess, onGoBack }: Props): React.ReactElement {
    const classes = useStyles();

    const { payWithPaymentTerminal } = usePayWithPaymentTerminal();
    const hasPaymentTerminalsAvailable = useHasPaymentTerminalsAvailable();

    const [loading, setLoading] = useState(false);
    const [paying, setPaying] = useState(false);
    const [paymentMethodSelected, setPaymentMethodSelected] = useState<PaymentMethod | undefined>(undefined);

    const useQpayTerminalEnabled = useSelector((state) => state.app.restaurant.useQpayTerminalEnabled);

    const payWithTerminalAvailable = useQpayTerminalEnabled || hasPaymentTerminalsAvailable;
    const updateOrder = useAction(app2.actions.updateOrder);

    const handleClose = () => {
        if (loading || paying) return;
        setPaymentMethodSelected(undefined);
        onClose();
    };

    const handleUpdatePaymentMethod = async () => {
        if (isCardPayment(paymentMethodSelected) && payWithTerminalAvailable && !isPosApp(order.app)) {
            setPaying(true);
            const paymentResponse = await payWithPaymentTerminal({
                amount: order.total,
            });
            if (!paymentResponse.paid) {
                setPaymentMethodSelected(undefined);
                setPaying(false);
                return;
            }
            setPaying(false);
        }
        const orderUpdated = await updatePaymentMethod();
        updateOrder({ order: orderUpdated });
    };

    const updatePaymentMethod = async () => {
        if (!paymentMethodSelected) return;

        setLoading(true);
        const response = await updatePaymentMethodApi({
            orderId: order.orderId,
            paymentMethod: paymentMethodSelected,
        });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        onSuccess?.(response.data);
        handleClose();
        return response.data;
    };

    const getPaymentMethods = () => {
        const defaultPaymentMethods = [PaymentMethods.CASH, PaymentMethods.CREDIT_CARD, PaymentMethods.PAYMENT_LINK];
        return defaultPaymentMethods.filter((paymentMethod) => paymentMethod !== order.paymentMethod);
    };

    const handleClickPaymentMethod = (paymentMethod: PaymentMethod) => {
        if (loading || paying) return;
        setPaymentMethodSelected(paymentMethod);
    };

    const paymentMethods = getPaymentMethods();

    if (isPosApp(order.app)) {
        return <ChangePosOrderPaymentMethodDialog open={open} order={order} customerNumber={customerNumber} onClose={onClose} onSuccess={onSuccess} onGoBack={onGoBack} />;
    }

    return (
        <Dialog title={translate('Change payment method')} open={open} onClose={handleClose} loading={loading || paying}>
            <div className={classes.paymentMethodsContainer}>
                {paymentMethods.map((paymentMethod) => {
                    const isPaymentMethodSelected = paymentMethod === paymentMethodSelected;
                    return (
                        <div
                            key={paymentMethod}
                            className={classNames(classes.paymentMethod, isPaymentMethodSelected && classes.paymentMethodSelected)}
                            onClick={() => handleClickPaymentMethod(paymentMethod)}
                        >
                            <PaymentMethodIcon paymentMethod={paymentMethod} isSelected={isPaymentMethodSelected} />
                            <span className={classes.paymentMethodText}>{translate(paymentMethod)}</span>
                        </div>
                    );
                })}
            </div>
            <DialogActions>
                <Button secondary onClick={handleClose} disabled={loading || paying} classes={{ button: classes.button }}>
                    {translate('Cancel')}
                </Button>
                <Button onClick={handleUpdatePaymentMethod} disabled={loading || paying || !paymentMethodSelected} classes={{ button: classes.button }}>
                    {loading ? translate('Changing') : translate('Change')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    paymentMethodsContainer: {
        display: 'flex',
        maxWidth: '45vw',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 10,
    },
    paymentMethod: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        fontFamily: theme.typography.regular,
        color: 'black',
        border: `1px solid #D9D9D9`,
        width: 100,
        height: 100,
        padding: '0 10px',
        boxSizing: 'content-box',
        borderRadius: 10,
        cursor: 'pointer',
        '& svg': {
            flexShrink: 0,
        },
    },
    paymentMethodText: {
        textAlign: 'center',
    },
    paymentMethodSelected: {
        fontFamily: theme.typography.medium,
        color: '#06B7A2',
        border: `1px solid #06B7A2`,
    },
    button: {
        width: 200,
    },
}));

type Props = {
    open: boolean;
    order: OrderVm;
    customerNumber?: number;
    onClose: any;
    onSuccess?: any;
    onGoBack?: any;
};
