/**
 * @prettier
 */
import { Grid, makeStyles, useTheme } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { DeviceIcon } from '@pidedirecto/ui/icons';
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { findDevicesApi } from 'src/api/letseatmanager/device/findDevicesApi';
import { createDeviceGroupApi } from 'src/api/letseatmanager/deviceGroup/createDeviceGroupApi';
import { findPaymentTerminalsApi } from 'src/api/letseatmanager/paymentTerminal/findPaymentTerminalsApi';
import { findPrintersApi } from 'src/api/letseatmanager/printer/findPrintersApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { SelectableList } from 'src/components/SelectableList';
import { Text } from 'src/components/Text';
import { DeviceOsTypes } from 'src/constants/DeviceOs';
import { translate } from 'src/i18n/translate';
import { PrinterIcon } from 'src/icons/PrinterIcon';
import { SimpleTerminalIcon } from 'src/icons/SimpleTerminalIcon';
import { getDeviceId } from 'src/services/device/getDeviceId';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { DeviceGroupVm } from 'src/types/DeviceGroupVm';
import type { DeviceVm } from 'src/types/DeviceVm';
import type { DeviceId, PaymentTerminalId, PrinterId } from 'src/types/Id';
import type { PrinterVm } from 'src/types/PrinterVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isAndroidPrinter } from 'src/utils/printer/isAndroidPrinter';
import { isBluetoothPrinter } from 'src/utils/printer/isBluetoothPrinter';
import { isUsbPrinter } from 'src/utils/printer/isUsbPrinter';
import { isWindowsPrinter } from 'src/utils/printer/isWindowsPrinter';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { translateDeviceBrand } from 'src/utils/translate/translateDeviceBrand';
import { translateDeviceModel } from 'src/utils/translate/translateDeviceModel';
import { translateDeviceOs } from 'src/utils/translate/translateDeviceOs';
import { translateDeviceType } from 'src/utils/translate/translateDeviceType';
import { toShortId } from 'src/utils/uuid/toShortId';

export function CreateDeviceGroupDialog({ open, onClose, onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();
    const theme = useTheme();
    const form = useForm();

    const [selectedDeviceIds, setSelectedDeviceIds] = useState<Array<any>>([]);
    const [creatingDeviceGroup, setCreatingDeviceGroup] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loadingDevices, devices] = useLoadApi(findDevicesApi, { restaurantId }, { initialValue: [], requiredValues: [open ? true : null] });
    const [loadingPrinters, printers] = useLoadApi(findPrintersApi, { restaurantId }, { initialValue: [], requiredValues: [open ? true : null] });
    const [loadingPaymentTerminals, paymentTerminals] = useLoadApi(findPaymentTerminalsApi, { restaurantId }, { initialValue: [], requiredValues: [open ? true : null] });

    const deviceGroups = useSelector((state) => state.app.deviceGroups);

    const { reloadRestaurant } = useReloadRestaurant();

    const loading = loadingDevices || loadingPrinters || loadingPaymentTerminals || creatingDeviceGroup;

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const createDeviceGroup = async (form: any) => {
        setCreatingDeviceGroup(true);
        const { deviceIds, printerIds, paymentTerminalIds } = separateSelectedDeviceIds();
        const response = await createDeviceGroupApi({
            restaurantId,
            deviceGroupName: form.deviceGroupName,
            deviceIds,
            printerIds,
            paymentTerminalIds,
        });
        setCreatingDeviceGroup(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        reloadRestaurant(restaurantId);
        onSuccess?.();
        onClose();
    };

    const separateSelectedDeviceIds = () => {
        const deviceIds: Array<DeviceId> = selectedDeviceIds.filter((selectedDeviceId) => devices.some((device) => device.deviceId === selectedDeviceId));
        const printerIds: Array<PrinterId> = selectedDeviceIds.filter((selectedDeviceId) => printers.some((printer) => printer.printerId === selectedDeviceId));
        const paymentTerminalIds: Array<PaymentTerminalId> = selectedDeviceIds.filter((selectedDeviceId) =>
            paymentTerminals.some((paymentTerminal) => paymentTerminal.paymentTerminalId === selectedDeviceId)
        );
        return {
            deviceIds,
            printerIds,
            paymentTerminalIds,
        };
    };

    const handleSelectDeviceIds = (deviceIds: Array<any>) => {
        setSelectedDeviceIds(deviceIds);
    };

    const filterDeviceListBySelectedDevices = (filteredDeviceList: any) => {
        const selectedDevice = devices.find((device) => selectedDeviceIds.includes(device.deviceId));
        if (!selectedDevice) return filteredDeviceList;

        const filteredDevices = devices.filter((device) => device.deviceOs === selectedDevice?.deviceOs);

        let filteredPrinters: Array<never> = [];

        switch (selectedDevice?.deviceOs) {
            case DeviceOsTypes.WINDOWS:
                filteredPrinters = filteredDeviceList.printers.filter((printer: PrinterVm) => isUsbPrinter(printer.printerType) || printer.externalPrinterId);
                break;
            case DeviceOsTypes.MAC_OS:
                filteredPrinters = filteredDeviceList.printers.filter((printer: PrinterVm) => isUsbPrinter(printer.printerType));
                break;
            case DeviceOsTypes.ANDROID:
                filteredPrinters = filteredDeviceList.printers.filter((printer: PrinterVm) => isBluetoothPrinter(printer.printerType) || printer.ipAddress);
                break;
            default:
                filteredPrinters = [];
        }

        return {
            devices: filteredDevices,
            printers: filteredPrinters,
        };
    };
    const filterDeviceListBySelectedAndroidPrinters = (filteredDeviceList: any) => {
        return {
            devices: devices.filter((device) => device.deviceOs === DeviceOsTypes.ANDROID),
            printers: filteredDeviceList.printers.filter((printer: PrinterVm) => isAndroidPrinter(printer)),
        };
    };
    const filterDeviceListBySelectedWindowsPrinters = (filteredDeviceList: any) => {
        return {
            devices: devices.filter((device) => device.deviceOs === DeviceOsTypes.WINDOWS),
            printers: filteredDeviceList.printers.filter((printer: PrinterVm) => isUsbPrinter(printer.printerType) || printer.externalPrinterId),
        };
    };
    const filterDeviceListBySelectedUsbPrinters = (filteredDeviceList: any) => {
        return {
            devices: devices.filter((device) => device.deviceOs === DeviceOsTypes.WINDOWS || device.deviceOs === DeviceOsTypes.MAC_OS),
            printers: filteredDeviceList.printers.filter((printer: PrinterVm) => isUsbPrinter(printer.printerType) || printer.externalPrinterId),
        };
    };
    const filterDeviceListBySelectedPrinters = (filteredDeviceList: any) => {
        const selectedPrinter = filteredDeviceList.printers.find((printer: PrinterVm) => selectedDeviceIds.includes(printer.printerId));

        if (!selectedPrinter) return filteredDeviceList;

        if (isAndroidPrinter(selectedPrinter)) {
            return filterDeviceListBySelectedAndroidPrinters(filteredDeviceList);
        } else if (isUsbPrinter(selectedPrinter?.printerType)) {
            return filterDeviceListBySelectedUsbPrinters(filteredDeviceList);
        } else if (isWindowsPrinter(selectedPrinter)) {
            return filterDeviceListBySelectedWindowsPrinters(filteredDeviceList);
        } else {
            return filteredDeviceList;
        }
    };
    const isAvailablePrinter = (printer: any) => {
        if (!isUsbPrinter(printer.printerType) && !isBluetoothPrinter(printer?.printerType)) return true;
        return !deviceGroups?.some((deviceGroup: DeviceGroupVm) => deviceGroup?.printerIds?.includes(printer.printerId));
    };
    const filterDeviceList = () => {
        // const filteredPrinters = printers.filter(printer => isAvailablePrinter(printer));
        // let filteredDeviceList = { devices: devices, printers: filteredPrinters };
        //
        // filteredDeviceList = filterDeviceListBySelectedDevices(filteredDeviceList);
        // filteredDeviceList = filterDeviceListBySelectedPrinters(filteredDeviceList);

        return [
            ...devices?.map((device: DeviceVm) => ({
                value: device.deviceId,
                content: (
                    <div className={classes.content}>
                        <DeviceIcon size={24} color={theme.palette.icons.primary} />
                        <Text>{device.deviceName || toShortId(device.deviceId)}</Text>
                        {getDeviceId() === device.deviceId ? (
                            <Chip label={translate('This device')} color='primary' size='small' />
                        ) : (
                            <>
                                <Text className={classes.deviceLabel}>{translateDeviceOs(device.deviceOs)}</Text>
                                {device.deviceBrand && <Text className={classes.deviceLabel}>{translateDeviceBrand(device.deviceBrand)}</Text>}
                                <Text className={classes.deviceLabel}>{translateDeviceModel(device.deviceModel) || translateDeviceType(device.deviceType)}</Text>
                            </>
                        )}
                    </div>
                ),
            })),
            ...paymentTerminals.map((paymentTerminal) => ({
                value: paymentTerminal.paymentTerminalId,
                content: (
                    <div className={classes.content}>
                        <SimpleTerminalIcon color={theme.palette.icons.primary} />
                        <Text>{paymentTerminal.deviceName}</Text>
                    </div>
                ),
            })),
            ...printers?.map((printer: PrinterVm) => ({
                value: printer.printerId,
                content: (
                    <div className={classes.content}>
                        <PrinterIcon color={theme.palette.icons.primary} />
                        <Text>{printer.deviceName || toShortId(printer.printerId)}</Text>
                    </div>
                ),
            })),
        ];
    };

    if (!open) return null;

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Create new group')} loading={loading} classes={{ dialog: classes.dialog }}>
            <Form form={form} onSubmit={createDeviceGroup}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormTextField name={'deviceGroupName'} label={translate('Group name')} required />
                    </Grid>
                    <Grid item xs={12} className={classes.deviceListContainer}>
                        <SelectableList title={translate('Select devices from group')} onChange={(values) => handleSelectDeviceIds(values)} multiselectable options={filterDeviceList()} />
                    </Grid>
                </Grid>
                <DialogActions className={(classes as any).dialogActions}>
                    <Button classes={{ button: (classes as any).button }} secondary onClick={handleClose} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button classes={{ button: (classes as any).button }} type='submit' disabled={loading}>
                        {translate('Create group')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: '50vw',
        [theme.breakpoints.down('sm')]: {
            width: '90vw',
        },
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        width: '100%',
    },
    icon: {
        display: 'grid',
        placeContent: 'center',
        width: 24,
    },
    deviceListContainer: {
        maxHeight: 420,
        overflowY: 'scroll',
    },
    deviceLabel: {
        fontFamily: theme.typography.regular,
        color: '#999999',
        fontSize: 12,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess?: any;
};
