/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import { roundDigits } from 'src/utils/number/roundDigits';
import { useSelector } from 'src/utils/react/useSelector';

export function usePosChange(): string {
    const total = useSelector((state) => state.pos.payment?.total);
    const totalPaid = useSelector((state) => state.pos.totalPaid);

    return roundDigits(BigNumber(totalPaid).minus(total).toString());
}
