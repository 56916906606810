/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect } from 'react';
import { IncomingOrdersAlert } from 'src/components/alertDialog/order/IncomingOrdersAlert';
import { AddMenuItemNoteDialog } from 'src/scenes/letseatmanager/pos/posAddMenuItem/AddMenuItemNoteDialog';
import { ChangeMenuItemNoteDialog } from 'src/scenes/letseatmanager/pos/posAddMenuItem/ChangeMenuItemNoteDialog';
import { PosOrderStepsComponent } from 'src/scenes/letseatmanager/pos/PosOrderStepsComponent';
import { PosProvider } from 'src/scenes/letseatmanager/pos/PosProvider';
import { PosTrackSession } from 'src/scenes/letseatmanager/pos/PosTrackSession';
import { ReachedMaximumCashInCashRegisterAlert } from 'src/scenes/letseatmanager/pos/ReachedMaximumCashInCashRegisterAlert';
import { useFetchCashRegister } from 'src/services/cashRegister/useFetchCashRegister';
import { useClearPos } from 'src/services/pos/useClearPos';
import { useSaveOfflinePosActions } from 'src/services/pos/useSaveOfflinePosActions';
import { useSelector } from 'src/utils/react/useSelector';

export function PosPage(): React.ReactElement {
    const classes = useStyles();
    useSaveOfflinePosActions();

    const { fetchOpenedCashRegister, loading } = useFetchCashRegister();
    const pinCodeUserSignedIn = useSelector((state) => state.authentication?.pinCodeUserSignedIn);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);

    const clearPos = useClearPos();

    useEffect(() => {
        return () => clearPos();
    }, []);

    useEffect(() => {
        if (!pinCodeUserSignedIn && !posMultipleCashRegistersEnabled) {
            fetchOpenedCashRegister();
        }
    }, []);

    useEffect(() => {
        if (pinCodeUserSignedIn && posMultipleCashRegistersEnabled) {
            fetchOpenedCashRegister();
        }
    }, [pinCodeUserSignedIn]);

    if (loading) {
        return (
            <div className={classes.loadingContainer}>
                <Loader size={40} loading={true} />;
            </div>
        );
    }

    return (
        <PosProvider>
            <ReachedMaximumCashInCashRegisterAlert />
            <IncomingOrdersAlert />
            <AddMenuItemNoteDialog />
            <ChangeMenuItemNoteDialog />
            <PosTrackSession>
                <PosOrderStepsComponent />
            </PosTrackSession>
        </PosProvider>
    );
}

const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
