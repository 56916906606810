/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { OrderItem } from 'src/components/orderCard/orderItems/OrderItem';
import { translate } from 'src/i18n/translate';
import type { OrderItemVm } from 'src/types/OrderVm';

export function ReturnedOrderItems({ returnedOrderItems, showDetails }: Props): any {
    const classes = useStyles();

    if (!returnedOrderItems || returnedOrderItems.length === 0) return null;

    return (
        <div className={classes.groupContainer}>
            <p className={classes.returnedOrderItemsTitle}>{translate('Returned Items:')}</p>

            {returnedOrderItems.map((returnOrderItem) => (
                <OrderItem key={returnOrderItem.menuItemId} showDetails={showDetails} orderItem={returnOrderItem} />
            ))}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    groupContainer: {
        backgroundColor: '#F1F6F9',
        borderRadius: 8,
        padding: 12,
    },
    returnedOrderItemsTitle: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        margin: 0,
        marginBottom: 5,
        marginTop: 15,
        color: '#e1383d',
    },
}));

type Props = {
    returnedOrderItems?: Array<OrderItemVm>;
    showDetails?: boolean;
};
