/**
 * @prettier
 */
import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { findDeliveryProofsApi } from 'src/api/letseatadmin/order/findDeliveryProofsApi';
import { completeDeliveryManuallyForOrderCompletedApi } from 'src/api/letseatmanager/driver/completeDeliveryManuallyForOrderCompletedApi';
import type { PaymentLinkVm } from 'src/api/letseatmanager/paymentLink/createPaymentLinkApi';
import { findPaymentLinkByOrderIdApi } from 'src/api/letseatmanager/paymentLink/findPaymentLinkByOrderIdApi';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { app2 } from 'src/app2';
import { OrderVerificationPhotosButton } from 'src/components/button/order/OrderVerificationPhotosButton';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { FormSubheading } from 'src/components/form/FormSubheading';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { InvoiceToolbarButton } from 'src/components/mui-datatables/InvoiceToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { OrderItems } from 'src/components/orderCard/orderItems/OrderItems';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { ChangeDynamicDeliveryEarningsDialog } from 'src/scenes/letseatadmin/dynamicDeliveryEarnings/ChangeDynamicDeliveryEarningsDialog';
import { AdminOrderVm } from 'src/types/AdminOrderVm';
import type { OrderId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatAsDuration } from 'src/utils/date/formatAsDuration';
import { formatDateTimeSecondsString } from 'src/utils/date/formatDateTimeSecondsString';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { copyTextToClipboard } from 'src/utils/html/copyTextToClipboard';
import { openDirections } from 'src/utils/linking/openDirections';
import { formatAsDistance } from 'src/utils/number/formatAsDistance';
import { useAction } from 'src/utils/react/useAction';
import { useAskForPassword } from 'src/utils/react/useAskForPassword';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';
import { openDriverPageInNewTab } from 'src/utils/window/openDriverPageInNewTab';

export default function OrderDetails({ orderId, onChangeOrder }: Props): React.ReactElement {
    const classes = useStyles();
    const askForPassword = useAskForPassword();
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState<AdminOrderVm | undefined>(undefined);
    const [paymentLink, setPaymentLink] = useState<PaymentLinkVm | any>();
    const [changeDynamicDeliveryEarningsDialogState, setChangeDynamicDeliveryEarningsDialogState] = useState({ open: false });
    const isOrderFinished = [OrderStatuses.REJECTED, OrderStatuses.COMPLETE, OrderStatuses.CANCELLED].includes(order?.orderStatus as any);

    const viewUser = useSelector((state) => state.authentication.viewUser);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const openRejectOrderDialog = useAction(app2.actions.openRejectOrderDialog);
    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    useEffect(() => {
        load();
    }, [orderId]);

    const getPaymentLinkByOrder = async (orderId: any) => {
        if (!orderId) return;
        setLoading(true);
        const response = await findPaymentLinkByOrderIdApi({
            restaurantId,
            orderId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        if (!response.data) return;
        setPaymentLink(response.data);
    };

    const completeDeliveryManuallyForOrderCompleted = async () => {
        if (!order) return alert(translate('Order Missing'));
        setLoading(true);
        const response = await completeDeliveryManuallyForOrderCompletedApi({ orderId: order.orderId });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
    };

    const load = async () => {
        if (!orderId) {
            return;
        }
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.admin.order({ orderId });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const order = response.data;
        if (order.paymentMethod === PaymentMethods.PAYMENT_LINK) {
            await getPaymentLinkByOrder(order.orderId);
        }
        setOrder(order);
        setLoading(false);
        onChangeOrder && onChangeOrder(order);
    };

    const findDeliveryProofs = async () => {
        if (!orderId) return;
        setLoading(true);
        const response = await findDeliveryProofsApi({ orderId });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        downloadBase64File(response.data.paymentDataUri, `${translate('Payment Data')} ${orderId}`);
        downloadBase64File(response.data.orderDataUri, `${translate('Order Data')} ${orderId}`);
    };

    const downloadBase64File = (base64: string, name: string) => {
        if (!base64 || !name) return;
        const downloadAction = document.createElement('a');
        downloadAction.href = base64;
        downloadAction.download = `${name}`;
        document.body?.appendChild(downloadAction);
        downloadAction.click();
        document.body?.removeChild(downloadAction);
    };

    const getOrderRejectReason = (order: any) => {
        if (!order.orderRejectReason) return;

        return translate(`Rejected by @user`, { user: order.rejectedBy });
    };

    const handleClickCompleteOrder = async () => {
        if (!order) return;
        const shouldAskForPassword = !order.externalDelivery;

        if (shouldAskForPassword) {
            const rightPassword = await askForPassword({ password: '6431' });
            if (!rightPassword) return;
        }

        await completeOrder(order.orderId);
    };

    const completeOrder = async (orderId: OrderId) => {
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.admin.completeOrder({ orderId });

        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        const update = response.data;
        setOrder(update);
        setLoading(false);
        onChangeOrder && onChangeOrder(update);
    };

    return (
        <div style={{ position: 'relative' }}>
            <ChangeDynamicDeliveryEarningsDialog
                open={changeDynamicDeliveryEarningsDialogState.open}
                orderId={orderId}
                onClose={() => setChangeDynamicDeliveryEarningsDialogState({ open: false })}
                onSuccess={load}
            />
            {isOrderFinished && (
                <Button onClick={completeDeliveryManuallyForOrderCompleted} className={classes.buttonAccept}>
                    {translate('Free Driver')}
                </Button>
            )}
            <Card classes={{ root: classes.root }}>
                <CardHeader
                    title={
                        <Typography display='block' gutterBottom variant='h6'>
                            {translate('Order Details')}
                        </Typography>
                    }
                    action={
                        <div className={classes.optionsContainer}>
                            {order && <OrderVerificationPhotosButton order={order as any} />}
                            <ChangeToolbarButton onClick={() => setChangeDynamicDeliveryEarningsDialogState({ open: true })} />
                            {order?.orderStatus === OrderStatuses.COMPLETE && order?.paymentMethod === PaymentMethods.CREDIT_CARD && (
                                <InvoiceToolbarButton onClick={findDeliveryProofs} tooltip={translate('Download Delivery Proof')} />
                            )}
                            {order?.orderStatus === OrderStatuses.NEW && (
                                <>
                                    <Button
                                        color='primary'
                                        disabled={loading || viewUser}
                                        onClick={async () => {
                                            setLoading(true);
                                            const response = await letseatmanagerApiDeprecated.admin.acceptOrder({ orderId: order?.orderId });
                                            if (!response.ok) {
                                                setLoading(false);
                                                alertKnownErrorOrSomethingWentWrong(response);
                                                return;
                                            }
                                            const update = response.data;
                                            setOrder(update);
                                            setLoading(false);
                                            onChangeOrder && onChangeOrder(update);
                                        }}
                                    >
                                        <DoneIcon />
                                        {translate('Accept').toUpperCase()}
                                    </Button>
                                    <Button
                                        disabled={loading || viewUser}
                                        onClick={() => {
                                            openRejectOrderDialog({
                                                orderId: order?.orderId,
                                                onReject: (order: AdminOrderVm) => {
                                                    setOrder(order);
                                                    onChangeOrder && onChangeOrder(order);
                                                },
                                            });
                                        }}
                                    >
                                        <ClearIcon />
                                        {translate('Reject').toUpperCase()}
                                    </Button>
                                </>
                            )}
                            {order?.orderStatus === OrderStatuses.ACCEPTED && (
                                <>
                                    <Button color='primary' disabled={loading || viewUser} onClick={handleClickCompleteOrder}>
                                        <DoneIcon />
                                        {translate('Completed').toUpperCase()}
                                    </Button>
                                    <Button
                                        disabled={loading || viewUser}
                                        onClick={async () => {
                                            if (order?.manualOrder && !order?.driverId) {
                                                setLoading(true);
                                                const response = await letseatmanagerApiDeprecated.admin.cancelOrder({ orderId: order?.orderId });
                                                if (!response.ok) {
                                                    setLoading(false);
                                                    alertKnownErrorOrSomethingWentWrong(response);
                                                    return;
                                                }
                                                const update = response.data;
                                                setOrder(update);
                                                setLoading(false);
                                                onChangeOrder && onChangeOrder(update);
                                                return;
                                            }
                                            openRejectOrderDialog({
                                                orderId: order?.orderId,
                                                onReject: (order: AdminOrderVm) => {
                                                    setOrder(order);
                                                    onChangeOrder && onChangeOrder(order);
                                                },
                                            });
                                        }}
                                    >
                                        <ClearIcon />
                                        {order?.manualOrder && !order?.driverId ? translate('Cancel').toUpperCase() : translate('Reject').toUpperCase()}
                                    </Button>
                                </>
                            )}
                            <RefreshToolbarButton onClick={load} />
                        </div>
                    }
                />
                <CardContent>
                    <Form
                        onSubmit={() => {}}
                        initialValues={
                            order
                                ? {
                                      order: {
                                          ...order,
                                          sodexoCard: order.sodexoCard ? translate('Yes') : '',
                                          subtotal: formatAsCurrencyNumber(order.subtotal, { country: order.country as any }),
                                          productDiscount: formatAsCurrencyNumber(order.productDiscount, { country: order.country as any }),
                                          promoCodeDiscount: formatAsCurrencyNumber(order.promoCodeDiscount, { country: order.country as any }),
                                          promoCodeCredits: formatAsCurrencyNumber(order.promoCodeCredits, { country: order.country as any }),
                                          companyCredits: formatAsCurrencyNumber(order.companyCredits, { country: order.country as any }),
                                          letsEatCredits: formatAsCurrencyNumber(order.letsEatCredits, { country: order.country as any }),
                                          usedRestaurantCashbackCredits: formatAsCurrencyNumber(order.usedRestaurantCashbackCredits, { country: order.country as any }),
                                          total: formatAsCurrencyNumber(order.total, { country: order.country as any }),
                                          paidWithCard: formatAsCurrencyNumber(order.paidWithCard, { country: order.country as any }),
                                          paidWithPayroll: formatAsCurrencyNumber(order.paidWithPayroll, { country: order.country as any }),
                                          pickupTime: formatDateTimeStringReadable(order.pickupTime, order.timeZone),
                                          deliveryTime: formatDateTimeStringReadable(order.deliveryTime, order.timeZone),
                                          createdAt: formatDateTimeStringReadable(order.createdAt, order.timeZone),
                                          acceptedAt: formatDateTimeStringReadable(order.acceptedAt, order.timeZone),
                                          cancelledAt: formatDateTimeStringReadable(order.cancelledAt, order.timeZone),
                                          rejectedAt: formatDateTimeStringReadable(order.rejectedAt, order.timeZone),
                                          completedAt: formatDateTimeStringReadable(order.completedAt, order.timeZone),
                                          // TODO: refundedAt is not in OrderVm type
                                          refundedAt: formatDateTimeStringReadable((order as any).refundedAt, order.timeZone),
                                      },
                                  }
                                : {}
                        }
                        render={({ handleSubmit, submitting, pristine, values }) => (
                            <>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={4}>
                                        <Field
                                            name='order.orderStatus'
                                            component={TextField}
                                            label={translate('Order Status')}
                                            helperText={(() => {
                                                if (order?.orderRejectReason) return translate('Rejected by restaurant');
                                                if (order?.paymentRejectReason) return translate('Rejected by psp');
                                            })()}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <Field name='order.orderType' component={TextField} label={translate('Order Type')} fullWidth disabled />
                                    </Grid>
                                    {order?.orderRejectReason && (
                                        <Grid item xs={6} sm={4}>
                                            <Field
                                                name='order.orderRejectReason'
                                                component={TextField}
                                                label={translate('Reject Reason')}
                                                helperText={getOrderRejectReason(order)}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    )}
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={4}>
                                        <Field name='order.paymentStatus' component={TextField} label={translate('Payment Status')} fullWidth disabled />
                                    </Grid>
                                    <Grid item xs={6} sm={4} style={{ display: 'flex' }}>
                                        <Field name='order.paymentMethod' component={TextField} label={translate('Payment Method')} fullWidth disabled />
                                        {BigNumber(order?.mandatoryCashAmount ?? 0).isGreaterThan(0) && (
                                            <Field name='order.mandatoryCashAmount' component={TextField} label={translate('Pays with')} fullWidth disabled />
                                        )}
                                        {order?.paymentMethod === PaymentMethods.PAYMENT_LINK && paymentLink && (
                                            <Tooltip title={translate('Copy')}>
                                                <IconButton
                                                    color='primary'
                                                    onClick={() => {
                                                        copyTextToClipboard(paymentLink.url);
                                                        showSnackbar({ message: translate('Copied to clipboard') });
                                                    }}
                                                >
                                                    <FilterNoneIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        {order?.payments && <Field name='order.payments[0].customPaymentMethod' component={TextField} label={translate('Custom Payment Methods')} fullWidth disabled />}
                                    </Grid>
                                    {order?.sodexoCard && (
                                        <Grid item xs={6} sm={4}>
                                            <Field name='order.sodexoCard' component={TextField} label={translate('Sodexo Card')} fullWidth disabled />
                                        </Grid>
                                    )}
                                    {order?.refundMethod && (
                                        <Grid item xs={6} sm={4}>
                                            <Field name='order.refundMethod' component={TextField} label={translate('Refund Method')} fullWidth disabled />
                                        </Grid>
                                    )}
                                    {order?.paymentRejectReason && (
                                        <Grid item xs={6} sm={4}>
                                            <Field name='order.paymentRejectReason' component={TextField} label={translate('Reject Reason')} fullWidth disabled />
                                        </Grid>
                                    )}
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            name='order.pickupTime'
                                            component={TextField}
                                            label={translate('Pickup Time')}
                                            tooltip={formatDateTimeSecondsString(order?.pickupTime, TimeZones.ETC_UTC)}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Field name='order.pickupTimeType' component={TextField} label={translate('Pickup Time Type')} fullWidth disabled />
                                    </Grid>

                                    {order?.preparationTime && (
                                        <Grid item xs={12} sm={4}>
                                            <Field name='order.preparationTime' component={TextField} label={translate('Preparation Time')} fullWidth disabled />
                                        </Grid>
                                    )}

                                    {order?.deliveryTime && (
                                        <Grid item xs={12} sm={4}>
                                            <Field name='order.deliveryTime' component={TextField} label={translate('Delivery Time')} fullWidth disabled />
                                        </Grid>
                                    )}

                                    {order?.extraDrivingDistance && (
                                        <Grid item xs={12} sm={4}>
                                            <Field name='order.extraDrivingDistance' component={TextField} label={translate('Extra Driving Distance (meters)')} fullWidth disabled />
                                        </Grid>
                                    )}

                                    {order?.deliverySecurityCode && (
                                        <Grid item xs={12} sm={4}>
                                            <Field name='order.deliverySecurityCode' component={TextField} label={translate('Delivery Security Code')} fullWidth disabled />
                                        </Grid>
                                    )}
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            name='order.createdAt'
                                            component={TextField}
                                            label={translate('Created')}
                                            tooltip={formatDateTimeSecondsString(order?.createdAt, TimeZones.ETC_UTC)}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>

                                    {order?.acceptedAt && (
                                        <Grid item xs={12} sm={4}>
                                            <Field
                                                name='order.acceptedAt'
                                                component={TextField}
                                                label={translate('Accepted')}
                                                tooltip={formatDateTimeSecondsString(order?.acceptedAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    )}

                                    {order?.cancelledAt && (
                                        <Grid item xs={12} sm={4}>
                                            <Field
                                                name='order.cancelledAt'
                                                component={TextField}
                                                label={translate('Cancelled')}
                                                tooltip={formatDateTimeSecondsString(order?.cancelledAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                                helperText={translate('Cancelled by customer')}
                                                disabled
                                            />
                                        </Grid>
                                    )}

                                    {order?.rejectedAt && (
                                        <Grid item xs={12} sm={4}>
                                            <Field
                                                name='order.rejectedAt'
                                                component={TextField}
                                                label={translate('Rejected')}
                                                tooltip={formatDateTimeSecondsString(order?.rejectedAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    )}

                                    {order?.completedAt && (
                                        <Grid item xs={12} sm={4}>
                                            <Field
                                                name='order.completedAt'
                                                component={TextField}
                                                label={translate('Completed')}
                                                tooltip={formatDateTimeSecondsString(order?.completedAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    )}

                                    {(order as any)?.refundedAt && (
                                        <Grid item xs={12} sm={4}>
                                            <Field
                                                name='order.refundedAt'
                                                component={TextField}
                                                label={translate('Refunded')}
                                                tooltip={formatDateTimeSecondsString((order as any)?.refundedAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    )}
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            name='order.appVersion'
                                            component={TextField}
                                            label={translate('App Version')}
                                            helperText={translate('Version used to create this order')}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <br />
                                        <br />
                                    </Grid>
                                </Grid>

                                {order?.address && (
                                    <>
                                        <Grid item xs={12} sm={9}>
                                            <div>
                                                <Typography variant='caption'>{translate('Deliver To')}</Typography>
                                                <Typography variant='body1'>{order.address?.street}</Typography>
                                                {!!order.address?.formattedAddress && <Typography variant='body1'>{order.address?.formattedAddress}</Typography>}
                                                {!!order.address?.other && (
                                                    <>
                                                        <Typography variant='caption'>
                                                            {translate('Staircase/apartment/floor/other')}: {order.address?.other}
                                                        </Typography>
                                                        <br />
                                                    </>
                                                )}
                                                {!!order.address?.instructions && (
                                                    <>
                                                        <Typography variant='caption'>
                                                            {translate('Instructions')}: {order.address?.instructions}
                                                        </Typography>
                                                        <br />
                                                    </>
                                                )}
                                                <Button
                                                    color='primary'
                                                    size='small'
                                                    style={{ marginLeft: -4 }}
                                                    onClick={() => openDirections(order.restaurant?.location, requireValue(order.address as any).location, requireValue(order.address).googlePlaceId)}
                                                >
                                                    {translate('Show directions')}
                                                </Button>
                                            </div>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <br />
                                                <br />
                                            </Grid>
                                        </Grid>
                                    </>
                                )}

                                {order?.driverId && (
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4}>
                                            <Field name='order.driverId' component={TextField} label={translate('Driver Id')} fullWidth disabled />
                                        </Grid>
                                        {/* @ts-ignore */}
                                        <Hidden xsDown>
                                            <Grid item xs={6} sm={6}></Grid>
                                        </Hidden>
                                        <Grid item xs={6} sm={4}>
                                            <Field name='order.driver.firstName' component={TextField} label={translate('Driver First Name')} fullWidth disabled />
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <Field name='order.driver.lastName' component={TextField} label={translate('Driver Last Name')} fullWidth disabled />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Button color='primary' onClick={() => openDriverPageInNewTab(order?.driverId)}>
                                                {translate('OPEN DRIVER')}
                                            </Button>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <br />
                                                <br />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}

                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={4}>
                                        <Field name='order.subtotal' component={TextField} label={translate('Subtotal')} fullWidth disabled />
                                    </Grid>
                                </Grid>

                                {order?.deliveryCost && (
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={4}>
                                            <Field
                                                name='order.customerDeliveryCost'
                                                component={TextField}
                                                label={translate('Customer Delivery Cost')}
                                                helperText={translate('Delivery cost for customer')}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <Field
                                                name='order.restaurantDeliveryCost'
                                                component={TextField}
                                                label={translate('Restaurant Delivery Cost')}
                                                helperText={translate('Delivery cost for restaurant')}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <Field
                                                name='order.deliveryEarnings'
                                                component={TextField}
                                                label={translate('Delivery Earnings')}
                                                helperText={`${translate('Delivery earnings for driver')}, (google maps info: driving distance=${
                                                    formatAsDistance((order as any).drivingDistance) ?? 'unknown'
                                                }, driving duration=${formatAsDuration((order as any).drivingDuration) ?? 'unknown'}, bird distance=${
                                                    formatAsDistance((order as any).birdDistance) ?? 'unknown'
                                                })`}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <Field name='order.dynamicDeliveryEarnings' component={TextField} label={translate('Dynamic Delivery Earnings')} fullWidth disabled />
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <Field name='order.dynamicDeliveryEarningReason' component={TextField} label={translate('Dynamic Delivery Earnings Reason')} fullWidth disabled />
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <Field
                                                name='order.dynamicDeliveryEarningReasonGivenBy'
                                                component={TextField}
                                                label={translate('Dynamic Delivery Earnings Reason Given By')}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                )}

                                {order?.productDiscount && (
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={4}>
                                            <Field
                                                name='order.productDiscount'
                                                component={TextField}
                                                label={translate('Product Discount')}
                                                helperText={translate('Total discount from all deducted menu item promo prices')}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                )}

                                {order?.promoCode && (
                                    <Grid container spacing={3}>
                                        {order?.promoCodeDiscount && (
                                            <Grid item xs={6} sm={4}>
                                                <Field name='order.promoCodeDiscount' component={TextField} label={translate('Promo Code Discount')} fullWidth disabled />
                                            </Grid>
                                        )}
                                        {order?.promoCodeCredits && (
                                            <Grid item xs={6} sm={4}>
                                                <Field
                                                    name='order.promoCodeCredits'
                                                    component={TextField}
                                                    label={translate('Promo Code Credits')}
                                                    helperText={translate('Credits are received when order is completed')}
                                                    fullWidth
                                                    disabled
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={6} sm={4}>
                                            <Field name='order.promoCode' component={TextField} label={translate('Promo Code')} fullWidth disabled />
                                        </Grid>
                                    </Grid>
                                )}

                                {order?.companyCredits && (
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={4}>
                                            <Field
                                                name='order.companyCredits'
                                                component={TextField}
                                                label={translate('Company Credits')}
                                                placeholder={'0'}
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <Field name='order.companyName' component={TextField} label={translate('Company')} fullWidth disabled />
                                        </Grid>
                                    </Grid>
                                )}

                                {order?.usedRestaurantCashbackCredits && (
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={4}>
                                            <Field name='order.usedRestaurantCashbackCredits' component={TextField} label={translate('Restaurant Cashback')} fullWidth disabled />
                                        </Grid>
                                    </Grid>
                                )}

                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={4}>
                                        <Field name='order.total' component={TextField} label={translate('Total')} fullWidth disabled />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormSubheading align='left'>{`${translate('Order Items')}`}</FormSubheading>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <OrderItems orderItems={order?.orderItems} showDetails={true} />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    />
                </CardContent>
            </Card>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    buttonAccept: {
        width: '100%',
        height: 42,
        margin: '3 auto',
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main}aa`,
        },
    },
    optionsContainer: {
        width: '100%',
        display: 'flex',
    },
}));

type Props = {
    orderId: OrderId;
    onChangeOrder?: (arg1: AdminOrderVm) => any;
};
