/**
 * @prettier
 */
import type { RRuleString } from 'src/types/Id';
import { createRRule } from 'src/utils/rrule/createRRule';

export function rRuleStringToText(value: RRuleString): string {
    if (!value) {
        return '';
    }
    return createRRule(value)?.toText() ?? '';
}
