/**
 * @prettier
 */
import * as React from 'react';
import { DeprecatedPrintersPage } from 'src/scenes/letseatmanager/DeprecatedPrintersPage';
import { PrintersPage } from 'src/scenes/letseatmanager/PrintersPage';
import { useSelector } from 'src/utils/react/useSelector';

//TODO: Remove when its safe
export function TemporalPrintersPage(): React.ReactElement {
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);

    if (newDeviceManagementEnabled) return <PrintersPage />;

    return <DeprecatedPrintersPage />;
}
