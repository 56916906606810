/**
 * @prettier
 */
import { Button, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import LaunchIcon from '@material-ui/icons/Launch';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getOrderApi } from 'src/api/letseatmanager/order/getOrderApi';
import type { PaymentLinkVm } from 'src/api/letseatmanager/paymentLink/createPaymentLinkApi';
import { findPaymentLinksApi } from 'src/api/letseatmanager/paymentLink/findPaymentLinksApi';
import { InformationAlertDialog } from 'src/components/dialog/InformationAlertDialog';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { PaymentLinkStatuses } from 'src/constants/PaymentLinkStatus';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { DeprecatedOrderDetailsDialog } from 'src/scenes/letseatadmin/order/DeprecatedOrderDetailsDialog';
import { ChangePaymentLinkDialog } from 'src/scenes/letseatmanager/paymentLinks/ChangePaymentLinkDialog';
import { CreatePaymentLinkDialog } from 'src/scenes/letseatmanager/paymentLinks/CreatePaymentLinkDialog';
import { RemovePaymentLinkDialog } from 'src/scenes/letseatmanager/paymentLinks/RemovePaymentLinkDialog';
import { appColors } from 'src/theme/AppColors';
import type { PaymentLinkId } from 'src/types/Id';
import { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { copyTextToClipboard } from 'src/utils/html/copyTextToClipboard';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { formatAsNumber } from 'src/utils/number/formatAsNumber';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function PaymentLinksPage(): React.ReactElement | null {
    const classes = useStyles();

    const [openCreatePaymentLinkDialog, setOpenCreatePaymentLinkDialog] = useState(false);
    const [loadingOrder, setLoadingOrder] = useState(false);
    const [changePaymentLinkDialogState, setChangePaymentLinkDialogState] = useState<ChangePaymentLinkDialogState>({} as any);
    const [paymentLinks, setPaymentLinks] = useState<Array<PaymentLinkVm>>([]);
    const [removePaymentLinkState, setRemovePaymentLinkState] = useState<{ open: boolean; paymentLinkIds: Array<PaymentLinkId> | undefined }>({ open: false, paymentLinkIds: undefined });
    const [orderDetailsDialogState, setOrderDetailsDialogState] = useState<{ open: boolean; order: OrderVm | undefined }>({ open: false, order: undefined });
    const [openInformationAlertDialog, setOpenInformationAlertDialog] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const clabe = useSelector((state) => state.app.restaurant.clabe);
    const bank = useSelector((state) => state.app.restaurant.bank);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const [loadingPaymentLinks, paymentLinksData, load] = useLoadApi(
        findPaymentLinksApi,
        {
            restaurantIds,
        },
        { initialValue: { paymentLinks: [] }, dependencies: [restaurantIds] }
    );

    useEffect(() => {
        if (paymentLinksData.paymentLinks) {
            setPaymentLinks(paymentLinksData.paymentLinks);
        }
    }, [paymentLinksData]);

    const handleRemovePaymentLink = (paymentLinkIds: Array<PaymentLinkId>) => {
        setRemovePaymentLinkState({
            open: true,
            paymentLinkIds,
        });
    };

    const handleCloseRemovePaymentLink = () => {
        setRemovePaymentLinkState({
            open: false,
            paymentLinkIds: undefined,
        });
    };

    const handleClickNew = () => {
        if (!clabe || !bank) return setOpenInformationAlertDialog(true);
        setOpenCreatePaymentLinkDialog(true);
    };

    const handleOpenOrder = async (orderId: any) => {
        setLoadingOrder(true);
        const response = await getOrderApi({ orderId });
        setLoadingOrder(false);
        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        setOrderDetailsDialogState({
            open: true,
            order: response.data,
        });
    };

    const handleCloseOrderDetailsDialog = () => {
        setOrderDetailsDialogState({
            open: false,
            order: undefined,
        });
    };

    const getStatusColor = (status: any) => {
        if (status === PaymentLinkStatuses.PAID || status === PaymentLinkStatuses.PAYED) return appColors.text.success;
        if (status === PaymentLinkStatuses.CANCELLED) return appColors.text.danger;
        return '';
    };

    return (
        <SecuredAndSubscribedPage title={translate('Payment Links')} rolePermission={RolePermissions.PAYMENT_LINKS_PAGE} className={classes.container}>
            <UpdatingContentProgress loading={loadingPaymentLinks} />
            <InformationAlertDialog
                openInformationAlert={openInformationAlertDialog}
                handleClose={() => setOpenInformationAlertDialog(false)}
                title={translate('Clabe and Bank not found')}
                dialogContent={translate('Restaurant clabe and bank not found, please verify that you have set your data correctly')}
            />
            <DeprecatedOrderDetailsDialog open={orderDetailsDialogState.open} order={orderDetailsDialogState.order} handleClose={handleCloseOrderDetailsDialog} />
            <RemovePaymentLinkDialog open={removePaymentLinkState.open} onSuccess={load} onClose={handleCloseRemovePaymentLink} paymentLinkIds={removePaymentLinkState.paymentLinkIds} />
            <CreatePaymentLinkDialog open={openCreatePaymentLinkDialog} onClose={() => setOpenCreatePaymentLinkDialog(false)} onPaymentLinkCreated={load} />
            <ChangePaymentLinkDialog
                open={changePaymentLinkDialogState.open}
                onClose={() => setChangePaymentLinkDialogState({ ...changePaymentLinkDialogState, open: false })}
                onPaymentLinkChanged={load}
                prevAmount={changePaymentLinkDialogState.amount}
                paymentLinkId={changePaymentLinkDialogState.paymentLinkId}
            />
            <Table
                className={classes.table}
                data={paymentLinks.map((paymentLink) => ({
                    paymentLinkId: paymentLink.paymentLinkId,
                    amount: paymentLink.amount,
                    status: paymentLink.status,
                    url: paymentLink.url,
                    delete: paymentLink.paymentLinkId,
                    created: formatDateTimeString(paymentLink.createdAt),
                    paidAt: formatDateTimeString(paymentLink.paidAt),
                    orderId: paymentLink.orderId,
                }))}
                columns={[
                    {
                        name: 'paymentLinkId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'created',
                        label: translate('Created'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'amount',
                        label: translate('Amount'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'status',
                        label: translate('Status'),
                        options: {
                            filter: false,
                            customBodyRender: (status) => <div style={{ color: getStatusColor(status) }}>{translate(`PaymentLinkStatuses.${status}`)}</div>,
                        },
                    },
                    {
                        name: 'paidAt',
                        label: translate('Paid'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'orderId',
                        label: translate('Order:'),
                        options: {
                            filter: false,
                            customBodyRender: (orderId) => {
                                if (!orderId) return null;
                                return (
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'left' }}>
                                        <Button onClick={() => handleOpenOrder(orderId)} disabled={loadingOrder}>
                                            <div className={classes.openOrderButton}>
                                                <LaunchIcon className={classes.icon} />
                                                <span>{translate('Open order')}</span>
                                            </div>
                                        </Button>
                                    </div>
                                );
                            },
                        },
                    },
                    {
                        name: 'orderId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'url',
                        label: translate('Copy'),
                        options: {
                            filter: false,
                            searchable: true,
                            sort: false,
                            customBodyRender: (url) => {
                                return (
                                    <IconButton
                                        color='primary'
                                        onClick={() => {
                                            copyTextToClipboard(url);
                                            showSnackbar({ message: translate('Copied to clipboard') });
                                        }}
                                    >
                                        <FilterNoneIcon fontSize='small' />
                                    </IconButton>
                                );
                            },
                            setCellProps: () => {
                                return {
                                    style: { paddingLeft: 8, paddingRight: 0 },
                                };
                            },
                        },
                    },
                    {
                        name: 'delete',
                        label: ' ',
                        options: {
                            filter: false,
                            searchable: true,
                            sort: false,
                            customBodyRender: (paymentLinkId) => {
                                return (
                                    <IconButton color='primary' onClick={() => handleRemovePaymentLink([paymentLinkId])} disabled={viewUser}>
                                        <CloseIcon fontSize='small' />
                                    </IconButton>
                                );
                            },
                            setCellProps: () => {
                                return {
                                    style: { paddingLeft: 8, paddingRight: 0 },
                                };
                            },
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: !viewUser ? 'multiple' : 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                            {!brandOpened && <CreateToolbarButton onClick={handleClickNew} />}
                        </>
                    ),
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedPaymentLinksId = selectedRowsData.map((d: PaymentLinkId) => d[0]);

                        const handleChangePaymentLink = () => {
                            const paymentLinkSelected = paymentLinks.find((paymentLink) => paymentLink.paymentLinkId === selectedPaymentLinksId[0]);
                            const paymentLinkWithOrder = selectedRowsData[0][6];

                            const paymentLinkMissingPay = paymentLinkSelected?.status === PaymentLinkStatuses.UNPAID || paymentLinkSelected?.status === PaymentLinkStatuses.FAILED;

                            if (paymentLinkWithOrder) {
                                return showSnackbar({ message: translate('Payment link is linked to an order, it cannot be edited.') });
                            }

                            if (!paymentLinkMissingPay && paymentLinkSelected) {
                                return showSnackbar({ message: translate('Payment link is @state, it cannot be edited', { state: translate(`PaymentLinkStatuses.${paymentLinkSelected.status}`) }) });
                            }

                            setChangePaymentLinkDialogState({
                                open: true,
                                amount: formatAsNumber(selectedRowsData[0][3]),
                                paymentLinkId: selectedPaymentLinksId[0],
                            });
                        };

                        return (
                            <div className={classes.toolbar}>
                                {selectedPaymentLinksId.length === 1 && <ChangeToolbarButton onClick={handleChangePaymentLink} />}
                                <RemoveToolbarButton onClick={() => handleRemovePaymentLink(selectedPaymentLinksId)} />
                            </div>
                        );
                    },
                    // filterList: [[], [], [], [], ['MEXICO']],
                }}
            />
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
    openOrderButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& span': {
            fontFamily: theme.typography.medium,
            textTransform: 'none',
        },
    },
    icon: {
        color: theme.palette.primary.main,
        marginRight: 10,
    },
}));

type ChangePaymentLinkDialogState = {
    amount: string;
    open: boolean;
    paymentLinkId: PaymentLinkId;
};
