/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Loader } from '@pidedirecto/ui';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import Select from 'src/components/deprecated/react-final-form/Select';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { Spacing } from 'src/components/spacing/Spacing';
import { Apps } from 'src/constants/App';
import type { CustomerType } from 'src/constants/CustomerType';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { CustomerId, LanguageTag } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { fullName } from 'src/utils/customer/fullName';
import { deleteUrlQueryParameter } from 'src/utils/history/deleteUrlQueryParameter';
import { getUrlQueryParameter } from 'src/utils/history/getUrlQueryParameter';
import { required } from 'src/utils/Validator';

export function CustomerSendDialog(props: Props): React.ReactElement {
    const classes = useStyles();
    const history = useHistory();

    const [open, setOpen] = useState(false);
    const [customer, setCustomer] = useState<{ customerId: CustomerId; name: string }>(INITIAL_STATE.customer);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setError] = useState(null);

    const customerId: CustomerId = getUrlQueryParameter(SEND_NOTIFICATION, history.location);

    useEffect(() => {
        async function openCustomerDialog() {
            if (customerId) {
                await openDialog(customerId);
            }
        }

        openCustomerDialog();
    }, [customerId]);

    const handleClose = () => {
        if (submitting) {
            return;
        }
        if (history.length <= 2) {
            history.replace({
                path: history.location.pathname,
                search: deleteUrlQueryParameter(SEND_NOTIFICATION),
            });
            return;
        }
        setOpen(false);
        history.goBack();
    };

    const handleExited = () => {
        setCustomer(INITIAL_STATE.customer);
        setOpen(false);
        setLoading(false);
        setSubmitting(false);
    };

    const openDialog = async (customerId: CustomerId) => {
        setOpen(true);
        await load(customerId);
    };

    const load = async (customerId: CustomerId) => {
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.admin.getCustomer({ customerId });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setOpen(false);
            return;
        }
        const customer: CustomerVm = response.data;
        setCustomer({
            customerId: customer.customerId,
            name: fullName(customer.firstName, customer.lastName),
        });
    };

    const onSubmit = async (form: any) => {
        setSubmitting(true);
        const response = await letseatmanagerApiDeprecated.admin.sendNotificationToCustomer({
            customerId: form.customer.customerId,
            app: form.app,
            title: form.title,
            body: form.body,
        });
        setSubmitting(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        handleClose();
    };

    return (
        <Dialog aria-labelledby='customer-send-dialog-title' fullWidth scroll='paper' open={open} onClose={handleClose} onExited={handleExited}>
            <Form
                onSubmit={onSubmit}
                initialValues={{
                    customer: customer,
                    app: Apps.PIDEDIRECTOAPP,
                    title: props.template ? props.template.title || '' : '',
                    body: props.template ? props.template.body || '' : '',
                }}
                render={({ handleSubmit, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                        <DialogTitle id='customer-send-dialog-title'>{translate('Send Notification To Customer').toUpperCase()}</DialogTitle>
                        <DialogContent>
                            {loading ? (
                                <Loader size={40} loading={true} />
                            ) : (
                                <>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Field name='customer.customerId' component={TextField} label={translate('Customer Id')} fullWidth disabled />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Field name='customer.name' component={TextField} label={translate('Name')} fullWidth disabled />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Field fullWidth name='app' label={translate('App')} component={Select} formControlProps={{ fullWidth: true }} required validate={required}>
                                                <MenuItem value={Apps.PIDEDIRECTOAPP}>{translate('Apps.PIDEDIRECTOAPP')}</MenuItem>
                                                <MenuItem value={Apps.LETSEATAPP}>{translate('LETSEATAPP')}</MenuItem>
                                            </Field>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Field
                                                name='title'
                                                component={TextField}
                                                label={translate('Title')}
                                                helperText={`${translate('@name will be replaced with customers first name or cliente')}`}
                                                fullWidth
                                                disabled={submitting}
                                                required
                                                validate={required}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Field
                                                name='body'
                                                component={TextField}
                                                multiline
                                                label={translate('Body')}
                                                helperText={`${translate('@name will be replaced with customers first name or cliente')}`}
                                                fullWidth
                                                disabled={submitting}
                                                required
                                                validate={required}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container justify='space-around'>
                                        <Grid item>
                                            <Spacing units={8} />
                                            {errorMessage && (
                                                <Typography display='block' color='error'>
                                                    {errorMessage}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} disabled={submitting}>
                                {translate('Cancel').toUpperCase()}
                            </Button>
                            <Button color='primary' type='submit' disabled={submitting}>
                                {submitting ? translate('Sending').toUpperCase() : translate('Send').toUpperCase()}
                            </Button>
                        </DialogActions>
                        {submitting && <LinearProgress className={classes.linearProgress} />}
                    </form>
                )}
            />
        </Dialog>
    );
}

export const SEND_NOTIFICATION = 'send-notification-customer';

const useStyles = makeStyles((theme) => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
}));

const INITIAL_STATE = {
    open: false,
    customer: {
        customerId: '' as any,
        name: '',
    },
    loading: false,
    submitting: false,
} as const;

type Props = {
    template?: {
        title: string;
        body: string;
    };
};

type CustomerVm = {
    customerId: CustomerId;
    customerType: CustomerType;
    mobileNumber?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    creditCards?: number;
    credits?: string;
    language?: LanguageTag;
    fcmPermission?: boolean;
    downloadedAppAt?: Date;
    signedUpAt?: Date;
    addedFirstCreditCardAt?: Date;
};
