/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import * as React from 'react';
import type { TimeZone } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import styles from 'src/scenes/letseatadmin/monitoring/driver-late-for-pickup-alarm/DriverLateForPickupAlarmStyles';
import type { DriverId, OrderId, RestaurantId } from 'src/types/Id';
import { classNames } from 'src/utils/react/classNames';
import { openOrderPageInNewTab } from 'src/utils/window/openOrderPageInNewTab';

class DriverLateForPickupAlarm extends React.PureComponent<Props, State> {
    interval: NodeJS.Timer | undefined;

    constructor(props: Props) {
        super(props);
        this.state = {
            highlighted: false,
        };
    }

    toggleHighlight = () => {
        this.setState({
            highlighted: !this.state.highlighted,
        });
    };

    componentDidMount() {
        this.interval = setInterval(() => {
            this.toggleHighlight();
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    openOrder = async () => {
        try {
            const { driverLateForPickupAlarm } = this.props;
            openOrderPageInNewTab(driverLateForPickupAlarm.orderId);
        } catch (e: any) {
            console.log(e);
        }
    };

    render() {
        const { classes, driverLateForPickupAlarm } = this.props;
        return (
            <Paper className={classNames(classes.paper, { [classes.highlighted]: this.state.highlighted })}>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <Typography display='block' variant='caption'>
                            {translate('RESTAURANT')}
                        </Typography>
                        <Typography display='block' variant='h6'>
                            {driverLateForPickupAlarm.restaurantName}
                        </Typography>
                        <Typography variant='subtitle1'>{driverLateForPickupAlarm.restaurantPhoneNumber}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography display='block' variant='caption'>
                            {translate('DRIVER HAS NOT PICKED UP ORDER')}
                        </Typography>
                        <Typography display='block' variant='h6'>
                            {driverLateForPickupAlarm.driverName}
                        </Typography>
                        <Typography variant='subtitle1'>{driverLateForPickupAlarm.driverMobileNumber}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography display='block' variant='caption'>
                            {translate('PICKUP TIME')}
                        </Typography>
                        <Typography variant='h6'>{(moment.duration(moment().diff(moment(driverLateForPickupAlarm.pickupTime))) as any).format()}</Typography>
                        <Typography variant='subtitle1'>{moment(driverLateForPickupAlarm.pickupTime).calendar()}</Typography>
                        <Button variant='contained' color='primary' fullWidth onClick={this.openOrder}>
                            {translate('Open Order')}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

const DriverLateForPickupAlarmWithStyles: React.ComponentType<any> = withStyles(styles)(DriverLateForPickupAlarm);

export default DriverLateForPickupAlarmWithStyles;

type Props = {
    driverLateForPickupAlarm: DriverLateForPickupAlarmVm;
    classes: any;
    acceptDrivers: any;
};

type State = {
    highlighted: boolean;
};

export type DriverLateForPickupAlarmVm = {
    restaurantId: RestaurantId;
    restaurantName: string;
    restaurantPhoneNumber: string;
    driverId: DriverId;
    driverName: string;
    driverMobileNumber: string;
    pickupTime: Date;
    orderId: OrderId;
    timeZone: TimeZone;
};
