/**
 * @prettier
 */
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import { SearchIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

/**
 * @example
 * Use <Input type='search' /> instead
 * @deprecated
 */

export function SearchBar({ query, placeholder }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.searchBar}>
            <SearchIcon />
            <InputBase placeholder={translate(placeholder)} className={classes.input} onChange={query} />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    searchBar: {
        display: 'flex',
        width: '100%',
        height: 50,
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        gap: 10,
        border: `1px solid ${theme.palette.border.primary}`,
        marginTop: 20,
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.down('sm')]: {
            marginTop: 150,
        },
    },
    input: {
        width: '100%',
    },
    searchIcon: {
        marginRight: theme.spacing(1),
    },
}));

type Props = {
    query: any;
    placeholder: string;
};
