/**
 * @prettier
 */
import { Button } from '@pidedirecto/ui';
import { useState } from 'react';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { CreateUruguayanOrderInvoiceDialog } from 'src/components/dialog/order/createOrderInvoiceDialog/CreateUruguayanOrderInvoiceDialog';
import { FiscalEntities } from 'src/constants/FiscalEntity';
import { translate } from 'src/i18n/translate';
import { SentryService } from 'src/services/SentryService';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isPedidosYaApp } from 'src/utils/app/isPedidosYaApp';
import { isUruguay } from 'src/utils/country/isUruguay';
import { isDeliveredDelivery } from 'src/utils/delivery/isDeliveredDelivery';
import { isPickedUpDelivery } from 'src/utils/delivery/isPickedUpDelivery';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isRoomServiceOrder } from 'src/utils/order/isRoomServiceOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { canRestaurantInvoice } from 'src/utils/restaurant/canRestaurantInvoice';

export function CompleteAndInvoiceRucUruguayanOrderButton({ order, onSuccess, classes: classesProp }: Props): React.ReactElement | null {
    const [loading, setLoading] = useState(false);
    const [createUruguayanOrderInvoiceDialog, setCreateUruguayanOrderInvoiceDialog] = useState({ open: false });

    const markReadyForPickupButtonEnabled = useSelector((state) => state.app.restaurant.markReadyForPickupButtonEnabled);
    const externalDelivery = useSelector((state) => state.app.restaurant.externalDelivery);
    const restaurant = useSelector((state) => state.app.restaurant);
    const autoInvoiceOrdersEnabled = useSelector((state) => state.app.restaurant.autoInvoiceOrdersEnabled);

    const externalDeliveryOrdersHasBeenPickedUp =
        (externalDelivery && isDeliveryOrder(order.orderType) && isPickedUpDelivery(order.deliveryStatus) && !isDeliveredDelivery(order.deliveryStatus)) || isPedidosYaApp(order.app);
    const takeAwayOrderHasBeenPickedUp = (!!order.markedReadyForPickupAt && markReadyForPickupButtonEnabled) || !markReadyForPickupButtonEnabled;
    const shouldCompleteOrderForTakeAwayOrder = isTakeAwayOrder(order.orderType) && takeAwayOrderHasBeenPickedUp && isAcceptedOrder(order.orderStatus);
    const isReadyForPickup = (isEatHereOrder(order.orderType) || isRoomServiceOrder(order.orderType)) && order.markedReadyForPickupAt && isAcceptedOrder(order.orderStatus);

    const shouldRenderButton =
        (externalDeliveryOrdersHasBeenPickedUp || shouldCompleteOrderForTakeAwayOrder || isReadyForPickup) &&
        canRestaurantInvoice(restaurant) &&
        isUruguay(restaurant.country) &&
        !order.invoiced &&
        autoInvoiceOrdersEnabled;

    const handleClick = async (event: any) => {
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.currentOrders.completeOrder({
            orderId: order.orderId,
            uruguayanRucInvoice: true,
        });
        setLoading(false);
        if (!response.ok) {
            SentryService.logError('Failed to complete order', { completeOrderRequest: { orderId: order.orderId }, response });
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        SentryService.logInfoBreadcrumb('Successfully completed order', { completeOrderRequest: { orderId: order.orderId } });
        setCreateUruguayanOrderInvoiceDialog({ open: true });
    };

    const closeCreateUruguayanOrderInvoiceDialog = () => setCreateUruguayanOrderInvoiceDialog({ open: false });

    if (!shouldRenderButton) return null;

    return (
        <>
            <CreateUruguayanOrderInvoiceDialog
                order={order}
                open={createUruguayanOrderInvoiceDialog.open}
                onClose={closeCreateUruguayanOrderInvoiceDialog}
                onSuccess={onSuccess}
                defaultFiscalEntity={FiscalEntities.BUSINESS}
            />
            <Button variant={'outline'} onClick={handleClick} disabled={loading} classes={{ button: classesProp?.button }}>
                {translate('Complete with RUC')}
            </Button>
        </>
    );
}

type Props = {
    order: OrderVm;
    onSuccess?: any;
    classes?: {
        button?: string;
    };
};
