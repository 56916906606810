/**
 * @prettier
 * @flow
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Text } from '@pidedirecto/ui';
import QRCode from 'qrcode.react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { copyTextToClipboard } from 'src/utils/html/copyTextToClipboard';
import { useAction } from 'src/utils/react/useAction';

export function Qr({ code, size, hasBorder, children }: Props): React.ReactElement | null {
    const classes = useStyles();

    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const copyToClipboard = () => {
        if (!code) return;

        copyTextToClipboard(code);
        showSnackbar({ message: translate('Copied to clipboard') });
    };

    const downloadQrCode = () => {
        const canvas: any = document.getElementById('qrcode');
        const pngUrl = canvas.toDataURL('image/png');

        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = `${code}.png`;
        downloadLink.click();
    };

    if (!code) return null;

    return (
        <Grid className={classes.qrContainer} container direction='column'>
            <Grid item>{children}</Grid>
            <Grid item className={hasBorder ? classes.qrBorder : ''}>
                <QRCode id='qrcode' value={code} size={size ?? 120} />
            </Grid>
            <Grid item>
                <Text className={classes.text} size={'small'}>
                    {code}
                </Text>
            </Grid>
            <Grid item container direction='row' justify='center' alignItems='center' spacing={1}>
                <Grid item xs={6}>
                    <Button outlined classes={{ button: classes.button }} onClick={() => copyToClipboard()}>
                        {translate('Copy Url')}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button outlined classes={{ button: classes.button }} onClick={() => downloadQrCode()}>
                        {translate('Download QR')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    qrContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        width: '100%',
    },
    qrBorder: {
        padding: 10,
        borderRadius: 8,
        border: `10px solid black`,
    },
    text: {
        backgroundColor: `${theme.palette.secondary.dark}`,
        borderRadius: 4,
        padding: 2,
        paddingLeft: 10,
        paddingRight: 10,
    },
}));

type Props = {
    code: string;
    size?: number;
    hasBorder?: boolean;
    children?: React.ReactNode;
};
