/**
 * @prettier
 */
import { usePosPaymentMethodsStore } from 'src/services/pos/posPaymentMethods/posPaymentMethodsStore';
import { RestaurantPaymentMethodVm } from 'src/types/RestaurantPaymentMethodVm';

export function usePosPaymentMethods(): Array<RestaurantPaymentMethodVm> {
    const posPaymentMethods = usePosPaymentMethodsStore((state) => state.posPaymentMethods);

    return posPaymentMethods;
}
