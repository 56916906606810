/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BannerVisibility } from 'src/constants/BannerVisibility';
import type { Country } from 'src/constants/Country';
import type { BannerVm } from 'src/types/BannerVm';
import type { DeliveryCategory, RestaurantId } from 'src/types/Id';

export async function createBannerApi(request: CreateBannerApiRequest): ApiSauceResponse<BannerVm> {
    return letseatadminApiMethod('banner/createBannerApi', request);
}

export type CreateBannerApiRequest = {
    bannerVisibility?: BannerVisibility;
    deliveryCategories?: Array<DeliveryCategory>;
    enabled: boolean;
    hours: string;
    country: Country;
    imageUrl: string;
    restaurantIds?: Array<{
        restaurantId: RestaurantId;
        name: string;
    }>;
    companyNames?: Array<string>;
};
