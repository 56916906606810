/**
 * @prettier
 */
export const DeviceOsTypes = {
    WINDOWS: 'WINDOWS',
    MAC_OS: 'MAC_OS',
    ANDROID: 'ANDROID',
    IOS: 'IOS',
} as const;

export type DeviceOs = typeof DeviceOsTypes[keyof typeof DeviceOsTypes] | string;
