/**
 * @prettier
 */
import { Button, Table } from '@pidedirecto/ui';
import { useLoadApi } from '@pidedirecto/ui/hooks';
import { RefreshIcon } from '@pidedirecto/ui/icons';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import * as React from 'react';
import { findOrderIncidentsByRestaurantApi } from 'src/api/letseatadmin/orderIncident/findOrderIncidentsByRestaurantApi';
import { translate } from 'src/i18n/translate';
import { CreateOrderIncidentToolbarButton } from 'src/scenes/letseatadmin/restaurantManualEarning/CreateOrderIncidentToolbarButton';
import { ViewOrderIncidentImageButton } from 'src/scenes/letseatadmin/restaurantManualEarning/ViewOrderIncidentImageButton';
import { OrderIncidentVm } from 'src/types/OrderIncidentVm';
import { useSelector } from 'src/utils/react/useSelector';
import { toShortId } from 'src/utils/uuid/toShortId';

export function OrderIncidentsTable({ filter }: Props): React.ReactElement {
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, orderIncidents, refreshOrderIncidents] = useLoadApi(
        findOrderIncidentsByRestaurantApi,
        {
            restaurantId,
            from: filter?.from,
            to: filter?.to,
        },
        { initialValue: [], requiredValues: [filter, restaurantId], dependencies: [filter, restaurantId] }
    );

    const searchOrderIncident = (search: string, orderIncident: OrderIncidentVm) => {
        const matchRestaurantName = orderIncident.restaurantName.toLowerCase().includes(search.toLowerCase());
        const matchManagerUsername = orderIncident.managerUsername.toLowerCase().includes(search.toLowerCase());
        const matchOrderId = toShortId(orderIncident.orderId).toLowerCase().includes(search.toLowerCase());
        return matchRestaurantName || matchManagerUsername || matchOrderId;
    };

    return (
        <Table
            title={translate('Order incidents')}
            loading={loading}
            columns={[
                {
                    id: 'orderId',
                    content: translate('Order Id'),
                },
                {
                    id: 'zendeskId',
                    content: translate('Ticket'),
                },
                {
                    id: 'refundAmount',
                    content: translate('refund'),
                },
                {
                    id: 'orderIncidentType',
                    content: translate('Reason'),
                },
                {
                    id: 'managerUsername',
                    content: translate('Agent'),
                },
                {
                    id: 'imageUrl',
                    content: translate('Evidence'),
                },
            ]}
            rows={orderIncidents.map((orderIncident) => ({
                orderId: toShortId(orderIncident.orderId),
                zendeskId: orderIncident.zendeskId,
                refundAmount: formatAsCurrencyNumber(orderIncident.refundAmount),
                orderIncidentType: translate(`OrderIncidentTypes.${orderIncident.orderIncidentType}`),
                managerUsername: orderIncident.managerUsername,
                imageUrl: orderIncident.imageUrl ? <ViewOrderIncidentImageButton orderIncident={orderIncident} /> : translate('Without file'),
                onSearch: (search: string) => searchOrderIncident(search, orderIncident),
            }))}
            searchable
            filters={{
                columns: true,
            }}
            toolbar={
                <>
                    <Button variant='secondary' onClick={() => refreshOrderIncidents()}>
                        <RefreshIcon size={20} title={translate('Refresh order incidents')} />
                    </Button>
                    <CreateOrderIncidentToolbarButton />
                </>
            }
        />
    );
}

type Props = {
    filter: {
        from: Date;
        to: Date;
    };
};
