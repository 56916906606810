/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import UpdateIcon from '@material-ui/icons/Update';
import { Loader } from '@pidedirecto/ui';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getOrderSalesApi } from 'src/api/letseatmanager/order/getOrderSalesApi';
import { getOrdersApi } from 'src/api/letseatmanager/order/getOrdersApi';
import { ApiErrorResponse } from 'src/api/types/ApiSauceResponse';
import { IncomingOrdersAlert } from 'src/components/alertDialog/order/IncomingOrdersAlert';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { OnEndReached } from 'src/components/OnEndReached';
import { OrderCardDeprecated } from 'src/components/orderCard/OrderCardDeprecated';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import TheMenuActions from 'src/scenes/letseatmanager/deprecatedMenu/TheMenuReducer';
import { OrdersSalesInfo } from 'src/scenes/letseatmanager/orders/OrdersSalesInfo';
import { useFindOpenedUserCashRegisterPosBusinessDay } from 'src/services/cashRegister/useFindOpenedUserCashRegisterPosBusinessDay';
import { useDownloadOrdersSalesCsv } from 'src/services/csv/useDownloadOrdersSalesCsv';
import { useIsOrderVisibleForUser } from 'src/services/pos/useIsOrderVisibleForUser';
import { SentryService } from 'src/services/SentryService';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { useUnzipLoadApi } from 'src/utils/react/useUnzipLoadApi';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

/** @deprecated */
export function AllOrdersDeprecated(): React.ReactElement {
    const classes = useStyles();
    const limit = 100;
    const INITIAL_PAGE = 1;

    const [userHasRolePermission] = useUserHasRolePermission();
    const isOrderVisibleForUser = useIsOrderVisibleForUser();
    const downloadOrdersSalesCsv = useDownloadOrdersSalesCsv();
    const { findUserCashRegisterPosBusinessDay } = useFindOpenedUserCashRegisterPosBusinessDay();

    const [orders, setOrders] = useState([]);
    const [pageOfOrders, setPageOfOrders] = useState(INITIAL_PAGE);
    const [sales, setSales] = useState({});

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const menus = useSelector((state) => state.theMenu.menus);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant.posMultipleCashRegistersEnabled);
    const openedCashRegisterPosBusinessDay = useSelector((state) => state.pos.openedCashRegisterPosBusinessDay);
    const displayPeriodByDateAndTimeEnabled = useSelector((state) => state.app.restaurant?.displayPeriodByDateAndTimeEnabled);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const [from, setFrom] = useState<Moment | string | Date>(moment().startOf('day'));
    const [to, setTo] = useState<Moment | string | Date>(moment().endOf('day'));

    const fetchMenus = useAction(TheMenuActions.fetch);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const userOpenedCashRegisterPosBusinessDay = findUserCashRegisterPosBusinessDay();

    const cashRegisterPosBusinessDayId = openedCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId ?? userOpenedCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId;
    const shouldFilterByCashRegisterPosBusinessDayId =
        userHasRolePermission(RolePermissions.VIEW_ONLY_ORDERS_IN_PERSONAL_CASH_REGISTER) &&
        !!posMultipleCashRegistersEnabled &&
        (!!openedCashRegisterPosBusinessDay || !!userOpenedCashRegisterPosBusinessDay) &&
        !brandOpened;

    const onError = (response: ApiErrorResponse, _: any) => {
        SentryService.logInfoBreadcrumb('Failed to download the orders view model', { response });
    };

    const [loading, ordersReport, load] = useUnzipLoadApi(
        getOrdersApi,
        {
            restaurantIds,
            from: moment(formatDateToRestaurantTimeZone(from as any)).toDate(),
            to: moment(formatDateToRestaurantTimeZone(to as any)).toDate(),
            maxOrders: limit,
            page: pageOfOrders,
            cashRegisterPosBusinessDayId: shouldFilterByCashRegisterPosBusinessDayId ? cashRegisterPosBusinessDayId : undefined,
        },
        { onError, initialValue: { orders: [], reportOrders: [], sales: undefined }, dependencies: [restaurantIds, salesType], requiredValues: [restaurantIds.length > 0 ? true : null] }
    );

    const [loadingSales, salesReportData, loadSalesReport] = useLoadApi(
        getOrderSalesApi,
        {
            restaurantIds,
            fromDate: moment(formatDateToRestaurantTimeZone(from as any)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(to as any)).toISOString(),
            cashRegisterPosBusinessDayId: shouldFilterByCashRegisterPosBusinessDayId ? cashRegisterPosBusinessDayId : undefined,
            salesType: salesType,
        },
        { dependencies: [restaurantIds, salesType], requiredValues: [restaurantIds.length > 0 ? true : null] }
    );

    useEffect(() => {
        setPageOfOrders(INITIAL_PAGE);
    }, [from, to]);

    useEffect(() => {
        if (ordersReport.orders) {
            SentryService.logInfoBreadcrumb('Successfully downloaded the orders view model', ordersReport.orders);
            setOrdersWithOffset(ordersReport.orders);
        }
        if (salesReportData) {
            setSales(salesReportData);
        }
    }, [ordersReport, salesReportData]);

    useEffect(() => {
        if (!menus.length && restaurantId) {
            fetchMenus(restaurantId);
        }
    }, [menus]);

    const handleChangeFromDate = (date: any) => {
        displayPeriodByDateAndTimeEnabled ? setFrom(moment(date).toDate()) : setFrom(moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
    };

    const handleChangeToDate = (date: any) => {
        displayPeriodByDateAndTimeEnabled ? setTo(moment(date).toDate()) : setTo(moment(date)?.endOf('day').format('YYYY-MM-DD HH:mm:ss'));
    };

    const handleClickRefresh = () => {
        setPageOfOrders(INITIAL_PAGE);
        setOrders([]);
        load();
        loadSalesReport();
    };

    const onEndReached = async () => {
        if (!orders) return;
        if (loading) return;
        if (ordersReport.orders.length < limit) return;
        setPageOfOrders(pageOfOrders + 1);
        if (pageOfOrders !== INITIAL_PAGE) {
            await load();
        }
    };

    const setOrdersWithOffset = (ordersByPage: any) => {
        const currentOrders = INITIAL_PAGE === pageOfOrders ? ordersByPage : [...orders, ...ordersByPage];
        const visibleOrders = currentOrders.filter(isOrderVisibleForUser);
        return setOrders(visibleOrders);
    };

    const handleClickDownloadCsv = () => {
        downloadOrdersSalesCsv(ordersReport);
    };

    return (
        <>
            <IncomingOrdersAlert />
            <OnEndReached onEndReached={onEndReached} loading={loading && pageOfOrders !== INITIAL_PAGE}>
                {loading && pageOfOrders === INITIAL_PAGE && (
                    <div className={classes.loadingContainer}>
                        <Loader size={40} loading={true} />
                    </div>
                )}
                <Grid className={classes.container}>
                    <Grid item>
                        <Card className={classes.card} elevation={0}>
                            <CardContent>
                                <div style={{ display: 'flex', gap: 8, marginBottom: 12 }}>
                                    <Input
                                        type={displayPeriodByDateAndTimeEnabled ? 'datetime-local' : 'date'}
                                        name='from'
                                        label={translate('From')}
                                        onChange={(date: any) => handleChangeFromDate(date)}
                                        value={moment(from).toDate()}
                                        disabled={!userHasRolePermission(RolePermissions.ALL_ORDERS_REPORTS)}
                                        classes={{ container: classes.inputContainer }}
                                    />
                                    <Input
                                        type={displayPeriodByDateAndTimeEnabled ? 'datetime-local' : 'date'}
                                        name='to'
                                        label={translate('To')}
                                        onChange={(date: any) => handleChangeToDate(date)}
                                        value={moment(to).toDate()}
                                        disabled={!userHasRolePermission(RolePermissions.ALL_ORDERS_REPORTS)}
                                        classes={{ container: classes.inputContainer }}
                                    />
                                </div>
                                <div className={classes.buttonsContainer}>
                                    <Button primary disabled={loading} onClick={handleClickRefresh} classes={{ button: classes.button }}>
                                        <UpdateIcon />
                                        {translate('REFRESH')}
                                    </Button>
                                    <OrdersSalesInfo sales={sales} loadingSales={loadingSales} />
                                    <SecuredContent rolePermission={RolePermissions.ALL_ORDERS_REPORTS}>
                                        <Button outlined disabled={loading} onClick={handleClickDownloadCsv} classes={{ button: classes.button }}>
                                            <CloudDownloadIcon />
                                            {translate('Download CSV')}
                                        </Button>
                                    </SecuredContent>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    {!loading && !orders?.length && (
                        <div className={classes.emptyDescription}>
                            <Typography display='block' variant='h3' align='center'>
                                {translate('NO ORDERS')}
                            </Typography>
                        </div>
                    )}
                    {(!loading || pageOfOrders !== INITIAL_PAGE) &&
                        orders?.map((order: any) => (
                            <div key={order.orderId}>
                                <OrderCardDeprecated
                                    order={order as any /* TODO: Sort things out with OrderVm in apis and remove any */}
                                    onChange={load}
                                    onError={() => {
                                        load();
                                        alertKnownErrorOrSomethingWentWrong();
                                    }}
                                    disabled={loading}
                                />
                            </div>
                        ))}
                </Grid>
            </OnEndReached>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
        gap: 20,
    },
    buttonContainer: {
        paddingTop: '30px',
    },
    yearHeader: {
        position: 'relative',
        textAlign: 'center',
    },
    monthHeader: {
        position: 'relative',
        textAlign: 'center',
    },
    emptyDescription: {
        position: 'absolute',
        bottom: '30%',
        left: '40%',
        textAlign: 'center',
        width: '100%',
        maxWidth: 700,
        [theme.breakpoints.down('sm')]: {
            top: '150%',
            left: 0,
        },
    },
    loadingContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: 12,
    },
    card: {
        position: 'relative',
        height: 'fit-content',
        width: 'fit-content',
        border: `1px solid ${theme.palette.secondary.dark}`,
        '&..MuiCardContent-root': {
            [theme.breakpoints.down('md')]: {
                padding: 5,
            },
        },
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: 5,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    textBold: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
    },
    menuTabText: {
        fontFamily: theme.typography.medium,
        textTransform: 'none',
    },
    alertMessage: {
        fontFamily: theme.typography.medium,
        width: 'fit-content',
        border: '1px solid #ddd',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
        position: 'fixed',
        right: 70,
        bottom: 40,
        zIndex: 10,
    },
    alertTitle: {
        fontFamily: theme.typography.semiBold,
    },
    dateInput: {
        appearance: 'none',
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderRadius: 4,
        padding: 8,
        fontSize: '14px',
    },
    inputContainer: {
        maxWidth: 135,
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    button: {
        width: '100%',
    },
}));
