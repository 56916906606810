/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SubscriptionPaymentMethod } from 'src/constants/SubscriptionPaymentMethod';

export async function getRestaurantsOverallStatsApi(request: RestaurantsOverallStatsApiRequest): ApiSauceResponse<OverallStatsVm> {
    return letseatadminApiMethod('overallStats/getRestaurantsOverallStatsApi', request);
}

type RestaurantsOverallStatsApiRequest = {
    from: Date;
    to: Date;
};

type OverallStatsVm = {
    orderAmountStats: OrderStatsVm;
    orderCountStats: OrderStatsVm;
    subscriptionStats: SubscriptionStatsVm;
};

type OrderStatsVm = {
    paymentLinks: string;
    pideDirectoCard: string;
    pideDirectoCash: string;
    pideDirectoAppCard: string;
    pideDirectoAppCash: string;
    manualOrders: string;
    rappiOrders: string;
    uberEatsOrders: string;
    didiOrders: string;
    total: string;
};

export type SubscriptionStatsVm = {
    cards: string;
    speiAndOxxo: string;
    previousPayments: Array<PreviousPaymentVm>;
};

type PreviousPaymentVm = {
    restaurantName: string;
    subscriptionPaymentMethod: SubscriptionPaymentMethod;
    previousPaymentAmount?: string;
    previousPaymentPaidAt?: Date;
};
