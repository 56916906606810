/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { PieChartCanvas } from 'src/components/charts/PieChartCanvas';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { GraphEmptyState } from 'src/scenes/letseatmanager/posReports/GraphEmptyState';
import type { ManagerUserSalesReportVm } from 'src/types/ManagerUserSalesReportVm';
import { sum } from 'src/utils/reduce/sum';

export function ManagerUserCanceledOrdersByWaiterReport({ managerUserSalesReport }: Props): React.ReactElement {
    const classes = useStyles();

    const getManagerUserServedOrdersPieChartData = () => {
        return managerUserSalesReport.managerUserCancelledOrdersReport.map((managerUserCancelledOrders) => ({
            id: managerUserCancelledOrders.servedBy,
            label: managerUserCancelledOrders.servedBy,
            value: managerUserCancelledOrders.orderVolume,
        }));
    };

    const isEmptyReport = () => {
        const managerUserOrders = managerUserSalesReport?.managerUserCancelledOrdersReport?.map((managerUserCancelledOrders) => managerUserCancelledOrders.orderVolume);
        const totalTips = managerUserOrders?.reduce(sum, BigNumber(0));
        return totalTips?.isZero();
    };

    if (isEmptyReport()) {
        return (
            <article className={classes.container}>
                <GraphEmptyState title={translate('Orders canceled by waiter')} />;
            </article>
        );
    }

    return (
        <article className={classes.container}>
            <h2 className={classes.title}>{translate('Orders canceled by waiter')}</h2>
            <PieChartCanvas
                data={getManagerUserServedOrdersPieChartData()}
                sortByValue
                tooltip={(e: any) => {
                    const { id, value } = e.datum;

                    return (
                        <div className={classes.tooltip}>
                            <span className={classes.tooltipTitle}>{id}</span>
                            <span className={classes.tooltipText}>{translate('Orders: @orders', { orders: value })}</span>
                        </div>
                    );
                }}
            />
            <Table
                data={managerUserSalesReport?.managerUserCancelledOrders?.map((managerUserCancelledOrders) => {
                    return {
                        servedBy: managerUserCancelledOrders.servedBy,
                        orderItem: managerUserCancelledOrders.orderItem,
                        orderVolume: managerUserCancelledOrders.orderVolume,
                        total: managerUserCancelledOrders.total,
                        reason: managerUserCancelledOrders.reason,
                    };
                })}
                columns={[
                    {
                        name: 'servedBy',
                        label: translate('User'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'orderItem',
                        label: translate('Product'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'orderVolume',
                        label: translate('Amount'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'total',
                        label: translate('Total'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'reason',
                        label: translate('Reason'),
                        options: {
                            filter: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                }}
            />
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 50,
        height: 'fit-content',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
    },
    tooltip: {
        padding: 10,
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipTitle: {
        fontFamily: theme.typography.bold,
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    managerUserSalesReport: ManagerUserSalesReportVm;
};
