/**
 * @prettier
 */
import { createSlice } from 'redux-starter-kit';
import { InitialRoutePath, InitialRoutePaths } from 'src/constants/InitialRoutePaths';
import type { RolePermission } from 'src/constants/RolePermission';
import { UserType, UserTypes } from 'src/constants/UserType';
import { ManagerUserId } from 'src/types/Id';
import type { PinCodeUser } from 'src/types/PinCodeUser';
import type { UserVm } from 'src/types/UserVm';
import { isBrandUser } from 'src/utils/user/isBrandUser';
import { isBusinessUser } from 'src/utils/user/isBusinessUser';

export const authReducer = createSlice<AuthenticationState, Actions>({
    name: 'authenticationReducer',
    initialState: {
        signingIn: false,
        adminUser: false,
        supportUser: false,
        operationsUser: false,
        viewUser: false,
        restaurantUser: false,
        kitchensUser: false,
        kitchenDisplayScreenUser: false,
        waiterUser: false,
        cashierUser: false,
        internalUser: false,
        kioskUser: false,
        userType: undefined,
        username: undefined,
        roleName: undefined,
        managerUserId: undefined,
        name: undefined,
        signedIn: false,
        pinCodeUserSignedIn: undefined,
        retrying: false,
        userRolePermissions: undefined,
        initialPage: InitialRoutePaths.ONGOING_ORDERS,
        pinCodeUsers: [],
    },
    reducers: {
        setUserType: (state: AuthenticationState, { payload: userType }: { payload: UserType }) => {
            const adminUser = userType === UserTypes.ADMIN;
            const supportUser = userType === UserTypes.SUPPORT;
            const operationsUser = userType === UserTypes.OPERATIONS;
            const viewUser = userType === UserTypes.VIEW;

            state.adminUser = adminUser;
            state.supportUser = supportUser;
            state.operationsUser = operationsUser;
            state.internalUser = adminUser || supportUser || operationsUser;
            state.viewUser = viewUser;
            state.restaurantUser = userType === UserTypes.RESTAURANT || isBrandUser(userType) || isBusinessUser(userType);
            state.kitchensUser = userType === UserTypes.KITCHENS;
            state.kitchenDisplayScreenUser = userType === UserTypes.KITCHEN_DISPLAY_SCREEN;
            state.waiterUser = userType === UserTypes.WAITER;
            state.cashierUser = userType === UserTypes.CASHIER;
            state.kioskUser = userType === UserTypes.KIOSK;
            state.userType = userType;
        },

        setName: (state: AuthenticationState, { payload: name }: { payload: string }) => {
            state.name = name;
        },

        setRoleName: (state: AuthenticationState, { payload: roleName }: { payload: string }) => {
            state.roleName = roleName;
        },

        setUserPermissions: (state: AuthenticationState, { payload: user }: { payload: UserVm }) => {
            state.initialPage = user.initialPage;
            state.userRolePermissions = user.userRolePermissions;
        },

        setPinCodeUsers: (state: AuthenticationState, { payload: pinCodeUsers }: { payload: Array<PinCodeUser> }) => {
            state.pinCodeUsers = pinCodeUsers ?? [];
        },

        startLogin: (state: AuthenticationState, { payload: { username, retrying } }: { payload: { username: string; retrying?: boolean } }) => {
            state.signingIn = true;
            state.retrying = !!retrying;
            state.username = username;
        },

        loginFailed: (state: AuthenticationState) => {
            state.signingIn = false;
            state.signedIn = false;
            state.name = undefined;
        },

        endLogin: (state: AuthenticationState) => {
            state.signingIn = false;
            state.signedIn = true;
        },

        pinCodeUserSignIn: (state: AuthenticationState, { payload: pinCodeUser }: { payload: PinCodeUser }) => {
            state.pinCodeUserSignedIn = pinCodeUser;
        },

        pinCodeUserLogout: (state: AuthenticationState) => {
            state.pinCodeUserSignedIn = undefined;
        },

        setManagerUserId: (state: AuthenticationState, { payload: managerUserId }: { payload: ManagerUserId }) => {
            state.managerUserId = managerUserId;
        },
    },
});

export type AuthenticationState = {
    pinCodeUsers?: Array<PinCodeUser>;
    userRolePermissions?: Array<RolePermission>;
    initialPage?: InitialRoutePath;
    userType?: UserType;
    signingIn: boolean;
    adminUser: boolean;
    supportUser: boolean;
    operationsUser: boolean;
    viewUser: boolean;
    restaurantUser: boolean;
    kitchensUser: boolean;
    kitchenDisplayScreenUser: boolean;
    waiterUser: boolean;
    cashierUser: boolean;
    kioskUser: boolean;
    internalUser: boolean;
    username?: string;
    managerUserId: ManagerUserId | undefined;
    roleName?: string;
    name?: string;
    signedIn: boolean;
    pinCodeUserSignedIn?: PinCodeUser;
    retrying: boolean;
};

type Actions = {
    setUserType: any;
    setUserPermissions: any;
    setPinCodeUsers: any;
    startLogin: any;
    endLogin: any;
    loginFailed: any;
    pinCodeUserSignIn: any;
    pinCodeUserLogout: any;
    setName: any;
    setRoleName: any;
    setManagerUserId: any;
};
