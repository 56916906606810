/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CoursesCarrousel } from 'src/components/pos/posCart/CoursesCarrousel';
import { PosOrderSteps } from 'src/constants/PosOrderStep';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { CircleCloseIcon } from 'src/icons/CircleCloseIcon';
import { posReducer } from 'src/reducers/posReducer';
import { PosAddNoteButton } from 'src/scenes/letseatmanager/pos/posMenu/PosAddNoteButton';
import { PosCartPickupTime } from 'src/scenes/letseatmanager/pos/posMenu/posCart/PosCartPickupTime';
import { PosCartActions } from 'src/scenes/letseatmanager/pos/posMenu/PosCartActions';
import { PosCartItems } from 'src/scenes/letseatmanager/pos/posMenu/PosCartItems';
import { PosCartSummary } from 'src/scenes/letseatmanager/pos/posMenu/PosCartSummary';
import { PosContext } from 'src/scenes/letseatmanager/pos/PosProvider';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export function PosCart(): React.ReactElement | null {
    const cartRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();
    const isSmallScreen = useIsSmallScreen();
    const posContext = useContext(PosContext);
    const history = useHistory();

    const [cartHeight, setCartHeight] = useState(420);

    const orderType = useSelector((state) => state.pos.orderType);
    const itemSelected = useSelector((state) => state.pos.itemSelected);
    const posMenu = useSelector((state) => state.pos.posMenu);
    const orderStep = useSelector((state) => state.pos.orderStep);
    const posSimpleView = useSelector((state) => state.pos.context?.posSimpleView);
    const posShowTablesEnabled = useSelector((state) => state.pos.context?.posShowTablesEnabled);
    const posTablesTabAsDefaultEnabled = useSelector((state) => state.app.restaurant.posTablesTabAsDefaultEnabled);
    const hasPendingOrder = useSelector((state) => !!state.pos.pendingOrder);

    const classes = useStyles({ posSimpleView });

    const closePosMenu = useAction(posReducer.actions.closePosMenu);

    const isPos = location.pathname === RoutePaths.POS || location.pathname === RoutePaths.POS_CASH_REGISTERS;
    const closePosCart = itemSelected || !orderType || !posMenu || orderStep !== PosOrderSteps.CREATE_ORDER || !isPos || !posMenu?.menus?.length;
    const hideGoBackButton = posSimpleView && !posShowTablesEnabled;

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    const handleClose = () => {
        if (isSmallScreen) return posContext.closeMobileCart();
        closePosMenu();
        if (posTablesTabAsDefaultEnabled) {
            history.push({
                pathname: RoutePaths.POS_TABLES,
                search: history.location.search,
            });
        }
    };

    const handleWindowResize = () => {
        handleCartHeight(cartRef.current);
    };
    const handleCartHeight = (cartContainer: HTMLDivElement | null) => {
        if (!cartContainer) return;

        const positionY = cartContainer.getBoundingClientRect().top;
        const cartHeight = isSmallScreen ? window.innerHeight - positionY + 20 : window.innerHeight - positionY - 20;
        setCartHeight(cartHeight);
    };

    const handleRef = (cartContainer: HTMLDivElement | null) => {
        if (!cartContainer) return;
        cartRef.current = cartContainer;
        handleCartHeight(cartContainer);
    };

    if (closePosCart) return null;

    return (
        <div className={classNames(classes.cartContainer, { [classes.hideCart]: !posContext.shouldRenderMobileCart && isSmallScreen })}>
            <div className={classes.container} style={{ minHeight: `${cartHeight}px`, maxHeight: `${cartHeight}px` }} ref={handleRef}>
                <div className={classes.topSection}>
                    <div className={classes.titleContainer}>
                        <div>
                            <h1 className={classes.title}>{hasPendingOrder ? translate('Edit Order') : translate('New Order')}</h1>
                            <div id='orderType' className={classes.orderTypeContainer}>
                                <span className={classes.orderType}>{translate(orderType)}</span>
                                <PosCartPickupTime />
                            </div>
                        </div>
                        {!hideGoBackButton && (
                            <Button variant={'icon'} classes={{ button: classes.backButton }} onClick={handleClose}>
                                <CircleCloseIcon title={translate('Close pos cart')} color={'#87888B'} size={32} />
                            </Button>
                        )}
                    </div>
                    <CoursesCarrousel />
                </div>
                <PosCartItems />
                <div className={classes.bottomSection}>
                    <PosAddNoteButton />
                    <PosCartSummary />
                    <PosCartActions />
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    cartContainer: (props: any) => ({
        [theme.breakpoints.up('md')]: {
            position: 'fixed',
            right: '5%',
            width: '27%',
            top: !!props?.posSimpleView ? 220 : 'unset',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'unset',
            position: 'absolute',
            left: 0,
            width: '100%',
            backgroundColor: '#fafbfb',
        },
    }),
    hideCart: {
        display: 'none !important',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexShrink: 0,
        borderBottom: 0,
        borderRadius: 16,
        padding: '30px 24px',
        overflowY: 'hidden',
        height: '100svh',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        [theme.breakpoints.up('md')]: {
            minWidth: 250,
            boxShadow: '0px 7px 8px rgba(0, 0, 0, 0.25)',
            backgroundColor: 'white',
            padding: '10px',
            position: 'relative',
            paddingBottom: 0,
        },
        [theme.breakpoints.up('lg')]: {
            padding: '18px',
        },
    },
    topSectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 'fit-content',
        width: '100%',
        justifyContent: 'space-between',
    },
    orderTypeContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
    },
    topSection: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
    },
    bottomSection: {
        width: '100%',
        display: 'flex',
        gap: 10,
        flexDirection: 'column',
        flexShrink: 0,
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    orderType: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: theme.palette.text.disabled,
    },
    backButton: {
        padding: 0,
        width: 'fit-content',
        height: 'fit-content',
        minHeight: 'fit-content',
        backgroundColor: 'transparent !important',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'transparent',
        },
    },
}));
