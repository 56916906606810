/**
 * @prettier
 */
export const SubscriptionPlans = {
    PRO_REGULAR: 'PRO_REGULAR',
    BASIC_REGULAR: 'BASIC_REGULAR',
    COMMISSION: 'COMMISSION',
    FREE: 'FREE',
    FREE_TRIAL: 'FREE_TRIAL',
    PAY_PER_USE: 'PAY_PER_USE',
} as const;

export type SubscriptionPlan = typeof SubscriptionPlans[keyof typeof SubscriptionPlans];
