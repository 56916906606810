/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeRestaurantReferralMessagesApi } from 'src/api/letseatadmin/restaurantReferral/changeRestaurantReferralMessagesApi';
import type { RestaurantReferralsVm } from 'src/api/letseatadmin/restaurantReferral/findRestaurantReferralsApi';
import { getRestaurantReferralsApi } from 'src/api/letseatadmin/restaurantReferral/getRestaurantReferralsApi';
import { removeRestaurantReferralApi } from 'src/api/letseatadmin/restaurantReferral/removeRestaurantReferralApi';
import { app2 } from 'src/app2';
import { Form } from 'src/components/form/Form';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormTextField } from 'src/components/form/FormTextField';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { Spacing } from 'src/components/spacing/Spacing';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ChangeRestaurantReferralsDialog } from 'src/scenes/letseatadmin/restaurantReferrals/ChangeRestaurantReferralsDialog';
import type { RestaurantReferralId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { openDriverPageInNewTab } from 'src/utils/window/openDriverPageInNewTab';

export function RestaurantReferralsPage(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const { watch } = form;
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const [loading, setLoading] = useState(false);
    const [changeRestaurantReferralDialogState, setChangeRestaurantReferralDialogState] = useState({ open: false, restaurantReferralId: undefined });
    const [vm, setVm] = useState({ restaurantReferrals: [] } as RestaurantReferralsVm);
    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Restaurant Referrals'));
        load();
    }, []);

    const closeChangeRestaurantReferralDialog = () => {
        setChangeRestaurantReferralDialogState({ open: false, restaurantReferralId: undefined });
    };

    const load = async () => {
        setLoading(true);
        const response = await getRestaurantReferralsApi();
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const restaurantReferralsMessage = {
            restaurantReferralMessageTitle: response.data.restaurantReferralMessageTitle,
            restaurantReferralMessageBody: response.data.restaurantReferralMessageBody,
        } as const;
        setVm(response.data);
        form.reset(restaurantReferralsMessage);
    };

    const removeRestaurantReferrals = async (restaurantReferralIds: Array<RestaurantReferralId>) => {
        setLoading(true);
        for (const restaurantReferralId of restaurantReferralIds) {
            const response = await removeRestaurantReferralApi({ restaurantReferralId });
            if (!response.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(response);
                await load();
                return;
            }
        }
        await load();
    };

    const onSubmit = async (form: any) => {
        const response = await changeRestaurantReferralMessagesApi({
            restaurantReferralMessageTitle: form.restaurantReferralMessageTitle,
            restaurantReferralMessageBody: form.restaurantReferralMessageBody,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
        }
        load();
    };

    return (
        <div className={classes.container}>
            <ChangeRestaurantReferralsDialog
                open={changeRestaurantReferralDialogState.open}
                restaurantReferralId={changeRestaurantReferralDialogState.restaurantReferralId}
                handleClose={closeChangeRestaurantReferralDialog}
                onSuccess={load}
            />
            <Form form={form} onSubmit={onSubmit}>
                <Grid container spacing={3}>
                    <>
                        <Grid item xs={12}>
                            <UpdatingContentProgress loading={loading} />
                            <FormTextField
                                name='restaurantReferralMessageTitle'
                                label={translate('Referral Message Title')}
                                helperText={translate('This title will appear on the drivers app on the restaurant referral page.')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                name='restaurantReferralMessageBody'
                                label={translate('Referral Message Body')}
                                helperText={translate('This body will appear in the drivers app on the restaurant referral page.')}
                            />
                        </Grid>
                    </>
                    <Grid container justify='flex-end' spacing={3}>
                        <Grid item>
                            <FormSubmitButton text={translate('Change')} disabled={loading} />
                        </Grid>
                    </Grid>
                </Grid>
                <Spacing units={8} />
                <UpdatingContentProgress loading={loading} />
                <MUIDataTable
                    /* @ts-ignore */
                    className={classes.table}
                    data={
                        vm.restaurantReferrals &&
                        vm.restaurantReferrals.map((restaurantReferral) => {
                            return {
                                restaurantReferralId: restaurantReferral.restaurantReferralId,
                                driverId: restaurantReferral.driverId,
                                driverName: restaurantReferral.driverName,
                                responsible: restaurantReferral.responsible,
                                businessName: restaurantReferral.businessName,
                                contactNumber: restaurantReferral.contactNumber,
                                contactName: restaurantReferral.contactName,
                                referralMessage: restaurantReferral.referralMessage,
                            };
                        })
                    }
                    columns={[
                        {
                            name: 'restaurantReferralId',
                            label: '',
                            options: {
                                display: 'excluded',
                                filter: false,
                            },
                        },
                        {
                            name: 'driverId',
                            label: '',
                            options: {
                                display: 'excluded',
                                filter: false,
                            },
                        },
                        {
                            name: 'responsible',
                            label: translate('Responsible'),
                            options: {
                                filter: true,
                            },
                        },
                        {
                            name: 'driverName',
                            label: translate('Driver Name'),
                            options: {
                                filter: false,
                                customBodyRender: (driverName, { rowData }) => {
                                    const driverId = rowData[1];
                                    return (
                                        <Button size='small' color='primary' onClick={() => openDriverPageInNewTab(driverId)}>
                                            {driverName}
                                        </Button>
                                    );
                                },
                            },
                        },
                        {
                            name: 'businessName',
                            label: translate('Referral Business Name'),
                            options: {
                                filter: true,
                            },
                        },
                        {
                            name: 'contactNumber',
                            label: translate('Contact Number'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'contactName',
                            label: translate('Contact Name'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'referralMessage',
                            label: translate('Referral Message'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'removeRestaurantReferral',
                            label: ' ',
                            options: {
                                filter: false,
                                customBodyRender: (driverName, { rowData }) => {
                                    const restaurantReferralId = rowData[0];
                                    return (
                                        <IconButton onClick={() => removeRestaurantReferrals([restaurantReferralId])}>
                                            <CloseIcon />
                                        </IconButton>
                                    );
                                },
                            },
                        },
                    ]}
                    options={{
                        responsive: 'standard',
                        tableBodyMaxHeight: '500px',
                        selectableRows: !viewUser ? 'multiple' : 'none',
                        filterType: 'checkbox',
                        rowsPerPage: 100,
                        customToolbar: () => (
                            <>
                                <RefreshToolbarButton onClick={load} />
                            </>
                        ),
                        customToolbarSelect: (selectedRows, displayData) => {
                            const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                            const selectedRestaurantReferralIds = selectedRowsData.map((d: any) => d[0]);

                            return (
                                <div className={classes.toolbar}>
                                    {selectedRowsData.length == 1 && (
                                        <ChangeToolbarButton
                                            onClick={() => {
                                                setChangeRestaurantReferralDialogState({ open: true, restaurantReferralId: selectedRestaurantReferralIds[0] });
                                            }}
                                        />
                                    )}
                                    <RemoveToolbarButton
                                        onClick={async () => {
                                            const remove = window.confirm(
                                                selectedRestaurantReferralIds.length === 1
                                                    ? 'Are you sure you want to remove the selected restaurantReferral'
                                                    : `Are you sure you want to remove the selected ${selectedRestaurantReferralIds.length} restaurantReferrals`
                                            );
                                            if (remove) {
                                                await removeRestaurantReferrals(selectedRestaurantReferralIds);
                                            }
                                        }}
                                    />
                                </div>
                            );
                        },
                    }}
                />
            </Form>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));
