/**
 * @prettier
 */
export const CommandSections = {
    ORDER_ID: 'ORDER_ID',
    CONSECUTIVE_ID: 'CONSECUTIVE_ID',
    ORDER_TYPE: 'ORDER_TYPE',
    CREATED_AT: 'CREATED_AT',
    CUSTOMER_NAME: 'CUSTOMER_NAME',
    TABLE_NUMBER: 'TABLE_NUMBER',
    SALES_INFO: 'SALES_INFO',
    SPLIT_GROUPED_ORDER_ITEMS: 'SPLIT_GROUPED_ORDER_ITEMS',
    ITEMS_COST: 'ITEMS_COST',
    TOTAL_COST: 'TOTAL_COST',
    PAYMENT_METHOD: 'PAYMENT_METHOD',
} as const;

export type CommandSection = typeof CommandSections[keyof typeof CommandSections];
