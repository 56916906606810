/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import { TipTypes } from 'src/constants/TipType';
import type { PosTipVm } from 'src/types/PosTipVm';
import { exchangeCurrencyAmount } from 'src/utils/order/exchangeCurrencyAmount';

export function calculateRestaurantCurrencyTipAmount(totalPaid: number, tip: PosTipVm): string | undefined {
    if (!tip.restaurantCurrency) {
        return;
    }

    let tipAmount: string | BigNumber = BigNumber('0'); // TODO: stop mixing types

    if (tip.tipType === TipTypes.AMOUNT) tipAmount = tipAmount.plus(BigNumber(tip.tipAmount ?? 0));

    if (tip.tipType === TipTypes.PERCENT) {
        const amount = BigNumber(tip.tipPercentage ?? 0)
            .dividedBy(100)
            .multipliedBy(totalPaid)
            .toString();

        tipAmount = !tip.restaurantCurrency ? amount : exchangeCurrencyAmount(amount.toString(), tip.restaurantCurrency);
    }

    return BigNumber(tipAmount).decimalPlaces(2).toString();
}
