/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { savePosOrderOffline } from 'src/localStorage/savePosOrderOffline';
import { posReducer } from 'src/reducers/posReducer';
import { useMapOrderItemsToCartItems } from 'src/services/pos/useMapOrderItemsToCartItems';
import { usePrintOrderCommand } from 'src/services/printer/usePrintOrderCommand';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { findItemsChangedInOrder } from 'src/utils/pos/findItemsChangedInOrder';
import { useHasPrintersToPrint } from 'src/utils/printer/useHasPrintersToPrint';
import { useAction } from 'src/utils/react/useAction';
import { useSaveNewPosOrder } from 'src/utils/react/useSaveNewPosOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { useUpdatePosPendingOrder } from 'src/utils/react/useUpdatePosPendingOrder';

export function PosPrintCommandButton(): React.ReactElement | null {
    const classes = useStyles();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();
    const mapOrderItemsToCartItems = useMapOrderItemsToCartItems();

    const [printOrderCommand] = usePrintOrderCommand();
    const { hasCommandPrintersToPrint } = useHasPrintersToPrint();
    const { updatePosPendingOrder } = useUpdatePosPendingOrder();
    const { saveNewPosOrder } = useSaveNewPosOrder();

    const [loading, setLoading] = useState(false);
    const [printCommandEnabled, setPrintCommandEnabled] = useState(false);

    const orderType = useSelector((state) => state.pos.orderType);
    const items = useSelector((state) => state.pos.items);
    const total = useSelector((state) => state.pos.payment?.total);
    const cancelledItems = useSelector((state) => state.pos.cancelledItems);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const disabledPosPaymentActions = useSelector((state) => state.pos.disabledPosPaymentActions);
    const posPendingOrdersEnabled = useSelector((state) => state.app.restaurant.posPendingOrdersEnabled);
    const acceptManuallyAllOrdersEnabled = useSelector((state) => state.app.restaurant?.acceptManuallyAllOrdersEnabled);
    const table = useSelector((state) => state.pos.table);
    const numberOfCustomers = useSelector((state) => state.pos.numberOfCustomers);

    const setPendingOrder = useAction(posReducer.actions.setPendingOrder);
    const setItems = useAction(posReducer.actions.setItems);
    const updateOrder = useAction(app2.actions.updateOrder);

    const itemsToPrint = findItemsChangedInOrder(pendingOrder?.orderItems ?? ([] as any), items ?? []);
    const disabledButtons = loading || (!total && !itemsToPrint?.length);
    const disabledPrintCommand = disabledButtons || !hasCommandPrintersToPrint || !printCommandEnabled;
    const newOrderItemsAdded = !pendingOrder && items?.length;
    const pendingOrderItemsChanged = pendingOrder && itemsToPrint.length;
    const showPrintCommandButton = isEatHereOrder(orderType) && posPendingOrdersEnabled && !acceptManuallyAllOrdersEnabled;

    useEffect(() => {
        updatePrintCommandEnabled();
    }, [pendingOrder, items]);

    const updatePrintCommandEnabled = () => {
        const hasNewItems = newOrderItemsAdded || pendingOrderItemsChanged;
        const hasNewCancelledItems = cancelledItems?.length;

        if (hasNewItems || hasNewCancelledItems) return setPrintCommandEnabled(true);

        setPrintCommandEnabled(false);
    };

    const updatePendingOrder = async () => {
        if (loading || disabledPosPaymentActions) return;
        setLoading(true);
        const { response, request } = await updatePosPendingOrder({
            firstName: pendingOrder?.firstName,
            lastName: pendingOrder?.lastName,
            mobileNumber: pendingOrder.customerMobileNumber,
            email: pendingOrder.email,
            customerNote: pendingOrder.customerNote,
        });
        setLoading(false);

        const itemsToPrint = findItemsChangedInOrder(pendingOrder?.orderItems ?? ([] as any), request.orderItems ?? []);
        const removedItemsToPrint = findItemsChangedInOrder(pendingOrder?.cancelledItems ?? ([] as any), request.cancelledItems ?? []);

        if (!response.ok) {
            if (response.problem === 'NETWORK_ERROR') {
                savePosOrderOffline(request);
                await printOrderCommand({ ...request, orderItems: itemsToPrint, cancelledItems: removedItemsToPrint } as any);
                return;
            }
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        setItems(mapOrderItemsToCartItems(response.data.orderItems));
        updateOrder({ order: response.data });

        await printOrderCommand(response.data);
        setPendingOrder(response.data);
    };

    const saveNewPendingOrder = async () => {
        if (loading || disabledPosPaymentActions) return;

        setLoading(true);
        const { response, request } = await saveNewPosOrder({
            numberOfCustomers: table?.numberOfCustomers ?? numberOfCustomers,
        });
        setLoading(false);

        if (!response.ok) {
            if (response.problem === 'NETWORK_ERROR') {
                await printOrderCommand({ ...request, orderItems: request.orderItems, cancelledItems } as any);
                savePosOrderOffline(request as any);
                return;
            }
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        setItems(mapOrderItemsToCartItems(response.data.orderItems));
        if (response.data.orderStatus !== OrderStatuses.COMPLETE) updateOrder({ order: response.data });

        await printOrderCommand(response.data as any);
        setPendingOrder(response.data);
    };

    const handlePrintCommandOrder = async () => {
        if (pendingOrder) {
            await updatePendingOrder();
            return;
        }
        await saveNewPendingOrder();
    };

    if (!showPrintCommandButton) return null;

    return (
        <Button larger={posInterfaceLargerEnabled} classes={{ button: classes.basicButton }} secondary onClick={handlePrintCommandOrder} disabled={disabledPrintCommand}>
            {translate('Print Command')}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    basicButton: {
        width: '100%',
        height: 50,
        color: theme.palette.text.secondary,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
