/**
 * @prettier
 */
import { usePosCustomerStore } from 'src/modules/pos/customer/posCustomerStore';

export function useHasCustomerInfoAnyError(): boolean {
    const hasAnyError = usePosCustomerStore(
        (state) => !!state.firstName.error || !!state.lastName.error || !!state.email.error || !!state.mobileNumber.error || !!state.numberOfCustomers.error || !!state.customerNote.error
    );

    return hasAnyError;
}
