/**
 * @prettier
 */
import { createActions, createReducer } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators }: any = createActions(
    {
        fetch: ['request'],
        fetchSuccess: ['vm'],
        fetchFailure: ['errorMessage'],
    },
    { prefix: 'MonitoringReducer/' }
);

export const MonitoringTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: any = {
    loading: false,
    vm: {
        orderAlarms: [],
        driverAlarms: [],
        driverLateForPickupAlarms: [],
    },
    errorMessage: null,
};

/* ------------- Reducers ------------- */
export const fetch = (state: any): any => {
    return {
        ...state,
        loading: true,
    };
};

export const fetchSuccess = (state: any, { vm }: any): any => {
    return {
        ...state,
        vm,
        errorMessage: null,
        loading: false,
    };
};

export const fetchFailure = (state: any, { errorMessage }: any): any => {
    return {
        ...state,
        errorMessage,
        loading: false,
    };
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer: any = createReducer(INITIAL_STATE, {
    [Types.FETCH]: fetch,
    [Types.FETCH_SUCCESS]: fetchSuccess,
    [Types.FETCH_FAILURE]: fetchFailure,
});
