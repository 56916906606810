/**
 * @prettier
 */
import { Button, makeStyles } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { findSuppliesByRestaurantIdApi } from 'src/api/letseatmanager/supply/findSuppliesByRestaurantIdApi';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { RoutePaths } from 'src/constants/RoutePath';
import { UnitsOfMeasurementTranslated } from 'src/constants/UnitOfMeasurementTranslated';
import { translate } from 'src/i18n/translate';
import { inventory } from 'src/reducers/inventoryReducer';
import { BuyOrderContainer } from 'src/scenes/letseatmanager/uploadBuyOrder/BuyOrderContainer';
import { convertCsvToBuyOrder } from 'src/utils/csv/convertCsvToBuyOrder';
import { objectsToCsv } from 'src/utils/csv/objectsToCsv';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function UploadBuyOrder(): React.ReactElement {
    const classes = useStyles();
    const history = useHistory();
    const inputRef = useRef<any>();

    const [files, setFiles] = useState<Array<{ name: string }>>([]);
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const setBuyOrder = useAction(inventory.actions.setBuyOrders);
    const setSupplies = useAction(inventory.actions.setSupplies);

    const [loadingSupplies, { supplies }] = useLoadApi(findSuppliesByRestaurantIdApi, { restaurantId }, { initialValue: { supplies: [] } });

    useEffect(() => {
        if (!!supplies.length) setSupplies(supplies);
    }, [supplies]);

    const handleUploadCsv = () => inputRef.current.click();

    const goToRegisterManualBuyOrderPage = () => {
        history.push({
            pathname: RoutePaths.INVENTORY_CREATE_BUY_ORDER,
            search: history.location.search,
        });
    };

    const handleInputFilesChange = async (e: any) => {
        const files = [...e.target.files];
        if (!files.length) return;

        setLoading(true);
        const buyOrder = await convertCsvToBuyOrder(files);
        setLoading(false);
        if (!buyOrder) return;
        setFiles(files);
        setBuyOrder(buyOrder.flat());
    };

    const handleDownloadTemplate = () => {
        const template = [
            {
                SKU: '1231',
                CANTIDAD: '10',
                UNIDAD: 'PIEZA',
                PRECIO: '15.8',
            },
            {
                SKU: '3456',
                CANTIDAD: '20',
                UNIDAD: 'CAJA',
                PRECIO: '20',
            },
        ];
        const csv = objectsToCsv(template);
        downloadTextInFile(csv, 'template-orden-compra.csv');
    };

    return (
        <SubscribedPage title={translate('Inventory')}>
            <BuyOrderContainer loading={loading} disabled={!files.length || loading || loadingSupplies} handlePressContinue={goToRegisterManualBuyOrderPage}>
                <div className={classes.container}>
                    <GoBackButton />
                    <h1 className={classes.title}>{translate('Create a purchase order from a csv file')}</h1>
                    <p className={classes.text}>{translate('To upload your purchase order, we need it to be a CSV file (using Excel or similar), download the template')} </p>
                    <span className={classes.link} onClick={handleDownloadTemplate}>
                        {translate('HERE')}
                    </span>
                    <input type='file' hidden ref={inputRef} onChange={handleInputFilesChange} accept='.csv' />

                    <p className={classes.textSmall}>
                        {translate('*Remember the price has to be unitary and the supply unit should be one of the follow units:', { units: Object.keys(UnitsOfMeasurementTranslated).join(', ') })}
                    </p>
                    <div className={classes.unitsContainer}>
                        {Object.keys(UnitsOfMeasurementTranslated).map((unit) => (
                            <div className={classes.unitContainer} key={unit}>
                                {unit}
                            </div>
                        ))}
                    </div>
                    <Button classes={{ root: classes.secondaryButton }} onClick={handleUploadCsv}>
                        {translate('Upload CSV')}
                    </Button>
                    {!!files.length && (
                        <ul className={classes.list}>
                            {files.map((file, idx) => (
                                <li key={file.name + idx} className={classes.text}>
                                    {file.name}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </BuyOrderContainer>
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '60%',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 25,
        width: '100%',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    textSmall: {
        fontFamily: theme.typography.regular,
        fontStyle: 'italic',
        fontSize: 13,
        marginTop: 30,
        color: '#616B79',
    },
    link: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        textDecoration: 'underline',
        cursor: 'pointer',
        color: theme.palette.primary.main,
    },
    secondaryButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        marginTop: 30,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    primaryButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        borderRadius: 5,
        height: 40,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        marginTop: 30,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    list: {
        marginTop: 10,
        listStyleType: 'none',
        padding: 0,
        width: '100%',
    },
    unitsContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        marginTop: 10,
    },
    unitContainer: {
        border: `1px solid ${theme.palette.secondary.dark}`,
        padding: 2,
        paddingLeft: 5,
        borderRadius: 5,
    },
}));
