/**
 * @prettier
 */
import { usePosPaymentMethodsStore } from 'src/services/pos/posPaymentMethods/posPaymentMethodsStore';
import type { CustomPaymentMethod } from 'src/types/Id';

export function useCustomPaymentMethodSelected(): CustomPaymentMethod | undefined {
    const customPaymentMethod = usePosPaymentMethodsStore((state) => state.customPaymentMethod);

    return customPaymentMethod;
}
