/**
 * @prettier
 */
import { FormSelect } from '@pidedirecto/ui/form';
import * as React from 'react';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';

export function FormPaymentMethodSelect({ name, label, placeholder, helperText, defaultValue, disabled, required }: Props): React.ReactElement {
    const paymentMethods = [PaymentMethods.CREDIT_CARD, PaymentMethods.CASH];

    const options = required
        ? Object.values(paymentMethods).map((paymentMethod) => ({ label: translate(paymentMethod), value: paymentMethod }))
        : [{ label: '', value: null }, ...Object.values(paymentMethods).map((paymentMethod) => ({ label: translate(paymentMethod), value: paymentMethod }))];
    return <FormSelect name={name} label={label} placeholder={placeholder} helperText={helperText} defaultValue={defaultValue} options={options} disabled={disabled} required={!!required} />;
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
};
