/**
 * @prettier
 */

/**
 * env variable tells if code is running locally in a test, set by test/setupTests.ts
 */
export function envIS_TEST(): boolean {
    return process.env.IS_TEST === 'true';
}
