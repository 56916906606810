/**
 * @prettier
 */
import { useState } from 'react';
import * as React from 'react';
import { removeCashRegisterWithdrawReasonApi } from 'src/api/letseatmanager/restaurant/removeCashRegisterWithdrawReasonApi';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import { translate } from 'src/i18n/translate';
import type { CashRegisterCustomReason } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function RemoveCashRegisterWithdrawCustomReasonDialog({ open, reason, onClose, onSuccess }: Props): React.ReactElement {
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const handleSubmit = async () => {
        if (!reason) return;
        setLoading(true);
        const response = await removeCashRegisterWithdrawReasonApi({ restaurantId, reason });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess();
        onClose();
    };

    return (
        <RemoveDialog
            open={open}
            title={translate('Remove Withdraw Reason')}
            subtitle={translate('Are you sure you want to remove the custom reason "@cashRegisterCustomReason?"', { cashRegisterCustomReason: reason })}
            onSubmit={handleSubmit}
            onClose={onClose}
            disabled={loading}
        />
    );
}

type Props = {
    open: boolean;
    reason?: CashRegisterCustomReason;
    onClose: any;
    onSuccess: any;
};
