/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { useEffect } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { Form } from 'src/components/form/Form';
import { FormBankAutocomplete } from 'src/components/form/FormBankAutocomplete';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormPhoneNumberField } from 'src/components/form/FormPhoneNumberField';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import ChangeRestaurantDetailsDialogActions from 'src/scenes/letseatadmin/restaurantsDetails/change-restaurant-details-dialog/ChangeRestaurantDetailsDialogReducer';
import { appLayout } from 'src/theme/AppLayout';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangeRestaurantDetailsDialog(): React.ReactElement {
    const open = useSelector((state) => state.changeRestaurantDetailsDialog.open);
    const showPrivateInfo = useSelector((state) => state.changeRestaurantDetailsDialog.showPrivateInfo);
    const restaurantDetails = useSelector((state) => state.changeRestaurantDetailsDialog.restaurantDetails);

    const onClose = useAction(ChangeRestaurantDetailsDialogActions.closeDialog);
    const changeRestaurantDetailsSuccess = useAction(ChangeRestaurantDetailsDialogActions.changeRestaurantDetailsSuccess);
    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const cities = useSelector((state) => state.app2.cities);

    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    useEffect(() => {
        if (restaurantDetails) form.reset(restaurantDetails);
    }, [restaurantDetails]);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const handleSubmit = async (form: any) => {
        const response = await letseatmanagerApiDeprecated.admin.saveRestaurantsDetails({
            zone: form.zone,
            contact: form.contact,
            contactNumber: form.contactNumber,
            establishmentsPhoneNumber: form.establishmentsPhoneNumber,
            fiscalAddress: form.fiscalAddress,
            addressOfEstablishment: form.addressOfEstablishment,
            email: form.email,
            bank: form.bank,
            account: form.account,
            clabe: form.clabe,
            rfc: form.rfc,
            businessName: form.businessName,
            contactName: form.rfc,
            emailInvoices: form.emailInvoices,
            paymentInEstablishment: form.paymentInEstablishment,
            commissionWebCard: form.commissionWebCard,
            commissionWebCash: form.commissionWebCash,
            commissionWebCardOnDelivery: form.commissionWebCardOnDelivery,
            commissionAppCard: form.commissionAppCard,
            commissionAppCash: form.commissionAppCash,
            commissionAppCardOnDelivery: form.commissionAppCardOnDelivery,
            commissionManualOrders: form.commissionManualOrders,
            device: form.device,
            responsible: form.responsible,
            bill: form.bill,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        changeRestaurantDetailsSuccess(response.data);
        showSnackbar({ message: `${translate('Saved changes')}` });
        fetchRestaurantDetails();
        onClose();
    };

    const fetchRestaurantDetails = async () => {
        const response = await letseatmanagerApiDeprecated.admin.restaurantsDetails({
            cities,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
    };

    return (
        <Dialog aria-labelledby='register-restaurant-title' fullWidth maxWidth='xl' scroll='paper' open={open} onClose={handleClose}>
            <Form form={form} onSubmit={handleSubmit} style={appLayout.dialog.form}>
                <DialogTitle id='register-restaurant-title'>{translate('Change Restaurant Details').toUpperCase()}</DialogTitle>
                <DialogContent style={{ overflowY: 'hidden' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='zone' label={translate('Zone')} disabled={isSubmitting} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} style={{ visibility: showPrivateInfo ? 'visible' : 'hidden' }}>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='contact' label={translate('Contact')} disabled={isSubmitting} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormPhoneNumberField name='contactNumber' label={translate('Contact Number')} disabled={isSubmitting} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormPhoneNumberField name='establishmentsPhoneNumber' label={translate('Establishments Phone Number')} disabled={isSubmitting} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='fiscalAddress' label={translate('Fiscal Address')} disabled={isSubmitting} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='addressOfEstablishment' label={translate('Address of Establishment')} disabled={isSubmitting} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormEmailField name='email' label={translate('Email')} disabled={isSubmitting} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormBankAutocomplete name='bank' label={translate('Bank')} disabled={isSubmitting} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='account' label={translate('Account')} disabled={isSubmitting} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='clabe' label={translate('Account Number')} disabled={isSubmitting} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='rfc' label={translate('RFC')} disabled={isSubmitting} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='businessName' label={translate('Business Name')} disabled={isSubmitting} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='contactName' label={translate('Contact Name')} disabled={isSubmitting} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormEmailField name='emailInvoices' label={translate('Email Invoices')} disabled={isSubmitting} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='paymentInEstablishment' label={translate('Payment in Establishment')} disabled={isSubmitting} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <DeprecatedFormPercentNumberField name='commissionWebCard' label={translate('Commission Web Card %')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DeprecatedFormPercentNumberField name='commissionWebCash' label={translate('Commission PD Web Cash %')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DeprecatedFormPercentNumberField name='commissionWebCardOnDelivery' label={translate('Commission PD Web Card on delivery %')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DeprecatedFormPercentNumberField name='commissionAppCard' label={translate('Commission PD App Card %')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DeprecatedFormPercentNumberField name='commissionAppCash' label={translate('Commission PD App Cash %')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DeprecatedFormPercentNumberField name='commissionAppCardOnDelivery' label={translate('Commission PD App Card on delivery %')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DeprecatedFormPercentNumberField name='commissionManualOrders' label={translate('Manual Orders Commission % (Always Cash)')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='device' label={translate('Device')} disabled={isSubmitting} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='responsible' label={translate('Responsible')} disabled={isSubmitting} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='bill' label={translate('Bill')} disabled={isSubmitting} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}
