/**
 * @prettier
 */
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { PinCodeUser } from 'src/types/PinCodeUser';

export function createUserSignedInToPideDirectoPosSuccessfulLogEvent({ managerUser, pinCodeEntered }: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_SIGNED_IN_TO_PIDE_DIRECTO_POS,
        message: `Login successful: ${managerUser.name} ${managerUser.managerUserId}`,
        details: `PinCode entered: ${pinCodeEntered}`,
        data: {
            managerUser,
            pinCodeEntered,
        },
    });
}

type Params = {
    managerUser: PinCodeUser;
    pinCodeEntered: string;
};
