/**
 * @prettier
 */
const styles = (theme: any): any => ({
    paper: {
        padding: 20,
    },
    rejected: {
        backgroundColor: 'rgb(237, 237, 237)',
        // opacity: 0.1,
    },
    highlighted: {
        backgroundColor: '#fafad2',
        // opacity: 0.1,
    },
    time: {
        color: 'rgba(0, 0, 0, 0.24)',
        fontSize: '1.4rem',
    },
    amount: {
        color: 'rgba(0, 0, 0, 0.24)',
        fontSize: '2.6rem',
        lineHeight: '2.6rem',
    },
    buttonContainer: {
        marginLeft: -20,
        marginRight: -20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
});

export default styles;
