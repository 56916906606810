/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import type { CartItemVm } from 'src/types/CartItemVm';
import type { OrderItemVm } from 'src/types/OrderVm';
import type { OrderItemToReturn } from 'src/utils/order/getOrderItemsFormattedToReturn';
import { sum } from 'src/utils/reduce/sum';

export function calculateOrderItemPrice(orderItem: CartItemVm | OrderItemVm | OrderItemToReturn): string {
    const priceWithoutOptions = orderItem.promoUnitPrice ? BigNumber(orderItem.promoUnitPrice) : BigNumber(orderItem.unitPrice);
    const modifierGroupsPrice = orderItem.modifierGroups
        ?.flatMap((modifierGroup) => modifierGroup.modifiers)
        .map((modifier) => BigNumber(modifier.price).multipliedBy(modifier.quantity))
        .reduce(sum, BigNumber(0));

    return priceWithoutOptions.plus(modifierGroupsPrice).multipliedBy(orderItem.quantity).toString();
}
