/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function verifyPosPasswordApi(request: VerifyPosPasswordApiRequest): ApiSauceResponse<boolean> {
    return letseatmanagerApiMethod('pos/verifyPosPasswordApi', request);
}

type VerifyPosPasswordApiRequest = {
    restaurantId: RestaurantId;
    posPassword: string;
};
