/**
 * @prettier
 */
import { Divider, makeStyles } from '@material-ui/core';
import * as React from 'react';

export function SectionTitle({ title }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <section>
            <h1 aria-label={'section-title'} className={classes.title}>
                {title}
            </h1>
            <Divider
                /* @ts-ignore */
                className={{ root: classes.divider }}
            />
        </section>
    );
}

const useStyles = makeStyles((theme) => ({
    divider: {
        backgroundColor: '#616B79',
    },
    title: {
        fontFamily: theme.typography.regular,
        fontWeight: 400,
        color: '#616B79',
        fontSize: 14,
    },
}));

type Props = {
    title: string;
};
