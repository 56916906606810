/**
 * @prettier
 */
import moment from 'moment-timezone';
import type { TimeZone } from 'src/constants/TimeZone';

export function formatDateString(value?: string | null | undefined | Date | null | undefined, timeZone?: TimeZone): string {
    if (value === undefined || value === null) {
        return value as any;
    }
    if (!timeZone) {
        const m = moment(value);
        return m.format('DD/MM/YYYY');
    }
    const m = moment.tz(value, timeZone);
    return m.format('DD/MM/YYYY');
}
