/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { getSmartBiReportApi } from 'src/api/letseatmanager/restaurantDashboard/getSmartBiReportApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ConversionPercentageIcon } from 'src/icons/ConversionPercentageIcon';
import { MoneyBagIcon } from 'src/icons/MoneyBagIcon';
import { OrderBagIcon } from 'src/icons/OrderBagIcon';
import { ReportIndicator } from 'src/scenes/letseatmanager/restaurantDashboard/ReportIndicator';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { SmartBiGraphLast30days } from 'src/scenes/letseatmanager/restaurantDashboard/smartBiReport/SmartBiGraphLast30days';
import { SmartBiGraphPredictions } from 'src/scenes/letseatmanager/restaurantDashboard/smartBiReport/SmartBiGraphPredictions';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function SmartBiReport(): React.ReactElement {
    const classes = useStyles();

    const restaurantIds = useSelector((state) => state.app.restaurantIds);

    const [loading, reports] = useLoadApi(
        getSmartBiReportApi,
        {
            restaurantIds,
        },
        { dependencies: [restaurantIds] }
    );

    return (
        <div className={classes.reportContainer}>
            {loading && <UpdatingContentProgress />}
            {reports && (
                <div>
                    <div className={classes.kpiSection}>
                        <ReportIndicator label={translate('Average daily sales')} value={reports?.kpis.last30DaysMeanDailyOrders ?? '0'} Icon={<MoneyBagIcon />} />
                        <ReportIndicator label={translate("Tomorrow's projection")} value={reports?.kpis.nextDayOrdersPrediction ?? '0'} Icon={<OrderBagIcon />} />
                        <ReportIndicator label={translate('14 day projection')} value={`${reports?.kpis.next14DaysTotalOrdersPrediction ?? '0'}`} Icon={<ConversionPercentageIcon />} />
                    </div>
                    <div className={classes.chartsContainer}>
                        <SmartBiGraphLast30days loading={loading} last30DaysOrders={reports.last30DaysOrders} />
                    </div>
                    <div className={classes.chartsContainer}>
                        <SmartBiGraphPredictions loading={loading} predictions={reports.predictions} />
                    </div>
                </div>
            )}
            {!reports && !loading && <ReportSectionEmptyState />}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    reportContainer: {
        marginBottom: 80,
    },
    kpiSection: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        gap: 12,
        margin: '24px 10px 24px 0px',
    },
    chartsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(45%, 1fr))',
        gap: 16,
    },
}));
