/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Tabs } from 'src/components/Tabs';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { AllOrdersDeprecated } from 'src/scenes/letseatmanager/orders/AllOrdersDeprecated';
import { CustomersInfo } from 'src/scenes/letseatmanager/orders/CustomersInfo';
import styles from 'src/scenes/letseatmanager/orders/OrdersStyles';

/** @deprecated */
export function DeprecatedOrdersPage(): React.ReactElement {
    const classes = useStyles();

    const tabs = [
        {
            value: '1',
            label: translate('All Orders'),
            rolePermission: RolePermissions.ALL_ORDERS,
        },
        {
            value: '2',
            label: translate('Customer information'),
            rolePermission: RolePermissions.CUSTOMER_INFO,
        },
    ];

    return (
        <SecuredAndSubscribedPage className={(classes as any).container} title={translate('All Orders')} rolePermission={RolePermissions.ALL_ORDERS_PAGE}>
            <Tabs tabs={tabs} classes={{ tabContent: (classes as any).tabContent }}>
                <AllOrdersDeprecated />
                <CustomersInfo />
            </Tabs>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    ...styles(theme),
    tabContent: {
        height: '100%',
        overflowY: 'hidden',
    },
}));
