/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function changePedidosYaIntegrationCommissionPercentageApi(request: ChangePedidosYaIntegrationCommissionPercentageApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurantIntegration/changeRestaurantPedidosYaSalesCommissionPercentageApi', request);
}

export type ChangePedidosYaIntegrationCommissionPercentageApiRequest = {
    restaurantId: RestaurantId;
    pedidosYaSalesCommissionPercentage?: number;
};
