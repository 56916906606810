/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions } from '@pidedirecto/ui';
import { Form, FormTextField } from '@pidedirecto/ui/form';
import { useForm } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function AddPaymentReferenceDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    const form = useForm();

    const handleSubmit = (form: { paymentReference: string }) => {
        onSuccess?.(form?.paymentReference);
    };

    return (
        <Dialog open={open} onClose={onClose} title={translate('Card reference')}>
            <Form form={form} onSubmit={handleSubmit}>
                <FormTextField name={'paymentReference'} label={translate('Key reference')} required />
                <DialogActions className={classes.dialogActions}>
                    <Button variant={'secondary'} onClick={onClose}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit'>{translate('Accept')}</Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        width: '30vw',
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            width: '80vw',
        },
    },
    dialogActions: {
        gap: 12,
        marginTop: 12,
        justifyContent: 'flex-start',
    },
}));

type Props = {
    open: boolean;
    onClose: () => void;
    onSuccess?: any;
};
