/**
 * @prettier
 */
import Papa from 'papaparse';

export async function csvToObjects<T extends any>(csv: string): Promise<Array<T>> {
    return new Promise((resolve: (result: Promise<never>) => void, reject: (error?: any) => void) => {
        Papa.parse(csv, {
            complete: (results) => resolve(results.data as any),
            error: (e: any) => reject(e),
            header: true,
        });
    });
}
