/**
 * @prettier
 */
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as React from 'react';

export function TableHeaderCalculationUy({ name }: Props): React.ReactElement {
    return (
        <TableHead>
            <TableRow>
                <TableCell>{name}</TableCell>
            </TableRow>
        </TableHead>
    );
}

type Props = {
    name: string;
};
