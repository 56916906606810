/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button, Text } from '@pidedirecto/ui';
import { SpeakerIcon, SpeakerOffIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export function ToggleSoundButton(): React.ReactElement | null {
    const classes = useStyles();
    const isSmallScreen = useIsSmallScreen();

    const soundMuted = useSelector((state) => state.app.soundMuted);
    const kitchensUser = useSelector((state) => state.authentication.kitchensUser);

    const setSoundMuted = useAction(appReducer.actions.setSoundMuted);

    const toggleSound = () => setSoundMuted(!soundMuted);

    if (kitchensUser) return null;

    return (
        <Button variant={isSmallScreen ? 'text' : 'secondary'} onClick={toggleSound} classes={{ button: classes.button }}>
            <div className={classes.iconContainer}>{soundMuted ? <SpeakerOffIcon size={24} title={translate('Mute sound')} /> : <SpeakerIcon size={24} title={translate('Unmute sound')} />}</div>
            {soundMuted ? <Text className={classes.hiddenDesktop}>{translate('Sound on')}</Text> : <Text className={classes.hiddenDesktop}>{translate('Sound off')}</Text>}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        color: theme.palette.primary.main,
        height: 48,
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.text.secondary,
            height: 42,
            width: '100%',
            textDecoration: 'none !important',
        },
    },
    hiddenDesktop: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            minWidth: 50,
        },
    },
}));
