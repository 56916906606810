/**
 * @prettier
 */
import type { PageContextType } from 'src/components/Page';
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';

export function createUserToggledAccordionLogEvent({ pageContext, title }: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_TOGGLED_ACCORDION,
        message: `accordion ${title || ''} toggled`,
        details: `${pageContext.stackTrace}/${normalizeUiStackTrace(`accordion_${title || ''}`)}`,
        data: {
            title,
            accordionId: normalizeUiStackTrace(`accordion_${title || ''} `),
            pathId: `${pageContext.stackTrace}/${normalizeUiStackTrace(`dialog_${title || ''}`)}`,
        },
    });
}

type Params = {
    title?: string;
    pageContext: PageContextType;
};
