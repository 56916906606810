/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BusinessVm } from 'src/types/BusinessVm';
import type { BrandId } from 'src/types/Id';

export async function createBusinessApi(request: CreateBusinessApiRequest): ApiSauceResponse<BusinessVm> {
    return letseatadminApiMethod('business/createBusinessApi', request);
}

type CreateBusinessApiRequest = {
    name: string;
    brandIds: Array<BrandId>;
    street?: string;
    phoneNumber?: string;
    email?: string;
};
