/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { SectionTitle } from 'src/components/title/SectionTitle';
import { TicketSections } from 'src/constants/TicketSection';
import { translate } from 'src/i18n/translate';

export function TicketPaymentMethodSettings({ disabled }: Props): React.ReactElement {
    const { control } = useFormContext();

    const paymentMethod = useWatch({ name: TicketSections.PAYMENT_METHOD, control });

    return (
        <>
            <SectionTitle title={translate('Payment ways')} />
            {/* @ts-ignore */}
            <Grid item xs={12} columnSpacing={2}>
                <FormSwitch disabled={disabled} name={TicketSections.PAYMENT_METHOD} label={translate(`TicketSections.${TicketSections.PAYMENT_METHOD}`)} />
            </Grid>
            {paymentMethod && (
                <Grid item xs={12}>
                    <FormSwitch disabled={disabled} name={TicketSections.PAYMENT_CHANGE} label={translate(`TicketSections.${TicketSections.PAYMENT_CHANGE}`)} />
                </Grid>
            )}
        </>
    );
}

type Props = {
    disabled: boolean;
};
