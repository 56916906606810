/**
 * @prettier
 */
import type { RestaurantVideoVm } from 'src/api/letseatadmin/restaurantVideo/findRestaurantVideosApi';
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';

export async function createRestaurantVideoApi(request: CreateRestaurantVideoApiRequest): ApiSauceResponse<RestaurantVideoVm> {
    return letseatadminApiMethod('restaurantVideo/createRestaurantVideoApi', request);
}

export type CreateRestaurantVideoApiRequest = {
    name: string;
    videoUrl: string;
};
