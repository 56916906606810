/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import * as React from 'react';
import { useEffect } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { Modifier } from 'src/scenes/letseatmanager/deprecatedMenu/Modifier';
import type { DeprecatedModifierGroupVm, DeprecatedModifierVm } from 'src/types/DeprecatedManagerMenuItemVm';

export function ModifierGroup({ modifierGroupIndex, removeModifierGroup, showHiddenSwitch, modifierGroup, control, loading, prevModifiers }: Props): React.ReactElement {
    const classes = useStyles();
    const { fields, append, remove } = useFieldArray({
        control,
        name: `modifierGroups.${modifierGroupIndex}.modifiers`,
    });

    const requiredMin = useWatch({ name: `modifierGroups.${modifierGroupIndex}.requiredMin`, control });
    const requiredMax = useWatch({ name: `modifierGroups.${modifierGroupIndex}.requiredMax`, control });

    const renderModifiers = () => {
        const addNewModifier = () => append({ modifierId: '', name: '', requiredMin: null, requiredMax: null, subModifiers: [{ name: '', price: '', hidden: false }] });
        return (
            <div className={classes.modifiersContainer}>
                <h3 className={classes.title}>{translate('MODIFIERS')}</h3>
                {fields.map((modifier, modifierIndex) => (
                    <Modifier
                        key={modifier.id}
                        modifierGroupIndex={modifierGroupIndex}
                        modifierIndex={modifierIndex}
                        modifier={modifier as any}
                        loading={loading}
                        showRemoveButton={fields.length > 1}
                        removeModifier={() => remove(modifierIndex)}
                        showHiddenSwitch={showHiddenSwitch}
                        control={control}
                        prevSubModifierGroups={prevModifiers ? (prevModifiers[modifierIndex]?.subModifierGroups ? prevModifiers[modifierIndex]?.subModifierGroups : []) : []}
                    />
                ))}
                <div className={classes.buttonContainer}>
                    <Button secondary onClick={addNewModifier} disabled={loading}>
                        {translate('ADD MODIFIER')}
                    </Button>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (prevModifiers && fields.length !== prevModifiers?.length) {
            for (const modifier of prevModifiers) {
                append({
                    externalModifierId: modifier.externalModifierId,
                    modifierId: modifier.modifierId,
                    name: modifier.name,
                    price: modifier.price,
                    type: modifier.type,
                    subModifierGroups: modifier.subModifierGroups,
                });
            }
        }
    }, [prevModifiers]);

    return (
        <div>
            <Grid container className={classes.titleContainer}>
                <h3 className={classes.title}>
                    {translate('Modifier Group')} {modifierGroupIndex + 1}
                </h3>
                <IconButton disabled={loading} aria-label='Remove' onClick={removeModifierGroup}>
                    <ClearIcon />
                </IconButton>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormTextField name={`modifierGroups.${modifierGroupIndex}.name`} label={translate('Name')} required disabled={loading} defaultValue={modifierGroup.name} />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField
                        name={`modifierGroups.${modifierGroupIndex}.externalModifierGroupId`}
                        label={translate('External Modifier Group Id')}
                        disabled={loading}
                        defaultValue={modifierGroup.externalModifierGroupId}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FormNumberField
                        name={`modifierGroups.${modifierGroupIndex}.requiredMin`}
                        label={translate('Required Min')}
                        required
                        max={{
                            value: (requiredMax as any) || 0,
                            message: translate('Must be number equal to or less than min required'),
                        }}
                        disabled={loading}
                        defaultValue={modifierGroup.requiredMin || 0}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FormNumberField
                        name={`modifierGroups.${modifierGroupIndex}.requiredMax`}
                        label={translate('Required Max')}
                        required
                        min={{
                            value: (requiredMin as any) || 0,
                            message: translate('Must be number equal to or larger than max required'),
                        }}
                        disabled={loading}
                        defaultValue={modifierGroup.requiredMax || 0}
                    />
                </Grid>
                {showHiddenSwitch && (
                    <Grid item xs={6}>
                        <FormSwitch name={`modifierGroups.${modifierGroupIndex}.hidden`} disabled={loading} label={translate('Hidden')} defaultValue={modifierGroup.hidden} />
                    </Grid>
                )}
            </Grid>
            {renderModifiers()}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 24,
    },
    title: {
        fontFamily: theme.typography.medium,
        margin: 0,
        paddingBottom: 24,
        textAlign: 'center',
    },
    secondaryButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    modifiersContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 20,
        paddingBottom: 24,
        borderBottom: '1px solid #d9d9d9',
    },
    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

type Props = {
    modifierGroupIndex: number;
    modifierGroup: DeprecatedModifierGroupVm;
    loading: boolean;
    control: any;
    removeModifierGroup: any;
    showHiddenSwitch: boolean;
    prevModifiers?: Array<DeprecatedModifierVm>;
    // updateHasDuplicatedModifiers?: Function
};
