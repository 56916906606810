/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';

export function UserSignInConfiguration({ disabled }: Props): React.ReactElement {
    const classes = useStyles();

    const { watch, setValue } = useFormContext();

    const passwordSignIn = watch(RolePermissions.PASSWORD_SIGN_IN);
    const openPersonalCashRegister = watch(RolePermissions.OPEN_PERSONAL_CASH_REGISTER);

    const disabledPinCodeSignIn = disabled || passwordSignIn;

    useEffect(() => {
        if (passwordSignIn) {
            setValue(RolePermissions.PIN_CODE_SIGN_IN, passwordSignIn);
        }
    }, [passwordSignIn]);

    return (
        <div className={classes.container}>
            <div className={classes.label}>{translate('Sign in type')}</div>
            <div className={classes.permissionsContainer}>
                <FormCheckbox defaultValue={openPersonalCashRegister} label={translate('RolePermissions.PIN_CODE_SIGN_IN')} name={RolePermissions.PIN_CODE_SIGN_IN} disabled={disabledPinCodeSignIn} />
                <FormCheckbox label={translate('RolePermissions.PASSWORD_SIGN_IN')} name={RolePermissions.PASSWORD_SIGN_IN} disabled={disabled} />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        color: theme.palette.secondary.contrastText,
    },
    permissionsContainer: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
    },
    homeContainer: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '25%',
    },
    radioButton: {
        color: `${theme.palette.primary.main} !important`,
    },
}));

type Props = {
    disabled?: boolean;
};
