/**
 * @prettier
 */
import { findMenusApi } from 'src/api/letseatmanager/menu/findMenusApi';
import { unzip } from 'src/api/utils/unzip';
import { menuReducer } from 'src/reducers/menuReducer';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

let callingApi = false;

export function useLoadMenus(): any {
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const setMenus = useAction(menuReducer.actions.setMenus);
    const setLoadingMenus = useAction(menuReducer.actions.setLoadingMenus);
    const setFailed = useAction(menuReducer.actions.setMenuFailed);

    const loadMenus = async () => {
        if (callingApi) return;

        setLoadingMenus(true);
        callingApi = true;
        const response = await findMenusApi({ restaurantId });
        if (!response.ok) {
            setFailed();
            setLoadingMenus(false);
            callingApi = false;
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setMenus(unzip(response.data));
        setLoadingMenus(false);
        callingApi = false;
    };

    return loadMenus;
}
