/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useRef, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { CashRegisterIsClosedDialog } from 'src/components/dialog/cashRegister/CashRegisterIsClosedDialog';
import { NumberOfCustomersDialog } from 'src/components/dialog/pos/NumberOfCustomersDialog';
import { OrderTypes } from 'src/constants/OrderType';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { ChangeTableDialog } from 'src/scenes/letseatmanager/posRestaurantZones/ChangeTableDialog';
import { JoinTablesDialog } from 'src/scenes/letseatmanager/posRestaurantZones/JoinTablesDialog';
import { PosTable } from 'src/scenes/letseatmanager/posRestaurantZones/PosTable';
import { useHasOpenedCashRegister } from 'src/services/cashRegister/useHasOpenedCashRegister';
import { useManageOpenCashRegister } from 'src/services/cashRegister/useManageOpenCashRegister';
import { useNotification } from 'src/services/notification/useNotification';
import { useIsOrderVisibleForUser } from 'src/services/pos/useIsOrderVisibleForUser';
import type { RestaurantZoneId } from 'src/types/Id';
import type { TableVm } from 'src/types/RestaurantZoneVm';
import { useAction } from 'src/utils/react/useAction';
import { useEditPendingOrder } from 'src/utils/react/useEditPendingOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';
import { requireValue } from 'src/utils/require/requireValue';

export function PosTables({ tables, restaurantZoneId, tablesPosition, editing, changeTables, refreshRestaurantZones }: Props): React.ReactElement | null {
    const classes = useStyles();

    const history = useHistory();
    const notification = useNotification();
    const editPendingOrder = useEditPendingOrder();
    const isOrderVisibleForUser = useIsOrderVisibleForUser();
    const [userHasRolePermission] = useUserHasRolePermission();

    const hasOpenedCashRegister = useHasOpenedCashRegister();
    const { manageOpenCashRegister } = useManageOpenCashRegister();

    const containerRef = useRef<HTMLDivElement>(null);
    const tableRef = useRef<{ clientX: number; clientY: number; tableNumber: string }>({
        clientX: 0,
        clientY: 0,
        tableNumber: '0',
    });

    const [changeTableDialogState, setChangeTableDialogState] = useState({ open: false, table: undefined });
    const [openNumberOfCustomersDialogState, setNumberOfCustomersDialogState] = useState<{ open: boolean; onSuccess: any }>({ open: false, onSuccess: undefined });
    const [joinTablesDialogState, setJoinTablesDialogState] = useState({ open: false, selectedTableNumber: undefined });
    const [openCashRegisterClosedDialog, setOpenCashRegisterClosedDialog] = useState(false);

    const posCustomersRequiredEnabled = useSelector((state) => state.pos.context?.posCustomersRequiredEnabled);
    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);

    const setPosTable = useAction(posReducer.actions.setPosTable);
    const selectPosOrderType = useAction(posReducer.actions.selectPosOrderType);
    const changeRestaurantTablesAndZones = useAction(posReducer.actions.changeRestaurantTablesAndZones);

    const isMobile = isMobileApp();

    const handleTableMouseDown = ({ e, tableNumber }: { e: any; tableNumber: string }) => {
        tableRef.current = {
            clientX: e.clientX,
            clientY: e.clientY,
            tableNumber,
        };
    };

    const handleTableMouseUp = () => {
        tableRef.current = {
            clientX: 0,
            clientY: 0,
            tableNumber: '0',
        };
    };

    const handleMouseMove = (event: any) => {
        const e = isMobile ? event.targetTouches[0] : event;
        if (tableRef.current.clientX && tableRef.current.clientY) {
            const element = document.getElementById(tableRef.current.tableNumber);

            const offsetWidth = element?.offsetWidth ?? 0;
            const offsetHeight = element?.offsetHeight ?? 0;
            const offsetTop = element?.offsetTop ?? 0;
            const offsetLeft = element?.offsetLeft ?? 0;

            const containerWidth = Math.floor(containerRef.current?.offsetWidth ?? 0);
            const containerHeight = Math.floor(containerRef.current?.offsetHeight ?? 0);

            let top = offsetTop - (tableRef.current.clientY - e.clientY);
            let left = offsetLeft - (tableRef.current.clientX - e.clientX);

            if (left < 0) left = 0;
            if (left + offsetWidth > containerWidth) left = containerWidth - offsetWidth;

            if (top < 0) top = 0;
            if (top + offsetHeight > containerHeight) top = containerHeight - offsetHeight;

            if (element) {
                element.style.top = `${top}px`;
                element.style.left = `${left}px`;
            }

            const tablePosition = tablesPosition.current.find((tablePosition) => tablePosition.tableNumber === tableRef.current.tableNumber);
            const tablePositionUpdated = {
                tableNumber: requireValue(tablePosition).tableNumber,
                tableShape: requireValue(tablePosition).tableShape,
                tableSize: requireValue(tablePosition).tableSize,
                rotation: requireValue(tablePosition).rotation,
                top,
                left,
            } as const;
            tablesPosition.current = [...tablesPosition.current.filter((tablePosition) => tablePosition.tableNumber !== tableRef.current.tableNumber), tablePositionUpdated];

            tableRef.current = {
                clientX: e.clientX,
                clientY: e.clientY,
                tableNumber: tableRef.current.tableNumber,
            };
            changeRestaurantTablesAndZones(true);
        }
    };

    const closeChangeTableDialog = () => setChangeTableDialogState({ open: false, table: undefined });

    const handleClickEdit = (table: any) => setChangeTableDialogState({ open: true, table });

    const changeTableDialogSucceeded = (table: any) => {
        if (tables.some((_table) => _table.tableNumber === table.tableNumber) && table.tableNumber !== table.prevTableNumber) {
            return alert(translate('There is already a table with the same tableNumber, please change it.'));
        }

        const tablePosition = tablesPosition.current.find((_table) => _table.tableNumber === table.prevTableNumber);
        changeTables([...tables.filter((_table) => _table.tableNumber !== table.prevTableNumber), { ...table, top: tablePosition?.top, left: tablePosition?.left, rotation: tablePosition?.rotation }]);
        closeChangeTableDialog();
        changeRestaurantTablesAndZones(true);
    };

    const handleClickRemove = (table: any) => {
        tablesPosition.current = tablesPosition.current.filter((_table) => _table.tableNumber !== table.tableNumber);
        changeTables(tables.filter((_table) => _table.tableNumber !== table.tableNumber));
        changeRestaurantTablesAndZones(true);
    };

    const selectTable = async (table: any, numberOfCustomers?: any) => {
        setPosTable({
            table: {
                restaurantZoneId,
                table: table.tableNumber,
                numberOfCustomers,
            },
            orderOpenedFromTable: true,
        });

        if (table.order && userHasRolePermission(RolePermissions.EDIT_ORDERS)) {
            editPendingOrder({ order: table.order });
        }
        selectPosOrderType(OrderTypes.TABLE_ORDER);
        history.push({
            pathname: RoutePaths.POS,
            search: history.location.search,
        });
    };

    const handleCloseCashRegisterIsClosedDialog = () => {
        setOpenCashRegisterClosedDialog(false);

        manageOpenCashRegister({ forceOpenCashRegister: true });
    };

    const handleClick = (table: any) => {
        if (posEnabled && !hasOpenedCashRegister && !posMultipleCashRegistersEnabled) {
            setOpenCashRegisterClosedDialog(true);
            return;
        }

        if (posEnabled && !hasOpenedCashRegister && !!posMultipleCashRegistersEnabled) {
            notification({ message: translate('In order to create a new order, you must first open the cash register') });
            return;
        }

        if (posCustomersRequiredEnabled && !table.order) {
            return setNumberOfCustomersDialogState({ open: true, onSuccess: (numberOfCustomers: any) => selectTable(table, numberOfCustomers) });
        }

        if (table.order && !isOrderVisibleForUser(table.order)) {
            return notification({ message: translate('This table is served by another waiter.') });
        }

        if (table.order?.numberOfCustomers) {
            return selectTable(table, table.order.numberOfCustomers);
        }

        selectTable(table);
    };

    const handleClickJoinTables = (selectedTableNumber: any) => setJoinTablesDialogState({ open: true, selectedTableNumber });

    const closeJoinTablesDialog = () => setJoinTablesDialogState({ open: false, selectedTableNumber: undefined });

    const onRotate = (table: any, rotation: any) => {
        const tablePosition = tablesPosition.current.find((_table) => _table.tableNumber === table.tableNumber);

        if (!tablePosition) return;

        const tablePositionUpdated = { ...tablePosition, rotation } as const;
        tablesPosition.current = [...tablesPosition.current.filter((tablePosition) => tablePosition.tableNumber !== table.tableNumber), tablePositionUpdated];
    };

    if (!tables?.length) return null;

    return (
        <div ref={containerRef} className={classes.container} onMouseMove={handleMouseMove} onMouseLeave={handleTableMouseUp} onTouchMove={handleMouseMove} onTouchEnd={handleTableMouseUp}>
            {restaurantZoneId && (
                <JoinTablesDialog
                    open={joinTablesDialogState.open}
                    restaurantZoneId={restaurantZoneId}
                    selectedTableNumber={joinTablesDialogState.selectedTableNumber}
                    tables={tables}
                    onClose={closeJoinTablesDialog}
                    onSuccess={refreshRestaurantZones}
                />
            )}
            <ChangeTableDialog open={changeTableDialogState.open} table={changeTableDialogState.table} handleClose={closeChangeTableDialog} onSuccess={changeTableDialogSucceeded} />
            <NumberOfCustomersDialog
                open={openNumberOfCustomersDialogState.open}
                handleClose={() => setNumberOfCustomersDialogState({ open: false, onSuccess: undefined })}
                onSuccess={openNumberOfCustomersDialogState.onSuccess}
            />
            <CashRegisterIsClosedDialog open={openCashRegisterClosedDialog} handleClose={handleCloseCashRegisterIsClosedDialog} />
            {tables?.map((table: TableVm, idx: number) => (
                <PosTable
                    key={idx}
                    table={table}
                    editing={editing}
                    onMouseDown={handleTableMouseDown}
                    onMouseUp={handleTableMouseUp}
                    onEdit={handleClickEdit}
                    onRemove={handleClickRemove}
                    onJoinTables={handleClickJoinTables}
                    onClick={handleClick}
                    onRotate={onRotate}
                    refreshRestaurantZones={refreshRestaurantZones}
                />
            ))}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        position: 'relative',
        paddingBottom: 60,
    },
}));

type Props = {
    tables: Array<TableVm>;
    restaurantZoneId?: RestaurantZoneId;
    tablesPosition: {
        current: Array<TableVm>;
    };
    editing: boolean;
    changeTables: any;
    refreshRestaurantZones: any;
};
