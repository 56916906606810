/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PrinterId } from 'src/types/Id';

export async function deletePrinterApi(request: DeletePrinterApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('printerDeprecated/deletePrinterApi', request);
}

export type DeletePrinterApiRequest = {
    printerId: PrinterId;
};
