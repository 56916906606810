/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import UpdateIcon from '@material-ui/icons/Update';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getRestaurantsOverallStatsApi, SubscriptionStatsVm } from 'src/api/letseatadmin/overallStats/getRestaurantsOverallStatsApi';
import { app2 } from 'src/app2';
import { SubscriptionPaymentMethods } from 'src/constants/SubscriptionPaymentMethod';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import styles from 'src/scenes/letseatadmin/overallStats/OverallStatsStyles';
import { SentryService } from 'src/services/SentryService';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateString } from 'src/utils/date/formatDateString';
import { useAction } from 'src/utils/react/useAction';

export function OverallStatsPage(): React.ReactElement {
    const classes = useStyles();

    const [selected, setSelected] = useState('Amount');

    const [from, setFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [to, setTo] = useState(moment().endOf('month').format('YYYY-MM-DD'));

    const [loading, setLoading] = useState(false);

    const [amounts, setAmounts] = useState({
        paymentLinks: '',
        pideDirectoCard: '',
        pideDirectoCash: '',
        pideDirectoAppCard: '',
        pideDirectoAppCash: '',
        manualOrders: '',
        rappiOrders: '',
        uberEatsOrders: '',
        didiOrders: '',
        total: '',
    });
    const [orders, setOrders] = useState({
        paymentLinks: '',
        pideDirectoCard: '',
        pideDirectoCash: '',
        pideDirectoAppCard: '',
        pideDirectoAppCash: '',
        manualOrders: '',
        rappiOrders: '',
        uberEatsOrders: '',
        didiOrders: '',
        total: '',
    });
    const [subscriptions, setSubscriptions] = useState<SubscriptionStatsVm>({
        cards: '',
        speiAndOxxo: '',
        previousPayments: [
            {
                restaurantName: '',
                subscriptionPaymentMethod: SubscriptionPaymentMethods.CARD,
                previousPaymentAmount: '',
                previousPaymentPaidAt: new Date(),
            },
        ],
    });

    const setTitle = useAction(app2.actions.setTitle);

    const handleChangeFromDate = (date: any) => {
        setFrom(date.format('YYYY-MM-DD'));
    };
    const handleChangeToDate = (date: any) => {
        setTo(date.format('YYYY-MM-DD'));
    };

    const handleSubmit = () => {
        fetchOverallStats();
    };

    const fetchOverallStats = async () => {
        setLoading(true);
        const request = {
            ...(from && { from: moment.tz(from, TimeZones.AMERICA_MONTERREY).toDate() }),
            ...(to && { to: moment.tz(to, TimeZones.AMERICA_MONTERREY).add(1, 'day').toDate() }),
        } as const;

        const response = await getRestaurantsOverallStatsApi(request as any);
        setLoading(false);

        if (!response.ok) {
            SentryService.logError('Failed to download the orders view model', { request, response });
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        SentryService.logInfoBreadcrumb('Successfully downloaded the orders view model', { request });

        setSubscriptions(response.data.subscriptionStats);
        setAmounts(response.data.orderAmountStats);
        setOrders(response.data.orderCountStats);
    };

    useEffect(() => {
        setTitle(translate('Overall Stats'));
        fetchOverallStats();
    }, []);

    return (
        <div className={(classes as any).container}>
            <Grid container direction='row' justify='center'>
                <Grid item xs={6} className={(classes as any).orderMaxWidth}>
                    <Paper square>
                        <Tabs
                            value={selected}
                            onChange={(event, value) => {
                                setSelected(value);
                            }}
                            indicatorColor='primary'
                            textColor='primary'
                            centered
                        >
                            <Tab label='Amount' value={'Amount'} onClick={() => setSelected('Amount')} />
                            <Tab label='Orders' value={'Orders'} onClick={() => setSelected('Orders')} />
                            <Tab label='Payments' value={'Payments'} onClick={() => setSelected('Payments')} />
                        </Tabs>
                    </Paper>
                </Grid>
            </Grid>
            <Typography className={(classes as any).padding} />
            <Grid container direction='row' justify='center'>
                <Grid item className={(classes as any).cardContainer}>
                    <Card className={(classes as any).card} elevation={0}>
                        <CardContent>
                            <div className={(classes as any).inputContainer}>
                                <DatePicker variant='inline' label={translate('From')} value={from} disabled={loading} onChange={handleChangeFromDate} format='ll' autoOk />
                                <DatePicker variant='inline' label={translate('To')} value={to} disabled={loading} onChange={handleChangeToDate} format='ll' autoOk />
                            </div>

                            {selected === 'Payments' ? (
                                <>
                                    <Grid container direction='column' alignItems='center'>
                                        <Grid item xs={8} className={(classes as any).infoContainer}>
                                            <Typography className={(classes as any).text}>{translate('Cards')}:</Typography>
                                            <Typography className={(classes as any).text}>{`$${subscriptions.cards}`}</Typography>
                                        </Grid>
                                        <Grid item xs={8} className={(classes as any).infoContainer}>
                                            <Typography className={(classes as any).text}>{translate('SPEI and OXXO')}:</Typography>
                                            <Typography className={(classes as any).text}>{`$${subscriptions.speiAndOxxo}`}</Typography>
                                        </Grid>
                                    </Grid>
                                    <MUIDataTable
                                        /* @ts-ignore */
                                        className={(classes as any).table}
                                        data={
                                            subscriptions.previousPayments &&
                                            subscriptions.previousPayments.map((subscription) => {
                                                return {
                                                    restaurantName: subscription.restaurantName,
                                                    subscriptionPaymentMethod: subscription.subscriptionPaymentMethod,
                                                    previousPaymentAmount: subscription.previousPaymentAmount,
                                                    previousPaymentPaidAt: formatDateString(subscription.previousPaymentPaidAt),
                                                };
                                            })
                                        }
                                        columns={[
                                            {
                                                name: 'restaurantName',
                                                label: translate('Restaurant Name'),
                                                options: {
                                                    filter: false,
                                                },
                                            },
                                            {
                                                name: 'subscriptionPaymentMethod',
                                                label: translate('Subscription Payment Method'),
                                                options: {
                                                    filter: false,
                                                },
                                            },
                                            {
                                                name: 'previousPaymentAmount',
                                                label: translate('Previous Payment Amount'),
                                                options: {
                                                    filter: false,
                                                },
                                            },
                                            {
                                                name: 'previousPaymentPaidAt',
                                                label: translate('Previous Payment Paid At'),
                                                options: {
                                                    filter: false,
                                                },
                                            },
                                        ]}
                                    />
                                </>
                            ) : (
                                <Grid container direction='column' alignItems='center'>
                                    <Grid item xs={8} className={(classes as any).infoContainer}>
                                        <Typography className={(classes as any).text}>{translate('Payment Links')}:</Typography>
                                        <Typography className={(classes as any).text}>{selected === 'Amount' ? `$${amounts.paymentLinks}` : `${orders.paymentLinks}`}</Typography>
                                    </Grid>
                                    <Grid item xs={8} className={(classes as any).infoContainer}>
                                        <Typography className={(classes as any).text}>{translate('PideDirecto Card')}:</Typography>
                                        <Typography className={(classes as any).text}>{selected === 'Amount' ? `$${amounts.pideDirectoCard}` : `${orders.pideDirectoCard}`}</Typography>
                                    </Grid>
                                    <Grid item xs={8} className={(classes as any).infoContainer}>
                                        <Typography className={(classes as any).text}>{translate('PideDirecto Cash')}:</Typography>
                                        <Typography className={(classes as any).text}>{selected === 'Amount' ? `$${amounts.pideDirectoCash}` : `${orders.pideDirectoCash}`}</Typography>
                                    </Grid>
                                    <Grid item xs={8} className={(classes as any).infoContainer}>
                                        <Typography className={(classes as any).text}>{translate('Marketplace Card')}:</Typography>
                                        <Typography className={(classes as any).text}>{selected === 'Amount' ? `$${amounts.pideDirectoAppCard}` : `${orders.pideDirectoAppCard}`}</Typography>
                                    </Grid>
                                    <Grid item xs={8} className={(classes as any).infoContainer}>
                                        <Typography className={(classes as any).text}>{translate('Marketplace Cash')}:</Typography>
                                        <Typography className={(classes as any).text}>{selected === 'Amount' ? `$${amounts.pideDirectoAppCash}` : `${orders.pideDirectoAppCash}`}</Typography>
                                    </Grid>
                                    <Grid item xs={8} className={(classes as any).infoContainer}>
                                        <Typography className={(classes as any).text}>{translate('Manual Orders')}:</Typography>
                                        <Typography className={(classes as any).text}>{selected === 'Amount' ? `$${amounts.manualOrders}` : `${orders.manualOrders}`}</Typography>
                                    </Grid>
                                    <Grid item xs={8} className={(classes as any).infoContainer}>
                                        <Typography className={(classes as any).text}>{translate('Rappi Orders')}:</Typography>
                                        <Typography className={(classes as any).text}>{selected === 'Amount' ? `$${amounts.rappiOrders}` : `${orders.rappiOrders}`}</Typography>
                                    </Grid>
                                    <Grid item xs={8} className={(classes as any).infoContainer}>
                                        <Typography className={(classes as any).text}>{translate('Uber Eats Orders')}:</Typography>
                                        <Typography className={(classes as any).text}>{selected === 'Amount' ? `$${amounts.uberEatsOrders}` : `${orders.uberEatsOrders}`}</Typography>
                                    </Grid>
                                    <Grid item xs={8} className={(classes as any).infoContainer}>
                                        <Typography className={(classes as any).text}>{translate('Didi Food Orders')}:</Typography>
                                        <Typography className={(classes as any).text}>{selected === 'Amount' ? `$${amounts.didiOrders}` : `${orders.didiOrders}`}</Typography>
                                    </Grid>
                                    <Grid item xs={8} className={(classes as any).infoContainer}>
                                        <Typography className={(classes as any).textBold}>{translate('Total')}:</Typography>
                                        <Typography className={(classes as any).textBold}>{selected === 'Amount' ? `$${amounts.total}` : `${orders.total}`}</Typography>
                                    </Grid>
                                </Grid>
                            )}

                            <Grid container direction='column' alignItems='center'>
                                <Button className={(classes as any).button} type='submit' disabled={loading} onClick={handleSubmit} startIcon={<UpdateIcon />}>
                                    {translate('REFRESH')}
                                </Button>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles(styles);
