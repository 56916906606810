/**
 * @prettier
 */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import { translate } from 'src/i18n/translate';
import { WindowsPrinter } from 'src/services/printer/printers/WindowsPrinter';

export function FormDriverPrintersSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, onChange }: Props): React.ReactElement {
    const [options, setOptions] = useState<Array<{ label: string; value: string | null }>>([{ label: '', value: null }]);

    useEffect(() => {
        async function load() {
            let printers;

            try {
                printers = await WindowsPrinter.getInstalledPrinters();

                setOptions(printers.map((printerName) => ({ label: translate(printerName), value: printerName })));
            } catch (e: any) {
                console.log('Failed to getInstalledPrinters, verify your configuration');
            }
        }

        load();
    }, []);

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={required}
            onChange={onChange}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    onChange?: any;
};
