/**
 * @prettier
 */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { SECONDS } from 'src/constants/TimeUnit';

export function useClock(): [string, string] {
    let interval: NodeJS.Timer;

    const [time, setTime] = useState(moment().format('LT'));
    const [date, setDate] = useState(moment().format('LLL'));

    useEffect(() => {
        interval = setInterval(() => {
            setTime(moment().format('LT'));
            setDate(moment().format('LLL'));
        }, 10 * SECONDS);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return [time, date];
}
