/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import Radio from 'src/components/deprecated/react-final-form/Radio';
import Select from 'src/components/deprecated/react-final-form/Select';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { Spacing } from 'src/components/spacing/Spacing';
import { history } from 'src/config/history';
import { Cities } from 'src/constants/City';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { DriverId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { pretty } from 'src/utils/string/pretty';
import { stringToBoolean } from 'src/utils/string/stringToBoolean';
import { combine, greaterThanOrEqualToZero, integer } from 'src/utils/Validator';

export const DriversChangeDialog: React.ComponentType<any> = withRouter<any, any>(
    withStyles((theme) => ({
        linearProgress: {
            position: 'absolute',
            width: '100%',
            bottom: 0,
        },
    }))(
        class DriversChangeDialog extends React.PureComponent<Props, State> {
            state: any = INITIAL_STATE;

            async componentDidMount(): Promise<void> {
                await this.openOrCloseDialog();
            }

            async componentDidUpdate(prevProps: Props) {
                if (this.props.location.pathname === prevProps.location.pathname) {
                    return;
                }
                await this.openOrCloseDialog();
            }

            handleClose = () => {
                if (this.state.submitting) {
                    return;
                }
                history.goBack();
            };

            handleExited = () => {
                this.setState(INITIAL_STATE as any);
            };

            openOrCloseDialog = async () => {
                if (new RegExp(`^${this.props.path}`).test(this.props.location.pathname)) {
                    if (!this.props.location.state) {
                        history.push({
                            pathname: RoutePaths.ADMIN_DRIVERS,
                            search: history.location.search,
                        });
                        return;
                    }
                    if (!this.state.open) {
                        this.setState({
                            open: true,
                            drivers: this.props.location.state.drivers,
                        });
                    }
                } else {
                    if (this.state.open) this.setState({ open: false });
                }
            };

            onSubmit = async (form: any) => {
                this.setState({ submitting: true });
                const response = await letseatmanagerApiDeprecated.admin.changeDrivers({
                    driverIds: this.state.drivers.map((driver: any) => driver.driverId),
                    city: this.state.changeCity ? form.city || null : undefined,
                    verified: this.state.changeVerified && (form.verified === 'true' || form.verified === 'false') ? stringToBoolean(form.verified) : undefined,
                    appPaymentsEnabled:
                        this.state.changeAppPaymentsEnabled && (form.appPaymentsEnabled === 'true' || form.appPaymentsEnabled === 'false') ? stringToBoolean(form.appPaymentsEnabled) : undefined,
                    cashPaymentsEnabled:
                        this.state.changeCashPaymentsEnabled && (form.cashPaymentsEnabled === 'true' || form.cashPaymentsEnabled === 'false') ? stringToBoolean(form.cashPaymentsEnabled) : undefined,
                    prio: this.state.changePrioEnabled ? parseInt(form.prio) : undefined,
                    banned: this.state.changeBanned && (form.banned === 'true' || form.banned === 'false') ? stringToBoolean(form.banned) : undefined,
                });
                this.setState({ submitting: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    return;
                }
                if (response.data.failed.length > 0) {
                    alert(`Failed to change for ${response.data.failed.length} drivers (${pretty(response.data.failed)})`);
                }
                this.props.onDriversChanged && this.props.onDriversChanged();
                history.goBack();
            };

            render() {
                const { classes } = this.props;
                return (
                    <Dialog aria-labelledby='drivers-change-dialog-title' fullWidth scroll='paper' open={this.state.open} onClose={this.handleClose} onExited={this.handleExited}>
                        <Form
                            onSubmit={this.onSubmit}
                            initialValues={{}}
                            render={({ handleSubmit, submitting, pristine, values }) => (
                                <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                                    <DialogTitle id='drivers-change-dialog-title'>{translate('Bulk Change Drivers').toUpperCase()}</DialogTitle>
                                    <DialogContent>{this.renderDialogContent()}</DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleClose} disabled={this.state.submitting}>
                                            {translate('Cancel').toUpperCase()}
                                        </Button>
                                        <Button color='primary' type='submit' disabled={this.state.submitting}>
                                            {this.state.submitting ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                                        </Button>
                                    </DialogActions>
                                    {this.state.submitting && <LinearProgress className={classes.linearProgress} />}
                                </form>
                            )}
                        />
                    </Dialog>
                );
            }

            handleEnableBanControls = (checked: any) => {
                const password = prompt(translate('Password'));
                if (password !== '1212') return alert('Wrong Password');
                this.setState({ changeBanned: checked });
            };

            renderDialogContent() {
                return (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={1}>
                                <Checkbox
                                    name='changeCity'
                                    checked={this.state.changeCity}
                                    onChange={(event, checked) => this.setState({ changeCity: checked })}
                                    color='primary'
                                    style={{ padding: 0, paddingTop: 12 }}
                                />
                            </Grid>
                            <Grid item xs={11}>
                                <RadioGroup row>
                                    <Grid item xs={12}>
                                        <Field
                                            name='city'
                                            label={translate('City')}
                                            component={Select}
                                            formControlProps={{ fullWidth: true }}
                                            fullWidth
                                            disabled={!this.state.changeCity || this.state.submitting}
                                        >
                                            <MenuItem value=''>&nbsp;</MenuItem>
                                            {Object.values(Cities).map((city: any) => (
                                                <MenuItem value={city} key={city}>
                                                    {translate(city)}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={1}>
                                <Checkbox
                                    name='changeVerified'
                                    checked={this.state.changeVerified}
                                    onChange={(event, checked) => this.setState({ changeVerified: checked })}
                                    color='primary'
                                    style={{ padding: 0, paddingTop: 12 }}
                                />
                            </Grid>
                            <Grid item xs={11}>
                                <RadioGroup row>
                                    <FormControlLabel
                                        label='Not Verified'
                                        control={<Field name='verified' component={Radio} type='radio' color='primary' value='false' disabled={!this.state.changeVerified || this.state.submitting} />}
                                    />
                                    <FormControlLabel
                                        label='Verified'
                                        control={<Field name='verified' component={Radio} type='radio' color='primary' value='true' disabled={!this.state.changeVerified || this.state.submitting} />}
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={1}>
                                <Checkbox
                                    name='changeAppPaymentsEnabled'
                                    checked={this.state.changeAppPaymentsEnabled}
                                    onChange={(event, checked) => this.setState({ changeAppPaymentsEnabled: checked })}
                                    color='primary'
                                    style={{ padding: 0, paddingTop: 12 }}
                                />
                            </Grid>
                            <Grid item xs={11}>
                                <RadioGroup row>
                                    <FormControlLabel
                                        label='No App Payments'
                                        control={
                                            <Field
                                                name='appPaymentsEnabled'
                                                component={Radio}
                                                type='radio'
                                                color='primary'
                                                value='false'
                                                disabled={!this.state.changeAppPaymentsEnabled || this.state.submitting}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label='App Payments'
                                        control={
                                            <Field
                                                name='appPaymentsEnabled'
                                                component={Radio}
                                                type='radio'
                                                color='primary'
                                                value='true'
                                                disabled={!this.state.changeAppPaymentsEnabled || this.state.submitting}
                                            />
                                        }
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={1}>
                                <Checkbox
                                    name='changeCashPaymentsEnabled'
                                    checked={this.state.changeCashPaymentsEnabled}
                                    onChange={(event, checked) => this.setState({ changeCashPaymentsEnabled: checked })}
                                    color='primary'
                                    style={{ padding: 0, paddingTop: 12 }}
                                />
                            </Grid>
                            <Grid item xs={11}>
                                <RadioGroup row>
                                    <FormControlLabel
                                        label='No Cash Payments'
                                        control={
                                            <Field
                                                name='cashPaymentsEnabled'
                                                component={Radio}
                                                type='radio'
                                                color='primary'
                                                value='false'
                                                disabled={!this.state.changeCashPaymentsEnabled || this.state.submitting}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label='Cash Payments'
                                        control={
                                            <Field
                                                name='cashPaymentsEnabled'
                                                component={Radio}
                                                type='radio'
                                                color='primary'
                                                value='true'
                                                disabled={!this.state.changeCashPaymentsEnabled || this.state.submitting}
                                            />
                                        }
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={1}>
                                <Checkbox
                                    name='changePrioEnabled'
                                    checked={this.state.changePrioEnabled}
                                    onChange={(event, checked) => this.setState({ changePrioEnabled: checked })}
                                    color='primary'
                                    style={{ padding: 0, paddingTop: 12 }}
                                />
                            </Grid>
                            <Grid item xs={11}>
                                <Grid item xs={12}>
                                    <Field
                                        name='prio'
                                        label={translate('Prio')}
                                        helperText={translate(
                                            'Prio represents the delay the driver has to wait before he gets a delivery request. If set to 0 the driver has the highest priority and will receive delivery request immediately when we start looking for drivers. If set to 20 the driver will receive the delivery request 20 seconds after we start looking for drivers.'
                                        )}
                                        component={TextField}
                                        fullWidth
                                        disabled={!this.state.changePrioEnabled || this.state.submitting}
                                        type='number'
                                        validate={combine(integer, greaterThanOrEqualToZero)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                <Checkbox
                                    name='changeBanned'
                                    checked={this.state.changeBanned}
                                    onChange={(event, checked) => this.handleEnableBanControls(checked)}
                                    color='primary'
                                    style={{ padding: 0, paddingTop: 12 }}
                                />
                            </Grid>
                            <Grid item xs={11}>
                                <RadioGroup row>
                                    <FormControlLabel
                                        label='Not Banned'
                                        control={<Field name='banned' component={Radio} type='radio' color='primary' value='false' disabled={!this.state.changeBanned || this.state.submitting} />}
                                    />
                                    <FormControlLabel
                                        label='Banned'
                                        control={<Field name='banned' component={Radio} type='radio' color='primary' value='true' disabled={!this.state.changeBanned || this.state.submitting} />}
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderReceivers()}
                            </Grid>
                        </Grid>

                        <Grid container justify='space-around'>
                            <Grid item>
                                <Spacing units={8} />
                                {this.state.errorMessage && (
                                    <Typography display='block' color='error'>
                                        {this.state.errorMessage}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </>
                );
            }

            renderReceivers() {
                if (this.state.drivers.length === 0) {
                    return '';
                }
                if (this.state.drivers.length <= 20) {
                    return `Bulk change driver ${this.state.drivers.map((driver: any) => driver.name || driver.driverId.substring(0, 8) + '...').join(', ')}`;
                }
                return `This bulk change will change driver ${this.state.drivers
                    .slice(0, 20)
                    .map((driver: any) => driver.name || driver.driverId.substring(0, 8) + '...')
                    .join(', ')} + ${this.state.drivers.length - 5} more`;
            }
        }
    )
);

const INITIAL_STATE = {
    open: false,
    drivers: [],
    submitting: false,
    changeCity: false,
    changeVerified: false,
    changeAppPaymentsEnabled: false,
    changeCashPaymentsEnabled: false,
    changePrioEnabled: false,
    changeBanned: false,
} as const;

type Props = {
    classes: any;
    location: {
        pathname: string;
        state: {
            drivers: Array<{
                driverId: DriverId;
                name: string;
            }>;
        };
    };
    onDriversChanged?: any;
    path: string;
};

type State = {
    open: boolean;
    submitting: boolean;
    drivers: Array<{
        driverId: DriverId;
        name: string;
    }>;
    changeCity: boolean;
    changeVerified: boolean;
    changeAppPaymentsEnabled: boolean;
    changeCashPaymentsEnabled: boolean;
    changePrioEnabled: boolean;
    changeBanned: boolean;
    errorMessage?: string;
};
