/**
 * @prettier
 */
import { pidedirectouploadApi } from 'src/api/pidedirectouploadApi';
import type { ImageType } from 'src/constants/ImageType';
import type { PrivateImageId } from 'src/types/Id';

export async function uploadPrivateImage(params: UploadPrivateImageParams): Promise<UploadPrivateImageResult> {
    try {
        const uploadRequestResponse = await pidedirectouploadApi.resources.requestUpload({ fileName: params.fileName });
        if (!uploadRequestResponse.ok) {
            return {
                privateImageId: uploadRequestResponse,
                error: true,
            };
        }

        const s3Response = await fetch(uploadRequestResponse.data, {
            method: 'PUT',
            body: params.imageBlob,
        });
        if (!s3Response.ok) {
            return {
                privateImageId: s3Response,
                error: true,
            };
        }

        const uploadResponse = await pidedirectouploadApi.resources.upload({ fileName: params.fileName, imageType: params.imageType });
        if (!uploadResponse.ok) {
            return {
                privateImageId: uploadResponse,
                error: true,
            };
        }

        return {
            privateImageId: uploadResponse.data,
            error: false,
        };
    } catch (e) {
        return {
            error: true,
        };
    }
}

export type UploadPrivateImageParams = {
    fileName: string;
    imageBlob: Blob;
    type: string;
    imageType: ImageType;
};

type UploadPrivateImageResult = {
    privateImageId?: PrivateImageId | any;
    error: boolean;
};
