/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import * as React from 'react';
import { useState } from 'react';
import { FormSmsServiceProviderSelect } from 'src/components/form/FormSmsServiceProviderSelect';
import { translate } from 'src/i18n/translate';
import { useAskForPassword } from 'src/utils/react/useAskForPassword';
import { useSelector } from 'src/utils/react/useSelector';

export function SmsServiceProviderSetting(): React.ReactElement | null {
    const classes = useStyles();
    const askForPassword = useAskForPassword();

    const [disabled, setDisabled] = useState(true);

    const adminUser = useSelector((state) => state.authentication.adminUser);

    const handleEnableSetting = async () => {
        const rightPassword = await askForPassword({ password: '9021' });
        if (!rightPassword) return;

        setDisabled(false);
    };

    if (!adminUser) return null;

    return (
        <div className={classes.container}>
            <FormSmsServiceProviderSelect name='customerAuthenticationSmsServiceProvider' label={translate('Customer authentication SMS service provider')} disabled={disabled} />
            <div>
                <Button onClick={handleEnableSetting} variant='secondary'>
                    {translate('Enable')}
                </Button>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        alignItems: 'flex-end',
        width: '100%',
    },
}));
