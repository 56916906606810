/**
 * @prettier
 */
import { Button } from '@pidedirecto/ui';
import * as React from 'react';
import { useState } from 'react';
import { translate } from 'src/i18n/translate';
import { PlannedPosOrderDialog } from 'src/scenes/letseatmanager/pos/posMenu/posCart/PlannedPosOrderDialog';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { isPlannedPickupTime } from 'src/utils/pickupTimeType/isPlannedPickupTime';
import { useSelector } from 'src/utils/react/useSelector';

export function PlannedPosOrderButton(): React.ReactElement | null {
    const orderType = useSelector((state) => state.pos.orderType);
    const pickupTimeType = useSelector((state) => state.pos.pickupTimeType);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const plannedOrdersEnabled = useSelector((state) => state.app.restaurant.plannedOrdersEnabled);

    const [schedulePosOrderDialogState, setSchedulePosOrderDialogState] = useState({ open: false });

    const openSchedulePosOrderDialog = () => setSchedulePosOrderDialogState({ open: true });

    const closeSchedulePosOrderDialog = () => setSchedulePosOrderDialogState({ open: false });

    if (!isTakeAwayOrder(orderType) || !plannedOrdersEnabled || !!pendingOrder || isPlannedPickupTime(pickupTimeType)) return null;

    return (
        <>
            <PlannedPosOrderDialog open={schedulePosOrderDialogState.open} onClose={closeSchedulePosOrderDialog} />
            <Button variant='secondary' onClick={openSchedulePosOrderDialog}>
                {translate('Schedule order')}
            </Button>
        </>
    );
}
