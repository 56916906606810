/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import type { SalesByPromoCodeByDay } from 'src/api/letseatmanager/restaurantDashboard/getPromoCodeRestaurantStatisticsApi';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { toHumanizedOrderId } from 'src/utils/order/toHumanizedOrderId';

export function SalesByPromoCodeTable({ loading, salesByPromoCodeByDayData, fileName }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const salesByPromoCodeByDay =
        salesByPromoCodeByDayData?.map((saleByPromoCode: SalesByPromoCodeByDay) => ({
            createdAt: saleByPromoCode.createdAt,
            customerName: saleByPromoCode.customerName,
            mobileNumber: saleByPromoCode.mobileNumber,
            promoCode: saleByPromoCode.promoCode,
            orderId: saleByPromoCode.orderId,
            restaurantName: saleByPromoCode.restaurantName,
            amount: formatAsCurrencyNumber(saleByPromoCode.amount),
            discount: formatAsCurrencyNumber(saleByPromoCode.discount),
            total: formatAsCurrencyNumber(saleByPromoCode.total),
        })) || [];
    const hasPromoCodeSales = salesByPromoCodeByDay.length > 0;

    const promoCodeColumns = [
        {
            name: 'createdAt',
            label: translate('Date'),
            options: {
                filter: true,
            },
        },
        {
            name: 'customerName',
            label: translate('Name'),
            options: {
                filter: true,
            },
        },
        {
            name: 'mobileNumber',
            label: translate('Phone Number'),
            options: {
                filter: false,
            },
        },
        {
            name: 'promoCode',
            label: translate('Promo Code'),
            options: {
                filter: false,
            },
        },
        {
            name: 'orderId',
            label: translate('Order Id'),
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex: number) => {
                    const orderId = salesByPromoCodeByDayData[dataIndex].orderId;
                    return `#${toHumanizedOrderId(orderId)}`;
                },
            },
        },
        {
            name: 'restaurantName',
            label: translate('Restaurant Name'),
            options: {
                filter: false,
            },
        },
        {
            name: 'amount',
            label: translate('Amount'),
            options: {
                filter: false,
            },
        },
        {
            name: 'discount',
            label: translate('Discount'),
            options: {
                filter: false,
            },
        },
        {
            name: 'total',
            label: translate('Total Sales'),
            options: {
                filter: false,
            },
        },
    ];

    if (!hasPromoCodeSales) return null;

    return (
        <ReportSection title={translate('Sales by promoCode per day')} loading={loading}>
            <div className={(classes as any).infoContainer}>
                <div className={classes.container}>
                    <Table
                        title={undefined}
                        data={salesByPromoCodeByDay}
                        columns={promoCodeColumns}
                        options={{
                            tableBodyMaxHeight: '400px',
                            responsive: 'standard',
                            selectableRows: 'none',
                            filter: false,
                            rowsPerPage: 100,
                            viewColumns: false,
                            print: false,
                            downloadOptions: {
                                filename: fileName,
                            },
                        }}
                    />
                </div>
            </div>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    graphContainer: {
        width: '100%',
        height: '45vh',
    },
    kpisContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '95%',
        height: '30%',
        justifyContent: 'space-around',
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100%',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            flexWrap: 'nowrap',
        },
    },
}));

type Props = {
    loading: boolean;
    salesByPromoCodeByDayData: Array<SalesByPromoCodeByDay>;
    fileName: string;
};
