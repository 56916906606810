/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { createReleaseNotesApi } from 'src/api/letseatadmin/releaseNotes/createReleaseNotesApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function CreateReleaseNotesDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();

    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const handleSubmit = async (form: any) => {
        setLoading(true);
        const response = await createReleaseNotesApi({
            title: form.title,
            releaseNotesUrl: form.releaseNotesUrl,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
        onClose?.();
    };

    return (
        <Dialog open={open} title={translate('New note')} loading={loading}>
            <Form form={form} onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name={'title'} label={translate('Title')} required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField name={'releaseNotesUrl'} label={translate('Url')} helperText={translate('Make sure to save the link with the https protocol')} required />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button secondary onClick={handleClose}>
                        {translate('Cancel')}
                    </Button>
                    <Button type={'submit'}>{translate('Create')}</Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    onClose: any;
    onSuccess?: any;
};
