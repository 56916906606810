/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentTerminalProvider } from 'src/constants/PaymentTerminalProvider';
import type { OcaPaymentTerminalPosId, OcaPaymentTerminalSystemId, OcaPaymentTerminalTerminalGroup, PaymentTerminalId, QpayTerminalDeviceId } from 'src/types/Id';
import type { PaymentTerminalVm } from 'src/types/PaymentTerminalVm';

export async function changePaymentTerminalApi(request: ChangePaymentTerminalApiRequest): ApiSauceResponse<PaymentTerminalVm> {
    return letseatmanagerApiMethod('paymentTerminal/changePaymentTerminalApi', request);
}

export type ChangePaymentTerminalApiRequest = {
    paymentTerminalId: PaymentTerminalId;
    deviceName: string;
    paymentTerminalProvider: PaymentTerminalProvider;
    qpayTerminalDeviceId?: QpayTerminalDeviceId;
    ocaPaymentTerminalPosId?: OcaPaymentTerminalPosId;
    ocaPaymentTerminalSystemId?: OcaPaymentTerminalSystemId;
    ocaPaymentTerminalTerminalGroup?: OcaPaymentTerminalTerminalGroup;
};
