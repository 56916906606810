/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { app2 } from 'src/app2';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { RoutePaths } from 'src/constants/RoutePath';
import { CustomerStatsPage } from 'src/scenes/letseatadmin/statistics/CustomerStatsPage';
import { OrderStatsPage } from 'src/scenes/letseatadmin/statistics/OrderStatsPage';
import { PaymentStatsPage } from 'src/scenes/letseatadmin/statistics/PaymentStatsPage';
import { PeriodScale, PeriodScales, XAxisScale, XAxisScales, YAxisScale, YAxisScales } from 'src/scenes/letseatadmin/statistics/TimeLineBarChart';
import type { CompanyId, PromoCodeId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useMeasurements } from 'src/utils/react/useMeasurements';

export function StatisticsPage(): React.ReactElement {
    const [statistics, setStatistics] = useState([] as StatisticsVm);
    const [loading, setLoading] = useState(false);
    const [period, setPeriod] = useState<PeriodScale>(PeriodScales.TWO_WEEKS);
    const [xAxis, setXAxis] = useState<XAxisScale>(XAxisScales.DAY);
    const [yAxis, setYAxis] = useState<YAxisScale>(YAxisScales.AMOUNT);

    const setTitle = useAction(app2.actions.setTitle);
    const location = useLocation();
    const { measurements, getRef } = useMeasurements();

    useEffect(() => {
        setTitle('Statistics');
        const load = async () => {
            setLoading(true);
            const response = await letseatmanagerApiDeprecated.admin.statistics.fetch();
            if (!response.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            setLoading(false);
            setStatistics(response.data);
        };
        load();
    }, []);

    return (
        <div style={{ position: 'relative', overflow: 'visible' }}>
            <div>
                <div style={{ position: 'fixed', zIndex: 99999 }} ref={getRef('togglers')}>
                    <Grid container spacing={1}>
                        <PeriodToggler
                            period={period}
                            onChange={(event: any, newPeriod: PeriodScale) => {
                                setPeriod(newPeriod);
                                if (newPeriod === PeriodScales.TWO_WEEKS) {
                                    setXAxis(XAxisScales.DAY);
                                }
                                if (newPeriod === PeriodScales.THREE_MONTHS) {
                                    setXAxis(XAxisScales.WEEK);
                                }
                                if (newPeriod === PeriodScales.A_YEAR) {
                                    setXAxis(XAxisScales.MONTH);
                                }
                            }}
                        />
                        <Grid item>
                            <YAxisToggler yAxis={yAxis} onChange={(event: any, value: YAxisScale) => setYAxis(value)} disabled={location.pathname === StatisticsTab.CUSTOMER_STATS} />
                        </Grid>
                        <Grid item>
                            <XAxisToggler xAxis={xAxis} onChange={(event: any, newXAxis: XAxisScale) => setXAxis(newXAxis)} period={period} />
                        </Grid>
                    </Grid>
                </div>
                <div style={{ height: (measurements.togglers?.height ?? 0) + 8 }} />
                <UpdatingContentProgress loading={loading} />
                <Paper>
                    <Tabs value={location.pathname === RoutePaths.ADMIN_STATISTICS ? RoutePaths.ADMIN_STATISTICS_CUSTOMERS : location.pathname} indicatorColor='primary' textColor='primary'>
                        <Tab
                            label='Customer Stats'
                            component={Link}
                            to={{
                                pathname: RoutePaths.ADMIN_STATISTICS_CUSTOMERS,
                                search: location.search,
                            }}
                            value={StatisticsTab.CUSTOMER_STATS}
                        />
                        <Tab
                            label='Order Stats'
                            component={Link}
                            to={{
                                pathname: RoutePaths.ADMIN_STATISTICS_ORDERS,
                                search: location.search,
                            }}
                            value={StatisticsTab.ORDER_STATS}
                        />
                        <Tab
                            label='Payment Stats'
                            component={Link}
                            to={{
                                pathname: RoutePaths.ADMIN_STATISTICS_PAYMENTS,
                                search: location.search,
                            }}
                            value={StatisticsTab.PAYMENT_STATS}
                        />
                    </Tabs>
                    <Switch>
                        <Route
                            path={[RoutePaths.ADMIN_STATISTICS, RoutePaths.ADMIN_STATISTICS_CUSTOMERS]}
                            exact
                            render={() => <CustomerStatsPage statistics={statistics} period={period} xAxis={xAxis} />}
                        />
                        <Route path={RoutePaths.ADMIN_STATISTICS_ORDERS} render={() => <OrderStatsPage statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />} />
                        <Route path={RoutePaths.ADMIN_STATISTICS_PAYMENTS} render={() => <PaymentStatsPage statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />} />
                    </Switch>
                </Paper>
            </div>
        </div>
    );
}

function PeriodToggler({ period, onChange }: { period: PeriodScale; onChange: any }) {
    return (
        <Grid item>
            <ToggleButtonGroup value={period} exclusive onChange={onChange} aria-label='text alignment'>
                <ToggleButton value={PeriodScales.A_YEAR} aria-label='left aligned'>
                    A Year
                </ToggleButton>
                <ToggleButton value={PeriodScales.THREE_MONTHS} aria-label='centered'>
                    3 Months
                </ToggleButton>
                <ToggleButton value={PeriodScales.TWO_WEEKS} aria-label='right aligned'>
                    2 Weeks
                </ToggleButton>
            </ToggleButtonGroup>
        </Grid>
    );
}

function YAxisToggler({ yAxis, onChange, disabled }: { yAxis: YAxisScale; onChange: any; disabled: boolean }) {
    return (
        <ToggleButtonGroup value={yAxis} exclusive onChange={onChange} aria-label='text alignment'>
            <ToggleButton value={YAxisScales.ORDERS} disabled={disabled} aria-label='left aligned'>
                Orders
            </ToggleButton>
            <ToggleButton value={YAxisScales.AMOUNT} disabled={disabled} aria-label='centered'>
                Amount
            </ToggleButton>
            <ToggleButton value={YAxisScales.AVERAGE} disabled={disabled} aria-label='right aligned'>
                Average
            </ToggleButton>
        </ToggleButtonGroup>
    );
}

function XAxisToggler({ xAxis, period, onChange }: { xAxis: XAxisScale; period: PeriodScale; onChange: any }) {
    return (
        <ToggleButtonGroup value={xAxis} exclusive onChange={onChange} aria-label='text alignment'>
            <ToggleButton value={XAxisScales.MONTH} aria-label='left aligned' disabled={period === PeriodScales.THREE_MONTHS || period === PeriodScales.TWO_WEEKS}>
                Month
            </ToggleButton>
            <ToggleButton value={XAxisScales.WEEK} aria-label='centered' disabled={period === PeriodScales.TWO_WEEKS}>
                Week
            </ToggleButton>
            <ToggleButton value={XAxisScales.DAY} aria-label='right aligned'>
                Day
            </ToggleButton>
        </ToggleButtonGroup>
    );
}

export type StatisticsVm = Array<StatisticVm>;

export type StatisticVm = {
    date: string; // YYYY-MM-DD,
    customer: CustomerStatistic;
    order: OrderStatistic;
    createdAt: Date;
    modifiedAt: Date;
};

export type CustomerStatistic = {
    downloads: number;
    signedUp: number;
    paying: number;
    active: number;
    inactive: number;
    addedCreditCard: number;
    pushAllowed: number;
    pushDenied: number;
    customerActivity: Array<number>;
};

export type OrderStatistic = {
    new: CountValue & OrderDetailsStatistic;
    accepted: CountValue & OrderDetailsStatistic;
    completed: CountValue & OrderDetailsStatistic;
    cancelled: CountValue & OrderDetailsStatistic;
    rejected: CountValue & {
        newRejected: CountValue;
        acceptedRejected: CountValue;
        closingSoon: CountValue;
        problemInRestaurant: CountValue;
        soldOut: CountValue;
        incorrectPrice: CountValue;
        other: CountValue;
    } & OrderDetailsStatistic;
    paymentFailed: CountValue & {
        unknown: CountValue;
        qpay: CountValue;
        conekta: CountValue;
        stripe: CountValue;
    } & OrderDetailsStatistic;
    refunded: CountValue & {
        credits: CountValue;
        card: CountValue;
        payroll: CountValue;
    };
};

export type OrderDetailsStatistic = {
    app: CountValue;
    store: CountValue;
    tableOrder: CountValue;
    selfServiceOrder: CountValue;
    takeAwayOrder: CountValue;
    pickupStationOrder: CountValue;
    subtotal: number;
    productDiscount: CountValue;
    promoCodeDiscount: CountValue & {
        promoCodes: Partial<
            Record<
                PromoCodeId,
                {
                    code: string;
                } & CountValue
            >
        >;
    };
    companyCredits: CountValue & {
        companies: Partial<
            Record<
                CompanyId,
                {
                    companyName: string;
                } & CountValue
            >
        >;
    };
    letsEatCredits: CountValue;
    giftCredits: CountValue;
    nonGiftCredits: CountValue;
    total: number;
    paidWithCard: CountValue & {
        unknown: CountValue;
        qpay: CountValue;
        conekta: CountValue;
        stripe: CountValue;
    };
    paidWithPayroll: CountValue & {
        companies: Partial<
            Record<
                CompanyId,
                {
                    companyName: string;
                } & CountValue
            >
        >;
    };
    createdAtByHour: Array<CountValue | undefined>;
    pickupTimeByHour: Array<CountValue | undefined>;
};

export type CountValue = {
    count: number;
    value: number;
};

const StatisticsTab = Object.freeze({
    CUSTOMER_STATS: RoutePaths.ADMIN_STATISTICS_CUSTOMERS,
    ORDER_STATS: RoutePaths.ADMIN_STATISTICS_ORDERS,
    PAYMENT_STATS: RoutePaths.ADMIN_STATISTICS_PAYMENTS,
});
