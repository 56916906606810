/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { UserRoleId } from 'src/types/Id';

export async function removeUserRoleApi(request: RemoveUserRoleApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('userRole/removeUserRoleApi', request);
}

type RemoveUserRoleApiRequest = {
    userRoleId?: UserRoleId;
};
