/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuItemId, RecipeId, RestaurantId } from 'src/types/Id';

export async function getMenuItemsRecipeOptionsApi(request: GetMenuItemsRecipeOptionsApiRequest): ApiSauceResponse<Array<MenuItemRecipeOption>> {
    return letseatmanagerApiMethod('options/getMenuItemsRecipeOptionsApi', request);
}

type GetMenuItemsRecipeOptionsApiRequest = {
    restaurantId: RestaurantId;
    recipeId?: RecipeId;
};

export type MenuItemRecipeOption = {
    label: string;
    value: MenuItemId;
};
