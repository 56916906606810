/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { RestaurantCustomerVm } from 'src/types/RestaurantCustomerVm';

export async function findRestaurantCustomersOptionsApi(request: FetchCustomersByPhoneNumberApiRequest): ApiSauceResponse<Array<RestaurantCustomerVm>> {
    return letseatmanagerApiMethod('restaurantCustomer/findRestaurantCustomersOptionsApi', request);
}

export type FetchCustomersByPhoneNumberApiRequest = {
    restaurantId: RestaurantId;
    mobileNumber: string;
};
