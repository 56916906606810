/**
 * @prettier
 */
import { Box, Grid, makeStyles } from '@material-ui/core';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function ReportSectionEmptyState(): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Grid container>
                <Grid item xs={12}>
                    <Box className={classes.notFoundContainer}>
                        <EqualizerIcon fontSize={'large'} />
                        <p className={classes.notFoundMessage}>{translate('Not found information for this date range')}</p>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    notFoundContainer: {
        fontSize: 27,
        textAlign: 'center',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    notFoundMessage: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        textAlign: 'center',
    },
}));
