/**
 * @prettier
 */
import * as React from 'react';
import { DeprecatedOrdersPage } from 'src/scenes/letseatmanager/orders/DeprecatedOrdersPage';
import { OrdersPage } from 'src/scenes/letseatmanager/OrdersPage';
import { useUserType } from 'src/services/restaurant/useUserType';
import { useSelector } from 'src/utils/react/useSelector';
import { isBrandUser } from 'src/utils/user/isBrandUser';

export function TemporalOrdersPage(): React.ReactElement {
    const userType = useUserType();

    const newOrdersPageEnabled = useSelector((state) => state.app.restaurant.newOrdersPageEnabled);
    const restaurants = useSelector((state) => state.app.restaurants);

    const someRestaurantHasNewOrdersPageEnabled = restaurants.some((restaurant) => restaurant.newOrdersPageEnabled);
    const renderNewOrdersPageEnabled = (someRestaurantHasNewOrdersPageEnabled && isBrandUser(userType)) || newOrdersPageEnabled;

    if (renderNewOrdersPageEnabled) return <OrdersPage />;

    return <DeprecatedOrdersPage />;
}
