/**
 * @prettier
 */
import { useState } from 'react';
import * as React from 'react';
import { removeModifierGroupApi } from 'src/api/letseatmanager/modifierGroup/removeModifierGroupApi';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import { translate } from 'src/i18n/translate';
import type { ModifierGroupVm } from 'src/types/ModifierGroupVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function RemoveModifierGroupDialog({ open, modifierGroup, onClose, onSuccess }: Props): React.ReactElement {
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const removeModifierGroup = async () => {
        if (!modifierGroup?.modifierGroupId) return;

        setLoading(true);
        const response = await removeModifierGroupApi({
            modifierGroupId: modifierGroup?.modifierGroupId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
    };

    return (
        <RemoveDialog
            open={open}
            title={translate('Remove modifier group')}
            subtitle={translate('You are about to remove the modifier group @modifierGroupName, are you sure you want to continue?', { modifierGroupName: modifierGroup?.name })}
            onSubmit={removeModifierGroup}
            onClose={handleClose}
            disabled={loading}
        />
    );
}

type Props = {
    open: boolean;
    modifierGroup?: ModifierGroupVm;
    onClose: any;
    onSuccess?: any;
};
