/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { useContext } from 'react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';

export function FormRadio({ label, value, disabled, classes, onClick }: Props): React.ReactElement {
    const {
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);

    const _classes = useStyles();

    return (
        <FormControlLabel
            label={label}
            classes={{ label: classes?.label ?? _classes.label }}
            control={<Radio className={_classes.radio} color={'primary'} value={value} disabled={isSubmitting || disabled || formContext.disabled} />}
            disabled={isSubmitting || disabled || formContext.disabled}
            onClick={onClick}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    label: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        marginBottom: 6,
        color: theme.palette.text.primary,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 0,
        },
    },
    radio: {
        '&.MuiRadio-colorPrimary.Mui-checked': {
            color: theme.palette.surface.brandContrast,
        },
    },
}));

export type Props = {
    value: any;
    label?: string;
    disabled?: boolean;
    classes?: any;
    onClick?: any;
};
