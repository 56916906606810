/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useState } from 'react';
import { app2 } from 'src/app2';
import { translate } from 'src/i18n/translate';
import EmployeeDetails from 'src/scenes/letseatadmin/company/EmployeeDetails';
import CustomerGifts from 'src/scenes/letseatadmin/customer/CustomerGifts';
import type { EmployeeId } from 'src/types/Id';
import { fullName } from 'src/utils/customer/fullName';
import { useAction } from 'src/utils/react/useAction';
import { useParams } from 'src/utils/react/useParams';

export function EmployeePage(): React.ReactElement {
    const { employeeId } = useParams<{
        employeeId: EmployeeId;
    }>();
    const [customerId, setCustomerId] = useState(null);
    const setTitle = useAction(app2.actions.setTitle);

    const handleEmployeeChange = (employee: any) => {
        setTitle(`${translate('Employees')} > ${fullName(employee.firstName, employee.lastName)}`);
        setCustomerId(employee.customerId);
    };

    return (
        <Grid container spacing={5} style={{ paddingBottom: 40 }}>
            <Grid item xs={12}>
                <EmployeeDetails employeeId={employeeId} onEmployeeChange={handleEmployeeChange} />
            </Grid>
            {customerId && (
                <Grid item xs={12}>
                    <CustomerGifts customerId={customerId} />
                </Grid>
            )}
        </Grid>
    );
}
