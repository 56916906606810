/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { EditIcon } from '@pidedirecto/ui/icons';
import { memo } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { NoteIcon } from 'src/icons/NoteIcon';
import { posReducer } from 'src/reducers/posReducer';
import type { CartItemVm } from 'src/types/CartItemVm';
import { useAction } from 'src/utils/react/useAction';

export const PosCartItemNote: React.ComponentType<Props> = memo<Props>(({ item, isItemSent }: Props) => {
    const classes = useStyles();

    const openAddMenuItemNoteDialogState = useAction(posReducer.actions.openAddMenuItemNoteDialogState);
    const openChangeMenuItemNoteDialogState = useAction(posReducer.actions.openChangeMenuItemNoteDialogState);
    const addPosMenuItemNote = useAction(posReducer.actions.addPosMenuItemNote);

    const handleAddMenuItemNote = () => {
        if (isItemSent) return;

        openAddMenuItemNoteDialogState({
            onSuccess: (note: string) => {
                addPosMenuItemNote({
                    menuItemId: item.menuItemId,
                    key: item.key,
                    note,
                });
            },
        });
    };
    const handleEditMenuItemNote = () => {
        if (isItemSent) return;

        openChangeMenuItemNoteDialogState({
            note: item.note,
            onSuccess: (note: string) => {
                addPosMenuItemNote({
                    menuItemId: item.menuItemId,
                    key: item.key,
                    note,
                });
            },
        });
    };

    if (!!item.removedAt) return null;

    return (
        <div className={classes.spaceBetween}>
            {!item.note && (
                <Button classes={{ button: classes.button, disabled: classes.disabledButton }} text onClick={handleAddMenuItemNote} disabled={isItemSent}>
                    <NoteIcon width={14} />
                    {translate('Add Note')}
                </Button>
            )}
            {item.note && (
                <div className={classes.separatedLineText}>
                    <span className={classes.note}>{item.note}</span>
                    {!isItemSent && (
                        <Tooltip title={translate('Edit Note')}>
                            <EditIcon size={18} onClick={handleEditMenuItemNote} />
                        </Tooltip>
                    )}
                </div>
            )}
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    note: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    separatedLineText: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 8,
    },
    button: {
        fontSize: 14,
        height: 'fit-content',
        width: 'fit-content',
        color: theme.palette.icons.primary,
    },
    disabledButton: {
        backgroundColor: 'transparent !important',
    },
    spaceBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

type Props = {
    item: CartItemVm;
    isItemSent: boolean;
};
