/**
 * @prettier
 */
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import isString from 'lodash/isString';
import type { CountryCode } from 'src/types/Id';

export function formatAsNationalPhoneNumber<T extends string | undefined | null>(value: T, countryCode?: CountryCode): T {
    if (value === undefined || value === null || !isString(value)) {
        return value;
    }
    const parsedPhoneNumber = parsePhoneNumberFromString(value, countryCode as any);
    if (!parsedPhoneNumber) {
        return value.trim().replace(/\s+/g, ' ') as any;
    }

    return parsedPhoneNumber.format('NATIONAL') as T;
}
