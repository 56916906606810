/**
 * @prettier
 * @flow
 */
import { makeStyles } from '@material-ui/core/styles';
import { Button, Switch, Table } from '@pidedirecto/ui';
import { EditIcon, TrashCanIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useRef, useState } from 'react';
import { disableCustomAlertDialogApi } from 'src/api/letseatadmin/customAlertDialog/disableCustomAlertDialogApi';
import { enableCustomAlertDialogApi } from 'src/api/letseatadmin/customAlertDialog/enableCustomAlertDialogApi';
import { findCustomAlertDialogsApi } from 'src/api/letseatadmin/customAlertDialog/findCustomAlertDialogsApi';
import { removeCustomAlertDialogApi } from 'src/api/letseatadmin/customAlertDialog/removeCustomAlertDialogApi';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { CustomAlertDialogType } from 'src/constants/CustomAlertDialogType';
import { translate } from 'src/i18n/translate';
import ChangeCustomAlertDialog from 'src/scenes/letseatadmin/customAlertDialogs/ChangeCustomAlertDialog';
import CreateCustomAlertDialog from 'src/scenes/letseatadmin/customAlertDialogs/CreateCustomAlertDialog';
import { CustomAlertDialogVm } from 'src/types/CustomAlertDialogVm';
import { CustomAlertDialogId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useLoadApi } from 'src/utils/react/useLoadApi';

export default function CustomAlertDialogTable(): React.ReactElement {
    const classes = useStyles();
    const table = useRef<any>(null);

    const [loading, customAlertDialogs, load] = useLoadApi(findCustomAlertDialogsApi);

    const [openCreateAlertDialog, setOpenCreateAlertDialog] = useState(false);
    const [openChangeAlertDialog, setOpenChangeAlertDialog] = useState<{ open: boolean; customAlertDialog: CustomAlertDialogVm | undefined }>({ open: false, customAlertDialog: undefined });

    const columns = [
        {
            content: translate('Title'),
            id: 'title',
        },
        {
            content: translate('Text'),
            id: 'text',
        },
        {
            content: translate('Seconds to accept'),
            id: 'secondsToAccept',
        },
        {
            content: translate('Show alert type'),
            id: 'customAlertDialogType',
        },
        {
            content: translate('Enabled'),
            id: 'enabled',
        },
    ];

    const removeCustomAlertDialog = async (customAlertDialogId: CustomAlertDialogId) => {
        const response = await removeCustomAlertDialogApi({ customAlertDialogId: customAlertDialogId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
    };

    const openCreateCustomAlertDialog = () => {
        setOpenCreateAlertDialog(true);
    };

    const openChangeCustomAlertDialog = (customAlertDialog: CustomAlertDialogVm | undefined) => {
        if (!customAlertDialog) return;
        setOpenChangeAlertDialog({ open: true, customAlertDialog: customAlertDialog });
    };

    const closeDialogs = () => {
        setOpenCreateAlertDialog(false);
        setOpenChangeAlertDialog({ open: false, customAlertDialog: undefined });
    };

    const enableCustomAlertDialog = async (e: React.ChangeEvent<HTMLInputElement>, customAlerDialog: CustomAlertDialogVm) => {
        const isEnabled = e.target.checked;
        if (isEnabled) {
            const response = await enableCustomAlertDialogApi({ customAlertDialogId: customAlerDialog.customAlertDialogId });

            if (!response.ok) {
                return alertKnownErrorOrSomethingWentWrong(response);
            }
            load();
            return;
        }
        const response = await disableCustomAlertDialogApi({ customAlertDialogId: customAlerDialog.customAlertDialogId });

        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        load();
        return;
    };

    return (
        <div style={{ position: 'relative' }}>
            <UpdatingContentProgress loading={loading} />
            <CreateCustomAlertDialog onClose={closeDialogs} open={openCreateAlertDialog} onSuccess={() => load()} />
            <ChangeCustomAlertDialog onClose={closeDialogs} open={openChangeAlertDialog.open} onSuccess={() => load()} customAlertDialog={openChangeAlertDialog.customAlertDialog} />
            <Table
                /* @ts-ignore */
                ref={table}
                className={classes.table}
                title={translate('Alert Dialogs')}
                loading={loading}
                rows={
                    customAlertDialogs
                        ? customAlertDialogs?.map((customAlertDialog: CustomAlertDialogVm) => {
                              const row = {
                                  rowId: customAlertDialog.customAlertDialogId,
                                  title: customAlertDialog.title,
                                  text: customAlertDialog.text,
                                  secondsToAccept: customAlertDialog.secondsToAccept,
                                  customAlertDialogType: customAlertDialog.customAlertDialogType,
                                  enabled: <Switch checked={customAlertDialog.enabled} onChange={(e: React.ChangeEvent<HTMLInputElement>) => enableCustomAlertDialog(e, customAlertDialog)} />,
                              };
                              return row;
                          })
                        : []
                }
                rowsPerPage={100}
                columns={columns}
                toolbar={({ selectedRows }) => {
                    let selectedCustomAlertDialog: CustomAlertDialogVm | undefined;
                    if (selectedRows.length > 0) {
                        selectedCustomAlertDialog = customAlertDialogs.find((customAlertDialog) => customAlertDialog.customAlertDialogId === selectedRows[0]);
                    }
                    return (
                        <>
                            <RefreshToolbarButton onClick={() => load()} />
                            <CreateToolbarButton onClick={openCreateCustomAlertDialog} />
                            {selectedRows.length > 0 && (
                                <>
                                    <Button
                                        variant='icon'
                                        onClick={() => {
                                            openChangeCustomAlertDialog(selectedCustomAlertDialog);
                                        }}
                                        disabled={loading}
                                    >
                                        <EditIcon />
                                    </Button>
                                    <Button variant='icon' onClick={() => removeCustomAlertDialog(selectedRows[0])} disabled={loading}>
                                        <TrashCanIcon />
                                    </Button>
                                </>
                            )}
                        </>
                    );
                }}
                selectable
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

type RowType = {
    rowId: CustomAlertDialogId;
    title: string;
    text: string;
    secondsToAccept: string;
    customAlertDialogType: CustomAlertDialogType;
    enabled: boolean;
};
