/**
 * @prettier
 */
import { Grid, makeStyles, useTheme } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { getSupplyInventoryTransactionApi } from 'src/api/letseatmanager/inventory/getSupplyInventoryTransactionApi';
import { Button } from 'src/components/Button';
import { Text } from 'src/components/Text';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { InventoryReportId } from 'src/types/Id';
import { objectsToCsv } from 'src/utils/csv/objectsToCsv';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';
import { formatAsNumber } from 'src/utils/number/formatAsNumber';
import { useLoadApi } from 'src/utils/react/useLoadApi';

export function InventoryReport({ inventoryReportId, goToInventoryTable }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [loading, suppliesInventoried] = useLoadApi(
        getSupplyInventoryTransactionApi,
        {
            inventoryTransactionReportId: inventoryReportId!,
        },
        { initialValue: [], requiredValues: [inventoryReportId] }
    );

    const handleClickDownloadCsv = () => {
        if (!suppliesInventoried.length) {
            alert(translate('There are no supplies resume to download'));
            return;
        }
        const rows = suppliesInventoried.map((supplyInventoried) => {
            const row = {
                supplySku: supplyInventoried.supply.sku,
                supplyName: supplyInventoried.supply.name,
                supplyCost: supplyInventoried.supply.fixedCost,
                supplyActualQuantity: formatAsNumber(supplyInventoried.quantityInventoried ?? 0),
                supplyTheoreticalQuantity: formatAsNumber(BigNumber(supplyInventoried.expectedQuantity ?? 0).toFixed(2)),
                supplyDifference: supplyInventoried.difference,
                supplyDifferenceValue: formatAsNumber(
                    BigNumber(supplyInventoried.difference)
                        .multipliedBy(supplyInventoried.supply.fixedCost || 0)
                        .toFixed(2)
                ),
            } as const;
            return row;
        });
        const csv = objectsToCsv(rows);
        downloadTextInFile(csv, 'inventory_report.csv');
        return;
    };

    return (
        <div className={classes.container}>
            <div className={classes.form}>
                <UpdatingContentProgress loading={loading} top right />
                <Grid className={classes.header}>
                    <Text title>{translate('Report')}</Text>
                    <Button secondary onClick={handleClickDownloadCsv}>
                        <CloudDownloadIcon color={theme.palette.primary.main as any} />
                    </Button>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={2}>
                        <span className={classes.subtitle}>{translate('SKU')}</span>
                    </Grid>
                    <Grid item xs={2}>
                        <span className={classes.subtitle}>{translate('Product')}</span>
                    </Grid>
                    <Grid item xs={2}>
                        <span className={classes.subtitle}>{translate('Actual Inventory')}</span>
                    </Grid>
                    <Grid item xs={2}>
                        <span className={classes.subtitle}>{translate('Theoretical Inventory')}</span>
                    </Grid>
                    <Grid item xs={2}>
                        <span className={classes.subtitle}>{translate('Difference')}</span>
                    </Grid>
                    <Grid item xs={2}>
                        <span className={classes.subtitle}>{translate('Difference Value')}</span>
                    </Grid>
                    {suppliesInventoried.map((supplyInventoried, index) => {
                        const mainUnit = supplyInventoried.supply.buyUnits.find((buyUnit) => buyUnit.isMainUnit);

                        const unitToShow = mainUnit?.unit ?? supplyInventoried.supply.sellUnit;

                        return (
                            <div key={supplyInventoried.inventoryTransactionId} className={classes.formRow}>
                                <Grid item xs={2}>
                                    <span className={classes.text}>{supplyInventoried.supply.sku}</span>
                                </Grid>
                                <Grid item xs={2}>
                                    <Tooltip title={`${translate('Cost')}: ${formatAsCurrencyNumber(supplyInventoried.supply.fixedCost)}`}>
                                        <span className={classes.text}>{supplyInventoried.supply.name}</span>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={2}>
                                    <span className={classes.text}>
                                        {formatAsNumber(supplyInventoried.quantityInventoried ?? 0)} {translate(`UnitOfMeasurements.${unitToShow}`)}
                                    </span>
                                </Grid>
                                <Grid item xs={2}>
                                    <span className={classes.text}>
                                        {formatAsNumber(BigNumber(supplyInventoried.expectedQuantity ?? 0).toFixed(2))} {translate(`UnitOfMeasurements.${unitToShow}`)}
                                    </span>
                                </Grid>
                                <Grid item xs={2}>
                                    <span className={classes.text}>{supplyInventoried.difference}</span>
                                </Grid>
                                <Grid item xs={2}>
                                    <span className={classes.text}>
                                        {formatAsCurrencyNumber(
                                            BigNumber(supplyInventoried.difference)
                                                .multipliedBy(supplyInventoried.supply.fixedCost || 0)
                                                .toFixed(2)
                                        )}
                                    </span>
                                </Grid>
                            </div>
                        );
                    })}
                    <Grid item xs={12}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={goToInventoryTable}>{translate('Go to Inventory')}</Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    backButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        marginBottom: 50,
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
    },
    formRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
        '& .MuiGrid-item': {
            padding: 12,
        },
    },
    form: {
        backgroundColor: 'white',
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '65vh',
        margin: '0 auto',
        border: `1px solid ${theme.palette.secondary.dark}`,
        borderRadius: 10,
        padding: 20,
        position: 'relative',
    },
}));

type Props = {
    inventoryReportId?: InventoryReportId;
    goToInventoryTable: any;
};
