/**
 * @prettier
 */
import * as React from 'react';
import { FormTextField } from 'src/components/form/FormTextField';
import type { AutoCompleteInputProp, Variant } from 'src/components/form/FormTypes';
import { translate } from 'src/i18n/translate';
import { trim } from 'src/utils/string/trim';

export function FormAddress({
    name,
    label,
    placeholder,
    helperText,
    defaultValue,
    autoComplete = 'off',
    upperCase,
    disabled,
    required,
    variant,
    fullWidth,
    InputProps,
    inputProps,
}: Props): React.ReactElement {
    return (
        <FormTextField
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            autoComplete={autoComplete}
            upperCase={upperCase}
            disabled={disabled}
            required={required}
            validate={{
                rfc: (value: any) => (trim(value)?.length < 1 ? translate('This field must not be empty') : true),
            }}
            variant={variant}
            InputProps={InputProps}
            inputProps={inputProps}
        />
    );
}

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    autoComplete?: AutoCompleteInputProp;
    upperCase?: boolean;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    fullWidth?: boolean;
    InputProps?: any;
    inputProps?: any;
};
