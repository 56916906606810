/**
 * @prettier
 */
import { InputBase, InputLabel } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import securitycode from 'payment-icons/min/outline/security-code.svg';
import * as React from 'react';
import { useContext, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { FormContext } from 'src/components/form/Form';
import { getError } from 'src/components/form/utils/getError';
import { transformStringInput } from 'src/components/form/utils/transformStringInput';
import { transformStringOutput } from 'src/components/form/utils/transformStringOutput';
import { translate } from 'src/i18n/translate';
import { isBlankString } from 'src/utils/string/isBlankString';

export function FormCvcField({ name, label, placeholder, helperText, value, onChange, variant = 'outlined', required, disabled, InputProps, rules, defaultValue = null }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const {
        errors,
        control,
        formState: { isSubmitting },
        watch,
    } = useFormContext();
    const formContext = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>();
    const cardId = watch(`${name}.cardId`);
    const cardNumber = watch(`${name}.cardNumber`);
    const expiry = watch(`${name}.expiry`);

    const minLength = 3;
    const maxLength = 4;

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <div>
                    <InputLabel classes={{ root: classes.label, error: classes.labelError }} error={!!getError(errors, name)} required={required}>
                        {label}
                    </InputLabel>
                    <InputBase
                        {...props}
                        value={transformStringInput(value)}
                        onChange={(value) => onChange(transformStringOutput(value as any))}
                        /* @ts-ignore */
                        label={label}
                        placeholder={translate('CVC')}
                        error={!!getError(errors, name)?.['cardNumber'] || !!getError(errors, name)?.['expiry'] || !!getError(errors, name)?.['cvc']?.message}
                        inputRef={inputRef}
                        inputComponent={NumberFormatCustom as any}
                        endAdornment={
                            <InputAdornment position='end'>
                                <img src={securitycode} alt='' height={theme.spacing(2.5)} style={{ opacity: 0.7 }} />
                            </InputAdornment>
                        }
                        autoComplete={'cc-csc'}
                        inputProps={{ autoComplete: 'cc-csc' }}
                        InputLabelProps={{ shrink: placeholder ? true : undefined }}
                        variant={variant}
                        required={required}
                        disabled={isSubmitting || disabled || formContext.disabled}
                        fullWidth
                        classes={{ root: classes.input, error: classes.inputError }}
                    />
                    {!!helperText && <FormHelperText classes={{ root: !!getError(errors, name) ? classes.helperTextError : classes.helperText }}>{helperText}</FormHelperText>}
                </div>
            )}
            control={control}
            name={`${name}.cvc`}
            defaultValue={cardId ? '***' : defaultValue}
            rules={{
                required: {
                    value: rules?.required || !isBlankString(cardNumber) || !isBlankString(expiry),
                    message: translate('CVC is required'),
                },
                minLength: {
                    value: minLength,
                    message: translate('CVC must be at least @number characters', { number: minLength }),
                },
                maxLength: {
                    value: maxLength,
                    message: translate('CVC must be at most @number characters', { number: maxLength }),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export function NumberFormatCustom(props: { inputRef: any; name: string; onChange: any }): React.ReactElement {
    const { inputRef, onChange, ...other } = props;

    return <NumberFormat {...other} getInputRef={inputRef} onValueChange={(values) => onChange(values.value)} isNumericString format='####' decimalScale={0} />;
}

const useStyles = makeStyles((theme) => ({
    input: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 5,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: 5,
        paddingBottom: 5,
        fontFamily: theme.typography.regular,
    },
    inputError: {
        border: '2px solid red',
    },
    label: {
        fontFamily: theme.typography.medium,
        color: theme.palette.secondary.contrastText,
        fontSize: 15,
        marginBottom: 5,
    },
    labelError: {
        color: 'red',
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: theme.palette.secondary.contrastText,
    },
    helperTextError: {
        fontFamily: theme.typography.light,
        color: 'red',
    },
}));

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    value?: string;
    onChange?: string;
    variant?: 'filled' | 'outlined' | 'standard';
    required?: boolean;
    disabled?: boolean;
    rules?: {
        required?: boolean;
    };
    InputProps?: any;
    defaultValue?: string | null | undefined;
};
