/**
 * @prettier
 */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PasswordDialog } from 'src/components/dialog/PasswordDialog';
import { UserType } from 'src/constants/UserType';
import { useSelector } from 'src/utils/react/useSelector';

export function PasswordRequiredContent({ children, customPassword, onUnAuthorized, allowedUserTypes, onAuthorized }: Props): React.ReactElement {
    const history = useHistory();

    const [openDialog, setOpenDialog] = useState(true);
    const [seeContentPage, setSeeContentPage] = useState(false);

    const userType = useSelector((state) => state.authentication.userType);

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleFailed = () => {
        if (onUnAuthorized) return onUnAuthorized?.();
        history.goBack();
    };

    const handleSuccess = async () => {
        setOpenDialog(false);
        setSeeContentPage(true);
        onAuthorized?.();
    };

    useEffect(() => {
        if (allowedUserTypes?.includes(userType)) {
            setSeeContentPage(true);
        }
    }, []);

    if (allowedUserTypes?.includes(userType)) {
        return <>{seeContentPage && children}</>;
    }

    return (
        <>
            <PasswordDialog open={openDialog} customPassword={customPassword} onClose={handleClose} onFailed={handleFailed} onSuccess={handleSuccess} />
            {seeContentPage && children}
        </>
    );
}

type Props = {
    children: React.ReactNode;
    customPassword?: string;
    allowedUserTypes?: Array<UserType>;
    onUnAuthorized?: any;
    onAuthorized?: any;
};
