/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PromoCodeId } from 'src/types/Id';

export async function removePromoCodeApi(request: RemovePromoCodeApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('promoCode/removePromoCodeApi', request);
}

export type RemovePromoCodeApiRequest = {
    promoCodeId: PromoCodeId;
};
