/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import type { CartItemVm } from 'src/types/CartItemVm';
import { removeNulls } from 'src/utils/removeNulls';
import { emptyStringToUndefined } from 'src/utils/string/emptyStringToUndefined';

export function getItemFormatted(item: CartItemVm): CartItemVm {
    return removeNulls({
        menuItemId: item.menuItemId,
        menuCategoryId: item.menuCategoryId,
        menuId: item.menuId,
        menuItemType: item.menuItemType,
        name: item.name,
        unitPrice: item.unitPrice,
        promoUnitPrice: item.promoUnitPrice,
        promoText: item.promoText,
        imageUrl: item.imageUrl,
        note: emptyStringToUndefined(item.note),
        modifierGroups: item.modifierGroups.map((modifierGroup) => ({
            name: modifierGroup.name,
            showModifierGroupNameInCommand: modifierGroup.showModifierGroupNameInCommand,
            freeModifiersQuantity: modifierGroup.freeModifiersQuantity,
            modifiers: modifierGroup.modifiers.map((modifier) => ({
                modifierId: modifier.modifierId,
                name: modifier.name,
                price: BigNumber(modifier.price ?? 0).toString(),
                quantity: modifier.quantity,
            })),
        })),
        orderItemKitchenStatus: item.orderItemKitchenStatus,
        salesUnit: item.salesUnit,
        currency: item.currency,
        isSoldByWeight: item.isSoldByWeight,
        pickupTime: item.pickupTime,
        quantity: item.quantity,
        addedAt: item.addedAt,
        cancellationReason: item.cancellationReason,
    });
}
