/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentTerminalPaymentFailedReason } from 'src/constants/PaymentTerminalPaymentFailedReason';
import type { PaymentTerminalPaymentStatus } from 'src/constants/PaymentTerminalPaymentStatus';
import type { PaymentTerminalId, PaymentTerminalPaymentId } from 'src/types/Id';

export async function refundPaymentTerminalPaymentApi(request: Request): ApiSauceResponse<Response> {
    return letseatmanagerApiMethod('paymentTerminalPayment/refundPaymentTerminalPaymentApi', request);
}

type Request = {
    paymentTerminalPaymentId: PaymentTerminalPaymentId;
    paymentTerminalId: PaymentTerminalId;
};

type Response = {
    paymentTerminalPaymentId: PaymentTerminalPaymentId;
    paymentTerminalPaymentStatus: PaymentTerminalPaymentStatus;
    paymentTerminalPaymentFailedReason?: PaymentTerminalPaymentFailedReason;
    message?: string;
};
