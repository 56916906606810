/**
 * @prettier
 */
import { useRef, useState } from 'react';
import { pollPaymentTerminalPaymentApi, PollPaymentTerminalPaymentApiResponse } from 'src/api/letseatmanager/paymentTerminalPayment/pollPaymentTerminalPaymentApi';
import { PaymentTerminalPaymentResultMock, pushPaymentTerminalPaymentApi } from 'src/api/letseatmanager/paymentTerminalPayment/pushPaymentTerminalPaymentApi';
import { app2 } from 'src/app2';
import { PaymentTerminalPaymentFailedReasons } from 'src/constants/PaymentTerminalPaymentFailedReason';
import { PaymentTerminalPaymentStatuses } from 'src/constants/PaymentTerminalPaymentStatus';
import type { PaymentTerminalProvider } from 'src/constants/PaymentTerminalProvider';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { useOpenSelectPaymentTerminalPaymentResultMockDialog } from 'src/scenes/letseatmanager/paymentTerminalPayment/SelectPaymentTerminalPaymentResultMockDialog';
import { useDeveloperMode } from 'src/services/developerMode/useDeveloperMode';
import { useConfirmDialog } from 'src/services/dialog/useConfirmDialog';
import { usePayWithPaymentTerminalDeprecatedService } from 'src/services/paymentTerminal/deprecated/usePayWithPaymentTerminalDeprecatedService';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { PaymentTerminalId, PaymentTerminalPaymentId } from 'src/types/Id';
import type { PaymentTerminalVm } from 'src/types/PaymentTerminalVm';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useTimeoutInterval } from 'src/utils/react/useTimeoutInterval';
import { isPaymentTerminalDevice } from 'src/utils/reactNative/isPaymentTerminalDevice';

export function usePayWithPaymentTerminal(): Service {
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);

    const payWithPaymentTerminalService = usePayWithPaymentTerminalService();
    const payWithPaymentTerminalDeprecatedService = usePayWithPaymentTerminalDeprecatedService();

    if (!newDeviceManagementEnabled) {
        return payWithPaymentTerminalDeprecatedService;
    }

    return payWithPaymentTerminalService;
}

export function usePayWithPaymentTerminalService(): Service {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const paymentResponseResolve = useRef((result: PaymentResult) => {});

    const confirmDialog = useConfirmDialog();

    const [loading, setLoading] = useState(false);
    const [paymentTerminalPaymentId, setPaymentTerminalPaymentId] = useState<PaymentTerminalPaymentId>();

    const deviceGroup = useSelector((state) => state.app.deviceGroup);
    const paymentTerminals = useSelector((state) => state.app.paymentTerminals);
    const qpayPosTerminalDeviceId = useSelector((state) => state.app.qpayPosTerminalDeviceId);

    const openSelectPaymentTerminalDialog = useAction(app2.actions.openSelectPaymentTerminalDialog);
    const setPaying = useAction(posReducer.actions.setPaying);
    const developerMode = useDeveloperMode();

    const openSelectPaymentTerminalPaymentResultMockDialog = useOpenSelectPaymentTerminalPaymentResultMockDialog();

    useTimeoutInterval(
        async () => {
            if (!paymentTerminalPaymentId) return;

            const response = await pollPaymentTerminalPaymentApi({ paymentTerminalPaymentId: paymentTerminalPaymentId });

            if (!response.ok) {
                console.log(`Failed silently, trying again in 5 seconds response`, response);
                return;
            }

            if (response.data.paymentTerminalPaymentStatus === PaymentTerminalPaymentStatuses.FAILED) {
                await onPaymentFailed(response.data.message || translate('Failed to do the payment.'));
                return;
            }

            if (response.data.paymentTerminalPaymentStatus === PaymentTerminalPaymentStatuses.PAID) {
                await onPaymentSuccess(response.data);
            }
        },
        2 * SECONDS,
        [paymentTerminalPaymentId]
    );

    const payWithPaymentTerminal = ({ paymentTerminalId, amount, tipAmount }: PayInTerminalPaymentRequest): Promise<PaymentResult> => {
        if (paymentTerminalId) {
            return payInPaymentTerminal({ paymentTerminalId, amount, tipAmount });
        }

        const paymentTerminalsInDeviceGroup = paymentTerminals.filter((paymentTerminal) => deviceGroup?.paymentTerminalIds?.includes(paymentTerminal.paymentTerminalId));

        if (paymentTerminalsInDeviceGroup.length > 1) {
            return selectPaymentTerminalAndPay({
                header: translate('Select a terminal to pay @amount', { amount: formatAsCurrencyNumber(amount) }),
                amount,
            });
        }

        const paymentTerminalIdFromDeviceGroup = paymentTerminalsInDeviceGroup[0]?.paymentTerminalId;
        return payInPaymentTerminal({ paymentTerminalId: paymentTerminalIdFromDeviceGroup, amount, tipAmount });
    };

    const payInPaymentTerminal = async ({ paymentTerminalId, amount, tipAmount }: PayInTerminalPaymentRequest): Promise<PaymentResult> => {
        return new Promise(async (resolve) => {
            paymentResponseResolve.current = resolve;

            if (!paymentTerminalId) {
                await onPaymentFailed(translate('Terminal not configured'));
                return;
            }

            let mock: PaymentTerminalPaymentResultMock | undefined;
            if (developerMode) {
                mock = await new Promise((resolve) => {
                    openSelectPaymentTerminalPaymentResultMockDialog({
                        onSelectMock: async (mock) => {
                            resolve(mock);
                        },
                        onCloseDialog: () => {
                            resolve(undefined);
                        },
                    });
                });
                if (!mock) {
                    setLoading(false);
                    setPaying(false);
                    resolve({ paid: false });
                    return;
                }
            }

            setLoading(true);
            setPaying(true);
            const paymentTerminal = paymentTerminals.find((paymentTerminal) => paymentTerminal.paymentTerminalId === paymentTerminalId);
            const response = await pushPaymentTerminalPaymentApi({
                paymentTerminalId,
                amount,
                tipAmount,
                apphook: isPaymentTerminalDevice() && paymentTerminal?.qpayTerminalDeviceId === qpayPosTerminalDeviceId ? 'pidedirectoadminapp' : undefined,
                mock,
            });

            if (!response.ok) {
                await onPaymentFailed(response.data?.message ? translate('Something went wrong @error', { error: response.data.message }) : translate('Something went wrong'));
                return;
            }

            const paymentTerminalPaymentStatus = response.data?.paymentTerminalPaymentStatus;

            if (paymentTerminalPaymentStatus === PaymentTerminalPaymentStatuses.FAILED) {
                const paymentTerminalPaymentFailedReason = response.data?.paymentTerminalPaymentFailedReason;

                if (paymentTerminalPaymentFailedReason === PaymentTerminalPaymentFailedReasons.TERMINAL_BUSY) {
                    await onPaymentFailed(translate('Terminal busy, please wait a moment and try again'));
                    return;
                }

                if (paymentTerminalPaymentFailedReason === PaymentTerminalPaymentFailedReasons.TERMINAL_NOT_FOUND) {
                    await onPaymentFailed(translate('Terminal not found, check your configuration and try again'));
                    return;
                }

                // should not happen, in case it happens create a PaymentTerminalPaymentFailedReasons for it and handle with if case above
                await onPaymentFailed(translate(response.data.message ? translate('Something went wrong @error', { error: response.data.message }) : translate('Something went wrong')));
                return;
            }

            paymentResponseResolve.current = resolve;
            setPaymentTerminalPaymentId(response.data.paymentTerminalPaymentId);
        });
    };

    const onPaymentFailed = async (message: string) => {
        setLoading(false);
        setPaying(false);
        setPaymentTerminalPaymentId(undefined);
        await confirmDialog({
            title: translate('Error'),
            content: message,
            buttonText: translate('Accept'),
            variant: 'error',
        });
        paymentResponseResolve.current({ paid: false });
        paymentResponseResolve.current = (paymentResult) => {};
    };

    const onPaymentSuccess = async (response: PollPaymentTerminalPaymentApiResponse) => {
        setLoading(false);
        setPaying(false);
        paymentResponseResolve.current({ paid: true, paymentTerminalPaymentId, paymentTerminalId: response.paymentTerminalId, paymentTerminalProvider: response.paymentTerminalProvider });
        paymentResponseResolve.current = (paymentResult) => {};
        setPaymentTerminalPaymentId(undefined);
    };

    const selectPaymentTerminalAndPay = async ({ header, amount, tipAmount }: SelectPaymentTerminalAndPayParams): Promise<PaymentResult> => {
        return await new Promise((resolve) => {
            openSelectPaymentTerminalDialog({
                header,
                onSelectPaymentTerminal: async (paymentTerminal: PaymentTerminalVm) => {
                    const paymentResponse = await payInPaymentTerminal({
                        amount,
                        tipAmount,
                        paymentTerminalId: paymentTerminal.paymentTerminalId,
                    });
                    if (!paymentResponse.paid) {
                        resolve(await selectPaymentTerminalAndPay({ header, amount: amount }));
                        return;
                    }

                    resolve(paymentResponse);
                },
                onCloseDialog: () => {
                    resolve({ paid: false });
                },
            });
        });
    };

    const cancelPayment = () => {
        setPaymentTerminalPaymentId(undefined);
    };

    return { payWithPaymentTerminal, cancelPayment, loading };
}

type Service = {
    payWithPaymentTerminal: PayWithPaymentTerminalFunction;
    cancelPayment: any;
    loading: boolean;
};

type PayInTerminalPaymentRequest = {
    paymentTerminalId?: PaymentTerminalId;
    amount: string;
    tipAmount?: string;
};

type PayWithPaymentTerminalFunction = (arg1: {
    paymentTerminalId?: PaymentTerminalId; // If not provided device group will decide what paymentTerminal to use for payments,
    amount: string;
    tipAmount?: string;
}) => Promise<PaymentResult>;

export type PaymentResult = {
    paid: boolean;
    paymentTerminalPaymentId?: PaymentTerminalPaymentId;
    paymentTerminalId?: PaymentTerminalId;
    paymentTerminalProvider?: PaymentTerminalProvider;
};

type SelectPaymentTerminalAndPayParams = {
    header?: string;
    amount: string;
    tipAmount?: string;
};
