/**
 * @prettier
 */
export function getDirtyFormValues({ form, dirtyFields }: Props): any {
    let dirtyFormValues: Record<string, any> = {};

    Object.keys(dirtyFields).forEach((key) => {
        dirtyFormValues = { ...dirtyFormValues, [key]: form[key] };
    });

    return dirtyFormValues;
}

type Props = {
    form: any;
    dirtyFields: any;
};
