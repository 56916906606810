/**
 * @prettier
 */
import * as React from 'react';
import { findSupplyCategoriesByRestaurantIdApi } from 'src/api/letseatmanager/supply/findSupplyCategoriesByRestaurantIdApi';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { getSupplyCategoryName } from 'src/utils/supplyCategory/getSupplyCategoryName';

export function FormSupplyCategorySelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant }: Props): React.ReactElement {
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loadingSupplyCategories, supplyCategories] = useLoadApi(findSupplyCategoriesByRestaurantIdApi, { restaurantId }, { initialValue: [] });

    const options = required
        ? supplyCategories?.map((supplyCategory: any) => ({ label: getSupplyCategoryName(supplyCategory.name), value: supplyCategory.supplyCategoryId }))
        : [{ label: '', value: null }, ...supplyCategories?.map((supplyCategory: any) => ({ label: getSupplyCategoryName(supplyCategory.name), value: supplyCategory.supplyCategoryId }))];

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options as any}
            disabled={disabled || loadingSupplyCategories}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
};
