/**
 * @prettier
 */
import { Chip, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useState } from 'react';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { translate } from 'src/i18n/translate';
import { AddIcon } from 'src/icons/AddIcon';
import type { EmailAddress } from 'src/types/Id';
import { isEmail } from 'src/utils/string/isEmail';

export function MultipleEmailsInput({ disabled, label, emails, onChangeEmails }: Props): React.ReactElement {
    const classes = useStyles();

    const [statisticsReportEmail, setStatisticsReportEmail] = useState('');

    const validateEmailOnChange = (email: any) => {
        if (!isEmail(email)) return { showError: true, errorMessage: translate('Invalid email') };
    };

    const handleRemoveStatisticsReportEmail = (email: EmailAddress) => {
        const filteredStatisticsReportEmailRecipients = emails?.filter((statisticsReportEmail: EmailAddress) => statisticsReportEmail !== email);

        onChangeEmails(filteredStatisticsReportEmailRecipients ?? []);
    };

    const handleAddStatisticsReportEmail = (event: any) => {
        event?.preventDefault();

        if (!statisticsReportEmail) return;

        if (!isEmail(statisticsReportEmail)) return;

        onChangeEmails([...(emails || []), statisticsReportEmail]);
        setStatisticsReportEmail('');
    };

    return (
        <Grid item xs={12} md={6}>
            <span className={classes.text}>{label}</span>
            <div className={classes.emails}>
                {emails?.map((email: EmailAddress) => {
                    return <Chip key={email} label={email} onDelete={() => handleRemoveStatisticsReportEmail(email)} />;
                })}
            </div>
            <div className={classes.addEmail}>
                <Input
                    name={'statisticsReportEmail'}
                    label={translate('Add email')}
                    type={'email'}
                    validation={validateEmailOnChange}
                    value={statisticsReportEmail}
                    onChange={setStatisticsReportEmail}
                    classes={{ container: classes.input }}
                    disabled={disabled}
                />
                <Button onClick={handleAddStatisticsReportEmail} disabled={disabled} classes={{ button: classes.button }} text>
                    <AddIcon title={'add-email'} color={'#06B7A2'} />
                </Button>
            </div>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    addEmail: {
        width: '100%',
        display: 'flex',
        gap: 10,
    },

    text: {
        fontFamily: theme.typography.regular,
        color: '#40464d',
    },
    input: {
        width: '90%',
    },
    emails: {
        margin: '10px 0',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    button: {
        height: 42,
        alignSelf: 'end',
    },
}));

type Props = {
    emails: Array<EmailAddress>;
    onChangeEmails: any;
    label: string;
    disabled?: boolean;
};
