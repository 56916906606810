/**
 * @prettier
 */
import { MexicanPaymentMethodCode } from 'src/constants/MexicanPaymentMethodCode';
import { usePosPaymentMethodsStore } from 'src/services/pos/posPaymentMethods/posPaymentMethodsStore';

export function useSelectedMexicanPaymentMethodCode(): MexicanPaymentMethodCode | undefined {
    const mexicanPaymentMethodCode = usePosPaymentMethodsStore((state) => state.mexicanPaymentMethodCode);

    return mexicanPaymentMethodCode;
}
