/**
 * @prettier
 */
import { makeStyles, useTheme } from '@material-ui/core';
import { CircleCheckIcon, DoorbellIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { AddIntegrationInUruguay } from 'src/images/AddIntegrationInUruguay';
import { AddIntegrationMexico } from 'src/images/AddIntegrationMexico';
import ambitLogo from 'src/images/ambit-logo.png';
import { isMexico } from 'src/utils/country/isMexico';
import { isUsa } from 'src/utils/country/isUsa';
import { useSelector } from 'src/utils/react/useSelector';

export function IntegrationsEmptyState(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();

    const restaurant = useSelector((state) => state.app.restaurant);

    const isMexicoOrUsa = isMexico(restaurant?.country) || isUsa(restaurant?.country);

    return (
        <div className={classes.principalContainer}>
            <div className={classes.container}>
                <div className={classes.subContainer}>
                    <div>
                        <div className={classes.titleContainer}>
                            <img src={ambitLogo} className={classes.logo} />
                            <div className={classes.titleText} style={{ lineHeight: 1.1 }}>
                                {translate('All your platforms on a single screen')}
                            </div>
                        </div>
                        <Button classes={{ button: classes.button }} primary>
                            {translate('Start test')}
                        </Button>
                        <div>
                            <div className={classes.subTitleContainer}>
                                <DoorbellIcon color={theme.palette.icons.brand} />
                                <h4>{translate('Integrate all platforms')}</h4>
                            </div>

                            {isMexicoOrUsa && (
                                <div>
                                    <div className={classes.secondaryText}>
                                        <CircleCheckIcon color={theme.palette.icons.brand} />
                                        <span className={classes.textWithMarginLeft}>Uber Eats</span>
                                    </div>
                                    <div className={classes.secondaryText}>
                                        <CircleCheckIcon color={theme.palette.icons.brand} />
                                        <span className={classes.textWithMarginLeft}>Rappi</span>
                                    </div>
                                    <div className={classes.secondaryText}>
                                        <CircleCheckIcon color={theme.palette.icons.brand} />
                                        <span className={classes.textWithMarginLeft}>Didi Food</span>
                                    </div>
                                </div>
                            )}
                            {!isMexicoOrUsa && (
                                <div>
                                    <div className={classes.secondaryText}>
                                        <CircleCheckIcon />
                                        <span className={classes.textWithMarginLeft}>Rappi</span>
                                    </div>
                                    <div className={classes.secondaryText}>
                                        <CircleCheckIcon />
                                        <span className={classes.textWithMarginLeft}>Pedidos Ya</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={classes.subContainer}>
                    {isMexicoOrUsa && <AddIntegrationMexico />}
                    {!isMexicoOrUsa && <AddIntegrationInUruguay />}
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    principalContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        marginTop: '20px',
        justifyContent: 'center',
    },
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        maxWidth: '857px',
        maxHeight: '397px',
        paddingLeft: '30px',
    },
    subContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    titleText: {
        fontSize: '30px',
        fontFamily: theme.typography.semiBold,
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        marginBottom: 30,
    },
    subTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        fontSize: '18px',
        fontFamily: theme.typography.semiBold,
    },
    boldText: {
        fontFamily: theme.typography.light,
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
    },
    secondaryText: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '18px',
        paddingBottom: '12px',
    },
    textWithMarginLeft: {
        paddingLeft: '10px',
    },
    logo: {
        width: 40,
    },
    icon: {
        width: 20,
        height: 24,
    },
    button: {
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        borderRadius: 8,
        height: 60,
        width: '100%',
        maxWidth: '60%',
    },
}));
