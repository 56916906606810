/**
 * @prettier
 */
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';

export function toLatLng(point: GeoJsonPoint): { lat: number; lng: number };
export function toLatLng(point: undefined): undefined;
export function toLatLng(point: null): null;
export function toLatLng(point: GeoJsonPoint): { lat: number; lng: number };
export function toLatLng(point: GeoJsonPoint | undefined): { lat: number; lng: number } | undefined;
export function toLatLng(point: GeoJsonPoint | null): { lat: number; lng: number } | null;
export function toLatLng(point: undefined | null): undefined | null;

export function toLatLng(point: GeoJsonPoint | undefined | null): { lat: number; lng: number } | undefined | null {
    if (!point) {
        return;
    }
    return {
        lat: point.coordinates[1],
        lng: point.coordinates[0],
    };
}
