/**
 * @prettier
 */
import { ResponsivePie } from '@nivo/pie';
import * as React from 'react';

export function PieChartCanvas({ data, margin, legends, innerRadius, valueFormat, tooltip, sortByValue, padAngle }: Props): React.ReactElement {
    const colors = [
        '#2476C2',
        '#20B2AA',
        '#F1948A',
        '#FFD700',
        '#53C7D7',
        '#7B68EE',
        '#FF8C00',
        '#40E0D0',
        '#FFA07A',
        '#9370DB',
        '#1E90FF',
        '#FFB6C1',
        '#0DFFD3',
        '#A0D8F8',
        '#A7A0F8',
        '#6256E9',
        '#00FF7F',
        '#8A2BE2',
        '#5DE3BC',
        '#24A8C2',
        '#13647D',
    ];

    return (
        <ResponsivePie
            data={data}
            margin={{ top: margin?.top ?? 40, right: margin?.right ?? 80, bottom: margin?.bottom ?? 80, left: margin?.left ?? 80 }}
            valueFormat={valueFormat}
            innerRadius={innerRadius ?? 0.6}
            padAngle={padAngle ?? 2}
            cornerRadius={3}
            activeOuterRadiusOffset={12}
            colors={colors}
            tooltip={tooltip}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor='#333333'
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={['#FFFFFF'] as any}
            enableArcLinkLabels={false}
            legends={
                legends
                    ? [
                          {
                              anchor: 'bottom',
                              direction: 'row',
                              justify: false,
                              translateX: 0,
                              translateY: 56,
                              itemsSpacing: 0,
                              itemWidth: 100,
                              itemHeight: 18,
                              itemTextColor: '#999',
                              itemDirection: 'left-to-right',
                              itemOpacity: 1,
                              symbolSize: 18,
                              symbolShape: 'circle',
                              effects: [
                                  {
                                      on: 'hover',
                                      style: {
                                          itemTextColor: '#000',
                                      },
                                  },
                              ],
                          },
                          {
                              anchor: 'top-left',
                              direction: 'column',
                              justify: false,
                              translateX: 0,
                              translateY: 0,
                              itemWidth: 100,
                              itemHeight: 20,
                              itemsSpacing: 12,
                              symbolSize: 20,
                              itemDirection: 'left-to-right',
                          },
                      ]
                    : undefined
            }
            sortByValue={sortByValue}
        />
    );
}

type Props = {
    data: Array<{
        id: string;
        label: string;
        value: number;
    }>;
    innerRadius?: number;
    valueFormat?: string | ((value: number) => string);
    tooltip?: React.ReactNode | any;
    margin?: {
        top?: number;
        right?: number;
        bottom?: number;
        left?: number;
    };
    legends?: boolean;
    sortByValue?: boolean;
    padAngle?: number;
};
