/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import type { PromoCodeVm } from 'src/api/letseatmanager/types/PromoCodeVm';
import type { OrderType } from 'src/constants/OrderType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import { RewardTypes } from 'src/constants/RewardType';
import { translate } from 'src/i18n/translate';
import { useOrderPaymentMethod } from 'src/services/pos/useOrderPaymentMethod';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CartItemVm } from 'src/types/CartItemVm';
import { useSelector } from 'src/utils/react/useSelector';

export function useValidatePromoCodeApplicable(): {
    isValid: boolean;
    message?: string;
} {
    const orderPaymentMethod = useOrderPaymentMethod();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const orderType = useSelector((state) => state.pos.orderType);
    const currency = useSelector((state) => state.app.restaurant?.currencyFormat?.currency);
    const total = useSelector((state) => state.pos.payment?.total);
    const driverRequest = useSelector((state) => state.pos.driverRequest);
    const customerId = useSelector((state) => state.pos.customerId);
    const promoCodeSelected = useSelector((state) => state.pos.promoCode);
    const items = useSelector((state) => state.pos.items);

    const customerDeliveryCost = driverRequest?.deliveryPaidByRestaurant ? '0' : driverRequest?.deliveryCost ?? '0';

    if (!promoCodeSelected) return { isValid: false, message: translate('No Promo Code Selected') };
    if (promoCodeSelected.orderTypes) {
        if (!isPromoCodeApplicableForOrderType(promoCodeSelected, orderType)) {
            return {
                isValid: false,
                message: translate('This Promo Code is not available for order of type: @orderType', { orderType: translate(orderType) }),
            };
        }
    }

    if (promoCodeSelected.paymentMethods) {
        if (!isPromoCodeApplicableForPaymentMethod(promoCodeSelected, orderPaymentMethod)) {
            return {
                isValid: false,
                message: translate('This Promo Code is not available for this payment method'),
            };
        }
    }

    if (promoCodeSelected.minOrderAmount) {
        if (!isPromoCodeApplicableForMinOrderAmount(promoCodeSelected, total, customerDeliveryCost)) {
            return {
                isValid: false,
                message: translate('You must order for at least @amount to use this promo code.', { amount: formatAsCurrencyNumber(promoCodeSelected.minOrderAmount, currency) }),
            };
        }
    }

    if (promoCodeSelected.rewardType === RewardTypes.CREDITS) {
        if (!customerId) {
            return {
                isValid: false,
                message: translate('User not found or promo code not available for this user'),
            };
        }
    }

    if (promoCodeSelected.menuItemIds) {
        if (!isPromoCodeApplicableForOrderItems(promoCodeSelected, items)) {
            return {
                isValid: false,
                message: translate('This promo code is not available for this items'),
            };
        }
    }

    return {
        isValid: true,
    };
}

function isPromoCodeApplicableForOrderType(promoCode?: PromoCodeVm, orderType?: OrderType): boolean {
    if (!promoCode?.orderTypes) {
        return true;
    }
    return Boolean(orderType) && promoCode.orderTypes.includes(orderType!);
}

function isPromoCodeApplicableForPaymentMethod(promoCode?: PromoCodeVm, paymentMethod?: PaymentMethod): boolean {
    if (!promoCode?.paymentMethods?.length) return true;

    return promoCode?.paymentMethods?.includes(paymentMethod!);
}

function isPromoCodeApplicableForMinOrderAmount(promoCode: PromoCodeVm | null | undefined, amount: string, deliveryCost?: string): boolean {
    if (!promoCode?.minOrderAmount) {
        return true;
    }
    if (promoCode.excludeDeliveryCost) {
        const amountWithoutDeliveryCost = BigNumber(amount).minus(deliveryCost ?? 0);
        return BigNumber(amountWithoutDeliveryCost).isGreaterThanOrEqualTo(promoCode?.minOrderAmount ?? 0);
    }
    return BigNumber(amount).isGreaterThanOrEqualTo(promoCode?.minOrderAmount ?? 0);
}

function isPromoCodeApplicableForOrderItems(promoCode: PromoCodeVm | null | undefined, orderItems: Array<CartItemVm>): boolean {
    if (!promoCode?.menuItemIds) return true;

    const orderItemIds = orderItems.map((orderItem) => orderItem.menuItemId);

    return orderItemIds.some((orderItemId) => promoCode.menuItemIds?.includes(orderItemId));
}
