/**
 * @prettier
 */
import { isPossiblePhoneNumber as _isPossiblePhoneNumber } from 'libphonenumber-js';
import type { Country } from 'src/constants/Country';

export function isPossiblePhoneNumber(value?: string | null, country?: Country): boolean {
    if (!value) {
        return false;
    }
    return _isPossiblePhoneNumber(value, country as any);
}
