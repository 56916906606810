/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CashRegisterCustomReason, RestaurantId } from 'src/types/Id';

export async function findCashRegisterDepositReasonsApi(request: FindCashRegisterDepositReasonsApiRequest): ApiSauceResponse<FindCashRegisterDepositReasonsApiResponse> {
    return letseatmanagerApiMethod('pos/findCashRegisterDepositReasonsApi', request);
}

export type FindCashRegisterDepositReasonsApiRequest = {
    restaurantId: RestaurantId;
};

export type FindCashRegisterDepositReasonsApiResponse = Array<{
    reason: CashRegisterCustomReason;
}>;
