/**
 * @prettier
 */
import * as React from 'react';

export function UserFilledIcon({ width, height, color, title }: Props): React.ReactElement {
    return (
        <svg width={width ?? '16'} height={height ?? '17'} viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.4567 13.2313C13.1027 12.6243 13.6173 11.8913 13.9688 11.0775C14.3202 10.2637 14.501 9.38644 14.5 8.5C14.5 4.91 11.59 2 8 2C4.41 2 1.5 4.91 1.5 8.5C1.49898 9.38644 1.67978 10.2637 2.03124 11.0775C2.38269 11.8913 2.89733 12.6243 3.54334 13.2313C4.74796 14.3693 6.3429 15.0022 8 15C9.65711 15.0022 11.252 14.3693 12.4567 13.2313ZM4.09667 12.3747C4.56472 11.7891 5.15868 11.3165 5.83443 10.9919C6.51018 10.6674 7.25036 10.4993 8 10.5C8.74965 10.4993 9.48983 10.6674 10.1656 10.9919C10.8413 11.3165 11.4353 11.7891 11.9033 12.3747C11.3927 12.8905 10.7847 13.2997 10.1147 13.5787C9.4446 13.8578 8.72582 14.0009 8 14C7.27419 14.0009 6.55541 13.8578 5.88535 13.5787C5.2153 13.2997 4.60732 12.8905 4.09667 12.3747ZM10.5 6.5C10.5 7.16304 10.2366 7.79893 9.76777 8.26777C9.29893 8.73661 8.66305 9 8 9C7.33696 9 6.70108 8.73661 6.23224 8.26777C5.7634 7.79893 5.5 7.16304 5.5 6.5C5.5 5.83696 5.7634 5.20107 6.23224 4.73223C6.70108 4.26339 7.33696 4 8 4C8.66305 4 9.29893 4.26339 9.76777 4.73223C10.2366 5.20107 10.5 5.83696 10.5 6.5Z'
                fill={color ?? '#616B79'}
            />
        </svg>
    );
}

type Props = {
    width?: number;
    height?: number;
    color?: string;
    title?: string;
};
