/**
 * @prettier
 */
import type { RestaurantId } from 'src/types/Id';

export const KEY_NAME = 'restaurantOptions';

export function getRestaurantOptionsInLocalStorage(): Array<RestaurantOption> {
    const item = window.localStorage.getItem('restaurantOptions');
    return item ? JSON.parse(item) : [];
}

export type RestaurantOption = {
    label: string;
    value: RestaurantId;
    newMenuManagementEnabled: boolean;
};
