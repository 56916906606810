/**
 * @prettier
 */
import { useNotification } from '@pidedirecto/ui/hooks';
import { RestaurantContext } from 'src/api/letseatmanager/getAppContextApi';
import { PrinterContents } from 'src/constants/PrinterContent';
import { PrintTypes } from 'src/constants/PrintType';
import { translate } from 'src/i18n/translate';
import { getDeprecatedPrintersInLocalStorage } from 'src/localStorage/getDeprecatedPrintersInLocalStorage';
import { useMenuCategories } from 'src/services/menuCategory/useMenuCategories';
import { createOrderCommandPrint } from 'src/services/printer/prints/createOrderCommandPrint';
import { printerDeprecatedToPrinter } from 'src/services/printer/utils/printerDeprecatedToPrinter';
import { usePrintContent } from 'src/services/printer/utils/usePrintContent';
import { MenuCategoryId } from 'src/types/Id';
import { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import type { OrderItemVm, OrderVm } from 'src/types/OrderVm';
import type { PrinterDeprecatedVm } from 'src/types/PrinterDeprecatedVm';
import { useSelector } from 'src/utils/react/useSelector';

export function usePrintOrderCommandDeprecated(): [(order: OrderVm, forceToPrint?: boolean) => Promise<void>] {
    const notification = useNotification();

    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant.newMenuManagementEnabled);
    const internalUser = useSelector((state) => state.authentication.internalUser);
    const restaurant = useSelector((state) => state.app.restaurant);
    const restaurants = useSelector((state) => state.app.restaurants);
    const { menuCategories, deprecatedMenuCategories } = useMenuCategories();

    const { printContent, printContents } = usePrintContent();

    const printers = getDeprecatedPrintersInLocalStorage();

    const printOrderCommandOldPrinterUi = async (order: OrderVm, forceToPrint: undefined | boolean) => {
        const orderRestaurant = restaurants?.find((restaurant: RestaurantContext) => restaurant.restaurantId === order.restaurant?.restaurantId);

        if (printers.length === 0 && forceToPrint) {
            notification({ message: translate('No active printers to select') });
            return;
        }

        if (printers.length === 1 && forceToPrint) {
            await printContents([
                {
                    printer: printerDeprecatedToPrinter(printers[0]),
                    print: {
                        printType: PrintTypes.COMMAND,
                        printerInstructions: createOrderCommandPrint({
                            order,
                            restaurant,
                            internalUser,
                            increaseCommandFontSizeEnabled: orderRestaurant?.increaseCommandFontSizeEnabled,
                            commandSections: orderRestaurant?.commandSections ?? [],
                        }),
                        numberOfPrints: orderRestaurant?.numberOfCommandPrints || restaurant.numberOfCommandPrints || 1,
                    },
                },
            ]);
            return;
        }

        const commandPrinters = printers.filter((printer) => {
            return printer.printerContent !== PrinterContents.TICKET;
        });

        if (commandPrinters.length === 0 && forceToPrint) {
            await printContent({
                printType: PrintTypes.COMMAND,
                printerInstructions: createOrderCommandPrint({
                    order: order,
                    restaurant,
                    internalUser,
                    increaseCommandFontSizeEnabled: orderRestaurant?.increaseCommandFontSizeEnabled,
                    commandSections: orderRestaurant?.commandSections ?? [],
                }),
                numberOfPrints: orderRestaurant?.numberOfCommandPrints || restaurant.numberOfCommandPrints || 1,
            });
            return;
        }

        if (!commandPrinters?.length) return;

        let pendingItemsToPrint = order.orderItems;
        let pendingCancelledItemsToPrint = order.cancelledItems ?? [];
        const hasCommandPrinterAvailableForOrderChannel = commandPrinters?.some((printer) => printer.channels?.includes(order?.app!));

        for (const commandPrinter of commandPrinters) {
            if (!order) continue;

            if (commandPrinter.channels && !commandPrinter.channels?.includes(order?.app!)) continue;

            const itemsToPrint = filterOrderItemToPrint(order.orderItems, commandPrinter);
            const cancelledItemsToPrint = filterOrderItemToPrint(order.cancelledItems ?? [], commandPrinter);
            let orderToPrint: OrderVm = { ...order, orderItems: itemsToPrint, cancelledItems: cancelledItemsToPrint };

            pendingItemsToPrint = pendingItemsToPrint.filter((orderItem) => !itemsToPrint?.some((itemToPrint) => itemToPrint.menuItemId === orderItem.menuItemId));
            pendingCancelledItemsToPrint = pendingCancelledItemsToPrint.filter(
                (cancelledItem) => !cancelledItemsToPrint?.some((cancelledItemToPrint) => cancelledItemToPrint.menuItemId === cancelledItem.menuItemId)
            );

            if (order.customers?.length) {
                const customers = order.customers?.map((customerOrder) => {
                    const itemsToPrint = filterOrderItemToPrint(customerOrder.orderItems, commandPrinter);
                    const cancelledItemsToPrint = filterOrderItemToPrint(customerOrder.cancelledItems ?? [], commandPrinter);
                    return {
                        ...customerOrder,
                        orderItems: itemsToPrint,
                        cancelledItems: cancelledItemsToPrint,
                    };
                });

                orderToPrint = { ...orderToPrint, customers };
            }

            if (order.courses?.length) {
                const courses = order.courses?.map((course) => {
                    const itemsToPrint = filterOrderItemToPrint(course.orderItems ?? [], commandPrinter);
                    const cancelledItemsToPrint = filterOrderItemToPrint(course.cancelledItems ?? [], commandPrinter);
                    return {
                        ...course,
                        orderItems: itemsToPrint,
                        cancelledItems: cancelledItemsToPrint,
                    };
                });

                orderToPrint = { ...orderToPrint, courses };
            }

            if (itemsToPrint.length === 0 && cancelledItemsToPrint.length === 0) continue;

            const contentsToPrint = [
                {
                    printer: printerDeprecatedToPrinter(commandPrinter),
                    print: {
                        printType: PrintTypes.COMMAND,
                        printerInstructions: createOrderCommandPrint({
                            order: orderToPrint,
                            restaurant,
                            internalUser,
                            increaseCommandFontSizeEnabled: orderRestaurant?.increaseCommandFontSizeEnabled,
                            commandSections: orderRestaurant?.commandSections ?? [],
                        }),
                        numberOfPrints: orderRestaurant?.numberOfCommandPrints || restaurant.numberOfCommandPrints || 1,
                    },
                },
            ];

            await printContents(contentsToPrint);
        }

        if ((!pendingItemsToPrint?.length && !pendingCancelledItemsToPrint.length) || !hasCommandPrinterAvailableForOrderChannel) {
            return;
        }

        await printContent({
            printType: PrintTypes.COMMAND,
            printerInstructions: createOrderCommandPrint({
                order: { ...order, orderItems: pendingItemsToPrint },
                restaurant,
                internalUser,
                increaseCommandFontSizeEnabled: orderRestaurant?.increaseCommandFontSizeEnabled,
                commandSections: orderRestaurant?.commandSections ?? [],
            }),
            numberOfPrints: orderRestaurant?.numberOfCommandPrints || restaurant.numberOfCommandPrints || 1,
        });
    };

    const filterOrderItemToPrint = (orderItems: Array<OrderItemVm>, commandPrinter: PrinterDeprecatedVm) => {
        return orderItems.filter((orderItem) => {
            const printerHasMenuCategories = !!commandPrinter.menuCategoryIds;

            let shouldPrintOrderItemByMenuCategory;
            if (orderItem.menuCategoryId) {
                shouldPrintOrderItemByMenuCategory = !!orderItem.menuCategoryId && commandPrinter.menuCategoryIds?.includes(orderItem.menuCategoryId);
            } else {
                shouldPrintOrderItemByMenuCategory = commandPrinter.menuCategoryIds?.some((menuCategoryId: MenuCategoryId) => {
                    if (newMenuManagementEnabled) {
                        const menuCategory = menuCategories.find((menuCategory) => menuCategory.menuCategoryId === menuCategoryId);
                        if (!menuCategory) return false;

                        return menuCategory?.menuItemIds.includes(orderItem.menuItemId);
                    }

                    const menuCategory = deprecatedMenuCategories.find((menuCategory) => menuCategory.menuCategoryId === menuCategoryId);
                    if (!menuCategory) return false;

                    return menuCategory?.menuItems.some((menuItem: ManagerMenuItemVm) => menuItem.menuItemId === orderItem.menuItemId);
                });
            }

            return !printerHasMenuCategories || shouldPrintOrderItemByMenuCategory;
        });
    };

    return [printOrderCommandOldPrinterUi];
}
