/**
 * @prettier
 */
export const PrinterBrands = {
    STAR: 'STAR',
    EPSON: 'EPSON',
    GHIA: 'GHIA',
    PIDEDIRECTO: 'PIDEDIRECTO',
    IMIN: 'IMIN',
    OTHER: 'OTHER',
    SUNMI: 'SUNMI',
    XPRINTER: 'XPRINTER',
} as const;

export type PrinterBrand = typeof PrinterBrands[keyof typeof PrinterBrands];
