/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { Checkbox } from 'src/components/Checkbox';
import { DropDown } from 'src/components/DropDown';
import { DropDownItem } from 'src/components/DropDownItem';
import { Input } from 'src/components/Input';
import { PageContext } from 'src/components/Page';
import { createUserClickedSelectOptionLogEvent } from 'src/services/logEvent/createUserClickedSelectOptionLogEvent';
import { isArray } from 'src/utils/array/isArray';
import { classNames } from 'src/utils/react/classNames';

/**
 * @callback onChange
 */

/**
 * Renders a select component
 * @param {string} label - Label for the select
 * @param {string} name - Name for the select
 * @param {Object[]} options - Options to be rendered in the select
 * @param {string} options[].value - Value for the option
 * @param {*[]} defaultValue - Set the values to be selected at beginning
 * @param {Object} [classes] - Classes to override the select styles
 * @param {string} [classes.item] - Class to override the item styles
 * @param {string} [classes.button] - Class to override the button styles
 * @param {boolean} [multiselectable] - Set the select to be multiselectable
 * @param {boolean} [disabled] - Set the select disabled
 * @param {boolean} [labelAsPlaceholder] - Remove the label element and put it as a placeholder
 * @param {onChange} onChange - Function to be called when the selected option has changed
 * @returns {React.Node}
 * @constructor
 */
export function Select({ label, name, options, multiselectable, disabled, labelAsPlaceholder, tooltipText, defaultValue, searchable, classes: classesProp, onChange }: Props): React.ReactElement {
    const pageContext = useContext(PageContext);
    const classes = useStyles();

    const [selectedValues, setSelectedValues] = useState<Array<any>>([]);
    const [filteredOptions, setFilteredOptions] = useState<
        Array<{
            value: string | null;
            label: string;
        }>
    >(options);

    useEffect(() => {
        handleDefaultValue();
    }, []);

    const handleDefaultValue = () => {
        if (!defaultValue) return;
        if (!multiselectable) return setSelectedValues([defaultValue?.[0]] as any);

        setSelectedValues(isArray(defaultValue) ? defaultValue : ([defaultValue] as any));
    };

    const handleOnChange = (selectedValue: any) => {
        createUserClickedSelectOptionLogEvent({ pageContext, label, name, selectedValue });
        pageContext.clearStackTrace();
        setSelectedValues(selectedValue);
        onChange(selectedValue);
    };

    const handleSearch = (value: any) => {
        const searchTerm = value.toLowerCase();
        if (!searchTerm) return options;
        const filtered = options.filter((option) => option.label.toLowerCase().includes(searchTerm));
        setFilteredOptions(filtered);
    };

    return (
        <DropDown
            label={label}
            selectable={!multiselectable}
            multiselectable={multiselectable}
            onChange={handleOnChange}
            disabled={disabled}
            labelAsPlaceholder={labelAsPlaceholder}
            defaultValues={defaultValue}
            tooltipText={tooltipText}
            classes={{ button: classesProp?.button }}
        >
            <>
                {searchable && (
                    <div className={classes.topContainer}>
                        <Input
                            name={'query'}
                            label={''}
                            type={'search'}
                            classes={{ container: classes.inputContainer, inputContainer: classes.input }}
                            onChange={(value: any) => {
                                handleSearch(value);
                            }}
                        />
                    </div>
                )}
                <div style={{ padding: searchable ? '0px' : '12px 8px' }}>
                    {(searchable ? filteredOptions : options).map((option) => {
                        const isSelected = selectedValues.includes(option.value);
                        return (
                            <DropDownItem key={option.value} value={option.value} className={classNames(classes.option, classesProp?.item)} label={option.label}>
                                {multiselectable ? <Checkbox name={name} value={option.value || undefined} label={option.label} checked={isSelected} onChange={() => {}} /> : option.label}
                            </DropDownItem>
                        );
                    })}
                </div>
            </>
        </DropDown>
    );
}

const useStyles = makeStyles((theme) => ({
    topContainer: {
        width: '100%',
        backgroundColor: theme.palette.surface.primary,
        position: 'sticky',
        top: 0,
    },
    inputContainer: {
        width: '100%',
        padding: 12,
        boxSizing: 'border-box',
    },
    input: {
        borderRadius: 24,
        width: '100%',
        height: 36,
        boxSizing: 'border-box',
    },
    option: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: theme.palette.text.primary,
        padding: '8px',
        width: '100%',
        borderRadius: 4,
        '&:hover': {
            backgroundColor: theme.palette.surface.secondary,
            cursor: 'pointer',
        },
    },
}));

type Props = {
    label?: string;
    name: string;
    options: Array<{
        value: string | null;
        label: string;
    }>;
    classes?: {
        item?: string;
        button?: string;
    };
    multiselectable?: boolean;
    disabled?: boolean;
    labelAsPlaceholder?: boolean;
    searchable?: boolean;
    defaultValue?: string | Array<any>;
    tooltipText?: string;
    onChange: any;
};
