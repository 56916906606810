/**
 * @prettier
 */
import { MenuItemTypes } from 'src/constants/DeprecatedMenuItemType';
import type { CartItemVm } from 'src/types/CartItemVm';
import { MenuId } from 'src/types/Id';
import type { OrderItemVm } from 'src/types/OrderVm';
import type { MenuItemVm } from 'src/types/PosMenuVm';
import { createCartItemKey } from 'src/utils/pos/createCartItemKey';
import { removeNulls } from 'src/utils/removeNulls';
import { emptyStringToUndefined } from 'src/utils/string/emptyStringToUndefined';

export function mapOrderItemToCartItem(menuItem: MenuItemVm, orderItem: OrderItemVm): CartItemVm {
    const item = removeNulls({
        menuItemId: menuItem.menuItemId,
        menuId: orderItem.menuId ?? menuItem.menuId,
        menuItemType: menuItem.isProduct ? MenuItemTypes.ITEM : MenuItemTypes.MODIFIER,
        menuCategoryId: orderItem.menuCategoryId,
        name: menuItem.name,
        unitPrice: getItemPrice(menuItem, orderItem),
        promoUnitPrice: getItemPromoPrice(menuItem, orderItem),
        promoText: getItemPromoText(menuItem, orderItem),
        imageUrl: menuItem.imageUrl,
        note: emptyStringToUndefined(orderItem.note),
        modifierGroups: orderItem.modifierGroups.map((modifierGroup) => ({
            name: modifierGroup.name,
            showModifierGroupNameInCommand: modifierGroup.showModifierGroupNameInCommand,
            freeModifiersQuantity: modifierGroup.freeModifiersQuantity,
            modifiers: modifierGroup.modifiers.map((modifier) => ({
                name: modifier.name,
                price: modifier.price,
                quantity: modifier.quantity,
            })),
        })),
        orderItemKitchenStatus: orderItem.orderItemKitchenStatus,
        quantity: orderItem.quantity,
        timesMoved: orderItem.timesMoved,
        addedAt: orderItem.addedAt,
        removedAt: orderItem.removedAt,
        salesUnit: orderItem.salesUnit,
        isSoldByWeight: orderItem.isSoldByWeight,
        itemsByCustomer: orderItem.itemsByCustomer,
        cancellationReason: orderItem.cancellationReason,
    });

    return { ...item, key: createCartItemKey(item) };
}

function getItemPrice(menuItem: MenuItemVm, orderItem: OrderItemVm): string {
    if (!menuItem.menusProductInfo || !orderItem.menuId) return menuItem.price;

    const menuItemInMenusProductInfo = menuItem.menusProductInfo?.find(
        (menuProductInfo: { hidden?: boolean; menuId: MenuId; price?: string; promoPrice?: string; promoText?: string }) => menuProductInfo.menuId === orderItem.menuId
    );
    return menuItemInMenusProductInfo?.price ?? menuItem.price;
}

function getItemPromoPrice(menuItem: MenuItemVm, orderItem: OrderItemVm): string | undefined {
    if (!menuItem.menusProductInfo || !orderItem.menuId) return menuItem?.promoPrice;

    const menuItemInMenusProductInfo = menuItem.menusProductInfo?.find(
        (menuProductInfo: { hidden?: boolean; menuId: MenuId; price?: string; promoPrice?: string; promoText?: string }) => menuProductInfo.menuId === orderItem.menuId
    );
    return menuItemInMenusProductInfo?.promoPrice ?? menuItem?.promoPrice;
}

function getItemPromoText(menuItem: MenuItemVm, orderItem: OrderItemVm): string | undefined {
    if (!menuItem.menusProductInfo || !orderItem.menuId) return menuItem?.promoText;

    const menuItemInMenusProductInfo = menuItem.menusProductInfo?.find(
        (menuProductInfo: { hidden?: boolean; menuId: MenuId; price?: string; promoPrice?: string; promoText?: string }) => menuProductInfo.menuId === orderItem.menuId
    );
    return menuItemInMenusProductInfo?.promoText ?? menuItem?.promoText;
}
