/**
 * @prettier
 */
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { BarChartCanvas } from 'src/components/charts/BarChartCanvas';
import { CashRegisterDepositReason, CashRegisterDepositReasons } from 'src/constants/CashRegisterDepositReason';
import { CashRegisterWithdrawReason, CashRegisterWithdrawReasons } from 'src/constants/CashRegisterWithdrawReason';
import { translate } from 'src/i18n/translate';
import { GraphIcon } from 'src/icons/GraphIcon';
import { TableIcon } from 'src/icons/TableIcon';
import { CashRegisterTransactionReasonsTableReport } from 'src/scenes/letseatmanager/posReports/cashRegisterTransactionsReport/CashRegisterTransactionReasonsTableReport';
import { GraphEmptyState } from 'src/scenes/letseatmanager/posReports/GraphEmptyState';
import type { CashRegisterTransactionsReportVm } from 'src/types/CashRegisterTransactionsReportVm';
import { CashRegisterCustomReason } from 'src/types/Id';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { sum } from 'src/utils/reduce/sum';
import { toShortId } from 'src/utils/uuid/toShortId';

export function CashRegisterTransactionReasonsReport({ cashRegisterTransactionsReport }: Props): React.ReactElement {
    const classes = useStyles();

    const [showTable, setShowTable] = useState(false);

    const getCashRegisterTransactionsBarChartData = () => {
        const cashRegisterTransactionReasons = getCashRegisterTransactionReasons();
        return cashRegisterTransactionReasons.map((cashRegisterTransactionReason) => {
            const transactionReasons: Array<string> = [];

            if (cashRegisterTransactionReason === CashRegisterWithdrawReasons.DRIVERS) transactionReasons.push(translate(`CashRegisterWithdrawReasons.${cashRegisterTransactionReason}`));
            if (cashRegisterTransactionReason === CashRegisterWithdrawReasons.DELIVERY) transactionReasons.push(translate(`CashRegisterWithdrawReasons.${cashRegisterTransactionReason}`));
            if (cashRegisterTransactionReason === CashRegisterDepositReasons.CHANGE) transactionReasons.push(translate(`CashRegisterDepositReasons.${cashRegisterTransactionReason}`));
            return {
                id: transactionReasons,
                ...getCashRegisterTransactionsByCashRegisterTransactionReason(cashRegisterTransactionReason),
            };
        });
    };

    const getCashRegisterTransactionsByCashRegisterTransactionReason = (cashRegisterTransactionReason: CashRegisterDepositReason | CashRegisterWithdrawReason | CashRegisterCustomReason) => {
        const cashRegisterTransactionsReasons: Record<string, any> = {};
        const cashRegisterTransactions = cashRegisterTransactionsReport.totalSummaryTransactionsByTransactionReason.filter(
            (cashRegisterTransaction) => cashRegisterTransaction.reason === cashRegisterTransactionReason
        );
        cashRegisterTransactions.forEach((cashRegisterTransaction) => {
            if (!cashRegisterTransactionsReasons[cashRegisterTransaction.cashRegisterId as string]) {
                cashRegisterTransactionsReasons[cashRegisterTransaction.cashRegisterId as string] = BigNumber(cashRegisterTransaction.transactions).toString();
                return;
            }
            cashRegisterTransactionsReasons[cashRegisterTransaction.cashRegisterId as string] = BigNumber(cashRegisterTransactionsReasons[cashRegisterTransaction.cashRegisterId as string])
                .plus(cashRegisterTransaction.transactions)
                .toString();
        });
        return cashRegisterTransactionsReasons;
    };

    const getCashRegisterIds = () => {
        const cashRegisterIds = cashRegisterTransactionsReport.totalSummaryTransactionsByTransactionReason.map((cashRegisterTransaction) => cashRegisterTransaction.cashRegisterId);
        return removeDuplicates(cashRegisterIds) ?? [];
    };

    const getCashRegisterTransactionReasons = () => {
        const cashRegisterTransactionReasons = cashRegisterTransactionsReport.totalSummaryTransactionsByTransactionReason.map((cashRegisterTransaction) => cashRegisterTransaction.reason);
        return removeDuplicates(cashRegisterTransactionReasons) ?? [];
    };

    const isEmptyReport = () => {
        const cashRegisterTransactions = cashRegisterTransactionsReport.totalSummaryTransactionsByTransactionReason.map((cashRegisterTransaction) => cashRegisterTransaction.transactions);
        const totalTransactions = cashRegisterTransactions.reduce(sum, BigNumber(0));
        return totalTransactions.isZero();
    };

    if (isEmptyReport()) {
        return <GraphEmptyState title={translate('Transaction reasons')} />;
    }

    return (
        <article className={classes.container}>
            <div className={classes.titleContainer}>
                <h2 className={classes.title}>{translate('Transaction reasons')}</h2>
                <IconButton onClick={() => setShowTable(!showTable)} className={(classes as any).iconButton}>
                    {showTable && <GraphIcon />}
                    {!showTable && <TableIcon />}
                </IconButton>
            </div>
            {!showTable && (
                <BarChartCanvas
                    data={getCashRegisterTransactionsBarChartData()}
                    keys={getCashRegisterIds()}
                    tooltip={({ id, value }: any) => {
                        return (
                            <div className={classes.tooltip}>
                                <span className={classes.tooltipTitle}>{translate('Cash register #@shortCashRegisterId', { shortCashRegisterId: toShortId(id) })}</span>
                                <span className={classes.tooltipText}>{translate('Transactions: @transactions', { transactions: value })}</span>
                            </div>
                        );
                    }}
                />
            )}
            {showTable && <CashRegisterTransactionReasonsTableReport totalSummaryTransactionsByTransactionReason={cashRegisterTransactionsReport.totalSummaryTransactionsByTransactionReason} />}
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '70vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '70vh',
        overflowY: 'scroll',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
    },
    tooltip: {
        padding: 10,
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipTitle: {
        fontFamily: theme.typography.bold,
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    cashRegisterTransactionsReport: CashRegisterTransactionsReportVm;
};
