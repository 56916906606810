/**
 * @prettier
 */
import { Auth } from 'aws-amplify';

export function useLogoutFromCognito(): () => Promise<void> {
    return async () => {
        await Auth.signOut();
    };
}
