/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { changeModifierGroupApi } from 'src/api/letseatmanager/modifierGroup/changeModifierGroupApi';
import { Button } from 'src/components/Button';
import { DraggableList } from 'src/components/DraggableList';
import { DraggableListItem } from 'src/components/DraggableListItem';
import { translate } from 'src/i18n/translate';
import { Modifier } from 'src/scenes/letseatmanager/menu/modifierGroup/Modifier';
import { SelectModifiersDialog } from 'src/scenes/letseatmanager/menu/modifierGroup/SelectModifiersDialog';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import { useLoadMenus } from 'src/services/menu/useLoadMenus';
import { useLoadModifierGroups } from 'src/services/modifierGroup/useLoadModifierGroups';
import { MenuItemId } from 'src/types/Id';
import type { ModifierGroupVm } from 'src/types/ModifierGroupVm';
import { ModifierVm } from 'src/types/ModifierVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { classNames } from 'src/utils/react/classNames';

export function ModifierGroup({ modifierGroup }: Props): React.ReactElement {
    const classes = useStyles();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();

    const [addModifiersDialogState, setAddModifiersDialogState] = useState({ open: false });

    const refreshModifierGroups = useLoadModifierGroups();
    const refreshMenus = useLoadMenus();

    const numberOfPreselectedModifiers = useMemo(() => {
        return modifierGroup?.modifiers?.reduce((preselectedModifiers: number, modifier: ModifierVm) => {
            if (modifier?.preselected) {
                return preselectedModifiers + 1;
            }

            return preselectedModifiers;
        }, 0);
    }, [modifierGroup]);

    const isLimitOfModifiersReached = !!modifierGroup?.requiredMax && BigNumber(modifierGroup?.requiredMax).isEqualTo(numberOfPreselectedModifiers);

    const openAddModifiersDialog = () => setAddModifiersDialogState({ open: true });

    const closeAddModifiersDialog = () => setAddModifiersDialogState({ open: false });

    const changeModifierGroup = async (modifiers: any) => {
        if (!modifierGroup) return;

        const response = await changeModifierGroupApi({
            modifierGroupId: modifierGroup.modifierGroupId,
            name: modifierGroup.name,
            externalModifierGroupId: modifierGroup.externalModifierGroupId,
            requiredMin: modifierGroup.requiredMin,
            requiredMax: modifierGroup.requiredMax,
            showModifierGroupNameInCommand: modifierGroup.showModifierGroupNameInCommand,
            hidden: modifierGroup.hidden,
            modifiers: modifiers,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        refreshMenusAndModifierGroups();
    };

    const onDragEnd = async (sortedModifierIds: any) => {
        const modifiers = sortedModifierIds.map((menuItemId: MenuItemId) => {
            const modifier = modifierGroup.modifiers.find((modifier) => modifier.menuItemId === menuItemId);
            return modifier;
        });
        await changeModifierGroup(modifiers);
    };

    const refreshMenusAndModifierGroups = () => {
        refreshMenus();
        refreshModifierGroups();
    };

    return (
        <div className={classes.modifiersContainer}>
            <SelectModifiersDialog open={addModifiersDialogState.open} defaultSelectedModifiers={modifierGroup.modifiers} onClose={closeAddModifiersDialog} onSuccess={changeModifierGroup} />
            <div className={classes.modifierGroupNameContainer}>
                <div>
                    <p className={classes.modifierGroupName}>{modifierGroup.name}</p>
                    {!!modifierGroup.requiredMax && (
                        <p className={classNames(classes.caption, { [classes.reached]: isLimitOfModifiersReached })}>{`${translate('Required Max')}: ${modifierGroup.requiredMax ?? ''}`}</p>
                    )}
                </div>
                <Button outlined onClick={openAddModifiersDialog} disabled={isMenuEditionDisabled}>
                    {translate('Add modifiers +')}
                </Button>
            </div>
            <DraggableList onDragEnd={onDragEnd}>
                {modifierGroup.modifiers?.map((modifier: ModifierVm) => (
                    <DraggableListItem key={modifier.menuItemId} value={modifier.menuItemId} iconColor={'#6C7076'} classes={{ container: classes.draggableItemContainer }}>
                        <Modifier
                            key={modifier.menuItemId}
                            modifierGroupId={modifierGroup.modifierGroupId}
                            disabled={isLimitOfModifiersReached}
                            modifier={modifier}
                            onChange={refreshMenusAndModifierGroups}
                        />
                    </DraggableListItem>
                ))}
            </DraggableList>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    modifiersContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    modifierGroupNameContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    modifierGroupName: {
        fontFamily: theme.typography.medium,
        fontSize: 17,
        margin: 0,
    },
    caption: {
        fontFamily: theme.typography.regular,
        color: '#87888B',
        fontSize: 12,
        margin: 0,
    },
    reached: {
        color: '#DB3956',
    },
    draggableItemContainer: {
        alignItems: 'center',
        marginBottom: 2,
    },
}));

type Props = {
    modifierGroup: ModifierGroupVm;
};
