/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { SalesByMenuCategoryFamily } from 'src/api/letseatmanager/restaurantDashboard/getMenuRestaurantStatisticsApi';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useSelector } from 'src/utils/react/useSelector';

export function SalesByMenuCategoryFamilyTable({ loading, salesByMenuCategoryFamilies }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const menuCategoryFamiliesEnabled = useSelector((state) => state.app.restaurant?.menuCategoryFamiliesEnabled);

    const columns = [
        {
            name: 'name',
            label: translate('Name'),
            options: {
                filter: true,
            },
        },
        {
            name: 'count',
            label: translate('Amount'),
            options: {
                filter: false,
            },
        },
        {
            name: 'countSales',
            label: translate('Sales'),
            options: {
                filter: false,
            },
        },
        {
            name: 'percentageOfSales',
            label: translate('% Sales'),
            options: {
                filter: false,
            },
        },
    ];

    if (!salesByMenuCategoryFamilies.length || !menuCategoryFamiliesEnabled) return null;

    return (
        <ReportSection title={translate('Sales by family')} loading={loading}>
            <div className={classes.splitContainer}>
                <div className={classes.sectionHeadKpi}>
                    <KpiCard
                        classes={{
                            container: classes.headingKpiContainer,
                            title: classes.headingKpiTitleKpi,
                            body: classes.headingKpiBody,
                            color: classes.headingKpiColor,
                            legend: classes.headingKpiLegendContainer,
                        }}
                        legend={salesByMenuCategoryFamilies[0]?.menuCategoryFamilyName}
                        title={translate('Best seller')}
                    />
                    <KpiCard
                        classes={{
                            container: classes.headingKpiContainer,
                            title: classes.headingKpiTitleKpi,
                            body: classes.headingKpiBody,
                            color: classes.headingKpiColor,
                            legend: classes.headingKpiLegendContainer,
                        }}
                        legend={`${salesByMenuCategoryFamilies[0]?.percentageOfSales}%`}
                        title={translate('% Sale')}
                    />
                </div>
                <div className={classes.container}>
                    <Table
                        data={salesByMenuCategoryFamilies.map((salesByMenuCategory, index) => ({
                            name: salesByMenuCategory.menuCategoryFamilyName,
                            count: salesByMenuCategory.orderVolume,
                            countSales: formatAsCurrencyNumber(salesByMenuCategory.total),
                            percentageOfSales: `${Math.round(salesByMenuCategory.percentageOfSales * 100) / 100} %`,
                        }))}
                        columns={columns}
                        options={{
                            tableBodyMaxHeight: '350px',
                            selectableRows: 'none',
                            responsive: 'standard',
                            filter: false,
                            rowsPerPage: 100,
                            viewColumns: false,
                            print: false,
                            download: true,
                            search: false,
                        }}
                    />
                </div>
            </div>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    splitContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',

        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'row-reverse',
            flexWrap: 'nowrap',
        },
    },
    sectionHeadKpi: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'column',
            width: '20%',
        },
    },
    headingKpiContainer: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '8px',
        margin: 5,
        textAlign: 'center',
        marginRight: '5%',
        marginBottom: '2%',
    },
    headingKpiTitleKpi: {
        fontFamily: theme.typography.regular,
        fontSize: 16,
        color: theme.palette.text.contrast,
    },
    headingKpiBody: {
        fontSize: 28,
        fontFamily: theme.typography.semiBold,
    },
    headingKpiColor: {
        width: 12,
        height: 12,
        borderRadius: 2,
    },
    headingKpiLegendContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
    },
}));

type Props = {
    loading: boolean;
    salesByMenuCategoryFamilies: Array<SalesByMenuCategoryFamily>;
};
