/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { Checkbox } from 'src/components/Checkbox';
import { SimpleTable } from 'src/components/SimpleTable';

/**
 * Create a selectable list with checkboxes in a table format.
 *
 * @param {string} title - The title of the list.
 * @param {Array} options - An array of options to display in the list.
 * @param {Function} onChange - A callback function triggered when selection changes.
 * @param {Array} defaultValue - An array of default selected values.
 * @param {boolean} disabled - If true, the checkboxes will be disabled.
 * @param {boolean} multiselectable - If true, multiple options can be selected.
 * @returns {React.Node} - A React component displaying a selectable list.
 */
export function SelectableList({ title, options, onChange, values: valuesProp, defaultValues, disabled, multiselectable, classes: classesProp }: Props): React.ReactElement {
    const classes = useStyles();

    const [values, setValues] = useState<Array<any>>([]);

    const handleSelectOption = (optionValue: any) => {
        if (!multiselectable || !values.length) {
            setValues([optionValue]);
            onChange([optionValue]);
            return;
        }

        setValues((prevValues) => {
            const valuesHasAlreadyBeenSelected = prevValues.includes(optionValue);
            const newValues = valuesHasAlreadyBeenSelected ? prevValues.filter((value) => value !== optionValue) : [...prevValues, optionValue];
            onChange(newValues);
            return newValues;
        });
    };

    const isRowChecked = (optionValue: any) => {
        return (valuesProp ?? values).includes(optionValue);
    };

    useEffect(() => {
        setValues(defaultValues ?? []);
    }, [defaultValues]);

    return (
        <div>
            <h2 className={classes.label}>{title}</h2>
            <div className={classesProp?.listContainer}>
                <SimpleTable
                    classes={{ cell: disabled ? classes.disabled : classes.tableCell }}
                    columns={[{ id: 'row', content: '' }]}
                    rows={options.map((option) => ({
                        row: (
                            <div className={classes.row}>
                                <Checkbox name={option.value} value={option.value} checked={isRowChecked(option.value)} label={''} onChange={() => {}} disabled={disabled} />
                                {option.content}
                            </div>
                        ),
                        value: option.value,
                        className: isRowChecked(option.value) ? classes.optionSelected : '',
                    }))}
                    onRowClick={(row: { value: any }) => handleSelectOption(row.value)}
                    hideHeaders
                />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    label: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: theme.palette.text.primary,
        marginTop: 0,
    },
    optionSelected: {
        backgroundColor: theme.palette.surface.brand,
    },
    tableCell: {
        height: '3rem',
    },
    disabled: {
        backgroundColor: theme.palette.surface.secondary,
        height: '3rem',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    title?: string;
    options: Array<{
        value: any;
        content: React.ReactNode;
    }>;
    onChange: (arg1: Array<any>) => void;
    values?: Array<any>;
    defaultValues?: Array<any>;
    disabled?: boolean;
    multiselectable?: boolean;
    classes?: {
        listContainer?: string;
    };
};
