/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { AdminCustomerVm } from 'src/types/AdminCustomerVm';
import { CustomerId } from 'src/types/Id';

export async function getCustomerApi(request: GetCustomerApiRequest): ApiSauceResponse<AdminCustomerVm> {
    return letseatadminApiMethod('customer/getCustomerApi', request);
}

type GetCustomerApiRequest = {
    customerId: CustomerId;
};
