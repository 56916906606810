/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CloseIcon from '@material-ui/icons/Close';
import { DateTimePicker } from '@material-ui/pickers';
import * as React from 'react';
import { useContext, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';
import { getError } from 'src/components/form/utils/getError';
import { translate } from 'src/i18n/translate';

export function FormDateTimePicker({ name, label, placeholder, helperText, defaultValue = null, minutesStep = 5, disabled, required, variant = 'inline' }: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>();
    const classes = useStyles();

    return (
        <Controller
            render={({ onChange, value, ref, ...props }) => (
                <div className={classes.inputContainer}>
                    <DateTimePicker
                        label={label}
                        placeholder={placeholder}
                        /* @ts-ignore */
                        name={name}
                        helperText={getError(errors, name)?.message ?? helperText}
                        disabled={isSubmitting || disabled || formContext.disabled}
                        error={!!getError(errors, name)}
                        // inputProps={restInput}
                        onChange={onChange}
                        // onBlur={onBlur}
                        value={!value ? null : value}
                        format='ddd lll'
                        ampm={false}
                        autoOk
                        fullWidth
                        variant={variant}
                        minutesStep={minutesStep}
                        required={required as any}
                        className={classes.inputContainer}
                        {...props}
                        InputProps={{
                            inputRef: inputRef,
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <CalendarTodayIcon fontSize='small' className={(classes as any).iconColor} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <>
                                    {!disabled && (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    onChange(null);
                                                }}
                                                size='small'
                                            >
                                                <CloseIcon fontSize='small' />
                                            </IconButton>
                                        </InputAdornment>
                                    )}
                                </>
                            ),
                        }}
                    />
                </div>
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        width: '100%',
        '& .MuiInputBase-root': {
            fontFamily: `${theme.typography.regular} !important`,
            fontSize: 12,
            backgroundColor: theme.palette.secondary.light,
            borderRadius: 8,
            padding: '8px 16px',
        },
        '& .MuiInputBase-root::before': {
            display: 'none',
        },
        '& .MuiInputBase-root::after': {
            display: 'none',
        },
        '& .MuiFormControl-root:first-child': {
            marginRight: 10,
        },
        '& label': {
            fontFamily: theme.typography.regular,
            fontSize: 14,
            transform: 'translate(0,0)',
            color: theme.palette.secondary.contrastText,
        },
    },
}));

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    minutesStep?: number;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: 'dialog' | 'inline' | 'static';
};
