/**
 * @prettier
 */
import { IconButton, makeStyles } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Button } from '@pidedirecto/ui';
import { PrinterIcon } from '@pidedirecto/ui/icons';
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { getClosedCashRegisterReportApi } from 'src/api/letseatmanager/pos/getClosedCashRegisterReportApi';
import { Accordion } from 'src/components/Accordion';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { usePrintShiftCashRegisterReport } from 'src/services/printer/usePrintShiftCashRegisterReport';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CashRegisterSummary } from 'src/types/PosBusinessDayReportVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { classNames } from 'src/utils/react/classNames';
import { isIntegrationApp } from 'src/utils/restaurant/isIntegrationApp';
import { translatePaymentMethod } from 'src/utils/translate/translatePaymentMethod';

export function CashRegisterInfo({ cashRegister, isLastItem }: Props): React.ReactElement {
    const classes = useStyles({ columns: !!cashRegister?.cashRegisterPosBusinessDayNumber ? 5 : 4 });
    const [printShiftCashRegisterReport] = usePrintShiftCashRegisterReport();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [seeMore, setSeeMore] = useState(false);
    const [loading, setLoading] = useState(false);

    const reprintCashRegisterShiftReport = async () => {
        if (!cashRegister.cashRegisterId) return;

        setLoading(true);
        const response = await getClosedCashRegisterReportApi({ cashRegisterId: cashRegister.cashRegisterId });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        await printShiftCashRegisterReport(response.data, true);
    };

    return (
        <div className={classNames(classes.container, isLastItem ? classes.noBorder : '')}>
            <div className={classes.tableRow}>
                <p className={(classes as any).text}>{formatDateTimeString(cashRegister.closedAt)}</p>
                {!!cashRegister?.cashRegisterPosBusinessDayNumber && (
                    <p className={(classes as any).text}> {translate('Cash register @cashRegisterNumber', { cashRegisterNumber: cashRegister?.cashRegisterPosBusinessDayNumber || '' })}</p>
                )}
                <p className={(classes as any).text}>{cashRegister.closedBy}</p>
                <p className={(classes as any).text}>{cashRegister.totalSoldWithoutIntegrations ?? cashRegister.totalSales}</p>
                <div className={classes.row}>
                    <p className={(classes as any).text}>{formatAsCurrencyNumber(cashRegister.totalSalesWithoutIntegrations ?? cashRegister.orders)}</p>
                    <IconButton onClick={() => setSeeMore(!seeMore)}>{seeMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                </div>
            </div>
            <Accordion open={seeMore} classes={{ container: classes.accordion }}>
                <div className={classes.infoContainer}>
                    <div className={classes.infoRow}>
                        <div className={classes.info}>
                            <Text variant='semiBold' size='large'>
                                {translate('Orders')}:
                            </Text>
                            <Text>{cashRegister.totalSoldWithoutIntegrations}</Text>
                        </div>
                        <div className={classes.info}>
                            <Text variant='semiBold' size='large'>
                                {translate('Initial Amount')}:
                            </Text>
                            <Text>{formatAsCurrencyNumber(cashRegister.initialAmount)}</Text>
                        </div>
                        <div className={classes.info}>
                            <Text variant='semiBold' size='large'>
                                {translate('Deposits')}:
                            </Text>
                            <Text>{formatAsCurrencyNumber(cashRegister.deposits)}</Text>
                        </div>
                        <div className={classes.info}>
                            <Text variant='semiBold' size='large'>
                                {translate('Withdraws')}:
                            </Text>
                            <Text>{formatAsCurrencyNumber(cashRegister.withdraws)}</Text>
                        </div>
                        <div className={classes.info}>
                            <Text variant='semiBold' size='large'>
                                {translate('Cancelled orders')}:
                            </Text>
                            <Text>{cashRegister.cancelledOrders}</Text>
                        </div>
                        <Button classes={{ button: classes.reprintButton }} variant={'outline'} onClick={reprintCashRegisterShiftReport} disabled={loading}>
                            <PrinterIcon />
                            {translate('Reprint')}
                        </Button>
                    </div>
                    <div className={classes.infoRow}>
                        <div className={classes.info}>
                            <Text variant='semiBold' size='large'>
                                {translate('Expected')}
                            </Text>
                            {cashRegister.paymentMethodReports.map((paymentMethodReport, idx) => (
                                <Text key={idx}>
                                    {`${translatePaymentMethod({ paymentMethod: paymentMethodReport.paymentMethod })} ${
                                        isIntegrationApp(paymentMethodReport.app!) ? translate(paymentMethodReport.app) : ''
                                    }`}
                                    :{formatAsCurrencyNumber(paymentMethodReport.expectedAmount)}
                                </Text>
                            ))}
                        </div>
                        <div className={classes.info}>
                            <Text variant='semiBold' size='large'>
                                {translate('Reported')}
                            </Text>
                            {cashRegister.paymentMethodReports.map((paymentMethodReport, idx) => (
                                <Text key={idx}>
                                    {`${translate(paymentMethodReport.paymentMethod)} ${isIntegrationApp(paymentMethodReport.app!) ? translate(paymentMethodReport.app) : ''}`}:
                                    {formatAsCurrencyNumber(paymentMethodReport.amount)}
                                </Text>
                            ))}
                        </div>
                        <div className={classes.info}>
                            <Text variant='semiBold' size='large'>
                                {translate('Difference')}
                            </Text>
                            {cashRegister.paymentMethodReports.map((paymentMethodReport, idx) => (
                                <Text key={idx}>
                                    {`${translate(paymentMethodReport.paymentMethod)} ${isIntegrationApp(paymentMethodReport.app!) ? translate(paymentMethodReport.app) : ''}`}:
                                    {formatAsCurrencyNumber(BigNumber(paymentMethodReport.amount).minus(paymentMethodReport.expectedAmount).toString())}
                                </Text>
                            ))}
                        </div>
                    </div>
                </div>
            </Accordion>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    accordion: {
        padding: 0,
        height: 'fit-content',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid #C4C8C9',
    },
    tableRow: (props: any) => ({
        display: 'grid',
        gridTemplateColumns: `repeat(${props.columns ?? 4}, 1fr)`,
    }),
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        width: '100%',
    },
    infoContainer: {
        height: 'fit-content',
        gap: 32,
        margin: 0,
        padding: '24px',
        paddingRight: 0,
        width: '100%',
        backgroundColor: '#F3F4F5',
    },
    reprintButton: {
        height: 24,
        margin: '0px 10px',
    },
    infoRow: {
        display: 'grid',
        gridTemplateColumns: 'repeat(6,1fr)',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 2fr))',
        },
        gap: 40,
        marginBottom: 16,
    },
    noBorder: {
        border: '0',
    },
}));

type Props = {
    cashRegister: CashRegisterSummary;
    isLastItem?: boolean;
};
