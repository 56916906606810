/**
 * @prettier
 */
import * as React from 'react';
import { findUserRolesApi } from 'src/api/letseatmanager/userRole/findUserRolesApi';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function FormUserRoleSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, onChange }: Props): React.ReactElement {
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const brandId = useSelector((state) => state.app.restaurant?.brandId);

    const [loading, userRoles] = useLoadApi(findUserRolesApi, { restaurantIds, brandId }, { initialValue: [], dependencies: [restaurantIds] });

    const options = required
        ? userRoles.map((userRole: any) => ({ label: userRole.roleName, value: userRole.userRoleId }))
        : [{ label: '', value: null }, ...userRoles.map((userRole: any) => ({ label: userRole.roleName, value: userRole.userRoleId }))];

    const handleChange = (userRoleId: any) => {
        const userRole = userRoles.find((userRole) => userRole.userRoleId === userRoleId);
        onChange?.(userRole);
    };

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled || loading}
            required={required}
            variant={variant}
            onChange={handleChange}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    onChange?: any;
};
