/**
 * @prettier
 */
import { Button } from '@pidedirecto/ui';
import * as React from 'react';
import { useState } from 'react';
import { CreateOrderIncidentDialog } from 'src/components/dialog/order/CreateOrderIncidentDialog';
import { translate } from 'src/i18n/translate';
import { OrderVm } from 'src/types/OrderVm';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateOrderIncidentButton({ order, disabled, onSuccess }: Props): React.ReactElement | null {
    const [createOrderIncidentDialogState, setCreateOrderIncidentDialogState] = useState({ open: false });

    const internalUser = useSelector((state) => state.authentication.internalUser);

    const orderHasExternalDelivery = order.externalDelivery || order.externalOwnFleet || order.ownFleet;

    const openCreateOrderIncidentDialog = () => setCreateOrderIncidentDialogState({ open: true });

    const closeCreateOrderIncidentDialog = () => setCreateOrderIncidentDialogState({ open: false });

    if (!isDeliveryOrder(order.orderType) || !internalUser || orderHasExternalDelivery) return null;

    return (
        <>
            <CreateOrderIncidentDialog open={createOrderIncidentDialogState.open} order={order} onClose={closeCreateOrderIncidentDialog} onSuccess={onSuccess} />
            <Button variant='secondary' disabled={disabled || order.hasOrderIncident} onClick={openCreateOrderIncidentDialog}>
                {translate('Register incident')}
            </Button>
        </>
    );
}

type Props = {
    order: OrderVm;
    disabled?: boolean;
    onSuccess?: () => void;
};
