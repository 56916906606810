/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function checkIfRappiCargoIsAvailableApi(request: Request): ApiSauceResponse<boolean> {
    return letseatadminApiMethod('rappiCargo/checkIfRappiCargoIsAvailable', request);
}

type Request = {
    restaurantId: RestaurantId;
};
