/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { CustomAlertDialogVm } from 'src/types/CustomAlertDialogVm';
import { RestaurantId } from 'src/types/Id';

export async function findActiveCustomAlertDialogApi(request: FindActiveCustomAlertDialogRequest): ApiSauceResponse<CustomAlertDialogVm> {
    return letseatmanagerApiMethod('customAlertDialog/findActiveCustomAlertDialogApi', request);
}

type FindActiveCustomAlertDialogRequest = {
    restaurantId: RestaurantId;
};
