/**
 * @prettier
 */
import { createContext } from 'react';
import * as React from 'react';
import { FormProvider } from 'react-hook-form';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function Form({ form, onSubmit, disabled, disableSubmitOnEnterKey, style, className, children, enabledForUserTypeView = false }: Props): React.ReactElement {
    const viewUser = useSelector((state) => state.authentication.viewUser);
    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    return (
        <FormProvider {...form}>
            <FormContext.Provider value={{ disabled: !!disabled }}>
                <form
                    role='form'
                    onSubmit={(event) => {
                        if (viewUser && !enabledForUserTypeView) {
                            showSnackbar({
                                message: translate('You dont have permission to perform this action!'),
                            });
                            event.preventDefault();
                            return;
                        }
                        if (disabled) return;
                        form.handleSubmit(onSubmit)(event);
                    }}
                    onKeyDown={
                        disableSubmitOnEnterKey
                            ? (event: any) => {
                                  if ((event.key || event.code) === 'Enter') event.preventDefault();
                              }
                            : undefined
                    }
                    style={style}
                    className={className}
                    noValidate
                >
                    {children}
                </form>
            </FormContext.Provider>
        </FormProvider>
    );
}

export const FormContext = createContext<{ disabled: boolean }>({ disabled: false as boolean });

type Props = {
    form: any;
    onSubmit?: any;
    disabled?: boolean;
    disableSubmitOnEnterKey?: boolean;
    style?: any;
    enabledForUserTypeView?: boolean;
    className?: any;
    children?: any;
};
