/**
 * @prettier
 */
import type { OrderVm } from 'src/types/OrderVm';
import { isItemSoldByWeight } from 'src/utils/orderItem/isItemSoldByWeight';

export function getTotalItem(order: OrderVm): number {
    return order.orderItems.reduce((total, orderItem) => {
        if (isItemSoldByWeight(orderItem)) {
            return total + 1;
        }

        return total + orderItem.quantity;
    }, 0);
}
