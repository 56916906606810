/**
 * @prettier
 */
import { createStore } from '@pidedirecto/ui/hooks';
import { CashRegisterExpectedAmountVm } from 'src/api/letseatmanager/pos/getCashRegisterExpectedAmountsApi';

export const [useExpectedAmountsStore, useExpectedAmountsActions] = createStore<State, Actions>({
    initialState: {
        expectedAmounts: [],
    },
    actions: {
        setExpectedAmounts: (state, expectedAmounts) => {
            state.expectedAmounts = expectedAmounts;
        },
    },
});

type State = {
    expectedAmounts: Array<CashRegisterExpectedAmountVm>;
};

export type Actions = {
    setExpectedAmounts: (expectedAmounts: Array<CashRegisterExpectedAmountVm>) => void;
};
