// this is an auto generated file. This will be overwritten

export const cancelDeliveryManager = /* GraphQL */ `
    mutation CancelDeliveryManager($orderId: ID!) {
        cancelDeliveryManager(orderId: $orderId) {
            orderId
            awsExecutionArn
            deliveryManagerStatus
            triedToRequestUberDirectDeliveryAt
            triedToRequestRappiCargoDeliveryAt
            error
            deliveryProviders
            dynamicDeliveryManager
            cyclicalDeliveryManager
            createdAt
            startAt
            startedAt
            restartAt
            restartedAt
            lastRunAt
            cancelledAt
            failedAt
            completedAt
        }
    }
`;
export const changeDeliveryManagerTrigger = /* GraphQL */ `
    mutation ChangeDeliveryManagerTrigger($orderId: ID) {
        changeDeliveryManagerTrigger(orderId: $orderId) {
            orderId
            awsExecutionArn
            deliveryManagerStatus
            triedToRequestUberDirectDeliveryAt
            triedToRequestRappiCargoDeliveryAt
            error
            deliveryProviders
            dynamicDeliveryManager
            cyclicalDeliveryManager
            createdAt
            startAt
            startedAt
            restartAt
            restartedAt
            lastRunAt
            cancelledAt
            failedAt
            completedAt
        }
    }
`;
export const restartDeliveryManager = /* GraphQL */ `
    mutation RestartDeliveryManager($orderId: ID!) {
        restartDeliveryManager(orderId: $orderId) {
            orderId
            awsExecutionArn
            deliveryManagerStatus
            triedToRequestUberDirectDeliveryAt
            triedToRequestRappiCargoDeliveryAt
            error
            createdAt
            startAt
            startedAt
            restartAt
            restartedAt
            lastRunAt
            cancelledAt
            failedAt
            completedAt
        }
    }
`;
export const completeDeliveryManager = /* GraphQL */ `
    mutation CompleteDeliveryManager($orderId: ID!) {
        completeDeliveryManager(orderId: $orderId) {
            orderId
            awsExecutionArn
            deliveryManagerStatus
            error
            createdAt
            startAt
            startedAt
            restartAt
            restartedAt
            lastRunAt
            cancelledAt
            failedAt
            completedAt
        }
    }
`;
export const startDeliveryManager = /* GraphQL */ `
    mutation StartDeliveryManager($orderId: ID!, $startAt: AWSDateTime) {
        startDeliveryManager(orderId: $orderId, startAt: $startAt) {
            orderId
            awsExecutionArn
            deliveryManagerStatus
            triedToRequestUberDirectDeliveryAt
            triedToRequestRappiCargoDeliveryAt
            error
            deliveryProviders
            dynamicDeliveryManager
            cyclicalDeliveryManager
            createdAt
            startAt
            startedAt
            restartAt
            restartedAt
            lastRunAt
            cancelledAt
            failedAt
            completedAt
        }
    }
`;
export const acceptDeliveryRequest = /* GraphQL */ `
    mutation AcceptDeliveryRequest($deliveryId: ID!, $autoAccepted: Boolean) {
        acceptDeliveryRequest(deliveryId: $deliveryId, autoAccepted: $autoAccepted) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const arrivedAtClientToHandOverDelivery = /* GraphQL */ `
    mutation ArrivedAtClientToHandOverDelivery($deliveryId: ID!) {
        arrivedAtClientToHandOverDelivery(deliveryId: $deliveryId) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const arrivedAtStoreToPickupDelivery = /* GraphQL */ `
    mutation ArrivedAtStoreToPickupDelivery($deliveryId: ID!) {
        arrivedAtStoreToPickupDelivery(deliveryId: $deliveryId) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const assignDriverToOrder = /* GraphQL */ `
    mutation AssignDriverToOrder($orderId: ID!, $driverId: ID!) {
        assignDriverToOrder(orderId: $orderId, driverId: $driverId) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const cancelDelivery = /* GraphQL */ `
    mutation CancelDelivery($deliveryId: ID!, $deliveryEarnings: String, $dontRestart: Boolean, $cancelReason: String) {
        cancelDelivery(deliveryId: $deliveryId, deliveryEarnings: $deliveryEarnings, dontRestart: $dontRestart, cancelReason: $cancelReason) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const cancelDeliveryRequest = /* GraphQL */ `
    mutation CancelDeliveryRequest($deliveryId: ID!) {
        cancelDeliveryRequest(deliveryId: $deliveryId) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const changeDeliveryEarnings = /* GraphQL */ `
    mutation ChangeDeliveryEarnings($deliveryId: ID!, $deliveryEarnings: String) {
        changeDeliveryEarnings(deliveryId: $deliveryId, deliveryEarnings: $deliveryEarnings) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const changeDeliveryTrigger = /* GraphQL */ `
    mutation ChangeDeliveryTrigger($deliveryId: ID) {
        changeDeliveryTrigger(deliveryId: $deliveryId) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const createDeliveryRequest = /* GraphQL */ `
    mutation CreateDeliveryRequest($orderId: ID!, $driverId: ID!) {
        createDeliveryRequest(orderId: $orderId, driverId: $driverId) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const distributeNextDeliveryRequest = /* GraphQL */ `
    mutation DistributeNextDeliveryRequest($orderId: ID!) {
        distributeNextDeliveryRequest(orderId: $orderId) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const markDeliveryDelivered = /* GraphQL */ `
    mutation MarkDeliveryDelivered($deliveryId: ID!) {
        markDeliveryDelivered(deliveryId: $deliveryId) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const markDeliveryPickedUp = /* GraphQL */ `
    mutation MarkDeliveryPickedUp($deliveryId: ID!) {
        markDeliveryPickedUp(deliveryId: $deliveryId) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const rejectDeliveryRequest = /* GraphQL */ `
    mutation RejectDeliveryRequest($deliveryId: ID!) {
        rejectDeliveryRequest(deliveryId: $deliveryId) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const withdrawDeliveryRequest = /* GraphQL */ `
    mutation WithdrawDeliveryRequest($deliveryId: ID!) {
        withdrawDeliveryRequest(deliveryId: $deliveryId) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const updateDriverPosition = /* GraphQL */ `
    mutation UpdateDriverPosition($driverId: ID!, $position: GeoJsonPointInput) {
        updateDriverPosition(driverId: $driverId, position: $position) {
            driverId
            position {
                type
                coordinates
            }
            positionedAt
        }
    }
`;
