/**
 * @prettier
 */
import { Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { cloneMenuApi } from 'src/api/letseatmanager/menu/cloneMenuApi';
import { getMenuApi } from 'src/api/letseatmanager/menu/getMenuApi';
import { Button } from 'src/components/Button';
import { Channels } from 'src/components/Channels';
import { Form } from 'src/components/form/Form';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { App, Apps } from 'src/constants/App';
import { translate } from 'src/i18n/translate';
import { useMenus } from 'src/services/menu/useMenus';
import type { MenuId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { hours } from 'src/utils/Validator';

export function CloneMenu({ menuId, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [channels, setChannels] = useState<Array<App>>([]);
    const [disabledChannels, setDisabledChannels] = useState<Array<App>>([]);
    const [loading, setLoading] = useState(false);

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const { menus } = useMenus();

    const [loadingMenu, menu] = useLoadApi(getMenuApi, { menuId: menuId! }, { requiredValues: [menuId], dependencies: [menuId] });

    const hasRappiMenu = menus.some((menu) => menu.channels?.includes(Apps.RAPPI));
    const hasDidiMenu = menus.some((menu) => menu.channels?.includes(Apps.DIDI_FOOD));

    useEffect(() => {
        const disabledChannels: Array<App> = [];
        if (hasDidiMenu) disabledChannels.push(Apps.DIDI_FOOD);
        if (hasRappiMenu) disabledChannels.push(Apps.RAPPI);
        setDisabledChannels(disabledChannels);
    }, [hasRappiMenu, hasDidiMenu]);

    useEffect(() => {
        if (menu) {
            form.reset({
                name: menu.name,
                hours: menu.hours || null,
                companyName: menu.companyName || null,
                hidden: menu.hidden || false,
            });
            let channels = menu.channels ?? [];
            if (hasDidiMenu) channels = channels.filter((channel) => channel !== Apps.DIDI_FOOD);
            if (hasRappiMenu) channels = channels.filter((channel) => channel !== Apps.RAPPI);
            setChannels(channels);
        }
    }, [menu, hasRappiMenu, hasDidiMenu]);

    const handleClose = () => {
        if (loading) return;
        onClose?.();
    };

    const onSubmit = async (form: any) => {
        if (!menuId) return;

        setLoading(true);
        const response = await cloneMenuApi({
            menuId,
            menuCategoryIds: menu.menuCategoryIds,
            name: form.name,
            hours: form.hours,
            companyName: form.companyName,
            hidden: form.hidden,
            channels,
        });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        onSuccess?.();
        handleClose();
    };

    const handleSelectChannels = (channels: any) => setChannels(channels);

    return (
        <Form form={form} onSubmit={onSubmit}>
            <h2 className={classes.title}>{menu?.name}</h2>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormTextField name='name' label={translate('Name')} required />
                </Grid>
                {internalUser && (
                    <Grid item xs={12}>
                        <FormTextField
                            name='hours'
                            label={translate('Hours')}
                            helperText={`${translate('E.g. "Mo-Fr 08:00-09:00", "Sa-Su" or "Mar Mo-Fr 11:00-14:00". see https://openingh.openstreetmap.de/evaluation_tool/#check')}`}
                            placeholder={'24/7'}
                            disabled={loading}
                            validate={hours}
                        />
                    </Grid>
                )}
                {internalUser && (
                    <Grid item xs={12}>
                        <FormTextField name='companyName' label={translate('Company Name')} disabled={loading} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FormSwitch name='hidden' label={translate('Hide')} disabled={loading} />
                </Grid>
                <Grid item xs={12}>
                    <span className={classes.text}>{translate('Select your sales channels')}</span>
                    <div className={classes.integrationChannelsContainer}>
                        <Channels onChange={handleSelectChannels} defaultSelectedChannels={channels} disabledChannels={disabledChannels} />
                    </div>
                </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
                {onClose && (
                    <Button secondary onClick={handleClose} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                )}
                <Button type='submit' disabled={loading || loadingMenu}>
                    {loading ? translate('Cloning') : translate('Clone')}
                </Button>
            </div>
        </Form>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.text.brand,
        textAlign: 'center',
        '& h2': {
            fontFamily: theme.typography.bold,
        },
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        margin: 0,
    },
    chipsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        gap: 5,
    },
    modifiersContainer: {
        margin: '10px 0',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    buttonsContainer: {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 20,
        '& button': {
            width: 200,
        },
    },
    integrationChannelsContainer: {
        width: '100%',
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
    },
}));

type Props = {
    menuId?: MenuId;
    onClose?: any;
    onSuccess?: any;
};
