/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import moment from 'moment/moment';
import * as React from 'react';
import { ResponsiveLineCanvas } from 'src/components/charts/ResponsiveLineCanvas';
import { translate } from 'src/i18n/translate';
import { GraphEmptyState } from 'src/scenes/letseatmanager/posReports/GraphEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CashRegisterReportVm } from 'src/types/CashRegisterReportVm';
import { sum } from 'src/utils/reduce/sum';

export function CashRegisterHistoricalSalesReport({ cashRegisterReport }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const getHistoricalSalesLineChartData = () => {
        return [
            {
                id: 'historicalSales',
                data: cashRegisterReport.historicalCashRegisterSales.map((historicalCashRegisterSales) => ({
                    x: moment(historicalCashRegisterSales.createdAt).format('YYYY-MM-DD HH:MM'),
                    y: historicalCashRegisterSales.total,
                })),
            },
        ];
    };

    const isEmptyReport = () => {
        const cashRegisterSales = cashRegisterReport.historicalCashRegisterSales.map((historicalCashRegisterSales) => historicalCashRegisterSales.total);
        const totalSales = cashRegisterSales.reduce(sum, BigNumber(0));
        return totalSales.isZero();
    };

    if (isEmptyReport()) {
        return <GraphEmptyState title={translate('Historical sales')} />;
    }

    return (
        <article>
            <h2 className={classes.title}>{translate('Historical sales')}</h2>
            <div className={classes.chartContainer}>
                <ResponsiveLineCanvas
                    data={getHistoricalSalesLineChartData()}
                    tooltip={({ point }: { point: { data: { x: string; y: string } } }) => (
                        <div className={classes.tooltip}>
                            <span className={classes.tooltipText}>{translate(`Date: @date`, { date: point.data.x })}</span>
                            <span className={classes.tooltipText}>{translate(`Total: @total`, { total: formatAsCurrencyNumber(point.data.y) })}</span>
                        </div>
                    )}
                />
            </div>
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    chartContainer: {
        height: '60vh',
        width: '100%',
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
    },
    tooltip: {
        padding: 10,
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    cashRegisterReport: CashRegisterReportVm;
};
