/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId, RestaurantManualEarningId } from 'src/types/Id';

export async function findRestaurantManualEarningsByRestaurantIdApi(request: FindRestaurantManualEarningsApiRequest): ApiSauceResponse<RestaurantManualEarningsVm> {
    return letseatadminApiMethod('restaurantManualEarning/findRestaurantManualEarningsByRestaurantIdApi', request);
}

export type FindRestaurantManualEarningsApiRequest = {
    restaurantId: RestaurantId;
    filter?: {
        from: Date;
        to: Date;
    };
};

export type RestaurantManualEarningsVm = {
    restaurantManualEarnings: Array<RestaurantManualEarningVm>;
};

type RestaurantManualEarningVm = {
    restaurantManualEarningId: RestaurantManualEarningId;
    restaurantId: RestaurantId;
    title: string;
    body?: string;
    comment?: string;
    amount: string;
    receivedAt: Date;
    modifiedAt: Date;
};
