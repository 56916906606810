/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect } from 'react';
import { app2 } from 'src/app2';
import { PasswordRequiredContent } from 'src/components/PasswordRequiredContent';
import { translate } from 'src/i18n/translate';
import { SubscriptionHistoryDetails } from 'src/scenes/letseatadmin/subscriptionHistory/SubscriptionHistoryDetail';
import type { SubscriptionHistoryId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';
import { useParams } from 'src/utils/react/useParams';

export function SubscriptionHistoryPage(): React.ReactElement {
    const setTitle = useAction(app2.actions.setTitle);
    const { subscriptionHistoryId } = useParams<{
        subscriptionHistoryId: SubscriptionHistoryId;
    }>();
    useEffect(() => {
        setTitle(`${translate('Subscription History')} > ${subscriptionHistoryId.substring(0, 8)}`);
    }, []);

    return (
        <PasswordRequiredContent>
            <Grid container spacing={5} style={{ paddingBottom: 40 }}>
                <Grid item xs={12}>
                    <SubscriptionHistoryDetails subscriptionHistoryId={subscriptionHistoryId} />
                </Grid>
            </Grid>
        </PasswordRequiredContent>
    );
}
