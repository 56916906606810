/**
 * @prettier
 */
export const SelfRegistrationStatuses = {
    STARTED: 'STARTED',
    FREE_TRIAL: 'FREE_TRIAL',
    SUBSCRIBED: 'SUBSCRIBED',
} as const;

export type SelfRegistrationStatus = typeof SelfRegistrationStatuses[keyof typeof SelfRegistrationStatuses];
