/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isRappiApp } from 'src/utils/order/isRappiApp';
import { isRoomServiceOrder } from 'src/utils/order/isRoomServiceOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function OrderCardCashInfo({ order, bigOrderCard = false }: Props): React.ReactElement | null {
    const classes = useStyles();

    const externalDelivery = useSelector((state) => state.app.restaurant.externalDelivery);

    if (externalDelivery) return null;

    const isManualOrderPaidByExternalPayment = order.manualOrder && order.externalCardPayment;

    return (
        <div style={{ width: '100%' }}>
            <div
                className={classNames(classes.spaceBetween, {
                    [classes.bigOrderCardBackground]: bigOrderCard,
                    [classes.simplifiedOrderCardBackground]: !bigOrderCard,
                })}
            >
                <div
                    className={classNames(classes.text, {
                        [classes.deliveryOrder]: isDeliveryOrder(order.orderType),
                        [classes.takeAwayOrder]: isTakeAwayOrder(order.orderType),
                        [classes.eatHereOrder]: isEatHereOrder(order.orderType),
                        [classes.roomServiceOrder]: isRoomServiceOrder(order.orderType),
                        [classes.bigOrderCardText]: bigOrderCard,
                    })}
                >
                    {!order.rappiCargoDeliveryId && !order.uberDaasDeliveryOrderId && !isRappiApp(order.app) && !isManualOrderPaidByExternalPayment
                        ? translate('The driver pays with cash 💵')
                        : translate('It is not needed receive cash')}
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    deliveryOrder: {
        color: '#133547',
    },
    takeAwayOrder: {
        color: '#0D7084',
    },
    eatHereOrder: {
        color: '#1A56AF',
    },
    roomServiceOrder: {
        color: '#C9E2FF',
    },
    bigOrderCardText: {
        color: '#FFFFFF',
    },
    bigOrderCardBackground: {
        backgroundColor: '#1B5764',
    },
    simplifiedOrderCardBackground: {
        backgroundColor: theme.palette.secondary.light,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 13,
        fontWeight: 'bold',
    },
    spaceBetween: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 30,
        borderRadius: '4px',
        marginTop: 5,
    },
}));

type Props = {
    order: OrderVm;
    bigOrderCard?: boolean;
};
