/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import * as React from 'react';
import { getCashTransactionsReportApi } from 'src/api/letseatmanager/cashRegister/getCashTransactionsReportApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { CashRegisterTotalTransactionsByManagerUserReport } from 'src/scenes/letseatmanager/posReports/cashRegisterTransactionsReport/CashRegisterTotalTransactionsByManagerUserReport';
import { CashRegisterTransactionReasonsReport } from 'src/scenes/letseatmanager/posReports/cashRegisterTransactionsReport/CashRegisterTransactionReasonsReport';
import { CashRegisterTransactionsByDayReport } from 'src/scenes/letseatmanager/posReports/cashRegisterTransactionsReport/CashRegisterTransactionsByDayReport';
import { CashRegisterTransactionsByMangerUserReport } from 'src/scenes/letseatmanager/posReports/cashRegisterTransactionsReport/CashRegisterTransactionsByMangerUserReport';
import { CashRegisterTransactionsCashFlowReport } from 'src/scenes/letseatmanager/posReports/cashRegisterTransactionsReport/CashRegisterTransactionsCashFlowReport';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { sum } from 'src/utils/reduce/sum';

export function CashRegisterTransactionsReport(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const period = useSelector((state) => state.app2.filterReportsState.period);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, cashRegisterTransactionsReport] = useLoadApi(
        getCashTransactionsReportApi,
        {
            restaurantIds,
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).toISOString(),
        },
        { dependencies: [restaurantIds, period] }
    );

    const getNumberOfTransactions = () => {
        const cashRegisterDepositTransactions = cashRegisterTransactionsReport.totalCashInflow.map((cashRegisterDepositReport) => cashRegisterDepositReport.transactions);
        const cashRegisterWithdrawsTransactions = cashRegisterTransactionsReport.totalCashOut.map((cashRegisterDepositReport) => cashRegisterDepositReport.transactions);
        const totalCashRegisterDeposits = cashRegisterDepositTransactions.reduce(sum, BigNumber(0));
        const totalCashRegisterWithdraws = cashRegisterWithdrawsTransactions.reduce(sum, BigNumber(0));
        return BigNumber(totalCashRegisterDeposits).plus(totalCashRegisterWithdraws).toString();
    };

    const getTotalCashRegisterDeposits = () => {
        const cashRegisterDeposits = cashRegisterTransactionsReport.totalCashInflow.map((cashRegisterDepositReport) => cashRegisterDepositReport.amount);
        return cashRegisterDeposits.reduce(sum, BigNumber(0)).toString();
    };

    const getTotalCashRegisterWithdraws = () => {
        const cashRegisterWithdraws = cashRegisterTransactionsReport.totalCashOut.map((cashRegisterDepositReport) => cashRegisterDepositReport.amount);
        return cashRegisterWithdraws.reduce(sum, BigNumber(0)).absoluteValue().toString();
    };

    const getTotalCashRegisterTransactions = () => {
        return BigNumber(getTotalCashRegisterDeposits()).minus(getTotalCashRegisterWithdraws()).toString();
    };

    const isEmptyReport = () => {
        if (!cashRegisterTransactionsReport) return true;
        return (
            cashRegisterTransactionsReport.totalCashInflow.length === 0 &&
            cashRegisterTransactionsReport.totalCashOut.length === 0 &&
            cashRegisterTransactionsReport.totalSummaryTransactionsByDay.length === 0 &&
            cashRegisterTransactionsReport.totalSummaryTransactionsByManagerUser.length === 0 &&
            cashRegisterTransactionsReport.totalSummaryTransactionsByTransactionReason.length === 0
        );
    };

    if (isEmptyReport()) {
        return (
            <div className={classes.container}>
                <UpdatingContentProgress loading={loading} top />
                <ReportSectionEmptyState />
            </div>
        );
    }

    return (
        <section className={classes.container}>
            <UpdatingContentProgress loading={loading} top />
            <article className={classes.kpiHeading}>
                <KpiCard
                    classes={{
                        container: classes.headingKpiContainer,
                        title: classes.headingKpiTitleKpi,
                        body: classes.headingKpiBody,
                        color: classes.headingKpiColor,
                        legend: classes.headingKpiLegendContainer,
                    }}
                    legend={getNumberOfTransactions()}
                    title={translate('Number of transactions')}
                />
                <KpiCard
                    classes={{
                        container: classes.headingKpiContainer,
                        title: classes.headingKpiTitleKpi,
                        body: classes.headingKpiBody,
                        color: classes.headingKpiColor,
                        legend: classes.headingKpiLegendContainer,
                    }}
                    legend={formatAsCurrencyNumber(getTotalCashRegisterDeposits())}
                    title={translate('Deposits')}
                />
                <KpiCard
                    classes={{
                        container: classes.headingKpiContainer,
                        title: classes.headingKpiTitleKpi,
                        body: classes.headingKpiBody,
                        color: classes.headingKpiColor,
                        legend: classes.headingKpiLegendContainer,
                    }}
                    legend={formatAsCurrencyNumber(getTotalCashRegisterWithdraws())}
                    title={translate('Withdraws')}
                />
                <KpiCard
                    classes={{
                        container: classes.headingKpiContainer,
                        title: classes.headingKpiTitleKpi,
                        body: classes.headingKpiBody,
                        color: classes.headingKpiColor,
                        legend: classes.headingKpiLegendContainer,
                    }}
                    legend={formatAsCurrencyNumber(getTotalCashRegisterTransactions())}
                    title={translate('Difference')}
                />
            </article>
            <div className={classes.reportsContainer}>
                <CashRegisterTransactionsCashFlowReport cashRegisterTransactionsReport={cashRegisterTransactionsReport} />
                <CashRegisterTransactionReasonsReport cashRegisterTransactionsReport={cashRegisterTransactionsReport} />
                <CashRegisterTransactionsByMangerUserReport cashRegisterTransactionsReport={cashRegisterTransactionsReport} />
                <CashRegisterTotalTransactionsByManagerUserReport cashRegisterTransactionsReport={cashRegisterTransactionsReport} />
                <CashRegisterTransactionsByDayReport cashRegisterTransactionsReport={cashRegisterTransactionsReport} />
            </div>
        </section>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        paddingBottom: 100,
    },
    kpiHeading: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        flexWrap: 'nowrap',
        [theme.breakpoints.between('xs', 'sm')]: {
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            flexWrap: 'nowrap',
        },
    },
    headingKpiContainer: {
        backgroundColor: 'transparent',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '8px',
        margin: 5,
        marginRight: '5%',
        marginBottom: '2%',
    },
    headingKpiTitleKpi: {
        fontFamily: theme.typography.regular,
        fontSize: 16,
        color: theme.palette.text.contrast,
        width: '100%',
        textAlign: 'center',
    },
    headingKpiBody: {
        fontSize: 28,
        fontFamily: theme.typography.semiBold,
    },
    headingKpiColor: {
        width: 12,
        height: 12,
        borderRadius: 2,
    },
    headingKpiLegendContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    reportsContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 20,
    },
}));
