/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { SalesType } from 'src/constants/SalesTypes';
import type { CashRegisterVm } from 'src/types/CashRegisterVm';
import { ManagerUserId, type CashRegisterId, type CustomPaymentMethod, type RestaurantId } from 'src/types/Id';

export async function closeCashRegisterApi(request: CloseCashRegisterApiRequest): ApiSauceResponse<CashRegisterVm> {
    return letseatmanagerApiMethod('pos/closeCashRegisterApi', request);
}

export type CloseCashRegisterApiRequest = {
    restaurantId: RestaurantId;
    cashRegisterId?: CashRegisterId;
    managerUserId?: ManagerUserId;
    cashRegisterReport?: Array<{
        paymentMethod: PaymentMethod;
        customPaymentMethod?: CustomPaymentMethod;
        amount: string;
        expectedAmount: string;
    }>;
    salesType: SalesType;
};
