/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { EmailAddress, RestaurantId } from 'src/types/Id';

export async function createPeruvianCompanyInvoiceApi(request: CreatePeruvianCompanyInvoiceApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('invoice/createPeruvianCompanyInvoiceApi', request);
}

export type CreatePeruvianCompanyInvoiceApiRequest = {
    restaurantId: RestaurantId;
    emailInvoices: EmailAddress;
    ruc: string;
    businessName: string;
    phoneNumber: string;
};
