/**
 * @prettier
 */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import SelectMaterialUi from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { hardDeleteDriverApi } from 'src/api/letseatadmin/development/hardDeleteDriverApi';
import { findDriverInPalencaApi, PalencaDriverVm } from 'src/api/letseatadmin/driver/findDriverInPalencaApi';
import { DriverVm, getDriverApi } from 'src/api/letseatadmin/driver/getDriverApi';
import { DriverIdPhotoVm, getDriverIdPhotoApi } from 'src/api/letseatadmin/driver/getDriverIdPhotoApi';
import { updateDriverIdImageApi } from 'src/api/letseatadmin/driver/updateDriverIdImageApi';
import { Button } from 'src/components/Button';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import Radio from 'src/components/deprecated/react-final-form/Radio';
import Select from 'src/components/deprecated/react-final-form/Select';
import SelectBankTypeahead from 'src/components/deprecated/react-final-form/SelectBankTypeahead';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { GoogleMapsDriverPosition } from 'src/components/googlemaps/GoogleMapsDriverPosition';
import { PrivateDriverIdImageUploader } from 'src/components/input/PrivateDriverIdImageUploader';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { HardDeleteToolbarButton } from 'src/components/mui-datatables/HardDeleteToolbarButton';
import { MobileToolbarButton } from 'src/components/mui-datatables/MobileToolbarButton';
import { OpenInNewTabToolbarButton } from 'src/components/mui-datatables/OpenInNewTabToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { SendNotificationToolbarButton } from 'src/components/mui-datatables/SendNotificationToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import { Cities } from 'src/constants/City';
import { RoutePaths } from 'src/constants/RoutePath';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import noImageIcon from 'src/images/icon-no-photo.png';
import { CHANGE_DRIVER, ChangeDriverDialog } from 'src/scenes/letseatadmin/driver/ChangeDriverDialog';
import { ChangeMobileNumberDriverDialog, ChangeMobileNumberDriverDialogState } from 'src/scenes/letseatadmin/driver/ChangeMobileNumberDriverDialog';
import { DriverIdImageDialog } from 'src/scenes/letseatadmin/driver/DriverIdImageDialog';
import { DriverSendDialog, SEND_NOTIFICATION } from 'src/scenes/letseatadmin/driver/DriverSendDialog';
import { uploadPrivateImage } from 'src/services/image/uploadPrivateImage';
import type { DriverId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { toCity } from 'src/utils/city/toCity';
import { fullName } from 'src/utils/customer/fullName';
import { formatDateTimeSecondsString } from 'src/utils/date/formatDateTimeSecondsString';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { minutesFromNow } from 'src/utils/date/minutesFromNow';
import { getDriverAvailability } from 'src/utils/driver/getDriverAvailability';
import { translateDriverAvailability } from 'src/utils/driver/translateDriverAvailability';
import { isDevelopmentEnvironment } from 'src/utils/environment/isDevelopmentEnvironment';
import { setUrlQueryParameter } from 'src/utils/history/setUrlQueryParameter';
import { useSelector } from 'src/utils/react/useSelector';
import { RegExps } from 'src/utils/RegExps';
import { requireValue } from 'src/utils/require/requireValue';

export function DriverDetails({ driverId, onChangeDriver }: Props): React.ReactElement {
    const classes = useStyles();
    const viewUser = useSelector((state) => state.authentication.viewUser);
    const [loading, setLoading] = useState(false);
    const [driver, setDriver] = useState<DriverVm | undefined>(undefined);
    const [driverIdPhoto, setDriverIdPhoto] = useState<DriverIdPhotoVm | undefined>(undefined);
    const [driverIdPhotoSelected, setDriverIdPhotoSelected] = useState<string | undefined>(undefined);
    const [open, setOpen] = useState(false);
    const [driverPalencaInfo, setDriverPalencaInfo] = useState<PalencaDriverVm | undefined>(undefined);
    const [uploadingImage, setUploadingImage] = useState<{ front: any; back: any }>({ front: null, back: null });

    const [changeMobileNumberDriverDialog, setChangeMobileNumberDriverDialogState] = useState<ChangeMobileNumberDriverDialogState>({
        open: false,
        driverId: undefined,
    });

    useEffect(() => {
        if (!driverId) return;
        load();
    }, [driverId]);

    const load = async () => {
        if (!driverId) {
            return;
        }
        setLoading(true);
        await getGeneralInfoDriver();
        await getPhotoIdDriver();
        setLoading(false);
        if (driver?.palencaDriverId) {
            setLoading(true);
            await getGeneralInfoPalencaDriver();
            setLoading(false);
        }
    };

    const getGeneralInfoDriver = async () => {
        if (!driverId) return;
        const response = await getDriverApi({ driverId });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const driver = response.data;
        setDriver(driver);
        onChangeDriver?.(driver);
    };

    const getPhotoIdDriver = async () => {
        if (!driverId) return;
        const response = await getDriverIdPhotoApi({ driverId });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const photoIdDriver = response.data;
        setDriverIdPhoto(photoIdDriver);
    };

    const getGeneralInfoPalencaDriver = async () => {
        const response = await findDriverInPalencaApi({
            palencaDriverId: requireValue(driver?.palencaDriverId),
            company: requireValue(driver?.signedUpWith),
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setDriverPalencaInfo(response.data);
    };

    const handleHardDeleteDriverClick = async () => {
        if (!driverId) return;
        setLoading(true);
        const response = await hardDeleteDriverApi({ driverId });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        await load();
        // setDriver(undefined);
    };

    const handleClickChangeMobileNumber = () => {
        setChangeMobileNumberDriverDialogState({ open: true, driverId: driverId });
    };

    const handleShowImageIdSelected = (imageId: undefined | string) => {
        const password = window.prompt('Please enter password ');

        if (password === '0674') {
            setDriverIdPhotoSelected(imageId);
            setOpen(true);
        }
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const handleChangeFrontImageUrl = (urlImage: any) => setUploadingImage({ ...uploadingImage, front: urlImage });

    const handleChangeBackImageUrl = (urlImage: any) => setUploadingImage({ ...uploadingImage, back: urlImage });

    const handleUpdateImageIds = async () => {
        let idFrontImageId = undefined;
        let idBackImageId = undefined;
        if (!driverId) return;

        if (uploadingImage.front) {
            const response = await uploadPrivateImage({
                fileName: uploadingImage.front?.fileName,
                imageBlob: uploadingImage.front?.imageBlob,
                type: uploadingImage.front?.type,
                imageType: uploadingImage.front?.imageType,
            });
            if (response.error) {
                alertKnownErrorOrSomethingWentWrong(response.error);
                return;
            }
            idFrontImageId = response.privateImageId;
        }
        if (uploadingImage.back) {
            const response = await uploadPrivateImage({
                fileName: uploadingImage.back?.fileName,
                imageBlob: uploadingImage.back?.imageBlob,
                type: uploadingImage.back?.type,
                imageType: uploadingImage.back?.imageType,
            });
            if (response.error) {
                alertKnownErrorOrSomethingWentWrong(response.error);
                return;
            }
            idBackImageId = response.privateImageId;
        }

        const uploadDriverIdImageResponse = await updateDriverIdImageApi({
            driverId,
            idBackImageId,
            idFrontImageId,
        });
        if (!uploadDriverIdImageResponse.ok) {
            alertKnownErrorOrSomethingWentWrong(uploadDriverIdImageResponse);
            return;
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <UpdatingContentProgress loading={loading} />
            <ChangeDriverDialog onDriverChanged={load} />
            <DriverSendDialog />
            <ChangeMobileNumberDriverDialog
                {...changeMobileNumberDriverDialog}
                onClose={() => {
                    setChangeMobileNumberDriverDialogState({ open: false, driverId: undefined });
                    load();
                }}
            />
            <DriverIdImageDialog open={open} driverIdPhoto={driverIdPhotoSelected} onClose={closeDialog} />
            <Card classes={{ root: classes.root }}>
                <CardHeader
                    title={
                        <Typography display='block' gutterBottom variant='h6'>
                            {translate('Driver Details')}
                        </Typography>
                    }
                    action={
                        <div className={classes.toolbar}>
                            {driverId && !viewUser && (
                                <>
                                    {isDevelopmentEnvironment() && (
                                        <HardDeleteToolbarButton
                                            text={translate('HARD DELETE')}
                                            tooltip={translate('HARD DELETE ALL ASSOCIATED DATA, ONLY AVAILABLE IN TEST ENVIRONMENTS')}
                                            onClick={handleHardDeleteDriverClick}
                                        />
                                    )}
                                    <RemoveToolbarButton onClick={() => window.open(RoutePaths.ADMIN_DRIVER.replace(`:driverId(${RegExps.uuid})`, driverId), '_blank')} />
                                    <OpenInNewTabToolbarButton onClick={() => window.open(RoutePaths.ADMIN_DRIVER.replace(`:driverId(${RegExps.uuid})`, driverId), '_blank')} />
                                    <ChangeToolbarButton onClick={() => history.push({ search: setUrlQueryParameter(CHANGE_DRIVER, driverId) })} />
                                    <SendNotificationToolbarButton onClick={() => history.push({ search: setUrlQueryParameter(SEND_NOTIFICATION, driverId) })} />
                                    <MobileToolbarButton onClick={handleClickChangeMobileNumber} />
                                </>
                            )}
                            <RefreshToolbarButton onClick={load} />
                        </div>
                    }
                />
                <CardContent>
                    <Form
                        onSubmit={() => {}}
                        initialValues={
                            driver
                                ? {
                                      driver: {
                                          driverId: driver.driverId,
                                          driverAvailability: translateDriverAvailability(getDriverAvailability(driver)),
                                          name: fullName(driver.firstName, driver.lastName),
                                          mobileNumber: driver.mobileNumber || '',
                                          email: driver.email || '',
                                          businessName: driver.businessName || '',
                                          claveId: driver.claveId || '',
                                          curp: driver.curp || '',
                                          bank: driver.bank || undefined,
                                          clabe: driver.clabe || '',
                                          rfc: driver.rfc || '',
                                          city: driver.city,
                                          vehicleType: driver.vehicleType ?? '',
                                          positionedInCity: toCity(driver.position),
                                          language: driver.language || '',
                                          fcmPermission: driver.fcmPermission ? 'Yes' : 'No',
                                          verified: driver.verified ? 'true' : 'false',
                                          appPaymentsEnabled: driver.appPaymentsEnabled ? 'true' : 'false',
                                          cashPaymentsEnabled: driver.cashPaymentsEnabled ? 'true' : 'false',
                                          prio: driver.prio,
                                          removed: driver.removed ? 'true' : 'false',
                                          banned: driver.banned ? 'true' : 'false',
                                          bannedByUsername: driver.banned ? driver.bannedByUsername : '',
                                          bannedAt: driver.banned ? formatDateTimeSecondsString(driver.bannedAt) : '',
                                          bannedUntil: driver.bannedUntil ? minutesFromNow(driver.bannedUntil) : '',
                                          appVersion: driver.appVersion || '',
                                          platform: driver.platform || '',
                                          platformVersion: driver.platformVersion || '',
                                          exportError: driver.exportError || '',
                                          downloadedAppAt: formatDateTimeStringReadable(driver.downloadedAppAt, TimeZones.AMERICA_MONTERREY) || 'Unknown',
                                          signedUpAt: formatDateTimeStringReadable(driver.signedUpAt, TimeZones.AMERICA_MONTERREY) || 'Unknown',
                                          firstDeliveryAt: formatDateTimeStringReadable(driver.firstDeliveryAt, TimeZones.AMERICA_MONTERREY) || 'Has not delivered yet',
                                          lastDeliveryAt: formatDateTimeStringReadable(driver.lastDeliveryAt, TimeZones.AMERICA_MONTERREY) || 'Has not delivered yet',
                                          positionedAt: formatDateTimeStringReadable(driver.positionedAt, TimeZones.AMERICA_MONTERREY) || 'Not yet positioned',
                                          locationPermission: driver.locationPermission || 'Not yet answered',
                                          exportedAt: formatDateTimeString(driver.exportedAt) || '',
                                          photoIdForward: driverIdPhoto?.idFrontImageUrl ?? '',
                                          photoIdBackWard: driverIdPhoto?.idBackImageUrl ?? '',
                                      },
                                      driverPalencaInfo: {
                                          palencaDriverInfoPlatform: driverPalencaInfo?.profile.platform || '',
                                          palencaDriverInfoRating: driverPalencaInfo?.profile.rating || '',
                                          palencaDriverInfoLifetimeTrips: driverPalencaInfo?.profile.lifetimeTrips || '',
                                          palencaDriverInfoLevelName: driverPalencaInfo?.profile.levelName || '',
                                          palencaDriverInfoLevelNumber: driverPalencaInfo?.profile.levelNumber || '',
                                          palencaDriverInfoMetricsInfoAcceptanceRate: driverPalencaInfo?.profile.metricsInfo?.acceptanceRate || '',
                                          palencaDriverInfoOtherInfoDebtPaid: driverPalencaInfo?.profile.otherInfo?.debtPending || '',
                                          palencaDriverInfoOtherInfoCashless: driverPalencaInfo?.profile.otherInfo?.cashless ? 'Verdadero' : 'Falso' || '',
                                          palencaDriverInfoOtherInfoIsExpert: driverPalencaInfo?.profile.otherInfo?.isExpert ? 'Verdadero' : 'Falso' || '',
                                          listVehicleInfoCourierType: driverPalencaInfo?.profile.listVehicleInfo?.transportMediaType,
                                      },
                                  }
                                : {}
                        }
                        render={({ handleSubmit, submitting, pristine, values }) => (
                            <Grid container spacing={3}>
                                <Grid item xs={6} className={classes.imageContainer}>
                                    <img
                                        className={classes.idImageContainer}
                                        src={!uploadingImage.front ? driverIdPhoto?.idFrontImageUrl ?? noImageIcon : uploadingImage.front?.imageUrl}
                                        onClick={() => handleShowImageIdSelected(driverIdPhoto?.idFrontImageUrl)}
                                    />
                                    <PrivateDriverIdImageUploader onChange={handleChangeFrontImageUrl} />
                                </Grid>
                                <Grid item xs={6} className={classes.imageContainer}>
                                    <img
                                        className={classes.idImageContainer}
                                        src={!uploadingImage.back ? driverIdPhoto?.idBackImageUrl ?? noImageIcon : uploadingImage.back?.imageUrl}
                                        onClick={() => handleShowImageIdSelected(driverIdPhoto?.idBackImageUrl)}
                                    />
                                    <PrivateDriverIdImageUploader onChange={handleChangeBackImageUrl} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button onClick={handleUpdateImageIds}>{translate('Save Changes')}</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field name='driver.driverAvailability' component={TextField} label={translate('Available')} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field name='driver.name' component={TextField} label={translate('Name')} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field name='driver.driverId' component={TextField} label={translate('Driver Id')} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field name='driver.mobileNumber' component={TextField} label={translate('Mobile Number')} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field name='driver.email' component={TextField} label={translate('Email')} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field name='driver.businessName' component={TextField} label={translate('Business Name/Name')} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field name='driver.claveId' component={TextField} label={translate('Clave Id')} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Field name='driver.exportError' component={TextField} label={translate('Export Error')} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field name='driver.curp' component={TextField} label={translate('CURP')} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field name='driver.vehicleType' component={TextField} label={translate('Vehicle')} fullWidth disabled />
                                </Grid>
                                {driver?.bank && (
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <Field name='driver.bank' component={SelectBankTypeahead} label={translate('Bank')} fullWidth disabled />
                                        </Grid>
                                        <Grid item xs={0 as any} sm={6}></Grid>
                                    </>
                                )}
                                {driver?.bank && (
                                    <Grid item xs={12} sm={6}>
                                        <Field name='driver.clabe' component={TextField} label={translate('CLABE')} fullWidth disabled />
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={6}>
                                    <Field name='driver.rfc' component={TextField} label={translate('RFC')} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <Field name='driver.city' label={translate('City')} component={Select} formControlProps={{ fullWidth: true }} fullWidth disabled>
                                                <MenuItem value=''>&nbsp;</MenuItem>
                                                {Object.values(Cities).map((city: any) => (
                                                    <MenuItem key={city} value={city}>
                                                        {translate(city)}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor='driver.positionedInCity' disabled>
                                                    {translate('Positioned In City')}
                                                </InputLabel>
                                                <SelectMaterialUi name='driver.positionedInCity' value={driver?.position ? toCity(driver?.position) : ''} disabled>
                                                    <MenuItem value=''>&nbsp;</MenuItem>
                                                    {Object.values(Cities).map((city: any) => (
                                                        <MenuItem key={city} value={city}>
                                                            {translate(city)}
                                                        </MenuItem>
                                                    ))}
                                                </SelectMaterialUi>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <RadioGroup row>
                                        <FormControlLabel
                                            label={translate('Not Verified')}
                                            control={<Field name='driver.verified' component={Radio} type='radio' color='primary' value='false' disabled />}
                                        />
                                        <FormControlLabel
                                            label={translate('Verified')}
                                            control={<Field name='driver.verified' component={Radio} type='radio' color='primary' value='true' disabled />}
                                        />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <RadioGroup row>
                                        <FormControlLabel
                                            label={translate('No App Payments')}
                                            control={<Field name='driver.appPaymentsEnabled' component={Radio} type='radio' color='primary' value='false' disabled />}
                                        />
                                        <FormControlLabel
                                            label={translate('App Payments')}
                                            control={<Field name='driver.appPaymentsEnabled' component={Radio} type='radio' color='primary' value='true' disabled />}
                                        />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <RadioGroup row>
                                        <FormControlLabel
                                            label={translate('No Cash Payments')}
                                            control={<Field name='driver.cashPaymentsEnabled' component={Radio} type='radio' color='primary' value='false' disabled />}
                                        />
                                        <FormControlLabel
                                            label={translate('Cash Payments')}
                                            control={<Field name='driver.cashPaymentsEnabled' component={Radio} type='radio' color='primary' value='true' disabled />}
                                        />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Field name='driver.prio' label={translate('Prio')} component={TextField} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12}>
                                    <RadioGroup row>
                                        <FormControlLabel
                                            label={translate('Not Removed')}
                                            control={<Field name='driver.removed' component={Radio} type='radio' color='primary' value='false' disabled />}
                                        />
                                        <FormControlLabel label={translate('Removed')} control={<Field name='driver.removed' component={Radio} type='radio' color='primary' value='true' disabled />} />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={5}>
                                            <RadioGroup row>
                                                <FormControlLabel
                                                    label={translate('Not Banned')}
                                                    control={<Field name='driver.banned' component={Radio} type='radio' color='primary' value='false' disabled />}
                                                />
                                                <FormControlLabel
                                                    label={translate('Banned')}
                                                    control={<Field name='driver.banned' component={Radio} type='radio' color='primary' value='true' disabled />}
                                                />
                                                <Field name='driver.bannedUntil' label={translate('Ban For X Minutes')} component={TextField} disabled />
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            {driver?.banned && <Field name='driver.bannedByUsername' component={TextField} label={translate('Banned By')} fullWidth disabled />}
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            {driver?.banned && <Field name='driver.bannedAt' component={TextField} label={translate('Banned At')} fullWidth disabled />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field name='driver.language' component={TextField} label={translate('Language')} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field name='driver.fcmPermission' component={TextField} label={translate('Push Notification')} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field name='driver.appVersion' component={TextField} label={translate('App Version')} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field name='driver.platform' component={TextField} label={translate('Platform')} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field name='driver.platformVersion' component={TextField} label={translate('Platform Version')} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field
                                        name='driver.downloadedAppAt'
                                        component={TextField}
                                        label={translate('Downloaded App At')}
                                        tooltip={formatDateTimeSecondsString(driver?.downloadedAppAt, TimeZones.ETC_UTC)}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field
                                        name='driver.signedUpAt'
                                        component={TextField}
                                        label={translate('Signed Up At')}
                                        tooltip={formatDateTimeSecondsString(driver?.signedUpAt, TimeZones.ETC_UTC)}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field
                                        name='driver.firstDeliveryAt'
                                        component={TextField}
                                        label={translate('First Delivery At')}
                                        tooltip={formatDateTimeSecondsString(driver?.firstDeliveryAt, TimeZones.ETC_UTC)}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field
                                        name='driver.lastDeliveryAt'
                                        component={TextField}
                                        label={translate('Last Delivery At')}
                                        tooltip={formatDateTimeSecondsString(driver?.lastDeliveryAt, TimeZones.ETC_UTC)}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <br />
                                <Grid item xs={12} sm={4}>
                                    <Field
                                        name='driver.positionedAt'
                                        component={TextField}
                                        label={translate('Positioned At')}
                                        tooltip={formatDateTimeSecondsString(driver?.lastDeliveryAt, TimeZones.ETC_UTC)}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field
                                        name='driver.locationPermission'
                                        component={TextField}
                                        label={translate('Location Permission')}
                                        tooltip={formatDateTimeSecondsString(driver?.lastDeliveryAt, TimeZones.ETC_UTC)}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field
                                        name='driver.exportedAt'
                                        component={TextField}
                                        label={translate('Exported At')}
                                        tooltip={formatDateTimeSecondsString(driver?.lastDeliveryAt, TimeZones.ETC_UTC)}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                {driver?.palencaDriverId && (
                                    <Grid item xs={12} sm={4}>
                                        <Field name='driverPalencaInfo.palencaDriverInfoPlatform' component={TextField} label={translate('External Platform')} fullWidth disabled />
                                    </Grid>
                                )}

                                {driver?.palencaDriverId && (
                                    <Grid item xs={12} sm={4}>
                                        <Field name='driverPalencaInfo.palencaDriverInfoRating' component={TextField} label={translate('External Rating')} fullWidth disabled />
                                    </Grid>
                                )}
                                {driver?.palencaDriverId && (
                                    <Grid item xs={12} sm={4}>
                                        <Field name='driverPalencaInfo.palencaDriverInfoLifetimeTrips' component={TextField} label={translate('External Total Trips')} fullWidth disabled />
                                    </Grid>
                                )}
                                {driver?.palencaDriverId && (
                                    <Grid item xs={12} sm={4}>
                                        <Field name='driverPalencaInfo.palencaDriverInfoLevelName' component={TextField} label={translate('External Level Name')} fullWidth disabled />
                                    </Grid>
                                )}
                                {driver?.palencaDriverId && (
                                    <Grid item xs={12} sm={4}>
                                        <Field name='driverPalencaInfo.palencaDriverInfoLevelNumber' component={TextField} label={translate('External Level Number')} fullWidth disabled />
                                    </Grid>
                                )}
                                {driver?.palencaDriverId && (
                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            name='driverPalencaInfo.palencaDriverInfoMetricsInfoAcceptanceRate'
                                            component={TextField}
                                            label={translate('External Acceptance Rate')}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                )}
                                {driver?.palencaDriverId && (
                                    <Grid item xs={12} sm={4}>
                                        <Field name='driverPalencaInfo.palencaDriverInfoOtherInfoDebtPaid' component={TextField} label={translate('External Debt')} fullWidth disabled />
                                    </Grid>
                                )}
                                {driver?.palencaDriverId && (
                                    <Grid item xs={12} sm={4}>
                                        <Field name='driverPalencaInfo.palencaDriverInfo_other_info_cashless' component={TextField} label={translate('External Cashless')} fullWidth disabled />
                                    </Grid>
                                )}
                                {driver?.palencaDriverId && (
                                    <Grid item xs={12} sm={4}>
                                        <Field name='driverPalencaInfo.palencaDriverInfoOtherInfoIsExpert' component={TextField} label={translate('Is Extenal Expert')} fullWidth disabled />
                                    </Grid>
                                )}
                                {driver?.palencaDriverId && (
                                    <Grid item xs={12} sm={4}>
                                        <Field name='driverPalencaInfo.listVehicleInfoCourierType' component={TextField} label={translate('Is Extenal Expert')} fullWidth disabled />
                                    </Grid>
                                )}
                                {driver?.position && <GoogleMapsDriverPosition position={driver?.position} />}
                            </Grid>
                        )}
                    />
                </CardContent>
            </Card>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    idImageContainer: {
        width: '100%',
        height: '200px',
        borderRadius: 10,
        maxWidth: 300,
        margin: 5,
    },
    toolbar: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    inputUrlImage: {
        width: '100%',
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    urlContainer: {
        height: '150px',
    },
}));

type Props = {
    driverId?: DriverId;
    onChangeDriver?: (arg1: DriverVm) => any;
    showLinkToDriver?: boolean;
};
