/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import moment from 'moment-timezone';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { SimpleTable } from 'src/components/SimpleTable';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { OwnFleetDriverReportDetailsVm, OwnFleetDriverReportVm } from 'src/types/OwnFleetDriverReportVm';
import { classNames } from 'src/utils/react/classNames';

export function OwnFleetDriverDetailsDialog({ open, onClose, ownFleetDriver }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const columns = [
        {
            id: 'createdAt',
            content: translate('Date'),
        },
        {
            id: 'orders',
            content: translate('Assigned'),
        },
        {
            id: 'completedOrders',
            content: translate('Completed'),
        },
        {
            id: 'totalDrivingDistance',
            content: translate('Kms'),
        },
        {
            id: 'totalDrivingDuration',
            content: translate('Hours'),
        },
        {
            id: 'totalDeliveryCost',
            content: translate('Costs'),
        },
    ];

    if (!ownFleetDriver) return null;

    return (
        <Dialog classes={{ dialog: classes.dialog }} open={open} onClose={onClose} title={ownFleetDriver.name}>
            <div className={classes.dialogContent}>
                <div className={classes.container}>
                    <div className={classes.separatedText}>
                        <span>{translate('Email')}</span>
                        <span>{ownFleetDriver?.email}</span>
                    </div>
                    <div className={classes.separatedText}>
                        <span>{translate('Phone Number')}</span>
                        <span>{ownFleetDriver?.mobileNumber}</span>
                    </div>
                    {ownFleetDriver?.vehicleType && (
                        <div className={classes.separatedText}>
                            <span>{translate('Vehicle')}</span>
                            <span>{translate(`VehicleTypes.${ownFleetDriver?.vehicleType}`)}</span>
                        </div>
                    )}
                    <div className={classes.separatedText}>
                        <span>{translate('Member since')}</span>
                        <span>{moment(ownFleetDriver?.createdAt).format('lll')}</span>
                    </div>
                </div>
                <SimpleTable
                    classes={{ table: classes.table, header: classNames(classes.header, classes.table) }}
                    columns={columns}
                    rows={ownFleetDriver?.driverDetails?.map((driver: OwnFleetDriverReportDetailsVm) => ({
                        createdAt: driver.createdAt,
                        orders: driver.orders,
                        completedOrders: driver.completedOrders,
                        totalDrivingDistance: driver.totalDrivingDistance,
                        totalDrivingDuration: driver.totalDrivingDuration,
                        totalDeliveryCost: formatAsCurrencyNumber(driver.totalDeliveryCost),
                    }))}
                />
            </div>
            <DialogActions>
                <Button onClick={onClose}>{translate('Close')}</Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gap: 8,
        fontSize: 14,
        fontFamily: theme.typography.regular,
        color: '#232933',
        paddingBottom: 12,
    },
    dialog: {
        [theme.breakpoints.up('sm')]: {
            minWidth: 700,
        },

        [theme.breakpoints.up('md')]: {
            minWidth: 800,
        },
    },
    dialogContent: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        color: '#232933',
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
    separatedText: {
        '& span': {
            display: 'block',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    table: {
        border: 'none',
        minHeight: 'unset',
        fontSize: '9px !important',
        [theme.breakpoints.up('sm')]: {
            fontSize: '10px !important',
        },
    },
    header: {
        fontSize: '12px !important',
    },
}));

type Props = {
    open: boolean;
    ownFleetDriver?: OwnFleetDriverReportVm;
    onClose: any;
};
