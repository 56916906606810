/**
 * @prettier
 */
export const PosOrderSteps = {
    SELECT_ORDER_TYPE: 'SELECT_ORDER_TYPE',
    SELECT_TABLE: 'SELECT_TABLE',
    CREATE_ORDER: 'CREATE_ORDER',
    PAYMENT: 'PAYMENT',
} as const;

export type PosOrderStep = typeof PosOrderSteps[keyof typeof PosOrderSteps];
