/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function PosMenuEmptyState(): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <h1 className={classes.title}>{translate("Couldn't find pos menus")}</h1>
            <p className={classes.text}>{translate("Couldn't find pos menus configured, please go to the menu section and create it.")}</p>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    title: {
        fontSize: 30,
        fontFamily: theme.typography.medium,
        color: theme.palette.secondary.contrastText,
        marginBottom: 20,
    },
    text: {
        fontSize: 20,
        fontFamily: theme.typography.regular,
        color: theme.palette.secondary.contrastText,
    },
}));
