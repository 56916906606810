/**
 * @prettier
 */
export const DeliveryTypes = {
    NORMAL: 'NORMAL',
    MULTI_PICKUP: 'MULTI_PICKUP',
    NEARBY: 'NEARBY',
} as const;

export type DeliveryType = typeof DeliveryTypes[keyof typeof DeliveryTypes];
