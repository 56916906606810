/**
 * @prettier
 */
import moment from 'moment-timezone';
import { convertTimeZone } from 'src/utils/date/convertTimeZone';
import { useSelector } from 'src/utils/react/useSelector';

export function useFormatDateToRestaurantTimeZone<T extends Date | string | undefined>(): (date: T) => T {
    const restaurantTimeZone = useSelector((state) => state.app.restaurant.timeZone);

    const getDateToRestaurantTimeZone = (date: T): T => {
        if (restaurantTimeZone === moment.tz.guess()) {
            return date;
        }
        return convertTimeZone(date, restaurantTimeZone);
    };

    return getDateToRestaurantTimeZone;
}
