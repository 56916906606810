/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function getRestaurantWithInventoryEnabledOptionsApi(): ApiSauceResponse<Array<RestaurantOptionVm>> {
    return letseatadminApiMethod('restaurant/getRestaurantWithInventoryEnabledOptionsApi');
}

export type RestaurantOptionVm = {
    label: string;
    value: RestaurantId;
};
