/**
 * @prettier
 */
import { useEffect } from 'react';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { useLoadModifierGroups } from 'src/services/modifierGroup/useLoadModifierGroups';
import { useNotification } from 'src/services/notification/useNotification';
import type { ModifierGroupId, RestaurantId } from 'src/types/Id';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function useModifierGroupSubscriptionUpdates() {
    const notification = useNotification();
    const loadModifierGroups = useLoadModifierGroups();

    const restaurantId = useSelector((state) => state.app.restaurantId);

    useEffect(() => {
        const menuModifierGroupCreatedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MODIFIER_GROUP_CREATED, handleModifierGroupsWebSocketEvents);
        const menuModifierGroupChangedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MODIFIER_GROUP_CHANGED, handleModifierGroupsWebSocketEvents);
        const menuModifierGroupRemovedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MODIFIER_GROUP_REMOVED, handleModifierGroupsWebSocketEvents);
        return () => {
            menuModifierGroupCreatedSyncWebSocketEvent.remove();
            menuModifierGroupChangedSyncWebSocketEvent.remove();
            menuModifierGroupRemovedSyncWebSocketEvent.remove();
        };
    }, [restaurantId]);

    const handleModifierGroupsWebSocketEvents = async (event: WebSocketEvent<ModifierGroupWebSocketEventResponse>) => {
        if (restaurantId !== event.data?.restaurantId) return;
        let message;
        const defaultMessage = translate('Synchronizing modifier groups');

        switch (event.webSocketEventType) {
            case WebSocketEventTypes.MODIFIER_GROUP_CREATED:
                message = translate(`Modifier Group @modifierGroupName created`, { modifierGroupName: event.data?.modifierGroupName });
                break;
            case WebSocketEventTypes.MODIFIER_GROUP_CHANGED:
                message = translate(`Modifier Group @modifierGroupName changed`, { modifierGroupName: event.data?.modifierGroupName });
                break;
            case WebSocketEventTypes.MODIFIER_GROUP_REMOVED:
                message = translate(`Modifier Group @modifierGroupName removed`, { modifierGroupName: event.data?.modifierGroupName });
                break;
            default:
                message = defaultMessage;
                break;
        }

        notification({ message: message });
        await loadModifierGroups();
    };
}

type ModifierGroupWebSocketEventResponse = {
    restaurantId: RestaurantId;
    modifierGroupId: ModifierGroupId;
    modifierGroupName: string;
};
