/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getDriverApi } from 'src/api/letseatadmin/driver/getDriverApi';
import { changeMobileNumberToDriverApi } from 'src/api/letseatmanager/driver/changeMobileNumberToDriverApi';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { DriverId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { requireValue } from 'src/utils/require/requireValue';

export function ChangeMobileNumberDriverDialog({ open, driverId, onClose }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) load();
    }, [open]);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const handleExited = () => {
        setLoading(false);
    };

    const load = async () => {
        setLoading(true);
        const response = await getDriverApi({ driverId: requireValue(driverId) });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            onClose();
            return;
        }
        const driver = response.data;
        form.reset(driver);
        setLoading(false);
    };

    const validatePasswordForChangeMobileNumber = () => {
        const password = prompt(translate('Password'));
        if (password !== '3513') {
            alert(translate('Invalid password'));
            return;
        } else {
            return true;
        }
    };

    const onSubmit = async (form: any) => {
        if (!validatePasswordForChangeMobileNumber()) return;

        const response = await changeMobileNumberToDriverApi({
            driverId: form.driverId,
            mobileNumber: form.mobileNumber,
        });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} onExited={handleExited}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Change Mobile Number To Driver').toUpperCase()}</DialogTitle>
                <DialogContent>
                    {loading ? (
                        <Loader size={40} loading={true} />
                    ) : (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormTextField name='driverId' label={translate('Driver Id')} disabled />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField name='firstName' label={translate('First Name')} disabled />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField name='mobileNumber' label={translate('Mobile Number')} multiline minLength={13} required />
                            </Grid>
                        </Grid>
                    )}
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={loading || isSubmitting}>
                        {isSubmitting ? translate('Sending').toUpperCase() : translate('Send').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = ChangeMobileNumberDriverDialogState & {
    onClose: any;
};

export type ChangeMobileNumberDriverDialogState = {
    open: boolean;
    driverId?: DriverId;
};
