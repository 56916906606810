/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { RestaurantId } from 'src/types/Id';
import { MenuCategoryFamilyVm } from 'src/types/MenuCategoryFamilyVm';

export async function createMenuCategoryFamilyApi(request: CreateMenuCategoryFamilyApiRequest): ApiSauceResponse<MenuCategoryFamilyVm> {
    return letseatmanagerApiMethod('menuCategoryFamily/createMenuCategoryFamilyApi', request);
}

export type CreateMenuCategoryFamilyApiRequest = {
    restaurantId: RestaurantId;
    name: string;
};
