/**
 * @prettier
 */
export const FilterReportTypes = {
    DAY: 'DAY',
    YESTERDAY: 'YESTERDAY',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    PERIOD: 'PERIOD',
} as const;

export type FilterReportType = typeof FilterReportTypes[keyof typeof FilterReportTypes];
