/**
 * @prettier
 */
import { CustomerType, CustomerTypes } from 'src/constants/CustomerType';
import { appColors } from 'src/theme/AppColors';

export function getCustomerTypeColor(customerType: CustomerType): string {
    switch (customerType) {
        case CustomerTypes.DOWNLOADED_APP:
        case CustomerTypes.SIGNED_UP:
        case CustomerTypes.SIGNED_UP_REJECTED:
        case CustomerTypes.SIGNED_UP_CANCELLED:
            return appColors.table.white;
        case CustomerTypes.INACTIVE:
            return appColors.table.blue;
        case CustomerTypes.ACTIVE_1_TO_2:
            return appColors.table.red;
        case CustomerTypes.ACTIVE_3_TO_5:
            return appColors.table.orange;
        case CustomerTypes.ACTIVE_6_TO_9:
            return appColors.table.yellow;
        case CustomerTypes.ACTIVE_10_OR_MORE:
            return appColors.table.green;
        default:
            return appColors.table.transparent;
    }
}
