/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useNotification } from '@pidedirecto/ui/hooks';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { memo, useState } from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { AddTipDialog } from 'src/scenes/letseatmanager/pos/AddTipDialog';
import { useAddPosTip } from 'src/services/pos/useAddPosTip';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export const PosAddTipButton: React.ComponentType<any> = memo<any>(() => {
    const classes = useStyles();

    const notification = useNotification();
    const addTip = useAddPosTip();

    const [addTipDialogState, setAddTipDialogState] = useState({ open: false });

    const payments = useSelector((state) => state.pos.payments);
    const disabledPosPaymentActions = useSelector((state) => state.pos.disabledPosPaymentActions);
    const paying = useSelector((state) => state.pos.paying);
    const total = useSelector((state) => state.pos.payment?.total);
    const totalPaid = useSelector((state) => state.pos.totalPaid);

    const setCustomerNumberSelected = useAction(posReducer.actions.setCustomerNumberSelected);

    const disabled = disabledPosPaymentActions || paying;
    const posPaymentIsPaymentLink = payments?.some((posPayment) => isPaymentLinkPayment(posPayment.paymentMethod));
    const hasPaymentCompleted = BigNumber(totalPaid).isGreaterThanOrEqualTo(total);

    const addPosTip = (tip: any) => {
        setCustomerNumberSelected(tip.customerNumber);
        addTip(tip);
        setAddTipDialogState({ open: false });
    };

    const openAddTipDialog = () => {
        if (!hasPaymentCompleted) return notification({ message: translate('Cannot add tip when payment is missing') });

        return setAddTipDialogState({ open: true });
    };

    return (
        <>
            <AddTipDialog open={addTipDialogState.open} onClose={() => setAddTipDialogState({ open: false })} onSuccess={addPosTip} />
            <Button secondary classes={{ button: classes.basicButton }} disabled={disabled || posPaymentIsPaymentLink} onClick={openAddTipDialog}>
                {translate('Add Tip')}
            </Button>
        </>
    );
});

const useStyles = makeStyles((theme) => ({
    basicButton: {
        width: '100%',
        height: 50,
        color: theme.palette.text.secondary,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
