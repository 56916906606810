/**
 * @prettier
 */
import { history } from 'src/config/history';

export function deleteUrlQueryParameter(name: string, location?: any): string {
    if (!location) {
        location = history.location;
    }
    const params = new URLSearchParams(location.search);
    params.delete(name);
    return params.toString().replace(/=$/, '').replace(/=&/, '&');
}
