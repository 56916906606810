/**
 * @prettier
 */
import * as React from 'react';
import { findDriverLivePositionsByCityApi } from 'src/api/letseatadmin/driverLivePosition/findDriverLivePositionsByCityApi';
import { Cities } from 'src/constants/City';
import { SECONDS } from 'src/constants/TimeUnit';
import { DriverMarker } from 'src/scenes/letseatadmin/liveDrivers/DriverMarker';
import { useInterval } from 'src/utils/react/useInterval';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function DriverMarkers(): React.ReactElement {
    const cities = useSelector((state) => state.app2.cities);
    const driverStatus = useSelector((state) => state.liveDrivers.driverStatus);

    const [loading, driverPositions, refreshDriverPositions] = useLoadApi(
        findDriverLivePositionsByCityApi,
        { city: cities?.[0] ?? Cities.MONTERREY, filter: driverStatus },
        { initialValue: { drivers: [] }, dependencies: [cities, driverStatus] }
    );

    useInterval(refreshDriverPositions, 20 * SECONDS, [cities, driverStatus]);

    return (
        <>
            {driverPositions.drivers.map((driverLivePosition) => (
                <DriverMarker key={driverLivePosition.driverId} driverLivePosition={driverLivePosition} />
            ))}
        </>
    );
}
