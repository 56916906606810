/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverArrivesAtStoreTime } from 'src/constants/DriverArrivesAtStoreTime';
import type { SubscriptionPlan } from 'src/constants/SubscriptionPlan';
import type { SubscriptionStatus } from 'src/constants/SubscriptionStatus';
import type { UberEatsStoreOfflineReason } from 'src/constants/UberEatsStoreOfflineReason';
import type { CashRegisterPosBusinessDayId, ManagerUserId, MenuId, RestaurantId } from 'src/types/Id';
import type { MinimumSupplyLimit } from 'src/types/MinimumSupplyLimit';
import type { OrderVm } from 'src/types/OrderVm';

export async function realTimeUpdatesApi(request: RealTimeUpdatesApiRequest): ApiSauceResponse<RealTimeUpdatesApiResponse> {
    return letseatmanagerApiMethod('realtimeUpdatesApi', request);
}

export type RealTimeUpdatesApiRequest = {
    restaurantId: RestaurantId;
    cashRegisterPosBusinessDayId?: CashRegisterPosBusinessDayId;
};

export type RealTimeUpdatesApiResponse = {
    managerMessageStyle?: string;
    managerMessageTitle?: string;
    managerMessageBody?: string;
    managerMessageUrl?: string;
    isCopyingMenu?: boolean;
    isRemovingMenu?: boolean;
    driverArrivesAtStoreTime?: DriverArrivesAtStoreTime;
    updatedOrders: Array<OrderVm>;
    updatedKitchenOrders: Array<OrderVm>;
    subscriptionPlan: SubscriptionPlan;
    subscriptionStatus?: SubscriptionStatus;
    remainingDays: number;
    payPerUsePaymentAmountLimitReached?: boolean;
    didiFoodStoreOnline?: boolean;
    uberEatsStoreOnline?: boolean;
    pedidosYaRestaurantOnline?: boolean;
    uberEatsStoreOfflineReason?: UberEatsStoreOfflineReason;
    uberEatsRdOptionalDisabled?: boolean;
    uberEatsStorePausedByUber?: boolean;
    rappiStoreOnline?: boolean;
    storeShouldBeOpen: boolean;
    cashRegisterOpen: boolean;
    quickActionMessage?: string;
    minimumSuppliesLimit?: Array<MinimumSupplyLimit>;
    currentEmployeesClockedIn?: Array<{
        managerUserId: ManagerUserId;
        clockedInAt: Date;
    }>;
    integrationMenuChangesApprovals?: Array<IntegrationMenuApprovalVm>;
};

export type IntegrationMenuApprovalVm = {
    menuId: MenuId;
    name: string;
    syncIntegrationChangesSucceeded?: boolean;
    syncIntegrationChangesFailed?: boolean;
    integrationChangesMessageError?: string;
};
