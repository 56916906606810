/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { assignCashRegisterPosBusinessDayForExternalMissingOrdersApi } from 'src/api/letseatmanager/pos/assignCashRegisterPosBusinessDayForExternalMissingOrdersApi';
import { openPosBusinessDayApi } from 'src/api/letseatmanager/pos/openPosBusinessDayApi';
import { openCashRegisterPosBusinessDayApi } from 'src/api/letseatmanager/pos/restaurantPosBusinessDay/openCashRegisterPosBusinessDayApi';
import { openPosBusinessDayAndCashRegisterPosBusinessDayApi } from 'src/api/letseatmanager/pos/restaurantPosBusinessDay/openPosBusinessDayAndCashRegisterPosBusinessDayApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { useFetchCashRegister } from 'src/services/cashRegister/useFetchCashRegister';
import { useFetchPosBusinessDaySummary } from 'src/services/cashRegister/useFetchPosBusinessDaySummary';
import { useConfirmDialog } from 'src/services/dialog/useConfirmDialog';
import { useNotification } from 'src/services/notification/useNotification';
import { CashRegisterPosBusinessDayId } from 'src/types/Id';
import { CashRegisterPosBusinessDayVm } from 'src/types/PosBusinessDayVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function OpenPosBusinessDayDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const { fetchPosBusinessDaySummary, loading: loadingPosBusinessDaySummary } = useFetchPosBusinessDaySummary();
    const { fetchOpenedCashRegister } = useFetchCashRegister();

    const confirmDialog = useConfirmDialog();
    const notification = useNotification();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurant?.restaurantId);
    const open = useSelector((state) => state.pos.openPosBusinessDayDialogState.open);
    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const managerUserId = useSelector((state) => state.authentication.pinCodeUserSignedIn?.managerUserId);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);
    const assignCashRegisterForExternalMissingOrdersEnabled = useSelector((state) => state.app.restaurant?.assignCashRegisterForExternalMissingOrdersEnabled);

    const setOpenedCashRegister = useAction(posReducer.actions.setOpenedCashRegister);
    const setOpenedCashRegisterPosBusinessDay = useAction(posReducer.actions.setOpenedCashRegisterPosBusinessDay);
    const setOpenedPosBusinessDay = useAction(posReducer.actions.setOpenedPosBusinessDay);
    const setCashRegisterOpen = useAction(posReducer.actions.setCashRegisterOpen);
    const closePosBusinessDayDialog = useAction(posReducer.actions.closePosBusinessDayDialog);

    const hasCashRegisterAssignedForExternalOrders = openedPosBusinessDay?.cashRegisterPosBusinessDays?.some(
        (cashRegisterPosBusinessDay: CashRegisterPosBusinessDayVm) => cashRegisterPosBusinessDay?.isForExternalMissingOrders
    );

    const handleClose = async () => {
        closePosBusinessDayDialog();
    };

    const handleOpenPosBusinessDay = async (amount: any) => {
        setLoading(true);
        const response = await openPosBusinessDayApi({
            restaurantId,
            managerUserId,
            initialAmount: amount,
        });
        setLoading(false);

        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);

        setOpenedPosBusinessDay(response.data);
        setCashRegisterOpen(true);
        fetchOpenedCashRegister();
    };

    const handleOpenCashRegisterPosBusinessDay = async (amount: any) => {
        setLoading(true);
        const response = await openCashRegisterPosBusinessDayApi({
            restaurantId,
            initialAmount: amount,
            managerUserId,
            posBusinessDayId: openedPosBusinessDay?.posBusinessDayId,
        });
        setLoading(false);

        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);

        await fetchPosBusinessDaySummary();
        setOpenedCashRegisterPosBusinessDay(response.data?.cashRegisterPosBusinessDay);
        setOpenedCashRegister(response?.data?.cashRegister);
        setCashRegisterOpen(true);

        if (assignCashRegisterForExternalMissingOrdersEnabled && !hasCashRegisterAssignedForExternalOrders) {
            assignCashRegisterForExternalOrders(response.data?.cashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId);
        }
    };

    const handleOpenPosBusinessDayAndCashRegisterPosBusinessDay = async (amount: any) => {
        setLoading(true);
        const response = await openPosBusinessDayAndCashRegisterPosBusinessDayApi({
            restaurantId,
            initialAmount: amount,
            managerUserId,
        });
        setLoading(false);

        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);

        if (assignCashRegisterForExternalMissingOrdersEnabled) {
            await confirmDialog({
                title: translate('Cash registers for external or auto accepted orders'),
                content: translate('This cash register will be used to receive external or auto accepted orders'),
                buttonText: translate('Accept'),
                variant: 'notification',
            });
        }

        setOpenedCashRegister(response?.data?.cashRegister);
        await fetchPosBusinessDaySummary();
        setOpenedCashRegisterPosBusinessDay(response.data?.posBusinessDay?.cashRegisterPosBusinessDays[0]);
        setOpenedPosBusinessDay(response.data?.posBusinessDay);
        setCashRegisterOpen(true);
    };

    const assignCashRegisterForExternalOrders = async (toCashRegisterPosBusinessDayId: undefined | CashRegisterPosBusinessDayId) => {
        if (!toCashRegisterPosBusinessDayId) return;

        const response = await assignCashRegisterPosBusinessDayForExternalMissingOrdersApi({ toCashRegisterPosBusinessDayId });

        if (!response.ok) return;

        await confirmDialog({
            title: translate('Cash registers for external or auto accepted orders'),
            content: translate('This cash register will be used to receive external or auto accepted orders'),
            buttonText: translate('Accept'),
            variant: 'notification',
        });

        setOpenedCashRegisterPosBusinessDay(response.data?.cashRegisterPosBusinessDay);
    };

    const handleSubmit = (form: any) => {
        if (BigNumber(form.amount).isNegative()) return notification({ message: translate('Wrong number') });

        if (!posMultipleCashRegistersEnabled) {
            handleOpenPosBusinessDay(form.amount);
        }

        if (posMultipleCashRegistersEnabled && openedPosBusinessDay) {
            handleOpenCashRegisterPosBusinessDay(form.amount);
        }

        if (posMultipleCashRegistersEnabled && !openedPosBusinessDay) {
            handleOpenPosBusinessDayAndCashRegisterPosBusinessDay(form.amount);
        }

        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Open Cash Register')}>
            <Form onSubmit={handleSubmit} form={form} className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormCurrencyNumberStringField name='amount' disabled={loading} label={translate('Initial Cash')} required />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={loading}>
                        <div className={(classes as any).cancelButton}>{translate('Cancel')}</div>
                    </Button>
                    <Button type='submit' disabled={loading || loadingPosBusinessDaySummary}>
                        <div className={(classes as any).acceptButton}>{translate('Open')}</div>
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}));
