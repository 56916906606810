/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesType } from 'src/constants/SalesTypes';
import type { RestaurantId } from 'src/types/Id';

export async function getIssueRestaurantStatisticsApi(request: GetIssueRestaurantStatisticsRequest): ApiSauceResponse<GetIssueStatisticsApiResponse> {
    return pidedirectoReportsMethod('/orderProblemsPageReportApi', request);
}

export type GetIssueRestaurantStatisticsRequest = {
    report: string;
    reportPage?: string;
    abcTableFilter?: string;
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
    salesType: SalesType;
};

export type GetIssueStatisticsApiResponse = Array<{
    cancelledAndRejectedOrdersByDayAndRejectReasons: Array<CancelledAndRejectedOrdersByDayAndRejectReasons>;
    name: string;
    notCompleteOrdersByDay: Array<NotCompleteOrdersByDay>;
    notCompleteOrdersByHour: Array<NotCompleteOrdersByHour>;
    notCompleteOrdersVSCompleteOrdersComparisonByDay: Array<NotCompleteOrdersVSCompleteOrdersComparisonByDay>;
    restaurantId: RestaurantId;
    totalLostSales: string;
    mostCommonOrderProblem: string;
}>;

export type CancelledAndRejectedOrdersByDayAndRejectReasons = {
    createdAt: string;
    orderRejectReason: string;
    orderStatus: string;
    orderVolume: number;
    total: number;
};

export type NotCompleteOrdersByDay = {
    createdAt: string;
    orderStatus: string;
    orderVolume: number;
    total: number;
};

export type NotCompleteOrdersByHour = {
    createdAt: string;
    orderStatus: string;
    orderVolume: number;
    total: number;
    menuId: string;
    cancelled: number;
    rejected: number;
};

export type NotCompleteOrdersVSCompleteOrdersComparisonByDay = {
    createdAt: string;
    orderStatus: string;
    orderVolume: number;
    total: number;
};
