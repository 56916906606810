/**
 * @prettier
 */
import { Grid, makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import { useEffect, useState } from 'react';
import * as React from 'react';
import type { SalesByApp } from 'src/api/letseatmanager/restaurantDashboard/getGeneralRestaurantStatisticsApi';
import { PieChartCanvas } from 'src/components/charts/PieChartCanvas';
import { translate } from 'src/i18n/translate';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function ChannelGeneralGraph({ colors, loading, deliveryTypeData }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [salesByApp, setSalesByApp] = useState<Array<PieData>>([]);
    const [totalDeliveryTypeSale, setTotalDeliveryTypeSale] = useState(0);

    useEffect(() => {
        getDeliveryTypeFromReports(deliveryTypeData);
    }, [deliveryTypeData]);

    const getDeliveryTypeFromReports = (salesPerDeliveryProvider: Array<SalesByApp>) => {
        if (!salesPerDeliveryProvider) {
            setSalesByApp([]);
            setTotalDeliveryTypeSale(0);
            return;
        }
        const totalSales = salesPerDeliveryProvider.reduce((acc, current) => acc + current.total, 0);
        const typeDeliveryItems = getDeliveryTypeReportData(salesPerDeliveryProvider);
        const salesByAppSorted = typeDeliveryItems.sort((itemA, itemB) => itemB.value - itemA.value);

        setSalesByApp(salesByAppSorted);
        setTotalDeliveryTypeSale(BigNumber(totalSales).toNumber());
    };

    const getDeliveryTypeReportData = (deliveryTypeData: Array<SalesByApp>): Array<PieData> => {
        const typeDeliveryItems: Array<PieData> = [];
        deliveryTypeData.forEach((typeDeliveryItem) => {
            const isDeliveryItem = typeDeliveryItems.find((elem) => elem.id === translate(`Apps.${typeDeliveryItem.app}`));
            if (!isDeliveryItem) {
                typeDeliveryItems.push({
                    id: translate(`Apps.${typeDeliveryItem.app}`),
                    label: translate(`Apps.${typeDeliveryItem.app}`),
                    value: typeDeliveryItem.total,
                });

                return;
            }
            isDeliveryItem.value = isDeliveryItem.value + typeDeliveryItem.total;
        });

        return typeDeliveryItems;
    };

    return (
        <ReportSection title={translate('Channels')} loading={loading} total={formatAsCurrencyNumber(totalDeliveryTypeSale)}>
            <Grid container className={(classes as any).infoContainer}>
                <Grid item md={9} className={classes.graphContainer}>
                    <PieChartCanvas data={salesByApp} valueFormat={(value) => formatAsCurrencyNumber(value)} />
                </Grid>
                <Grid item md={3} className={classes.kpisContainer}>
                    {salesByApp?.map((itemType: PieData, index: number) => (
                        <KpiCard key={`${itemType.id}-${index}`} legend={formatAsCurrencyNumber(itemType.value)} title={translate(itemType.id)} color={colors[index]} />
                    ))}
                </Grid>
            </Grid>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    graphContainer: {
        width: '100%',
        height: '45vh',
    },
    kpisContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '95%',
        height: 'auto',
        justifyContent: 'space-around',
        gap: 12,
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100%',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            flexWrap: 'nowrap',
        },
    },
}));

type Props = {
    colors: Array<string>;
    deliveryTypeData: Array<SalesByApp>;
    loading: boolean;
};

type PieData = {
    id: string;
    label: string;
    value: number;
};
