/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import moment from 'moment-timezone';
import { isEmpty } from 'src/utils/object/isEmpty';

export class LocalizationService {
    static formatDateTimeAsTime(date: any, timeZone: any): any {
        if (!date) {
            return '';
        }
        return moment.tz(date, timeZone).calendar(null, {
            sameDay: 'LT',
            sameElse: 'llll',
        });
    }

    static formatDateTimeAsCalendarDate(date: any): any {
        if (!date) {
            return '';
        }

        return moment.utc(date).from(moment.utc());
    }

    static formatCurrency(number: any, restaurant: any): any {
        if ((!number && number !== 0) || !restaurant) {
            return '';
        }

        if (isEmpty(restaurant.currencyFormat)) {
            const fractionDigits = BigNumber(number).isInteger() ? 0 : 2;
            const numberFormat = Intl.NumberFormat('es-MX', {
                style: 'decimal',
                currency: 'MXN',
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
            });
            return numberFormat.format(number);
        }

        const numberFormat = new Intl.NumberFormat(restaurant.currencyFormat.locale, {
            style: 'currency',
            currency: restaurant.currencyFormat.currency,
            minimumFractionDigits: restaurant.currencyFormat.fractionDigits,
            maximumFractionDigits: restaurant.currencyFormat.fractionDigits,
        });
        return numberFormat.format(number);
    }
}
