/**
 * @prettier
 */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment/moment';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import type { FilterState } from 'src/components/input/FromToFilter';
import { OpenInNewTabToolbarButton } from 'src/components/mui-datatables/OpenInNewTabToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { RoutePaths } from 'src/constants/RoutePath';
import CustomerPayrollPaymentsTable from 'src/scenes/letseatadmin/companyPayrollBilling/CustomerPayrollPaymentsTable';
import PayrollBillingOrdersTable from 'src/scenes/letseatadmin/companyPayrollBilling/PayrollPaymentsTable';
import { OrdersTable } from 'src/scenes/letseatadmin/order/OrdersTable';
import type { CompanyId, CustomerId, EmployeeIdAtCompany, EmployeeNumberAtCompany, OrderId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { deepEqual } from 'src/utils/object/deepEqual';
import { RegExps } from 'src/utils/RegExps';

export default withStyles((theme) => ({
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class CompanyPayrollBillingDataForCompany extends React.Component<Props, State> {
        state = {
            loading: false,
            companyId: '' as any,
            companyName: '',
            customers: [],
            paidWithPayroll: '0',
            payrollPayments: [],
            notAccepted: undefined,
        };

        async componentDidMount() {
            await this.load();
        }

        async componentDidUpdate(prevProps: Props, prevState: State) {
            if (!deepEqual(prevProps.filter, this.props.filter)) {
                await this.load();
            }
        }

        // shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
        //     return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
        // }

        load = async () => {
            this.setState({ loading: true });
            const request: Request = {
                companyId: this.props.companyId,
                from: moment(this.props.filter.from).format('YYYY-MM-DD'),
                to: moment(this.props.filter.to).format('YYYY-MM-DD'),
            };
            const response = await letseatmanagerApiDeprecated.admin.getCompanyPayrollBillingDataForCompany(request);
            this.setState({ loading: false });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            this.setState({ ...response.data });
            this.props.onLoad && this.props.onLoad(response.data.companyName);
        };

        render() {
            const { classes } = this.props;
            return (
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <UpdatingContentProgress loading={this.state.loading} />
                        <Card classes={{ root: classes.root }}>
                            <CardHeader
                                title={
                                    <Typography display='block' gutterBottom variant='h6'>
                                        {this.state.companyName}
                                    </Typography>
                                }
                                action={
                                    <div>
                                        {this.props.companyId && (
                                            <>
                                                <OpenInNewTabToolbarButton
                                                    onClick={() => window.open(RoutePaths.ADMIN_COMPANY.replace(`:companyId(${RegExps.uuid})`, this.props.companyId), '_blank')}
                                                />
                                            </>
                                        )}{' '}
                                        <RefreshToolbarButton onClick={this.load} />
                                    </div>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={3}>
                                        <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                            Total Paid With Payroll
                                        </Typography>
                                        <Typography variant='body1' gutterBottom>
                                            {this.state.paidWithPayroll}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                            Number of orders
                                        </Typography>
                                        <Typography variant='body1' gutterBottom>
                                            {this.state.payrollPayments.length}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomerPayrollPaymentsTable title='CustomersInfo' customers={this.state.customers} loading={this.state.loading} onClickRefresh={this.load} />
                    </Grid>
                    <Grid item xs={12}>
                        <PayrollBillingOrdersTable title='Payroll Payments' payrollPayments={this.state.payrollPayments} loading={this.state.loading} onClickRefresh={this.load} />
                    </Grid>
                    {this.state.notAccepted && (
                        <Grid item xs={12}>
                            <OrdersTable title='WARNING ORDERS NOT ACCEPTED YET' orders={this.state.notAccepted} loading={this.state.loading} onClickRefresh={this.load} />
                        </Grid>
                    )}
                </Grid>
            );
        }
    }
) as React.ComponentType<any>;

type Props = {
    filter: FilterState;
    companyId: CompanyId;
    onLoad?: (arg1: string) => Record<any, any>;
    classes: any;
};

type State = {
    loading: boolean;
} & CompanyPayrollBillingDataVm;

type Request = {
    from: string;
    to: string;
    companyId: CompanyId;
};

type CompanyPayrollBillingDataVm = {
    companyId: CompanyId;
    companyName: string;
    paidWithPayroll: string;
    customers: Array<{
        customerId: CustomerId;
        customerName: string;
        mobileNumber: string;
        email: string;
        employeeIdAtCompany?: EmployeeIdAtCompany;
        employeeNumberAtCompany?: EmployeeNumberAtCompany;
        paidWithPayroll: string;
        orders: number;
    }>;
    payrollPayments: Array<PayrollPaymentVm>;
    notAccepted?: Array<OrderVm>;
};

export type PayrollPaymentVm = {
    orderId: OrderId;
    customerId: CustomerId;
    createdAt: Date;
    paidWithPayroll: string;
    customerName: string;
    mobileNumber: string;
    email: string;
    employeeIdAtCompany?: EmployeeIdAtCompany;
    employeeNumberAtCompany?: EmployeeNumberAtCompany;
};
