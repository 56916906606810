/**
 * @prettier
 */
import { usePosCustomerActions } from 'src/modules/pos/customer/posCustomerStore';

export function useSetCustomerLastName() {
    const setCustomerLastName = usePosCustomerActions((actions) => actions.setCustomerLastName);

    return setCustomerLastName;
}
