/**
 * @prettier
 */

import { Button, Dialog, DialogActions, SelectableList } from '@pidedirecto/ui';
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { translate } from 'src/i18n/translate';
import { useMenuItems } from 'src/services/menuItem/useMenuItems';
import { MenuItemId } from 'src/types/Id';

export function SelectProductsDialog({ open, defaultSelectedMenuItemIds, onClose, onSelect }: Props): React.ReactElement {
    const { products } = useMenuItems();

    const [selectedMenuItemIds, setSelectedMenuItemIds] = useState<Array<MenuItemId>>([]);

    const options = products.map((menuItem) => ({
        value: menuItem.menuItemId,
        label: menuItem.name,
    }));

    useEffect(() => {
        if (open && defaultSelectedMenuItemIds) setSelectedMenuItemIds(defaultSelectedMenuItemIds);
    }, [open, defaultSelectedMenuItemIds]);

    const selectMenuItems = (menuItemIds: Array<MenuItemId>) => {
        setSelectedMenuItemIds(menuItemIds);
    };

    const saveMenuItems = () => {
        onSelect(selectedMenuItemIds);
        setSelectedMenuItemIds([]);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} title={translate('Select your products')}>
            <SelectableList options={options} onChange={selectMenuItems} value={selectedMenuItemIds} multiselectable searchable virtualized height={400} />
            <DialogActions>
                <Button variant='secondary' onClick={onClose}>
                    {translate('Cancel')}
                </Button>
                <Button onClick={saveMenuItems}>{translate('Save')}</Button>
            </DialogActions>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    defaultSelectedMenuItemIds?: Array<MenuItemId>;
    onClose: () => void;
    onSelect: (menuItemIds: Array<MenuItemId>) => void;
};
