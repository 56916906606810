/**
 * @prettier
 */

import { usePosCartModifiersStore } from 'src/services/pos/posModifiersCart/posCartModifiersStore';

export function useMissingSectionName(): string | undefined {
    const missingSectionName = usePosCartModifiersStore((state) => state.missingSectionName);

    return missingSectionName;
}
