/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { Button } from 'src/components/Button';
import { ChangeOrderPaymentMethodDialog } from 'src/components/dialog/order/ChangeOrderPaymentMethodDialog';
import { SelectCustomerDialog } from 'src/components/dialog/order/SelectCustomerDialog';
import { Tooltip } from 'src/components/Tooltip';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import type { OrderVm } from 'src/types/OrderVm';
import { isEcommerceApp } from 'src/utils/app/isEcommerceApp';
import { isKioskApp } from 'src/utils/app/isKioskApp';
import { isLetsEatApp } from 'src/utils/app/isLetsEatApp';
import { isPedidosYaApp } from 'src/utils/app/isPedidosYaApp';
import { isPideDirectoApp } from 'src/utils/app/isPideDirectoApp';
import { isPosApp } from 'src/utils/app/isPosApp';
import { hasExternalDelivery } from 'src/utils/order/hasExternalDelivery';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { isPaid } from 'src/utils/payment/isPaid';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';

export function ChangeOrderPaymentMethodButton({ order, disabled, onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();

    const [changeOrderPaymentDialogState, setChangeOrderPaymentDialogState] = useState<{ open: boolean; customerNumber: number | undefined }>({ open: false, customerNumber: undefined });
    const [selectCustomerDialogState, setSelectCustomerDialogState] = useState({ open: false });
    const [changeOrderPaymentTooltipState, setChangeOrderPaymentTooltipState] = useState({ open: false, message: '' });

    const isPosOrderPending = isPendingOrder(order.orderStatus) && isPosApp(order.app);
    const isEcommerceOrderPaidWithCard = (isEcommerceApp(order.app) || isPideDirectoApp(order.app) || isLetsEatApp(order.app)) && isCardPayment(order.paymentMethod);
    const isOrderPaidWithPaymentLink = isPaymentLinkPayment(order.paymentMethod) && isPaid(order.paymentStatus);
    const isKioskOrderPaidWithTerminal = isKioskApp(order.app) && order.paidWithTerminal;
    const isPosOrderPaidWithTerminal = isPosApp(order.app) && order.paidWithTerminal;
    const hasCurrencies = order.payments?.some((payment: OrderPaymentVm) => !!payment.restaurantCurrency);
    const orderHasDriver = !!order.driverName || !!order.driverId;
    const deliveryOrderCanChangePaymentMethod = hasExternalDelivery(order) || !orderHasDriver;

    const handleChangePaymentDialog = () => {
        if (isChangePaymentMethodDisabled()) return showChangePaymentMethodError();
        const posPayments = getValidPosPayments();
        const hasPaymentByCustomer = posPayments.some((posPayment) => !!posPayment.customerNumber);
        if (!isPosApp(order.app) || !hasPaymentByCustomer) {
            openChangeOrderPaymentDialog(undefined);
            return;
        }
        openSelectOrderPosCustomerDialog();
    };

    const isChangePaymentMethodDisabled = () => {
        return isEcommerceOrderPaidWithCard || isOrderPaidWithPaymentLink || isKioskOrderPaidWithTerminal || isPosOrderPaidWithTerminal;
    };

    const showChangePaymentMethodError = () => {
        if (isPosOrderPending)
            setChangeOrderPaymentTooltipState({ open: true, message: translate('Is not possible to change the payment method for orders pending payment. Edit order to add a payment method') });
        if (isEcommerceOrderPaidWithCard) setChangeOrderPaymentTooltipState({ open: true, message: translate('Is not possible to change the payment method for ecommerce orders paid with card') });
        if (isOrderPaidWithPaymentLink) setChangeOrderPaymentTooltipState({ open: true, message: translate('Is not possible to change the payment method for orders with payment link already paid') });
        if (isKioskOrderPaidWithTerminal) setChangeOrderPaymentTooltipState({ open: true, message: translate('Is not possible to change the payment method for kiosk orders paid with terminal') });
        if (isPosOrderPaidWithTerminal) setChangeOrderPaymentTooltipState({ open: true, message: translate('Is not possible to change the payment method for pos orders paid with terminal') });

        setTimeout(() => {
            setChangeOrderPaymentTooltipState({ open: false, message: '' });
        }, 5 * SECONDS);
    };

    const getValidPosPayments = () => {
        if (!order.paidWithTerminal) return order.payments ?? [];
        return order.payments?.filter((posPayment: OrderPaymentVm) => !isCardPayment(posPayment.paymentMethod)) ?? [];
    };

    const openSelectOrderPosCustomerDialog = () => setSelectCustomerDialogState({ open: true });

    const closeSelectOrderPosCustomerDialog = () => setSelectCustomerDialogState({ open: false });

    const closeChangeOrderPaymentDialog = () => setChangeOrderPaymentDialogState({ open: false, customerNumber: undefined });

    const openChangeOrderPaymentDialog = (customerNumber: number | undefined) => setChangeOrderPaymentDialogState({ open: true, customerNumber });

    const handleGoBackFromChangeOrderPaymentMethodDialog = () => {
        closeChangeOrderPaymentDialog();
        openSelectOrderPosCustomerDialog();
    };

    if ((!deliveryOrderCanChangePaymentMethod && isDeliveryOrder(order.orderType)) || isPedidosYaApp(order.app)) return null;

    return (
        <div className={classes.container}>
            <ChangeOrderPaymentMethodDialog
                open={changeOrderPaymentDialogState.open}
                order={order}
                customerNumber={changeOrderPaymentDialogState.customerNumber}
                onClose={closeChangeOrderPaymentDialog}
                onSuccess={onSuccess}
                onGoBack={handleGoBackFromChangeOrderPaymentMethodDialog}
            />
            <SelectCustomerDialog open={selectCustomerDialogState.open} order={order} onClose={closeSelectOrderPosCustomerDialog} onSuccess={openChangeOrderPaymentDialog} />
            <Tooltip forceOpen={changeOrderPaymentTooltipState.open} text={changeOrderPaymentTooltipState.message}>
                <Button secondary disabled={disabled || hasCurrencies} onClick={handleChangePaymentDialog}>
                    {translate('Change payment method')}
                </Button>
            </Tooltip>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    optionDisabled: {
        opacity: 0.5,
    },
}));

type Props = {
    order: OrderVm;
    disabled?: boolean;
    onSuccess?: any;
};
