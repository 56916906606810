/**
 * @prettier
 */
export const SubscriptionPaymentMethods = {
    CARD: 'CARD',
    SPEI: 'SPEI',
    SPEI_INTERNAL: 'SPEI_INTERNAL',
    DEBT: 'DEBT',
} as const;

export type SubscriptionPaymentMethod = typeof SubscriptionPaymentMethods[keyof typeof SubscriptionPaymentMethods];
