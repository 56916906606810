/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, Text } from '@pidedirecto/ui';
import * as React from 'react';
import { useState } from 'react';
import { translate } from 'src/i18n/translate';
import { usePrintOrderCommand } from 'src/services/printer/usePrintOrderCommand';
import { OrderItemVm, OrderVm } from 'src/types/OrderVm';
import { splitGroupedItems } from 'src/utils/order/splitGroupedItems';
import { isItemSoldByWeight } from 'src/utils/orderItem/isItemSoldByWeight';
import { createOrderItemKey } from 'src/utils/pos/createOrderItemKey';

export function PrintOrderCommandDialog({ open, order, onClose }: Props): React.ReactElement {
    const [printOrderCommand] = usePrintOrderCommand();
    const classes = useStyles();

    const [selectedOrderItems, setSelectedOrderItems] = useState<Array<SelectedOrderItem>>([]);

    const closeDialog = () => {
        onClose();
        setSelectedOrderItems([]);
    };

    const printCommand = async () => {
        const orderItems = getOrderItemsToPrint();
        await printOrderCommand({ ...order, orderItems }, true);
        closeDialog();
    };

    const getOrderItemsToPrint = () => {
        const orderItemKeysToPrint = selectedOrderItems.map((selectedOrderItem) => selectedOrderItem.orderItemKey);

        const orderItemsToPrint = order.orderItems.filter((orderItem) => {
            const orderItemKey = createOrderItemKey({ ...orderItem, quantity: isItemSoldByWeight(orderItem) ? orderItem.quantity : 1 });
            return orderItemKeysToPrint.includes(orderItemKey);
        });

        return orderItemsToPrint.map((orderItem) => {
            const orderItemKey = createOrderItemKey({ ...orderItem, quantity: isItemSoldByWeight(orderItem) ? orderItem.quantity : 1 });
            const timesSelectedOrderItem = orderItemKeysToPrint.filter((orderItemKeyToPrint) => orderItemKeyToPrint === orderItemKey).length;
            return {
                ...orderItem,
                quantity: isItemSoldByWeight(orderItem) ? orderItem.quantity : timesSelectedOrderItem,
            };
        });
    };

    const selectOrderItem = (orderItem: OrderItemVm, position: number) => {
        const orderItemKey = createOrderItemKey(orderItem);
        const isOrderItemSelected = selectedOrderItems.some((selectedOrderItem) => selectedOrderItem.orderItemKey === orderItemKey && selectedOrderItem.position === position);
        if (isOrderItemSelected) {
            setSelectedOrderItems(selectedOrderItems.filter((selectedOrderItem) => selectedOrderItem.orderItemKey !== orderItemKey || selectedOrderItem.position !== position));
            return;
        }

        setSelectedOrderItems([...selectedOrderItems, { orderItemKey, position }]);
    };

    return (
        <Dialog open={open} onClose={closeDialog} title={translate('Reprint Command')}>
            <DialogContent>
                <Text size='large' weight='semiBold' className={classes.subtitle}>
                    {translate('Select items')}
                </Text>
                {splitGroupedItems(order.orderItems).map((orderItem, idx) => {
                    const orderItemKey = createOrderItemKey(orderItem);
                    const isChecked = selectedOrderItems.some((selectedOrderItem) => selectedOrderItem.orderItemKey === orderItemKey && selectedOrderItem.position === idx);
                    return (
                        <div className={classes.orderItem} key={`${orderItemKey}-${idx}`} onClick={() => selectOrderItem(orderItem, idx)}>
                            <Checkbox checked={isChecked} />
                            <div className={classes.modifiersContainer}>
                                <Text>{orderItem.name}</Text>
                                {orderItem.modifierGroups.map((modifierGroup) => {
                                    return modifierGroup.modifiers.map((modifier) => {
                                        if (modifier.quantity === 1) {
                                            return (
                                                <Text size='small' className={classes.modifier}>
                                                    {modifier.name}
                                                </Text>
                                            );
                                        }
                                        return (
                                            <Text size='small' className={classes.modifier}>
                                                {modifier.name} x{modifier.quantity}
                                            </Text>
                                        );
                                    });
                                })}
                            </div>
                        </div>
                    );
                })}
            </DialogContent>
            <DialogActions>
                <Button variant='secondary' onClick={closeDialog}>
                    {translate('Cancel')}
                </Button>
                <Button onClick={printCommand} disabled={!selectedOrderItems.length}>
                    {translate('Print')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    subtitle: {
        textAlign: 'center',
        marginBottom: 10,
    },
    orderItem: {
        display: 'flex',
        flexDirection: 'row',
        gap: 6,
        cursor: 'pointer',
        width: 'fit-content',
    },
    modifiersContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    modifier: {
        color: theme.palette.text.contrast,
    },
}));

type Props = {
    open: boolean;
    order: OrderVm;
    onClose: () => void;
};

type SelectedOrderItem = {
    orderItemKey: string;
    position: number;
};
