/**
 * @prettier
 */
export const ImageTypes = {
    BANNER: 'BANNER',
    RESTAURANT: 'RESTAURANT',
    RESTAURANT_FAVICON: 'RESTAURANT_FAVICON',
    RESTAURANT_APPICON: 'RESTAURANT_APPICON',
    RESTAURANT_SPLASHICON: 'RESTAURANT_SPLASHICON',
    MENU_CATEGORY: 'MENU_CATEGORY',
    MENU_ITEM: 'MENU_ITEM',
    FAQ: 'FAQ',
    LOGO: 'LOGO',
    ID_IMAGE: 'ID_IMAGE',
} as const;

export type ImageType = typeof ImageTypes[keyof typeof ImageTypes];
