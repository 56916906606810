/**
 * @prettier
 */
import { makeStyles, useTheme } from '@material-ui/core';
import { Input, Text, Tooltip } from '@pidedirecto/ui';
import { SearchIcon } from '@pidedirecto/ui/icons';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { PosCartButton } from 'src/scenes/letseatmanager/pos/posMenu/posCart/PosCartButton';
import { PosCartContextualMenu } from 'src/scenes/letseatmanager/pos/posMenu/PosCartContextualMenu';
import { PosMenuCategories } from 'src/scenes/letseatmanager/pos/posMenu/PosMenuCategories';
import { PosMenuCategoryFamilies } from 'src/scenes/letseatmanager/pos/posMenu/PosMenuCategoryFamilies';
import { PosMenuItems } from 'src/scenes/letseatmanager/pos/posMenu/PosMenuItems';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PosMenus(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();

    const [productFilter, setProductFilter] = useState('');

    const selectedMenu = useSelector((state) => state.pos.selectedMenu?.name);

    const closePosMenu = useAction(posReducer.actions.closePosMenu);

    const handleInputFilter = useCallback((filter: string) => setProductFilter(filter), []);

    return (
        <div className={classes.container}>
            <div className={classes.categoriesSection}>
                <div className={classes.header}>
                    <div className={classes.titleContainer}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                            <GoBackButton label={translate('Return')} goBack={closePosMenu} classes={{ button: classes.goBackButton }} />
                            <h2 className={classes.title}>{translate('Add products to the order')}</h2>
                            <Tooltip text={translate('Available menu')}>
                                <Text variant='paragraph'>{selectedMenu}</Text>
                            </Tooltip>
                        </div>
                        <div className={classes.actionsContainer}>
                            <PosCartContextualMenu />
                            <PosCartButton />
                        </div>
                    </div>
                    <Input
                        name='search'
                        placeholder={`${translate('Search')}...`}
                        value={productFilter}
                        onChange={handleInputFilter}
                        leftAdornment={<SearchIcon size={18} color={theme.palette.icons.primary} />}
                    />
                </div>
                <PosMenuCategoryFamilies />
                <div className={classes.categoriesContainer}>
                    <PosMenuCategories />
                </div>
            </div>
            <PosMenuItems productFilter={productFilter} />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '70%',
        backgroundColor: 'transparent',
        padding: 20,
        paddingTop: 0,
        paddingRight: 40,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '0 24px',
        },
    },
    goBackButton: {
        position: 'relative',
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    categoriesSection: {
        width: '100%',
        backgroundColor: 'transparent',
        paddingTop: 0,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: 'fit-content',
            paddingBottom: 0,
            marginTop: 40,
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginBottom: 20,
        },
    },
    titleContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    title: {
        fontSize: 16,
        margin: 0,
        fontFamily: theme.typography.medium,
        color: theme.palette.secondary.contrastText,
        [theme.breakpoints.up('sm')]: {
            fontSize: 20,
        },
    },
    categoriesContainer: {
        display: 'flex',
        gap: 12,
        maxHeight: '100%',
        marginBottom: 20,
        maxWidth: '100%',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            paddingBottom: 0,
            marginBottom: 20,
        },
    },
}));
