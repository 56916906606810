/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { CircleCloseIcon } from 'src/icons/CircleCloseIcon';
import { getDeprecatedPrintersInLocalStorage } from 'src/localStorage/getDeprecatedPrintersInLocalStorage';
import { PrinterItem } from 'src/scenes/letseatmanager/device/printers/PrinterItem';
import { ReactComponentPrinter } from 'src/services/printer/printers/ReactComponentPrinter';
import { printerDeprecatedToPrinter } from 'src/services/printer/utils/printerDeprecatedToPrinter';
import { PrinterVm } from 'src/types/PrinterVm';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function SelectPrinterDialog(): React.ReactElement {
    const classes = useStyles();

    const [selectedPrinterId, setSelectedPrinterId] = useState(undefined);

    const isOpenSelectPrinterDialog = useSelector((state) => state.app2.selectPrinterDialog.open);
    const onSelectPrinter = useSelector((state) => state.app2.selectPrinterDialog.onSelectPrinter);
    const header = useSelector((state) => state.app2.selectPrinterDialog.header);
    const error = useSelector((state) => state.app2.selectPrinterDialog.error);
    const printerInstructions = useSelector((state) => state.app2.selectPrinterDialog.printerInstructions);
    const printType = useSelector((state) => state.app2.selectPrinterDialog.printType);
    const printers = useSelector((state) => state.app.printers);
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);

    const closeSelectPrinterDialog = useAction(app2.actions.closeSelectPrinterDialog);

    let printersAvailable: Array<PrinterVm> = [];
    if (newDeviceManagementEnabled) printersAvailable = printers;
    else printersAvailable = getDeprecatedPrintersInLocalStorage()?.map(printerDeprecatedToPrinter);

    useEffect(() => {
        setSelectedPrinterId(undefined);
    }, [isOpenSelectPrinterDialog]);

    const handleClose = () => {
        closeSelectPrinterDialog();
    };

    const handleSelectPrinter = (printer: any) => {
        setSelectedPrinterId(printer.printerId);
        closeSelectPrinterDialog();
        onSelectPrinter(printer);
    };

    return (
        <Dialog
            open={isOpenSelectPrinterDialog}
            onClose={handleClose}
            title={header ?? translate(`Select a printer to print @printType`, { printType: translate(`PrintTypes.${printType}`) })}
            classes={{ dialog: classes.dialog, dialogContainer: classes.dialogContainer }}
        >
            {error && (
                <Text style={{ width: '100%', textAlign: 'center' }} error={true}>
                    {error}
                </Text>
            )}
            <div className={classes.dialogContent}>
                <div style={{ width: '100%' }}>
                    <Text variant='medium' size='large' style={{ marginBottom: 12 }}>
                        {translate('Impresoras')}
                    </Text>
                    <div className={classes.printersContainer}>
                        {printersAvailable?.map((printer: any) => {
                            const isSelected = printer.printerId === selectedPrinterId;
                            return <PrinterItem key={printer.printerId} printer={printer} isSelected={isSelected} onSelectPrinter={handleSelectPrinter} />;
                        })}
                    </div>
                </div>
                <div className={classes.commandPreview}>
                    <Text variant='medium' size='large' style={{ marginBottom: 12 }}>
                        {translate('Preview')}
                    </Text>
                    {printerInstructions && <div className={classes.command}>{ReactComponentPrinter.print(printerInstructions)}</div>}
                </div>
            </div>
            <Button onClick={handleClose} secondary classes={{ button: classes.closeButton }}>
                <CircleCloseIcon />
            </Button>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        zIndex: 2000,
    },
    dialog: {
        height: 'fit-content',
        width: '900px',
    },
    dialogContent: {
        display: 'flex',
        gap: 24,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    commandPreview: {
        width: 'fit-content',
        minWidth: 280,
    },
    command: {
        transform: 'scale(0.7)',
        transformOrigin: '0 0',
    },
    printersContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
        gap: 12,
    },
    closeButton: {
        position: 'absolute',
        top: 30,
        right: 30,
        border: 0,
        '&:hover': {
            border: 0,
        },
    },
}));
