/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { translate } from 'src/i18n/translate';

export function SyncMenuIntegrationsErrorDialog({ open, onClose, syncMenuErrors }: Props): React.ReactElement {
    const classes = useStyles();
    return (
        <Dialog onClose={onClose} open={open} title={translate('Errors to sync your menus')}>
            <div className={classes.container}>
                {syncMenuErrors?.map((error: string) => (
                    <div className={classes.errorText}>{error}</div>
                ))}
            </div>
            <DialogActions>
                <Button onClick={onClose}>{translate('Accept')}</Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    errorText: {
        fontSize: 14,
        fontFamily: theme.typography.regular,
        maxHeight: '60px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    syncMenuErrors?: Array<string>;
};
