/**
 * @prettier
 */
import type { PosMenuVm } from 'src/types/PosMenuVm';

export const KEY_NAME = 'posMenuOffline';

export function findPosMenuOfflineInLocalStorage(): PosMenuVm | undefined {
    try {
        const item = window.localStorage.getItem('posMenuOffline');
        const posMenu = JSON.parse(item as any);
        if (typeof posMenu === 'string') return;
        return;
    } catch (e: any) {
        console.error('Failed to findPosMenuOfflineInLocalStorage: ', e);
    }
}
