/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function toggleDeliverOrdersFromUberEatsApi(request: ToggleDeliverOrdersFromUberEatsApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurantIntegration/toggleDeliverOrdersFromUberEatsApi', request);
}

type ToggleDeliverOrdersFromUberEatsApiRequest = {
    restaurantId: RestaurantId;
};
