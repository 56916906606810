/**
 * @prettier
 */
import { Sounds, Sound as SoundType } from 'src/constants/Sound';

export async function playAlertSound(sound: SoundType = Sounds.PIDE_DIRECTO): Promise<void> {
    if (sound === Sounds.NONE) return;
    const audio = await getCachedAudio(sound);
    audio.play().catch((error: any) => console.error(`Playing alert sound ${sound} failed for some reason error=`, error));
}

export async function getCachedAudio(sound: SoundType = Sounds.PIDE_DIRECTO): Promise<HTMLAudioElement> {
    if (audioCache[sound]) return audioCache[sound];
    const audio = new Audio(`${SOUNDS_URL}/${sound}.mp3`);
    audioCache[sound] = audio;
    return audio;
}

const audioCache: Record<string, any> = {};
const SOUNDS_URL = 'https://s3.amazonaws.com/manager.letseat.sounds';
