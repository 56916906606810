/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Page } from 'src/components/Page';
import { translate } from 'src/i18n/translate';
import { OrdersTable } from 'src/scenes/letseatadmin/order/OrdersTable';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function OrdersPage(): React.ReactElement {
    const [from, setFrom] = useState(moment().subtract(0, 'days').format('YYYY-MM-DD'));
    const [to, setTo] = useState(moment().format('YYYY-MM-DD'));
    const [orders, setOrders] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);

    const cities = useSelector((state) => state.app2.cities);

    const [loadingOrders, responseOrders, load] = useLoadApi(
        letseatmanagerApiDeprecated.admin.orders.fetch,
        {
            ...(from && { from: moment(from).toDate() }),
            ...(to && { to: moment(to).add(1, 'day').toDate() }),
            cities,
            page,
            rowsPerPage,
        },
        {
            zip: true,
            dependencies: [page, rowsPerPage],
        }
    );

    useEffect(() => {
        if (responseOrders) {
            setOrders(responseOrders.orders);
            setTotalOrders(responseOrders.total);
        }
    }, [responseOrders]);

    const handleChangeFromDate = (date: any) => setFrom(date.format('YYYY-MM-DD'));

    const handleChangeToDate = (date: any) => setTo(date.format('YYYY-MM-DD'));

    const handleSubmit = async () => await load();

    const handleChangePage = (page: any) => {
        setPage(page);
    };

    const handleChangeRowsPerPage = (rowsPerPage: any) => {
        setRowsPerPage(rowsPerPage);
    };

    return (
        <Page title={translate('Orders')}>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Card style={{ padding: 24 }}>
                        <Grid container direction='row' spacing={3} alignItems='center'>
                            <Grid item>
                                <DatePicker variant='inline' label={translate('From')} value={from} onChange={handleChangeFromDate} format='ll' autoOk />
                            </Grid>
                            <Grid item>
                                <DatePicker variant='inline' label={translate('To')} value={to} onChange={handleChangeToDate} format='ll' autoOk />
                            </Grid>
                            <Grid item>
                                <Button color='primary' type='submit' onClick={handleSubmit} disabled={loadingOrders}>
                                    {loadingOrders ? translate('Searching').toUpperCase() : translate('Search').toUpperCase()}
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <OrdersTable
                        orders={orders}
                        serverSide={true}
                        totalOrders={totalOrders}
                        loading={loadingOrders}
                        onClickRefresh={load}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid>
        </Page>
    );
}
