/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { ModifierGroupId } from 'src/types/Id';

export async function removeModifierGroupApi(request: RemoveModifierGroupApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('modifierGroup/removeModifierGroupApi', request);
}

type RemoveModifierGroupApiRequest = {
    modifierGroupId: ModifierGroupId;
};
