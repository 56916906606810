/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { app2 } from 'src/app2';
import { ManageMenu } from 'src/scenes/letseatmanager/deprecatedMenu/ManageMenu';
import TheMenuActions from 'src/scenes/letseatmanager/deprecatedMenu/TheMenuReducer';
import type { MenuCategoryId, MenuId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';

export function ManageMenuCategory({ loading, category, position, menuId, onSuccess, children }: Props): React.ReactElement {
    const classes = useStyles();

    const moveMenuCategoryUp = useAction(TheMenuActions.moveMenuCategoryUp);
    const moveMenuCategoryDown = useAction(TheMenuActions.moveMenuCategoryDown);
    const openCreateMenuCategoryDialog = useAction(app2.actions.openCreateMenuCategoryDialog);
    const openRemoveMenuCategoryDialog = useAction(app2.actions.openRemoveMenuCategoryDialog);
    const openChangeMenuCategoryDialog = useAction(app2.actions.openChangeMenuCategoryDialog);

    const handleMoveUp = () => {
        if (loading) return;
        const moveMenuCategoryUpRequest = {
            menuCategoryId: category?.menuCategoryId,
        } as const;
        moveMenuCategoryUp(moveMenuCategoryUpRequest);
    };

    const handleMoveDown = () => {
        if (loading) return;
        const moveMenuCategoryDownRequest = {
            menuCategoryId: category?.menuCategoryId,
        } as const;
        moveMenuCategoryDown(moveMenuCategoryDownRequest);
    };

    const handleClickRemove = () => {
        if (loading) return;
        openRemoveMenuCategoryDialog({ category, onSuccess });
    };

    const handleClickCreate = () => {
        if (loading) return;
        openCreateMenuCategoryDialog({ menuId, position, onSuccess });
    };

    const handleClickChange = () => {
        if (loading) return;
        openChangeMenuCategoryDialog({ category, onSuccess });
    };

    return (
        <div className={classes.container}>
            {children}
            <ManageMenu onClickMoveUp={handleMoveUp} onClickMoveDown={handleMoveDown} onClickChange={handleClickChange} onClickCreate={handleClickCreate} onClickRemove={handleClickRemove} />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

type Props = {
    menuId?: MenuId;
    category?: {
        menuCategoryId: MenuCategoryId;
        name?: string;
        imageUrl?: string;
    };
    position: number;
    loading: boolean;
    onSuccess: any;
    children: React.ReactNode;
};
