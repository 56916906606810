/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import moment from 'moment-timezone';
import * as React from 'react';
import { getInventoryAdjustmentsReportApi } from 'src/api/letseatmanager/restaurantDashboard/getInventoryAdjustmentsReportApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { CostTypes } from 'src/constants/CostType';
import { translate } from 'src/i18n/translate';
import { InventoryKpi } from 'src/scenes/letseatmanager/inventory/inventory/InventoryKpi';
import { InventoryAdjustmentsReportTable } from 'src/scenes/letseatmanager/inventoryAdjustmentsReport/InventoryAdjustmentsReportTable';
import { FilterCostType } from 'src/scenes/letseatmanager/restaurantDashboard/FilterCostType';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function InventoryAdjustmentsReport(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const costType = useSelector((state) => state.app2.filterReportsState.costType);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, inventoryAdjustmentsReport, refreshInventoryAdjustmentsReports] = useLoadApi(
        getInventoryAdjustmentsReportApi,
        {
            restaurantIds: [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).add(1, 'day').toISOString(),
        },
        {
            initialValue: {
                inventoryAdjustmentsReport: [],
            },
            dependencies: [restaurantId, period?.from, period?.to],
        }
    );

    const isEmptyReport = () => {
        if (!inventoryAdjustmentsReport.inventoryAdjustmentsReport) return true;
    };

    const getKpiByCostType = (kpi: any) => {
        if (!kpi) return 0;
        switch (costType) {
            case CostTypes.COST_FIXED:
                return kpi.inventoryValueByFixedCost;
            case CostTypes.COST_BY_AVERAGE_WEIGHTED_COST:
                return kpi.inventoryValueByAverageWeightedCost;
            case CostTypes.COST_BY_LAST_PURCHASE_COST:
                return kpi.inventoryValueByLastPurchaseCost;
            default:
                return 0;
        }
    };

    if (isEmptyReport()) {
        return (
            <div className={classes.container}>
                <UpdatingContentProgress loading={loading} top />
                <ReportSectionEmptyState />
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <div className={classes.filtersContainer}>
                <FilterReports />
                <FilterCostType position='right' />
            </div>
            <div className={classes.tableContainer}>
                <InventoryAdjustmentsReportTable loading={loading} data={inventoryAdjustmentsReport?.inventoryAdjustmentsReport} refresh={refreshInventoryAdjustmentsReports} />
                {inventoryAdjustmentsReport.kpis && (
                    <div className={classes.kpis}>
                        <InventoryKpi kpi={`${formatAsCurrencyNumber(getKpiByCostType(inventoryAdjustmentsReport.kpis.missingInventoryValue)) || 0}`} label={translate('Total value in shortages')} />
                        <InventoryKpi kpi={`${formatAsCurrencyNumber(getKpiByCostType(inventoryAdjustmentsReport.kpis.spareInventoryValue)) || 0}`} label={translate('Total value in leftovers')} />
                    </div>
                )}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
    tableContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: 20,
        marginTop: 20,
    },
    kpis: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    filtersContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 10,
        width: '100%',
    },
}));
