/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button, Dialog, DialogActions, Text } from '@pidedirecto/ui';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormRadio } from 'src/components/form/FormRadio';
import { FormRadioGroup } from 'src/components/form/FormRadioGroup';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { TipType, TipTypes } from 'src/constants/TipType';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { PosTipVm } from 'src/types/PosTipVm';
import { calculateTipAmount } from 'src/utils/order/calculateTipAmount';
import { classNames } from 'src/utils/react/classNames';
import { isTipAmount } from 'src/utils/tip/isTipAmount';
import { isTipPercentage } from 'src/utils/tip/isTipPercentage';

export function AddCreditCardTipDialog({ open, amountToPay, onContinue }: Props) {
    const classes = useStyles();
    const form = useForm();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const { control } = form;

    const tipPercentages: Array<TipPercentage> = ['10', '15', '20'];

    const [tipPercentageSelected, setTipPercentageSelected] = useState<TipPercentage | 'Other'>(tipPercentages[0]);

    const tipType = useWatch<TipType>({ name: 'tipType', control, defaultValue: TipTypes.PERCENT });

    const isTipPercentageSelected = (tipPercentage: any) => tipPercentage === tipPercentageSelected;

    const onSubmit = (form: CardTipForm) => {
        const tipPercentage = isTipPercentageSelected('Other') ? form.tipPercentage : tipPercentageSelected;

        const tip = {
            tipType,
            tipPercentage: isTipPercentage(tipType) ? tipPercentage : undefined,
            paymentMethod: PaymentMethods.CREDIT_CARD,
            tipAmount: form.tipAmount ?? '0',
            isTipFromChange: true,
        };

        onContinue?.({ ...tip, tipAmount: calculateTipAmount(BigNumber(amountToPay).toNumber(), tip).toString() });

        setTipPercentageSelected(tipPercentages[0]);
    };

    const getTipAmountByPercentage = (tipPercentage: TipPercentage) => {
        return calculateTipAmount(BigNumber(amountToPay).toNumber(), { tipType, tipPercentage, paymentMethod: PaymentMethods.CREDIT_CARD } as PosTipVm);
    };

    const continueWithoutTip = () => {
        onContinue?.();
    };

    return (
        <Dialog open={open} title={translate('Do you want to add tip?')}>
            <Form form={form} onSubmit={onSubmit}>
                <FormRadioGroup name={'tipType'} label={translate('Tip Type')} defaultValue={TipTypes.PERCENT}>
                    <FormRadio label={translate('Percentage')} value={TipTypes.PERCENT} />
                    <FormRadio label={translate('Amount')} value={TipTypes.AMOUNT} onClick={() => setTipPercentageSelected(tipPercentages[0])} />
                </FormRadioGroup>
                {isTipPercentage(tipType) && (
                    <section>
                        <Text>{translate('Tip')}</Text>
                        <div className={classes.tipPercentages}>
                            {tipPercentages.map((tipPercentage) => (
                                <Button
                                    classes={{ button: classNames(classes.button, isTipPercentageSelected(tipPercentage) ? classes.selectedButton : undefined) }}
                                    variant={'outline'}
                                    onClick={() => setTipPercentageSelected(tipPercentage)}
                                >
                                    <Text>{`${tipPercentage}%`}</Text>
                                    <Text>{formatAsCurrencyNumber(getTipAmountByPercentage(tipPercentage))}</Text>
                                </Button>
                            ))}
                            <Button
                                classes={{ button: classNames(classes.button, isTipPercentageSelected('Other') ? classes.selectedButton : undefined) }}
                                variant={'outline'}
                                onClick={() => setTipPercentageSelected('Other')}
                            >
                                <Text>%</Text>
                                <Text>{translate('Other')}</Text>
                            </Button>
                        </div>
                    </section>
                )}
                {isTipAmount(tipType) && <FormCurrencyNumberStringField name='tipAmount' label={translate('Tip')} required />}
                {isTipPercentage(tipType) && isTipPercentageSelected('Other') && <DeprecatedFormPercentNumberField name='tipPercentage' label={translate('Tip')} required />}
                <DialogActions>
                    <Button variant={'secondary'} onClick={continueWithoutTip}>
                        {translate('Do not add')}
                    </Button>
                    <Button type={'submit'}>{translate('Yes, add')}</Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        minWidth: 80,
        borderRadius: 4,
        flexDirection: 'column',
    },
    selectedButton: {
        borderColor: theme.palette.border.brandContrast,
        backgroundColor: theme.palette.surface.brand,
    },
    tipPercentages: {
        display: 'flex',
        gap: 24,
        marginBottom: 24,
        marginTop: 10,
    },
}));

type TipPercentage = '10' | '15' | '20';

type CardTipForm = {
    tipType: TipType;
    tipAmount?: string;
    tipPercentage?: string;
};

type Props = {
    open: boolean;
    amountToPay: string;
    onContinue: (tip?: PosTipVm | undefined) => void;
};
