/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import * as React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormHiddenField } from 'src/components/form/FormHiddenField';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormUnitOfMeasurementSelect } from 'src/components/form/FormUnitOfMeasurementSelect';
import type { SupplyVm } from 'src/types/SupplyVm';

export function BuyUnitSupplyReport({ index, supply }: Props): React.ReactElement {
    const classes = useStyles();
    const { control } = useFormContext();
    const { fields, append } = useFieldArray({
        control,
        name: `suppliesInventoried.${index}.unitsInventoried`,
    });

    useEffect(() => {
        if (supply) {
            for (const buyUnit of supply.buyUnits) {
                append({ quantity: undefined, unitOfMeasurement: buyUnit.unit, conversionFactor: buyUnit.conversionFactor });
            }
        }
    }, [supply]);

    return (
        <div className={classes.container}>
            {fields?.map((buyUnitInventoryCount: any, idx: any) => (
                <div className={classes.row} key={buyUnitInventoryCount.id}>
                    <FormHiddenField name={`suppliesInventoried.${index}.unitsInventoried.${idx}.conversionFactor`} defaultValue={buyUnitInventoryCount.conversionFactor} />
                    <div className={classes.quantityContainer}>
                        <FormNumberField name={`suppliesInventoried.${index}.unitsInventoried.${idx}.quantity`} />
                    </div>
                    <div className={classes.unitOfMeasurementContainer}>
                        <FormUnitOfMeasurementSelect name={`suppliesInventoried.${index}.unitsInventoried.${idx}.unit`} disabled defaultValue={buyUnitInventoryCount.unitOfMeasurement} />
                    </div>
                </div>
            ))}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    quantityContainer: {
        width: '30%',
    },
    unitOfMeasurementContainer: {
        width: '66%',
    },
}));

type Props = {
    index: number;
    supply: SupplyVm;
};
