/**
 * @prettier
 */
import { Auth } from 'aws-amplify';
import { getUserApi } from 'src/api/letseatmanager/authentication/getUserApi';
import { app2 } from 'src/app2';
import { history } from 'src/config/history';
import { InitialRoutePath } from 'src/constants/InitialRoutePaths';
import { PublicRoutePaths } from 'src/constants/PublicRoutePath';
import { RolePermission, RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { UserTypes } from 'src/constants/UserType';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { authReducer } from 'src/reducers/authReducer';
import { useOpenBrand } from 'src/services/restaurant/useOpenBrand';
import { useOpenRestaurant } from 'src/services/restaurant/useOpenRestaurant';
import { RestaurantId } from 'src/types/Id';
import { RestaurantVm } from 'src/types/RestaurantVm';
import { UserVm } from 'src/types/UserVm';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';
import { getUrlQueryParameter } from 'src/utils/history/getUrlQueryParameter';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { isBrandUser } from 'src/utils/user/isBrandUser';
import { isUuid } from 'src/utils/uuid/isUuid';

export function useLogin(): [(arg1: string | undefined) => Promise<void>] {
    let isAdminOrView = false;
    let isSupport = false;
    let isOperationsUser = false;
    let isKitchens = false;
    let isKitchenDisplayScreenUser = false;
    let isKioskUser = false;
    let userType = undefined;
    let restaurantIds: undefined | Array<RestaurantId> = [];
    let restaurants:
        | Array<any>
        | Array<{
              name: string;
              restaurantId: RestaurantId;
          }> = [];
    let userRolePermissions: undefined | Array<RolePermission> = [];

    const webSoundPermissionAllowed = useSelector((state) => state.app2.webSoundPermissionAllowed);

    const startLogin = useAction(authReducer.actions.startLogin);
    const endLogin = useAction(authReducer.actions.endLogin);
    const setUserType = useAction(authReducer.actions.setUserType);
    const setUserPermissions = useAction(authReducer.actions.setUserPermissions);
    const loginFailed = useAction(authReducer.actions.loginFailed);
    const setRestaurants = useAction(appReducer.actions.setRestaurants);
    const setName = useAction(authReducer.actions.setName);
    const setRoleName = useAction(authReducer.actions.setName);
    const setUserRestaurantIds = useAction(appReducer.actions.setUserRestaurantIds);
    const setManagerUserId = useAction(authReducer.actions.setManagerUserId);

    const setSettings = useAction(app2.actions.setSettings);
    const setZendeskJwt = useAction(app2.actions.setZendeskJwt);

    const { openRestaurant } = useOpenRestaurant();
    const { openBrand } = useOpenBrand();

    const tryToLogin = async (username: undefined | string) => {
        try {
            await login(username);
        } catch (e: any) {
            await handleLoginFailed(e);
        }
    };

    const login = async (username: undefined | string) => {
        startLogin({ username });

        const response = await getUserApi({
            languageTag: navigator.language,
            screenWidth: window.screen.width,
            screenHeight: window.screen.height,
            webSoundPermissionAllowed,
        });

        if (!response.ok) {
            if (username) {
                startLogin({ username, retrying: true });
            } else {
                await Auth.signOut();
            }
            return;
        }

        const user = response.data;

        isAdminOrView = user.userType === UserTypes.ADMIN || user.userType === UserTypes.VIEW;
        isSupport = user.userType === UserTypes.SUPPORT;
        isOperationsUser = user.userType === UserTypes.OPERATIONS;
        isKitchens = user.userType === UserTypes.KITCHENS;
        isKitchenDisplayScreenUser = user.userType === UserTypes.KITCHEN_DISPLAY_SCREEN;
        isKioskUser = user.userType === UserTypes.KIOSK;
        restaurantIds = user.restaurantIds;
        userType = user.userType;
        restaurants = user.restaurants;
        userRolePermissions = user.userRolePermissions;
        setUserType(user.userType);

        validateUser(response);
        setZendeskJwt(user.zendeskJwt);
        setUserInformation(user);
        const restaurant = await openRestaurantIdInUrl();
        if (isBrandUser(userType)) await openBrand(user.brandIds?.[0]);
        redirectDependingOnUser(user.initialPage, !!restaurant?.newOrdersPageEnabled);

        endLogin();
    };

    const validateUser = (response: { data: UserVm; ok: true; problem: 'NONE'; status: number }) => {
        logoutIfThereIsNoStoreConfiguredForTheUser(response);
        logoutIfUserDoesNotHavePermissionToSignInWithPassword(response);
    };

    const logoutIfThereIsNoStoreConfiguredForTheUser = (response: { data: UserVm; ok: true; problem: 'NONE'; status: number }) => {
        const isSpecialUser = isAdminOrView || isSupport || isKitchens || isOperationsUser;
        const userHasRestaurantIds = restaurantIds?.every(isUuid);

        if (isSpecialUser || userHasRestaurantIds) return;

        alert(translate('Failed to sign in because your account is not configured for any restaurant. Please contact support!'));
        throw new Error({
            ...(response as any),
            message: 'Failed to sign in because your account is not configured for any restaurant. Please contact support!',
        });
    };

    const logoutIfUserDoesNotHavePermissionToSignInWithPassword = (response: { data: UserVm; ok: true; problem: 'NONE'; status: number }) => {
        if (!userRolePermissions) return;

        const userHasPermissionToSignInWithPassword = userRolePermissions?.includes(RolePermissions.PASSWORD_SIGN_IN);

        if (userHasPermissionToSignInWithPassword) return;

        alert(translate('Failed to sign in because your account does not have permission to sign in with password. Please contact your manager to sign in first.'));
        throw new Error({
            ...(response as any),
            message: 'Failed to sign in because your account does not have permission to sign in with password. Please contact your manager to sign in first.',
        });
    };

    const setUserInformation = (user: UserVm) => {
        setName(user.name);
        setManagerUserId(user.managerUserId);
        setRoleName(user.userRole?.roleName);
        setSettings(user.settings);

        if (!isAdminOrView && !isSupport && !isOperationsUser) {
            setUserPermissions(user);
            return;
        }

        setRestaurants(restaurants);
        setUserRestaurantIds(user.restaurantIds);
    };

    const openRestaurantIdInUrl: () => Promise<RestaurantVm | undefined> = async () => {
        if (isSupport || isOperationsUser) return;

        const queryParameterRestaurantId = getUrlQueryParameter('restaurant');

        const restaurantId = getRestaurantIdToOpen(queryParameterRestaurantId);
        if (!restaurantId) return;

        return openRestaurant(restaurantId);
    };

    const redirectDependingOnUser = (initialPage: undefined | InitialRoutePath, newOrdersPageEnabled: boolean) => {
        const queryParameterRestaurantId: RestaurantId = getUrlQueryParameter('restaurant');

        const isHomePage = history.location.pathname === PublicRoutePaths.HOME;
        const isRestaurantUser = !isAdminOrView && !isSupport && !isKitchens && !isOperationsUser;

        if (!isRestaurantUser && isHomePage) {
            if (!initialPage) return history.replace(getInitialRoutePath(newOrdersPageEnabled));

            return history.replace(RoutePaths[initialPage] ?? getInitialRoutePath(newOrdersPageEnabled));
        }

        const restaurantUserCanAccessToRestaurantId = isUuid(queryParameterRestaurantId) && restaurantIds?.includes(queryParameterRestaurantId);

        if (restaurantUserCanAccessToRestaurantId || isUuid(restaurantIds?.[0])) {
            return redirectRestaurantUser(initialPage, newOrdersPageEnabled);
        }

        return;
    };

    const redirectRestaurantUser = (initialPage: undefined | InitialRoutePath, newOrdersPageEnabled: boolean) => {
        const isHomePage = history.location.pathname === PublicRoutePaths.HOME;

        if (isKioskUser && !isHomePage) return handleRedirectToKiosk();
        if (isKitchenDisplayScreenUser && !isHomePage) return history.replace(RoutePaths.KITCHEN_DISPLAY_SCREEN);

        if (!isHomePage) return;

        const pageToRedirect = initialPage ? RoutePaths[initialPage] : getInitialRoutePath(newOrdersPageEnabled);

        return history.replace(pageToRedirect);
    };

    const getRestaurantIdToOpen = (queryParameterRestaurantId: any) => {
        if (isAdminOrView) {
            if (isUuid(queryParameterRestaurantId)) return queryParameterRestaurantId;
            return;
        }

        if (restaurantIds?.includes(queryParameterRestaurantId)) return queryParameterRestaurantId;

        return restaurantIds?.[0];
    };

    const getInitialRoutePath = (newOrdersPageEnabled: boolean) => {
        if (isAdminOrView) return RoutePaths.ADMIN_MONITORING;
        if (isSupport || isOperationsUser) return RoutePaths.ADMIN_ONGOING_ORDERS;
        if (isKitchens) return RoutePaths.DRIVER_DELIVERY_ITEMS_PAGE;
        if (isKioskUser) return handleRedirectToKiosk();
        if (isKitchenDisplayScreenUser) return RoutePaths.KITCHEN_DISPLAY_SCREEN;
        if (newOrdersPageEnabled) return RoutePaths.ORDERS;
        return RoutePaths.ONGOING_ORDERS;
    };

    const handleLoginFailed = async (e: any) => {
        history.replace(PublicRoutePaths.HOME);
        await Auth.signOut();
        loginFailed();
    };

    const handleRedirectToKiosk = () => {
        alert(translate('You are being redirected to the kiosk'));

        if (isProductionEnvironment()) return (window.location.href = 'https://kiosk.pidedirecto.mx');

        return (window.location.href = 'https://dev.kiosk.pidedirecto.mx');
    };

    return [tryToLogin];
}
