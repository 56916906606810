/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { closeUberEatsStoreApi } from 'src/api/letseatmanager/restaurantIntegration/closeUberEatsStoreApi';
import { disintegrateUberEatsApi } from 'src/api/letseatmanager/restaurantIntegration/disintegrateUberEatsApi';
import { openUberEatsStoreApi } from 'src/api/letseatmanager/restaurantIntegration/openUberEatsStoreApi';
import { setUpUberEatsIntegrationApi, UberEatsStoreVm } from 'src/api/letseatmanager/restaurantIntegration/setUpUberEatsIntegrationApi';
import { toggleDeliverOrdersFromUberEatsApi } from 'src/api/letseatmanager/restaurantIntegration/toggleDeliverOrdersFromUberEatsApi';
import { app2 } from 'src/app2';
import { translateOfflineReason } from 'src/components/alertDialog/restaurant/IntegrationAlerts';
import { Button } from 'src/components/Button';
import { Switch } from 'src/components/Switch';
import { Tooltip } from 'src/components/Tooltip';
import { UberEatsStoreOfflineReasons } from 'src/constants/UberEatsStoreOfflineReason';
import { translate } from 'src/i18n/translate';
import uberEatsLogo from 'src/images/uber-eats-logo.png';
import { ChangeUberIntegrationPercentageDialog } from 'src/scenes/letseatmanager/integrations/ChangeUberIntegrationPercentageDialog';
import { UberEatsStoreIntegrationDialog } from 'src/scenes/letseatmanager/integrations/UberEatsStoreIntegrationDialog';
import { useConfirmDialog } from 'src/services/dialog/useConfirmDialog';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';
import { formatAsPercentage } from 'src/utils/number/formatAsPercentage';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function UberIntegration({ restaurantIntegrationsStatus, load, isIntegrationLoaded }: { restaurantIntegrationsStatus: any; load: any; isIntegrationLoaded: boolean }): React.ReactElement {
    const classes = useStyles();
    const confirmDialog = useConfirmDialog();

    const uberEatsStoreOnline = useSelector((state) => state.app2.uberEatsStoreOnline);
    const uberEatsStoreOfflineReason = useSelector((state) => state.app2.uberEatsStoreOfflineReason);
    const uberEatsRdOptionalDisabled = useSelector((state) => state.app2.uberEatsRdOptionalDisabled);
    const uberEatsStorePausedByUber = useSelector((state) => state.app2.uberEatsStorePausedByUber);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const adminUser = useSelector((state) => state.authentication.adminUser);
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const location = useLocation();
    const setUberEatsStoreOnline = useAction(app2.actions.setUberEatsStoreOnline);
    const setUberEatsStoreOfflineReason = useAction(app2.actions.setUberEatsStoreOfflineReason);
    const setUberEatsRdOptionalDisabled = useAction(app2.actions.setUberEatsRdOptionalDisabled);
    const setUberEatsStorePausedByUber = useAction(app2.actions.setUberEatsStorePausedByUber);

    const [integratingUberEats, setIntegratingUberEats] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uberEatsStoreIntegrationDialogState, setUberEatsStoreIntegrationDialogState] = useState<{ open: boolean; uberEatsStores: Array<UberEatsStoreVm> }>({ open: false, uberEatsStores: [] });
    const [uberEatsChangePercentageDialogState, setUberEatsChangePercentageDialogState] = useState({ open: false });

    const offlineReasonByUber = uberEatsStoreOfflineReason || uberEatsStoreOfflineReason === UberEatsStoreOfflineReasons.INVISIBLE;

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const uberAuthCode = params.get('code');
        if (uberAuthCode) handleIntegrateUberEats(uberAuthCode);
    }, [location.search]);

    const closeUberEatsStore = async () => {
        setLoading(true);
        const response = await closeUberEatsStoreApi({ restaurantId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setLoading(false);
            return;
        }
        setUberEatsStoreOnline(response.data.uberEatsStoreOnline);
        setUberEatsStoreOfflineReason(response.data.uberEatsStoreOfflineReason);
        setUberEatsRdOptionalDisabled(response.data.uberEatsRdOptionalDisabled);
        setUberEatsStorePausedByUber(response.data.uberEatsStorePausedByUber);
        setLoading(false);
        await load();
    };

    const openUberEatsStore = async () => {
        setLoading(true);
        if (offlineReasonByUber) {
            alert(translate('Your account is paused from Uber and cannot receive orders. Open your store from your Uber Eats platform.'));
            return;
        }

        const response = await openUberEatsStoreApi({ restaurantId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setLoading(false);
            return;
        }

        setUberEatsStoreOnline(response.data.uberEatsStoreOnline);
        setUberEatsStoreOfflineReason(response.data.uberEatsStoreOfflineReason);
        setUberEatsRdOptionalDisabled(response.data.uberEatsRdOptionalDisabled);
        setUberEatsStorePausedByUber(response.data.uberEatsStorePausedByUber);
        setLoading(false);

        load();
    };

    const handleClickIntegrateUberEats = async () => {
        if (!restaurantIntegrationsStatus.uberEatsStoreId) {
            return window.open(
                `https://login.uber.com/oauth/v2/authorize?response_type=code&client_id=${isProductionEnvironment() ? 'MEWTmdSo5jnM-eaX1nEnEtX5aPrjvq1j' : 'thHjIHxDqJrtYTSnILcPievs39i7Kxcx'}`,
                '_blank'
            );
        }
        await handleDisintegrateUberEats();
    };

    const handleDisintegrateUberEats = async () => {
        const password = window.prompt('Please enter password to remove Restaurant', 'Password');
        if (password === '9999') {
            const remove = window.confirm('Are you sure you want to desintegrate this restaurant?');
            if (remove) {
                setLoading(true);
                const response = await disintegrateUberEatsApi({
                    restaurantId,
                });
                setLoading(false);
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    return;
                }
                await load();
            }
        } else {
            alert('Ask your seller for the password to desintegrate');
        }
    };

    const handleChangeDeliverOrdersFromUberEats = async () => {
        const confirmMessage = restaurantIntegrationsStatus.deliverOrdersFromUberEats
            ? translate('Do you wish to stop delivering UberEats Orders by PideDirecto?')
            : translate('Do you wish to start delivering UberEats Orders by PideDirecto?');

        const confirmed = await confirmDialog({
            title: confirmMessage,
            buttonText: translate('Yes'),
            cancelButtonText: translate('No'),
        });

        if (!confirmed) return;

        const isValidPassword = restaurantIntegrationsStatus.deliverOrdersFromUberEats ? await validatePassword() : true;

        if (!isValidPassword) return;

        setLoading(true);
        await toggleDeliverOrdersFromUberEatsApi({ restaurantId });
        await load();
        setLoading(false);
    };

    const validatePassword = async () => {
        const password = window.prompt('Please enter password to remove Restaurant', 'Password');
        if (password === '9999') {
            const shouldRemove = window.confirm('Are you sure you want to stop delivering this restaurant with Pd?');
            if (shouldRemove) {
                return true;
            }
        } else {
            alert('Ask your seller for the password to disintegrate');
        }
        return false;
    };

    const uberEatsIntegrationSuccessful = () => {
        handleCloseUberEatsIntegrationDialog();
        load();
    };

    const handleIntegrateUberEats = async (uberAuthCode: string) => {
        setLoading(true);
        setIntegratingUberEats(true);
        const response = await setUpUberEatsIntegrationApi({
            restaurantId,
            uberAuthCode,
        });
        setLoading(false);
        setIntegratingUberEats(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setUberEatsStoreIntegrationDialogState({ open: true, uberEatsStores: response.data });
    };

    const handleCloseUberEatsIntegrationDialog = () => setUberEatsStoreIntegrationDialogState({ open: false, uberEatsStores: [] });

    const handleChangeIntegrationPercentage = async () => {
        setUberEatsChangePercentageDialogState({ open: true });
    };

    const handleOnSuccessChangePercentage = () => {
        setUberEatsChangePercentageDialogState({ open: false });
        load();
    };

    return (
        <>
            <ChangeUberIntegrationPercentageDialog
                open={uberEatsChangePercentageDialogState.open}
                onClose={() => setUberEatsChangePercentageDialogState({ open: false })}
                onSuccess={handleOnSuccessChangePercentage}
                uberCommissionPercentage={restaurantIntegrationsStatus.uberEatsSalesCommissionPercentage}
            />
            <UberEatsStoreIntegrationDialog
                open={uberEatsStoreIntegrationDialogState.open}
                uberEatsStores={uberEatsStoreIntegrationDialogState.uberEatsStores}
                handleClose={handleCloseUberEatsIntegrationDialog}
                onSuccess={uberEatsIntegrationSuccessful}
            />
            <div className={classes.integrationContainer}>
                <div className={classes.integrationInfoContainer}>
                    <div className={classes.integrationImageContainer}>
                        <img src={uberEatsLogo} alt='Uber Eats' />
                    </div>
                    <div className={classes.integrationInfo}>
                        <div className={classes.integrationNameContainer}>
                            <span className={classes.integrationName}>Uber Eats</span>
                            <span className={restaurantIntegrationsStatus.uberEatsStoreId ? classes.integrated : classes.notIntengrated}>
                                {restaurantIntegrationsStatus.uberEatsStoreId ? translate('Integrated') : translate('Not integrated')}
                            </span>
                        </div>

                        <div className={classes.integrationPercentageInfoContainer}>
                            <span className={classes.integrationPercentageInfo}>
                                {restaurantIntegrationsStatus.uberEatsSalesCommissionPercentage ? translate('Commission of') : translate('Without commission')}
                                &nbsp;
                                <strong>
                                    {restaurantIntegrationsStatus.uberEatsSalesCommissionPercentage ? formatAsPercentage(restaurantIntegrationsStatus.uberEatsSalesCommissionPercentage) : ''}
                                </strong>
                            </span>
                            <Button onClick={handleChangeIntegrationPercentage} disabled={loading || isIntegrationLoaded || viewUser} text>
                                {translate('Edit')}
                            </Button>
                        </div>
                        {restaurantIntegrationsStatus.uberEatsStoreId &&
                            (uberEatsStoreOnline ? (
                                <span className={classes.integrationStoreStatusOpen}>{translate('Opened')}</span>
                            ) : (
                                <Tooltip text={translateOfflineReason(uberEatsStoreOfflineReason, uberEatsRdOptionalDisabled, uberEatsStorePausedByUber)}>
                                    <span className={classes.integrationStoreStatusClosed}>{translate(offlineReasonByUber ? 'Paused' : 'Closed')}</span>
                                </Tooltip>
                            ))}
                    </div>
                </div>

                <div className={classes.actionsContainer}>
                    <div className={classes.buttonGroup}>
                        <Button
                            classes={{ button: !!restaurantIntegrationsStatus.uberEatsStoreId ? classes.buttonDisintegrate : undefined }}
                            onClick={async () => await handleClickIntegrateUberEats()}
                            disabled={loading || isIntegrationLoaded || viewUser}
                            primary
                        >
                            {restaurantIntegrationsStatus.uberEatsStoreId ? translate('Disintegrate') : integratingUberEats ? translate('Integrating') : translate('Integrate')}
                        </Button>
                        {restaurantIntegrationsStatus.uberEatsStoreId && (
                            <>
                                {uberEatsStoreOnline && (
                                    <Button classes={{ button: classes.buttonCloseStore }} onClick={() => closeUberEatsStore()} disabled={loading} secondary>
                                        {translate('Close')}
                                    </Button>
                                )}
                                {!uberEatsStoreOnline && (
                                    <Button classes={{ button: classes.buttonCloseStore }} onClick={() => openUberEatsStore()} disabled={loading} secondary>
                                        {translate('Open')}
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                    {adminUser && (
                        <div className={classes.integrationInfoContainer}>
                            <span className={classes.integrationLabel}>{translate('Deliver orders')}</span>
                            <Switch onChange={() => handleChangeDeliverOrdersFromUberEats()} value={restaurantIntegrationsStatus.deliverOrdersFromUberEats} name='deliverOrdersFromUberEats' />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    integrationContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        backgroundColor: theme.palette.secondary.light,
        minHeight: 120,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: 'fit-content',
            paddingTop: 20,
            paddingBottom: 20,
        },
        borderRadius: 8,
        border: '1px solid #D9D9D9',
        background: '#FAFBFB',
        padding: '12px',
    },
    integrationInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: 10,
            justifyContent: 'space-between',
        },
        gap: '1rem',
    },
    integrationImageContainer: {
        backgroundColor: 'white',
        width: 80,
        height: 80,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 20,
    },
    integrationInfo: {
        display: 'flex',
        flexDirection: 'column',
    },
    integrationName: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
    },
    integrationStatus: {
        fontFamily: theme.typography.medium,
        fontSize: 13,
    },
    integrated: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 14,
    },
    notIntengrated: {
        fontFamily: theme.typography.regular,
        color: '#fa6684',
        fontSize: 14,
    },
    integrationStoreStatusClosed: {
        fontFamily: theme.typography.semiBold,
        color: '#fa6684',
        fontSize: 13,
    },
    integrationStoreStatusOpen: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 13,
    },
    buttonDisintegrate: {
        backgroundColor: '#fa6684',
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
    },
    buttonCloseStore: {
        padding: '4px 16px',
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    dialogText: {
        fontFamily: theme.typography.regular,
        fontSize: 18,
        textAlign: 'center',
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    noMargin: {
        margin: '0 !important',
    },
    arrow: {
        color: theme.palette.secondary.light,
    },
    tooltip: {
        color: theme.palette.common.white,
        fontSize: '12px',
        backgroundColor: theme.palette.secondary.light,
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: 10,
            justifyContent: 'space-between',
        },
        gap: '1rem',
    },
    integrationLabel: {
        fontFamily: theme.typography.regular,
        color: '#2E3748',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    integrationNameContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    integrationPercentageInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1rem',
    },
    integrationPercentageInfo: {
        fontFamily: theme.typography.regular,
        color: '#2E3748',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        whiteSpace: 'nowrap',
    },
    link: {
        fontFamily: theme.typography.regular,
        color: '#06B7A2',
        fontSize: 14,
        lineHeight: '31px',
        textDecorationLine: 'underline',
        border: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#06B7A2',
            textDecorationLine: 'underline',
        },
    },
}));
