/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BrandRestaurantVm } from 'src/types/BrandRestaurantVm';
import type { RestaurantId } from 'src/types/Id';

export async function findBrandRestaurantsApi(request: FindBrandRestaurantsApiRequest): ApiSauceResponse<Array<BrandRestaurantVm>> {
    return letseatmanagerApiMethod('brand/findBrandRestaurantsApi', request);
}

export type FindBrandRestaurantsApiRequest = {
    restaurantId: RestaurantId;
};
