/**
 * @prettier
 */
import { Divider, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { changeQuickActionApi } from 'src/api/letseatadmin/quickAction/changeQuickActionApi';
import { getQuickActionApi } from 'src/api/letseatadmin/quickAction/getQuickActionApi';
import { Form } from 'src/components/form/Form';
import { FormCityAutocomplete } from 'src/components/form/FormCityAutocomplete';
import { FormCurrencyNumberField } from 'src/components/form/FormCurrencyNumberField';
import { FormDeliveryProvidersAutocomplete } from 'src/components/form/FormDeliveryProvidersAutocomplete';
import { FormDriverArrivesAtStoreTimeSelect } from 'src/components/form/FormDriverArrivesAtStoreTimeSelect';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormNotificationMessageTypeAutocomplete } from 'src/components/form/FormNotificationMessageTypeAutocomplete';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormRestaurantsAutocomplete } from 'src/components/form/FormRestaurantsAutocomplete';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormZonesAutocomplete } from 'src/components/form/FormZonesAutocomplete';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { RestaurantId, type QuickActionId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { requireValue } from 'src/utils/require/requireValue';

export function ChangeQuickActionDialog({ open, quickActionId, onClose, onQuickActionChanged }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();
    const {
        formState: { isSubmitting },
        control,
    } = form;

    const [loading, setLoading] = useState(false);

    const notificationMessage = useWatch({ name: 'notificationMessage', control });
    const includedRestaurantIds = useWatch<Array<RestaurantId>>({ name: 'includedRestaurantIds', control });
    const excludedRestaurantIds = useWatch<Array<RestaurantId>>({ name: 'excludedRestaurantIds', control });
    const includedRestaurantIdsString = useWatch<string>({ name: 'includedRestaurantIdsString', control });
    const excludedRestaurantIdsString = useWatch<string>({ name: 'excludedRestaurantIdsString', control });

    useEffect(() => {
        if (open) load();
    }, [open]);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const handleExited = () => {
        setLoading(false);
    };

    const load = async () => {
        setLoading(true);
        const response = await getQuickActionApi({ quickActionId: requireValue(quickActionId) });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            onClose();
            return;
        }
        const quickAction = response.data;
        form.reset({
            quickActionId: quickAction.quickActionId ?? null,
            name: quickAction.name ?? null,
            city: quickAction.city ?? null,
            zones: quickAction.zones ?? null,
            deliveryProviders: quickAction.deliveryProviders ?? [],
            activated: quickAction.activated ?? null,
            cashPaymentsEnabled: quickAction.cashPaymentsEnabled ?? null,
            creditCardPaymentsEnabled: quickAction.creditCardPaymentsEnabled ?? null,
            temporarilyOffline: quickAction.temporarilyOffline ?? null,
            temporarilyWithoutDelivery: quickAction.temporarilyWithoutDelivery ?? null,
            pideDirectoDeliveryEnabled: quickAction.pideDirectoDeliveryEnabled ?? null,
            rappiCargoDeliveryEnabled: quickAction.rappiCargoDeliveryEnabled ?? null,
            uberDaasDeliveryEnabled: quickAction.uberDaasDeliveryEnabled ?? null,
            uberDirectDeliveryEnabled: quickAction.uberDirectDeliveryEnabled ?? null,
            manualOrdersEnabled: quickAction.manualOrdersEnabled ?? null,
            longDistanceExtraDeliveryCostFixed: quickAction.longDistanceExtraDeliveryCostFixed ?? null,
            dynamicEarningsAmountToIncrementByMinute: quickAction.dynamicEarningsAmountToIncrementByMinute ?? null,
            dynamicEarningsMinimumMinutes: quickAction.dynamicEarningsMinimumMinutes ?? null,
            dynamicEarningsRoof: quickAction.dynamicEarningsRoof ?? null,
            deliveryCostBonus: quickAction.deliveryCostBonus ?? null,
            activeOrdersDynamicDeliveryEarnings: quickAction.activeOrdersDynamicDeliveryEarnings ?? null,
            deliveryRadiusInKm: quickAction.deliveryRadiusInKm ?? null,
            maximumNumberOfOngoingDeliveries: quickAction.maximumNumberOfOngoingDeliveries ?? null,
            customerDeliveryCostFixed: quickAction.customerDeliveryCostFixed ?? null,
            customerDeliveryCostPerKm: quickAction.customerDeliveryCostPerKm ?? null,
            driverArrivesAtStoreTime: quickAction.driverArrivesAtStoreTime ?? null,
            managerMessageBody: quickAction.managerMessageBody ?? null,
            managerMessageTitle: quickAction.managerMessageTitle ?? null,
            managerMessageStyle: quickAction.managerMessageStyle ?? null,
            notificationMessageBody: quickAction.notificationMessageBody ?? null,
            notificationMessageTitle: quickAction.notificationMessageTitle ?? null,
            notificationMessageType: quickAction.notificationMessageType ?? [],
            endUserMessage: quickAction.endUserMessage ?? null,
            excludedRestaurantIds: quickAction.excludedRestaurantIds ?? [],
            includedRestaurantIds: quickAction.includedRestaurantIds ?? [],
        });
        setLoading(false);
    };

    const onSubmit = async (form: any) => {
        let allIncludedRestaurantsIds = form.includedRestaurantIds;
        let allExcludedRestaurantsIds = form.excludedRestaurantIds;
        if (form.includedRestaurantIdsString) {
            allIncludedRestaurantsIds = form.includedRestaurantIdsString.split(',').map((restaurantId: RestaurantId) => restaurantId.replace(/\s/g, ''));
            allIncludedRestaurantsIds = removeDuplicates([...form.includedRestaurantIds, ...allIncludedRestaurantsIds]);
        }
        if (form.excludedRestaurantIdsString) {
            allExcludedRestaurantsIds = form.excludedRestaurantIdsString.split(',').map((restaurantId: RestaurantId) => restaurantId.replace(/\s/g, ''));
            allExcludedRestaurantsIds = removeDuplicates([...form.excludedRestaurantIds, ...allExcludedRestaurantsIds]);
        }
        const response = await changeQuickActionApi({
            quickActionId: requireValue(quickActionId),
            name: form.name,
            city: form.city,
            zones: form.zones,
            deliveryProviders: form.deliveryProviders,
            activated: form.activated,
            cashPaymentsEnabled: form.cashPaymentsEnabled,
            creditCardPaymentsEnabled: form.creditCardPaymentsEnabled,
            temporarilyOffline: form.temporarilyOffline,
            temporarilyWithoutDelivery: form.temporarilyWithoutDelivery,
            pideDirectoDeliveryEnabled: form.pideDirectoDeliveryEnabled,
            rappiCargoDeliveryEnabled: form.rappiCargoDeliveryEnabled,
            uberDaasDeliveryEnabled: form.uberDaasDeliveryEnabled,
            uberDirectDeliveryEnabled: form.uberDirectDeliveryEnabled,
            manualOrdersEnabled: form.manualOrdersEnabled,
            longDistanceExtraDeliveryCostFixed: form.longDistanceExtraDeliveryCostFixed,
            dynamicEarningsAmountToIncrementByMinute: form.dynamicEarningsAmountToIncrementByMinute,
            dynamicEarningsMinimumMinutes: form.dynamicEarningsMinimumMinutes,
            dynamicEarningsRoof: form.dynamicEarningsRoof,
            deliveryCostBonus: form.deliveryCostBonus,
            activeOrdersDynamicDeliveryEarnings: form.activeOrdersDynamicDeliveryEarnings,
            deliveryRadiusInKm: form.deliveryRadiusInKm,
            maximumNumberOfOngoingDeliveries: form.maximumNumberOfOngoingDeliveries,
            customerDeliveryCostFixed: form.customerDeliveryCostFixed,
            customerDeliveryCostPerKm: form.customerDeliveryCostPerKm,
            driverArrivesAtStoreTime: form.driverArrivesAtStoreTime,
            managerMessageBody: form.managerMessageBody,
            managerMessageTitle: form.managerMessageTitle,
            managerMessageStyle: form.managerMessageStyle,
            notificationMessageBody: form.notificationMessageBody,
            notificationMessageTitle: form.notificationMessageTitle,
            notificationMessageType: form.notificationMessageType,
            endUserMessage: form.endUserMessage,
            excludedRestaurantIds: allExcludedRestaurantsIds,
            includedRestaurantIds: allIncludedRestaurantsIds,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onQuickActionChanged();
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} onExited={handleExited}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Change Quick Action').toUpperCase()}</DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField name='name' label={translate('Name')} placeholder={translate('Rainy day')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCityAutocomplete name='city' label={translate('City')} placeholder={translate('All')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormZonesAutocomplete name='zones' label={translate('Zones')} placeholder={translate('All')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormDeliveryProvidersAutocomplete name='deliveryProviders' label={translate('Delivery Providers')} placeholder={translate('All')} />
                        </Grid>
                        <Divider />
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='activated' label={translate('activated')} helperText={translate('With this switch you can activated/deactivated the quick action')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='cashPaymentsEnabled' label={translate('Cash payments')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='creditCardPaymentsEnabled' label={translate('Credit card payments')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='temporarilyOffline' label={translate('Set Temporarily Offline All Online Orders')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='temporarilyWithoutDelivery' label={translate('Set Temporarily Offline All Delivery Orders')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='manualOrdersEnabled' label={translate('Manual OrdersPage')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='pideDirectoDeliveryEnabled' label={translate('Pide Directo Delivery Enabled')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='uberDaasDeliveryEnabled' label={translate('Uber Daas Delivery Enabled')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='uberDirectDeliveryEnabled' label={translate('Uber Eats Delivery Enabled')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='rappiCargoDeliveryEnabled' label={translate('Rappi Cargo Delivery Enabled')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField
                                name='longDistanceExtraDeliveryCostFixed'
                                label={translate('Driver Long Distance Delivery Extra Earnings Fixed')}
                                helperText={translate(
                                    'Add this extra earnings for drivers when delivery distance for a delivery is longer than in setting "Driver Long Distance Delivery Extra Earnings Starts From Km". If not set the default value used is configured in Settings.'
                                )}
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField name='dynamicEarningsMinimumMinutes' label={translate('Dynamic Earnings Minimum Minutes')} min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberField name='dynamicEarningsAmountToIncrementByMinute' label={translate('Dynamic Earnings Amount To Increment By Minute')} min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberField name='dynamicEarningsRoof' label={translate('Dynamic Earnings Roof')} min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberField name='deliveryCostBonus' label={translate('Delivery Cost Bonus')} min={0} />
                        </Grid>

                        <Grid item xs={12}>
                            <FormCurrencyNumberField name='activeOrdersDynamicDeliveryEarnings' label={translate('Active Orders Dynamic Delivery Earnings')} min={0} />
                        </Grid>

                        <Grid item xs={12}>
                            <FormNumberField
                                name='deliveryRadiusInKm'
                                label={translate('Delivery Radius in Km')}
                                min={0}
                                helperText={translate('This will set the delivery radius for the city and zone')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField name='maximumNumberOfOngoingDeliveries' label={translate('Maximum amount of multi-pickup orders at the same time')} min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberField name='customerDeliveryCostFixed' label={translate('Customer Delivery Cost Fixed')} min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberField name='customerDeliveryCostPerKm' label={translate('Customer Delivery Cost Per Km')} min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormDriverArrivesAtStoreTimeSelect name='driverArrivesAtStoreTime' label={translate('Driver Arrives At Store Time')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='managerMessageTitle' label={translate('Manager Message Title')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='managerMessageBody' label={translate('Manager Message Body')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                name='managerMessageStyle'
                                label={translate('Manager Message Style')}
                                helperText={translate(
                                    'Can be "error", "warning", "info", "success", "text". For looks see https://material-ui.com/components/alert . You can create multiple messages by separating them with a semicolon. E.g. "warning;info"'
                                )}
                                placeholder={'warning'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='notificationMessageBody' label={translate('Notification Message Body')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='notificationMessageTitle' label={translate('Notification Message Title')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNotificationMessageTypeAutocomplete name='notificationMessageType' label={translate('Notification Message Type')} required={!!notificationMessage} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='endUserMessage' label={translate('Message To End User')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormRestaurantsAutocomplete
                                name='includedRestaurantIds'
                                label={translate('Applicable restaurants')}
                                disabled={!!excludedRestaurantIds?.length || !!excludedRestaurantIdsString?.length}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                name='includedRestaurantIdsString'
                                label={translate('Include Restaurants by Id')}
                                disabled={!!excludedRestaurantIds?.length || !!excludedRestaurantIdsString?.length}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormRestaurantsAutocomplete
                                name='excludedRestaurantIds'
                                label={translate('Restaurants excluded')}
                                disabled={!!includedRestaurantIds?.length || !!includedRestaurantIdsString?.length}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                name='excludedRestaurantIdsString'
                                label={translate('Exclude Restaurants by Id')}
                                disabled={!!includedRestaurantIds?.length || !!includedRestaurantIdsString?.length}
                            />
                        </Grid>
                    </Grid>
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={loading || isSubmitting}>
                        {isSubmitting ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        overflow: 'hidden',
    },
}));

type Props = {
    open: boolean;
    quickActionId: QuickActionId | undefined;
    onClose: any;
    onQuickActionChanged: any;
};
