/**
 * @prettier
 */
import { useContext } from 'react';
import { ConfirmDialogContext, ConfirmDialogOptions } from 'src/providers/ConfirmDialogProvider';

export function useConfirmDialog(): (arg1: ConfirmDialogOptions) => Promise<boolean | void> | void {
    const context = useContext(ConfirmDialogContext);

    return context.confirmDialog;
}
