/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { findAvailableRestaurantZonesAndTablesApi } from 'src/api/letseatmanager/pos/findAvailableRestaurantZonesAndTablesApi';
import { ApiResponse } from 'src/api/types/ApiSauceResponse';
import { FormSelect } from 'src/components/form/FormSelect';
import { translate } from 'src/i18n/translate';
import { getPosRestaurantZonesOfflineInLocalStorage } from 'src/localStorage/getPosRestaurantZonesOfflineInLocalStorage';
import { TableVm } from 'src/types/RestaurantZoneVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function FormAvailableTablesSelect(): React.ReactElement {
    const classes = useStyles();

    const { control } = useFormContext();
    const restaurantZoneId = useWatch({ name: 'restaurantZoneId', control });

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loadingAvailableRestaurantZones, availableRestaurantZones] = useLoadApi(findAvailableRestaurantZonesAndTablesApi, { restaurantId }, { initialValue: [], onError: handleApiError });

    const disabledSelect = loadingAvailableRestaurantZones || !availableRestaurantZones.length;

    const availableZoneOptions = availableRestaurantZones.map((currentZone) => ({
        label: currentZone.name,
        value: currentZone.restaurantZoneId,
    }));
    const zoneSelected = availableRestaurantZones.find((zone) => zone.restaurantZoneId === restaurantZoneId);
    const availableTableOptions = !zoneSelected
        ? []
        : zoneSelected.tables?.map((currentTable: TableVm) => ({
              value: currentTable.tableNumber,
              label: currentTable.tableNumber,
          })) ?? [];

    return (
        <div className={classes.content}>
            <FormSelect name={'restaurantZoneId'} options={availableZoneOptions} required label={translate('Available Zones')} placeholder={translate('Select a Zone')} disabled={disabledSelect} />
            <FormSelect name={'table'} options={availableTableOptions} required label={translate('Available Tables')} disabled={Boolean(!restaurantZoneId || disabledSelect)} />
            {!availableZoneOptions.length && !loadingAvailableRestaurantZones && <p className={classes.bodyText}>{translate(`No tables available`)}</p>}
        </div>
    );
}

function handleApiError(response: ApiResponse<any>, setData: any) {
    if (response.problem === 'NETWORK_ERROR') {
        const restaurantZonesOffline = getPosRestaurantZonesOfflineInLocalStorage();
        setData(restaurantZonesOffline);
        return;
    }
    return alertKnownErrorOrSomethingWentWrong(response);
}

const useStyles = makeStyles((theme) => ({
    bodyText: {
        fontFamily: theme.typography.medium,
        textAlign: 'center',
        color: '#2E3748',
        fontSize: 12,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
    },
}));
