/**
 * @prettier
 */
import { useEffect } from 'react';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { RestaurantId } from 'src/types/Id';
import { OrderVm } from 'src/types/OrderVm';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function usePrintAutoAcceptedOrderSubscription(handlePrintOrderWebSocketEvent: (event: WebSocketEvent<PrintOrderWebSocketEventResponse>) => Promise<void>) {
    const restaurantId = useSelector((state) => state.app.restaurantId);

    useEffect(() => {
        const printAcceptedOrderWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.PRINT_AUTO_ACCEPTED_ORDER, handlePrintOrderWebSocketEvent);

        return () => {
            printAcceptedOrderWebSocketEvent.remove();
        };
    }, [restaurantId]);
}

export type PrintOrderWebSocketEventResponse = {
    order: OrderVm;
    restaurantId: RestaurantId;
};
