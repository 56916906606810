/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesType } from 'src/constants/SalesTypes';
import type { RestaurantId } from 'src/types/Id';

export async function getDeliveryRestaurantStatisticsApi(request: GetDeliveryRestaurantStatisticsRequest): ApiSauceResponse<GetDeliveryStatisticsApiResponse> {
    return pidedirectoReportsMethod('/deliveryPageReportApi', request);
}

export type GetDeliveryRestaurantStatisticsRequest = {
    report: string;
    reportPage?: string;
    abcTableFilter?: string;
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
    salesType: SalesType;
};

export type GetDeliveryStatisticsApiResponse = Array<{
    averageDeliveryTimeByDeliveryMethod: Array<AverageDeliveryTimeByDeliveryMethod>;
    averageDeliveryTimeByDeliveryMethodAndDay: Array<AverageDeliveryTimeByDeliveryMethodAndDay>;
    maximumDeliveryTimeByDeliveryMethod: Array<MaximumDeliveryTimeByDeliveryMethod>;
    name: string;
    restaurantId: RestaurantId;
    salesByDeliveryMethod?: Array<SalesByDeliveryMethod>;
    listOfAvailableDeliveryChannels: Array<string>;
}>;

export type AverageDeliveryTimeByDeliveryMethod = {
    deliveryMethod: string;
    timeAverage: number;
};

export type AverageDeliveryTimeByDeliveryMethodAndDay = {
    createdAt: string;
    timeAverage: number;
    deliveryMethod: string;
};

export type SalesByDeliveryMethod = {
    deliveryMethod: string;
    orderVolume: number;
};

export type MaximumDeliveryTimeByDeliveryMethod = {
    deliveryMethod: string;
    timeMaximum: number;
};
