/**
 * @prettier
 */
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { IntegrationMenuApprovalVm, realTimeUpdatesApi, RealTimeUpdatesApiRequest, RealTimeUpdatesApiResponse } from 'src/api/letseatmanager/order/realTimeUpdatesApi';
import { app2 } from 'src/app2';
import type { DriverArrivesAtStoreTime } from 'src/constants/DriverArrivesAtStoreTime';
import { Environments } from 'src/constants/Environment';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { SubscriptionPlan, SubscriptionPlans } from 'src/constants/SubscriptionPlan';
import type { SubscriptionStatus } from 'src/constants/SubscriptionStatus';
import { SECONDS } from 'src/constants/TimeUnit';
import type { UberEatsStoreOfflineReason } from 'src/constants/UberEatsStoreOfflineReason';
import { envENVIRONMENT } from 'src/env/envENVIRONMENT';
import { useFindOpenedUserCashRegisterPosBusinessDay } from 'src/services/cashRegister/useFindOpenedUserCashRegisterPosBusinessDay';
import { useUserType } from 'src/services/restaurant/useUserType';
import { SentryService } from 'src/services/SentryService';
import { CashRegisterPosBusinessDayId, RestaurantId, type ManagerUserId } from 'src/types/Id';
import { MinimumSupplyLimit } from 'src/types/MinimumSupplyLimit';
import { OrderVm } from 'src/types/OrderVm';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';
import { isBrandUser } from 'src/utils/user/isBrandUser';
import { wait } from 'src/utils/wait';

let callingApi = false;
export function useFetchRealtimeUpdates(options?: Options): [any] {
    const secondsToFetchRealtimeUpdates = (envENVIRONMENT() === Environments.LOCALHOST ? 10 : 4) * SECONDS;

    const location = useLocation();
    const userType = useUserType();
    const [userHasRolePermission] = useUserHasRolePermission();
    const { findUserCashRegisterPosBusinessDay } = useFindOpenedUserCashRegisterPosBusinessDay();

    const openedCashRegisterPosBusinessDayRef = useRef<CashRegisterPosBusinessDayId>();
    const locationRef = useRef(location.pathname);
    const restaurantIdRef = useRef<RestaurantId>();
    const hasAlreadyStartedFetching = useRef(false);
    const lastTimeIncomingOrdersDialogOpened = useRef<Date>();
    const brandOpenedRef = useRef(false);

    const restaurants = useSelector((state) => state.app.restaurants);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const lastTimeOpened = useSelector((state) => state.app2.incomingOrdersAlertState.lastTimeOpened);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const internalUser = useSelector((state) => state.authentication.internalUser);
    const kitchensUser = useSelector((state) => state.authentication.kitchensUser);
    const kioskUser = useSelector((state) => state.authentication.kioskUser);
    const newOrdersPageEnabled = useSelector((state) => state.app.restaurant.newOrdersPageEnabled);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant.posMultipleCashRegistersEnabled);
    const openedCashRegisterPosBusinessDay = useSelector((state) => state.pos.openedCashRegisterPosBusinessDay);

    const fetchRealtimeUpdatesFailed = useAction(app2.actions.fetchRealtimeUpdatesFailed);
    const openIncomingOrdersAlert = useAction(app2.actions.openIncomingOrdersAlert);
    const fetchSuccessCurrentOrders = useAction(app2.actions.fetchSuccessCurrentOrders);
    const openMinimumInventoryReachedAlert = useAction(app2.actions.openMinimumInventoryReachedAlert);
    const closeIncomingOrdersAlert = useAction(app2.actions.closeIncomingOrdersAlert);
    const setRealtimeUpdate = useAction(app2.actions.setRealtimeUpdate);
    const openIntegrationMenuChangesApprovalDialog = useAction(app2.actions.openIntegrationMenuChangesApprovalDialog);

    const userOpenedCashRegisterPosBusinessDay = findUserCashRegisterPosBusinessDay();

    const someRestaurantHasNewOrdersPageEnabled = restaurants.some((restaurant) => restaurant.newOrdersPageEnabled);

    const cashRegisterPosBusinessDayId = openedCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId ?? userOpenedCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId;
    const shouldFilterByCashRegisterPosBusinessDayId =
        !!userHasRolePermission(RolePermissions.VIEW_ONLY_ORDERS_IN_PERSONAL_CASH_REGISTER) &&
        !!posMultipleCashRegistersEnabled &&
        (!!openedCashRegisterPosBusinessDay || !!userOpenedCashRegisterPosBusinessDay) &&
        !brandOpened;

    useEffect(() => {
        lastTimeIncomingOrdersDialogOpened.current = lastTimeOpened;
    }, [lastTimeOpened]);

    useEffect(() => {
        brandOpenedRef.current = brandOpened;
    }, [brandOpened]);

    useEffect(() => {
        locationRef.current = location.pathname;
    }, [location]);

    useEffect(() => {
        restaurantIdRef.current = restaurantId;
    }, [restaurantId]);

    useEffect(() => {
        lastTimeIncomingOrdersDialogOpened.current = lastTimeOpened;
    }, [lastTimeOpened]);

    useEffect(() => {
        if (shouldFilterByCashRegisterPosBusinessDayId && openedCashRegisterPosBusinessDayRef) {
            openedCashRegisterPosBusinessDayRef.current = cashRegisterPosBusinessDayId;
        }

        if (!shouldFilterByCashRegisterPosBusinessDayId) {
            openedCashRegisterPosBusinessDayRef.current = undefined;
        }
    }, [shouldFilterByCashRegisterPosBusinessDayId]);

    const handleFetchRealTimeUpdates = () => {
        if (hasAlreadyStartedFetching.current) return;
        hasAlreadyStartedFetching.current = true;
        fetchRealtimeUpdates();
    };

    const fetchRealtimeUpdates = async () => {
        if (callingApi) return;
        if (!shouldFetchRealtimeUpdates() || !restaurantIdRef.current) return waitToFetchAgain();

        let request: RealTimeUpdatesApiRequest = { restaurantId: restaurantIdRef.current };

        if (!!openedCashRegisterPosBusinessDayRef.current) {
            request = { ...request, cashRegisterPosBusinessDayId: openedCashRegisterPosBusinessDayRef.current };
        }

        callingApi = true;
        const response = await realTimeUpdatesApi(request);
        callingApi = false;
        if (!response.ok) {
            SentryService.logInfoBreadcrumb('Failed to downloaded realtime updates', { request: { restaurantId }, response });
            fetchRealtimeUpdatesFailed({ e: response.problem });
            waitToFetchAgain();
            return;
        }

        const update = response.data;
        const minimumSuppliesLimit = update.minimumSuppliesLimit;
        SentryService.logInfoBreadcrumb('Successfully downloaded realtime updates', { request: { restaurantId } });

        const realtimeUpdate: RealtimeUpdate = {
            ...getGeneralUpdate(update),
            ...getSubscriptionUpdate(update),
            ...getIntegrationsUpdate(update),
        };
        setCurrentOrdersInfo(update);
        openMinimumInventoryAlert(minimumSuppliesLimit);
        handleInventoryMenuIntegrationsDialog(update.integrationMenuChangesApprovals ?? []);
        setRealtimeUpdate(realtimeUpdate);

        waitToFetchAgain();
    };

    const shouldFetchRealtimeUpdates = () => {
        const isOngoingOrdersLocation = locationRef.current === RoutePaths.ONGOING_ORDERS;
        const isOrdersPage = locationRef.current === RoutePaths.ORDERS;
        const isPosLocation = locationRef.current === RoutePaths.POS;
        const isKitchenLocation = locationRef.current === RoutePaths.KITCHEN_DISPLAY_SCREEN;
        const isIntegrationsLocation = locationRef.current === RoutePaths.INTEGRATIONS;
        const isTheMenuLocation = locationRef.current === RoutePaths.THE_MENU;

        const newOrdersPage = isOrdersPage && ((someRestaurantHasNewOrdersPageEnabled && isBrandUser(userType)) || newOrdersPageEnabled);

        if (!restaurantIdRef.current) return false;
        if ((internalUser || kioskUser || kitchensUser) && !isOngoingOrdersLocation && !isPosLocation && !isKitchenLocation && !newOrdersPage && !isIntegrationsLocation && !isTheMenuLocation)
            return false;
        return true;
    };

    const waitToFetchAgain = async () => {
        await wait(secondsToFetchRealtimeUpdates);
        if (!options?.callApiContinuously) {
            hasAlreadyStartedFetching.current = false;
            return;
        }

        fetchRealtimeUpdates();
    };

    const getGeneralUpdate = (update: RealTimeUpdatesApiResponse) => {
        return {
            managerMessageStyle: update.managerMessageStyle,
            managerMessageTitle: update.managerMessageTitle,
            managerMessageBody: update.managerMessageBody,
            managerMessageUrl: update.managerMessageUrl,
            isCopyingMenu: update.isCopyingMenu,
            isRemovingMenu: update.isRemovingMenu,
            driverArrivesAtStoreTime: update.driverArrivesAtStoreTime,
            quickActionMessage: update.quickActionMessage,
            storeShouldBeOpen: update.storeShouldBeOpen,
            currentEmployeesClockedIn: update.currentEmployeesClockedIn ?? [],
        };
    };

    const getSubscriptionUpdate = (update: RealTimeUpdatesApiResponse) => {
        const subscriptionPayPerUseAccess = update.subscriptionPlan && update.subscriptionPlan === SubscriptionPlans.PAY_PER_USE && !update.remainingDays;
        const subscriptionAccess = !internalUser && restaurantId && (update.remainingDays !== undefined || subscriptionPayPerUseAccess);

        const subscriptionUpdate: any = {
            remainingDays: update.remainingDays,
            subscriptionAccess: !!subscriptionAccess,
            subscriptionStatus: update.subscriptionStatus,
        };

        if (update.subscriptionPlan) {
            subscriptionUpdate.subscriptionPlan = update.subscriptionPlan;
        }

        if (update.payPerUsePaymentAmountLimitReached) {
            subscriptionUpdate.payPerUsePaymentAmountLimitReached = update.payPerUsePaymentAmountLimitReached;
        }

        return subscriptionUpdate;
    };

    const getIntegrationsUpdate = (update: RealTimeUpdatesApiResponse) => {
        return {
            didiFoodStoreOnline: update.didiFoodStoreOnline,
            rappiStoreOnline: update.rappiStoreOnline,
            uberEatsStoreOnline: update.uberEatsStoreOnline,
            uberEatsStoreOfflineReason: update.uberEatsStoreOfflineReason,
            uberEatsRdOptionalDisabled: update.uberEatsRdOptionalDisabled,
            uberEatsStorePausedByUber: update.uberEatsStorePausedByUber,
            pedidosYaRestaurantOnline: update.pedidosYaRestaurantOnline,
        };
    };

    const setCurrentOrdersInfo = (update: RealTimeUpdatesApiResponse) => {
        const currentOrders = update.updatedOrders;
        openNewOrdersAlert(currentOrders);
        fetchSuccessCurrentOrders({ currentOrders });
    };

    const getNewOrders = (currentOrders: Array<OrderVm>) => {
        if (brandOpenedRef.current) return currentOrders.filter((order) => order.orderStatus === OrderStatuses.NEW);
        return currentOrders.filter((order) => order.orderStatus === OrderStatuses.NEW && order.restaurant?.restaurantId === restaurantId);
    };

    const getMinimumInventoryReached = (minimumSuppliesLimit: undefined | Array<MinimumSupplyLimit>) => {
        return minimumSuppliesLimit?.filter((minimumSupplyLimit: MinimumSupplyLimit) => minimumSupplyLimit.menuItemIds && minimumSupplyLimit.menuItemIds.length > 0);
    };

    const openMinimumInventoryAlert = (minimumSuppliesLimit: undefined | Array<MinimumSupplyLimit>) => {
        const minimumSupplies = getMinimumInventoryReached(minimumSuppliesLimit);
        if (!minimumSupplies?.length) return;
        openMinimumInventoryReachedAlert({ minimumSuppliesLimit: minimumSupplies });
    };

    const handleInventoryMenuIntegrationsDialog = (integrationMenuChangesApprovals: Array<IntegrationMenuApprovalVm> | Array<any>) => {
        if (!userHasRolePermission(RolePermissions.MENU_EDIT)) return;
        if (integrationMenuChangesApprovals.length === 0) return;

        openIntegrationMenuChangesApprovalDialog({ menu: integrationMenuChangesApprovals[0] });
    };

    const openNewOrdersAlert = (currentOrders: Array<OrderVm>) => {
        let newOrders = getNewOrders(currentOrders);

        if (!lastTimeIncomingOrdersDialogOpened.current && newOrders.length > 0) {
            return openIncomingOrdersAlert({ newOrders });
        }

        newOrders = currentOrders.filter((order) => {
            if (brandOpenedRef.current) {
                return filterNewOrderForMaster(order);
            } else {
                return filterNewOrder(order, restaurantIdRef.current);
            }
        });

        if (!newOrders.length) {
            return closeIncomingOrdersAlert();
        }

        openIncomingOrdersAlert({
            newOrders,
        });
    };

    const filterNewOrderForMaster = (order: OrderVm) => {
        return order.orderStatus === OrderStatuses.NEW;
    };

    const filterNewOrder = (order: OrderVm, restaurantId: undefined | RestaurantId) => {
        return order.orderStatus === OrderStatuses.NEW && order.restaurant?.restaurantId === restaurantId;
    };

    return [handleFetchRealTimeUpdates];
}

type Options = {
    callApiContinuously?: boolean;
};

type RealtimeUpdate = {
    managerMessageStyle: string | undefined;
    managerMessageTitle: string | undefined;
    managerMessageBody: string | undefined;
    managerMessageUrl: string | undefined;
    isCopyingMenu: boolean;
    isRemovingMenu: boolean;
    driverArrivesAtStoreTime: DriverArrivesAtStoreTime | undefined;
    quickActionMessage: string | undefined;
    storeShouldBeOpen: boolean;
    currentEmployeesClockedIn: Array<ManagerUserId>;
    remainingDays: number;
    subscriptionPlan?: SubscriptionPlan;
    subscriptionStatus: SubscriptionStatus | undefined;
    subscriptionAccess: boolean;
    payPerUsePaymentAmountLimitReached?: boolean;
    didiFoodStoreOnline: boolean | undefined;
    uberEatsStoreOnline: boolean | undefined;
    pedidosYaRestaurantOnline: boolean | undefined;
    uberEatsStoreOfflineReason: UberEatsStoreOfflineReason | undefined;
    uberEatsRdOptionalDisabled: boolean | undefined;
    uberEatsStorePausedByUber: boolean | undefined;
};
