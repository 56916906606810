/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { Button } from 'src/components/Button';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { translate } from 'src/i18n/translate';
import { useAskForPassword } from 'src/utils/react/useAskForPassword';
import { useSelector } from 'src/utils/react/useSelector';

export function LokiSetting(): React.ReactElement | null {
    const classes = useStyles();
    const askForPassword = useAskForPassword();

    const [disabled, setDisabled] = useState(true);

    const adminUser = useSelector((state) => state.authentication.adminUser);

    const enabledSetting = async () => {
        if (!disabled) return;

        const rightPassword = await askForPassword({ password: '9134' });
        if (!rightPassword) return;

        setDisabled(false);
    };

    if (!adminUser) return null;

    return (
        <div className={classes.container}>
            <FormSwitch name='lokiEnabled' label={translate('Loki enabled')} disabled={disabled} />
            <Button onClick={enabledSetting} text disabled={!disabled}>
                {translate('Enable loki edition')}
            </Button>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
    },
}));
