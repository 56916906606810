/**
 * @prettier
 */
import { Grid, makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { FormNumberStringField } from 'src/components/form/FormNumberStringField';
import { FormSupplyAutocomplete } from 'src/components/form/FormSupplyAutocomplete';
import { FormUnitOfMeasurementSelect } from 'src/components/form/FormUnitOfMeasurementSelect';
import { SupplyType } from 'src/constants/SupplyType';
import { translate } from 'src/i18n/translate';
import { TrashcanIcon } from 'src/icons/TrashcanIcon';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { SupplyId } from 'src/types/Id';
import type { Ingredient } from 'src/types/RecipeVm';
import { SupplyVm } from 'src/types/SupplyVm';
import { isCompoundSupply } from 'src/utils/inventory/supply/isCompoundSupply';

export function CompoundSupplyIngredients({ ingredients, onChangeSupplyIngredients, supplyId }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useFormContext();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const {
        formState: { isSubmitting },
        control,
    } = form;

    const [currentSupply, setCurrentSupply] = useState<SupplyVm>();
    const [supplyIngredients, setSupplyIngredients] = useState<Array<Ingredient>>([]);

    const supplyQuantity = useWatch<string>({ name: 'supplyQuantity', control });
    const type = useWatch<SupplyType>({ name: 'type', control });

    useEffect(() => {
        onChangeSupplyIngredients(supplyIngredients);
    }, [supplyIngredients]);

    useEffect(() => {
        if (!ingredients) return;
        setSupplyIngredients(ingredients);
    }, [ingredients]);

    const addRecipeSupply = () => {
        if (!currentSupply || !supplyQuantity) return;
        if (supplyIngredients?.some((recipeSupply) => recipeSupply.supply.supplyId === currentSupply.supplyId)) return;

        setSupplyIngredients([...supplyIngredients, { supply: currentSupply, quantity: supplyQuantity }]);

        form.setValue('supply', undefined);
        form.setValue('supplyQuantity', undefined);
    };

    const onSupplyChange = (supply: SupplyVm) => {
        setCurrentSupply(supply);
        form.setValue('unitOfMeasurement', supply.sellUnit);
    };

    const removeRecipeSupply = (supplyId: SupplyId) => {
        setSupplyIngredients(supplyIngredients?.filter((recipeSupply) => recipeSupply.supply.supplyId !== supplyId));
    };

    return (
        <Grid item xs={12} md={12} className={classes.supplyIngredientsContainer}>
            <h1 className={classes.title}>{translate('Ingredients')}</h1>
            <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                    <FormSupplyAutocomplete
                        name='supply'
                        label={translate('Supply')}
                        onChange={onSupplyChange}
                        filterOptions={supplyId ? (supply: SupplyVm) => supply.supplyId !== supplyId : undefined}
                    />
                </Grid>
                <Grid item xs={2} md={2}>
                    <FormNumberStringField name='supplyQuantity' label={translate('Quantity')} />
                </Grid>
                <Grid item xs={2} md={4}>
                    <FormUnitOfMeasurementSelect name='unitOfMeasurement' label={translate('Unit')} disabled />
                </Grid>
                <Grid item xs={2} md={12}>
                    <Button type='button' onClick={addRecipeSupply} classes={{ button: classes.addButton }} disabled={isSubmitting}>
                        {translate('Add')}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {supplyIngredients.map((recipeSupply) => {
                        const { buyUnits, fixedCost } = recipeSupply.supply;

                        let priceUnit = BigNumber(fixedCost);

                        if (isCompoundSupply(recipeSupply.supply.type) || isCompoundSupply(type)) {
                            const mainUnit = buyUnits.find((buyUnit) => buyUnit.isMainUnit);
                            priceUnit = BigNumber(fixedCost).dividedBy(mainUnit?.conversionFactor ?? 1);
                        }

                        const totalUsed = BigNumber(recipeSupply.quantity);

                        const estimatedPriceOfSupply = BigNumber(priceUnit).multipliedBy(totalUsed).toFixed(2);

                        return (
                            <div className={classes.supplyContainer} key={recipeSupply.supply.supplyId}>
                                <span>
                                    {recipeSupply.quantity} {recipeSupply.supply.sellUnit} | {recipeSupply.supply.name} | {formatAsCurrencyNumber(estimatedPriceOfSupply)}
                                </span>

                                <div>
                                    <Button icon onClick={() => removeRecipeSupply(recipeSupply.supply.supplyId)}>
                                        <TrashcanIcon color='#616B79' />
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    supplyIngredientsContainer: {
        marginBottom: 50,
    },
    title: {
        fontSize: 20,
        fontWeight: 600,
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        '& td': {
            border: `0.1px solid ${theme.palette.secondary.dark}`,
            padding: 10,
        },
        '& th': {
            border: `0.1px solid ${theme.palette.secondary.dark}`,
            padding: 10,
        },
    },
    supplyContainer: {
        width: '100 %',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 15px',
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
    button: {
        marginTop: 10,
    },
    unit: {
        fontSize: 16,
        marginTop: 30,
    },
    addButton: {
        width: 78,
        height: 34,
        padding: 8,
        minHeight: 34,
        borderRadius: 8,
        gap: 10,
        marginTop: 20,
    },
}));

type Props = {
    supplyId?: SupplyId;
    ingredients?: Array<Ingredient>;
    onChangeSupplyIngredients: any;
};
