/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormSubheading } from 'src/components/form/FormSubheading';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextAreaField } from 'src/components/form/FormTextAreaField';
import { translate } from 'src/i18n/translate';

export function RestaurantUserManagerSettings(): React.ReactElement {
    const { control } = useFormContext();

    const clockInEmployeeDayShiftEnabled = useWatch<boolean>({ name: 'clockInEmployeeDayShiftEnabled', control });
    const posMultipleCashRegistersEnabled = useWatch<boolean>({ name: 'posMultipleCashRegistersEnabled', control });

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormSubheading align={'center'}>{translate('Users Management')}</FormSubheading>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <>
                    <Grid item xs={12} md={6}>
                        <FormSwitch
                            disabled={posMultipleCashRegistersEnabled}
                            name='clockInEmployeeDayShiftEnabled'
                            label={translate('Enable clock in and clock out')}
                            tooltip={translate('Enabled this feature to manage clock in and clock out for POS employees')}
                        />
                    </Grid>
                    {clockInEmployeeDayShiftEnabled && (
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <FormTextAreaField name='clockInMessage' label={translate('Clock in message')} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormTextAreaField name='clockOutMessage' label={translate('Clock out message')} />
                            </Grid>
                        </Grid>
                    )}
                </>
            </Grid>
        </div>
    );
}
