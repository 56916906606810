/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantVideoId } from 'src/types/Id';

export async function findRestaurantVideosApi(): ApiSauceResponse<RestaurantVideosVm> {
    return letseatadminApiMethod('restaurantVideo/findRestaurantVideosApi');
}

export type RestaurantVideosVm = {
    restaurantVideos: Array<RestaurantVideoVm>;
};

export type RestaurantVideoVm = {
    restaurantVideoId: RestaurantVideoId;
    name: string;
    videoUrl: string;
    createdAt: Date;
    modifiedAt: Date;
};
