/**
 * @prettier
 */
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { translate } from 'src/i18n/translate';
import { printerInstructionsBuilder } from 'src/services/printer/prints/utils/printerInstructionsBuilder';
import type { PrinterInstruction } from 'src/services/printer/types/PrinterInstruction';
import { PartialCashRegisterShiftReportVm } from 'src/types/PartialCashRegisterShiftReportVm';
import type { RestaurantVm } from 'src/types/RestaurantVm';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { isIntegrationApp } from 'src/utils/restaurant/isIntegrationApp';

export function createPartialShiftCashRegisterReportPrint({ partialShiftCashRegisterReport, restaurant }: Params): Array<PrinterInstruction> {
    const builder = printerInstructionsBuilder();

    builder.addCenteredBoldText(translate('Partial cut'));
    builder.addCenteredBoldText(partialShiftCashRegisterReport.restaurantName);
    builder.addNewLine();
    if (partialShiftCashRegisterReport.username) {
        builder.addSeparatedTexts(translate('User'), partialShiftCashRegisterReport.username);
    }
    builder.addSeparatedTexts(translate('Print date'), formatDateTimeString(new Date(), restaurant.timeZone));
    builder.addSeparatedTexts(translate('Completed orders'), partialShiftCashRegisterReport.totalCompletedOrders?.toString());
    builder.addSeparatedTexts(translate('Ongoing orders'), partialShiftCashRegisterReport.totalOngoingOrders?.toString());
    addCashRegisterReport();

    builder.addCenteredText(translate('With technology from Ambit.la'));
    return builder.build();

    function addCashRegisterReport(): void {
        builder.addLineSeparator();
        let totalExpectedAmount = '0';

        builder.addBoldSeparatedTexts(translate('Medium'), translate('Expected'));

        partialShiftCashRegisterReport.expectedAmounts?.forEach((paymentMethodReport: any) => {
            totalExpectedAmount = (parseFloat(totalExpectedAmount) + parseFloat(paymentMethodReport.amount)).toString();
            let paymentMethod = translate(paymentMethodReport.customPaymentMethod ?? paymentMethodReport.paymentMethod);
            const paymentMethodApp = isIntegrationApp(paymentMethodReport.app!) ? translate(paymentMethodReport.app) : '';
            paymentMethod = !!paymentMethodApp ? `${paymentMethod} ${paymentMethodApp}` : paymentMethod;

            const expected = formatAsCurrencyNumber(paymentMethodReport.amount ?? 0, { country: restaurant.country as any });

            builder.addSeparatedTexts(paymentMethod, expected);
        });

        builder.addBoldSeparatedTexts(translate('Total'), formatAsCurrencyNumber(totalExpectedAmount, { country: restaurant.country as any }));
        builder.addLineSeparator();
    }
}

type Params = {
    partialShiftCashRegisterReport: PartialCashRegisterShiftReportVm;
    restaurant: RestaurantVm;
};
