/**
 * @prettier
 */
import { createContext, useEffect, useState } from 'react';
import * as React from 'react';
import { CreateOrderInvoiceDialog } from 'src/components/dialog/order/CreateOrderInvoiceDialog';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { FiscalEntity } from 'src/constants/FiscalEntity';
import { PosOrderSteps } from 'src/constants/PosOrderStep';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { PosEmptyState } from 'src/scenes/letseatmanager/pos/PosEmptyState';
import { PosUserLockscreen } from 'src/scenes/letseatmanager/pos/posUserLockscreen/PosUserLockscreen';
import type { OrderVm } from 'src/types/OrderVm';
import { useSelector } from 'src/utils/react/useSelector';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export function PosProvider({ children }: Props): React.ReactElement {
    const isSmallScreen = useIsSmallScreen();

    const [createOrderInvoiceDialogState, setCreateOrderInvoiceDialogState] = useState<{ open: boolean; order: OrderVm | undefined; defaultFiscalEntity: FiscalEntity | undefined }>({
        open: false,
        order: undefined,
        defaultFiscalEntity: undefined,
    });
    const [shouldRenderMobileCart, setShouldRenderMobileCart] = useState(false);
    const [menuOpenedAt, setMenuOpenedAt] = useState<Date | any>();

    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);
    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);
    const userManagementEnabled = useSelector((state) => state.app.restaurant.userManagementEnabled);
    const posOrderStep = useSelector((state) => state.pos.orderStep);

    const openCreateOrderInvoiceDialog = (order: OrderVm, defaultFiscalEntity: FiscalEntity | undefined) => setCreateOrderInvoiceDialogState({ open: true, order, defaultFiscalEntity });

    const closeOrderInvoiceDialog = () => setCreateOrderInvoiceDialogState({ open: false, order: undefined, defaultFiscalEntity: undefined });

    const openMobileCart = () => setShouldRenderMobileCart(true);

    const closeMobileCart = () => setShouldRenderMobileCart(false);
    const updateMenuOpenedAt = (date: Date) => setMenuOpenedAt(date);

    useEffect(() => {
        if (!isSmallScreen) setShouldRenderMobileCart(false);
    }, [isSmallScreen]);

    useEffect(() => {
        if (posOrderStep === PosOrderSteps.CREATE_ORDER) {
            setMenuOpenedAt(new Date());
        }
    }, [posOrderStep]);

    if (!posEnabled) {
        return (
            <SecuredAndSubscribedPage rolePermission={RolePermissions.POS_PAGE} title={translate('Directo POS')}>
                <PosEmptyState />
            </SecuredAndSubscribedPage>
        );
    }

    if (!pinCodeUserSignedIn && userManagementEnabled) {
        return (
            <SecuredAndSubscribedPage rolePermission={RolePermissions.POS_PAGE} title={translate('Directo POS')}>
                <PosUserLockscreen />
            </SecuredAndSubscribedPage>
        );
    }

    return (
        <PosContext.Provider value={{ shouldRenderMobileCart, menuOpenedAt, openCreateOrderInvoiceDialog, openMobileCart, closeMobileCart, updateMenuOpenedAt }}>
            <CreateOrderInvoiceDialog
                open={createOrderInvoiceDialogState.open}
                order={createOrderInvoiceDialogState.order}
                onClose={closeOrderInvoiceDialog}
                defaultFiscalEntity={createOrderInvoiceDialogState.defaultFiscalEntity}
            />
            <SecuredAndSubscribedPage rolePermission={RolePermissions.POS_PAGE} title={translate('Directo POS')}>
                {children}
            </SecuredAndSubscribedPage>
        </PosContext.Provider>
    );
}

// @ts-ignore
export const PosContext = createContext<PosContextType>({
    shouldRenderMobileCart: false,
    menuOpenedAt: undefined,
    openCreateOrderInvoiceDialog: () => {},
    openMobileCart: () => {},
    closeMobileCart: () => {},
    updateMenuOpenedAt: () => {},
});

type Props = {
    children: React.ReactNode;
};

type PosContextType = {
    shouldRenderMobileCart: boolean;
    menuOpenedAt: Date | undefined;
    openCreateOrderInvoiceDialog: (order: OrderVm, uruguayanFiscalEntity?: FiscalEntity | undefined) => void;
    openMobileCart: any;
    closeMobileCart: any;
    updateMenuOpenedAt: (arg1: Date) => void;
};
