/**
 * @prettier
 */
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import RegisterCompanyDialog, { REGISTER_COMPANY } from 'src/scenes/letseatadmin/company/RegisterCompanyDialog';
import type { AwsCognitoUsername, CompanyId, ConektaCustomerId, StripeCustomerId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { setUrlQueryParameter } from 'src/utils/history/setUrlQueryParameter';
import { RegExps } from 'src/utils/RegExps';

export default withStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class Companies extends React.Component<Props, State> {
        state = {
            loading: false,
            companies: [],
        };

        async componentDidMount() {
            await this.load();
        }

        // shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
        //     return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
        // }

        load = async () => {
            this.setState({ loading: true });
            const response = await letseatmanagerApiDeprecated.admin.getCompanies();
            this.setState({ loading: false });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            this.setState({ companies: response.data.companies });
        };

        handleClickNew = () => {
            history.push({
                search: setUrlQueryParameter(REGISTER_COMPANY, ''),
            });
        };

        render() {
            const { classes } = this.props;
            return (
                <div style={{ position: 'relative' }}>
                    <UpdatingContentProgress loading={this.state.loading} />
                    <RegisterCompanyDialog onCompanyRegistered={this.load} />
                    <MUIDataTable
                        /* @ts-ignore */
                        className={classes.table}
                        title={''}
                        data={this.state.companies.map((company: any) => ({
                            companyId: company.companyId,
                            name: company.name,
                            address: company.address || '',
                            username: company.username,
                            paused: company.paused ? 'Paused' : '',
                            createdAt: formatDateTimeString(company.createdAt) || '',
                        }))}
                        columns={[
                            {
                                name: 'companyId',
                                label: ' ',
                                options: {
                                    display: 'excluded',
                                    filter: false,
                                },
                            },
                            {
                                name: 'name',
                                label: translate('Name'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'address',
                                label: translate('Address'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'username',
                                label: translate('Username'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'paused',
                                label: translate('Paused'),
                                options: {
                                    searchable: false,
                                },
                            },
                            {
                                name: 'createdAt',
                                label: translate('Created'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                        ]}
                        options={{
                            responsive: 'standard',
                            tableBodyMaxHeight: '500px',
                            selectableRows: 'none',
                            // setRowProps: (row) => {
                            //     return {
                            //         style: { backgroundColor: getCompaniestatusColor(row[30], row[32]) },
                            //     };
                            // },
                            onRowClick: (
                                rowData: Array<string>,
                                rowMeta: {
                                    dataIndex: number;
                                    rowIndex: number;
                                }
                            ) => {
                                history.push({
                                    pathname: RoutePaths.ADMIN_COMPANY.replace(`:companyId(${RegExps.uuid})`, rowData[0]),
                                    search: history.location.search,
                                });
                            },
                            filterType: 'multiselect',
                            rowsPerPage: 100,
                            customToolbar: () => {
                                return (
                                    <>
                                        <Tooltip title='Refresh'>
                                            <IconButton onClick={this.load}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {!this.props.disabled && (
                                            <Tooltip title='Register Company'>
                                                <IconButton onClick={this.handleClickNew}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </>
                                );
                            },
                            // filterList: [[], [], [], [], ['MEXICO']],
                        }}
                    />
                </div>
            );
        }
    }
) as React.ComponentType<any>;

type Props = {
    companyId: CompanyId;
    classes: any;
    disabled?: boolean;
};

type State = {
    loading: boolean;
} & CompaniesVm;

type CompaniesVm = {
    companies: Array<CompanyVm>;
};

type CompanyVm = {
    companyId: CompanyId;
    username: AwsCognitoUsername;
    name: string;
    address?: string;
    contact: CompanyContactVm;
    conektaCustomerId?: ConektaCustomerId;
    stripeCustomerId?: StripeCustomerId;
    creditCards: number;
    paused?: boolean;
    createdAt: Date;
    modifiedAt: Date;
};

type CompanyContactVm = {
    email?: string;
    phoneNumber?: string;
    firstName?: string;
    lastName?: string;
};
