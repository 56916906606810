/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomDiscountComment } from 'src/constants/CustomDiscountComment';
import type { RestaurantId } from 'src/types/Id';

export async function findCustomDiscountCommentsApi(request: FindCustomDiscountCommentsApiRequest): ApiSauceResponse<FindCustomDiscountCommentsApiResponse> {
    return letseatmanagerApiMethod('pos/findCustomDiscountCommentsApi', request);
}

type FindCustomDiscountCommentsApiRequest = {
    restaurantId: RestaurantId;
};

type FindCustomDiscountCommentsApiResponse = Array<{
    comment: CustomDiscountComment;
}>;
