/**
 * @prettier
 */
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import AddEmployeeDialog, { ADD_EMPLOYEE } from 'src/scenes/letseatadmin/company/AddEmployeeDialog';
import type { BenefitPlanId, CompanyId, CustomerId, EmployeeId, EmployeeIdAtCompany, EmployeeNumberAtCompany } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { fullName } from 'src/utils/customer/fullName';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { setUrlQueryParameter } from 'src/utils/history/setUrlQueryParameter';
import { RegExps } from 'src/utils/RegExps';

export default withStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class Employees extends React.Component<Props, State> {
        state = {
            loading: false,
            employees: [],
        };

        async componentDidMount() {
            await this.load();
        }
        async componentDidUpdate(prevProps: Props, prevState: State) {
            if (this.props.refresh !== prevProps.refresh) {
                await this.load();
            }
        }

        // shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
        //     return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
        // }

        load = async () => {
            this.setState({ loading: true });
            const response = await letseatmanagerApiDeprecated.admin.getEmployees({ benefitPlanId: this.props.benefitPlanId });
            this.setState({ loading: false });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            this.setState({ employees: response.data.employees });
        };

        handleClickNew = () => {
            history.push({
                search: setUrlQueryParameter(ADD_EMPLOYEE, ''),
            });
        };

        render() {
            const { classes } = this.props;
            return (
                <div style={{ position: 'relative' }}>
                    <UpdatingContentProgress loading={this.state.loading} />
                    <AddEmployeeDialog benefitPlanId={this.props.benefitPlanId} onEmployeeAdded={this.load} />
                    <MUIDataTable
                        /* @ts-ignore */
                        className={classes.table}
                        title={'Employees'}
                        data={this.state.employees.map((employee: any) => ({
                            employeeId: employee.employeeId,
                            name: fullName(employee.firstName, employee.lastName),
                            email: employee.email ?? '',
                            employeeIdAtCompany: employee.employeeIdAtCompany ?? '',
                            employeeNumberAtCompany: employee.employeeNumberAtCompany ?? '',
                            paused: employee.paused ? 'Paused' : '',
                            createdAt: formatDateTimeString(employee.createdAt) ?? '',
                            connectedAt: formatDateTimeString(employee.connectedAt) ?? translate('Not connected'),
                        }))}
                        columns={[
                            {
                                name: 'employeeId',
                                label: ' ',
                                options: {
                                    display: 'excluded',
                                    filter: false,
                                },
                            },
                            {
                                name: 'name',
                                label: translate('Name'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'employeeIdAtCompany',
                                label: translate('Employee Id'),
                                options: {
                                    filter: false,
                                    searchable: true,
                                },
                            },
                            {
                                name: 'employeeNumberAtCompany',
                                label: translate('Employee Number'),
                                options: {
                                    filter: false,
                                    searchable: true,
                                },
                            },
                            {
                                name: 'email',
                                label: translate('Email'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'paused',
                                label: translate('Paused'),
                                options: {
                                    searchable: false,
                                },
                            },
                            {
                                name: 'createdAt',
                                label: translate('Created'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'connectedAt',
                                label: translate('Connected'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                        ]}
                        options={{
                            responsive: 'standard',
                            tableBodyMaxHeight: '500px',
                            selectableRows: 'none',
                            // setRowProps: (row) => {
                            //     return {
                            //         style: { backgroundColor: getEmployeestatusColor(row[30], row[32]) },
                            //     };
                            // },
                            onRowClick: (
                                rowData: Array<string>,
                                rowMeta: {
                                    dataIndex: number;
                                    rowIndex: number;
                                }
                            ) => {
                                history.push({
                                    pathname: RoutePaths.ADMIN_EMPLOYEE.replace(`:employeeId(${RegExps.uuid})`, rowData[0]),
                                    search: history.location.search,
                                });
                            },
                            filterType: 'multiselect',
                            rowsPerPage: 100,
                            customToolbar: () => {
                                return (
                                    <>
                                        <Tooltip title='Refresh'>
                                            <IconButton onClick={this.load}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Add Employee'>
                                            <IconButton onClick={this.handleClickNew}>
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                );
                            },
                            // filterList: [[], [], [], [], ['MEXICO']],
                        }}
                    />
                </div>
            );
        }
    }
) as React.ComponentType<any>;

type Props = {
    benefitPlanId: BenefitPlanId;
    classes: any;
    refresh: number;
};

type State = {
    loading: boolean;
} & EmployeesVm;

type EmployeesVm = {
    employees: Array<EmployeeVm>;
};

type EmployeeVm = {
    employeeId: EmployeeId;
    companyId: CompanyId;
    benefitPlanId: BenefitPlanId;
    customerId?: CustomerId;
    email: string;
    employeeIdAtCompany?: EmployeeIdAtCompany;
    employeeNumberAtCompany?: EmployeeNumberAtCompany;
    firstName: string;
    lastName: string;
    paused?: boolean;
    connectedAt?: Date;
    createdAt: Date;
    modifiedAt: Date;
};
