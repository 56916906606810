/**
 * @prettier
 */
export const MexicanTaxes = {
    IVA: 'IVA',
    IEPS: 'IEPS',
    IVA_RET: 'IVA_RET',
    IVA_EXENTO: 'IVA_EXENTO',
} as const;

export type MexicanTax = typeof MexicanTaxes[keyof typeof MexicanTaxes];
