/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@pidedirecto/ui';
import { GridIcon, TableIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useOrderPageActions, useOrderPageStore } from 'src/modules/order/orderPageStore';

export function ToggleOrdersPageViewButton(): React.ReactElement {
    const classes = useStyles();

    const isTableView = useOrderPageStore((state) => state.isTableView);

    const showOrdersTableView = useOrderPageActions((actions) => actions.showOrdersTableView);
    const showOrdersCardView = useOrderPageActions((actions) => actions.showOrdersCardView);

    const toggleView = () => {
        if (isTableView) {
            showOrdersCardView();
            return;
        }
        showOrdersTableView();
    };

    return (
        <Button variant='secondary' onClick={toggleView} classes={{ button: classes.button }}>
            <span className={classes.hideBelowDesktop}>{translate('Change view')}</span>
            {isTableView ? <GridIcon size={16} title={translate('Cards view')} /> : <TableIcon size={16} title={translate('Table view')} />}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    hideBelowDesktop: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}));
