/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Page } from 'src/components/Page';
import { Sounds } from 'src/constants/Sound';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import DriverAlarm from 'src/scenes/letseatadmin/monitoring/driver-alarm/DriverAlarm';
import DriverLateForPickupAlarm from 'src/scenes/letseatadmin/monitoring/driver-late-for-pickup-alarm/DriverLateForPickupAlarm';
import 'src/scenes/letseatadmin/monitoring/MonitoringAnimations.css';
import OrderAlarm from 'src/scenes/letseatadmin/monitoring/order-alarm/OrderAlarm';
import { useNotification } from 'src/services/notification/useNotification';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useInterval } from 'src/utils/react/useInterval';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { playAlertSound } from 'src/utils/sound/playAlertSound';

export function Monitoring(): React.ReactElement {
    const classes = useStyles();

    const notification = useNotification();

    const soundMuted = useSelector((state) => state.app.soundMuted);
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const [loadingMonitoring, monitoringData, load] =
        useLoadApi(letseatmanagerApiDeprecated.admin.monitoring.fetch, undefined, {
            zip: true,
            onError: (response) => {
                if (response.problem === 'NETWORK_ERROR') {
                    notification({ message: translate('Network Error') });
                    return;
                }
                alertKnownErrorOrSomethingWentWrong(response);
            },
        }) || [];

    useInterval(
        () => {
            updateMonitoringViewModel();
        },
        10 * SECONDS,
        []
    );

    useInterval(
        () => {
            playAlarmSoundIfExists();
        },
        2 * SECONDS,
        [soundMuted, monitoringData?.orderAlarms.length > 0 || monitoringData?.driverAlarms?.length > 0 || monitoringData?.driverLateForPickupAlarms.length > 0]
    );

    const playAlarmSoundIfExists = () => {
        if (soundMuted) return true;

        if (monitoringData && (monitoringData?.orderAlarms?.length > 0 || monitoringData?.driverAlarms.length > 0 || monitoringData?.driverLateForPickupAlarms.length > 0)) {
            playAlertSound(Sounds.DEFAULT_SOUND);
        }
    };

    const updateMonitoringViewModel = () => {
        if (loadingMonitoring) return true;
        load();
    };

    return (
        <Page title={translate('Monitoring')}>
            <Grid container direction='row' justify='center'>
                <Grid item xs={12}>
                    <TransitionGroup>
                        {monitoringData?.orderAlarms.map((orderAlarm: any) => (
                            <CSSTransition timeout={1000} classNames='card-animation' key={orderAlarm.restaurantName}>
                                <OrderAlarm orderAlarm={orderAlarm} disabled={viewUser} />
                            </CSSTransition>
                        ))}
                        {monitoringData?.driverAlarms.map((driverAlarm: any) => (
                            <CSSTransition timeout={1000} classNames='card-animation' key={driverAlarm.restaurantName}>
                                <DriverAlarm driverAlarm={driverAlarm} />
                            </CSSTransition>
                        ))}
                        {monitoringData?.driverLateForPickupAlarms.map((driverLateForPickupAlarm: any) => (
                            <CSSTransition timeout={1000} classNames='card-animation' key={driverLateForPickupAlarm.orderId}>
                                <DriverLateForPickupAlarm driverLateForPickupAlarm={driverLateForPickupAlarm} />
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                </Grid>
            </Grid>

            <CSSTransition in={monitoringData?.orderAlarms.length === 0} timeout={1000} classNames='card-animation' unmountOnExit>
                <div className={classes.emptyDescription}>
                    <Typography display='block' variant='h3' align='center'>
                        {translate('NO ALARM')}
                    </Typography>
                </div>
            </CSSTransition>
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    emptyDescription: {
        paddingTop: '200px',
        paddingBottom: '100px',
    },
    cardSpacing: {
        marginBottom: 16,
    },
}));

export type MonitoringVm = {
    loading: boolean;
    monitoringData: {
        driverAlarms: Array<any>;
        driverLateForPickupAlarms: Array<any>;
        orderAlarms: Array<any>;
    };
};
