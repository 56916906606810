/**
 * @prettier
 */
import * as React from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { SubscriptionPaymentMethods } from 'src/constants/SubscriptionPaymentMethod';
import { translate } from 'src/i18n/translate';

export function FormSubscriptionPaymentMethodSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant }: Props): React.ReactElement {
    const options = required
        ? Object.values(SubscriptionPaymentMethods).map((subscriptionPaymentMethod: any) => ({
              label: translate(subscriptionPaymentMethod),
              value: subscriptionPaymentMethod,
          }))
        : [
              { label: '', value: null },
              ...Object.values(SubscriptionPaymentMethods).map((subscriptionPaymentMethod: any) => ({
                  label: translate(subscriptionPaymentMethod),
                  value: subscriptionPaymentMethod,
              })),
          ];
    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
};
