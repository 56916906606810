/**
 * @prettier
 */
export function formatAsDuration(value?: number | null): string {
    // TODO: Return maybe type
    if (!value) {
        return '';
    }

    const rounded = (value as any) + (value % 60 === 0) ? value : 60 - (value % 60);
    const rest1 = rounded % (24 * 60 * 60);
    const days = (rounded - rest1) / (24 * 60 * 60);

    const rest2 = rest1 % (60 * 60);
    const hours = (rest1 - rest2) / (60 * 60);

    const minutes = Math.ceil(rest2 / 60);

    return [days ? `${days} d` : '', hours ? `${hours} h` : '', minutes ? `${minutes} min` : ''].join(' ');
}
