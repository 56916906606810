/**
 * @prettier
 */

import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import type { IntegrationOrdersSummaryReport } from 'src/api/letseatmanager/restaurantDashboard/getIntegrationsPaymentReportApi';
import { Table } from 'src/components/Table';
import { IntegrationPlatforms, type IntegrationPlatform } from 'src/constants/IntegrationPlatforms';
import { translate } from 'src/i18n/translate';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function IntegrationsPaymentReportSalesPerDayTable({ integrationOrdersSummaryReport, selectedIntegrationPlatforms, loading }: Props): React.ReactElement | null {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const getIntegrationPlatforms = (integrationOrder: IntegrationOrdersSummaryReport) => {
        const integrationPlatforms = [];

        if (BigNumber(integrationOrder.uberEatsIntegrationOrderCommission).toNumber() && selectedIntegrationPlatforms.includes(IntegrationPlatforms.UBER_EATS)) {
            integrationPlatforms.push(translate(IntegrationPlatforms.UBER_EATS));
        }
        if (BigNumber(integrationOrder.rappiIntegrationOrderCommission).toNumber() && selectedIntegrationPlatforms.includes(IntegrationPlatforms.RAPPI)) {
            integrationPlatforms.push(translate(IntegrationPlatforms.RAPPI));
        }
        if (BigNumber(integrationOrder.didiFoodIntegrationOrderCommission).toNumber() && selectedIntegrationPlatforms.includes(IntegrationPlatforms.DIDI_FOOD)) {
            integrationPlatforms.push(translate(IntegrationPlatforms.DIDI_FOOD));
        }
        if (BigNumber(integrationOrder.pedidosYaIntegrationOrderCommission).toNumber() && selectedIntegrationPlatforms.includes(IntegrationPlatforms.PEDIDOS_YA)) {
            integrationPlatforms.push(translate(IntegrationPlatforms.PEDIDOS_YA));
        }

        return integrationPlatforms.join(', ');
    };

    const hasNonZeroCommission = (integrationOrder: IntegrationOrdersSummaryReport, platform: IntegrationPlatform) => {
        switch (platform) {
            case IntegrationPlatforms.UBER_EATS:
                return BigNumber(integrationOrder.uberEatsIntegrationOrderCommission).toNumber() !== 0;
            case IntegrationPlatforms.RAPPI:
                return BigNumber(integrationOrder.rappiIntegrationOrderCommission).toNumber() !== 0;
            case IntegrationPlatforms.DIDI_FOOD:
                return BigNumber(integrationOrder.didiFoodIntegrationOrderCommission).toNumber() !== 0;
            case IntegrationPlatforms.PEDIDOS_YA:
                return BigNumber(integrationOrder.pedidosYaIntegrationOrderCommission).toNumber() !== 0;
            default:
                return false;
        }
    };

    const filteredSalesData = integrationOrdersSummaryReport
        .filter((integrationOrder) => selectedIntegrationPlatforms.some((platform) => hasNonZeroCommission(integrationOrder, platform)))
        .map((integrationOrder) => ({
            orderId: integrationOrder.shortOrderId,
            date: integrationOrder.createdAt,
            integrationPlatform: getIntegrationPlatforms(integrationOrder),
            grossTotal: formatAsCurrencyNumber(integrationOrder.restaurantGrossTotal ?? 0),
            commission: formatAsCurrencyNumber(
                BigNumber(integrationOrder.rappiIntegrationOrderCommission ?? 0)
                    .plus(integrationOrder.uberEatsIntegrationOrderCommission ?? 0)
                    .plus(integrationOrder.didiFoodIntegrationOrderCommission ?? 0)
                    .plus(integrationOrder.pedidosYaIntegrationOrderCommission ?? 0)
                    .toNumber()
            ),
            netTotal: formatAsCurrencyNumber(integrationOrder.restaurantNetTotal ?? 0),
        }));

    const columns = [
        {
            name: 'orderId',
            label: translate('Order'),
            options: {
                filter: true,
            },
        },
        {
            name: 'date',
            label: translate('Date'),
            options: {
                filter: false,
            },
        },
        {
            name: 'integrationPlatform',
            label: translate('Integration platform'),
            options: {
                filter: false,
            },
        },
        {
            name: 'grossTotal',
            label: translate('Gross total'),
            options: {
                filter: false,
            },
        },
        {
            name: 'commission',
            label: translate('Commission'),
            options: {
                filter: false,
            },
        },
        {
            name: 'netTotal',
            label: translate('Net total'),
            options: {
                filter: false,
            },
        },
    ];

    if (!selectedIntegrationPlatforms.length) return null;

    return (
        <ReportSection loading={loading} title={translate('Integration orders')}>
            <Table
                data={filteredSalesData}
                columns={columns}
                options={{
                    tableBodyMaxHeight: '400px',
                    responsive: 'standard',
                    selectableRows: 'none',
                    filter: false,
                    rowsPerPage: 100,
                    viewColumns: false,
                    print: true,
                }}
            />
        </ReportSection>
    );
}

type Props = {
    loading: boolean;
    integrationOrdersSummaryReport: Array<IntegrationOrdersSummaryReport>;
    selectedIntegrationPlatforms: Array<IntegrationPlatform>;
};
