/**
 * @prettier
 */
import * as React from 'react';
import { getRestaurantWithInventoryEnabledOptionsApi } from 'src/api/letseatadmin/options/getRestaurantWithInventoryEnabledOptionsApi';
import { FormAutocomplete } from 'src/components/form/FormAutocomplete';
import type { Variant } from 'src/components/form/FormTypes';
import { useLoadApi } from 'src/utils/react/useLoadApi';

export function FormRestaurantWithInventoryEnabledAutocomplete({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, InputProps, tooltip }: Props): React.ReactElement {
    const [loading, options] = useLoadApi(getRestaurantWithInventoryEnabledOptionsApi, undefined, { initialValue: [] });

    return (
        <FormAutocomplete
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled || loading}
            required={required}
            variant={variant}
            InputProps={InputProps}
            tooltip={tooltip}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    tooltip?: string;
};
