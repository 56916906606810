/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function unlinkInventoryBetweenRestaurantsApi(request: UnlinkInventoryBetweenRestaurantApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('inventory/unlinkInventoryBetweenRestaurantsApi', request);
}

type UnlinkInventoryBetweenRestaurantApiRequest = {
    restaurantId: RestaurantId;
};
