/**
 * @prettier
 */
import type { AppBuildVm } from 'src/api/letseatmanager/appBuild/types/AppBuildVm';
import { letseatmanagerApiMethodPublic } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PideDirectoApp } from 'src/constants/PideDirectoApp';

export async function getAppBuildsApi(request: GetAppBuildsApiRequest): ApiSauceResponse<Array<AppBuildVm>> {
    return letseatmanagerApiMethodPublic('appBuild/getAppBuildsApi', request);
}

export type GetAppBuildsApiRequest = {
    pideDirectoApp: PideDirectoApp;
};
