/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { Spacing } from 'src/components/spacing/Spacing';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { deleteUrlQueryParameter } from 'src/utils/history/deleteUrlQueryParameter';
import { hasUrlQueryParameter } from 'src/utils/history/hasUrlQueryParameter';
import { required } from 'src/utils/Validator';

export default withRouter<any, any>(
    withStyles((theme) => ({}))(
        class RegisterCompanyDialog extends React.PureComponent<Props, State> {
            state: any = INITIAL_STATE;

            async componentDidMount(): Promise<void> {
                const registerCompany: boolean = hasUrlQueryParameter(REGISTER_COMPANY, this.props.location);
                if (registerCompany) {
                    await this.openDialog();
                }
            }

            async componentDidUpdate(prevProps: Props) {
                const prevRegisterCompany: boolean = hasUrlQueryParameter(REGISTER_COMPANY, prevProps.location);
                const registerCompany: boolean = hasUrlQueryParameter(REGISTER_COMPANY, this.props.location);
                if (registerCompany && !prevRegisterCompany && !this.state.open) {
                    await this.openDialog();
                } else if (!registerCompany && prevRegisterCompany && this.state.open) {
                    this.closeDialog();
                }
            }

            componentWillUnmount() {}

            handleClose = () => {
                if (this.state.submitting) {
                    return;
                }
                if (history.length <= 2) {
                    history.replace({
                        path: this.props.location.pathname,
                        search: deleteUrlQueryParameter(REGISTER_COMPANY),
                    });
                    return;
                }
                history.goBack();
            };

            handleExited = () => {
                this.setState(INITIAL_STATE as any);
            };

            openDialog = () => {
                this.setState({ open: true });
            };

            closeDialog = () => {
                this.setState({ open: false });
            };

            onSubmit = async (form: any) => {
                this.setState({ submitting: true });
                const request: RegisterCompanyRequest = {
                    name: form.company.name,
                    address: form.company.address,
                    contact: {
                        email: form.company.contact.email,
                        phoneNumber: form.company.contact.phoneNumber,
                        firstName: form.company.contact.firstName,
                        lastName: form.company.contact.lastName,
                    },
                };
                const response = await letseatmanagerApiDeprecated.admin.registerCompany(request);
                this.setState({ submitting: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    return;
                }
                this.props.onCompanyRegistered();
                this.handleClose();
            };

            render() {
                return (
                    <Dialog aria-labelledby='register-company-dialog-title' fullWidth scroll='body' open={this.state.open} onClose={this.handleClose} onExited={this.handleExited}>
                        <Form
                            onSubmit={this.onSubmit}
                            initialValues={{
                                company: this.state.company,
                            }}
                            render={({ handleSubmit, submitting, pristine, values }) => {
                                return (
                                    <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                                        <DialogTitle id='register-company-dialog-title'>{translate('Register Company').toUpperCase()}</DialogTitle>
                                        <DialogContent>{this.renderDialogContent(values)}</DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleClose} disabled={this.state.submitting}>
                                                {translate('Cancel').toUpperCase()}
                                            </Button>
                                            <Button color='primary' type='submit' disabled={this.state.submitting}>
                                                {this.state.submitting ? translate('Registering').toUpperCase() : translate('Register').toUpperCase()}
                                            </Button>
                                        </DialogActions>
                                        <UpdatingContentProgress loading={this.state.submitting} top />
                                    </form>
                                );
                            }}
                        />
                    </Dialog>
                );
            }

            renderDialogContent(values: any) {
                return (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    name='company.name'
                                    label={translate('Name')}
                                    component={TextField}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    required
                                    validate={required}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field name='company.address' label={translate('Address')} component={TextField} fullWidth disabled={this.state.submitting} inputProps={{ autoComplete: 'no' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name='company.contact.email'
                                    label={translate('Contact Email')}
                                    component={TextField}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name='company.contact.phoneNumber'
                                    label={translate('Contact Phone Number')}
                                    component={TextField}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>
                            §
                            <Grid item xs={12}>
                                <Field
                                    name='company.contact.firstName'
                                    label={translate('Contact First Name')}
                                    component={TextField}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name='company.contact.lastName'
                                    label={translate('Contact Last Name')}
                                    component={TextField}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container justify='space-around'>
                            <Grid item>
                                <Spacing units={8} />
                                {this.state.errorMessage && (
                                    <Typography display='block' color='error'>
                                        {this.state.errorMessage}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </>
                );
            }
        }
    )
) as React.ComponentType<any>;

export const REGISTER_COMPANY = 'register-company';
const INITIAL_STATE = {
    open: false,
    company: {
        name: '',
        address: '',
        contact: {
            email: '',
            phoneNumber: '',
            firstName: '',
            lastName: '',
        },
    },
    submitting: false,
} as const;

type Props = {
    classes: any;
    location: {
        pathname: string;
    };
    onCompanyRegistered: any;
};

type State = {
    open: boolean;
    submitting: boolean;
    company: {
        name?: string;
        address?: string;
        contact: {
            email?: string;
            phoneNumber?: string;
            firstName?: string;
            lastName?: string;
        };
    };
    errorMessage?: string;
};

type RegisterCompanyRequest = {
    name: string;
    address?: string;
    contact: CompanyContact;
};

export type CompanyContact = {
    email?: string;
    phoneNumber?: string;
    firstName?: string;
    lastName?: string;
};
