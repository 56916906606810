/**
 * @prettier
 */
export const WeekSchedules = {
    ALWAYS: 'ALWAYS',
    WEEKDAY: 'WEEKDAY',
    WEEKEND: 'WEEKEND',
    CUSTOM: 'CUSTOM',
} as const;

export type WeekSchedule = typeof WeekSchedules[keyof typeof WeekSchedules];
