/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CopyIcon, EditIcon, PromotionIcon, TrashCanIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { Channel } from 'src/components/Channel';
import { Chip } from 'src/components/Chip';
import { ContextualMenu } from 'src/components/ContextualMenu';
import { ContextualMenuItem } from 'src/components/ContextualMenuItem';
import { App } from 'src/constants/App';
import { translate } from 'src/i18n/translate';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import type { MenuVm } from 'src/types/MenuVm';

export function MenuListItem({ menu, onChangeMenu, onRemoveMenu, onClick, onClone, onCreatePromotion }: Props): React.ReactElement {
    const classes = useStyles();

    const isMenuEditionDisabled = useIsMenuEditionDisabled();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className={classes.container} onClick={() => onClick?.(menu)}>
            <div className={classes.menuInfoContainer}>
                <div className={classes.nameContainer}>
                    <span className={classes.name}>{menu.name}</span>
                    {!menu.hidden && <Chip>{translate('Active')}</Chip>}
                    {menu.unlinked && <Chip variant={'ERROR'}>{translate('Unlinked')}</Chip>}
                </div>
                <div className={classes.channelsContainer}>
                    {menu?.channels?.map((channel: App) => (
                        <Channel key={channel} channel={channel} size={smallScreen ? 20 : 35} disabled />
                    ))}
                </div>
            </div>
            <div className={classes.sectionContainer}>
                <ContextualMenu disableButton={isMenuEditionDisabled}>
                    {!menu.unlinked && (
                        <ContextualMenuItem onClick={() => onChangeMenu?.(menu)}>
                            <EditIcon size={20} />
                            {translate('Edit')}
                        </ContextualMenuItem>
                    )}
                    {!menu.unlinked && (
                        <ContextualMenuItem onClick={() => onClone?.(menu)}>
                            <CopyIcon size={20} />
                            {translate('Clone')}
                        </ContextualMenuItem>
                    )}
                    <ContextualMenuItem onClick={() => onRemoveMenu?.(menu)}>
                        <TrashCanIcon color={'#E37878'} size={20} />
                        {translate('Remove')}
                    </ContextualMenuItem>
                    <ContextualMenuItem onClick={() => onCreatePromotion?.(menu)}>
                        <PromotionIcon size={20} />
                        {translate('Create promotion in menu')}
                    </ContextualMenuItem>
                </ContextualMenu>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 10,
    },
    menuInfoContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 15,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            gap: 5,
        },
    },
    sectionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 15,
    },
    nameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    channelsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            '& svg': {
                width: 15,
                height: 15,
                flexShrink: 0,
            },
            '& img': {
                width: 15,
                height: 15,
                flexShrink: 0,
            },
        },
    },
    name: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: 'fit-content',
        },
    },
}));

type Props = {
    menu: MenuVm;
    onChangeMenu: any;
    onRemoveMenu: any;
    onClone: any;
    onCreatePromotion: any;
    onClick?: any;
};
