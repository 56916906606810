/**
 * @prettier
 */
export const UnitsOfMeasurementTranslated = {
    KILOGRAMO: 'KILOGRAM',
    GRAMO: 'GRAM',
    LITRO: 'LITRE',
    MILILITRO: 'MILLILITER',
    CAJA: 'BOX',
    BOLSA: 'BAG',
    PIEZA: 'PIECE',
    PAQUETE: 'PACKAGE',
    BARRA: 'STICK',
    ROLLO: 'ROLL',
    BOTE: 'CAN',
    PORCION: 'SLICE',
    LIBRA: 'POUND',
    ONZA: 'OUNCE',
    CUCHARADA: 'TABLESPOON',
    TAZA: 'CUP',
    GALON: 'GALLON',
    CUARTO: 'QUART',
} as const;

export type UnitOfMeasurementTranslated = typeof UnitsOfMeasurementTranslated[keyof typeof UnitsOfMeasurementTranslated];
