/**
 * @prettier
 */
import { makeStyles, MenuItem } from '@material-ui/core';
import { useContext } from 'react';
import * as React from 'react';
import { ContextualMenuContext } from 'src/components/ContextualMenu';
import { PageContext } from 'src/components/Page';
import { createUserClickedContextualMenuItemLogEvent } from 'src/services/logEvent/createUserClickedContextualMenuItemLogEvent';
import { classNames } from 'src/utils/react/classNames';
import { convertReactNodeToString } from 'src/utils/react/convertReactNodeToString';

export function ContextualMenuItem({ label, children, className, disabled, onClick }: Props): React.ReactElement {
    const classes = useStyles();
    const pageContext = useContext(PageContext);
    const contextualMenuContext = useContext(ContextualMenuContext);

    const handleClick = async (e: any) => {
        createUserClickedContextualMenuItemLogEvent({ pageContext, label: getLabel() });
        const preventClose = await onClick(e);
        pageContext.clearStackTrace();
        if (!preventClose) contextualMenuContext.closeContextMenu();
    };

    const getLabel = () => {
        if (label) return label;
        if (children) return convertReactNodeToString(children);
        return '';
    };

    return (
        <MenuItem classes={{ root: classNames(classes.menuItem, className) }} disabled={disabled} onClick={handleClick}>
            {label || children}
        </MenuItem>
    );
}

const useStyles = makeStyles((theme) => ({
    menuItem: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        color: theme.palette.text.primary,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
}));

type Props = {
    label?: string;
    children?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    onClick: any;
};
