/**
 * @prettier
 */
import { usePosCustomerActions } from 'src/modules/pos/customer/posCustomerStore';

export function useSetCustomerFirstName() {
    const setCustomerFirstName = usePosCustomerActions((actions) => actions.setCustomerFirstName);

    return setCustomerFirstName;
}
