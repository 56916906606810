/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function getRestaurantReportBillingDataApi(request: GetRestaurantReportBillingDataRequest): ApiSauceResponse<RestaurantReportBillingDataVm> {
    return letseatmanagerApiMethod('restaurant/getRestaurantReportBillingDataApi', request);
}

type GetRestaurantReportBillingDataRequest = {
    restaurantId: RestaurantId;
    from: Date;
    to: Date;
};

export type RestaurantReportBillingDataVm = {
    addressOfEstablishment?: string;
    businessName?: string;
    cashback: number;
    commissionAppCard: number;
    commissionAppCash: number;
    commissionAppCardOnDelivery: number;
    commissionManualOrders: number;
    commissionWebCard: number;
    commissionWebCash: number;
    commissionWebCardOnDelivery: number;
    deposit: number;
    commissionForWebCardOrders: number;
    commissionForWebCashOrders: number;
    commissionForWebCardOnDeliveryOrders: number;
    commissionForWhiteLabelAppCardOrders: number;
    commissionForWhiteLabelAppCashOrders: number;
    commissionForWhiteLabelAppCardOnDeliveryOrders: number;
    commissionForMarketPlaceAppCardOrders: number;
    commissionForMarketPlaceAppCashOrders: number;
    commissionForMarketPlaceAppCardOnDeliveryOrders: number;
    commissionForManualOrders: number;
    commissionForRappiCargoWebCard: number;
    commissionForRappiCargoWebCash: number;
    commissionForRappiCargoAppCard: number;
    commissionForRappiCargoAppCash: number;
    commissionForRappiCargoManualOrder: number;
    commissionForRappiCargoPaymentLink: number;
    commissionForRappiCargoPosCard: number;
    commissionForRappiCargoPosCash: number;
    commissionForUberDaasWebCard: number;
    commissionForUberDaasWebCash: number;
    commissionForUberDaasAppCard: number;
    commissionForUberDaasAppCash: number;
    commissionForUberDaasManualOrder: number;
    commissionForUberDaasPaymentLink: number;
    commissionForUberDaasPosCard: number;
    commissionForUberDaasPosCash: number;
    commission: number;
    iva: number;
    manualEarning: number;
    manualOrders: number;
    marketplaceAppCard: number;
    marketplaceAppCash: number;
    marketplaceAppCardOnDelivery: number;
    paymentLink: number;
    rappiCargoAppCard: number;
    rappiCargoAppCash: number;
    rappiCargoManualOrder: number;
    rappiCargoPaymentLink: number;
    rappiCargoPosCard: number;
    rappiCargoPosCash: number;
    rappiCargoWebCard: number;
    rappiCargoWebCash: number;
    restaurantName: string;
    restaurantPromoCodeCostApp: number;
    rfc?: string;
    webCard: number;
    webCash: number;
    webCardOnDelivery: number;
    whiteLabelAppCard: number;
    whiteLabelAppCash: number;
    whiteLabelAppCardOnDelivery: number;
    commissionRappiCargoWebCard: number;
    commissionRappiCargoWebCash: number;
    commissionRappiCargoAppCard: number;
    commissionRappiCargoAppCash: number;
    commissionRappiCargoManualOrder: number;
    commissionRappiCargoPosCard: number;
    commissionRappiCargoPosCash: number;
    subscriptionPayPerUsePayment: number;
    manualEarningInvoice: number;
    subscriptionPayPerUsePaymentInvoice: number;
    uberDaasWebCard: number;
    uberDaasWebCash: number;
    uberDaasAppCard: number;
    uberDaasAppCash: number;
    uberDaasManualOrder: number;
    uberDaasPaymentLink: number;
    uberDaasPosCard: number;
    uberDaasPosCash: number;
    commissionUberDaasWebCard: number;
    commissionUberDaasWebCash: number;
    commissionUberDaasAppCard: number;
    commissionUberDaasAppCash: number;
    commissionUberDaasManualOrder: number;
    commissionUberDaasPosCard: number;
    commissionUberDaasPosCash: number;
    deliveryCostPaidByRestaurant: number;
};
