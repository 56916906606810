/**
 * @prettier
 */
import { Grid, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import type { SalesByOrderType } from 'src/api/letseatmanager/restaurantDashboard/getGeneralRestaurantStatisticsApi';
import { PieChartCanvas } from 'src/components/charts/PieChartCanvas';
import { translate } from 'src/i18n/translate';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function OrderTypeGeneralGraph({ colors, loading, orderTypeData }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [salesByOrderType, setSalesByOrderType] = useState<Array<PieData>>([]);
    const totalOrderTypeSale = salesByOrderType.reduce((acc, current) => acc + current.value, 0);

    useEffect(() => {
        getOrderTypeFromReports(orderTypeData ?? []);
    }, [orderTypeData]);

    const getOrderTypeFromReports = (salesByOrderType: Array<SalesByOrderType>) => {
        if (!salesByOrderType) {
            setSalesByOrderType([]);
            return;
        }
        const typeOrderItems = getOrderTypeReportData(salesByOrderType);
        const salesByOrderTypeSorted = typeOrderItems.sort((itemA, itemB) => itemB.value - itemA.value);

        setSalesByOrderType(salesByOrderTypeSorted);
    };

    const getOrderTypeReportData = (orderTypesData: Array<SalesByOrderType>): Array<PieData> => {
        const typeOrderItems: Array<PieData> = [];
        orderTypesData.forEach((typeOrderItem) => {
            const isOrderItem = typeOrderItems.find((elem) => elem.id === typeOrderItem.orderType);
            if (!isOrderItem) {
                typeOrderItems.push({
                    id: translate(typeOrderItem.orderType),
                    label: translate(typeOrderItem.orderType),
                    value: typeOrderItem.total,
                });
                return;
            }
            isOrderItem.value = isOrderItem.value + typeOrderItem.total;
        });

        return typeOrderItems;
    };

    return (
        <ReportSection title={translate('Order type')} loading={loading} total={formatAsCurrencyNumber(totalOrderTypeSale)}>
            <Grid container className={(classes as any).infoContainer}>
                <Grid item md={9} className={classes.graphContainer}>
                    <PieChartCanvas data={salesByOrderType} valueFormat={(value) => formatAsCurrencyNumber(value)} />
                </Grid>
                <Grid item md={3} className={classes.kpisContainer}>
                    {salesByOrderType.map((itemType, index) => (
                        <KpiCard key={`${itemType.id}-${index}`} color={colors[index]} legend={formatAsCurrencyNumber(itemType.value)} title={itemType.id} />
                    ))}
                </Grid>
            </Grid>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    graphContainer: {
        width: '100%',
        height: '45vh',
    },
    kpisContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '95%',
        height: 'auto',
        justifyContent: 'space-around',
        gap: 12,
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100%',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            flexWrap: 'nowrap',
        },
    },
}));

type Props = {
    colors: Array<string>;
    loading: boolean;
    orderTypeData: Array<SalesByOrderType>;
};

type PieData = {
    id: string;
    label: string;
    value: number;
};
