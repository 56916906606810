/**
 * @prettier
 */
import { RolePermissions } from 'src/constants/RolePermission';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function useIsMenuEditionDisabled(): boolean {
    const restaurant = useSelector((state) => state.app.restaurant);
    const internalUser = useSelector((state) => state.authentication.internalUser);

    const [userHasRolePermission] = useUserHasRolePermission();

    return (
        !!restaurant?.wansoftIntegrationEnabled ||
        !!restaurant?.isConnectedWithMasterMenu ||
        (!!restaurant?.hideMenuEditionEnabled && !internalUser) ||
        !userHasRolePermission(RolePermissions.MENU_EDIT)
    );
}
