/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import { PosState } from 'src/reducers/posReducer';
import type { CartItemVm } from 'src/types/CartItemVm';
import { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import { isItemSoldByWeight } from 'src/utils/orderItem/isItemSoldByWeight';
import { refreshPosPaymentCustomerState } from 'src/utils/pos/refreshPosPaymentCustomerState';

export function removeCustomerPosItem(state: PosState, item: CartItemVm, isCancelledItem: boolean): void {
    const customer = state.customers?.find((customer: PosCustomerOrderDetails) => customer.customerNumber === state.customerNumberSelected);

    if (!customer) return;
    const existingPosItem = customer.orderItems?.find((current: CartItemVm) => current.key === item.key);
    if (!existingPosItem) return;

    if (existingPosItem.quantity === 1 || isItemSoldByWeight(item)) {
        customer.orderItems = customer.orderItems?.filter((item: CartItemVm) => item.key !== existingPosItem?.key);
    }

    if (existingPosItem.quantity > 1 && !isItemSoldByWeight(existingPosItem)) {
        existingPosItem.quantity = BigNumber(existingPosItem.quantity).minus(1).toNumber();
    }

    if (isCancelledItem) {
        customer.cancelledItems = [...(customer.cancelledItems ?? []), item];
    }

    refreshPosPaymentCustomerState({ customer, payments: state.payments, promotions: state.promotions });
}
