/**
 * @prettier
 */
import { useEffect, useState } from 'react';
import { RolePermissions } from 'src/constants/RolePermission';
import { CashRegisterPosBusinessDayVm } from 'src/types/PosBusinessDayVm';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function useHasOpenedCashRegister(): boolean {
    const [userHasRolePermission] = useUserHasRolePermission();

    const [hasOpenedCashRegister, setHasOpenedCashRegister] = useState(false);

    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);
    const cashRegisterOpened = useSelector((state) => state.pos.cashRegisterOpened);
    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);
    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const openedCashRegisterPosBusinessDay = useSelector((state) => state.pos.openedCashRegisterPosBusinessDay);
    const openedCashRegister = useSelector((state) => state.pos.openedCashRegister);

    const hasManageCashRegisterPermission = userHasRolePermission(RolePermissions.MANAGE_CASH_REGISTERS);
    const hasOpenPersonalCashRegisterPermission = userHasRolePermission(RolePermissions.OPEN_PERSONAL_CASH_REGISTER);

    const userHasOpenedCashRegister = openedPosBusinessDay?.cashRegisterPosBusinessDays?.some(
        (cashRegisterPosBusinessDay: CashRegisterPosBusinessDayVm) => cashRegisterPosBusinessDay.assignedToManagerUserId === pinCodeUserSignedIn?.managerUserId && cashRegisterPosBusinessDay.opened
    );

    const pinCodeUserHasOpenedCashRegister = (hasOpenPersonalCashRegisterPermission && userHasOpenedCashRegister) || (hasManageCashRegisterPermission && openedCashRegisterPosBusinessDay);

    useEffect(() => {
        setHasOpenedCashRegister(!!cashRegisterOpened || (posMultipleCashRegistersEnabled && pinCodeUserHasOpenedCashRegister && openedCashRegister));
    }, [openedPosBusinessDay, userHasOpenedCashRegister, cashRegisterOpened, openedCashRegisterPosBusinessDay, pinCodeUserSignedIn]);

    return hasOpenedCashRegister;
}
