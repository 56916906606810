/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SubscriptionHistoryId } from 'src/types/Id';

export async function deleteSubscriptionHistoryApi(request: DeleteSubscriptionHistoryApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('subscriptionHistory/deleteSubscriptionHistoryApi', request);
}

export type DeleteSubscriptionHistoryApiRequest = {
    subscriptionHistoryId: SubscriptionHistoryId;
};
