/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, Radio, Text } from '@pidedirecto/ui';
import { Form, FormIntegerField, FormRadioGroup, FormTimePicker } from '@pidedirecto/ui/form';
import { useForm } from '@pidedirecto/ui/hooks';
import moment from 'moment-timezone';
import { useState } from 'react';
import * as React from 'react';
import { useWatch } from 'react-hook-form';
import { addTemporaryPreparationTimeApi } from 'src/api/letseatmanager/restaurant/addTemporaryPreparationTimeApi';
import { translate } from 'src/i18n/translate';
import { useNotification } from 'src/services/notification/useNotification';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function AddTemporaryPreparationTimeDialog({ open, onClose }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { isSubmitting },
        control,
    } = form;
    const { reloadRestaurant } = useReloadRestaurant();

    const [loading, setLoading] = useState(false);

    const preparationTimeInMinutes = useSelector((state) => state.app.restaurant.preparationTime);
    const temporaryPreparationTime = useSelector((state) => state.app.restaurant.temporaryPreparationTime);
    const temporaryPreparationTimeUntil = useSelector((state) => state.app.restaurant.temporaryPreparationTimeUntil);
    const restaurantId = useSelector((state) => state.app.restaurant.restaurantId);
    const notification = useNotification();

    const option = useWatch({ name: 'option', control });

    const getHoursFromNow = (hours: number) => {
        if (hours === 1) return moment().add(hours, 'hours').calendar();
        return moment().add(hours, 'hours').calendar();
    };

    const handleSubmit = async (form: any) => {
        const setPreparationTimeUntil = calculatePreparationTimeUntil(form);
        setLoading(true);
        const response = await addTemporaryPreparationTimeApi({
            restaurantId: restaurantId,
            temporaryPreparationTime: form.preparationTimeInMinutes,
            temporaryPreparationTimeUntil: setPreparationTimeUntil?.toDate(),
        });

        if (!response.ok) {
            setLoading(false);
            return alertKnownErrorOrSomethingWentWrong();
        }

        if (loading && isSubmitting) return;
        onClose();
        reloadRestaurant(restaurantId);
        notification({ message: translate('Saved changes') });
    };

    const calculatePreparationTimeUntil = (form: any) => {
        if (form.option === PreparationTimeDuration.ONE_HOUR) {
            return moment().add(1, 'hours');
        } else if (form.option === PreparationTimeDuration.THREE_HOUR) {
            return moment().add(3, 'hours');
        } else if (form.option === PreparationTimeDuration.DATE) {
            const dateSplited = form.date.split(':');
            return moment().set({
                hour: parseInt(dateSplited[0]),
                minute: parseInt(dateSplited[1]),
                second: 0,
                millisecond: 0,
            });
        } else if (form.option === form.UNDEFINED) {
            return undefined;
        }
    };

    return (
        <Dialog open={open} title={translate('Modify your preparation time')} onClose={onClose}>
            <Form form={form} onSubmit={handleSubmit}>
                <Text className={classes.subtitle}>{translate('Many orders? Adjust preparation time during peak hours')}</Text>
                <Text className={classes.currentTimeText}>
                    {translate('Current preparation time: @preparationTimeInMinutes minutes', {
                        preparationTimeInMinutes: moment(temporaryPreparationTimeUntil).isAfter(moment()) ? temporaryPreparationTime : preparationTimeInMinutes,
                    })}
                </Text>
                <FormIntegerField
                    name={'preparationTimeInMinutes'}
                    label={translate('Preparation time (minutes)')}
                    inputProps={{ placeholder: preparationTimeInMinutes }}
                    helperText={translate('This time is temporary, it will only be activated at the established time')}
                />
                <h3 className={classes.label}>{translate('How long do you want to change it?')}</h3>
                <FormRadioGroup name='option'>
                    <Radio label={translate('1 Hour (Will end at @time)', { time: getHoursFromNow(1) })} value={PreparationTimeDuration.ONE_HOUR} />
                    <Radio label={translate('3 Hour (Will end at @time)', { time: getHoursFromNow(3) })} value={PreparationTimeDuration.THREE_HOUR} />
                    <Radio label={translate('Schedule')} value={PreparationTimeDuration.DATE} />
                    {option === PreparationTimeDuration.DATE && <FormTimePicker name={'date'} label={translate('Date')} />}
                    <Radio label={translate('Undefined')} value={PreparationTimeDuration.UNDEFINED} />
                </FormRadioGroup>
                <DialogActions>
                    <Button variant='secondary' onClick={onClose}>
                        {translate('Close')}
                    </Button>
                    <Button type='submit'>{translate('Save')}</Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const PreparationTimeDuration = Object.freeze({
    ONE_HOUR: 'ONE_HOUR',
    THREE_HOUR: 'THREE_HOUR',
    DATE: 'DATE',
    UNDEFINED: 'UNDEFINED',
});

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginBottom: 20,
    },
    formInputs: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 0,
        fontSize: 14,
    },
    subtitle: {
        marginTop: 0,
        color: theme.palette.text.secondary,
        textAlign: 'center',
    },
    currentTimeText: {
        color: theme.palette.text.brand,
        margin: '16px auto',
    },
    label: {
        fontWeight: 300,
        fontFamily: theme.typography.regular,
        color: theme.palette.text.primary,
        fontSize: 14,
        marginTop: 16,
        marginBottom: 8,
    },
}));

type Props = {
    open: boolean;
    onClose: () => void;
};
