/**
 * @prettier
 */
import type { RestaurantId, UberDirectStoreId } from 'src/types/Id';

export async function convertCsvToImportRestaurantDetailsUberDirect(file: any): Promise<ImportRestaurantDetailsUberDirect> {
    const result: any = await new Promise((resolve: (result: Promise<never>) => void, reject: (error?: any) => void) => {
        const reader = new FileReader();
        reader.onload = function (e: any) {
            resolve(e.target.result);
        };
        reader.readAsText(file);
    });
    const headers = result.slice(0, result.indexOf('\n')).split(',');
    const rows = result.slice(result.indexOf('\n') + 1).split('\n');

    const newArray = rows.map((row: any) => {
        const values = row.split(',');
        const eachObject = headers.reduce((obj: any, header: any, i: number) => {
            obj[header] = values[i].replace('\r', '');
            return obj;
        }, {});
        return eachObject;
    });

    return {
        restaurantDetailsUberDirect: newArray.map((row: any) => ({
            restaurantId: row['PideDirecto store uuid'],
            uberDirectStoreId: row['Uber Store uuid'],
        })),
    };
}

export type ImportRestaurantDetailsUberDirect = {
    restaurantDetailsUberDirect: Array<{
        restaurantId: RestaurantId;
        uberDirectStoreId: UberDirectStoreId;
    }>;
};
