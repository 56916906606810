/**
 * @prettier
 */
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import * as React from 'react';
import type { RestaurantBillingVm } from 'src/api/letseatadmin/restaurantBilling/getRestaurantBillingApi';
import { TableHeaderCalculation } from 'src/scenes/letseatadmin/restaurantBilling/TableHeaderCalculation';
import { TableRowCalculation } from 'src/scenes/letseatadmin/restaurantBilling/TableRowCalculation';

export function ToDepositCalculation({ restaurantBillingVm }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <TableHeaderCalculation name={`To Deposit Calculation`} />
            <Table className={classes.table} size='small'>
                <TableBody>
                    <TableRowCalculation name='Web Card' amount={restaurantBillingVm.webCard} />
                    <TableRowCalculation name='White Label App Card' amount={restaurantBillingVm.whiteLabelAppCard} />
                    <TableRowCalculation name='Marketplace App Card' amount={restaurantBillingVm.marketplaceAppCard} />
                    <TableRowCalculation name='Payment Link' amount={restaurantBillingVm.paymentLink} />
                    <TableRowCalculation minus name='Restaurant Promo Code Cost App' amount={restaurantBillingVm.restaurantPromoCodeCostApp} />
                    <TableRowCalculation name='Restaurant Promo Code Balance' amount={restaurantBillingVm.restaurantPromoCodeBalance} />
                    <TableRowCalculation name='Rappi Cargo Web Card' amount={restaurantBillingVm.rappiCargoWebCard} />
                    <TableRowCalculation name='Rappi Cargo Web Cash' amount={restaurantBillingVm.rappiCargoWebCash} />
                    <TableRowCalculation name='Rappi Cargo App Card' amount={restaurantBillingVm.rappiCargoAppCard} />
                    <TableRowCalculation name='Rappi Cargo App Cash' amount={restaurantBillingVm.rappiCargoAppCash} />
                    <TableRowCalculation name='Rappi Cargo Manual Order' amount={restaurantBillingVm.rappiCargoManualOrder} />
                    <TableRowCalculation name='Rappi Cargo Payment Link' amount={restaurantBillingVm.rappiCargoPaymentLink} />
                    <TableRowCalculation name='Rappi Cargo Pos Card' amount={restaurantBillingVm.rappiCargoPosCard} />
                    <TableRowCalculation name='Rappi Cargo Pos Cash' amount={restaurantBillingVm.rappiCargoPosCash} />
                    <TableRowCalculation name='Rappi Cargo Bringg Cash' amount={restaurantBillingVm.rappiCargoBringgCash} />
                    <TableRowCalculation name='Rappi Cargo Bringg Card' amount={restaurantBillingVm.rappiCargoBringgCard} />
                    <TableRowCalculation name='Uber Daas Web Card' amount={restaurantBillingVm.uberDaasWebCard} />
                    <TableRowCalculation name='Uber Daas Web Cash' amount={restaurantBillingVm.uberDaasWebCash} />
                    <TableRowCalculation name='Uber Daas App Card' amount={restaurantBillingVm.uberDaasAppCard} />
                    <TableRowCalculation name='Uber Daas App Cash' amount={restaurantBillingVm.uberDaasAppCash} />
                    <TableRowCalculation name='Uber Daas Manual Order' amount={restaurantBillingVm.uberDaasManualOrder} />
                    <TableRowCalculation name='Uber Daas Payment Link' amount={restaurantBillingVm.uberDaasPaymentLink} />
                    <TableRowCalculation name='Uber Daas Pos Card' amount={restaurantBillingVm.uberDaasPosCard} />
                    <TableRowCalculation name='Uber Daas Pos Cash' amount={restaurantBillingVm.uberDaasPosCash} />
                    <TableRowCalculation name='Uber Daas Bringg Cash' amount={restaurantBillingVm.uberDaasBringgCash} />
                    <TableRowCalculation name='Uber Daas Bringg Card' amount={restaurantBillingVm.uberDaasBringgCard} />
                    <TableRowCalculation minus name='Marketplace Service Fee Cash' amount={restaurantBillingVm.marketplaceServiceFeeCash} />
                    <TableRowCalculation minus name='Cashback' amount={restaurantBillingVm.cashback} />
                    <TableRowCalculation minus name='Iva' amount={restaurantBillingVm.iva} />
                    <TableRowCalculation minus name='Commission' amount={restaurantBillingVm.commission} />
                    <TableRowCalculation minus name='Subscription Pay Per Use Payment Invoiced' amount={restaurantBillingVm.subscriptionPayPerUsePaymentInvoice} />
                    <TableRowCalculation minus name='Manual Earnings Invoiced' amount={restaurantBillingVm.manualEarningInvoice} />
                    <TableRowCalculation name='To Deposit' amount={restaurantBillingVm.deposit} />
                </TableBody>
            </Table>
        </TableContainer>
    );
}

type Props = {
    restaurantBillingVm: RestaurantBillingVm;
};

const useStyles = makeStyles({
    table: {},
});
