/**
 * @prettier
 */
export const BuyOrderStatuses = {
    PENDING: 'PENDING',
    SENT: 'SENT',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
} as const;

export type BuyOrderStatus = typeof BuyOrderStatuses[keyof typeof BuyOrderStatuses];
