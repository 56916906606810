/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { ClipboardListIcon } from 'src/icons/ClipboardListIcon';
import { CreateMenuCategorySection } from 'src/scenes/letseatmanager/menu/menuCategory/CreateMenuCategorySection';
import { useLoadMenuCategories } from 'src/services/menuCategory/useLoadMenuCategories';

export function MenuCategoriesEmptyState(): React.ReactElement {
    const classes = useStyles();

    const refreshMenuCategories = useLoadMenuCategories();

    return (
        <div className={classes.container}>
            <div className={classes.emptyStateContainer}>
                <div className={classes.iconContainer}>
                    <ClipboardListIcon color={'#4ABC99'} />
                </div>
                <h2 className={classes.subtitle}>{translate('Categories catalog your products in the menu')}</h2>
                <p className={classes.text}>{translate('On this section you can create and manage your categories')}</p>
            </div>
            <CreateMenuCategorySection onSuccess={refreshMenuCategories} />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
        padding: '20px 0',
    },
    emptyStateContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '45%',
    },
    iconContainer: {
        width: 120,
        height: 120,
        borderRadius: 120,
        backgroundColor: `${theme.palette.surface.brand}63`,
        color: theme.palette.text.brand,
        marginBottom: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        width: '50%',
        textAlign: 'center',
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '50%',
        textAlign: 'center',
        margin: '20px 0',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
}));
