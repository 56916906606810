/**
 * @prettier
 */
import * as React from 'react';

export function CalculatorIcon({ color, title, width, height }: Props): React.ReactElement {
    return (
        <svg width={width || 14} height={height || 18} viewBox='0 0 14 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                d='M10.0769 12.0769V13.9231M3.92308 8.38462H3.92964V8.39118H3.92308V8.38462ZM3.92308 10.2308H3.92964V10.2373H3.92308V10.2308ZM3.92308 12.0769H3.92964V12.0835H3.92308V12.0769ZM3.92308 13.9231H3.92964V13.9296H3.92308V13.9231ZM5.97272 8.38462H5.97846V8.39118H5.97272V8.38462ZM5.97272 10.2308H5.97846V10.2373H5.97272V10.2308ZM5.97272 12.0769H5.97846V12.0835H5.97272V12.0769ZM5.97272 13.9231H5.97846V13.9296H5.97272V13.9231ZM8.02728 8.38462H8.03385V8.39118H8.02728V8.38462ZM8.02728 10.2308H8.03385V10.2373H8.02728V10.2308ZM8.02728 12.0769H8.03385V12.0835H8.02728V12.0769ZM8.02728 13.9231H8.03385V13.9296H8.02728V13.9231ZM10.0769 8.38462H10.0835V8.39118H10.0769V8.38462ZM10.0769 10.2308H10.0835V10.2373H10.0769V10.2308ZM3.92308 4.07692H10.0769V5.92308H3.92308V4.07692ZM7 1C5.44759 1 3.91651 1.09026 2.41087 1.26421C1.50831 1.36923 0.846153 2.14872 0.846153 3.05703V15.1538C0.846153 15.6435 1.04066 16.1131 1.38688 16.4593C1.7331 16.8055 2.20268 17 2.69231 17H11.3077C11.7973 17 12.2669 16.8055 12.6131 16.4593C12.9593 16.1131 13.1538 15.6435 13.1538 15.1538V3.05703C13.1538 2.1479 12.4925 1.36923 11.5891 1.26421C10.0658 1.0878 8.53352 0.999586 7 1Z'
                stroke={color || 'currentColor'}
                strokeWidth='1.3'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    color?: string;
    title?: string;
    width?: number;
    height?: number;
};
