/**
 * @prettier
 */
import * as React from 'react';
import { App } from 'src/constants/App';
import { translate } from 'src/i18n/translate';
import { DidiCircleIcon } from 'src/icons/DidiCircleIcon';
import { KioskCircleIcon } from 'src/icons/KioskCircleIcon';
import { OldPideDirectoCircleIcon } from 'src/icons/OldPideDirectoCircleIcon';
import { PedidosYaCircleIcon } from 'src/icons/PedidosYaCircleIcon';
import { PideDirectoCircleIcon } from 'src/icons/PideDirectoCircleIcon';
import { PosCircleIcon } from 'src/icons/PosCircleIcon';
import { RappiCircleIcon } from 'src/icons/RappiCircleIcon';
import { UberDaasCircleIcon } from 'src/icons/UberDaasCircleIcon';
import { UberEatsCircleIcon } from 'src/icons/UberEatsCircleIcon';
import { isKioskApp } from 'src/utils/app/isKioskApp';
import { isLetsEatManagerApp } from 'src/utils/app/isLetsEatManagerApp';
import { isPedidosYaApp } from 'src/utils/app/isPedidosYaApp';
import { isPosApp } from 'src/utils/app/isPosApp';
import { isDidiApp } from 'src/utils/order/isDidiApp';
import { isRappiApp } from 'src/utils/order/isRappiApp';
import { isUberDaasApp } from 'src/utils/order/isUberDaasApp';
import { isUberEatsApp } from 'src/utils/order/isUberEatsApp';

export function AppIcon({ app, width, height }: Props): React.ReactElement {
    if (isDidiApp(app)) return <DidiCircleIcon title={translate('Didi icon')} width={width} height={height} />;
    if (isRappiApp(app)) return <RappiCircleIcon title={translate('Rappi icon')} width={width} height={height} />;
    if (isUberEatsApp(app)) return <UberEatsCircleIcon title={translate('Uber icon')} width={width} height={height} />;
    if (isUberDaasApp(app)) return <UberDaasCircleIcon title={translate('Uberdaas icon')} width={width} height={height} />;
    if (isPosApp(app)) return <PosCircleIcon title={translate('Pos icon')} width={width} height={height} />;
    if (isKioskApp(app)) return <KioskCircleIcon title={translate('Kiosk icon')} />;
    if (isPedidosYaApp(app)) return <PedidosYaCircleIcon title={translate('PedidosYa icon')} width={width} height={height} />;
    if (isLetsEatManagerApp(app)) return <OldPideDirectoCircleIcon title={translate('Manual order icon')} width={width} height={height} />;

    return <PideDirectoCircleIcon title={translate('PD icon')} width={width} height={height} />;
}

type Props = {
    app?: App;
    width?: number;
    height?: number;
};
