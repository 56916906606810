/**
 * @prettier
 */
import type { DeviceId } from 'src/types/Id';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';

export function setDeviceIdInMobileApp(deviceId: DeviceId) {
    if (isMobileApp()) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: 'SET_DEVICE_ID',
                data: deviceId,
            })
        );
    }
}
