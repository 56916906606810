/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { EmailTemplateId } from 'src/types/Id';

export async function getEmailTemplatesApi(): ApiSauceResponse<EmailTemplatesVm> {
    return letseatadminApiMethod('emailTemplate/findEmailTemplatesApi');
}

export type EmailTemplatesVm = {
    emailTemplates: Array<EmailTemplateVm>;
};

export type EmailTemplateVm = {
    emailTemplateId: EmailTemplateId;
    name: string;
    subject: string;
    bodyHtml?: string;
    bodyText?: string;
    createdAt: Date;
};
