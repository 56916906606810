/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { CashRegisterShiftIcon } from 'src/icons/CashRegisterShiftIcon';
import { PosRouter } from 'src/scenes/letseatmanager/pos/PosRouter';
import { useManageOpenCashRegister } from 'src/services/cashRegister/useManageOpenCashRegister';
import { ManagerUserId } from 'src/types/Id';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function PosClosedCashRegister(): React.ReactElement {
    const classes = useStyles();

    const [userHasRolePermission] = useUserHasRolePermission();

    const { manageOpenCashRegister } = useManageOpenCashRegister();

    const online = useSelector((state) => state.app2.online);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);
    const currentEmployeesClockedIn = useSelector((state) => state.app2.currentEmployeesClockedIn);
    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);

    const canOpenCashRegister = (userHasRolePermission(RolePermissions.OPEN_PERSONAL_CASH_REGISTER) && posMultipleCashRegistersEnabled) || !posMultipleCashRegistersEnabled;
    const employee = currentEmployeesClockedIn?.find(
        (employeeClockedIn: { clockedInAt: Date; managerUserId: ManagerUserId }) => employeeClockedIn.managerUserId === pinCodeUserSignedIn?.managerUserId
    );

    const handleOpenPosBusinessDay = () => {
        manageOpenCashRegister({ forceOpenCashRegister: false });
    };

    return (
        <div className={classes.cashRegister}>
            <PosRouter />
            <SecuredContent rolePermission={RolePermissions.OPEN_CASH_REGISTER}>
                <div className={classNames(classes.cashRegister, classes.noCashRegister)}>
                    <div className={classes.iconContainer}>
                        <CashRegisterShiftIcon width={120} height={120} />
                    </div>
                    <div>{translate('Cash register closed')}</div>
                    {canOpenCashRegister && (
                        <>
                            <div className={classes.subtext}>{translate('Open your cash register to start making orders')}</div>
                            <Button classes={{ button: classes.button }} disabled={!online} onClick={handleOpenPosBusinessDay}>
                                {translate('Open Cash Register')}
                            </Button>
                        </>
                    )}
                    {!canOpenCashRegister && <div className={classes.subtext}>{translate('You do not have the permissions to open cash register')}</div>}
                    {employee && <div className={classes.subtext}>{translate('Clock in time: @clockedInAt', { clockedInAt: moment(employee.clockedInAt).format('lll') })}</div>}
                </div>
            </SecuredContent>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    cashRegister: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: theme.palette.text.primary,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    noCashRegister: {
        fontFamily: theme.typography.regular,
        fontSize: 16,
        fontWeight: 500,
        marginTop: 30,
        gap: 24,
    },
    subtext: {
        color: theme.palette.text.secondary,
        fontSize: 14,
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 100,
        height: 100,
        borderRadius: '50%',
        backgroundColor: theme.palette.surface.brand,
        color: theme.palette.text.brand,
    },
    button: {
        fontSize: 18,
        width: 145,
        height: 50,
    },
}));
