/**
 * @prettier
 */
export const PositiveSurveyAnswers = {
    PUNCTUALITY: 'PUNCTUALITY',
    APPLICATION: 'APPLICATION',
    FOOD: 'FOOD',
    PRESENTATION: 'PRESENTATION',
    SERVICE: 'SERVICE',
} as const;

export type PositiveSurveyAnswer = typeof PositiveSurveyAnswers[keyof typeof PositiveSurveyAnswers];
