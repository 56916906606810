/**
 * @prettier
 */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { FormSubheading } from 'src/components/form/FormSubheading';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import type { PaymentRejectReason } from 'src/constants/PaymentRejectReason';
import { PaymentStatus, PaymentStatuses } from 'src/constants/PaymentStatus';
import { Psp, Psps } from 'src/constants/Psp';
import type { QpayPaymentStatus } from 'src/constants/QpayPaymentStatus';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import type {
    ConektaOrderId,
    CustomerId,
    HolaCashChargeId,
    MercadoPagoPaymentId,
    OrderId,
    PaymentId,
    QpayCodigoAprobacion,
    QpayReferenciaTransaccionBancaria,
    QpayTransaccionId,
    RestaurantId,
    StripeChargeId,
    TipId,
} from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeSecondsString } from 'src/utils/date/formatDateTimeSecondsString';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';

export function PaymentDetails({ paymentId, onChangePayment }: Props): React.ReactElement {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [payment, setPayment] = useState(undefined as PaymentVm | undefined);

    useEffect(() => {
        if (!paymentId) return;
        load();
    }, [paymentId]);

    const load = async () => {
        if (!paymentId) {
            return;
        }
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.admin.payment({ paymentId });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const payment: PaymentVm = response.data;
        setPayment(response.data);
        setLoading(false);
        onChangePayment && onChangePayment(payment);
    };

    return (
        <div style={{ position: 'relative' }}>
            <UpdatingContentProgress loading={loading} />
            <Card classes={{ root: classes.root }}>
                <CardHeader
                    title={
                        <Typography display='block' gutterBottom variant='h6'>
                            {translate('Payment Details')}
                        </Typography>
                    }
                    action={
                        <div>
                            <RefreshToolbarButton onClick={load} />
                        </div>
                    }
                />
                <CardContent>
                    <Form
                        onSubmit={() => {}}
                        initialValues={
                            payment
                                ? {
                                      payment: {
                                          ...payment,
                                          qpayPaymentErrors: payment.qpayPaymentErrors && payment.qpayPaymentErrors.map((i) => JSON.stringify(i)).join(', '),
                                          stripePaymentErrors: payment.stripePaymentErrors && payment.stripePaymentErrors.join(', '),
                                          conektaPaymentErrors: payment.conektaPaymentErrors && payment.conektaPaymentErrors.join(', '),
                                          amount: formatAsCurrencyNumber(payment.amount),
                                          createdAt: formatDateTimeStringReadable(payment.createdAt, TimeZones.AMERICA_MONTERREY),
                                          modifiedAt: formatDateTimeStringReadable(payment.modifiedAt, TimeZones.AMERICA_MONTERREY),
                                      },
                                  }
                                : {}
                        }
                        render={({ handleSubmit, submitting, pristine, values }) => (
                            <>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={4}>
                                        <Field name='payment.paymentStatus' component={TextField} label={translate('Payment Status')} fullWidth disabled />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <Field name='payment.amount' component={TextField} label={translate('Amount')} fullWidth disabled />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <Field name='payment.psp' component={TextField} label={translate('PSP')} defaultValue={translate('None').toUpperCase()} fullWidth disabled />
                                    </Grid>
                                    {payment?.psp === Psps.QPAY && (
                                        <Grid item xs={6} sm={4}>
                                            <Field name='payment.psp' component={TextField} label={translate('PSP')} defaultValue={translate('None').toUpperCase()} fullWidth disabled />
                                        </Grid>
                                    )}
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            name='payment.createdAt'
                                            component={TextField}
                                            label={translate('Created')}
                                            tooltip={formatDateTimeSecondsString(payment?.createdAt, TimeZones.ETC_UTC)}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>

                                    {payment?.createdAt !== payment?.modifiedAt && (
                                        <Grid item xs={12} sm={4}>
                                            <Field
                                                name='payment.modifiedAt'
                                                component={TextField}
                                                label={translate('Modified')}
                                                tooltip={formatDateTimeSecondsString(payment?.modifiedAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    )}
                                </Grid>

                                {payment?.paymentStatus !== PaymentStatuses.FAILED && (
                                    <>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <FormSubheading align='left'>{`${translate('Accepted By')} ${payment?.psp as any}`.toUpperCase()}</FormSubheading>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            {payment?.psp === Psps.QPAY && (
                                                <>
                                                    <Grid item xs={6} sm={4}>
                                                        <Field name='payment.qpayPaymentStatus' component={TextField} label={translate('Qpay Payment Status')} fullWidth disabled />
                                                    </Grid>
                                                    <Grid item xs={6} sm={4}>
                                                        <Field name='payment.qpayTransaccionId' component={TextField} label={translate('Qpay Transaccion Id')} fullWidth disabled />
                                                    </Grid>
                                                    <Grid item xs={6} sm={4}>
                                                        <Field name='payment.qpayCodigoAprobacion' component={TextField} label={translate('Qpay Codigo Aprobacion')} fullWidth disabled />
                                                    </Grid>
                                                    <Grid item xs={6} sm={4}>
                                                        <Field
                                                            name='payment.qpayReferenciaTransaccionBancaria'
                                                            component={TextField}
                                                            label={translate('Qpay Referencia Transaccion Bancaria')}
                                                            fullWidth
                                                            disabled
                                                        />
                                                    </Grid>
                                                </>
                                            )}

                                            {payment?.psp === Psps.STRIPE && (
                                                <>
                                                    <Grid item xs={6} sm={4}>
                                                        <Field name='payment.stripePaymentStatus' component={TextField} label={translate('Stripe Payment Status')} fullWidth disabled />
                                                    </Grid>
                                                    <Grid item xs={6} sm={4}>
                                                        <Field name='payment.stripeChargeId' component={TextField} label={translate('Stripe Charge Id')} fullWidth disabled />
                                                    </Grid>
                                                </>
                                            )}

                                            {payment?.psp === Psps.CONEKTA && (
                                                <>
                                                    <Grid item xs={6} sm={4}>
                                                        <Field name='payment.conektaPaymentStatus' component={TextField} label={translate('Conekta Payment Status')} fullWidth disabled />
                                                    </Grid>
                                                    <Grid item xs={6} sm={4}>
                                                        <Field name='payment.conektaOrderId' component={TextField} label={translate('Conekta Order Id')} fullWidth disabled />
                                                    </Grid>
                                                </>
                                            )}

                                            {payment?.psp === Psps.HOLACASH && (
                                                <>
                                                    <Grid item xs={6} sm={4}>
                                                        <Field name='payment.holaCashPaymentStatus' component={TextField} label={translate('HolaCash Payment Status')} fullWidth disabled />
                                                    </Grid>
                                                    <Grid item xs={6} sm={4}>
                                                        <Field name='payment.holaCashChargeId' component={TextField} label={translate('HolaCash Charge Id')} fullWidth disabled />
                                                    </Grid>
                                                </>
                                            )}

                                            {payment?.psp === Psps.MERCADOPAGO && (
                                                <>
                                                    <Grid item xs={6} sm={4}>
                                                        <Field name='payment.mercadoPagoPaymentStatus' component={TextField} label={translate('Mercado Pago Payment Status')} fullWidth disabled />
                                                    </Grid>
                                                    <Grid item xs={6} sm={4}>
                                                        <Field name='payment.mercadoPagoPaymentId' component={TextField} label={translate('Mercado Pago Payment Id')} fullWidth disabled />
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </>
                                )}

                                {payment?.qpayPaymentRejectReason && (
                                    <>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <FormSubheading align='left'>{`${translate('Rejected by')} ${Psps.QPAY}`.toUpperCase()}</FormSubheading>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={8}>
                                                <Field name='payment.qpayPaymentRejectReason' component={TextField} label={translate('Reject Reason')} fullWidth disabled />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Field name='payment.qpayPaymentErrors' component={TextField} label={translate('Debug Info')} fullWidth disabled />
                                            </Grid>
                                        </Grid>
                                    </>
                                )}

                                {payment?.stripePaymentRejectReason && (
                                    <>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <FormSubheading align='left'>{`${translate('Rejected by')} ${Psps.STRIPE}`.toUpperCase()}</FormSubheading>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={8}>
                                                <Field name='payment.stripePaymentRejectReason' component={TextField} label={translate('Reject Reason')} fullWidth disabled />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Field name='payment.stripePaymentErrors' component={TextField} label={translate('Debug Info')} fullWidth disabled />
                                            </Grid>
                                        </Grid>
                                    </>
                                )}

                                {payment?.conektaPaymentRejectReason && (
                                    <>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <FormSubheading align='left'>{`${translate('Rejected by')} ${Psps.CONEKTA}`.toUpperCase()}</FormSubheading>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={8}>
                                                <Field name='payment.conektaPaymentRejectReason' component={TextField} label={translate('Reject Reason')} fullWidth disabled />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Field name='payment.conektaPaymentErrors' component={TextField} label={translate('Debug Info')} fullWidth disabled />
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                    />
                </CardContent>
            </Card>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
}));

type Props = {
    paymentId?: PaymentId;
    onChangePayment?: (arg1: PaymentVm) => any;
};

export type PaymentVm = {
    paymentId: PaymentId;
    restaurantId: RestaurantId;
    customerId: CustomerId;
    orderId?: OrderId;
    tipId?: TipId;
    paymentStatus: PaymentStatus;
    amount?: string;
    psp?: Psp;
    paymentRejectReason?: PaymentRejectReason;
    qpayTransaccionId?: QpayTransaccionId;
    qpayCodigoAprobacion?: QpayCodigoAprobacion;
    qpayReferenciaTransaccionBancaria?: QpayReferenciaTransaccionBancaria;
    qpayPaymentStatus?: QpayPaymentStatus;
    qpayPaymentRejectReason?: string;
    qpayPaymentErrors?: Array<{
        qpayResponse: any;
    }>;
    conektaOrderId?: ConektaOrderId;
    conektaPaymentStatus?: string;
    conektaPaymentRejectReason?: string;
    conektaPaymentErrors?: Array<string>;
    stripeChargeId?: StripeChargeId;
    stripePaymentStatus?: string;
    stripePaymentRejectReason?: string;
    stripePaymentErrors?: Array<string>;
    holaCashChargeId?: HolaCashChargeId;
    holaCashPaymentStatus?: string;
    holaCashPaymentError?: Array<string>;
    mercadoPagoPaymentId?: MercadoPagoPaymentId;
    mercadoPagoPaymentStatus?: string;
    mercadoPagoPaymentError?: Array<string>;
    createdAt: Date;
    modifiedAt: Date;
};
