/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useState } from 'react';
import { changeOrderRejectReasonApi } from 'src/api/letseatmanager/order/changeOrderRejectReasonApi';
import { ExtraOrderRejectReasons } from 'src/components/dialog/order/rejectOrderDialog/ExtraOrderRejectReasons';
import { RappiCargoOrderRejectReasons } from 'src/components/dialog/order/rejectOrderDialog/RappiCargoOrderRejectReasons';
import { RestaurantOrderRejectReasons } from 'src/components/dialog/order/rejectOrderDialog/RestaurantOrderRejectReasons';
import { WithOutAssignmentOrderRejectReasons } from 'src/components/dialog/order/rejectOrderDialog/WithOutAssignmentOrderRejectReasons';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function ChangeRejectOrderReasonDialog({ order, open, onCloseDialog }: Props): React.ReactElement {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const onReasonClick = async (reason: any) => {
        if (loading) return;

        setLoading(true);
        const response = await changeOrderRejectReasonApi({ orderId: order.orderId, orderRejectReason: reason });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onCloseDialog();
    };

    return (
        <Dialog aria-labelledby='reject-order-dialog-title' scroll='paper' open={open} onClose={onCloseDialog}>
            <DialogTitle id='reject-order-dialog-title' classes={{ root: classes.dialogTitle }}>
                {translate('Select cause of the rejection')}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} direction='column' justify='flex-start'>
                    <RestaurantOrderRejectReasons disabled={loading} onReasonClick={onReasonClick} />
                    <RappiCargoOrderRejectReasons disabled={loading} onReasonClick={onReasonClick} />
                    <WithOutAssignmentOrderRejectReasons disabled={loading} onReasonClick={onReasonClick} />
                    <ExtraOrderRejectReasons disabled={loading} order={order} />
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 46,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.secondary.contrastText,
        fontSize: 25,
        marginRight: 10,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    text: {
        fontFamily: theme.typography.semiBold,
        fontSize: 17,
        textTransform: 'none',
    },
    horizontal_vertical_center: {
        textAlign: 'center',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
    },
}));

type Props = {
    order: OrderVm;
    open: boolean;
    onCloseDialog: any;
};
