/**
 * @prettier
 */
import type { AwsCognitoUsername } from 'src/types/Id';

export function normalizeToUsername(value: string): AwsCognitoUsername {
    // Normalise, remove non ascii characters and lowercase
    return value
        .normalize('NFKD')
        .replace(/[^a-zA-Z0-9À-ž]/g, '')
        .toLowerCase() as any;
}
