/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { SelectItemsDialog } from 'src/components/dialog/SelectItemsDialog';
import { translate } from 'src/i18n/translate';
import { useMenuCategories } from 'src/services/menuCategory/useMenuCategories';
import type { MenuCategoryId } from 'src/types/Id';
import { MenuCategoryVm } from 'src/types/MenuCategoryVm';

export function SelectMenuCategoriesDialog({ open, defaultSelectedMenuCategories, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    const { menuCategories } = useMenuCategories();

    const handleDialogSuccess = (modifierGroupIds: any) => {
        onSuccess?.(modifierGroupIds);
    };

    const items =
        menuCategories?.map((menuCategory: MenuCategoryVm) => ({
            value: menuCategory.menuCategoryId,
            searchableBy: menuCategory.name,
            item: menuCategory,
        })) ?? [];

    return (
        <SelectItemsDialog
            open={open}
            title={translate('Select your categories')}
            items={items}
            defaultSelectedItems={defaultSelectedMenuCategories}
            renderItem={({ item: menuCategory }: { item: MenuCategoryVm }) => (
                <div className={classes.menuCategoryContainer}>
                    <span className={classes.text}>{menuCategory.name}</span>
                </div>
            )}
            onClose={onClose}
            onSuccess={handleDialogSuccess}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    menuCategoryContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
}));

type Props = {
    open: boolean;
    defaultSelectedMenuCategories?: Array<MenuCategoryId>;
    onClose: any;
    onSuccess?: any;
};
