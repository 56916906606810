/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog } from '@pidedirecto/ui';
import * as React from 'react';
import { ProductCancellationReason, ProductCancellationReasons } from 'src/constants/ProductCancellationReason';
import { translate } from 'src/i18n/translate';
import type { CustomProductCancellationReason } from 'src/types/Id';
import { useSelector } from 'src/utils/react/useSelector';

export function CancelProductDialog({ open, onClose, onSelect }: Props): React.ReactElement {
    const classes = useStyles();

    const productCancellationReasons: Array<{
        reason: CustomProductCancellationReason;
    }> = useSelector((state) => state.app.restaurant?.productCancellationReasons);
    const defaultProductCancellationReasons: Array<{
        reason: ProductCancellationReason;
    }> = Object.keys(ProductCancellationReasons).map((value) => ({ reason: value as ProductCancellationReason }));

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog title={translate('Select cause of the cancellation')} open={open} onClose={handleClose}>
            <Grid container spacing={2} direction='column' justify='flex-start'>
                <Grid item xs={12}>
                    {productCancellationReasons &&
                        productCancellationReasons.map(({ reason }) => (
                            <Grid item key={reason}>
                                <Button variant={'text'} onClick={() => onSelect(reason)} size='large'>
                                    <span className={classes.text}>{reason}</span>
                                </Button>
                            </Grid>
                        ))}
                    {defaultProductCancellationReasons.map(({ reason }) => (
                        <Grid item key={reason}>
                            <Button variant={'text'} onClick={() => onSelect(reason)} size='large'>
                                <span className={classes.text}>{translate(`ProductCancellationReasons.${reason}`)}</span>
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: theme.typography.semiBold,
        fontSize: 17,
        textTransform: 'none',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSelect: any;
};
