/**
 * @prettier
 */
import { translate } from 'src/i18n/translate';

export function alertKnownErrorOrSomethingWentWrongError(error: any): void {
    if (!error) {
        alert(translate('Something went wrong'));
        return;
    }
    alert(translate(`Something went wrong @error`, { error }));
}
