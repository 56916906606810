/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { BoxIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { changeMenuCategoryApi } from 'src/api/letseatmanager/menuCategory/changeMenuCategoryApi';
import { Button } from 'src/components/Button';
import { DraggableList } from 'src/components/DraggableList';
import { DraggableListItem } from 'src/components/DraggableListItem';
import { translate } from 'src/i18n/translate';
import { MenuItemInMenuCategory } from 'src/scenes/letseatmanager/menu/menuCategory/MenuItemInMenuCategory';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import { useMenuItems } from 'src/services/menuItem/useMenuItems';
import type { MenuItemId } from 'src/types/Id';
import { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import type { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function MenuItemsInMenuCategory({ menuCategory, menuItemIds, onAddProduct, onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();

    const { menuItems } = useMenuItems();

    const menuCategoryHasProducts = menuCategory.menuItemIds.length > 0;

    const updateMenuCategory = async (menuItemIds: any) => {
        const response = await changeMenuCategoryApi({
            menuCategoryId: menuCategory.menuCategoryId,
            menuItemIds: menuItemIds,
            modifierGroupIds: menuCategory.modifierGroupIds,
            name: menuCategory.name,
            hidden: menuCategory.hidden,
            promotion: menuCategory.promotion,
            imageUrl: menuCategory.imageUrl,
            printerNames: menuCategory.printerNames,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
    };

    if (!menuCategoryHasProducts) {
        return (
            <div className={classes.container}>
                <div className={classes.iconContainer}>
                    <BoxIcon title={'box icon'} />
                </div>
                <p className={classes.text}>{translate('On this section you can manage the different products in your category')}</p>
                <Button outlined onClick={onAddProduct} disabled={isMenuEditionDisabled}>
                    {translate('Add Product +')}
                </Button>
            </div>
        );
    }

    return (
        <DraggableList onDragEnd={updateMenuCategory}>
            {menuItemIds.map((menuItemId) => {
                const menuItem = menuItems?.find((menuItem: ManagerMenuItemVm) => menuItem.menuItemId === menuItemId);
                if (!menuItem) return null;

                return (
                    <DraggableListItem key={menuItemId} value={menuItemId} iconColor={'#6C7076'} classes={{ container: classes.draggableItemContainer }}>
                        <MenuItemInMenuCategory menuItem={menuItem} menuCategoryId={menuCategory.menuCategoryId} onSuccess={onSuccess} />
                    </DraggableListItem>
                );
            })}
        </DraggableList>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    iconContainer: {
        width: 80,
        height: 80,
        borderRadius: 80,
        backgroundColor: `${theme.palette.surface.brand}63`,
        color: theme.palette.text.brand,

        marginBottom: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '80%',
        textAlign: 'center',
        margin: '20px 0',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    draggableItemContainer: {
        alignItems: 'center',
        marginBottom: 2,
    },
}));

type Props = {
    menuCategory: MenuCategoryVm;
    menuItemIds: Array<MenuItemId>;
    onAddProduct: any;
    onSuccess?: any;
};
