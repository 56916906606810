/**
 * @prettier
 */
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { useFindOpenedUserCashRegisterPosBusinessDay } from 'src/services/cashRegister/useFindOpenedUserCashRegisterPosBusinessDay';
import { useNotification } from 'src/services/notification/useNotification';
import { PinCodeUser } from 'src/types/PinCodeUser';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function useOpenCashRegister(): {
    openCashRegister: (arg1: { forceOpenCashRegister: boolean }) => void;
} {
    const notification = useNotification();
    const [userHasRolePermission] = useUserHasRolePermission();
    const { findOpenedUserCashRegisterPosBusinessDay } = useFindOpenedUserCashRegisterPosBusinessDay();

    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);
    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);
    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const openedCashRegisterPosBusinessDay = useSelector((state) => state.pos.openedCashRegisterPosBusinessDay);

    const setOpenedCashRegisterPosBusinessDay = useAction(posReducer.actions.setOpenedCashRegisterPosBusinessDay);
    const setOpenCashRegisterDialogState = useAction(posReducer.actions.setOpenCashRegisterDialogState);
    const openPinCodeUserSignInDialog = useAction(posReducer.actions.openPinCodeUserSignInDialog);
    const setSelectOpenedCashRegisterDialogState = useAction(posReducer.actions.setSelectOpenedCashRegisterDialogState);

    const handleOpenPinCodeUserSignInDialog = (forceOpenCashRegister?: boolean) => {
        openPinCodeUserSignInDialog({
            onSuccess: async (pinCodeUser: PinCodeUser) => {
                if (!pinCodeUser.userRolePermissions?.includes(RolePermissions.OPEN_PERSONAL_CASH_REGISTER)) {
                    if (forceOpenCashRegister) {
                        setSelectOpenedCashRegisterDialogState({ open: true });
                        return;
                    }

                    notification({ message: translate('You do not have the permissions to open cash register') });
                    return;
                }

                const hasOpenedCashRegister = findOpenedUserCashRegisterPosBusinessDay(pinCodeUser);

                if (!hasOpenedCashRegister) {
                    notification({ message: translate('You can not open a cash register') });
                    return;
                }

                setOpenedCashRegisterPosBusinessDay(hasOpenedCashRegister);
                notification({ message: translate('Identified correctly') });
                setOpenCashRegisterDialogState({ open: true });
            },
            onFailed: () => {
                notification({ message: translate('Unauthorized to access.') });
            },
        });
    };

    const openCashRegister = ({ forceOpenCashRegister }: { forceOpenCashRegister: boolean }) => {
        if (!openedPosBusinessDay) {
            notification({ message: translate('You can not open a cash register') });
            return;
        }

        if (!userHasRolePermission(RolePermissions.OPEN_CASH_REGISTER) && !posMultipleCashRegistersEnabled)
            return notification({ message: translate('You do not have the permissions to open cash register') });

        if (!posMultipleCashRegistersEnabled) {
            setOpenCashRegisterDialogState({ open: true });
            return;
        }

        if (!pinCodeUserSignedIn) {
            handleOpenPinCodeUserSignInDialog();
            return;
        }

        if (userHasRolePermission(RolePermissions.OPEN_PERSONAL_CASH_REGISTER)) {
            const hasOpenedCashRegister = findOpenedUserCashRegisterPosBusinessDay();

            if (hasOpenedCashRegister) {
                setOpenedCashRegisterPosBusinessDay(hasOpenedCashRegister);
                setOpenCashRegisterDialogState({ open: true });
                return;
            }

            notification({ message: translate('You have already opened a cash register before, you cannot open more than one cash register in a day') });
            return;
        }

        // TODO: check why openedCashRegisterPosBusinessDay.opened in not in type
        if (userHasRolePermission(RolePermissions.MANAGE_CASH_REGISTERS) && openedCashRegisterPosBusinessDay && !!(openedCashRegisterPosBusinessDay as any).opened) {
            setOpenCashRegisterDialogState({ open: true });
            return;
        }

        if (forceOpenCashRegister) {
            setSelectOpenedCashRegisterDialogState({ open: true });
            return;
        }

        notification({ message: translate('You can not open a cash register') });
    };

    return { openCashRegister };
}
