/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import { useContext } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { PosContext } from 'src/scenes/letseatmanager/pos/PosProvider';
import { useClearPos } from 'src/services/pos/useClearPos';
import { usePrintOrderCommand } from 'src/services/printer/usePrintOrderCommand';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import { PosPayment } from 'src/types/PosPayment';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { findItemsChangedInOrder } from 'src/utils/pos/findItemsChangedInOrder';
import { useCompletePosOrder } from 'src/utils/react/useCompletePosOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { canRestaurantInvoice } from 'src/utils/restaurant/canRestaurantInvoice';

export function PosPaymentCompleteAndInvoiceButton(): React.ReactElement | null {
    const classes = useStyles();
    const [printOrderCommand] = usePrintOrderCommand();
    const [printOrderTicket] = usePrintOrderTicket();

    const clearPos = useClearPos();

    const { completeOrder } = useCompletePosOrder();
    const posContext = useContext(PosContext);

    const disabledPosPaymentActions = useSelector((state) => state.pos.disabledPosPaymentActions);
    const paying = useSelector((state) => state.pos.paying);
    const cancelledItems = useSelector((state) => state.pos.cancelledItems);
    const items = useSelector((state) => state.pos.items);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const discount = useSelector((state) => state.pos.discount);
    const payments = useSelector((state) => state.pos.payments);
    const restaurant = useSelector((state) => state.app.restaurant);
    const totalPaid = useSelector((state) => state.pos.totalPaid);
    const total = useSelector((state) => state.pos.payment?.total);
    const hasPaymentMissing = BigNumber(totalPaid).isLessThan(total);

    const posPaymentIsPaymentLink = payments?.some((posPayment: PosPayment) => isPaymentLinkPayment(posPayment.paymentMethod));
    const disabled = disabledPosPaymentActions || paying || posPaymentIsPaymentLink || hasPaymentMissing;

    const handleCompleteOrder = async () => {
        const order = await completeOrder();
        if (!order) return;

        const itemsToPrint = findItemsChangedInOrder(pendingOrder?.orderItems ?? ([] as any), items ?? ([] as any));
        const removedItemsToPrint = findItemsChangedInOrder(pendingOrder?.cancelledItems ?? ([] as any), cancelledItems ?? []);

        if (itemsToPrint?.length || removedItemsToPrint?.length) {
            await printOrderCommand({ ...order, orderItems: itemsToPrint, cancelledItems: removedItemsToPrint } as any);
        }

        if (order.invoiced) {
            await printOrderTicket(order);
        }

        posContext.openCreateOrderInvoiceDialog(order);
        clearPos();
    };

    if (!canRestaurantInvoice(restaurant)) return null;

    return (
        <Button classes={{ button: classes.basicButton }} onClick={handleCompleteOrder} disabled={disabled}>
            {translate('Charge and Invoice')}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    basicButton: {
        width: '100%',
        height: 50,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
