/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { EmployeeLogEventVm } from 'src/types/EmployeeLogEventVm';
import type { ManagerUserId, RestaurantId } from 'src/types/Id';

export async function findRestaurantEmployeeLogEventsApi(request: FindRestaurantEmployeeLogEventsApiRequest): ApiSauceResponse<Array<EmployeeLogEventVm>> {
    return letseatmanagerApiMethod('restaurantEmployeeDayShift/findRestaurantEmployeeLogEventsApi', request);
}

type FindRestaurantEmployeeLogEventsApiRequest = {
    restaurantId: RestaurantId;
    managerUserId: ManagerUserId;
    from: string;
    to: string;
};
