/**
 * @prettier
 */
import { history } from 'src/config/history';

export function getUrlQueryParameter(name: string, location?: any): any {
    if (!location) {
        location = history.location;
    }
    return new URLSearchParams(location.search).get(name);
}
