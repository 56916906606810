/**
 * @prettier
 */
import * as React from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import { ProductLayout, ProductLayouts } from 'src/constants/ProductLayouts';
import { translate } from 'src/i18n/translate';

export function FormProductLayoutSelect({ onChange, defaultValue, label, name, required }: Props): React.ReactElement {
    const options = required
        ? Object.values(ProductLayouts).map((layout) => ({ label: translate(`ProductLayouts.${layout}`), value: layout }))
        : [{ label: '', value: '' }, ...Object.values(ProductLayouts).map((layout) => ({ label: translate(`ProductLayouts.${layout}`), value: layout }))];

    return <FormSelect label={label} name={name} onChange={onChange} options={options} defaultValue={defaultValue} />;
}

type Props = {
    onChange?: any;
    name: string;
    label?: string;
    defaultValue?: ProductLayout;
    required?: boolean;
};
