/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { ArgentinianTax } from 'src/constants/ArgentinianTaxes';
import type { MexicanTax } from 'src/constants/MexicanTaxes';
import type { OrderType } from 'src/constants/OrderType';
import type { UruguayanTax } from 'src/constants/UruguayanTaxes';
import type { RestaurantTaxId } from 'src/types/Id';

export function changeRestaurantTaxApi(request: ChangeTaxApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurantTax/changeRestaurantTaxApi', request);
}

export type ChangeTaxApiRequest = {
    restaurantTaxId: RestaurantTaxId;
    name: string;
    description: string;
    taxType: ArgentinianTax | MexicanTax | UruguayanTax;
    taxRate: number;
    orderTypes: Array<OrderType>;
};
