/**
 * @prettier
 */
export const TableSizes = {
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
    LARGE: 'LARGE',
} as const;

export type TableSize = typeof TableSizes[keyof typeof TableSizes];
