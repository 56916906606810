/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { translate } from 'src/i18n/translate';
import { SearchableList } from 'src/scenes/letseatmanager/menu/components/SearchableList';
import { ChangeMenuCategorySection } from 'src/scenes/letseatmanager/menu/menuCategory/ChangeMenuCategorySection';
import { CreateMenuCategorySection } from 'src/scenes/letseatmanager/menu/menuCategory/CreateMenuCategorySection';
import { MenuCategoryListItem } from 'src/scenes/letseatmanager/menu/menuCategory/MenuCategoryListItem';
import { MenuCategorySection } from 'src/scenes/letseatmanager/menu/menuCategory/MenuCategorySection';
import { RemoveMenuCategoryDialog } from 'src/scenes/letseatmanager/menu/menuCategory/RemoveMenuCategoryDialog';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import { useLoadMenus } from 'src/services/menu/useLoadMenus';
import { useLoadMenuCategories } from 'src/services/menuCategory/useLoadMenuCategories';
import { useMenuCategories } from 'src/services/menuCategory/useMenuCategories';
import { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import { deepEqual } from 'src/utils/object/deepEqual';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export function MenuCategories(): React.ReactElement {
    const classes = useStyles();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();
    const isSmallScreen = useIsSmallScreen();

    const [removeMenuCategoryDialogState, setRemoveMenuCategoryDialogState] = useState({ open: false, menuCategory: undefined });
    const [selectedMenuCategory, setSelectedMenuCategory] = useState<MenuCategoryVm | undefined>(undefined);
    const [menuCategoriesManagementType, setMenuCategoriesManagementType] = useState<keyof typeof MenuCategoriesManagementTypes>(MenuCategoriesManagementTypes.SORT);

    const { menuCategories } = useMenuCategories();
    const refreshMenuCategories = useLoadMenuCategories();
    const refreshMenus = useLoadMenus();

    useEffect(() => {
        refreshMenuCategories();
    }, []);

    useEffect(() => {
        if (menuCategories && !selectedMenuCategory && !isSmallScreen) {
            setSelectedMenuCategory(menuCategories[0]);
        }
        if (menuCategories && selectedMenuCategory) updateSelectedMenuCategory();
    }, [menuCategories, selectedMenuCategory, isSmallScreen]);

    const updateSelectedMenuCategory = () => {
        if (!selectedMenuCategory) return;

        const updatedMenuCategory = menuCategories.find((menuCategory) => menuCategory.menuCategoryId === selectedMenuCategory.menuCategoryId);
        if (deepEqual(updatedMenuCategory, selectedMenuCategory) || !updatedMenuCategory) return;

        setSelectedMenuCategory(updatedMenuCategory);
    };

    const showCreateMenuCategory = () => setMenuCategoriesManagementType(MenuCategoriesManagementTypes.CREATE);

    const showChangeMenuCategory = (menuCategory: any) => {
        setMenuCategoriesManagementType(MenuCategoriesManagementTypes.CHANGE);
        setSelectedMenuCategory(menuCategory);
    };

    const showSortProducts = () => {
        setMenuCategoriesManagementType(MenuCategoriesManagementTypes.SORT);
        if (isSmallScreen) setSelectedMenuCategory(undefined);
    };

    const handleSelectMenuCategory = (menuCategory: any) => {
        setSelectedMenuCategory(menuCategory);
    };

    const openRemoveMenuCategoryDialog = (menuCategory: any) => {
        setRemoveMenuCategoryDialogState({ open: true, menuCategory });
    };

    const closeRemoveMenuCategoryDialog = () => {
        setRemoveMenuCategoryDialogState({ open: false, menuCategory: undefined });
    };

    const handleUpdate = () => {
        refreshMenuCategories();
        refreshMenus();
        showSortProducts();
    };

    return (
        <div className={classes.row}>
            <div className={classes.menuCategoriesContainer}>
                <SearchableList
                    title={translate('Categories')}
                    defaultListItemSelected={selectedMenuCategory?.menuCategoryId}
                    onCreate={showCreateMenuCategory}
                    disabled={isMenuEditionDisabled}
                    items={menuCategories}
                    itemSize={103}
                    height={600}
                    getItemKey={(menuCategory: MenuCategoryVm) => menuCategory.menuCategoryId}
                    getItemSearchableBy={(menuCategory) => menuCategory.name}
                    renderItem={(menuCategory: MenuCategoryVm) => (
                        <MenuCategoryListItem
                            menuCategory={menuCategory}
                            onChangeMenuCategory={showChangeMenuCategory}
                            onRemoveMenuCategory={openRemoveMenuCategoryDialog}
                            onClick={handleSelectMenuCategory}
                        />
                    )}
                />
            </div>
            {menuCategoriesManagementType === MenuCategoriesManagementTypes.CHANGE && (
                <ChangeMenuCategorySection menuCategoryId={selectedMenuCategory?.menuCategoryId} onClose={showSortProducts} onSuccess={handleUpdate} />
            )}
            {menuCategoriesManagementType === MenuCategoriesManagementTypes.CREATE && <CreateMenuCategorySection onClose={showSortProducts} onSuccess={handleUpdate} />}
            {menuCategoriesManagementType === MenuCategoriesManagementTypes.SORT && selectedMenuCategory && (
                <MenuCategorySection menuCategory={selectedMenuCategory} onClose={() => setSelectedMenuCategory(undefined)} />
            )}
            <RemoveMenuCategoryDialog
                open={removeMenuCategoryDialogState.open}
                onClose={closeRemoveMenuCategoryDialog}
                menuCategory={removeMenuCategoryDialogState.menuCategory}
                onSuccess={handleUpdate}
            />
        </div>
    );
}

const MenuCategoriesManagementTypes = {
    CREATE: 'CREATE',
    CHANGE: 'CHANGE',
    SORT: 'SORT',
} as const;

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: theme.typography.regular,
        fontSize: '24px',
        lineHeight: '36px',
        color: theme.palette.text.primary,
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: '20px',
        gap: 60,
    },
    menuCategoriesContainer: {
        width: '100%',
    },
}));
