/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function OrderIssuesTable({ ordersIssuesWithReasonsData, loading }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const ordersIssuesWithReasons =
        ordersIssuesWithReasonsData?.ordersIssuesWithReasonsData?.map((orderIssue: any) => ({
            createdAt: orderIssue.createdAt,
            orderRejectReason: orderIssue.orderRejectReason,
            orderStatus: translate(orderIssue.orderStatus),
            orderVolume: orderIssue.orderVolume,
            total: orderIssue.total,
        })) || [];
    const totalLostSales = ordersIssuesWithReasonsData?.totalLostSales ?? [];
    const hasIssuesWithReasons = ordersIssuesWithReasons?.length > 0;

    const IssuesWithReasonsReportColumns = [
        {
            name: 'createdAt',
            label: translate('Day'),
            options: {
                filter: false,
            },
        },
        {
            name: 'orderStatus',
            label: translate('Status'),
            options: {
                filter: false,
            },
        },
        {
            name: 'orderRejectReason',
            label: translate('Reject Reasons'),
            options: {
                filter: false,
            },
        },
        {
            name: 'orderVolume',
            label: translate('Amount'),
            options: {
                filter: false,
            },
        },
        {
            name: 'total',
            label: translate('Total Lost'),
            options: {
                filter: false,
            },
        },
    ];

    if (!hasIssuesWithReasons) return null;

    return (
        <ReportSection title={translate('Order issues by day')} loading={loading}>
            <div className={classes.splitContainer}>
                <div className={classes.sectionHeadKpi}>
                    <KpiCard
                        classes={{
                            container: classes.headingKpiContainer,
                            title: classes.headingKpiTitleKpi,
                            body: classes.headingKpiBody,
                            color: classes.headingKpiColor,
                            legend: classes.headingKpiLegendContainer,
                        }}
                        legend={formatAsCurrencyNumber(totalLostSales?.[0] || '0')}
                        title={translate('Total Lost')}
                    />
                    <KpiCard
                        classes={{
                            container: classes.headingKpiContainer,
                            title: classes.headingKpiTitleKpi,
                            body: classes.headingKpiBody,
                            color: classes.headingKpiColor,
                            legend: classes.headingKpiLegendContainer,
                        }}
                        legend={translate(`OrderRejectReasons.${totalLostSales?.[1]}`)}
                        title={translate(`Most common Order Problem`)}
                    />
                </div>
                <div className={classes.container}>
                    <Table
                        data={ordersIssuesWithReasons?.map((ordersIssue: any) => ({
                            ...ordersIssue,
                            total: formatAsCurrencyNumber(ordersIssue.total || '0'),
                            orderRejectReason: translate(`OrderRejectReasons.${ordersIssue.orderRejectReason}`),
                            orderStatus: translate(ordersIssue.orderStatus),
                        }))}
                        columns={IssuesWithReasonsReportColumns}
                        options={{
                            tableBodyMaxHeight: '350px',
                            search: false,
                            responsive: 'standard',
                            selectableRows: 'none',
                            filter: false,
                            rowsPerPage: 100,
                            viewColumns: false,
                            print: false,
                        }}
                    />
                </div>
            </div>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    splitContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',

        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'row-reverse',
            flexWrap: 'nowrap',
        },
    },
    sectionHeadKpi: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'column',
            width: '20%',
        },
    },

    headingKpiContainer: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '8px',
        margin: 5,
        textAlign: 'center',
        marginRight: '5%',
        marginBottom: '2%',
    },
    headingKpiTitleKpi: {
        fontFamily: theme.typography.regular,
        fontSize: 16,
        color: '#6D7B7F',
    },
    headingKpiBody: {
        fontSize: 28,
        fontFamily: theme.typography.semiBold,
    },
    headingKpiColor: {
        width: 12,
        height: 12,
        borderRadius: 2,
    },
    headingKpiLegendContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.between('sm', 'lg')]: {
            justifyContent: 'left',
            textAlign: 'left',
            marginLeft: 40,
        },
    },
}));

type Props = {
    loading: boolean;
    ordersIssuesWithReasonsData: any;
};
