/**
 * @prettier
 */
export const PrinterTypes = {
    WIFI: 'WIFI',
    BLUETOOTH: 'BLUETOOTH',
    USB: 'USB',
    PAYMENT_TERMINAL_PRINTER: 'PAYMENT_TERMINAL_PRINTER',
    SDK: 'SDK',
} as const;

export type PrinterType = typeof PrinterTypes[keyof typeof PrinterTypes];
