/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SurveyAnswer } from 'src/constants/SurveyAnswer';
import type { CustomerId, RestaurantId, SurveyId } from 'src/types/Id';

export async function getSurveysApi(request: GetSurveysApiRequest): ApiSauceResponse<SurveysVm> {
    return letseatadminApiMethod('survey/getSurveysApi', request);
}

type GetSurveysApiRequest = {
    from: string; // YYYY-MM-DD,
    to: string; // YYYY-MM-DD
};

export type SurveysVm = {
    total: number;
    dismissed: number;
    withRating: number;
    rating: {
        ['1']: number;
        ['2']: number;
        ['3']: number;
        ['4']: number;
        ['5']: number;
    };
    averageRating: number;
    withTip: number;
    withTipPaymentStatusPaid: number;
    totalTip: string;
    totalTipPaymentStatusPaid: string;
    averageTip: string;
    averageTipPaymentStatusPaid: string;
    withAnswer: number;
    surveyAnswers: {
        FOOD_WAS_COLD: number;
        MISSING_OR_INCORRECT_ITEMS: number;
        FOOD_WAS_NOT_PREPARED: number;
        FOOD_ARRIVED_LATE: number;
        CONFUSED_PICK_UP_PLACE: number;
        BAD_SERVICE: number;
        PUNCTUALITY: number;
        APPLICATION: number;
        FOOD: number;
        PRESENTATION: number;
        SERVICE: number;
    };
    otherPositiveAnswers: Array<string>;
    otherNegativeAnswers: Array<string>;
    surveys: Array<SurveyVm>;
    restaurants: Array<RestaurantSurveyVm>;
};

export type SurveyVm = {
    surveyId: SurveyId;
    restaurantId: RestaurantId;
    restaurantName: string;
    customerId?: CustomerId;
    customerName: string;
    mobileNumber: string;
    rating?: number;
    tip?: string;
    surveyAnswers?: Array<SurveyAnswer>;
    otherAnswer?: string;
    unsolved?: boolean;
    createdAt: Date;
    answeredAt: Date;
    solvedAt?: Date;
};

export type RestaurantSurveyVm = {
    restaurantId: RestaurantId;
    restaurantName: string;
    total: number;
    dismissed: number;
    withRating: number;
    rating: {
        ['1']: number;
        ['2']: number;
        ['3']: number;
        ['4']: number;
        ['5']: number;
    };
    averageRating: number;
    totalTip: string;
    surveyAnswers: {
        FOOD_WAS_COLD: number;
        MISSING_OR_INCORRECT_ITEMS: number;
        FOOD_WAS_NOT_PREPARED: number;
        FOOD_ARRIVED_LATE: number;
        CONFUSED_PICK_UP_PLACE: number;
        BAD_SERVICE: number;
        PUNCTUALITY: number;
        APPLICATION: number;
        FOOD: number;
        PRESENTATION: number;
        SERVICE: number;
    };
    otherPositiveAnswers: Array<string>;
    otherNegativeAnswers: Array<string>;
};
