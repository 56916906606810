/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { app2 } from 'src/app2';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import type { ScheduledTasksVm } from 'src/scenes/letseatadmin/ScheduledTasksPage';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { createFilteredOnDownloadFunctional } from 'src/utils/mui-datatables/createFilteredOnDownloadFunctional';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

type Props = {
    title?: string;
    onClickRefresh?: any;
};

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

export default function ScheduledTasksTable({ title, onClickRefresh }: Props): React.ReactElement {
    const classes = useStyles();
    const table = useRef(null);
    const viewUser = useSelector((state) => state.authentication.viewUser);
    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState({ scheduledTasks: [] } as ScheduledTasksVm);
    const openCreateScheduledTaskDialog = useAction(app2.actions.openCreateScheduledTaskDialog);
    const openChangeScheduledTaskDialog = useAction(app2.actions.openChangeScheduledTaskDialog);

    useEffect(() => {
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.admin.scheduledTasks();
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        setVm(response.data);
    };

    const columns = [
        {
            name: 'scheduledTaskId',
            label: ' ',
            options: {
                display: 'excluded' as any,
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'name',
            label: translate('Name'),
            options: {
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'schedule',
            label: translate('Schedule'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'nextScheduledRunAt',
            label: translate('Next Scheduled Run'),
            options: {
                filter: true,
                searchable: false,
            },
        },
        {
            name: 'running',
            label: translate('Running'),
            options: {
                filter: true,
                searchable: false,
            },
        },
        {
            name: 'paused',
            label: translate('Paused'),
            options: {
                filter: true,
                searchable: false,
            },
        },
        {
            name: 'startedAt',
            label: translate('Started'),
            options: {
                filter: true,
                searchable: false,
            },
        },
        {
            name: 'endedAt',
            label: translate('Ended'),
            options: {
                filter: true,
                searchable: false,
            },
        },
        {
            name: 'createdAt',
            label: translate('Created'),
            options: {
                filter: true,
                searchable: false,
            },
        },
    ];

    return (
        <div style={{ position: 'relative' }}>
            <UpdatingContentProgress loading={loading} />
            <MUIDataTable
                // @ts-ignore
                ref={table}
                className={classes.table}
                title={title || translate('Scheduled Tasks')}
                data={vm.scheduledTasks.map((scheduledTask) => ({
                    scheduledTaskId: scheduledTask.scheduledTaskId,
                    name: scheduledTask.name ?? '',
                    // actions : scheduledTask.actions,
                    // targets : scheduledTask.targets,
                    schedule: scheduledTask.schedule,
                    nextScheduledRunAt: formatDateTimeStringReadable(scheduledTask.nextScheduledRunAt, TimeZones.AMERICA_MONTERREY),
                    running: scheduledTask.running ? 'Running' : '',
                    paused: scheduledTask.paused ? 'Paused' : '',
                    startedAt: formatDateTimeStringReadable(scheduledTask.startedAt, TimeZones.AMERICA_MONTERREY),
                    endedAt: formatDateTimeStringReadable(scheduledTask.endedAt, TimeZones.AMERICA_MONTERREY),
                    createdAt: formatDateTimeStringReadable(scheduledTask.createdAt, TimeZones.AMERICA_MONTERREY),
                }))}
                columns={columns}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    onRowClick: (
                        rowData: Array<string>,
                        rowMeta: {
                            dataIndex: number;
                            rowIndex: number;
                        }
                    ) => {
                        const scheduledTaskId = rowData[0];
                        openChangeScheduledTaskDialog({
                            scheduledTaskId,
                            onSuccess: () => load(),
                        });
                    },
                    // customToolbarSelect: (selectedRows, displayData) => {
                    //     return (
                    //         <CustomToolbarSelect>
                    //             <ChangeToolbarButton onClick={() => {
                    //                 // const selectedScheduledTaskIds = selectedRowsData.map(d => d[0]);
                    //                 // openChangeScheduledTasksDialog({
                    //                 //     scheduledTaskIds: selectedScheduledTaskIds,
                    //                 //     onChange: load,
                    //                 // });
                    //             }} />
                    //         </CustomToolbarSelect>
                    //     );
                    // },
                    filterType: 'multiselect',
                    rowsPerPage: 100,
                    customToolbar: () => {
                        return (
                            <>
                                <RefreshToolbarButton
                                    onClick={() => {
                                        load();
                                        onClickRefresh && onClickRefresh();
                                    }}
                                />
                                <CreateToolbarButton
                                    onClick={() => {
                                        openCreateScheduledTaskDialog({
                                            onSuccess: () => load(),
                                        });
                                    }}
                                />
                            </>
                        );
                    },
                    onDownload: createFilteredOnDownloadFunctional(table),
                }}
            />
        </div>
    );
}
