/**
 * @prettier
 */
import moment from 'moment';
import { translate } from 'src/i18n/translate';

export function validateLimitOfRangeDate(date: Date, limitDate: Date): ValidateLimitOfRangeDateResult | undefined {
    if (moment(date).isAfter(limitDate))
        return { showError: true, errorMessage: translate('The selected date cannot be greater than @limitDate', { limitDate: moment(limitDate).format('YYYY-MM-DD') }) };
}

type ValidateLimitOfRangeDateResult = {
    showError: boolean;
    errorMessage: string;
};
