/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { FilterState, FromToPaymentDateFilter } from 'src/components/input/FromToPaymentDateFilter';
import { RoutePaths } from 'src/constants/RoutePath';
import { DriverBilling } from 'src/scenes/letseatadmin/driverBilling/DriverBilling';
import { DriversBilling } from 'src/scenes/letseatadmin/driverBilling/DriversBilling';

export function DriverBillingPage(): React.ReactElement {
    const [filter, setFilter] = useState<FilterState>({
        from: moment().subtract(1, 'week').startOf('isoWeek').toDate(),
        to: moment().subtract(1, 'week').endOf('isoWeek').toDate(),
        paymentDate: moment().isoWeekday(2).startOf('day').toDate(), // tuesday
    });

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <FromToPaymentDateFilter filter={filter} onChangeFilter={setFilter} />
            </Grid>
            <Grid item xs={12}>
                <Switch>
                    <Route path={RoutePaths.ADMIN_DRIVER_BILLING_FOR_DRIVER} render={(props) => <DriverBilling filter={filter} driverId={props.match.params.driverId as any} />} />
                    <Route path={RoutePaths.ADMIN_DRIVER_BILLING} render={() => <DriversBilling filter={filter} />} />
                </Switch>
            </Grid>
        </Grid>
    );
}
