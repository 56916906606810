/**
 * @prettier
 */
export const CitiesNamesForSettings = {
    ACAPULCO: 'Acapulco',
    ACUNA: 'Acuna',
    AGUASCALIENTES: 'Aguascalientes',
    ALTAMIRA: 'Altamira',
    ATLACOMULCO: 'Atlacomulco',
    ATLIXCO_PUEBLO_MAGICO: 'Atlixco',
    BOGOTA: 'Bogota',
    CADEREYTA_DE_JIMENEZ: 'CadereytaDeJimenez',
    CAMPECHE: 'Campeche',
    CANCUN: 'Cancun',
    CD_VICTORIA: 'CiudadVictoria',
    CELAYA: 'Celaya',
    CHETUMAL: 'Chetumal',
    CHIHUAHUA: 'Chihuahua',
    CHILPANCINGO_DE_LOS_BRAVO: 'ChilpancingoDeLosBravo',
    CHIMALHUACAN: 'Chimalhuacan',
    CIUDAD_DEL_CARMEN: 'CiudadDelCarmen',
    CIUDAD_JUAREZ: 'CiudadJuarez',
    CIUDAD_OBREGON: 'CiudadObregon',
    COATZACOALCOS: 'Coatzacoalcos',
    COLIMA: 'Colima',
    COMITAN_DE_DOMINGUEZ: 'ComitanDeDominguez',
    CORDOBA: 'Cordoba',
    COZUMEL: 'Cozumel',
    CUAUHTEMOC: 'Cuauhtemoc',
    CUAUTLA: 'Cuautla',
    CUERNAVACA: 'Cuernavaca',
    CULIACAN: 'Culiacan',
    DELICIAS: 'Delicias',
    DOLORES_HIDALGO: 'DoloresHidalgo',
    DURANGO: 'Durango',
    ENSENADA: 'Ensenada',
    FRESNILLO: 'Fresnillo',
    GARCIA: 'Garcia',
    GUADALAJARA: 'Gdl',
    GUANAJUATO: 'Guanajuato',
    GUASAVE: 'Guasave',
    GUAYMAS: 'Guaymas',
    HERMOSILLO: 'Hermosillo',
    IRAPUATO: 'Irapuato',
    LA_PAZ: 'LaPaz',
    LA_PIEDAD: 'LaPiedad',
    LAZARO_CARDENAS: 'LazaroCardenas',
    LEON: 'Leon',
    LOS_CABOS: 'LosCabos',
    LOS_MOCHIS: 'LosMochis',
    MANZANILLO: 'Manzanillo',
    MATAMOROS: 'Matamoros',
    MAZATLAN: 'Mazatlan',
    MERIDA: 'Mer',
    MEXICALI: 'Mexicali',
    MEXICO_CITY: 'CdMx',
    MINATITLAN: 'Minatitlan',
    MONCLOVA: 'Monclova',
    MONTERREY: 'Mty',
    MORELIA: 'Morelia',
    NAVOJOA: 'Novojoa',
    NOGALES: 'Nogales',
    NUEVO_LAREDO: 'NuevoLaredo',
    OAXACA: 'Oaxaca',
    PACHUCA: 'Pachuca',
    PIEDRAS_NEGRAS: 'PiedrasNegras',
    PLAYA_DEL_CARMEN: 'PlayaDelCarmen',
    POZA_RICA: 'PozaRica',
    PROGRESO: 'Progreso',
    PUEBLA: 'Pue',
    PUERTO_VALLARTA: 'PuertoVallarta',
    QUERETARO: 'Qro',
    REYNOSA: 'Reynosa',
    ROSARITO: 'Rosarito',
    SALAMANCA: 'Salamanca',
    SALTILLO: 'Sal',
    SAN_CRISTOBAL_DE_LAS_CASAS: 'SanCristobalDeLasCasas',
    SAN_JOSE: 'SanJose',
    SAN_JOSE_DEL_CABO: 'SanJoseDelCabo',
    SAN_JUAN_DEL_RIO: 'SanJuanDelRio',
    SAN_LUIS_POTOSI: 'SanLuisPotosi',
    SAN_LUIS_RIO_COLORADO: 'SanLuisRioColorado',
    SAN_MIGUEL_DE_ALLENDE: 'SanMiguelAllende',
    SANTIAGO: 'Santiago',
    SILAO: 'Silao',
    STOCKHOLM: 'Sth',
    TAMPICO: 'Tampico',
    TAPACHULA: 'Tapachula',
    TEHUACAN: 'Tehuacan',
    TEPIC: 'Tepic',
    TEXCOCO: 'Texcoco',
    TEZIUTLAN_Y_CHIGNAUTLA: 'Teziutlan',
    TIJUANA: 'Tijuana',
    TLAXCALA: 'Tlaxcala',
    TOLUCA: 'Toluca',
    TORREON: 'Trc',
    TUXTLA_GUTIERREZ: 'TuxtlaGutierrez',
    URUAPAN: 'Uruapan',
    VALLE_DE_BRAVO: 'ValleDeBravo',
    VALLE_DE_CHALCO: 'ValleDeChalco',
    VALLE_DE_SANTIAGO: 'ValleDeSantiago',
    VERACRUZ: 'Veracruz',
    VILLAHERMOSA: 'Villahermosa',
    XALAPA: 'Xalapa',
    ZACATECAS: 'Zacatecas',
    ZAMORA: 'Zamora',
    UY_MONTEVIDEO: 'UY_MONTEVIDEO',
    UY_CANELONES: 'UY_CANELONES',
    UY_MALDONADO: 'UY_MALDONADO',
    UY_SALTO: 'UY_SALTO',
    UY_PAYSANDU: 'UY_PAYSANDU',
    UY_COLONIA: 'UY_COLONIA',
    UY_SANJOSE: 'UY_SANJOSE',
    UY_FLORIDA: 'UY_FLORIDA',
    AR_BUENOSAIRES: 'AR_BUENOSAIRES',
    AR_SANTEFE: 'AR_SANTEFE',
    AR_ROSARIO: 'AR_ROSARIO',
    AR_CORDOBA: 'AR_CORDOBA',
    AR_MENDOZA: 'AR_MENDOZA',
    AR_MISIONES: 'AR_MISIONES',
    AR_MARDELPLATA: 'AR_MARDELPLATA',
    AR_TUCUMAN: 'AR_TUCUMAN',
    AR_SALTA: 'AR_SALTA',
    AR_CORRIENTES: 'AR_CORRIENTES',
    BR_SAOPAULO: 'BR_SAOPAULO',
    BR_RIODEJANEIRO: 'BR_RIODEJANEIRO',
    BR_BRASILIA: 'BR_BRASILIA',
    BR_SALVADOR: 'BR_SALVADOR',
    BR_FORTALEZA: 'BR_FORTALEZA',
    BR_BELOHORIZONTE: 'BR_BELOHORIZONTE',
    BR_PORTOALEGRE: 'BR_PORTOALEGRE',
    BR_MANAUS: 'BR_MANAUS',
    BR_CURITIBA: 'BR_CURITIBA',
    BR_RECIFE: 'BR_RECIFE',
    BR_TORRES: 'BR_TORRES',
    PE_LIMA: 'PE_LIMA',
    PE_CUZCO: 'PE_CUZCO',
    PE_CALLAO: 'PE_CALLAO',
    PE_AREQUIPA: 'PE_AREQUIPA',
    PE_TRUJILLO: 'PE_TRUJILLO',
    PE_CHICLAYO: 'PE_CHICLAYO',
    PE_PIURA: 'PE_PIURA',
    PE_HUNCAYO: 'PE_HUNCAYO',
    CL_SANTIAGO: 'CL_SANTIAGO',
    CL_VALPARAISO: 'CL_VALPARAISO',
    CL_CONCEPCION: 'CL_CONCEPCION',
    CL_ANTOFAGASTA: 'CL_ANTOFAGASTA',
    CL_COQUIMBO: 'CL_COQUIMBO',
    CL_BIOBIO: 'CL_BIOBIO',
    PY_ASUNCION: 'PY_ASUNCION',
    PY_CIUDADDELESTE: 'PY_CIUDADDELESTE',
    PY_ENCARNACION: 'PY_ENCARNACION',
} as const;

export type CityNameForSetting = typeof CitiesNamesForSettings[keyof typeof CitiesNamesForSettings];
