/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { MenuItemSubModifierGroups } from 'src/components/orderCard/orderItems/MenuItemSubModifierGroups';
import type { ModifierGroupVm } from 'src/types/OrderVm';

export function MenuItemModifierGroups({ modifierGroups }: Props): React.ReactElement | null {
    const classes = useStyles();

    const modifiers = modifierGroups?.flatMap((modifierGroup: ModifierGroupVm) => modifierGroup.modifiers);

    if (!modifiers?.length || !modifierGroups) return null;

    const allModifiersHasQuantityEqualToOne = modifiers.every((modifier) => modifier.quantity === 1);

    return (
        <>
            {modifierGroups.map((modifierGroup) => {
                return (
                    <div key={modifierGroup.name} className={classes.modifierGroupContainer}>
                        {modifierGroup.showModifierGroupNameInCommand && <div className={classes.modifierGroupName}>{modifierGroup.name}</div>}
                        {modifierGroup.modifiers.map((modifier, index) => (
                            <div key={index} className={classes.modifier}>
                                ·{modifier.name} {allModifiersHasQuantityEqualToOne ? '' : 'x' + modifier.quantity}{' '}
                                {!!modifier.subModifierGroups && <MenuItemSubModifierGroups subModifierGroups={modifier.subModifierGroups} />}
                            </div>
                        ))}
                    </div>
                );
            })}
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    modifierGroupContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        color: theme.palette.text.secondary,
        fontWeight: 400,
    },
    modifierGroupName: {
        fontWeight: 600,
        paddingLeft: 10,
    },
    modifier: {
        paddingLeft: 12,
    },
}));

type Props = {
    modifierGroups?: Array<ModifierGroupVm>;
};
