/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesType } from 'src/constants/SalesTypes';
import type { CashRegisterPosBusinessDayId, RestaurantId } from 'src/types/Id';
import type { SalesReportVm } from 'src/types/OrdersAndReportsVm';

export async function getOrderSalesApi(request: GetOrderSalesApiRequest): ApiSauceResponse<SalesReportVm> {
    return pidedirectoReportsMethod('/getOrderSalesApi', request);
}

export type GetOrderSalesApiRequest = {
    cashRegisterPosBusinessDayId?: CashRegisterPosBusinessDayId;
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
    salesType: SalesType;
};
