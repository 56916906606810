/**
 * @prettier
 */
import { SubscriptionPaymentErrorType, SubscriptionPaymentErrorTypes } from 'src/constants/SubscriptionPaymentErrorType';
import { translate } from 'src/i18n/translate';

export function translateSubscriptionPaymentErrorType(value?: SubscriptionPaymentErrorType | null, ignored?: Array<SubscriptionPaymentErrorType>): string {
    if (!value) {
        return '';
    }
    if (ignored?.includes(value)) {
        return '';
    }
    switch (value) {
        case SubscriptionPaymentErrorTypes.NO_CARD: {
            return translate('Card not added');
        }
        case SubscriptionPaymentErrorTypes.PAYMENT_METHOD_SPEI: {
            return translate('Payment method is SPEI');
        }
        case SubscriptionPaymentErrorTypes.NO_NEXT_PAYMENT_DATE: {
            return translate('Missing next payment date');
        }
        case SubscriptionPaymentErrorTypes.NO_NEXT_PAYMENT_AMOUNT: {
            return translate('Missing next payment amount');
        }
        case SubscriptionPaymentErrorTypes.FUTURE_PAYMENT_DATE: {
            return translate('Does not have to be paid yet');
        }
        case SubscriptionPaymentErrorTypes.PAYMENT_FAILED: {
            return translate('Payment with card failed');
        }
        case SubscriptionPaymentErrorTypes.UNKNOWN: {
            return translate('Unknown problem, contact developer');
        }
        default: {
            return translate('Unknown problem, contact developer');
        }
    }
}
