/**
 * @prettier
 */
import type { PageContextType } from 'src/components/Page';
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';

export function createUserClickedTabLogEvent({ tabLabel, pageContext }: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_CLICKED_TAB,
        message: `tab ${tabLabel} clicked`,
        details: `${pageContext.stackTrace}/${normalizeUiStackTrace(`tab_${tabLabel}`)}`,
        data: {
            tabId: normalizeUiStackTrace(`tab_${tabLabel}`),
            pathId: `${pageContext.stackTrace}/${normalizeUiStackTrace(`tab_${tabLabel}`)}`,
        },
    });
}

type Params = {
    pageContext: PageContextType;
    tabLabel: string;
};
