/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BusinessVm } from 'src/types/BusinessVm';
import type { BusinessId } from 'src/types/Id';

export async function getBusinessApi(request: GetBusinessApiRequest): ApiSauceResponse<BusinessVm> {
    return letseatadminApiMethod('business/getBusinessApi', request);
}

type GetBusinessApiRequest = {
    businessId: BusinessId;
};
