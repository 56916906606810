/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuId } from 'src/types/Id';

export async function removeMenuWithLinkedMenuElementsApi(request: RemoveMenuWithLinkedMenuElementsApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menu/removeMenuWithLinkedMenuElementsApi', request);
}

export type RemoveMenuWithLinkedMenuElementsApiRequest = {
    menuId: MenuId;
};
