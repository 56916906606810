/**
 * @prettier
 */
import type { SupportContactVm } from 'src/api/letseatadmin/supportContact/getSupportContactApi';
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SupportContactId } from 'src/types/Id';

export async function changeSupportContactApi(request: ChangeSupportContactApiRequest): ApiSauceResponse<SupportContactVm> {
    return letseatadminApiMethod('supportContact/changeSupportContactApi', request);
}

export type ChangeSupportContactApiRequest = {
    supportContactId: SupportContactId;
    name?: string;
    phoneNumber: string;
    available: boolean;
};
