/**
 * @prettier
 */
import { App, Apps } from 'src/constants/App';
import type { OrderVm } from 'src/types/OrderVm';

export function isExternalOrder(order: OrderVm): boolean {
    const externalApps: Array<App> = [Apps.DIDI_FOOD, Apps.RAPPI, Apps.UBER_EATS, Apps.PEDIDOS_YA];
    return externalApps.includes(order.app!) || !!order.externalOrderId;
}
