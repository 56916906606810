/**
 * @prettier
 */
import { makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Loader } from '@pidedirecto/ui';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import * as React from 'react';
import { Page } from 'src/components/Page';
import { googleMapsConfig } from 'src/config/googleMapsConfig';
import { Cities } from 'src/constants/City';
import { translate } from 'src/i18n/translate';
import { DriverInfo } from 'src/scenes/letseatadmin/liveDrivers/DriverInfo';
import { DriverMarkers } from 'src/scenes/letseatadmin/liveDrivers/DriverMarkers';
import { FiltersAndResumeLiveMap } from 'src/scenes/letseatadmin/liveDrivers/FiltersAndResumeLiveMap';
import { RestaurantInfo } from 'src/scenes/letseatadmin/liveOrders/RestaurantInfo';
import { RestaurantMarkers } from 'src/scenes/letseatadmin/liveOrders/RestaurantMarkers';
import { getCenterOfCity } from 'src/utils/city/getCenterOfCity';
import { toLatLng } from 'src/utils/googlemaps/toLatLng';
import { useCurrentLocation } from 'src/utils/react/useCurrentLocation';
import { useSelector } from 'src/utils/react/useSelector';

export function LiveDriversPage(): React.ReactElement {
    const classes = useStyles();

    const { isLoaded, loadError } = useLoadScript(googleMapsConfig);
    const { location: currentLocation } = useCurrentLocation();

    const cities = useSelector((state) => state.app2.cities);

    if (!isLoaded || !currentLocation) {
        return (
            <div className={classes.loadingContainer}>
                <Loader size={40} loading={true} />
            </div>
        );
    }
    if (loadError) {
        return <Typography>{translate('Failed to load google maps, please refresh page.')}</Typography>;
    }

    return (
        <Page title={translate('Live Drivers')} className={classes.container}>
            <Grid container className={classes.mapResumeContainer}>
                <FiltersAndResumeLiveMap />

                {/* @ts-ignore */}
                <Grid item xs={12} md className={classes.mapContainer} order={{ xs: 1, sm: 2 }}>
                    {/* @ts-ignore */}
                    <GoogleMap
                        id='circle-example'
                        mapContainerStyle={mapContainerStyle}
                        zoom={13}
                        center={toLatLng(getCenterOfCity(cities?.[0] ?? Cities.MONTERREY))}
                        options={{
                            styles: mapStyles as any,
                            fullscreenControl: false,
                            mapTypeControl: false,
                            streetViewControl: false,
                            gestureHandling: 'cooperative',
                            minZoom: 12,
                        }}
                    >
                        <RestaurantMarkers />
                        <RestaurantInfo />
                        <DriverMarkers />
                        <DriverInfo />
                    </GoogleMap>
                </Grid>
            </Grid>
        </Page>
    );
}

const mapContainerStyle = {
    height: '100%',
    width: '100%',
    position: 'relative',
} as const;

const mapStyles = [
    {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
];

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
    },
    mapResumeContainer: {
        width: '100%',
        height: '100%',
    },
    mapContainer: {
        height: '85%',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '50vh',
        },
    },
    loadingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
}));
