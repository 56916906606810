/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { UserRoleId } from 'src/types/Id';
import type { UserRoleVm } from 'src/types/UserRoleVm';

export function getUserRoleApi(request: GetUserRoleApiRequest): ApiSauceResponse<UserRoleVm> {
    return letseatmanagerApiMethod('userRole/getUserRoleApi', request);
}

type GetUserRoleApiRequest = {
    userRoleId: UserRoleId;
};
