/**
 * @prettier
 */
import { useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { CreateOrderInvoiceDialog } from 'src/components/dialog/order/CreateOrderInvoiceDialog';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { isPedidosYaApp } from 'src/utils/app/isPedidosYaApp';
import { isUruguay } from 'src/utils/country/isUruguay';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isPaid } from 'src/utils/payment/isPaid';
import { useSelector } from 'src/utils/react/useSelector';
import { canRestaurantInvoice } from 'src/utils/restaurant/canRestaurantInvoice';

export function InvoiceOrderButton({ order, disabled, onSuccess }: Props): React.ReactElement | null {
    const [createOrderInvoiceDialogState, setCreateOrderInvoiceDialogState] = useState({ open: false });

    const restaurant = useSelector((state) => state.app.restaurant);
    const isPedidosYaAndOrderStatusNotCompleted = isPedidosYaApp(order.app) && !isCompletedOrder(order.orderStatus);

    const orderStatusForInvoiceUy = isUruguay(order.country) && isPaid(order.paymentStatus) && isAcceptedOrder(order.orderStatus);

    const openCreateOrderInvoiceDialog = () => {
        if ((!isCompletedOrder(order.orderStatus) || order.invoiced) && !orderStatusForInvoiceUy) return;
        setCreateOrderInvoiceDialogState({ open: true });
    };

    const closeCreateOrderInvoiceDialog = () => setCreateOrderInvoiceDialogState({ open: false });

    if (order.invoiced || !canRestaurantInvoice(restaurant) || isPedidosYaAndOrderStatusNotCompleted) return null;

    return (
        <div>
            <CreateOrderInvoiceDialog open={createOrderInvoiceDialogState.open} order={order} onClose={closeCreateOrderInvoiceDialog} onSuccess={onSuccess} />
            <Button secondary onClick={openCreateOrderInvoiceDialog} disabled={disabled}>
                {translate('Invoice order')}
            </Button>
        </div>
    );
}

type Props = {
    order: OrderVm;
    disabled?: boolean;
    onSuccess?: any;
};
