/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CashRegisterId, CashRegisterPosBusinessDayId, OrderId, PosBusinessDayId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export async function acceptOrderApi(request: AcceptOrderApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('order/acceptOrderApi', request);
}

export type AcceptOrderApiRequest = {
    orderId: OrderId;
    posBusinessDayId?: PosBusinessDayId;
    cashRegisterId?: CashRegisterId;
    cashRegisterPosBusinessDayId?: CashRegisterPosBusinessDayId;
};
