/**
 * @prettier
 */
import { useState } from 'react';
import { getPosBusinessDaySummaryApi } from 'src/api/letseatmanager/pos/restaurantPosBusinessDay/getPosBusinessDaySummaryApi';
import { ApiErrorResponse } from 'src/api/types/ApiSauceResponse';
import { RolePermissions } from 'src/constants/RolePermission';
import { findPosBusinessDaySummaryFromLocalStorage } from 'src/localStorage/findPosBusinessDaySummaryFromLocalStorage';
import { setPosBusinessDaySummaryInLocalStorage } from 'src/localStorage/setPosBusinessDaySummaryInLocalStorage';
import type { PosBusinessDaySummaryVm } from 'src/types/PosBusinessDaySummaryVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function useFetchPosBusinessDaySummary(): {
    fetchPosBusinessDaySummary: () => Promise<void>;
    posBusinessDaySummary: PosBusinessDaySummaryVm | undefined;
    loading: boolean;
} {
    const [userHasRolePermission] = useUserHasRolePermission();

    const [posBusinessDaySummary, setPosBusinessDaySummary] = useState<PosBusinessDaySummaryVm>();
    const [loading, setLoading] = useState(false);

    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);

    const hasOpenPersonalCashRegisterPermission = userHasRolePermission(RolePermissions.OPEN_PERSONAL_CASH_REGISTER);
    const hasManageCashRegisterPermission = userHasRolePermission(RolePermissions.MANAGE_CASH_REGISTERS);
    const hasReadOnlyPermissions = posMultipleCashRegistersEnabled && !hasManageCashRegisterPermission && !hasOpenPersonalCashRegisterPermission;
    const canFetchPosBusinessDaySummary = !loading || (posMultipleCashRegistersEnabled && (hasManageCashRegisterPermission || hasReadOnlyPermissions) && openedPosBusinessDay);

    const onFetchPosBusinessDayCashRegistersApiError = (response: ApiErrorResponse) => {
        if (response.problem === 'NETWORK_ERROR') return setPosBusinessDaySummary(findPosBusinessDaySummaryFromLocalStorage());
        return alertKnownErrorOrSomethingWentWrong(response);
    };

    const fetchPosBusinessDaySummary = async () => {
        if (!openedPosBusinessDay) {
            setPosBusinessDaySummary(undefined);
            return;
        }

        if (canFetchPosBusinessDaySummary) {
            setLoading(true);
            const response = await getPosBusinessDaySummaryApi({ posBusinessDayId: openedPosBusinessDay?.posBusinessDayId });
            setLoading(false);
            if (!response.ok) {
                onFetchPosBusinessDayCashRegistersApiError(response);
                return;
            }

            setPosBusinessDaySummary(response.data);
            setPosBusinessDaySummaryInLocalStorage(response.data);
        }
    };

    return { fetchPosBusinessDaySummary, posBusinessDaySummary, loading };
}
