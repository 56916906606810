/**
 * @prettier
 */
import { useRef, useState } from 'react';
import { pollPaymentTerminalPaymentApi } from 'src/api/letseatmanager/paymentTerminalPayment/pollPaymentTerminalPaymentApi';
import { refundPaymentTerminalPaymentApi } from 'src/api/letseatmanager/paymentTerminalPayment/refundPaymentTerminalPaymentApi';
import { app2 } from 'src/app2';
import { PaymentTerminalPaymentFailedReasons } from 'src/constants/PaymentTerminalPaymentFailedReason';
import { PaymentTerminalPaymentStatuses } from 'src/constants/PaymentTerminalPaymentStatus';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { useConfirmDialog } from 'src/services/dialog/useConfirmDialog';
import type { PaymentTerminalId, PaymentTerminalPaymentId } from 'src/types/Id';
import type { PaymentTerminalVm } from 'src/types/PaymentTerminalVm';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useTimeoutInterval } from 'src/utils/react/useTimeoutInterval';

export function useRefundPaymentTerminal(): Service {
    const useOcaPaymentTerminalEnabled = useSelector((state) => state.app.restaurant.useOcaPaymentTerminalEnabled);

    const refundWithPaymentTerminalDeprecatedService = useRefundWithPaymentTerminalService();

    if (!useOcaPaymentTerminalEnabled) {
        return {
            refundWithPaymentTerminal: async () => {
                console.log('Refunding a payment is only supported for oca payments');
                return {
                    refunded: false,
                };
            },
            cancelRefund: () => {},
            refunding: false,
        };
    }

    return refundWithPaymentTerminalDeprecatedService;
}

export function useRefundWithPaymentTerminalService(): Service {
    const paymentResponseResolve = useRef((result: RefundResult) => {});

    const confirmDialog = useConfirmDialog();

    const [paymentTerminalPaymentId, setPaymentTerminalPaymentId] = useState<PaymentTerminalPaymentId>();

    const refunding = useSelector((state) => state.pos.refunding);
    const deviceGroup = useSelector((state) => state.app.deviceGroup);
    const paymentTerminals = useSelector((state) => state.app.paymentTerminals);
    // const qpayPosTerminalDeviceId = useSelector((state) => state.app.qpayPosTerminalDeviceId);

    const setRefunding = useAction(posReducer.actions.setRefunding);
    const openSelectPaymentTerminalDialog = useAction(app2.actions.openSelectPaymentTerminalDialog);
    // const developerMode = useDeveloperMode();

    // const openMockPaymentTerminalPaymentResultDialog = useAction(posReducer.actions.openMockPaymentTerminalPaymentResultDialog);

    useTimeoutInterval(
        async () => {
            if (!paymentTerminalPaymentId) return;

            const response = await pollPaymentTerminalPaymentApi({ paymentTerminalPaymentId: paymentTerminalPaymentId });

            if (!response.ok) {
                console.log(`Failed silently, trying again in 5 seconds response`, response);
                return;
            }

            if (response.data.paymentTerminalPaymentStatus === PaymentTerminalPaymentStatuses.FAILED) {
                await onPaymentFailed(translate('Failed to do the refund.'));
                return;
            }

            if (response.data.paymentTerminalPaymentStatus === PaymentTerminalPaymentStatuses.PAID) {
                await onPaymentSuccess();
            }
        },
        2 * SECONDS,
        [paymentTerminalPaymentId]
    );

    const refundWithPaymentTerminal = ({
        paymentTerminalPaymentId,
        paymentTerminalId,
    }: {
        paymentTerminalPaymentId: PaymentTerminalPaymentId;
        paymentTerminalId?: PaymentTerminalId;
    }): Promise<RefundResult> => {
        if (paymentTerminalId) {
            return refundInPaymentTerminal({ paymentTerminalId, paymentTerminalPaymentId });
        }

        const paymentTerminalsInDeviceGroup = paymentTerminals.filter((paymentTerminal) => deviceGroup?.paymentTerminalIds?.includes(paymentTerminal.paymentTerminalId));

        if (paymentTerminalsInDeviceGroup.length > 1) {
            return selectPaymentTerminalAndRefund({
                header: translate('Select a terminal to use for the refund'),
                paymentTerminalPaymentId,
            });
        }

        const paymentTerminalIdFromDeviceGroup = paymentTerminalsInDeviceGroup[0]?.paymentTerminalId;
        return refundInPaymentTerminal({ paymentTerminalPaymentId, paymentTerminalId: paymentTerminalIdFromDeviceGroup });
    };

    const refundInPaymentTerminal = async ({
        paymentTerminalId,
        paymentTerminalPaymentId,
    }: {
        paymentTerminalId: PaymentTerminalId;
        paymentTerminalPaymentId: PaymentTerminalPaymentId;
    }): Promise<RefundResult> => {
        return new Promise(
            async (
                resolve: (
                    result:
                        | Promise<{
                              refunded: boolean;
                          }>
                        | {
                              refunded: boolean;
                          }
                ) => void
            ) => {
                paymentResponseResolve.current = resolve;

                if (!paymentTerminalId) {
                    await onPaymentFailed(translate('Terminal not configured'));
                    return;
                }

                // let mock: PaymentTerminalPaymentResultMock | void;
                // if (developerMode) {
                //     mock = await new Promise((resolve) => {
                //         openMockPaymentTerminalPaymentResultDialog({
                //             onSelectMock: async (mock: PaymentTerminalPaymentResultMock) => {
                //                 resolve(mock);
                //             },
                //             onCloseDialog: () => {
                //                 resolve(undefined);
                //             },
                //         });
                //     });
                //     if (!mock) {
                //         setRefunding(false);
                //         resolve({ refunded: false });
                //         return;
                //     }
                // }

                setRefunding(true);
                const response = await refundPaymentTerminalPaymentApi({
                    paymentTerminalPaymentId,
                    paymentTerminalId,
                });

                if (!response.ok) {
                    await onPaymentFailed(response.data?.message ? translate('Something went wrong @error', { error: response.data.message }) : translate('Something went wrong'));
                    return;
                }

                const paymentTerminalPaymentStatus = response.data?.paymentTerminalPaymentStatus;

                if (paymentTerminalPaymentStatus === PaymentTerminalPaymentStatuses.FAILED) {
                    const paymentTerminalPaymentFailedReason = response.data?.paymentTerminalPaymentFailedReason;

                    if (paymentTerminalPaymentFailedReason === PaymentTerminalPaymentFailedReasons.TERMINAL_BUSY) {
                        await onPaymentFailed(translate('Terminal busy, please wait a moment and try again'));
                        return;
                    }

                    if (paymentTerminalPaymentFailedReason === PaymentTerminalPaymentFailedReasons.TERMINAL_NOT_FOUND) {
                        await onPaymentFailed(translate('Terminal not found, check your configuration and try again'));
                        return;
                    }

                    // should not happen, in case it happens create a PaymentTerminalPaymentFailedReasons for it and handle with if case above
                    await onPaymentFailed(translate(response.data.message ? translate('Something went wrong @error', { error: response.data.message }) : translate('Something went wrong')));
                    return;
                }

                paymentResponseResolve.current = resolve;
                setPaymentTerminalPaymentId(response.data.paymentTerminalPaymentId);
            }
        );
    };

    const onPaymentFailed = async (message: string) => {
        setRefunding(false);
        setPaymentTerminalPaymentId(undefined);
        await confirmDialog({
            title: translate('Error'),
            content: message,
            buttonText: translate('Accept'),
            variant: 'error',
        });
        paymentResponseResolve.current({ refunded: false });
        paymentResponseResolve.current = (paymentResult: { refunded: boolean }) => {};
    };

    const onPaymentSuccess = async () => {
        setRefunding(false);
        setPaymentTerminalPaymentId(undefined);
        paymentResponseResolve.current({ refunded: true });
        paymentResponseResolve.current = (paymentResult: { refunded: boolean }) => {};
    };

    const selectPaymentTerminalAndRefund = async ({ header, paymentTerminalPaymentId }: SelectPaymentTerminalAndPayParams): Promise<RefundResult> => {
        return await new Promise((resolve) => {
            openSelectPaymentTerminalDialog({
                header,
                onSelectPaymentTerminal: async (paymentTerminal: PaymentTerminalVm) => {
                    const paymentResponse = await refundInPaymentTerminal({
                        paymentTerminalId: paymentTerminal.paymentTerminalId,
                        paymentTerminalPaymentId,
                    });
                    if (!paymentResponse.refunded) {
                        resolve(await selectPaymentTerminalAndRefund({ header, paymentTerminalPaymentId }));
                        return;
                    }

                    resolve(paymentResponse);
                },
                onCloseDialog: () => {
                    resolve({ refunded: false });
                },
            });
        });
    };

    const cancelRefund = () => {
        setPaymentTerminalPaymentId(undefined);
    };

    return { refundWithPaymentTerminal, cancelRefund, refunding };
}

type Service = {
    refundWithPaymentTerminal: RefundPaymentTerminalFunction;
    cancelRefund: any;
    refunding: boolean;
};

type RefundPaymentTerminalFunction = (arg1: {
    paymentTerminalId?: PaymentTerminalId; // If not provided device group will decide what paymentTerminal to use for payments,
    paymentTerminalPaymentId: PaymentTerminalPaymentId;
}) => Promise<RefundResult>;

type RefundResult = {
    refunded: boolean;
};

type SelectPaymentTerminalAndPayParams = {
    header?: string;
    paymentTerminalPaymentId: PaymentTerminalPaymentId;
};
