/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OwnFleetDriverStatus } from 'src/constants/OwnFleetDriverStatus';
import type { DriverId, RestaurantId } from 'src/types/Id';

export async function setOwnFleetDriverStatusApi(request: SetOwnFleetDriverStatusApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('ownFleet/setOwnFleetDriverStatusApi', request);
}

type SetOwnFleetDriverStatusApiRequest = {
    driverId: DriverId;
    restaurantId: RestaurantId;
    ownFleetDriverStatus: OwnFleetDriverStatus;
};
