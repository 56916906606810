/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { buyOrderTicketDemo } from 'src/scenes/letseatmanager/customizePrinters/buyOrderTicketPrint/data/buyOrderTicketDemo';
import { PrintBuyOrderTicketTestButton } from 'src/scenes/letseatmanager/customizePrinters/buyOrderTicketPrint/PrintBuyOrderTicketTestButton';
import { ReactComponentPrinter } from 'src/services/printer/printers/ReactComponentPrinter';
import { createBuyOrderTicketPrint } from 'src/services/printer/prints/createBuyOrderTicketPrint';
import { useSelector } from 'src/utils/react/useSelector';

export function BuyOrderTicketPrintExample(): React.ReactElement {
    const classes = useStyles();

    const restaurant = useSelector((state) => state.app.restaurant);

    return (
        <div className={classes.buyOrderTicketTab}>
            <section className={classes.header}>
                <div>
                    <h1 className={classes.title}>{translate('Example Print')}</h1>
                </div>
                <PrintBuyOrderTicketTestButton disabled={false} />
            </section>
            <section className={classes.main}>
                {/*<Form className={classes.form} form={form}>*/}
                {/*</Form>*/}
                {/*<Divider variant='middle' classes={{ vertical: classes.divider }} orientation={'vertical'} />*/}
                <div className={classes.ticketPreview}>
                    {ReactComponentPrinter.print(
                        createBuyOrderTicketPrint({
                            buyOrder: buyOrderTicketDemo,
                            restaurant,
                        })
                    )}
                </div>
            </section>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    buyOrderTicketTab: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: 80,
        [theme.breakpoints.down('md')]: {
            padding: '0 20px',
        },
    },
    ticketPreview: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },
    ticket: {
        maxWidth: 400,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px 25px',
        width: '90%',
        background: '#FFF',
        boxShadow: '-2px 4px 4px rgba(0,0,0,0.15), -4px 0px 20px rgba(0, 0, 0, 0.05)',
        color: 'black',
        fontSize: 12,
        marginTop: 40,
        fontFamily: theme.typography.regular,
        [theme.breakpoints.up('md')]: {
            fontSize: 14,
        },
    },
    title: {
        fontFamily: theme.typography.bold,
        color: '#616B79',
        fontSize: 20,
    },
    subtitle: {
        fontFamily: theme.typography.regular,
        color: '#616B79',
        fontSize: 16,
    },
    divider: {
        backgroundColor: '#06B7A2',
        width: 2,
        height: 'auto',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 20,
        },
    },
    main: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    form: {
        width: '100%',
    },
}));
