/**
 * @prettier
 */
import type { CashRegisterWithdrawReason } from 'src/constants/CashRegisterWithdrawReason';
import type { CashRegisterCustomReason, CashRegisterId, CashRegisterTransactionId, ManagerUserId } from 'src/types/Id';

export const KEY_NAME = 'posWithdrawsOffline';

export function getPosWithdrawsOfflineInLocalStorage(): Array<PosWithdrawOffline> {
    const item = window.localStorage.getItem('posWithdrawsOffline');
    return item ? JSON.parse(item) : [];
}

export type PosWithdrawOffline = {
    temporalCashRegisterTransactionId: CashRegisterTransactionId;
    cashRegisterId?: CashRegisterId;
    managerUserId?: ManagerUserId;
    amount: string;
    reason: CashRegisterWithdrawReason | CashRegisterCustomReason;
    comment?: string;
    createdAt?: Date;
};
