/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Input } from '@pidedirecto/ui';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { usePosCustomerActions, usePosCustomerStore } from 'src/modules/pos/customer/posCustomerStore';
import { isEmail } from 'src/utils/string/isEmail';

export function PosCustomerEmailInput(): React.ReactElement {
    const classes = useStyles();

    const email = usePosCustomerStore((state) => state.email.value);
    const emailError = usePosCustomerStore((state) => state.email.error);

    const setError = usePosCustomerActions((actions) => actions.setError);
    const setCustomerEmail = usePosCustomerActions((actions) => actions.setCustomerEmail);

    const validEmail = (email: any) => {
        if (isEmail(email)) return { showError: false, errorMessage: undefined };

        return { showError: true, errorMessage: translate('Invalid email') };
    };

    const onChangeEmail = (value: string) => {
        setCustomerEmail(value as any);
        const error = validEmail(value);
        setError('email', error.errorMessage);
    };

    return <Input classes={{ container: classes.input }} type={'email'} name='email' label={translate('Email')} onChange={onChangeEmail} value={email} error={!!emailError} />;
}

const useStyles = makeStyles((theme) => ({
    input: {
        width: '100%',
    },
}));
