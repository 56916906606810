/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions } from '@pidedirecto/ui';
import { Form, FormNextWeekDaysSelect, FormTimePicker } from '@pidedirecto/ui/form';
import { useForm, useNotification } from '@pidedirecto/ui/hooks';
import moment from 'moment';
import * as React from 'react';
import { PickupTimeTypes } from 'src/constants/PickupTimeType';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { useAction } from 'src/utils/react/useAction';

export function PlannedPosOrderDialog({ open, onClose }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;
    const notification = useNotification();

    const setPickupTimeType = useAction(posReducer.actions.setPickupTimeType);
    const setPickupTime = useAction(posReducer.actions.setPickupTime);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: FormType) => {
        const hours = Number(form.time.split(':')[0]);
        const minutes = Number(form.time.split(':')[1]);
        const pickupTime = moment(form.day).set({ hours, minutes }).toDate();

        if (moment(pickupTime).isBefore(new Date())) {
            return notification({ message: translate('Please, select a date starting from today.') });
        }

        setPickupTime(pickupTime);
        setPickupTimeType(PickupTimeTypes.PLANNED);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Schedule order')}>
            <Form form={form} onSubmit={onSubmit}>
                <div className={classes.container}>
                    <FormNextWeekDaysSelect
                        name='day'
                        label={translate('Day')}
                        required={{
                            value: true,
                            message: translate('This field is required'),
                        }}
                    />
                    <FormTimePicker
                        name='time'
                        label={translate('Hour')}
                        hoursAsValue
                        required={{
                            value: true,
                            message: translate('This field is required'),
                        }}
                    />
                </div>
                <DialogActions>
                    <Button variant='secondary' onClick={onClose}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit'>{translate('Accept')}</Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
}));

type Props = {
    open: boolean;
    onClose: () => void;
};

type FormType = {
    day: Date;
    time: string;
};
