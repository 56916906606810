/**
 * @prettier
 */
import type { PromoCodeVm } from 'src/api/letseatadmin/promoCode/getPromoCodeApi';
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { Country } from 'src/constants/Country';
import type { DiscountType } from 'src/constants/DiscountType';
import type { OrderType } from 'src/constants/OrderType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { PromoType } from 'src/constants/PromoType';
import type { RewardType } from 'src/constants/RewardType';
import type { CompanyId, CustomerId, RestaurantId } from 'src/types/Id';

export async function createPromoCodeApi(request: CreatePromoCodeApiRequest): ApiSauceResponse<PromoCodeVm> {
    return letseatadminApiMethod('promoCode/createPromoCodeApi', request);
}

export type CreatePromoCodeApiRequest = {
    code: string;
    description?: string;
    promoType: PromoType;
    rewardType: RewardType;
    discount: string;
    excludeDeliveryCost?: boolean;
    freeDelivery?: boolean;
    appOnly?: boolean;
    webOnly?: boolean;
    discountType: DiscountType;
    countries?: Array<Country>;
    restaurantIds?: Array<RestaurantId>;
    customerIds?: Array<CustomerId>;
    companyIds?: Array<CompanyId>;
    companyNames?: Array<string>;
    orderTypes?: Array<OrderType>;
    paymentMethods?: Array<PaymentMethod>;
    requireAtLeastOneCreditCard?: boolean;
    minOrderAmount?: string;
    maxDiscountAmount?: string;
    restaurantPaidPercentage?: string;
    maxUsage?: number;
    startsAt: Date;
    endsAt: Date;
};
