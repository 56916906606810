/**
 * @prettier
 */
import { Button } from '@pidedirecto/ui';
import { useState } from 'react';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { translate } from 'src/i18n/translate';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import { SentryService } from 'src/services/SentryService';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isUruguay } from 'src/utils/country/isUruguay';
import { isDeliveredDelivery } from 'src/utils/delivery/isDeliveredDelivery';
import { isPickedUpDelivery } from 'src/utils/delivery/isPickedUpDelivery';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isRoomServiceOrder } from 'src/utils/order/isRoomServiceOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function CompleteOrderButton({ order, onSuccess, classes: classesProp }: Props): React.ReactElement | null {
    const [loading, setLoading] = useState(false);
    const [printOrderTicket] = usePrintOrderTicket();

    const markReadyForPickupButtonEnabled = useSelector((state) => state.app.restaurant.markReadyForPickupButtonEnabled);
    const externalDelivery = useSelector((state) => state.app.restaurant.externalDelivery);

    const externalDeliveryOrdersHasBeenPickedUp = externalDelivery && isDeliveryOrder(order.orderType) && isPickedUpDelivery(order.deliveryStatus) && !isDeliveredDelivery(order.deliveryStatus);
    const takeAwayOrderHasBeenPickedUp = (!!order.markedReadyForPickupAt && markReadyForPickupButtonEnabled) || !markReadyForPickupButtonEnabled;
    const shouldCompleteOrderForTakeAwayOrder = isTakeAwayOrder(order.orderType) && takeAwayOrderHasBeenPickedUp && isAcceptedOrder(order.orderStatus);
    const isReadyForPickup = (isEatHereOrder(order.orderType) || isRoomServiceOrder(order.orderType)) && order.markedReadyForPickupAt && isAcceptedOrder(order.orderStatus);
    const autoInvoiceOrdersEnabled = useSelector((state) => state.app.restaurant.autoInvoiceOrdersEnabled);

    const shouldRenderButton = externalDeliveryOrdersHasBeenPickedUp || shouldCompleteOrderForTakeAwayOrder || isReadyForPickup;

    const handleClick = async (event: any) => {
        event.stopPropagation();
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.currentOrders.completeOrder({
            orderId: order.orderId,
        });
        setLoading(false);
        if (!response.ok) {
            SentryService.logError('Failed to complete order', { completeOrderRequest: { orderId: order.orderId }, response });
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        SentryService.logInfoBreadcrumb('Successfully completed order', { completeOrderRequest: { orderId: order.orderId } });

        const completedOrder = response.data;
        if (!order.invoiced && completedOrder.invoiced && isUruguay(completedOrder.country) && autoInvoiceOrdersEnabled) {
            await printOrderTicket(completedOrder);
        }

        onSuccess?.();
    };

    if (!shouldRenderButton) return null;

    return (
        <Button onClick={handleClick} disabled={loading} classes={{ button: classesProp?.button }}>
            {translate('Complete order')}
        </Button>
    );
}

type Props = {
    order: OrderVm;
    onSuccess?: any;
    classes?: {
        button?: string;
    };
};
