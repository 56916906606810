/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormAutocompleteMultiple } from 'src/components/form/FormAutocompleteMultiple';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormTextField } from 'src/components/form/FormTextField';
import { history } from 'src/config/history';
import { Days } from 'src/constants/Day';
import { translate } from 'src/i18n/translate';
import type { CompanyId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { sortDays } from 'src/utils/day/sortDays';
import { deleteUrlQueryParameter } from 'src/utils/history/deleteUrlQueryParameter';
import { hasUrlQueryParameter } from 'src/utils/history/hasUrlQueryParameter';
import { combine, greaterThanZero, integer, number, required } from 'src/utils/Validator';

export function CreateBenefitPlanDialog({ companyId, onBenefitPlanCreated }: Props): React.ReactElement {
    const classes = useStyles();
    const location = useLocation();
    const form = useForm();

    const createBenefitPlan: boolean = hasUrlQueryParameter(CREATE_BENEFIT_PLAN);

    const distributeOncePerWeek = form.watch('distributeOncePerWeek');
    const days = form.watch('days');

    const [addBenefitPlanDialogState, setAddBenefitPlanDialogState] = useState({ open: false });

    const dayOptions = Object.keys(Days)?.map((day: string) => ({
        value: Days[day as keyof typeof Days],
        label: translate(`Days.${Days[day as keyof typeof Days]}`),
    }));

    useEffect(() => {
        if (createBenefitPlan) {
            setAddBenefitPlanDialogState({ open: true });
        }

        return () => {
            setAddBenefitPlanDialogState({ open: false });
        };
    }, [createBenefitPlan]);

    const handleCloseAddBenefitPlanDialog = () => {
        if (form.formState?.isSubmitting) return;
        setAddBenefitPlanDialogState({ open: false });

        if (history?.length <= 2) {
            history.replace({
                path: location.pathname,
                search: deleteUrlQueryParameter(CREATE_BENEFIT_PLAN),
            });
            return;
        }
        history.goBack();
    };

    const onSubmit = async (form: any) => {
        const request: CreateBenefitPlanRequest = {
            companyId: companyId,
            name: form.name,
            credits: form.credits,
            distributeOncePerWeek: form.distributeOncePerWeek,
            restrictedToDelivery: form.restrictedToDelivery,
            oneProductPerDay: form.oneProductPerDay,
            meals: parseInt(form.meals),
            days: sortDays(form.days).join(','),
        };
        const response = await letseatmanagerApiDeprecated.admin.createBenefitPlan(request);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onBenefitPlanCreated();
        handleCloseAddBenefitPlanDialog();
    };

    return (
        <Dialog open={addBenefitPlanDialogState.open} onClose={handleCloseAddBenefitPlanDialog} title={translate('Create BenefitPlan')} classes={{ dialog: classes.dialog }}>
            <Form onSubmit={onSubmit} form={form} className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name='name' label={translate('Name')} disabled={form.formState?.isSubmitting} required validate={required} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField name='credits' label={translate('Credits')} disabled={form.formState?.isSubmitting} required validate={combine(required, number)} />
                    </Grid>

                    <Grid item xs={12}>
                        <FormCheckbox label={translate('Restrict Credits to Delivery')} name={'restrictedToDelivery'} />
                    </Grid>

                    <Grid item xs={12}>
                        <FormCheckbox label={translate('Receive all weekly credits on Saturday')} name={'distributeOncePerWeek'} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox
                            label={translate('Allow only one product per day')}
                            name={'oneProductPerDay'}
                            helperText={translate('Activating this will restrict you to only one product per day in lets eat kiosk')}
                        />
                    </Grid>
                    {!distributeOncePerWeek && (
                        <Grid item xs={12}>
                            <FormTextField
                                name='meals'
                                label={translate('Meals')}
                                helperText={`${translate('Max number of meals per week')}`}
                                placeholder={`${days?.length ?? 0}`}
                                disabled={form.formState?.isSubmitting}
                                validate={combine(integer, greaterThanZero)}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={10}>
                        <FormAutocompleteMultiple name='days' label={translate('Days')} required options={dayOptions} />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button classes={{ button: classes.button }} secondary onClick={handleCloseAddBenefitPlanDialog} disabled={form.formState?.isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button classes={{ button: classes.button }} type='submit' disabled={form.formState?.isSubmitting}>
                        {translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

export const CREATE_BENEFIT_PLAN = 'create-benefit-plan';

const useStyles = makeStyles((theme) => ({
    dialog: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: '60vw',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    button: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '30%',
        },
    },
}));

type CreateBenefitPlanRequest = {
    companyId: CompanyId;
    name: string;
    credits: string;
    distributeOncePerWeek?: boolean;
    restrictedToDelivery?: boolean;
    oneProductPerDay?: boolean;
    meals?: number;
    days: string;
    paused?: boolean | null | undefined;
};

type Props = {
    companyId: CompanyId;
    onBenefitPlanCreated: any;
};
