/**
 * @prettier
 */
const styles = (theme: any): any => ({
    container: {
        position: 'relative',
    },
    formContent: {
        padding: theme.spacing(3),
    },
    linearProgress: {
        position: 'absolute',
        width: '100%',
        left: 0,
    },
    fullWidth: {
        width: '100%',
    },
    openingHourLabel: {
        paddingTop: '24px',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    hideScrollbar: {
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
});

export default styles;
