/**
 * @prettier
 */
import * as React from 'react';
import { getIntegrationsLogEventsApi } from 'src/api/letseatmanager/logEvent/getIntegrationsLogEventsApi';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { toShortId } from 'src/utils/uuid/toShortId';

export function IntegrationsLogEvents(): React.ReactElement {
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, integrationLogEvents] = useLoadApi(getIntegrationsLogEventsApi, { restaurantId }, { initialValue: [] });

    const columns = [
        {
            name: 'logEventId',
            label: translate('Event Id'),
            options: {
                filter: false,
            },
        },
        {
            name: 'logEventLevel',
            label: translate('Log Level'),
            options: {
                filter: false,
            },
        },
        {
            name: 'logEventType',
            label: translate('Log Type'),
            options: {
                filter: false,
            },
        },
        {
            name: 'message',
            label: translate('Message'),
            options: {
                filter: false,
            },
        },
        {
            name: 'restaurantId',
            label: translate('Restaurant Id'),
            options: {
                filter: false,
            },
        },
        {
            name: 'createdAt',
            label: translate('Created At'),
            options: {
                filter: false,
            },
        },
    ];

    return (
        <div style={{ position: 'relative' }}>
            <Table
                loading={loading}
                options={{ selectableRows: 'none' }}
                data={integrationLogEvents?.map((logEvent: any) => ({
                    logEventId: toShortId(logEvent.logEventId),
                    logEventLevel: logEvent.logEventLevel,
                    logEventType: logEvent.logEventType,
                    message: logEvent.message,
                    restaurantId: toShortId(logEvent.restaurantId),
                    createdAt: formatDateTimeString(logEvent.createdAt),
                }))}
                columns={columns}
            />
        </div>
    );
}
