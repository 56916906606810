/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { DateSelectDialog } from 'src/components/dialog/DateSelectDialog';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Tabs } from 'src/components/Tabs';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { InventoryAdjustmentsReport } from 'src/scenes/letseatmanager/inventoryAdjustmentsReport/InventoryAdjustmentsReport';
import { InventoryConsumptionsReport } from 'src/scenes/letseatmanager/inventoryConsumptionsReport/InventoryConsumptionsReport';
import { InventoryKardexReport } from 'src/scenes/letseatmanager/inventoryKardexReport/InventoryKardexReport';
import { InventorySuppliersReport } from 'src/scenes/letseatmanager/inventorySuppliersReport/InventorySuppliersReport';
import { InventorySupplyHistoryReport } from 'src/scenes/letseatmanager/inventorySupplyHistoryReport/InventorySupplyHistoryReport';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function InventoryReportPage(): React.ReactElement | null {
    const classes = useStyles();
    const history = useHistory();

    const [userHasRolePermission] = useUserHasRolePermission();

    const inventoryEnabled = useSelector((state) => state.app.restaurant.inventoryEnabled);

    useEffect(() => {
        if (!inventoryEnabled) history.goBack();
    }, [inventoryEnabled]);

    const tabs = [
        {
            value: '1',
            label: translate('Kardex'),
            hidden: !inventoryEnabled,
            rolePermission: RolePermissions.KARDEX_REPORT,
        },
        {
            value: '2',
            label: translate('Inventory Consumption'),
            hidden: !inventoryEnabled,
            rolePermission: RolePermissions.INVENTORY_CONSUMPTION_REPORT,
        },
        {
            value: '3',
            label: translate('Inventory Adjustments'),
            hidden: !inventoryEnabled,
            rolePermission: RolePermissions.INVENTORY_ADJUSTMENTS_REPORT,
        },
        {
            value: '4',
            label: translate('Suppliers Report'),
            hidden: !inventoryEnabled,
            rolePermission: RolePermissions.INVENTORY_SUPPLIERS_REPORT,
        },
        {
            value: '5',
            label: translate('Supplies History'),
            hidden: !inventoryEnabled,
            rolePermission: RolePermissions.SUPPLIES_HISTORY_REPORT,
        },
    ];

    if (!inventoryEnabled) return null;

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.REPORTS_PAGE} title={translate('POS reports')} className={classes.container}>
            <DateSelectDialog />
            <Tabs tabs={tabs}>
                {inventoryEnabled && userHasRolePermission(RolePermissions.KARDEX_REPORT) && <InventoryKardexReport />}
                {inventoryEnabled && userHasRolePermission(RolePermissions.INVENTORY_CONSUMPTION_REPORT) && <InventoryConsumptionsReport />}
                {inventoryEnabled && userHasRolePermission(RolePermissions.INVENTORY_ADJUSTMENTS_REPORT) && <InventoryAdjustmentsReport />}
                {inventoryEnabled && userHasRolePermission(RolePermissions.INVENTORY_SUPPLIERS_REPORT) && <InventorySuppliersReport />}
                {inventoryEnabled && userHasRolePermission(RolePermissions.SUPPLIES_HISTORY_REPORT) && <InventorySupplyHistoryReport />}
            </Tabs>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
    filterContainer: {
        paddingTop: 20,
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
