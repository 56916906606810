/**
 * @prettier
 */
export const VehicleTypes = {
    CAR: 'CAR',
    MOTORCYCLE: 'MOTORCYCLE',
    BICYCLE: 'BICYCLE',
    PEDESTRIAN: 'PEDESTRIAN',
} as const;

export type VehicleType = typeof VehicleTypes[keyof typeof VehicleTypes];
