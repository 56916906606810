/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button, Chip, Dialog, DialogActions, DialogContent } from '@pidedirecto/ui';
import { Form, FormTextField } from '@pidedirecto/ui/form';
import { useConfirmDialog, useForm, useLoadApi } from '@pidedirecto/ui/hooks';
import { CrossIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { createMenuCategoryFamilyApi } from 'src/api/letseatmanager/menuCategoryFamily/createMenuCategoryFamilyApi';
import { findMenuCategoryFamiliesApi } from 'src/api/letseatmanager/menuCategoryFamily/findMenuCategoryFamiliesApi';
import { removeMenuCategoryFamilyApi } from 'src/api/letseatmanager/menuCategoryFamily/removeMenuCategoryFamilyApi';
import { translate } from 'src/i18n/translate';
import { MenuCategoryFamilyVm } from 'src/types/MenuCategoryFamilyVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function ManageMenuCategoryFamiliesDialog({ open, onClose }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const confirmDialog = useConfirmDialog();
    const { reset } = form;

    const [submitting, setSubmitting] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, menuCategoryFamilies, load] = useLoadApi(
        findMenuCategoryFamiliesApi,
        { restaurantId },
        { dependencies: [restaurantId], requiredValues: [restaurantId, open || null], initialValue: [] }
    );

    const closeDialog = () => {
        if (loading || submitting) return;
        onClose();
    };

    const createMenuCategoryFamily = async (form: FormType) => {
        setSubmitting(true);
        const response = await createMenuCategoryFamilyApi({
            restaurantId,
            name: form.name,
        });
        setSubmitting(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        load();
        reset({ name: '' });
    };

    const removeMenuCategoryFamily = async (menuCategoryFamily: MenuCategoryFamilyVm) => {
        const shouldRemoveMenuCategoryFamily = await confirmDialog({
            acceptButtonText: translate('Accept'),
            cancelButtonText: translate('Cancel'),
            title: translate('Remove menu family'),
            content: translate('Are you sure you want to remove menu family @menuCategoryFamilyName?', { menuCategoryFamilyName: menuCategoryFamily.name }),
        });
        if (!shouldRemoveMenuCategoryFamily) return;

        setSubmitting(true);
        const response = await removeMenuCategoryFamilyApi({
            menuCategoryFamilyId: menuCategoryFamily.menuCategoryFamilyId,
        });
        setSubmitting(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        load();
    };

    return (
        <Dialog open={open} onClose={closeDialog} loading={loading || submitting} title={translate('Manage families')}>
            <Form form={form} onSubmit={createMenuCategoryFamily}>
                <DialogContent>
                    <div className={classes.inputContainer}>
                        <FormTextField
                            name='name'
                            label={translate('Name')}
                            required={{
                                value: true,
                                message: translate('This field is required'),
                            }}
                        />
                        <Button type='submit' classes={{ button: classes.button }}>
                            {translate('Add')}
                        </Button>
                    </div>
                    {!!menuCategoryFamilies.length && (
                        <div className={classes.chipsContainer}>
                            {menuCategoryFamilies.map((menuCategoryFamily) => (
                                <Chip onClick={() => removeMenuCategoryFamily(menuCategoryFamily)} variant='secondary' classes={{ chip: classes.chip }}>
                                    {menuCategoryFamily.name}
                                    <CrossIcon size={10} title={translate('Remove @menuCategoryFamilyName', { menuCategoryFamilyName: menuCategoryFamily.name })} />
                                </Chip>
                            ))}
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant='secondary' onClick={closeDialog}>
                        {translate('Cancel')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    chipsContainer: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 6,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    chip: {
        gap: 4,
    },
}));

type FormType = {
    name: string;
};

type Props = {
    open: boolean;
    onClose: () => void;
};
