/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { RestaurantCustomerId } from 'src/types/Id';
import { RestaurantCustomerVm } from 'src/types/RestaurantCustomerVm';

export async function getRestaurantCustomerApi(request: GetRestaurantCustomerApiRequest): ApiSauceResponse<RestaurantCustomerVm> {
    return letseatadminApiMethod('restaurantCustomer/getRestaurantCustomerApi', request);
}

export type GetRestaurantCustomerApiRequest = {
    restaurantCustomerId: RestaurantCustomerId;
};
