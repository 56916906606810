/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getOwnFleetReportApi } from 'src/api/letseatmanager/ownFleet/getOwnFleetReportApi';
import { DateSelectDialog } from 'src/components/dialog/DateSelectDialog';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Table } from 'src/components/Table';
import { Text } from 'src/components/Text';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { OwnFleetDriverDetailsDialog } from 'src/scenes/letseatmanager/ownFleetDriver/OwnFleetDriverDetailsDialog';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { OwnFleetDriverReportVm } from 'src/types/OwnFleetDriverReportVm';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function OwnFleetDriversReportPage(): React.ReactElement | null {
    const classes = useStyles();
    const history = useHistory();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [ownFleetDriverDetailsDialogState, setOwnFleetDriverDetailsDialogState] = useState<{ open: boolean; ownFleetDriver: OwnFleetDriverReportVm | undefined }>({
        open: false,
        ownFleetDriver: undefined,
    });

    const ownFleetEnabled = useSelector((state) => state.app.restaurant?.ownFleetEnabled);
    const externalOwnFleetEnabled = useSelector((state) => state.app.restaurant?.externalOwnFleetEnabled);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const period = useSelector((state) => state.app2.filterReportsState.period);

    const getDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, ownFleetDriversReport, refresh] = useLoadApi(
        getOwnFleetReportApi,
        {
            restaurantIds: brandOpened ? restaurantIds : [restaurantId],
            fromDate: moment(getDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(getDateToRestaurantTimeZone(period.to)).toISOString(),
        },
        {
            dependencies: [period, brandOpened, restaurantId],
            requiredValues: [restaurantId, period],
        }
    );

    const ownFleetDriversReportColumns = [
        {
            name: 'name',
            label: translate('User'),
            options: {
                filter: true,
                searchable: true,
                customBodyRender: (driver: OwnFleetDriverReportVm) => (
                    <Text
                        onClick={() => {
                            setOwnFleetDriverDetailsDialogState({ open: true, ownFleetDriver: driver });
                        }}
                        link
                    >
                        {driver.name}
                    </Text>
                ),
            },
        },
        {
            name: 'mobileNumber',
            label: translate('Phone Number'),
            options: {
                filter: true,
            },
        },
        {
            name: 'orders',
            label: translate('Assigned'),
            options: {
                filter: false,
            },
        },
        {
            name: 'total',
            label: translate('Total'),
            options: {
                filter: false,
            },
        },
        {
            name: 'completedOrders',
            label: translate('Completed'),
            options: {
                filter: false,
            },
        },
        {
            name: 'totalDrivingDistance',
            label: translate('Kms'),
            options: {
                filter: false,
            },
        },
        {
            name: 'totalDrivingDuration',
            label: translate('Hours'),
            options: {
                filter: false,
            },
        },
        {
            name: 'totalDeliveryCost',
            label: translate('Costs'),
            options: {
                filter: false,
            },
        },
    ];

    useEffect(() => {
        if (!ownFleetEnabled && !externalOwnFleetEnabled) history.goBack();
    }, [ownFleetEnabled, externalOwnFleetEnabled]);

    if (!ownFleetEnabled && !externalOwnFleetEnabled) return null;

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.REPORTS_PAGE} title={translate('Own Drivers')} className={classes.container}>
            <DateSelectDialog />
            <OwnFleetDriverDetailsDialog
                ownFleetDriver={ownFleetDriverDetailsDialogState.ownFleetDriver}
                open={ownFleetDriverDetailsDialogState.open}
                onClose={() => {
                    setOwnFleetDriverDetailsDialogState({ open: false, ownFleetDriver: undefined });
                }}
            />
            <FilterReports />
            <Table
                loading={loading}
                data={ownFleetDriversReport?.map((driver: OwnFleetDriverReportVm) => ({
                    name: driver,
                    mobileNumber: driver.mobileNumber,
                    orders: parseInt(driver.orders),
                    total: formatAsCurrencyNumber(driver.total),
                    completedOrders: parseInt(driver.completedOrders),
                    totalDrivingDistance: driver.totalDrivingDistance,
                    totalDrivingDuration: driver.totalDrivingDuration,
                    totalDeliveryCost: formatAsCurrencyNumber(driver.totalDeliveryCost),
                }))}
                columns={ownFleetDriversReportColumns}
                options={{
                    tableBodyMaxHeight: '100%',
                    responsive: 'standard',
                    selectableRows: 'none',
                    filter: false,
                    rowsPerPage: 100,
                    viewColumns: false,
                    customSearch: (searchQuery, currentRow) => {
                        let matchFound = false;
                        currentRow.forEach((columnValue) => {
                            const isObjectValue = typeof columnValue === 'object' && columnValue !== null;
                            if (isObjectValue) {
                                matchFound = Object.values(columnValue).some((value) => {
                                    if (typeof value === 'string') {
                                        return value.includes(searchQuery);
                                    }
                                    return false;
                                });
                            }

                            if (columnValue.toString().indexOf(searchQuery) >= 0 && !isObjectValue) {
                                matchFound = true;
                            }
                        });

                        return matchFound;
                    },
                    onDownload: (buildHead, buildBody, columns, data) => {
                        const customRowData = data.map((row: { data: Array<any> }) => {
                            const driver = row.data[0];
                            row.data[0] = driver.name;
                            return row;
                        });

                        return '\uFEFF' + buildHead(columns) + buildBody(customRowData);
                    },
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={refresh} />
                        </>
                    ),
                }}
            />
            {!ownFleetDriversReport?.length && !loading && <ReportSectionEmptyState />}
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
}));
