/**
 * @prettier
 */
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { openUberEatsOrderTrackingUrlInNewTab } from 'src/utils/window/openUberEatsOrderTrackingUrlInNewTab';

export function TrackUberOrderButton({ order, disabled }: Props): React.ReactElement | null {
    if (!order.uberEatsOrderTrackingUrl) return null;

    return (
        <Button secondary onClick={() => openUberEatsOrderTrackingUrlInNewTab(order.uberEatsOrderTrackingUrl)} disabled={disabled}>
            {translate('Track Uber Order')}
        </Button>
    );
}

type Props = {
    order: OrderVm;
    disabled?: boolean;
};
