/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { Stepper } from 'src/components/Stepper';
import type { CreatePrinterStep } from 'src/constants/CreatePrinterStep';
import { PrinterBrands } from 'src/constants/PrinterBrand';
import { PrinterPaperSizes } from 'src/constants/PrinterPaperSize';
import { translate } from 'src/i18n/translate';
import { CircleCloseIcon } from 'src/icons/CircleCloseIcon';
import { PrinterWizardContext } from 'src/providers/PrinterWizardProvider';
import { ChannelsSelection } from 'src/scenes/letseatmanager/device/printers/createPrinterWizardSteps/ChannelsSelection';
import { CreatePrinterWizardConfirmation } from 'src/scenes/letseatmanager/device/printers/createPrinterWizardSteps/CreatePrinterWizardConfirmation';
import { DeviceName } from 'src/scenes/letseatmanager/device/printers/createPrinterWizardSteps/DeviceName';
import { DownloadDrivers } from 'src/scenes/letseatmanager/device/printers/createPrinterWizardSteps/DownloadDrivers';
import { IpAddress } from 'src/scenes/letseatmanager/device/printers/createPrinterWizardSteps/IpAddress';
import { Port } from 'src/scenes/letseatmanager/device/printers/createPrinterWizardSteps/Port';
import { PrinterBrandSelection } from 'src/scenes/letseatmanager/device/printers/createPrinterWizardSteps/PrinterBrandSelection';
import { PrinterContentSelection } from 'src/scenes/letseatmanager/device/printers/createPrinterWizardSteps/PrinterContentSelection';
import { PrinterPaperSizeSelection } from 'src/scenes/letseatmanager/device/printers/createPrinterWizardSteps/PrinterPaperSizeSelection';
import { PrinterTypeSelection } from 'src/scenes/letseatmanager/device/printers/createPrinterWizardSteps/PrinterTypeSelection';
import { SerialNumber } from 'src/scenes/letseatmanager/device/printers/createPrinterWizardSteps/SerialNumber';
import { WebUsbPrinter } from 'src/services/printer/printers/WebUsbPrinter';
import { isPaymentTerminalPrinter } from 'src/utils/printer/isPaymentTerminalPrinter';
import { isUsbPrinter } from 'src/utils/printer/isUsbPrinter';
import { useSelector } from 'src/utils/react/useSelector';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';
import { isPaymentTerminalDevice } from 'src/utils/reactNative/isPaymentTerminalDevice';

export function CreatePrinterWizard({ openManagePrintersSection }: Props): React.ReactElement {
    const classes = useStyles();
    const isSmallScreen = useIsSmallScreen();

    const { resetFormValues, createPrinterSteps, activeCreatePrinterStep, formValues, setCreatePrinterWizardFormValue } = useContext(PrinterWizardContext);

    const qpayPosTerminalDeviceId = useSelector((state) => state.app.qpayPosTerminalDeviceId);

    useEffect(() => {
        if (isUsbPrinter(formValues.printerType) && formValues.printerBrand) {
            tryToConnectWithUsbPrinter();
        }
    }, [formValues.printerType, formValues.printerBrand]);

    useEffect(() => {
        if (isPaymentTerminalPrinter(formValues.printerType)) {
            resetFormValues((prevFormData: any) => ({
                ...prevFormData,
                printerPaperSize: PrinterPaperSizes['58_MM'],
                printerBrand: PrinterBrands.OTHER,
                serialNumber: isPaymentTerminalDevice() && qpayPosTerminalDeviceId ? qpayPosTerminalDeviceId : '',
            }));
        }
    }, [formValues.printerType]);

    const tryToConnectWithUsbPrinter = async () => {
        if (!formValues.printerBrand) return;
        try {
            const serialNumber = await WebUsbPrinter.getPrinterSerialNumber(formValues.printerBrand);
            setCreatePrinterWizardFormValue('serialNumber', serialNumber);
        } catch (e: any) {
            console.log(e);
        }
    };

    const getCreatePrinterStepFormatted = (step: CreatePrinterStep, idx: number) => ({
        label: translate(`CreatePrinterSteps.${step}`),
        value: idx,
    });

    return (
        <div className={classes.container}>
            {!isSmallScreen && (
                <div className={classes.stepperContainer}>
                    <Stepper steps={createPrinterSteps?.map(getCreatePrinterStepFormatted)} activeStep={createPrinterSteps.findIndex((currentStep) => activeCreatePrinterStep === currentStep)} />
                </div>
            )}
            <div className={classes.wizardContainer}>
                <div className={classes.titleContainer}>
                    <h1 className={classes.title}>{translate('Create a printer')}</h1>
                    <button onClick={openManagePrintersSection} className={classes.closeButton}>
                        <CircleCloseIcon color={'#8A95A5'} size={30} />
                    </button>
                </div>
                <PrinterTypeSelection />
                <DeviceName />
                <IpAddress />
                <Port />
                <SerialNumber />
                <PrinterBrandSelection />
                <PrinterPaperSizeSelection />
                <ChannelsSelection />
                <DownloadDrivers />
                <PrinterContentSelection />
                <CreatePrinterWizardConfirmation openManagePrintersSection={openManagePrintersSection} />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: 60,
        minHeight: '80vh',
        alignItems: 'flex-start',
        [theme.breakpoints.up('md')]: {
            width: '70%',
            margin: '60px auto',
        },
    },
    stepperContainer: {
        minWidth: 220,
    },
    wizardContainer: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            minWidth: 420,
        },
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        fontFamily: theme.typography.bold,
        color: '#0D3037',
        fontSize: 24,
        margin: 0,
        marginBottom: 18,
    },
    closeButton: {
        border: 0,
        display: 'grid',
        placeItems: 'center',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        padding: 0,
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: '#D9D9D9',
        },
    },
}));

type Props = {
    openManagePrintersSection: any;
};
