/**
 * @prettier
 */
import * as React from 'react';

export function PrinterOutlineIcon({ color, title }: Props): React.ReactElement {
    return (
        <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                d='M4.6 10.5242C4.4 10.5492 4.2 10.5758 4 10.6042M4.6 10.5242C7.52188 10.1576 10.4781 10.1576 13.4 10.5242M4.6 10.5242L4.28333 14M13.4 10.5242C13.6 10.5492 13.8 10.5758 14 10.6042M13.4 10.5242L13.7167 14L13.9075 16.1025C13.9193 16.2322 13.904 16.363 13.8624 16.4864C13.8209 16.6099 13.7541 16.7233 13.6663 16.8195C13.5785 16.9157 13.4716 16.9926 13.3524 17.0452C13.2332 17.0978 13.1044 17.125 12.9742 17.125H5.02583C4.47417 17.125 4.0425 16.6517 4.0925 16.1025L4.28333 14M4.28333 14H3.375C2.87772 14 2.40081 13.8025 2.04917 13.4508C1.69754 13.0992 1.5 12.6223 1.5 12.125V6.88C1.5 5.97917 2.14 5.20083 3.03083 5.0675C3.56079 4.98823 4.09229 4.9196 4.625 4.86167M13.715 14H14.6242C14.8705 14.0001 15.1144 13.9517 15.342 13.8575C15.5695 13.7633 15.7763 13.6252 15.9505 13.4511C16.1247 13.277 16.2629 13.0703 16.3572 12.8427C16.4515 12.6152 16.5 12.3713 16.5 12.125V6.88C16.5 5.97917 15.86 5.20083 14.9692 5.0675C14.4392 4.98823 13.9077 4.9196 13.375 4.86167M13.375 4.86167C10.4669 4.54525 7.53308 4.54525 4.625 4.86167M13.375 4.86167V1.8125C13.375 1.295 12.955 0.875 12.4375 0.875H5.5625C5.045 0.875 4.625 1.295 4.625 1.8125V4.86167M14 7.75H14.0067V7.75667H14V7.75ZM11.5 7.75H11.5067V7.75667H11.5V7.75Z'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            fill={color ?? '#63D6B3'}
        </svg>
    );
}

type Props = {
    color?: string;
    title?: string;
};
