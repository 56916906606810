/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeSubscriptionHistoryStartAndServiceDatesApi } from 'src/api/letseatadmin/subscriptionHistory/changeSubscriptionHistoryStartAndServiceDatesApi';
import { getSubscriptonHistoryApi, SubscriptionHistoryVm } from 'src/api/letseatadmin/subscriptionHistory/getSubscriptionHistoryApi';
import { Form } from 'src/components/form/Form';
import { FormDatePicker } from 'src/components/form/FormDatePicker';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { SubscriptionHistoryId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { requireValue } from 'src/utils/require/requireValue';

export function ChangeSubscriptionHistoryDialog({ open, subscriptionHistoryId, onClose, onChangeSubscriptionHistory }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;
    const [loading, setLoading] = useState(false);
    const [subscriptionHistory, setSubscriptionHistory] = useState<SubscriptionHistoryVm | undefined>(undefined);

    useEffect(() => {
        if (open) load();
    }, [open]);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const handleExited = () => {
        setLoading(false);
    };

    const load = async () => {
        setLoading(true);
        const response = await getSubscriptonHistoryApi({ subscriptionHistoryId: requireValue(subscriptionHistoryId) });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            onClose();
            return;
        }
        const subscriptionHistory = response.data;
        setSubscriptionHistory(subscriptionHistory);
        form.reset(subscriptionHistory);
        setLoading(false);
    };

    const onSubmit = async (form: any) => {
        const response = await changeSubscriptionHistoryStartAndServiceDatesApi({
            subscriptionHistoryId: requireValue(subscriptionHistoryId),
            startServiceDate: form.startServiceDate,
            endServiceDate: form.endServiceDate,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onChangeSubscriptionHistory();
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} onExited={handleExited}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Change Subscription History').toUpperCase()}</DialogTitle>
                <DialogContent>
                    <FormContent loading={loading} subscriptionHistory={subscriptionHistory ?? undefined} />
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={loading || isSubmitting}>
                        {isSubmitting ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

function FormContent({ loading }: { loading: boolean; subscriptionHistory?: any }) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <FormDatePicker name='startServiceDate' label={translate('Start Service Date')} disabled={loading} />
            </Grid>
            <Grid item xs={12}>
                <FormDatePicker name='endServiceDate' label={translate('End Service Date')} disabled={loading} />
            </Grid>
        </Grid>
    );
}

type Props = {
    open: boolean;
    subscriptionHistoryId: SubscriptionHistoryId | undefined;
    onClose: any;
    onChangeSubscriptionHistory: any;
};
