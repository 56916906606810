/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CardPriority } from 'src/constants/CardPriority';
import type { SubscriptionId } from 'src/types/Id';

export async function addSubscriptionCardApi(request: AddSubscriptionCardApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('subscription/addSubscriptionCardApi', request);
}

export type AddSubscriptionCardApiRequest = {
    subscriptionId: SubscriptionId;
    cardToken: string;
    stripeCardToken?: string;
    conektaCardToken?: string;
    mercadoPagoCardToken?: string;
    cardPriority: CardPriority;
};
