/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { GeoJsonPolygonDeprecated } from 'src/types/GeoJsonPolygonDeprecated';
import type { DeliveryZoneId, RestaurantId } from 'src/types/Id';

export async function changeDeliveryZoneApi(request: ChangeDeliveryZoneApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('deliveryZone/changeDeliveryZoneApi', request);
}

export type ChangeDeliveryZoneApiRequest = {
    restaurantId: RestaurantId;
    deliveryZoneId: DeliveryZoneId;
    name?: string;
    price: string;
    color: string;
    enabled: boolean;
    area: GeoJsonPolygonDeprecated;
};
