/**
 * @prettier
 */
import * as React from 'react';

export function DinersIcon({ title }: Props): React.ReactElement {
    return (
        <svg width='41' height='40' viewBox='0 0 41 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <circle cx='20.667' cy='20' r='20' fill='#F9DFF6' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.167 16.5C18.167 15.837 18.4304 15.2011 18.8992 14.7322C19.3681 14.2634 20.004 14 20.667 14C21.33 14 21.9659 14.2634 22.4348 14.7322C22.9036 15.2011 23.167 15.837 23.167 16.5C23.167 17.163 22.9036 17.7989 22.4348 18.2678C21.9659 18.7366 21.33 19 20.667 19C20.004 19 19.3681 18.7366 18.8992 18.2678C18.4304 17.7989 18.167 17.163 18.167 16.5ZM23.167 18.5C23.167 17.9696 23.3777 17.4609 23.7528 17.0858C24.1279 16.7107 24.6366 16.5 25.167 16.5C25.6974 16.5 26.2061 16.7107 26.5812 17.0858C26.9563 17.4609 27.167 17.9696 27.167 18.5C27.167 19.0304 26.9563 19.5391 26.5812 19.9142C26.2061 20.2893 25.6974 20.5 25.167 20.5C24.6366 20.5 24.1279 20.2893 23.7528 19.9142C23.3777 19.5391 23.167 19.0304 23.167 18.5ZM14.167 18.5C14.167 17.9696 14.3777 17.4609 14.7528 17.0858C15.1279 16.7107 15.6366 16.5 16.167 16.5C16.6974 16.5 17.2061 16.7107 17.5812 17.0858C17.9563 17.4609 18.167 17.9696 18.167 18.5C18.167 19.0304 17.9563 19.5391 17.5812 19.9142C17.2061 20.2893 16.6974 20.5 16.167 20.5C15.6366 20.5 15.1279 20.2893 14.7528 19.9142C14.3777 19.5391 14.167 19.0304 14.167 18.5ZM16.8737 22.078C17.2803 21.4407 17.841 20.9162 18.5041 20.553C19.1671 20.1898 19.911 19.9996 20.667 20C21.3002 19.9994 21.9264 20.1326 22.5046 20.3908C23.0828 20.649 23.5999 21.0264 24.0221 21.4982C24.4444 21.9701 24.7622 22.5259 24.9548 23.1291C25.1473 23.7323 25.2104 24.3694 25.1397 24.9987C25.131 25.0768 25.1041 25.1519 25.061 25.2177C25.0179 25.2835 24.9599 25.3382 24.8917 25.3773C23.606 26.115 22.1492 26.5021 20.667 26.5C19.1303 26.5 17.687 26.092 16.4423 25.3773C16.3741 25.3382 16.3161 25.2835 16.273 25.2177C16.2299 25.1519 16.203 25.0768 16.1943 24.9987C16.0824 23.9764 16.322 22.9465 16.8737 22.0787V22.078Z'
                fill='#F54EE4'
            />
            <path
                d='M16.055 21.5027C15.3975 22.5176 15.0912 23.7202 15.183 24.926C14.7827 24.8653 14.389 24.7671 14.007 24.6327L13.9304 24.606C13.862 24.5817 13.8021 24.5382 13.7579 24.4805C13.7138 24.4229 13.6873 24.3537 13.6817 24.2814L13.675 24.2007C13.6481 23.866 13.6889 23.5293 13.7949 23.2107C13.9009 22.8921 14.0701 22.5982 14.2922 22.3464C14.5143 22.0946 14.7849 21.8901 15.0878 21.7451C15.3906 21.6002 15.7196 21.5177 16.055 21.5027ZM26.151 24.926C26.2429 23.7202 25.9365 22.5176 25.279 21.5027C25.6145 21.5177 25.9435 21.6002 26.2463 21.7451C26.5492 21.8901 26.8198 22.0946 27.0419 22.3464C27.264 22.5982 27.4331 22.8921 27.5392 23.2107C27.6452 23.5293 27.686 23.866 27.659 24.2007L27.6524 24.2814C27.6466 24.3536 27.6201 24.4226 27.576 24.4801C27.5318 24.5376 27.472 24.5811 27.4037 24.6054L27.327 24.632C26.949 24.7654 26.5564 24.8647 26.151 24.926Z'
                fill='#F54EE4'
            />
        </svg>
    );
}

type Props = {
    title?: string;
};
