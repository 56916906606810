/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { Tooltip } from 'src/components/Tooltip';
import { classNames } from 'src/utils/react/classNames';

export function ResumeOrderInfoRow({ title, resumeInfo, classes, tooltipText, showTooltip }: Props): React.ReactElement {
    const defaultClasses = useStyles();

    return (
        <Grid item xs={12} className={defaultClasses.infoContainer}>
            <span className={classNames(defaultClasses.text, classes?.text)}>
                {title}
                {showTooltip && <Tooltip text={tooltipText} />}
            </span>
            <span className={classNames(defaultClasses.text, classes?.text)}>{resumeInfo}</span>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        display: 'flex',
        gap: 6,
    },
}));

type Props = {
    title: string;
    resumeInfo: number | string;
    classes?: {
        text?: string;
    };
    tooltipText?: string;
    showTooltip?: boolean;
};
