/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { translateUnits } from 'src/utils/translate/translateUnits';

export function SupplyHistoryTransactionsTable({ supplyTransactions, loading }: Props): React.ReactElement {
    return (
        <Table
            title={undefined}
            loading={loading}
            data={supplyTransactions?.map((supplyTransaction) => {
                return {
                    reason: supplyTransaction.reason,
                    differential: translateUnits(supplyTransaction.differential, supplyTransaction.unitOfMeasurement),
                    quantityAfterTransaction: translateUnits(supplyTransaction.quantityAfterTransaction, supplyTransaction.unitOfMeasurement),
                    createdAt: supplyTransaction.createdAt,
                };
            })}
            columns={[
                {
                    name: 'reason',
                    label: translate('Reason'),
                    options: {
                        filter: false,
                        customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{translate(`InventoryTransactionReasons.${value}`)}</div>,
                    },
                },
                {
                    name: 'differential',
                    label: translate('Differential'),
                    options: {
                        filter: true,
                        customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                    },
                },
                {
                    name: 'quantityAfterTransaction',
                    label: translate('Quantity After Transaction'),
                    options: {
                        filter: true,
                        customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                    },
                },
                {
                    name: 'createdAt',
                    label: translate('Created At'),
                    options: {
                        filter: true,
                        customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                    },
                },
            ]}
            options={{
                responsive: 'standard',
                tableBodyMaxHeight: '500px',
                selectableRows: 'none',
                filterType: 'checkbox',
                rowsPerPage: 100,
            }}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        '& .MuiInputBase-root': {
            fontFamily: `${theme.typography.regular} !important`,
            fontSize: 14,
            backgroundColor: theme.palette.secondary.light,
            borderRadius: 5,
            padding: '5px 0 5px 15px',
        },
        '& .MuiInputBase-root::before': {
            display: 'none',
        },
        '& .MuiInputBase-root::after': {
            display: 'none',
        },
        '& .MuiFormControl-root:first-child': {
            marginRight: 10,
        },
        '& label': {
            fontFamily: theme.typography.regular,
            fontSize: 15,
            color: theme.palette.primary.main,
        },
    },
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContext: 'space-between',
    },
}));

type Props = {
    supplyTransactions: Array<{
        reason: string;
        differential: number;
        quantityAfterTransaction: number;
        createdAt: string;
        unitOfMeasurement: string;
    }>;
    loading: boolean;
};
