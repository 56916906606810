/**
 * @prettier
 */

import { usePosCartModifiersActions } from 'src/services/pos/posModifiersCart/posCartModifiersStore';
import type { CartModifierVm } from 'src/types/CartModifierVm';

export function useSetPosCartModifiers(): SetPosCartModifiers {
    const setPosCartModifiers = usePosCartModifiersActions((actions) => actions.setPosCartModifiers);

    return setPosCartModifiers;
}

type SetPosCartModifiers = (posCartModifiers: { [key: string]: Array<CartModifierVm> }) => void;
