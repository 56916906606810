/**
 * @prettier
 */
import { useEffect } from 'react';
import { tryToSavePosDepositsCreatedOffline } from 'src/services/cashRegister/tryToSavePosDepositsCreatedOffline';
import { tryToSavePosWithdrawsCreatedOffline } from 'src/services/cashRegister/tryToSavePosWithdrawsCreatedOffline';
import { tryToSavePosOrdersCreatedOffline } from 'src/services/order/tryToSavePosOrdersCreatedOffline';
import { useSelector } from 'src/utils/react/useSelector';

export function useSaveOfflinePosActions(): null {
    const online = useSelector((state) => state.app2.online);

    useEffect(() => {
        if (online) {
            tryToSavePosOrdersCreatedOffline();
            tryToSavePosDepositsCreatedOffline();
            tryToSavePosWithdrawsCreatedOffline();
        }
    }, [online]);

    return null;
}
