/**
 * @prettier
 */
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CallIcon from '@material-ui/icons/Call';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function MobileToolbarButton({ tooltip, onClick, disabled = false }: Props): React.ReactElement {
    const viewUser = useSelector((state) => state.authentication.viewUser);

    return (
        <Tooltip title={tooltip || translate('Change Mobile Number')}>
            <IconButton onClick={onClick} disabled={viewUser || disabled}>
                <CallIcon />
            </IconButton>
        </Tooltip>
    );
}

type Props = {
    tooltip?: string;
    onClick: any;
    disabled?: boolean;
};
