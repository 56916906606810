/**
 * @prettier
 */
import { DeliveryStatuses } from 'src/constants/DeliveryStatus';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { OrderTypes } from 'src/constants/OrderType';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';

export function getOrderStatus(order: OrderVm): string {
    const isAssigningCourier = order.orderType === OrderTypes.DELIVERY_ORDER && !order.driverId && !order.uberEatsOrderTrackingUrl && !order.uberDaasOrderTrackingUrl && !order.externalDelivery;
    const isAssignedCourier = order.orderType === OrderTypes.DELIVERY_ORDER && order.deliveryStatus === DeliveryStatuses.ACCEPTED;
    const isOrderPickedUp = order.orderType === OrderTypes.DELIVERY_ORDER && order.deliveryStatus === DeliveryStatuses.PICKED_UP;
    const isAssigningExternalCourier = order.orderType === OrderTypes.DELIVERY_ORDER && order.externalDelivery && order.deliveryStatus !== DeliveryStatuses.PICKED_UP;

    switch (order.orderStatus) {
        case OrderStatuses.NEW:
            return translate('New');
        case OrderStatuses.CANCELLED:
            return translate('Cancelled');
        case OrderStatuses.REJECTED:
            return translate('Rejected') + translate('. Reason: ') + (order.orderRejectReason ? translate(`OrderRejectReasons.${order.orderRejectReason ?? ''}`) : 'N/A');
        case OrderStatuses.ACCEPTED:
            if (order.arrivedAtClientAt) return translate('Arrived at client');
            if (isAssigningCourier || isAssigningExternalCourier) return translate('Assigning courier');
            if (isAssignedCourier) return translate('Courier assigned');
            if (isOrderPickedUp) return translate('Picked up');
            return translate('Accepted');
        case OrderStatuses.COMPLETED:
            return translate('Completed');
        case OrderStatuses.COMPLETE:
            return translate('Completed');
        default:
            return '';
    }
}
