/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeModifierInModifierGroupApi } from 'src/api/letseatmanager/modifierGroup/changeModifierInModifierGroupApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormModifierTypeSelect } from 'src/components/form/FormOptionItemTypeSelect';
import { translate } from 'src/i18n/translate';
import type { ModifierGroupId } from 'src/types/Id';
import type { ModifierVm } from 'src/types/ModifierVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function ChangeModifierInModifierGroupDialog({ open, modifierGroupId, modifier, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        form.reset({
            price: modifier?.price,
            modifierType: modifier.modifierType,
        });
    }, [modifier]);

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        const response = await changeModifierInModifierGroupApi({
            modifierGroupId,
            menuItemId: modifier.menuItemId,
            price: form.price,
            modifierType: form.modifierType,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
        onClose();
    };

    return (
        <Dialog open={open} title={translate('Change modifier')}>
            <Form form={form} onSubmit={onSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormCurrencyNumberStringField name='price' label={translate('Price')} helperText={translate('If you leave it empty will be used the price of the product')} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormModifierTypeSelect name='modifierType' label={translate('Modifier type')} required />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button secondary onClick={handleClose}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {translate('Change')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    modifierGroupId: ModifierGroupId;
    modifier: ModifierVm;
    onClose: any;
    onSuccess?: any;
};
