/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BrandId } from 'src/types/Id';

export async function getBrandOptionsApi(): ApiSauceResponse<Array<BrandOptionVm>> {
    return letseatadminApiMethod('options/getBrandOptionsApi');
}

export type BrandOptionVm = {
    label: string;
    value: BrandId;
};
