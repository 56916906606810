/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { AutoCompleteInputProp, Variant } from 'src/components/form/FormTypes';
import { getError } from 'src/components/form/utils/getError';
import { translate } from 'src/i18n/translate';

export function FormRadioGroup({ name, label, helperText, defaultValue = null, disabled, row, children }: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const classes = useStyles();

    return (
        <>
            <FormControl component='fieldset'>
                <FormLabel component='legend' classes={{ root: classes.label }}>
                    {translate(label)}
                </FormLabel>
                <Controller
                    control={control}
                    name={name}
                    render={({ onChange, onBlur, value, name }) => (
                        <RadioGroup
                            value={value}
                            /* @ts-ignore */
                            disabled
                            row
                            onBlur={onBlur}
                            onChange={(e) => {
                                onChange(e);
                            }}
                        >
                            {children}
                        </RadioGroup>
                    )}
                    defaultValue={defaultValue}
                />
                {(!!getError(errors, name) || !!helperText) && <FormHelperText error={!!getError(errors, name)}>{helperText}</FormHelperText>}
            </FormControl>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    label: {
        fontFamily: theme.typography.medium,
        color: '#232933',
        fontSize: 15,
    },
}));

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    autoComplete?: AutoCompleteInputProp;
    upperCase?: boolean;
    disabled?: boolean;
    row?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    minLength?:
        | number
        | {
              value: number;
              message: string;
          };
    maxLength?:
        | number
        | {
              value: number;
              message: string;
          };
    pattern?:
        | RegExp
        | {
              value: RegExp;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    inputProps?: any;
    children?: any;
};
