/**
 * @prettier
 */
import { history } from 'src/config/history';

export function hasUrlQueryParameter(name: string, location?: any): boolean {
    if (!location) {
        location = history.location;
    }
    return new URLSearchParams(location.search).has(name);
}
