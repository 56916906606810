/**
 * @prettier
 */
import moment from 'moment-timezone';
import { Apps } from 'src/constants/App';
import { PrinterBrands } from 'src/constants/PrinterBrand';
import { PrinterContents } from 'src/constants/PrinterContent';
import { PrinterPaperSizes } from 'src/constants/PrinterPaperSize';
import { PrinterTypes } from 'src/constants/PrinterType';
import { TimeZones } from 'src/constants/TimeZone';
import type { PrinterVm } from 'src/types/PrinterVm';
import { fixedUuid } from 'src/utils/uuid/fixedUuid';

export const printerInfoDemo: PrinterVm = {
    printerId: fixedUuid('printerId'),
    restaurantId: fixedUuid('restaurantId'),
    deviceName: 'Hot Kitchen Printer',
    externalPrinterId: 'Epson TM-T88V',
    serialNumber: '123123',
    ipAddress: '192.168.1.104',
    port: '80',
    printerType: PrinterTypes.WIFI,
    printerBrand: PrinterBrands.EPSON,
    printerPaperSize: PrinterPaperSizes['80_MM'], // TODO support media queries to support both printer paper sizes
    printerContent: PrinterContents.TICKET,
    disabled: true,
    channels: [Apps.PIDEDIRECTO, Apps.UBER_EATS, Apps.RAPPI, Apps.DIDI_FOOD],
    createdAt: moment.tz('2023-05-01T12:32', TimeZones.AMERICA_MONTERREY).toDate(),
    modifiedAt: moment.tz('2023-05-03T16:43', TimeZones.AMERICA_MONTERREY).toDate(),
};
