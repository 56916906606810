/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
    AverageDeliveryTimeByDeliveryMethod,
    AverageDeliveryTimeByDeliveryMethodAndDay,
    getDeliveryRestaurantStatisticsApi,
    MaximumDeliveryTimeByDeliveryMethod,
    SalesByDeliveryMethod,
} from 'src/api/letseatmanager/restaurantDashboard/getDeliveryRestaurantStatisticsApi';
import { Form } from 'src/components/form/Form';
import { FormSelect } from 'src/components/form/FormSelect';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { AverageTimeGraph } from 'src/scenes/letseatmanager/restaurantDashboard/deliveryReport/AverageTimeGraph';
import { FilterMasterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterMasterReports';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { RestaurantId } from 'src/types/Id';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function DeliveryReport(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const { control } = form;

    const [deliveryChannels, setDeliveryChannels] = useState<Array<DeliveryChannelsType>>([]);
    const [salesByDeliveryMethod, setSalesByDeliveryMethod] = useState<Array<SalesByDeliveryMethod>>([]);
    const [averageTimeData, setAverageTimeData] = useState<Array<AverageDeliveryTimeByDeliveryMethodAndDay>>([]);
    const [averageDeliveryTimeByDeliveryMethod, setAverageDeliveryTimeByDeliveryMethod] = useState<Array<AverageDeliveryTimeByDeliveryMethod>>([]);
    const [maximumDeliveryTimeByDeliveryMethod, setMaximumDeliveryTimeByDeliveryMethod] = useState<Array<MaximumDeliveryTimeByDeliveryMethod>>([]);
    const [hasInfoFound, setHasInfoFound] = useState(false);
    const [showAsMaster, setShowAsMaster] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const currentRestaurantId = useSelector((state) => state.app2.filterReportsState.currentRestaurantId);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const currentChannel = useWatch({ name: 'restaurantChannels', control });

    const [loading, reports] = useLoadApi(
        getDeliveryRestaurantStatisticsApi,
        {
            report: showAsMaster ? 'asBrand' : 'perRestaurant',
            restaurantIds: brandOpened ? restaurantIds : [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).toISOString(),
            salesType: salesType,
        },
        {
            dependencies: [period, showAsMaster, brandOpened, restaurantId, salesType],
        }
    );

    useEffect(() => {
        if (reports) {
            if (showAsMaster || !brandOpened) return handleUpdateDeliveryReports(reports[0]?.restaurantId);

            handleUpdateDeliveryReports(currentRestaurantId ?? reports[0].restaurantId);
        }
    }, [brandOpened, reports, currentRestaurantId, currentChannel]);

    const handleUpdateDeliveryReports = (newRestaurantId: RestaurantId) => {
        const currentRestaurantReportInfo = reports?.find((restaurant) => restaurant.restaurantId === newRestaurantId);
        if (!hasRestaurantReportInfo(currentRestaurantReportInfo)) {
            setHasInfoFound(false);
            return;
        }
        getDeliveryChannelFromData(currentRestaurantReportInfo?.listOfAvailableDeliveryChannels ?? []);
        getDeliveryDataFilteredByChannel(currentRestaurantReportInfo);

        setHasInfoFound(true);
    };

    const hasRestaurantReportInfo = (currentRestaurant: typeof reports[number] | undefined) => currentRestaurant && !!currentRestaurant.averageDeliveryTimeByDeliveryMethod;

    const hasDeliveryChannels = (deliveryChannels: Array<DeliveryChannelsType> | Array<never> | Array<string>) => deliveryChannels && deliveryChannels.length > 0;

    const getDeliveryDataFilteredByChannel = (currentRestaurant: typeof reports[number] | undefined) => {
        const averageDeliveryTime = currentRestaurant?.averageDeliveryTimeByDeliveryMethod?.filter((item: AverageDeliveryTimeByDeliveryMethod) => item?.deliveryMethod === currentChannel);
        const maximumDeliveryTime = currentRestaurant?.maximumDeliveryTimeByDeliveryMethod?.filter((item: MaximumDeliveryTimeByDeliveryMethod) => item?.deliveryMethod === currentChannel);
        const averageDeliveryTimeByAppAndDay = currentRestaurant?.averageDeliveryTimeByDeliveryMethodAndDay?.filter(
            (item: AverageDeliveryTimeByDeliveryMethodAndDay) => item?.deliveryMethod === currentChannel
        );
        const deliveryChannel = currentRestaurant?.salesByDeliveryMethod?.filter((item: SalesByDeliveryMethod) => item?.deliveryMethod === currentChannel);

        setAverageTimeData(averageDeliveryTimeByAppAndDay ?? []);
        setMaximumDeliveryTimeByDeliveryMethod(maximumDeliveryTime ?? []);
        setAverageDeliveryTimeByDeliveryMethod(averageDeliveryTime ?? []);
        setSalesByDeliveryMethod(deliveryChannel ?? []);
    };

    const getDeliveryChannelFromData = (deliveryChannels: Array<never> | Array<string>) => {
        if (!hasDeliveryChannels(deliveryChannels)) return;

        const deliveryChannelData = deliveryChannels?.map((channelName: string) => ({
            label: translate(channelName),
            value: channelName,
        }));
        setDeliveryChannels(deliveryChannelData ?? []);
    };

    const handleShowAsMaster = (value: any) => {
        setShowAsMaster(value);
    };

    return (
        <div className={classes.container}>
            <FilterReports />
            <FilterMasterReports onChangeMaster={handleShowAsMaster} showAsMaster={showAsMaster} />
            {loading && <UpdatingContentProgress />}
            <div>
                {hasDeliveryChannels(deliveryChannels) && (
                    <div className={classes.channelsContainer}>
                        <Form form={form} className={classes.channelsSelect}>
                            <FormSelect name={'restaurantChannels'} options={deliveryChannels} defaultValue={deliveryChannels[0].value ?? ''} />
                        </Form>
                    </div>
                )}
                {hasInfoFound && (
                    <ReportSection title={translate('Average Delivery Time per Day')} loading={loading}>
                        <div className={classes.splitContainer}>
                            <div className={classes.sectionHeadKpi}>
                                <KpiCard
                                    classes={{
                                        container: classes.headingKpiContainer,
                                        title: classes.headingKpiTitleKpi,
                                        body: classes.headingKpiBody,
                                        color: classes.headingKpiColor,
                                        legend: classes.headingKpiLegendContainer,
                                    }}
                                    legend={salesByDeliveryMethod[0]?.orderVolume}
                                    title={translate('Orders')}
                                />
                                <KpiCard
                                    classes={{
                                        container: classes.headingKpiContainer,
                                        title: classes.headingKpiTitleKpi,
                                        body: classes.headingKpiBody,
                                        color: classes.headingKpiColor,
                                        legend: classes.headingKpiLegendContainer,
                                    }}
                                    legend={`${averageDeliveryTimeByDeliveryMethod[0]?.timeAverage ?? 0} min`}
                                    title={translate('Average Delivery Time')}
                                />
                                <KpiCard
                                    classes={{
                                        container: classes.headingKpiContainer,
                                        title: classes.headingKpiTitleKpi,
                                        body: classes.headingKpiBody,
                                        color: classes.headingKpiColor,
                                        legend: classes.headingKpiLegendContainer,
                                    }}
                                    legend={`${maximumDeliveryTimeByDeliveryMethod[0]?.timeMaximum ?? 0} min`}
                                    title={translate('Max Time')}
                                />
                            </div>
                            <div className={classes.container}>
                                <AverageTimeGraph averageTimeData={averageTimeData} />
                            </div>
                        </div>
                    </ReportSection>
                )}
                {!hasInfoFound && !loading && <ReportSectionEmptyState />}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    channelsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    channelsSelect: {
        width: '100%',
        [theme.breakpoints.between('md', 'lg')]: {
            width: '45%',
        },
    },
    splitContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',
        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'row-reverse',
            flexWrap: 'nowrap',
        },
    },
    sectionHeadKpi: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 12,
        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'column',
            width: '20%',
        },
    },
    headingKpiContainer: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '8px',
        margin: 5,
        textAlign: 'center',
        marginRight: '5%',
        marginBottom: '2%',
    },
    headingKpiTitleKpi: {
        fontFamily: theme.typography.regular,
        fontSize: 16,
        color: '#6D7B7F',
    },
    headingKpiBody: {
        fontSize: 28,
        fontFamily: theme.typography.semiBold,
    },
    headingKpiColor: {
        width: 12,
        height: 12,
        borderRadius: 2,
    },
    headingKpiLegendContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.between('sm', 'lg')]: {
            justifyContent: 'left',
            textAlign: 'left',
            marginLeft: 40,
        },
    },
}));

type DeliveryChannelsType = {
    label: string;
    value: string;
};
