/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as React from 'react';
import { useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { Variant } from 'src/components/form/FormTypes';
import { TransformedTextField } from 'src/components/form/material-ui/TransformedTextField';
import { getError } from 'src/components/form/utils/getError';
import { translate } from 'src/i18n/translate';

export function FormPinCode({
    name,
    label,
    placeholder,
    helperText,
    defaultValue = null,
    autoComplete = 'off',
    disabled,
    required,
    variant,
    InputProps,
    inputProps,
    onClickShowPassword,
    minLength,
    maxLength,
    validate,
    tooltip,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();

    const classes = useStyles();

    const inputRef = useRef<HTMLInputElement>();

    const [showPassword, setShowPassword] = useState(false);

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <TransformedTextField
                    {...props}
                    value={value}
                    type={'number'}
                    onChange={onChange}
                    label={label}
                    placeholder={placeholder}
                    helperText={getError(errors, name)?.message ?? helperText}
                    trim
                    disabled={isSubmitting || disabled}
                    required={required}
                    error={!!getError(errors, name)}
                    inputRef={inputRef}
                    tooltip={tooltip}
                    InputProps={{
                        ...InputProps,
                        autoComplete,
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    onClick={() => {
                                        onClickShowPassword?.();
                                        setShowPassword(!showPassword);
                                    }}
                                    className={classes.iconButton}
                                >
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    classesProp={{ input: showPassword ? classes.input : classes.inputHidden }}
                    inputProps={{ autoComplete, ...inputProps }}
                    InputLabelProps={{ shrink: placeholder ? true : undefined }}
                    variant={variant}
                    fullWidth
                />
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
                minLength: {
                    value: (minLength as any)?.value ?? minLength,
                    message: (minLength as any)?.message ?? translate('Must be at least @number characters', { number: (minLength as any)?.value ?? minLength }),
                },
                maxLength: {
                    value: (maxLength as any)?.value ?? maxLength,
                    message: (maxLength as any)?.message ?? translate('Must be at most @number characters', { number: (maxLength as any)?.value ?? maxLength }),
                },
                validate: validate,
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

const useStyles = makeStyles(() => ({
    inputHidden: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
            ' -webkit-text-security': 'disc',
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
    input: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
    iconButton: {
        backgroundColor: 'transparent !important',
        padding: 0,
    },
}));

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    autoComplete?: string;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    minLength?:
        | number
        | {
              value: number;
              message: string;
          };
    maxLength?:
        | number
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    inputProps?: any;
    onClickShowPassword?: any;
    validate?: any;
    tooltip?: string;
};
