/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function copyRestaurantInventoryApi(request: CopyRestaurantInventoryApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('inventory/copyRestaurantInventoryApi', request);
}

export type CopyRestaurantInventoryApiRequest = {
    fromRestaurantId: RestaurantId;
    toRestaurantIds: Array<RestaurantId>;
};
