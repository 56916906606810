/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { SearchableList } from 'src/scenes/letseatmanager/menu/components/SearchableList';
import { ChangeMenuItemSection } from 'src/scenes/letseatmanager/menu/menuItem/ChangeMenuItemSection';
import { CreateMenuItemSection } from 'src/scenes/letseatmanager/menu/menuItem/CreateMenuItemSection';
import { HideMenuItemDialog } from 'src/scenes/letseatmanager/menu/menuItem/HideMenuItemDialog';
import { MenuItemListItem } from 'src/scenes/letseatmanager/menu/menuItem/MenuItemListItem';
import { MenuItemSection } from 'src/scenes/letseatmanager/menu/menuItem/MenuItemSection';
import { RemoveMenuItemDialog } from 'src/scenes/letseatmanager/menu/menuItem/RemoveMenuItemDialog';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import { useLoadMenus } from 'src/services/menu/useLoadMenus';
import { useMenus } from 'src/services/menu/useMenus';
import { useLoadMenuCategories } from 'src/services/menuCategory/useLoadMenuCategories';
import { useMenuCategories } from 'src/services/menuCategory/useMenuCategories';
import { useLoadMenuItems } from 'src/services/menuItem/useLoadMenuItems';
import { useMenuItems } from 'src/services/menuItem/useMenuItems';
import { useModifierGroups } from 'src/services/modifierGroup/useModifierGroups';
import { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import { deepEqual } from 'src/utils/object/deepEqual';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export function MenuItems(): React.ReactElement {
    const classes = useStyles();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();
    const isSmallScreen = useIsSmallScreen();
    const scrollRef = useRef<HTMLDivElement | null>(null);

    const [menuItemManagementType, setMenuItemManagementType] = useState<keyof typeof MenuItemManagementTypes>(MenuItemManagementTypes.SORT);
    const [showProducts, setShowProducts] = useState(true);
    const [selectedMenuItem, setSelectedMenuItem] = useState<ManagerMenuItemVm | undefined>(undefined);
    const [removeMenuItemDialogState, setRemoveMenuItemDialogState] = useState<{ open: boolean; menuItem: ManagerMenuItemVm | undefined }>({ open: false, menuItem: undefined });
    const [hideMenuItemDialogState, setHideMenuItemDialogState] = useState<{ open: boolean; menuItem: ManagerMenuItemVm | undefined }>({ open: false, menuItem: undefined });
    const [menuItemsFiltered, setMenuItemsFiltered] = useState<Array<ManagerMenuItemVm>>([]);

    const { menus } = useMenus();
    const { menuCategories } = useMenuCategories();
    const { modifierGroups } = useModifierGroups();

    const { menuItems } = useMenuItems();
    const refreshMenuItems = useLoadMenuItems();
    const refreshMenus = useLoadMenus();
    const refresMenuCategories = useLoadMenuCategories();

    const filter = {
        name: 'filter',
        label: translate('All'),
        options: [
            { value: null, label: translate('All') },
            ...menus.map((menuItem) => ({
                value: menuItem.menuId,
                label: menuItem.name,
            })),
        ],
    } as const;

    useEffect(() => {
        refreshMenuItems();
    }, []);

    useEffect(() => {
        showAllProductsOrModifiers();

        if (menuItems && !selectedMenuItem && !isSmallScreen) {
            setSelectedMenuItem(menuItems[0]);
        }
        if (menuItems && selectedMenuItem) updateSelectedMenuItem();
    }, [menuItems, selectedMenuItem, isSmallScreen, showProducts]);

    const updateSelectedMenuItem = () => {
        if (!selectedMenuItem) return;

        const updatedMenuItem = menuItems.find((menuItem) => menuItem.menuItemId === selectedMenuItem.menuItemId);
        if (deepEqual(updatedMenuItem, selectedMenuItem) || !updatedMenuItem) return;

        setSelectedMenuItem(updatedMenuItem);
    };

    const showCreateMenuItem = () => setMenuItemManagementType(MenuItemManagementTypes.CREATE);

    const showChangeMenuItem = useCallback((menuItem: ManagerMenuItemVm) => {
        setMenuItemManagementType(MenuItemManagementTypes.CHANGE);
        setSelectedMenuItem(menuItem);
    }, []);

    const showSortMenuItem = () => setMenuItemManagementType(MenuItemManagementTypes.SORT);

    const openRemoveMenuItemDialog = useCallback((menuItem: ManagerMenuItemVm) => {
        setRemoveMenuItemDialogState({ open: true, menuItem });
    }, []);

    const closeRemoveMenuItemDialog = () => setRemoveMenuItemDialogState({ open: false, menuItem: undefined });

    const openHideMenuItemDialog = useCallback((menuItem: ManagerMenuItemVm) => {
        setHideMenuItemDialogState({ open: true, menuItem });
    }, []);

    const closeHideMenuItemDialog = () => setHideMenuItemDialogState({ open: false, menuItem: undefined });

    const handleClickMenuItem = (menuItem: ManagerMenuItemVm) => {
        setSelectedMenuItem(menuItem);
    };

    const showAllProductsOrModifiers = () => {
        setMenuItemsFiltered(menuItems.filter((menuItem: ManagerMenuItemVm) => (showProducts ? menuItem.isProduct : menuItem.isModifier)));
    };

    const handleClickedFilter = (selectedMenuIds: any) => {
        if (!selectedMenuIds[0]) return showAllProductsOrModifiers();
        if (showProducts) return showFilteredProducts(selectedMenuIds[0]);
        showFilteredModifiers(selectedMenuIds[0]);
    };

    const showFilteredProducts = (selectedMenuId: any) => {
        const menuCategoryIdsSelected = menus.filter((menu) => menu.menuId === selectedMenuId).flatMap((menu) => menu.menuCategoryIds);
        const menuCategoriesSelected = menuCategories.filter((menuCategory) => menuCategoryIdsSelected.includes(menuCategory.menuCategoryId));
        const menuItemIdsSelected = menuCategoriesSelected.flatMap((menuCategory) => menuCategory.menuItemIds);
        setMenuItemsFiltered(menuItems.filter((menuItem) => menuItemIdsSelected.includes(menuItem.menuItemId) && menuItem.isProduct));
    };

    const showFilteredModifiers = (selectedMenuId: any) => {
        const menuCategoryIdsSelected = menus.filter((menu) => menu.menuId === selectedMenuId).flatMap((menu) => menu.menuCategoryIds);
        const menuCategoriesSelected = menuCategories.filter((menuCategory) => menuCategoryIdsSelected.includes(menuCategory.menuCategoryId));
        const menuItemIdsSelected = menuCategoriesSelected.flatMap((menuCategory) => menuCategory.menuItemIds);

        const modifierGroupIdsOfMenuCategoriesSelected = menuCategoriesSelected.flatMap((menuCategory) => menuCategory.modifierGroupIds);
        const modifierGroupsOfMenuCategoriesFiltered = modifierGroups.filter((modifierGroup) => modifierGroupIdsOfMenuCategoriesSelected.includes(modifierGroup.modifierGroupId));
        const menuItemIdsOfOfMenuCategoriesSelected = modifierGroupsOfMenuCategoriesFiltered.flatMap((modifierGroup) => modifierGroup.modifiers).flatMap((modifier) => modifier.menuItemId);

        const modifierGroupIdsOfMenuItemsSelected = menuItems.filter((menuItem) => menuItemIdsSelected.includes(menuItem.menuItemId)).flatMap((menuItem) => menuItem.modifierGroupIds);
        const modifierGroupsOfMenuItemsFiltered = modifierGroups.filter((modifierGroup) => modifierGroupIdsOfMenuItemsSelected.includes(modifierGroup.modifierGroupId));
        const menuItemIdsOfMenuItemsSelected = modifierGroupsOfMenuItemsFiltered.flatMap((modifierGroup) => modifierGroup.modifiers).flatMap((modifier) => modifier.menuItemId);

        const allMenuItemIdsFiltered = [...new Set([...menuItemIdsOfMenuItemsSelected, ...menuItemIdsOfOfMenuCategoriesSelected])];
        const menuItemsFiltered = menuItems.filter((menuItem) => allMenuItemIdsFiltered.includes(menuItem.menuItemId));
        setMenuItemsFiltered(menuItemsFiltered);
    };

    const handleMenuItem = () => {
        refreshMenus();
        refreshMenuItems();
        refresMenuCategories();
        showSortMenuItem();
        scrollToTop();
    };

    const scrollToTop = () => {
        scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className={classes.container} ref={scrollRef}>
            <RemoveMenuItemDialog open={removeMenuItemDialogState.open} menuItem={removeMenuItemDialogState.menuItem} onClose={closeRemoveMenuItemDialog} onSuccess={handleMenuItem} />
            <HideMenuItemDialog open={hideMenuItemDialogState.open} menuItem={hideMenuItemDialogState.menuItem} onClose={closeHideMenuItemDialog} onSuccess={handleMenuItem} />
            <section className={classes.menusContainer}>
                <div className={classes.switchMenuItemType}>
                    <Button text={!showProducts} classes={{ button: classes.menuItemTypeButton }} onClick={() => setShowProducts(true)}>
                        {translate('Products')}
                    </Button>
                    <Button text={showProducts} classes={{ button: classes.menuItemTypeButton }} onClick={() => setShowProducts(false)}>
                        {translate('Modifiers')}
                    </Button>
                </div>
                <SearchableList
                    title={showProducts ? translate('Products') : translate('Modifiers')}
                    onCreate={showCreateMenuItem}
                    onItemClick={handleClickMenuItem}
                    defaultListItemSelected={menuItemsFiltered?.[0]?.menuItemId}
                    disabled={isMenuEditionDisabled}
                    items={menuItemsFiltered}
                    itemSize={158}
                    height={800}
                    getItemKey={(menuItem: ManagerMenuItemVm) => menuItem.menuItemId}
                    getItemSearchableBy={(menuItem) => menuItem.name}
                    renderItem={(menuItem: ManagerMenuItemVm) => (
                        <MenuItemListItem menuItem={menuItem} onEdit={showChangeMenuItem} onRemove={openRemoveMenuItemDialog} onHide={openHideMenuItemDialog} isProduct={showProducts} />
                    )}
                    filter={filter as any}
                    onFilterClick={handleClickedFilter}
                />
            </section>
            {menuItemManagementType === MenuItemManagementTypes.CREATE && <CreateMenuItemSection onClose={showSortMenuItem} onSuccess={handleMenuItem} isProductSelected={showProducts} />}
            {menuItemManagementType === MenuItemManagementTypes.CHANGE && <ChangeMenuItemSection menuItemId={selectedMenuItem?.menuItemId} onClose={showSortMenuItem} onSuccess={handleMenuItem} />}
            {menuItemManagementType === MenuItemManagementTypes.SORT && selectedMenuItem && <MenuItemSection menuItem={selectedMenuItem} onClose={() => setSelectedMenuItem(undefined)} />}
        </div>
    );
}

const MenuItemManagementTypes = {
    CREATE: 'CREATE',
    CHANGE: 'CHANGE',
    SORT: 'SORT',
} as const;

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
        gap: 60,
    },
    menuItemsList: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 24,
        padding: 0,
    },
    menusContainer: {
        width: '50%',
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    switchMenuItemType: {
        padding: 10,
        borderRadius: 15,
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        backgroundColor: '#E5E8ED',
        width: 'fit-content',
        margin: '0 auto',
        marginBottom: 20,
    },
    menuItemTypeButton: {
        width: 150,
    },
    menuItemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
}));
