/**
 * @prettier
 */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { findCustomersApi } from 'src/api/letseatmanager/customer/findCustomersApi';
import { FormAutocompleteMultiple } from 'src/components/form/FormAutocompleteMultiple';
import type { Variant } from 'src/components/form/FormTypes';
import { getRestaurantCustomersInLocalStorage, RestaurantCustomer } from 'src/localStorage/getRestaurantCustomersInLocalStorage';
import { setRestaurantCustomersInLocalStorage } from 'src/localStorage/setRestaurantCustomersInLocalStorage';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function FormCustomersAutocomplete({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, InputProps }: Props): React.ReactElement {
    const [options, setOptions] = useState(getRestaurantCustomersInLocalStorage());

    const restaurantId = useSelector((state) => state.app.restaurantId);

    useEffect(() => {
        async function load() {
            let formattedResponse: Array<RestaurantCustomer> = [];
            const response = await findCustomersApi({ restaurantId });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            formattedResponse = response.data.map((customer) => ({ label: customer?.name ?? '', value: customer.customerId }));

            setRestaurantCustomersInLocalStorage(formattedResponse);
            if (cancelled) return;
            setOptions(formattedResponse);
        }

        let cancelled = false;
        load();
        return () => {
            cancelled = true;
        };
    }, []);

    return (
        <FormAutocompleteMultiple
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={required}
            variant={variant}
            InputProps={InputProps}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: Array<string>;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
};
