/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DeliveryZoneId, RestaurantId } from 'src/types/Id';

export async function enableDeliveryZoneApi(request: EnableDeliveryZoneApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('deliveryZone/enableDeliveryZoneApi', request);
}

type EnableDeliveryZoneApiRequest = {
    restaurantId: RestaurantId;
    deliveryZoneId: DeliveryZoneId;
};
