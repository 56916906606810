/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Dialog } from 'src/components/Dialog';
import { NumericPad } from 'src/components/NumericPad';
import { translate } from 'src/i18n/translate';
import { authReducer } from 'src/reducers/authReducer';
import { posReducer } from 'src/reducers/posReducer';
import { PinCodeUser } from 'src/types/PinCodeUser';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PinCodeUserSignInDialog(): React.ReactElement {
    const classes = useStyles();

    const pinCodeUsers = useSelector((state) => state.authentication.pinCodeUsers);
    const open = useSelector((state) => state.pos.pinCodeUserSignInDialog.open);
    const onSuccess = useSelector((state) => state.pos.pinCodeUserSignInDialog.onSuccess);
    const onFailed = useSelector((state) => state.pos.pinCodeUserSignInDialog.onFailed);

    const closePinCodeUserSignInDialog = useAction(posReducer.actions.closePinCodeUserSignInDialog);
    const pinCodeUserSignIn = useAction(authReducer.actions.pinCodeUserSignIn);

    const handleClose = () => {
        closePinCodeUserSignInDialog();
    };

    const handleSubmit = async (pinCode: any) => {
        const pinCodeUser = pinCodeUsers?.find((user: PinCodeUser) => user.pinCode === pinCode);

        if (!pinCodeUser) {
            return onFailed?.();
        }

        closePinCodeUserSignInDialog();
        pinCodeUserSignIn(pinCodeUser);
        onSuccess?.(pinCodeUser);
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Identify')}>
            <p className={classes.bodyText}>{translate('Your personal pin code is required to continue')}</p>
            <NumericPad onSubmit={handleSubmit} isPassword={true} />
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    bodyText: {
        fontFamily: theme.typography.medium,
        textAlign: 'center',
        color: '#324054',
    },
}));
