/**
 * @prettier
 */
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import * as React from 'react';

export default (props: any): React.ReactElement => {
    const {
        input: { name, value, onChange, ...restInput },
        meta,
        label,
        disabled,
        formControlProps,
        selectMultiple,
        ...rest
    } = props;
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

    return (
        <FormControl {...formControlProps} error={showError}>
            <InputLabel htmlFor={name} disabled={disabled}>
                {label}
            </InputLabel>

            <Select {...rest} name={name} onChange={onChange} inputProps={restInput} value={value} multiple={selectMultiple} disabled={disabled} />

            {showError && <FormHelperText>{meta.error || meta.submitError}</FormHelperText>}
        </FormControl>
    );
};
