/**
 * @prettier
 */
import { IconButton, makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import LinkIcon from '@material-ui/icons/Link';
import { CircleIconContainer } from '@pidedirecto/ui';
import { CheckIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { OrderInvoiceIcon } from 'src/icons/OrderInvoiceIcon';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { OrderVm } from 'src/types/OrderVm';
import { copyTextToClipboard } from 'src/utils/html/copyTextToClipboard';
import { isManualOrder } from 'src/utils/order/isManualOrder';
import { isRappiApp } from 'src/utils/order/isRappiApp';
import { isPaid } from 'src/utils/payment/isPaid';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { translateOrderPaymentMethodWithEmoji } from 'src/utils/translate/translateOrderPaymentMethodWithEmoji';
import { translatePaymentMethodWithEmoji } from 'src/utils/translate/translatePaymentMethodWithEmoji';

export function OrderCardPaymentInfo({ order }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const rappiCheckInCodeEnabled = useSelector((state) => state.app.restaurant.rappiStoreCheckInCodeEnabled);
    const rappiStoreCheckInCode = useSelector((state) => state.app.restaurant.rappiStoreCheckInCode);

    const shouldShowPosPayments = !!order.payments && !order.rappiCargoDeliveryId && !order.uberDaasDeliveryOrderId;
    const shouldShowPaymentMethod = !order.posPayments && !order.rappiCargoDeliveryId && !order.uberDaasDeliveryOrderId && !order.payments;
    const mandatoryCashAmount = order.mandatoryCashAmount ?? 0;

    const hasPaymentMethodChangedToCard = order.hasPaymentMethodChangedToCard && isCardPayment(order.paymentMethod);
    const onlyPaidWithCard = !!order.payments?.every((payment) => isCardPayment(payment.paymentMethod) && !payment.customPaymentMethod);

    const isManualOrderAlreadyPaid = isManualOrder(order) && isPaid(order.paymentStatus) && order.externalCardPayment;

    return (
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 8 }}>
                <Text variant='semiBold' className={classNames(classes.text, (classes as any).paymentMethodTitle)}>
                    {translate('Payment method')}
                </Text>
                <div className={classes.uniquePaymentsContainer}>
                    {(!!order.rappiCargoDeliveryId || !!order.uberDaasDeliveryOrderId) && (
                        <span className={classes.text}>
                            {translateOrderPaymentMethodWithEmoji(order)} {!isManualOrderAlreadyPaid && translate(`(Do not charge the delivery man, it is deposited)`)}
                        </span>
                    )}
                    {shouldShowPosPayments &&
                        order.payments?.map((payment, idx) => {
                            const paymentCurrency = !payment.restaurantCurrency ? '' : `(${payment.restaurantCurrency?.currency})`;
                            const tipLabel = payment.isTipPayment ? `(${translate('Tip')})` : '';

                            return (
                                <div key={idx} className={classes.paymentContainer}>
                                    <div className={classes.spaceBetween}>
                                        <Text error={isCashPayment(payment.paymentMethod) && !payment.customPaymentMethod}>{`${translatePaymentMethodWithEmoji({
                                            paymentMethod: payment.customPaymentMethod ?? payment.paymentMethod,
                                            app: order.app,
                                        })} ${paymentCurrency} ${tipLabel}`}</Text>
                                        <div style={{ display: 'flex', gap: 8 }}>
                                            <Text>{formatAsCurrencyNumber(payment.amount)}</Text>
                                            {!!order.paymentLinkUrl && isPaymentLinkPayment(payment.paymentMethod) && (
                                                <IconButton onClick={() => copyTextToClipboard(order.paymentLinkUrl ?? '')} size='small'>
                                                    <LinkIcon />
                                                </IconButton>
                                            )}
                                        </div>
                                    </div>
                                    {payment.paymentReference && (
                                        <>
                                            <div className={classes.spaceBetween}>
                                                <Text className={classes.paymentReference}>{translate('Reference')}</Text>
                                                <Text className={classes.paymentReference}>{payment.paymentReference}</Text>
                                            </div>
                                            <div className={classes.paymentReferenceCheck}>
                                                <CircleIconContainer classes={{ container: classes.paymentReferenceCheckContainer }}>
                                                    <CheckIcon />
                                                </CircleIconContainer>
                                                <Text>{translate('Comission @paymentMethod', { paymentMethod: translate(payment.paymentMethod) })}</Text>
                                            </div>
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    {shouldShowPaymentMethod && (
                        <div>
                            {hasPaymentMethodChangedToCard && (
                                <Tooltip title={translate('The payment method has been changed manually')} classes={{ tooltip: classes.tooltip }}>
                                    <span className={classes.text} style={isCashPayment(order.paymentMethod) ? { color: 'red' } : {}}>
                                        {translateOrderPaymentMethodWithEmoji(order)}
                                    </span>
                                </Tooltip>
                            )}
                            {!hasPaymentMethodChangedToCard && (
                                <span className={classes.text} style={isCashPayment(order.paymentMethod) ? { color: 'red' } : {}}>
                                    {translateOrderPaymentMethodWithEmoji(order)}
                                </span>
                            )}
                            {!!order.paymentLinkUrl && (
                                <IconButton onClick={() => copyTextToClipboard(order.paymentLinkUrl ?? '')} size='small'>
                                    <LinkIcon />
                                </IconButton>
                            )}
                        </div>
                    )}
                    {!!mandatoryCashAmount && (
                        <span className={classes.text} style={{ color: 'red' }}>
                            {translate('Pays with: @mandatoryCashAmount', { mandatoryCashAmount: formatAsCurrencyNumber(mandatoryCashAmount ?? 0) })}
                        </span>
                    )}
                    {!onlyPaidWithCard && (
                        <div style={{ width: '100%' }} className={classes.spaceBetween}>
                            <Text>{translate('change')}</Text>
                            <Text>{formatAsCurrencyNumber(order.change ?? order.posChange ?? 0)}</Text>
                        </div>
                    )}
                    {!!isManualOrderAlreadyPaid && (
                        <div style={{ width: '100%' }} className={classes.spaceBetween}>
                            <Text className={classes.manualOrderAlreadyPaidText}>{translate('Order is already paid')}</Text>
                        </div>
                    )}
                </div>
                {!!order.deliveryCashHandlingFee && (
                    <div className={classes.deliveryCashHandlingFeeContainer}>
                        <CircleIconContainer classes={{ container: classes.iconContainer }}>
                            <CheckIcon size={8} />
                        </CircleIconContainer>
                        <span className={classes.text}>{translate('Cash fee')}</span>
                    </div>
                )}
            </div>
            {rappiCheckInCodeEnabled && isRappiApp(order.app) && (
                <div className={classes.spaceBetween}>
                    <span className={classes.text}>{translate('Rappi Restaurant Code')}</span>
                    <span className={classes.text}>{rappiStoreCheckInCode}</span>
                </div>
            )}
            <div className={classes.separator}></div>
            {order.invoiced && (
                <>
                    <div className={classes.invoiced}>
                        <OrderInvoiceIcon color={'#C1C3C8'} title={translate('Invoice')} />
                        <span className={classes.invoiceText}>{translate('Order Invoiced')}</span>
                    </div>
                    <div className={classes.separator}></div>
                </>
            )}
            {order.notes && (
                <div className={classes.spaceBetween}>
                    <span className={classes.text}>{translate('Comment')}</span>
                    <span className={classes.text}>{order.notes ?? 'N/A'}</span>
                </div>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    separator: {
        height: '0px',
        width: '100%',
        border: '1px solid #F3F3F3',
        margin: '12px 0px',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#454A4A',
    },
    spaceBetween: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    invoiced: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
    },
    invoiceText: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
    },
    tooltip: {
        fontFamily: theme.typography.regular,
    },
    uniquePaymentsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    paymentContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: 10,
    },
    paymentReference: {
        alignSelf: 'flex-end',
        fontFamily: theme.typography.regular,
        color: theme.palette.text.secondary,
        fontSize: 12,
    },
    paymentReferenceCheck: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5,
        alignSelf: 'flex-end',
        fontFamily: theme.typography.regular,
        color: theme.palette.text.secondary,
        fontSize: 12,
    },
    paymentReferenceCheckContainer: {
        height: 18,
        width: 18,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
    manualOrderAlreadyPaidText: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#14AE5C',
    },
    deliveryCashHandlingFeeContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 6,
    },
    iconContainer: {
        backgroundColor: theme.palette.surface.brandContrast,
        color: 'white',
        border: 0,
        width: 18,
        height: 18,
    },
}));

type Props = {
    order: OrderVm;
};
