/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getDriverMobileNumberOptionsApi } from 'src/api/letseatmanager/options/getDriverMobileNumberOptionsApi';
import { FormCustomizableAutocomplete } from 'src/components/form/FormCustomizableAutocomplete';
import type { Variant } from 'src/components/form/FormTypes';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { getDriverMobileNumberOptionsInLocalStorage } from 'src/localStorage/getDriverMobileNumberOptionsInLocalStorage';
import { setDriverMobileNumberOptionsInLocalStorage } from 'src/localStorage/setDriverMobileNumberOptionsInLocalStorage';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function FormDriverMobileNumberAutocomplete({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, InputProps }: Props): React.ReactElement {
    const classes = useStyles();

    const [options, setOptions] = useState(getDriverMobileNumberOptionsInLocalStorage());
    const restaurantId = useSelector((state) => state.app.restaurantId);

    useEffect(() => {
        async function load() {
            const response = await getDriverMobileNumberOptionsApi({ restaurantId });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            setDriverMobileNumberOptionsInLocalStorage(response.data);
            if (cancelled) return;
            setOptions(response.data);
        }

        let cancelled = false;
        load();
        return () => {
            cancelled = true;
        };
    }, [restaurantId]);

    return (
        <FormCustomizableAutocomplete
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            data={options}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            getOptionDisabled={(option) => option.banned}
            renderOption={(option) => (
                <div className={classNames(classes.containerOption, { [classes.containerOptionDisabled]: option.banned })}>
                    <Text>{option.label}</Text>
                    {option.banned && <Text className={classes.bannedText}>{translate('Banned')}</Text>}
                </div>
            )}
            classes={{ optionContainer: classes.optionContainer }}
            disabled={disabled}
            required={required}
            variant={variant}
            InputProps={InputProps}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    containerOption: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 4px',
    },
    containerOptionDisabled: {
        backgroundColor: '#F5F5F5',
        color: '#9BA1AA',
    },
    bannedText: {
        color: '#E32F45',
    },
    optionContainer: {
        backgroundColor: 'transparent !important',
        '&:active': {
            backgroundColor: `${theme.palette.primary.light} !important`,
        },
        '&:hover': {
            backgroundColor: `${theme.palette.primary.light} !important`,
        },
    },
}));

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
};
