/**
 * @prettier
 */
import type { CashRegisterShiftReportVm } from 'src/types/CashRegisterShiftReportVm';
import type { RestaurantVm } from 'src/types/RestaurantVm';
import {
    cancelledOrdersReport,
    cashRegisterTransactions,
    creditCardOrders,
    discountsBreakdownReport,
    integrationOrdersReport,
    paymentMethodReports,
    posBusinessDayCashRegisterTransactionSummary,
    posBusinessDayPaymentMethodsBreakdown,
    posBusinessDayPaymentsSummary,
    posBusinessDaySummary,
    promoCodeReport,
    salesByAppReport,
    salesByItemReport,
    salesByManagerUserReport,
    salesByOrderTypeReport,
} from 'src/utils/posBusinessDay/getPosBusinessDayReportDemo';
import { newUuid } from 'src/utils/uuid/newUuid';

export function getCashRegisterShiftReportDemo(restaurant: RestaurantVm): CashRegisterShiftReportVm {
    return {
        cashRegisterId: newUuid(),
        restaurantId: newUuid(),
        cashRegisterPosBusinessDayNumber: '01',
        restaurantInformation: {
            name: restaurant.name,
            businessName: restaurant.businessName,
            rfc: restaurant.rfc,
            street: restaurant.restaurantStreet ?? '',
            fiscalAddress: restaurant.fiscalAddress,
        },
        cashRegisterSummary: posBusinessDaySummary,
        cashRegisterPaymentsSummary: posBusinessDayPaymentsSummary,
        cashRegisterTransactionSummary: posBusinessDayCashRegisterTransactionSummary,
        cashRegisterPaymentMethodsBreakdown: posBusinessDayPaymentMethodsBreakdown,
        creditCardOrders,
        integrationOrdersReport,
        salesByOrderTypeReport,
        salesByAppReport,
        salesByManagerUserReport,
        discountsBreakdownReport,
        cancelledOrdersReport,
        salesByItemReport,
        cashRegisterTransactions,
        promoCodeReport,
        cashRegisterReport: paymentMethodReports,
        orders: [],
    };
}
