/**
 * @prettier
 */
import { useState } from 'react';
import * as React from 'react';
import { removeCurrencyApi } from 'src/api/letseatmanager/restaurant/removeCurrencyApi';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import { translate } from 'src/i18n/translate';
import type { RestaurantCurrency } from 'src/types/RestaurantCurrency';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function RemoveCurrencyDialog({ open, restaurantCurrency, onClose, onSuccess }: Props): React.ReactElement {
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const handleSubmit = async () => {
        if (!restaurantCurrency) return;
        setLoading(true);
        const response = await removeCurrencyApi({ restaurantId, currency: restaurantCurrency.currency });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        onSuccess();
        onClose();
    };

    return (
        <RemoveDialog
            open={open}
            title={translate('Remove currency')}
            subtitle={translate('Are you sure you want to remove currency: "@currency"?', { currency: restaurantCurrency?.currency })}
            onSubmit={handleSubmit}
            onClose={onClose}
            disabled={loading}
        />
    );
}

type Props = {
    open: boolean;
    restaurantCurrency?: RestaurantCurrency;
    onClose: any;
    onSuccess: any;
};
