/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ReplyIcon from '@material-ui/icons/Reply';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { app2 } from 'src/app2';
import { Chip } from 'src/components/Chip';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import type { City } from 'src/constants/City';
import type { Country } from 'src/constants/Country';
import type { RestaurantStatus } from 'src/constants/RestaurantStatus';
import type { SalesforceRestaurantType } from 'src/constants/SalesforceRestaurantType';
import { translate } from 'src/i18n/translate';
import { RegisterRestaurantDialog } from 'src/scenes/letseatadmin/restaurants/RegisterRestaurantDialog';
import { appColors } from 'src/theme/AppColors';
import type { RestaurantId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { createFilteredOnDownloadFunctional } from 'src/utils/mui-datatables/createFilteredOnDownloadFunctional';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { formatAsInternationalPhoneNumber } from 'src/utils/string/formatAsInternationalPhoneNumber';
import { translateRestaurantStatus } from 'src/utils/translate/translateRestaurantStatus';
import { openRestaurantPageInNewTab } from 'src/utils/window/openRestaurantPageInNewTab';

export function RestaurantsPage(): React.ReactElement {
    const classes = useStyles();
    const viewUser = useSelector((state) => state.authentication.viewUser);
    const table = useRef(null);
    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState<RestaurantsVm>({ restaurants: [] });
    const [registerRestaurantDialogState, setRegisterRestaurantDialogState] = useState({ open: false });
    const cities = useSelector((state) => state.app2.cities);

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Restaurants'));
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.admin.restaurants({ cities });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setVm(response.data);
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <RegisterRestaurantDialog
                open={registerRestaurantDialogState.open}
                restaurants={vm.restaurants}
                onClose={() => setRegisterRestaurantDialogState({ open: false })}
                onRestaurantRegistered={load}
            />
            <MUIDataTable
                /* @ts-ignore */
                className={classes.table}
                ref={table}
                data={
                    vm.restaurants &&
                    vm.restaurants.map((restaurant) => ({
                        restaurantId: restaurant.restaurantId,
                        restaurantStatus: translateRestaurantStatus(restaurant.restaurantStatus),
                        temporarilyOffline: restaurant.temporarilyOffline ? translate('Offline') : translate('No'),
                        name: restaurant.name,
                        salesforceRestaurantType: restaurant.salesforceRestaurantType,
                        street: restaurant.street,
                        phoneNumber: formatAsInternationalPhoneNumber(restaurant.phoneNumber),
                        restaurantType: restaurant.restaurantType,
                        manualOrdersEnabled: restaurant.manualOrdersEnabled ? 'Enabled' : 'Disabled',
                        webOrdersEnabled: restaurant.webOrdersEnabled ? 'Enabled' : 'Disabled',
                        urlSubdomain: restaurant.urlSubdomain,
                        urlPathname: restaurant.urlPathname,
                        city: translate(restaurant.city),
                        country: translate(restaurant.country),
                    }))
                }
                columns={[
                    {
                        name: 'restaurantId',
                        label: ' ',
                        options: {
                            filter: false,
                            searchable: true,
                            sort: false,
                            customBodyRender: (restaurantId) => {
                                return (
                                    <IconButton color='primary' onClick={() => openRestaurantPageInNewTab(restaurantId)}>
                                        <ReplyIcon fontSize='small' />
                                    </IconButton>
                                );
                            },
                            setCellProps: () => {
                                return {
                                    style: { paddingLeft: 8, paddingRight: 0 },
                                };
                            },
                        },
                    },
                    {
                        name: 'restaurantStatus',
                        label: translate('Status'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'temporarilyOffline',
                        label: translate('Offline'),
                        options: {
                            filter: true,
                            searchable: false,
                            customBodyRender: (temporarilyOffline) => {
                                if (temporarilyOffline === translate('Offline')) {
                                    return <div style={{ color: appColors.text.danger }}>Offline</div>;
                                }
                                return <div />;
                            },
                        },
                    },
                    {
                        name: 'name',
                        label: translate('Name'),
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'salesforceRestaurantType',
                        label: translate('Salesforce'),
                        options: {
                            filter: false,
                            customBodyRender: (value) => <div>{value && <Chip>{value}</Chip>}</div>,
                        },
                    },
                    {
                        name: 'street',
                        label: translate('Street'),
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'phoneNumber',
                        label: translate('Phone Number'),
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'restaurantType',
                        label: translate('Restaurant Type'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'manualOrdersEnabled',
                        label: translate('Manual Orders'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'webOrdersEnabled',
                        label: translate('Web Orders'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'urlSubdomain',
                        label: translate('Url Subdomain'),
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'urlPathname',
                        label: translate('Url Pathname'),
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'city',
                        label: translate('City'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'country',
                        label: translate('Country'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    sortOrder: {
                        name: 'name',
                        direction: 'asc',
                    },
                    tableBodyMaxHeight: '500px',
                    selectableRows: !viewUser ? 'multiple' : 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                            <CreateToolbarButton tooltip={translate('Register Restaurant')} onClick={() => setRegisterRestaurantDialogState({ open: true })} />
                        </>
                    ),
                    onDownload: createFilteredOnDownloadFunctional(table),
                    // filterList: [[], [], [], [], ['MEXICO']],
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

type RestaurantsVm = {
    restaurants: Array<RestaurantVm>;
};

type RestaurantVm = {
    restaurantId: RestaurantId;
    restaurantType: RestaurantId;
    name: string;
    street: string;
    phoneNumber: string;
    restaurantStatus: RestaurantStatus;
    temporarilyOffline: boolean;
    manualOrdersEnabled?: boolean;
    webOrdersEnabled?: boolean;
    urlSubdomain?: string;
    urlPathname?: string;
    city?: City;
    country: Country;
    rappiStoreIntegrationEnabled?: boolean;
    uberEatsIntegrationEnabled?: boolean;
    didiFoodIntegrationEnabled?: boolean;
    salesforceRestaurantType?: SalesforceRestaurantType;
};
