/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import SignalWifi4BarIcon from '@material-ui/icons/SignalWifi4Bar';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { AppAlertDialogs } from 'src/components/app/AppAlertDialogs';
import { AppBar } from 'src/components/app/appBar/AppBar';
import { AppContentContainer } from 'src/components/app/AppContentContainer';
import { AppDrawer } from 'src/components/app/AppDrawer';
import { AppSnackbar } from 'src/components/app/AppSnackbar';
import { AppVersionInfo } from 'src/components/app/AppVersionInfo';
import { DeveloperModeLabel } from 'src/components/app/DeveloperModeLabel';
import { EnvironmentLabel } from 'src/components/app/EnvironmentLabel';
import { PideDirectoAdminAppConfig } from 'src/components/app/PideDirectoAdminAppConfig';
import { UserSessionListener } from 'src/components/app/UserSessionListener';
import { PublicRoutePaths } from 'src/constants/PublicRoutePath';
import { SelectPaymentTerminalDialog } from 'src/scenes/letseatmanager/device/paymentTerminals/SelectPaymentTerminalDialog';
import { SelectPrinterDialog } from 'src/scenes/letseatmanager/device/printers/SelectPrinterDialog';
import { useErudaHtmlConsole } from 'src/services/eruda/useErudaHtmlConsole';
import { useOnline } from 'src/utils/react/useOnline';
import { useSelector } from 'src/utils/react/useSelector';

export function AppContainer({ children }: Props): React.ReactElement {
    const classes = useStyles();
    const location = useLocation();
    useErudaHtmlConsole();
    useOnline();

    const signedIn = useSelector((state) => state.authentication.signedIn);
    const online = useSelector((state) => state.app2.online);

    const selfRegisterLocation = location.pathname === PublicRoutePaths.RESTAURANT_SELF_REGISTRATION;

    return (
        <div className={classes.container}>
            <PideDirectoAdminAppConfig />
            <UserSessionListener />
            <SelectPrinterDialog />
            <SelectPaymentTerminalDialog />
            <div className={classes.appContainer}>
                {!signedIn && !selfRegisterLocation && (
                    <div style={{ position: 'absolute', top: 30, right: 30 }}>{online ? <SignalWifi4BarIcon className={classes.icon} /> : <SignalWifiOffIcon className={classes.icon} />}</div>
                )}
                <AppBar />
                <AppDrawer />
                <AppContentContainer>
                    {children}
                    <AppAlertDialogs />
                </AppContentContainer>
            </div>
            <AppSnackbar />
            <EnvironmentLabel />
            <DeveloperModeLabel />
            <AppVersionInfo />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.primary.main,
    },
    posIcon: {
        color: 'white',
    },
    container: {
        width: '100%',
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
    },
    appContainer: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '100%',
    },
}));

type Props = {
    children: React.ReactNode;
};
