/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverId, DriverReferralId } from 'src/types/Id';

export async function findDriverReferralsApi(): ApiSauceResponse<DriverReferralsVm> {
    return letseatadminApiMethod('driverReferral/findDriverReferralsApi');
}

export type DriverReferralsVm = {
    driverReferrals: Array<DriverReferralVm>;
};

type DriverReferralVm = {
    driverReferralId: DriverReferralId;
    referredDriverId: DriverId;
    referredByDriverId: DriverId;
    deliveriesDelivered: number;
    referredDriverEarnings: number;
    referredByDriverEarnings: number;
    deliveriesToDeliver: number;
    expiresAt: Date;
    startedAt: Date;
    expiredAt?: Date;
    completedAt?: Date;
    createdAt: Date;
    modifiedAt: Date;
};
