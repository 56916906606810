/**
 * @prettier
 */
import * as React from 'react';

export function EatHereIcon({ width, height, color, title }: Props): React.ReactElement {
    return (
        <svg width={width || '35'} height={height || '40'} viewBox='0 0 35 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                d='M17.205 11.6172C17.4456 12.6016 17.4534 13.6328 17.2438 14.625C17.0264 15.5547 16.5838 16.5547 15.9627 17.3516C14.837 18.6406 13.4317 19.4609 11.7314 19.8516V36.875C11.7314 37.7031 11.4751 38.5 10.8929 39.0859C10.3106 39.6719 9.51863 40 8.62577 40C7.87266 40 7.08229 39.6719 6.49922 39.0859C5.91692 38.5 5.52018 37.7031 5.52018 36.875V19.8516C3.95651 19.5391 2.48136 18.6641 1.4208 17.375C0.791143 16.5781 0.351624 15.6484 0.136251 14.6484C-0.0791214 13.6484 -0.0645639 12.6172 0.178798 11.625L2.52406 0.972659C2.59006 0.671722 2.76474 0.406018 3.01397 0.226565C3.26242 0.047034 3.56909 -0.0335675 3.87344 0.000205889C4.17857 0.0336121 4.46117 0.179925 4.66614 0.410862C4.87111 0.641721 4.98291 0.940628 4.98136 1.25V12.5H6.32996L7.65683 0.930471C7.68788 0.674612 7.81055 0.439143 8.00465 0.268284C8.19099 0.0975028 8.43943 0.00313558 8.62577 0.00313558C8.95186 0.00313558 9.2003 0.0975028 9.38664 0.268284C9.58074 0.439143 9.63509 0.674612 9.73602 0.930471L11.0637 12.5H12.3525V1.25C12.3525 0.942972 12.531 0.64594 12.663 0.415393C12.9348 0.184847 13.2143 0.037034 13.5171 0.000205889C13.8199 -0.0332941 14.0606 0.0483621 14.3789 0.229378C14.6273 0.410393 14.7981 0.677893 14.868 0.980471L17.205 11.6172ZM34.7826 36.875C34.7826 37.7031 34.4565 38.5 33.8742 39.0859C33.2919 39.6719 32.5 40 31.677 40C30.854 40 30.0621 39.6719 29.4798 39.0859C28.8975 38.5 28.5714 37.7031 28.5714 36.875V27.5H27.2671C26.6149 27.5 25.9627 27.3672 25.3571 27.1172C24.7593 26.8594 24.2081 26.4922 23.7422 26.0234C23.2842 25.5547 22.9192 25 22.6009 24.3906C22.4224 23.7813 22.2981 23.125 22.2981 22.4688L22.2904 13.5781C22.3602 10.6953 23.222 7.875 24.8292 5.49063C26.4363 3.10235 28.7112 1.25391 31.3742 0.182737C31.6848 0.0313309 32.1584 -0.0250129 32.5621 0.0186825C32.9658 0.0623621 33.354 0.20469 33.6879 0.433284C34.0217 0.661878 34.3012 0.969534 34.4876 1.33047C34.6817 1.69063 34.7127 2.09219 34.7826 2.5V36.875Z'
                fill={color ?? 'white'}
            />
        </svg>
    );
}

type Props = {
    width?: number;
    height?: number;
    color?: string;
    title?: string;
};
