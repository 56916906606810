/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import type { AverageDeliveryTimeByDeliveryMethodAndDay } from 'src/api/letseatmanager/restaurantDashboard/getDeliveryRestaurantStatisticsApi';
import { ResponsiveLineCanvas } from 'src/components/charts/ResponsiveLineCanvas';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function AverageTimeGraph({ averageTimeData }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const deliveryTimeByAppAndDay = [
        {
            id: 'delivery',
            color: 'hsl(127, 70%, 50%)',
            data: averageTimeData.map((item) => ({
                x: item.createdAt,
                y: item.timeAverage,
            })),
        },
    ];
    const hasAverageTimeData = deliveryTimeByAppAndDay?.length > 0;

    if (!hasAverageTimeData) return null;

    return (
        <div className={classes.lineGraphicContainer}>
            <ResponsiveLineCanvas
                data={deliveryTimeByAppAndDay}
                tooltip={({ point }: { point: { data: { x: string; y: string } } }) => (
                    <div className={classes.tooltip}>
                        <span className={classes.tooltipText}>{translate(`Date: @date`, { date: point.data.x })}</span>
                        <span className={classes.tooltipText}>{translate(`Total: @total`, { total: formatAsCurrencyNumber(point.data.y) })}</span>
                    </div>
                )}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    kpisContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: 12,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
    },
    lineGraphicContainer: {
        height: '45vh',
    },
    kpisContainerMobile: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.between('md', 'lg')]: {
            display: 'none',
        },
    },
    selectMobile: {
        display: 'block',
        [theme.breakpoints.between('md', 'lg')]: {
            display: 'none',
        },
    },
    kpisContainerDesktop: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.between('xs', 'sm')]: {
            display: 'none',
        },
    },
    tooltip: {
        color: `#0D3037`,
        padding: 5,
        textTransform: 'capitalize',
        borderRadius: '10px',
        backgroundColor: 'whitesmoke',
    },
    tooltipText: {
        textTransform: 'uppercase',
        display: 'flex',
        flexDirection: 'column',
    },
}));

type Props = {
    averageTimeData: Array<AverageDeliveryTimeByDeliveryMethodAndDay>;
};
