/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { DropDown, DropDownItem } from '@pidedirecto/ui';
import { EllipsisVerticalIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { NetworkConnectivityIndicator } from 'src/components/app/appBar/NetworkConnectivityIndicator';
import { RightToolBarCurrencyContextualMenuItem } from 'src/components/app/appBar/RightToolBarCurrencyContextualMenuItem';
import { RightToolBarSalesContextualMenuItem } from 'src/components/app/appBar/RightToolBarSalesContextualMenuItem';
import { RightToolBarSignOutContextualMenuItem } from 'src/components/app/appBar/RightToolBarSignOutContextualMenuItem';
import { RightToolBarUpdateChangesContextualMenuItem } from 'src/components/app/appBar/RightToolBarUpdateChangesContextualMenuItem';
import { SignOutPinCodeUserButton } from 'src/components/app/appBar/SignOutPinCodeUserButton';
import { ToggleSoundButton } from 'src/components/app/appBar/ToggleSoundButton';
import { UserName } from 'src/components/app/appBar/UserName';
import { translate } from 'src/i18n/translate';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function RightToolBarContextualMenu(): React.ReactElement | null {
    const classes = useStyles();

    const viewUser = useSelector((state) => state.authentication.viewUser);
    const internalUser = useSelector((state) => state.authentication.internalUser);
    const kitchensUser = useSelector((state) => state.authentication.kitchensUser);
    const restaurantUser = useSelector((state) => state.authentication.restaurantUser);
    const kitchenDisplayScreenUser = useSelector((state) => state.authentication.kitchenDisplayScreenUser);
    const waiterUser = useSelector((state) => state.authentication.waiterUser);
    const cashierUser = useSelector((state) => state.authentication.cashierUser);

    const showSpecialActions = internalUser || viewUser || kitchensUser;
    const showSpecialActionsToExternalUsers = restaurantUser || kitchenDisplayScreenUser || waiterUser || cashierUser;

    if (!showSpecialActions && !showSpecialActionsToExternalUsers) return null;

    return (
        <DropDown content={<EllipsisVerticalIcon title={translate('Menu')} />} variant='icon' classes={{ button: classes.contextualMenuButton, dropdown: classes.dropDown }} position='right'>
            <DropDownItem classes={{ container: classNames(classes.desktopHidden, classes.menuItem) }}>
                <UserName />
            </DropDownItem>
            <DropDownItem classes={{ container: classNames(classes.desktopHidden, classes.menuItem) }}>
                <NetworkConnectivityIndicator />
            </DropDownItem>
            <DropDownItem classes={{ container: classNames(classes.desktopHidden, classes.menuItem) }}>
                <ToggleSoundButton />
            </DropDownItem>
            <DropDownItem classes={{ container: classNames(classes.desktopHidden, classes.menuItem) }}>
                <SignOutPinCodeUserButton />
            </DropDownItem>
            <div className={classNames(classes.divider, classes.desktopHidden)} />
            <RightToolBarCurrencyContextualMenuItem />
            <RightToolBarUpdateChangesContextualMenuItem />
            <RightToolBarSalesContextualMenuItem />
            <RightToolBarSignOutContextualMenuItem />
        </DropDown>
    );
}

const useStyles = makeStyles((theme) => ({
    menuItem: {
        paddingTop: 0,
        paddingBottom: 0,
        minWidth: 250,
    },
    contextualMenuButton: {
        height: 48,
        width: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.palette.border.primary}`,
        color: theme.palette.text.brand,
        borderRadius: 6,
    },
    dropDown: {
        maxHeight: 'unset',
    },
    desktopHidden: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    mobileDivider: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        },
    },
    divider: {
        borderTop: `1px solid ${theme.palette.border.primary}`,
        width: '100%',
        height: 1,
    },
}));
