/**
 * @prettier
 */
import { translate } from 'src/i18n/translate';

const PluralUnitExceptions = {
    BOX: 'BOXES',
} as const;

export function translateUnits(number: number, unitOfMeasurement: string): string {
    if (!number || !unitOfMeasurement) {
        return `0`;
    }
    if (number === 1) {
        return `${number} ${translate(unitOfMeasurement)}`;
    }

    const pluralUnit = PluralUnitExceptions[unitOfMeasurement as keyof typeof PluralUnitExceptions] || `${unitOfMeasurement}S`;

    return `${number} ${translate(`UnitOfMeasurements.${pluralUnit}`)}`;
}
