/**
 * @prettier
 */
import { DriverArrivesAtStoreTime, DriverArrivesAtStoreTimes } from 'src/constants/DriverArrivesAtStoreTime';

export function getDriverArrivesAtStoreTimeInterval(driverArrivesAtStoreTime: DriverArrivesAtStoreTime): string {
    switch (driverArrivesAtStoreTime) {
        case DriverArrivesAtStoreTimes.TIME_30_40_MIN:
            return '30-40';
        case DriverArrivesAtStoreTimes.TIME_40_50_MIN:
            return '40-50';
        case DriverArrivesAtStoreTimes.TIME_50_60_MIN:
            return '50-60';
        default:
            return '15-30';
    }
}
