/**
 * @prettier
 */
import * as React from 'react';

export function BellIcon({ color, width, height, title }: Props): React.ReactElement {
    return (
        <svg width={width || 16} height={height || 18} viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <path
                d='M10.3807 13.235C11.9334 13.0512 13.459 12.6847 14.9257 12.1433C13.6836 10.7673 12.9972 8.97875 12.9999 7.125V6.54167V6.5C12.9999 5.17392 12.4731 3.90215 11.5354 2.96447C10.5978 2.02678 9.32599 1.5 7.99991 1.5C6.67383 1.5 5.40206 2.02678 4.46438 2.96447C3.52669 3.90215 2.99991 5.17392 2.99991 6.5V7.125C3.00242 8.97886 2.31573 10.7675 1.07324 12.1433C2.51741 12.6767 4.03991 13.0475 5.61908 13.235M10.3807 13.235C8.79906 13.4226 7.20075 13.4226 5.61908 13.235M10.3807 13.235C10.5008 13.6099 10.5307 14.0078 10.4679 14.3964C10.4051 14.785 10.2514 15.1533 10.0194 15.4713C9.78735 15.7893 9.4835 16.048 9.13257 16.2263C8.78164 16.4047 8.39356 16.4976 7.99991 16.4976C7.60626 16.4976 7.21818 16.4047 6.86725 16.2263C6.51632 16.048 6.21247 15.7893 5.98043 15.4713C5.74838 15.1533 5.59471 14.785 5.53192 14.3964C5.46913 14.0078 5.49899 13.6099 5.61908 13.235'
                stroke={color || 'currentColor'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    color?: string;
    title?: string;
    width?: number;
    height?: number;
};
