/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { ReleaseNotesVm } from 'src/types/ReleaseNotesVm';

export async function findReleasesNotesApi(): ApiSauceResponse<Array<ReleaseNotesVm>> {
    return letseatadminApiMethod('releaseNotes/findReleasesNotesApi');
}
