/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { CustomAlertDialogType } from 'src/constants/CustomAlertDialogType';
import type { CustomAlertDialogId } from 'src/types/Id';

export async function changeCustomAlertDialogApi(request: ChangeCustomAlertDialogApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('customAlertDialogs/changeCustomAlertDialogApi', request);
}

type ChangeCustomAlertDialogApiRequest = {
    customAlertDialogId: CustomAlertDialogId;
    title: string;
    text: string;
    secondsToAccept: string;
    customAlertDialogType: CustomAlertDialogType;
};
