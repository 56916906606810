/**
 * @prettier
 */
import type { LogEventVm } from 'src/api/letseatmanager/types/LogEventVm';
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function getMenuLogEventsApi(request: GetIntegrationsLogEventsApiRequest): ApiSauceResponse<Array<LogEventVm>> {
    return letseatmanagerApiMethod('logEvent/getMenuLogEventsApi', request);
}

export type GetIntegrationsLogEventsApiRequest = {
    restaurantId: RestaurantId;
};
