/**
 * @prettier
 */
import { history } from 'src/config/history';
import { RoutePaths } from 'src/constants/RoutePath';
import type { SubscriptionId } from 'src/types/Id';
import { RegExps } from 'src/utils/RegExps';

export function openSubscriptionPage(subscriptionId?: SubscriptionId): void {
    if (!subscriptionId) return;
    history.push({
        pathname: RoutePaths.ADMIN_SUBSCRIPTION.replace(`:subscriptionId(${RegExps.uuid})`, subscriptionId),
        search: history.location.search,
    });
}
