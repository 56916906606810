/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentId } from 'src/types/Id';

export async function getFacturamaInvoiceUri(request: SendInvoiceApiRequest): ApiSauceResponse<GetFacturamaInvoiceUriResponse> {
    return await letseatmanagerApiMethod('subscription/getFacturamaInvoiceUriApi', request);
}

export type SendInvoiceApiRequest = {
    paymentId: PaymentId;
    documentType: 'xml' | 'pdf';
};

export type GetFacturamaInvoiceUriResponse = {
    invoiceUri: string;
};
