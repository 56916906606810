/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Loader } from '@pidedirecto/ui';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { getDriverApi } from 'src/api/letseatadmin/driver/getDriverApi';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { Spacing } from 'src/components/spacing/Spacing';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { DriverId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { fullName } from 'src/utils/customer/fullName';
import { deleteUrlQueryParameter } from 'src/utils/history/deleteUrlQueryParameter';
import { getUrlQueryParameter } from 'src/utils/history/getUrlQueryParameter';
import { required } from 'src/utils/Validator';

export function DriverSendDialog(props: Props): React.ReactElement {
    const classes = useStyles();

    const history = useHistory();

    const [open, setOpen] = useState(false);
    const [driver, setDriver] = useState<{ driverId: DriverId; name: string }>(INITIAL_STATE.driver);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setError] = useState(null);

    const driverId: DriverId = getUrlQueryParameter(SEND_NOTIFICATION, history.location);
    useEffect(() => {
        async function openDriverDialog() {
            if (driverId) {
                await openDialog(driverId);
            }
        }

        openDriverDialog();
    }, [driverId]);

    const handleClose = () => {
        if (submitting) {
            return;
        }
        if (history.length <= 2) {
            history.replace({
                path: history.location.pathname,
                search: deleteUrlQueryParameter(SEND_NOTIFICATION),
            });
            return;
        }
        setOpen(false);
        history.goBack();
    };

    const handleExited = () => {
        setDriver(INITIAL_STATE.driver);
        setOpen(false);
        setLoading(false);
        setSubmitting(false);
    };

    const openDialog = async (driverId: DriverId) => {
        setOpen(true);
        await load(driverId);
    };

    const load = async (driverId: DriverId) => {
        setLoading(true);
        const response = await getDriverApi({ driverId });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setOpen(false);
            return;
        }
        const driver = response.data;
        setDriver({
            driverId: driver.driverId,
            name: fullName(driver.firstName, driver.lastName),
        });
    };

    const onSubmit = async (form: any) => {
        setSubmitting(true);
        const response = await letseatmanagerApiDeprecated.admin.sendNotificationToDriver({
            driverId: form.driver.driverId,
            title: form.title,
            body: form.body,
        });
        setSubmitting(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        handleClose();
    };

    return (
        <Dialog aria-labelledby='driver-send-dialog-title' fullWidth scroll='paper' open={open} onClose={handleClose} onExited={handleExited}>
            <Form
                onSubmit={onSubmit}
                initialValues={{
                    driver: driver,
                    title: props.template ? props.template.title || '' : '',
                    body: props.template ? props.template.body || '' : '',
                }}
                render={({ handleSubmit, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                        <DialogTitle id='driver-send-dialog-title'>{translate('Send Notification To Driver').toUpperCase()}</DialogTitle>
                        <DialogContent>
                            {loading ? (
                                <Loader size={40} loading={true} />
                            ) : (
                                <>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Field name='driver.driverId' component={TextField} label={translate('Driver Id')} fullWidth disabled />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Field name='driver.name' component={TextField} label={translate('Name')} fullWidth disabled />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Field
                                                name='title'
                                                component={TextField}
                                                label={translate('Title')}
                                                helperText={`${translate('@name will be replaced with drivers first name or Socio if it does not exist')}`}
                                                fullWidth
                                                disabled={submitting}
                                                required
                                                validate={required}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Field
                                                name='body'
                                                component={TextField}
                                                multiline
                                                label={translate('Body')}
                                                helperText={`${translate('@name will be replaced with drivers first name or Socio if it does not exist')}`}
                                                fullWidth
                                                disabled={submitting}
                                                required
                                                validate={required}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container justify='space-around'>
                                        <Grid item>
                                            <Spacing units={8} />
                                            {errorMessage && (
                                                <Typography display='block' color='error'>
                                                    {errorMessage}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} disabled={submitting}>
                                {translate('Cancel').toUpperCase()}
                            </Button>
                            <Button color='primary' type='submit' disabled={submitting}>
                                {submitting ? translate('Sending').toUpperCase() : translate('Send').toUpperCase()}
                            </Button>
                        </DialogActions>
                        {submitting && <LinearProgress className={classes.linearProgress} />}
                    </form>
                )}
            />
        </Dialog>
    );
}

export const SEND_NOTIFICATION = 'send-notification-driver';

const useStyles = makeStyles((theme) => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
}));

const INITIAL_STATE = {
    open: false,
    driver: {
        driverId: '' as any,
        name: '',
    },
    loading: false,
    submitting: false,
} as const;

type Props = {
    template?: {
        title: string;
        body: string;
    };
};
