/**
 * @prettier
 */
import type { PaymentLinkVm } from 'src/api/letseatmanager/paymentLink/createPaymentLinkApi';
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function findPaymentLinksApi(request: PaymentLinkRequest): ApiSauceResponse<PaymentLinksVm> {
    return letseatmanagerApiMethod('paymentLink/findPaymentLinksApi', request);
}

type PaymentLinkRequest = {
    restaurantIds: Array<RestaurantId>;
};

export type PaymentLinksVm = {
    paymentLinks: Array<PaymentLinkVm>;
};
