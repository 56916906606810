/**
 * @prettier
 */
import { KEY_NAME } from 'src/localStorage/findPosMenuOfflineInLocalStorage';
import type { PosMenuVm } from 'src/types/PosMenuVm';

export function setPosMenuOfflineInLocalStorage(value: PosMenuVm): void {
    try {
        window.localStorage.setItem(KEY_NAME, JSON.stringify(value));
    } catch (e: any) {
        console.log(e);
    }
}
