/**
 * @prettier
 */
import { Text } from '@pidedirecto/ui';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { OngoingEatHereOrderOption } from 'src/modules/pos/order/components/moveOrderItemsDialog/OngoingEatHereOrderOption';
import { OrderVm } from 'src/types/OrderVm';
import { isPosApp } from 'src/utils/app/isPosApp';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function OngoingEatHereOrdersList(): React.ReactElement {
    const currentOrders = useSelector((state) => state.app2.currentOrders);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);

    const posOrdersToMoveOrderItems = currentOrders?.filter(
        (order: OrderVm) =>
            !isDeliveryOrder(order.orderType) &&
            order.orderId !== pendingOrder?.orderId &&
            isPosApp(order.app) &&
            order.restaurant?.restaurantId === restaurantId &&
            !order.courses?.length &&
            !order.customers?.length
    );

    if (!posOrdersToMoveOrderItems?.length) return <Text>{translate('There are no orders to move the products')}</Text>;

    return (
        <>
            {posOrdersToMoveOrderItems?.map((order) => (
                <OngoingEatHereOrderOption key={order.orderId} order={order} />
            ))}
        </>
    );
}
