/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import amex from 'payment-icons/min/flat/amex.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import visa from 'payment-icons/min/flat/visa.svg';
import unknown from 'payment-icons/min/outline/default.svg';
import * as React from 'react';
import { CardBrand, CardBrands } from 'src/constants/CardBrand';
import { CardPriorities, CardPriority } from 'src/constants/CardPriority';
import { translate } from 'src/i18n/translate';
import type { SubscriptionCardVm } from 'src/types/SubscriptionCardVm';

export function SubscriptionCard({ card, cardPriority, loading, admin, addNewCard, removeCard, disabled }: Props): React.ReactElement {
    const classes = useStyles();

    const getCardIconBrand = (brand: CardBrand) => {
        switch (brand) {
            case CardBrands.VISA:
                return visa;
            case CardBrands.MASTERCARD:
                return mastercard;
            case CardBrands.AMERICAN_EXPRESS:
                return amex;
            default:
                return unknown;
        }
    };

    if (!card?.cardId) {
        return (
            <div className={classes.cardContainer}>
                <span className={classes.cardTitle}>{cardPriority === CardPriorities.PRIMARY ? translate('Primary').toUpperCase() : translate('Back Up').toUpperCase()}</span>
                <Button classes={{ root: classes.button }} onClick={() => addNewCard?.(cardPriority)} disabled={loading || disabled}>
                    <div className={classes.addCardButton}>
                        <span className={classes.textBold}>+ {cardPriority === CardPriorities.PRIMARY ? translate('Add primary payment method') : translate('Add back up payment method')}</span>
                        {cardPriority === CardPriorities.BACKUP && (
                            <span className={classes.text}>{translate('When the primary payment method fails, a backup payment method pays the balance automatically')}</span>
                        )}
                    </div>
                </Button>
            </div>
        );
    }

    return (
        <div className={classes.cardContainer}>
            <span className={classes.cardTitle}>{cardPriority === CardPriorities.PRIMARY ? translate('Primary').toUpperCase() : translate('Back Up').toUpperCase()}</span>
            <div className={classes.cardInfoContainer}>
                <div className={classes.cardInfo}>
                    <div className={classes.brandContainer}>
                        <img src={getCardIconBrand(card?.brand)} style={{ height: 30 }} />
                    </div>
                    <div className={classes.cardNumberContainer}>
                        <span className={classes.cardNumber}>{card?.cardNumber}</span>
                        <span className={classes.expiry}>
                            {translate('Expires')} {card?.expiry.slice(0, 2)}/{card?.expiry.slice(2, 4)}
                        </span>
                    </div>
                </div>
                {!admin && (
                    <div className={classes.cardButtonsContainer}>
                        <Button onClick={() => removeCard?.(card?.cardId)} disabled={loading || disabled}>
                            <span className={classes.cardButton}>{translate('Remove')}</span>
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
    },
    cardTitle: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.secondary.contrastText,
        marginBottom: 20,
    },
    addCardButton: {
        border: `1px dashed ${theme.palette.secondary.contrastText}`,
        width: '100%',
        height: 170,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0 5%',
    },
    cardInfoContainer: {
        width: '100%',
        height: 170,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    cardInfo: {
        height: 120,
        backgroundColor: theme.palette.secondary.light,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 20,
    },
    cardButtonsContainer: {
        backgroundColor: theme.palette.secondary.light,
        height: 40,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0 20px',
        justifyContent: 'flex-end',
    },
    brandContainer: {
        backgroundColor: 'white',
        width: 100,
        height: 70,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 20,
    },
    cardNumberContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    cardButton: {
        fontFamily: theme.typography.medium,
        color: theme.palette.primary.main,
        fontSize: 15,
        textTransform: 'none',
    },
    cardNumber: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
    },
    expiry: {
        fontFamily: theme.typography.regular,
        fontSize: 13,
    },
    button: {
        padding: 0,
        width: '100%',
    },
    textBold: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.secondary.contrastText,
        fontSize: 15,
        textTransform: 'none',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 13,
        textTransform: 'none',
    },
}));

type Props = {
    card?: SubscriptionCardVm;
    cardPriority: CardPriority;
    loading?: boolean;
    disabled?: boolean;
    admin?: boolean;
    addNewCard?: any;
    removeCard?: any;
};
