/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import { EditIcon, TrashCanIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { findUserRolesApi } from 'src/api/letseatmanager/userRole/findUserRolesApi';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { RoutePaths } from 'src/constants/RoutePath';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { RemoveUserRoleDialog } from 'src/scenes/letseatmanager/userManagement/RemoveUserRoleDialog';
import { UserRoleId } from 'src/types/Id';
import { UserRoleVm } from 'src/types/UserRoleVm';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { RegExps } from 'src/utils/RegExps';

export function RolesTable(): React.ReactElement {
    const classes = useStyles();
    const history = useHistory();

    const [removeUserRoleDialogState, setRemoveUserRoleDialogState] = useState<{ open: boolean; userRole: UserRoleVm | undefined }>({ open: false, userRole: undefined });

    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const timeZone = useSelector((state) => state.app.restaurant?.timeZone);
    const brandId = useSelector((state) => state.app.restaurant?.brandId);

    const [loadingRoles, roles, refreshRoles] = useLoadApi(findUserRolesApi, { restaurantIds, brandId }, { initialValue: [], dependencies: [restaurantIds] });

    const removeRole = async (userRoleId: UserRoleId) => {
        const userRole = roles.find((role) => role.userRoleId === userRoleId);
        setRemoveUserRoleDialogState({ open: true, userRole });
    };

    const removeRoleSucceeded = () => {
        setRemoveUserRoleDialogState({ open: false, userRole: undefined });
        refreshRoles();
    };

    const closeRemoveUserRoleDialog = () => {
        setRemoveUserRoleDialogState({ open: false, userRole: undefined });
    };

    const gotoCreateRole = () => {
        history.push({
            pathname: RoutePaths.CREATE_USER_ROLE,
            search: history.location.search,
        });
    };

    const gotoChangeRole = (userRoleId: any) => {
        history.push({
            pathname: RoutePaths.CHANGE_USER_ROLE.replace(`:userRoleId(${RegExps.uuid})`, userRoleId),
            search: history.location.search,
        });
    };

    return (
        <div className={classes.container}>
            <RemoveUserRoleDialog open={removeUserRoleDialogState.open} userRole={removeUserRoleDialogState.userRole} onSuccess={removeRoleSucceeded} onClose={closeRemoveUserRoleDialog} />
            <Table
                title={translate('Roles')}
                loading={loadingRoles}
                data={roles.map((role) => {
                    return {
                        userRoleId: role.userRoleId,
                        roleName: role.roleName,
                        createdAt: formatDateTimeString(role.createdAt, timeZone ?? TimeZones.AMERICA_MONTERREY),
                        delete: role.userRoleId,
                        navigate: role.userRoleId,
                    };
                })}
                columns={[
                    {
                        name: 'userRoleId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'roleName',
                        label: translate('Role'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'createdAt',
                        label: translate('Created At'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'navigate',
                        label: translate('Show more'),
                        options: {
                            filter: false,
                            customBodyRender: (userRoleId: UserRoleId) => (
                                <Button classes={{ button: classes.editIcon }} variant={'icon'} onClick={() => gotoChangeRole(userRoleId)}>
                                    <EditIcon title='show more icon' />
                                </Button>
                            ),
                        },
                    },
                    {
                        name: 'delete',
                        label: translate('Remove'),
                        options: {
                            filter: false,
                            customBodyRender: (userRoleId: UserRoleId) => (
                                <Button classes={{ button: classes.deleteIcon }} variant={'icon'} onClick={() => removeRole(userRoleId)}>
                                    <TrashCanIcon title='delete icon' />
                                </Button>
                            ),
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    filterType: 'checkbox',
                    selectableRows: 'none',
                    rowsPerPage: 100,
                    filter: false,
                    print: false,
                    viewColumns: false,
                    download: false,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={refreshRoles} />
                            <CreateToolbarButton onClick={gotoCreateRole} />
                        </>
                    ),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: 20,
    },
    editIcon: {
        color: theme.palette.icons.brand,
    },
    deleteIcon: {
        color: theme.palette.text.danger,
    },
}));
