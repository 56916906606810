/**
 * @prettier
 */
import { FormSelect } from '@pidedirecto/ui/form';
import * as React from 'react';
import { OrderTypes, type OrderType } from 'src/constants/OrderType';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function FormOrderTypeSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, multiselectable }: Props): React.ReactElement {
    const roomServiceEnabled = useSelector((state) => state.app.restaurant.roomServiceEnabled);

    const orderTypes: Array<OrderType> = [OrderTypes.TABLE_ORDER, OrderTypes.DELIVERY_ORDER, OrderTypes.TAKE_AWAY_ORDER];

    if (roomServiceEnabled) orderTypes.push(OrderTypes.ROOM_SERVICE_ORDER);

    const options = required
        ? orderTypes.map((orderType: OrderType) => ({ label: translate(`OrderTypes.${orderType}`), value: orderType }))
        : [{ label: '', value: null }, ...orderTypes.map((orderType: OrderType) => ({ label: translate(`OrderTypes.${orderType}`), value: orderType }))];

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            multiselectable={multiselectable}
            required={{
                value: !!required,
                message: translate('This field is required'),
            }}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?: boolean;
    multiselectable?: boolean;
};
