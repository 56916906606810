/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CashRegisterWithdrawReason } from 'src/constants/CashRegisterWithdrawReason';
import { CashRegisterTransactionVm } from 'src/types/CashRegisterTransactionVm';
import type { CashRegisterCustomReason, CashRegisterId, ManagerUserId } from 'src/types/Id';

export async function withdrawCashRegisterApi(request: WithdrawCashRegisterApiRequest): ApiSauceResponse<CashRegisterTransactionVm> {
    return letseatmanagerApiMethod('pos/withdrawCashRegisterApi', request);
}

export type WithdrawCashRegisterApiRequest = {
    cashRegisterId?: CashRegisterId;
    managerUserId?: ManagerUserId;
    amount: string;
    reason: CashRegisterWithdrawReason | CashRegisterCustomReason;
    comment?: string;
    createdAt?: Date;
};
