/**
 * @prettier
 */
import { RoutePaths } from 'src/constants/RoutePath';
import type { RestaurantId, SubscriptionId } from 'src/types/Id';
import { RegExps } from 'src/utils/RegExps';

export function openSubscriptionPageInNewTab(subscriptionId?: SubscriptionId, restaurantId?: RestaurantId): void {
    if (!subscriptionId) return;
    if (!restaurantId) {
        window.open(`${RoutePaths.ADMIN_SUBSCRIPTION.replace(`:subscriptionId(${RegExps.uuid})`, subscriptionId)}`, '_blank');
    }
    window.open(`${RoutePaths.ADMIN_SUBSCRIPTION.replace(`:subscriptionId(${RegExps.uuid})`, subscriptionId)}?restaurant=${restaurantId}`, '_blank');
}
