/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function AddCreditsToolbarButton({ tooltip, onClick, enabledForUserTypeView = false }: Props): React.ReactElement {
    const classes = useStyles();
    const viewUser = useSelector((state) => state.authentication.viewUser);

    return (
        <Tooltip title={tooltip || translate('Add Credits')}>
            <Button icon classes={{ button: classes.button }} onClick={onClick} disabled={viewUser && !enabledForUserTypeView}>
                <AttachMoneyIcon />
            </Button>
        </Tooltip>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        verticalAlign: 'center',
        display: 'inline-flex',
    },
}));

type Props = {
    tooltip?: string;
    onClick: any;
    enabledForUserTypeView?: boolean;
};
