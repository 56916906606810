/**
 * @prettier
 *
 */
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { SectionTitle } from 'src/components/title/SectionTitle';
import { CommandSections } from 'src/constants/CommandSection';
import { translate } from 'src/i18n/translate';

export function CommandGeneralInfoSettings({ disabled }: Props): React.ReactElement {
    return (
        <section>
            <SectionTitle title={translate('General Information')} />
            <Grid container xs={12}>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch name={CommandSections.ORDER_ID} label={translate(`CommandSections.${CommandSections.ORDER_ID}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={CommandSections.CONSECUTIVE_ID} label={translate(`CommandSections.${CommandSections.CONSECUTIVE_ID}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={CommandSections.ORDER_TYPE} label={translate(`CommandSections.${CommandSections.ORDER_TYPE}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={CommandSections.CREATED_AT} label={translate(`CommandSections.${CommandSections.CREATED_AT}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={CommandSections.CUSTOMER_NAME} label={translate(`CommandSections.${CommandSections.CUSTOMER_NAME}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={CommandSections.PAYMENT_METHOD} label={translate(`CommandSections.${CommandSections.PAYMENT_METHOD}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch
                        disabled={disabled}
                        name={CommandSections.TABLE_NUMBER}
                        label={translate(`CommandSections.${CommandSections.TABLE_NUMBER}`)}
                        helperText={translate('Applicable for orders to eat here')}
                    />
                </Grid>
            </Grid>
        </section>
    );
}

type Props = {
    disabled: boolean;
};
