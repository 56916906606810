/**
 * @prettier
 */
import { isString } from 'src/utils/string/isString';

export function requireBooleanString(value: string | undefined | null): string {
    if (value === undefined) {
        throw new Error('Required value is undefined when it should be a boolean string');
    }
    if (value === null) {
        throw new Error('Required value is null when it should be a boolean string');
    }
    if (!isString(value)) {
        throw new Error(`Required value is ${value} when it should be a boolean string`);
    }
    if (value !== 'true' && value !== 'false') {
        throw new Error(`Required value is "${value}" when it should be a boolean string`);
    }
    return value;
}
