/**
 * @prettier
 */
import { FormControlLabel, FormGroup, Grid, makeStyles, Switch } from '@material-ui/core';
import { Button, Card } from '@pidedirecto/ui';
import { useNotification } from '@pidedirecto/ui/hooks';
import { PlusIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeTheRestaurantApi } from 'src/api/letseatadmin/theRestaurant/changeTheRestaurantApi';
import { createPeruvianCompanyInvoiceApi } from 'src/api/letseatmanager/invoice/createPeruvianCompanyInvoiceApi';
import { findRestaurantPaymentMethodsApi } from 'src/api/letseatmanager/restaurantPaymentMethod/findRestaurantPaymentMethodsApi';
import { ChangeRestaurantPaymentMethodDialog } from 'src/components/dialog/restaurantPaymentMethod/ChangeRestaurantPaymentMethodDialog';
import { CreateRestaurantPaymentDialog } from 'src/components/dialog/restaurantPaymentMethod/CreateRestaurantPaymentDialog';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { Form } from 'src/components/form/Form';
import { FormAppAutocompleteMultiple } from 'src/components/form/FormAppAutocompleteMultiple';
import { FormBankAutocomplete } from 'src/components/form/FormBankAutocomplete';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormNumberStringField } from 'src/components/form/FormNumberStringField';
import { FormPasswordField } from 'src/components/form/FormPasswordField';
import { FormPhoneNumberField } from 'src/components/form/FormPhoneNumberField';
import { FormRegimeFiscalSelect } from 'src/components/form/FormRegimeFiscalSelect';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { getDirtyFormValues } from 'src/components/form/utils/getDirtyFormValues';
import { PaymentMethodSettings } from 'src/components/PaymentMethodSettings';
import { Spacing } from 'src/components/spacing/Spacing';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { Countries } from 'src/constants/Country';
import { RegimesFiscal } from 'src/constants/RegimeFiscal';
import { RestaurantPaymentMethodTypes } from 'src/constants/RestaurantPaymentMethodType';
import { RestaurantPsps } from 'src/constants/RestaurantPsp';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { RestaurantPspSettings } from 'src/scenes/letseatadmin/theRestaurant/RestaurantPspSettings';
import { CustomPaymentMethodSettings } from 'src/scenes/letseatmanager/theRestaurant/CustomPaymentMethodSettings';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { appColors } from 'src/theme/AppColors';
import { RestaurantPaymentMethodVm } from 'src/types/RestaurantPaymentMethodVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isPeru } from 'src/utils/country/isPeru';
import { useAction } from 'src/utils/react/useAction';
import { useAskForPassword } from 'src/utils/react/useAskForPassword';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function RestaurantPaymentsSettings({ loading, restaurant, onChangeRestaurant, setIsDirtyForm }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const askForPassword = useAskForPassword();

    const {
        formState: { dirtyFields },
        watch,
        formState,
    } = form;

    const notification = useNotification();
    const creditCardPaymentsEnabled = watch('creditCardPaymentsEnabled');
    const cashPaymentsEnabled = watch('cashPaymentsEnabled');
    const paymentLinkPaymentsEnabled = watch('paymentLinksEnabled');

    const [showPrivateInfo, setShowPrivateInfo] = useState(false);
    const [restaurantPaymentMethodsEnabled, setRestaurantPaymentMethodsEnabled] = useState(false);
    const [changeRestaurantPaymentMethodDialogState, setChangeRestaurantPaymentMethodDialogState] = useState<{ open: boolean; restaurantPaymentMethod: RestaurantPaymentMethodVm | undefined }>({
        open: false,
        restaurantPaymentMethod: undefined,
    });
    const [createRestaurantPaymentDialogState, setCreateRestaurantPaymentDialogState] = useState<boolean>(false);

    const appLoading = useSelector((state) => state.app.loading);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantCountry = useSelector((state) => state.app.restaurant.country);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);
    const { reloadRestaurant } = useReloadRestaurant();

    const [loadingRestaurantPaymentMethods, restaurantPaymentMethods, loadRestaurantPaymentMethods] = useLoadApi(
        findRestaurantPaymentMethodsApi,
        { restaurantId },
        { dependencies: [restaurantId], requiredValues: [restaurant.restaurantPaymentMethodsEnabled], initialValue: [] }
    );

    const restaurantPayments = restaurantPaymentMethods?.filter((restaurantPaymentMethod) => restaurantPaymentMethod.restaurantPaymentMethodType === RestaurantPaymentMethodTypes.RESTAURANT);
    const paymentMethods = restaurantPaymentMethods?.filter((restaurantPaymentMethod) => restaurantPaymentMethod.restaurantPaymentMethodType === RestaurantPaymentMethodTypes.PIDE_DIRECTO);

    useEffect(() => {
        if (!loading)
            form.reset({
                restaurantId: restaurant.restaurantId ?? null,
                cashPaymentsEnabled: restaurant.cashPaymentsEnabled ?? null,
                ecommerceCashPaymentsToEatHereEnabled: restaurant.ecommerceCashPaymentsToEatHereEnabled ?? null,
                creditCardPaymentsEnabled: restaurant.creditCardPaymentsEnabled ?? null,
                paymentLinksEnabled: restaurant.paymentLinksEnabled ?? null,
                startDeliveryUntilPaymentLinkIsPaidEnabled: restaurant.startDeliveryUntilPaymentLinkIsPaidEnabled ?? null,
                cashPaymentsEnabledIn: restaurant.cashPaymentsEnabledIn ?? [],
                creditCartPaymentsEnabledIn: restaurant.creditCartPaymentsEnabledIn ?? [],
                paymentLinkPaymentsEnabledIn: restaurant.paymentLinkPaymentsEnabledIn ?? [],
                contact: restaurant.contact ?? null,
                restaurantPsp: restaurant.restaurantPsps?.[0] ?? RestaurantPsps.AUTO,
                restaurantPspsEnabled: restaurant.restaurantPspsEnabled ?? null,
                plexoIntegrationEnabled: restaurant.plexoIntegrationEnabled ?? null,
                plexoStoreId: restaurant.plexoStoreId ?? null,
                mercadoPagoPublicKey: restaurant.mercadoPagoPublicKey ?? null,
                mercadoPagoSecretKey: restaurant.mercadoPagoSecretKey ?? null,
                contactNumber: restaurant.contactNumber ?? null,
                establishmentsPhoneNumber: restaurant.establishmentsPhoneNumber ?? null,
                fiscalAddress: restaurant.fiscalAddress ?? null,
                addressOfEstablishment: restaurant.addressOfEstablishment ?? null,
                email: restaurant.email ?? null,
                bank: restaurant.bank ?? null,
                account: restaurant.account ?? null,
                clabe: restaurant.clabe ?? null,
                rfc: restaurant.rfc ?? null,
                regimeFiscal: restaurant.regimeFiscal ?? null,
                businessName: restaurant.businessName ?? null,
                contactName: restaurant.contactName ?? null,
                emailInvoices: restaurant.emailInvoices ?? null,
                zipCode: restaurant.zipCode ?? null,
                nubefactStoreId: restaurant.nubefactStoreId ?? null,
                nubefactToken: restaurant.nubefactToken ?? null,
                uruwareCodComercio: restaurant.uruwareCodComercio ?? null,
                uruwareCodTerminal: restaurant.uruwareCodTerminal ?? null,
                uruwareClave: restaurant.uruwareClave ?? null,
                uruwareAddressCode: restaurant.uruwareAddressCode ?? null,
                paymentInEstablishment: restaurant.paymentInEstablishment ?? null,
                commissionWebCard: restaurant.commissionWebCard ?? null,
                commissionWebCash: restaurant.commissionWebCash ?? null,
                commissionAppCard: restaurant.commissionAppCard ?? null,
                commissionAppCash: restaurant.commissionAppCash ?? null,
                commissionManualOrders: restaurant.commissionManualOrders ?? null,
                commissionOfficeDeliveryFixed: restaurant.commissionOfficeDeliveryFixed ?? null,
                commissionWebCardOnDelivery: restaurant.commissionWebCardOnDelivery ?? null,
                commissionAppCardOnDelivery: restaurant.commissionAppCardOnDelivery ?? null,
                rappiCargoDeliveryEnabled: restaurant.rappiCargoDeliveryEnabled ?? null,
                pideDirectoDeliveryEnabled: restaurant.pideDirectoDeliveryEnabled ?? null,
                uberDaasDeliveryEnabled: restaurant.uberDaasDeliveryEnabled ?? null,
                autoInvoiceOrdersEnabled: restaurant.autoInvoiceOrdersEnabled ?? null,
                cardOnDeliveryPaymentsEnabled: restaurant.cardOnDeliveryPaymentsEnabled ?? null,
                automaticInvoiceEnabled: restaurant.automaticInvoiceEnabled ?? null,
                paymentMethods: restaurant.paymentMethods ?? null,
                restaurantPaymentMethodsEnabled: restaurant.restaurantPaymentMethodsEnabled ?? null,
                financeReference: restaurant.financeReference ?? null,
                financeReferenceEnabled: restaurant.financeReferenceEnabled ?? null,
            });
    }, [loading]);

    useEffect(() => {
        setIsDirtyForm(Object.keys(dirtyFields).length !== 0);
    }, [formState]);

    const changePrivateInfo = async () => {
        const rightPassword = await askForPassword({ password: '1212' });
        if (!rightPassword) return;

        setShowPrivateInfo(true);
    };

    const onRestaurantPaymentMethodsEnabled = async () => {
        const rightPassword = await askForPassword({ password: '9012' });
        if (!rightPassword) return;
        setRestaurantPaymentMethodsEnabled(true);
    };

    const sendCompanyInfoToNubefact = async () => {
        const dirtyFormValues = getDirtyFormValues({ form, dirtyFields });

        const response = await createPeruvianCompanyInvoiceApi({
            restaurantId,
            ruc: requireValue(dirtyFormValues.ruc),
            businessName: requireValue(dirtyFormValues.businessName),
            emailInvoices: requireValue(dirtyFormValues.emailInvoices),
            phoneNumber: requireValue(dirtyFormValues.contactNumber),
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        await reloadRestaurant(restaurantId);
        await onChangeRestaurant();
        notification({ message: translate('Company added to nubefact') });
    };

    const openChangeRestaurantPaymentMethodDialogState = (restaurantPaymentMethod: RestaurantPaymentMethodVm) => {
        setChangeRestaurantPaymentMethodDialogState({ open: true, restaurantPaymentMethod });
    };
    const openCreateRestaurantPaymentDialogState = () => {
        setCreateRestaurantPaymentDialogState(true);
    };

    const closeCreateRestaurantPaymentDialogState = () => {
        setCreateRestaurantPaymentDialogState(false);
    };

    const closeChangeRestaurantPaymentMethodDialogState = () => {
        setChangeRestaurantPaymentMethodDialogState({ open: false, restaurantPaymentMethod: undefined });
    };

    const onSubmit = async (form: any) => {
        const dirtyFormValues = getDirtyFormValues({ form, dirtyFields });
        const response = await changeTheRestaurantApi({
            restaurantId,
            restaurantPsps: [form.restaurantPsp],
            ...dirtyFormValues,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        await reloadRestaurant(restaurantId);
        await onChangeRestaurant();
        showSnackbar({ message: translate('Saved changes') });
    };

    return (
        <div className={classes.paper}>
            <ChangeRestaurantPaymentMethodDialog
                open={changeRestaurantPaymentMethodDialogState.open}
                onClose={closeChangeRestaurantPaymentMethodDialogState}
                restaurantPaymentMethod={changeRestaurantPaymentMethodDialogState.restaurantPaymentMethod}
                onSuccess={loadRestaurantPaymentMethods}
            />
            <CreateRestaurantPaymentDialog open={createRestaurantPaymentDialogState} onClose={closeCreateRestaurantPaymentDialogState} onSuccess={loadRestaurantPaymentMethods} />
            <UpdatingContentProgress loading={loading || appLoading} />
            <Grid container direction='row' justify='flex-end'>
                <FormGroup>
                    <FormControlLabel control={<Switch onChange={changePrivateInfo} checked={showPrivateInfo} />} label={translate('Show Private Info')} />
                </FormGroup>
            </Grid>
            {!!restaurantPaymentMethods.length && (
                <>
                    <Card title={translate('Payment Methods')} classes={{ cardContent: classes.paymentsContent, cardContainer: classes.paymentsContainer }}>
                        {paymentMethods?.map((payment: RestaurantPaymentMethodVm) => {
                            return <PaymentMethodSettings paymentMethod={payment} onEdit={openChangeRestaurantPaymentMethodDialogState} />;
                        })}
                    </Card>
                    <Card title={translate('Payment Methods created')} classes={{ cardContent: classes.paymentsContent, cardContainer: classes.paymentsContainer }}>
                        {restaurantPayments?.map((payment: RestaurantPaymentMethodVm) => {
                            return <PaymentMethodSettings paymentMethod={payment} onEdit={openChangeRestaurantPaymentMethodDialogState} />;
                        })}
                        <div className={classes.addPaymentContainer}>
                            <Button variant={'outline'} onClick={openCreateRestaurantPaymentDialogState}>
                                <PlusIcon />
                                {translate('Add payment method')}
                            </Button>
                        </div>
                    </Card>
                </>
            )}
            <Form form={form} onSubmit={onSubmit} disabled={loading || appLoading}>
                <div className={classes.formContent}>
                    {!restaurant.restaurantPaymentMethodsEnabled && (
                        <>
                            <Grid item xs={12} md={6}>
                                <FormSwitch name='cashPaymentsEnabled' label={translate('Cash Payments')} required={!creditCardPaymentsEnabled} tooltip={translate('Activate cash payments')} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormSwitch
                                    name='ecommerceCashPaymentsToEatHereEnabled'
                                    label={translate('Cash Payments for Eat here')}
                                    tooltip={translate('Activate cash payments for Ecommerce for the option Eat here')}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormSwitch name='creditCardPaymentsEnabled' label={translate('Credit Card Payments')} required={!cashPaymentsEnabled} tooltip={translate('Activate card payments')} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormSwitch name='paymentLinksEnabled' label={translate('Payment Links')} tooltip={translate('Activate the option of payment links')} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormSwitch
                                    name='cardOnDeliveryPaymentsEnabled'
                                    label={translate('Pay With Card on Delivery')}
                                    tooltip={translate('Activate the field to take the terminal in the delivery of the orders (For shipping function)')}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} md={6}>
                        <FormSwitch name='startDeliveryUntilPaymentLinkIsPaidEnabled' label={translate('Search for delivery when completing payment link (manual orders)')} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSwitch
                            name='automaticInvoiceEnabled'
                            label={translate('Generate automatic invoice')}
                            tooltip={translate('Activate this field if you want an automatic invoice to be generated when paying for your subscription')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSwitch name='restaurantPaymentMethodsEnabled' label={translate('Show new restaurant administration Payment Methods')} disabled={!restaurantPaymentMethodsEnabled} />
                        <Button variant={'outline'} onClick={onRestaurantPaymentMethodsEnabled}>
                            {translate('Enable')}
                        </Button>
                    </Grid>
                    <Spacing units={4} />
                    {!restaurant.restaurantPaymentMethodsEnabled && <CustomPaymentMethodSettings onSuccess={onChangeRestaurant} paymentMethods={restaurant.paymentMethods} />}
                    <Spacing units={4} />
                    {!restaurant.restaurantPaymentMethodsEnabled && (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <h2 className={classes.sectionTitle}>{translate('Payment Method Channels Settings')}</h2>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormAppAutocompleteMultiple
                                    name='cashPaymentsEnabledIn'
                                    label={translate('Cash payments channels')}
                                    helperText={translate(`${cashPaymentsEnabled ? 'If this is blank will be all the channels' : 'Enabled cash payments'}`)}
                                    disabled={!cashPaymentsEnabled}
                                    tooltip={translate(
                                        'Select the channels in which the CASH payment method is received, for example, Direct Point of Sale, Request Direct Kiosk, etc. (if you do not want to select any, they will be accepted in all channels by default)'
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormAppAutocompleteMultiple
                                    name='creditCartPaymentsEnabledIn'
                                    label={translate('Credit card payments channels')}
                                    helperText={translate(`${creditCardPaymentsEnabled ? 'If this is blank will be all the channels' : 'Enabled credit card payments'}`)}
                                    disabled={!creditCardPaymentsEnabled}
                                    tooltip={translate(
                                        'Select the channels in which the CARD payment method is received, for example Direct Point of Sale, Order Manual Lets eat etc., (if none is selected, they will be accepted in all channels by default)'
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormAppAutocompleteMultiple
                                    name='paymentLinkPaymentsEnabledIn'
                                    label={translate('PaymentLink channels')}
                                    helperText={translate(`${paymentLinkPaymentsEnabled ? 'If this is blank will be all the channels' : 'Enabled paymentLinks'}`)}
                                    disabled={!paymentLinkPaymentsEnabled}
                                    tooltip={translate(
                                        'Select the channels in which the PAYMENT LINK payment method is received (if you do not select any, they will be accepted in all channels by default)'
                                    )}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid container alignItems='center' spacing={3} xs={12} sm={12}>
                        <Grid item md={6} xs={6}>
                            <FormTextField name='financeReference' label={translate('Finance Reference')} tooltip={translate('Reference number used by finance')} disabled={true} />
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <FormSwitch name='financeReferenceEnabled' label={translate('Finance Reference Enabled')} tooltip={translate('Enable this to use the finance reference')} />
                        </Grid>
                    </Grid>
                    <RestaurantPspSettings restaurant={restaurant} />
                    {restaurantCountry === Countries.UY && (
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='autoInvoiceOrdersEnabled' label={translate('Automatic invoice')} tooltip={translate('Activate this option to automatically invoice orders')} />
                        </Grid>
                    )}
                    <Grid container spacing={3} style={{ visibility: showPrivateInfo ? 'visible' : 'hidden' }}>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='contact' label={translate('Contact')} tooltip={translate('Write Local Contact Name')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormPhoneNumberField name='contactNumber' label={translate('Contact Number')} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormPhoneNumberField
                                name='establishmentsPhoneNumber'
                                label={translate('Establishments Phone Number')}
                                tooltip={translate('Write the telephone number of the establishment or branch')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='fiscalAddress' label={translate('Fiscal Address')} tooltip={translate('Write the fiscal address of your company.')} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='addressOfEstablishment' label={translate('Address of Establishment')} tooltip={translate('Write the address where your establishment is located.')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormEmailField name='email' label={translate('Email')} tooltip={translate('Write your contact email')} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormBankAutocomplete name='bank' label={translate('Bank')} tooltip={translate('Write the name of the bank where the deposits will be made.')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='account' label={translate('Account')} tooltip={translate('Enter the bank account number.')} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='clabe' label={translate('Account Number')} tooltip={translate('Write the 18-digit interbank code CLABE.')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField
                                name='rfc'
                                label={restaurantCountry === Countries.UY ? translate('RUC') : translate('RFC')}
                                tooltip={translate(
                                    'Write the Federal Taxpayer Registration Number of the physical or moral person (RFC), it will be used for billing and/or deposits. Notes: For Uruguay write the RUC'
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormTextField
                                name='businessName'
                                label={translate('Business Name')}
                                tooltip={translate('Enter the company name of the physical or legal person that will be used for billing and/or deposits.')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormRegimeFiscalSelect
                                name='regimeFiscal'
                                label={translate('Regime Fiscal')}
                                filter={[RegimesFiscal['601'], RegimesFiscal['612'], RegimesFiscal['626']]}
                                tooltip={translate('Write under which tax regime of the physical or moral person your company is located.')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='contactName' label={translate('Contact Name')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormEmailField name='emailInvoices' label={translate('Email Invoices')} tooltip={translate('Type Email that will be used to make and send the billing.')} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='zipCode' label={translate('Zip code')} required />
                        </Grid>
                    </Grid>
                    <Spacing units={6} />
                    {isPeru(restaurantCountry) && (
                        <Card title={translate('Invoice Peru')} classes={{ cardContent: classes.cardContent, cardTitle: classes.cardTitle }}>
                            <Grid container spacing={3}>
                                <Grid item xs={3} sm={3}>
                                    <FormTextField name='nubefactStoreId' label={translate('nubefactStoreId')} />
                                </Grid>
                                <Grid item xs={3} sm={3}>
                                    <FormTextField name='nubefactToken' label={translate('nubefactToken')} />
                                </Grid>
                                <Grid item xs={3} sm={3}>
                                    <Button variant={'outline'} onClick={sendCompanyInfoToNubefact} disabled>
                                        {translate('Upload to Nubefact')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Card>
                    )}
                    <Spacing units={6} />

                    {restaurantCountry === Countries.UY && (
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <FormTextField
                                    name='uruwareCodComercio'
                                    label={translate('Uruware Business Code')}
                                    tooltip={translate('Add the business key provided by your uruware provider if you require billing')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormTextField
                                    name='uruwareCodTerminal'
                                    label={translate('Uruware Cod Terminal')}
                                    tooltip={translate('Add the terminal key provided by your uruware provider if you require billing')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormPasswordField
                                    name='uruwareClave'
                                    label={translate('Uruware Password Web Service')}
                                    tooltip={translate('Add the web service code provided by your uruware provider if you require billing')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormTextField
                                    name='uruwareAddressCode'
                                    label={translate('Uruware Address Code')}
                                    tooltip={translate('Add the address code provided by your uruware provider if you require billing')}
                                />
                            </Grid>
                        </Grid>
                    )}

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <DeprecatedFormPercentNumberField
                                name='commissionWebCard'
                                label={translate('Commission Web Card %')}
                                tooltip={translate('Add the commission for card payments in ecommerce.')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DeprecatedFormPercentNumberField
                                name='commissionWebCash'
                                label={translate('Commission PD Web Cash %')}
                                tooltip={translate('Add the commission for cash payments in ecommerce')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DeprecatedFormPercentNumberField
                                name='commissionAppCard'
                                label={translate('Commission PD App Card %')}
                                tooltip={translate('Add the commission for card payments in the app')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DeprecatedFormPercentNumberField
                                name='commissionAppCash'
                                label={translate('Commission PD App Cash %')}
                                tooltip={translate('Add the commission for cash payments in the app')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DeprecatedFormPercentNumberField
                                name='commissionManualOrders'
                                label={translate('Manual Orders Commission % (Always Cash)')}
                                tooltip={translate('Add commission for manual orders')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DeprecatedFormPercentNumberField name='commissionWebCardOnDelivery' label={translate('Commission PD Web Card on delivery %')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DeprecatedFormPercentNumberField name='commissionAppCardOnDelivery' label={translate('Commission PD App Card on delivery %')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormNumberStringField name='commissionOfficeDeliveryFixed' label={translate('Office Delivery Fixed Commission')} />
                        </Grid>
                    </Grid>

                    <FormFixErrorHint />
                </div>

                <Grid container justify='center' spacing={3}>
                    <Grid item>
                        <FormSubmitButton text={translate('Change')} disabled={loading || appLoading} classes={{ container: classes.submitButton }} />
                    </Grid>
                </Grid>
            </Form>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
    },
    formContent: {
        padding: theme.spacing(3),
    },
    deleteButton: {
        color: appColors.text.dangerLight,
    },
    section: {
        width: '100%',
        borderRadius: 5,
        margin: '20px auto',
        border: `1px solid ${theme.palette.secondary.dark}`,
    },
    sectionTitleContainer: {
        backgroundColor: theme.palette.secondary.light,
        marginTop: 20,
    },
    sectionTitle: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
    },
    subtitlesubtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    cashRegisterCustomReasonItem: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        minWidth: 140,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #676060B2',
        borderRadius: 12,
        padding: '0 10px',
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
        color: theme.palette.secondary.contrastText,
        marginLeft: 10,
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    paymentMethod: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    kioskUserContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    kioskInfo: {
        display: 'flex',
        flexDirection: 'column',
    },
    integrationStoreStatusClosed: {
        fontFamily: theme.typography.semiBold,
        color: '#fa6684',
        fontSize: 13,
    },
    integrationStoreStatusOpen: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 13,
    },
    titleContainer: {
        display: 'flex',
    },
    submitButton: {
        zIndex: 1000,
    },
    paymentsContent: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 12,
    },
    paymentsContainer: {
        width: '97%',
        margin: 'auto',
        marginBottom: 20,
        border: 'none',
    },
    addPaymentContainer: {
        width: '100%',
    },
    cardContent: {
        padding: 12,
    },
    cardTitle: {
        marginLeft: 12,
    },
}));

type Props = {
    loading: boolean;
    restaurant: any;
    onChangeRestaurant: any;
    setIsDirtyForm: any;
};
