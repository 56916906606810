/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverManualEarningReason } from 'src/constants/DriverManualEarningReason';
import type { DriverManualEarningVm } from 'src/types/DriverManualEarningVm';
import type { DriverManualEarningId } from 'src/types/Id';

export async function changeDriverManualEarningApi(request: ChangeDriverManualEarningApiRequest): ApiSauceResponse<DriverManualEarningVm> {
    return letseatadminApiMethod('driverManualEarning/changeDriverManualEarningApi', request);
}

export type ChangeDriverManualEarningApiRequest = {
    driverManualEarningId: DriverManualEarningId;
    title: string;
    body?: string;
    comment?: string;
    amount: string;
    reason?: DriverManualEarningReason;
};
