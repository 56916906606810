/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { BarChartCanvas } from 'src/components/charts/BarChartCanvas';
import { translate } from 'src/i18n/translate';
import { GraphEmptyState } from 'src/scenes/letseatmanager/posReports/GraphEmptyState';
import type { ManagerUserSalesReportVm } from 'src/types/ManagerUserSalesReportVm';
import { sum } from 'src/utils/reduce/sum';

export function ManagerUserServedOrdersReport({ managerUserSalesReport }: Props): React.ReactElement {
    const classes = useStyles();

    const getManagerUserServedOrdersPieChartData = () => {
        return managerUserSalesReport.managerUserHistoricalSales.reduce<Array<{ id: string; label: string; value: number }>>((managerUserOrdersAndTipsByUsers, managerUserOrdersAndTips) => {
            const ordersAndTipsByUser = managerUserOrdersAndTipsByUsers?.find((report) => report.id === managerUserOrdersAndTips.servedBy);

            if (ordersAndTipsByUser) {
                ordersAndTipsByUser.value = BigNumber(ordersAndTipsByUser.value).plus(managerUserOrdersAndTips.orderVolume).toNumber();
                return managerUserOrdersAndTipsByUsers;
            }

            return [
                ...managerUserOrdersAndTipsByUsers,
                {
                    id: managerUserOrdersAndTips.servedBy,
                    label: managerUserOrdersAndTips.servedBy,
                    value: managerUserOrdersAndTips.orderVolume,
                },
            ];
        }, []);
    };

    const isEmptyReport = () => {
        const managerUserOrders = managerUserSalesReport.managerUserOrdersAndTips.map((managerUserOrdersAndTips) => managerUserOrdersAndTips.orderVolume);
        const totalTips = managerUserOrders.reduce(sum, BigNumber(0));
        return totalTips.isZero();
    };

    if (isEmptyReport()) {
        return (
            <article className={classes.container}>
                <GraphEmptyState title={translate('Orders served by waiter')} />
            </article>
        );
    }

    return (
        <article className={classes.container}>
            <h2 className={classes.title}>{translate('Orders served by waiter')}</h2>
            <BarChartCanvas
                data={getManagerUserServedOrdersPieChartData()}
                tooltip={(e: any) => {
                    const { id, value } = e.data;
                    return (
                        <div className={classes.tooltip}>
                            <span className={classes.tooltipTitle}>{id}</span>
                            <span className={classes.tooltipText}>{translate('Orders: @orders', { orders: value })}</span>
                        </div>
                    );
                }}
            />
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 100%',
        [theme.breakpoints.up('md')]: {
            flex: '1 1 45%',
        },
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
    },
    tooltip: {
        padding: 10,
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipTitle: {
        fontFamily: theme.typography.bold,
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    managerUserSalesReport: ManagerUserSalesReportVm;
};
