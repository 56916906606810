/**
 * @prettier
 */
import moment from 'moment-timezone';
import type { TimeZone } from 'src/constants/TimeZone';

export function convertTimeZone<T extends Date | string | undefined>(value: T, timeZone: TimeZone): T {
    if (!value) {
        return value;
    }
    return moment(value).tz(timeZone, true).toDate() as T;
}
