/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { PrinterBrand } from 'src/constants/PrinterBrand';
import type { PrinterContent } from 'src/constants/PrinterContent';
import type { PrinterPaperSize } from 'src/constants/PrinterPaperSize';
import type { PrinterType } from 'src/constants/PrinterType';
import type { RestaurantId } from 'src/types/Id';
import type { PrinterVm } from 'src/types/PrinterVm';

export async function createPrinterApi(request: CreatePrinterApiRequest): ApiSauceResponse<PrinterVm> {
    return letseatmanagerApiMethod('printer/createPrinterApi', request);
}

export type CreatePrinterApiRequest = {
    restaurantId: RestaurantId;
    printerType: PrinterType;
    deviceName?: string;
    externalPrinterId?: string;
    serialNumber?: string;
    ipAddress?: string;
    port?: string;
    printerBrand?: PrinterBrand;
    printerContent?: PrinterContent;
    channels?: Array<App>;
    printerPaperSize?: PrinterPaperSize;
    disabled?: boolean;
};
