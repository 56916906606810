/**
 * @prettier
 */
import { useState } from 'react';
import * as React from 'react';
import { CreateInventoryReport } from 'src/scenes/letseatmanager/inventory/inventory/CreateInventoryReport';
import { InventoryLogs } from 'src/scenes/letseatmanager/inventory/inventory/InventoryLogs';
import { InventoryReport } from 'src/scenes/letseatmanager/inventory/inventory/InventoryReport';
import { InventoryTable } from 'src/scenes/letseatmanager/inventory/inventory/InventoryTable';
import { InventoryReportHistory } from 'src/scenes/letseatmanager/supplyHistoryReport/InventoryReportHistory';

export function Inventory(): React.ReactElement {
    const [currentView, setCurrentView] = useState('INVENTORY_TABLE');
    const [inventoryReportId, setInventoryReportId] = useState(undefined);

    const goToInventorySettings = () => setCurrentView('INVENTORY_LOGS');

    const goToInventoryCount = () => setCurrentView('INVENTORY_REPORT');

    const goToInventoryTable = () => setCurrentView('INVENTORY_TABLE');

    const goToInventoryDone = (inventoryReportId: any) => {
        setInventoryReportId(inventoryReportId);
        setCurrentView('INVENTORY_REPORT_DONE');
    };

    const goToInventoryRecord = () => setCurrentView('INVENTORY_REPORT_RECORD');

    return (
        <div style={{ width: '100%' }}>
            {currentView === 'INVENTORY_TABLE' && <InventoryTable goToInventorySettings={goToInventorySettings} goToInventoryCount={goToInventoryCount} goToInventoryRecord={goToInventoryRecord} />}
            {currentView === 'INVENTORY_LOGS' && <InventoryLogs goBack={goToInventoryTable} />}
            {currentView === 'INVENTORY_REPORT' && <CreateInventoryReport goBack={goToInventoryTable} goToInventoryDone={goToInventoryDone} />}
            {currentView === 'INVENTORY_REPORT_DONE' && <InventoryReport goToInventoryTable={goToInventoryTable} inventoryReportId={inventoryReportId} />}
            {currentView === 'INVENTORY_REPORT_RECORD' && <InventoryReportHistory goBack={goToInventoryTable} goToInventoryDone={goToInventoryDone} />}
        </div>
    );
}
