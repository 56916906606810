/**
 * @prettier
 */
import { Collapse, List, makeStyles, useTheme } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useState } from 'react';
import * as React from 'react';
import { AppMenuLink } from 'src/components/app/appMenu/AppMenuLink';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { SettingsIcon } from 'src/icons/SettingsIcon';
import { useSelector } from 'src/utils/react/useSelector';

export function DriverAppSettingsMenu(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();

    const [showDriverApSettingsMenu, setShowDriverApSettingsMenu] = useState(false);

    const supportUser = useSelector((state) => state.authentication.supportUser);

    return (
        <div>
            <ListItem button onClick={() => setShowDriverApSettingsMenu(!showDriverApSettingsMenu)} className={classes.listItem}>
                <ListItemIcon>
                    <SettingsIcon title={'Settings icon'} color={theme.palette.icons.brand} />
                </ListItemIcon>
                <ListItemText classes={{ root: classes.listItemText }} primary={translate('Delivery drivers configuration')} />
                <ListItemIcon>{showDriverApSettingsMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</ListItemIcon>
            </ListItem>
            <Collapse in={showDriverApSettingsMenu}>
                <List classes={{ root: classes.subList }}>
                    <AppMenuLink routePath={RoutePaths.ADMIN_DRIVER_REFERRALS} text={translate('Driver Referrals')} data-testid='adminAppMenu.driverReferralsLink' />
                    <AppMenuLink routePath={RoutePaths.ADMIN_DRIVERS} text={translate('Drivers')} data-testid='adminAppMenu.driversLink' />
                    <AppMenuLink routePath={RoutePaths.ADMIN_DRIVERAPP_SETTINGS} text={translate('News Banner in App')} data-testid='adminAppMenu.driverSettings' />
                    <AppMenuLink routePath={RoutePaths.ADMIN_DRIVER_BONUS_OFFERS} text={translate('Driver Bonus Offers')} data-testid='adminAppMenu.driverBonusOffersLink' />
                    <AppMenuLink routePath={RoutePaths.ADMIN_ONGOING_DRIVER_BONUSES} text={translate('Ongoing Driver Bonuses')} data-testid='adminAppMenu.ongoingDriverBonusesLink' />
                    {!supportUser && <AppMenuLink routePath={RoutePaths.ADMIN_FAQ} text={translate('FAQs App Drivers')} data-testid='adminAppMenu.faqsLink' />}
                    <AppMenuLink routePath={RoutePaths.ADMIN_DRIVER_BILLING} text={translate('Driver Billing')} data-testid='adminAppMenu.driverBillingLink' />
                    <AppMenuLink routePath={RoutePaths.ADMIN_DRIVER_BONUS_BILLING} text={translate('Driver Bonus Billing')} data-testid='adminAppMenu.driverBonusBillingLink' />
                </List>
            </Collapse>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    subList: {
        paddingLeft: 20,
    },
    listItem: {
        borderRadius: 6,
        '&:hover': {
            backgroundColor: theme.palette.surface.tertiary,
        },
    },
    listItemText: {
        '& span': {
            fontFamily: theme.typography.regular,
        },
    },
    icon: {
        color: theme.palette.primary.main,
        width: 30,
        height: 30,
    },
}));
