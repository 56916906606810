/**
 * @prettier
 */
import { CartItemVm } from 'src/types/CartItemVm';

export function isOrderItemPaid(
    item: {
        key?: string;
    } & CartItemVm
): boolean {
    if (!item.itemsByCustomer?.length) return false;

    const quantityPaid = item.itemsByCustomer?.reduce(
        (
            quantity: number,
            payment: {
                customerNumber: number;
                itemsQuantity: number;
            }
        ) => quantity + payment.itemsQuantity,
        0
    );
    const remainingItems = item.quantity - quantityPaid;

    return !(remainingItems > 0);
}
