/**
 * @prettier
 */
import { Grid, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeBusinessApi } from 'src/api/letseatadmin/business/changeBusinessApi';
import { getBusinessApi } from 'src/api/letseatadmin/business/getBusinessApi';
import { Form } from 'src/components/form/Form';
import { FormBrandAutocompleteMultiple } from 'src/components/form/FormBrandAutocompleteMultiple';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormPhoneNumberField } from 'src/components/form/FormPhoneNumberField';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { BusinessId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useLoadApi } from 'src/utils/react/useLoadApi';

export function ChangeBusinessDialog({ open, businessId, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [loadingBusiness, business] = useLoadApi(getBusinessApi, { businessId: businessId! }, { requiredValues: [businessId] });

    useEffect(() => {
        if (business) {
            form.reset({
                name: business.name,
                brandIds: business.brands.map((brand) => brand.brandId),
                street: business.street || null,
                phoneNumber: business.phoneNumber || null,
                email: business.email || null,
            });
        }
    }, [business]);

    const handleClose = () => {
        onClose();
    };

    const onSubmit = async (form: any) => {
        if (!businessId) return;

        setLoading(true);
        const response = await changeBusinessApi({
            businessId: businessId,
            name: form.name,
            brandIds: form.brandIds,
            street: form.street,
            phoneNumber: form.phoneNumber,
            email: form.email,
        });
        setLoading(false);
        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        handleClose();
        onSuccess?.();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Change business')}</DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField name='name' label={translate('Name')} disabled={loading || loadingBusiness} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormBrandAutocompleteMultiple name='brandIds' label={translate('Brands')} disabled={loading || loadingBusiness} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='street' label={translate('Address')} disabled={loading || loadingBusiness} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormPhoneNumberField name='phoneNumber' label={translate('Phone')} disabled={loading || loadingBusiness} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormEmailField name='email' label={translate('Email')} disabled={loading || loadingBusiness} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.buttonsContainer}>
                    <Button onClick={handleClose} disabled={loading || loadingBusiness} classes={{ root: classes.cancelButton }}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading || loadingBusiness} classes={{ root: classes.acceptButton }}>
                        {loading ? translate('Changing') : translate('Change')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    dialogContent: {
        overflowY: 'hidden',
    },
    buttonsContainer: {
        margin: '10px 0',
        justifyContent: 'center',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        marginLeft: '0px !important',
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
}));

type Props = {
    open: boolean;
    businessId?: BusinessId;
    onClose: any;
    onSuccess?: any;
};
