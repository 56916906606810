/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FixedSizeList } from 'react-window';

export function VirtualizedList({ children, height, itemSize, orientation, width }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <FixedSizeList layout={orientation} height={height ?? 800} width={width ?? '100%'} itemCount={React.Children.count(children)} itemSize={itemSize ?? 150}>
                {({ index, style }) => <div style={style}>{React.Children.toArray(children)[index]}</div>}
            </FixedSizeList>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
    },
}));

type Props = {
    children: React.ReactNode;
    height?: number;
    width?: number;
    itemSize?: number;
    orientation?: 'horizontal';
};
