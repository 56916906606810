/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import DownloadIcon from '@material-ui/icons/SaveAltRounded';
import { BigNumber } from 'bignumber.js';
import moment from 'moment-timezone';
import { useMemo, useState } from 'react';
import * as React from 'react';
import { getOrderSalesApi } from 'src/api/letseatmanager/order/getOrderSalesApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { Input } from 'src/components/Input';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { RefreshIcon } from 'src/icons/RefreshIcon';
import { OrdersSalesInfo } from 'src/scenes/letseatmanager/orders/OrdersSalesInfo';
import { useFindOpenedUserCashRegisterPosBusinessDay } from 'src/services/cashRegister/useFindOpenedUserCashRegisterPosBusinessDay';
import { useDownloadOrdersSalesCsv } from 'src/services/csv/useDownloadOrdersSalesCsv';
import { type OrdersAndReportsVm } from 'src/types/OrdersAndReportsVm';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function OrdersSalesDialog({ open, filter, ordersReport, onClose, onChangeFrom, onChangeTo }: Props): React.ReactElement | null {
    const downloadOrdersSalesCsv = useDownloadOrdersSalesCsv();
    const [userHasRolePermission] = useUserHasRolePermission();
    const { findUserCashRegisterPosBusinessDay } = useFindOpenedUserCashRegisterPosBusinessDay();

    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const openedCashRegisterPosBusinessDay = useSelector((state) => state.pos.openedCashRegisterPosBusinessDay);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant.posMultipleCashRegistersEnabled);
    const displayPeriodByDateAndTimeEnabled = useSelector((state) => state.app.restaurant?.displayPeriodByDateAndTimeEnabled);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const limitDate = moment(filter.from).add(30, 'days');

    const userOpenedCashRegisterPosBusinessDay = findUserCashRegisterPosBusinessDay();
    const cashRegisterPosBusinessDayId = openedCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId ?? userOpenedCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId;
    const shouldFilterByCashRegisterPosBusinessDayId =
        !!userHasRolePermission(RolePermissions.VIEW_ONLY_ORDERS_IN_PERSONAL_CASH_REGISTER) &&
        !!posMultipleCashRegistersEnabled &&
        (!!openedCashRegisterPosBusinessDay || !!userOpenedCashRegisterPosBusinessDay) &&
        !brandOpened;

    const [loadingSales, salesReportData, loadSalesReport] = useLoadApi(
        getOrderSalesApi,
        {
            restaurantIds,
            fromDate: moment(formatDateToRestaurantTimeZone(filter.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(filter.to)).toISOString(),
            cashRegisterPosBusinessDayId: shouldFilterByCashRegisterPosBusinessDayId ? cashRegisterPosBusinessDayId : undefined,
            salesType,
        },
        { dependencies: [restaurantId, restaurantIds, filter.from, filter.to, salesType], initialValue: {}, requiredValues: [restaurantId, restaurantIds[0]] }
    );

    const handleChangeFromDate = (date: any) => {
        onChangeFrom(displayPeriodByDateAndTimeEnabled ? moment(date).toDate() : moment(date)?.startOf('day').toDate());
    };

    const handleChangeToDate = (date: any) => {
        onChangeTo(displayPeriodByDateAndTimeEnabled ? moment(date).toDate() : moment(date)?.endOf('day').toDate());
    };

    const handleClickRefresh = () => {
        setLoading(true);
        loadSalesReport();
        setLoading(false);
    };

    const thereIsInfoToShow = useMemo(() => {
        return Object.values(salesReportData).some((value) => {
            if (Array.isArray(value)) {
                return value.length > 0;
            } else if (typeof value === 'string' || typeof value === 'number') {
                return BigNumber(value).isGreaterThan(0);
            }
            return false;
        });
    }, [salesReportData]);

    const handleClickDownloadCsv = () => {
        downloadOrdersSalesCsv(ordersReport);
    };

    const validateSearchRangeDate = (date: any) => {
        if (date.isAfter(limitDate)) return { showError: true, errorMessage: translate('The selected date cannot be greater than @limitDate', { limitDate: moment(limitDate).format('YYYY-MM-DD') }) };
    };

    if (loadingSales && !salesReportData) return null;

    return (
        <Dialog open={open} title={translate('Sales report')} classes={{ title: (classes as any).dialogTitle }} onClose={onClose} loading={loadingSales}>
            <div className={classes.container}>
                <div className={classes.subContainer}>
                    <h3 className={classes.subTitle}>{translate('Select dates')}</h3>
                    <div className={classes.datesContainer}>
                        <Input
                            name={'from'}
                            type={displayPeriodByDateAndTimeEnabled ? 'datetime-local' : 'date'}
                            label={translate('From')}
                            onChange={handleChangeFromDate}
                            maxDate={filter.to}
                            value={filter.from}
                        />
                        <Input
                            name={'to'}
                            type={displayPeriodByDateAndTimeEnabled ? 'datetime-local' : 'date'}
                            label={translate('To')}
                            onChange={handleChangeToDate}
                            minDate={filter.from}
                            value={filter.to}
                            validation={validateSearchRangeDate}
                        />
                    </div>
                    <Button classes={{ button: classes.button }} disabled={loading} onClick={handleClickRefresh}>
                        <div className={(classes as any).icon}>
                            <RefreshIcon title={'RefreshIcon'} />
                        </div>
                        {translate('REFRESH')}
                    </Button>
                </div>
                <div className={classes.resumeContainer}>
                    {thereIsInfoToShow && (
                        <div className={classes.resumeContainer} style={{ height: '100%' }}>
                            <OrdersSalesInfo loadingSales={loading} sales={salesReportData} />
                            <SecuredContent rolePermission={RolePermissions.ALL_ORDERS_REPORTS}>
                                <Button classes={{ button: classes.button }} outlined disabled={loading} onClick={handleClickDownloadCsv}>
                                    <DownloadIcon titleAccess={'DownloadIcon'} />
                                    {translate('Download CSV')}
                                </Button>
                            </SecuredContent>
                        </div>
                    )}
                    {!thereIsInfoToShow && (
                        <div className={classes.emptyReport}>
                            <EqualizerIcon fontSize={'large'} />
                            <p className={classes.text}>{translate('There is no data for this report')}</p>
                        </div>
                    )}
                </div>
            </div>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        margin: 10,
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    subContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        marginBottom: 25,
        gap: 20,
        [theme.breakpoints.up('md')]: {
            marginRight: 25,
            maxWidth: '50%',
        },
    },
    datesContainer: {
        display: 'flex',
        justifyContent: 'start',
        gap: 10,
    },
    subTitle: {
        color: '#0D3037',
        fontFamily: theme.typography.medium,
        fontSize: 12,
        margin: 0,
    },
    resumeContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    button: {
        width: '100%',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '100%',
        textAlign: 'center',
    },
    emptyReport: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
type Props = {
    ordersReport?: OrdersAndReportsVm;
    open: boolean;
    onClose: any;
    onChangeFrom: any;
    onChangeTo: any;
    filter: {
        to: Date;
        from: Date;
    };
};
