/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';

export function formatAsCsvNumberCurrency(value?: string | number): string | undefined {
    if (!value) {
        return;
    }

    return BigNumber(value ?? 0).toFixed(2);
}
