/**
 * @prettier
 */
import { useEffect } from 'react';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { useFetchCashRegister } from 'src/services/cashRegister/useFetchCashRegister';
import type { CashRegisterId, RestaurantId } from 'src/types/Id';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { useSelector } from 'src/utils/react/useSelector';
import { EventListenerRemover, WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function useMaximumCashInCashRegisterSubscriptionUpdates() {
    const { fetchOpenedCashRegister } = useFetchCashRegister();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const maximumCashAllowedInCashRegisterEnabled = useSelector((state) => state.app.restaurant.maximumCashAllowedInCashRegisterEnabled);
    const maximumCashAllowedInCashRegister = useSelector((state) => state.app.restaurant.maximumCashAllowedInCashRegister);

    useEffect(() => {
        let maximumCashInCashRegisterReachedSyncWebSocketEvent: EventListenerRemover | undefined;
        let cashRegisterNewDepositSyncWebSocketEvent: EventListenerRemover | undefined;
        let cashRegisterNewWithdrawSyncWebSocketEvent: EventListenerRemover | undefined;

        if (maximumCashAllowedInCashRegisterEnabled && maximumCashAllowedInCashRegister) {
            maximumCashInCashRegisterReachedSyncWebSocketEvent = WebSocketEvents.addEventListener(
                WebSocketEventTypes.MAXIMUM_CASH_IN_CASH_REGISTER_REACHED,
                handleMaximumCashInCashRegisterReachedWebsocketEvents
            );
            cashRegisterNewDepositSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.CASH_REGISTER_NEW_DEPOSIT, handleMaximumCashInCashRegisterReachedWebsocketEvents);
            cashRegisterNewWithdrawSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.CASH_REGISTER_NEW_WITHDRAW, handleMaximumCashInCashRegisterReachedWebsocketEvents);
        }

        return () => {
            maximumCashInCashRegisterReachedSyncWebSocketEvent?.remove();
            cashRegisterNewDepositSyncWebSocketEvent?.remove();
            cashRegisterNewWithdrawSyncWebSocketEvent?.remove();
        };
    }, [restaurantId]);

    const handleMaximumCashInCashRegisterReachedWebsocketEvents = async (event: WebSocketEvent<MaximumCashInCashRegisterWebSocketEventResponse>) => {
        if (restaurantId !== event.data?.restaurantId) return;

        await fetchOpenedCashRegister();
    };
}

type MaximumCashInCashRegisterWebSocketEventResponse = {
    restaurantId: RestaurantId;
    cashRegisterId?: CashRegisterId;
};
