/**
 * @prettier
 */
export const ProblemResponseTypes = {
    NONE: 'NONE',
    CLIENT_ERROR: 'CLIENT_ERROR',
    SERVER_ERROR: 'SERVER_ERROR',
    TIMEOUT_ERROR: 'TIMEOUT_ERROR',
    CONNECTION_ERROR: 'CONNECTION_ERROR',
    NETWORK_ERROR: 'NETWORK_ERROR',
    CANCEL_ERROR: 'CANCEL_ERROR',
} as const;

export type ProblemResponseType = typeof ProblemResponseTypes[keyof typeof ProblemResponseTypes];
