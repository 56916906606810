/**
 * @prettier
 */
import { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import { PosPayment } from 'src/types/PosPayment';
import { newUuid } from 'src/utils/uuid/newUuid';

export function mapOrderPaymentToPosPayment(orderPayment: OrderPaymentVm): PosPayment {
    return {
        paymentTerminalPaymentId: orderPayment.paymentTerminalPaymentId,
        paymentTerminalId: orderPayment.paymentTerminalId,
        paymentTerminalProvider: orderPayment.paymentTerminalProvider,
        amount: orderPayment.amount,
        paymentMethod: orderPayment.paymentMethod,
        customPaymentMethod: orderPayment.customPaymentMethod,
        id: newUuid() as any,
        customerNumber: orderPayment.customerNumber,
        isTipPayment: orderPayment.isTipPayment,
        restaurantCurrencyAmount: orderPayment.restaurantCurrencyAmount,
        restaurantCurrency: orderPayment.restaurantCurrency,
    };
}
