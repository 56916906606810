/**
 * @prettier
 */
import * as React from 'react';
import { FormAutocompleteMultiple } from 'src/components/form/FormAutocompleteMultiple';
import type { Variant } from 'src/components/form/FormTypes';
import { PaymentMethod, PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';

export function FormPaymentMethodsAutocomplete({ name, label, placeholder, helperText, defaultValue = [], disabled, required, variant }: Props): React.ReactElement {
    return (
        <FormAutocompleteMultiple
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue as any}
            options={Object.values(PaymentMethods).map((paymentMethod) => ({ label: translate(paymentMethod), value: paymentMethod }))}
            disabled={disabled}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: Array<PaymentMethod>;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
};
