/**
 * @prettier
 */
import * as React from 'react';
import { memo } from 'react';
import { ResponsiveBarChart } from 'src/scenes/letseatadmin/restaurantSales/ResponsiveBarChart';

export const RestaurantSalesChart: React.ComponentType<Props> = memo<Props>(function RestaurantSalesChart({ data }: Props): React.ReactElement {
    const myStyle = {
        width: 550,
        height: 500,
    } as const;

    return (
        <div style={myStyle}>
            <ResponsiveBarChart data={data} />
        </div>
    );
});

type Props = {
    data: RestaurantSalesVm;
};

type RestaurantSalesVm = {
    restaurantName: string;
    weeksData: Array<RestaurantSalesWeekData>;
};

type RestaurantSalesWeekData = {
    week: number;
    sale: number;
};
