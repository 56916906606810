/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { ErrorAlertIcon } from 'src/icons/ErrorAlertIcon';
import { NotificationIcon } from 'src/icons/NotificationIcon';
import { SuccessAlertIcon } from 'src/icons/SuccessAlertIcon';
import { WarningAlertIcon } from 'src/icons/WarningAlertIcon';
import { classNames } from 'src/utils/react/classNames';

// Inspired by https://dev.to/dmtrkovalenko/the-neatest-way-to-handle-alert-dialogs-in-react-1aoe
// and https://github.com/jonatanklosko/material-ui-confirm/
// recommended from https://mui.com/material-ui/react-dialog/#complementary-projects
export function ConfirmDialog({ open, title, content, buttonText, cancelButtonText, onAccept, onClose, variant, classes: classesProp }: Props): React.ReactElement | null {
    const classes = useStyles();

    const getButtonClasses = () => {
        let buttonClasses = classes.button;

        if (variant === 'error') buttonClasses = classNames(buttonClasses, classes.errorButton);
        if (variant === 'warning') buttonClasses = classNames(buttonClasses, classes.warningButton);
        if (variant === 'success') buttonClasses = classNames(buttonClasses, classes.successButton);

        return buttonClasses;
    };

    const getVariantIcon = () => {
        if (variant === 'error') return <ErrorAlertIcon />;
        if (variant === 'warning') return <WarningAlertIcon />;
        if (variant === 'success') return <SuccessAlertIcon />;
        if (variant === 'notification') return <NotificationIcon />;

        return null;
    };

    return (
        <Dialog classes={{ dialog: classNames(classes.dialog, classesProp?.dialog) }} open={open} onClose={onClose}>
            <div className={classes.iconContainer}>
                {getVariantIcon()}
                {!!title && <Text className={classNames(classesProp?.title, classes.title)}>{title}</Text>}
            </div>
            {!!content && (
                <div className={classes.dialogContent}>
                    <Text className={classNames(classesProp?.message, classes.message)}>{content}</Text>
                </div>
            )}
            <DialogActions className={classes.buttonsContainer}>
                {!!cancelButtonText && (
                    <Button secondary onClick={onClose}>
                        {cancelButtonText ?? translate('Cancel')}
                    </Button>
                )}
                <Button classes={{ button: getButtonClasses() }} onClick={onAccept}>
                    {buttonText ?? translate('Understood')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    iconContainer: {
        textAlign: 'center',
        fontFamily: theme.typography.semiBold,
        minWidth: 280,
        [theme.breakpoints.up('sm')]: {
            minWidth: 460,
        },
    },
    dialogContent: {
        textAlign: 'center',
        margin: '25px 0',
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        width: '100%',
        justifyContent: 'center',
        gap: 20,
    },
    button: {
        minWidth: 110,
    },
    errorButton: {
        backgroundColor: '#E32F45',
        color: 'white',
        '&:hover': {
            backgroundColor: '#E96273',
        },
    },
    warningButton: {
        backgroundColor: '#FC9B41',
        color: 'white',
        '&:hover': {
            backgroundColor: '#FFEFDC',
        },
    },
    successButton: {
        backgroundColor: '#06B7A2',
        color: 'white',
        '&:hover': {
            backgroundColor: '#CBF7E9',
        },
    },
    dialog: {
        maxWidth: 280,
        [theme.breakpoints.up('sm')]: {
            maxWidth: '90%',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '50%',
        },
    },
    message: {
        fontFamily: theme.typography.semiBold,
        color: '#4A4A4A',
        fontSize: 16,
        [theme.breakpoints.up('sm')]: {
            fontSize: 18,
        },
    },
    title: {
        fontFamily: theme.typography.regular,
        color: '#4A4A4A',
        fontSize: 18,
        [theme.breakpoints.up('sm')]: {
            fontSize: 22,
        },
        fontWeight: 600,
    },
}));

type Props = {
    open: boolean;
    onAccept?: () => void;
    onCancel?: () => void;
    onClose?: () => void;
    title?: string;
    content?: string;
    buttonText?: string;
    cancelButtonText?: string;
    classes?: {
        title?: string;
        dialog?: string;
        message?: string;
    };
    variant?: 'warning' | 'error' | 'success' | 'notification';
};
