/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverManualEarningVm } from 'src/types/DriverManualEarningVm';
import type { DriverId } from 'src/types/Id';

export async function findDriverManualEarningsByDriverIdApi(request: FindDriverManualEarningsByDriverIdAPiRequest): ApiSauceResponse<FindDriverManualEarningsByDriverIdApiResponse> {
    return letseatadminApiMethod('driverManualEarning/findDriverManualEarningsByDriverIdApi', request);
}

export type FindDriverManualEarningsByDriverIdAPiRequest = {
    driverId: DriverId;
    filter?: {
        from: Date;
        to: Date;
        paymentDate: Date;
    };
};

export type FindDriverManualEarningsByDriverIdApiResponse = {
    driverManualEarnings: Array<DriverManualEarningVm>;
};
