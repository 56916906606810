/**
 * @prettier
 */
import type { OrderStatus } from 'src/constants/OrderStatus';
import { OrderType, OrderTypes } from 'src/constants/OrderType';
import type { OrderVm } from 'src/types/OrderVm';

export function orderIsInternalDeliveryOrder(
    order:
        | OrderVm
        | {
              orderType: OrderType;
              orderStatus: OrderStatus;
              externalDelivery?: boolean;
          }
): boolean {
    return order.orderType === OrderTypes.DELIVERY_ORDER && !order.externalDelivery;
}
