/**
 * @prettier
 */
import { useEffect } from 'react';
import { useLoadMenus } from 'src/services/menu/useLoadMenus';
import type { MenuVm as DeprecatedMenuVm } from 'src/types/DeprecatedMenuVm';
import type { MenuVm } from 'src/types/MenuVm';
import { useSelector } from 'src/utils/react/useSelector';

export function useMenus(): Menus {
    const menus = useSelector((state) => state.menu.menus);
    const deprecatedMenus = useSelector((state) => state.theMenu.menus);
    const menusLoaded = useSelector((state) => state.menu.menusLoaded);
    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant.newMenuManagementEnabled);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const loadMenus = useLoadMenus();

    useEffect(() => {
        if (newMenuManagementEnabled && !menus?.length && !menusLoaded && restaurantId) {
            loadMenus();
        }
    }, [newMenuManagementEnabled, menus, menusLoaded, restaurantId]);

    return {
        menus,
        deprecatedMenus,
    };
}

type Menus = {
    menus: Array<MenuVm>;
    deprecatedMenus: Array<DeprecatedMenuVm>;
};
