/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import * as React from 'react';
import { RestaurantCustomerAddress } from 'src/api/letseatmanager/restaurantCustomer/findRestaurantCustomersAddressOptionsApi';

export function RestaurantCustomerAddressRadioGroup({ restaurantCustomerAddresses, value, onChange }: Props): React.ReactElement {
    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedAddressStreet = event.target.value;
        onChange(selectedAddressStreet);
    };

    return (
        <RadioGroup aria-label='restaurant customer addresses radio group' name='restaurantCustomerAddresses' value={value} onChange={handleChange}>
            {restaurantCustomerAddresses.map((restaurantCustomerAddress) => (
                <FormControlLabel
                    key={restaurantCustomerAddress.formattedAddress ?? restaurantCustomerAddress.street}
                    label={restaurantCustomerAddress.formattedAddress ?? restaurantCustomerAddress.street}
                    classes={{ label: classes.label }}
                    className={classes.customLabel}
                    value={restaurantCustomerAddress.street}
                    control={<Radio className={classes.radio} color={'primary'} />}
                />
            ))}
        </RadioGroup>
    );
}

type Props = {
    restaurantCustomerAddresses: Array<RestaurantCustomerAddress>;
    value?: string;
    onChange: (street: string) => void;
};

const useStyles = makeStyles((theme) => ({
    label: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        marginBottom: 6,
        color: theme.palette.text.primary,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 0,
        },
    },
    radio: {
        '&.MuiRadio-colorPrimary.Mui-checked': {
            color: theme.palette.surface.brandContrast,
        },
    },
    customLabel: {
        '& .MuiFormControlLabel-label': {
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
            marginBottom: 6,
            color: theme.palette.text.primary,
            [theme.breakpoints.up('sm')]: {
                marginBottom: 0,
            },
        },
    },
}));
