/**
 * @prettier
 */
import * as React from 'react';
import { useEffect } from 'react';
import { FormAutocomplete } from 'src/components/form/FormAutocomplete';
import type { Variant } from 'src/components/form/FormTypes';
import TheMenuActions from 'src/scenes/letseatmanager/deprecatedMenu/TheMenuReducer';
import type { MenuItemId } from 'src/types/Id';
import { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function FormModifiersAutocomplete({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, onChange }: Props): React.ReactElement {
    const modifiers = useSelector((state) => state.theMenu.modifiers);
    const menus = useSelector((state) => state.theMenu.menus);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const fetch = useAction(TheMenuActions.fetch);

    const options = required
        ? modifiers?.map((modifier: ManagerMenuItemVm) => ({ label: modifier.name, value: modifier.menuItemId })) ?? []
        : [{ label: '', value: null }, ...(modifiers?.map((modifier: ManagerMenuItemVm) => ({ label: modifier.name, value: modifier.menuItemId })) ?? [])];

    useEffect(() => {
        if (!menus?.length) fetch(restaurantId);
    }, [menus]);

    const handleChange = (modifierId: any) => {
        const modifier = modifiers?.find((modifier: ManagerMenuItemVm) => modifier.menuItemId === modifierId);
        onChange?.(modifier);
    };

    return (
        <FormAutocomplete
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue as any}
            options={options}
            disabled={disabled}
            required={required}
            variant={variant}
            onChange={handleChange}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: MenuItemId;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    onChange?: any;
};
