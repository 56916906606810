/**
 * @prettier
 */
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';
import type { Variant } from 'src/components/form/FormTypes';
import { getError } from 'src/components/form/utils/getError';
import { transformStringInput } from 'src/components/form/utils/transformStringInput';
import { transformStringOutput } from 'src/components/form/utils/transformStringOutput';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { classNames } from 'src/utils/react/classNames';
import { wait } from 'src/utils/wait';

export function FormSelect({
    name,
    label,
    placeholder,
    helperText,
    defaultValue = null,
    options,
    disabled,
    required,
    variant,
    onChange: onChangeProp,
    hasTooltip,
    tooltipText,
    classesProp,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>();
    const classes = useStyles();

    const [isSubmittingWorkaround, setIsSubmittingWorkaround] = useState(false);
    useEffect(() => {
        // Workaround since only inputRef.current.disabled = false; does not work
        async function delay() {
            await wait(0);
            setIsSubmittingWorkaround(isSubmitting);
        }
        delay();
    }, [isSubmitting]);

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <div className={classes.container}>
                    <InputLabel
                        id={`${name}-select-label`}
                        error={!!getError(errors, name)}
                        required={required as any}
                        classes={{ root: classNames(classes.label, classesProp?.label), error: classes.labelError }}
                    >
                        {label}
                        {tooltipText && <Tooltip text={tooltipText} />}
                    </InputLabel>
                    <Select
                        {...props}
                        labelId={`${name}-select-label`}
                        value={transformStringInput(value)}
                        disabled={disabled || formContext.disabled}
                        onChange={(e) => {
                            const value = transformStringOutput((e.target as any).value);
                            onChangeProp && onChangeProp(value);
                            onChange(value);
                        }}
                        renderValue={(selected: any) => {
                            if (selected.length === 0) {
                                return <em>{placeholder}</em>;
                            }
                            const selectedOption = options?.find((option: { label: string; value: any }) => option.value === selected);
                            return selectedOption?.label;
                        }}
                        placeholder={placeholder}
                        variant={variant}
                        error={!!getError(errors, name)}
                        inputProps={{
                            inputRef: inputRef,
                        }}
                        classes={{ root: classNames(classes.select, classesProp?.input), icon: classes.selectIcon, selectMenu: classes.selectMenu }}
                        IconComponent={KeyboardArrowDownIcon}
                    >
                        {options?.map((option: { label: string; value: any }) => (
                            <MenuItem key={`${option.value}-${option.label}`} value={option.value}>
                                {option.label}&nbsp;
                            </MenuItem>
                        ))}
                    </Select>
                    {(helperText ?? getError(errors, name)) && <FormHelperText error={!!getError(errors, name)}>{errors?.[name]?.message ?? helperText}</FormHelperText>}
                </div>
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        '& .MuiInputBase-root': {
            width: '100%',
            borderRadius: 5,
        },
        '& .MuiInputBase-root:before, .MuiInputBase-root:after': {
            display: 'none',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            borderRadius: 5,
        },
    },
    select: {
        height: '42px',
        padding: 15,
        width: '100%',
        fontFamily: theme.typography.regular,
        fontSize: 14,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '.Mui-error > &': {
            border: '2px solid red',
        },
        outline: '1px solid #D9D9D9',
        backgroundColor: 'transparent',
        borderRadius: '4px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        boxSizing: 'border-box',
        color: '#191919',
        border: 0,
    },
    selectIcon: {
        color: theme.palette.icons.primary,
        marginRight: theme.spacing(1),
    },
    selectMenu: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
    },
    label: {
        fontFamily: theme.typography.light,
        color: '#2E3748',
        fontSize: 14,
        paddingBottom: 5,
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
    labelError: {
        color: 'red',
    },
}));

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | number | null | undefined;
    options: Array<{
        label: string;
        value: any;
    }>;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    onChange?: (arg1?: any) => any;
    hasTooltip?: boolean;
    tooltipText?: string;
    classesProp?: {
        label?: string;
        input?: string;
    };
};
