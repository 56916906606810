/**
 * @prettier
 */
import { createActions, createReducer } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators }: any = createActions(
    {
        openDialog: ['order'],
        closeDialog: [],
    },
    { prefix: 'RejectOrderDialogReducer/' }
);

export const RejectOrderDialogTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: any = {
    open: false,
    order: {},
};

/* ------------- Reducers ------------- */
export const openDialog = (state: any, { order }: any): any => {
    return {
        ...state,
        open: true,
        order,
    };
};

export const closeDialog = (state: any): any => {
    return {
        ...state,
        open: false,
    };
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer: any = createReducer(INITIAL_STATE, {
    [Types.OPEN_DIALOG]: openDialog,
    [Types.CLOSE_DIALOG]: closeDialog,
});
