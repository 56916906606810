/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { memo } from 'react';
import { Button } from 'src/components/Button';
import { usePosUserLockScreenIsWrongPassword } from 'src/services/pos/PosUserLockScreen/usePosUserLockScreenIsWrongPassword';
import { classNames } from 'src/utils/react/classNames';

export const PosUserLockScreenNumericKey: React.ComponentType<Props> = memo<Props>(({ value, onClick }: Props) => {
    const classes = useStyles();

    const isWrongPassword = usePosUserLockScreenIsWrongPassword();

    return (
        <Button classes={{ button: classNames(classes.number, isWrongPassword ? classes.numericKeyError : '') }} outlined onClick={() => onClick(value)}>
            {value}
        </Button>
    );
});
const useStyles = makeStyles((theme) => ({
    number: {
        display: 'grid',
        placeItems: 'center',
        fontSize: 18,
        height: 60,
        width: 90,
        borderRadius: 4,
        color: 'white',
        fontFamily: theme.typography.regular,
        '&:hover': {
            backgroundColor: `${theme.palette.surface.brandContrast}15`,
        },
    },
    numericKeyError: {
        border: `1px solid #EA5146`,
        backgroundColor: 'rgba(234,81,70,0.04)',
    },
}));

type Props = {
    onClick: any;
    value: string;
};
