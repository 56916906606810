/**
 * @prettier
 */
import * as React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { SECONDS } from 'src/constants/TimeUnit';
import { isNewOrder } from 'src/utils/order/isNewOrder';
import { useInterval } from 'src/utils/react/useInterval';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';
import { isWebApp } from 'src/utils/reactNative/isWebApp';
import { setShouldPlayAlertSoundMobileApp } from 'src/utils/reactNative/setShouldPlayAlertSoundMobileApp';
import { setSoundMutedInMobileApp } from 'src/utils/reactNative/setSoundMutedInMobileApp';
import { playAlertSound } from 'src/utils/sound/playAlertSound';

export function NewOrdersSoundPlayer(): React.ReactElement | null {
    const location = useLocation();

    const [userHasRolePermission] = useUserHasRolePermission();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const soundMuted = useSelector((state) => state.app.soundMuted);
    const restaurant = useSelector((state) => state.app.restaurant);
    const kioskUser = useSelector((state) => state.authentication.kioskUser);
    const currentOrders = useSelector((state) => state.app2.currentOrders);
    const incomingOrdersAlertIsOpen = useSelector((state) => state.app2.incomingOrdersAlertState.open);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);
    const adminUser = useSelector((state) => state.authentication.adminUser);

    const newOrders = brandOpened
        ? currentOrders.some((order) => isNewOrder(order.orderStatus))
        : currentOrders.some((order) => isNewOrder(order.orderStatus) && order.restaurant?.restaurantId === restaurantId);
    const isKitchenLocation = location.pathname === RoutePaths.KITCHEN_DISPLAY_SCREEN;
    const playOngoingOrderSound = !soundMuted && (newOrders || incomingOrdersAlertIsOpen);

    useInterval(
        () => {
            if (kioskUser) return;
            if (isKitchenLocation) return;
            if (adminUser && location.pathname !== RoutePaths.ONGOING_ORDERS && location.pathname !== RoutePaths.ORDERS && location.pathname !== RoutePaths.POS) return;
            if (!playOngoingOrderSound) {
                setShouldPlayAlertSoundMobileApp(playOngoingOrderSound, restaurant.alertSound);
                return;
            }

            if (isWebApp() && userHasRolePermission(RolePermissions.VIEW_INCOMING_ORDERS_ALERT)) {
                playAlertSound(restaurant.alertSound);
            }

            setShouldPlayAlertSoundMobileApp(userHasRolePermission(RolePermissions.VIEW_INCOMING_ORDERS_ALERT), restaurant.alertSound);
        },
        2 * SECONDS,
        [adminUser, location.pathname, playOngoingOrderSound, restaurant, pinCodeUserSignedIn]
    );

    useEffect(() => {
        setSoundMutedInMobileApp(soundMuted);
    }, [soundMuted]);

    return null;
}
