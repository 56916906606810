/**
 * @prettier
 */
export const DefaultTips = {
    '5': 0.05,
    '10': 0.1,
    '15': 0.15,
} as const;

export type DefaultTip = typeof DefaultTips[keyof typeof DefaultTips];
