/**
 * @prettier
 */
import { DeviceOs, DeviceOsTypes } from 'src/constants/DeviceOs';

export function translateDeviceOs(deviceOs?: DeviceOs): string {
    if (!deviceOs) return '';
    switch (deviceOs) {
        case DeviceOsTypes.WINDOWS:
            return 'Windows';
        case DeviceOsTypes.MAC_OS:
            return 'macOS';
        case DeviceOsTypes.ANDROID:
            return 'Android';
        case DeviceOsTypes.IOS:
            return 'iOS';
        default:
            return deviceOs ?? '';
    }
}
