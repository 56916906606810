/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { findPaymentTerminalsApi } from 'src/api/letseatmanager/paymentTerminal/findPaymentTerminalsApi';
import { SectionList } from 'src/components/SectionList';
import { SectionListItem } from 'src/components/SectionListItem';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { CreatePaymentTerminalDialog } from 'src/scenes/letseatmanager/device/paymentTerminals/CreatePaymentTerminalDialog';
import { PaymentTerminalListItem } from 'src/scenes/letseatmanager/device/paymentTerminals/PaymentTerminalListItem';
import { PaymentTerminalPaymentProgressLightBox } from 'src/scenes/letseatmanager/paymentTerminalPayment/PaymentTerminalPaymentProgressLightBox';
import { useConfirmDialog } from 'src/services/dialog/useConfirmDialog';
import { usePayWithPaymentTerminal } from 'src/services/paymentTerminal/usePayWithPaymentTerminal';
import type { PaymentTerminalId } from 'src/types/Id';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function PaymentTerminals(): React.ReactElement {
    const classes = useStyles();

    const [createPaymentTerminalDialogState, setCreatePaymentTerminalDialogState] = useState({ open: false });
    const [selectedPaymentTerminalId, setSelectedPaymentTerminalId] = useState(undefined);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loadingPaymentTerminals, paymentTerminals, refreshPaymentTerminals] = useLoadApi(findPaymentTerminalsApi, { restaurantId: restaurantId }, { initialValue: [] });

    const confirmDialog = useConfirmDialog();
    const { payWithPaymentTerminal, cancelPayment } = usePayWithPaymentTerminal();

    const openCreatePaymentTerminalDialog = () => setCreatePaymentTerminalDialogState({ open: true });

    const closeCreatePaymentTerminalDialog = () => setCreatePaymentTerminalDialogState({ open: false });

    const handleRefresh = async () => {
        await refreshPaymentTerminals();
    };

    const selectPaymentTerminal = (paymentTerminalId: any) => setSelectedPaymentTerminalId(paymentTerminalId);

    const handleClickTestPaymentTerminal = async (paymentTerminalId: PaymentTerminalId) => {
        const result = await payWithPaymentTerminal({ paymentTerminalId, amount: '1' });
        if (result.paid) {
            await confirmDialog({
                title: translate('Payment Result'),
                content: translate('Paid'),
                buttonText: translate('Accept'),
                variant: 'success',
            });
        }
    };

    return (
        <>
            {/* TODO: move to global scope and rename to PaymentTerminalProcessProgressLightBox */}
            <PaymentTerminalPaymentProgressLightBox onCancelPayment={cancelPayment} />
            <div className={classes.container}>
                <UpdatingContentProgress loading={loadingPaymentTerminals} top />
                <CreatePaymentTerminalDialog open={createPaymentTerminalDialogState.open} onClose={closeCreatePaymentTerminalDialog} onSuccess={handleRefresh} />
                <SectionList
                    title={translate('Payment Terminals')}
                    onCreate={openCreatePaymentTerminalDialog}
                    onItemClick={selectPaymentTerminal}
                    defaultListItemSelected={selectedPaymentTerminalId}
                    classes={{ container: classes.list }}
                >
                    {paymentTerminals.map((paymentTerminal) => (
                        <SectionListItem key={paymentTerminal.paymentTerminalId} value={paymentTerminal.deviceName}>
                            <PaymentTerminalListItem
                                paymentTerminal={paymentTerminal}
                                onChange={handleRefresh}
                                onClickTest={() => {
                                    handleClickTestPaymentTerminal(paymentTerminal.paymentTerminalId);
                                }}
                            />
                        </SectionListItem>
                    ))}
                </SectionList>
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'relative',
    },
    list: {
        width: '50vw',
        [theme.breakpoints.down('sm')]: {
            width: '90vw',
        },
    },
}));
