/**
 * @prettier
 */
import { getBrandContextApi } from 'src/api/letseatmanager/getBrandContextApi';
import { appReducer } from 'src/reducers/appReducer';
import { useUserRestaurantIds } from 'src/services/restaurant/useUserRestaurantIds';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { isBusinessUser } from 'src/utils/user/isBusinessUser';

export function useOpenBrand(): {
    openBrand: any;
} {
    const currentUserRestaurantIds = useUserRestaurantIds();

    const brands = useSelector((state) => state.app.brands);
    const userType = useSelector((state) => state.authentication.userType);

    const openBrand = useAction(appReducer.actions.openBrand);
    const setRestaurantIds = useAction(appReducer.actions.setRestaurantIds);

    const handleOpenBrand = async (brandId: any) => {
        const response = await getBrandContextApi({ brandId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong();
            return;
        }
        const brandSelected = brands?.find((brand) => brand.brandId === brandId);
        openBrand({ brand: brandSelected, brandContext: response.data });

        if (isBusinessUser(userType)) {
            setRestaurantIds(brandSelected?.restaurantIds ?? []);
            return;
        }

        const userRestaurantIds = !!currentUserRestaurantIds.length ? currentUserRestaurantIds : response.data.restaurantIds;
        setRestaurantIds(userRestaurantIds);
    };

    return { openBrand: handleOpenBrand };
}
