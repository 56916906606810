/**
 * @prettier
 */
export const DriverArrivesAtStoreTimes = {
    TIME_30_40_MIN: 'TIME_30_40_MIN',
    TIME_40_50_MIN: 'TIME_40_50_MIN',
    TIME_50_60_MIN: 'TIME_50_60_MIN',
} as const;

export type DriverArrivesAtStoreTime = typeof DriverArrivesAtStoreTimes[keyof typeof DriverArrivesAtStoreTimes];
