/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { changeTheRestaurantApi } from 'src/api/letseatadmin/theRestaurant/changeTheRestaurantApi';
import { Form } from 'src/components/form/Form';
import { FormEmailTemplateAutocomplete } from 'src/components/form/FormEmailTemplateAutocomplete';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormHoursField } from 'src/components/form/FormHoursField';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormSubheading } from 'src/components/form/FormSubheading';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { getDirtyFormValues } from 'src/components/form/utils/getDirtyFormValues';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { appColors } from 'src/theme/AppColors';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantMarketingSettings({ loading, restaurant, onChangeRestaurant, setIsDirtyForm }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { dirtyFields },
        watch,
        formState,
    } = form;

    const thanksForOrderingEmail = watch('thanksForOrderingEmail');
    const welcomeEmail = watch('welcomeEmail');
    const menuHighlightEmail = watch('menuHighlightEmail');
    const promoCodeEmail = watch('promoCodeEmail');
    const defaultSendThanksForOrderingEmailMinutesAfter = watch('defaultSendThanksForOrderingEmailMinutesAfter');
    const defaultSendWelcomeEmailMinutesAfter = watch('defaultSendWelcomeEmailMinutesAfter');
    const defaultSendMenuHighlightEmailDaysAfter = watch('defaultSendMenuHighlightEmailDaysAfter');
    const defaultSendPromoCodeEmailDaysAfter = watch('defaultSendPromoCodeEmailDaysAfter');

    const appLoading = useSelector((state) => state.app.loading);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);
    const { reloadRestaurant } = useReloadRestaurant();

    useEffect(() => {
        if (!loading)
            form.reset({
                restaurantId: restaurant.restaurantId ?? null,
                promoPrices: restaurant.promoPrices ?? null,
                comingSoon: restaurant.comingSoon ?? null,
                thanksForOrderingEmail: restaurant.thanksForOrderingEmail ?? null,
                sendThanksForOrderingEmailMinutesAfter: restaurant.sendThanksForOrderingEmailMinutesAfter ?? null,
                thanksForOrderingEmailTemplateId: restaurant.thanksForOrderingEmailTemplateId ?? null,
                welcomeEmail: restaurant.welcomeEmail ?? null,
                sendWelcomeEmailMinutesAfter: restaurant.sendWelcomeEmailMinutesAfter ?? null,
                welcomeEmailTemplateId: restaurant.welcomeEmailTemplateId ?? null,
                menuHighlightEmail: restaurant.menuHighlightEmail ?? null,
                sendMenuHighlightEmailDaysAfter: restaurant.sendMenuHighlightEmailDaysAfter ?? null,
                menuHighlightEmailTemplateId: restaurant.menuHighlightEmailTemplateId ?? null,
                promoCodeEmail: restaurant.promoCodeEmail ?? null,
                sendPromoCodeEmailDaysAfter: restaurant.sendPromoCodeEmailDaysAfter ?? null,
                promoCodeEmailTemplateId: restaurant.promoCodeEmailTemplateId ?? null,
                restaurantPromoCodesEnabled: restaurant.restaurantPromoCodesEnabled ?? null,
                addCreditsToCustomersEnabled: restaurant.addCreditsToCustomersEnabled ?? null,
                marketingEnabled: restaurant.marketingEnabled ?? null,
                analyticsEnabled: restaurant.analyticsEnabled ?? null,
                menuAnalyticsUrl: restaurant.menuAnalyticsUrl ?? null,
                customersAnalyticsUrl: restaurant.customersAnalyticsUrl ?? null,
                promoCodesAnalyticsUrl: restaurant.promoCodesAnalyticsUrl ?? null,
            });
    }, [loading]);

    useEffect(() => {
        setIsDirtyForm(Object.keys(dirtyFields).length !== 0);
    }, [formState]);

    const onSubmit = async (form: any) => {
        const dirtyFormValues = getDirtyFormValues({ form, dirtyFields });
        const response = await changeTheRestaurantApi({ restaurantId, ...dirtyFormValues });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        await reloadRestaurant(restaurantId);
        await onChangeRestaurant();
        showSnackbar({ message: translate('Saved changes') });
    };

    return (
        <Paper className={classes.paper}>
            <UpdatingContentProgress loading={loading || appLoading} />
            <Form form={form} onSubmit={onSubmit} disabled={loading || appLoading}>
                <div className={classes.formContent}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormSubheading align={'start' as any}>{translate('Menu Promo Prices')}</FormSubheading>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormSwitch
                                name='promoPrices.enabled'
                                label={translate('Create Separate Promotion Menu')}
                                tooltip={translate('Activate this option if you want to separate the items with promotion in a submenu')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormHoursField
                                name='promoPrices.hours'
                                label={translate('Hours')}
                                helperText={`${translate('E.g. "Mo-Fr 08:00-09:00", "Sa-Su" or "Mar Mo-Fr 11:00-14:00". see https://openingh.openstreetmap.de/evaluation_tool/#check')}`}
                                placeholder={translate('24/7')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormSwitch
                            name='comingSoon'
                            label={translate('Coming Soon')}
                            tooltip={translate('Enable a message on the Ecommerce page to indicate that the establishment will open soon')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSwitch
                            name='thanksForOrderingEmail'
                            label={translate('Thanks For Ordering Email')}
                            tooltip={translate('Activate this option if you want an email to be sent to the user thanking you for the purchase')}
                        />
                    </Grid>
                    {thanksForOrderingEmail && (
                        <Grid item xs={12}>
                            <FormNumberField
                                helperText={`${translate('Default is 30 minutes')}`}
                                placeholder={`${defaultSendThanksForOrderingEmailMinutesAfter}`}
                                name='sendThanksForOrderingEmailMinutesAfter'
                                label={translate('Send Email Minutes After')}
                            />
                        </Grid>
                    )}
                    {thanksForOrderingEmail && (
                        <Grid item xs={12}>
                            <FormEmailTemplateAutocomplete
                                helperText={`${translate('Default is Default Template')}`}
                                placeholder={translate(`Default Template`)}
                                name='thanksForOrderingEmailTemplateId'
                                label={translate('Email Template for Thanks for Ordering')}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormSwitch name='welcomeEmail' label={translate('Welcome Email')} tooltip={translate('Activate this option if you want a welcome email to be sent to the user')} />
                    </Grid>
                    {welcomeEmail && (
                        <Grid item xs={12}>
                            <FormNumberField
                                helperText={`${translate('Default is 30 minutes')}`}
                                placeholder={`${defaultSendWelcomeEmailMinutesAfter}`}
                                name='sendWelcomeEmailMinutesAfter'
                                label={translate('Send Email Minutes After')}
                            />
                        </Grid>
                    )}
                    {welcomeEmail && (
                        <Grid item xs={12}>
                            <FormEmailTemplateAutocomplete
                                helperText={`${translate('Default is Default Template')}`}
                                placeholder={translate(`Default Template`)}
                                name='welcomeEmailTemplateId'
                                label={translate('Email Template for Thanks for Ordering')}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormSwitch
                            name='menuHighlightEmail'
                            label={translate('Menu Highlight Email')}
                            tooltip={translate('Enable the option if you want a report to be sent by mail about your sales')}
                        />
                    </Grid>
                    {menuHighlightEmail && (
                        <Grid item xs={12}>
                            <FormNumberField
                                helperText={`${translate('Default is 15 days')}`}
                                placeholder={`${defaultSendMenuHighlightEmailDaysAfter}`}
                                name='sendMenuHighlightEmailDaysAfter'
                                label={translate('Send Email Days After')}
                            />
                        </Grid>
                    )}
                    {menuHighlightEmail && (
                        <Grid item xs={12}>
                            <FormEmailTemplateAutocomplete
                                helperText={`${translate('Default is Default Template')}`}
                                placeholder={translate(`Default template`)}
                                name='menuHighlightEmailTemplateId'
                                label={translate('Email template for Menu Highlight')}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormSwitch
                            name='promoCodeEmail'
                            label={translate('Promo Code Email')}
                            tooltip={translate('You enable this option if you require the template to send email for promotions.')}
                        />
                    </Grid>
                    {promoCodeEmail && (
                        <Grid item xs={12}>
                            <FormNumberField
                                helperText={`${translate('Default is 30 days')}`}
                                placeholder={`${defaultSendPromoCodeEmailDaysAfter}`}
                                name='sendPromoCodeEmailDaysAfter'
                                label={translate('Send Email Days After')}
                            />
                        </Grid>
                    )}
                    {promoCodeEmail && (
                        <Grid item xs={12}>
                            <FormEmailTemplateAutocomplete
                                helperText={`${translate('Default is Default Template')}`}
                                placeholder={translate(`Default template`)}
                                name='promoCodeEmailTemplateId'
                                label={translate('Email template for Promo Code')}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <FormSwitch
                            name='restaurantPromoCodesEnabled'
                            label={translate('Restaurant Promo Codes')}
                            tooltip={translate('Activate the option for promotional codes in the restaurant cart')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSwitch
                            name='addCreditsToCustomersEnabled'
                            label={translate('Give credits to customers enabled')}
                            tooltip={translate('Enables restaurant to send credits to their customers, this credits will be 100% paid by the restaurant')}
                        />
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='marketingEnabled'
                                label={translate('Marketing Enabled')}
                                tooltip={translate('Enable the new marketing view to display customer information, create promotional codes and send notifications to your customers')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormSubheading align={'start' as any}>{translate('Analytics Settings')}</FormSubheading>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='analyticsEnabled' label={translate('Analytics Enabled')} tooltip={'Activate this option to obtain an advanced reports section'} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextField
                                name='menuAnalyticsUrl'
                                label={translate('Menu Analytics url')}
                                tooltip={translate('Request PD url to place it in this field and be able to view menu reports from the manager.')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextField
                                name='customersAnalyticsUrl'
                                label={translate('Customers Analytics url')}
                                tooltip={translate('Request PD url to place it in this field and be able to view customer analysis reports from the manager.')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextField
                                name='promoCodesAnalyticsUrl'
                                label={translate('Promo Codes Analytics url')}
                                tooltip={translate('Request the PD url to place it in this field and be able to view promotion reports from the manager.')}
                            />
                        </Grid>
                    </Grid>
                    <FormFixErrorHint />
                </div>

                <Grid container justify='center' spacing={3}>
                    <Grid item>
                        <FormSubmitButton text={translate('Change')} disabled={loading || appLoading} classes={{ container: classes.submitButton }} />
                    </Grid>
                </Grid>
            </Form>
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
    },
    formContent: {
        padding: theme.spacing(3),
    },
    deleteButton: {
        color: appColors.text.dangerLight,
    },
    section: {
        width: '100%',
        borderRadius: 5,
        margin: '20px auto',
        border: `1px solid ${theme.palette.secondary.dark}`,
    },
    sectionTitleContainer: {
        backgroundColor: theme.palette.secondary.light,
        marginTop: 20,
    },
    sectionTitle: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
    },
    subtitlesubtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    cashRegisterCustomReasonItem: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        minWidth: 140,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #676060B2',
        borderRadius: 12,
        padding: '0 10px',
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
        color: theme.palette.secondary.contrastText,
        marginLeft: 10,
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    paymentMethod: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    secondaryButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        padding: '5px 10px',
    },
    kioskUserContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    kioskInfo: {
        display: 'flex',
        flexDirection: 'column',
    },
    integrationStoreStatusClosed: {
        fontFamily: theme.typography.semiBold,
        color: '#fa6684',
        fontSize: 13,
    },
    integrationStoreStatusOpen: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 13,
    },
    submitButton: {
        zIndex: 1000,
    },
}));

type Props = {
    loading: boolean;
    restaurant: any;
    onChangeRestaurant: any;
    setIsDirtyForm: any;
};
