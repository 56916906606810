/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function AddCustomerNameDialog({ open, handleClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    const form = useForm();

    const posCustomerNameRequiredOnTakeAwayOrdersEnabled = useSelector((state) => state.pos.context?.posCustomerNameRequiredOnTakeAwayOrdersEnabled);

    const setCustomerName = useAction(posReducer.actions.setCustomerName);

    const handleSubmit = (form: any) => {
        setCustomerName({ firstName: form.firstName, lastName: form.lastName });
        onSuccess?.();
        handleClose();
    };

    return (
        <Dialog classes={{ dialog: classes.dialogContainer }} open={open} onClose={handleClose} title={translate('Assign name to order')}>
            <Form onSubmit={handleSubmit} form={form}>
                <div className={classes.formContent}>
                    <FormTextField name='firstName' label={translate('First Name')} required={posCustomerNameRequiredOnTakeAwayOrdersEnabled} />
                    <FormTextField name='lastName' label={translate('Last Name')} />
                </div>
                <DialogActions className={classes.dialogActions}>
                    <Button onClick={handleClose} secondary>
                        {translate('Cancel')}
                    </Button>
                    <Button type={'submit'}>{translate('Continue')}</Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    formContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
    },
    dialogContainer: {
        width: 260,
        [theme.breakpoints.up('sm')]: {
            width: 360,
        },
    },
    buttonsContainer: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            marginBottom: 20,
        },
    },
    dialogActions: {
        flexWrap: 'unset',
    },
}));

type Props = {
    open: boolean;
    handleClose: any;
    onSuccess?: any;
};
