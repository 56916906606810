/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { LogEventVm } from 'src/api/letseatmanager/types/LogEventVm';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SubscriptionId } from 'src/types/Id';

export async function getSubscriptionLogEventsApi(request: GetSubscriptionLogEventsApiRequest): ApiSauceResponse<Array<LogEventVm>> {
    return letseatadminApiMethod('logEvent/getSubscriptionLogEventsApi', request);
}

export type GetSubscriptionLogEventsApiRequest = {
    subscriptionId: SubscriptionId;
};
