/**
 * @prettier
 */
export const DriverBonusTypes = {
    FIRST_TIME_BONUS: 'FIRST_TIME_BONUS',
    ONE_TIME_BONUS: 'ONE_TIME_BONUS',
    REUSABLE_BONUS: 'REUSABLE_BONUS',
} as const;

export type DriverBonusType = typeof DriverBonusTypes[keyof typeof DriverBonusTypes];
