/**
 * @prettier
 */
export const UnableToClosePosBusinessDayReasons = {
    THERE_ARE_ONGOING_ORDERS: 'THERE_ARE_ONGOING_ORDERS',
    THERE_ARE_ONGOING_INTEGRATION_ORDERS: 'THERE_ARE_ONGOING_INTEGRATION_ORDERS',
    THERE_ARE_ONGOING_ORDERS_WITH_EXTERNAL_DELIVERY: 'THERE_ARE_ONGOING_ORDERS_WITH_EXTERNAL_DELIVERY',
    THERE_ARE_COMPLETED_ORDERS_NOT_INVOICED: 'THERE_ARE_COMPLETED_ORDERS_NOT_INVOICED',
} as const;

export type UnableToClosePosBusinessDayReason = typeof UnableToClosePosBusinessDayReasons[keyof typeof UnableToClosePosBusinessDayReasons];
