/**
 * @prettier
 */
import type { SupportContactVm } from 'src/api/letseatadmin/supportContact/getSupportContactApi';
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';

export async function createSupportContactApi(request: CreateSupportContactApiRequest): ApiSauceResponse<SupportContactVm> {
    return letseatadminApiMethod('supportContact/createSupportContactApi', request);
}

export type CreateSupportContactApiRequest = {
    name?: string;
    phoneNumber: string;
    available: boolean;
};
