/**
 * @prettier
 */
import { Countries, Country } from 'src/constants/Country';

export function getIvaByCountry(country: Country): number {
    if (country === Countries.AR) return 0.21;
    if (country === Countries.MX) return 0.16;
    if (country === Countries.UY) return 0.22;
    return 0.16;
}
