/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { useState } from 'react';
import * as React from 'react';
import { disableDriverBannerApi } from 'src/api/letseatadmin/driver/disableDriverBannerApi';
import { enableDriverBannerApi } from 'src/api/letseatadmin/driver/enableDriverBannerApi';
import { findDriverBannersApi } from 'src/api/letseatadmin/driver/findDriverBannersApi';
import { removeDriverBannerApi } from 'src/api/letseatadmin/driver/removeDriverBannerApi';
import { Button } from 'src/components/Button';
import { SimpleTable } from 'src/components/SimpleTable';
import { DriverBannerImageTypes } from 'src/constants/DriverBannerImageType';
import { translate } from 'src/i18n/translate';
import { AddIcon } from 'src/icons/AddIcon';
import { EditIcon } from 'src/icons/EditIcon';
import { RemoveIcon } from 'src/icons/RemoveIcon';
import { ChangeDriverBannerDialog } from 'src/scenes/letseatadmin/driverAppSetting/ChangeDriverBannerDialog';
import { CreateDriverBannerDialog } from 'src/scenes/letseatadmin/driverAppSetting/CreateDriverBannerDialog';
import type { DriverBannerVm } from 'src/types/DriverBannerVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useLoadApi } from 'src/utils/react/useLoadApi';

export function DriverBannersPage(): React.ReactElement | null {
    const classes = useStyles();

    const [createDriverBannerDialogOpen, setCreateDriverBannerDialogOpen] = useState(false);
    const [changeDriverBannerDialogOpen, setChangeDriverBannerDialogOpen] = useState(false);
    const [driverBannerSelected, setDriverBannerSelected] = useState<DriverBannerVm>();

    const [loading, driverBanners, load] = useLoadApi(findDriverBannersApi, undefined, { initialValue: [] });

    const columns = [
        {
            id: 'text',
            content: translate('Text'),
            size: 1,
        },
        {
            id: 'linkUrl',
            content: translate('Link'),
            size: 1.2,
        },
        {
            id: 'city',
            content: translate('City'),
            size: 0.9,
        },
        {
            id: 'imageType',
            content: translate('Image Type'),
            size: 1,
        },
        {
            id: 'bannerEnabled',
            content: translate('Active'),
            size: 0.5,
        },
        {
            id: 'actions',
            content: '',
            size: 1,
        },
    ];

    const handleClose = () => {
        setCreateDriverBannerDialogOpen(false);
        setChangeDriverBannerDialogOpen(false);
    };

    const handleRowClicked = (driverBanner: DriverBannerVm) => {
        setDriverBannerSelected(driverBanner);
        setChangeDriverBannerDialogOpen(true);
    };

    const handleDeleteRowClicked = async (bannerInfo: DriverBannerVm) => {
        const response = await removeDriverBannerApi({ driverBannerId: bannerInfo.driverBannerId });
        if (!response?.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        load();
    };

    const createNewBanner = () => {
        setDriverBannerSelected(undefined);
        setCreateDriverBannerDialogOpen(true);
    };

    const handleActiveBanner = async (e: any, bannerInfo: DriverBannerVm) => {
        const isActive = e.target.checked;
        if (isActive) {
            await handleEnableDriverBanner(bannerInfo);
            return;
        }
        await handleDisableDriverBanner(bannerInfo);
    };

    const handleEnableDriverBanner = async (bannerInfo: DriverBannerVm) => {
        const response = await enableDriverBannerApi({ driverBannerId: bannerInfo.driverBannerId });
        if (!response?.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        load();
    };

    const handleDisableDriverBanner = async (bannerInfo: DriverBannerVm) => {
        const response = await disableDriverBannerApi({ driverBannerId: bannerInfo.driverBannerId });
        if (!response?.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        load();
    };

    if (!driverBanners) return null;

    return (
        <div>
            <CreateDriverBannerDialog open={createDriverBannerDialogOpen} onClose={handleClose} onSuccess={load} />
            <ChangeDriverBannerDialog open={changeDriverBannerDialogOpen} onClose={handleClose} bannerDriver={driverBannerSelected} onSuccess={load} />
            <div className={classes.subContainer}>
                <Button onClick={createNewBanner}>
                    {translate('New Banner')} <AddIcon color={'#ffffff'} />
                </Button>
            </div>
            <SimpleTable
                classes={{ cell: classes.tableCell }}
                columns={columns}
                rows={driverBanners?.map((driverBanner: DriverBannerVm) => ({
                    text: driverBanner?.text ?? '',
                    linkUrl: driverBanner?.linkUrl ?? '',
                    bannerEnabled: <Switch checked={driverBanner?.enabled} onChange={(e) => handleActiveBanner(e, driverBanner)} inputProps={{ 'aria-label': 'controlled' }} />,
                    imageType: driverBanner?.imageType ?? DriverBannerImageTypes.DEFAULT,
                    city: driverBanner?.city,
                    actions: (
                        <div className={classes.containerButtons}>
                            <Button outlined onClick={() => handleRowClicked(driverBanner)} classes={{ button: classes.button }} disabled={loading}>
                                <EditIcon />
                            </Button>
                            <Button outlined onClick={() => handleDeleteRowClicked(driverBanner)} classes={{ button: classes.deleteButton }} disabled={loading}>
                                <RemoveIcon color={'#ff103f'} />
                            </Button>
                        </div>
                    ),
                }))}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        paddingBottom: 30,
    },
    tableContainer: {
        width: '100%',
        marginTop: 20,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    ordersContainer: {
        width: '100%',
        marginTop: 20,
        display: 'grid',
        gap: 20,
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    },
    link: {
        color: '#198074',
        fontFamily: theme.typography.regular,
        fontSize: 16,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    tableCell: {
        alignItems: 'center',
    },
    orderType: {
        color: 'white',
        padding: '4px 12px',
        borderRadius: 4,
    },

    subContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        padding: 5,
    },
    containerButtons: {
        display: 'flex',
        gap: 2,
    },
    button: {
        width: 65,
        height: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        backgroundColor: 'transparent',
    },

    deleteButton: {
        paddingLeft: 25,
        width: 65,
        height: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        backgroundColor: 'transparent',
    },
}));
