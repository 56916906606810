/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { useState } from 'react';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { createPaymentTerminalApi } from 'src/api/letseatmanager/paymentTerminal/createPaymentTerminalApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormPaymentTerminalProvidersSelect } from 'src/components/select/paymentTerminalProvider/FormPaymentTerminalProvidersSelect';
import { translate } from 'src/i18n/translate';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isOcaPaymentTerminalProvider } from 'src/utils/paymentTerminalProvider/isOcaPaymentTerminalProvider';
import { isQpayPaymentTerminalProvider } from 'src/utils/paymentTerminalProvider/isQpayPaymentTerminalProvider';
import { useSelector } from 'src/utils/react/useSelector';

export function CreatePaymentTerminalDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const { control, setValue } = form;

    const [loading, setLoading] = useState(false);
    const paymentTerminalProvider = useWatch({ name: 'paymentTerminalProvider', control, defaultValue: undefined });

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const { reloadRestaurant } = useReloadRestaurant();

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        const response = await createPaymentTerminalApi({
            restaurantId,
            deviceName: form.deviceName,
            paymentTerminalProvider: form.paymentTerminalProvider,
            qpayTerminalDeviceId: form.qpayTerminalDeviceId,
            ocaPaymentTerminalPosId: form.ocaPaymentTerminalPosId,
            ocaPaymentTerminalSystemId: form.ocaPaymentTerminalSystemId,
            ocaPaymentTerminalTerminalGroup: form.ocaPaymentTerminalTerminalGroup,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        reloadRestaurant(restaurantId);
        onSuccess?.();
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} loading={loading} title={translate('Create payment terminal')}>
            <Form form={form} onSubmit={onSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name='deviceName' label={translate('Name')} required disabled={loading} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormPaymentTerminalProvidersSelect name='paymentTerminalProvider' required />
                    </Grid>
                    {isQpayPaymentTerminalProvider(paymentTerminalProvider) && (
                        <Grid item xs={12}>
                            <FormTextField name='qpayTerminalDeviceId' label={translate('Serial number')} required disabled={loading} />
                        </Grid>
                    )}
                    {isOcaPaymentTerminalProvider(paymentTerminalProvider) && (
                        <>
                            <Grid item xs={12}>
                                <FormTextField name='ocaPaymentTerminalPosId' label={translate('Terminal Pos Id')} required disabled={loading} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField name='ocaPaymentTerminalSystemId' label={translate('Terminal System Id')} required disabled={loading} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField name='ocaPaymentTerminalTerminalGroup' label={translate('Terminal Group')} required disabled={loading} />
                            </Grid>
                        </>
                    )}
                </Grid>
                <DialogActions>
                    <Button secondary onClick={handleClose}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit'>{translate('Create')}</Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    onClose: any;
    onSuccess?: any;
};
