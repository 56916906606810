/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeRestaurantManualEarningApi } from 'src/api/letseatadmin/restaurantManualEarning/changeRestaurantManualEarningApi';
import { getRestaurantManualEarningApi } from 'src/api/letseatadmin/restaurantManualEarning/getRestaurantManualEarningApi';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { RestaurantManualEarningId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { requireValue } from 'src/utils/require/requireValue';

export function ChangeRestaurantManualEarningDialog({ open, restaurantManualEarningId, onClose, onChangeRestaurantManualEarning }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) load();
    }, [open]);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const handleExited = () => {
        setLoading(false);
    };

    const load = async () => {
        setLoading(true);
        const response = await getRestaurantManualEarningApi({ restaurantManualEarningId: requireValue(restaurantManualEarningId) });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            onClose();
            return;
        }
        const restaurantManualEarning = response.data;
        form.reset(restaurantManualEarning);
        setLoading(false);
    };

    const onSubmit = async (form: any) => {
        const response = await changeRestaurantManualEarningApi({
            restaurantManualEarningId: requireValue(restaurantManualEarningId),
            title: form.title,
            body: form.body,
            comment: form.comment,
            amount: form.amount,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onChangeRestaurantManualEarning();
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} onExited={handleExited}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Change RestaurantManualEarning').toUpperCase()}</DialogTitle>
                <DialogContent>
                    <FormContent loading={loading} />
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={loading || isSubmitting}>
                        {isSubmitting ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

function FormContent({ loading }: { loading: boolean }) {
    if (loading) {
        return <Loader size={40} loading={true} />;
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <FormTextField name='title' label={translate('Title')} required />
            </Grid>
            <Grid item xs={12}>
                <FormTextField name='body' label={translate('Body')} />
            </Grid>
            <Grid item xs={12}>
                <FormTextField name='comment' label={translate('Comment')} />
            </Grid>
            <Grid item xs={12}>
                <FormTextField name='amount' label={translate('Amount')} required />
            </Grid>
        </Grid>
    );
}

type Props = {
    open: boolean;
    restaurantManualEarningId: RestaurantManualEarningId | undefined;
    onClose: any;
    onChangeRestaurantManualEarning: any;
};
