/**
 * @prettier
 */
export const MomentFormats = {
    ISO_DATE: 'YYYY-MM-DD',
    ISO_TIME: 'HH:mm',
    ISO_DATE_TIME: 'YYYY-MM-DDTHH:mm',
    ISO_YEAR_MONTH: 'YYYY-MM',
} as const;

export type MomentFormat = typeof MomentFormats[keyof typeof MomentFormats];
