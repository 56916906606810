/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuItemId } from 'src/types/Id';

export async function hideMenuItemsApi(request: HideMenuItemsApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menuItem/hideMenuItemsApi', request);
}

type HideMenuItemsApiRequest = {
    menuItemIds: Array<MenuItemId>;
};
