/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { findRestaurantPasswordApi } from 'src/api/letseatmanager/restaurant/findRestaurantPasswordApi';
import { changeTheRestaurantApi } from 'src/api/letseatmanager/theRestaurant/changeTheRestaurantApi';
import type { TheRestaurantVm } from 'src/api/letseatmanager/theRestaurant/getTheRestaurantApi';
import { Button } from 'src/components/Button';
import { ChangePasswordDialog } from 'src/components/dialog/ChangePasswordDialog';
import { Form } from 'src/components/form/Form';
import { FormGoogleMapsAddressSearchAutocomplete } from 'src/components/form/FormGoogleMapsAddressSearchAutocomplete';
import { FormImageUpload } from 'src/components/form/FormImageUpload';
import { FormPasswordField } from 'src/components/form/FormPasswordField';
import { FormPhoneNumberField } from 'src/components/form/FormPhoneNumberField';
import { FormRestaurantStatisticsReportEmails } from 'src/components/form/FormRestaurantStatisticsReportEmails';
import { FormServiceTypeSelect } from 'src/components/form/FormServiceTypeSelect';
import { FormSubheading } from 'src/components/form/FormSubheading';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormUrlField } from 'src/components/form/FormUrlField';
import { getDirtyFormValues } from 'src/components/form/utils/getDirtyFormValues';
import { Spacing } from 'src/components/spacing/Spacing';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { CsdCertificateSettings } from 'src/scenes/letseatmanager/theRestaurant/csdCertificate/CsdCertificateSettings';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { SentryService } from 'src/services/SentryService';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useAskForPassword } from 'src/utils/react/useAskForPassword';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantGeneralSettings({ loading, restaurant, onChangeRestaurant, setIsDirtyForm }: Props): React.ReactElement {
    const classes = useStyles();
    const askForPassword = useAskForPassword();

    const form = useForm();
    const {
        setValue,
        control,
        formState,
        formState: { dirtyFields },
    } = form;

    const [formGoogleMapsAddressSearchAutocompleteDisabled, setFormGoogleMapsAddressSearchAutocompleteDisabled] = useState(false);
    const [changePasswordDialogState, setChangePasswordDialogState] = useState(false);

    const eatHereEnabled = useWatch<boolean>({ name: 'eatHereEnabled', control });
    const posEnabled = useWatch<boolean>({ name: 'posEnabled', control });
    const password = useWatch({ name: 'password', control });

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const appLoading = useSelector((state) => state.app.loading);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const { reloadRestaurant } = useReloadRestaurant();

    useEffect(() => {
        if (!loading) {
            form.reset({
                restaurantId: restaurant.restaurantId ?? null,
                name: restaurant.name ?? null,
                street: restaurant.street ?? null,
                address: restaurant.address ?? null,
                location: restaurant.location ?? null,
                phoneNumber: restaurant.phoneNumber ?? null,
                secondaryPhoneNumber: restaurant.secondaryPhoneNumber ?? null,
                logoImageUrl: restaurant.logoImageUrl ?? null,
                restaurantColors: restaurant.restaurantColors ?? [],
                info: restaurant.info ?? null,
                infoUrl: restaurant.infoUrl ?? null,
                eatHereEnabled: restaurant.eatHereEnabled ?? null,
                serviceType: restaurant.serviceType ?? null,
                takeAwayEnabled: restaurant.takeAwayEnabled ?? null,
                deliveryEnabled: restaurant.deliveryEnabled ?? null,
                plannedOrdersEnabled: restaurant.plannedOrdersEnabled ?? null,
                posSimpleView: restaurant.posSimpleView ?? null,
                posEnabled: restaurant.posEnabled ?? null,
                ordersWithoutIvaEnabled: restaurant.ordersWithoutIvaEnabled ?? null,
                temporarilyOffline: restaurant.temporarilyOffline ?? null,
                username: restaurant.username ?? null,
                statisticsReportEmailRecipients: restaurant.statisticsReportEmailRecipients ?? [],
            });
        }
    }, [loading]);

    useEffect(() => {
        setIsDirtyForm(Object.keys(dirtyFields).length !== 0);
    }, [formState]);

    const handleClickShowPassword = async () => {
        if (password) {
            return;
        }

        const rightPassword = await askForPassword({ password: '9988' });
        if (!rightPassword) return;

        const response = await findRestaurantPasswordApi({ restaurantId: restaurantId });

        if (!response.ok) {
            alert(translate('Failed to download password'));
        }

        setValue('password', response.data || 'unknown');
    };

    const handleClickEditPassword = async () => {
        const rightPassword = await askForPassword({ password: '9988' });
        if (!rightPassword) return;
        setChangePasswordDialogState(true);
    };

    const handleCloseChangePasswordDialog = () => setChangePasswordDialogState(false);

    const onSubmit = async (form: any) => {
        const dirtyFormValues = getDirtyFormValues({ form, dirtyFields });
        const response = await changeTheRestaurantApi({
            restaurantId,
            csdCertificate: form.csdCertificate ?? undefined,
            csdCertificateKey: form.csdCertificateKey ?? undefined,
            csdCertificatePassword: form.csdCertificatePassword ?? undefined,
            csdCertificateRestaurantId: form.csdCertificateRestaurantId ?? undefined,
            ...dirtyFormValues,
        });

        if (!response.ok) {
            SentryService.logError('Failed to save the restaurant', { response });
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        await reloadRestaurant(restaurantId);
        await onChangeRestaurant();
        showSnackbar({ message: translate('Saved changes') });
    };

    return (
        <Paper className={classes.paper}>
            <ChangePasswordDialog open={changePasswordDialogState} username={restaurant.username} onClose={handleCloseChangePasswordDialog} onSuccess={onChangeRestaurant} />
            <UpdatingContentProgress loading={loading || appLoading} />
            <Form form={form} onSubmit={onSubmit}>
                <div className={classes.formContent}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField name='name' label={translate('Name')} disabled />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='street' label={translate('Street')} tooltip={translate('Street where the establishment is located')} disabled />
                        </Grid>
                        <Grid item xs={12}>
                            <FormGoogleMapsAddressSearchAutocomplete
                                name={'address'}
                                label={translate('Google Street Address')}
                                autocompleteChange={(address: any) => {
                                    if (!address) return;
                                    setValue('location.latitude', address.location.coordinates[1]);
                                    setValue('location.longitude', address.location.coordinates[0]);
                                    setFormGoogleMapsAddressSearchAutocompleteDisabled(true);
                                }}
                                tooltip={translate('Location of establishment on Google Maps')}
                                disabled={formGoogleMapsAddressSearchAutocompleteDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormPhoneNumberField name='phoneNumber' label={translate('Phone Number')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormPhoneNumberField name='secondaryPhoneNumber' label={translate('Secondary Phone Number')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormImageUpload name='logoImageUrl' label={translate('Logo Image')} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField
                                name='info'
                                label={translate('Info')}
                                tooltip={translate('Show in the white label app an information message below the restaurant information')}
                                helperText={translate('Info visible in app below opening hours when opening a restaurant (the top of the menu page)')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormUrlField
                                name='infoUrl'
                                label={translate('Info Url')}
                                tooltip={translate('Show the link of your website')}
                                helperText={translate('The url which the info box will redirect when the customer press on it')}
                            />
                        </Grid>
                    </Grid>

                    <Spacing units={3} />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormSubheading>{translate('Services')}</FormSubheading>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <>
                                <FormSwitch name='eatHereEnabled' label={translate('Eat Here')} tooltip={translate('Enables orders to eat inside the restaurant')} />
                                {eatHereEnabled && <FormServiceTypeSelect name='serviceType' label={translate('ServiceType')} required />}
                            </>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormSwitch name='deliveryEnabled' label={translate('Delivery')} tooltip={translate('Enable orders for home delivery')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormSwitch name='takeAwayEnabled' label={translate('Take Away')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormSwitch name='plannedOrdersEnabled' label={translate('Planned orders')} tooltip={translate('Enable planned orders to ecommerce and white label app')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='posSimpleView' label={translate('POS Simple View')} tooltip={translate('Extra fast sale')} disabled={!posEnabled} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormSwitch name='ordersWithoutIvaEnabled' label={translate('Orders without IVA')} />
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <FormTextField name='username' label={translate('Username')} disabled />
                            </Grid>

                            <Grid item xs={12} sm={6} className={classes.passwordContainer}>
                                <div className={classes.passwordInputContainer}>
                                    <FormPasswordField name='password' label={translate('Password')} onClickShowPassword={handleClickShowPassword} disabled />
                                </div>
                                <Button classes={{ button: classes.changePasswordButton }} onClick={handleClickEditPassword}>
                                    {translate('Change password')}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FormRestaurantStatisticsReportEmails name={'statisticsReportEmailRecipients'} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormRestaurantStatisticsReportEmails name={'statisticsReportEmailRecipients'} />
                        </Grid>
                        <CsdCertificateSettings onChangeRestaurant={onChangeRestaurant} form={form} />
                    </Grid>
                </div>

                <Grid container justify='center' spacing={3}>
                    <Grid item>
                        <FormSubmitButton text={translate('Change')} disabled={loading || appLoading} classes={{ container: classes.submitButton }} />
                    </Grid>
                </Grid>
            </Form>
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
    },
    formContent: {
        padding: theme.spacing(3),
    },
    section: {
        width: '100%',
        borderRadius: 5,
        margin: '20px auto',
        border: `1px solid ${theme.palette.secondary.dark}`,
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
        color: theme.palette.secondary.contrastText,
        marginLeft: 10,
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    passwordContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        gap: 12,
    },
    passwordInputContainer: {
        flexGrow: '1' as any,
    },
    changePasswordButton: {
        height: 30,
    },
    submitButton: {
        zIndex: 1000,
    },
}));

type Props = {
    loading: boolean;
    restaurant: TheRestaurantVm;
    onChangeRestaurant: any;
    setIsDirtyForm: any;
};
