/**
 * @prettier
 */
import type { PrinterInstruction } from 'src/services/printer/types/PrinterInstruction';

export function printerInstructionsBuilder(): PrinterInstructionsBuilder {
    const printerInstructions: Array<PrinterInstruction> = [];
    const builder: PrinterInstructionsBuilder = {
        addText: function (text) {
            printerInstructions.push({ instructionType: 'addText', params: [text ?? ''] });
            return builder;
        },
        setLargerFont: function () {
            printerInstructions.push({ instructionType: 'setLargerFont', params: [] });
            return builder;
        },
        setRegularFont: function () {
            printerInstructions.push({ instructionType: 'setRegularFont', params: [] });
            return builder;
        },
        addBoldText: function (text) {
            printerInstructions.push({ instructionType: 'addBoldText', params: [text ?? ''] });
            return builder;
        },
        addCenteredText: function (text) {
            printerInstructions.push({ instructionType: 'addCenteredText', params: [text ?? ''] });
            return builder;
        },
        addCenteredBoldText: function (text) {
            printerInstructions.push({ instructionType: 'addCenteredBoldText', params: [text ?? ''] });
            return builder;
        },
        addRightText: function (text) {
            printerInstructions.push({ instructionType: 'addRightText', params: [text ?? ''] });
            return builder;
        },
        addBoldRightText: function (text) {
            printerInstructions.push({ instructionType: 'addBoldRightText', params: [text ?? ''] });
            return builder;
        },
        addSeparatedTexts: function (leftText, rightText) {
            printerInstructions.push({ instructionType: 'addSeparatedTexts', params: [leftText ?? '', rightText ?? ''] });
            return builder;
        },
        addSeparatedBoldLeftTexts: function (leftText, rightText) {
            printerInstructions.push({ instructionType: 'addSeparatedBoldLeftTexts', params: [leftText ?? '', rightText ?? ''] });
            return builder;
        },
        addBoldSeparatedTexts: function (leftText, rightText) {
            printerInstructions.push({ instructionType: 'addBoldSeparatedTexts', params: [leftText ?? '', rightText ?? ''] });
            return builder;
        },
        addColumns: function (columns: Array<BuilderPrintColumn>) {
            printerInstructions.push({
                instructionType: 'addColumns',
                params: [
                    columns.map((column) => ({
                        ...column,
                        text: column.text ?? '',
                    })),
                ],
            });
            return builder;
        },
        addBoldColumns: function (columns: Array<BuilderPrintColumn>) {
            printerInstructions.push({
                instructionType: 'addBoldColumns',
                params: [
                    columns.map((column) => ({
                        ...column,
                        text: column.text ?? '',
                    })),
                ],
            });
            return builder;
        },
        addLogoImage: function (url) {
            printerInstructions.push({ instructionType: 'addLogoImage', params: [url] });
            return builder;
        },
        addQrCode: function (url) {
            printerInstructions.push({ instructionType: 'addQrCode', params: [url] });
            return builder;
        },
        addNewLine: function () {
            printerInstructions.push({ instructionType: 'addNewLine', params: [] });
            return builder;
        },
        addCenteredUnderLine: function () {
            printerInstructions.push({ instructionType: 'addCenteredUnderLine', params: [] });
            return builder;
        },
        addLineSeparator: function () {
            printerInstructions.push({ instructionType: 'addLineSeparator', params: [] });
            return builder;
        },
        openCashbox: function () {
            printerInstructions.push({ instructionType: 'openCashbox', params: [] });
            return builder;
        },
        build: function () {
            return printerInstructions;
        },
    };
    return builder;
}

export type PrinterInstructionsBuilder = {
    addText: (text: string | undefined) => PrinterInstructionsBuilder;
    addBoldText: (text: string | undefined) => PrinterInstructionsBuilder;
    addCenteredText: (text: string | undefined) => PrinterInstructionsBuilder;
    addCenteredBoldText: (text: string | undefined) => PrinterInstructionsBuilder;
    addRightText: (text: string | undefined) => PrinterInstructionsBuilder;
    addBoldRightText: (text: string | undefined) => PrinterInstructionsBuilder;
    addSeparatedTexts: (leftText: string | undefined, rightText: string | undefined) => PrinterInstructionsBuilder;
    addSeparatedBoldLeftTexts: (leftText: string | undefined, rightText: string | undefined) => PrinterInstructionsBuilder;
    addBoldSeparatedTexts: (leftText: string | undefined, rightText: string | undefined) => PrinterInstructionsBuilder;
    addColumns: (columns: Array<BuilderPrintColumn>) => PrinterInstructionsBuilder;
    addBoldColumns: (columns: Array<BuilderPrintColumn>) => PrinterInstructionsBuilder;
    addLogoImage: (url: string) => PrinterInstructionsBuilder;
    addQrCode: (url: string) => PrinterInstructionsBuilder;
    addNewLine: () => PrinterInstructionsBuilder;
    addCenteredUnderLine: () => PrinterInstructionsBuilder;
    addLineSeparator: () => PrinterInstructionsBuilder;
    setLargerFont: () => PrinterInstructionsBuilder;
    setRegularFont: () => PrinterInstructionsBuilder;
    openCashbox: () => PrinterInstructionsBuilder;
    build: () => Array<PrinterInstruction>;
};

export type BuilderPrintColumn = {
    text: string | undefined;
    percentageWidth: number;
    fontWeight?: 'regular' | 'bold';
    textAlign?: 'left' | 'right'; // TODO: implement in all printers, first implementation is in ReactComponentPrinter
};
