/**
 * @prettier
 */
import { IconButton, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { MoneyBillsSelector } from 'src/components/MoneyBillsSelector';
import billOf20 from 'src/images/money/bill-20.svg';
import billOf50 from 'src/images/money/bill-50.svg';
import billOf100 from 'src/images/money/bill-100.svg';
import billOf200 from 'src/images/money/bill-200.svg';
import billOf500 from 'src/images/money/bill-500.svg';
import coin1 from 'src/images/money/coin-1.svg';
import coin2 from 'src/images/money/coin-2.svg';
import coin5 from 'src/images/money/coin-5.svg';
import coin10 from 'src/images/money/coin-10.svg';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function MoneyBillsNumericPad({ disabled, onChange, onEnter }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [number, setNumber] = useState('0');

    const handleNewValue = (currencyValue: any) => changeNumber(Number(number) + currencyValue);

    const changeNumber = (newNumber: any) => {
        setNumber(BigNumber(newNumber).toString());
        onChange?.(newNumber);
    };

    const handleEnter = async () => {
        const failed = await onEnter(number);
        if (!failed) setNumber('0');
    };

    return (
        <div className={classes.container}>
            <div className={classes.displayContainer}>
                <p className={classes.numberText}>{formatAsCurrencyNumber(number)}</p>
                <IconButton disabled={disabled} className={classes.checkButton} onClick={handleEnter}>
                    <CheckIcon titleAccess={'check icon'} />
                </IconButton>
            </div>

            <div className={classes.row}>
                <MoneyBillsSelector value={20} onChange={handleNewValue} image={billOf20} />
                <MoneyBillsSelector value={1} onChange={handleNewValue} image={coin1} />
            </div>
            <div className={classes.row}>
                <MoneyBillsSelector value={50} onChange={handleNewValue} image={billOf50} />
                <MoneyBillsSelector value={2} onChange={handleNewValue} image={coin2} />
            </div>
            <div className={classes.row}>
                <MoneyBillsSelector value={100} onChange={handleNewValue} image={billOf100} />
                <MoneyBillsSelector value={5} onChange={handleNewValue} image={coin5} />
            </div>
            <div className={classes.row}>
                <MoneyBillsSelector value={200} onChange={handleNewValue} image={billOf200} />
                <MoneyBillsSelector value={10} onChange={handleNewValue} image={coin10} />
            </div>
            <div className={classes.onlyOne}>
                <MoneyBillsSelector value={500} onChange={handleNewValue} image={billOf500} />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        flexShrink: 0,
    },
    displayContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
    },
    checkButton: {
        width: 48,
        height: 48,
        margin: 'auto',
        backgroundColor: '#63d6b357',
        position: 'absolute',
        right: '-16px',
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    onlyOne: {
        width: '98%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '0 10px 0 0',
    },
    numberText: {
        fontFamily: theme.typography.medium,
        fontSize: 42,
        color: '#2E3748',
        margin: 0,
        marginBottom: 12,
    },
    button: {
        width: 80,
        height: 80,
        fontFamily: theme.typography.medium,
        fontSize: 18,
    },
    paymentButton: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        textTransform: 'none',
        color: 'white',
        borderRadius: 5,
        padding: '8px 0',
        fontSize: 14,
        height: 42,
        fontFamily: theme.typography.semiBold,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));

type Props = {
    disabled?: boolean;
    onChange?: any;
    onEnter: any;
};
