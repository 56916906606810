/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BrandVm } from 'src/types/BrandVm';
import type { BrandId, RestaurantId } from 'src/types/Id';

export async function changeBrandApi(request: ChangeBrandApiRequest): ApiSauceResponse<BrandVm> {
    return letseatadminApiMethod('brand/changeBrandApi', request);
}

export type ChangeBrandApiRequest = {
    brandId: BrandId;
    restaurantIds: Array<RestaurantId>;
    brandName: string;
    comment?: string;
    marketingActionsForRestaurantEnabled: boolean;
};
