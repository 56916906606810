/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getDriverApi } from 'src/api/letseatadmin/driver/getDriverApi';
import { sendSmsToDriverApi } from 'src/api/letseatmanager/driver/sendSmsToDriverApi';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { DriverId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { requireValue } from 'src/utils/require/requireValue';

export function SendSmsToDriverDialog({ open, driverId, onClose }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) load();
    }, [open]);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const handleExited = () => {
        setLoading(false);
    };

    const load = async () => {
        setLoading(true);
        const response = await getDriverApi({ driverId: requireValue(driverId) });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            onClose();
            return;
        }
        const driver = response.data;
        form.reset(driver);
        setLoading(false);
    };

    const onSubmit = async (form: any) => {
        const response = await sendSmsToDriverApi({
            driverId: form.driverId,
            smsText: form.smsText,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} onExited={handleExited}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Send SMS To Driver').toUpperCase()}</DialogTitle>
                <DialogContent>
                    <FormContent loading={loading} />
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={loading || isSubmitting}>
                        {isSubmitting ? translate('Sending').toUpperCase() : translate('Send').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

function FormContent({ loading }: { loading: boolean }) {
    if (loading) {
        return <Loader size={40} loading={true} />;
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <FormTextField name='driverId' label={translate('Driver Id')} disabled />
            </Grid>
            <Grid item xs={12}>
                <FormTextField name='firstName' label={translate('First Name')} disabled />
            </Grid>
            <Grid item xs={12}>
                <FormTextField
                    name='smsText'
                    label={translate('SMS Text')}
                    helperText={`${translate('@name will be replaced with drivers first name or client, and this message only accept max 120 characters')}`}
                    multiline
                    maxLength={120}
                    required
                />
            </Grid>
        </Grid>
    );
}

type Props = SendSmsToDriverDialogState & {
    onClose: any;
};

export type SendSmsToDriverDialogState = {
    open: boolean;
    driverId?: DriverId;
};
