/**
 * @prettier
 */
import { Grid, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { changeDeviceGroupApi } from 'src/api/letseatmanager/deviceGroup/changeDeviceGroupApi';
import { getDeviceGroupApi } from 'src/api/letseatmanager/deviceGroup/getDeviceGroupApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { DeviceId, PaymentTerminalId, PrinterId, type DeviceGroupId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangeDeviceGroupNameDialog({ open, deviceGroupId, onClose, onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();
    const form = useForm();
    const { reloadRestaurant } = useReloadRestaurant();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [, deviceGroup] = useLoadApi(getDeviceGroupApi, { deviceGroupId: deviceGroupId! }, { initialValue: [], requiredValues: [deviceGroupId, open ? true : null] });

    const deviceIds = deviceGroup?.deviceIds?.map((deviceId: DeviceId) => deviceId);
    const printerIds = deviceGroup?.printerIds?.map((printerId: PrinterId) => printerId);
    const paymentTerminalIds = deviceGroup?.paymentTerminalIds?.map((paymentTerminalId: PaymentTerminalId) => paymentTerminalId);

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const changeDeviceGroup = async (form: any) => {
        if (!deviceGroupId) return;
        setLoading(true);
        const response = await changeDeviceGroupApi({
            deviceGroupId,
            deviceGroupName: form.deviceGroupName,
            deviceIds,
            printerIds,
            paymentTerminalIds,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        reloadRestaurant(restaurantId);
        onSuccess?.();
        onClose();
    };

    if (!open) return null;

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Change device group name')} loading={loading} classes={{ dialog: classes.dialog }}>
            <Form form={form} onSubmit={changeDeviceGroup}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormTextField name={'deviceGroupName'} label={translate('Group name')} required />
                    </Grid>
                </Grid>
                <DialogActions className={(classes as any).dialogActions}>
                    <Button secondary onClick={handleClose} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {translate('Save')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: 540,
        [theme.breakpoints.down('sm')]: {
            width: '90vw',
        },
    },
}));

type Props = {
    open: boolean;
    deviceGroupId: DeviceGroupId | undefined;
    onClose: any;
    onSuccess?: any;
};
