/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { ManagerUserId, RestaurantId } from 'src/types/Id';
import type { RestaurantEmployeeDayShiftVm } from 'src/types/RestaurantEmployeeDayShiftVm';

export async function clockInRestaurantEmployeeApi(request: ClockOutRestaurantEmployeeApiRequest): ApiSauceResponse<RestaurantEmployeeDayShiftVm> {
    return letseatmanagerApiMethod('restaurantEmployeeDayShift/clockInRestaurantEmployeeApi', request);
}

type ClockOutRestaurantEmployeeApiRequest = {
    restaurantId: RestaurantId;
    managerUserId: ManagerUserId;
    clockInBy: ManagerUserId;
};
