/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { moveMenuItemToMenuApi } from 'src/api/letseatmanager/menu/moveMenuItemToMenuApi';
import { app2 } from 'src/app2';
import { Form } from 'src/components/form/Form';
import { FormCategorySelect } from 'src/components/form/FormCategorySelect';
import { FormMenuSelect } from 'src/components/form/FormMenuSelect';
import { translate } from 'src/i18n/translate';
import { MenuId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function MoveMenuItemToMenuDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const { control } = form;

    const [loading, setLoading] = useState(false);

    const open = useSelector((state) => state.app2.moveMenuItemToMenuDialog.open);
    const menuItem = useSelector((state) => state.app2.moveMenuItemToMenuDialog.menuItem);
    const onSuccess = useSelector((state) => state.app2.moveMenuItemToMenuDialog.onSuccess);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const closeMoveMenuItemToMenuDialog = useAction(app2.actions.closeMoveMenuItemToMenuDialog);

    const menuId = useWatch({ name: 'menuId', control });

    const handleClose = () => {
        if (loading) return;
        closeMoveMenuItemToMenuDialog();
    };

    const handleSubmit = async (form: any) => {
        setLoading(true);
        const response = await moveMenuItemToMenuApi({
            restaurantId,
            menuId: form.menuId,
            menuCategoryId: form.menuCategoryId,
            menuItemId: menuItem?.menuItemId,
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        onSuccess();
        closeMoveMenuItemToMenuDialog();
    };

    return (
        <Dialog aria-labelledby='remove-menu-item-dialog-title' scroll='paper' open={open} onClose={handleClose}>
            <Form onSubmit={handleSubmit} form={form} className={classes.form}>
                <DialogTitle id='remove-menu-item-dialog-title' classes={{ root: classes.dialogTitle }}>
                    {translate('Move to Menu')}
                </DialogTitle>
                <DialogContent style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormMenuSelect label={translate('Menu')} name='menuId' required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCategorySelect label={translate('Category')} name='menuCategoryId' menuId={menuId as MenuId} disabled={!menuId} required />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.buttonsContainer}>
                    <Button onClick={handleClose} disabled={loading}>
                        <div className={classes.cancelButton}>{translate('Cancel')}</div>
                    </Button>
                    <Button color='primary' type='submit' disabled={loading} classes={{ root: classes.noMargin }}>
                        <div className={classes.acceptButton}>{loading ? translate('Moving') : translate('Move')}</div>
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        height: '90vh',
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '90%',
        margin: '0 auto',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    noMargin: {
        margin: '0 !important',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    inputDescription: {
        height: '15vh',
        alignItems: 'flex-start',
    },
    inputDescriptionError: {
        border: '2px solid red',
    },
    menuItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        width: 'fit-content',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    image: {
        borderRadius: 8,
        width: 70,
        height: 70,
        objectFit: 'cover',
        flexShrink: 0,
    },
    noImageContainer: {
        borderRadius: 8,
        width: 70,
        height: 70,
        backgroundColor: theme.palette.secondary.dark,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
    },
    noImage: {
        width: 30,
        height: 30,
        objectFit: 'cover',
    },
    menuItemInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 20,
    },
    name: {
        fontFamily: theme.typography.semiBold,
        fontSize: 17,
        margin: 0,
    },
    description: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        margin: 0,
        marginTop: 5,
    },
}));
