/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { SectionTitle } from 'src/components/title/SectionTitle';
import { TicketSections } from 'src/constants/TicketSection';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';
import { canRestaurantInvoice } from 'src/utils/restaurant/canRestaurantInvoice';

export function TicketInvoiceInfoSettings({ disabled }: Props): React.ReactElement | null {
    const restaurant = useSelector((state) => state.app.restaurant);

    const showInvoiceInfo = canRestaurantInvoice(restaurant) && restaurant?.urlSubdomain && restaurant?.urlPathname;

    if (!showInvoiceInfo) return null;

    return (
        <section>
            <SectionTitle title={translate('Billing')} />
            {/* @ts-ignore */}
            <Grid item xs={12} columnSpacing={2}>
                <FormSwitch disabled={disabled} name={TicketSections.INVOICE_LINK} label={translate(`TicketSections.${TicketSections.INVOICE_LINK}`)} />
            </Grid>
            {/* @ts-ignore */}
            <Grid item xs={12} columnSpacing={2}>
                <FormSwitch disabled={disabled} name={TicketSections.INVOICE_QR} label={translate(`TicketSections.${TicketSections.INVOICE_QR}`)} helperText={translate('Only available for app')} />
            </Grid>
        </section>
    );
}

type Props = {
    disabled: boolean;
};
