/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import type { SurveyAnswersFrequency } from 'src/api/letseatmanager/restaurantDashboard/getCustomerRestaurantStatisticsApi';
import { BarChartCanvas } from 'src/components/charts/BarChartCanvas';
import { translate } from 'src/i18n/translate';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';

export function FrequencyAnswerCustomerGraph({ loading, frequencyAnswerData }: Props): React.ReactElement | null {
    const classes = useStyles();

    const frequencyAnswer = frequencyAnswerData ?? [];

    const hasFrequencyAnswerByCustomer = frequencyAnswer.length > 0;

    const translatedFrequencySurveyAnswers = frequencyAnswer.map((surveyAnswersFrequency) => ({
        surveyAnswers: translate(`SurveyAnswers.${surveyAnswersFrequency.surveyAnswers}`),
        surveyVolume: surveyAnswersFrequency.surveyVolume,
    }));

    if (!hasFrequencyAnswerByCustomer) return null;

    return (
        <ReportSection title={translate('Frequent Survey Answers')} loading={loading}>
            <div className={classes.graphContainer}>
                <BarChartCanvas data={translatedFrequencySurveyAnswers} keys={['surveyVolume']} indexBy={'surveyAnswers'} tickRotation={0} classes={{ container: classes.barCharCanvasContainer }} />
            </div>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    graphContainer: {
        marginBottom: 50,
    },
    tableContainer: {
        width: '60%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    kpisContainerDesktop: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.between('xs', 'sm')]: {
            display: 'none',
        },
    },
    kpisContainerMobile: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.between('md', 'lg')]: {
            display: 'none',
        },
    },
    containerBarchart: {
        width: '100%',
        height: '100%',
    },
    barCharCanvasContainer: {
        width: '100%',
        height: 400,
    },
}));

type Props = {
    loading: boolean;
    frequencyAnswerData: Array<SurveyAnswersFrequency>;
};
