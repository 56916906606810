/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesType } from 'src/constants/SalesTypes';
import type { RestaurantId } from 'src/types/Id';
import type { ManagerUserSalesReportVm } from 'src/types/ManagerUserSalesReportVm';

export async function getManagerUserSalesReportApi(request: GetManagerUserSalesReportApiRequest): ApiSauceResponse<ManagerUserSalesReportVm> {
    return pidedirectoReportsMethod('/getManagerUserSalesReportApi', request);
}

type GetManagerUserSalesReportApiRequest = {
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
    salesType: SalesType;
};
