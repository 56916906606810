/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { findKioskUserApi } from 'src/api/letseatadmin/managerUser/findKioskUserApi';
import { changeTheRestaurantApi } from 'src/api/letseatadmin/theRestaurant/changeTheRestaurantApi';
import { Button } from 'src/components/Button';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormNumberStringField } from 'src/components/form/FormNumberStringField';
import { FormPinCode } from 'src/components/form/FormPinCode';
import { FormSoundSelect } from 'src/components/form/FormSoundSelect';
import { FormSubheading } from 'src/components/form/FormSubheading';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormUrlImageField } from 'src/components/form/FormUrlImageField';
import { getDirtyFormValues } from 'src/components/form/utils/getDirtyFormValues';
import { Tooltip } from 'src/components/Tooltip';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { ImageTypes } from 'src/constants/ImageType';
import { Sounds } from 'src/constants/Sound';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { NewDeviceManagementConfirmDialog } from 'src/scenes/letseatadmin/theRestaurant/NewDeviceManagementConfirmDialog';
import { RestaurantInventorySettings } from 'src/scenes/letseatadmin/theRestaurant/RestaurantInventorySettings';
import { RestaurantUserManagerSettings } from 'src/scenes/letseatadmin/theRestaurant/RestaurantUserManagerSettings';
import { AppsUsedAtThePosBusinessDayReportSettings } from 'src/scenes/letseatmanager/theRestaurant/AppsUsedAtTheEndOfTheDayReportSettings';
import { CashRegisterDepositReasonsSettings } from 'src/scenes/letseatmanager/theRestaurant/CashRegisterDepositReasonsSettings';
import { CashRegisterWithdrawReasonsSettings } from 'src/scenes/letseatmanager/theRestaurant/CashRegisterWithdrawReasonsSettings';
import { DiscountCommentsSettings } from 'src/scenes/letseatmanager/theRestaurant/DiscountCommentsSettings';
import { ManageCoursesButton } from 'src/scenes/letseatmanager/theRestaurant/ManageCoursesButton';
import { CurrenciesSettings } from 'src/scenes/letseatmanager/theRestaurant/pos/CurrenciesSettings';
import { ProductCancellationReasonsSettings } from 'src/scenes/letseatmanager/theRestaurant/ProductCancellationReasonsSettings';
import { migrateImage } from 'src/services/image/migrateImage';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { appColors } from 'src/theme/AppColors';
import { ManagerUserVm } from 'src/types/ManagerUserVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getErrorDescriptionFromResponse } from 'src/utils/error/getErrorDescriptionFromResponse';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { playAlertSound } from 'src/utils/sound/playAlertSound';

export function RestaurantPosSettings({ loading, restaurant, onChangeRestaurant, setIsDirtyForm }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { dirtyFields },
        setValue,
        control,
        formState,
    } = form;

    const posRestaurantZonesEnabled = useWatch<boolean>({ name: 'posRestaurantZonesEnabled', control });
    const kioskEnabled = useWatch<boolean>({ name: 'kioskEnabled', control });
    const qpayTerminalCommission = useWatch({ name: 'useQpayTerminalEnabled', control });
    const posEnabledForm = useWatch({ name: 'posEnabled', control });
    const posCustomDiscountCommentsEnabled = useWatch<boolean>({ name: 'posCustomDiscountCommentsEnabled', control });
    const userManagementEnabled = useWatch<boolean>({ name: 'userManagementEnabled', control });
    const posMultipleCashRegistersEnabled = useWatch<boolean>({ name: 'posMultipleCashRegistersEnabled', control });
    const posTakeOrderByCourseEnabled = useWatch<boolean>({ name: 'posTakeOrderByCourseEnabled', control });
    const newPrinterUiEnabledToggle = useWatch({ name: 'newPrinterUiEnabled', control });
    const newDeviceManagementEnabledToggle = useWatch({ name: 'newDeviceManagementEnabled', control });
    const maximumCashAllowedInCashRegisterEnabled = useWatch<boolean>({ name: 'maximumCashAllowedInCashRegisterEnabled', control });
    const acceptManuallyAllOrdersEnabled = useWatch<boolean>({ name: 'acceptManuallyAllOrdersEnabled', control });
    const posPendingOrdersEnabled = useWatch<boolean>({ name: 'posPendingOrdersEnabled', control });
    const kioskOrdersCashbackEnabled = useWatch<boolean>({ name: 'kioskOrdersCashbackEnabled', control });
    const kitchenDisplayScreenEnabled = useWatch<boolean>({ name: 'kitchenDisplayScreenEnabled', control });

    const [uploadingImage, setUploadingImage] = useState(false);
    const [showKioskUser, setShowKioskUser] = useState(false);
    const [loadingKioskUser, setLoadingKioskUser] = useState(false);
    const [kioskUser, setKioskUser] = useState<ManagerUserVm>();
    const [newDeviceManagementConfirmDialogState, setNewDeviceManagementConfirmDialogState] = useState({ open: false });

    const appLoading = useSelector((state) => state.app.loading);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);
    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant?.newMenuManagementEnabled);
    const cashRegisterOpened = useSelector((state) => state.pos.cashRegisterOpened);
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);
    const { reloadRestaurant } = useReloadRestaurant();

    useEffect(() => {
        if (!loading)
            form.reset({
                restaurantId: restaurant.restaurantId ?? null,
                kioskBackgroundImageUrl: restaurant.kioskBackgroundImageUrl ?? null,
                posOnlyCashInCashRegisterReportEnabled: restaurant.posOnlyCashInCashRegisterReportEnabled ?? null,
                logoImageUrl: restaurant.logoImageUrl ?? null,
                posEnabled: restaurant.posEnabled ?? null,
                posPendingOrdersEnabled: restaurant.posPendingOrdersEnabled ?? null,
                posUseMenuItemColorEnabled: restaurant.posUseMenuItemColorEnabled ?? null,
                posRestaurantZonesEnabled: restaurant.posRestaurantZonesEnabled ?? null,
                posShowTablesEnabled: restaurant.posShowTablesEnabled ?? null,
                posTableRequiredEnabled: restaurant.posTableRequiredEnabled ?? null,
                posCustomDiscountCommentsEnabled: restaurant.posCustomDiscountCommentsEnabled ?? null,
                posCustomersRequiredEnabled: restaurant.posCustomersRequiredEnabled ?? null,
                posCustomerNameRequiredOnTakeAwayOrdersEnabled: restaurant.posCustomerNameRequiredOnTakeAwayOrdersEnabled ?? null,
                useOcaPaymentTerminalEnabled: restaurant.useOcaPaymentTerminalEnabled ?? null,
                useQpayTerminalEnabled: restaurant.useQpayTerminalEnabled ?? null,
                qpayTerminalCommission: restaurant.qpayTerminalCommission ?? null,
                qpayTerminalDeviceId: restaurant.qpayTerminalDeviceId ?? null,
                kioskQpayTerminalDeviceId: restaurant.kioskQpayTerminalDeviceId ?? null,
                posPassword: restaurant.posPassword ?? null,
                userManagementEnabled: restaurant.userManagementEnabled ?? null,
                clockInEmployeeDayShiftEnabled: restaurant.clockInEmployeeDayShiftEnabled ?? null,
                clockInMessage: restaurant.clockInMessage ?? null,
                clockOutMessage: restaurant.clockOutMessage ?? null,
                kioskEnabled: restaurant.kioskEnabled ?? null,
                kioskPayWithCreditCardInCounterEnabled: restaurant.kioskPayWithCreditCardInCounterEnabled ?? null,
                ticketCustomHeader: restaurant.ticketCustomHeader ?? null,
                kitchenDisplayScreenEnabled: restaurant.kitchenDisplayScreenEnabled ?? null,
                pwa: restaurant.pwa ?? null,
                supplyLimitAlertEnabled: restaurant.supplyLimitAlertEnabled ?? null,
                cashRegisterWithdrawReasons: restaurant.cashRegisterWithdrawReasons ?? null,
                cashRegisterDepositReasons: restaurant.cashRegisterDepositReasons ?? null,
                productCancellationReasons: restaurant.productCancellationReasons ?? null,
                authorizationToReprintCompletedOrderEnabled: restaurant.authorizationToReprintCompletedOrderEnabled ?? null,
                requestPasswordForClosePosBusinessDayEnabled: restaurant.requestPasswordForClosePosBusinessDayEnabled ?? null,
                preventClosePosBusinessDayWithOrdersNotInvoicedEnabled: restaurant.preventClosePosBusinessDayWithOrdersNotInvoicedEnabled ?? null,
                inventoryEnabled: restaurant.inventoryEnabled ?? null,
                updateInventoryForCountsEnabled: restaurant.updateInventoryForCountsEnabled ?? null,
                inventoryShowNegative: restaurant.inventoryShowNegative ?? null,
                buyOrdersEnabled: restaurant.buyOrdersEnabled ?? null,
                supplyCategoryIds: restaurant.supplyCategoryIds ?? [],
                moneyBillsPadEnabled: restaurant.moneyBillsPadEnabled ?? null,
                newDeviceManagementEnabled: restaurant.newDeviceManagementEnabled ?? null,
                newPrinterUiEnabled: restaurant.newPrinterUiEnabled ?? null,
                kioskOrdersCashbackPercentage: restaurant.kioskOrdersCashbackPercentage ?? null,
                kioskOrdersCashbackEnabled: restaurant.kioskOrdersCashbackEnabled ?? null,
                customDiscountComments: restaurant.customDiscountComments ?? null,
                posMultipleCashRegistersEnabled: restaurant.posMultipleCashRegistersEnabled ?? null,
                posTakeOrderByCourseEnabled: restaurant.posTakeOrderByCourseEnabled ?? null,
                assignCashRegisterForExternalMissingOrdersEnabled: restaurant.assignCashRegisterForExternalMissingOrdersEnabled ?? null,
                avoidOpenCashBoxOnReprintTicketEnabled: restaurant.avoidOpenCashBoxOnReprintTicketEnabled ?? null,
                openCashRegisterForTransactionsEnabled: restaurant.openCashRegisterForTransactionsEnabled ?? null,
                openCashRegisterButtonEnabled: restaurant.openCashRegisterButtonEnabled ?? null,
                printCashRegisterTransactionsEnabled: restaurant.printCashRegisterTransactionsEnabled ?? null,
                maximumCashAllowedInCashRegisterEnabled: restaurant.maximumCashAllowedInCashRegisterEnabled ?? null,
                maximumCashAllowedInCashRegister: restaurant.maximumCashAllowedInCashRegister ?? null,
                acceptManuallyAllOrdersEnabled: restaurant.acceptManuallyAllOrdersEnabled ?? null,
                posTablesTabAsDefaultEnabled: restaurant.posTablesTabAsDefaultEnabled ?? null,
                appsContemplatedAtTheEndOfTheDayReports: restaurant.channelsContemplatedAtTheEndOfDayReport ?? null,
                currencies: restaurant.currencies ?? null,
                acceptMultipleCurrenciesPaymentEnabled: restaurant.acceptMultipleCurrenciesPaymentEnabled ?? null,
                kitchenDisplayScreenAlertSound: restaurant.kitchenDisplayScreenAlertSound ?? null,
                includeBreakdownIntegrationsInPosBusinessDayEnabled: restaurant.includeBreakdownIntegrationsInPosBusinessDayEnabled ?? null,
            });
    }, [loading]);

    useEffect(() => {
        if (!posRestaurantZonesEnabled) {
            setValue('posShowTablesEnabled', false);
            setValue('posTableRequiredEnabled', false);
            setValue('posCustomersRequiredEnabled', false);
        }
    }, [posRestaurantZonesEnabled]);

    useEffect(() => {
        if (acceptManuallyAllOrdersEnabled) {
            setValue('posPendingOrdersEnabled', false);
        }
    }, [acceptManuallyAllOrdersEnabled]);

    useEffect(() => {
        if (posPendingOrdersEnabled) {
            setValue('acceptManuallyAllOrdersEnabled', false);
        }
    }, [posPendingOrdersEnabled]);

    useEffect(() => {
        setIsDirtyForm(Object.keys(dirtyFields).length !== 0);
    }, [formState]);

    useEffect(() => {
        if (newDeviceManagementEnabledToggle && !newDeviceManagementEnabled) {
            setNewDeviceManagementConfirmDialogState({ open: true });
        }
    }, [newDeviceManagementEnabledToggle, newDeviceManagementEnabled]);

    const onSubmit = async (form: any) => {
        const dirtyFormValues = getDirtyFormValues({ form, dirtyFields });
        if (dirtyFormValues.kioskBackgroundImageUrl) {
            const kioskBackgroundImageUrl = await migrateImage({
                imageUrl: dirtyFormValues.kioskBackgroundImageUrl,
                imageType: ImageTypes.RESTAURANT,
            });

            if (kioskBackgroundImageUrl.error) {
                alert(`Failed to save cause of ${getErrorDescriptionFromResponse(kioskBackgroundImageUrl.migrateResponse)}`);
                return;
            }
        }

        if (dirtyFormValues.logoImageUrl) {
            const logoImageUrl = await migrateImage({
                imageUrl: dirtyFormValues.logoImageUrl,
                imageType: ImageTypes.RESTAURANT,
            });

            if (logoImageUrl.error) {
                alert(`Failed to save cause of ${getErrorDescriptionFromResponse(logoImageUrl.migrateResponse)}`);
                return;
            }
        }

        const response = await changeTheRestaurantApi({ restaurantId, ...dirtyFormValues });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        await reloadRestaurant(restaurantId);
        await onChangeRestaurant();
        showSnackbar({ message: translate('Saved changes') });
    };

    const handleShowKioskUser = async () => {
        setLoadingKioskUser(true);
        const response = await findKioskUserApi({ restaurantId });
        setLoadingKioskUser(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setKioskUser(response.data);
    };

    const onChangeSuccess = () => {
        if (!dirtyFields) {
            onChangeRestaurant();
            return;
        }

        onSubmit(form.getValues());
    };

    return (
        <Paper className={classes.paper}>
            <UpdatingContentProgress loading={loading || appLoading} />
            <NewDeviceManagementConfirmDialog
                open={newDeviceManagementConfirmDialogState.open}
                onClose={() => setNewDeviceManagementConfirmDialogState({ open: false })}
                onSuccess={() => setNewDeviceManagementConfirmDialogState({ open: false })}
            />

            <Form form={form} onSubmit={onSubmit} disabled={loading || appLoading}>
                <div className={classes.formContent}>
                    <div className={classes.section}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <h1 className={(classes as any).subtitle}>{translate('General')}</h1>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <FormSwitch name='posEnabled' label={translate('POS Enabled')} tooltip={translate('Enable the POS')} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormSwitch
                                    name='posPendingOrdersEnabled'
                                    label={translate('POS Pending Orders Enabled')}
                                    disabled={acceptManuallyAllOrdersEnabled || restaurant.sendWebhookEventsForPosOrdersEnabled}
                                    tooltip={translate(
                                        'You enable this option if you want open orders in POS, it allows you to save orders without payment, it also allows you to add items to orders later.'
                                    )}
                                />
                            </Grid>
                            {!newDeviceManagementEnabledToggle && (
                                <Grid item xs={12} md={6}>
                                    <FormSwitch
                                        name='useQpayTerminalEnabled'
                                        label={translate('Use QPAY Terminal For POS')}
                                        tooltip={translate('Enable the option to allow paying with the qpay terminal')}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} md={6}>
                                <FormSwitch
                                    name='useOcaPaymentTerminalEnabled'
                                    label={translate('Use OCA Terminal For POS')}
                                    tooltip={translate('Enable the option to allow paying with the oca terminal')}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormSwitch
                                    name='acceptManuallyAllOrdersEnabled'
                                    disabled={posPendingOrdersEnabled}
                                    label={translate('Manually accept all created orders')}
                                    tooltip={translate('Activate this option if you want to be notified and forced to accept all types of orders including POS orders')}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormSwitch
                                    name='kitchenDisplayScreenEnabled'
                                    label={translate('Kitchen Display Screen Enabled')}
                                    tooltip={translate('Activate the option if you want to enable the KDS kitchen monitor (instead of or in addition to the printing of orders)')}
                                />
                            </Grid>
                            {kitchenDisplayScreenEnabled && (
                                <Grid item xs={12} md={6}>
                                    <FormSoundSelect
                                        name='kitchenDisplayScreenAlertSound'
                                        required={kitchenDisplayScreenEnabled}
                                        defaultValue={Sounds.PIDE_DIRECTO}
                                        label={translate('Alert Sound for KDS')}
                                        onChange={(sound) => playAlertSound(sound)}
                                        tooltip={translate('Select Alert sound for incoming orders when you are on kitchen display screen')}
                                    />
                                </Grid>
                            )}
                            {!newPrinterUiEnabledToggle && !newDeviceManagementEnabledToggle && (
                                <Grid item xs={12} md={6}>
                                    <FormTextField
                                        name='qpayTerminalDeviceId'
                                        label={translate('Qpay Terminal Device Id')}
                                        tooltip={translate('Put the ID of the QPAY terminal to be able to call the order collection instructions')}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} md={6}>
                                <FormPinCode
                                    name='posPassword'
                                    label={translate('Pos Settings Password')}
                                    required={posEnabledForm as any}
                                    tooltip={'Define the password to perform actions on the POS'}
                                />
                            </Grid>
                        </Grid>
                    </div>

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <section className={classes.section}>
                                <Grid item xs={12}>
                                    <h1 className={(classes as any).subtitle}>{translate('Administration')}</h1>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch name='newDeviceManagementEnabled' label={translate('Enable New Device Management')} />
                                </Grid>
                                {newMenuManagementEnabled && (
                                    <Grid item xs={12}>
                                        <FormSwitch
                                            name='posUseMenuItemColorEnabled'
                                            label={translate('POS Use Menu Item colors')}
                                            tooltip={translate('Activate this option if you want to use the color of the product in the POS menu item card')}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <DeprecatedFormPercentNumberField
                                        name='qpayTerminalCommission'
                                        label={translate('QPAY Terminal Commission')}
                                        tooltip={translate('Define the commission when paying with a Qpay terminal')}
                                        disabled={!qpayTerminalCommission}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <h1 className={(classes as any).subtitle}>{translate('Tables')}</h1>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='posCustomersRequiredEnabled'
                                        label={translate('POS Number Of Customers Required Enabled')}
                                        tooltip={translate('Activate the option if you want the number of diners for the table to be required')}
                                        disabled={!posRestaurantZonesEnabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='posRestaurantZonesEnabled'
                                        label={translate('POS Restaurant Areas Enabled')}
                                        tooltip={translate('Activate this option if you want to create or edit areas and tables in the Orders to eat here section.')}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='posShowTablesEnabled'
                                        label={translate('POS Show Tables When Order Enabled')}
                                        tooltip={translate('Enable the display of the selection of tables in the order creation process')}
                                        disabled={!posRestaurantZonesEnabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='posTablesTabAsDefaultEnabled'
                                        label={translate('POS Tables view as default')}
                                        tooltip={translate('Enable the option if you want to set the POS tables view instead of the default select order type view')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='posTableRequiredEnabled'
                                        label={translate('POS Table Required Enabled')}
                                        tooltip={translate('Activate the option if you want the table selection to be required, show the table selection when creating the order')}
                                        disabled={!posRestaurantZonesEnabled}
                                    />
                                </Grid>
                            </section>
                        </Grid>

                        <Grid item xs={6}>
                            <section className={classes.section}>
                                <Grid item xs={12}>
                                    <h1 className={(classes as any).subtitle}>{translate('Cash Register')}</h1>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='maximumCashAllowedInCashRegisterEnabled'
                                        label={translate('Define maximum cash on cash register')}
                                        tooltip={translate('Enabled option will take the value set to notify when this maximum has been reached and should be withdrawn')}
                                    />
                                </Grid>
                                {maximumCashAllowedInCashRegisterEnabled && (
                                    <Grid item xs={12}>
                                        <FormNumberStringField
                                            required={maximumCashAllowedInCashRegisterEnabled}
                                            name={'maximumCashAllowedInCashRegister'}
                                            label={translate('Maximum cash on cash register')}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='avoidOpenCashBoxOnReprintTicketEnabled'
                                        label={translate('Do not open the cash register on ticket reprints')}
                                        tooltip={translate('Activate this option if you do not want to open the cash register when reprinting a completed order that was paid with cash')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='authorizationToReprintCompletedOrderEnabled'
                                        label={translate('Request authorization to reprint completed order')}
                                        tooltip={translate('Enable the option if you want to request a POS password to reprint an order that has already been completed')}
                                    />
                                </Grid>
                                {userManagementEnabled && (
                                    <Grid item xs={12}>
                                        <FormSwitch
                                            disabled={cashRegisterOpened}
                                            name='posMultipleCashRegistersEnabled'
                                            label={translate('Enabled multiple cash registers')}
                                            tooltip={translate(
                                                'Activate this option if you want to have more than one cash register open at the same time. You need to not have open cash registers to activate'
                                            )}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='openCashRegisterButtonEnabled'
                                        label={translate('Show button to open cash register')}
                                        tooltip={translate('Activate this option if you want to show a button to open the cash register')}
                                    />
                                </Grid>
                                {posMultipleCashRegistersEnabled && (
                                    <Grid item xs={12}>
                                        <FormSwitch
                                            name='assignCashRegisterForExternalMissingOrdersEnabled'
                                            label={translate('Assign cash register for auto accepted orders')}
                                            tooltip={translate('Activate this option to be able to have visibility and control of the designated cash register for external or  auto accepted orders')}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='openCashRegisterForTransactionsEnabled'
                                        label={translate('Open cash register for withdrawals and deposits')}
                                        tooltip={translate('Activate the option if you want to open cash box when making deposits or withdrawals')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='printCashRegisterTransactionsEnabled'
                                        label={translate('Print withdrawal and deposit tickets')}
                                        tooltip={translate('Enable this option to print a ticket when making a deposit or withdrawal with the details of the transaction')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <h1 className={(classes as any).subtitle}>{translate('Closing of the day and cash cut')}</h1>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='requestPasswordForClosePosBusinessDayEnabled'
                                        label={translate('Request password to make closing day')}
                                        tooltip={translate('Activate this option if you require the POS password to be requested to closing day')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='preventClosePosBusinessDayWithOrdersNotInvoicedEnabled'
                                        label={translate('Do not allow to close day with orders not invoiced')}
                                        tooltip={translate('Do not allow to close day meanwhile there are completed orders but not invoiced.')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='posOnlyCashInCashRegisterReportEnabled'
                                        label={translate('POS Cash Count Only in Cash Register')}
                                        tooltip={translate('Activate the option if you want to count only the cash in boxes, without taking card payments into account')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='includeBreakdownIntegrationsInPosBusinessDayEnabled'
                                        label={translate('Include integrations in cash cut  and day-end closing')}
                                        tooltip={translate(
                                            'The expected amounts for the integrations will be shown in the cash cut-off and closing of the day and will be displayed in the closing of the day report'
                                        )}
                                    />
                                </Grid>
                            </section>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <section className={classes.section}>
                                <Grid item xs={12}>
                                    <h1 className={(classes as any).subtitle}>{translate('Restaurant')}</h1>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormSwitch name='posTakeOrderByCourseEnabled' label={translate('POS Take orders per course')} disabled={loading} />
                                </Grid>

                                {posTakeOrderByCourseEnabled && (
                                    <Grid item xs={12} md={6}>
                                        <ManageCoursesButton />
                                    </Grid>
                                )}

                                <Grid item xs={12} md={6}>
                                    <FormSwitch name='posCustomDiscountCommentsEnabled' label={translate('POS Customizable discount comments')} disabled={loading} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormSwitch
                                        name='posCustomerNameRequiredOnTakeAwayOrdersEnabled'
                                        label={translate('POS Customer name on take away orders required')}
                                        tooltip={translate('Enable the option if you want the system to prompt for a name for takeout orders created at the POS')}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormSwitch name='moneyBillsPadEnabled' label={translate('Enable money bills numeric pad')} />
                                </Grid>
                            </section>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormSubheading align={'start' as any}>{translate('Personalization')}</FormSubheading>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            {posEnabled && <CashRegisterDepositReasonsSettings onSuccess={() => onChangeRestaurant()} />}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {posEnabled && <CashRegisterWithdrawReasonsSettings onSuccess={() => onChangeRestaurant()} />}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DeprecatedFormPercentNumberField
                                name='qpayTerminalCommission'
                                label={translate('QPAY Terminal Commission')}
                                tooltip={translate('Define the commission when paying with a Qpay terminal')}
                                disabled={!qpayTerminalCommission}
                            />

                            <ProductCancellationReasonsSettings onSuccess={() => onChangeRestaurant()} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AppsUsedAtThePosBusinessDayReportSettings onSuccess={onChangeRestaurant} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <CurrenciesSettings onSuccess={onChangeRestaurant} />
                        </Grid>
                        {posCustomDiscountCommentsEnabled && (
                            <Grid item xs={12} md={12}>
                                <DiscountCommentsSettings onSuccess={onChangeSuccess} />
                            </Grid>
                        )}
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <RestaurantUserManagerSettings />
                        </Grid>
                        <Grid item xs={12}>
                            <FormUrlImageField
                                name='logoImageUrl'
                                label={uploadingImage ? translate('Uploading Image...') : translate('POS login screen image')}
                                helperText={uploadingImage ? undefined : translate('Enter an URL to an image or drag and drop your image in this field')}
                            />
                        </Grid>
                    </Grid>

                    <RestaurantInventorySettings onChangeRestaurant={onChangeRestaurant} />

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormSubheading align={'start' as any}>{translate('Kiosk Settings')}</FormSubheading>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='kioskEnabled' label={translate('Kiosk Enabled')} tooltip={translate('Enable the kiosk for self service')} />
                        </Grid>
                        {!newDeviceManagementEnabled && (
                            <Grid item xs={12} md={6}>
                                <FormTextField
                                    name='kioskQpayTerminalDeviceId'
                                    label={translate('Qpay Terminal Device Id For Kiosk')}
                                    tooltip={translate('Put the qpay terminal ID to call the copper instructions in the KIOSK')}
                                />
                            </Grid>
                        )}
                        {kioskEnabled && (
                            <Grid item xs={12} md={6}>
                                <FormSwitch
                                    name='kioskPayWithCreditCardInCounterEnabled'
                                    label={translate('Pay with credit card in the counter')}
                                    tooltip={translate('Enabled option to pay in the counter with card when restaurant has payment with credit card enabled for kiosk')}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                            <FormTextField
                                name='kioskQpayTerminalDeviceId'
                                label={translate('Qpay Terminal Device Id For Kiosk')}
                                tooltip={translate('Put the qpay terminal ID to call the copper instructions in the KIOSK')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormUrlImageField
                                name='kioskBackgroundImageUrl'
                                label={uploadingImage ? translate('Uploading Image...') : translate('Kiosk Background Image')}
                                helperText={uploadingImage ? undefined : translate('Enter an URL to an image or drag and drop your image in this field')}
                            />
                        </Grid>
                        {kioskEnabled && (
                            <Grid item xs={12}>
                                <div className={classes.kioskUserContainer}>
                                    {showKioskUser && !loadingKioskUser && (
                                        <div className={classes.kioskInfo}>
                                            <span className={classes.text}>
                                                {translate('Username')}: {kioskUser?.username}
                                            </span>
                                            <span className={classes.text}>
                                                {translate('Password')}: {kioskUser?.password}
                                            </span>
                                        </div>
                                    )}
                                    {showKioskUser && loadingKioskUser && <Loader loading={true} size={12} />}
                                    {!showKioskUser && (
                                        <div className={classes.titleContainer}>
                                            <span className={classes.text}>{translate('Show kiosk User')}</span>
                                            <Tooltip text={translate('View hidden kiosk user credentials')} />
                                        </div>
                                    )}
                                    <Button
                                        onClick={() => {
                                            handleShowKioskUser();
                                            setShowKioskUser(!showKioskUser);
                                        }}
                                        classes={{ button: classes.setShowKioskUser }}
                                        icon
                                    >
                                        {showKioskUser ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </Button>
                                </div>
                            </Grid>
                        )}

                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='kioskOrdersCashbackEnabled'
                                label={translate('Kiosk Orders Cashback Enabled')}
                                tooltip={translate('Enable the option if you want the user to be awarded cashback points after they make a purchase.')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DeprecatedFormPercentNumberField
                                name='kioskOrdersCashbackPercentage'
                                label={translate('Kiosk Orders Cashback %')}
                                disabled={!kioskOrdersCashbackEnabled}
                                tooltip={translate('Define the percentage of cashback points for orders placed in the app')}
                            />
                        </Grid>
                    </Grid>
                    <FormFixErrorHint />
                </div>

                <Grid container justify='center' spacing={3}>
                    <Grid item>
                        <FormSubmitButton text={translate('Change')} disabled={loading || appLoading} classes={{ container: classes.submitButton }} />
                    </Grid>
                </Grid>
            </Form>
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
    },
    formContent: {
        padding: theme.spacing(3),
    },
    deleteButton: {
        color: appColors.text.dangerLight,
    },

    paymentMethod: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    kioskUserContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    kioskInfo: {
        display: 'flex',
        flexDirection: 'column',
    },

    titleContainer: {
        display: 'flex',
    },
    setShowKioskUser: {
        marginLeft: 10,
    },
    submitButton: {
        zIndex: 1000,
    },
    section: {
        width: '100%',
        borderRadius: 5,
        border: `1px solid ${theme.palette.secondary.dark}`,
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        gap: 20,
        marginTop: 20,
        marginBottom: 20,
    },
}));

type Props = {
    loading: boolean;
    restaurant: any;
    onChangeRestaurant: any;
    setIsDirtyForm: any;
};
