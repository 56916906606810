/**
 * @prettier
 */
export const OrderRejectReasons = {
    CLOSING_SOON: 'CLOSING_SOON',
    PROBLEM_IN_RESTAURANT: 'PROBLEM_IN_RESTAURANT',
    SOLD_OUT: 'SOLD_OUT',
    INCORRECT_PRICE: 'INCORRECT_PRICE',
    DRIVER_NOT_FOUND: 'DRIVER_NOT_FOUND',
    REJECTED_BY_ADMIN: 'REJECTED_BY_ADMIN',
    EXTERNAL_COURIER_CANCEL: 'EXTERNAL_COURIER_CANCEL',
    UNASSIGNED_COURIER: 'UNASSIGNED_COURIER',
    CANCELLED_BY_CLIENT_DUE_TO_WAITING_TIME: 'CANCELLED_BY_CLIENT_DUE_TO_WAITING_TIME',
    CANCELLED_DUE_TO_CLIENT_ERROR: 'CANCELLED_DUE_TO_CLIENT_ERROR',
    CANCELLED_BY_LACK_OF_CLIENT_CONTACT: 'CANCELLED_BY_LACK_OF_CLIENT_CONTACT',
    WANT_MODIFY_ORDER: 'WANT_MODIFY_ORDER',
    WAITING_DELAY_IN_PREPARATION: 'WAITING_DELAY_IN_PREPARATION',
    TECH_ERROR_WITH_THE_PLATFORM: 'TECH_ERROR_WITH_THE_PLATFORM',
    ERROR_WITH_CUSTOMER_DATA: 'ERROR_WITH_CUSTOMER_DATA',
    NO_CONTACT_WITH_ALLY: 'NO_CONTACT_WITH_ALLY',
    CHURNED_RESTAURANT: 'CHURNED_RESTAURANT',
    CLOSED_STORE: 'CLOSED_STORE',
    POORLY_PACK_PRODUCT: 'POORLY_PACK_PRODUCT',
    EXCEEDED_VOLUME: 'EXCEEDED_VOLUME',
    NO_CUSTOMER_CONTACT: 'NO_CUSTOMER_CONTACT',
    FRAUD: 'FRAUD',
    CAN_NOT_PAY_ORDER: 'CAN_NOT_PAY_ORDER',
    COURIER_ACCIDENT: 'COURIER_ACCIDENT',
    WEATHER_CONDITIONS: 'WEATHER_CONDITIONS',
    OUT_OF_COVERAGE: 'OUT_OF_COVERAGE',
    DUPLICATE_ASSIGNMENT: 'DUPLICATE_ASSIGNMENT',
    ORDER_HIGH_AMOUNT_LARGE_SIZE: 'ORDER_HIGH_AMOUNT_LARGE_SIZE',
    ORDER_THEFT: 'ORDER_THEFT',
    RED_ZONE: 'RED_ZONE',
    PROBLEMS_WITH_MANAGER: 'PROBLEMS_WITH_MANAGER',
    THE_ORDER_DOESNOT_APPEAR_SYSTEM: 'THE_ORDER_DOESNOT_APPEAR_SYSTEM',
    DUPLICATED_ORDER: 'DUPLICATED_ORDER',
    MISPLACED_PIN: 'MISPLACED_PIN',
    EDIT_ORDER: 'EDIT_ORDER',
    WRONG_ADDRESS: 'WRONG_ADDRESS',
    DELIVERY_ACCIDENT: 'DELIVERY_ACCIDENT',
    LOW_SUPPLY: 'LOW_SUPPLY',
    HIGH_DEMAND: 'HIGH_DEMAND',
    SATURATION: 'SATURATION',
    COMENSAL_NOT_RECEIVE: 'COMENSAL_NOT_RECEIVE',
    REFUND: 'REFUND',
    TICKET: 'TICKET',
    OTHER: 'OTHER', // @deprecated no longer used
    SK_DOESNT_ANSWER: 'SK_DOESNT_ANSWER',
    PROBLEMS_WITH_CLIENT_PAYMENT: 'PROBLEMS_WITH_CLIENT_PAYMENT',
    PRODUCT_NOT_AVAILABLE: 'PRODUCT_NOT_AVAILABLE',
    SK_DOESNT_WANT_DELIVERY_ORDER_WITH_PRODUCT: 'SK_DOESNT_WANT_DELIVERY_ORDER_WITH_PRODUCT',
    ANOTHER_SK_TOOK_ORDER: 'ANOTHER_SK_TOOK_ORDER',
    SK_COULDNT_COMPLETE_ORDER: 'SK_COULDNT_COMPLETE_ORDER',
    INCOMPLETE_ORDER_BAD_STATE: 'INCOMPLETE_ORDER_BAD_STATE',
    DOES_NOT_WORK_WITH_RAPPI: 'DOES_NOT_WORK_WITH_RAPPI',
    DIDNT_CANCEL_BY_AUTOMATION: 'DIDNT_CANCEL_BY_AUTOMATION',
    RELEASED_WITH_PRODUCT: 'RELEASED_WITH_PRODUCT',
    CLIENT_WHIM_NOT_ALLOWED_FAVOR: 'CLIENT_WHIM_NOT_ALLOWED_FAVOR',
    BILLING_ERROR: 'BILLING_ERROR',
    INNER_TEST: 'INNER_TEST',
    REVIEW: 'REVIEW',
    CANCEL_BY_USER: 'CANCEL_BY_USER',
    DRIVER_DID_NOT_ARRIVE: 'DRIVER_DID_NOT_ARRIVE',
    MANAGE_UBER_EATS_ORDERS_NOT_ALLOWED: 'MANAGE_UBER_EATS_ORDERS_NOT_ALLOWED',
} as const;

export type OrderRejectReason = typeof OrderRejectReasons[keyof typeof OrderRejectReasons];
