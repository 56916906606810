/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useRef } from 'react';
import type { DriverBonusVm } from 'src/api/types/driverBonus/DriverBonusVm';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { RestaurantId } from 'src/types/Id';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { createFilteredOnDownloadFunctional } from 'src/utils/mui-datatables/createFilteredOnDownloadFunctional';
import { formatAsNumber } from 'src/utils/number/formatAsNumber';

export function DriverBonusTable({ driverBonuses, loading, title, onClickRefresh }: Props): React.ReactElement {
    const classes = useStyles();
    const table = useRef(null);

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <MUIDataTable
                /* @ts-ignore */
                className={classes.table}
                ref={table}
                title={title || translate('Bonuses')}
                data={
                    driverBonuses &&
                    driverBonuses.map((driverBonus) => ({
                        driverBonusId: driverBonus.driverBonusId,
                        name: driverBonus.name,
                        description: driverBonus.description,
                        driverBonusType: driverBonus.driverBonusType,
                        driverBonusCategory: driverBonus.driverBonusCategory,
                        driverIncentiveType: driverBonus.driverIncentiveType,
                        earning: formatAsCurrencyNumber(driverBonus.earning),
                        collected: driverBonus.collected ? 'Collected' : '',
                        startedAt: formatDateTimeString(driverBonus.createdAt),
                        collectedAt: formatDateTimeString(driverBonus.collectedAt),
                        deliveries: formatAsNumber(driverBonus.deliveries),
                        deliveriesRequired: formatAsNumber(driverBonus.deliveriesRequired),
                        concurrent: driverBonus.concurrent ? 'Concurrent' : '',
                        city: translate(driverBonus.city),
                        hours: driverBonus.hours,
                        minDrivingDistance: formatAsNumber(driverBonus.minDrivingDistance),
                        minOrderSubtotal: formatAsCurrencyNumber(driverBonus.minOrderSubtotal),
                        restaurants: driverBonus.restaurants?.map((restaurant: { name: string; restaurantId: RestaurantId }) => restaurant.name)?.join(', '),
                        startsAt: formatDateTimeString(driverBonus.startsAt),
                        endsAt: formatDateTimeString(driverBonus.endsAt),
                    }))
                }
                columns={[
                    {
                        name: 'driverBonusId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'name',
                        label: translate('Name'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'description',
                        label: translate('Description'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'driverBonusType',
                        label: translate('Driver Bonus Type'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'driverBonusCategory',
                        label: translate('Driver Bonus Category'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'driverIncentiveType',
                        label: translate('Driver Incentive Type'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'earning',
                        label: translate('Earning'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'collected',
                        label: translate('Collected'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'startedAt',
                        label: translate('Started At'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'collectedAt',
                        label: translate('Collected At'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'deliveries',
                        label: translate('Deliveries'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'deliveriesRequired',
                        label: translate('Deliveries Required'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'concurrent',
                        label: translate('Concurrent'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'city',
                        label: translate('City'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'hours',
                        label: translate('Hours'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'minDrivingDistance',
                        label: translate('Min Driving Distance'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'minOrderSubtotal',
                        label: translate('Min Order Subtotal'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'restaurants',
                        label: translate('Restaurants'),
                        options: {
                            filter: false,
                            customBodyRender: (restaurants) => restaurants?.split(', ')?.map((restaurant: any, i: any) => <div key={`${restaurant}-${i}`}>{restaurant}</div>),
                        },
                    },
                    {
                        name: 'startsAt',
                        label: translate('Starts'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'endsAt',
                        label: translate('Ends'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={onClickRefresh} />
                        </>
                    ),
                    onDownload: createFilteredOnDownloadFunctional(table),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

type Props = {
    driverBonuses: Array<DriverBonusVm>;
    loading: boolean;
    title?: string;
    onClickRefresh: any;
};
