/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesType } from 'src/constants/SalesTypes';
import type { GetGeneralEcommerceReportVm } from 'src/types/GeneralEcommerceReportVm';
import type { RestaurantId } from 'src/types/Id';

export async function getGeneralEcommerceReportApi(request: GetGeneralEcommerceReportApiRequest): ApiSauceResponse<GetGeneralEcommerceReportVm> {
    return pidedirectoReportsMethod('/getGeneralEcommerceReportApi', request);
}

export type GetGeneralEcommerceReportApiRequest = {
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
    salesType: SalesType;
};
