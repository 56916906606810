/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from 'src/constants/RoutePath';
import { SubscriptionPlans } from 'src/constants/SubscriptionPlan';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function SubscriptionPayPerUseLimitReachedBanner(): React.ReactElement | null {
    const classes = useStyles();
    const history = useHistory();

    const [showReminderSubscription, setShowReminderSubscription] = useState(false);

    const payPerUsePaymentAmountLimitReached = useSelector((state) => state.app2.payPerUsePaymentAmountLimitReached);
    const subscriptionPlan = useSelector((state) => state.app2.subscriptionPlan);

    useEffect(() => {
        if (payPerUsePaymentAmountLimitReached && subscriptionPlan === SubscriptionPlans.PAY_PER_USE) {
            setShowReminderSubscription(true);
        }
    }, [payPerUsePaymentAmountLimitReached, subscriptionPlan, history.location.pathname]);

    const handleRedirectToSubscription = () => {
        history.push(RoutePaths.SUBSCRIPTION);
    };

    const handleCloseAlert = () => {
        setShowReminderSubscription(false);
    };

    if (!showReminderSubscription) return null;

    return (
        <>
            <Grid item xs={12} className={classes.alert}>
                <Alert
                    severity={'warning'}
                    classes={{ message: classes.alertMessage }}
                    action={
                        <IconButton aria-label='close' color='inherit' size='small' onClick={handleCloseAlert}>
                            <CloseIcon fontSize='inherit' />
                        </IconButton>
                    }
                    // @ts-ignore
                    sx={{ mb: 2 }}
                >
                    <div className={classes.alertMessageContainer}>
                        <div>
                            <AlertTitle classes={{ root: classes.alertTitle }}>{translate('You are close to reaching the usage limit of your pay per use subscription')}</AlertTitle>
                            {translate('Pay now and avoid suspension of your service')}
                        </div>
                        <Button variant='text' size='small' onClick={handleRedirectToSubscription}>
                            {translate(' Go to Subscription')}
                        </Button>
                    </div>
                </Alert>
            </Grid>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    alert: {
        marginBottom: theme.spacing(3),
        borderColor: '#ddd',
        borderWidth: '1px',
        borderStyle: 'solid',
    },
    alertTitle: {
        fontFamily: theme.typography.semiBold,
    },
    alertMessage: {
        fontFamily: theme.typography.medium,
        width: '100%',
    },
    alertMessageContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));
