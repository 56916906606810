/**
 * @prettier
 */

import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { ResponsiveLineCanvas } from 'src/components/charts/ResponsiveLineCanvas';
import { translate } from 'src/i18n/translate';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import type { SalesInSingleDayResults } from 'src/types/SmartBiReportVm';

export function SmartBiGraphPredictions({ loading, predictions }: Props): React.ReactElement {
    const classes = useStyles();

    const orders = [
        {
            id: 'predictions',
            color: `hsl(162, 58%, 61%)`,
            data: predictions.map((prediction) => ({
                x: prediction.createdAt,
                y: prediction.orders,
            })),
        },
    ];

    return (
        <ReportSection title={translate('14 day projection')} loading={loading}>
            <div className={classes.graphContainer}>
                <ResponsiveLineCanvas
                    data={orders}
                    colors={['#4075FF']}
                    tooltip={({ point }: { point: { data: { x: string; y: string } } }) => (
                        <div className={classes.tooltip}>
                            <span className={classes.tooltipText}>{translate(`Day: @day`, { day: point.data.x })}</span>
                            <span className={classes.tooltipText}>{translate(`Orders: @orders`, { orders: point.data.y })}</span>
                        </div>
                    )}
                />
            </div>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    graphContainer: {
        width: '100%',
        height: '45vh',
    },
    kpisContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '95%',
        height: '30%',
        justifyContent: 'space-around',
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100%',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            flexWrap: 'nowrap',
        },
    },
    tooltip: {
        color: theme.palette.text.secondary,
        padding: '8px 12px',
        textTransform: 'capitalize',
        borderRadius: '10px',
        backgroundColor: 'whitesmoke',
    },
    tooltipText: {
        textTransform: 'capitalize',
        display: 'flex',
        flexDirection: 'column',
    },
}));

type Props = {
    loading: boolean;
    predictions: Array<SalesInSingleDayResults>;
};
