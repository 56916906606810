/**
 * @prettier
 */
import { isString } from 'src/utils/string/isString';

export function lineWrapText<T extends string | null | undefined>(value: T, maxLength: number): T {
    if (value === undefined || value === null || !isString(value)) {
        return value;
    }
    const words = value.split(' ');
    const wrappedLines = words.reduce<Array<any>>((result: Array<string>, word: string) => {
        const wordTooLongForOneLine = word.length > maxLength;
        if (wordTooLongForOneLine) {
            const s = `.{1,${maxLength}}`;
            const splitWord: Array<string> = word.match(new RegExp(s, 'g')) ?? [];
            splitWord.forEach((w) => result.push(w));
            return result;
        }

        const firstLine = result[0] === undefined;
        if (firstLine) {
            result[0] = word;
            return result;
        }

        if (result[result.length - 1].length + 1 + word.length <= maxLength) {
            result[result.length - 1] = `${result[result.length - 1]} ${word}`;
            return result;
        } else {
            result.push(word);
            return result;
        }
    }, []);
    return wrappedLines.join('\n') as any;
}
