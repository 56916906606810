/**
 * @prettier
 */
import * as React from 'react';
import { findCashRegisterDepositReasonsApi } from 'src/api/letseatmanager/pos/findCashRegisterDepositReasonsApi';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { CashRegisterDepositReasons } from 'src/constants/CashRegisterDepositReason';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { translateCashRegisterDepositReasons } from 'src/utils/translate/translateCashRegisterDepositReasons';

export function FormCashRegisterDepositReasonSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant }: Props): React.ReactElement {
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, cashRegisterDepositReasons, getDepositReasons] = useLoadApi(findCashRegisterDepositReasonsApi, { restaurantId }, { initialValue: [] });

    const customCashRegisterDepositReasons = cashRegisterDepositReasons
        ? cashRegisterDepositReasons.map((depositReason) => depositReason.reason).concat(Object.values(CashRegisterDepositReasons) as any)
        : Object.values(CashRegisterDepositReasons);

    const options = required
        ? customCashRegisterDepositReasons.map((cashRegisterDepositReason: any) => ({
              label: translateCashRegisterDepositReasons(cashRegisterDepositReason),
              value: cashRegisterDepositReason,
          }))
        : [
              { label: '', value: null },
              ...customCashRegisterDepositReasons.map((cashRegisterDepositReason: any) => ({
                  label: translateCashRegisterDepositReasons(cashRegisterDepositReason),
                  value: cashRegisterDepositReason,
              })),
          ];
    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled || loading}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
};
