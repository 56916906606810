/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import { PosState } from 'src/reducers/posReducer';
import type { CartItemVm } from 'src/types/CartItemVm';
import { PosCourseOrderDetails } from 'src/types/PosCourseOrderDetails';

export function addCoursePosItem(state: PosState, item: CartItemVm): void {
    if (!state.courseIdSelected) return;
    const course = state.ongoingCourses?.find((course: PosCourseOrderDetails) => course.courseId === state.courseIdSelected);

    if (!course) return;

    const existingPosItem = course.orderItems?.find((current: CartItemVm) => current.key === item.key);

    if (existingPosItem) {
        existingPosItem.quantity = BigNumber(existingPosItem.quantity).plus(item.quantity).toNumber();
        return;
    }

    course.orderItems = [...course.orderItems, item];
}
