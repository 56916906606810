/**
 * @prettier
 */
import type { EmailTemplateId } from 'src/types/Id';

export const KEY_NAME = 'emailTemplateOptions';

export function getEmailTemplateOptionsInLocalStorage(): Array<EmailTemplateOption> {
    const item = window.localStorage.getItem('emailTemplateOptions');
    return item ? JSON.parse(item) : [];
}

export type EmailTemplateOption = {
    label: string;
    value: EmailTemplateId;
};
