/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import * as React from 'react';
import { memo } from 'react';
import { Button } from 'src/components/Button';
import { classNames } from 'src/utils/react/classNames';

export const PosItemQuantitySpinner: React.ComponentType<Props> = memo<Props>(({ classes: classesProp, quantity, title, onSubtract, onAdd, variant = 'primary' }) => {
    const classes = useStyles();

    const getIconClassNames = () => {
        return classNames(classes.icon, classesProp?.icon, variant === 'secondary' ? classes.secondaryIcon : '');
    };

    return (
        <div className={classNames(classes.container, classesProp?.container)}>
            <Button
                classes={{ button: classNames(classes.spinnerButton, classesProp?.substract, classesProp?.button, variant === 'secondary' ? (classes as any).secondarySubstract : '') }}
                onClick={onSubtract}
            >
                <RemoveIcon name={'removeItem'} titleAccess={`remove icon ${title ?? ''}`} className={getIconClassNames()} />
            </Button>
            <span className={classNames(classes.quantity, classesProp?.quantity, variant === 'secondary' ? classes.secondaryQuantity : '')}>{quantity}</span>
            <Button classes={{ button: classNames(classes.spinnerButton, classesProp?.add, classesProp?.button, variant === 'secondary' ? classes.secondaryAdd : '') }} onClick={onAdd}>
                <AddIcon name={'addItem'} titleAccess={`add icon ${title ?? ''}`} className={getIconClassNames()} />
            </Button>
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minWidth: 70,
        width: 'fit-content',
        position: 'relative',
        gap: 12,
    },
    mobileContainer: {
        minWidth: 140,
    },
    mobileButton: {
        width: 50,
        height: 50,
    },
    mobileIcon: {
        fontSize: 24,
    },
    mobileQuantity: {
        fontSize: 24,
        margin: '0 16px',
    },
    spinnerButton: {
        borderRadius: 4,
        minHeight: 'unset',
        margin: 0,
        width: 32,
        height: 32,
    },
    secondaryAdd: {
        borderRight: `2px solid ${theme.palette.surface.brandContrast}`,
        borderTop: `2px solid ${theme.palette.surface.brandContrast}`,
        borderBottom: `2px solid ${theme.palette.surface.brandContrast}`,
        backgroundColor: 'white',
        padding: '8px 0',
        paddingRight: 10,
    },
    secondarySubtract: {
        borderLeft: `2px solid ${theme.palette.primary.main}`,
        borderTop: `2px solid ${theme.palette.primary.main}`,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: 'white',
        padding: '8px 0',
        paddingLeft: 10,
    },
    icon: {
        color: 'white',
        fontSize: 16,
    },
    secondaryIcon: {
        color: theme.palette.primary.main,
        opacity: 1,
    },
    quantity: {
        position: 'relative',
        fontFamily: theme.typography.regular,
        color: '#2E3748',
        fontSize: 14,
        margin: 0,
    },
    secondaryQuantity: {
        color: theme.palette.primary.main,
    },
}));

type Props = {
    quantity: number;
    variant?: 'primary' | 'secondary';
    title?: string | number;
    onSubtract: () => void;
    onAdd: () => void;
    classes?: any;
};
