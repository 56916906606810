/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { createExternalOwnFleetDriverApi } from 'src/api/letseatmanager/driver/createExternalOwnFleetDriverApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormPhoneNumberField } from 'src/components/form/FormPhoneNumberField';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormVehicleTypeSelect } from 'src/components/form/FormVehicleTypeSelect';
import { translate } from 'src/i18n/translate';
import type { RestaurantId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function CreateExternalOwnFleetDriverDialog({ open, onClose, onSuccess, restaurantId }: Props): React.ReactElement {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        const response = await createExternalOwnFleetDriverApi({
            restaurantId: restaurantId,
            firstName: form.firstName,
            lastName: form.lastName,
            mobileNumber: form.mobileNumber,
            email: form.email,
            vehicleType: form.vehicleType,
        });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} loading={loading} title={translate('Create Own Fleet Driver')}>
            <Form form={form} onSubmit={onSubmit} className={classes.form}>
                <FormTextField name='firstName' label={translate('First Name')} required />
                <FormTextField name='lastName' label={translate('Last Name')} />
                <FormPhoneNumberField name='mobileNumber' label={translate('Mobile Number')} />
                <FormEmailField name='email' label={translate('Email')} required />
                <FormVehicleTypeSelect name='vehicleType' label={translate('Vehicle Type')} required />
                <FormFixErrorHint />
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button color='primary' type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Creating') : translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess?: any;
    restaurantId: RestaurantId;
};
