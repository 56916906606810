/**
 * @prettier
 */
export const ClientFrequencies = {
    NEW: 'NEW',
    RECURRENT: 'RECURRENT',
    LOST: 'LOST',
    RECOVERED: 'RECOVERED',
    HEAVY_USER: 'HEAVY_USER',
    GHOST_USER: 'GHOST_USER',
    UNTRACKED: 'UNTRACKED',
} as const;

export type ClientFrequency = typeof ClientFrequencies[keyof typeof ClientFrequencies];
