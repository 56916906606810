/**
 * @prettier
 */
import { SurveyAnswer, SurveyAnswers } from 'src/constants/SurveyAnswer';
import { translate } from 'src/i18n/translate';

/**
 * @deprecated
 * TODO: This is a general translation, move translations of constant values to en.js and es.js instead and calls with translate(...)
 **/
export function translateSurveyAnswer(surveyAnswer: SurveyAnswer): string {
    if (!surveyAnswer) {
        return '';
    }
    switch (surveyAnswer) {
        case SurveyAnswers.FOOD_WAS_COLD: {
            return translate('The food was cold');
        }
        case SurveyAnswers.MISSING_OR_INCORRECT_ITEMS: {
            return translate('Missing or incorrect items');
        }
        case SurveyAnswers.FOOD_WAS_NOT_PREPARED: {
            return translate('The food was not prepared');
        }
        case SurveyAnswers.FOOD_ARRIVED_LATE: {
            return translate('The food arrived late');
        }
        case SurveyAnswers.CONFUSED_PICK_UP_PLACE: {
            return translate('Confused pick-up place');
        }
        case SurveyAnswers.BAD_SERVICE: {
            return translate('Bad customer service');
        }
        case SurveyAnswers.PUNCTUALITY: {
            return translate('Punctuality');
        }
        case SurveyAnswers.APPLICATION: {
            return translate('The application');
        }
        case SurveyAnswers.FOOD: {
            return translate('The food');
        }
        case SurveyAnswers.PRESENTATION: {
            return translate('Presentation');
        }
        case SurveyAnswers.SERVICE: {
            return translate('Good customer service');
        }
        default: {
            return SurveyAnswers.SERVICE;
        }
    }
}
