/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { ReleaseNotesId } from 'src/types/Id';

export async function removeReleaseNotesApi(request: RemoveReleaseNotesApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('releaseNotes/removeReleaseNotesApi', request);
}

type RemoveReleaseNotesApiRequest = {
    releaseNotesId: ReleaseNotesId;
};
