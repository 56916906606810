/**
 * @prettier
 */
export const InventoryTransactionReasons = {
    WASTE: 'WASTE',
    ACCIDENT: 'ACCIDENT',
    TRANSFERENCE: 'TRANSFERENCE',
    ORDER: 'ORDER',
    // PURCHASE: 'PURCHASE',
    //TODO Este tipo se va a usar mas adelante
    ADJUSTMENT: 'ADJUSTMENT',
    CANCEL: 'CANCEL',
    COUNT_UPDATE: 'COUNT_UPDATE',
} as const;

export type InventoryTransactionReason = typeof InventoryTransactionReasons[keyof typeof InventoryTransactionReasons];
