/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomDiscountComment } from 'src/constants/CustomDiscountComment';
import type { DiscountType } from 'src/constants/DiscountType';
import type { OrderType } from 'src/constants/OrderType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { PickupTimeType } from 'src/constants/PickupTimeType';
import type { TipType } from 'src/constants/TipType';
import type { CourseOrderDetailsVm } from 'src/types/CourseOrderDetailsVm';
import type { CustomerOrderDetailsVm } from 'src/types/CustomerOrderDetailsVm';
import { PromotionId, type CustomerId, type OrderId, type PromoCodeId, type RestaurantId } from 'src/types/Id';
import type { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import type { OrderItemVm, OrderVm } from 'src/types/OrderVm';
import type { PosPaymentVm } from 'src/types/PosPaymentVm';
import type { PosTipVm } from 'src/types/PosTipVm';

export async function completePendingOrderApi(request: CompletePendingOrderApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('pos/completePendingOrderApi', request);
}

export type CompletePendingOrderApiRequest = {
    orderId: OrderId;
    restaurantId: RestaurantId;
    customerId?: CustomerId;
    promoCodeId?: PromoCodeId;
    orderType: OrderType;
    promotionIds?: Array<PromotionId>;
    orderStatus: 'COMPLETE';
    firstName?: string;
    mobileNumber?: string;
    email?: string;
    customerNote?: string;
    paymentMethod: PaymentMethod;
    posPayments: Array<PosPaymentVm>;
    payments: Array<OrderPaymentVm>;
    partiallyPaidByCash?: boolean;
    partiallyPaidByCashAmount?: string;
    pickupTimeType?: PickupTimeType;
    orderItems: Array<OrderItemVm>;
    cancelledItems: Array<OrderItemVm>;
    posDiscountPercentage?: string;
    posDiscount?: {
        discountType: DiscountType;
        discount: string;
        discountPercentage?: string;
        notes?: string | CustomDiscountComment;
    };
    posTip?: {
        tip: string;
        tipType: TipType;
    };
    tips?: Array<PosTipVm>;
    customers?: Array<CustomerOrderDetailsVm>;
    courses?: Array<CourseOrderDetailsVm>;
    uruguayanRucInvoice?: boolean;
    useLetsEatCredits?: boolean;
    usedCustomerCredits?: string;
    pickupTime?: Date;
    notes?: string;
};
