/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Channel } from 'src/components/Channel';
import { App } from 'src/constants/App';
import { useGetRestaurantChannels } from 'src/services/printer/useGetRestaurantChannels';

export function Channels({ onChange, disabled, defaultSelectedChannels, disabledChannels, excludedChannels, size }: Props): React.ReactElement | null {
    const classes = useStyles();

    const [selectedChannels, setSelectedChannels] = useState<Array<App>>([]);

    const channels = useGetRestaurantChannels();

    useEffect(() => {
        if (defaultSelectedChannels) setSelectedChannels(defaultSelectedChannels);
    }, [defaultSelectedChannels]);

    const selectChannel = (channel: App) => {
        if (disabledChannels?.includes(channel)) return;

        const channelIsSelected = selectedChannels.includes(channel);
        if (channelIsSelected) {
            const newSelectedChannels = selectedChannels.filter((selectedChannel) => selectedChannel !== channel);

            onChange?.(newSelectedChannels);
            setSelectedChannels(newSelectedChannels);
            return;
        }

        const newSelectedChannels = [...selectedChannels, channel];

        onChange?.(newSelectedChannels);
        setSelectedChannels(newSelectedChannels);
    };

    return (
        <div className={classes.container}>
            {channels?.map((channel: App) => {
                const channelDisabled = disabledChannels?.includes(channel) || disabled;
                const channelExcluded = excludedChannels?.includes(channel);
                const isSelected = selectedChannels.includes(channel);

                if (channelExcluded) return null;
                return <Channel key={channel} channel={channel} isSelected={isSelected} disabled={channelDisabled} onClick={selectChannel} size={size} />;
            })}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        flexWrap: 'wrap',
        width: '100%',
    },
}));

type Props = {
    onChange?: any;
    disabled?: boolean;
    defaultSelectedChannels?: Array<App>;
    disabledChannels?: Array<App>;
    excludedChannels?: Array<App>;
    size?: number;
};
