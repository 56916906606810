/**
 * @prettier
 */
import * as React from 'react';

export function SimpleTerminalIcon({ color, title }: Props): React.ReactElement {
    return (
        <svg width='24' height='26' viewBox='0 0 24 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M17.2499 13C17.2499 13.4142 16.9142 13.75 16.4999 13.75L7.49995 13.75C7.08573 13.75 6.74995 13.4142 6.74995 13C6.74995 12.5858 7.08573 12.25 7.49995 12.25L16.4999 12.25C16.9142 12.25 17.2499 12.5858 17.2499 13Z'
                fill={color ?? 'currentColor'}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.75005 16.9999C6.75005 16.5857 7.08584 16.2499 7.50005 16.2499H8.62505C9.03927 16.2499 9.37505 16.5857 9.37505 16.9999C9.37505 17.4141 9.03927 17.7499 8.62505 17.7499H7.50005C7.08584 17.7499 6.75005 17.4141 6.75005 16.9999ZM10.1251 16.9999C10.1251 16.5857 10.4608 16.2499 10.8751 16.2499H13.1251C13.5393 16.2499 13.8751 16.5857 13.8751 16.9999C13.8751 17.4141 13.5393 17.7499 13.1251 17.7499H10.8751C10.4608 17.7499 10.1251 17.4141 10.1251 16.9999ZM14.6251 16.9999C14.6251 16.5857 14.9608 16.2499 15.3751 16.2499H16.5001C16.9143 16.2499 17.2501 16.5857 17.2501 16.9999C17.2501 17.4141 16.9143 17.7499 16.5001 17.7499H15.3751C14.9608 17.7499 14.6251 17.4141 14.6251 16.9999ZM6.75005 21.0001C6.75005 20.5859 7.08584 20.2501 7.50005 20.2501H8.62505C9.03927 20.2501 9.37505 20.5859 9.37505 21.0001C9.37505 21.4143 9.03927 21.7501 8.62505 21.7501L7.50005 21.7501C7.08584 21.7501 6.75005 21.4143 6.75005 21.0001ZM10.1251 21.0001C10.1251 20.5859 10.4608 20.2501 10.8751 20.2501H13.1251C13.5393 20.2501 13.8751 20.5859 13.8751 21.0001C13.8751 21.4143 13.5393 21.7501 13.1251 21.7501H10.8751C10.4608 21.7501 10.1251 21.4143 10.1251 21.0001ZM14.6251 21.0001C14.6251 20.5859 14.9608 20.2501 15.3751 20.2501H16.5001C16.9143 20.2501 17.2501 20.5859 17.2501 21.0001C17.2501 21.4143 16.9143 21.7501 16.5001 21.7501H15.3751C14.9608 21.7501 14.6251 21.4143 14.6251 21.0001Z'
                fill={color ?? 'currentColor'}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.5 7.75001C5.36193 7.75001 5.25 7.86194 5.25 8.00001V24C5.25 24.1381 5.36193 24.25 5.5 24.25H18.5C18.6381 24.25 18.75 24.1381 18.75 24V8.00001C18.75 7.86194 18.6381 7.75001 18.5 7.75001H16.5C16.0858 7.75001 15.75 7.41422 15.75 7.00001C15.75 6.58579 16.0858 6.25001 16.5 6.25001H18.5C19.4665 6.25001 20.25 7.03351 20.25 8.00001V24C20.25 24.9665 19.4665 25.75 18.5 25.75H5.5C4.5335 25.75 3.75 24.9665 3.75 24V8.00001C3.75 7.03351 4.5335 6.25001 5.5 6.25001H7.5C7.91421 6.25001 8.25 6.58579 8.25 7.00001C8.25 7.41422 7.91421 7.75001 7.5 7.75001H5.5Z'
                fill={color ?? 'currentColor'}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.50005 1.75C8.8097 1.75 8.25005 2.30964 8.25005 3V9.25H15.7501V1.75H9.50005ZM17.2501 1.75V3.25H18.7501V3C18.7501 2.30964 18.1904 1.75 17.5001 1.75H17.2501ZM17.2501 4.75H20.2501V3C20.2501 1.48122 19.0188 0.25 17.5001 0.25H9.50005C7.98127 0.25 6.75005 1.48122 6.75005 3V10.75H17.2501V4.75Z'
                fill={color ?? 'currentColor'}
            />
        </svg>
    );
}

type Props = {
    color?: string;
    title?: string;
};
