/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { PosBusinessDayReportSummaryVm } from 'src/types/PosBusinessDayReportSummaryVm';

export async function findPosBusinessDayReportsApi(request: FindPosBusinessDayReportsApiRequest): ApiSauceResponse<Array<PosBusinessDayReportSummaryVm>> {
    return pidedirectoReportsMethod('/findEndOfDayReportApi', request);
}

type FindPosBusinessDayReportsApiRequest = {
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
};
