/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { QuestionIcon } from 'src/icons/QuestionIcon';
import { RequiredIcon } from 'src/icons/requiredIcon';
import { classNames } from 'src/utils/react/classNames';

/**
 * Add a tooltip to an element if a children is passed if not it renders a help icon
 * @param {string} [text] - text for the tooltip
 * @param {string} [type] - tooltip type, leave it blank for normal behavior, could be either error or success
 * @param {string} [position] - tooltip position, by default the tooltip is right positioned, possible values {bottom}
 * @param {string} [text] - text for the tooltip
 * @param {Object} [classes] - classes object to override the default style
 * @param {string} [id] - id for the tooltip element
 * @param {boolean} [forceOpen] - force the tooltip to be opened, if you pass a value to this the normal opened on hover behavior will be ignored
 * @param {React.Node} [children] - children to be rendered instead of the default help icon
 * @returns {React.Node}
 */
export function Tooltip({ text, id, forceOpen, children, type, position, classes: classesProp }: Props): React.ReactElement {
    const classes = useStyles();
    const tooltipContainerRef = useRef<HTMLDivElement>(null);

    const [showText, setShowText] = useState(false);

    const isTooltipError = !children && type === 'error';
    const visibleTooltip = showText || forceOpen;

    const handleMouseEnter = () => {
        if (forceOpen !== null && forceOpen !== undefined) return;
        setShowText(true);
    };

    const getTooltipStyle = () => {
        const containerRect = tooltipContainerRef?.current?.getBoundingClientRect();
        if (!containerRect) return {};
        let top = 0;
        let left = 0;
        top = containerRect.top + window.scrollY + (position === 'bottom' ? 20 : -25);
        left = containerRect.right + window.scrollX + (position === 'bottom' ? -100 : 10);

        return {
            top,
            left,
            zIndex: 9999,
        };
    };

    return (
        <div
            ref={tooltipContainerRef}
            id={id}
            className={classNames(classes.tooltip, isTooltipError && (classes as any).errorTooltip)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={() => setShowText(false)}
        >
            {children && children}
            {isTooltipError && <RequiredIcon color={'#E32F45'} />}
            {!children && <QuestionIcon color={'#A4AAAD'} />}
            {createPortal(
                <p aria-hidden={!visibleTooltip} style={getTooltipStyle()} role={'tooltip'} className={classNames(classes.text, classesProp?.text, visibleTooltip ? classes.visible : '')}>
                    {text}
                </p>,
                document.body
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    tooltip: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    text: {
        position: 'absolute',
        top: -10,
        left: 10,
        backgroundColor: theme.palette.surface.heavyContrast,
        color: theme.palette.text.invert,
        fontFamily: theme.typography.regular,
        fontSize: 12,
        padding: '6px 12px',
        display: 'none',
        borderRadius: 4,
        zIndex: 99,
        width: 'max-content',
        maxWidth: '200px',
        textAlign: 'center',
    },
    visible: {
        display: 'flex',
    },
}));

type Props = {
    id?: string;
    text?: string;
    type?: 'error' | 'success';
    forceOpen?: boolean;
    classes?: {
        text?: string;
    };
    position?: 'top' | 'bottom' | 'left' | 'right';
    children?: React.ReactNode;
};
