/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { getInternalReportsApi } from 'src/api/letseatadmin/internalReport/getInternalReportsApi';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormDatePicker } from 'src/components/form/FormDatePicker';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormInternalReportSelect } from 'src/components/form/FormInternalReportSelect';
import { Page } from 'src/components/Page';
import { PasswordRequiredContent } from 'src/components/PasswordRequiredContent';
import { Text } from 'src/components/Text';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { useNotification } from 'src/services/notification/useNotification';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function RequestInternalReportPage(): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const notification = useNotification();

    const [loading, setLoading] = useState(false);

    const requestInternalReport = async (form: any) => {
        setLoading(true);
        const response = await getInternalReportsApi({
            internalReport: form.internalReport,
            email: form.email,
            fromDate: new Date(form.from.year(), form.from.month(), form.from.date(), 0, 0, 0).toISOString(),
            toDate: new Date(form.to.year(), form.to.month(), form.to.date(), 23, 59, 59).toISOString(),
        });
        setLoading(false);
        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        notification({ message: translate('The request has been done successfully!') });
    };

    return (
        <Page title={translate('Request internal report')} className={classes.container}>
            <PasswordRequiredContent customPassword={'3487'}>
                <Form form={form} className={classes.form} onSubmit={requestInternalReport}>
                    <UpdatingContentProgress bottom left loading={loading} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormInternalReportSelect name='internalReport' label={translate('Report')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormEmailField name='email' label={translate('Email')} required />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormDatePicker name='from' label={translate('From')} required />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormDatePicker name='to' label={translate('To')} required />
                        </Grid>
                    </Grid>
                    <Button type='submit' classes={{ button: classes.button }}>
                        {translate('Request')}
                    </Button>
                    <Text size={'small'} style={{ textAlign: 'center' }}>
                        {translate('The report could take a couple of minutes depending of the time interval selected')}
                    </Text>
                </Form>
            </PasswordRequiredContent>
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    form: {
        backgroundColor: 'white',
        width: '40vw',
        borderRadius: 12,
        padding: 20,
        border: `1px solid #D9D9D9`,
        gap: 20,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
    },
    button: {
        width: 200,
    },
}));
