/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderId } from 'src/types/Id';

export async function switchOrderDeliveryProviderToPideDirectoApi(request: switchOrderDeliveryProviderToUberDirectApiRequest): ApiSauceResponse<string> {
    return letseatadminApiMethod('delivery/switchOrderDeliveryProviderToPideDirectoApi', request);
}

export type switchOrderDeliveryProviderToUberDirectApiRequest = {
    orderId: OrderId;
};
