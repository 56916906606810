/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { createContext, useEffect, useRef } from 'react';
import { SECONDS } from 'src/constants/TimeUnit';
import { classNames } from 'src/utils/react/classNames';
import { wait } from 'src/utils/wait';

let previousEvent: any = undefined;

export function DraggableList({ children, onDragEnd, classes: classesProp }: Props): React.ReactElement {
    const classes = useStyles();

    const organizeListAfterItemEliminationTimeOut = useRef<NodeJS.Timer>();
    const container = useRef<HTMLUListElement>(null);
    const currentListItem = useRef<any>();
    const finishingDragging = useRef(false);
    const dragging = useRef(false);
    const mouseMovement = useRef<MouseMovement>();
    const list = useRef<Array<any>>([]);

    const ANIMATION_DURATION_IN_SECONDS = 0.2;

    useEffect(() => {
        return () => {
            clear();
        };
    }, []);

    const handleStartDragElement = (element: any, value: any, e: any) => {
        previousEvent = e;
        dragging.current = true;
        list.current = list.current.filter((listItem) => !!listItem.listItem);
        const sortedList = getListItemsSorted();
        const currentListItemIndex = sortedList.findIndex((listItem) => listItem.value === value);

        currentListItem.current = { listItem: element, value, startingPositionIndex: currentListItemIndex, startingTopPosition: getElementTopPosition(element) };
    };

    const handleDragElement = (e: any) => {
        setMouseMovementValue(e);

        if (!currentListItem.current || finishingDragging.current) {
            return;
        }
        const { listItem } = currentListItem.current;

        const newYPosition = getMovementY(e) + getElementTopPosition(listItem);
        listItem.style.top = `${newYPosition}px`;
        previousEvent = e;
    };

    const getMovementY = (e: any) => {
        if (e.movementY) return e.movementY;
        if (!previousEvent) return 0;
        if (!previousEvent.touches) return 0;
        return e.touches[0].pageY - previousEvent.touches[0].pageY;
    };

    const handleEndDragElement = async () => {
        if (!currentListItem.current || finishingDragging.current) return;
        finishingDragging.current = true;

        const sortedList = getListItemsSorted();

        await organizeList(sortedList);

        previousEvent = undefined;
        dragging.current = false;
        const sortedValues = sortedList.map((listItem) => listItem.value);
        await onDragEnd?.(sortedValues);
    };

    const setMouseMovementValue = (e: any) => {
        if (getMovementY(e) === 0) return;

        mouseMovement.current = getMovementY(e) < 0 ? MouseMovements.UP : MouseMovements.DOWN;
    };

    const getElementTopPosition = (element: any) => {
        if (!element) return 0;

        const top = element.style.top;
        if (!top) return 0;

        return Number(top.replace(/px/, ''));
    };

    const getElementHeight = (element: any) => {
        if (!element) return 0;

        const offsetHeight = element.offsetHeight;
        const elementStyle = getComputedStyle(element);

        const marginTop = formatPixelToNumber(elementStyle.marginTop);
        const marginBottom = formatPixelToNumber(elementStyle.marginBottom);

        return offsetHeight + marginTop + marginBottom;
    };

    const formatPixelToNumber = (cssProperty: string) => {
        return Number(cssProperty.replace(/[^0-9]/g, '') || 0);
    };

    const isMouseMovingUp = () => mouseMovement.current === MouseMovements.UP;

    const isMouseMovingDown = () => mouseMovement.current === MouseMovements.DOWN;

    const getListItemsSorted = () => {
        const arraySorted = [...list.current].sort((listItemA, listItemB) => {
            return listItemA.listItem.offsetTop - listItemB.listItem.offsetTop;
        });
        return arraySorted;
    };

    const organizeList = async (
        sortedList: Array<{
            listItem: any;
            value: any;
        }>
    ) => {
        if (!currentListItem.current) return;
        const currentListItemValue = currentListItem.current?.value;

        const currentListItemIndex = sortedList.findIndex((listItem) => listItem.value === currentListItemValue);
        if (currentListItemIndex < 0) return;

        await Promise.allSettled([
            moveItemToItsNewPosition(currentListItem.current?.listItem, getCurrentListItemNewTopPosition(sortedList, currentListItemIndex)),
            moveAboveItemsToItsNewPositions(sortedList, currentListItemIndex),
            moveBelowItemsToItsNewPositions(sortedList, currentListItemIndex),
        ]);

        clear();
    };

    const getCurrentListItemNewTopPosition = (
        sortedList: Array<{
            listItem: any;
            value: any;
        }>,
        finishingPositionIndex: number
    ) => {
        if (!currentListItem.current) return 0;

        const startingPositionIndex = currentListItem.current?.startingPositionIndex;
        const startingTopPosition = currentListItem.current?.startingTopPosition ?? 0;

        if (startingPositionIndex > finishingPositionIndex) {
            let newTopPosition = 0;
            for (let i = startingPositionIndex; i > finishingPositionIndex; i--) {
                const listItem = sortedList[i].listItem;
                const elementHeight = getElementHeight(listItem);
                newTopPosition -= elementHeight;
            }

            return newTopPosition + startingTopPosition;
        }

        let newTopPosition = 0;
        for (let i = startingPositionIndex; i < finishingPositionIndex; i++) {
            const listItem = sortedList[i].listItem;
            const elementHeight = getElementHeight(listItem);
            newTopPosition += elementHeight;
        }

        return newTopPosition + startingTopPosition;
    };

    const moveItemToItsNewPosition = async (element: any, newPosition: any) => {
        if (!element) return;

        element.style.transition = `top ${ANIMATION_DURATION_IN_SECONDS}s linear`;
        element.style.top = `${newPosition}px`;

        await wait(ANIMATION_DURATION_IN_SECONDS * SECONDS);

        element.style.transition = '';
    };

    const moveAboveItemsToItsNewPositions = async (
        sortedList: Array<{
            listItem: any;
            value: any;
        }>,
        currentListItemIndex: number
    ) => {
        if (isMouseMovingUp() || !currentListItem.current) return;

        const aboveListItems = sortedList.slice(currentListItem.current.startingPositionIndex, currentListItemIndex);
        await Promise.allSettled(
            aboveListItems.map((aboveListItem) => {
                const currentPosition = getElementTopPosition(aboveListItem.listItem);
                const currentElementHeight = getElementHeight(currentListItem.current?.listItem);
                const newPosition = currentPosition - currentElementHeight;
                return moveItemToItsNewPosition(aboveListItem.listItem, newPosition);
            })
        );
    };

    const moveBelowItemsToItsNewPositions = async (
        sortedList: Array<{
            listItem: any;
            value: any;
        }>,
        currentListItemIndex: number
    ) => {
        if (isMouseMovingDown() || !currentListItem.current) return;
        const belowListItems = sortedList.slice(currentListItemIndex + 1, currentListItem.current.startingPositionIndex + 1);
        await Promise.allSettled(
            belowListItems.map((belowListItem) => {
                const currentPosition = getElementTopPosition(belowListItem.listItem);
                const currentElementHeight = getElementHeight(currentListItem.current?.listItem);
                return moveItemToItsNewPosition(belowListItem.listItem, currentPosition + currentElementHeight);
            })
        );
    };

    const clear = () => {
        currentListItem.current = undefined;
        mouseMovement.current = undefined;
        finishingDragging.current = false;
        list.current.forEach(({ listItem }) => (listItem.style.transition = ''));
    };

    const handleListItemRef = (ref: any, value: any) => {
        if (!ref || !value || dragging.current) return;

        const listItemIndex = list.current.findIndex((listItem) => listItem.value === value);
        const listItemHasBeenAdded = listItemIndex >= 0;
        if (listItemHasBeenAdded) {
            list.current[listItemIndex].listItem.style.top = '0px';
            return;
        }

        list.current.push({ listItem: ref, value });
    };

    const handleRemoveListItem = (value: any) => {
        list.current = list.current.filter((listItem) => listItem.value !== value);

        if (organizeListAfterItemEliminationTimeOut.current) clearTimeout(organizeListAfterItemEliminationTimeOut.current);

        organizeListAfterItemEliminationTimeOut.current = setTimeout(() => {
            organizeItemsList();
            organizeListAfterItemEliminationTimeOut.current = undefined;
        }, 50);
    };

    const organizeItemsList = () => {
        let position = 0;
        for (const listItem of list.current ?? []) {
            listItem.listItem.style.top = `0px`;
            position += getElementHeight(listItem.listItem);
        }
    };

    return (
        <ul className={classNames(classes.container, classesProp?.container)} onMouseMove={handleDragElement} onTouchMove={handleDragElement} ref={(ref) => ((container as any).current = ref)}>
            <DraggableListContext.Provider value={{ onDragStart: handleStartDragElement, onDragEnd: handleEndDragElement, initializeItem: handleListItemRef, onUnmountItem: handleRemoveListItem }}>
                {children}
            </DraggableListContext.Provider>
        </ul>
    );
}

export const DraggableListContext = createContext<Context>({
    onDragStart: () => {},
    onDragEnd: () => {},
    initializeItem: () => {},
    onUnmountItem: () => {},
});

const MouseMovements = {
    UP: 'UP',
    DOWN: 'DOWN',
} as const;

export type MouseMovement = typeof MouseMovements[keyof typeof MouseMovements];

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        padding: 0,
        margin: 0,
        width: '100%',
        height: '100%',
        zIndex: 10,
    },
    item: {
        position: 'relative',
        transition: 'top 0.5s linear',
    },
}));

type Props = {
    children: React.ReactNode;
    onDragEnd?: any;
    classes?: {
        container: string;
    };
};

type Context = {
    onDragStart: any;
    onDragEnd: any;
    initializeItem: any;
    onUnmountItem: any;
};
