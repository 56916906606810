/**
 * @prettier
 */
import { createCSVDownload } from 'src/utils/mui-datatables/utils';

export function createHandleClickDownloadCsvFunctional(
    ref: {
        current: any;
    },
    displayData: any
): any {
    return () => {
        if (!ref?.current) {
            return;
        }
        return createCSVDownload(ref.current.state.columns, displayData, ref.current.options);
    };
}
