/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import type { SalesByDay } from 'src/api/letseatmanager/restaurantDashboard/getFinanceRestaurantStatisticsApi';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useSelector } from 'src/utils/react/useSelector';

export function SalesByDayTable({ loading, salesByDayData }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const restaurantTaxManagementEnabled = useSelector((state) => state.app.restaurant?.restaurantTaxManagementEnabled);

    const dayWithMostSales = salesByDayData.dayWithMostSales;
    const salesByDays = salesByDayData.salesByDay ?? [];
    const hasSalesByDaysData = salesByDays?.length > 0;

    const salesByDayColumns = [
        {
            name: 'createdAt',
            label: translate('Day'),
            options: {
                filter: false,
            },
        },
        {
            name: 'orderVolume',
            label: translate('Orders'),
            options: {
                filter: false,
            },
        },
        {
            name: 'total',
            label: translate('Sales'),
            options: {
                filter: false,
            },
        },
        {
            name: 'taxes',
            label: translate('Taxes'),
            options: {
                filter: false,
                display: restaurantTaxManagementEnabled,
            },
        },
    ];
    if (!hasSalesByDaysData) return null;

    return (
        <ReportSection title={translate('Sales by Day')} loading={loading}>
            <div className={classes.splitContainer}>
                <div className={classes.sectionHeadKpi}>
                    <KpiCard
                        classes={{
                            container: classes.headingKpiContainer,
                            title: classes.headingKpiTitleKpi,
                            body: classes.headingKpiBody,
                            color: classes.headingKpiColor,
                            legend: classes.headingKpiLegendContainer,
                        }}
                        legend={dayWithMostSales?.[0]}
                        title={translate('Day with most sales')}
                    />
                    <KpiCard
                        classes={{
                            container: classes.headingKpiContainer,
                            title: classes.headingKpiTitleKpi,
                            body: classes.headingKpiBody,
                            color: classes.headingKpiColor,
                            legend: classes.headingKpiLegendContainer,
                        }}
                        legend={formatAsCurrencyNumber(dayWithMostSales?.[1] || '0')}
                        title={translate(`Sales of @date`, { date: dayWithMostSales?.[0] })}
                    />
                </div>
                <div className={classes.container}>
                    <Table
                        data={salesByDays?.map((dayFinance: SalesByDay) => ({
                            ...dayFinance,
                            total: formatAsCurrencyNumber(dayFinance.total || '0'),
                            taxes: formatAsCurrencyNumber(dayFinance.taxes ?? '0'),
                        }))}
                        columns={salesByDayColumns}
                        options={{
                            tableBodyMaxHeight: '350px',
                            search: false,
                            responsive: 'standard',
                            selectableRows: 'none',
                            filter: false,
                            rowsPerPage: 100,
                            viewColumns: false,
                            print: false,
                        }}
                    />
                </div>
            </div>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    splitContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',

        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'row-reverse',
            flexWrap: 'nowrap',
        },
    },
    sectionHeadKpi: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'column',
            width: '20%',
        },
    },
    headingKpiContainer: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '8px',
        margin: 5,
        textAlign: 'center',
        marginRight: '5%',
        marginBottom: '2%',
    },
    headingKpiTitleKpi: {
        fontFamily: theme.typography.regular,
        fontSize: 16,
        color: theme.palette.text.contrast,
    },
    headingKpiBody: {
        fontSize: 28,
        fontFamily: theme.typography.semiBold,
    },
    headingKpiColor: {
        width: 12,
        height: 12,
        borderRadius: 2,
    },
    headingKpiLegendContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.between('sm', 'lg')]: {
            justifyContent: 'left',
            textAlign: 'left',
            marginLeft: 40,
        },
    },
}));

type Props = {
    loading: boolean;
    salesByDayData: SalesByDayDataType;
};

type SalesByDayDataType = {
    dayWithMostSales: Array<number | string>;
    salesByDay: Array<SalesByDay>;
};
