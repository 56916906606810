/**
 * @prettier
 */
import { Dialog, DialogContent, Grid, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function ConektaOxxoPaymentDialog({ open, subscriptionPrice, conektaOxxoReference, onClose }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const handleClose = () => onClose();

    return (
        <Dialog open={open} classes={{ paper: classes.paper }} onClose={handleClose}>
            <DialogContent className={classes.dialogContent}>
                <Grid container>
                    <Grid item xs={12} style={{ maxWidth: 700 }}>
                        <div className={classes.opps}>
                            <div>
                                <div className={classes.oppsReminder}>Ficha digital. No es necesario imprimir.</div>
                                <div className={classes.oppsInfo}>
                                    <div className={classes.oppsBrand}>
                                        <img className={classes.oppsBrandImg} src='https://etn.com.mx/img/oxxo-pay.webp' alt='OXXOPay' />
                                    </div>
                                    <div className={classes.oppsAmmount}>
                                        <h3>Monto a pagar</h3>
                                        <h2 className={classes.oppsAmmountH2}>{formatAsCurrencyNumber(subscriptionPrice)}</h2>
                                        <p className={classes.oppsAmmountP}>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>
                                    </div>
                                </div>
                                <div className={classes.oppsReference}>
                                    <h3>Referencia</h3>
                                    <h1>{conektaOxxoReference}</h1>
                                </div>
                            </div>
                            <div className={classes.oppsInstructions}>
                                <h3>Instrucciones</h3>
                                <ol>
                                    <li>
                                        Acude a la tienda OXXO más cercana.{' '}
                                        <a href='https://www.google.com.mx/maps/search/oxxo/' target='_blank'>
                                            Encuéntrala aquí
                                        </a>
                                        .
                                    </li>
                                    <li>
                                        Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.
                                    </li>
                                    <li>Dicta al cajero el número de referencia en esta ficha para que tecleé directamete en la pantalla de venta.</li>
                                    <li>Realiza el pago correspondiente con dinero en efectivo.</li>
                                    <li>
                                        Al confirmar tu pago, el cajero te entregará un comprobante impreso. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este
                                        comprobante de pago.
                                    </li>
                                </ol>
                                <div className={classes.oppsFootnote}>
                                    Al completar estos pasos recibirás un correo de <strong>PideDirecto</strong> confirmando tu pago.
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.buttonsContainer}>
                <Button onClick={handleClose}>
                    <div className={classes.cancelButton}>{translate('Close')}</div>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '40%',
        height: '90%',
        maxWidth: '100%',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            height: '80%',
            marginBottom: 20,
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
    },
    dialogTitle: {
        textAlign: 'center',
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: 0,
        marginBottom: 10,
        '& h2': {
            fontFamily: theme.typography.medium,
            fontSize: 25,
        },
    },
    dialogContent: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            padding: '0 20px',
        },
        maxWidth: '100%',
    },
    subTitle: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        textTransform: 'none',
        textAlign: 'center',
        width: '70%',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            width: '80%',
            marginBottom: 20,
        },
    },
    buttonsContainer: {
        marginTop: 5,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    noMargin: {
        margin: '0 !important',
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    opps: {
        width: '100%',
        height: '87%',
        borderRadius: '4px',
        boxSizing: 'border-box',
        padding: '0 45px',
        margin: '40px auto',
        overflow: 'hidden',
        border: '1px solid #b0afb5',
        fontFamily: 'Open Sans, sans-serif',
        color: '#4f5365',
    },

    oppsReminder: {
        position: 'relative',
        top: '-1px',
        padding: '9px 0 10px',
        fontSize: '11px',
        textTransform: 'uppercase',
        textAlign: 'center',
        color: '#ffffff',
        background: '#000000',
    },

    oppsInfo: {
        marginTop: '26px',
        position: 'relative',
    },

    oppsBrand: {
        width: '45%',
        float: 'left',
    },

    oppsBrandImg: {
        maxWidth: '150px',
        marginTop: '2px',
    },

    oppsAmmount: {
        width: '55%',
        float: 'right',
    },

    oppsAmmountH2: {
        fontSize: '36px',
        color: '#000000',
        lineHeight: '24px',
        marginBottom: '15px',
    },

    oppsAmmountP: {
        fontSize: '10px',
        lineHeight: '14px',
    },

    oppsReference: {
        marginTop: '14px',
    },

    oppsInstructions: {
        margin: '32px -45px 0',
        padding: '32px 45px 45px',
        borderTop: '1px solid #b0afb5',
        background: '#f8f9fa',
    },

    oppsFootnote: {
        marginTop: '22px',
        padding: '22px 20 24px',
        color: '#108f30',
        textAlign: 'center',
        border: '1px solid #108f30',
        borderRadius: '4px',
        background: '#ffffff',
    },
}));

type Props = {
    open: boolean;
    subscriptionPrice?: string;
    conektaOxxoReference: string;
    onClose: any;
};
