/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Text } from '@pidedirecto/ui';
import { WifiIcon, WifiOffIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function NetworkConnectivityIndicator(): React.ReactElement {
    const classes = useStyles();

    const online = useSelector((state) => state.app2.online);

    if (!online) {
        return (
            <div className={classes.container}>
                <div className={classes.iconContainer}>
                    <WifiOffIcon title={translate('No internet connection')} size={20} className={classes.icon} />
                </div>
                <Text className={classes.text}>{translate('Offline')}</Text>
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <div className={classes.iconContainer}>
                <WifiIcon title={translate('Internet connection')} size={20} className={classes.icon} />
            </div>
            <Text className={classes.text}>{translate('Online')}</Text>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        color: theme.palette.text.secondary,
        height: 42,
        alignItems: 'center',
    },
    icon: {
        [theme.breakpoints.up('md')]: {
            color: theme.palette.icons.brand,
        },
    },
    text: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    iconContainer: {
        [theme.breakpoints.down('sm')]: {
            minWidth: 50,
            display: 'flex',
            alignItems: 'center',
        },
    },
}));
