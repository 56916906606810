/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeFaqApi } from 'src/api/letseatadmin/faq/changeFaqApi';
import { getFaqApi } from 'src/api/letseatadmin/faq/getFaqApi';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormTextField } from 'src/components/form/FormTextField';
import ImageUploader from 'src/components/input/ImageUploader';
import { ImageTypes } from 'src/constants/ImageType';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { FaqId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { https } from 'src/utils/Validator';

export default function ChangeFaqDialog({ open, faqId, onClose, load }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
        setValue,
    } = form;
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [loadingBanner, setLoadingBanner] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);

    useEffect(() => {
        if (faqId) getFaq();
    }, [faqId]);

    const getFaq = async () => {
        setLoadingBanner(true);
        if (faqId) {
            const response = await getFaqApi({ faqId });
            setLoadingBanner(false);
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            form.reset({
                question: response.data.question || null,
                answer: response.data.answer || null,
                imageUrl: response.data.imageUrl || null,
                position: response.data.position || null,
                articleUrl: response.data.articleUrl || null,
            });
        }
    };

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        if (faqId) {
            const response = await changeFaqApi({
                faqId,
                answer: form.answer,
                imageUrl: form.imageUrl,
                question: form.question,
                position: form.position,
                articleUrl: form.articleUrl,
            });
            setLoading(false);
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
        }
        onClose();
        load();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} classes={{ paper: classes.paper }}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form} className={classes.form}>
                <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Change Faq')}</DialogTitle>
                {loadingBanner && (
                    <div style={{ marginBottom: 40, marginTop: -44 }}>
                        <Loader size={40} loading={true} />
                    </div>
                )}
                {!loadingBanner && (
                    <>
                        <DialogContent classes={{ root: classes.dialogContent }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormTextField name='question' label={translate('Question')} helperText={`${translate('Question')}`} placeholder={'Question'} disabled={loading} required />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField name='answer' label={translate('Answer')} helperText={`${translate('Answer')}`} placeholder={'Answer'} disabled={loading} required />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item>
                                    <FormTextField name='articleUrl' label={translate('Article Url')} disabled={loading} validate={https} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item>
                                    <FormTextField
                                        name='imageUrl'
                                        label={uploadingImage ? translate('Uploading Image...') : translate('Image')}
                                        helperText={uploadingImage ? undefined : translate('Enter an URL to an image or drag and drop your image anywhere in the browser')}
                                        disabled={loading}
                                        validate={https}
                                    />
                                    <ImageUploader
                                        imageType={ImageTypes.BANNER}
                                        onUploadingChanged={(uploadingImage) => setUploadingImage(uploadingImage)}
                                        onUploadSuccess={(imageUrl) => setValue('imageUrl', imageUrl)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <FormNumberField name='position' label={translate('On Which position should it appear')} disabled={loading} />
                            </Grid>
                            <FormFixErrorHint />
                        </DialogContent>
                        <DialogActions className={classes.buttonsContainer}>
                            <Button onClick={handleClose} disabled={isSubmitting || loading}>
                                <div className={classes.cancelButton}>{translate('Cancel')}</div>
                            </Button>
                            <Button color='primary' type='submit' disabled={isSubmitting || loading} classes={{ root: classes.noMargin }}>
                                <div className={classes.acceptButton}>{isSubmitting || loading ? translate('Changing') : translate('Change')}</div>
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    paper: {
        [theme.breakpoints.down('md')]: {
            width: '80%',
            maxWidth: '80%',
        },
    },
    dialogContent: {
        width: '80%',
        [theme.breakpoints.down('md')]: {
            width: '70%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    buttonsContainer: {
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: 20,
        },
    },
    noMargin: {
        margin: '0 !important',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
}));

type Props = {
    open: boolean;
    faqId: FaqId | undefined;
    onClose: any;
    load: any;
};
