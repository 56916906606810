/**
 * @prettier
 */
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiErrorLogEvent } from 'src/services/logEvent/createUiErrorLogEvent';
import type { PrinterVm } from 'src/types/PrinterVm';
import { toShortId } from 'src/utils/uuid/toShortId';

export function createUserPrintFailedLogEvent({ printer, stringPrint }: Params): void {
    createUiErrorLogEvent({
        logEventType: LogEventTypes.USER_PRINT_FAILED,
        message: `Printer ${printer.deviceName ?? ''} ${toShortId(printer.printerId)} brand ${printer.printerBrand ?? 'N/A'} type ${printer.printerType ?? 'N/A'}`,
        details: stringPrint,
        data: {
            printer,
            stringPrint,
        },
    });
}

type Params = {
    printer: PrinterVm;
    stringPrint?: string;
};
