/**
 * @prettier
 */
import * as React from 'react';
import { memo } from 'react';
import TimeLineBarChart, {
    Color,
    PeriodScale,
    pushData,
    XAxisScale,
    xAxisTooltipFormat,
    yAxisFormat,
    yAxisLegend,
    YAxisScale,
    yAxisTooltipFormat,
} from 'src/scenes/letseatadmin/statistics/TimeLineBarChart';
import type { StatisticsVm } from 'src/scenes/letseatadmin/StatisticsPage';

type Props = {
    statistics?: StatisticsVm;
    period: PeriodScale;
    yAxis: YAxisScale;
    xAxis: XAxisScale;
};

export const OrderTypeChart: React.ComponentType<Props> = memo<Props>(function OrderTypeChart({ statistics, period, yAxis, xAxis }: Props): React.ReactElement | null {
    if (!statistics) {
        return null;
    }

    return (
        <TimeLineBarChart
            data={statisticsToData(statistics, period, xAxis, yAxis)}
            period={period}
            yAxis={yAxis}
            xAxis={xAxis}
            colors={[Color.LETS_EAT, Color.BLUE_LIGHT, Color.BLUE]}
            yAxisLegend={yAxisLegend(yAxis)}
            yAxisFormat={yAxisFormat(yAxis)}
            yAxisTooltipFormat={yAxisTooltipFormat(yAxis)}
            xAxisTooltipFormat={xAxisTooltipFormat(xAxis)}
        />
    );
});

function statisticsToData(statistics: StatisticsVm, period: PeriodScale, xAxis: XAxisScale, yAxis: YAxisScale) {
    return statistics.reduce(
        (chartData, statistic) => {
            pushData(chartData[0].data, statistic.date, statistic.order.new.pickupStationOrder, period, yAxis, xAxis);
            pushData(chartData[1].data, statistic.date, statistic.order.new.takeAwayOrder, period, yAxis, xAxis);
            pushData(chartData[2].data, statistic.date, statistic.order.new.selfServiceOrder, period, yAxis, xAxis);
            // pushData(chartData[3].data, statistic.date, statistic.order.new.tableOrder, period, yAxis, xAxis);
            return chartData;
        },
        [
            { id: 'Delivery', data: [] },
            { id: 'Take Away', data: [] },
            { id: 'Eat Here', data: [] },
            // { id: 'Table Order', data: [] },
        ]
    );
}
