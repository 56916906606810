/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import type { RestaurantCurrency } from 'src/types/RestaurantCurrency';

export function convertAmountToMainCurrency(value: string | number, currency?: RestaurantCurrency): string {
    if (!currency) return value?.toString();

    return BigNumber(value).multipliedBy(currency.exchangeRate).toFixed(2);
}
