/**
 * @prettier
 */
import * as React from 'react';
import { updateOrderTicketRequestedApi } from 'src/api/letseatmanager/order/updateOrderTicketRequestedApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import type { OrderVm } from 'src/types/OrderVm';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { useHasPrintersToPrint } from 'src/utils/printer/useHasPrintersToPrint';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PrintOrderTicketButton({ order, disabled }: Props): React.ReactElement | null {
    const [printOrderTicket] = usePrintOrderTicket();

    const { hasPrintersToPrint } = useHasPrintersToPrint();

    const authorizationToReprintCompletedOrderEnabled = useSelector((state) => state.app.restaurant.authorizationToReprintCompletedOrderEnabled);
    const posEnabled = useSelector((state) => state.app.restaurant.posEnabled);
    const restaurants = useSelector((state) => state.app.restaurants);

    const openVerifyPosPasswordDialog = useAction(app2.actions.openVerifyPosPasswordDialog);

    const handlePrintTicket = async () => {
        const restaurantFromOrder = restaurants?.find((restaurant) => restaurant.restaurantId === order.restaurant?.restaurantId);

        if (isCompletedOrder(order.orderStatus) && authorizationToReprintCompletedOrderEnabled && posEnabled) {
            return openVerifyPosPasswordDialog({
                onAuthorized: async () => {
                    await printOrderTicket(order, { forceToPrint: true, openCashBox: !restaurantFromOrder?.avoidOpenCashBoxOnReprintTicketEnabled });
                },
                onUnauthorized: () => alert(translate('Unauthorized to access.')),
            });
        }

        if (!order?.ticketRequestedAt && isEatHereOrder(order.orderType)) {
            updateOrderTicketRequestedApi({ orderId: order?.orderId });
        }

        if (isCompletedOrder(order.orderStatus)) {
            await printOrderTicket(order, { forceToPrint: true, openCashBox: !restaurantFromOrder?.avoidOpenCashBoxOnReprintTicketEnabled });
            return;
        }

        await printOrderTicket(order, { forceToPrint: true });
    };

    if (!hasPrintersToPrint) return null;

    return (
        <Button secondary onClick={handlePrintTicket} disabled={disabled}>
            {isCompletedOrder(order.orderStatus) ? translate('Reprint Bill') : translate('Print Bill')}
        </Button>
    );
}

type Props = {
    order: OrderVm;
    disabled?: boolean;
};
