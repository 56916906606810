/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import { locale } from 'src/config/configureI18n';

export function formatAsDistance(distance?: number): string {
    if (distance === undefined) {
        return '';
    }

    const roundedDistance = Math.round(distance / 10) * 10;
    if (roundedDistance < 1000) {
        const numberFormat = new Intl.NumberFormat(locale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
        return numberFormat.format(roundedDistance) + ' m';
    }
    if (roundedDistance < 10000) {
        const numberFormat = new Intl.NumberFormat(locale, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        });
        return numberFormat.format(BigNumber(roundedDistance).dividedBy(1000).toNumber()) + ' km';
    }
    const numberFormat = new Intl.NumberFormat(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    return numberFormat.format(BigNumber(roundedDistance).dividedBy(1000).toNumber()) + ' km';
}
