/**
 * @prettier
 */
import * as React from 'react';

export function StoreIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M4.35253 1.875C3.93836 1.875 3.54086 2.04 3.24836 2.33333L2.16503 3.415C1.60522 3.97377 1.27812 4.72404 1.2497 5.51448C1.22128 6.30493 1.49366 7.07675 2.0119 7.67428C2.53014 8.27181 3.25568 8.65059 4.0422 8.73424C4.82873 8.81788 5.61771 8.60017 6.25003 8.125C6.77253 8.51667 7.42169 8.75 8.12503 8.75C8.82836 8.75 9.47836 8.51667 10 8.125C10.5217 8.51667 11.1717 8.75 11.875 8.75C12.5784 8.75 13.2275 8.51667 13.75 8.125C14.3823 8.60017 15.1713 8.81788 15.9579 8.73424C16.7444 8.65059 17.4699 8.27181 17.9882 7.67428C18.5064 7.07675 18.7788 6.30493 18.7504 5.51448C18.7219 4.72404 18.3948 3.97377 17.835 3.415L16.7517 2.3325C16.4588 2.0397 16.0617 1.87514 15.6475 1.875H4.35253Z'
                fill={color ?? '#63D6B3'}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2.5 16.875V9.57917C3.68333 10.1408 5.06667 10.14 6.25 9.57917C6.83594 9.8571 7.47649 10.0009 8.125 10C8.795 10 9.43167 9.84834 10 9.57834C10.5859 9.85655 11.2264 10.0006 11.875 10C12.545 10 13.1808 9.84834 13.75 9.57834C14.9333 10.14 16.3167 10.1408 17.5 9.57917V16.875H18.125C18.2908 16.875 18.4497 16.9409 18.5669 17.0581C18.6842 17.1753 18.75 17.3342 18.75 17.5C18.75 17.6658 18.6842 17.8247 18.5669 17.9419C18.4497 18.0592 18.2908 18.125 18.125 18.125H1.875C1.70924 18.125 1.55027 18.0592 1.43306 17.9419C1.31585 17.8247 1.25 17.6658 1.25 17.5C1.25 17.3342 1.31585 17.1753 1.43306 17.0581C1.55027 16.9409 1.70924 16.875 1.875 16.875H2.5ZM5 11.875C5 11.7092 5.06585 11.5503 5.18306 11.4331C5.30027 11.3159 5.45924 11.25 5.625 11.25H8.125C8.29076 11.25 8.44973 11.3159 8.56694 11.4331C8.68415 11.5503 8.75 11.7092 8.75 11.875V14.375C8.75 14.5408 8.68415 14.6997 8.56694 14.8169C8.44973 14.9342 8.29076 15 8.125 15H5.625C5.45924 15 5.30027 14.9342 5.18306 14.8169C5.06585 14.6997 5 14.5408 5 14.375V11.875ZM11.875 11.25C11.7092 11.25 11.5503 11.3159 11.4331 11.4331C11.3158 11.5503 11.25 11.7092 11.25 11.875V16.25C11.25 16.595 11.53 16.875 11.875 16.875H14.375C14.5408 16.875 14.6997 16.8092 14.8169 16.6919C14.9342 16.5747 15 16.4158 15 16.25V11.875C15 11.7092 14.9342 11.5503 14.8169 11.4331C14.6997 11.3159 14.5408 11.25 14.375 11.25H11.875Z'
                fill={color ?? '#63D6B3'}
            />
        </svg>
    );
}

type Props = {
    color?: string;
};
