/**
 * @prettier
 */
import { useState } from 'react';
import * as React from 'react';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { AssignWaiterDialog } from 'src/components/orderCard/AssignWaiterDialog';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';
import { useUserIsAnAvailableWaiter } from 'src/utils/react/useUserIsAnAvailableWaiter';

export function AssignWaiterToOrderButton({ order, disabled, onSuccess }: Props): React.ReactElement | null {
    const [userIsAnAvailableWaiter] = useUserIsAnAvailableWaiter();
    const [userHasRolePermission] = useUserHasRolePermission();

    const [assignWaiterDialogState, setAssignWaiterDialogState] = useState({ open: false });

    const userManagementEnabled = useSelector((state) => state.app.restaurant?.userManagementEnabled);

    const openVerifyPosPasswordDialog = useAction(app2.actions.openVerifyPosPasswordDialog);

    const shouldRenderButton = userManagementEnabled && isEatHereOrder(order.orderType) && (isAcceptedOrder(order.orderStatus) || isPendingOrder(order.orderStatus));

    const handleAssignWaiter = async () => {
        if (!userHasRolePermission(RolePermissions.ASSIGN_ORDERS)) {
            return openVerifyPosPasswordDialog({
                onAuthorized: () => {
                    openAssignWaiterDialog();
                },
                onUnauthorized: () => {
                    alert(translate('Unauthorized to access.'));
                },
            });
        }
        openAssignWaiterDialog();
    };

    const openAssignWaiterDialog = () => setAssignWaiterDialogState({ open: true });

    const closeAssignWaiterDialog = () => setAssignWaiterDialogState({ open: false });

    if (!shouldRenderButton) return null;

    return (
        <div>
            <AssignWaiterDialog open={assignWaiterDialogState.open} orderId={order.orderId} handleClose={closeAssignWaiterDialog} onSuccess={onSuccess} />
            <Button onClick={handleAssignWaiter} outlined disabled={disabled}>
                {userIsAnAvailableWaiter(order.servedBy) ? translate('Reassign waiter') : translate('Assign waiter')}
            </Button>
        </div>
    );
}

type Props = {
    order: OrderVm;
    disabled?: boolean;
    onSuccess?: any;
};
