/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { KitchenStatus } from 'src/constants/KitchenStatus';
import type { MenuItemId, OrderId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export async function updateOrderItemKitchenStatusApi(request: updateOrderItemKitchenStatusApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('kitchen/updateOrderItemKitchenStatusApi', request);
}

export type updateOrderItemKitchenStatusApiRequest = {
    orderId: OrderId;
    menuItemId: MenuItemId;
    addedAt?: Date;
    kitchenStatus: KitchenStatus;
};
