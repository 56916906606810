/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { posReducer } from 'src/reducers/posReducer';
import { AddPosCartModifiersButton } from 'src/scenes/letseatmanager/pos/posAddMenuItem/AddPosCartModifiersButton';
import { PosCartModifiersCard } from 'src/scenes/letseatmanager/pos/posAddMenuItem/PosCartModifiersCard';
import { PosCartModifiersQuantitySpinner } from 'src/scenes/letseatmanager/pos/posAddMenuItem/PosCartModifiersQuantitySpinner';
import { PosModifiers } from 'src/scenes/letseatmanager/pos/posAddMenuItem/PosModifiers';
import { useSetPosCartModifiers } from 'src/services/pos/posModifiersCart/useSetPosCartModifiers';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PosAddMenuItem(): React.ReactElement | null {
    const classes = useStyles();

    const setPosCartModifiers = useSetPosCartModifiers();

    const closeAddMenuItem = useAction(posReducer.actions.closeAddMenuItem);
    const itemSelected = useSelector((state) => state.pos.itemSelected);

    const onCloseAddMenuItem = () => {
        closeAddMenuItem();
        setPosCartModifiers({});
    };

    if (!itemSelected) return null;

    return (
        <div>
            <div className={classes.background} onClick={onCloseAddMenuItem}></div>
            <div className={classes.desktopContainer}>
                <div className={classes.leftSection}>
                    <PosCartModifiersQuantitySpinner />
                    <PosModifiers />
                </div>
                <div className={classes.actionsContainer}>
                    <AddPosCartModifiersButton />
                </div>
                <PosCartModifiersCard />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    desktopContainer: {
        position: 'absolute',
        width: '100%',
        height: 'calc(100vh - 73px)',
        top: 73,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 10,
        backgroundColor: theme.palette.surface.primary,
        [theme.breakpoints.down('sm')]: {
            zIndex: `${110} !important`,
            width: '100%',
            height: 'unset',
            top: 'unset',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            bottom: 0,
            left: 0,
            padding: 20,
            borderTopRightRadius: 30,
            borderTopLeftRadius: 30,
            transition: '0.3s',
        },
    },
    actionsContainer: {
        display: 'none',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            zIndex: `${110} !important`,
        },
    },
    leftSection: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    background: {
        position: 'fixed',
        width: '100%',
        height: '100vh',
        top: 0,
        left: 0,
        zIndex: 98,
        background: 'rgba(0, 0, 0, 0.50)',
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
}));
