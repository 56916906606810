/**
 * @prettier
 */
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function DeletePaymentToolbarButton({ tooltip, onClick, enabledForUserTypeView = false }: Props): React.ReactElement {
    const viewUser = useSelector((state) => state.authentication.viewUser);

    return (
        <Tooltip title={tooltip || translate('Create Payment')}>
            <IconButton onClick={onClick} disabled={viewUser && !enabledForUserTypeView}>
                <CloseIcon />
            </IconButton>
        </Tooltip>
    );
}

type Props = {
    tooltip?: string;
    onClick: any;
    enabledForUserTypeView?: boolean;
};
