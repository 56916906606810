/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import type { SalesByMenuItem } from 'src/api/letseatmanager/restaurantDashboard/getMenuRestaurantStatisticsApi';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useSelector } from 'src/utils/react/useSelector';

export function SalesByMenuItemTable({ loading, salesByMenuItems }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const currencyFormat = useSelector((state) => state.app.restaurant?.currencyFormat);
    const menuCategoryFamiliesEnabled = useSelector((state) => state.app.restaurant?.menuCategoryFamiliesEnabled);

    const columns = [
        {
            name: 'name',
            label: translate('Name'),
            options: {
                filter: true,
            },
        },
        {
            name: 'count',
            label: translate('Amount'),
            options: {
                filter: false,
            },
        },
        {
            name: 'countSales',
            label: translate('Sales'),
            options: {
                filter: false,
            },
        },
        {
            name: 'price',
            label: translate('Price'),
            options: {
                filter: false,
            },
        },
        {
            name: 'percentageOfSales',
            label: translate('% Sales'),
            options: {
                filter: false,
            },
        },
        {
            name: 'menuCategory',
            label: translate('Category'),
            options: {
                filter: false,
            },
        },
    ];

    if (menuCategoryFamiliesEnabled) {
        columns.push({
            name: 'menuCategoryFamilyName',
            label: translate('Family'),
            options: {
                filter: false,
            },
        });
    }

    if (!salesByMenuItems.length) return null;

    return (
        <ReportSection title={translate('Sales by Item')} loading={loading}>
            <div className={classes.splitContainer}>
                <div className={classes.sectionHeadKpi}>
                    <KpiCard
                        classes={{
                            container: classes.headingKpiContainer,
                            title: classes.headingKpiTitleKpi,
                            body: classes.headingKpiBody,
                            color: classes.headingKpiColor,
                            legend: classes.headingKpiLegendContainer,
                        }}
                        legend={salesByMenuItems[0]?.itemName}
                        title={translate('Top seller')}
                    />
                    <KpiCard
                        classes={{
                            container: classes.headingKpiContainer,
                            title: classes.headingKpiTitleKpi,
                            body: classes.headingKpiBody,
                            color: classes.headingKpiColor,
                            legend: classes.headingKpiLegendContainer,
                        }}
                        legend={`${salesByMenuItems[0]?.percentageOfSales}%`}
                        title={translate('% Sale')}
                    />
                </div>
                <div className={classes.tableContainer}>
                    <Table
                        data={
                            salesByMenuItems.map((salesByMenuItem, index) => ({
                                name: salesByMenuItem.itemName,
                                menuCategoryFamilyName: salesByMenuItem.menuCategoryFamilyName,
                                count: salesByMenuItem.orderVolume,
                                countSales: formatAsCurrencyNumber(salesByMenuItem.total || '0'),
                                price: formatAsCurrencyNumber(salesByMenuItem.itemPrice || '0'),
                                percentageOfSales: `${BigNumber(salesByMenuItem.percentageOfSales).toNumber()} %`,
                            })) ?? []
                        }
                        columns={columns}
                        options={{
                            responsive: 'standard',
                            tableBodyMaxHeight: '350px',
                            selectableRows: 'none',
                            filter: false,
                            rowsPerPage: 100,
                            viewColumns: false,
                            print: false,
                        }}
                    />
                </div>
            </div>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    splitContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',

        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'row-reverse',
            flexWrap: 'nowrap',
        },
    },
    tableContainer: {
        width: '100%',
    },
    sectionHeadKpi: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'column',
            width: '20%',
        },
    },
    headingKpiContainer: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '8px',
        margin: 5,
        textAlign: 'center',
        marginRight: '5%',
        marginBottom: '2%',
    },
    headingKpiTitleKpi: {
        fontFamily: theme.typography.regular,
        fontSize: 16,
        color: theme.palette.text.contrast,
    },
    headingKpiBody: {
        fontSize: 28,
        fontFamily: theme.typography.semiBold,
    },
    headingKpiColor: {
        width: 12,
        height: 12,
        borderRadius: 2,
    },
    headingKpiLegendContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
    },
}));

type Props = {
    loading: boolean;
    salesByMenuItems: Array<SalesByMenuItem>;
};
