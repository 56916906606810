/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { UnableToClosePosBusinessDayReason } from 'src/constants/UnableToClosePosBusinessDayReason';
import { PosBusinessDayId } from 'src/types/Id';

export async function isAbleToClosePosBusinessDayApi(request: IsAbleToClosePosBusinessDayApiRequest): ApiSauceResponse<IsAbleToClosePosBusinessDayApiResponse> {
    return letseatmanagerApiMethod('pos/isAbleToClosePosBusinessDayApi', request);
}

export type IsAbleToClosePosBusinessDayApiRequest = {
    posBusinessDayId: PosBusinessDayId;
};

type IsAbleToClosePosBusinessDayApiResponse = {
    isAbleToClosePosBusinessDay: boolean;
    unableToClosePosBusinessDayReason?: UnableToClosePosBusinessDayReason;
};
