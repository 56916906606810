/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CashRegisterTransactionsReportVm } from 'src/types/CashRegisterTransactionsReportVm';
import type { RestaurantId } from 'src/types/Id';

export async function getCashTransactionsReportApi(request: GetCashTransactionsReportApiRequest): ApiSauceResponse<CashRegisterTransactionsReportVm> {
    return pidedirectoReportsMethod('/getCashRegisterTransactionsReportApi', request);
}

type GetCashTransactionsReportApiRequest = {
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
};
