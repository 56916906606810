/**
 * @prettier
 */
import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
// @ts-ignore
import { PersistGate } from 'redux-persist5/integration/react';
import { App } from 'src/App';
import { ConfigureSoundOnFirstClick } from 'src/components/app/ConfigureSoundOnFirstClick';
import { history } from 'src/config/history';
import { persistor, store } from 'src/config/store';
import 'src/google-maps.css';
import 'src/index.css';
import 'src/letseatfont.css';
import 'typeface-roboto';
import { ConfirmDialog, PickersProvider } from '@pidedirecto/ui';
import moment from 'moment';
import { ConfirmDialogProvider } from 'src/providers/ConfirmDialogProvider';
import { NotificationsProvider } from 'src/providers/NotificationsProvider';
import { PasswordDialogProvider } from 'src/providers/PasswordDialogProvider';
import { theme } from 'src/theme/AppTheme';

export function AppRoot(): React.ReactElement {
    // TODO: Add a way to implement the mode switching between light mode and dark mode.
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <PickersProvider muiTheme={theme} momentInstance={moment}>
                    <MuiThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <NotificationsProvider>
                                <ConfirmDialog />
                                <ConfirmDialogProvider>
                                    <PasswordDialogProvider>
                                        <Router history={history}>
                                            <div id='app-container'>
                                                <ConfigureSoundOnFirstClick />
                                                <CssBaseline />
                                                <App />
                                            </div>
                                        </Router>
                                    </PasswordDialogProvider>
                                </ConfirmDialogProvider>
                            </NotificationsProvider>
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                </PickersProvider>
            </PersistGate>
        </Provider>
    );
}
