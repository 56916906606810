/**
 * @prettier
 */
import moment from 'moment-timezone';
import type { TimeZone } from 'src/constants/TimeZone';

export function isWithinHalfAnHour(date: string | Date, timeZone: TimeZone): boolean {
    const tz = moment.tz(date, timeZone);
    return tz.isBefore(moment.tz(timeZone)) && tz.isAfter(moment.tz(timeZone).subtract(30, 'minutes'));
}
