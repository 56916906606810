/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import * as React from 'react';
import { openDidiFoodStoreApi } from 'src/api/letseatmanager/restaurantIntegration/openDidiFoodStoreApi';
import { openRappiStoreApi } from 'src/api/letseatmanager/restaurantIntegration/openRappiStoreApi';
import { openUberEatsStoreApi } from 'src/api/letseatmanager/restaurantIntegration/openUberEatsStoreApi';
import { app2 } from 'src/app2';
import { AlertDialog } from 'src/components/AlertDialog';
import { history } from 'src/config/history';
import { RoutePaths } from 'src/constants/RoutePath';
import { UberEatsStoreOfflineReason, UberEatsStoreOfflineReasons } from 'src/constants/UberEatsStoreOfflineReason';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function IntegrationAlerts(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const uberEatsStoreOnline = useSelector((state) => state.app2.uberEatsStoreOnline);
    const uberEatsStoreOfflineReason = useSelector((state) => state.app2.uberEatsStoreOfflineReason);
    const uberEatsRdOptionalDisabled = useSelector((state) => state.app2.uberEatsRdOptionalDisabled);
    const uberEatsStorePausedByUber = useSelector((state) => state.app2.uberEatsStorePausedByUber);
    const rappiStoreOnline = useSelector((state) => state.app2.rappiStoreOnline);
    const didiFoodStoreOnline = useSelector((state) => state.app2.didiFoodStoreOnline);
    const storeShouldBeOpen = useSelector((state) => state.app2.storeShouldBeOpen);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const setUberEatsStoreOnline = useAction(app2.actions.setUberEatsStoreOnline);
    const setUberEatsStoreOfflineReason = useAction(app2.actions.setUberEatsStoreOfflineReason);
    const setUberEatsRdOptionalDisabled = useAction(app2.actions.setUberEatsRdOptionalDisabled);
    const setUberEatsStorePausedByUber = useAction(app2.actions.setUberEatsStorePausedByUber);

    const alertStatuses = [
        {
            key: 'rappiStoreOnline',
            open: rappiStoreOnline === false,
            title: translate('@store Store Closed', { store: 'Rappi' }),
            body: undefined,
            reason: undefined,
        },
        {
            key: 'didiFoodStoreOnline',
            open: didiFoodStoreOnline === false,
            title: translate('@store Store Closed', { store: 'Didi Food' }),
            body: undefined,
            reason: undefined,
        },
        {
            key: `uberEatsStoreOnline`,
            open: uberEatsStoreOnline === false,
            title: translate('@store Store Closed', { store: 'Uber Eats' }),
            body: translateOfflineReason(uberEatsStoreOfflineReason, uberEatsRdOptionalDisabled, uberEatsStorePausedByUber),
            reason: uberEatsStoreOfflineReason,
        },
    ];

    const openRappiStore = async () => {
        setLoading(true);
        const response = await openRappiStoreApi({ restaurantId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setLoading(false);
            return;
        }
        setLoading(false);
    };

    const openDidiFoodStore = async () => {
        setLoading(true);
        const response = await openDidiFoodStoreApi({ restaurantId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response, translate('You can only try opening and closing once a minute.'));
            setLoading(false);
            return;
        }
        setLoading(false);
    };

    const openUberEatsStore = async () => {
        setLoading(true);
        const response = await openUberEatsStoreApi({ restaurantId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setLoading(false);
            return;
        }
        setUberEatsStoreOnline(response.data.uberEatsStoreOnline);
        setUberEatsStoreOfflineReason(response.data.uberEatsStoreOfflineReason);
        setUberEatsRdOptionalDisabled(response.data.uberEatsRdOptionalDisabled);
        setUberEatsStorePausedByUber(response.data.uberEatsStorePausedByUber);
        setLoading(false);
    };

    const handleOpeningHours = () => history.push(RoutePaths.OPENING_HOURS);
    const handleOpenTheStore = (store: string) => {
        if (store === 'rappiStoreOnline') openRappiStore();
        else if (store === 'didiFoodStoreOnline') openDidiFoodStore();
        else if (store === 'uberEatsStoreOnline') openUberEatsStore();
    };

    return (
        <>
            {alertStatuses.map((alertStatus) => {
                return (
                    <AlertDialog key={alertStatus.key} severity={'error'} title={alertStatus.title} open={alertStatus.open}>
                        {alertStatus.body}
                        {storeShouldBeOpen ||
                        (alertStatus.key === 'uberEatsStoreOnline' &&
                            (uberEatsRdOptionalDisabled || uberEatsStorePausedByUber || uberEatsStoreOfflineReason === UberEatsStoreOfflineReasons.INVISIBLE)) ? (
                            <Button onClick={() => handleOpenTheStore(alertStatus.key)} className={classes.link} disabled={loading}>
                                {translate('Open store')}
                            </Button>
                        ) : (
                            <Button onClick={handleOpeningHours} className={classes.link}>
                                {translate('Open the store modifying the opening hours')}
                            </Button>
                        )}
                    </AlertDialog>
                );
            })}
        </>
    );
}

export function translateOfflineReason(uberEatsStoreOfflineReason: UberEatsStoreOfflineReason, uberEatsRdOptionalDisabled?: boolean, uberEatsStorePausedByUber?: boolean): string {
    if (uberEatsRdOptionalDisabled) {
        return translate(`Reason: @reason`, { reason: translate('RD-Optional Disabled') });
    }
    if (uberEatsStorePausedByUber) {
        return translate(`Reason: @reason`, { reason: translate(`UberEatsStoreOfflineReasons.${UberEatsStoreOfflineReasons.PAUSED_BY_UBER}`) });
    }
    if (!uberEatsStoreOfflineReason) {
        return '';
    }
    return translate(`Reason: @reason`, { reason: translate(`UberEatsStoreOfflineReasons.${uberEatsStoreOfflineReason}`) });
}

const useStyles = makeStyles((theme) => ({
    link: {
        background: 'transparent',
        borderColor: 'transparent',
        color: '#37B788',
        fontFamily: theme.typography.medium,
        cursor: 'pointer',
        textTransform: 'none',
    },
}));
