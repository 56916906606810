/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderId, RestaurantId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export async function markOrderReadyForPickUpApi(request: MarkOrderReadyForPickUpApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('order/markOrderReadyForPickUpApi', request);
}

export type MarkOrderReadyForPickUpApiRequest = {
    restaurantId: RestaurantId;
    orderId: OrderId;
};
