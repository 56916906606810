/**
 * @prettier
 */
import { Button, Table } from '@pidedirecto/ui';
import { useLoadApi } from '@pidedirecto/ui/hooks';
import { RefreshIcon } from '@pidedirecto/ui/icons';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import moment from 'moment-timezone';
import * as React from 'react';
import { useState } from 'react';
import { findOrderIncidentsApi } from 'src/api/letseatadmin/orderIncident/findOrderIncidentsApi';
import { FilterState, FromToFilter } from 'src/components/input/FromToFilter';
import { Page } from 'src/components/Page';
import { translate } from 'src/i18n/translate';
import { CreateOrderIncidentToolbarButton } from 'src/scenes/letseatadmin/restaurantManualEarning/CreateOrderIncidentToolbarButton';
import { ViewOrderIncidentImageButton } from 'src/scenes/letseatadmin/restaurantManualEarning/ViewOrderIncidentImageButton';
import { OrderIncidentVm } from 'src/types/OrderIncidentVm';
import { toShortId } from 'src/utils/uuid/toShortId';

export function OrderIncidentsPage(): React.ReactElement {
    const [filter, setFilter] = useState<FilterState>({
        from: moment().startOf('day').toDate(),
        to: moment().endOf('day').toDate(),
    });

    const [loading, orderIncidents, refreshOrderIncidents] = useLoadApi(
        findOrderIncidentsApi,
        {
            from: filter?.from,
            to: filter?.to,
        },
        { initialValue: [], requiredValues: [filter], dependencies: [filter] }
    );

    const handleChangeFilter = (filter: { from: Date; to: Date }) => {
        setFilter({
            from: moment(filter.from).startOf('day').toDate(),
            to: moment(filter.to).endOf('day').toDate(),
        });
    };

    const searchOrderIncident = (search: string, orderIncident: OrderIncidentVm) => {
        const matchRestaurantName = orderIncident.restaurantName.toLowerCase().includes(search.toLowerCase());
        const matchManagerUsername = orderIncident.managerUsername.toLowerCase().includes(search.toLowerCase());
        const matchOrderId = toShortId(orderIncident.orderId).toLowerCase().includes(search.toLowerCase());
        return matchRestaurantName || matchManagerUsername || matchOrderId;
    };

    return (
        <Page title={translate('Order incidents')}>
            <FromToFilter filter={filter} onChangeFilter={handleChangeFilter} />
            <Table
                emptyMessage={translate('There is no data to show')}
                title={translate('Order incidents')}
                loading={loading}
                columns={[
                    {
                        id: 'restaurantName',
                        content: translate('Restaurant'),
                    },
                    {
                        id: 'orderId',
                        content: translate('Order Id'),
                    },
                    {
                        id: 'zendeskId',
                        content: translate('Ticket'),
                    },
                    {
                        id: 'refundAmount',
                        content: translate('refund'),
                    },
                    {
                        id: 'orderIncidentType',
                        content: translate('Reason'),
                    },
                    {
                        id: 'managerUsername',
                        content: translate('Agent'),
                    },
                    {
                        id: 'imageUrl',
                        content: translate('Evidence'),
                    },
                ]}
                rows={orderIncidents.map((orderIncident) => ({
                    restaurantName: orderIncident.restaurantName,
                    orderId: toShortId(orderIncident.orderId),
                    zendeskId: orderIncident.zendeskId,
                    refundAmount: formatAsCurrencyNumber(orderIncident.refundAmount),
                    orderIncidentType: translate(`OrderIncidentTypes.${orderIncident.orderIncidentType}`),
                    managerUsername: orderIncident.managerUsername,
                    imageUrl: orderIncident.imageUrl ? <ViewOrderIncidentImageButton orderIncident={orderIncident} /> : translate('Without file'),
                    onSearch: (search: string) => searchOrderIncident(search, orderIncident),
                }))}
                filters={{
                    columns: true,
                }}
                searchable
                toolbar={
                    <>
                        <Button variant='secondary' onClick={() => refreshOrderIncidents()}>
                            <RefreshIcon size={20} title={translate('Refresh order incidents')} />
                        </Button>
                        <CreateOrderIncidentToolbarButton />
                    </>
                }
            />
        </Page>
    );
}
