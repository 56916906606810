/**
 * @prettier
 */
import { PrinterContents } from 'src/constants/PrinterContent';
import { getDeprecatedPrintersInLocalStorage } from 'src/localStorage/getDeprecatedPrintersInLocalStorage';
import { printerDeprecatedToPrinter } from 'src/services/printer/utils/printerDeprecatedToPrinter';
import { DeviceGroupId } from 'src/types/Id';
import { PrinterVm } from 'src/types/PrinterVm';
import { useSelector } from 'src/utils/react/useSelector';

export function useHasPrintersToPrint(): Result {
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);
    const printersNewUI = useSelector((state) => state.app.printers);
    const deviceGroup = useSelector((state) => state.app.deviceGroup);

    const printersOldUi = getDeprecatedPrintersInLocalStorage().map(printerDeprecatedToPrinter);

    const hasPrintersToPrint = () => {
        if (newDeviceManagementEnabled) {
            var printers = printersNewUI?.filter((printer: PrinterVm) =>
                printer.deviceGroups?.some((deviceGroupToSearch: { deviceGroupId: DeviceGroupId; deviceGroupName: string }) => deviceGroupToSearch.deviceGroupId === deviceGroup?.deviceGroupId)
            );
        } else {
            var printers = printersOldUi;
        }
        return !!printers.length;
    };

    const hasCommandPrintersToPrint = () => {
        if (newDeviceManagementEnabled) {
            // TODO Do we need to filter in the new Ui?
            var printers = printersNewUI?.filter((printer: PrinterVm) =>
                printer.deviceGroups?.some((deviceGroupToSearch: { deviceGroupId: DeviceGroupId; deviceGroupName: string }) => deviceGroupToSearch.deviceGroupId === deviceGroup?.deviceGroupId)
            );
        } else {
            var printers = printersOldUi.filter((printer) => {
                return printer.printerContent !== PrinterContents.TICKET;
            });
        }
        return !!printers.length;
    };

    return { hasPrintersToPrint: hasPrintersToPrint(), hasCommandPrintersToPrint: hasCommandPrintersToPrint() };
}
type Result = {
    hasPrintersToPrint: boolean;
    hasCommandPrintersToPrint: boolean;
};
