/**
 * @prettier
 */
import moment from 'moment';

export function groupOrderItemsAndCancelledItemsByAddedDate<T>(orderItems: Array<T>, cancelledItems: Array<T>): Array<Array<T>> {
    const items = [...orderItems, ...cancelledItems];

    const orderItemsSorted = items.sort((orderItemA, orderItemB) => {
        const dateToCompareA = (orderItemA as any)?.removedAt ?? (orderItemA as any)?.addedAt ?? new Date();
        const dateToCompareB = (orderItemB as any)?.removedAt ?? (orderItemB as any)?.addedAt ?? new Date();

        return (new Date(dateToCompareA) as any) - (new Date(dateToCompareB) as any);
    });

    return orderItemsSorted?.reduce((orderItems: Array<Array<T>>, orderItem: any) => {
        if (orderItems.length === 0) {
            orderItems.push([orderItem]);
            return orderItems;
        }

        const lastGroupIndex = orderItems.length - 1;
        const lasOrderItemInTheGroup: any = orderItems[lastGroupIndex][0];
        const dateToCompare = lasOrderItemInTheGroup.removedAt ?? lasOrderItemInTheGroup.addedAt;

        const orderItemHasTheSameAddedDate = moment(dateToCompare).isSame(orderItem.addedAt) && !(orderItem as any).removedAt;
        const cancelledItemHasTheSameAddedDate = dateToCompare === (orderItem as any).removedAt;

        if (orderItemHasTheSameAddedDate || cancelledItemHasTheSameAddedDate) {
            orderItems[lastGroupIndex].push(orderItem);
        } else {
            orderItems.push([orderItem]);
        }

        return orderItems;
    }, []);
}
