/**
 * @prettier
 */
import { createStore } from '@pidedirecto/ui/hooks';
import type { CartModifierVm } from 'src/types/CartModifierVm';

export const [usePosCartModifiersStore, usePosCartModifiersActions] = createStore<State, Actions>({
    initialState: {
        posCartModifiers: {},
        quantity: 0,
        missingSectionName: undefined,
    },
    actions: {
        setPosCartModifiers: (state, posCartModifiers) => {
            state.posCartModifiers = posCartModifiers;
        },
        setMissingSectionName: (state, missingSectionName) => {
            state.missingSectionName = missingSectionName;
        },
        setQuantity: (state, quantity) => {
            state.quantity = quantity;
        },
    },
});

type State = {
    quantity: number;
    missingSectionName?: string;
    posCartModifiers: {
        [key: string]: Array<CartModifierVm>;
    };
};

export type Actions = {
    setQuantity: (quantity: number) => void;
    setMissingSectionName: (missingSectionName: string) => void;
    setPosCartModifiers: (posCartModifiers: { [key: string]: Array<CartModifierVm> }) => void;
};
