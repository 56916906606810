/**
 * @prettier
 */
import { InputBase, InputLabel, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { Variant } from 'src/components/form/FormTypes';
import { Tooltip } from 'src/components/Tooltip';
import { googleMapsConfig } from 'src/config/googleMapsConfig';
import type { Country } from 'src/constants/Country';
import { translate } from 'src/i18n/translate';
import { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import { GooglePlaceId } from 'src/types/Id';
import { classNames } from 'src/utils/react/classNames';
import { trim } from 'src/utils/string/trim';

export function FormGoogleMapsAddressSearchAutocomplete({
    name,
    label,
    required,
    variant = 'outlined',
    country,
    autocompleteChange,
    addressFound,
    onFocus,
    onBlur,
    tooltip,
    disabled,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const inputRef = useRef<HTMLInputElement>();

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <SearchAutoComplete
                    label={label}
                    controllerProps={props}
                    variant={variant}
                    addressFound={addressFound}
                    inputRef={inputRef}
                    country={country}
                    autocompleteChange={autocompleteChange}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    required={required}
                    disabled={isSubmitting || disabled}
                    propValue={value}
                    tooltip={tooltip}
                />
            )}
            control={control}
            name={name}
            defaultValue={null}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
            }}
            onFocus={() => {
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

function SearchAutoComplete({
    label,
    controllerProps,
    variant,
    addressFound,
    inputRef,
    country,
    autocompleteChange,
    onChange,
    onFocus,
    onBlur,
    required,
    propValue,
    disabled,
    tooltip,
}: PropsSearchAutoComplete) {
    const { isLoaded, loadError } = useLoadScript(googleMapsConfig);
    const mapRef = useRef<HTMLDivElement>(null);

    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();
    const [value, setValue] = useState<Address | null>();
    const [inputValue, setInputValue] = useState<string | undefined>('');
    const [lastInputValue, setLastInputValue] = useState<string>();
    const [hideLoad, setHideLoad] = useState(true);
    const classes = useStyles();

    const findPlaceFromQuery = (service: any) => {
        return new Promise((resolve: (result: google.maps.places.PlaceResult[]) => void, reject: (error?: any) => void) => {
            service.findPlaceFromQuery(
                {
                    query: addressFound,
                    fields: ['formatted_address', 'place_id', 'name', 'geometry'],
                },
                function (results: google.maps.places.PlaceResult[], status: google.maps.places.PlacesServiceStatus) {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) return resolve(results);
                    reject();
                }
            );
        });
    };

    const setAutocompleteValue = async () => {
        const service = new window.google.maps.places.PlacesService(mapRef.current as any);
        const results = await findPlaceFromQuery(service);
        if (!results[0]?.place_id) {
            onChange(null);
            setValue(null);
            if (inputRef.current) inputRef.current.value = '';
            setLastInputValue('');
            return;
        }
        const addressLocation: GeoJsonPoint = {
            type: 'Point',
            coordinates: [(results[0].geometry as any).location.lng(), (results[0].geometry as any).location.lat()],
        };
        const newValue: Address = {
            street: lastInputValue ?? results[0].formatted_address,
            googlePlaceId: results[0].place_id as GooglePlaceId,
            name: results[0].name!,
            formattedAddress: results[0].formatted_address,
            location: addressLocation,
        };
        onChange(newValue);
        setValue(newValue);
        setInputValue(newValue.formattedAddress);
        setLastInputValue(inputRef.current?.value);
    };

    useEffect(() => {
        autocompleteChange?.(value);
    }, [value]);

    useEffect(() => {
        if (inputRef.current && !disabled && propValue) {
            setInputValue(propValue.formattedAddress);
            setValue(propValue);
            setLastInputValue(propValue.formattedAddress);
        }

        if (inputRef.current && !disabled && !propValue) {
            setInputValue('');
            setValue(null);
            setLastInputValue('');
        }
    }, [propValue, disabled]);

    useEffect(() => {
        if (addressFound && isLoaded) {
            setInputValue(addressFound);
            setAutocompleteValue();
        }
    }, [addressFound, isLoaded]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setHideLoad(false);
        }, 1000);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    if (!isLoaded) {
        return (
            <TextField
                {...controllerProps}
                type='text'
                label={label}
                placeholder={!hideLoad ? translate('Loading google maps...') : ''}
                variant={variant}
                fullWidth
                onKeyPress={(e) => {
                    (e.key || e.code) === 'Enter' && e.preventDefault();
                }}
            />
        );
    }

    if (loadError) {
        return (
            <TextField
                {...controllerProps}
                type='text'
                label={label}
                variant={variant}
                fullWidth
                error={true}
                helperText={translate('Failed to load google maps, please check your connection and try again.')}
                onKeyPress={(e) => {
                    (e.key || e.code) === 'Enter' && e.preventDefault();
                }}
            />
        );
    }

    return (
        <>
            <InputLabel classes={{ root: classes.label, error: classes.labelError }} required={required}>
                {label}
                {tooltip && <Tooltip text={tooltip} />}
            </InputLabel>
            <Autocomplete
                fields={['name', 'geometry.location', 'place_id', 'formatted_address']}
                /* @ts-ignore */
                style={{ width: '100%' }}
                restrictions={country ? { country } : undefined}
                onLoad={setAutocomplete as any}
                onUnmount={() => setAutocomplete(undefined)}
                onPlaceChanged={() => {
                    if (autocomplete) {
                        const result = autocomplete.getPlace();
                        if (!result?.place_id) {
                            onChange(null);
                            setValue(null);
                            if (inputRef.current) inputRef.current.value = '';
                            setLastInputValue('');
                            return;
                        }
                        const addressLocation: GeoJsonPoint = {
                            type: 'Point',
                            coordinates: [(result.geometry as any).location.lng(), (result.geometry as any).location.lat()],
                        };
                        const newValue: Address = {
                            street: lastInputValue ?? result.formatted_address,
                            googlePlaceId: result.place_id as GooglePlaceId,
                            name: result.name!,
                            formattedAddress: result.formatted_address,
                            location: addressLocation,
                        };
                        onChange(newValue);
                        setValue(newValue);
                        setInputValue(newValue.formattedAddress);
                        setLastInputValue(inputRef.current?.value);
                    } else {
                        console.log('Autocomplete is not loaded yet!');
                    }
                }}
            >
                <InputBase
                    type='text'
                    placeholder={translate('Select a location')}
                    fullWidth
                    inputRef={inputRef}
                    disabled={disabled}
                    classes={{ root: classNames(classes.input), error: classNames(classes.inputError) }}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyPress={(e) => {
                        (e.key || e.code) === 'Enter' && e.preventDefault();
                    }}
                    onFocus={() => {
                        onFocus?.();
                    }}
                    onBlur={(event) => {
                        onBlur?.();
                        if (!trim(inputRef?.current?.value)) {
                            autocomplete?.set('place', {});
                            return;
                        }
                    }}
                />
            </Autocomplete>
            <div style={{ display: 'none' }} ref={mapRef}></div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    input: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 5,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontFamily: theme.typography.regular,
        border: `1px solid ${theme.palette.secondary.dark}`,
    },
    inputError: {
        border: '2px solid red',
    },
    label: {
        fontFamily: theme.typography.medium,
        color: theme.palette.secondary.contrastText,
        fontSize: 15,
        marginBottom: 5,
        display: 'flex',
    },
    labelError: {
        color: 'red',
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: theme.palette.secondary.contrastText,
    },
    helperTextError: {
        fontFamily: theme.typography.light,
        color: 'red',
    },
}));

type Props = {
    name: string;
    label?: string;
    required?: boolean;
    variant?: Variant;
    country?: Country;
    autocompleteChange?: (address: Address) => any;
    addressFound?: string;
    onFocus?: any;
    onBlur?: any;
    tooltip?: string;
    disabled?: boolean;
};

type PropsSearchAutoComplete = {
    label?: string;
    controllerProps: any;
    variant?: Variant;
    addressFound?: string;
    inputRef: any;
    country?: Country;
    autocompleteChange?: any;
    onChange: any;
    onFocus?: any;
    onBlur?: any;
    required?: boolean;
    propValue?: any;
    disabled?: boolean;
    tooltip?: string;
};

type Address = {
    street: string | undefined;
    googlePlaceId: GooglePlaceId;
    name: string;
    formattedAddress: string | undefined;
    location: GeoJsonPoint;
};
