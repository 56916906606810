/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function linkInventoryBetweenRestaurantsApi(request: LinkInventoryBetweenRestaurantsApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('inventory/linkInventoryBetweenRestaurantsApi', request);
}

type LinkInventoryBetweenRestaurantsApiRequest = {
    fromRestaurantId: RestaurantId;
    toRestaurantId: RestaurantId;
};
