/**
 * @prettier
 */
import { makeStyles, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useRef, useState } from 'react';
import { DownloadCsvToolbarButton } from 'src/components/mui-datatables/DownloadCsvToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { objectsToCsv } from 'src/utils/csv/objectsToCsv';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';
import { createFilteredOnDownload } from 'src/utils/mui-datatables/createFilteredOnDownload';
import { SortCompares } from 'src/utils/mui-datatables/SortCompares';
import { getOrderStatusColor } from 'src/utils/order/getOrderStatusColor';
import { toHumanizedOrderId } from 'src/utils/order/toHumanizedOrderId';
import { translateOrderType } from 'src/utils/translate/translateOrderType';
import { openOrderPageInNewTab } from 'src/utils/window/openOrderPageInNewTab';

export function OrdersTable({ loading, title, orders, serverSide, totalOrders, simplifiedDownload, onClickRefresh, onChangePage, onChangeRowsPerPage }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const table = useRef();

    const [page, setPage] = useState<number | undefined>(undefined);
    const [rowsPerPage, setRowsPerPage] = useState();

    const handleClickDownloadCsv = (table: any) => {
        if (orders.length <= 0) {
            alert(translate('There are no orders to download'));
            return;
        }
        const rows = orders.map((order) => {
            const row = {
                orderId: order.orderId,
                externalOrderId: order.rappiOrderId ?? order.uberEatsOrderId ?? order.didiFoodOrderId,
                app: order.app,
                orderingDate: moment.tz(order.createdAt, order.timeZone).format('MM/DD/YYYY'),
                //acceptedAt: moment.tz(order.acceptedAt, order.timeZone),
                orderType: order.orderType,
                orderAt: moment.tz(order.createdAt, order.timeZone).format('HH:mm'),
                subTotal: order.subtotal,
                productDiscount: order.promoPriceDiscount,
                promoCodeDiscount: order.promoCodeDiscount,
                companyCredits: order.companyCredits,
                totalDiscount: order.discount,
                total: order.total,
                paymentMethod: order.paymentMethod,
                driverName: order.driverName,
                orderStatus: order.orderStatus,
                paymentStatus: order.paymentStatus,
                restaurantName: order.restaurant?.name ?? ' ',
            } as const;
            return row;
        });
        const csv = objectsToCsv(rows);
        downloadTextInFile(csv, 'exported-order-sales.csv');
        return;
    };

    const changePage = (page: number) => {
        onChangePage?.(page);
        setPage(page);
    };

    const changeRowsPerPage = (rowsPerPage: any) => {
        onChangeRowsPerPage?.(rowsPerPage);
        setRowsPerPage(rowsPerPage);
        changePage(0);
    };

    return (
        <div style={{ position: 'relative' }}>
            <UpdatingContentProgress loading={loading} />
            <MUIDataTable
                /* @ts-ignore */
                ref={table}
                className={classes.table}
                title={title || translate('Orders')}
                data={orders.map((order) => ({
                    orderId: order.orderId,
                    restaurantId: order.restaurantId,
                    app: order.app,
                    orderingDate: moment.tz(order.createdAt, order.timeZone).format('L'),
                    year: moment.tz(order.createdAt, order.timeZone).format('YYYY'),
                    month: moment.tz(order.createdAt, order.timeZone).format('MM'),
                    day: moment.tz(order.createdAt, order.timeZone).format('ddd'),
                    orderType: translateOrderType(order.orderType),
                    orderedAt: moment.tz(order.createdAt, order.timeZone).format('LT'),
                    acceptedAt: order.acceptedAt ? moment.tz(order.acceptedAt, order.timeZone).format('LT') : '',
                    rejectedCancelledAt: order.rejectedAt || order.cancelledAt ? moment.tz(order.rejectedAt || order.cancelledAt, order.timeZone).format('LT') : '',
                    completedAt: order.completedAt ? moment.tz(order.completedAt, order.timeZone).format('LT') : '',
                    pickupTime: formatDateTimeString(order.pickupTime, order.timeZone),
                    deliveryTime: formatDateTimeString(order.deliveryTime, order.timeZone),
                    driverAcceptedDuration: order.driverAcceptedDuration ? `${Math.ceil(moment.duration(order.driverAcceptedDuration * 1_000).asMinutes())} min` : undefined,
                    driverArrivedToStoreDuration: order.driverArrivedToStoreDuration ? `${Math.ceil(moment.duration(order.driverArrivedToStoreDuration * 1_000).asMinutes())} min` : undefined,
                    driverPickupDuration: order.driverPickupDuration ? `${Math.ceil(moment.duration(order.driverPickupDuration * 1_000).asMinutes())} min` : undefined,
                    driverArrivedToClientDuration: order.driverArrivedToClientDuration ? `${Math.ceil(moment.duration(order.driverArrivedToClientDuration * 1_000).asMinutes())} min` : undefined,
                    driverDrivingDuration: order.driverDrivingDuration ? `${Math.ceil(moment.duration(order.driverDrivingDuration * 1_000).asMinutes())} min` : undefined,
                    googleDrivingDuration: order.googleDrivingDuration ? `${Math.ceil(moment.duration(order.googleDrivingDuration * 1_000).asMinutes())} min` : undefined,
                    customerType: order.customerType,
                    name: order.name,
                    mobileNumber: order.mobileNumber,
                    email: order.email,
                    orders: order.totalNumberOfOrders?.all,
                    completed: order.totalNumberOfOrders?.completed,
                    rejected: order.totalNumberOfOrders?.rejected,
                    cancelled: order.totalNumberOfOrders?.cancelled,
                    signedUp: formatDateTimeString(order.signedUpAt),
                    days: moment.tz(order.timeZone).diff(moment.tz(order.firstOrderAt, order.timeZone), 'days'),

                    subtotal: order.subtotal,
                    productDiscount: order.productDiscount ?? order.promoPriceDiscount ?? '', // TODO: make sure to always send order.productDiscount from server instead
                    promoCodeDiscount: order.promoCodeDiscount ?? '',
                    promoCode: order.promoCode ?? '',
                    companyCredits: order.companyCredits ?? '',
                    companyName: order.companyName,
                    letsEatCredits: order.letsEatCredits ?? '',
                    usedRestaurantCashbackCredits: order.usedRestaurantCashbackCredits ?? '',
                    total: order.total,
                    paidWithCard: order.paidWithCard ?? '',
                    paidWithPayroll: order.paidWithPayroll ?? '',
                    paymentMethod: order.paymentMethod ?? '',
                    sodexoCard: order.sodexoCard ? 'SODEXO' : '',

                    restaurantName: order.restaurantName,
                    restaurantAddress: order.restaurantAddress,
                    city: order.city,
                    zone: order.zone,
                    driverName: order.driverName,
                    driverMobileNumber: order.driverMobileNumber,
                    orderStatus: order.orderStatus,
                    orderRejectReason: order.orderRejectReason || '',
                    paymentStatus: order.paymentStatus,
                    paymentRejectReason: order.paymentRejectReason,
                    refundMethod: order.refundMethod || '',
                    qpayPaymentStatus: order.qpayPaymentStatus,
                    qpayPaymentRejectReason: order.qpayPaymentRejectReason,
                    conektaPaymentStatus: order.conektaPaymentStatus,
                    conektaPaymentRejectReason: order.conektaPaymentRejectReason,
                    stripePaymentStatus: order.stripePaymentStatus,
                    stripePaymentRejectReason: order.stripePaymentRejectReason,
                    stripePaymentSourceError: order.stripePaymentSourceError,
                }))}
                columns={[
                    {
                        name: 'orderId',
                        label: translate('Order Id'),
                        options: {
                            display: 'excluded',
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'orderId',
                        label: translate('Order Id'),
                        options: {
                            filter: false,
                            searchable: false,
                            download: false,
                            customBodyRenderLite: (dataIndex) => {
                                const orderId = orders[dataIndex].orderId;
                                return (
                                    <Button size='small' color={(theme.palette.text as any).brandContrast} onClick={() => openOrderPageInNewTab(orderId)}>
                                        #{toHumanizedOrderId(orderId)}
                                    </Button>
                                );
                            },
                        },
                    },
                    {
                        name: 'app',
                        label: translate('App'),
                        options: {
                            filter: true,
                            searchable: false,
                            display: 'excluded',
                        },
                    },
                    {
                        name: 'orderingDate',
                        label: translate('Ordering Date'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'year',
                        label: translate('Year'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'month',
                        label: translate('Month'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'day',
                        label: translate('Day'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'orderType',
                        label: translate('Order Type'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'orderedAt',
                        label: translate('Ordered At'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'acceptedAt',
                        label: translate('Accepted At'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'rejectedCancelledAt',
                        label: translate('Rej/Canc At'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'completedAt',
                        label: translate('Completed At'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'pickupTime',
                        label: translate('Pickup Time'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'deliveryTime',
                        label: translate('Delivery Time'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'driverAcceptedDuration',
                        label: translate('Driver Accepted Duration'),
                        options: {
                            filter: false,
                            searchable: false,
                            sortCompare: SortCompares.parseFloat,
                        },
                    },
                    {
                        name: 'driverArrivedToStoreDuration',
                        label: translate('Driver Arrived To Store Duration'),
                        options: {
                            filter: false,
                            searchable: false,
                            sortCompare: SortCompares.parseFloat,
                        },
                    },
                    {
                        name: 'driverPickupDuration',
                        label: translate('Driver Pickup Duration'),
                        options: {
                            filter: false,
                            searchable: false,
                            sortCompare: SortCompares.parseFloat,
                        },
                    },
                    {
                        name: 'driverArrivedToClientDuration',
                        label: translate('Driver Arrived To Client Duration'),
                        options: {
                            filter: false,
                            searchable: false,
                            sortCompare: SortCompares.parseFloat,
                        },
                    },
                    {
                        name: 'driverDrivingDuration',
                        label: translate('Driver Driving Duration'),
                        options: {
                            filter: false,
                            searchable: false,
                            sortCompare: SortCompares.parseFloat,
                        },
                    },
                    {
                        name: 'googleDrivingDuration',
                        label: translate('Google Driving Duration'),
                        options: {
                            filter: false,
                            searchable: false,
                            sortCompare: SortCompares.parseFloat,
                        },
                    },
                    {
                        name: 'customerType',
                        label: translate('Customer Type'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'name',
                        label: translate('Name'),
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'mobileNumber',
                        label: translate('Mobile Number'),
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'email',
                        label: translate('Email'),
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'orders',
                        label: translate('Orders'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'completed',
                        label: translate('Completed'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'rejected',
                        label: translate('Rejected'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'cancelled',
                        label: translate('Cancelled'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'signedUp',
                        label: translate('Signed Up'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'days',
                        label: translate('Days'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },

                    {
                        name: 'subtotal',
                        label: translate('Subtotal'),
                        options: {
                            filter: false,
                            searchable: false,
                            sortCompare: SortCompares.parseFloat,
                        },
                    },
                    {
                        name: 'productDiscount',
                        label: translate('Product Discount'),
                        options: {
                            filter: false,
                            searchable: false,
                            sortCompare: SortCompares.parseFloat,
                        },
                    },
                    {
                        name: 'promoCodeDiscount',
                        label: translate('Promo Code Discount'),
                        options: {
                            filter: false,
                            searchable: false,
                            sortCompare: SortCompares.parseFloat,
                        },
                    },
                    {
                        name: 'promoCode',
                        label: translate('Promo Code'),
                        options: {
                            filter: true,
                            searchable: true,
                        },
                    },
                    {
                        name: 'companyCredits',
                        label: translate('Company Credits'),
                        options: {
                            filter: false,
                            searchable: false,
                            sortCompare: SortCompares.parseFloat,
                        },
                    },
                    {
                        name: 'companyName',
                        label: translate('Company Name'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'letsEatCredits',
                        label: translate('Credits'),
                        options: {
                            filter: false,
                            searchable: false,
                            sortCompare: SortCompares.parseFloat,
                        },
                    },
                    {
                        name: 'usedRestaurantCashbackCredits',
                        label: translate('Restaurant Cashaback Credits'),
                        options: {
                            filter: false,
                            searchable: false,
                            sortCompare: SortCompares.parseFloat,
                        },
                    },
                    {
                        name: 'total',
                        label: translate('Total'),
                        options: {
                            filter: false,
                            searchable: false,
                            sortCompare: SortCompares.parseFloat,
                        },
                    },
                    {
                        name: 'paidWithCard',
                        label: translate('Paid with Card'),
                        options: {
                            filter: false,
                            searchable: false,
                            sortCompare: SortCompares.parseFloat,
                        },
                    },
                    {
                        name: 'paidWithPayroll',
                        label: translate('Paid with Payroll'),
                        options: {
                            filter: false,
                            searchable: false,
                            sortCompare: SortCompares.parseFloat,
                        },
                    },
                    {
                        name: 'paymentMethod',
                        label: translate('Payment Method'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'sodexoCard',
                        label: translate('Sodexo Card'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'restaurantId',
                        label: 'Restaurant Id',
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'restaurantName',
                        label: translate('Restaurant Name'),
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'restaurantAddress',
                        label: translate('Restaurant Address'),
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'city',
                        label: translate('City'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'zone',
                        label: translate('Zone'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'driverName',
                        label: translate('Driver'),
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'driverMobileNumber',
                        label: translate('Driver Mobile'),
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'orderStatus',
                        label: translate('Order Status'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'orderRejectReason',
                        label: translate('Order Reject Reason'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'paymentStatus',
                        label: translate('Payment Status'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'paymentRejectReason',
                        label: translate('Payment Reject Reason'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'refundMethod',
                        label: translate('Refund Method'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'qpayPaymentStatus',
                        label: translate('Qpay Status'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'qpayPaymentRejectReason',
                        label: translate('Qpay Reject Reason'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'conektaPaymentStatus',
                        label: translate('Conekta Status'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'conektaPaymentRejectReason',
                        label: translate('Conekta Reject Reason'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'stripePaymentStatus',
                        label: translate('Stripe Status'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'stripePaymentRejectReason',
                        label: translate('Stripe Reject Reason'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'stripePaymentSourceError',
                        label: translate('Stripe Add Card Error'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    download: simplifiedDownload ? 'false' : 'true',
                    onDownload: createFilteredOnDownload({ table: table.current }),
                    setRowProps: (row, dataIndex) => {
                        const order = orders[dataIndex];
                        return {
                            style: { backgroundColor: getOrderStatusColor(order.orderStatus, order.paymentStatus as any) },
                        };
                    },
                    filterType: 'multiselect',
                    rowsPerPageOptions: [10, 20, 50, 100],
                    rowsPerPage: rowsPerPage || 100,
                    count: totalOrders || orders.length,
                    serverSide: serverSide ?? false,
                    page: page,
                    onTableChange: (action, tableState) => {
                        if (!loading) {
                            switch (action) {
                                case 'changePage':
                                    changePage(tableState.page);
                                    break;
                                case 'changeRowsPerPage':
                                    changeRowsPerPage(tableState.rowsPerPage);
                                    break;
                                default:
                            }
                        }
                    },
                    customToolbar: () => {
                        return (
                            <>
                                <Tooltip title='Refresh'>
                                    <IconButton onClick={onClickRefresh}>
                                        <RefreshIcon />
                                    </IconButton>
                                </Tooltip>
                                {simplifiedDownload && <DownloadCsvToolbarButton onClick={handleClickDownloadCsv} />}
                            </>
                        );
                    },
                    // filterList: [[], [], [], [], ['MEXICO']],
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

type Props = {
    loading: boolean;
    title?: string;
    totalOrders?: number;
    serverSide?: boolean;
    orders: Array<OrderVm>;
    simplifiedDownload?: boolean;
    onClickRefresh: any;
    onChangePage?: any;
    onChangeRowsPerPage?: any;
};
