/**
 * @prettier
 */
export const ExternalDeliveryEstimatedTimes = {
    '15-30': '15-30',
    '40-45': '40-45',
    '45-60': '45-60',
    '60-75': '50-75',
    '75-90': '75-90',
    '90-120': '90-120',
} as const;
export type ExternalDeliveryEstimatedTime = typeof ExternalDeliveryEstimatedTimes[keyof typeof ExternalDeliveryEstimatedTimes];
