/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { Checkbox } from 'src/components/Checkbox';
import { translate } from 'src/i18n/translate';
import { PeopleIcon } from 'src/icons/PeopleIcon';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { TableVm } from 'src/types/RestaurantZoneVm';
import { classNames } from 'src/utils/react/classNames';

export function SelectableTable({ table, onSelectTable }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [isSelected, setIsSelected] = useState(false);

    const isTableBusy = !!table.order;

    const handleClickCheckbox = () => {
        setIsSelected(!isSelected);
        onSelectTable(table.tableNumber, !isSelected);
    };

    return (
        <div className={classes.container}>
            <Checkbox name={table.tableNumber} value={table.tableNumber} checked={isSelected} label={''} onChange={handleClickCheckbox} />
            <span className={classes.text} style={{ width: 100 }}>
                {translate('Table @number', { number: table.tableNumber })}
            </span>
            <div className={classes.tableStateContainer}>
                <div className={classNames(classes.tableState, isTableBusy ? classes.tableBusy : classes.tableAvailable)}></div>
                {isTableBusy && (
                    <span className={classes.text} style={{ width: 130 }}>
                        {translate('Busy (@total)', { total: formatAsCurrencyNumber(table.order?.total ?? '0') })}
                    </span>
                )}
                {!isTableBusy && (
                    <span className={classes.text} style={{ width: 130 }}>
                        {translate('Available')}
                    </span>
                )}
            </div>
            <div className={classes.numberOfCustomers}>
                <PeopleIcon color={'#2E3748'} />
                <span role={'emphasis'} className={classes.text}>
                    {table.order?.numberOfCustomers ? table.order?.numberOfCustomers : '-'}
                </span>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        color: '#2E3748',
    },
    numberOfCustomers: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5,
    },
    tableStateContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            gap: 5,
        },
    },
    tableState: {
        width: 16,
        height: 16,
        borderRadius: 16,
    },
    tableBusy: {
        backgroundColor: '#C7413A',
    },
    tableAvailable: {
        backgroundColor: '#06B7A2',
    },
}));

type Props = {
    table: TableVm;
    onSelectTable: any;
};
