/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeBrandApi } from 'src/api/letseatadmin/brand/changeBrandApi';
import { getBrandApi } from 'src/api/letseatadmin/brand/getBrandApi';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormRestaurantsAutocomplete } from 'src/components/form/FormRestaurantsAutocomplete';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { BrandId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { requireValue } from 'src/utils/require/requireValue';

export function ChangeBrandDialog({ open, brandId, onClose, onChangeBrand }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) load();
    }, [open]);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const handleExited = () => {
        setLoading(false);
    };

    const load = async () => {
        setLoading(true);
        const response = await getBrandApi({ brandId: requireValue(brandId) });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            onClose();
            return;
        }
        const brand = response.data;
        form.reset(brand);
        setLoading(false);
    };

    const onSubmit = async (form: any) => {
        const response = await changeBrandApi({
            brandId: requireValue(brandId),
            brandName: form.brandName,
            restaurantIds: form.restaurantIds ?? [],
            comment: form.comment,
            marketingActionsForRestaurantEnabled: form.marketingActionsForRestaurantEnabled,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onChangeBrand();
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} onExited={handleExited}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Change Brand').toUpperCase()}</DialogTitle>
                <DialogContent>
                    <FormContent loading={loading} />
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={loading || isSubmitting}>
                        {isSubmitting ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

function FormContent({ loading }: { loading: boolean }) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <FormTextField name='brandName' label={translate('Brand Name')} />
            </Grid>
            <Grid item xs={12}>
                <FormRestaurantsAutocomplete name='restaurantIds' label={translate('Restaurants')} />
            </Grid>
            <Grid item xs={12}>
                <FormTextField name='comment' label={translate('Comment')} />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSwitch name='marketingActionsForRestaurantEnabled' label={translate('Marketing Actions For Restaurant Enabled')} />
            </Grid>
        </Grid>
    );
}

type Props = {
    open: boolean;
    brandId: BrandId | undefined;
    onClose: any;
    onChangeBrand: any;
};
