/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { FoldersIcon } from '@pidedirecto/ui/icons';
import { MUIDataTableColumn } from 'mui-datatables';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { findSuppliesByRestaurantIdApi } from 'src/api/letseatmanager/supply/findSuppliesByRestaurantIdApi';
import { removeSupplyApi } from 'src/api/letseatmanager/supply/removeSupplyApi';
import { Button } from 'src/components/Button';
import { Chip } from 'src/components/Chip';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { inventory } from 'src/reducers/inventoryReducer';
import { ChangeSupplyDialog } from 'src/scenes/letseatmanager/inventory/supply/ChangeSupplyDialog';
import { CreateSupplyDialog } from 'src/scenes/letseatmanager/inventory/supply/CreateSupplyDialog';
import { CreateSupplyProductionDialog } from 'src/scenes/letseatmanager/inventory/supply/CreateSupplyProductionDialog';
import { ManagementSupplyCategoryDialog } from 'src/scenes/letseatmanager/inventory/supply/ManagementSupplyCategoryDialog';
import { SuppliesTableContextualMenu } from 'src/scenes/letseatmanager/inventory/supply/SuppliesTableContextualMenu';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { SupplyId } from 'src/types/Id';
import { SupplyVm } from 'src/types/SupplyVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isCompoundSupply } from 'src/utils/inventory/supply/isCompoundSupply';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { getSupplyCategoryName } from 'src/utils/supplyCategory/getSupplyCategoryName';

export function SuppliesTable(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [loading, setLoading] = useState(false);
    const [createSupplyDialogState, setCreateSupplyDialogState] = useState({ open: false });
    const [changeSupplyDialogState, setChangeSupplyDialogState] = useState<{ open: boolean; supplyId: SupplyId | undefined }>({ open: false, supplyId: undefined });
    const [managementSupplyCategoryDialogOpen, setManagementSupplyCategoryDialogOpen] = useState(false);
    const [produceCompoundSupply, setProduceCompoundSupply] = useState<{ open: boolean; supplyId: SupplyId | undefined }>({ open: false, supplyId: undefined });

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const supplies = useSelector((state) => state.inventory.supplies);
    const inventoryLinkedToRestaurantId = useSelector((state) => state.app.restaurant?.inventoryLinkedToRestaurantId);

    const setSupplies = useAction(inventory.actions.setSupplies);

    const [loadingSupplies, suppliesData, refreshSupplies] = useLoadApi(findSuppliesByRestaurantIdApi, { restaurantId }, { initialValue: { supplies: [] } });

    useEffect(() => {
        onRefreshSupplies();
    }, [suppliesData]);

    const onRefreshSupplies = () => {
        if (!suppliesData) return;
        setSupplies(suppliesData.supplies);
    };

    const removeSupply = async (supplyId: SupplyId) => {
        const supply = supplies.find((supply) => supply.supplyId === supplyId);
        const remove = window.confirm(translate('Are you sure you want to remove the supply @supplyName?', { supplyName: supply?.name }));

        if (!remove) return;

        setLoading(true);
        const response = await removeSupplyApi({
            supplyId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        refreshSupplies();
    };

    const closeCreateSupplyCategory = () => setManagementSupplyCategoryDialogOpen(false);

    const handleProductionSuccess = () => {
        refreshSupplies();
        setProduceCompoundSupply({ open: false, supplyId: undefined });
    };

    const handleSupplyCreated = (produceAfterSave: any, supplyId: any) => {
        if (produceAfterSave) {
            setProduceCompoundSupply({ open: true, supplyId });
        }
        refreshSupplies();
    };

    const columns: Array<MUIDataTableColumn> = [
        {
            name: 'supplyId',
            label: '',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'name',
            label: translate('Name'),
            options: {
                filter: false,
                customBodyRender: (name: string) => {
                    const supply = supplies?.find((supply: SupplyVm) => supply.name === name);

                    const formattedName = isCompoundSupply(supply?.supplyType) ? `${name} (${supply?.ingredients?.length || 0})` : name;
                    return (
                        <div className={classes.nameContainer}>
                            <span>{formattedName}</span>
                            {supply?.isConsumable && <Chip classes={{ chip: classes.consumable }}>{translate('Is consumable')}</Chip>}
                        </div>
                    );
                },
            },
        },
        {
            name: 'buyUnit',
            label: translate('Unit'),
            options: {
                filter: false,
            },
        },
        {
            name: 'supplyCategory',
            label: translate('Category'),
            options: {
                filter: false,
            },
        },
        {
            name: 'cost',
            label: translate('Cost'),
            options: {
                filter: false,
            },
        },
    ];

    if (!inventoryLinkedToRestaurantId) {
        columns.push({
            name: 'edit',
            label: ' ',
            options: {
                filter: false,
                customBodyRender: (value: SupplyId) => (
                    <SuppliesTableContextualMenu
                        supplyId={value}
                        onProduce={() => setProduceCompoundSupply({ open: true, supplyId: value })}
                        onEdit={() => setChangeSupplyDialogState({ open: true, supplyId: value })}
                        onRemove={() => removeSupply(value)}
                        disabled={loading}
                        supplyType={supplies.find((supply) => supply.supplyId === value)?.type}
                    />
                ),
            },
        });
    }

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading || loadingSupplies} bottom />
            <CreateSupplyDialog open={createSupplyDialogState.open} onClose={() => setCreateSupplyDialogState({ open: false })} onSupplyCreated={handleSupplyCreated} />
            <ChangeSupplyDialog
                open={changeSupplyDialogState.open}
                supplyId={changeSupplyDialogState.supplyId}
                onClose={() => setChangeSupplyDialogState({ open: false, supplyId: undefined })}
                onChangeSupply={handleSupplyCreated}
            />
            <ManagementSupplyCategoryDialog open={managementSupplyCategoryDialogOpen} onClose={closeCreateSupplyCategory} />
            <CreateSupplyProductionDialog
                open={produceCompoundSupply.open}
                supplyId={produceCompoundSupply.supplyId}
                onClose={() => setProduceCompoundSupply({ open: false, supplyId: undefined })}
                onSuccess={handleProductionSuccess}
            />

            <Table
                data={supplies.map((supply) => {
                    return {
                        supplyId: supply.supplyId,
                        name: supply.name,
                        buyUnit: translate(`UnitOfMeasurements.${supply.buyUnits.find((unit) => unit.isMainUnit)?.unit || ''}`),
                        supplyCategory: getSupplyCategoryName(supply.supplyCategoryName),
                        cost: formatAsCurrencyNumber(supply.fixedCost),
                        edit: supply.supplyId,
                        produce: supply.supplyId,
                    };
                })}
                columns={columns}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    filter: false,
                    print: false,
                    viewColumns: false,
                    onDownload: (buildHead, buildBody, columns, data) => {
                        const filteredColumns = columns.filter((column: { name: string }) => column.name !== 'edit');
                        const customRowData = data.map((row: { data: Array<any> }) => {
                            const supply = row.data[1];
                            const name = isCompoundSupply(supply.supplyType) ? `${supply.name} (${supply.ingredients?.length || 0})` : supply.name;
                            row.data[1] = name;
                            row.data.pop();
                            return row;
                        });

                        return '\uFEFF' + buildHead(filteredColumns) + buildBody(customRowData);
                    },

                    customToolbar: () => (
                        <>
                            {!inventoryLinkedToRestaurantId && <CreateToolbarButton onClick={() => setCreateSupplyDialogState({ open: true })} titleAccess='Create supply' />}
                            <RefreshToolbarButton onClick={refreshSupplies} />
                            <Button icon classes={{ button: classes.button }} onClick={() => setManagementSupplyCategoryDialogOpen(true)}>
                                <FoldersIcon />
                                {translate(`Categories`)}
                            </Button>
                        </>
                    ),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        margin: 0,
        marginBottom: -50,
        marginTop: 50,
        position: 'relative',
        zIndex: 5,
        width: 'fit-content',
    },
    button: {
        width: 'fit-content !important',
        padding: '0 16px',
        height: 50,
        '&:focus': {
            border: '1px solid #D9D9D9',
        },
    },
    consumable: {
        backgroundColor: theme.palette.surface.brand,
        fontSize: 14,
        color: theme.palette.surface.brandContrast,
        padding: '4px 12px',
    },
    nameContainer: {
        display: 'flex',
        gap: 10,
    },
}));
