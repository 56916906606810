/**
 * @prettier
 */
import { IconButton, makeStyles } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import * as React from 'react';
import { findInventoryTransactionReportsApi } from 'src/api/letseatmanager/inventory/findInventoryTransactionReportsApi';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function InventoryReportHistory({ goBack, goToInventoryDone }: Props): React.ReactElement {
    const classes = useStyles();

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, inventoryReports, refreshInventoryReports] = useLoadApi(
        findInventoryTransactionReportsApi,
        {
            restaurantId,
        },
        { initialValue: [], requiredValues: [restaurantId] }
    );

    return (
        <div className={classes.inventoryTableContainer}>
            <UpdatingContentProgress loading={loading} bottom />
            <div className={classes.backButtonContainer}>
                <IconButton onClick={goBack}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                <span className={classes.text}>{translate('Go Back')}</span>
            </div>
            <h1 className={classes.title}>{translate('Inventory Reports History')}</h1>
            <Table
                className={classes.table}
                data={inventoryReports
                    .sort((inventoryReportA, inventoryReportB) => (inventoryReportB.createdAt as any) - (inventoryReportA.createdAt as any))
                    .map((inventoryReport) => {
                        return {
                            inventoryReportId: inventoryReport.inventoryTransactionReportId,
                            createdAt: formatDateTimeString(inventoryReport.createdAt),
                            createdBy: inventoryReport.createdBy,
                        };
                    })}
                columns={[
                    {
                        name: 'inventoryReportId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'createdAt',
                        label: translate('Created At'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'createdBy',
                        label: translate('Created By'),
                        options: {
                            filter: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    filter: false,
                    print: false,
                    viewColumns: false,
                    onRowClick: (rowData: Array<string>) => {
                        goToInventoryDone(rowData[0]);
                    },
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={refreshInventoryReports} />
                        </>
                    ),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    table: {
        width: '100%',
    },
    inventoryTableContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    backButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        margin: 0,
        marginBottom: -50,
        marginTop: 50,
        position: 'relative',
        zIndex: 5,
        width: 'fit-content',
    },
}));

type Props = {
    goBack: any;
    goToInventoryDone: any;
};
