/**
 * @prettier
 */
import moment from 'moment-timezone';
import { TimeZone, TimeZones } from 'src/constants/TimeZone';

export function minutesFromNow(date: string | Date, timeZone: TimeZone = TimeZones.AMERICA_MONTERREY): number {
    const nextDate = moment.tz(date, timeZone);
    return nextDate.diff(moment.tz(timeZone), 'minutes');
}
