/**
 * @prettier
 */
import { CommandSection, CommandSections } from 'src/constants/CommandSection';
import type { FormActiveCommandSections } from 'src/types/FormActiveCommandSections';

export function mapCommandSectionsToFormCommandSections(sections: Array<CommandSection>): FormActiveCommandSections {
    const commandSections: Array<CommandSection> = Object.values(CommandSections);
    const mappedSections: Record<string, any> = {};

    for (const commandKey of commandSections) {
        if (sections?.includes(commandKey)) {
            mappedSections[commandKey] = true;
            continue;
        }
        mappedSections[commandKey] = false;
    }

    return mappedSections;
}
