/**
 * @prettier
 */
import apisauce from 'apisauce';
import moment from 'moment';
import { getJwtToken } from 'src/api/utils/getJwtToken';
import { APP_VERSION } from 'src/config/appVersion';
import { Apps } from 'src/constants/App';
import { envPIDEDIRECTO_UPLOAD_API_URL } from 'src/env/envPIDEDIRECTO_UPLOAD_API_URL';
import { getDeviceId } from 'src/services/device/getDeviceId';
import { isJestTest } from 'src/utils/environment/isJestTest';

const createPidedirectouploadApi = () => {
    const baseURL = envPIDEDIRECTO_UPLOAD_API_URL();
    const api = apisauce.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: 60000, // 60 second timeout
    });

    api.addRequestTransform((request) => {
        if (isJestTest()) {
            // TODO: try to move this logic into setupTests.js, problem is that mocking code that runs inside code rendered by
            //  @testing-library/react render function is not mockable and is ignored, so currently this is the only safe way.
            throw Error(`Your test is calling api ${baseURL}/${request.url}. Make sure to mock all api calls in your tests, see usage of mockSuccessApiResponse.ts for examples.`);
        }
        if (!request.method) return;

        if (['get', 'head', 'delete', 'link', 'unlink'].includes(request.method)) {
            request.params._deviceId = getDeviceId();
            request.params._app = Apps.PIDEDIRECTOADMIN;
            request.params._pideDirectoAdminVersion = APP_VERSION;
        } else {
            request.data._deviceId = getDeviceId();
            request.data._app = Apps.PIDEDIRECTOADMIN;
            request.data._pideDirectoAdminVersion = APP_VERSION;
        }
    });

    const isDebuggingInChrome = process.env.NODE_ENV === 'development' && !!window.navigator.userAgent; // eslint-disable-line no-undef
    if (isDebuggingInChrome) {
        api.addRequestTransform((request) => {
            console.log(`${request.method?.toUpperCase()} ${baseURL}/${request.url} @ ${moment().format('HH:mm:ss.SSS')}`, request.params ?? request.data);
        });
        api.addMonitor((response) => {
            console.log(`${response.status ?? ''} <- ${response.config?.url ?? ''} @ ${moment().format('HH:mm:ss.SSS')}`, response.data);
        });
    }

    // api.addMonitor(async (response) => {
    //     if (response.status === HttpStatus.FORBIDDEN) {
    //         await Auth.signOut();
    //     }
    // });

    const withIdToken = async (apiCall: () => any) => {
        const jwtTokenResult = await getJwtToken();
        if (jwtTokenResult.errorResponse) {
            return jwtTokenResult.errorResponse;
        }

        api.setHeaders({ authorization: jwtTokenResult.jwtToken });
        return apiCall();
    };

    return {
        config: api,
        images: {
            requestUpload: (request: any) => withIdToken(() => api.get('/images/request-upload', request)),
            upload: (request: any) => withIdToken(() => api.post('/images/upload', request)),
            migrate: (request: any) => withIdToken(() => api.post('/images/migrate', request)),
        },
        resources: {
            requestUpload: (request: any) => withIdToken(() => api.get('/resources/request-upload', request)),
            upload: (request: any) => withIdToken(() => api.post('/resources/upload', request)),
        },
    };
};

export const pidedirectouploadApi: PidedirectouploadApi = createPidedirectouploadApi();

type PidedirectouploadApi = {
    config: any;
    images: {
        requestUpload: (request?: any) => any;
        upload: (request?: any) => any;
        migrate: (request?: any) => any;
    };
    resources: {
        requestUpload: (request?: any) => any;
        upload: (request?: any) => any;
    };
};
