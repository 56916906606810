/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { CustomPaymentMethodIcon } from 'src/constants/CustomPaymentMethodIcons';
import { MexicanPaymentMethodCode } from 'src/constants/MexicanPaymentMethodCode';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { RestaurantId } from 'src/types/Id';

export async function addPaymentMethodApi(request: AddPaymentMethodApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurant/addPaymentMethodApi', request);
}

export type AddPaymentMethodApiRequest = {
    restaurantId: RestaurantId;
    name: string;
    paymentMethod: PaymentMethod;
    mexicanPaymentMethodCode?: MexicanPaymentMethodCode;
    paymentReferenceEnabled?: boolean;
    commission?: string;
    channels?: Array<App>;
    icon?: CustomPaymentMethodIcon;
};
