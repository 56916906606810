/**
 * @prettier
 */
export const CustomerActivities = {
    D1: 'D1',
    D3: 'D3',
    D5: 'D5',
    D7: 'D7',
    D10: 'D10',
    D15: 'D15',
    D30: 'D30',
    D60: 'D60',
    D90: 'D90',
} as const;

export type CustomerActivity = typeof CustomerActivities[keyof typeof CustomerActivities];
