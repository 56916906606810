/**
 * @prettier
 */
import { app2 } from 'src/app2';
import { history } from 'src/config/history';
import { PublicRoutePaths } from 'src/constants/PublicRoutePath';
import { appReducer } from 'src/reducers/appReducer';
import { SentryService } from 'src/services/SentryService';
import { useAction } from 'src/utils/react/useAction';

export function useLogout(): () => Promise<void> {
    const closeRestaurantReducer = useAction(appReducer.actions.closeRestaurant);
    const closeRestaurant = useAction(app2.actions.closeRestaurant);
    const reset = useAction(appReducer.actions.reset);

    return async () => {
        closeRestaurantReducer();
        reset();
        SentryService.clearUsername();
        closeRestaurant();
        history.push(PublicRoutePaths.HOME);
        window.location.reload();
    };
}
