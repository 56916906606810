/**
 * @prettier
 */
/* eslint-disable react/prop-types, react/jsx-handler-names */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import SelectTypeahead from 'src/components/deprecated/react-final-form/SelectTypeahead';
import { getRestaurantKitchensAndDeliveryStationsOptionsInLocalStorage } from 'src/localStorage/getRestaurantKitchensAndDeliveryStationsOptionsInLocalStorage';
import { setRestaurantKitchensAndDeliveryStationsOptionsInLocalStorage } from 'src/localStorage/setRestaurantKitchensAndDeliveryStationsOptionsInLocalStorage';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export default (props: any): React.ReactElement => {
    const [options, setOptions] = useState(getRestaurantKitchensAndDeliveryStationsOptionsInLocalStorage());

    useEffect(() => {
        async function load() {
            const response = await letseatmanagerApiDeprecated.admin.getRestaurantKitchensAndDeliveryStationsOptions();
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            setRestaurantKitchensAndDeliveryStationsOptionsInLocalStorage(response.data);
            if (cancelled) return;
            setOptions(response.data);
        }

        let cancelled = false;
        load();
        return () => {
            cancelled = true;
        };
    }, []);

    const { restaurantIds, ...restProps } = props;

    return <SelectTypeahead {...restProps} options={restaurantIds ? options.filter((o) => restaurantIds.includes(o.value)) : options} />;
};
