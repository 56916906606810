/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import type { LogEventVm } from 'src/api/letseatmanager/types/LogEventVm';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { SimpleTable } from 'src/components/SimpleTable';
import { translate } from 'src/i18n/translate';

export function DetailsDialog({ open, onClose, logEvent }: Props): React.ReactElement | null {
    const classes = useStyles();

    if (!logEvent) return null;

    const handleClose = () => {
        onClose();
    };

    const columns = [
        {
            id: 'name',
            content: translate('Name'),
            size: 1.5,
        },
        {
            id: 'value',
            content: translate('Value'),
            size: 1.5,
        },
        {
            id: 'origin',
            content: translate('Origin'),
            size: 1.5,
        },
    ];

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Details')} classes={{ dialog: classes.dialog }}>
            <p className={classes.details}>{logEvent.details}</p>
            {logEvent.relatedItems && (
                <SimpleTable
                    columns={columns}
                    rows={logEvent.relatedItems.map((relatedItem) => ({
                        name: relatedItem.name,
                        value: relatedItem.value,
                        origin: relatedItem.origin,
                    }))}
                />
            )}
            <DialogActions>
                <Button primary onClick={handleClose}>
                    {translate('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        minWidth: '20%',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        },
    },
    details: {
        padding: '24px 42px',
        textAlign: 'center',
    },
}));

type Props = {
    open: boolean;
    logEvent?: LogEventVm;
    onClose: any;
};
