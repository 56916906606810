/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function ConfirmDisintegrateFromPedidosYaDialog({ open, onClose, disabled }: Props): React.ReactElement {
    const classes = useStyles();

    const viewUser = useSelector((state) => state.authentication.viewUser);

    return (
        <Dialog open={open} title={translate('Confirm')}>
            <Text className={classes.text}>{translate('Contact your vendor or support in order do desintegrate Pedidos Ya')}</Text>
            <DialogActions className={classes.buttonContainer}>
                <Button disabled={disabled || viewUser} onClick={onClose} primary>
                    {translate('Ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 12,
    },
    text: {
        marginTop: 24,
        marginBottom: 24,
        fontSize: 16,
    },
}));

type Props = {
    open: boolean;
    disabled: boolean;
    onClose: any;
};
