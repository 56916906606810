/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { ChangeRestaurantZoneDialog } from 'src/scenes/letseatmanager/posRestaurantZones/ChangeRestaurantZoneDialog';
import { CreateRestaurantZoneDialog } from 'src/scenes/letseatmanager/posRestaurantZones/CreateRestaurantZoneDialog';
import { CreateTableDialog } from 'src/scenes/letseatmanager/posRestaurantZones/CreateTableDialog';
import { ManageMenu } from 'src/scenes/letseatmanager/posRestaurantZones/ManageMenu';
import { RemoveRestaurantZoneDialog } from 'src/scenes/letseatmanager/posRestaurantZones/RemoveRestaurantZoneDialog';
import { TableVm } from 'src/types/RestaurantZoneVm';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function EditRestaurantZonesMenu({ onSuccess, onSave, onCancel, onAddTable }: Props): React.ReactElement {
    const classes = useStyles();
    const restaurantZone = useSelector((state) => state.pos.restaurantZone);
    const restaurantZoneId = useSelector((state) => state.pos.changeRestaurantZoneDialog.restaurantZoneId);

    const closeCreateRestaurantZoneDialog = useAction(posReducer.actions.closeCreateRestaurantZoneDialog);
    const closeCreateTableDialog = useAction(posReducer.actions.closeCreateTableDialog);
    const closeChangeRestaurantZoneDialog = useAction(posReducer.actions.closeChangeRestaurantZoneDialog);
    const closeRemoveRestaurantZoneDialog = useAction(posReducer.actions.closeRemoveRestaurantZoneDialog);
    const openCreateRestaurantZoneDialog = useAction(posReducer.actions.openCreateRestaurantZoneDialog);
    const openCreateTableDialog = useAction(posReducer.actions.openCreateTableDialog);
    const openChangeRestaurantZoneDialog = useAction(posReducer.actions.openChangeRestaurantZoneDialog);
    const openRemoveRestaurantZoneDialog = useAction(posReducer.actions.openRemoveRestaurantZoneDialog);

    const changeRestaurantZoneDialogSucceeded = () => {
        closeChangeRestaurantZoneDialog();
        onSuccess();
    };

    const createRestaurantZoneDialogSucceeded = () => {
        closeCreateRestaurantZoneDialog();
        onSuccess();
    };

    const removeRestaurantZoneDialogSucceeded = () => {
        closeRemoveRestaurantZoneDialog();
        onSuccess();
    };

    const createTableDialogSucceeded = (table: any) => {
        if (restaurantZone?.tables?.some((_table: TableVm) => _table.tableNumber === table.tableNumber)) {
            return alert(translate('There is already a table with the same number, please change it.'));
        }
        const request = {
            tableNumber: table.tableNumber,
            tableSize: table.tableSize,
            tableShape: table.tableShape,
            top: 0,
            left: 0,
            rotation: 0,
        } as const;
        onAddTable(request);
        closeCreateTableDialog();
    };

    return (
        <div className={classes.container}>
            <CreateRestaurantZoneDialog />
            {restaurantZoneId && <ChangeRestaurantZoneDialog />}
            <CreateTableDialog />
            <RemoveRestaurantZoneDialog />
            <ManageMenu
                onClickCreate={() => {
                    openCreateRestaurantZoneDialog({ onSuccess: createRestaurantZoneDialogSucceeded });
                }}
                onClickCreateTable={() => {
                    openCreateTableDialog({ restaurantZoneId: restaurantZone?.restaurantZoneId, onSuccess: createTableDialogSucceeded });
                }}
                onClickChangeZone={() => {
                    openChangeRestaurantZoneDialog({ restaurantZoneId: restaurantZone?.restaurantZoneId, onSuccess: changeRestaurantZoneDialogSucceeded });
                }}
                onClickRemoveZone={() => {
                    openRemoveRestaurantZoneDialog({ restaurantZoneId: restaurantZone?.restaurantZoneId, onSuccess: removeRestaurantZoneDialogSucceeded });
                }}
                onClickSaveChanges={onSave}
                onClickCancel={onCancel}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: 10,
        display: 'inline-block',
    },
}));

type Props = {
    onSuccess: any;
    onSave: any;
    onCancel: any;
    onAddTable: any;
};
