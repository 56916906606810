/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderId, RestaurantZoneId } from 'src/types/Id';

export async function updateTableOrderApi(request: UpdateTableOrderApi): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('order/updateTableOrderApi', request);
}

export type UpdateTableOrderApi = {
    orderId: OrderId;
    restaurantZoneId: RestaurantZoneId;
    table: string;
};
