/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import type { ModifierVm } from 'src/types/OrderVm';

export function OrderModifier({ modifier, showModifierGroupNameInCommand }: Props): React.ReactElement {
    const classes = useStyles();

    const subModifierGroups = modifier.subModifierGroups ?? [];

    const subModifiers = subModifierGroups.reduce<Array<any>>((subModifiers, subModifierGroup) => {
        if (!subModifierGroup.subModifiers || subModifierGroup.subModifiers.length === 0) {
            return subModifiers;
        }
        subModifiers.push(...subModifierGroup.subModifiers);
        return subModifiers;
    }, []);

    const subModifiersFiltered = subModifiers.filter((subModifier) => subModifier.quantity > 0);

    const allSubModifiersHasQuantityEqualToOne = subModifiersFiltered.every((subModifier) => subModifier.quantity === 1);

    return (
        <Typography className={classes.modifier}>
            {`${showModifierGroupNameInCommand ? '* ' : '·'}${modifier.name} ${modifier.quantity === 1 ? '' : 'x' + modifier.quantity}`}
            {subModifiersFiltered.map((subModifier, idx) => (
                <Typography key={idx} className={classes.modifier}>
                    ·{subModifier.name} {allSubModifiersHasQuantityEqualToOne ? '' : 'x' + subModifier.quantity}
                </Typography>
            ))}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    quantity: {
        fontSize: '1.6rem',
        marginRight: 6,
    },
    name: {
        fontFamily: theme.typography.regular,
        fontSize: 16,
    },
    modifier: {
        fontFamily: theme.typography.regular,
        fontStyle: 'italic',
        paddingLeft: 31,
        fontSize: 14,
    },
}));

type Props = {
    modifier: ModifierVm;
    showModifierGroupNameInCommand?: boolean;
    showQuantity?: boolean;
};
