/**
 * @prettier
 */
import type { RRule } from 'rrule';

export function nextFiveRRuleDates(value?: RRule): Array<Date> {
    if (!value) {
        return [];
    }
    const now = new Date();
    now.setMilliseconds(0); // RRule do not set millis
    let count = 0;
    return value
        .all(function (date, i) {
            if (date >= now) count++;
            return count <= 5;
        })
        .filter((date) => date >= now);
}
