/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormDriverPrintersSelect } from 'src/components/form/FormDriverPrintersSelect';
import { CreatePrinterSteps } from 'src/constants/CreatePrinterStep';
import { translate } from 'src/i18n/translate';
import { PrinterWizardContext, PrinterWizardProviderValues } from 'src/providers/PrinterWizardProvider';

export function DownloadDrivers(): React.ReactElement | null {
    const classes = useStyles();

    const { formValues, goNextWizardStep, goBackWizardStep, setCreatePrinterWizardFormValue, activeCreatePrinterStep } = useContext<PrinterWizardProviderValues>(PrinterWizardContext);

    const form = useForm();
    const { control } = form;

    const driverPrinterSelected = useWatch({ name: 'driverPrinterSelected', control });

    const disabled = !driverPrinterSelected || driverPrinterSelected === '';

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleChangeDriversSelection = (externalPrinterId: any) => {
        setCreatePrinterWizardFormValue('externalPrinterId', externalPrinterId);
        goNextWizardStep();
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && !disabled) {
            handleChangeDriversSelection(driverPrinterSelected);
        }
    };

    const handleSubmit = async (form: any) => {
        handleChangeDriversSelection(form.driverPrinterSelected);
    };

    const handleDriverDownload = () => {
        window.open('https://habitue-img.s3.sa-east-1.amazonaws.com/driver+instalator.zip');
    };

    if (activeCreatePrinterStep !== CreatePrinterSteps.DOWNLOAD_DRIVERS) return null;

    return (
        <Form form={form} onSubmit={handleSubmit} className={classes.form}>
            <p className={classes.label}>{translate('Download Drivers')}</p>
            <FormDriverPrintersSelect name='driverPrinterSelected' label={translate('Selected printer name:')} defaultValue={formValues.externalPrinterId} />
            <Button secondary onClick={handleDriverDownload} classes={{ button: classes.secondaryButton }}>
                {translate('Download Drivers')}
            </Button>
            <div className={classes.actionsContainer}>
                <Button secondary onClick={goBackWizardStep} type={'button'}>
                    {translate('Back')}
                </Button>
                <Button primary type={'submit'} disabled={disabled}>
                    {translate('Next')}
                </Button>
                <p className={classes.enterText}>{translate('hit Enter ↩')}</p>
            </div>
        </Form>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        animation: '$appear 700ms ease',
    },
    helperText: {
        color: '#565B69',
        fontSize: 12,
        fontFamily: theme.typography.regular,
    },
    secondaryButton: {
        marginTop: 12,
        alignSelf: 'end',
    },
    label: {
        fontSize: 16,
        fontFamily: theme.typography.regular,
        color: '#0D3037',
        padding: 0,
        margin: 0,
        marginBottom: 12,
    },
    actionsContainer: {
        display: 'flex',
        gap: 12,
        marginTop: 24,
        width: '100%',
        justifyContent: 'flex-end',
    },
    enterText: {
        fontFamily: theme.typography.regular,
        color: '#0D3037',
    },
    '@keyframes appear': {
        '0%': {
            opacity: 0,
            transform: 'translateY(100px)',
        },
        '100%': {
            opacity: 1,
            transform: 'translateY(0px)',
        },
    },
}));
