/**
 * @prettier
 */
import { PosBusinessDaySections } from 'src/constants/PosBusinessDaySections';
import { PrinterInstructionsBuilder } from 'src/services/printer/prints/utils/printerInstructionsBuilder';
import type { RestaurantVm } from 'src/types/RestaurantVm';

export function addRestaurantInfoToReport(restaurant: RestaurantVm, builder: PrinterInstructionsBuilder): void {
    if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.RESTAURANT_NAME)) builder.addCenteredBoldText(restaurant.name);

    if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.BUSINESS_NAME) && restaurant.businessName) {
        builder.addCenteredText(restaurant.businessName);
    }
    if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.RFC) && restaurant.rfc) {
        builder.addCenteredText(restaurant.rfc);
    }
    if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.RESTAURANT_STREET) && restaurant.restaurantStreet) {
        builder.addCenteredText(restaurant.restaurantStreet);
    }
}
