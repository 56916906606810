/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { createBannerApi } from 'src/api/letseatmanager/banner/createBannerApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormImageUpload } from 'src/components/form/FormImageUpload';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormPromoCodeSelect } from 'src/components/select/promoCode/FormPromoCodeSelect';
import { App, Apps } from 'src/constants/App';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';
import { hours } from 'src/utils/Validator';

export function CreateBannerDialog({ open, onClose, load }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
        control,
    } = form;
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);

    const imageUrl = useWatch<string>({ name: 'imageUrl', control });
    const ecommerceEnable = useWatch({ name: 'ecommerceEnable', control });
    const wlaEnabled = useWatch<boolean>({ name: 'wlaEnabled', control });

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        const channels: Array<App> = [];
        if (form.ecommerceEnable) channels.push(Apps.PIDEDIRECTO);
        if (form.wlaEnabled) channels.push(Apps.PIDEDIRECTOAPP);
        setLoading(true);
        const response = await createBannerApi({
            restaurantId,
            hours: form.hours,
            imageUrl: form.imageUrl,
            enabled: form.enabled,
            promoCode: form.promoCode,
            pinned: form.pinned,
            channels: channels,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onClose();
        load();
    };

    return (
        <Dialog title={translate('Create Banner')} open={open} onClose={handleClose} classes={{ dialog: classes.dialog }}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form} className={classes.form}>
                <div className={classes.dialogContent}>
                    {/* @ts-ignore */}
                    <Grid container spacing={3}>
                        <Grid item xs={12} className={classes.container}>
                            <div className={classes.previewBanner}>{imageUrl && <img className={classes.bannerImg} src={`${imageUrl}`} alt={`Banner to show drivers`} />}</div>
                            <div className={classes.input}>
                                <FormImageUpload
                                    name={'imageUrl'}
                                    message={translate('or drag here the image of your product. SVG, PNG or JPG (max 300x600px)')}
                                    classes={{ imagePreview: classes.imagePreview, label: classes.label }}
                                />
                            </div>
                        </Grid>
                        {wlaEnabled && (
                            <Grid item xs={12}>
                                <FormTextField
                                    name='hours'
                                    label={translate('Hours')}
                                    helperText={`${translate('E.g. "Mo-Fr 08:00-09:00", "Sa-Su" or "Mar Mo-Fr 11:00-14:00". see https://openingh.openstreetmap.de/evaluation_tool/#check')}`}
                                    placeholder={'24/7'}
                                    disabled={loading}
                                    validate={hours}
                                />
                            </Grid>
                        )}
                        {ecommerceEnable && (
                            <Grid item xs={12}>
                                <FormPromoCodeSelect name='promoCode' label={translate('Promo code')} disabled={loading} restaurantIds={restaurantIds} />
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <FormSwitch name='ecommerceEnable' label={translate('For Ecommerce')} />
                            <FormSwitch name='wlaEnabled' label={translate('For WLA')} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormSwitch name='enabled' label={translate('Enabled')} />
                            {wlaEnabled && <FormSwitch name='pinned' label={translate('Pinned')} />}
                        </Grid>
                    </Grid>
                    <FormFixErrorHint />
                </div>
                <DialogActions>
                    <Button outlined onClick={handleClose} disabled={isSubmitting || loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={isSubmitting || loading}>
                        {isSubmitting || loading ? translate('Creating') : translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '70%',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '40%',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    dialogContent: {
        width: '80%',
        [theme.breakpoints.down('md')]: {
            width: '70%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    previewBanner: {
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
    },
    bannerImg: {
        borderRadius: '12px',
        objectFit: 'cover',
    },
    imagePreview: {
        display: 'none',
    },
    label: {
        fontFamily: theme.typography.regular,
        color: theme.palette.secondary.contrastText,
        fontSize: 14,
        marginBottom: 8,
    },
    input: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    warningMessage: {
        fontFamily: theme.typography.regular,
        color: '#F44336',
        textAlign: 'left',
        fontSize: 12,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    load: any;
};
