/**
 * @prettier
 */
import { withdrawCashRegisterApi } from 'src/api/letseatmanager/pos/withdrawCashRegisterApi';
import { getPosWithdrawsOfflineInLocalStorage, PosWithdrawOffline } from 'src/localStorage/getPosWithdrawsOfflineInLocalStorage';
import { setPosWithdrawsOfflineInLocalStorage } from 'src/localStorage/setPosWithdrawsOfflineInLocalStorage';

export async function tryToSavePosWithdrawsCreatedOffline() {
    const posWithdrawsOffline = getPosWithdrawsOfflineInLocalStorage();
    for (const posWithdrawOffline of posWithdrawsOffline) {
        await saveWithdraw(posWithdrawOffline);
    }
}

async function saveWithdraw(request: PosWithdrawOffline) {
    const response = await withdrawCashRegisterApi(request as any);
    if (!response.ok) return;
    savedPosWithdrawOffline(request);
}

function savedPosWithdrawOffline(request: PosWithdrawOffline) {
    const posWithdrawsOffline = getPosWithdrawsOfflineInLocalStorage();
    setPosWithdrawsOfflineInLocalStorage(posWithdrawsOffline.filter((posWithdrawsOffline) => posWithdrawsOffline.temporalCashRegisterTransactionId !== request.temporalCashRegisterTransactionId));
}
