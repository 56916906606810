/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RecipeId, RestaurantId } from 'src/types/Id';
import type { RecipeVm } from 'src/types/RecipeVm';

export async function getRecipeApi(request: GetRecipeApiRequest): ApiSauceResponse<RecipeVm> {
    return letseatmanagerApiMethod('recipe/getRecipeApi', request);
}

export type GetRecipeApiRequest = {
    recipeId: RecipeId;
    restaurantId: RestaurantId;
};
