/**
 * @prettier
 */
import * as React from 'react';

export function ClipboardIcon({ title, color, size }: Props): React.ReactElement {
    return (
        <svg width={size ?? 21} height={size ?? 21} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                d='M6.66659 13.3333H4.99992C4.55789 13.3333 4.13397 13.1577 3.82141 12.8452C3.50885 12.5326 3.33325 12.1087 3.33325 11.6667V5.00001C3.33325 4.55798 3.50885 4.13406 3.82141 3.8215C4.13397 3.50894 4.55789 3.33334 4.99992 3.33334H11.6666C12.1086 3.33334 12.5325 3.50894 12.8451 3.8215C13.1577 4.13406 13.3333 4.55798 13.3333 5.00001V6.66668M8.33325 16.6667H14.9999C15.4419 16.6667 15.8659 16.4911 16.1784 16.1785C16.491 15.866 16.6666 15.442 16.6666 15V8.33334C16.6666 7.89132 16.491 7.46739 16.1784 7.15483C15.8659 6.84227 15.4419 6.66668 14.9999 6.66668H8.33325C7.89122 6.66668 7.4673 6.84227 7.15474 7.15483C6.84218 7.46739 6.66659 7.89132 6.66659 8.33334V15C6.66659 15.442 6.84218 15.866 7.15474 16.1785C7.4673 16.4911 7.89122 16.6667 8.33325 16.6667Z'
                stroke={color ?? 'currentColor'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    color?: string;
    size?: number;
};
