/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { VirtualizedList } from '@pidedirecto/ui';
import { useElementSize } from '@pidedirecto/ui/hooks';
import { useRef, useState } from 'react';
import * as React from 'react';
import { OrderDetailsDialog } from 'src/components/orderCard/OrderDetailsDialog';
import { translate } from 'src/i18n/translate';
import { PosPendingOrderCard } from 'src/scenes/letseatmanager/pos/posOrderTypeSelect/PosPendingOrderCard';
import { useIsOrderVisibleForUser } from 'src/services/pos/useIsOrderVisibleForUser';
import { OrderVm } from 'src/types/OrderVm';
import { useSelector } from 'src/utils/react/useSelector';

export function PosPendingOrders(): React.ReactElement | null {
    const classes = useStyles();
    const isOrderVisibleForUser = useIsOrderVisibleForUser();

    const containerRef = useRef<HTMLDivElement | null>(null);

    const { width, height } = useElementSize(containerRef);

    const [orderDetailsDialogState, setOrderDetailsDialogState] = useState({ open: false, pendingOrder: undefined });

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const currentOrders = useSelector((state) => state.app2.currentOrders);

    const pendingOrders = currentOrders?.filter((order: OrderVm) => order.restaurant?.restaurantId === restaurantId);
    const filteredOrders = pendingOrders?.filter(isOrderVisibleForUser);

    const changeOrderDetailsDialogState = (state: any) => setOrderDetailsDialogState(state);

    if (!pendingOrders.length) return null;

    return (
        <div className={classes.container}>
            {!!orderDetailsDialogState.pendingOrder && (
                <OrderDetailsDialog
                    open={orderDetailsDialogState.open}
                    order={orderDetailsDialogState.pendingOrder}
                    onClose={() => setOrderDetailsDialogState({ open: false, pendingOrder: undefined })}
                />
            )}
            <h2 aria-label={'Ongoing Orders List'} className={classes.subtitle}>
                {translate('Ongoing Orders')}
            </h2>
            <div ref={containerRef} className={classes.pendingOrdersContainer}>
                <VirtualizedList itemSize={340} height={340} orientation={'horizontal'} width={width}>
                    {filteredOrders.map((posPendingOrder, idx) => {
                        return <PosPendingOrderCard key={`${posPendingOrder.orderId}-${idx}`} pendingOrder={posPendingOrder} changeOrderDetailsDialogState={changeOrderDetailsDialogState} />;
                    })}
                </VirtualizedList>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        transition: 'width 0.4s linear',
        width: '100%',
        position: 'relative',
        marginBottom: 100,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 50,
            marginTop: 70,
        },
    },
    pendingOrdersContainer: {
        display: 'flex',
        width: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
    },
    subtitle: {
        color: '#2C374A',
        fontFamily: theme.typography.regular,
        fontSize: 18,
        margin: '0px 0px 10px 0px',
        [theme.breakpoints.up('sm')]: {
            fontSize: 24,
        },
    },

    closePosPendingOrdersIcon: {
        position: 'absolute',
        top: '-20px',
        left: '-30px',
        backgroundColor: 'rgba(0,0,0, 0.05)',
    },
}));
