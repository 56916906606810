/**
 * @prettier
 */
import { ResponsiveTreeMap } from '@nivo/treemap';
import * as React from 'react';

export function TreeMapCanvas({ data }: Props): React.ReactElement {
    const colors = ['#2476C2', '#53C7D7', '#5DE3BC', '#24A8C2', '#13647D', '#0DFFD3', '#A0D8F8', '#A7A0F8', '#6256E9'];
    return (
        <ResponsiveTreeMap
            data={{
                id: 'treemap',
                children: data,
            }}
            leavesOnly={true}
            colors={colors}
        />
    );
}

type Props = {
    data: Array<{
        id: string;
        value: string;
    }>;
};
