/**
 * @prettier
 */
import { App, Apps } from 'src/constants/App';
import { useSelector } from 'src/utils/react/useSelector';

export function useGetRestaurantChannels(): Array<App> {
    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);
    const kioskEnabled = useSelector((state) => state.app.restaurant?.kioskEnabled);
    const whiteLabelOrdersEnabled = useSelector((state) => state.app.restaurant?.whiteLabelOrdersEnabled);
    const pedidosYaRestaurantId = useSelector((state) => state.app.restaurant?.pedidosYaRestaurantId);
    const restaurant = useSelector((state) => state.app.restaurant);

    const newChannels: Array<App> = [Apps.PIDEDIRECTO];

    if (posEnabled && !newChannels.includes(Apps.PIDEDIRECTOPOS)) newChannels.push(Apps.PIDEDIRECTOPOS);
    if (kioskEnabled && !newChannels.includes(Apps.PIDEDIRECTOKIOSK)) newChannels.push(Apps.PIDEDIRECTOKIOSK);
    if (whiteLabelOrdersEnabled && !newChannels.includes(Apps.PIDEDIRECTOAPP)) newChannels.push(Apps.PIDEDIRECTOAPP);
    if (pedidosYaRestaurantId && !newChannels.includes(Apps.PEDIDOS_YA)) newChannels.push(Apps.PEDIDOS_YA);
    if (restaurant?.uberEatsStoreId && !newChannels.includes(Apps.UBER_EATS)) newChannels.push(Apps.UBER_EATS);
    if (restaurant?.rappiStoreId && !newChannels.includes(Apps.RAPPI)) newChannels.push(Apps.RAPPI);
    if (restaurant?.didiFoodStoreId && !newChannels.includes(Apps.DIDI_FOOD)) newChannels.push(Apps.DIDI_FOOD);

    return newChannels;
}
