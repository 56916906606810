/**
 * @prettier
 */
import { makeStyles, Radio } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { InitialRoutePaths } from 'src/constants/InitialRoutePaths';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function CashRegistersAccessConfiguration({ disabled }: Props): React.ReactElement {
    const classes = useStyles();
    const { watch, setValue } = useFormContext();

    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);

    const initialPage = watch('initialPage');
    const openPersonalCashRegisterEnabled = watch(RolePermissions.OPEN_PERSONAL_CASH_REGISTER);
    const manageCashRegistersEnabled = watch(RolePermissions.MANAGE_CASH_REGISTERS);

    const cashRegisterPosBusinessDayPermissionsDisabled = !posMultipleCashRegistersEnabled || disabled;
    const handleRadioButton = () => setValue('initialPage', InitialRoutePaths.POS_CASH_REGISTERS);

    useEffect(() => {
        if (openPersonalCashRegisterEnabled) return;

        setValue(RolePermissions.VIEW_ONLY_ORDERS_IN_PERSONAL_CASH_REGISTER, false);
    }, [openPersonalCashRegisterEnabled]);

    return (
        <div className={classes.container}>
            <div className={classes.moduleContainer}>{translate('Cash registers')}</div>
            <div className={classes.permissionsContainer}>
                <FormSwitch name={RolePermissions.CASH_REGISTER_PAGE} label={translate('RolePermissions.CASH_REGISTER_PAGE')} disabled={disabled} />
                <FormCheckbox name={RolePermissions.OPEN_CASH_REGISTER} label={translate('RolePermissions.OPEN_CASH_REGISTER')} disabled={disabled} />
                <FormCheckbox name={RolePermissions.CHARGE_ORDERS} label={translate('RolePermissions.CHARGE_ORDERS')} disabled={disabled} />
                <FormCheckbox name={RolePermissions.DEPOSIT_CASH_REGISTER} label={translate('RolePermissions.DEPOSIT_CASH_REGISTER')} disabled={disabled} />
                <FormCheckbox name={RolePermissions.WITHDRAW_CASH_REGISTER} label={translate('RolePermissions.WITHDRAW_CASH_REGISTER')} disabled={disabled} />
                <FormCheckbox name={RolePermissions.CASH_REGISTER_SHIFT} label={translate('RolePermissions.CASH_REGISTER_SHIFT')} disabled={disabled} />
                <FormCheckbox name={RolePermissions.CASH_REGISTER_CLOSE} label={translate('RolePermissions.CASH_REGISTER_CLOSE')} disabled={disabled} />
                <FormCheckbox name={RolePermissions.PRINT_PARTIAL_SHIFT_CASH_REGISTER_REPORT} label={translate('RolePermissions.PRINT_PARTIAL_SHIFT_CASH_REGISTER_REPORT')} disabled={disabled} />
                <FormCheckbox
                    disabled={cashRegisterPosBusinessDayPermissionsDisabled || manageCashRegistersEnabled}
                    name={RolePermissions.OPEN_PERSONAL_CASH_REGISTER}
                    label={translate('RolePermissions.OPEN_PERSONAL_CASH_REGISTER')}
                />
                <FormCheckbox
                    disabled={cashRegisterPosBusinessDayPermissionsDisabled || manageCashRegistersEnabled || !openPersonalCashRegisterEnabled}
                    name={RolePermissions.VIEW_ONLY_ORDERS_IN_PERSONAL_CASH_REGISTER}
                    label={translate('RolePermissions.VIEW_ONLY_ORDERS_IN_PERSONAL_CASH_REGISTER')}
                />
                <FormCheckbox
                    disabled={openPersonalCashRegisterEnabled || cashRegisterPosBusinessDayPermissionsDisabled}
                    name={RolePermissions.MANAGE_CASH_REGISTERS}
                    label={translate('RolePermissions.MANAGE_CASH_REGISTERS')}
                />
                <FormCheckbox name={RolePermissions.BLIND_CASH_REGISTER_SHIFT_FOR_CASH_ONLY} label={translate('RolePermissions.BLIND_CASH_REGISTER_SHIFT_FOR_CASH_ONLY')} disabled={disabled} />
                <FormCheckbox name={RolePermissions.BLIND_CASH_REGISTER_SHIFT} label={translate('RolePermissions.BLIND_CASH_REGISTER_SHIFT')} disabled={disabled} />
            </div>
            <div className={classes.homeContainer}>
                <Radio
                    name='initialPageCashRegister'
                    classes={{ checked: classes.radioButton }}
                    checked={initialPage === InitialRoutePaths.POS_CASH_REGISTERS}
                    value={InitialRoutePaths.POS_CASH_REGISTERS}
                    onChange={handleRadioButton}
                    disabled={disabled}
                />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        borderBottom: `1px solid #c8c8c8`,
    },
    moduleContainer: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '25%',
    },
    permissionsContainer: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
    },
    homeContainer: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '25%',
    },
    radioButton: {
        color: `${theme.palette.primary.main} !important`,
    },
}));

type Props = {
    disabled?: boolean;
};
