/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeRestaurantReferralResponsibleApi } from 'src/api/letseatadmin/restaurantReferral/changeRestaurantReferralResponsibleApi';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import type { RestaurantReferralId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function ChangeRestaurantReferralsDialog({ open, restaurantReferralId, handleClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (form: any) => {
        setLoading(true);
        const response = await changeRestaurantReferralResponsibleApi({
            restaurantReferralId,
            responsible: form.responsible,
        });
        setLoading(false);
        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        handleClose();
        onSuccess();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose}>
            <UpdatingContentProgress loading={loading} />
            <Form onSubmit={handleSubmit} form={form} className={classes.form}>
                <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Change Restaurant Referral')}</DialogTitle>
                <DialogContent style={{ overflow: 'hidden' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField name='responsible' label={translate('Responsible')} required disabled={loading} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ marginTop: 20, marginBottom: 20 }} className={classes.buttonsContainer}>
                    <Button onClick={handleClose} disabled={loading}>
                        <div className={classes.cancelButton}>{translate('Cancel')}</div>
                    </Button>
                    <Button color='primary' type='submit' disabled={loading} classes={{ root: classes.noMargin }}>
                        <div className={classes.acceptButton}>{loading ? translate('Changing') : translate('Change')}</div>
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    noMargin: {
        margin: '0 !important',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    menu: {
        fontFamily: theme.typography.medium,
    },
}));

type Props = {
    open: boolean;
    restaurantReferralId?: RestaurantReferralId;
    handleClose: any;
    onSuccess: any;
};
