/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { createFaqApi } from 'src/api/letseatadmin/faq/createFaqApi';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormTextField } from 'src/components/form/FormTextField';
import ImageUploader from 'src/components/input/ImageUploader';
import { ImageTypes } from 'src/constants/ImageType';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { https } from 'src/utils/Validator';

export default function CreateFaqDialog({ open, onClose, load }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
        setValue,
    } = form;

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);

    const onSubmit = async (form: any) => {
        setLoading(true);
        const response = await createFaqApi({
            answer: form.answer,
            imageUrl: form.imageUrl,
            question: form.question,
            position: form.position,
            articleUrl: form.articleUrl,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onClose();
        load();
    };

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} classes={{ paper: classes.paper }}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form} className={classes.form}>
                <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Create Faq Template').toUpperCase()}</DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField name='question' label={translate('Question')} helperText={`${translate('Question')}`} placeholder={'Question'} disabled={loading} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                name='answer'
                                multiline
                                classes={{ input: classes.inputDescription, error: {} }}
                                label={translate('Answer')}
                                helperText={`${translate('Answer')}`}
                                placeholder={'Answer'}
                                disabled={loading}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField name='position' label={translate('On Which position should it appear')} disabled={loading} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item>
                            <FormTextField name='articleUrl' label={translate('Article Url')} disabled={loading} validate={https} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item>
                            <FormTextField
                                name='imageUrl'
                                label={uploadingImage ? translate('Uploading Image...') : translate('Image')}
                                helperText={uploadingImage ? undefined : translate('Enter an URL to an image or drag and drop your image anywhere in the browser')}
                                disabled={loading}
                                validate={https}
                            />
                            <ImageUploader
                                imageType={ImageTypes.BANNER}
                                onUploadingChanged={(uploadingImage) => setUploadingImage(uploadingImage)}
                                onUploadSuccess={(imageUrl) => setValue('imageUrl', imageUrl)}
                            />
                        </Grid>
                    </Grid>
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions className={classes.buttonsContainer}>
                    <Button onClick={handleClose} disabled={loading}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={loading}>
                        {loading ? translate('Creating').toUpperCase() : translate('Create').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '90vh',
    },
    inputDescription: {
        height: '42vh',
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    paper: {
        [theme.breakpoints.down('md')]: {
            width: '80%',
            maxWidth: '80%',
        },
    },
    dialogContent: {
        width: '80%',
        [theme.breakpoints.down('md')]: {
            width: '70%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    buttonsContainer: {
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: 20,
        },
    },
    noMargin: {
        margin: '0 !important',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    load: any;
};
