/**
 * @prettier
 */
export const CustomPaymentMethodIcons = {
    CHAIN: 'CHAIN',
    CELL_PHONE: 'CELL_PHONE',
    ARROW_RIGHT_LEFT: 'ARROW_RIGHT_LEFT',
    WALLET: 'WALLET',
    DOOR_HANGER: 'DOOR_HANGER',
    SHEET: 'SHEET',
    CREDIT_CARD: 'CREDIT_CARD',
    CASH: 'CASH',
    COINS: 'COINS',
    BANK: 'BANK',
} as const;

export type CustomPaymentMethodIcon = typeof CustomPaymentMethodIcons[keyof typeof CustomPaymentMethodIcons];
