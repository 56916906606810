/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesType } from 'src/constants/SalesTypes';
import type { RestaurantId } from 'src/types/Id';

export async function getMenuRestaurantStatisticsApi(request: GetMenuRestaurantStatisticsRequest): ApiSauceResponse<GetMenuReportsStatisticsApiResponse> {
    return pidedirectoReportsMethod('/menuPageReportApi', request);
}

export type GetMenuRestaurantStatisticsRequest = {
    report: string;
    reportPage?: string;
    abcTableFilter?: string;
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
    salesType: SalesType;
};

export type GetMenuReportsStatisticsApiResponse = Array<{
    name: string;
    restaurantId: RestaurantId;
    salesByMenuCategory: Array<SalesByMenuCategory>;
    salesByMenuCategoryFamily: Array<SalesByMenuCategoryFamily>;
    salesByItem: Array<SalesByMenuItem>;
    salesByModifier: Array<SalesByModifier>;
}>;

export type SalesByMenuCategory = {
    amount: number;
    categoryType: string;
    menuCategoryFamilyName?: string;
    discount: number;
    total: number;
    percentageOfSales: number;
    orderVolume: number;
};

export type SalesByMenuItem = {
    itemName: string;
    menuCategoryFamilyName?: string;
    itemPrice: number;
    orderVolume: number;
    percentageOfSales: number;
    total: number;
    totalSalesItemPrice: number;
    menuCategory: string;
};

export type SalesByModifier = {
    modifiers: string;
    modifierPrice: number;
    orderVolume: number;
    percentageOfSales: number;
    total: number;
};

export type SalesByMenuCategoryFamily = {
    menuCategoryFamilyName: string;
    orderVolume: number;
    percentageOfSales: number;
    total: number;
};
