/**
 * @prettier
 */
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function alertResponseWithAlertKnowErrorOrSomethingWentWrong(response: any, message?: string): void {
    if (response.ok) {
        alert(translate(`${!!message ? `${message}\n` : ''}${translate(response.data)}`));
        return;
    }
    alertKnownErrorOrSomethingWentWrong(response);
}
