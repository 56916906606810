/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { InventoryReportId, RestaurantId } from 'src/types/Id';

export async function findInventoryTransactionReportsApi(request: FindInventoryReportsApiRequest): ApiSauceResponse<Array<InventoryTransactionReportVm>> {
    return letseatmanagerApiMethod('inventory/findInventoryTransactionReportsApi', request);
}

type FindInventoryReportsApiRequest = {
    restaurantId: RestaurantId;
};

type InventoryTransactionReportVm = {
    inventoryTransactionReportId: InventoryReportId;
    restaurantId: RestaurantId;
    createdBy: string;
    createdAt: Date;
};
