/**
 * @prettier
 */
import * as React from 'react';
import { useState } from 'react';
import { removeMenuItemApi } from 'src/api/letseatmanager/menuItem/removeMenuItemApi';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function RemoveMenuItemDialog({ open, menuItem, onClose, onSuccess }: Props): React.ReactElement {
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const removeMenuItem = async () => {
        if (!menuItem?.menuItemId) return;

        setLoading(true);
        const response = await removeMenuItemApi({
            menuItemId: menuItem?.menuItemId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
    };

    return (
        <RemoveDialog
            open={open}
            title={translate('Remove menu item')}
            subtitle={translate('You are about to remove the menu item @menuItemName, are you sure you want to continue?', { menuItemName: menuItem?.name })}
            onSubmit={removeMenuItem}
            onClose={handleClose}
            disabled={loading}
        />
    );
}

type Props = {
    open: boolean;
    menuItem?: any;
    onClose: any;
    onSuccess?: any;
};
