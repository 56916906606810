/**
 * @prettier
 */
import { Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createMenuApi } from 'src/api/letseatmanager/menu/createMenuApi';
import { Button } from 'src/components/Button';
import { Channels } from 'src/components/Channels';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { App, Apps } from 'src/constants/App';
import { translate } from 'src/i18n/translate';
import { useMenus } from 'src/services/menu/useMenus';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';
import { hours } from 'src/utils/Validator';

export function CreateMenu({ onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [channels, setChannels] = useState([]);
    const [disabledChannels, setDisabledChannels] = useState<Array<App>>([]);
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurant = useSelector((state) => state.app.restaurant);
    const internalUser = useSelector((state) => state.authentication.internalUser);
    const { menus } = useMenus();

    const hasRappiMenu = menus.some((menu) => menu.channels?.includes(Apps.RAPPI));
    const hasDidiMenu = menus.some((menu) => menu.channels?.includes(Apps.DIDI_FOOD));

    useEffect(() => {
        const disabledChannels: Array<App> = [];
        if (hasDidiMenu) disabledChannels.push(Apps.DIDI_FOOD);
        if (hasRappiMenu) disabledChannels.push(Apps.RAPPI);
        if (!restaurant.uberEatsStoreId) disabledChannels.push(Apps.UBER_EATS);
        if (!restaurant.rappiStoreId) disabledChannels.push(Apps.RAPPI);
        if (!restaurant.didiFoodStoreId) disabledChannels.push(Apps.DIDI_FOOD);

        setDisabledChannels(disabledChannels);
    }, [hasRappiMenu, hasDidiMenu, restaurantId]);

    const handleClose = () => {
        if (loading) return;
        onClose?.();
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        const response = await createMenuApi({
            restaurantId,
            name: form.name,
            hours: form.hours,
            companyName: form.companyName,
            channels,
        });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        onSuccess?.();
        handleClose();
    };

    const handleSelectChannels = (channels: any) => setChannels(channels);

    return (
        <Form form={form} onSubmit={onSubmit}>
            <h2 className={classes.title}>{translate('Create Menu')}</h2>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormTextField name='name' label={translate('Name')} required />
                </Grid>
                {internalUser && (
                    <Grid item xs={12}>
                        <FormTextField
                            name='hours'
                            label={translate('Hours')}
                            helperText={`${translate('E.g. "Mo-Fr 08:00-09:00", "Sa-Su" or "Mar Mo-Fr 11:00-14:00". see https://openingh.openstreetmap.de/evaluation_tool/#check')}`}
                            placeholder={'24/7'}
                            disabled={loading}
                            validate={hours}
                        />
                    </Grid>
                )}
                {internalUser && (
                    <Grid item xs={12}>
                        <FormTextField
                            name='companyName'
                            label={translate('Company Name')}
                            helperText={translate('This field is only used for lets eat menus, writing something here if it is not for lets eat will hide the menu.')}
                            disabled={loading}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <span className={classes.text}>{translate('Select your sales channels')}</span>
                    <div className={classes.integrationChannelsContainer}>
                        <Channels onChange={handleSelectChannels} disabledChannels={disabledChannels} />
                    </div>
                </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
                {onClose && (
                    <Button onClick={handleClose} secondary>
                        {translate('Cancel')}
                    </Button>
                )}
                <Button type='submit' disabled={loading}>
                    {loading ? translate('Creating') : translate('Create')}
                </Button>
            </div>
        </Form>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.text.brand,
        textAlign: 'center',
        fontFamily: theme.typography.bold,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        margin: 0,
    },
    buttonsContainer: {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 20,
        '& button': {
            width: 200,
        },
    },
    integrationChannelsContainer: {
        width: '100%',
        marginTop: 20,
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '0 auto',
    },
}));

type Props = {
    onClose?: any;
    onSuccess?: any;
};
