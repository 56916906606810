/**
 * @prettier
 */
export const appLayout = {
    dialog: {
        form: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
} as const;
