/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { markOrderAsPreparedApi } from 'src/api/letseatmanager/kitchen/markOrderAsPreparedApi';
import { updateOrderItemsKitchenStatusApi } from 'src/api/letseatmanager/kitchen/updateOrderItemsKitchenStatusApi';
import { Text } from 'src/components/Text';
import { KitchenStatuses } from 'src/constants/KitchenStatus';
import { translate } from 'src/i18n/translate';
import { KitchenOrderCard } from 'src/scenes/letseatmanager/kitchenDisplayScreen/kitchenOrder/KitchenOrderCard';
import { KitchenPreparingOrdersSummary } from 'src/scenes/letseatmanager/kitchenDisplayScreen/KitchenPreparingOrdersSummary';
import type { OrderId } from 'src/types/Id';
import { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { groupOrderItemsAndCancelledItemsByAddedDate } from 'src/utils/kitchen/groupOrderItemsAndCancelledItemsByAddedDate';
import { useUpdateKitchenOrder } from 'src/utils/kitchen/useUpdateKitchenOrder';
import { isPartiallyPreparedKitchenOrder } from 'src/utils/order/isPartiallyPreparedKitchenOrder';
import { isPreparedKitchenOrder } from 'src/utils/order/isPreparedKitchenOrder';
import { isPreparingKitchenOrder } from 'src/utils/order/isPreparingKitchenOrder';
import { useFetchKitchenOrdersUpdates } from 'src/utils/react/useFetchKitchenOrdersUpdates';
import { useSelector } from 'src/utils/react/useSelector';

export function KitchenPreparingOrders(): React.ReactElement {
    const classes = useStyles();

    const { updateKitchenOrder } = useUpdateKitchenOrder();
    const { fetchKitchenOrdersUpdates } = useFetchKitchenOrdersUpdates();

    const [loading, setLoading] = useState(false);

    const preparingKitchenOrders = useSelector((state) => state.app2.preparingKitchenOrders);

    const setKitchenStatusAsPrepared = async (order: OrderVm) => {
        if (loading) return;

        setLoading(true);
        const response = await markOrderAsPreparedApi({ orderId: order.orderId });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setLoading(false);
            return;
        }

        updateKitchenOrder({ ...order, kitchenStatus: KitchenStatuses.PREPARED });
        setLoading(false);
    };

    const updateOrderItemsKitchenStatus = async (order: OrderVm) => {
        if (loading) return;

        const response = await updateOrderItemsKitchenStatusApi({
            orderId: order.orderId,
            orderItems: order.orderItems,
            kitchenStatus: KitchenStatuses.PARTIALLY_PREPARED,
        });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        fetchKitchenOrdersUpdates();
    };

    if (!preparingKitchenOrders?.length) {
        return (
            <div className={classes.emptyDescription}>
                <Text size={'large'}>{translate('No kitchen orders')}</Text>
            </div>
        );
    }

    const allItemsArePrepared = (orderId: OrderId) => {
        const order = preparingKitchenOrders.find((order) => order.orderId === orderId);
        if (!order) return false;

        return order.orderItems.every((orderItem) => isPreparedKitchenOrder(orderItem.orderItemKitchenStatus) || isPartiallyPreparedKitchenOrder(orderItem.orderItemKitchenStatus));
    };

    const hasMissingPreparingOrderItems = (orderId: OrderId) => {
        const order = preparingKitchenOrders.find((order) => order.orderId === orderId);
        if (!order) return false;
        const preparingOrderItem = order.orderItems?.filter((item) => !isPartiallyPreparedKitchenOrder(item.orderItemKitchenStatus));
        const orderItemsAndCancelledItemsGrouped = groupOrderItemsAndCancelledItemsByAddedDate(preparingOrderItem, []);

        return orderItemsAndCancelledItemsGrouped?.length > 1;
    };

    const handleClickKitchenOrderCard = (order: OrderVm, isDuplicatedOrder: boolean) => {
        if (isPreparingKitchenOrder(order.kitchenStatus) && !order.orderItems?.length && !isDuplicatedOrder) return setKitchenStatusAsPrepared(order);
        if (!isDuplicatedOrder) return setKitchenStatusAsPrepared(order);

        if (allItemsArePrepared(order.orderId)) return setKitchenStatusAsPrepared(order);
        if (!order.orderItems.length) return;
        if (!hasMissingPreparingOrderItems(order.orderId)) return setKitchenStatusAsPrepared(order);

        return updateOrderItemsKitchenStatus(order);
    };

    return (
        <>
            <div className={classes.ordersContainer}>
                {preparingKitchenOrders?.map((order: OrderVm) => {
                    const preparingCancelledOrderItem = order.cancelledItems?.filter((item) => !isPartiallyPreparedKitchenOrder(item.orderItemKitchenStatus));
                    const orderItemsAndCancelledItemsGrouped = groupOrderItemsAndCancelledItemsByAddedDate(order.orderItems, preparingCancelledOrderItem ?? []);

                    return orderItemsAndCancelledItemsGrouped.map((items, index) => {
                        const orderItems = items?.filter((orderItem) => !orderItem.removedAt);
                        const preparingItems = orderItems?.filter((item) => !isPartiallyPreparedKitchenOrder(item.orderItemKitchenStatus));
                        const cancelledItems = items?.filter((orderItem) => !!orderItem.removedAt);
                        const allItemsArePartiallyPrepared = orderItems?.length && orderItems.every((item) => isPartiallyPreparedKitchenOrder(item.orderItemKitchenStatus));

                        if (allItemsArePartiallyPrepared) return null;

                        return (
                            <div key={order.orderId + index}>
                                <KitchenOrderCard
                                    order={{ ...order, orderItems: preparingItems, cancelledItems }}
                                    onClick={() => handleClickKitchenOrderCard({ ...order, orderItems: preparingItems, cancelledItems }, orderItemsAndCancelledItemsGrouped.length > 1)}
                                />
                            </div>
                        );
                    });
                })}
            </div>
            <KitchenPreparingOrdersSummary />
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    ordersContainer: {
        width: 'calc(100% - 310px)',
        height: 'fit-content',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(255px, 1fr))',
        gap: 20,
        marginBottom: '6vh',
    },
    emptyDescription: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
