/**
 * @prettier
 */
import { createSlice } from 'redux-starter-kit';
import type { UnitOfMeasurement } from 'src/constants/UnitOfMeasurement';
import type { SupplyVm } from 'src/types/SupplyVm';

export const inventory = createSlice<InventoryState, Actions>({
    name: 'inventory',
    initialState: {
        buyOrders: [],
        supplies: [],
    },
    reducers: {
        setBuyOrders: (state: InventoryState, { payload: buyOrders }: { payload: Array<BuyOrder> }) => {
            state.buyOrders = buyOrders;
        },

        setSupplies: (state: InventoryState, { payload: supplies }: { payload: Array<SupplyVm> }) => {
            state.supplies = supplies;
        },
    },
});

export type InventoryState = {
    buyOrders: Array<BuyOrder>;
    supplies: Array<SupplyVm>;
};

type Actions = {
    setBuyOrders: any;
    setSupplies: any;
};

export type BuyOrder = {
    sku: string;
    quantity: string;
    unit: UnitOfMeasurement;
    price: string;
};
