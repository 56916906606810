/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { CreatePrinterSteps } from 'src/constants/CreatePrinterStep';
import { translate } from 'src/i18n/translate';
import { PrinterWizardContext, PrinterWizardProviderValues } from 'src/providers/PrinterWizardProvider';

export function SerialNumber(): React.ReactElement | null {
    const classes = useStyles();

    const { formValues, goNextWizardStep, goBackWizardStep, setCreatePrinterWizardFormValue, activeCreatePrinterStep } = useContext<PrinterWizardProviderValues>(PrinterWizardContext);

    const form = useForm();
    const { control, setValue } = form;

    const serialNumber = useWatch({ name: 'serialNumber', control });

    const disabled = !serialNumber || serialNumber === '';

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (formValues.serialNumber) {
            setValue('serialNumber', formValues.serialNumber);
        }
    }, [formValues.serialNumber]);

    const handleChangeSerialNumber = (serialNumber: any) => {
        setCreatePrinterWizardFormValue('serialNumber', serialNumber);
        goNextWizardStep();
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && !disabled) {
            handleChangeSerialNumber(serialNumber);
        }
    };

    const handleSubmit = async (form: any) => {
        handleChangeSerialNumber(form.serialNumber);
    };

    if (activeCreatePrinterStep !== CreatePrinterSteps.SERIAL_NUMBER) return null;

    return (
        <Form form={form} onSubmit={handleSubmit} className={classes.stepContent}>
            <FormTextField
                name='serialNumber'
                placeholder={translate('Example: PT20230612001')}
                label={translate('Serial Number')}
                classes={{ label: classes.label }}
                defaultValue={formValues.serialNumber}
            />
            <p className={classes.helperText}>
                {translate(
                    "The serial number of a printer can typically be found on the back or bottom panel, side panels, control panel, user manual or packaging, or through the printer's online account or management console."
                )}
            </p>
            <div className={classes.actionsContainer}>
                <Button secondary onClick={goBackWizardStep} type={'button'}>
                    {translate('Back')}
                </Button>
                <Button primary type={'submit'} disabled={disabled}>
                    {translate('Next')}
                </Button>
                <p className={classes.enterText}>{translate('hit Enter ↩')}</p>
            </div>
        </Form>
    );
}

const useStyles = makeStyles((theme) => ({
    stepContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        animation: '$appear 700ms ease',
    },
    helperText: {
        color: '#565B69',
        fontSize: 12,
        fontFamily: theme.typography.regular,
    },
    label: {
        fontSize: 22,
        fontFamily: theme.typography.regular,
        color: '#0D3037',
        padding: 0,
        lineHeight: 1.4,
    },
    actionsContainer: {
        display: 'flex',
        gap: 12,
        marginTop: 24,
        width: '100%',
        justifyContent: 'flex-end',
    },
    enterText: {
        fontFamily: theme.typography.regular,
        color: '#0D3037',
    },
    '@keyframes appear': {
        '0%': {
            opacity: 0,
            transform: 'translateY(100px)',
        },
        '100%': {
            opacity: 1,
            transform: 'translateY(0px)',
        },
    },
}));
