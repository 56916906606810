/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SubscriptionId } from 'src/types/Id';

export async function removeSubscriptionApi(request: RemoveSubscriptionApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('subscription/removeSubscriptionApi', request);
}

export type RemoveSubscriptionApiRequest = {
    subscriptionId: SubscriptionId;
};
