/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { useState } from 'react';
import { Button } from 'src/components/Button';
import { Text } from 'src/components/Text';
import { Tooltip } from 'src/components/Tooltip';
import type { App } from 'src/constants/App';
import { CustomPaymentMethodIcon, CustomPaymentMethodIcons } from 'src/constants/CustomPaymentMethodIcons';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { CreateCustomPaymentMethodDialog } from 'src/scenes/letseatmanager/theRestaurant/CreateCustomPaymentMethodDialog';
import { PaymentMethodIcon } from 'src/scenes/letseatmanager/theRestaurant/PaymentMethodIcon';
import { RemoveCustomPaymentMethodDialog } from 'src/scenes/letseatmanager/theRestaurant/RemoveCustomPaymentMethodDialog';
import type { CustomPaymentMethod } from 'src/types/Id';

export function CustomPaymentMethodSettings({ onSuccess, paymentMethods }: Props): React.ReactElement {
    const classes = useStyles();

    const [createPaymentMethodDialogState, setCreatePaymentMethodDialogState] = useState<{ open: boolean }>({ open: false });
    const [removePaymentMethodDialogState, setRemovePaymentMethodDialogState] = useState<{ open: boolean; name: CustomPaymentMethod | undefined }>({ open: false, name: undefined });

    const openAddPaymentMethodDialog = () => setCreatePaymentMethodDialogState({ open: true });

    const closeAddPaymentMethodDialog = () => setCreatePaymentMethodDialogState({ open: false });

    const openRemovePaymentMethodDialog = (paymentMethodName: CustomPaymentMethod) => setRemovePaymentMethodDialogState({ open: true, name: paymentMethodName });

    const closeRemovePaymentMethodDialog = () => setRemovePaymentMethodDialogState({ open: false, name: undefined });

    return (
        <>
            <CreateCustomPaymentMethodDialog open={createPaymentMethodDialogState.open} onSuccess={onSuccess} onClose={closeAddPaymentMethodDialog} />
            <RemoveCustomPaymentMethodDialog
                open={removePaymentMethodDialogState.open}
                paymentMethodName={removePaymentMethodDialogState.name}
                onClose={closeRemovePaymentMethodDialog}
                onSuccess={onSuccess}
            />
            <div className={classes.section}>
                <h2 className={classes.subtitle}>
                    {translate('Custom Payment Methods')}
                    <Tooltip
                        text={translate(
                            'Define the custom payment methods that can be used in conjunction with card and cash payments as long as they are enabled, for example clip, transfer, vouchers, etc. You can add new ones or remove them'
                        )}
                    />
                </h2>
                <div className={classes.itemsContainer}>
                    {paymentMethods?.map((paymentMethod, idx: number) => (
                        <div className={classes.paymentMethodItem} key={idx}>
                            {paymentMethod.icon && <PaymentMethodIcon icon={CustomPaymentMethodIcons[paymentMethod?.icon]} />}
                            <div className={classes.paymentMethod}>
                                <span>{paymentMethod.name}</span>
                                {!!paymentMethod.channels && (
                                    <Text size={'small'}>{translate('Available on @channels', { channels: paymentMethod.channels?.map((channel: App) => translate(channel)).join(', ') })}</Text>
                                )}
                            </div>
                            <Button icon onClick={() => openRemovePaymentMethodDialog(paymentMethod.name)} classes={{ button: classes.iconButton }}>
                                <CloseIcon titleAccess={translate('Remove')} />
                            </Button>
                        </div>
                    ))}
                </div>
                <Button outlined onClick={openAddPaymentMethodDialog}>
                    <AddIcon />
                    {translate('Add payment method')}
                </Button>
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
    },
    section: {
        width: '100%',
        borderRadius: 5,
        border: `1px solid ${theme.palette.secondary.dark}`,
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        gap: 20,
    },
    itemsContainer: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    paymentMethodItem: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        minWidth: 140,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #676060B2',
        borderRadius: 6,
        padding: '5px 10px',
        gap: 5,
        height: '100%',
    },
    paymentMethod: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 6,
    },
    iconButton: {
        width: 28,
        height: 28,
        borderRadius: 28,
        minHeight: 28,
    },
}));

type Props = {
    onSuccess: any;
    paymentMethods?: Array<{
        name: CustomPaymentMethod;
        paymentMethod: PaymentMethod;
        commission?: string;
        enabled?: boolean;
        icon?: CustomPaymentMethodIcon;
        channels?: Array<App>;
    }>;
};
