/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BrandVm } from 'src/types/BrandVm';

export async function findBrandsApi(): Promise<
    ApiSauceResponse<{
        brands: Array<BrandVm>;
    }>
> {
    return letseatadminApiMethod('brand/findBrandsApi');
}
