/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import { EditIcon, TrashCanIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { findRestaurantUsersApi, UserVm } from 'src/api/letseatmanager/userManagement/findRestaurantUsersApi';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { ChangeRestaurantUserDialog } from 'src/scenes/letseatmanager/userManagement/ChangeRestaurantUserDialog';
import { CreateRestaurantUserDialog } from 'src/scenes/letseatmanager/userManagement/CreateRestaurantUserDialog';
import { RemoveRestaurantUserDialog } from 'src/scenes/letseatmanager/userManagement/RemoveRestaurantUserDialog';
import { ManagerUserId } from 'src/types/Id';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function UsersTable(): React.ReactElement {
    const classes = useStyles();

    const [createUserDialogState, setCreateUserDialogState] = useState({ open: false });
    const [removeUserDialogState, setRemoveUserDialogState] = useState<{ open: boolean; restaurantUser: UserVm | undefined }>({ open: false, restaurantUser: undefined });
    const [changeUserDialogState, setChangeUserDialogState] = useState<{ open: boolean; managerUserId: ManagerUserId | undefined }>({ open: false, managerUserId: undefined });
    const [loading, setLoading] = useState(false);

    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const timeZone = useSelector((state) => state.app.restaurant?.timeZone);

    const [loadingUsers, users, refreshUsers] = useLoadApi(findRestaurantUsersApi, { restaurantIds }, { initialValue: [], dependencies: [restaurantIds] });

    const removeUser = async (managerUserId: any) => {
        const restaurantUser = users.find((user) => user.managerUserId === managerUserId);
        setRemoveUserDialogState({ open: true, restaurantUser });
    };

    const removeRestaurantUserDialogSucceeded = () => {
        setRemoveUserDialogState({ open: false, restaurantUser: undefined });
        refreshUsers();
    };

    const closeRemoveRestaurantUserDialog = () => {
        setRemoveUserDialogState({ open: false, restaurantUser: undefined });
    };

    const openChangeUserDialog = (managerUserId: any) => setChangeUserDialogState({ open: true, managerUserId });

    const closeChangeUserDialog = () => setChangeUserDialogState({ open: false, managerUserId: undefined });

    return (
        <div className={classes.container}>
            <RemoveRestaurantUserDialog
                open={removeUserDialogState.open}
                restaurantUser={removeUserDialogState.restaurantUser}
                onClose={closeRemoveRestaurantUserDialog}
                onSuccess={removeRestaurantUserDialogSucceeded}
            />
            <CreateRestaurantUserDialog open={createUserDialogState.open} onClose={() => setCreateUserDialogState({ open: false })} onSuccess={refreshUsers} />
            <ChangeRestaurantUserDialog open={changeUserDialogState.open} managerUserId={changeUserDialogState.managerUserId} onClose={closeChangeUserDialog} onSuccess={refreshUsers} />
            <Table
                title={undefined}
                loading={loadingUsers || loading}
                data={users.map((user) => {
                    return {
                        managerUserId: user.managerUserId,
                        name: user.name,
                        roleName: user.roleName,
                        email: user.email,
                        ordersCancelled: user.ordersCancelled ?? 0,
                        createdAt: formatDateTimeString(user.createdAt, timeZone ?? TimeZones.AMERICA_MONTERREY),
                        edit: user.managerUserId,
                        delete: user.managerUserId,
                    };
                })}
                columns={[
                    {
                        name: 'managerUserId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'name',
                        label: translate('Name'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'email',
                        label: translate('Email'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'roleName',
                        label: translate('Role'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'ordersCancelled',
                        label: translate('Orders cancelled'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'createdAt',
                        label: translate('Created At'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'edit',
                        label: ' ',
                        options: {
                            filter: false,
                            customBodyRender: (managerUserId: ManagerUserId) => (
                                <Button variant={'icon'} classes={{ button: classes.editIcon }} onClick={() => openChangeUserDialog(managerUserId)}>
                                    <EditIcon title='edit icon' />
                                </Button>
                            ),
                        },
                    },
                    {
                        name: 'delete',
                        label: ' ',
                        options: {
                            filter: false,
                            customBodyRender: (managerUserId: ManagerUserId) => (
                                <Button variant={'icon'} classes={{ button: classes.deleteIcon }} onClick={() => removeUser(managerUserId)}>
                                    <TrashCanIcon title='remove icon' />
                                </Button>
                            ),
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    filter: false,
                    print: false,
                    viewColumns: false,
                    download: false,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={refreshUsers} />
                            <CreateToolbarButton onClick={() => setCreateUserDialogState({ open: true })} />
                        </>
                    ),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: 20,
    },
    editIcon: {
        color: theme.palette.icons.brand,
    },
    deleteIcon: {
        color: theme.palette.text.danger,
    },
}));
