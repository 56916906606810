/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import type { GiftVm } from 'src/types/GiftVm';
import { isGiftValid } from 'src/utils/gift/isGiftValid';
import { sum } from 'src/utils/reduce/sum';

export function sumValidGifts(gifts?: Array<GiftVm>): string {
    if (!gifts) {
        return BigNumber(0).toString();
    }
    return gifts
        .filter(isGiftValid)
        .map((gift) => BigNumber(gift.credits).minus(gift.usedCredits || 0))
        .reduce(sum, BigNumber(0))
        .toString();
}
