/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Text } from '@pidedirecto/ui';
import { LightingIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { OrderCardCashInfo } from 'src/components/orderCard/OrderCardCashInfo';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isDidiApp } from 'src/utils/order/isDidiApp';
import { toHumanizedOrderId } from 'src/utils/order/toHumanizedOrderId';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { useSelector } from 'src/utils/react/useSelector';

/** @deprecated */
export function DeprecatedOrderCardGeneralInfo({ order }: Props): React.ReactElement {
    const classes = useStyles();

    const consecutiveOrderIdEnabled = useSelector((state) => state.app.restaurant?.consecutiveOrderIdEnabled);
    const internalUser = useSelector((state) => state.authentication.internalUser);
    const brandOpened = useSelector((state) => state.app.brandOpened);

    const showHumanizedOrderId = consecutiveOrderIdEnabled ? internalUser || !Boolean(order.consecutiveOrderId) : true;

    return (
        <div style={{ width: '100%' }}>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <>
                    {showHumanizedOrderId && (
                        <Typography variant='h4' className={classes.id}>
                            #{toHumanizedOrderId(order.orderId)}
                        </Typography>
                    )}
                    {consecutiveOrderIdEnabled && order.consecutiveOrderId && (
                        <Typography variant='h4' className={classes.id}>
                            #{order.consecutiveOrderId}
                        </Typography>
                    )}
                </>
                <Typography variant='caption' className={classes.time}>
                    {formatDateTimeString(order.createdAt, order.timeZone)}
                </Typography>
            </Grid>
            {order.demoOrder && <span className={classes.id}>{translate('Demo Order')}</span>}
            {order.hasOrderIncident && internalUser && <span className={classes.id}>{translate('Order incident')}</span>}
            {isCashPayment(order.paymentMethod) && !isDidiApp(order.app) && isDeliveryOrder(order.orderType) && <OrderCardCashInfo order={order} bigOrderCard={true} />}
            {order.rappiTurboOrder && (
                <div className={classes.row}>
                    <LightingIcon size={14} className={classes.icon} />
                    <Text>{translate('Rappi turbo')}</Text>
                </div>
            )}
            <div className={classes.separator}></div>
            {brandOpened && (
                <>
                    <Grid item xs={12} style={{ width: '100%', padding: 0, textAlign: 'center' }}>
                        <Typography className={(classes as any).restaurantName}>{order.restaurant?.name}</Typography>
                    </Grid>
                    <div className={classes.separator}></div>
                </>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    separator: {
        height: '0px',
        width: '100%',
        border: '1px solid #F3F3F3',
        margin: '12px 0px',
    },
    id: {
        fontFamily: theme.typography.medium,
        color: '#6A7575',
        fontSize: 14,
        margin: 0,
        width: '100%',
        height: 'fit-content',
    },
    time: {
        fontFamily: theme.typography.medium,
        color: '#6A7575',
        fontSize: 14,
        width: '100%',
        textAlign: 'right',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        gap: 4,
        alignItems: 'center',
    },
    icon: {
        color: theme.palette.icons.brand,
    },
}));
type Props = {
    order: OrderVm;
};
