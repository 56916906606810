/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { AlertDialog } from 'src/components/AlertDialog';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { nonUndefined } from 'src/utils/filter/nonUndefined';

export function IntegrationsSyncOpeningHoursAlert({ rappi, didiFood, uberEats }: Props): React.ReactElement {
    const classes = useStyles();

    const [alertStatuses, setAlertStatuses] = useState<
        Array<{
            key: 'rappi' | 'didiFood' | 'uberEats';
            open: boolean;
            title: string;
            syncStatus: boolean;
            message: string | undefined;
        }>
    >([
        {
            key: 'rappi',
            open: false,
            title: 'Rappi ' + translate('sync opening hours'),
            syncStatus: false,
            message: undefined,
        },
        {
            key: 'didiFood',
            open: false,
            title: 'DidiFood ' + translate('sync opening hours'),
            syncStatus: false,
            message: undefined,
        },
        {
            key: `uberEats`,
            open: false,
            title: 'UberEats ' + translate('sync opening hours'),
            syncStatus: false,
            message: undefined,
        },
    ]);

    const handleClose = (key: undefined | string) => {
        setAlertStatuses((prevAlertStatuses) =>
            prevAlertStatuses.map((alert) => {
                if (alert?.key === key) {
                    return { ...alert, open: false };
                }
                return alert;
            })
        );
    };

    useEffect(() => {
        setAlertStatuses((prevAlertStatuses) =>
            prevAlertStatuses
                .map((alert) => {
                    if (alert?.key === 'rappi') {
                        return { ...alert, open: rappi?.open ?? false, syncStatus: !!rappi?.syncStatus, message: rappi?.message };
                    }
                    if (alert?.key === 'didiFood') {
                        return { ...alert, open: didiFood?.open ?? false, syncStatus: !!didiFood?.syncStatus, message: didiFood?.message };
                    }
                    if (alert?.key === 'uberEats') {
                        return { ...alert, open: uberEats?.open ?? false, syncStatus: !!uberEats?.syncStatus, message: uberEats?.message };
                    }
                })
                .filter(nonUndefined)
        );
    }, [rappi, didiFood, uberEats]);

    return (
        <div className={classes.alertContainer}>
            <Grid container direction='column' alignItems='flex-end' spacing={1}>
                {alertStatuses.map((alertStatus) => (
                    <AlertDialog key={alertStatus?.key} severity={alertStatus?.syncStatus ? 'success' : 'error'} title={alertStatus?.title ?? ''} open={alertStatus?.open ?? false}>
                        <div className={classes.message}>{alertStatus?.message}</div>
                        <Button onClick={() => handleClose(alertStatus?.key)} secondary>
                            {translate('Accept')}
                        </Button>
                    </AlertDialog>
                ))}
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    alertContainer: {
        position: 'fixed',
        right: 70,
        bottom: 40,
        zIndex: 99,
    },
    message: {
        paddingBottom: 12,
    },
}));

export type Props = {
    rappi?: {
        open: boolean;
        syncStatus: boolean;
        message?: string;
    };
    didiFood?: {
        open: boolean;
        syncStatus: boolean;
        message?: string;
    };
    uberEats?: {
        open: boolean;
        syncStatus: boolean;
        message?: string;
    };
};
