/**
 * @prettier
 */
import { RRule } from 'rrule';
import type { RRuleString } from 'src/types/Id';

export function createRRule(value?: RRuleString): any | undefined {
    if (!value) {
        return;
    }
    try {
        return RRule.fromString(value.replace(/\s+/g, '\n'));
    } catch (e: any) {
        return;
    }
}
