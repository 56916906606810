/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { SupplyCategoryVm } from 'src/types/SupplyCategoryVm';

export async function findSupplyCategoriesByRestaurantIdApi(request: FindRestaurantSupplyCategoriesRequest): ApiSauceResponse<Array<SupplyCategoryVm>> {
    return letseatmanagerApiMethod('supplyCategory/findSupplyCategoriesByRestaurantIdApi', request);
}

export type FindRestaurantSupplyCategoriesRequest = {
    restaurantId: RestaurantId;
};
