/**
 * @prettier
 */
import { RoutePaths } from 'src/constants/RoutePath';
import type { SurveyId } from 'src/types/Id';
import { RegExps } from 'src/utils/RegExps';

export function openSurveyPageInNewTab(surveyId: SurveyId): void {
    window.open(RoutePaths.ADMIN_SURVEY.replace(`:surveyId(${RegExps.uuid})`, surveyId), '_blank');
}
