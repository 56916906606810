/**
 * @prettier
 */
import { usePosCustomerActions } from 'src/modules/pos/customer/posCustomerStore';

export function useSetCustomerInfoError() {
    const setError = usePosCustomerActions((actions) => actions.setError);

    return setError;
}
