/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { ExternalId, RestaurantId } from 'src/types/Id';
import type { ModifierVm } from 'src/types/ModifierVm';

export async function createModifierGroupApi(request: CreateModifierGroupApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('modifierGroup/createModifierGroupApi', request);
}

export type CreateModifierGroupApiRequest = {
    restaurantId: RestaurantId;
    name?: string;
    externalModifierGroupId?: ExternalId;
    requiredMin?: number;
    requiredMax?: number;
    freeModifiersQuantity?: number;
    hidden: boolean;
    showModifierGroupNameInCommand?: boolean;
    modifiers: Array<ModifierVm>;
};
