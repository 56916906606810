/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { MenuCategoryFamilyId } from 'src/types/Id';
import { MenuCategoryFamilyVm } from 'src/types/MenuCategoryFamilyVm';

export async function removeMenuCategoryFamilyApi(request: RemoveMenuCategoryFamilyApiRequest): ApiSauceResponse<MenuCategoryFamilyVm> {
    return letseatmanagerApiMethod('menuCategoryFamily/removeMenuCategoryFamilyApi', request);
}

export type RemoveMenuCategoryFamilyApiRequest = {
    menuCategoryFamilyId: MenuCategoryFamilyId;
};
