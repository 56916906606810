/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderType } from 'src/constants/OrderType';
import type { MenuItemId, RecipeId, SupplyId } from 'src/types/Id';
import type { RecipeVm } from 'src/types/RecipeVm';

export async function changeRecipeApi(request: ChangeRecipeApiRequest): ApiSauceResponse<RecipeVm> {
    return letseatmanagerApiMethod('recipe/changeRecipeApi', request);
}

export type ChangeRecipeApiRequest = {
    recipeId: RecipeId;
    menuItemIds: Array<MenuItemId>;
    name: string;
    ingredients: Array<Ingredient>;
    averageWaste?: string;
    estimatedPrice?: string;
};

type Ingredient = {
    supplyId: SupplyId;
    quantity: string;
    excludedOrderTypes?: Array<OrderType>;
    waste?: number;
};
