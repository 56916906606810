/**
 * @prettier
 */
export const Psps = {
    QPAY: 'QPAY',
    CONEKTA: 'CONEKTA',
    STRIPE: 'STRIPE',
    HOLACASH: 'HOLACASH',
    MERCADOPAGO: 'MERCADOPAGO',
} as const;

export type Psp = typeof Psps[keyof typeof Psps];
