/**
 * @prettier
 */
import { usePosPaymentMethodsStore } from 'src/services/pos/posPaymentMethods/posPaymentMethodsStore';

export function useSelectedPaymentMethodHasReferenceEnabled(): boolean | undefined {
    const paymentReferenceEnabled = usePosPaymentMethodsStore((state) => state.paymentReferenceEnabled);

    return paymentReferenceEnabled;
}
