/**
 * @prettier
 */
import { PrinterContents } from 'src/constants/PrinterContent';
import { PrintTypes } from 'src/constants/PrintType';
import { getDeprecatedPrintersInLocalStorage } from 'src/localStorage/getDeprecatedPrintersInLocalStorage';
import { createManagerUserTipSalesReportPrint } from 'src/services/printer/prints/createManagerUserTipSalesReportPrint';
import { printerDeprecatedToPrinter } from 'src/services/printer/utils/printerDeprecatedToPrinter';
import { usePrintContent } from 'src/services/printer/utils/usePrintContent';
import { DeviceGroupId } from 'src/types/Id';
import { ManagerUserTipSalesReportVm } from 'src/types/ManagerUserTipSalesReportVm';
import { PrinterVm } from 'src/types/PrinterVm';
import { useSelector } from 'src/utils/react/useSelector';

export function usePrintManagerUserTipSalesReport(): (managerUserTipSalesReport: ManagerUserTipSalesReportVm, forceToPrint?: boolean) => Promise<void> {
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);
    const deviceGroup = useSelector((state) => state.app.deviceGroup);
    const printersNewUI = useSelector((state) => state.app.printers);
    const restaurant = useSelector((state) => state.app.restaurant);

    const printersOldUi = getDeprecatedPrintersInLocalStorage().map(printerDeprecatedToPrinter);

    const { printContent, printContents } = usePrintContent();

    if (newDeviceManagementEnabled) {
        var printers = printersNewUI?.filter((printer: PrinterVm) =>
            printer.deviceGroups?.some((deviceGroupToSearch: { deviceGroupId: DeviceGroupId; deviceGroupName: string }) => deviceGroupToSearch.deviceGroupId === deviceGroup?.deviceGroupId)
        );
    } else {
        var printers = printersOldUi;
    }

    const printManagerUserTipSalesReport = async (managerUserTipSalesReport: ManagerUserTipSalesReportVm, forceToPrint: undefined | boolean) => {
        if (printers?.length === 0) {
            if (forceToPrint) alert('No active printers to select');
            return;
        } else if (printers?.length === 1) {
            if (forceToPrint) {
                printContents([
                    {
                        printer: printers[0],
                        print: {
                            printType: PrintTypes.MANAGER_USER_TIP_SALES_REPORT,
                            printerInstructions: createManagerUserTipSalesReportPrint({ managerUserTipSalesReport, restaurant }),
                        },
                    },
                ]);
                return;
            }
        }

        const posBusinessDayPrinters = printers?.filter((printer: PrinterVm) => {
            return printer.printerContent !== PrinterContents.COMMAND;
        });

        if (posBusinessDayPrinters?.length === 0) {
            if (forceToPrint)
                printContent({
                    printType: PrintTypes.MANAGER_USER_TIP_SALES_REPORT,
                    printerInstructions: createManagerUserTipSalesReportPrint({ managerUserTipSalesReport, restaurant }),
                });
            return;
        }

        const contentsToPrint = posBusinessDayPrinters?.map((printer: PrinterVm) => ({
            printer: printer,
            print: {
                printType: PrintTypes.MANAGER_USER_TIP_SALES_REPORT,
                printerInstructions: createManagerUserTipSalesReportPrint({ managerUserTipSalesReport, restaurant }),
            },
        }));

        printContents(contentsToPrint);
    };
    return printManagerUserTipSalesReport;
}
