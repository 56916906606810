/**
 * @prettier
 */
import momentTimezone from 'moment-timezone';
import OpeningHours from 'opening_hours';
import type { TimeZone } from 'src/constants/TimeZone';

export function isWithinHours(hours?: string | null, timeZone?: TimeZone): boolean {
    if (!hours || hours.trim() === '' || hours.trim() === '24/7' || hours.trim() === '00:00-24:00') {
        return true;
    }
    if (!timeZone) {
        return new OpeningHours(hours).getState(new Date());
    }
    const oh = new OpeningHours(hours);
    const now = momentTimezone().tz(timeZone);
    const nowDate = new Date(now.year(), now.month(), now.date(), now.hours(), now.minutes(), now.seconds(), now.milliseconds());
    return oh.getState(nowDate);
}
