/**
 * @prettier
 */
export const PromoTypes = {
    FIRST_ORDER_PROMO: 'FIRST_ORDER_PROMO',
    ONE_TIME_PROMO: 'ONE_TIME_PROMO',
    REUSABLE_PROMO: 'REUSABLE_PROMO',
} as const;

export type PromoType = typeof PromoTypes[keyof typeof PromoTypes];
