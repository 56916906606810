/**
 * @prettier
 */
import apisauce from 'apisauce';
import moment from 'moment';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OngoingDriverBonusesVm } from 'src/api/types/driverBonus/OngoingDriverBonusesVm';
import type { ChangeDriverBonusOfferRequest } from 'src/api/types/driverBonusOffer/ChangeDriverBonusOfferRequest';
import type { CreateDriverBonusOfferRequest } from 'src/api/types/driverBonusOffer/CreateDriverBonusOfferRequest';
import type { DriverBonusOffersVm } from 'src/api/types/driverBonusOffer/DriverBonusOffersVm';
import type { DriverBonusOfferVm } from 'src/api/types/driverBonusOffer/DriverBonusOfferVm';
import type { GetDriverBonusOfferRequest } from 'src/api/types/driverBonusOffer/GetDriverBonusOfferRequest';
import type { RegisterRestaurantRequest } from 'src/api/types/restaurant/RegisterRestaurantRequest';
import type { RegisterRestaurantResponse } from 'src/api/types/restaurant/RegisterRestaurantResponse';
import { getJwtToken } from 'src/api/utils/getJwtToken';
import { APP_VERSION } from 'src/config/appVersion';
import { Apps } from 'src/constants/App';
import type { RestaurantStatus } from 'src/constants/RestaurantStatus';
import { envPIDEDIRECTO_MANAGER_API_URL } from 'src/env/envPIDEDIRECTO_MANAGER_API_URL';
import { getDeviceId } from 'src/services/device/getDeviceId';
import type { CompanyId, CustomerId, GiftId, KitchenId, PickupStationId } from 'src/types/Id';
import { isJestTest } from 'src/utils/environment/isJestTest';

const createLetseatmanagerApi = () => {
    const baseURL = envPIDEDIRECTO_MANAGER_API_URL().replace('/v2', '');
    const api = apisauce.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: 60000, // 60 second timeout
    });

    api.addRequestTransform((request) => {
        if (isJestTest()) {
            // TODO: try to move this logic into setupTests.js, problem is that mocking code that runs inside code rendered by
            //  @testing-library/react render function is not mockable and is ignored, so currently this is the only safe way.
            throw Error(`Your test is calling api ${baseURL}/${request.url}. Make sure to mock all api calls in your tests, see usage of mockSuccessApiResponse.ts for examples.`);
        }
        if (!request.method) return;

        if (['get', 'head', 'delete', 'link', 'unlink'].includes(request.method)) {
            request.params.app = request.params.app ?? Apps.LETSEATMANAGER; // Remove later when app is no longer used backend and start using _app instead
            request.params.appVersion = APP_VERSION; // Remove later when appVersion is no longer used backend and start using _appVersion instead
            request.params._deviceId = getDeviceId();
            request.params._app = Apps.PIDEDIRECTOADMIN;
            request.params._pideDirectoAdminVersion = APP_VERSION;
        } else {
            request.data.app = request.data.app ?? Apps.LETSEATMANAGER; // Remove later when app is no longer used backend and start using _app instead
            request.data.appVersion = APP_VERSION; // Remove later when appVersion is no longer used backend and start using _appVersion instead
            request.data._deviceId = getDeviceId();
            request.data._app = Apps.PIDEDIRECTOADMIN;
            request.data._pideDirectoAdminVersion = APP_VERSION;
        }
    });

    const isDebuggingInChrome = process.env.NODE_ENV === 'development' && !!window.navigator.userAgent; // eslint-disable-line no-undef
    if (isDebuggingInChrome) {
        api.addRequestTransform((request) => {
            console.log(`${request.method?.toUpperCase()} ${baseURL}/${request.url} @ ${moment().format('HH:mm:ss.SSS')}`, request.params ?? request.data);
        });
        api.addMonitor((response) => {
            console.log(`${response.status ?? ''} <- ${response.config?.url ?? ''} @ ${moment().format('HH:mm:ss.SSS')}`, response.data);
        });
    }

    const withIdToken = async (apiCall: () => any) => {
        const jwtTokenResult = await getJwtToken();
        if (jwtTokenResult.errorResponse) {
            return jwtTokenResult.errorResponse as any;
        }

        api.setHeaders({ authorization: jwtTokenResult.jwtToken });
        return apiCall();
    };

    return {
        api,

        authentication: {
            getUser: () => withIdToken(() => api.get('/user', {})),
        },

        createMenu: (request: any) => withIdToken(() => api.post('/the-menu/create-menu', request)),
        changeMenu: (request: any) => withIdToken(() => api.post('/the-menu/change-menu', request)),
        removeMenu: (request: any) => withIdToken(() => api.post('/the-menu/remove-menu', request)),
        createMenuCategory: (request: any) => withIdToken(() => api.post('/the-menu/create-menu-category', request)),
        changeMenuCategory: (request: any) => withIdToken(() => api.post('/the-menu/change-menu-category', request)),
        removeMenuCategory: (request: any) => withIdToken(() => api.post('/the-menu/remove-menu-category', request)),
        createMenuItem: (request: any) => withIdToken(() => api.post('/the-menu/create-menu-item', request)),
        changeMenuItem: (request: any) => withIdToken(() => api.post('/the-menu/change-menu-item', request)),
        removeMenuItem: (request: any) => withIdToken(() => api.post('/the-menu/remove-menu-item', request)),

        currentOrders: {
            fetch: (request: any) => withIdToken(() => api.get('/current-orders', request)),
            rejectOrder: (request: any) => withIdToken(() => api.post('/current-orders/reject-order', request)),
            acceptOrder: (request: any) => withIdToken(() => api.post('/current-orders/accept-order', request)),
            cancelOrder: (request: any) => withIdToken(() => api.post('/current-orders/cancel-order', request)),
            completeOrder: (request: any) => withIdToken(() => api.post('/current-orders/complete-order', request)),
            fetchOrder: (request: any) => withIdToken(() => api.get('/current-orders/order', request)),
        },

        images: {
            requestUpload: (request: any) => withIdToken(() => api.get('/images/request-upload', request)),
            upload: (request: any) => withIdToken(() => api.post('/images/upload', request)),
        },

        checks: {
            fetch: (request: any) => withIdToken(() => api.get('/checks', request)),
        },

        theRestaurant: {
            fetch: (request: any) => withIdToken(() => api.get('/the-restaurant', request)),
            save: (request: any) => withIdToken(() => api.post('/the-restaurant', request)),
        },

        theMenu: {
            fetch: (request: any) => withIdToken(() => api.get('/the-menu', request)),
            moveMenuUp: (request: any) => withIdToken(() => api.post('/the-menu/move-menu-up', request)),
            moveMenuDown: (request: any) => withIdToken(() => api.post('/the-menu/move-menu-down', request)),
            moveMenuCategoryUp: (request: any) => withIdToken(() => api.post('/the-menu/move-menu-category-up', request)),
            moveMenuCategoryDown: (request: any) => withIdToken(() => api.post('/the-menu/move-menu-category-down', request)),
            moveMenuItemUp: (request: any) => withIdToken(() => api.post('/the-menu/move-menu-item-up', request)),
            moveMenuItemDown: (request: any) => withIdToken(() => api.post('/the-menu/move-menu-item-down', request)),
            hideMenuItem: (request: any) => withIdToken(() => api.post('/the-menu/hide-menu-item', request)),
            unHideMenuItem: (request: any) => withIdToken(() => api.post('/the-menu/un-hide-menu-item', request)),
        },

        admin: {
            monitoring: {
                fetch: () => withIdToken(() => api.get('/admin/monitoring')),
            },
            statistics: {
                fetch: (request: any) => withIdToken(() => api.get('/admin/statistics', request)),
            },
            statisticsOld: {
                fetch: (request: any) => withIdToken(() => api.get('/admin/statistics-old', request)),
            },
            currentOrders: {
                fetch: (request: any) => withIdToken(() => api.get('/admin/current-orders', request)),
                rejectOrder: (request: any) => withIdToken(() => api.post('/admin/current-orders/reject-order', request)),
                acceptOrder: (request: any) => withIdToken(() => api.post('/admin/current-orders/accept-order', request)),
                cancelOrder: (request: any) => withIdToken(() => api.post('/admin/current-orders/cancel-order', request)),
                completeOrder: (request: any) => withIdToken(() => api.post('/admin/current-orders/complete-order', request)),
                fetchOrder: (request: any) => withIdToken(() => api.get('/admin/current-orders/order', request)),
            },
            orders: {
                fetch: (request: any) => withIdToken(() => api.get('/admin/orders', request)),
            },
            order: (request: any) => withIdToken(() => api.get('/admin/order', request)),
            rejectOrder: (request: any) => withIdToken(() => api.post('/admin/reject-order', request)),
            acceptOrder: (request: any) => withIdToken(() => api.post('/admin/accept-order', request)),
            cancelOrder: (request: any) => withIdToken(() => api.post('/admin/cancel-order', request)),
            completeOrder: (request: any) => withIdToken(() => api.post('/admin/complete-order', request)),
            payment: (request: any) => withIdToken(() => api.get('/admin/payment', request)),
            restaurant: (request: any) => withIdToken(() => api.get('/admin/restaurant', request)),
            officeDelivery: (request: any) => withIdToken(() => api.post('/admin/office-delivery', request)),
            restaurants: (request: any) => withIdToken(() => api.get('/admin/restaurants', request)),
            registerRestaurant: (request: RegisterRestaurantRequest): ApiSauceResponse<RegisterRestaurantResponse> => withIdToken(() => api.post('/admin/register-restaurant', request)),
            restaurantsDetails: (request: any) => withIdToken(() => api.post('/admin/get-restaurants-details', request)),
            saveRestaurantsDetails: (request: any) => withIdToken(() => api.post('/admin/restaurants-details', request)),
            exportRestaurantAccounts: (request: any) => withIdToken(() => api.post('/admin/export-restaurant-accounts', request)),
            theRestaurant: {
                fetch: (request: any) => withIdToken(() => api.get('/admin/the-restaurant', request)),
                save: (request: any) => withIdToken(() => api.post('/admin/the-restaurant', request)),
            },
            removeRestaurant: (request: any) => withIdToken(() => api.post('/admin/remove-restaurant', request)),
            copyRestaurantMenu: (request: any) => withIdToken(() => api.post('/admin/copy-restaurant-menu', request)),
            changeMenuItemPromosInRestaurantMenu: (request: any) => withIdToken(() => api.post('/admin/change-menu-item-promos-in-restaurant-menu', request)),
            userPassword: {
                fetch: (request: any) => withIdToken(() => api.get('/admin/user-password', request)),
            },
            getCustomers: (request: any) => withIdToken(() => api.get('/admin/customers', request)),
            getCustomerOrders: (request: any) => withIdToken(() => api.get('/admin/customer-orders', request)),
            getCustomerGifts: (request: GetCustomerGiftsVmRequest) => withIdToken(() => api.get('/admin/customer-gifts', request)),
            getCustomerTransactions: (request: GetCustomerTransactionsVmRequest) => withIdToken(() => api.get('/admin/customer-transactions', request)),
            getCustomer: (request: any) => withIdToken(() => api.get('/admin/customer', request)),
            changeCustomer: (request: any) => withIdToken(() => api.post('/admin/change-customer', request)),
            changeCustomers: (request: any) => withIdToken(() => api.post('/admin/change-customers', request)),
            sendNotificationToCustomer: (request: any) => withIdToken(() => api.post('/admin/send-notification-to-customer', request)),
            sendNotificationToCustomers: (request: any) => withIdToken(() => api.post('/admin/send-notification-to-customers', request)),
            sendEmailToCustomer: (request: any) => withIdToken(() => api.post('/admin/send-email-to-customer', request)),
            sendEmailToCustomers: (request: any) => withIdToken(() => api.post('/admin/send-email-to-customers', request)),
            addCredits: (request: any) => withIdToken(() => api.post('/admin/add-credits', request)),
            removeCredits: (request: any) => withIdToken(() => api.post('/admin/remove-credits', request)),
            bulkAddCredits: (request: any) => withIdToken(() => api.post('/admin/bulk-add-credits', request)),
            bulkRemoveCredits: (request: any) => withIdToken(() => api.post('/admin/bulk-remove-credits', request)),
            exportDriverAccounts: (request: any) => withIdToken(() => api.post('/admin/export-driver-accounts', request)),
            getDriver: (request: any) => withIdToken(() => api.get('/admin/driver', request)),
            getDriverOrders: (request: any) => withIdToken(() => api.get('/admin/driver-orders', request)),
            changeDriver: (request: any) => withIdToken(() => api.post('/admin/change-driver', request)),
            changeDrivers: (request: any) => withIdToken(() => api.post('/admin/change-drivers', request)),
            sendNotificationToDriver: (request: any) => withIdToken(() => api.post('/admin/send-notification-to-driver', request)),
            sendNotificationToDrivers: (request: any) => withIdToken(() => api.post('/admin/send-notification-to-drivers', request)),
            sendEmailToDriver: (request: any) => withIdToken(() => api.post('/admin/send-email-to-driver', request)),
            sendEmailToDrivers: (request: any) => withIdToken(() => api.post('/admin/send-email-to-drivers', request)),
            getDriverBonusOffers: (request: any): ApiSauceResponse<DriverBonusOffersVm> => withIdToken(() => api.get('/admin/driver-bonus-offers', request)),
            getDriverBonusOffer: (request: GetDriverBonusOfferRequest): ApiSauceResponse<DriverBonusOfferVm> => withIdToken(() => api.get('/admin/driver-bonus-offer', request)),
            createDriverBonusOffer: (request: CreateDriverBonusOfferRequest): ApiSauceResponse<DriverBonusOfferVm> => withIdToken(() => api.post('/admin/create-driver-bonus-offer', request)),
            changeDriverBonusOffer: (request: ChangeDriverBonusOfferRequest): ApiSauceResponse<DriverBonusOfferVm> => withIdToken(() => api.post('/admin/change-driver-bonus-offer', request)),
            removeDriverBonusOffer: (request: any) => withIdToken(() => api.post('/admin/remove-driver-bonus-offer', request)),
            getOngoingDriverBonuses: (): ApiSauceResponse<OngoingDriverBonusesVm> => withIdToken(() => api.get('/admin/ongoing-driver-bonuses')),
            getBanners: () => withIdToken(() => api.get('/admin/banners')),
            getBanner: (request: any) => withIdToken(() => api.get('/admin/banner', request)),
            createBanner: (request: any) => withIdToken(() => api.post('/admin/create-banner', request)),
            changeBanner: (request: any) => withIdToken(() => api.post('/admin/change-banner', request)),
            removeBanner: (request: any) => withIdToken(() => api.post('/admin/remove-banner', request)),
            getCompanies: () => withIdToken(() => api.get('/admin/companies')),
            getCompany: (request: any) => withIdToken(() => api.get('/admin/company', request)),
            getCompanyConnectedCustomers: (request: any) => withIdToken(() => api.get('/admin/company-connected-customers', request)),
            getCompanyGifts: (request: GetCompanyGiftsVmRequest) => withIdToken(() => api.get('/admin/company-gifts', request)),
            registerCompany: (request: any) => withIdToken(() => api.post('/admin/register-company', request)),
            unregisterCompany: (request: any) => withIdToken(() => api.post('/admin/unregister-company', request)),
            changeCompany: (request: any) => withIdToken(() => api.post('/admin/change-company', request)),
            getBenefitPlans: (request: any) => withIdToken(() => api.get('/admin/benefit-plans', request)),
            getBenefitPlan: (request: any) => withIdToken(() => api.get('/admin/benefit-plan', request)),
            createBenefitPlan: (request: any) => withIdToken(() => api.post('/admin/create-benefit-plan', request)),
            removeBenefitPlan: (request: any) => withIdToken(() => api.post('/admin/remove-benefit-plan', request)),
            changeBenefitPlan: (request: any) => withIdToken(() => api.post('/admin/change-benefit-plan', request)),
            getEmployees: (request: any) => withIdToken(() => api.get('/admin/employees', request)),
            getEmployee: (request: any) => withIdToken(() => api.get('/admin/employee', request)),
            addEmployee: (request: any) => withIdToken(() => api.post('/admin/add-employee', request)),
            removeEmployee: (request: any) => withIdToken(() => api.post('/admin/remove-employee', request)),
            changeEmployee: (request: any) => withIdToken(() => api.post('/admin/change-employee', request)),
            getUnconnectedCustomers: (request: any) => withIdToken(() => api.get('/admin/unconnected-customers', request)),
            connectCustomerToBenefitPlan: (request: any) => withIdToken(() => api.post('/admin/connect-customer-to-benefit-plan', request)),
            getKitchenBillingData: (request: any) => withIdToken(() => api.get('/admin/kitchen-billing-data', request)),
            getKitchenBillingDataForKitchen: (request: any) => withIdToken(() => api.get('/admin/kitchen-billing-data-for-kitchen', request)),
            exportKitchenBillingData: (request: any) => withIdToken(() => api.post('/admin/export-kitchen-billing-data', request)),
            exportKitchenBillingDataForKitchen: (request: any) => withIdToken(() => api.post('/admin/export-kitchen-billing-data-for-kitchen', request)),
            getCompanyCreditsBillingData: (request: any) => withIdToken(() => api.get('/admin/company-credits-billing-data', request)),
            getCompanyCreditsBillingDataForBenefitPlan: (request: any) => withIdToken(() => api.get('/admin/company-credits-billing-data-for-benefit-plan', request)),
            getCompanyPayrollBillingDataForCompany: (request: any) => withIdToken(() => api.get('/admin/company-payroll-billing-data-for-company', request)),
            getCompanyPayrollBillingData: (request: any) => withIdToken(() => api.get('/admin/company-payroll-billing-data', request)),
            getRestaurantOptions: (request: any) => withIdToken(() => api.get('/admin/restaurant-options', request)),
            getRestaurantKitchensAndDeliveryStationsOptions: (request: any) => withIdToken(() => api.get('/admin/restaurants-kitchens-and-pickup-stations', request)),
            getRestaurantCustomers: (request: any) => withIdToken(() => api.get('/admin/customer-options', request)),
            getDriverOptions: (request: any) => withIdToken(() => api.get('/admin/driver-options', request)),
            getCompanyOptions: (request: any) => withIdToken(() => api.get('/admin/company-options', request)),
            getCompanyNameOptions: (request: any) => withIdToken(() => api.get('/admin/company-name-options', request)),
            getKitchenOptions: (request: any) => withIdToken(() => api.get('/admin/kitchen-options', request)),
            scheduledTasks: (request: any) => withIdToken(() => api.get('/admin/scheduled-tasks', request)),
            scheduledTask: (request: any) => withIdToken(() => api.get('/admin/scheduled-task', request)),
            createScheduledTask: (request: any) => withIdToken(() => api.post('/admin/create-scheduled-task', request)),
            changeScheduledTask: (request: any) => withIdToken(() => api.post('/admin/change-scheduled-task', request)),
            removeScheduledTask: (request: any) => withIdToken(() => api.post('/admin/remove-scheduled-task', request)),
            emails: (request: any) => withIdToken(() => api.get('/admin/emails', request)),
            pickupStations: () => withIdToken(() => api.get('/admin/pickup-stations')),
            changePickupStations: (request: ChangePickupStationsRequest) => withIdToken(() => api.post('/admin/change-pickup-stations', request)),
            removeGifts: (request: RemoveGiftsRequest) => withIdToken(() => api.post('/admin/remove-gifts', request)),
        },

        driver: {
            getDeliveryItems: (request: any) => withIdToken(() => api.post('/driver/delivery-items', request)),
            completeDeliveryOrders: (request: any) => withIdToken(() => api.post('/driver/complete-delivery-orders', request)),
        },
    };
};

/**
 *  @deprecated, use letseatmanagerApi instead
 */
export const letseatmanagerApiDeprecated: LetseatmanagerApiDeprecated = createLetseatmanagerApi();

type LetseatmanagerApiDeprecated = {
    api: any;
    authentication: {
        getUser: () => any;
    };
    createMenu: (request?: any) => any;
    changeMenu: (request?: any) => any;
    removeMenu: (request?: any) => any;
    createMenuCategory: (request?: any) => any;
    changeMenuCategory: (request?: any) => any;
    removeMenuCategory: (request?: any) => any;
    createMenuItem: (request?: any) => any;
    changeMenuItem: (request?: any) => any;
    removeMenuItem: (request?: any) => any;
    currentOrders: {
        fetch: (request?: any) => any;
        rejectOrder: (request?: any) => any;
        acceptOrder: (request?: any) => any;
        cancelOrder: (request?: any) => any;
        completeOrder: (request?: any) => any;
        fetchOrder: (request?: any) => any;
    };
    images: {
        requestUpload: (request?: any) => any;
        upload: (request?: any) => any;
    };
    checks: {
        fetch: (request?: any) => any;
    };
    theRestaurant: {
        fetch: (request?: any) => any;
        save: (request?: any) => any;
    };
    theMenu: {
        fetch: (request?: any) => any;
        moveMenuUp: (request?: any) => any;
        moveMenuDown: (request?: any) => any;
        moveMenuCategoryUp: (request?: any) => any;
        moveMenuCategoryDown: (request?: any) => any;
        moveMenuItemUp: (request?: any) => any;
        moveMenuItemDown: (request?: any) => any;
        hideMenuItem: (request?: any) => any;
        unHideMenuItem: (request?: any) => any;
    };
    admin: {
        monitoring: {
            fetch: () => any;
        };
        statistics: {
            fetch: (request?: any) => any;
        };
        statisticsOld: {
            fetch: (request?: any) => any;
        };
        currentOrders: {
            fetch: (request?: any) => any;
            rejectOrder: (request?: any) => any;
            acceptOrder: (request?: any) => any;
            cancelOrder: (request?: any) => any;
            completeOrder: (request?: any) => any;
            fetchOrder: (request?: any) => any;
        };
        orders: {
            fetch: (request?: any) => any;
        };
        order: (request?: any) => any;
        rejectOrder: (request?: any) => any;
        acceptOrder: (request?: any) => any;
        cancelOrder: (request?: any) => any;
        completeOrder: (request?: any) => any;
        payment: (request?: any) => any;
        restaurant: (request?: any) => any;
        officeDelivery: (request?: any) => any;
        restaurants: (request?: any) => any;
        registerRestaurant: (request: RegisterRestaurantRequest) => ApiSauceResponse<RegisterRestaurantResponse>;
        restaurantsDetails: (request?: any) => any;
        saveRestaurantsDetails: (request?: any) => any;
        exportRestaurantAccounts: (request?: any) => any;
        theRestaurant: {
            fetch: (request?: any) => any;
            save: (request?: any) => any;
        };
        removeRestaurant: (request?: any) => any;
        copyRestaurantMenu: (request?: any) => any;
        changeMenuItemPromosInRestaurantMenu: (request?: any) => any;
        userPassword: {
            fetch: (request?: any) => any;
        };
        getCustomers: (request?: any) => any;
        getCustomerOrders: (request?: any) => any;
        getCustomerGifts: (request: GetCustomerGiftsVmRequest) => any;
        getCustomerTransactions: (request: GetCustomerTransactionsVmRequest) => any;
        getCustomer: (request?: any) => any;
        changeCustomer: (request?: any) => any;
        changeCustomers: (request?: any) => any;
        sendNotificationToCustomer: (request?: any) => any;
        sendNotificationToCustomers: (request?: any) => any;
        sendEmailToCustomer: (request?: any) => any;
        sendEmailToCustomers: (request?: any) => any;
        addCredits: (request?: any) => any;
        removeCredits: (request?: any) => any;
        bulkAddCredits: (request?: any) => any;
        bulkRemoveCredits: (request?: any) => any;
        exportDriverAccounts: (request?: any) => any;
        getDriver: (request?: any) => any;
        getDriverOrders: (request?: any) => any;
        changeDriver: (request?: any) => any;
        changeDrivers: (request?: any) => any;
        sendNotificationToDriver: (request?: any) => any;
        sendNotificationToDrivers: (request?: any) => any;
        sendEmailToDriver: (request?: any) => any;
        sendEmailToDrivers: (request?: any) => any;
        getDriverBonusOffers: (request?: any) => ApiSauceResponse<DriverBonusOffersVm>;
        getDriverBonusOffer: (request: GetDriverBonusOfferRequest) => ApiSauceResponse<DriverBonusOfferVm>;
        createDriverBonusOffer: (request: CreateDriverBonusOfferRequest) => ApiSauceResponse<DriverBonusOfferVm>;
        changeDriverBonusOffer: (request: ChangeDriverBonusOfferRequest) => ApiSauceResponse<DriverBonusOfferVm>;
        removeDriverBonusOffer: (request?: any) => any;
        getOngoingDriverBonuses: () => ApiSauceResponse<OngoingDriverBonusesVm>;
        getBanners: () => any;
        getBanner: (request?: any) => any;
        createBanner: (request?: any) => any;
        changeBanner: (request?: any) => any;
        removeBanner: (request?: any) => any;
        getCompanies: () => any;
        getCompany: (request?: any) => any;
        getCompanyConnectedCustomers: (request?: any) => any;
        getCompanyGifts: (request: GetCompanyGiftsVmRequest) => any;
        registerCompany: (request?: any) => any;
        unregisterCompany: (request?: any) => any;
        changeCompany: (request?: any) => any;
        getBenefitPlans: (request?: any) => any;
        getBenefitPlan: (request?: any) => any;
        createBenefitPlan: (request?: any) => any;
        removeBenefitPlan: (request?: any) => any;
        changeBenefitPlan: (request?: any) => any;
        getEmployees: (request?: any) => any;
        getEmployee: (request?: any) => any;
        addEmployee: (request?: any) => any;
        removeEmployee: (request?: any) => any;
        changeEmployee: (request?: any) => any;
        getUnconnectedCustomers: (request?: any) => any;
        connectCustomerToBenefitPlan: (request?: any) => any;
        getKitchenBillingData: (request?: any) => any;
        getKitchenBillingDataForKitchen: (request?: any) => any;
        exportKitchenBillingData: (request?: any) => any;
        exportKitchenBillingDataForKitchen: (request?: any) => any;
        getCompanyCreditsBillingData: (request?: any) => any;
        getCompanyCreditsBillingDataForBenefitPlan: (request?: any) => any;
        getCompanyPayrollBillingDataForCompany: (request?: any) => any;
        getCompanyPayrollBillingData: (request?: any) => any;
        getRestaurantOptions: (request?: any) => any;
        getRestaurantKitchensAndDeliveryStationsOptions: (request?: any) => any;
        getRestaurantCustomers: (request?: any) => any;
        getDriverOptions: (request?: any) => any;
        getCompanyOptions: (request?: any) => any;
        getCompanyNameOptions: (request?: any) => any;
        getKitchenOptions: (request?: any) => any;
        scheduledTasks: (request?: any) => any;
        scheduledTask: (request?: any) => any;
        createScheduledTask: (request?: any) => any;
        changeScheduledTask: (request?: any) => any;
        removeScheduledTask: (request?: any) => any;
        emails: (request?: any) => any;
        pickupStations: () => any;
        changePickupStations: (request: ChangePickupStationsRequest) => any;
        removeGifts: (request: RemoveGiftsRequest) => any;
    };
    driver: {
        getDeliveryItems: (request?: any) => any;
        completeDeliveryOrders: (request?: any) => any;
    };
};

type GetCustomerGiftsVmRequest = {
    customerId: CustomerId;
};

type GetCustomerTransactionsVmRequest = {
    customerId: CustomerId;
};

type GetCompanyGiftsVmRequest = {
    companyId: CompanyId;
};

type ChangePickupStationsRequest = {
    pickupStationIds: Array<PickupStationId>;
    kitchenIds?: Array<KitchenId>;
    status?: RestaurantStatus;
};

type RemoveGiftsRequest = {
    giftIds: Array<GiftId>;
};
