/**
 * @prettier
 */
import type { CustomerOrderDetailsVm } from 'src/types/CustomerOrderDetailsVm';
import type { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import type { MenuItemVm } from 'src/types/PosMenuVm';
import type { PosPayment } from 'src/types/PosPayment';
import { mapOrderItemsToCartItems } from 'src/utils/pos/mapOrderItemsToCartItems';
import { refreshPosPaymentCustomerState } from 'src/utils/pos/refreshPosPaymentCustomerState';

export function mapOrderCustomersToPosCustomers(orderCustomers: Array<CustomerOrderDetailsVm>, menuItems: Array<MenuItemVm>, posPayments: Array<PosPayment>): Array<PosCustomerOrderDetails> {
    return orderCustomers?.map((customer: CustomerOrderDetailsVm, idx: number) => {
        const newCustomer: PosCustomerOrderDetails = {
            customerNumber: customer.customerNumber,
            orderItems: mapOrderItemsToCartItems({ orderItems: customer.orderItems, menuItems: menuItems }),
            cancelledItems: mapOrderItemsToCartItems({ orderItems: customer.cancelledItems ?? [], menuItems: menuItems }),
        };

        if (customer.posDiscount && customer.posDiscountType) {
            newCustomer.discount = {
                discount: customer.posDiscount,
                discountType: customer.posDiscountType,
                discountPercentage: customer.discountPercentage,
                notes: customer.posDiscountNotes,
            };
        }

        if (customer.tips) {
            newCustomer.tips = customer.tips.map((tip) => ({
                tipType: tip.tipType,
                paymentId: tip.paymentId,
                paymentMethod: tip.paymentMethod,
                customPaymentMethod: tip.customPaymentMethod,
                tipAmount: tip.tipAmount,
            }));
        }

        refreshPosPaymentCustomerState({ customer: newCustomer, payments: posPayments });

        return newCustomer;
    });
}
