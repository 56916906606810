/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { posReducer } from 'src/reducers/posReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangeMenuItemNoteDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const open = useSelector((state) => state.pos.changeMenuItemNoteDialogState.open);
    const note = useSelector((state) => state.pos.changeMenuItemNoteDialogState.note);
    const onSuccess = useSelector((state) => state.pos.changeMenuItemNoteDialogState.onSuccess);

    const closeChangeMenuItemNoteDialog = useAction(posReducer.actions.closeChangeMenuItemNoteDialog);

    const onSubmit = (form: any) => {
        onSuccess?.(form.note);
        closeChangeMenuItemNoteDialog(false);
    };

    const handleClose = () => {
        closeChangeMenuItemNoteDialog(false);
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Edit item note')} classes={{ dialog: (classes as any).dialog }}>
            <Form form={form} onSubmit={onSubmit} className={(classes as any).dialogContainer}>
                <FormTextField name='note' label={translate('Note')} required defaultValue={note} />
                <DialogActions>
                    <Button larger={posInterfaceLargerEnabled} classes={{ button: classes.button }} onClick={handleClose} secondary>
                        {translate('Cancel')}
                    </Button>
                    <Button disabled={form.formState.isSubmitting} larger={posInterfaceLargerEnabled} type={'submit'} classes={{ button: classes.button }}>
                        {translate('Edit')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        width: '50%',
    },
}));
