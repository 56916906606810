/**
 * @prettier
 */
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import { usePosPaymentMethodsStore } from 'src/services/pos/posPaymentMethods/posPaymentMethodsStore';

export function usePaymentMethodSelected(): PaymentMethod | undefined {
    const paymentMethod = usePosPaymentMethodsStore((state) => state.paymentMethod);

    return paymentMethod;
}
