/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SurveyId } from 'src/types/Id';

export async function solveSurveysApi(request: SolveSurveysApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('survey/solveSurveysApi', request);
}

export type SolveSurveysApiRequest = {
    surveyIds: Array<SurveyId>;
};
