/**
 * @prettier
 */
import * as React from 'react';
import { changeSubscriptionPlanApi } from 'src/api/letseatmanager/subscription/changeSubscriptionPlanApi';
import { SubscriptionPlans } from 'src/constants/SubscriptionPlan';
import { BasicRegularPlanCard } from 'src/scenes/letseatmanager/freeSubscriptionPlanEnd/BasicRegularPlanCard';
import { PayPerUsePlanCard } from 'src/scenes/letseatmanager/freeSubscriptionPlanEnd/PayPerUsePlanCard';
import type { DetailSubscriptionPlanVm } from 'src/types/DetailSubscriptionPlanVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';
import { isBasicRegularSubscription } from 'src/utils/subscriptionPlan/isBasicRegularSubscription';
import { isPayPerUseSubscription } from 'src/utils/subscriptionPlan/isPayPerUseSubscription';

export function SubscriptionPlanCard({ detailSubscriptionPlan }: Props): React.ReactElement | null {
    const subscriptionId = useSelector((state) => state.app.restaurant.subscriptionId);
    const handleSelectPlan = async () => {
        const response = await changeSubscriptionPlanApi({
            subscriptionId: requireValue(subscriptionId),
            subscriptionPlan: requireValue(detailSubscriptionPlan.plan),
        });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        window.location.href = '/ongoing-orders';
    };

    if (!isPayPerUseSubscription(detailSubscriptionPlan.plan) && !isBasicRegularSubscription(SubscriptionPlans.BASIC_REGULAR)) return null;

    if (isPayPerUseSubscription(detailSubscriptionPlan.plan)) return <PayPerUsePlanCard detailSubscriptionPlan={detailSubscriptionPlan} onSelectPlan={handleSelectPlan} />;

    return <BasicRegularPlanCard basicRegularPaymentAmount={detailSubscriptionPlan.basicRegularPaymentAmount} onSelectPlan={handleSelectPlan} />;
}
type Props = {
    detailSubscriptionPlan: DetailSubscriptionPlanVm;
};
