/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SurveyAnswer } from 'src/constants/SurveyAnswer';
import type { CustomerId, OrderId, RestaurantId, SurveyId, TipId } from 'src/types/Id';

export async function getSurveyApi(request: GetSurveyApiRequest): ApiSauceResponse<SurveyVm> {
    return letseatadminApiMethod('survey/getSurveyApi', request);
}

export type GetSurveyApiRequest = {
    surveyId: SurveyId;
};

export type SurveyVm = {
    surveyId: SurveyId;
    customerId?: CustomerId;
    restaurantId: RestaurantId;
    orderId: OrderId;
    answered: boolean;
    dismissed?: boolean;
    rating?: number;
    tip?: string;
    tipId?: TipId;
    surveyAnswers?: Array<SurveyAnswer>;
    otherAnswer?: string;
    unsolved?: boolean;
    createdAt: Date;
    answeredAt?: Date;
    solvedAt?: Date;
    modifiedAt: Date;
};
