/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CustomerReturnsVm, getCustomerReturnsByRestaurantIdApi } from 'src/api/letseatadmin/customerReturn/getCustomerReturnsByRestaurantIdApi';
import { Button } from 'src/components/Button';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { useSelector } from 'src/utils/react/useSelector';
import { openCustomerPageInNewTab } from 'src/utils/window/openCustomerPageInNewTab';
import { openOrderPageInNewTab } from 'src/utils/window/openOrderPageInNewTab';

export function CustomerReturnsPage(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState({ customerReturns: [] } as CustomerReturnsVm);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    useEffect(() => {
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await getCustomerReturnsByRestaurantIdApi({ restaurantId: restaurantId });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setVm(response.data);
    };

    return (
        <SubscribedPage title={translate('Customer Return')}>
            <UpdatingContentProgress loading={loading} />
            <Table
                className={classes.table}
                data={
                    vm.customerReturns &&
                    vm.customerReturns.map((customerReturn) => {
                        return {
                            customerReturnId: customerReturn.customerReturnId,
                            customerId: customerReturn.customerId,
                            orderId: customerReturn.orderId,
                            createdAt: formatDateTimeStringReadable(customerReturn.createdAt),
                            sendThanksForOrderingEmailAt: formatDateTimeStringReadable(customerReturn.sendThanksForOrderingEmailAt),
                            thanksForOrderingEmailSentAt: formatDateTimeStringReadable(customerReturn.thanksForOrderingEmailSentAt),
                            failedToSendThanksForOrderingEmail: customerReturn.failedToSendThanksForOrderingEmail ? translate('Failed') : '',
                            sendWelcomeEmailAt: formatDateTimeStringReadable(customerReturn.sendWelcomeEmailAt),
                            welcomeEmailSentAt: formatDateTimeStringReadable(customerReturn.welcomeEmailSentAt),
                            failedToSendWelcomeEmail: customerReturn.failedToSendWelcomeEmail ? translate('Failed') : '',
                            sendMenuHighlightEmailAt: formatDateTimeStringReadable(customerReturn.sendMenuHighlightEmailAt),
                            menuHighlightEmailSentAt: formatDateTimeStringReadable(customerReturn.menuHighlightEmailSentAt),
                            sendPromoCodeEmailAt: formatDateTimeStringReadable(customerReturn.sendPromoCodeEmailAt),
                            failedToSendMenuHighlightEmail: customerReturn.failedToSendMenuHighlightEmail ? translate('Failed') : '',
                            promoCodeEmailSentAt: formatDateTimeStringReadable(customerReturn.promoCodeEmailSentAt),
                            promoCodeSent: customerReturn.promoCodeSent,
                            failedToSendPromoCodeEmail: customerReturn.failedToSendPromoCodeEmail ? translate('Failed') : '',
                            customerReturnedAt: formatDateTimeStringReadable(customerReturn.customerReturnedAt),
                            customerReturnedOrderId: customerReturn.customerReturnedOrderId,
                            customerUsedLink: customerReturn.customerUsedLink,
                        };
                    })
                }
                columns={[
                    {
                        name: 'customerReturnId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'customerId',
                        label: translate('Customer'),
                        options: {
                            filter: false,
                            customBodyRender: (customerId) => (
                                <Button classes={{ button: classes.button }} secondary onClick={() => openCustomerPageInNewTab(customerId)}>
                                    {`${translate('Open')} ${customerId.substring(0, 4)}`}
                                </Button>
                            ),
                        },
                    },
                    {
                        name: 'orderId',
                        label: translate('Order'),
                        options: {
                            filter: false,
                            customBodyRender: (orderId) => (
                                <Button classes={{ button: classes.button }} secondary onClick={() => openOrderPageInNewTab(orderId)}>
                                    {translate('Open Order')}
                                </Button>
                            ),
                        },
                    },
                    {
                        name: 'createdAt',
                        label: translate('Created At'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'sendThanksForOrderingEmailAt',
                        label: translate('Send Thanks For Ordering Email At'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'thanksForOrderingEmailSentAt',
                        label: translate('Thanks For Ordering Email Sent At'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'failedToSendThanksForOrderingEmail',
                        label: translate('Failed To Send Thanks For Ordering Email'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'sendWelcomeEmailAt',
                        label: translate('Send Welcome Email At'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'welcomeEmailSentAt',
                        label: translate('Welcome Email Sent At'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'failedToSendWelcomeEmail',
                        label: translate('Failed To Send Welcome Email'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'sendMenuHighlightEmailAt',
                        label: translate('Send Menu Highlight Email At'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'menuHighlightEmailSentAt',
                        label: translate('Menu Highlight Email Sent At'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'failedToSendMenuHighlightEmail',
                        label: translate('Failed To Send Menu Highlight Email'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'sendPromoCodeEmailAt',
                        label: translate('Send Promo Code Email At'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'promoCodeEmailSentAt',
                        label: translate('Promo Code Email Sent At'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'failedToSendPromoCodeEmail',
                        label: translate('Failed To Send Promo Code Email'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'promoCodeSent',
                        label: translate('Promo Code Sent'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'customerReturnedAt',
                        label: translate('Customer Returned At'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'customerReturnedOrderId',
                        label: translate('Customer Returned OrderId'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'customerUsedLink',
                        label: translate('Customer Used Link'),
                        options: {
                            filter: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'multiple',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                        </>
                    ),
                }}
            />
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
    button: {
        '&:hover': {
            border: `1px solid ${theme.palette.border.brandContrast}`,
        },
    },
}));
