/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function changeRappiIntegrationToPendingApi(request: changeRappiIntegrationToPendingApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('rappiIntegration/changeRappiIntegrationToPendingApi', request);
}

export type changeRappiIntegrationToPendingApiRequest = {
    restaurantId: RestaurantId;
};
