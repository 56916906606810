/**
 * @prettier
 */
export const DriverIncentiveTypes = {
    COMMERCIAL: 'COMMERCIAL',
    CHALLENGE: 'CHALLENGE',
    FIXED: 'FIXED',
    SUPPLIES: 'SUPPLIES',
    SUPPLY: 'SUPPLY',
    WEATHER: 'WEATHER',
    FESTIVE: 'FESTIVE',
} as const;

export type DriverIncentiveType = typeof DriverIncentiveTypes[keyof typeof DriverIncentiveTypes];
