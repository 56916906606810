/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import * as React from 'react';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { CashRegisterInfo } from 'src/scenes/letseatmanager/posBusinessDayReport/CashRegisterInfo';
import type { CashRegisterPosBusinessDayReportVm } from 'src/types/CashRegisterPosBusinessDayReportVm';
import { CashRegisterSummary, type PosBusinessDayReportVm } from 'src/types/PosBusinessDayReportVm';

export function CashRegisterShifts({ posBusinessDay }: Props): React.ReactElement {
    const shouldRenderCashRegisterNumberHeader = posBusinessDay.cashRegistersSummaries?.some((cashRegister: CashRegisterSummary) => !!cashRegister.cashRegisterPosBusinessDayNumber);
    const classes = useStyles({ columns: shouldRenderCashRegisterNumberHeader ? 5 : 4 });

    return (
        <div className={classes.container}>
            <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                <Text title style={{ fontSize: 20 }}>
                    {translate('Cash register shifts')}
                </Text>
                <Tooltip title={translate(`This section doesn't include sales of integration channels`)}>
                    <HelpOutlineIcon fontSize={'small'} classes={{ root: classes.helpIcon }} color={'primary'} />
                </Tooltip>
            </div>
            <div className={classes.tableHeaderRow}>
                <p className={classes.tableHeader}>{translate('Date')}</p>
                {shouldRenderCashRegisterNumberHeader && <p className={classes.tableHeader}>{translate('Cash Register')}</p>}
                <p className={classes.tableHeader}>{translate('User')}</p>
                <p className={classes.tableHeader}>{translate('Total Orders')}</p>
                <p className={classes.tableHeader}>{translate('Total Sales')}</p>
            </div>

            {posBusinessDay?.cashRegistersSummaries.map((cashRegister: CashRegisterSummary, idx: number) => {
                const isLastItem = idx === posBusinessDay?.cashRegistersSummaries?.length - 1;
                return <CashRegisterInfo key={idx} isLastItem={isLastItem} cashRegister={cashRegister} />;
            })}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '24px 0',
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.primary.main,
    },
    tableHeaderRow: (props: any) => ({
        display: 'grid',
        gridTemplateColumns: `repeat(${props.columns ?? 4}, 1fr)`,
        borderBottom: '1px solid #9BA4A6',
    }),
    tableHeader: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
    },
    helpIcon: {
        marginLeft: 5,
        '&.MuiSvgIcon-colorPrimary': {
            color: theme.palette.primary.main,
        },
    },
}));

type Props = {
    posBusinessDay: PosBusinessDayReportVm | CashRegisterPosBusinessDayReportVm;
};
