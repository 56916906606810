/**
 * @prettier
 */
import type { PinCodeUser } from 'src/types/PinCodeUser';
import { CashRegisterPosBusinessDayVm } from 'src/types/PosBusinessDayVm';
import { useSelector } from 'src/utils/react/useSelector';

export function useFindOpenedUserCashRegisterPosBusinessDay(): {
    findOpenedUserCashRegisterPosBusinessDay: (arg1?: PinCodeUser | null | undefined) => CashRegisterPosBusinessDayVm | undefined;
    findUserCashRegisterPosBusinessDay: (arg1?: PinCodeUser | null | undefined) => CashRegisterPosBusinessDayVm | undefined;
    userHadOpenedCashRegisterPosBusinessDay: (arg1?: PinCodeUser | null | undefined) => boolean;
} {
    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);
    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const openedCashRegisterPosBusinessDay = useSelector((state) => state.pos.openedCashRegisterPosBusinessDay);

    const findOpenedUserCashRegisterPosBusinessDay = (pinCodeUser: PinCodeUser | null | undefined) => {
        const managerUserId = pinCodeUser?.managerUserId || openedCashRegisterPosBusinessDay?.assignedToManagerUserId || pinCodeUserSignedIn?.managerUserId;

        return openedPosBusinessDay?.cashRegisterPosBusinessDays?.find(
            (cashRegisterPosBusinessDay: CashRegisterPosBusinessDayVm) => cashRegisterPosBusinessDay.assignedToManagerUserId === managerUserId && cashRegisterPosBusinessDay.opened
        );
    };

    const findUserCashRegisterPosBusinessDay = (pinCodeUser: PinCodeUser | null | undefined) => {
        const managerUserId = pinCodeUser?.managerUserId || openedCashRegisterPosBusinessDay?.assignedToManagerUserId || pinCodeUserSignedIn?.managerUserId;

        return openedPosBusinessDay?.cashRegisterPosBusinessDays?.find(
            (cashRegisterPosBusinessDay: CashRegisterPosBusinessDayVm) => cashRegisterPosBusinessDay.assignedToManagerUserId === managerUserId
        );
    };

    const userHadOpenedCashRegisterPosBusinessDay = (pinCodeUser: PinCodeUser | null | undefined) => {
        const managerUserId = pinCodeUser?.managerUserId || openedCashRegisterPosBusinessDay?.assignedToManagerUserId || pinCodeUserSignedIn?.managerUserId;

        return openedPosBusinessDay?.cashRegisterPosBusinessDays?.some(
            (cashRegisterPosBusinessDay: CashRegisterPosBusinessDayVm) => cashRegisterPosBusinessDay.assignedToManagerUserId === managerUserId
        );
    };

    return { findOpenedUserCashRegisterPosBusinessDay, findUserCashRegisterPosBusinessDay, userHadOpenedCashRegisterPosBusinessDay };
}
