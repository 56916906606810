/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import moment from 'moment/moment';
import { useContext, useEffect } from 'react';
import * as React from 'react';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { OrderTypes } from 'src/constants/OrderType';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { savePosOrderOffline } from 'src/localStorage/savePosOrderOffline';
import { useClearCustomerInfo } from 'src/modules/pos/customer/useClearCustomerInfo';
import { useGetCustomerInfo } from 'src/modules/pos/customer/useGetCustomerInfo';
import { useHasCustomerInfoAnyError } from 'src/modules/pos/customer/useHasCustomerInfoAnyError';
import { posReducer } from 'src/reducers/posReducer';
import { PosContext } from 'src/scenes/letseatmanager/pos/PosProvider';
import { useNotification } from 'src/services/notification/useNotification';
import { useClearPos } from 'src/services/pos/useClearPos';
import { useShouldRenderPosPaymentSecondaryButton } from 'src/services/pos/useShouldRenderPosPaymentSecondaryButton';
import { usePrintOrderCommand } from 'src/services/printer/usePrintOrderCommand';
import { PosPayment } from 'src/types/PosPayment';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { findItemsChangedInOrder } from 'src/utils/pos/findItemsChangedInOrder';
import { posPaymentCreatePaymentLink } from 'src/utils/pos/posPaymentCreatePaymentLink';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSaveNewPosOrder } from 'src/utils/react/useSaveNewPosOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { useUpdatePosPendingOrder } from 'src/utils/react/useUpdatePosPendingOrder';
import { requireValue } from 'src/utils/require/requireValue';

export function PosPaymentSavePendingOrderButton(): React.ReactElement | null {
    const classes = useStyles();
    const notification = useNotification();
    const shouldRenderSecondaryButton = useShouldRenderPosPaymentSecondaryButton();

    const { updateMenuOpenedAt, menuOpenedAt } = useContext(PosContext);

    const hasAnyError = useHasCustomerInfoAnyError();
    const getCustomerInfo = useGetCustomerInfo();
    const clearCustomerInfo = useClearCustomerInfo();

    const { updatePosPendingOrder } = useUpdatePosPendingOrder();
    const { saveNewPosOrder } = useSaveNewPosOrder();
    const [printOrderCommand] = usePrintOrderCommand();
    const clearPos = useClearPos();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const total = useSelector((state) => state.pos.payment?.total);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const posPendingOrdersEnabled = useSelector((state) => state.pos.context?.posPendingOrdersEnabled);
    const orderType = useSelector((state) => state.pos.orderType);
    const driverRequest = useSelector((state) => state.pos.driverRequest);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const disabledPosPaymentActions = useSelector((state) => state.pos.disabledPosPaymentActions);
    const paying = useSelector((state) => state.pos.paying);
    const totalPaid = useSelector((state) => state.pos.totalPaid);
    const table = useSelector((state) => state.pos.table);
    const payments = useSelector((state) => state.pos.payments);
    const posNumberOfCustomers = useSelector((state) => state.pos.numberOfCustomers);
    const posCustomersRequiredEnabled = useSelector((state) => state.app.restaurant?.posCustomersRequiredEnabled);

    const updateOrder = useAction(app2.actions.updateOrder);
    const setPendingOrder = useAction(posReducer.actions.setPendingOrder);

    const disabled = disabledPosPaymentActions || paying;
    const hasPaymentMissing = BigNumber(totalPaid).isLessThan(total);

    useEffect(() => {
        onChangePosPayments();
    }, [payments]);

    const onChangePosPayments = async () => {
        if (!payments.length) return;
        if (orderType === OrderTypes.DELIVERY_ORDER) return;

        if (pendingOrder) {
            if (pendingOrder?.payments?.length === payments.length) return;
            await updatePaymentsOfPendingOrder();
            return;
        }

        const hasCardPayment = payments?.some((payment: PosPayment) => isCardPayment(payment.paymentMethod) && !payment.customPaymentMethod);
        if (hasCardPayment) await updateCardPaymentsOfNewOrder();
    };

    const updatePaymentsOfPendingOrder = async () => {
        if (hasAnyError) {
            notification({ message: translate('There is pending data or an error in the customer data') });
            return;
        }
        const { firstName, lastName, mobileNumber, numberOfCustomers, email, customerNote } = getCustomerInfo();

        const { response, request } = await updatePosPendingOrder({
            firstName,
            lastName,
            mobileNumber,
            email,
            customerNote,
        });

        if (!response.ok) {
            if (response.problem === 'NETWORK_ERROR') {
                savePosOrderOffline(request);
            }

            return alertKnownErrorOrSomethingWentWrong(response);
        }

        updateOrder({ order: response.data });
    };

    const updateCardPaymentsOfNewOrder = async () => {
        if (disabledPosPaymentActions) return;

        const { firstName, lastName, mobileNumber, numberOfCustomers, email, customerNote } = getCustomerInfo();

        const { response, request } = await saveNewPosOrder({
            firstName,
            lastName,
            mobileNumber,
            email,
            customerNote,
            numberOfCustomers: parseFloat(numberOfCustomers?.toString()!) ?? parseFloat(table?.numberOfCustomers?.toString()!) ?? parseFloat(posNumberOfCustomers?.toString()),
            orderStatus: OrderStatuses.PENDING,
        });

        if (!response.ok) {
            if (response.problem === 'NETWORK_ERROR') {
                savePosOrderOffline(request);
            }

            return alertKnownErrorOrSomethingWentWrong(response);
        }

        setPendingOrder(response.data);
        updateMenuOpenedAt(moment(response.data.createdAt).toDate());
    };

    const updatePendingOrder = async () => {
        if (disabledPosPaymentActions) return;
        if (orderType === OrderTypes.DELIVERY_ORDER) requireValue(driverRequest);
        const { firstName, lastName, mobileNumber, numberOfCustomers, email, customerNote } = getCustomerInfo();

        const { response, request } = await updatePosPendingOrder({
            firstName,
            lastName,
            mobileNumber,
            email,
            customerNote,
        });

        const itemsToPrint = findItemsChangedInOrder(pendingOrder?.orderItems ?? ([] as any), request.orderItems);
        const removedItemsToPrint = findItemsChangedInOrder(pendingOrder?.cancelledItems ?? ([] as any), request.cancelledItems);

        if (!response.ok) {
            if (response.problem === 'NETWORK_ERROR') {
                savePosOrderOffline(request);
                const orderCommand = { ...pendingOrder, orderItems: itemsToPrint, cancelledItems: removedItemsToPrint } as any;
                await printOrderCommand(orderCommand);
                clearPos();
                clearCustomerInfo();

                return;
            }
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        if (isPaymentLinkPayment(response?.data?.paymentMethod)) {
            await posPaymentCreatePaymentLink({ orderId: response.data.orderId, restaurantId, totalWithDeliveryCost: total });
        }

        const orderCommand = { ...response.data, orderItems: itemsToPrint, cancelledItems: removedItemsToPrint } as any;

        updateOrder({ order: response.data });
        clearPos();

        await printOrderCommand(orderCommand);
    };

    const savePendingOrder = async () => {
        if (disabledPosPaymentActions) return;
        if (hasAnyError) {
            notification({ message: translate('There is pending data or an error in the customer data') });
            return;
        }
        if (hasPaymentMissing && (!posPendingOrdersEnabled || isDeliveryOrder(orderType))) {
            alert(translate('There is pending balance.'));
            return;
        }
        const { firstName, lastName, mobileNumber, numberOfCustomers, email, customerNote } = getCustomerInfo();

        if (posCustomersRequiredEnabled && !numberOfCustomers && pendingOrder?.table) {
            return notification({ message: translate('Number of customers is required') });
        }

        const { response, request } = await saveNewPosOrder({
            firstName,
            lastName,
            mobileNumber,
            email,
            customerNote,
            numberOfCustomers: parseFloat(numberOfCustomers?.toString()!) ?? parseFloat(table?.numberOfCustomers?.toString()!) ?? parseFloat(posNumberOfCustomers?.toString()),
            orderStatus: OrderStatuses.PENDING,
        });

        if (!response.ok) {
            if (response.problem === 'NETWORK_ERROR') {
                savePosOrderOffline(request as any);
                clearPos();
                clearCustomerInfo();
                return;
            }
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        if (isPaymentLinkPayment(response?.data?.paymentMethod)) {
            await posPaymentCreatePaymentLink({ orderId: response.data.orderId, restaurantId, totalWithDeliveryCost: total });
            notification({ message: translate('Payment Link copied to clipboard') });
        }

        notification({ message: translate('Order saved') });

        clearPos();
        await printOrderCommand(response.data);
    };

    const getSecondaryButtonText = () => {
        if (!pendingOrder && (BigNumber(total).isZero() || hasPaymentMissing)) return translate('Save Order');

        return translate('Save Without Charging');
    };

    if (!shouldRenderSecondaryButton) return null;

    return (
        <Button
            classes={{ button: classNames(classes.button, posInterfaceLargerEnabled ? classes.largerButton : '') }}
            secondary
            onClick={!!pendingOrder ? updatePendingOrder : savePendingOrder}
            disabled={disabled}
        >
            {getSecondaryButtonText()}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        maxHeight: 50,
        height: 50,
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            order: 1,
        },
    },
    largerButton: {
        order: 1,
        width: '100%',
        height: 52,
        fontSize: 15,
    },
}));
