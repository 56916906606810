/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';

export function AddNoteToOrderDialog({ open, actualNote, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    useEffect(() => {
        if (actualNote) form.reset({ note: actualNote });

        return () => {
            form.reset({});
        };
    }, [actualNote]);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        onSuccess(form.note);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={actualNote ? translate('Edit Comment') : translate('Add Comment')}>
            <Form form={form} onSubmit={onSubmit}>
                <FormTextField required={!actualNote} name='note' label={translate('Comment')} classes={{ input: classes.inputNote, error: classes.inputNoteError }} multiline defaultValue={''} />
                <DialogActions>
                    <Button secondary onClick={onClose}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit'>{translate('Save Comment')}</Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    inputNote: {
        height: '15vh',
        alignItems: 'flex-start',
    },
    inputNoteError: {
        border: '2px solid red',
    },
}));

type Props = {
    open: boolean;
    actualNote?: string;
    onClose: any;
    onSuccess: any;
};
