/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BuyOrderVm } from 'src/types/BuyOrderVm';
import type { RestaurantId } from 'src/types/Id';

export async function findBuyOrdersByRestaurantIdApi(request: FindBuyOrdersApiRequest): ApiSauceResponse<Array<BuyOrderVm>> {
    return letseatmanagerApiMethod('buyOrder/findBuyOrdersApi', request);
}

type FindBuyOrdersApiRequest = {
    restaurantId: RestaurantId;
};
