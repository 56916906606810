/**
 * @prettier
 */

import { Country } from 'src/constants/Country';
import { isArgentina } from 'src/utils/country/isArgentina';
import { isPeru } from 'src/utils/country/isPeru';
import { isUruguay } from 'src/utils/country/isUruguay';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';

export function getDirectoCountryUrl(country: Country): string {
    const pidedirecto = isProductionEnvironment() ? 'pidedirecto' : 'dev.pidedirecto';

    if (isUruguay(country)) return `${pidedirecto}.uy`;
    if (isPeru(country)) return `${pidedirecto}.pe`;
    if (isArgentina(country)) return `${pidedirecto}.ar`;
    return `${pidedirecto}.mx`;
}
