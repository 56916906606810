/**
 * @prettier
 */
import { NegativeSurveyAnswers } from 'src/constants/NegativeSurveyAnswer';
import { PositiveSurveyAnswers } from 'src/constants/PositiveSurveyAnswer';

export const SurveyAnswers = {
    ...NegativeSurveyAnswers,
    ...PositiveSurveyAnswers,
} as const;

export type SurveyAnswer = typeof SurveyAnswers[keyof typeof SurveyAnswers];
