/**
 * @prettier
 */
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { acceptOrderApi } from 'src/api/letseatmanager/order/acceptOrderApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { OrderDetailsDialog } from 'src/components/orderCard/OrderDetailsDialog';
import { SecuredContent } from 'src/components/SecuredContent';
import { Text } from 'src/components/Text';
import { PickupTimeTypes } from 'src/constants/PickupTimeType';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { BellRingingIcon } from 'src/icons/BellRingingIcon';
import { CloseIcon } from 'src/icons/CloseIcon';
import { useFetchCashRegister } from 'src/services/cashRegister/useFetchCashRegister';
import { useHasOpenedCashRegister } from 'src/services/cashRegister/useHasOpenedCashRegister';
import { useManageOpenCashRegister } from 'src/services/cashRegister/useManageOpenCashRegister';
import { LocalizationService } from 'src/services/LocalizationService';
import { useNotification } from 'src/services/notification/useNotification';
import { usePrintOrderCommand } from 'src/services/printer/usePrintOrderCommand';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import { SentryService } from 'src/services/SentryService';
import { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isToday } from 'src/utils/date/isToday';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function IncomingSingleOrderAlert(): React.ReactElement | null {
    const classes = useStyles();
    const theme = useTheme();

    const notification = useNotification();

    const [printOrderTicket] = usePrintOrderTicket();
    const [printOrderCommand] = usePrintOrderCommand();

    const hasOpenedCashRegister = useHasOpenedCashRegister();
    const { fetchOpenedCashRegister } = useFetchCashRegister();
    const { manageOpenCashRegister } = useManageOpenCashRegister();

    const [orderDialogState, setOrderDialogState] = useState<{ open: boolean; order: OrderVm | undefined }>({ open: false, order: undefined });
    const [loading, setLoading] = useState(false);

    const open = useSelector((state) => state.app2.incomingOrdersAlertState.open);
    const newOrders = useSelector((state) => state.app2.incomingOrdersAlertState.newOrders);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);
    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);
    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const openedCashRegister = useSelector((state) => state.pos.openedCashRegister);
    const openedCashRegisterPosBusinessDay = useSelector((state) => state.pos.openedCashRegisterPosBusinessDay);

    const closeIncomingOrdersAlert = useAction(app2.actions.closeIncomingOrdersAlert);
    const openRejectOrderDialog = useAction(app2.actions.openRejectOrderDialog);

    const order = newOrders ? newOrders[0] : undefined;
    const isPlannedOrder = order?.pickupTimeType === PickupTimeTypes.PLANNED;
    const pickUpTime = isPlannedOrder ? LocalizationService.formatDateTimeAsTime(order?.pickupTime, order?.timeZone) : '';

    useEffect(() => {
        if (pinCodeUserSignedIn && !openedCashRegister) {
            fetchOpenedCashRegister();
        }
    }, [pinCodeUserSignedIn]);

    const closeOrderDetailsDialog = () => {
        setOrderDialogState({ open: false, order: undefined });
        closeIncomingOrdersAlert();
    };

    const handleSeeOrder = () => setOrderDialogState({ open: true, order });

    const handleRejectOrder = async () => {
        if (!order) return;

        openRejectOrderDialog({
            orderId: order.orderId,
            onReject: () => {
                closeIncomingOrdersAlert();
            },
        });
    };

    async function handleAcceptOrder() {
        if (!posEnabled) {
            await acceptOrder();
            return;
        }

        if ((!hasOpenedCashRegister && !posMultipleCashRegistersEnabled) || (posMultipleCashRegistersEnabled && !openedCashRegister)) {
            notification({ message: translate('To accept an order you must open the cash register') });

            manageOpenCashRegister({ forceOpenCashRegister: true });
            return;
        }

        await acceptOrder();
    }

    const acceptOrder = async () => {
        if (!order) return;

        setLoading(true);
        const response = await acceptOrderApi({
            orderId: order.orderId,
            cashRegisterId: openedCashRegister?.cashRegisterId,
            cashRegisterPosBusinessDayId: openedCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId,
            posBusinessDayId: openedPosBusinessDay?.posBusinessDayId,
        });

        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            SentryService.logError('Failed to accept order', {
                acceptOrderRequest: {
                    orderId: order.orderId,
                },
                response,
            });
            return;
        }
        closeIncomingOrdersAlert();

        await printOrderTicket(order);
        await printOrderCommand(order);
        SentryService.logInfoBreadcrumb('Successfully accepted order', {
            acceptOrderRequest: {
                orderId: order.orderId,
            },
        });
    };

    if (!open) return null;
    if (newOrders.length > 1) return null;
    if (!order) return null;

    return (
        <>
            {!!orderDialogState.order && <OrderDetailsDialog open={orderDialogState.open} onClose={closeOrderDetailsDialog} order={order} />}
            <Alert classes={{ root: classes.alertContainer }} icon={<BellRingingIcon color={theme.palette.icons.brand} />}>
                <div className={classes.alertContent}>
                    <div className={classes.alertTitle}>
                        <div>
                            <Text variant='semiBold' style={{ fontSize: 16 }}>
                                {translate('You have a new order')}
                            </Text>
                            <Text style={{ fontSize: 12, color: theme.palette.text.secondary }}>
                                {isToday(order.createdAt, order.timeZone) ? LocalizationService.formatDateTimeAsCalendarDate(order.createdAt) : ''}
                            </Text>
                        </div>
                        <Button icon onClick={closeOrderDetailsDialog} classes={{ button: classes.iconButon }}>
                            <CloseIcon color={theme.palette.icons.primary} />
                        </Button>
                    </div>
                    <div>
                        {brandOpened && <Text variant='medium'>{order.restaurant?.name}</Text>}
                        {isPlannedOrder && <Text style={{ color: theme.palette.text.secondary }}>{translate('Planned order: @pickUpTime', { pickUpTime })}</Text>}
                    </div>
                    <div className={classes.actionsContainer}>
                        <Button onClick={handleSeeOrder} text disabled={loading}>
                            {translate('See order')}
                        </Button>
                        <div className={classes.buttonsContainer}>
                            <SecuredContent rolePermission={RolePermissions.REJECT_ORDERS}>
                                <Button secondary onClick={handleRejectOrder} disabled={loading}>
                                    <Text error>{translate('Reject')}</Text>
                                </Button>
                            </SecuredContent>
                            <Button onClick={handleAcceptOrder} disabled={loading} outlined>
                                {translate('Accept')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Alert>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    alertContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        fontFamily: theme.typography.medium,
        position: 'absolute',
        top: 100,
        background: theme.palette.surface.primary,
        borderRadius: 8,
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
        width: 'fit-content',
        zIndex: 10,
        [theme.breakpoints.up('sm')]: {
            minWidth: 300,
            right: 40,
        },
    },
    alertTitle: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    buttonsContainer: {
        display: 'flex',
        gap: 12,
    },
    alertContent: {
        minWidth: 350,
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    iconButon: {
        width: 28,
        height: 28,
        minHeight: 0,
        padding: 8,
    },
}));
