/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { createContext, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { Button } from 'src/components/Button';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { ArrowDownIcon } from 'src/icons/ArrowDownIcon';
import { isArray } from 'src/utils/array/isArray';
import { classNames } from 'src/utils/react/classNames';
import { useHasClickedOutside } from 'src/utils/react/useHasClickedOutside';

/**
 * @callback onChange
 */

/**
 * Renders a drop-down component
 * @param {string} label - Label for the dropdown
 * @param {React.Node} [children] - Elements to be rendered as list, each of these elements should be a DropDownItem component in order to work correctly
 * @param {boolean} [selectable=true] - Set the drop-down is selectable, this is true by default
 * @param {boolean} [multiselectable] - Set the drop-down to be multi-selectable, indicates if the drop-down can select multiple items at the same time
 * @param {boolean} [disabled] - Set the drop-down disabled
 * @param {boolean} [labelAsPlaceholder] - Remove the label element and put it as a placeholder
 * @param {Object} [classes] - Classes to override dropdown style
 * @param {string} [classes.button] - Class to override button dropdown style
 * @param {string} [classes.dropdown] - Class to override dropdown style
 * @param {onChange} [onChange] - Function to be called when the selection of the drop-down changes
 * @returns {React.Node}
 * @constructor
 */
export function DropDown({ label, children, selectable, multiselectable, disabled, labelAsPlaceholder, classes: classesProp, defaultValues, onChange, tooltipText }: Props): React.ReactElement {
    const classes = useStyles();
    const dropDownContainerRef = useRef<HTMLDivElement>(null);
    const dropDownRef = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(undefined);
    const [values, setValues] = useState<string | Array<any>>([]);
    const [selectedLabel, setSelectedLabel] = useState('');

    useHasClickedOutside({
        element: dropDownContainerRef.current,
        onClick: ({ hasClickedOutside, elementClicked }) => {
            if (hasClickedOutside && dropDownRef.current && !dropDownRef.current.contains(elementClicked)) setIsOpen(false);
        },
    });

    useEffect(() => {
        if (defaultValues) {
            if (multiselectable && isArray(defaultValues)) setValues(defaultValues);
            else setValue(defaultValues[0]);
        }
    }, [defaultValues, multiselectable]);

    const normalizeLabel = () => {
        if (!label) return '';
        return label.replace(/\s+/g, '').toLowerCase();
    };

    const handleItem = ({ value, label }: { value: any; label: string }) => {
        if (multiselectable) return handleMultiselectable(value);
        if (!selectable) return [];
        setValue(value);
        setSelectedLabel(label);
        onChange?.([value]);
        setIsOpen(false);
    };

    const handleMultiselectable = (newValue: any) => {
        if (!isArray(values)) return;
        const valuesHasAlreadyBeenSelected = values.find((value) => value === newValue);
        let newValues = [...values];
        if (valuesHasAlreadyBeenSelected) {
            newValues = newValues.filter((value) => value !== newValue);
        } else {
            newValues.push(newValue);
        }
        setValues(newValues);
        onChange?.(newValues);
    };

    const getSelectedValues = (): Array<any> | string => {
        if (selectable) return [value];
        if (multiselectable) return values;
        return [];
    };

    const getDisplayValue = () => {
        if (multiselectable && labelAsPlaceholder) {
            if (values.length === 0) {
                return label;
            } else {
                return `${label ?? ''} (${values.length})`;
            }
        }
        if (multiselectable && !labelAsPlaceholder) {
            if (values.length === 0) {
                return translate('Select an option');
            } else {
                return translate('Selections (@values)', { values: values.length });
            }
        }

        if (selectable && value) return selectedLabel;
        if (!labelAsPlaceholder && !value) return translate('Select an option');
        return label;
    };

    const getDropDownLeft = () => {
        if (!dropDownContainerRef.current) return;
        return dropDownContainerRef.current.getBoundingClientRect().left;
    };

    const getDropDownTop = () => {
        if (!dropDownContainerRef.current) return;
        return dropDownContainerRef.current?.getBoundingClientRect().top + (dropDownContainerRef.current?.clientHeight || 0) + 10;
    };

    return (
        <div ref={dropDownContainerRef} className={classes.container}>
            {!labelAsPlaceholder && (
                <label className={classes.label} id={`listbox-${normalizeLabel()}-label`} htmlFor={`listbox-${normalizeLabel()}-button`}>
                    {label}
                    {tooltipText && <Tooltip text={tooltipText} />}
                </label>
            )}
            <Button
                id={`listbox-${normalizeLabel()}-button`}
                classes={{ button: classNames(classes.button, classesProp?.button) }}
                secondary
                disabled={disabled}
                onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
            >
                <div className={classes.textContainer}>{getDisplayValue()}</div>
                <ArrowDownIcon className={classNames(classes.icon, isOpen ? classes.iconUp : '')} />
            </Button>
            {isOpen &&
                createPortal(
                    <div
                        role='listbox'
                        className={classNames(classes.dropdown, classesProp?.dropdown)}
                        aria-labelledby={labelAsPlaceholder ? `listbox-${normalizeLabel()}-button` : `listbox-${normalizeLabel()}-label`}
                        aria-activedescendant={value ? `option-${value}` : undefined}
                        aria-multiselectable={multiselectable}
                        aria-readonly={!multiselectable && !selectable}
                        style={{ left: getDropDownLeft(), top: getDropDownTop() }}
                        ref={dropDownRef}
                    >
                        <DropDownContext.Provider value={{ selectedValues: getSelectedValues(), onClickItem: handleItem }}>{children}</DropDownContext.Provider>
                    </div>,
                    document.body
                )}
        </div>
    );
}

export const DropDownContext = createContext<Context>({ selectedValues: [], onClickItem: () => {} });

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        width: 'fit-content',
        height: 'fit-content',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    icon: {
        position: 'absolute',
        right: 13,
        width: 11,
        transform: 'rotate(0deg)',
        transition: '300ms ease-in-out',
    },
    iconUp: {
        transform: 'rotate(-180deg)',
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        width: 'max-content',
        maxWidth: '100%',
        paddingRight: 34,
        '&:focus': {
            border: `2px solid ${theme.palette.surface.brandContrast}`,
            outline: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        marginTop: 4,
    },
    textContainer: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dropdown: {
        position: 'absolute',
        top: '120%',
        minWidth: 250,
        maxHeight: 400,
        overflowY: 'auto',
        listStyle: 'none',
        backgroundColor: theme.palette.surface.primary,
        borderRadius: 12,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        boxShadow: '0px 4px 4px rgb(0,0,0,0.25)',
        zIndex: 1000,
    },
    label: {
        fontSize: 14,
        color: theme.palette.text.primary,
        fontFamily: theme.typography.regular,
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        marginBottom: 4,
    },
}));

type Props = {
    label: string | null | undefined;
    multiselectable?: boolean;
    disabled?: boolean;
    selectable?: boolean;
    labelAsPlaceholder?: boolean;
    defaultValues?: string | Array<any>;
    classes?: {
        button?: string;
        dropdown?: string;
    };
    children: React.ReactNode;
    onChange?: any;
    tooltipText?: string;
};

type Context = {
    selectedValues: Array<any> | string;
    onClickItem: any;
};
