/**
 * @prettier
 */
import { InputBase, InputLabel, makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import * as React from 'react';
import { useContext, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { FormContext } from 'src/components/form/Form';
import { getError } from 'src/components/form/utils/getError';
import { transformFloatInput } from 'src/components/form/utils/transformFloatInput';
import { transformFloatOutput } from 'src/components/form/utils/transformFloatOutput';
import { translate } from 'src/i18n/translate';

export function FormIntegerFieldWithIncrementalButtons({ name, disabled, helperText, defaultValue = null, required, min = 0, max, text, label }: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>();
    const classes = useStyles();

    const error = !!getError(errors, name);

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <div>
                    <div className={classes.container}>
                        <InputLabel classes={{ root: classes.label, error: classes.labelError }} error={error} required={required}>
                            {label}
                        </InputLabel>
                        <div aria-label='small outlined button group' className={classes.inputContainer}>
                            <IconButton
                                onClick={() => {
                                    const newValue = value - 5;
                                    if (newValue < min) return;
                                    onChange(newValue);
                                }}
                                className={classes.buttons}
                            >
                                -
                            </IconButton>
                            <div className={classes.label}>
                                <InputBase
                                    {...props}
                                    value={transformFloatInput(value)}
                                    onChange={(value) => onChange(transformFloatOutput(value as any))}
                                    disabled={isSubmitting || disabled || formContext.disabled}
                                    required={required}
                                    error={error}
                                    inputRef={inputRef}
                                    inputComponent={NumberFormatCustom as any}
                                    classes={{ root: classes.input, error: classes.inputError }}
                                    className={classes.inputField}
                                />
                                {text}
                            </div>
                            <IconButton
                                onClick={() => {
                                    const newValue = value + 5;
                                    if (newValue > (max as any)) return;
                                    onChange(newValue);
                                }}
                                className={classes.buttons}
                            >
                                +
                            </IconButton>
                        </div>
                    </div>
                    <FormHelperText classes={{ root: !!getError(errors, name) ? classes.helperTextError : classes.helperText }}>{getError(errors, name)?.message ?? helperText}</FormHelperText>
                </div>
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
                min: {
                    value: (min as any)?.value ?? min,
                    message: (min as any)?.message ?? translate('Must be number equal to or larger than @number', { number: (min as any)?.value ?? min }),
                },
                max: {
                    value: (max as any)?.value ?? max,
                    message: (max as any)?.message ?? translate('Must be number equal to or less than @number', { number: (max as any)?.value ?? max }),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export function NumberFormatCustom({
    inputRef,
    thousandSeparator,
    onChange,
    min,
    ...other
}: {
    inputRef: any;
    thousandSeparator: boolean;
    name: string;
    onChange: any;
    min?: number;
}): React.ReactElement {
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => onChange(values.value)}
            thousandSeparator={thousandSeparator}
            decimalScale={0}
            allowNegative={min === undefined ? true : min < 0}
        />
    );
}

export type Props = {
    text?: string;
    name: string;
    helperText?: string;
    label: string;
    disabled?: boolean;
    defaultValue: number | null;
    required?: boolean;
    min?: number;
    max?: number;
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '20vw',
        height: '3vw',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            alignItems: 'center',
        },
    },
    label: {
        fontFamily: theme.typography.medium,
        color: theme.palette.secondary.contrastText,
        fontSize: 15,
        marginBottom: 5,
    },
    '&:hover': {
        backgroundColor: 'green',
    },

    buttons: {
        color: theme.palette.primary.main,
        fontFamily: 15 as any, // TODO: correct this, this should not be a number
        width: 40,
        height: 40,
        borderRadius: 40,
        flexShrink: 0,
        padding: 0,
    },
    inputField: {
        backgroundColor: 'white',
        width: '7vw',
    },
    input: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 5,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontFamily: theme.typography.regular,
        marginRight: 5,
    },
    inputError: {
        border: '2px solid red',
    },
    labelError: {
        color: 'red',
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: theme.palette.secondary.contrastText,
    },
    helperTextError: {
        fontFamily: theme.typography.light,
        color: 'red',
    },
}));
