/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { Spacing } from 'src/components/spacing/Spacing';
import { Tooltip } from 'src/components/Tooltip';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { BenefitPlanId, EmployeeIdAtCompany, EmployeeNumberAtCompany } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { deleteUrlQueryParameter } from 'src/utils/history/deleteUrlQueryParameter';
import { hasUrlQueryParameter } from 'src/utils/history/hasUrlQueryParameter';
import { isValidEmployeePassword } from 'src/utils/is/isValidEmployeePassword';
import { required } from 'src/utils/Validator';

export default withRouter(
    withStyles((theme) => ({}))(
        class AddEmployeeDialog extends React.PureComponent<Props, State> {
            state: any = INITIAL_STATE;

            async componentDidMount(): Promise<void> {
                const addEmployee: boolean = hasUrlQueryParameter(ADD_EMPLOYEE, this.props.location);
                if (addEmployee) {
                    await this.openDialog();
                }
            }

            async componentDidUpdate(prevProps: Props) {
                const prevAddEmployee: boolean = hasUrlQueryParameter(ADD_EMPLOYEE, prevProps.location);
                const addEmployee: boolean = hasUrlQueryParameter(ADD_EMPLOYEE, this.props.location);
                if (addEmployee && !prevAddEmployee && !this.state.open) {
                    await this.openDialog();
                } else if (!addEmployee && prevAddEmployee && this.state.open) {
                    this.closeDialog();
                }
            }

            componentWillUnmount() {}

            handleClose = () => {
                if (this.state.submitting) {
                    return;
                }
                if (history.length <= 2) {
                    history.replace({
                        path: this.props.location.pathname,
                        search: deleteUrlQueryParameter(ADD_EMPLOYEE),
                    });
                    return;
                }
                history.goBack();
            };

            handleExited = () => {
                this.setState(INITIAL_STATE as any);
            };

            openDialog = () => {
                this.setState({ open: true });
            };

            closeDialog = () => {
                this.setState({ open: false });
            };

            onSubmit = async (form: any) => {
                if (!!form.employee.password && !isValidEmployeePassword(form.employee.password)) {
                    this.setState({ errorMessage: translate('Password must be numeric and 4 characters long') });
                    return;
                }

                this.setState({ submitting: true });
                const request: AddEmployeeRequest = {
                    benefitPlanId: this.props.benefitPlanId,
                    firstName: form.employee.firstName,
                    lastName: form.employee.lastName,
                    email: form.employee.email,
                    password: form.employee.password,
                    employeeIdAtCompany: form.employee.employeeIdAtCompany,
                    employeeNumberAtCompany: form.employee.employeeNumberAtCompany,
                };
                const response = await letseatmanagerApiDeprecated.admin.addEmployee(request);
                this.setState({ submitting: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    return;
                }
                this.props.onEmployeeAdded();
                this.handleClose();
            };

            render() {
                return (
                    <Dialog aria-labelledby='add-employee-dialog-title' fullWidth scroll='body' open={this.state.open} onClose={this.handleClose} onExited={this.handleExited}>
                        <Form
                            onSubmit={this.onSubmit}
                            /* @ts-ignore */
                            initialValues={{
                                employee: this.state.employee,
                            }}
                            render={({ handleSubmit, submitting, pristine, values }: any) => {
                                return (
                                    <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                                        <DialogTitle id='add-employee-dialog-title'>{translate('Add Employee').toUpperCase()}</DialogTitle>
                                        <DialogContent>{this.renderDialogContent(values)}</DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleClose} disabled={this.state.submitting}>
                                                {translate('Cancel').toUpperCase()}
                                            </Button>
                                            <Button color='primary' type='submit' disabled={this.state.submitting}>
                                                {this.state.submitting ? translate('Adding').toUpperCase() : translate('Add').toUpperCase()}
                                            </Button>
                                        </DialogActions>
                                        <UpdatingContentProgress loading={this.state.submitting} top />
                                    </form>
                                );
                            }}
                        />
                    </Dialog>
                );
            }

            renderDialogContent(values: any) {
                return (
                    <>
                        <Grid container spacing={3}>
                            {/*<Grid item xs={12}>*/}
                            {/*    <Field name="employee.firstName"*/}
                            {/*           label={translate('First Name')}*/}
                            {/*           component={TextField}*/}
                            {/*           fullWidth*/}
                            {/*           disabled={this.state.submitting}*/}
                            {/*           required*/}
                            {/*           validate={required}*/}
                            {/*           inputProps={{ autoComplete: 'no' }}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={12}>*/}
                            {/*    <Field name="employee.lastName"*/}
                            {/*           label={translate('Last Name')}*/}
                            {/*           component={TextField}*/}
                            {/*           fullWidth*/}
                            {/*           disabled={this.state.submitting}*/}
                            {/*           required*/}
                            {/*           validate={required}*/}
                            {/*           inputProps={{ autoComplete: 'no' }}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            <Grid item xs={12}>
                                <Field
                                    /* @ts-ignore */
                                    name='employee.email'
                                    label={translate('Email')}
                                    component={TextField}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    required
                                    validate={required}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    /* @ts-ignore */
                                    name='employee.employeeIdAtCompany'
                                    label={translate('Employee Id (At Company)')}
                                    component={TextField}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    /* @ts-ignore */
                                    name='employee.employeeNumberAtCompany'
                                    label={translate('Employee Number (At Company)')}
                                    component={TextField}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>
                        </Grid>
                        <Tooltip text={translate('Password used for letseat kiosk customers')}>
                            <Grid item xs={12}>
                                <Field
                                    /* @ts-ignore */
                                    name='employee.password'
                                    component={TextField}
                                    label={translate('Password')}
                                    fullWidth
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>
                        </Tooltip>
                        <Grid container justify='space-around'>
                            <Grid item>
                                <Spacing units={8} />
                                {this.state.errorMessage && (
                                    <Typography display='block' color='error'>
                                        {this.state.errorMessage}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </>
                );
            }
        }
    ) as React.ComponentType<any>
) as React.ComponentType<any>;

export const ADD_EMPLOYEE = 'add-employee';
const INITIAL_STATE = {
    open: false,
    employee: {
        firstName: '',
        lastName: '',
        email: '',
        employeeIdAtCompany: '',
        employeeNumberAtCompany: '',
    },
    submitting: false,
} as const;

type Props = {
    classes: any;
    location: {
        pathname: string;
    };
    benefitPlanId: BenefitPlanId;
    onEmployeeAdded: any;
};

type State = {
    open: boolean;
    submitting: boolean;
    employee: {
        firstName?: string;
        lastName?: string;
        email?: string;
        employeeIdAtCompany?: string;
        employeeNumberAtCompany?: string;
    };
    errorMessage?: string;
};

type AddEmployeeRequest = {
    benefitPlanId: BenefitPlanId;
    email: string;
    password?: string;
    employeeIdAtCompany?: EmployeeIdAtCompany;
    employeeNumberAtCompany?: EmployeeNumberAtCompany;
    firstName: string;
    lastName: string;
    paused?: boolean | null | undefined;
};
