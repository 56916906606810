/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { BannerVm, findBannersApi } from 'src/api/letseatmanager/banner/findBannersApi';
import { removeBannerApi } from 'src/api/letseatmanager/banner/removeBannerApi';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ChangeBannerDialog } from 'src/scenes/letseatmanager/marketing/ChangeBannerDialog';
import { CreateBannerDialog } from 'src/scenes/letseatmanager/marketing/CreateBannerDialog';
import { BannerId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useSelector } from 'src/utils/react/useSelector';

export function BannersTable(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [banners, setBanners] = useState<Array<BannerVm>>([]);
    const [changeBannerDialogState, setChangeBannerDialogState] = useState({ open: false, bannerId: undefined });
    const [createBannerDialogState, setCreateBannerDialogState] = useState({ open: false });

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const marketingActionsForRestaurantEnabled = useSelector((state) => state.app.restaurant.marketingActionsForRestaurantEnabled);

    useEffect(() => {
        load();
    }, [restaurantIds]);

    const load = async () => {
        setLoading(true);
        const response = await findBannersApi({
            restaurantIds,
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        setBanners(response.data);
        return;
    };

    const removeBanners = async (bannerIds: any) => {
        setLoading(true);
        for (const bannerId of bannerIds) {
            const response = await removeBannerApi({ restaurantId, bannerId });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                load();
            }
        }
        setLoading(false);
        load();
    };

    const closeCreateBannerDialog = () => setCreateBannerDialogState({ open: false });

    const closeChangeBannerDialog = () => setChangeBannerDialogState({ open: false, bannerId: undefined });

    const openCreateBannerDialog = () => setCreateBannerDialogState({ open: true });

    return (
        <>
            <CreateBannerDialog open={createBannerDialogState.open} onClose={closeCreateBannerDialog} load={load} />
            <ChangeBannerDialog open={changeBannerDialogState.open} bannerId={changeBannerDialogState.bannerId} onClose={closeChangeBannerDialog} load={load} />
            <h2 className={classes.title}>{translate('Banners')}</h2>
            <UpdatingContentProgress loading={loading} />
            <Table
                className={classes.table}
                loading={loading}
                data={banners.map((banner) => ({
                    bannerId: banner.bannerId,
                    imageUrl: banner.imageUrl,
                }))}
                columns={[
                    {
                        name: 'bannerId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'imageUrl',
                        label: translate('Image'),
                        options: {
                            filter: false,
                            customBodyRender: (imageUrl) => {
                                return (
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <img src={imageUrl} className={classes.bannerThumbnail} />;
                                    </div>
                                );
                            },
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: marketingActionsForRestaurantEnabled ? 'multiple' : 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                            {marketingActionsForRestaurantEnabled && <CreateToolbarButton onClick={openCreateBannerDialog} />}
                        </>
                    ),
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const bannerIds = selectedRowsData.map((d: Array<BannerId>) => d[0]);

                        const handleClickRemove = async () => {
                            const textTranslated =
                                bannerIds.length === 1
                                    ? translate('Are you sure you want to remove the selected banner')
                                    : translate('Are you sure you want to remove the selected @number banners', { number: bannerIds.length });
                            const remove = window.confirm(textTranslated);
                            if (remove) await removeBanners(bannerIds);
                        };

                        return (
                            <div className={classes.toolbar}>
                                {bannerIds.length === 1 && !brandOpened && <ChangeToolbarButton onClick={() => setChangeBannerDialogState({ open: true, bannerId: bannerIds[0] })} />}
                                {!brandOpened && <RemoveToolbarButton onClick={handleClickRemove} />}
                            </div>
                        );
                    },
                }}
            />
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 25,
        marginTop: '4em',
    },
    bannerThumbnail: {
        objectFit: 'cover',
        width: 200,
        height: 120,
        borderRadius: 20,
    },
}));
