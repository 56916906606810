/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createNextSubscriptionPaymentApi } from 'src/api/letseatadmin/subscription/createNextSubscriptionPaymentApi';
import { addConektaOxxoReferenceApi } from 'src/api/letseatmanager/subscription/addConektaOxxoReferenceApi';
import { addConektaSpeiReferenceApi } from 'src/api/letseatmanager/subscription/addConektaSpeiReferenceApi';
import { changeSubscriptionApi } from 'src/api/letseatmanager/subscription/changeSubscriptionApi';
import { getSubscriptionApi } from 'src/api/letseatmanager/subscription/getSubscriptionApi';
import { removeSubscriptionCardApi } from 'src/api/letseatmanager/subscription/removeSubscriptionCardApi';
import { app2 } from 'src/app2';
import { LoadConektaScript } from 'src/components/facade/conekta/LoadConektaScript';
import { LoadStripeScript } from 'src/components/facade/stripe/LoadStripeScript';
import { DownloadCommissionReportForm } from 'src/components/form/DownloadCommissionReportForm';
import { Form } from 'src/components/form/Form';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormRegimeFiscalSelect } from 'src/components/form/FormRegimeFiscalSelect';
import { FormRfcField } from 'src/components/form/FormRfcField';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormTextField } from 'src/components/form/FormTextField';
import { Page } from 'src/components/Page';
import { Text } from 'src/components/Text';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { CardPriorities } from 'src/constants/CardPriority';
import { SubscriptionPaymentErrorTypes } from 'src/constants/SubscriptionPaymentErrorType';
import { SubscriptionPaymentMethods } from 'src/constants/SubscriptionPaymentMethod';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { AddNewCardDialog } from 'src/scenes/letseatmanager/subscription/AddNewCardDialog';
import { ConektaOxxoPaymentDialog } from 'src/scenes/letseatmanager/subscription/ConektaOxxoPaymentDialog';
import { ConektaSpeiPaymentDialog } from 'src/scenes/letseatmanager/subscription/ConektaSpeiPaymentDialog';
import { PaymentsTable } from 'src/scenes/letseatmanager/subscription/PaymentsTable';
import { SubscriptionCard } from 'src/scenes/letseatmanager/subscription/SubscriptionCard';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { appColors } from 'src/theme/AppColors';
import { SubscriptionCardVm } from 'src/types/SubscriptionCardVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateStringReadable } from 'src/utils/date/formatDateStringReadable';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';
import { removeSpaceCharacters } from 'src/utils/string/removeSpaceCharacters';
import { translatePaymentRejectReason } from 'src/utils/translate/translatePaymentRejectReason';

export function SubscriptionPage(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    const [loading, setLoading] = useState(false);
    const [conektaSpeiPaymentDialogState, setConektaSpeiPaymentDialogState] = useState({ open: false });
    const [conektaOxxoPaymentDialogState, setConektaOxxoPaymentDialogState] = useState({ open: false });
    const [paidSubscription, setPaidSubscription] = useState(false);
    const [oxxoReference, setOxxoReference] = useState('');
    const [speiReference, setSpeiReference] = useState('');

    const subscriptionId = useSelector((state) => state.app.restaurant.subscriptionId);
    const subscriptionAccess = useSelector((state) => state.app2.subscriptionAccess);
    const remainingDays = useSelector((state) => state.app2.remainingDays);
    const timeZone = useSelector((state) => state.app.restaurant.timeZone);
    const posEnabled = useSelector((state) => state.app.restaurant.posEnabled);
    const restaurantId = useSelector((state) => state.app.restaurant?.restaurantId);
    const financeReference = useSelector((state) => state.app.restaurant?.financeReference);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);
    const openAddNewCardDialog = useAction(app2.actions.openAddNewCardDialog);

    const [loadingSubscription, subscription, refreshSubscription] = useLoadApi(getSubscriptionApi, { subscriptionId }, { requiredValues: [subscriptionId], dependencies: [subscriptionId] });

    const primaryCard = subscription?.cards?.find((card: SubscriptionCardVm) => card.cardPriority === CardPriorities.PRIMARY);
    const backUpCard = subscription?.cards?.find((card: SubscriptionCardVm) => card.cardPriority === CardPriorities.BACKUP);
    const renderPaymentMethodWarning = subscription?.subscriptionPaymentMethod !== SubscriptionPaymentMethods.SPEI && posEnabled;

    useEffect(() => {
        if (subscription) {
            form.reset({
                businessName: subscription.businessName ?? null,
                emailInvoices: subscription.emailInvoices ?? null,
                rfc: subscription.rfc ?? null,
                zipCode: subscription.zipCode ?? null,
                regimeFiscal: subscription.regimeFiscal ?? null,
            });
        }
    }, [subscription]);

    const openConektaSpeiPaymentDialog = () => setConektaSpeiPaymentDialogState({ open: true });

    const closeConektaSpeiPaymentDialog = () => setConektaSpeiPaymentDialogState({ open: false });

    const openConektaOxxoPaymentDialog = () => setConektaOxxoPaymentDialogState({ open: true });

    const closeConektaOxxoPaymentDialog = () => setConektaOxxoPaymentDialogState({ open: false });

    const addNewCard = (cardPriority: any) => {
        openAddNewCardDialog({ cardPriority, businessName: subscription?.businessName, onSuccess: refreshSubscription });
    };

    const seeConektaOxxoPaymentForm = async () => {
        const oxxoReference = await addConektaOxxoReferenceApi({ subscriptionId: subscriptionId });
        if (!oxxoReference.ok) {
            alertKnownErrorOrSomethingWentWrong(oxxoReference);
            return;
        }
        setOxxoReference(oxxoReference.data ?? '');
        openConektaOxxoPaymentDialog();
    };

    const seeConektaSpeiPaymentForm = async () => {
        const speiReference = await addConektaSpeiReferenceApi({ subscriptionId: subscriptionId, restaurantId });
        if (!speiReference.ok) {
            alertKnownErrorOrSomethingWentWrong(speiReference);
            return;
        }
        setSpeiReference(speiReference.data ?? '');
        openConektaSpeiPaymentDialog();
    };

    const onSubmit = async (form: any) => {
        const response = await changeSubscriptionApi({
            subscriptionId: requireValue(subscriptionId),
            businessName: form.businessName,
            rfc: removeSpaceCharacters(form.rfc),
            emailInvoices: form.emailInvoices,
            regimeFiscal: form.regimeFiscal,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        if (subscription?.cards?.length) {
            const response = await createNextSubscriptionPaymentApi({
                subscriptionId: requireValue(subscriptionId),
            });
            if (!response.ok) {
                // Skip showing error to customer for connection errors, subscription will be payed later anyway when managing the payments
                await refreshSubscription();
                return;
            }
            if (response.data.paymentErrorType === SubscriptionPaymentErrorTypes.PAYMENT_FAILED) {
                alert(`${translate('Failed pay for subscription')}${response.data.paymentRejectReason ? `: ${translatePaymentRejectReason(response.data.paymentRejectReason)}` : ''}`);
                await refreshSubscription();
                return;
            }
            if (!response.data.paymentErrorType) {
                showSnackbar({ message: translate('Saved changes and paid for subscription') });
                setPaidSubscription(true);
                await refreshSubscription();
                return;
            }
            await refreshSubscription();
            return;
        }

        await refreshSubscription();
        showSnackbar({ message: translate('Saved changes') });
    };

    const removeCard = async (cardId: any) => {
        if (subscription?.cards?.length === 1) {
            alert(translate('To remove the card, you need to add a second payment method. In case if you want to deactivate the service, please reach out to your sales agent.'));
            return;
        }
        setLoading(true);
        const response = await removeSubscriptionCardApi({
            subscriptionId: requireValue(subscriptionId),
            cardId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        showSnackbar({ message: translate('Removed Card') });
        await refreshSubscription();
    };

    return (
        <Page title={translate('Subscription')} className={classes.container}>
            {subscriptionAccess && remainingDays === 0 && (
                <div className={classes.alertsContainer}>
                    <Alert severity={'error'} style={{ borderColor: '#ddd', borderWidth: '1px', borderStyle: 'solid' }} classes={{ root: (classes as any).alertMessage }}>
                        <AlertTitle classes={{ root: classes.alertTitle }}>{translate('Suspended Service')}</AlertTitle>
                        {translate('Pay your bill to continue using your service')}
                    </Alert>
                </div>
            )}
            <LoadStripeScript />
            <LoadConektaScript />
            <AddNewCardDialog />
            <ConektaOxxoPaymentDialog
                open={conektaOxxoPaymentDialogState.open}
                subscriptionPrice={subscription?.paymentAmount}
                conektaOxxoReference={oxxoReference}
                onClose={closeConektaOxxoPaymentDialog}
            />
            <ConektaSpeiPaymentDialog
                open={conektaSpeiPaymentDialogState.open}
                subscriptionPrice={subscription?.paymentAmount}
                conektaSpeiReference={speiReference}
                financeReference={financeReference}
                onClose={closeConektaSpeiPaymentDialog}
            />
            <UpdatingContentProgress loading={loading || loadingSubscription} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DownloadCommissionReportForm />
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.buttonsContainer}>
                        <div className={classes.subscriptionTitle}>
                            <Typography display='block' gutterBottom variant='h6' className={classes.title}>
                                {translate('Subscription')}
                            </Typography>
                            {!!subscription?.managerMessage && (
                                <Typography gutterBottom variant='subtitle1' className={classes.text}>
                                    {subscription?.managerMessage}
                                </Typography>
                            )}
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Card classes={{ root: classes.root }}>
                        <CardContent>
                            <Form form={form} onSubmit={onSubmit} enabledForUserTypeView={true}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                <FormTextField name='businessName' label={translate('Business Name')} required disabled />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormEmailField name='emailInvoices' label={translate('Email Invoices')} required disabled />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormRfcField name='rfc' label={translate('RFC')} required disabled />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormTextField name='zipCode' label={translate('Zip code')} required disabled />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormRegimeFiscalSelect name='regimeFiscal' label={translate('Regime fiscal')} required disabled />
                                            </Grid>
                                            <Grid item xs={12} justify={'center'}>
                                                {!posEnabled && subscription?.subscriptionPaymentMethod === SubscriptionPaymentMethods.CARD && (
                                                    <div className={classes.paymentsContainer}>
                                                        <div className={classes.cardContainer}>
                                                            <SubscriptionCard
                                                                card={primaryCard}
                                                                cardPriority={CardPriorities.PRIMARY}
                                                                loading={loading}
                                                                addNewCard={addNewCard}
                                                                removeCard={removeCard}
                                                            />
                                                        </div>
                                                        <div className={classes.cardContainer}>
                                                            <SubscriptionCard
                                                                card={backUpCard}
                                                                cardPriority={CardPriorities.BACKUP}
                                                                loading={loading}
                                                                addNewCard={addNewCard}
                                                                removeCard={removeCard}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {(subscription?.subscriptionPaymentMethod === SubscriptionPaymentMethods.SPEI ||
                                                    subscription?.subscriptionPaymentMethod === SubscriptionPaymentMethods.SPEI_INTERNAL ||
                                                    posEnabled) && (
                                                    <div className={classes.paymentsContainer}>
                                                        <Button onClick={seeConektaSpeiPaymentForm}>
                                                            <img className={classes.paymentMethodsImg} src={'https://milito.mx/wp-content/uploads/2020/04/spei.png'} />
                                                        </Button>
                                                    </div>
                                                )}
                                            </Grid>
                                            {renderPaymentMethodWarning && (
                                                <Grid item xs={12}>
                                                    <Text error>{translate('For clients who include Point of Sale in his subscription, payment method must be by SPEI')}</Text>
                                                </Grid>
                                            )}
                                            {subscription?.nextPaymentAmount && subscription?.nextPaymentDate && (
                                                <Grid item xs={12}>
                                                    <div className={classes.text}>
                                                        {translate('Your next payment is @nextPaymentAmount and will be charged: @nextPaymentDate', {
                                                            nextPaymentAmount: formatAsCurrencyNumber(subscription?.nextPaymentAmount),
                                                            nextPaymentDate: formatDateStringReadable(subscription?.nextPaymentDate, timeZone),
                                                        })}
                                                    </div>
                                                </Grid>
                                            )}
                                            {!!primaryCard?.cardId && (
                                                <>
                                                    {!!subscription?.paymentRejectReason && (
                                                        <Grid item xs={12}>
                                                            {translate('Your last payment failed')}: {translatePaymentRejectReason(subscription?.paymentRejectReason)}
                                                        </Grid>
                                                    )}
                                                    {paidSubscription && (
                                                        <Grid item xs={12} style={{ color: appColors.text.success }}>
                                                            {translate('Saved changes and paid for subscription')}
                                                        </Grid>
                                                    )}
                                                </>
                                            )}
                                            <div className={classes.submitButtonContainer}>
                                                <FormSubmitButton text={isSubmitting ? translate('Saving') : translate('Save')} disabled={isSubmitting || loading} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <PaymentsTable payments={subscription?.payments} loading={loading} onClickRefresh={refreshSubscription} />
                </Grid>
            </Grid>
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: 15,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '80vh',
        height: 'fit-content',
        border: `1px solid ${theme.palette.secondary.dark}`,
        marginBottom: 20,
        paddingBottom: 40,
        position: 'relative',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            margin: '0 auto',
            marginTop: 20,
        },
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
        border: 0,
        boxShadow: '0px 0px',
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: 20,
        paddingLeft: 20,
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    subscriptionTitle: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    primaryButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 250,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            width: '78vw',
        },
    },
    submitButtonContainer: {
        width: '100%',
        textAlign: 'center',
    },
    title: {
        fontFamily: theme.typography.semiBold,
    },
    text: {
        fontFamily: theme.typography.regular,
    },
    paymentsContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    cardContainer: {
        width: '45%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: 20,
        },
    },
    paymentMethodsImg: {
        height: '15vh',
        width: '30vw',
    },
    alertTitle: {
        fontFamily: theme.typography.semiBold,
    },
    alertsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
    },
}));
