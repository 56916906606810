/**
 * @prettier
 */
import { IconButton, makeStyles } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useState } from 'react';
import * as React from 'react';
import { findInventoryTransactionsByRestaurantIdApi } from 'src/api/letseatmanager/inventory/findInventoryTransactionsByRestaurantIdApi';
import { app2 } from 'src/app2';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { CreateInventoryLogDialog } from 'src/scenes/letseatmanager/inventory/inventory/CreateInventoryLogDialog';
import type { InventoryTransactionVm } from 'src/types/InventoryTransactionVm';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function InventoryLogs({ goBack }: Props): React.ReactElement {
    const classes = useStyles();
    const [userHasRolePermission] = useUserHasRolePermission();

    const [createInventoryDialogState, setCreateInventoryDialogState] = useState({ open: false });

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const openVerifyPosPasswordDialog = useAction(app2.actions.openVerifyPosPasswordDialog);

    const [loading, { inventoryTransactions }, refreshInventoryTransactions] = useLoadApi(
        findInventoryTransactionsByRestaurantIdApi,
        { restaurantId },
        {
            initialValue: { inventoryTransactions: [] },
        }
    );

    const handleCreateInventoryLog = () => {
        if (!userHasRolePermission(RolePermissions.CREATE_INVENTORY_LOG)) {
            return openVerifyPosPasswordDialog({
                onAuthorized: () => {
                    setCreateInventoryDialogState({ open: true });
                },
                onUnauthorized: () => {
                    alert(translate('Unauthorized to access.'));
                },
            });
        }
        setCreateInventoryDialogState({ open: true });
    };

    return (
        <div className={classes.container}>
            <CreateInventoryLogDialog open={createInventoryDialogState.open} onClose={() => setCreateInventoryDialogState({ open: false })} onSuccess={refreshInventoryTransactions} />
            <div className={classes.backButtonContainer}>
                <IconButton onClick={goBack}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                <span className={classes.text}>{translate('Go Back')}</span>
            </div>
            <UpdatingContentProgress loading={loading} bottom />
            <h1 className={classes.title}>{translate('Inventory Logs History')}</h1>
            <Table
                data={inventoryTransactions
                    ?.sort((a: InventoryTransactionVm, b: InventoryTransactionVm) => (b.createdAt as any) - (a.createdAt as any))
                    .map((inventoryTransaction: InventoryTransactionVm) => {
                        return {
                            inventoryTransactionId: inventoryTransaction.inventoryTransactionId,
                            date: formatDateTimeString(inventoryTransaction.createdAt),
                            operation: translate(`InventoryTransactionTypes.${inventoryTransaction.type}`),
                            type: translate(`InventoryTransactionReasons.${inventoryTransaction.reason}`),
                            supply: inventoryTransaction.supply.name,
                            quantity: `${inventoryTransaction.quantityAfterTransaction} ${translate(`UnitOfMeasurements.${inventoryTransaction.unitOfMeasurement}`)}`,
                            notes: inventoryTransaction.notes,
                            user: inventoryTransaction.user,
                        };
                    })}
                columns={[
                    {
                        name: 'inventoryTransactionId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'date',
                        label: translate('Date'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'operation',
                        label: translate('Operation'),
                        options: {
                            filter: true,
                        },
                    },
                    {
                        name: 'type',
                        label: translate('Type'),
                        options: {
                            filter: true,
                        },
                    },
                    {
                        name: 'supply',
                        label: translate('Supply'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'quantity',
                        label: translate('Quantity'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'notes',
                        label: translate('Notes'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'user',
                        label: translate('User'),
                        options: {
                            filter: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    filter: true,
                    print: false,
                    viewColumns: false,
                    customToolbar: () => (
                        <>
                            <CreateToolbarButton onClick={handleCreateInventoryLog} />
                            <RefreshToolbarButton onClick={refreshInventoryTransactions} />
                        </>
                    ),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    backButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        margin: 0,
        marginBottom: -50,
        marginTop: 50,
        position: 'relative',
        zIndex: 5,
        width: 'fit-content',
    },
}));

type Props = {
    goBack: any;
};
