/**
 * @prettier
 */
import * as React from 'react';

export function CreditsIcon({ title }: Props): React.ReactElement {
    return (
        <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title ?? <title>{title}</title>}
            <path d='M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z' fill='#E5DAFB' />
            <path
                d='M15 14.5C14.4696 14.5 13.9609 14.7107 13.5858 15.0858C13.2107 15.4609 13 15.9696 13 16.5V17H27V16.5C27 15.9696 26.7893 15.4609 26.4142 15.0858C26.0391 14.7107 25.5304 14.5 25 14.5H15Z'
                fill='#7F46F9'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M27 18.5H13V23.5C13 24.0304 13.2107 24.5391 13.5858 24.9142C13.9609 25.2893 14.4696 25.5 15 25.5H25C25.5304 25.5 26.0391 25.2893 26.4142 24.9142C26.7893 24.5391 27 24.0304 27 23.5V18.5ZM15 21C15 20.8674 15.0527 20.7402 15.1464 20.6464C15.2402 20.5527 15.3674 20.5 15.5 20.5H19.5C19.6326 20.5 19.7598 20.5527 19.8536 20.6464C19.9473 20.7402 20 20.8674 20 21C20 21.1326 19.9473 21.2598 19.8536 21.3536C19.7598 21.4473 19.6326 21.5 19.5 21.5H15.5C15.3674 21.5 15.2402 21.4473 15.1464 21.3536C15.0527 21.2598 15 21.1326 15 21ZM15.5 22.5C15.3674 22.5 15.2402 22.5527 15.1464 22.6464C15.0527 22.7402 15 22.8674 15 23C15 23.1326 15.0527 23.2598 15.1464 23.3536C15.2402 23.4473 15.3674 23.5 15.5 23.5H17.5C17.6326 23.5 17.7598 23.4473 17.8536 23.3536C17.9473 23.2598 18 23.1326 18 23C18 22.8674 17.9473 22.7402 17.8536 22.6464C17.7598 22.5527 17.6326 22.5 17.5 22.5H15.5Z'
                fill='#7F46F9'
            />
        </svg>
    );
}

type Props = {
    title?: string;
};
