/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { findRestaurantVideosApi, RestaurantVideosVm } from 'src/api/letseatadmin/restaurantVideo/findRestaurantVideosApi';
import { removeRestaurantVideoApi } from 'src/api/letseatadmin/restaurantVideo/removeRestaurantVideoApi';
import { app2 } from 'src/app2';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ChangeRestaurantVideoDialog } from 'src/scenes/letseatadmin/restaurantVideo/ChangeRestaurantVideoDialog';
import { CreateRestaurantVideoDialog } from 'src/scenes/letseatadmin/restaurantVideo/CreateRestaurantVideoDialog';
import type { RestaurantVideoId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantVideosPage(): React.ReactElement {
    const classes = useStyles();
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState({ restaurantVideos: [] } as RestaurantVideosVm);
    const [createRestaurantVideoDialogState, setCreateRestaurantVideoDialogState] = useState({ open: false });
    const [changeRestaurantVideoDialogState, setChangeRestaurantVideoDialogState] = useState({ open: false, restaurantVideoId: undefined });

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Restaurant Videos'));
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await findRestaurantVideosApi();
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setVm(response.data);
    };

    const removeRestaurantVideo = async (restaurantVideoIds: Array<RestaurantVideoId>) => {
        setLoading(true);
        for (const restaurantVideoId of restaurantVideoIds) {
            const response = await removeRestaurantVideoApi({ restaurantVideoId });
            if (!response.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(response);
                await load();
                return;
            }
        }
        await load();
    };

    const handleClickNew = () => {
        setCreateRestaurantVideoDialogState({ open: true });
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <CreateRestaurantVideoDialog open={createRestaurantVideoDialogState.open} onClose={() => setCreateRestaurantVideoDialogState({ open: false })} onRestaurantVideoCreated={load} />
            <ChangeRestaurantVideoDialog
                open={changeRestaurantVideoDialogState.open}
                restaurantVideoId={changeRestaurantVideoDialogState.restaurantVideoId}
                onClose={() => setChangeRestaurantVideoDialogState({ open: false, restaurantVideoId: undefined })}
                onChangeRestaurantVideo={load}
            />

            <MUIDataTable
                /* @ts-ignore */
                className={classes.table}
                data={
                    vm.restaurantVideos &&
                    vm.restaurantVideos.map((restaurantVideo) => {
                        return {
                            restaurantVideoId: restaurantVideo.restaurantVideoId,
                            name: restaurantVideo.name,
                            videoUrl: restaurantVideo.videoUrl,
                            // createdAt: restaurantVideo.createdAt,
                        };
                    })
                }
                columns={[
                    {
                        name: 'restaurantVideoId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'name',
                        label: translate('Name'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'videoUrl',
                        label: translate('Video Url'),
                        options: {
                            filter: false,
                        },
                    },
                    //
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: !viewUser ? 'multiple' : 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                            <CreateToolbarButton onClick={handleClickNew} />
                        </>
                    ),
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedRestaurantVideoIds = selectedRowsData.map((d: any) => d[0]);
                        return (
                            <div className={classes.toolbar}>
                                {selectedRestaurantVideoIds.length === 1 && (
                                    <ChangeToolbarButton
                                        onClick={() => {
                                            if (selectedRestaurantVideoIds.length === 1) setChangeRestaurantVideoDialogState({ open: true, restaurantVideoId: selectedRestaurantVideoIds[0] });
                                        }}
                                    />
                                )}
                                <RemoveToolbarButton
                                    onClick={async () => {
                                        const remove = window.confirm(
                                            selectedRestaurantVideoIds.length === 1
                                                ? 'Are you sure you want to remove the selected video'
                                                : `Are you sure you want to remove the selected ${selectedRestaurantVideoIds.length} video`
                                        );
                                        if (remove) {
                                            await removeRestaurantVideo(selectedRestaurantVideoIds);
                                        }
                                    }}
                                />
                            </div>
                        );
                    },
                    // filterList: [[], [], [], [], ['MEXICO']],
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));
