/**
 * @prettier
 */
export async function fileToBase64(file: any): Promise<string> {
    return new Promise((resolve: (result: Promise<never>) => void, reject: (error?: any) => void) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as any);
        reader.onerror = (error: ProgressEvent) => reject(error);
    });
}
