/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useRef } from 'react';
import { refundPaymentApi } from 'src/api/letseatadmin/subscription/refundPaymentApi';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import type { PaymentRejectReason } from 'src/constants/PaymentRejectReason';
import type { PaymentStatus } from 'src/constants/PaymentStatus';
import type { Psp } from 'src/constants/Psp';
import { translate } from 'src/i18n/translate';
import type { PaymentId } from 'src/types/Id';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { createFilteredOnDownloadFunctional } from 'src/utils/mui-datatables/createFilteredOnDownloadFunctional';
import { translatePaymentRejectReasonCompact } from 'src/utils/translate/translatePaymentRejectReasonCompact';
import { translatePaymentStatus } from 'src/utils/translate/translatePaymentStatus';

export function PaymentsTable({ payments = [], loading, title, onClickRefresh }: Props): React.ReactElement {
    const classes = useStyles();
    const table = useRef(null);

    const refundPayment = async (paymentId: PaymentId) => {
        const password = window.prompt('Please enter password to refund the payment', '');
        if (password === '9820') {
            const remove = window.confirm('Are you sure you want to refund this payment?');
            if (remove) {
                const response = await refundPaymentApi({ paymentId: paymentId });
                if (!response.ok) {
                    alert('Failed to refund restaurant');
                    return;
                }
                alert('Payment refunded');
                onClickRefresh();
            }
        } else {
            alert('Wrong Password');
        }
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <MUIDataTable
                /* @ts-ignore */
                ref={table}
                className={classes.table}
                title={title || translate('Payments')}
                data={payments.map((payment) => ({
                    paymentId: payment.paymentId,
                    amount: formatAsCurrencyNumber(payment.amount),
                    psp: payment.psp,
                    paymentStatus: `${translatePaymentStatus(payment.paymentStatus)}${payment.paymentRejectReason ? `: ${translatePaymentRejectReasonCompact(payment.paymentRejectReason)}` : ''}`,
                    createdAt: formatDateTimeStringReadable(payment.createdAt),
                }))}
                columns={[
                    {
                        name: 'paymentId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'amount',
                        label: translate('Total'),
                        options: {
                            filter: true,
                        },
                    },
                    {
                        name: 'psp',
                        label: translate('PSP'),
                        options: {
                            filter: true,
                        },
                    },
                    {
                        name: 'paymentStatus',
                        label: translate('Status'),
                        options: {
                            filter: true,
                        },
                    },
                    {
                        name: 'createdAt',
                        label: translate('Date'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'paymentId',
                        label: translate('Refund Payment'),
                        options: {
                            searchable: false,
                            customBodyRender: (paymentId, { rowData }) => {
                                return (
                                    <Button size='small' color='primary' onClick={() => refundPayment(paymentId)}>
                                        Refund Payment
                                    </Button>
                                );
                            },
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={onClickRefresh} />
                        </>
                    ),
                    onDownload: createFilteredOnDownloadFunctional(table),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

type Props = {
    payments: Array<{
        paymentId: PaymentId;
        amount: string;
        psp?: Psp;
        paymentStatus: PaymentStatus;
        paymentRejectReason?: PaymentRejectReason;
        createdAt: Date;
    }>;
    loading: boolean;
    title?: string;
    onClickRefresh: any;
};
