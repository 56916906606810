/**
 * @prettier
 */
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { Loader } from '@pidedirecto/ui';
import { useConfirmDialog } from '@pidedirecto/ui/hooks';
import toInteger from 'lodash/toInteger';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { completeBuyOrderApi } from 'src/api/letseatmanager/buyOrder/completeBuyOrderApi';
import { createBuyOrderApi } from 'src/api/letseatmanager/buyOrder/createBuyOrderApi';
import { getBuyOrderApi } from 'src/api/letseatmanager/buyOrder/getBuyOrderApi';
import { findSuppliersByRestaurantIdApi } from 'src/api/letseatmanager/supplier/findSuppliersByRestaurantIdApi';
import { findSuppliesByRestaurantIdApi } from 'src/api/letseatmanager/supply/findSuppliesByRestaurantIdApi';
import { Button } from 'src/components/Button';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { ConfirmUpdateSupplyCostDialog } from 'src/components/dialog/inventory/buyOrder/ConfirmUpdateSupplyCostDialog';
import { Form } from 'src/components/form/Form';
import { FormSupplierAutocomplete } from 'src/components/form/FormSupplierAutocomplete';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { SecuredContent } from 'src/components/SecuredContent';
import { BuyOrderStatuses } from 'src/constants/BuyOrderStatus';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { EnvelopeIcon } from 'src/icons/EnvelopeIcon';
import { FlappyDiskIcon } from 'src/icons/FlappyDiskIcon';
import { UploadIcon } from 'src/icons/UploadIcon';
import { AddBuyOrderSupply } from 'src/scenes/letseatmanager/inventory/buyOrder/AddBuyOrderSupply';
import { BuyOrderSummaryDialog } from 'src/scenes/letseatmanager/inventory/buyOrder/BuyOrderSummaryDialog';
import { BuyOrderSupply } from 'src/scenes/letseatmanager/inventory/buyOrder/BuyOrderSupply';
import { SendBuyOrderDialog } from 'src/scenes/letseatmanager/inventory/buyOrder/SendBuyOrderDialog';
import { useNotification } from 'src/services/notification/useNotification';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { BuyOrderSummary } from 'src/types/BuyOrderSummary';
import type { BuyOrderId } from 'src/types/Id';
import { SupplyVm } from 'src/types/SupplyVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isNormalSupply } from 'src/utils/inventory/supply/isNormalSupply';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { RegExps } from 'src/utils/RegExps';

export function CreateBuyOrderPage(): React.ReactElement {
    const form = useForm();
    const classes = useStyles();
    const history = useHistory();
    const confirmDialog = useConfirmDialog();
    const notification = useNotification();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const buyOrderId = history.location.state?.buyOrderId;
    const isEntryOfMerchandise = history.location.state?.entryOfMerchandise;

    const {
        formState: { isSubmitting },
        control,
        watch,
    } = form;

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'supplies',
    });

    const supplierId = watch(`supplierId`);

    const [openBuyOrderSummaryDialog, setOpenBuyOrderSummaryDialog] = useState(false);
    const [buyOrderSummary, setBuyOrderSummary] = useState<BuyOrderSummary>();
    const [sendBuyOrderDialogState, setSendBuyOrderDialogState] = useState<{ open: boolean; buyOrderId: BuyOrderId | undefined }>({ open: false, buyOrderId: undefined });
    const [sendOrder, setSendOrder] = useState(false);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [completeOrder, setCompleteOrder] = useState(false);
    const [confirmUpdateSupplyCostsDialogState, setConfirmUpdateSupplyCostsDialogState] = useState({ open: false });

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const pinCodeUserManagerUserId = useSelector((state) => state.authentication.pinCodeUserSignedIn?.managerUserId || undefined);
    const buyOrders = useSelector((state) => state.inventory.buyOrders);

    const [loadingSupplies, { supplies }] = useLoadApi(findSuppliesByRestaurantIdApi, { restaurantId }, { initialValue: { supplies: [] } });
    const [loadingBuyOrder, buyOrder] = useLoadApi(getBuyOrderApi, { buyOrderId }, { requiredValues: [buyOrderId], dependencies: [buyOrderId] });
    const [loadingSuppliers, { suppliers }] = useLoadApi(findSuppliersByRestaurantIdApi, { restaurantId }, { initialValue: { suppliers: [] } });

    useEffect(() => {
        if (!!supplies?.length && buyOrder) initializeFormWithBuyOrder(form, buyOrder, append);
    }, [buyOrder, supplies]);

    useEffect(() => {
        if (!buyOrderId && fields.length === 0) handleSupplierChange();
    }, [supplierId]);

    const handleSupplierChange = async () => {
        if (!supplierId) return;

        const confirmAutocompleteSuppliesBySelectedSupplier = await confirmDialog({
            title: translate('Do you want to load supplies from this supplier?'),
            acceptButtonText: translate('Yes'),
            cancelButtonText: translate('No'),
            variant: 'warning',
        });

        if (!confirmAutocompleteSuppliesBySelectedSupplier) return;

        handleAutocompleteSuppliesBySelectedSupplier();
    };

    const initializeFormWithBuyOrder = async (form: any, buyOrder: any, append: any) => {
        if (!buyOrder || buyOrders?.length) return;
        if (!buyOrderId) {
            append({ supplyId: '', quantity: 0, price: 0, buyUnit: '' });
            return;
        }

        const filteredSupplies = buyOrder?.supplies?.filter((orderSupply: SupplyVm) => supplies.find((supply) => orderSupply.supplyId === supply.supplyId));

        if (filteredSupplies?.length < buyOrder?.supplies?.length) {
            const confirmWarning = await confirmDialog({
                title: translate('Error with supplies'),
                content: translate('The buy order includes items that are no longer available and will be omitted'),
                acceptButtonText: translate('Accept'),
                variant: 'warning',
            });
            if (!confirmWarning) return;
        }
        form.reset({
            supplierId: buyOrder.supplierId,
        });
        filteredSupplies.forEach((buyOrderSupply: any) => {
            append({
                supplyId: buyOrderSupply.supplyId,
                quantity: buyOrderSupply.quantity,
                price: buyOrderSupply.price,
                buyUnit: buyOrderSupply.unitOfMeasurement,
            });
        });
    };

    const createBuyOrder = async (): Promise<BuyOrderId | undefined> => {
        setLoading(true);
        const formData = form.getValues();

        const response = await createBuyOrderApi({
            ...formData,
            supplies: formData.supplies,
            buyOrderStatus: BuyOrderStatuses.PENDING,
            restaurantId,
            managerUserId: pinCodeUserManagerUserId,
        } as any); // TODO: remove any and use real type
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        notification({ message: isEntryOfMerchandise ? translate('Merchandise Income created successfully') : translate('Buy order created successfully') });
        setOpenBuyOrderSummaryDialog(false);

        return response.data?.buyOrderId;
    };
    const onSubmit = async () => {
        if (isEntryOfMerchandise && completeOrder) {
            setConfirmUpdateSupplyCostsDialogState({ open: true });
            return;
        }

        const buyOrderId = await createBuyOrder();
        if (!buyOrderId) return;

        if (sendOrder) {
            setSendBuyOrderDialogState({ open: true, buyOrderId: buyOrderId });
            return;
        }

        history.push({
            pathname: RoutePaths.INVENTORY_CHANGE_BUY_ORDER.replace(`:buyOrderId(${RegExps.uuid})`, buyOrderId),
            search: history.location.search,
        });
    };

    const completeBuyOrder = async (updateSupplyPrices: any) => {
        const buyOrderId = await createBuyOrder();

        if (!buyOrderId) return;

        setLoading(true);
        const response = await completeBuyOrderApi({
            buyOrderId,
            managerUserId: pinCodeUserManagerUserId,
            updateSupplyPrices,
        });
        setLoading(false);
        closeConfirmUpdateSupplyCostsDialog();

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        history.push({
            pathname: RoutePaths.INVENTORY_BUY_ORDER,
            search: history.location.search,
        });
    };

    const handleSubmit = async (form: any) => {
        mapSuppliesForSummary(form);
        setOpenBuyOrderSummaryDialog(true);
    };

    const handleSubmitAndSend = async () => {
        mapSuppliesForSummary(form.getValues());
        setOpenBuyOrderSummaryDialog(true);
        setSendOrder(true);
    };

    const handleSubmitAndComplete = async () => {
        mapSuppliesForSummary(form.getValues());
        setSendOrder(false);
        setCompleteOrder(true);
        setOpenBuyOrderSummaryDialog(true);
    };

    const handleCloseSendBuyOrderDialog = () => {
        setSendBuyOrderDialogState({ open: false, buyOrderId: undefined });
        history.push({
            pathname: RoutePaths.INVENTORY_CREATE_BUY_ORDER,
            search: history.location.search,
        });
    };

    const handleAutocompleteSuppliesBySelectedSupplier = () => {
        suppliesBySelectedSupplier.forEach((supply) => {
            append({ supplyId: supply.supplyId, quantity: 0, price: supply.fixedCost, buyUnit: '' });
        });
    };

    const closeConfirmUpdateSupplyCostsDialog = () => setConfirmUpdateSupplyCostsDialogState({ open: false });

    const mapSuppliesForSummary = (form: any) => {
        const buyOrderSupplies = form?.supplies?.map((buyOrderSupply: any) => {
            const supply = supplies.find((supplyForm) => supplyForm.supplyId === buyOrderSupply.supplyId);
            return {
                name: supply!.name,
                sku: supply!.sku,
                quantity: buyOrderSupply.quantity,
                unitOfMeasurement: buyOrderSupply.unitOfMeasurement,
                price: buyOrderSupply.price,
                total: buyOrderSupply.total,
            };
        });

        const supplier = suppliers?.find((supplier: any) => supplier.supplierId === form.supplierId);

        setBuyOrderSummary({
            supplies: buyOrderSupplies,
            supplierBusinessName: supplier?.businessName!,
        });
    };

    const handleRemoveSupply = (index: any) => {
        remove(index);
    };

    const handleTotalBuyOrder = () => {
        const supplies = form.getValues().supplies;
        let total = 0;
        supplies.forEach((supply: any) => {
            total += toInteger(supply.quantity) * toInteger(supply.price);
        });
        setTotal(total);
    };

    const handleAddNewField = (data: any) => {
        append({ supplyId: data.supply, buyUnit: data.unitOfMeasurement, quantity: data.quantity, price: data.price });
    };

    const getSupply = (supplyId: any) => {
        const supply = supplies.find((supp) => supp.supplyId === supplyId);
        return supply;
    };

    const suppliesBySelectedSupplier = supplies?.filter((supply) => supply.suppliers.find((supplier) => supplier.supplierId === supplierId)).filter((supply) => isNormalSupply(supply.type));

    const disabledSaveButton = isSubmitting || sendBuyOrderDialogState.buyOrderId || fields.length === 0;
    const disabledSendButton = isSubmitting || fields.length === 0;

    if (loadingSupplies || loadingSuppliers || loadingBuyOrder) {
        return (
            <div className={classes.loaderContainer}>
                <Loader loading={true} text={translate('Loading...')} />
            </div>
        );
    }

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.BUY_ORDERS_PAGE} title={translate('Purchases')} className={classes.container}>
            <ConfirmUpdateSupplyCostDialog open={confirmUpdateSupplyCostsDialogState.open} onClose={closeConfirmUpdateSupplyCostsDialog} onSuccess={completeBuyOrder} />
            {sendBuyOrderDialogState.buyOrderId && <SendBuyOrderDialog open={sendBuyOrderDialogState.open} onClose={handleCloseSendBuyOrderDialog} buyOrderId={sendBuyOrderDialogState.buyOrderId} />}
            {buyOrderSummary && (
                <BuyOrderSummaryDialog
                    open={openBuyOrderSummaryDialog}
                    onClose={() => setOpenBuyOrderSummaryDialog(false)}
                    buyOrder={buyOrderSummary}
                    onSubmit={onSubmit}
                    disabled={loading}
                    title={isEntryOfMerchandise ? translate('Please check that your merchandise income is correct') : undefined}
                />
            )}
            <GoBackButton />
            <h1 className={classes.title}>{isEntryOfMerchandise ? translate('Create Merchandise Income') : translate('Create Buy Order')}</h1>
            <p className={classes.text}>{translate('Add the inputs and confirm in your summary')}</p>
            <Form form={form} onSubmit={handleSubmit} disabled={loadingSupplies}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={2} className={classes.topBarButtons}>
                        <Grid item xs={3} className={classes.submitButtons}>
                            {!isEntryOfMerchandise && (
                                <>
                                    <Tooltip title={translate('Save')}>
                                        {/* @ts-ignore */}
                                        <Button icon type='submit' disabled={disabledSaveButton} classes={{ button: classes.iconButton }}>
                                            <FlappyDiskIcon color={disabledSaveButton ? 'grey' : ''} title={translate('Save button')} />
                                        </Button>
                                    </Tooltip>
                                    <SecuredContent rolePermission={RolePermissions.SEND_BUY_ORDERS}>
                                        <Tooltip title={translate('Send by Email')}>
                                            <Button icon type='button' disabled={disabledSendButton} onClick={handleSubmitAndSend} classes={{ button: classes.iconButton }}>
                                                <EnvelopeIcon color={isSubmitting || disabledSendButton ? 'grey' : ''} title={translate('Send by Email button')} />
                                            </Button>
                                        </Tooltip>
                                    </SecuredContent>
                                </>
                            )}

                            <SecuredContent rolePermission={RolePermissions.CREATE_MERCHANDISE_ENTRY}>
                                <Tooltip title={translate('Upload Inventory')}>
                                    <Button icon type='button' disabled={!isEntryOfMerchandise} onClick={handleSubmitAndComplete} classes={{ button: classes.iconButton }}>
                                        <UploadIcon disabled={!isEntryOfMerchandise} title={translate('Upload Inventory button')} />
                                    </Button>
                                </Tooltip>
                            </SecuredContent>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.topBar}>
                        <Grid item xs={4}>
                            <FormSupplierAutocomplete name='supplierId' label={translate('Supplier')} defaultValue={buyOrder?.supplierId} />
                        </Grid>

                        <Grid item xs={3} className={classes.topBarTotal}>
                            <Typography variant='h6'>{translate('Articles: @articles', { articles: fields.length })}</Typography>
                            <hr className={classes.verticalSeparator} />
                            <Typography variant='h6'>{translate('Total: @total', { total: formatAsCurrencyNumber(total) })}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    {/* @ts-ignore */}
                    <Grid container spacing={12} margin={4} className={classes.topBar}>
                        <AddBuyOrderSupply getSupply={getSupply} onSubmit={handleAddNewField} />
                        <Divider className={classes.horizontalSeparator} />

                        {fields.length === 0 && (
                            <Grid item xs={12} md={12} className={classes.emptyBuyOrder}>
                                <h5 className={classes.emptyBuyOrderText}>{translate('Add at least 1 supply')}</h5>
                            </Grid>
                        )}
                        {fields.length !== 0 &&
                            fields.map((fieldSupply: any, idx) => {
                                return <BuyOrderSupply key={fieldSupply.id} idx={idx} supply={fieldSupply} onRemove={handleRemoveSupply} getSupply={getSupply} onChange={handleTotalBuyOrder} />;
                            })}
                    </Grid>
                </Grid>
            </Form>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    loaderContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        padding: 20,
        position: 'relative',
        paddingTop: 60,
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 25,
        width: '100%',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    removeSupplyButton: {
        marginTop: 20,
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        borderRadius: 8,
        '&:hover': {
            color: theme.palette.error.dark,
            borderColor: theme.palette.error.dark,
        },
    },
    addSupplierButton: {
        marginTop: 20,
        backgroundColor: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
        borderRadius: 8,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        },

        color: theme.palette.primary.contrastText,
    },
    item: {
        marginTop: 'auto',
    },
    separator: {
        border: `0.1px solid ${theme.palette.secondary.dark}`,
        width: '100%',
        margin: '20px 0',
    },
    topBar: {
        marginTop: 20,
        justifyContent: 'space-between',
    },
    topBarButtons: {
        marginTop: 20,
        justifyContent: 'flex-end',
        alignItems: 'baseline',
    },
    topBarTotal: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        border: `0.1px solid ${theme.palette.secondary.dark}`,
        borderRadius: 8,
        padding: '5 !important',
    },
    submitButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    verticalSeparator: {
        border: `0.1px solid ${theme.palette.secondary.dark}`,
        borderLeft: `1px solid ${theme.palette.secondary.dark}`,
        height: 20,
    },
    horizontalSeparator: {
        border: `0.1px solid ${theme.palette.secondary.dark}`,
        width: '100%',
        margin: '20px 0',
    },
    iconButton: {
        backgroundColor: 'transparent',
        border: `1.5px solid ${theme.palette.secondary.dark}`,
        borderRadius: 8,
        padding: 10,
        marginLeft: 10,
    },
    icon: {
        color: theme.palette.primary.main,
    },
    iconDiabled: {
        color: theme.palette.secondary.dark,
    },
    emptyBuyOrder: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 150,
    },
    emptyBuyOrderText: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        color: '#EB5757',
    },
}));
