/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { CashIcon, CreditCardIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Countries } from 'src/constants/Country';
import { MexicanPaymentMethodCode, MexicanPaymentMethodCodes } from 'src/constants/MexicanPaymentMethodCode';
import { OrderTypes } from 'src/constants/OrderType';
import { PaymentMethod, PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { PaymentMethodIcon } from 'src/scenes/letseatmanager/theRestaurant/PaymentMethodIcon';
import { usePosPaymentMethodsDeprecated } from 'src/services/pos/posPaymentMethods/usePosPaymentMethodsDeprecated';
import type { DeprecatedRestaurantPaymentMethodVm } from 'src/types/DeprecatedRestaurantPaymentMethodVm';
import type { CustomPaymentMethod } from 'src/types/Id';
import { PosPayment } from 'src/types/PosPayment';
import { isMexico } from 'src/utils/country/isMexico';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

/**
 * @deprecated
 */
export function PosPaymentMethodDeprecated({ disabled, onSelect, selectedPaymentMethod }: Props): React.ReactElement {
    const classes = useStyles();
    const posPaymentMethodsDeprecated = usePosPaymentMethodsDeprecated();

    const clabe = useSelector((state) => state.app.restaurant.clabe);
    const bank = useSelector((state) => state.app.restaurant.bank);
    const paymentLinksEnabled = useSelector((state) => state.pos.context?.paymentLinksEnabled);
    const cashPaymentsEnabled = useSelector((state) => state.pos.context?.cashPaymentsEnabled);
    const creditCardPaymentsEnabled = useSelector((state) => state.pos.context?.creditCardPaymentsEnabled);
    const externalDelivery = useSelector((state) => state.app.restaurant.externalDelivery);
    const orderType = useSelector((state) => state.pos.orderType);
    const payments = useSelector((state) => state.pos.payments);
    const useLetsEatCredits = useSelector((state) => state.pos.useLetsEatCredits);
    const country = useSelector((state) => state.app.restaurant.country);
    const selectedCurrency = useSelector((state) => state.pos.selectedCurrency);
    const mainCurrency = useSelector((state) => state.app.restaurant?.currencyFormat?.currency);

    const paymentLinkEnabled = clabe && bank && paymentLinksEnabled;
    const isPaymentLinkMethodSelected = payments?.every((posPayment: PosPayment) => isPaymentLinkPayment(posPayment.paymentMethod));
    const disabledPaymentLinkMethod = disabled || !isPaymentLinkMethodSelected;
    const disabledCustomPaymentMethods = orderType === OrderTypes.DELIVERY_ORDER && !externalDelivery;
    const isUyOrArCountry = country === Countries.AR || country === Countries.UY;
    const disabledCreditCardMethod = isUyOrArCountry ? disabled : disabled || isDeliveryOrder(orderType);
    const isMainCurrency = !!selectedCurrency?.currency ? mainCurrency === selectedCurrency?.currency : !!mainCurrency;

    const disabledPosCreditCardMethod = ({ paymentMethod }: DeprecatedRestaurantPaymentMethodVm) =>
        disabled || useLetsEatCredits || (isCardPayment(paymentMethod) && isDeliveryOrder(orderType) && !externalDelivery);

    const handleSelect = async ({
        paymentMethod,
        customPaymentMethod,
        mexicanPaymentMethodCode,
        paymentReferenceEnabled,
    }: {
        paymentMethod: PaymentMethod;
        customPaymentMethod?: CustomPaymentMethod | undefined;
        mexicanPaymentMethodCode?: MexicanPaymentMethodCode;
        paymentReferenceEnabled?: boolean;
    }) => {
        await onSelect({ paymentMethod, customPaymentMethod, mexicanPaymentMethodCode, paymentReferenceEnabled });
    };

    return (
        <div className={classes.container}>
            <h2 style={{ marginTop: 0 }} className={classes.paymentMethodsTitle}>
                {translate('Payment Methods')}
            </h2>
            <div className={classes.buttonsContainer}>
                {cashPaymentsEnabled && (
                    <Button
                        outlined
                        disabled={disabled}
                        classes={{ button: classNames(classes.paymentMethodButton, isCashPayment(selectedPaymentMethod) ? classes.paymentMethodButtonSelected : '') }}
                        onClick={() => handleSelect({ paymentMethod: PaymentMethods.CASH, mexicanPaymentMethodCode: isMexico(country) ? MexicanPaymentMethodCodes.CASH : undefined })}
                    >
                        <div className={classes.paymentMethodIconContainer}>
                            <CashIcon size={24} />
                            <div style={{ marginTop: 4 }}>{translate('Cash')}</div>
                        </div>
                    </Button>
                )}
                {creditCardPaymentsEnabled && (
                    <Button
                        outlined
                        disabled={disabledCreditCardMethod || !isMainCurrency}
                        classes={{ button: classNames(classes.paymentMethodButton, isCardPayment(selectedPaymentMethod) ? classes.paymentMethodButtonSelected : '') }}
                        onClick={() => handleSelect({ paymentMethod: PaymentMethods.CREDIT_CARD, mexicanPaymentMethodCode: isMexico(country) ? MexicanPaymentMethodCodes.CREDIT_CARD : undefined })}
                    >
                        <div className={classes.paymentMethodIconContainer}>
                            <CreditCardIcon width={'30'} height={'24'} />
                            <div style={{ marginTop: 4 }}>{translate('Credit Card')}</div>
                        </div>
                    </Button>
                )}
                {paymentLinkEnabled && orderType === OrderTypes.DELIVERY_ORDER && (
                    <Button
                        outlined
                        disabled={disabledPaymentLinkMethod || !isMainCurrency}
                        classes={{ button: classNames(classes.paymentMethodButton, isPaymentLinkPayment(selectedPaymentMethod) ? classes.paymentMethodButtonSelected : '') }}
                        onClick={() => handleSelect({ paymentMethod: PaymentMethods.PAYMENT_LINK, mexicanPaymentMethodCode: isMexico(country) ? MexicanPaymentMethodCodes.CREDIT_CARD : undefined })}
                    >
                        <div className={classes.paymentMethodIconContainer}>
                            <LinkIcon fontSize='small' />
                            <div style={{ marginTop: 4 }}>{translate('Payment Link')}</div>
                        </div>
                    </Button>
                )}
                {posPaymentMethodsDeprecated?.map((customPaymentMethod: DeprecatedRestaurantPaymentMethodVm, idx: number) => (
                    <Button
                        outlined
                        key={idx}
                        disabled={disabledPosCreditCardMethod(customPaymentMethod) || disabledCustomPaymentMethods || (isCardPayment(customPaymentMethod.paymentMethod) && !isMainCurrency)}
                        classes={{ button: classNames(classes.paymentMethodButton, selectedPaymentMethod === customPaymentMethod.name ? classes.paymentMethodButtonSelected : '') }}
                        onClick={() =>
                            handleSelect({
                                paymentMethod: customPaymentMethod.paymentMethod,
                                customPaymentMethod: customPaymentMethod.name as any,
                                paymentReferenceEnabled: customPaymentMethod.paymentReferenceEnabled,
                                mexicanPaymentMethodCode: isMexico(country) ? customPaymentMethod.mexicanPaymentMethodCode : undefined,
                            })
                        }
                    >
                        <div className={classes.paymentMethodIconContainer}>
                            <PaymentMethodIcon icon={customPaymentMethod.icon} />
                            <div className={classes.nameContainer}>{customPaymentMethod.name}</div>
                        </div>
                    </Button>
                ))}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    paymentMethodsTitle: {
        fontFamily: theme.typography.regular,
        fontSize: 18,
        marginBottom: 12,
        color: theme.palette.text.primary,
    },
    buttonsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        width: '100%',
        gap: 4,
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 2fr))',
            gap: 6,
        },
    },
    paymentMethodButton: {
        color: theme.palette.text.secondary,
        border: `1px solid ${theme.palette.border.primary}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        borderRadius: 4,
        height: 90,
        maxWidth: 130,
        minWidth: 100,
        width: '100%',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    paymentMethodButtonSelected: {
        color: theme.palette.text.brand,
        border: `1px solid ${theme.palette.text.brand}`,
    },
    paymentMethodIconContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    nameContainer: {
        fontSize: 13.5,
        marginTop: 4,
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
}));

type Props = {
    disabled?: boolean;
    onSelect: any;
    selectedPaymentMethod: PaymentMethod | CustomPaymentMethod | undefined;
};
