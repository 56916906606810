/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { FaqId } from 'src/types/Id';

export async function removeFaqApi(request: RemoveFaqApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('faq/removeFaqApi', request);
}

export type RemoveFaqApiRequest = {
    faqId: FaqId;
};
