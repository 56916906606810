/**
 * @prettier
 */
export const Sounds = {
    PIDE_DIRECTO: 'PIDE_DIRECTO',
    DEFAULT_SOUND: 'DEFAULT_SOUND',
    NONE: 'NONE',
    BELL: 'BELL',
    BUBBLING_UP: 'BUBBLING_UP',
    JINGLE_BELLS_SMS: 'JINGLE_BELLS_SMS',
    MARTIAN_GUN: 'MARTIAN_GUN',
    NASTY_ERROR_LONG: 'NASTY_ERROR_LONG',
    OH_BOY: 'OH_BOY',
    PLUCKY: 'PLUCKY',
    QUITE_IMPRESSED: 'QUITE_IMPRESSED',
    SYSTEM_FAULT: 'SYSTEM_FAULT',
    THIS_GUITAR: 'THIS_GUITAR',
    UNSURE: 'UNSURE',
} as const;

export type Sound = typeof Sounds[keyof typeof Sounds];
