/**
 * @prettier
 */
import * as React from 'react';

export function MenuIcon({ title, color, width, height }: Props): React.ReactElement {
    return (
        <svg width={width ?? 19} height={height ?? '20'} viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                d='M17.4348 19V13.5217M17.4348 13.5217V1.59343C17.4348 1.3125 17.1501 1.1281 16.9108 1.27521C15.0252 2.43431 12.0647 5.97327 11.9594 13.1272C11.9562 13.3433 12.1317 13.5217 12.3478 13.5217H17.4348ZM4.91304 9.6087C7.07416 9.6087 8.82609 7.85677 8.82609 5.69565L8.04348 1M4.91304 9.6087C2.75193 9.6087 1 7.85677 1 5.69565L1.78261 1M4.91304 9.6087V19M4.91304 5.69565V1'
                stroke={color || 'currentColor'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}
type Props = {
    title?: string;
    width?: number;
    height?: number;
    color?: string;
};
