/**
 * @prettier
 */
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useTheme } from '@material-ui/core/styles';
import { PromotionIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { AppMenuLink } from 'src/components/app/appMenu/AppMenuLink';
import { AppMenuRestaurantSelect } from 'src/components/app/appMenu/AppMenuRestaurantSelect';
import { FeedBackSection } from 'src/components/app/appMenu/FeedbackSection';
import { RestaurantConfigurationAppMenu } from 'src/components/app/appMenu/RestaurantConfigurationAppMenu';
import { RestaurantInvoicesAppMenu } from 'src/components/app/appMenu/RestaurantInvoicesAppMenu';
import { RestaurantMarketingAppMenu } from 'src/components/app/appMenu/RestaurantMarketingAppMenu';
import { RestaurantReportsAppMenu } from 'src/components/app/appMenu/RestaurantReportsAppMenu';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { AssignmentIcon } from 'src/icons/AssignmentIcon';
import { BagIcon } from 'src/icons/BagIcon';
import { BarChartIcon } from 'src/icons/BarChartIcon';
import { CashRegisterIcon } from 'src/icons/CashRegisterIcon';
import { ClockIcon } from 'src/icons/ClockIcon';
import { DriverIcon } from 'src/icons/DriverIcon';
import { KitchenIcon } from 'src/icons/KitchenIcon';
import { MenuIcon } from 'src/icons/MenuIcon';
import { StarTicketIcon } from 'src/icons/StarTicketIcon';
import { SurveyIcon } from 'src/icons/SurveyIcon';
import { VideoLibraryOutlinedIcon } from 'src/icons/VideoLibraryOutlinedIcon';
import { WalletIcon } from 'src/icons/WalletIcon';
import { useSelector } from 'src/utils/react/useSelector';
import { isFreeTrialSubscription } from 'src/utils/subscriptionPlan/isFreeTrialSubscription';

export function RestaurantAppMenu(): React.ReactElement | null {
    const location = useLocation();
    const theme = useTheme();

    const brandOpened = useSelector((state) => state.app.brandOpened);
    const businessOpened = useSelector((state) => state.app.businessOpened);
    const analyticsEnabled = useSelector((state) => state.app.restaurant?.analyticsEnabled);
    const promotionsEnabled = useSelector((state) => state.app.restaurant?.promotionsEnabled);
    const brandMarketingEnabled = useSelector((state) => state.app.brandContext?.marketingEnabled);
    const brandPaymentLinksEnabled = useSelector((state) => state.app.brandContext?.paymentLinksEnabled);
    const brandPromoCodesEnabled = useSelector((state) => state.app.brandContext?.restaurantPromoCodesEnabled);
    const restaurant = useSelector((state) => state.app.restaurant);
    const inventoryEnabled = useSelector((state) => state.app.restaurant?.inventoryEnabled);
    const ownFleetEnabled = useSelector((state) => state.app.restaurant.ownFleetEnabled);
    const externalOwnFleetEnabled = useSelector((state) => state.app.restaurant.externalOwnFleetEnabled);
    const adminUser = useSelector((state) => state.authentication.adminUser);
    const viewUser = useSelector((state) => state.authentication.viewUser);
    const internalUser = useSelector((state) => state.authentication.internalUser);
    const kitchensUser = useSelector((state) => state.authentication.kitchensUser);
    const subscriptionPlan = useSelector((state) => state.app2.subscriptionPlan);
    const newOrdersPageEnabled = useSelector((state) => state.app.restaurant.newOrdersPageEnabled);

    const isPosRoute = location.pathname === RoutePaths.POS || location.pathname === RoutePaths.POS_CASH_REGISTERS || location.pathname === RoutePaths.POS_TABLES;
    const isOngoingOrdersRoute = location.pathname === RoutePaths.ONGOING_ORDERS;
    const isAllOrdersRoute = location.pathname === RoutePaths.ORDERS;
    const isOwnFleetDriversRoute = location.pathname === RoutePaths.OWN_FLEET_DRIVERS;
    const isKitchenDisplayScreenRoute = location.pathname === RoutePaths.KITCHEN_DISPLAY_SCREEN;
    const isInventoryRoute = location.pathname === RoutePaths.INVENTORY;
    const isSurveysRoute = location.pathname === RoutePaths.SURVEYS;
    const isPromoCodesRoute = location.pathname === RoutePaths.PROMO_CODES;
    const isPaymentLinksRoute = location.pathname === RoutePaths.PAYMENT_LINKS;
    const isTheMenuRoute = location.pathname === RoutePaths.THE_MENU;
    const isPromotionsRoute = location.pathname === RoutePaths.PROMOTIONS;
    const isHowDoesItWorkRoute = location.pathname === RoutePaths.HOW_DOES_IT_WORK;
    const isRestaurantReportsRoute = location.pathname === RoutePaths.ADVANCED_REPORTS;

    const hasGroupMarketingEnabled = (brandOpened || businessOpened) && brandMarketingEnabled;
    const hasGroupPaymentLinksEnabled = (brandOpened || businessOpened) && brandPaymentLinksEnabled;
    const hasGroupPromoCodesEnabled = (brandOpened || businessOpened) && brandPromoCodesEnabled;
    const renderMarketingOption = hasGroupMarketingEnabled || (!isFreeTrialSubscription(subscriptionPlan) && !brandOpened);
    const renderPaymentLinksOption = hasGroupPaymentLinksEnabled || (restaurant?.paymentLinksEnabled && !brandOpened);
    const renderPromoCodesOption = hasGroupPromoCodesEnabled || (restaurant?.restaurantPromoCodesEnabled && !brandOpened);

    if (kitchensUser) return null;

    return (
        <List subheader={<AppMenuRestaurantSelect />}>
            {!brandOpened && !businessOpened && (
                <SecuredContent rolePermission={RolePermissions.POS_PAGE}>
                    <AppMenuLink routePath={RoutePaths.POS} text={translate('Directo POS')} data-testid='restaurantAppMenu.directoPosLink'>
                        <ListItemIcon>
                            <CashRegisterIcon color={isPosRoute ? theme.palette.icons.invert : theme.palette.icons.brand} title={translate('cash register')} />
                        </ListItemIcon>
                    </AppMenuLink>
                </SecuredContent>
            )}
            <SecuredContent rolePermission={RolePermissions.ALL_ORDERS_PAGE}>
                <AppMenuLink routePath={RoutePaths.ORDERS} text={newOrdersPageEnabled ? translate('Orders') : translate('All Orders')} data-testid='restaurantAppMenu.allOrdersLink'>
                    <ListItemIcon>
                        <BagIcon color={isAllOrdersRoute ? theme.palette.icons.invert : theme.palette.icons.brand} title={'bag'} strokeWidth={1.7} />
                    </ListItemIcon>
                </AppMenuLink>
            </SecuredContent>
            {!newOrdersPageEnabled && (
                <SecuredContent rolePermission={RolePermissions.PENDING_ORDERS_PAGE}>
                    <AppMenuLink routePath={RoutePaths.ONGOING_ORDERS} text={translate('Ongoing Orders')} data-testid='restaurantAppMenu.ongoingOrdersLink'>
                        <ListItemIcon>
                            <ClockIcon title={translate('clock')} color={isOngoingOrdersRoute ? theme.palette.icons.invert : theme.palette.icons.brand} />
                        </ListItemIcon>
                    </AppMenuLink>
                </SecuredContent>
            )}
            {restaurant?.kitchenDisplayScreenEnabled && (
                <SecuredContent rolePermission={RolePermissions.KITCHEN_DISPLAY_SCREEN_PAGE}>
                    <AppMenuLink routePath={RoutePaths.KITCHEN_DISPLAY_SCREEN} text={translate('Kitchen Display Screen')} data-testid='restaurantAppMenu.kitchenDisplayScreenLink'>
                        <ListItemIcon>
                            <KitchenIcon color={isKitchenDisplayScreenRoute ? theme.palette.icons.invert : theme.palette.icons.brand} title={'kitchen'} />
                        </ListItemIcon>
                    </AppMenuLink>
                </SecuredContent>
            )}
            <RestaurantReportsAppMenu />
            {analyticsEnabled && (
                <SecuredContent rolePermission={RolePermissions.ADVANCED_REPORTS_PAGE}>
                    <AppMenuLink routePath={RoutePaths.ADVANCED_REPORTS} text={translate('Advanced Reports')} data-testid='restaurantAppMenu.advancedReportsLink'>
                        <ListItemIcon>
                            <BarChartIcon title={translate('advanced reports')} color={isRestaurantReportsRoute ? theme.palette.icons.invert : theme.palette.icons.brand} />
                        </ListItemIcon>
                    </AppMenuLink>
                </SecuredContent>
            )}
            {inventoryEnabled && (
                <SecuredContent rolePermission={RolePermissions.INVENTORY_PAGE}>
                    <AppMenuLink routePath={RoutePaths.INVENTORY} text={translate('Inventory')} data-testid='restaurantAppMenu.inventoryLink'>
                        <ListItemIcon>
                            <AssignmentIcon title={'clipboard'} color={isInventoryRoute ? theme.palette.icons.invert : theme.palette.icons.brand} />
                        </ListItemIcon>
                    </AppMenuLink>
                </SecuredContent>
            )}
            {!brandOpened && !businessOpened && (
                <SecuredContent rolePermission={RolePermissions.MENU_EDIT_PAGE}>
                    <AppMenuLink routePath={RoutePaths.THE_MENU} text={translate('The Menu')} data-testid='restaurantAppMenu.theMenuLink'>
                        <ListItemIcon>
                            <MenuIcon title={'menu'} color={isTheMenuRoute ? theme.palette.icons.invert : theme.palette.icons.brand} />
                        </ListItemIcon>
                    </AppMenuLink>
                </SecuredContent>
            )}
            {!brandOpened && !businessOpened && promotionsEnabled && (
                <SecuredContent rolePermission={RolePermissions.PROMOTIONS_PAGE}>
                    <AppMenuLink routePath={RoutePaths.PROMOTIONS} text={translate('Promotions')} data-testid='restaurantAppMenu.promotionsLink'>
                        <ListItemIcon>
                            <PromotionIcon title={translate('Promotions')} color={isPromotionsRoute ? theme.palette.icons.invert : theme.palette.icons.brand} />
                        </ListItemIcon>
                    </AppMenuLink>
                </SecuredContent>
            )}
            {(ownFleetEnabled || externalOwnFleetEnabled) && (
                <SecuredContent rolePermission={RolePermissions.OWN_FLEET_PAGE}>
                    <AppMenuLink routePath={RoutePaths.OWN_FLEET_DRIVERS} text={translate('Own Drivers')} data-testid='restaurantAppMenu.ownDriversLink'>
                        <ListItemIcon>
                            <DriverIcon color={isOwnFleetDriversRoute ? theme.palette.icons.invert : theme.palette.icons.brand} title={'driver'} />
                        </ListItemIcon>
                    </AppMenuLink>
                </SecuredContent>
            )}
            {renderPromoCodesOption && (
                <SecuredContent rolePermission={RolePermissions.PROMO_CODES_PAGE}>
                    <AppMenuLink routePath={RoutePaths.PROMO_CODES} text={translate('Promo Codes')} data-testid='restaurantAppMenu.promoCodesLink'>
                        <ListItemIcon>
                            <StarTicketIcon title={'star'} color={isPromoCodesRoute ? theme.palette.icons.invert : theme.palette.icons.brand} />
                        </ListItemIcon>
                    </AppMenuLink>
                </SecuredContent>
            )}
            {renderPaymentLinksOption && (
                <SecuredContent rolePermission={RolePermissions.PAYMENT_LINKS_PAGE}>
                    <AppMenuLink routePath={RoutePaths.PAYMENT_LINKS} text={translate('Payment Links')} data-testid='restaurantAppMenu.paymentLinksLink'>
                        <ListItemIcon>
                            <WalletIcon title={'wallet'} color={isPaymentLinksRoute ? theme.palette.icons.invert : theme.palette.icons.brand} />
                        </ListItemIcon>
                    </AppMenuLink>
                </SecuredContent>
            )}
            {!isFreeTrialSubscription(subscriptionPlan) && (
                <SecuredContent rolePermission={RolePermissions.SURVEYS_PAGE}>
                    <AppMenuLink routePath={RoutePaths.SURVEYS} text={translate('Surveys')} data-testid='restaurantAppMenu.surveysLink'>
                        <ListItemIcon>
                            <SurveyIcon title='document' color={isSurveysRoute ? theme.palette.icons.invert : theme.palette.icons.brand} />
                        </ListItemIcon>
                    </AppMenuLink>
                </SecuredContent>
            )}
            {renderMarketingOption && <RestaurantMarketingAppMenu />}
            {!isFreeTrialSubscription(subscriptionPlan) && <RestaurantInvoicesAppMenu />}
            <AppMenuLink routePath={RoutePaths.HOW_DOES_IT_WORK} text={translate('How Does It Work?')} data-testid='restaurantAppMenu.howDoesItWorkLink'>
                <ListItemIcon>
                    <VideoLibraryOutlinedIcon title={'video library'} color={isHowDoesItWorkRoute ? theme.palette.icons.invert : theme.palette.icons.brand} />
                </ListItemIcon>
            </AppMenuLink>
            <SecuredContent rolePermission={RolePermissions.CONFIGURATION}>
                <RestaurantConfigurationAppMenu />
            </SecuredContent>
            {(viewUser || internalUser) && (
                <AppMenuLink routePath={RoutePaths.ADMIN_RESTAURANT_MANUAL_EARNING} text={translate('Restaurant Manual Earning')} data-testid='restaurantAppMenu.restaurantManualEarningLink' />
            )}
            {(adminUser || viewUser) && <AppMenuLink routePath={RoutePaths.CUSTOMER_RETURNS} text={translate('CustomersInfo Return')} data-testid='restaurantAppMenu.customersInfoReturnLink' />}
            <FeedBackSection />
        </List>
    );
}
