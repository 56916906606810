/**
 * @prettier
 */
import { InputBase, InputLabel, makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import * as React from 'react';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { AutoCompleteInputProp, Variant } from 'src/components/form/FormTypes';
import { transformToOutput } from 'src/components/form/material-ui/TransformedTextField';
import { getError } from 'src/components/form/utils/getError';
import { translate } from 'src/i18n/translate';

export function FormTextAreaField({
    name,
    label,
    placeholder,
    helperText,
    defaultValue = null,
    autoComplete = 'off',
    trim = true,
    upperCase,
    disabled,
    required,
    min,
    max,
    variant,
    InputProps,
    inputProps,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const inputRef = useRef<HTMLInputElement>();
    const classes = useStyles();
    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <div>
                    <InputLabel htmlFor={`${name}-textarea`} classes={{ root: classes.label, error: classes.labelError }} error={!!getError(errors, name)} required={required as any}>
                        {label}
                    </InputLabel>
                    <InputBase
                        {...props}
                        {...InputProps}
                        id={`${name}-textarea`}
                        inputComponent={'textarea'}
                        classes={{ root: classes.input, error: classes.inputError, input: classes.textarea }}
                        value={value}
                        onChange={(value) => onChange?.(transformToOutput(value as any, trim, upperCase))}
                        placeholder={placeholder}
                        disabled={isSubmitting || disabled}
                        required={required}
                        error={!!getError(errors, name)}
                        variant={variant}
                        inputRef={inputRef}
                        inputProps={{ autoComplete, ...inputProps }}
                        fullWidth
                    />
                    {!!(getError(errors, name)?.message ?? helperText) && (
                        <FormHelperText classes={{ root: !!getError(errors, name) ? classes.helperTextError : classes.helperText }}>{getError(errors, name)?.message ?? helperText}</FormHelperText>
                    )}
                </div>
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
                min: {
                    value: (min as any)?.value ?? min,
                    message: (min as any)?.message ?? translate('Must be number equal to or larger than @number', { number: (min as any)?.value ?? min }),
                },
                max: {
                    value: (max as any)?.value ?? max,
                    message: (max as any)?.message ?? translate('Must be number equal to or less than @number', { number: (max as any)?.value ?? max }),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    input: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 5,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontFamily: theme.typography.regular,
        border: '1px solid #d9d9d9',
        width: '100%',
        height: 100,
    },
    textarea: {
        height: '100%',
        textAlign: 'start',
        resize: 'none',
        boxSizing: 'border-box',
    },
    inputError: {
        border: '2px solid red',
    },
    label: {
        fontFamily: theme.typography.medium,
        color: theme.palette.secondary.contrastText,
        fontSize: 15,
        marginBottom: 5,
    },
    labelError: {
        color: 'red',
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: theme.palette.secondary.contrastText,
    },
    helperTextError: {
        fontFamily: theme.typography.light,
        color: 'red',
    },
}));

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: number | null | undefined;
    autoComplete?: AutoCompleteInputProp;
    thousandSeparator?: 'on' | 'off';
    disabled?: boolean;
    trim?: boolean;
    upperCase?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    min?:
        | number
        | {
              value: number;
              message: string;
          };
    max?:
        | number
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    inputProps?: any;
};
