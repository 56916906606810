// this is an auto generated file. This will be overwritten

export const onChangeDeliveryManager = /* GraphQL */ `
    subscription OnChangeDeliveryManager($orderId: ID) {
        onChangeDeliveryManager(orderId: $orderId) {
            orderId
            awsExecutionArn
            deliveryManagerStatus
            triedToRequestUberDirectDeliveryAt
            triedToRequestRappiCargoDeliveryAt
            error
            deliveryProviders
            dynamicDeliveryManager
            cyclicalDeliveryManager
            createdAt
            startAt
            startedAt
            restartAt
            restartedAt
            lastRunAt
            cancelledAt
            failedAt
            completedAt
        }
    }
`;
export const onChangeDelivery = /* GraphQL */ `
    subscription OnChangeDelivery($deliveryId: ID!) {
        onChangeDelivery(deliveryId: $deliveryId) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const onChangeDeliveryByOrderId = /* GraphQL */ `
    subscription OnChangeDeliveryByOrderId($orderId: ID!) {
        onChangeDeliveryByOrderId(orderId: $orderId) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const onCreateDeliveryRequestByDriverId = /* GraphQL */ `
    subscription OnCreateDeliveryRequestByDriverId($driverId: ID!) {
        onCreateDeliveryRequestByDriverId(driverId: $driverId) {
            deliveryId
            orderId
            driverId
            deliveryStatus
            deliveryType
            isNewRestaurant
            paymentMethod
            positionOnRequest {
                type
                coordinates
            }
            positionedAtOnRequest
            requestedAt
            rejectedAt
            acceptedAt
            arrivedAtStoreAt
            pickedUpAt
            arrivedAtClientAt
            deliveredAt
            cancelledAt
            withdrawnAt
            customerFirstName
            customerLastName
            customerMobileNumber
            customerAddressId
            customerAddressLocation {
                type
                coordinates
            }
            customerAddressStreet
            customerAddressOther
            customerAddressInstructions
            customerAddressGooglePlaceId
            customerAddressGooglePlaceIdV2
            customerAddressFormattedAddress
            orderItems {
                name
                quantity
                modifierGroups {
                    name
                    modifiers {
                        name
                        quantity
                        subModifierGroups {
                            name
                            subModifiers {
                                name
                                quantity
                            }
                        }
                    }
                }
            }
            driverName
            driverInstructions
            deliverySecurityCode
            restaurantName
            restaurantStreet
            restaurantPhoneNumber
            restaurantLocation {
                type
                coordinates
            }
            invoiceRestaurantDeliveryCost
            deliveryCost
            deliveryCostBonus
            deliveryEarnings
            deliveryEarningsInCash
            deliveryEarningsDeposited
            dynamicDeliveryEarnings
            driverBonusIds
            driverBonuses {
                driverBonusId
                driverBonusOfferId
                name
                description
                deliveries
                deliveriesRequired
            }
            drivingDistance
            paymentMethodCash
            payRestaurant
            chargeCustomer
            pickupTime
            preparationTime
        }
    }
`;
export const onChangeDriverPosition = /* GraphQL */ `
    subscription OnChangeDriverPosition($driverId: ID) {
        onChangeDriverPosition(driverId: $driverId) {
            driverId
            position {
                type
                coordinates
            }
            positionedAt
        }
    }
`;
