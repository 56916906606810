/**
 * @prettier
 */
import { letseatmanagerApiMethod, letseatmanagerApiMethodPublic } from 'src/api/letseatmanagerApi';
import { getJwtToken } from 'src/api/utils/getJwtToken';
import { envUI_LOG_EVENT_DISABLED } from 'src/env/envUI_LOG_EVENT_DISABLED';
import { pushUiInteractionLogEventToLocalStorage } from 'src/localStorage/logEvent/pushUiInteractionLogEventToLocalStorage';
import type { UiLogEvent } from 'src/services/logEvent/types/UiLogEvent';

/**
 * calling the api method from the service layer is against conventions,
 * but we want to avoid asynchronous api calls for Ui logEvents
 */
export function createUiInteractionLogEvent(request: UiLogEvent): void {
    if (envUI_LOG_EVENT_DISABLED()) return;
    createUiInteractionLogEventAsynchronous({ ...request, occurredAt: new Date() });
}
async function createUiInteractionLogEventAsynchronous(request: UiLogEvent): Promise<void> {
    let response;
    if ((await getJwtToken()).jwtToken) {
        response = await letseatmanagerApiMethod('logEvent/createUiInteractionLogEventApi', request);
    } else {
        response = await letseatmanagerApiMethodPublic('logEvent/createUiInteractionLogEventApi', request);
    }

    if (!response.ok) pushUiInteractionLogEventToLocalStorage({ ...request, offline: true });
}
