/**
 * @prettier
 */
import { PrinterPaperSizes } from 'src/constants/PrinterPaperSize';
import type { PrinterDeprecatedVm } from 'src/types/PrinterDeprecatedVm';
import type { PrinterVm } from 'src/types/PrinterVm';

export function printerDeprecatedToPrinter(printer: PrinterDeprecatedVm): PrinterVm {
    return {
        printerId: printer.printerId,
        restaurantId: printer.restaurantId,
        deviceName: printer.deviceName,
        externalPrinterId: printer.externalPrinterId,
        serialNumber: printer.serialNumber,
        ipAddress: printer.ipAddress,
        port: printer.port,
        printerType: printer.type,
        printerBrand: printer.printerBrand,
        printerPaperSize: printer.printerPaperSize ?? (printer.isTabletPrinter ? PrinterPaperSizes['58_MM'] : PrinterPaperSizes['80_MM']),
        printerContent: printer.printerContent,
        disabled: printer.disabled,
        charactersPerRow: printer.charactersPerRow,
        channels: printer.channels,
        createdAt: printer.createdAt,
        modifiedAt: printer.modifiedAt,
    };
}
