/**
 * @prettier
 */
import { Grid, makeStyles } from '@material-ui/core';
import moment from 'moment-timezone';
import * as React from 'react';
import { useState } from 'react';
import { getInventorySuppliersReportApi } from 'src/api/letseatmanager/inventory/getInventorySuppliersReportApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { InventorySuppliersReportDetailDialog } from 'src/scenes/letseatmanager/inventorySuppliersReport/InventorySuppliersReportDetailDialog';
import { InventorySuppliersReportTable } from 'src/scenes/letseatmanager/inventorySuppliersReport/InventorySuppliersReportTable';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function InventorySuppliersReport(): React.ReactElement {
    const classes = useStyles();

    const restaurant = useSelector((state) => state.app.restaurant);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const period = useSelector((state) => state.app2.filterReportsState.period);

    const [detailsDialogState, setDetailsDialogState] = useState({ open: false, reportDetails: [], businessName: '' });

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, inventorySuppliersReport, refreshInventorySuppliersReports] = useLoadApi(
        getInventorySuppliersReportApi,
        {
            restaurantIds: [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).add(1, 'day').toISOString(),
        },
        {
            dependencies: [restaurantId, period?.from, period?.to],
        }
    );

    const onRowClick = (reportDetails: any) => {
        setDetailsDialogState({ open: true, reportDetails: reportDetails.supplyDetailBySupplier, businessName: reportDetails.businessName });
    };

    const handleClose = () => {
        setDetailsDialogState({ open: false, reportDetails: [], businessName: '' });
    };

    const isEmptyReport = () => {
        if (!inventorySuppliersReport) {
            return true;
        }
    };

    if (isEmptyReport()) {
        return (
            <div className={classes.container}>
                <UpdatingContentProgress loading={loading} top />
                <ReportSectionEmptyState />
            </div>
        );
    }

    return (
        <Grid container spacing={4} className={(classes as any).infographic}>
            <Grid item xs={12}>
                <InventorySuppliersReportTable loading={loading} inventorySuppliersReport={inventorySuppliersReport} onRowClick={onRowClick} refresh={refreshInventorySuppliersReports} />
                <InventorySuppliersReportDetailDialog
                    open={detailsDialogState.open}
                    onClose={handleClose}
                    supplyDetailsBySupplier={detailsDialogState.reportDetails}
                    businessName={detailsDialogState.businessName}
                />
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
    kpis: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
}));
