/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuId } from 'src/types/Id';

export async function syncMenuWithIntegrationsApi(request: SyncMenuWithIntegrationsApiRequest): ApiSauceResponse<SyncMenuWithIntegrationsApiResponse> {
    return letseatmanagerApiMethod('menu/syncMenuWithIntegrationsApi', request);
}

type SyncMenuWithIntegrationsApiRequest = {
    menuId: MenuId;
    force?: boolean;
};

export type SyncMenuWithIntegrationsApiResponse = {
    uberEatsMenuSyncSucceeded: {
        syncStatus: boolean;
        message: string;
    };
    rappiMenuSyncSucceeded: {
        syncStatus: boolean;
        message: string;
    };
    didiFoodMenuSyncSucceeded: {
        syncStatus: boolean;
        message: string;
    };
    pedidosYaMenuSyncSucceeded: {
        syncStatus: boolean;
        message: string;
    };
    message?: string;
};
