/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { changeOrderTypeApi } from 'src/api/letseatmanager/order/changeOrderTypeApi';
import { app2 } from 'src/app2';
import { ContextualMenuItem } from 'src/components/ContextualMenuItem';
import { OrderTypes } from 'src/constants/OrderType';
import { PickupTimeTypes } from 'src/constants/PickupTimeType';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangeOrderTypeContextualMenuItem({ order, onChangeOrderType }: Props): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const updateOrder = useAction(app2.actions.updateOrder);

    const handleTakeAwayOrder = async () => {
        setLoading(true);
        const response = await changeOrderTypeApi({
            restaurantId,
            orderId: order.orderId,
            orderType: OrderTypes.TAKE_AWAY_ORDER,
            pickupTimeType: PickupTimeTypes.ASAP,
            pickupTime: new Date(),
        });
        setLoading(false);

        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        onChangeOrderType?.();
        updateOrder({ order: response.data });
    };

    return (
        <ContextualMenuItem
            label={translate('Switch to order @orderType', { orderType: translate(`OrderTypes.${OrderTypes.TAKE_AWAY_ORDER}`) })}
            className={classes.item}
            onClick={handleTakeAwayOrder}
            disabled={loading}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    item: {
        fontSize: 14,
        '&:hover': {
            backgroundColor: '#EBFFF9',
        },
    },
}));

type Props = {
    order: OrderVm;
    onChangeOrderType?: any;
};
