/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { addCreditsToCustomerApi } from 'src/api/letseatmanager/marketing/addCreditsToCustomerApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { translate } from 'src/i18n/translate';
import { useNotification } from 'src/services/notification/useNotification';
import { appLayout } from 'src/theme/AppLayout';
import type { CustomerId } from 'src/types/Id';
import { useSelector } from 'src/utils/react/useSelector';

export function AddCreditsToCustomersDialog({ open, customerIds, onClose }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;
    const classes = useStyles();
    const notification = useNotification();

    const [loading, setLoading] = useState(false);

    const restaurantIds = useSelector((state) => state.app.restaurantIds);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        const result: Record<string, number> = { success: 0, failed: 0 } as const;
        for (const customerId of customerIds ?? []) {
            const response = await addCreditsToCustomerApi({
                restaurantIds,
                customerId,
                amount: form.amount,
            });
            if (response.ok) {
                result.success = result.success + 1;
            } else {
                result.failed = result.failed + 1;
            }
        }
        notification({
            message:
                translate('Result') +
                '\n' +
                Object.entries(result)
                    .map(([result, count]: [any, any]) => `${translate(result)}: ${String(count)}`)
                    .join('\n'),
        });
        setLoading(false);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Add Credits')} loading={loading}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <Grid item xs={12}>
                    <FormCurrencyNumberStringField name='amount' label={translate('Credits')} required InputProps={{ autoFocus: true }} disabled={loading} min={0} />
                </Grid>
                <DialogActions className={classes.buttonsContainer}>
                    <Button classes={{ button: classes.button }} secondary onClick={handleClose} disabled={isSubmitting || loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button classes={{ button: classes.button }} type='submit' disabled={isSubmitting || loading}>
                        {isSubmitting || loading ? translate('Adding') : translate('Add')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    buttonsContainer: {
        marginTop: 20,
    },
    button: {
        width: 200,
    },
}));

type Props = {
    open: boolean;
    customerIds?: Array<CustomerId>;
    onClose: any;
};
