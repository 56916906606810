/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CommandSection } from 'src/constants/CommandSection';
import type { RestaurantId } from 'src/types/Id';

export async function changeCommandSectionsApi(request: ChangeCommandSectionsRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurant/changeCommandSectionsApi', request);
}

type ChangeCommandSectionsRequest = {
    restaurantId: RestaurantId;
    commandSections: Array<CommandSection>;
    increaseCommandFontSizeEnabled?: boolean;
    numberOfCommandPrints?: number;
};
