/**
 * @prettier
 */
import { usePosPaymentMethodsActions } from 'src/services/pos/posPaymentMethods/posPaymentMethodsStore';
import { RestaurantPaymentMethodVm } from 'src/types/RestaurantPaymentMethodVm';

export function useSetPosPaymentMethods(): (posPaymentMethods: Array<RestaurantPaymentMethodVm>) => void {
    const setPosPaymentMethods = usePosPaymentMethodsActions((actions) => actions.setPosPaymentMethods);

    return setPosPaymentMethods;
}
