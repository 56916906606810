/**
 * @prettier
 */
import { isValidPhoneNumber as _isValidPhoneNumber } from 'libphonenumber-js';
import { Countries } from 'src/constants/Country';

export function isValidPhoneNumber(value?: string | null): boolean {
    if (!value || value.length === 3) {
        return false;
    }
    return _isValidPhoneNumber(value, Countries.MX as any);
}
