/**
 * @prettier
 */
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import * as React from 'react';
import type { RestaurantBillingVm } from 'src/api/letseatadmin/restaurantBilling/getRestaurantBillingApi';
import { TableHeaderCalculation } from 'src/scenes/letseatadmin/restaurantBilling/TableHeaderCalculation';
import { TableRowCalculation } from 'src/scenes/letseatadmin/restaurantBilling/TableRowCalculation';
import { formatAsNumberNew } from 'src/utils/number/formatAsNumberNew';

export function CommissionCalculation({ restaurantBillingVm }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size='small'>
                <TableHeaderCalculation name={`Commission Calculation`} />
                <TableBody>
                    <TableRowCalculation
                        name={`Web Card Orders + Payment Link - Restaurant Promo Code Cost App`}
                        calculation={`(${[
                            [formatAsNumberNew(restaurantBillingVm.webCard, { fractionDigits: 2 } || 0), formatAsNumberNew(restaurantBillingVm.paymentLink, { fractionDigits: 2 } || 0)].join(' + '),
                            formatAsNumberNew(restaurantBillingVm.restaurantPromoCodeCostApp, { fractionDigits: 2 } || '0'),
                        ].join(' - ')}) * ${formatAsNumberNew(restaurantBillingVm.commissionWebCard)}%`}
                        amount={restaurantBillingVm.commissionForWebCardOrders}
                    />
                    <TableRowCalculation
                        name={`Web Cash Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.webCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionWebCash)}%`}
                        amount={restaurantBillingVm.commissionForWebCashOrders}
                    />
                    <TableRowCalculation
                        name={`Web Card on delivery Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.webCardOnDelivery, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionWebCardOnDelivery)}%`}
                        amount={restaurantBillingVm.commissionForWebCardOnDeliveryOrders}
                    />
                    <TableRowCalculation
                        name={`White Label App Card Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.whiteLabelAppCard, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionAppCard)}%`}
                        amount={restaurantBillingVm.commissionForWhiteLabelAppCardOrders}
                    />
                    <TableRowCalculation
                        name={`White Label App Cash Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.whiteLabelAppCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionAppCash)}%`}
                        amount={restaurantBillingVm.commissionForWhiteLabelAppCashOrders}
                    />
                    <TableRowCalculation
                        name={`White Label App Card on delivery Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.whiteLabelAppCardOnDelivery, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            restaurantBillingVm.commissionAppCardOnDelivery
                        )}%`}
                        amount={restaurantBillingVm.commissionForWhiteLabelAppCardOnDeliveryOrders}
                    />
                    <TableRowCalculation
                        name={`Market Place App Card Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.marketplaceAppCard, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionAppCard)}%`}
                        amount={restaurantBillingVm.commissionForMarketPlaceAppCardOrders}
                    />
                    <TableRowCalculation
                        name={`Market Place App Cash Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.marketplaceAppCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionAppCash)}%`}
                        amount={restaurantBillingVm.commissionForMarketPlaceAppCashOrders}
                    />
                    <TableRowCalculation
                        name={`Market Place App Card on delivery Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.marketplaceAppCardOnDelivery, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            restaurantBillingVm.commissionAppCardOnDelivery
                        )}%`}
                        amount={restaurantBillingVm.commissionForMarketPlaceAppCardOnDeliveryOrders}
                    />
                    <TableRowCalculation
                        name={`Manual Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.manualOrders, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionManualOrders)}%`}
                        amount={restaurantBillingVm.commissionForManualOrders}
                    />
                    <TableRowCalculation
                        name={`Rappi Cargo Web Card`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.rappiCargoWebCard, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionRappiCargoWebCard)}%`}
                        amount={restaurantBillingVm.commissionForRappiCargoWebCard}
                    />
                    <TableRowCalculation
                        name={`Rappi Cargo Web Cash`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.rappiCargoWebCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionRappiCargoWebCash)}%`}
                        amount={restaurantBillingVm.commissionForRappiCargoWebCash}
                    />
                    <TableRowCalculation
                        name={`Rappi Cargo App Card`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.rappiCargoAppCard, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionRappiCargoAppCard)}%`}
                        amount={restaurantBillingVm.commissionForRappiCargoAppCard}
                    />
                    <TableRowCalculation
                        name={`Rappi Cargo App Cash`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.rappiCargoAppCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionRappiCargoAppCash)}%`}
                        amount={restaurantBillingVm.commissionForRappiCargoAppCash}
                    />
                    <TableRowCalculation
                        name={`Rappi Cargo Manual Order`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.rappiCargoManualOrder, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            restaurantBillingVm.commissionRappiCargoManualOrder
                        )}%`}
                        amount={restaurantBillingVm.commissionForRappiCargoManualOrder}
                    />
                    <TableRowCalculation
                        name={`Rappi Cargo Payment Link`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.rappiCargoPaymentLink, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionWebCard)}%`}
                        amount={restaurantBillingVm.commissionForRappiCargoPaymentLink}
                    />
                    <TableRowCalculation
                        name={`Rappi Cargo Pos Cash`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.rappiCargoPosCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionRappiCargoPosCash)}%`}
                        amount={restaurantBillingVm.commissionForRappiCargoPosCash}
                    />
                    <TableRowCalculation
                        name={`Rappi Cargo Pos Card`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.rappiCargoPosCard, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionRappiCargoPosCard)}%`}
                        amount={restaurantBillingVm.commissionForRappiCargoPosCard}
                    />
                    <TableRowCalculation
                        name={`Uber Daas Web Card`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.uberDaasWebCard, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionUberDaasWebCard)}%`}
                        amount={restaurantBillingVm.commissionForUberDaasWebCard}
                    />
                    <TableRowCalculation
                        name={`Uber Daas Web Cash`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.uberDaasWebCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionUberDaasWebCash)}%`}
                        amount={restaurantBillingVm.commissionForUberDaasWebCash}
                    />
                    <TableRowCalculation
                        name={`Uber Daas App Card`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.uberDaasAppCard, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionUberDaasAppCard)}%`}
                        amount={restaurantBillingVm.commissionForUberDaasAppCard}
                    />
                    <TableRowCalculation
                        name={`Uber Daas App Cash`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.uberDaasAppCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionUberDaasAppCash)}%`}
                        amount={restaurantBillingVm.commissionForUberDaasAppCash}
                    />
                    <TableRowCalculation
                        name={`Uber Daas Manual Order`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.uberDaasManualOrder, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionUberDaasManualOrder)}%`}
                        amount={restaurantBillingVm.commissionForUberDaasManualOrder}
                    />
                    <TableRowCalculation
                        name={`Uber Daas Payment Link`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.uberDaasPaymentLink, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionWebCard)}%`}
                        amount={restaurantBillingVm.commissionForUberDaasPaymentLink}
                    />
                    <TableRowCalculation
                        name={`Uber Daas Pos Cash`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.uberDaasPosCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionUberDaasPosCash)}%`}
                        amount={restaurantBillingVm.commissionForUberDaasPosCash}
                    />
                    <TableRowCalculation
                        name={`Uber Daas Pos Card`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.uberDaasPosCard, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingVm.commissionUberDaasPosCard)}%`}
                        amount={restaurantBillingVm.commissionForUberDaasPosCard}
                    />
                    <TableRowCalculation name={`Commission`} amount={restaurantBillingVm.commission} />
                    <TableRowCalculation
                        name={`IVA`}
                        calculation={`${formatAsNumberNew(restaurantBillingVm.commission, { fractionDigits: 2 })} * ${formatAsNumberNew('16')}%`}
                        amount={restaurantBillingVm.iva}
                    />
                </TableBody>
            </Table>
        </TableContainer>
    );
}

type Props = {
    restaurantBillingVm: RestaurantBillingVm;
};

const useStyles = makeStyles({
    table: {},
});
