/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { memo, useCallback, useMemo } from 'react';
import * as React from 'react';
import { PosMenuItem } from 'src/scenes/letseatmanager/pos/posMenu/PosMenuItem';
import { MenuId, MenuItemId, type MenuCategoryId } from 'src/types/Id';
import type { MenuCategoryVm, MenuItemVm } from 'src/types/PosMenuVm';
import { useSelector } from 'src/utils/react/useSelector';
import { removeNulls } from 'src/utils/removeNulls';

export const PosMenuItems: React.ComponentType<Props> = memo<Props>(({ productFilter }: Props) => {
    const classes = useStyles();

    const menuCategories = useSelector((state) => state.pos.posMenu?.menuCategories) ?? [];
    const menuItems = useSelector((state) => state.pos.posMenu?.menuItems) ?? [];
    const restaurantMenu = useSelector((state) => state.pos.posMenu?.restaurantMenu);
    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant.newMenuManagementEnabled);
    const selectedMenuCategory = useSelector((state) => state.pos.selectedMenuCategory);
    const selectedMenu = useSelector((state) => state.pos.selectedMenu);

    const doesMenuItemMatchSearch = useCallback(
        (menuItem: MenuItemVm) => {
            try {
                return menuItem.name.match(new RegExp(productFilter, 'ig'));
            } catch (e: any) {
                return false;
            }
        },
        [productFilter]
    );

    const getMenuItemsFilteredBySearch = () => {
        if (!newMenuManagementEnabled) {
            const filteredMenuItems = menuItems.filter(doesMenuItemMatchSearch);
            return filteredMenuItems as any;
        }

        const mappedMenuItems: Array<MappedMenuItem> = menuCategories?.reduce((mappedMenuItems: Array<MappedMenuItem>, menuCategory: MenuCategoryVm) => {
            const usedMenuItems = menuItems.filter((menuItem) => menuCategory.menuItemIds?.includes(menuItem.menuItemId));
            const newMappedMenuItems = usedMenuItems.map((menuItem) => ({ ...menuItem, menuCategoryId: menuCategory.menuCategoryId }));
            return [...mappedMenuItems, ...newMappedMenuItems];
        }, []);
        const filteredMenuItems = mappedMenuItems.filter(doesMenuItemMatchSearch);
        return filteredMenuItems;
    };

    const menuItemsToShow = useMemo(() => {
        if (!selectedMenuCategory) return [];
        if (!!productFilter) {
            return getMenuItemsFilteredBySearch();
        }

        const notHiddenMenuItemsForSelectedMenu = menuItems.filter((menuItem) => {
            const menuItemMenu = menuItem.menusProductInfo?.some(
                (menuItemMenu: { hidden?: boolean; menuId: MenuId; price?: string; promoPrice?: string; promoText?: string }) => menuItemMenu.menuId === selectedMenu.menuId && menuItemMenu.hidden
            );
            return !menuItemMenu;
        });

        if (newMenuManagementEnabled) {
            return removeNulls(
                selectedMenuCategory.menuItemIds?.map((menuItemId: MenuItemId) => {
                    const menuItem = notHiddenMenuItemsForSelectedMenu.find((menuItem) => menuItem.menuItemId === menuItemId);
                    if (!menuItem) return null as any;
                    return { ...menuItem, menuCategoryId: undefined };
                })
            );
        }

        if (!restaurantMenu) return [];

        const menuCategoriesStructure = restaurantMenu.menus.flatMap((menu) => menu.menuCategories);
        const menuCategoryStructure = menuCategoriesStructure.find((menuCategoryStructure) => menuCategoryStructure.menuCategoryId === selectedMenuCategory?.menuCategoryId);
        if (!menuCategoryStructure) return [];

        return removeNulls(
            menuCategoryStructure.menuItemIds.map((menuItemId) => {
                const menuItem = notHiddenMenuItemsForSelectedMenu.find((menuItem) => menuItem.menuItemId === menuItemId);
                if (!menuItem) return null as any;
                return { ...menuItem, menuCategoryId: undefined };
            })
        );
    }, [selectedMenuCategory, menuItems, productFilter, menuCategories]);

    return (
        <div className={classes.itemsContainer}>
            {selectedMenuCategory &&
                menuItemsToShow?.map((mappedMenuItem: any, idx: number) => {
                    const { menuCategoryId, ...menuItem } = mappedMenuItem;
                    const posMenuItem = { ...menuItem, menuId: selectedMenu.menuId } as const;
                    const key = `${menuItem.menuItemId}-${idx}` + (menuCategoryId ?? selectedMenuCategory?.menuCategoryId ?? '');
                    return <PosMenuItem item={posMenuItem} key={key} menuCategoryId={menuCategoryId} />;
                })}
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    itemsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
        gap: 12,
        maxHeight: '100%',
        width: '100%',
        overflowY: 'auto',
        paddingBottom: '10vh',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
}));

type MappedMenuItem = MenuItemVm & {
    menuCategoryId?: MenuCategoryId;
};

type Props = {
    productFilter: string;
};
