/**
 * @prettier
 */
import { envENVIRONMENT } from 'src/env/envENVIRONMENT';
import { requireStringNotBlank } from 'src/utils/require/requireStringNotBlank';

export function envSENTRY_DNS(): string {
    try {
        return requireStringNotBlank(process.env.REACT_APP_SENTRY_DNS);
    } catch (e: any) {
        throw Error(`REACT_APP_SENTRY_DNS is not configured correctly in .env.${envENVIRONMENT().toLowerCase()}`);
    }
}
