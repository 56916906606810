/**
 * @prettier
 */
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { Transition } from 'react-transition-group';
import { closeDidiFoodStoreApi } from 'src/api/letseatmanager/restaurantIntegration/closeDidiFoodStoreApi';
import { openDidiFoodStoreApi } from 'src/api/letseatmanager/restaurantIntegration/openDidiFoodStoreApi';
import { toggleDeliverOrdersFromDidiFoodApi } from 'src/api/letseatmanager/restaurantIntegration/toggleDeliverOrdersFromDidiFoodApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Switch } from 'src/components/Switch';
import { translate } from 'src/i18n/translate';
import didiFoodLogo from 'src/images/didifood-logo.png';
import { ChangeDidiIntegrationPercentageDialog } from 'src/scenes/letseatmanager/integrations/ChangeDidiIntegrationPercentageDialog';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';
import { formatAsPercentage } from 'src/utils/number/formatAsPercentage';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function DidiIntegration({ restaurantIntegrationsStatus, load, isIntegrationLoaded }: { restaurantIntegrationsStatus: any; load: any; isIntegrationLoaded: boolean }): React.ReactElement {
    const classes = useStyles();

    const didiFoodStoreOnline = useSelector((state) => state.app2.didiFoodStoreOnline);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const adminUser = useSelector((state) => state.authentication.adminUser);
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const setDidiFoodStoreOnline = useAction(app2.actions.setDidiFoodStoreOnline);
    const [confirmDeliverOrdersFromDidiFoodDialogState, setConfirmDeliverOrdersFromDidiFoodDialogState] = useState(false);
    const [didiChangePercentageDialogState, setDidiChangePercentageDialogState] = useState({ open: false });

    const [loading, setLoading] = useState(false);

    const closeDidiFoodStore = async () => {
        setLoading(true);
        const response = await closeDidiFoodStoreApi({ restaurantId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response, translate('You can only try opening and closing once a minute.'));
            setLoading(false);
            return;
        }
        setDidiFoodStoreOnline(false);
        load();
    };

    const openDidiFoodStore = async () => {
        setLoading(true);
        const response = await openDidiFoodStoreApi({ restaurantId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response, translate('You can only try opening and closing once a minute.'));
            setLoading(false);
            return;
        }
        setDidiFoodStoreOnline(true);
        setLoading(false);

        load();
    };

    const ConfirmDeliverOrdersFromDidiFoodDialog = () => {
        return (
            // @ts-ignore
            <Dialog open={confirmDeliverOrdersFromDidiFoodDialogState} TransitionComponent={Transition} keepMounted aria-describedby='alert-dialog-slide-description'>
                <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Confirm')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description' classes={{ root: classes.dialogText }}>
                        {restaurantIntegrationsStatus.deliverOrdersFromDidiFood
                            ? translate('Do you wish to stop delivering DidiFood Orders by PideDirecto?')
                            : translate('Do you wish to start delivering DidiFood Orders by PideDirecto?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.buttonsContainer}>
                    <Button disabled={loading || viewUser || isIntegrationLoaded} onClick={() => handleConfirmDeliverOrdersFromDidiFoodDialogState(false)} secondary>
                        {translate('Cancel')}
                    </Button>
                    <Button disabled={loading || viewUser || isIntegrationLoaded} onClick={() => handleConfirmDeliverOrdersFromDidiFoodDialogState(true)} primary>
                        {translate('Agree')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleClickIntegrateDidiFood = async () => {
        if (restaurantIntegrationsStatus.didiFoodStoreId) {
            const password = window.prompt('Please enter password to remove Restaurant', 'Password');
            if (password === '9999') {
                const remove = window.confirm('Are you sure you want to desintegrate this restaurant?');
                if (remove) {
                    return window.open(
                        `https://didi-food.com/es-MX/store/ui-sdk/authorization?appId=${isProductionEnvironment() ? '5764607750573654187' : '5764607542590701817'}&appShopId=${restaurantId}`,
                        '_blank'
                    );
                }
            } else {
                alert('Ask your seller for the password to desintegrate');
            }
        }

        return window.open(
            `https://didi-food.com/es-MX/store/ui-sdk/authorization?appId=${isProductionEnvironment() ? '5764607750573654187' : '5764607542590701817'}&appShopId=${restaurantId}`,
            '_blank'
        );
    };

    const handleConfirmDeliverOrdersFromDidiFoodDialogState = async (confirmed: boolean) => {
        if (!confirmed) {
            setConfirmDeliverOrdersFromDidiFoodDialogState(false);
            return;
        }
        setLoading(true);
        await toggleDeliverOrdersFromDidiFoodApi({ restaurantId });
        await load();
        setLoading(false);
        setConfirmDeliverOrdersFromDidiFoodDialogState(false);
    };

    const handleChangeIntegrationPercentage = async () => {
        setDidiChangePercentageDialogState({ open: true });
    };

    const handleOnSuccessChangePercentage = () => {
        setDidiChangePercentageDialogState({ open: false });
        load();
    };

    return (
        <>
            <ChangeDidiIntegrationPercentageDialog
                open={didiChangePercentageDialogState.open}
                onClose={() => setDidiChangePercentageDialogState({ open: false })}
                onSuccess={handleOnSuccessChangePercentage}
            />
            <div className={classes.integrationContainer}>
                <div className={classes.integrationInfoContainer}>
                    <div className={classes.integrationImageContainer}>
                        <img src={didiFoodLogo} />
                    </div>
                    <div className={classes.integrationInfo}>
                        <div className={classes.integrationNameContainer}>
                            <span className={classes.integrationName}>Didi Food</span>
                            {/* TODO: Fix classes.rappiStoreId condition */}
                            {/* @ts-ignore */}
                            <span className={classes.didiFoodStoreId ? classes.integrated : classes.notIntengrated}>
                                {restaurantIntegrationsStatus.didiFoodStoreId ? translate('Integrated') : translate('Not integrated')}
                            </span>
                            {restaurantIntegrationsStatus.didiFoodStoreId &&
                                (didiFoodStoreOnline ? (
                                    <span className={classes.integrationStoreStatusOpen}>{translate('Opened')}</span>
                                ) : (
                                    <span className={classes.integrationStoreStatusClosed}>{translate('Closed')}</span>
                                ))}
                        </div>

                        <div className={classes.integrationPercentageInfoContainer}>
                            <span className={classes.integrationPercentageInfo}>
                                {restaurantIntegrationsStatus.didiFoodSalesCommissionPercentage ? translate('Commission of') : translate('Without commission')}
                                <strong>
                                    {' '}
                                    {restaurantIntegrationsStatus.didiFoodSalesCommissionPercentage ? formatAsPercentage(restaurantIntegrationsStatus.didiFoodSalesCommissionPercentage) : ''}
                                </strong>
                            </span>

                            <Button onClick={handleChangeIntegrationPercentage} text disabled={loading || isIntegrationLoaded || viewUser}>
                                {translate('Edit')}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={classes.actionsContainer}>
                    <div className={classes.buttonGroup}>
                        <Button
                            classes={{ button: !!restaurantIntegrationsStatus.didiFoodStoreId ? classes.buttonDisintegrate : undefined }}
                            onClick={() => handleClickIntegrateDidiFood()}
                            disabled={loading || isIntegrationLoaded || viewUser}
                            primary
                        >
                            {restaurantIntegrationsStatus.didiFoodStoreId ? translate('Disintegrate') : translate('Integrate')}
                        </Button>
                        {didiFoodStoreOnline === true && (
                            <Button classes={{ button: classes.buttonCloseStore }} onClick={() => closeDidiFoodStore()} disabled={loading} secondary>
                                {translate('Close Didi Food Store')}
                            </Button>
                        )}
                        {didiFoodStoreOnline === false && (
                            <Button classes={{ button: classes.buttonCloseStore }} onClick={() => openDidiFoodStore()} disabled={loading} secondary>
                                {translate('Open Didi Food Store')}
                            </Button>
                        )}
                    </div>

                    {adminUser && (
                        <div className={classes.integrationInfoContainer}>
                            <span className={classes.integrationLabel}>{translate('Deliver orders')}</span>
                            <Switch
                                value={restaurantIntegrationsStatus.deliverOrdersFromDidiFood}
                                name='deliverOrdersFromDidiFood'
                                onChange={() => {
                                    if (restaurantIntegrationsStatus.deliverOrdersFromDidiFood) {
                                        const password = window.prompt('Please enter password to remove Restaurant', 'Password');
                                        if (password === '9999') {
                                            const remove = window.confirm('Are you sure you want to stop delivering this restaurant with Pd?');
                                            if (remove) {
                                                setConfirmDeliverOrdersFromDidiFoodDialogState(true);
                                                return;
                                            }
                                        } else {
                                            alert('Ask your seller for the password to desintegrate');
                                            return;
                                        }
                                    }
                                    setConfirmDeliverOrdersFromDidiFoodDialogState(true);
                                }}
                                disabled={viewUser}
                            />
                        </div>
                    )}
                    <ConfirmDeliverOrdersFromDidiFoodDialog />
                </div>
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    integrationContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        backgroundColor: theme.palette.secondary.light,
        minHeight: 120,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: 'fit-content',
            paddingTop: 20,
            paddingBottom: 20,
        },
        borderRadius: 8,
        border: '1px solid #D9D9D9',
        background: '#FAFBFB',
        padding: '12px',
    },
    integrationInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: 10,
            justifyContent: 'space-between',
        },
        gap: '1rem',
    },
    integrationImageContainer: {
        backgroundColor: 'white',
        width: 80,
        height: 80,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 20,
    },
    integrationInfo: {
        display: 'flex',
        flexDirection: 'column',
    },
    integrationName: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
    },
    integrationStatus: {
        fontFamily: theme.typography.medium,
        fontSize: 13,
    },
    notIntengrated: {
        fontFamily: theme.typography.regular,
        color: '#fa6684',
        fontSize: 14,
    },
    integrationStoreStatusClosed: {
        fontFamily: theme.typography.semiBold,
        color: '#fa6684',
        fontSize: 13,
    },
    integrationStoreStatusOpen: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 13,
    },
    buttonDisintegrate: {
        backgroundColor: '#fa6684',
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    buttonGroup: {
        flexDirection: 'row',
        gap: '1rem',
        display: 'flex',
    },
    buttonCloseStore: {
        padding: '4px 16px',
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    dialogText: {
        fontFamily: theme.typography.regular,
        fontSize: 18,
        textAlign: 'center',
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    noMargin: {
        margin: '0 !important',
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: 10,
            justifyContent: 'space-between',
        },
        gap: '1rem',
    },
    integrationLabel: {
        fontFamily: theme.typography.regular,
        color: '#2E3748',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    integrationNameContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    integrationPercentageInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1rem',
    },
    integrationPercentageInfo: {
        fontFamily: theme.typography.regular,
        color: '#2E3748',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        whiteSpace: 'nowrap',
    },
    link: {
        fontFamily: theme.typography.regular,
        color: '#06B7A2',
        fontSize: 14,
        lineHeight: '31px',
        textDecorationLine: 'underline',
        border: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#06B7A2',
            textDecorationLine: 'underline',
        },
    },
    integrated: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 14,
    },
}));
