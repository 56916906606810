/**
 * @prettier
 */
export const PublicRoutePaths = {
    HOME: '/',
    RESTAURANT_SELF_REGISTRATION: '/registro',
    NOT_FOUND: '*',
} as const;

export type PublicRoutePath = typeof PublicRoutePaths[keyof typeof PublicRoutePaths];
