/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export async function markOrderAsPreparedApi(request: MarkOrderAsPreparedRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('kitchen/markOrderAsPreparedApi', request);
}

export type MarkOrderAsPreparedRequest = {
    orderId: OrderId;
};
