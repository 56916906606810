/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { PrinterOutlineIcon } from 'src/icons/PrinterOutlineIcon';
import { usePrintShiftCashRegisterReport } from 'src/services/printer/usePrintShiftCashRegisterReport';
import { getCashRegisterShiftReportDemo } from 'src/utils/posBusinessDay/getCashRegisterShiftReportDemo';
import { useSelector } from 'src/utils/react/useSelector';

export function PrintCashRegisterReportTestButton({ disabled }: Props): React.ReactElement {
    const classes = useStyles();

    const [printShiftCashRegisterReport] = usePrintShiftCashRegisterReport();

    const restaurant = useSelector((state) => state.app.restaurant);

    const handlePrintCashRegisterReport = async () => {
        await printShiftCashRegisterReport(getCashRegisterShiftReportDemo(restaurant), true);
    };

    return (
        <Button onClick={handlePrintCashRegisterReport} disabled={disabled} classes={{ button: classes.printButton }}>
            <PrinterOutlineIcon color={'#FFF'} title={'print-pos-business-day-report-test'} />
            {translate('Print test')}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    printButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 50,
        width: 160,
        [theme.breakpoints.up('md')]: {
            alignSelf: 'center',
        },
        marginTop: 10,
    },
}));

type Props = {
    disabled: boolean;
};
