/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeRestaurantVideoApi } from 'src/api/letseatadmin/restaurantVideo/changeRestaurantVideoApi';
import { getRestaurantVideoApi } from 'src/api/letseatadmin/restaurantVideo/getRestaurantVideoApi';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { RestaurantVideoId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { requireValue } from 'src/utils/require/requireValue';

export function ChangeRestaurantVideoDialog({ open, restaurantVideoId, onClose, onChangeRestaurantVideo }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) load();
    }, [open]);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const handleExited = () => {
        setLoading(false);
    };

    const load = async () => {
        setLoading(true);
        const response = await getRestaurantVideoApi({ restaurantVideoId: requireValue(restaurantVideoId) });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            onClose();
            return;
        }
        const restaurantVideo = response.data;
        form.reset(restaurantVideo);
        setLoading(false);
    };

    const onSubmit = async (form: any) => {
        const response = await changeRestaurantVideoApi({
            restaurantVideoId: requireValue(restaurantVideoId),
            name: form.name,
            videoUrl: form.videoUrl,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onChangeRestaurantVideo();
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} onExited={handleExited}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Change Restaurant Video').toUpperCase()}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField name='name' label={translate('Restaurant Video')} disabled={loading} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='videoUrl' label={translate('Video Url')} required disabled={loading} />
                        </Grid>
                    </Grid>
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={loading || isSubmitting}>
                        {isSubmitting ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    restaurantVideoId: RestaurantVideoId | undefined;
    onClose: any;
    onChangeRestaurantVideo: any;
};
