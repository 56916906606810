/**
 * @prettier
 */
import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function ResumeSectionInLiveMap(): React.ReactElement {
    const classes = useStyles();

    const ordersOverview = useSelector((state) => state.liveDrivers.ordersOverview);
    const driversOverview = useSelector((state) => state.liveDrivers.driversOverview);

    return (
        <div className={classes.container}>
            <section className={classes.resumeSection}>
                <Typography className={classes.resumeSectionTitle}>{translate('Orders resume')}</Typography>

                <InfoField value={ordersOverview?.activeOrders} title={translate('Active orders')} />
                <InfoField value={ordersOverview?.assignedOrders} title={translate('Assigned orders')} />
                <InfoField value={ordersOverview?.unassignedOrders} title={translate('Unassigned orders')} />
            </section>
            <section className={classes.resumeSection}>
                <Typography className={classes.resumeSectionTitle}>{translate('Drivers resume')}</Typography>
                <InfoField value={driversOverview?.activeDrivers} title={translate('Active delivery')} />
                <InfoField value={driversOverview?.driversWithoutOrder} title={translate('Delivery without order')} />
            </section>
        </div>
    );
}

function InfoField({ value, title }: Props): React.ReactElement {
    const classes = useStylesInfoField();

    return (
        <section className={classes.container}>
            <Typography className={classes.valueOfSection}>{value}</Typography>
            <Typography className={classes.titleOfSection}>{title}</Typography>
        </section>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            marginTop: '14px',
        },
    },
    resumeSection: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 20,
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            padding: '0 24px',
        },
    },
    resumeSectionTitle: {
        color: '#0FA489',
        fontWeight: 600,
        fontSize: '20px',
        width: '100%',
        textAlign: 'left',
    },
    filterInfo: {
        height: '10%',
        width: '100%',
    },
}));

const useStylesInfoField = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyItems: 'center',
        margin: 10,
    },
    valueOfSection: {
        color: '#224753',
        fontWeight: 600,
        fontSize: '28px',
    },
    titleOfSection: {
        color: '#6D7B7F',
        fontWeight: 400,
        fontSize: '16px',
        width: '100px',
        wordWrap: 'break-word',
        [theme.breakpoints.down('sm')]: {
            width: '130px',
        },
    },
}));

type Props = {
    value: number;
    title: string;
};
