/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverId } from 'src/types/Id';

export async function getDriverIdPhotoApi(request: GetDriverIdPhotoApiRequest): ApiSauceResponse<DriverIdPhotoVm> {
    return letseatadminApiMethod('driver/getDriverIdImagesApi', request);
}

export type GetDriverIdPhotoApiRequest = {
    driverId: DriverId;
};

export type DriverIdPhotoVm = {
    idFrontImageUrl?: string;
    idBackImageUrl?: string;
};
