/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button, Checkbox } from '@pidedirecto/ui';
import { ClosedEyeIcon, EyeIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { hideModifierInModifierGroupApi } from 'src/api/letseatmanager/modifierGroup/hideModifierInModifierGroupApi';
import { setPreselectedModifierInModifierGroupApi } from 'src/api/letseatmanager/modifierGroup/setPreselectedModifierInModifierGroupApi';
import { showModifierInModifierGroupApi } from 'src/api/letseatmanager/modifierGroup/showModifierInModifierGroupApi';
import { unsetPreselectedModifierInModifierGroupApi } from 'src/api/letseatmanager/modifierGroup/unsetPreselectedModifierInModifierGroupApi';
import { translate } from 'src/i18n/translate';
import { ModifierInModifierGroupContextualMenu } from 'src/scenes/letseatmanager/menu/modifierGroup/ModifierInModifierGroupContextualMenu';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { ModifierGroupId } from 'src/types/Id';
import type { ModifierVm } from 'src/types/ModifierVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function Modifier({ modifierGroupId, disabled, modifier, onChange }: Props): React.ReactElement {
    const classes = useStyles();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [loading, setLoading] = useState(false);

    const loadingModifierGroups = useSelector((state) => state.menu.loadingModifierGroups);

    const preselectedDisabled = (loading || isMenuEditionDisabled || disabled || loadingModifierGroups) && !modifier.preselected;

    const hideModifier = async () => {
        setLoading(true);
        const response = await hideModifierInModifierGroupApi({ modifierGroupId, menuItemId: modifier.menuItemId });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onChange?.();
    };

    const showModifier = async () => {
        setLoading(true);
        const response = await showModifierInModifierGroupApi({ modifierGroupId, menuItemId: modifier.menuItemId });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onChange?.();
    };

    const handlePreselected = async () => {
        setLoading(true);

        let response;

        if (modifier.preselected) {
            response = await unsetPreselectedModifierInModifierGroupApi({ modifierGroupId, menuItemId: modifier.menuItemId });
        } else {
            response = await setPreselectedModifierInModifierGroupApi({ modifierGroupId, menuItemId: modifier.menuItemId });
        }
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onChange?.();
    };

    return (
        <div className={classes.container}>
            <div className={classes.nameContainer}>
                <span className={classes.text}>{modifier.name}</span>

                <Checkbox
                    checked={modifier.preselected}
                    disabled={preselectedDisabled}
                    classes={{ label: classes.checkboxLabel }}
                    label={translate('Pre-selected')}
                    onChange={(e: any) => {
                        handlePreselected();
                    }}
                    name='preselected'
                    tooltip={translate('This modifier will be automatically selected')}
                    value={modifier.name}
                />
            </div>
            <div className={classes.priceContainer}>
                <span className={classes.text}>{formatAsCurrencyNumber(modifier.price)}</span>
                {modifier.hidden && (
                    <Button variant='icon' disabled={loading || isMenuEditionDisabled} onClick={showModifier}>
                        <ClosedEyeIcon title={'closed eye icon'} color={'#6C7076'} size={20} />
                    </Button>
                )}
                {!modifier.hidden && (
                    <Button variant='icon' disabled={loading || isMenuEditionDisabled} onClick={hideModifier}>
                        <EyeIcon title={'opened eye icon'} color={'#6C7076'} size={20} />
                    </Button>
                )}
                <ModifierInModifierGroupContextualMenu modifierGroupId={modifierGroupId} modifier={modifier} disabled={loading || isMenuEditionDisabled} onSuccess={onChange} />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 10px',
    },
    nameContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    priceContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        margin: '0 8px',
    },
    checkboxLabel: {
        fontSize: 12,
    },
}));

type Props = {
    modifierGroupId: ModifierGroupId;
    disabled: boolean;
    modifier: ModifierVm;
    onChange?: any;
};
