/**
 * @prettier
 */
export const SortCompares = {
    parseFloat: function (order: 'asc' | 'desc'): any {
        return (obj1: any, obj2: any) => {
            if (obj1.data === undefined || obj1.data === null) return order === 'asc' ? -1 : 1;
            if (obj2.data === undefined || obj2.data === null) return order === 'asc' ? 1 : -1;
            return (parseFloat(obj1.data) - parseFloat(obj2.data)) * (order === 'asc' ? 1 : -1);
        };
    },
} as const;
