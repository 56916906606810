/**
 * @prettier
 */
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { AdminAppMenu } from 'src/components/app/appMenu/AdminAppMenu';
import { KitchensAppMenu } from 'src/components/app/appMenu/KitchensAppMenu';
import { OperationsAppMenu } from 'src/components/app/appMenu/OperationsAppMenu';
import { RestaurantAppMenu } from 'src/components/app/appMenu/RestaurantAppMenu';
import { RestaurantQr } from 'src/components/app/appMenu/RestaurantQr';
import { SupportAppMenu } from 'src/components/app/appMenu/SupportAppMenu';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function AppMenu(): React.ReactElement {
    const classes = useStyles();

    const appMenuOpen = useSelector((state) => state.app2.appMenuOpen);
    const appLoading = useSelector((state) => state.app.loading);
    const restaurant = useSelector((state) => state.app.restaurant);

    return (
        <div className={classes.sideBar} style={{ left: appMenuOpen ? '0' : -300 }}>
            <AdminAppMenu />
            <SupportAppMenu />
            <OperationsAppMenu />
            <KitchensAppMenu />

            {appLoading && restaurant.name && (
                <div style={{ paddingLeft: 16 }}>
                    <Loader size={40} loading={true} text={translate('Loading restaurant')} />
                </div>
            )}
            <RestaurantAppMenu />
            <RestaurantQr />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    sideBar: {
        backgroundColor: theme.palette.surface.primary,
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        width: 300,
        zIndex: 3,
        overflowY: 'auto',
        padding: '5vh 8px',
        '@global': {
            a: {
                borderRadius: 6,
                fontFamily: theme.typography.regular,
                fontSize: 20,
                fill: theme.palette.surface.brandContrast,
                stroke: theme.palette.surface.brandContrast,
                '@global': {
                    h3: {
                        color: grey[700],
                    },
                    svg: {
                        color: theme.palette.surface.brandContrast,
                        '&.MuiSvgIcon-root': {
                            stroke: 'transparent',
                        },
                    },
                    span: {
                        paddingLeft: 0,
                        fontFamily: theme.typography.regular,
                    },
                    '.MuiListItemText-inset': {
                        paddingLeft: 0,
                    },
                },
            },
            'a:hover': {
                backgroundColor: theme.palette.surface.tertiary,
            },
            'a.active': {
                backgroundColor: theme.palette.surface.brandContrast,
                color: theme.palette.surface.primary,
                '@global': {
                    h3: {
                        fontWeight: 'bold',
                        color: grey[900],
                    },
                    svg: {
                        color: theme.palette.icons.invert,
                        '&.MuiSvgIcon-root': {
                            stroke: 'transparent',
                        },
                    },
                },
                fill: theme.palette.surface.primary,
                stroke: theme.palette.surface.primary,
            },
        },
    },
}));
