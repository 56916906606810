/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { PosPaymentCustomerItem } from 'src/scenes/letseatmanager/pos/posPayment/PosPaymentCustomerItem';
import { PosPaymentTotalTicketItem } from 'src/scenes/letseatmanager/pos/posPayment/PosPaymentTotalTicketItem';
import { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import { useSelector } from 'src/utils/react/useSelector';

export function PosPaymentCustomersList(): React.ReactElement | null {
    const classes = useStyles();

    const customers = useSelector((state) => state.pos.customers);

    if (!customers?.length) return null;

    return (
        <div className={classes.container}>
            <h2 style={{ margin: 0 }} className={classes.customerInfoTitle}>
                {translate('Select customer')}
            </h2>
            {customers?.map((customer: PosCustomerOrderDetails) => {
                if (!customer.orderItems?.length) return null;

                return <PosPaymentCustomerItem key={customer.customerNumber} customerOrderDetails={customer} />;
            })}
            <PosPaymentTotalTicketItem />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 25,
    },
    customerInfoTitle: {
        fontFamily: theme.typography.regular,
        fontSize: 18,
        marginBottom: 12,
        color: '#2E3748',
    },
    titleSection: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    helpIcon: {
        marginLeft: 5,
        '&.MuiSvgIcon-colorPrimary': {
            color: '#0FA489',
        },
    },
}));
