/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import moment from 'moment-timezone';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { findPosBusinessDayReportsApi } from 'src/api/letseatmanager/pos/findPosBusinessDayReportsApi';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { RegExps } from 'src/utils/RegExps';

export function PosBusinessDayReports(): React.ReactElement {
    const classes = useStyles();
    const history = useHistory();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const period = useSelector((state) => state.app2.filterReportsState.period);

    const brandOpened = useSelector((state) => state.app.brandOpened);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, posBusinessDayReports, refreshPosBusinessDayReports] = useLoadApi(
        findPosBusinessDayReportsApi,
        {
            restaurantIds: [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).add(1, 'day').toISOString(),
        },
        {
            initialValue: [],
            dependencies: [restaurantId, brandOpened, period?.from, period?.to],
        }
    );

    if (brandOpened) {
        return <ReportSectionEmptyState />;
    }

    return (
        <div className={classes.container}>
            <Table
                className={classes.root}
                loading={loading}
                data={posBusinessDayReports
                    .sort((a, b) => new Date(b.openedAt).getTime() - new Date(a.openedAt).getTime())
                    .map((posBusinessDayReport) => {
                        return {
                            posBusinessDayId: posBusinessDayReport.posBusinessDayReportId,
                            opening: translate('@dateAt by @doneBy', { dateAt: formatDateTimeString(posBusinessDayReport.openedAt), doneBy: posBusinessDayReport.openedBy }),
                            closing: posBusinessDayReport.closedAt
                                ? translate('@dateAt by @doneBy', { dateAt: formatDateTimeString(posBusinessDayReport.closedAt), doneBy: posBusinessDayReport.closedBy })
                                : '\u2014',
                            initialAmount: formatAsCurrencyNumber(posBusinessDayReport.initialAmount),
                            expectedAmount: formatAsCurrencyNumber(posBusinessDayReport.cashAmountExpected ?? 0),
                            amount: formatAsCurrencyNumber(posBusinessDayReport.cashAmount ?? 0),
                            differenceAmount: formatAsCurrencyNumber(posBusinessDayReport.difference),
                            deposits: formatAsCurrencyNumber(posBusinessDayReport.deposits),
                            withdraws: formatAsCurrencyNumber(posBusinessDayReport.withdraws),
                            status: posBusinessDayReport.opened ? translate('Opened') : translate('Closed'),
                        };
                    })}
                columns={[
                    {
                        name: 'posBusinessDayId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'opening',
                        label: translate('Opening'),
                        options: {
                            filter: false,
                            setCellProps: () => ({
                                style: {
                                    maxWidth: '180px',
                                },
                            }),
                        },
                    },
                    {
                        name: 'closing',
                        label: translate('Closing'),
                        options: {
                            filter: false,
                            setCellProps: () => ({
                                style: {
                                    maxWidth: '170px',
                                },
                            }),
                        },
                    },
                    {
                        name: 'initialAmount',
                        label: translate('Initial Amount'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'expectedAmount',
                        label: translate('Expected Amount'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'amount',
                        label: translate('Declared Amount'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'differenceAmount',
                        label: translate('Difference'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'deposits',
                        label: translate('Deposits'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'withdraws',
                        label: translate('Withdraws'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'status',
                        label: translate('Status'),
                        options: {
                            filter: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    onRowClick: (rowData: Array<string>) => {
                        const posBusinessDayId = rowData[0];
                        const posBusinessDay = posBusinessDayReports.find((posBusinessDay) => posBusinessDay.posBusinessDayReportId === posBusinessDayId);

                        if (posBusinessDay?.opened) return;

                        history.push({
                            pathname: RoutePaths.POS_BUSINESS_DAY.replace(`:posBusinessDayId(${RegExps.uuid})`, posBusinessDayId),
                            search: history.location.search,
                        });
                    },
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={refreshPosBusinessDayReports} />
                        </>
                    ),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
    },
    root: {
        '& tbody tr:hover': {
            cursor: 'pointer',
        },
    },
    chip: {
        height: 30,
        padding: '5px 20px',
        fontSize: 12,
    },
}));
