/**
 * @prettier
 */
import { Button, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { changeUserRoleApi } from 'src/api/letseatmanager/userRole/changeUserRoleApi';
import { getUserRoleApi } from 'src/api/letseatmanager/userRole/getUserRoleApi';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { Form } from 'src/components/form/Form';
import { FormErrorMessage } from 'src/components/form/FormErrorMessage';
import { FormHiddenField } from 'src/components/form/FormHiddenField';
import { FormTextField } from 'src/components/form/FormTextField';
import { SecuredPage } from 'src/components/page/SecuredPage';
import { RolePermission, RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { AdvancedReportsAccessConfiguration } from 'src/scenes/letseatmanager/userRole/AdvancedReportsAccessConfiguration';
import { AllOrdersAccessConfiguration } from 'src/scenes/letseatmanager/userRole/AllOrdersAccessConfiguration';
import { CashRegistersAccessConfiguration } from 'src/scenes/letseatmanager/userRole/CashRegistersAccessConfiguration';
import { DeliveryZonesAccessConfiguration } from 'src/scenes/letseatmanager/userRole/DeliveryZonesAccessConfiguration';
import { GeneralConfiguration } from 'src/scenes/letseatmanager/userRole/GeneralConfiguration';
import { GeneralReportsAccessConfiguration } from 'src/scenes/letseatmanager/userRole/GeneralReportsAccessConfiguration';
import { InventoryAccessConfiguration } from 'src/scenes/letseatmanager/userRole/InventoryAccessConfiguration';
import { InventoryReportsAccessConfiguration } from 'src/scenes/letseatmanager/userRole/InventoryReportsAccessConfiguration';
import { KitchenDisplayScreenAccessConfiguration } from 'src/scenes/letseatmanager/userRole/KitchenDisplayScreenAccessConfiguration';
import { LokiAccessConfiguration } from 'src/scenes/letseatmanager/userRole/LokiAccessConfiguration';
import { MarketingAccessConfiguration } from 'src/scenes/letseatmanager/userRole/MarketingAccessConfiguration';
import { MenuAccessConfiguration } from 'src/scenes/letseatmanager/userRole/MenuAccessConfiguration';
import { OrderInvoicesAccessConfiguration } from 'src/scenes/letseatmanager/userRole/OrderInvoicesAccessConfiguration';
import { OwnFleetAccessConfiguration } from 'src/scenes/letseatmanager/userRole/OwnFleetAccessConfiguration';
import { PaymentLinksAccessConfiguration } from 'src/scenes/letseatmanager/userRole/PaymentLinksAccessConfiguration';
import { PendingOrdersAccessConfiguration } from 'src/scenes/letseatmanager/userRole/PendingOrdersAccessConfiguration';
import { PosAccessConfiguration } from 'src/scenes/letseatmanager/userRole/PosAccessConfiguration';
import { PosReportsAccessConfiguration } from 'src/scenes/letseatmanager/userRole/PosReportsAccessConfiguration';
import { PromoCodesAccessConfiguration } from 'src/scenes/letseatmanager/userRole/PromoCodesAccessConfiguration';
import { PromotionsAccessConfiguration } from 'src/scenes/letseatmanager/userRole/PromotionsAccessConfiguration';
import { ReportsAccessConfiguration } from 'src/scenes/letseatmanager/userRole/ReportsAccessConfiguration';
import { RestaurantAccessConfiguration } from 'src/scenes/letseatmanager/userRole/RestaurantAccessConfiguration';
import { RestaurantZonesAccessConfiguration } from 'src/scenes/letseatmanager/userRole/RestaurantZonesAccessConfiguration';
import { SalesReportsAccessConfiguration } from 'src/scenes/letseatmanager/userRole/SalesReportsAccessConfiguration';
import { SurveysAccessConfiguration } from 'src/scenes/letseatmanager/userRole/SurveysAccessConfiguration';
import { UserSignInConfiguration } from 'src/scenes/letseatmanager/userRole/UserSignInConfiguration';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import type { UserRoleId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { classNames } from 'src/utils/react/classNames';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useParams } from 'src/utils/react/useParams';
import { useSelector } from 'src/utils/react/useSelector';
import { removeNulls } from 'src/utils/removeNulls';
import { validateRole } from 'src/utils/userRole/validateRole';

export function ChangeUserRolePage(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const { reloadRestaurant } = useReloadRestaurant();

    const history = useHistory();
    const { userRoleId } = useParams<{
        userRoleId: UserRoleId;
    }>();

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loadingUserRole, userRole] = useLoadApi(getUserRoleApi, { userRoleId }, { requiredValues: [userRoleId, restaurantId] });

    useEffect(() => {
        if (userRole) setUserRoleInfo();
    }, [userRole]);

    const setUserRoleInfo = () => {
        const formRolePermissions = mapUserRolePermissionsToFormRolePermissions(userRole.permissions);
        form.reset({
            ...formRolePermissions,
            ...userRole,
        });
    };

    const handleSubmit = async (form: any) => {
        const { roleName, initialPage, ...userPermissionForms } = form;
        const permissions = mapFormRolePermissionsToUserRolePermissions(userPermissionForms);

        const validatedRole = validateRole(permissions, initialPage);
        if (!validatedRole.valid) return setErrorMessage(validatedRole.errorMessage!);

        setLoading(true);
        const response = await changeUserRoleApi({
            userRoleId,
            restaurantId,
            roleName,
            initialPage,
            permissions: [...permissions],
        });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        reloadRestaurant(restaurantId);

        history.replace({
            pathname: RoutePaths.USER_MANAGEMENT,
            search: history.location.search,
        });
    };

    const mapFormRolePermissionsToUserRolePermissions = (form: any) => {
        const rolePermissionsInForm = Object.keys(form) as any;

        const rolePermissions = rolePermissionsInForm.map((rolePermissionInForm: any) => {
            if (form[rolePermissionInForm]) return rolePermissionInForm;
            return null as any;
        });

        return removeNulls<Array<RolePermission>>(rolePermissions);
    };

    const mapUserRolePermissionsToFormRolePermissions = (userRolePermissions: any) => {
        const rolePermissionsKeys = Object.keys(RolePermissions);
        const formRolePermissions: Record<string, any> = {};

        for (const rolePermissionsKey of rolePermissionsKeys) {
            if (userRolePermissions.includes(rolePermissionsKey)) {
                formRolePermissions[rolePermissionsKey] = true;
                continue;
            }
            formRolePermissions[rolePermissionsKey] = false;
        }

        return formRolePermissions;
    };

    return (
        <SecuredPage rolePermission={RolePermissions.USER_MANAGEMENT_PAGE} title={translate('Change Role')} className={classes.container}>
            <GoBackButton />
            <Form form={form} onSubmit={handleSubmit}>
                <FormHiddenField name='initialPage' />
                <div className={classes.inputsContainer}>
                    <FormTextField name='roleName' label={translate('Role name')} required disabled={loading || loadingUserRole} />
                    <span />
                    <UserSignInConfiguration disabled={loading || loadingUserRole} />
                    <GeneralConfiguration disabled={loading || loadingUserRole} />
                </div>
                <div className={classes.columns}>
                    <div className={classes.column}>
                        <div className={classes.header}>
                            <div className={classes.moduleHeader}>{translate('Module')}</div>
                            <div className={classes.permissionsHeader}>{translate('Permissions')}</div>
                            <div className={classes.homeHeader}>{translate('Initial page')}</div>
                        </div>
                        <PosAccessConfiguration disabled={loading || loadingUserRole} />
                        <AdvancedReportsAccessConfiguration disabled={loading || loadingUserRole} />
                        <ReportsAccessConfiguration disabled={loading || loadingUserRole} />
                        <GeneralReportsAccessConfiguration disabled={loading || loadingUserRole} />
                        <SalesReportsAccessConfiguration disabled={loading || loadingUserRole} />
                        <PosReportsAccessConfiguration disabled={loading || loadingUserRole} />
                        <InventoryReportsAccessConfiguration disabled={loading || loadingUserRole} />
                        <PendingOrdersAccessConfiguration disabled={loading || loadingUserRole} />
                        <KitchenDisplayScreenAccessConfiguration disabled={loading || loadingUserRole} />
                        <AllOrdersAccessConfiguration disabled={loading || loadingUserRole} />
                        <OrderInvoicesAccessConfiguration disabled={loading || loadingUserRole} />
                        <SurveysAccessConfiguration disabled={loading || loadingUserRole} />
                        <LokiAccessConfiguration disabled={loading} />
                    </div>
                    <div className={classes.column}>
                        <div className={classNames(classes.header, classes.hideHeader)}>
                            <div className={classes.moduleHeader}>{translate('Module')}</div>
                            <div className={classes.permissionsHeader}>{translate('Permissions')}</div>
                            <div className={classes.homeHeader}>{translate('Initial page')}</div>
                        </div>
                        <PromoCodesAccessConfiguration disabled={loading || loadingUserRole} />
                        <PromotionsAccessConfiguration disabled={loading || loadingUserRole} />
                        <MarketingAccessConfiguration disabled={loading || loadingUserRole} />
                        <MenuAccessConfiguration disabled={loading || loadingUserRole} />
                        <PaymentLinksAccessConfiguration disabled={loading || loadingUserRole} />
                        <OwnFleetAccessConfiguration disabled={loading || loadingUserRole} />
                        <CashRegistersAccessConfiguration disabled={loading || loadingUserRole} />
                        <RestaurantZonesAccessConfiguration disabled={loading || loadingUserRole} />
                        <DeliveryZonesAccessConfiguration disabled={loading || loadingUserRole} />
                        <RestaurantAccessConfiguration disabled={loading || loadingUserRole} />
                        <InventoryAccessConfiguration disabled={loading || loadingUserRole} />
                        <div className={classes.buttonContainer}>
                            <Button type='submit' classes={{ root: classes.button }}>
                                {translate('Change')}
                            </Button>
                            <FormErrorMessage errorMessage={errorMessage} />
                        </div>
                    </div>
                </div>
            </Form>
        </SecuredPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 20,
        position: 'relative',
        paddingTop: 60,
    },
    inputsContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        marginBottom: 10,
        gap: 30,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            gridTemplateColumns: '1fr',
            gap: 10,
        },
    },
    columns: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        columnGap: 20,
        paddingBottom: 20,
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
        },
    },
    column: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
    },
    header: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#c8c8c8',
        padding: 10,
    },
    hideHeader: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    moduleHeader: {
        width: '25%',
        fontFamily: theme.typography.medium,
        fontSize: 15,
    },
    permissionsHeader: {
        width: '50%',
        fontFamily: theme.typography.medium,
        fontSize: 15,
    },
    homeHeader: {
        width: '25%',
        fontFamily: theme.typography.medium,
        fontSize: 15,
    },
    buttonContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        marginTop: 20,
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        borderRadius: 8,
        height: 45,
        width: 120,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));
