/**
 * @prettier
 */
import * as React from 'react';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';
import { MultipleEmailsInput } from 'src/components/input/MultipleEmailsInput';
import { translate } from 'src/i18n/translate';

export function FormMultipleEmailsInput({ name, label, defaultValue, disabled, required }: Props): React.ReactElement {
    const { control } = useFormContext();
    const formContext = useContext(FormContext);

    return (
        <Controller
            control={control}
            name={name}
            render={({ value, onChange, ...props }) => <MultipleEmailsInput label={label} emails={value} disabled={disabled || formContext.disabled} onChangeEmails={onChange} />}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
            }}
        />
    );
}

type Props = {
    name: string;
    label: string;
    defaultValue?: Array<string>;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
};
