/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { Currency } from 'src/constants/Currency';
import type { RestaurantId } from 'src/types/Id';

export async function removeCurrencyApi(request: RemoveCurrencyApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurant/removeCurrencyApi', request);
}

export type RemoveCurrencyApiRequest = {
    restaurantId: RestaurantId;
    currency: Currency;
};
