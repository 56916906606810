/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';
import * as React from 'react';
import { changeOrderRejectReasonExtrasApi } from 'src/api/letseatmanager/order/changeOrderRejectReasonExtrasApi';
import { OrderRejectReasonCategories } from 'src/constants/OrderRejectReasonCategory';
import { OrderRejectReasonExtra, OrderRejectReasonExtras } from 'src/constants/OrderRejectReasonExtra';
import { UserType, UserTypes } from 'src/constants/UserType';
import { translate } from 'src/i18n/translate';
import { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';
import { isBrandUser } from 'src/utils/user/isBrandUser';
import { isBusinessUser } from 'src/utils/user/isBusinessUser';

export function ExtraOrderRejectReasons({ disabled, order }: Props): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const adminUser = useSelector((state) => state.authentication.adminUser);
    const supportUser = useSelector((state) => state.authentication.supportUser);
    const restaurantUser = useSelector((state) => state.authentication.restaurantUser);
    const userType = useSelector((state) => state.authentication.userType);

    let orderRejectReasonExtras = order?.orderRejectReasonExtras ?? [];
    const rejectReasonExtras = [
        { orderRejectReasonExtra: OrderRejectReasonExtras.REFUND, showTo: [UserTypes.SUPPORT] as Array<UserType> },
        { orderRejectReasonExtra: OrderRejectReasonExtras.TICKET, showTo: [UserTypes.SUPPORT] as Array<UserType> },
    ];

    const orderReasonsFilteredByUserType = () => {
        if (adminUser) return rejectReasonExtras;
        else if (restaurantUser || isBrandUser(userType) || isBusinessUser(userType))
            return rejectReasonExtras.filter((orderRejectReasonExtra) => orderRejectReasonExtra.showTo.includes(UserTypes.RESTAURANT));
        else if (supportUser) return rejectReasonExtras.filter((orderRejectReasonExtra) => orderRejectReasonExtra.showTo.includes(UserTypes.SUPPORT));
        return [];
    };

    const onExtraClick = async (newOrderRejectReasonExtra: OrderRejectReasonExtra) => {
        if (loading || disabled) return;
        setLoading(true);

        if (orderRejectReasonExtras.includes(newOrderRejectReasonExtra)) {
            orderRejectReasonExtras = orderRejectReasonExtras.filter((orderRejectReasonExtra) => orderRejectReasonExtra !== newOrderRejectReasonExtra);
        } else {
            orderRejectReasonExtras.push(newOrderRejectReasonExtra);
        }

        const response = await changeOrderRejectReasonExtrasApi({ orderId: order.orderId, orderRejectReasonExtras });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
    };

    return (
        <Grid container>
            <Grid className={classes.box_category} item alignContent='center' alignItems='center' xs={12}>
                <Typography className={classes.category_name} display='block' variant='h6' color='inherit' noWrap>
                    {translate(`OrderRejectReasonCategories.${OrderRejectReasonCategories.EXTRA}`)}
                </Typography>
            </Grid>
            <Grid className={classes.extra_checkbox} item xs={12}>
                {/* @ts-ignore */}
                <Grid item xs={12} textAlign={'center'}>
                    {orderReasonsFilteredByUserType().map(({ orderRejectReasonExtra }) => {
                        const preSelected = orderRejectReasonExtras.includes(orderRejectReasonExtra);
                        return (
                            <div>
                                <FormControlLabel
                                    label={translate(`OrderRejectReasonExtras.${orderRejectReasonExtra}`)}
                                    align-items={'right'}
                                    disabled={disabled || loading}
                                    defaultChecked={preSelected}
                                    // @ts-ignore
                                    fullWidth={true}
                                    size='large'
                                    onChange={() => onExtraClick(orderRejectReasonExtra)}
                                    control={<Checkbox defaultChecked={preSelected} />}
                                />
                            </div>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    text: {
        fontSize: 17,
        textTransform: 'none',
    },
    category_name: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    horizontal_vertical_center: {
        textAlign: 'center',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
    },
    box_category: {
        paddingTop: '1rem',
        borderTop: '1px solid #000;',
        textAlign: 'center',
        margin: 'auto',
    },
    extra_checkbox: {
        paddingTop: '1rem',
        textAlign: 'center',
        margin: 'auto',
    },
}));

type Props = {
    order: OrderVm;
    disabled: boolean;
};
