/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DeviceGroupId } from 'src/types/Id';

export async function removeDeviceGroupApi(request: RemoveDeviceGroupApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('deviceGroup/removeDeviceGroupApi', request);
}

export type RemoveDeviceGroupApiRequest = {
    deviceGroupId: DeviceGroupId;
};
