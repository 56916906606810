/**
 * @prettier
 */
import { KitchenStatuses } from 'src/constants/KitchenStatus';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { useNotification } from 'src/services/notification/useNotification';
import type { CartItemVm } from 'src/types/CartItemVm';
import { createCartItemKey } from 'src/utils/pos/createCartItemKey';
import { getItemFormatted } from 'src/utils/pos/getItemFormatted';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function useAddPosItem(): {
    addItem: AddItemFunction;
} {
    const notification = useNotification();

    const kitchenDisplayScreenEnabled = useSelector((state) => state.app.restaurant?.kitchenDisplayScreenEnabled);
    const courseIdSelected = useSelector((state) => state.pos.courseIdSelected);
    const takeOrderByCourse = useSelector((state) => state.pos.takeOrderByCourse);

    const addPosItem = useAction(posReducer.actions.addPosItem);

    const addItem = (item: CartItemVm): void => {
        const itemFormatted = getItemFormatted(item);

        if (takeOrderByCourse && !courseIdSelected) return notification({ message: translate('Please select a course') });

        if (kitchenDisplayScreenEnabled) itemFormatted.orderItemKitchenStatus = itemFormatted.orderItemKitchenStatus ?? KitchenStatuses?.PREPARING;

        const posItem = { ...itemFormatted, key: createCartItemKey(itemFormatted) } as const;

        addPosItem(posItem);
    };

    return { addItem };
}

type AddItemFunction = (item: CartItemVm) => void;
