/**
 * @prettier
 */
export function duplicates<T>(prev: Array<T>, next?: T): Array<T> {
    if (!next) {
        return prev;
    }
    if (prev.includes(next)) {
        return prev;
    }
    return prev.concat(next);
}
