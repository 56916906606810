/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { createMexicanOrderInvoiceApi } from 'src/api/letseatmanager/invoice/createMexicanOrderInvoiceApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCardTypeSelect } from 'src/components/form/FormCardTypeSelect';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormRegimeFiscalSelect } from 'src/components/form/FormRegimeFiscalSelect';
import { FormRfcAutocompleteField } from 'src/components/form/FormRfcAutocompleteField';
import { FormTextField } from 'src/components/form/FormTextField';
import { MexicanPaymentMethodCode } from 'src/constants/MexicanPaymentMethodCode';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isMexico } from 'src/utils/country/isMexico';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function CreateMexicanOrderInvoiceDialog({ open, order, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const [loading, setLoading] = useState(false);

    const restaurantCountry = useSelector((state) => state.app.restaurant.country);

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const handleSubmit = async (form: any) => {
        if (!order.paymentMethod) return;

        setLoading(true);
        const response = await createMexicanOrderInvoiceApi({
            orderId: order.orderId,
            paymentMethod: requireValue(order.paymentMethod),
            rfc: form.rfc,
            businessName: form.businessName,
            regimeFiscal: form.regimeFiscal,
            cardType: form.cardType,
            email: form.email,
            zipCode: form.zipCode,
        });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        onSuccess?.();
        handleClose();
    };

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const onSelectInvoiceLegalInfoAutocomplete = (invoiceLegalInfo: any) => {
        form.setValue('rfc', invoiceLegalInfo.fiscalId);
        form.setValue('businessName', invoiceLegalInfo.businessName);
        form.setValue('regimeFiscal', invoiceLegalInfo.regimeFiscal);
    };

    const getMexicanPaymentMethodCodeTranslate = (mexicanPaymentMethodCode: MexicanPaymentMethodCode | undefined) => {
        if (!mexicanPaymentMethodCode) return undefined;

        return translate(`MexicanPaymentMethodCodes.${mexicanPaymentMethodCode}`);
    };

    return (
        <Dialog title={translate('Create order invoice')} open={open} onClose={handleClose} classes={{ dialog: classes.dialog }}>
            <Form onSubmit={handleSubmit} form={form}>
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormRfcAutocompleteField name='rfc' label={translate('RFC')} required onSelect={onSelectInvoiceLegalInfoAutocomplete} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextField name='businessName' label={translate('Business Name')} required disabled={loading} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormRegimeFiscalSelect name='regimeFiscal' label={translate('Regime fiscal')} required disabled={loading} />
                        </Grid>
                        {isMexico(restaurantCountry) && order.mexicanPaymentMethodCode && (
                            <Grid item xs={12} md={6}>
                                <FormTextField
                                    name='mexicanPaymentMethodCode'
                                    label={translate('Payment method')}
                                    disabled={true}
                                    defaultValue={getMexicanPaymentMethodCodeTranslate(order.mexicanPaymentMethodCode)}
                                />
                            </Grid>
                        )}
                        {!order.mexicanPaymentMethodCode && (
                            <Grid item xs={12} md={6}>
                                <FormTextField name='paymentMethod' label={translate('Payment method')} disabled={true} defaultValue={translate(order.paymentMethod)} />
                            </Grid>
                        )}
                        {isCardPayment(order.paymentMethod) && (
                            <Grid item xs={12} md={6}>
                                <FormCardTypeSelect name='cardType' label={translate('Card type')} required disabled={loading} />
                            </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                            <FormEmailField name='email' label={translate('Email')} required disabled={loading} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextField name='zipCode' label={translate('Zip code')} disabled={loading} />
                        </Grid>
                    </Grid>
                </div>
                <DialogActions>
                    <Button secondary larger={posInterfaceLargerEnabled} onClick={handleClose} disabled={loading} classes={{ button: classes.button }}>
                        {translate('Cancel')}
                    </Button>
                    <Button larger={posInterfaceLargerEnabled} type='submit' disabled={loading} classes={{ button: classes.button }}>
                        {loading ? translate('Creating') : translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    dialog: {
        [theme.breakpoints.up('sm')]: {
            width: '50%',
        },
    },
    button: {
        width: '50%',
    },
    buttonsContainer: {
        margin: '10px 0px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
}));

type Props = {
    open: boolean;
    order: OrderVm;
    onClose: any;
    onSuccess?: any;
};
