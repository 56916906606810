/**
 * @prettier
 */
import type { LogEventVm } from 'src/api/letseatmanager/types/LogEventVm';
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';

export async function getIntegrationsLogEventsApi(request: IntegrationLogEventsApiRequest): ApiSauceResponse<Array<LogEventVm>> {
    return letseatmanagerApiMethod('logEvent/getIntegrationsLogEventsApi', request);
}

export type IntegrationLogEventsApiRequest = {
    restaurantId: string;
};
