/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { useConfirmDialog, useLoadApi } from '@pidedirecto/ui/hooks';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { BigNumber } from 'bignumber.js';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useState } from 'react';
import { disableQuickActionApi } from 'src/api/letseatadmin/quickAction/disableQuickActionApi';
import { enableQuickActionApi } from 'src/api/letseatadmin/quickAction/enableQuickActionApi';
import { getQuickActionsApi } from 'src/api/letseatadmin/quickAction/getQuickActionsApi';
import { getSmsQuantityForQuickActionApi } from 'src/api/letseatadmin/quickAction/getSmsQuantityForQuickActionApi';
import { removeQuickActionApi } from 'src/api/letseatadmin/quickAction/removeQuickActionApi';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { Page } from 'src/components/Page';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ChangeQuickActionDialog } from 'src/scenes/letseatadmin/quickAction/ChangeQuickActionDialog';
import { CreateQuickActionDialog } from 'src/scenes/letseatadmin/quickAction/CreateQuickActionDialog';
import type { QuickActionId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function QuickActionsPage(): React.ReactElement {
    const classes = useStyles();
    const confirmDialog = useConfirmDialog();

    const viewUser = useSelector((state) => state.authentication.viewUser);

    const [submitting, setSubmitting] = useState(false);
    const [createQuickActionDialogState, setCreateQuickActionDialogState] = useState({ open: false });
    const [changeQuickActionDialogState, setChangeQuickActionDialogState] = useState({ open: false, quickActionId: undefined });

    const [loading, { quickActions, zonesInfo }, load] = useLoadApi(getQuickActionsApi, undefined, { initialValue: { quickActions: [], zonesInfo: [] } });

    const removeMultiple = async (quickActionIds: Array<QuickActionId>) => {
        setSubmitting(true);
        for (const quickActionId of quickActionIds) {
            const response = await removeQuickActionApi({ quickActionId });
            if (!response.ok) {
                setSubmitting(false);
                alertKnownErrorOrSomethingWentWrong(response);
                await load();
                return;
            }
        }
        setSubmitting(false);
        await load();
    };

    const handleClickNew = () => {
        setCreateQuickActionDialogState({ open: true });
    };

    const handleToggleQuickAction = async (quickActionId: QuickActionId, enabled: boolean) => {
        if (!enabled) {
            await enableQuickAction(quickActionId);
            return;
        }

        await disabledQuickAction(quickActionId);
    };

    const enableQuickAction = async (quickActionId: QuickActionId) => {
        const smsQuantityResponse = await getSmsQuantityForQuickActionApi({ quickActionId });
        if (!smsQuantityResponse?.ok) {
            alertKnownErrorOrSomethingWentWrong(smsQuantityResponse);
            load();
            return;
        }

        const smsQuantity = smsQuantityResponse.data ?? 0;
        if (smsQuantity > SmsQuantityLimit) {
            const smsCost = BigNumber(smsQuantity).multipliedBy(0.1).toString();
            const shouldEnableQuickAction = await confirmDialog({
                title: translate('Enable quick action will send @smsNumber SMS and will cost @smsCost', { smsNumber: smsQuantity, smsCost: formatAsCurrencyNumber(smsCost) }),
                content: translate('Do you want to continue?'),
                acceptButtonText: translate('Yes, continue'),
                cancelButtonText: translate('No, cancel'),
                variant: 'warning',
            });
            if (!shouldEnableQuickAction) return;
        }

        const response = await enableQuickActionApi({ quickActionId: requireValue(quickActionId) });

        if (!response?.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            load();
            return;
        }

        load();
    };

    const disabledQuickAction = async (quickActionId: QuickActionId) => {
        const response = await disableQuickActionApi({ quickActionId: requireValue(quickActionId) });

        if (!response?.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            load();
            return;
        }

        load();
    };

    return (
        <Page className={classes.container} title={translate('Quick Actions')}>
            <UpdatingContentProgress loading={loading || submitting} />
            <CreateQuickActionDialog open={createQuickActionDialogState.open} onClose={() => setCreateQuickActionDialogState({ open: false })} onQuickActionCreated={load} />
            <ChangeQuickActionDialog
                open={changeQuickActionDialogState.open}
                quickActionId={changeQuickActionDialogState.quickActionId}
                onClose={() => setChangeQuickActionDialogState({ open: false, quickActionId: undefined })}
                onQuickActionChanged={load}
            />
            <Grid container>
                <Grid item xs={12}>
                    <h2>{translate('Zones information')}</h2>
                </Grid>
                <Grid item xs={12}>
                    <MUIDataTable
                        /* @ts-ignore */
                        className={classes.table}
                        data={zonesInfo.map((zoneInfo) => ({
                            zone: zoneInfo.zone,
                            orders: zoneInfo.orders,
                            ratio: zoneInfo.ratio,
                            ordersWithDriver: zoneInfo.ordersWithDriver,
                            ordersWithoutDriver: zoneInfo.ordersWithoutDriver,
                            online: zoneInfo.online,
                        }))}
                        columns={[
                            {
                                name: 'zone',
                                label: translate('Zone'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'ratio',
                                label: translate('Ratio'),
                                options: {
                                    filter: false,
                                    customBodyRender: (ratio) => {
                                        const getColor = () => {
                                            if (ratio < 1.2) return 'green';
                                            if (ratio < 2) return 'yellow';
                                            if (ratio < 2.5) return 'orange';
                                            return 'red';
                                        };

                                        return <span style={{ backgroundColor: getColor(), padding: 5, borderRadius: 5 }}>{ratio}</span>;
                                    },
                                },
                            },
                            {
                                name: 'orders',
                                label: translate('Active Orders'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'ordersWithDriver',
                                label: translate('Driver with order'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'ordersWithoutDriver',
                                label: translate('Pending'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'online',
                                label: translate('Drivers online'),
                                options: {
                                    filter: false,
                                },
                            },
                        ]}
                        options={{
                            responsive: 'standard',
                            tableBodyMaxHeight: '500px',
                            selectableRows: 'none',
                            filterType: 'checkbox',
                            rowsPerPage: 100,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <h2>{translate('Quick Actions')}</h2>
                </Grid>
                <Grid item xs={12}>
                    <MUIDataTable
                        /* @ts-ignore */
                        className={classes.table}
                        data={quickActions.map((quickAction) => ({
                            quickActionId: quickAction.quickActionId,
                            name: quickAction.name,
                            city: quickAction.city ?? translate('All Cities'),
                            zones: quickAction.zones?.join(', ') ?? translate('All Zones'),
                            deliveryRadiusInKm: quickAction.deliveryRadiusInKm,
                            activated: quickAction.activated,
                        }))}
                        columns={[
                            {
                                name: 'quickActionId',
                                label: '',
                                options: {
                                    display: 'excluded',
                                    filter: false,
                                },
                            },
                            {
                                name: 'name',
                                label: translate('Name'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'city',
                                label: translate('City'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'zones',
                                label: translate('Zones'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'deliveryRadiusInKm',
                                label: translate('deliveryRadiusInKm'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'activated',
                                label: translate('activated'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                    customBodyRender: (activated, { rowData }) => {
                                        const quickActionId = rowData[0];
                                        const handleClick = async () => {
                                            await handleToggleQuickAction(quickActionId, activated);
                                        };
                                        return <Switch onChange={handleClick} name='activated' color='primary' checked={activated} disabled={viewUser} />;
                                    },
                                },
                            },
                        ]}
                        options={{
                            responsive: 'standard',
                            tableBodyMaxHeight: '500px',
                            selectableRows: !viewUser ? 'multiple' : 'none',
                            filterType: 'checkbox',
                            rowsPerPage: 100,
                            customToolbar: () => (
                                <>
                                    <RefreshToolbarButton onClick={load} />
                                    <CreateToolbarButton onClick={handleClickNew} />
                                </>
                            ),
                            customToolbarSelect: (selectedRows, displayData) => {
                                const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                                const selectedQuickActionIds = selectedRowsData.map((d: any) => d[0]);
                                const handleClickEdit = () => {
                                    if (selectedQuickActionIds.length === 1) setChangeQuickActionDialogState({ open: true, quickActionId: selectedQuickActionIds[0] });
                                };
                                const handleClickRemove = async () => {
                                    const remove = window.confirm(
                                        selectedQuickActionIds.length === 1
                                            ? 'Are you sure you want to remove the selected quick Action'
                                            : `Are you sure you want to remove the selected ${selectedQuickActionIds.length} quick actions`
                                    );
                                    if (remove) {
                                        await removeMultiple(selectedQuickActionIds);
                                    }
                                };
                                return (
                                    <div className={classes.toolbar}>
                                        {selectedQuickActionIds.length === 1 && <ChangeToolbarButton onClick={handleClickEdit} />}
                                        <RemoveToolbarButton onClick={handleClickRemove} />
                                    </div>
                                );
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </Page>
    );
}

const SmsQuantityLimit = 500;

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));
