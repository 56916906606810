/**
 * @prettier
 */
import { ListSubheader, makeStyles } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { useOpenBrand } from 'src/services/restaurant/useOpenBrand';
import { useOpenBusiness } from 'src/services/restaurant/useOpenBusiness';
import { useOpenRestaurant } from 'src/services/restaurant/useOpenRestaurant';
import { useSelector } from 'src/utils/react/useSelector';

export function BusinessAppMenuSelect(): React.ReactElement | null {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();

    const [selectIsOpened, setSelectIsOpened] = useState(false);
    const [selectValue, setSelectValue] = useState('');

    const restaurant = useSelector((state) => state.app.restaurant);
    const brands = useSelector((state) => state.app.brands);
    const restaurants = useSelector((state) => state.app.restaurants) ?? [];
    const currentOrders = useSelector((state) => state.app2.currentOrders) ?? [];

    const { openRestaurant } = useOpenRestaurant();
    const { openBrand } = useOpenBrand();
    const { openBusiness } = useOpenBusiness();

    const invalidLocations = [RoutePaths.POS, RoutePaths.THE_MENU, RoutePaths.THE_RESTAURANT, RoutePaths.PRINTERS, RoutePaths.OPENING_HOURS, RoutePaths.INTEGRATIONS];
    const isUserOnInvalidRestaurantGroupLocation = invalidLocations.includes(location.pathname);
    const newOrders = currentOrders.filter((order) => order.orderStatus === OrderStatuses.NEW);

    useEffect(() => {
        if (restaurant?.restaurantId && !selectValue) setSelectValue(restaurant?.restaurantId);
    }, [restaurant, selectValue]);

    const handleRestaurantsSelect = (e: any) => {
        e.stopPropagation();
        if (e.target.value === 'business') return handleBusinessOption(e);
        if (isBrand(e.target.value)) return handleBrandOption(e);
        setSelectIsOpened(false);
        setSelectValue(e.target.value);
        openRestaurant(e.target.value);
    };

    const isBrand = (value: any) => brands.some((brand) => brand.brandId === value);

    const handleBusinessOption = (e: any) => {
        if (isUserOnInvalidRestaurantGroupLocation) {
            history.replace({
                pathname: RoutePaths.ORDERS,
                search: location.search,
            });
        }
        setSelectIsOpened(false);
        setSelectValue('business');
        openBusiness();
    };

    const handleBrandOption = (e: any) => {
        if (isUserOnInvalidRestaurantGroupLocation) {
            history.replace({
                pathname: RoutePaths.ORDERS,
                search: location.search,
            });
        }
        setSelectIsOpened(false);
        setSelectValue(e.target.value);
        openBrand(e.target.value);
    };

    const handleClickSelect = () => {
        setSelectIsOpened(!selectIsOpened);
    };

    if (!restaurant.restaurantId) return null;

    return (
        <Select open={selectIsOpened} value={selectValue} onChange={handleRestaurantsSelect} fullWidth onClick={handleClickSelect}>
            <MenuItem value={'business'} className={classes.restaurantSelectItem}>
                <span className={classes.restaurantSelectItemSpan}>{translate('Business')}</span>
            </MenuItem>
            <ListSubheader classes={{ root: classes.listSubheader }}>{translate('Brands')}</ListSubheader>
            {brands.map((brand) => (
                <MenuItem value={brand.brandId} className={classes.restaurantSelectItem} key={brand.brandId}>
                    <span className={classes.restaurantSelectItemSpan}>{brand.name}</span>
                </MenuItem>
            ))}
            <ListSubheader classes={{ root: classes.listSubheader }}>{translate('Restaurants')}</ListSubheader>
            {restaurants.map((restaurant) => {
                const newOrdersForRestaurant = newOrders.filter((order) => order.restaurant?.restaurantId === restaurant.restaurantId).length;
                return (
                    <MenuItem value={restaurant.restaurantId} className={classes.restaurantSelectItem} key={restaurant.restaurantId}>
                        <span className={classes.restaurantSelectItemSpan}>{restaurant.name}</span>
                        <Badge className={classes.restaurantSelectItemBadge} badgeContent={newOrdersForRestaurant} classes={{ badge: classes.badge }} />
                    </MenuItem>
                );
            })}
        </Select>
    );
}

const useStyles = makeStyles((theme) => ({
    listSubheader: {
        fontFamily: theme.typography.medium,
        paddingLeft: 10,
    },
    restaurantSelectItem: {
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 20,
    },
    restaurantSelectItemDisabled: {
        color: '#9a9b9b',
    },
    restaurantSelectItemSpan: {
        width: '100%',
        fontFamily: theme.typography.regular,
    },
    restaurantSelectItemBadge: {
        marginLeft: '2vw',
    },
    badge: {
        backgroundColor: theme.palette.surface.brand,
        color: 'white',
    },
    tooltip: {
        fontFamily: theme.typography.regular,
    },
}));
