/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { DiscountTypes } from 'src/constants/DiscountType';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateRestaurantMenuPromotionDialog(): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;
    const classes = useStyles();

    const open = useSelector((state) => state.app2.createRestaurantMenuPromotionDialog.open);
    const onSuccess = useSelector((state) => state.app2.createRestaurantMenuPromotionDialog.onSuccess);
    const restaurantMenuId = useSelector((state) => state.app.restaurant?.restaurantMenuId);

    const closeCreateRestaurantMenuPromotionDialog = useAction(app2.actions.closeCreateRestaurantMenuPromotionDialog);
    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const handleClose = () => {
        if (isSubmitting) return;
        closeCreateRestaurantMenuPromotionDialog();
    };

    const onSubmit = async (form: any) => {
        const response = await letseatmanagerApiDeprecated.admin.changeMenuItemPromosInRestaurantMenu({
            restaurantMenuId: restaurantMenuId,
            discount: form.discount,
            discountType: DiscountTypes.PERCENT,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        showSnackbar({ message: translate(`Created ${form.discount}% restaurant menu promotion`) });
        onSuccess?.();
        closeCreateRestaurantMenuPromotionDialog();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Create Restaurant Menu Promotion')}>
            <Form onSubmit={onSubmit} form={form} className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DeprecatedFormPercentNumberField
                            name='discount'
                            label={translate('Discount')}
                            helperText={translate('In percentage, e.g. type 20 to create a 20% discount promotion. To reset all promo prices type 0')}
                            required
                        />
                    </Grid>
                </Grid>
                <FormFixErrorHint />
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <FormSubmitButton text={isSubmitting ? translate('Creating') : translate('Create')} />
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));
