/**
 * @prettier
 */
export const CitiesWithPideDirectoDelivery = {
    MONTERREY: 'MONTERREY',
    MEXICO_CITY: 'MEXICO_CITY',
    GUADALAJARA: 'GUADALAJARA',
    MERIDA: 'MERIDA',
    SALTILLO: 'SALTILLO',
    TORREON: 'TORREON',
    PUEBLA: 'PUEBLA',
    QUERETARO: 'QUERETARO',
} as const;

export type CityWithDeliveryPd = typeof CitiesWithPideDirectoDelivery[keyof typeof CitiesWithPideDirectoDelivery];
