/**
 * @prettier
 */
export const ScheduleTaskIntervals = {
    EACH_MONTH: 'EACH_MONTH',
    EACH_WEEK: 'EACH_WEEK',
    EACH_DAY: 'EACH_DAY',
    NO_REPEAT: 'NO_REPEAT',
} as const;

export type ScheduleTaskInterval = typeof ScheduleTaskIntervals[keyof typeof ScheduleTaskIntervals];
