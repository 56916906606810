/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CourseVm } from 'src/types/CourseVm';
import type { RestaurantId } from 'src/types/Id';

export async function getCoursesApi(request: GetCoursesApiRequest): ApiSauceResponse<CoursesVm> {
    return letseatmanagerApiMethod('course/getCoursesApi', request);
}

export type GetCoursesApiRequest = {
    restaurantId: RestaurantId;
};

export type CoursesVm = Array<CourseVm>;
