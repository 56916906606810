/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuItemType } from 'src/constants/DeprecatedMenuItemType';
import type { MenuItemSize } from 'src/constants/MenuItemSize';
import type { ModifierType } from 'src/constants/ModifierType';
import type { ExternalId, MenuCategoryId, MenuItemId, RestaurantId } from 'src/types/Id';

export async function deprecatedCreateMenuItemApi(request: CreateMenuItemApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menu/createMenuItemApi', request);
}

type CreateMenuItemApiRequest = {
    restaurantId: RestaurantId;
    menuCategoryId?: MenuCategoryId;
    position?: number;
    menuItemType: MenuItemType;
    modifierType?: ModifierType;
    externalProductId?: ExternalId;
    name: string;
    description?: string;
    contains?: string;
    price: string;
    promoPrice?: string;
    promoText?: string;
    kitchenPrice?: string;
    size: MenuItemSize;
    imageUrl?: string;
    modifierGroups: Array<ModifierGroup>;
};

export type ModifierGroup = {
    name: string;
    externalModifierGroupId?: ExternalId;
    requiredMin?: number;
    requiredMax?: number;
    hidden?: boolean;
    modifiers: Array<Modifier>;
};

export type Modifier = {
    wansoftProductCode?: ExternalId;
    externalModifierId?: ExternalId;
    modifierId: MenuItemId;
    price: string;
    type: ModifierType;
    subModifierGroups?: Array<SubModifierGroup>;
};

export type SubModifierGroup = {
    name: string;
    externalSubModifierGroupId?: ExternalId;
    requiredMin?: number;
    requiredMax?: number;
    hidden: boolean;
    subModifiers: Array<SubModifier>;
};

export type SubModifier = {
    wansoftProductCode?: ExternalId;
    externalSubModifierId?: ExternalId;
    name: string;
    price: string;
    type: ModifierType;
    hidden?: boolean;
};
