/**
 * @prettier
 */
import 'src/scenes/letseatadmin/surveys/RatingDetails.css';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useRef } from 'react';
import type { SurveyVm } from 'src/api/letseatadmin/survey/getSurveysApi';
import { solveSurveysApi } from 'src/api/letseatadmin/survey/solveSurveysApi';
import { AddCreditsToolbarButton } from 'src/components/mui-datatables/AddCreditsToolbarButton';
import { CustomToolbarSelect } from 'src/components/mui-datatables/CustomToolbarSelect';
import { DownloadCsvToolbarButton } from 'src/components/mui-datatables/DownloadCsvToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { SendEmailToolbarButton } from 'src/components/mui-datatables/SendEmailToolbarButton';
import { SendNotificationToolbarButton } from 'src/components/mui-datatables/SendNotificationToolbarButton';
import { SolveSurveysToolbarButton } from 'src/components/mui-datatables/SolveSurveysToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { ADD_CREDITS } from 'src/scenes/letseatadmin/customer/CustomerCreditsDialog';
import { SEND_NOTIFICATION } from 'src/scenes/letseatadmin/customer/CustomerSendDialog';
import { SEND_EMAIL } from 'src/scenes/letseatadmin/customer/CustomerSendEmailDialog';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { setUrlQueryParameter } from 'src/utils/history/setUrlQueryParameter';
import { createFilteredOnDownloadFunctional } from 'src/utils/mui-datatables/createFilteredOnDownloadFunctional';
import { createHandleClickDownloadCsvFunctional } from 'src/utils/mui-datatables/createHandleClickDownloadCsvFunctional';
import { getSelectedRows } from 'src/utils/mui-datatables/getSelectedRows';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { translateSurveyAnswer } from 'src/utils/translate/translateSurveyAnswer';
import { openSurveyPageInNewTab } from 'src/utils/window/openSurveyPageInNewTab';

const UNSOLVED = 'UNSOLVED';

type Props = {
    loading?: boolean;
    title?: string;
    surveys: Array<SurveyVm>;
    onClickRefresh: any;
};

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

export default function SurveysTable({ loading, title, surveys, onClickRefresh }: Props): React.ReactElement | null {
    const classes = useStyles();
    const table = useRef(null);

    if (!surveys?.length) {
        return null;
    }

    const columns = [
        {
            name: 'surveyId',
            label: ' ',
            options: {
                display: 'excluded' as any,
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'restaurantId',
            label: ' ',
            options: {
                display: 'excluded' as any,
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'customerId',
            label: ' ',
            options: {
                display: 'excluded' as any,
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'answeredAt',
            label: translate('Answered'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'restaurantName',
            label: translate('Restaurant'),
            options: {
                filter: true,
                searchable: true,
            },
        },
        {
            name: 'customerName',
            label: translate('Customer'),
            options: {
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'mobileNumber',
            label: translate('Mobile'),
            options: {
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'rating',
            label: translate('Rating'),
            options: {
                filter: true,
                searchable: false,
            },
        },
        {
            name: 'tip',
            label: translate('Tip'),
            options: {
                filter: true,
                searchable: false,
            },
        },
        {
            name: 'surveyAnswers',
            label: translate('Answers'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'otherAnswer',
            label: translate('Other Answer'),
            options: {
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'unsolved',
            label: translate('Unsolved'),
            options: {
                filter: true,
                searchable: false,
            },
        },
        {
            name: 'solvedAt',
            label: translate('Solved'),
            options: {
                filter: false,
                searchable: false,
            },
        },
    ];

    return (
        <div style={{ position: 'relative' }}>
            <UpdatingContentProgress loading={loading} />
            <MUIDataTable
                //@ts-ignore
                ref={table}
                className={classes.table}
                title={title || translate('Surveys')}
                data={surveys.map((survey) => {
                    const row = {
                        surveyId: survey.surveyId,
                        restaurantId: survey.restaurantId,
                        customerId: survey.customerId,
                        answeredAt: formatDateTimeString(survey.answeredAt),
                        restaurantName: survey.restaurantName,
                        customerName: survey.customerName,
                        mobileNumber: survey.mobileNumber,
                        rating: survey.rating,
                        tip: survey.tip ?? '',
                        surveyAnswers: survey.surveyAnswers ? survey.surveyAnswers.map(translateSurveyAnswer).join(', ') : '',
                        otherAnswer: survey.otherAnswer ?? '',
                        unsolved: survey.unsolved ? UNSOLVED : '',
                        solvedAt: formatDateTimeString(survey.solvedAt),
                    } as const;
                    return row;
                })}
                columns={columns}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'multiple',
                    onRowClick: (
                        rowData: Array<string>,
                        rowMeta: {
                            dataIndex: number;
                            rowIndex: number;
                        }
                    ) => {
                        const surveyId = rowData[0] as any;
                        openSurveyPageInNewTab(surveyId);
                    },
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedUnsolvedSurveyIds = selectedRowsData.filter((d: any) => d[11] === UNSOLVED).map((d: any) => d[0]);
                        const selectedCustomerIds = selectedRowsData.map((d: any) => d[2]);
                        const handleSolveSurveys = async () => {
                            if (selectedUnsolvedSurveyIds.length === 1) {
                                await solveSurveysApi({ surveyIds: selectedUnsolvedSurveyIds });
                                await onClickRefresh();
                                return;
                            }
                            const answer = window.confirm(`Do you want to mark ${selectedUnsolvedSurveyIds.length} surveys as solved?`);
                            if (answer) {
                                await solveSurveysApi({ surveyIds: selectedUnsolvedSurveyIds });
                                await onClickRefresh();
                            }
                        };
                        const handleClickSendPushNotification = () => {
                            if (selectedCustomerIds.length === 1) {
                                history.push({
                                    search: setUrlQueryParameter(SEND_NOTIFICATION, selectedCustomerIds[0]),
                                });
                            } else {
                                history.push({
                                    pathname: RoutePaths.ADMIN_SURVEYS_SEND_NOTIFICATIONS,
                                    search: history.location.search,
                                    state: {
                                        customers: selectedRowsData.map((rowData: any) => ({
                                            customerId: rowData[2],
                                            name: rowData[5],
                                        })),
                                    },
                                });
                            }
                        };
                        const handleClickSendEmail = () => {
                            if (selectedCustomerIds.length === 1) {
                                history.push({
                                    search: setUrlQueryParameter(SEND_EMAIL, selectedCustomerIds[0]),
                                });
                            } else {
                                history.push({
                                    pathname: RoutePaths.ADMIN_SURVEYS_SEND_EMAILS,
                                    search: history.location.search,
                                    state: {
                                        customers: selectedRowsData.map((rowData: any) => ({
                                            customerId: rowData[2],
                                            name: rowData[5],
                                        })),
                                    },
                                });
                            }
                        };
                        const handleClickCredits = () => {
                            if (selectedCustomerIds.length === 1) {
                                history.push({
                                    search: setUrlQueryParameter(ADD_CREDITS, selectedCustomerIds[0]),
                                });
                            } else {
                                history.push({
                                    pathname: RoutePaths.ADMIN_SURVEYS_CREDITS,
                                    search: history.location.search,
                                    state: {
                                        customers: selectedRowsData.map((rowData: any) => ({
                                            customerId: rowData[2],
                                            name: rowData[5],
                                        })),
                                    },
                                });
                            }
                        };
                        return (
                            <CustomToolbarSelect>
                                <DownloadCsvToolbarButton onClick={createHandleClickDownloadCsvFunctional(table, getSelectedRows(selectedRows, displayData))} />
                                <SendNotificationToolbarButton onClick={handleClickSendPushNotification} />
                                <SendEmailToolbarButton onClick={handleClickSendEmail} />
                                <AddCreditsToolbarButton onClick={handleClickCredits} />
                                <SolveSurveysToolbarButton onClick={handleSolveSurveys} />
                            </CustomToolbarSelect>
                        );
                    },
                    filterType: 'multiselect',
                    rowsPerPage: 100,
                    customToolbar: () => {
                        return (
                            <>
                                <RefreshToolbarButton onClick={onClickRefresh} />
                            </>
                        );
                    },
                    onDownload: createFilteredOnDownloadFunctional(table),
                }}
            />
        </div>
    );
}
