/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Dialog } from 'src/components/Dialog';
import { CreateMenu } from 'src/scenes/letseatmanager/menu/menu/CreateMenu';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export function CreateMenuSection({ onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    const isSmallScreen = useIsSmallScreen();

    if (isSmallScreen) {
        return (
            <Dialog open={true} onClose={onClose}>
                <CreateMenu onSuccess={onSuccess} onClose={onClose} />
            </Dialog>
        );
    }

    return (
        <div className={classes.container}>
            <CreateMenu onSuccess={onSuccess} onClose={onClose} />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

type Props = {
    onClose?: any;
    onSuccess?: any;
};
