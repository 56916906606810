/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { app2 } from 'src/app2';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CustomToolbarSelect } from 'src/components/mui-datatables/CustomToolbarSelect';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import type { PickupStationsVm } from 'src/scenes/letseatadmin/PickupStationsPage';
import type { RestaurantId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { createFilteredOnDownloadFunctional } from 'src/utils/mui-datatables/createFilteredOnDownloadFunctional';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { openRestaurantPageInNewTab } from 'src/utils/window/openRestaurantPageInNewTab';

export default function PickupStationsTable({ title, onClickRefresh }: Props): React.ReactElement {
    const classes = useStyles();
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const table = useRef(null);
    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState({ pickupStations: [] } as PickupStationsVm);
    const openChangePickupStationsDialog = useAction(app2.actions.openChangePickupStationsDialog);

    useEffect(() => {
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.admin.pickupStations();
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        setVm(response.data);
    };

    const columns = [
        {
            name: 'pickupStationId',
            label: ' ',
            options: {
                display: 'excluded' as any,
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'kitchenId',
            label: ' ',
            options: {
                display: 'excluded' as any,
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'status',
            label: translate('Status'),
            options: {
                filterList: ['LIVE'],
                filter: true,
                searchable: false,
            },
        },
        {
            name: 'name',
            label: translate('Name'),
            options: {
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'closingTime',
            label: translate('Closing Time'),
            options: {
                filter: true,
                searchable: false,
            },
        },
        {
            name: 'deliveryTime',
            label: translate('Delivery Time'),
            options: {
                filter: true,
                searchable: false,
            },
        },
        {
            name: 'kitchenNames',
            label: translate('Kitchens'),
            options: {
                filter: true,
                searchable: true,
                customBodyRender: (kitchenNames: any) => (
                    <>
                        {kitchenNames.split('\n').map((kitchenName: any, index: number) => (
                            <div key={index}>{kitchenName}</div>
                        ))}
                    </>
                ),
            },
        },
        {
            name: 'public',
            label: translate('Public'),
            options: {
                filter: true,
                searchable: false,
            },
        },
    ];

    return (
        <div style={{ position: 'relative' }}>
            <UpdatingContentProgress loading={loading} />
            <MUIDataTable
                // @ts-ignore
                ref={table}
                className={classes.table}
                title={title || translate('Pickup Stations')}
                data={vm.pickupStations.map((pickupStation) => ({
                    pickupStationId: pickupStation.pickupStationId,
                    kitchenIds: pickupStation.kitchenIds,
                    status: pickupStation.status,
                    name: pickupStation.name,
                    closingTime: pickupStation.closingTime ?? '',
                    deliveryTime: pickupStation.deliveryTime ?? '',
                    kitchenNames: pickupStation.kitchenNames?.join('\n') ?? '',
                    public: pickupStation.public ? 'Public' : 'Company Private',
                }))}
                columns={columns}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: !viewUser ? 'multiple' : 'none',
                    onRowClick: (
                        rowData: Array<string>,
                        rowMeta: {
                            dataIndex: number;
                            rowIndex: number;
                        }
                    ) => {
                        const pickupStationId: RestaurantId = rowData[0] as any;
                        openRestaurantPageInNewTab(pickupStationId);
                    },
                    customToolbarSelect: (selectedRows, displayData) => {
                        return (
                            <CustomToolbarSelect>
                                <ChangeToolbarButton
                                    onClick={() => {
                                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                                        const selectedPickupStationIds = selectedRowsData.map((d: any) => d[0]);
                                        openChangePickupStationsDialog({
                                            pickupStationIds: selectedPickupStationIds,
                                            onSuccess: load,
                                        });
                                    }}
                                />
                            </CustomToolbarSelect>
                        );
                    },
                    filterType: 'multiselect',
                    rowsPerPage: 100,
                    customToolbar: () => {
                        return (
                            <>
                                <RefreshToolbarButton
                                    onClick={() => {
                                        load();
                                        onClickRefresh && onClickRefresh();
                                    }}
                                />
                            </>
                        );
                    },
                    onDownload: createFilteredOnDownloadFunctional(table),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

type Props = {
    title?: string;
    onClickRefresh?: any;
};
