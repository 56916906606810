/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { VehicleType } from 'src/constants/VehicleTypes';
import type { DriverManualEarningVm } from 'src/types/DriverManualEarningVm';
import type { EmailAddress, RestaurantId } from 'src/types/Id';

export async function createExternalOwnFleetDriverApi(request: CreateExternalOwnFleetDriverApiRequest): ApiSauceResponse<DriverManualEarningVm> {
    return letseatmanagerApiMethod('ownFleet/createExternalOwnFleetDriver', request);
}

export type CreateExternalOwnFleetDriverApiRequest = {
    restaurantId: RestaurantId;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    email?: EmailAddress;
    vehicleType?: VehicleType;
};
