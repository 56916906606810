/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SurveyAnswer } from 'src/constants/SurveyAnswer';
import type { CustomerId, RestaurantId, SurveyId } from 'src/types/Id';

export async function getSurveysApi(request: GetSurveysApiRequest): ApiSauceResponse<SurveysVm> {
    return letseatmanagerApiMethod('survey/getSurveysApi', request);
}

type GetSurveysApiRequest = {
    restaurantIds: Array<RestaurantId>;
    from: string; // YYYY-MM-DD,
    to: string; // YYYY-MM-DD
};

export type SurveysVm = {
    total: number;
    dismissed: number;
    withRating: number;
    rating: {
        ['1']: number;
        ['2']: number;
        ['3']: number;
        ['4']: number;
        ['5']: number;
    };
    recommendationRating: {
        ['1']: number;
        ['2']: number;
        ['3']: number;
        ['4']: number;
        ['5']: number;
    };
    averageRating: number;
    averageRecommendationRating: number;
    withAnswer: number;
    surveyAnswers: {
        FOOD_WAS_COLD: number;
        MISSING_OR_INCORRECT_ITEMS: number;
        FOOD_WAS_NOT_PREPARED: number;
        FOOD_ARRIVED_LATE: number;
        CONFUSED_PICK_UP_PLACE: number;
        BAD_SERVICE: number;
        PUNCTUALITY: number;
        APPLICATION: number;
        FOOD: number;
        PRESENTATION: number;
        SERVICE: number;
    };
    otherPositiveAnswers: Array<string>;
    otherNegativeAnswers: Array<string>;
    surveys: Array<SurveyVm>;
};

export type SurveyVm = {
    surveyId: SurveyId;
    customerId: CustomerId;
    customerName: string;
    mobileNumber: string;
    rating?: number;
    recommendationRating?: number;
    surveyAnswers?: Array<SurveyAnswer>;
    otherAnswer?: string;
    createdAt: Date;
    answeredAt: Date;
};
