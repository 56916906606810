/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CashRegisterVm } from 'src/types/CashRegisterVm';
import type { ManagerUserId, RestaurantId } from 'src/types/Id';
import type { PosBusinessDayVm } from 'src/types/PosBusinessDayVm';

export async function openPosBusinessDayAndCashRegisterPosBusinessDayApi(
    request: OpenPosBusinessDayAndCashRegisterPosBusinessDayApiRequest
): ApiSauceResponse<OpenPosBusinessDayAndCashRegisterPosBusinessDayApiResponse> {
    return letseatmanagerApiMethod('pos/openPosBusinessDayAndCashRegisterPosBusinessDayApi', request);
}

export type OpenPosBusinessDayAndCashRegisterPosBusinessDayApiRequest = {
    restaurantId: RestaurantId;
    managerUserId: ManagerUserId;
    initialAmount: string;
};

export type OpenPosBusinessDayAndCashRegisterPosBusinessDayApiResponse = {
    posBusinessDay: PosBusinessDayVm;
    cashRegister: CashRegisterVm;
};
