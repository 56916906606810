/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button, Chip, Table } from '@pidedirecto/ui';
import { TrashCanIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { findPromotionsApi } from 'src/api/letseatmanager/promotion/findPromotionsApi';
import { removePromotionApi } from 'src/api/letseatmanager/promotion/removePromotionApi';
import { Page } from 'src/components/Page';
import { translate } from 'src/i18n/translate';
import { ChangePromotionButton } from 'src/scenes/letseatmanager/promotions/ChangePromotionButton';
import { CreatePromotionButton } from 'src/scenes/letseatmanager/promotions/CreatePromotionButton';
import { useConfirmDialog } from 'src/services/dialog/useConfirmDialog';
import { PromotionId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateString } from 'src/utils/date/formatDateString';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function PromotionsPage(): React.ReactElement {
    const confirmDialog = useConfirmDialog();
    const classes = useStyles();

    const restaurantIds = useSelector((state) => state.app.restaurantIds);

    const [loading, setLoading] = useState(false);

    const [loadingPromotions, promotions, reloadPromotions] = useLoadApi(findPromotionsApi, { restaurantIds }, { initialValue: [] });

    const removePromotions = async (selectedPromotionIds: Array<PromotionId>) => {
        const shouldRemovePromotions = await confirmDialog({
            title: translate('Remove promotions'),
            content: translate('Are you sure to remove the selected promotions?'),
            buttonText: translate('Accept'),
            cancelButtonText: translate('Cancel'),
            variant: 'error',
        });
        if (!shouldRemovePromotions) return;

        setLoading(true);

        for (const selectedPromotionId of selectedPromotionIds) {
            const response = await removePromotionApi({
                promotionId: selectedPromotionId,
            });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
            }
        }

        setLoading(false);
        reloadPromotions();
    };

    return (
        <Page title={translate('Promotions')} className={classes.container}>
            <Table
                emptyMessage={translate('There are no promotion to show')}
                loading={loading || loadingPromotions}
                selectable
                columns={[
                    {
                        id: 'name',
                        content: translate('Name'),
                    },
                    {
                        id: 'promotionType',
                        content: translate('Type'),
                    },
                    {
                        id: 'dates',
                        content: translate('Dates'),
                    },
                    {
                        id: 'status',
                        content: translate('Status'),
                        size: 0.5,
                    },
                    {
                        id: 'usage',
                        content: translate('Sales'),
                    },
                    {
                        id: 'description',
                        content: translate('Description'),
                    },
                ]}
                rows={promotions.map((promotion) => ({
                    name: promotion.name,
                    promotionType: translate(`PromotionTypes.${promotion.promotionType}`),
                    dates: `${formatDateString(promotion.startsAt)} - ${formatDateString(promotion.endsAt)}`,
                    status: promotion.disabled ? <Chip variant='warning'>{translate('Paused')}</Chip> : <Chip variant='success'>{translate('Active')}</Chip>,
                    usage: promotion.usage,
                    description: promotion.description,
                    rowId: promotion.promotionId,
                }))}
                toolbar={({ selectedRows: selectedPromotionIds, clearSelectedRows }) => {
                    return (
                        <>
                            {!!selectedPromotionIds.length && (
                                <Button
                                    variant='secondary'
                                    onClick={() => {
                                        removePromotions(selectedPromotionIds);
                                        clearSelectedRows();
                                    }}
                                >
                                    <TrashCanIcon size={20} title={translate('Remove selected promotions')} />
                                </Button>
                            )}
                            {selectedPromotionIds.length === 1 && (
                                <ChangePromotionButton
                                    promotionId={selectedPromotionIds[0]}
                                    onSuccess={() => {
                                        reloadPromotions();
                                        clearSelectedRows();
                                    }}
                                />
                            )}
                            {!selectedPromotionIds.length && <CreatePromotionButton onSuccess={reloadPromotions} />}
                        </>
                    );
                }}
            />
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        paddingBottom: 40,
        height: 'unset',
    },
}));
