/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { transformStringOutput } from 'src/components/form/utils/transformStringOutput';
import { Tooltip } from 'src/components/Tooltip';
import { classNames } from 'src/utils/react/classNames';

export function AutoComplete({
    name,
    options,
    value,
    label,
    tooltip,
    placeholder,
    helperText,
    defaultValue,
    required,
    disabled,
    error,
    clearOnBlur,
    freeSolo,
    classes: classesProp,
    onChange: onChangeProp,
    onInputChange: onInputChangeProp,
}: Props): React.ReactElement {
    const defaultValueSet = useRef(false);
    const classes = useStyles();

    const [autoCompleteValue, setAutoCompleteValue] = useState<string | undefined | null>('');
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (defaultValue && !value) {
            handleSetDefaultValue();
        }
    }, [defaultValue, options, value]);

    useEffect(() => {
        if (value) {
            handleSetValue();
        }
    }, [value, options]);

    const handleSetDefaultValue = () => {
        if (defaultValueSet.current || autoCompleteValue || inputValue) return;

        const optionDefaultSelected = options.find((option) => option.value === defaultValue);
        if (!optionDefaultSelected) return;

        defaultValueSet.current = true;
        setAutoCompleteValue(defaultValue);
        setInputValue(optionDefaultSelected.label);
    };

    const handleSetValue = () => {
        const optionDefaultSelected = options.find((option) => option.value === value);
        if (!optionDefaultSelected) return;

        setInputValue(optionDefaultSelected.label);
    };

    const handleAutCompleteChange = (e: any, data: any) => {
        const newValue = transformStringOutput(data?.value);
        setAutoCompleteValue(newValue);
        onChangeProp?.(newValue);
    };

    const handleInputChange = (e: any, value: any) => {
        setInputValue(value);
        onInputChangeProp?.(value);
    };

    return (
        <Autocomplete
            renderInput={({ InputProps, InputLabelProps, ...params }) => {
                return (
                    <div className={classes.container} ref={InputProps.ref}>
                        <label
                            {...InputLabelProps}
                            htmlFor={`${name}-input`}
                            /* @ts-ignore */
                            required={required as any}
                            className={classNames(classes.label, classesProp?.label, { [(classes as any).labelError]: error })}
                        >
                            {label}
                            {!!tooltip && <Tooltip text={tooltip} />}
                        </label>
                        <input
                            {...params.inputProps}
                            className={classNames(classes.input, classesProp?.input, { [(classes as any).inputError]: error })}
                            id={`${name}-input`}
                            aria-describedby={`${name}-helperText`}
                            name={name}
                            placeholder={placeholder}
                            value={inputValue}
                        />
                        {!!helperText && (
                            <span id={`${name}-helperText`} className={classNames(classes.helperText, { [(classes as any).helperTextError]: error })}>
                                {helperText}
                            </span>
                        )}
                    </div>
                );
            }}
            value={value || autoCompleteValue}
            inputValue={inputValue}
            onChange={handleAutCompleteChange}
            onInputChange={handleInputChange}
            options={options}
            clearOnBlur={clearOnBlur}
            getOptionLabel={(data) => {
                if (data?.label) {
                    return data.label;
                }
                return options.find((option) => option.value === data)?.label ?? '';
            }}
            getOptionSelected={(option, value) => {
                return option.value === value;
            }}
            /* @ts-ignore */
            required={required as any}
            disabled={disabled}
            freeSolo={freeSolo}
            fullWidth
            classes={{ option: classes.autoCompleteOption }}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        fontFamily: theme.typography.light,
        color: '#2E3748',
        fontSize: 14,
        paddingBottom: 5,
        lineHeight: '1',
    },
    input: {
        height: '42px',
        padding: 15,
        width: '100%',
        outline: '1px solid #D9D9D9',
        backgroundColor: 'transparent',
        borderRadius: '4px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        boxSizing: 'border-box',
        color: '#191919',
        fontFamily: theme.typography.light,
        border: 0,
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: theme.palette.secondary.contrastText,
        wordBreak: 'break-all',
        marginTop: 3,
        fontSize: 10,
    },
    autoCompleteOption: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
    },
}));

type Props = {
    options: Array<{
        value: any;
        label: string;
    }>;
    classes?: {
        label?: string;
        helperText?: string;
        input?: string;
        error?: string;
    };
    name: string;
    value: any;
    label?: string;
    placeholder?: string;
    tooltip?: string;
    helperText?: string;
    defaultValue?: any;
    onChange: any;
    onInputChange?: any;
    required?: boolean;
    disabled?: boolean;
    error?: boolean;
    clearOnBlur?: boolean;
    freeSolo?: boolean;
};
