/**
 * @prettier
 */
export const RewardTypes = {
    DISCOUNT: 'DISCOUNT',
    CREDITS: 'CREDITS',
    PRODUCTS: 'PRODUCTS',
} as const;

export type RewardType = typeof RewardTypes[keyof typeof RewardTypes];
