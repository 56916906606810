/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { PromotionId } from 'src/types/Id';
import { PromotionVm } from 'src/types/PromotionVm';

export async function getPromotionApi(request: GetPromotionApiRequest): ApiSauceResponse<PromotionVm> {
    return letseatmanagerApiMethod('promotion/getPromotionApi', request);
}

export type GetPromotionApiRequest = {
    promotionId: PromotionId;
};
