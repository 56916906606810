/**
 * @prettier
 */
import { findModifierGroupsApi } from 'src/api/letseatmanager/modifierGroup/findModifierGroupsApi';
import { unzip } from 'src/api/utils/unzip';
import { menuReducer } from 'src/reducers/menuReducer';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

let callingApi = false;

export function useLoadModifierGroups(): any {
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const setModifierGroups = useAction(menuReducer.actions.setModifierGroups);
    const setLoading = useAction(menuReducer.actions.setLoadingModifierGroups);
    const setFailed = useAction(menuReducer.actions.setModifierGroupFailed);

    const loadModifierGroups = async () => {
        if (callingApi) return;

        setLoading(true);
        callingApi = true;
        const response = await findModifierGroupsApi({ restaurantId });
        if (!response.ok) {
            setFailed();
            setLoading(false);
            callingApi = false;
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setModifierGroups(unzip(response.data));
        setLoading(false);
        callingApi = false;
    };

    return loadModifierGroups;
}
