/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { findDriverManualEarningsByDriverIdApi } from 'src/api/letseatadmin/driverManualEarning/findDriverManualEarningsByDriverIdApi';
import { removeDriverManualEarningApi } from 'src/api/letseatadmin/driverManualEarning/removeDriverManualEarningApi';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ChangeDriverManualEarningDialog } from 'src/scenes/letseatadmin/driverManualEarning/ChangeDriverManualEarningDialog';
import { CreateDriverManualEarningDialog } from 'src/scenes/letseatadmin/driverManualEarning/CreateDriverManualEarningDialog';
import { DriverManualEarningVm } from 'src/types/DriverManualEarningVm';
import type { DriverId, DriverManualEarningId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useSelector } from 'src/utils/react/useSelector';

export function DriverManualEarnings({ driverId, filter }: Props): React.ReactElement {
    const classes = useStyles();
    const viewUser = useSelector((state) => state.authentication.viewUser);
    const supportUser = useSelector((state) => state.authentication.supportUser);
    const operationsUser = useSelector((state) => state.authentication.operationsUser);

    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState<{ driverManualEarnings: Array<DriverManualEarningVm> }>({ driverManualEarnings: [] });
    const [createDriverManualEarningDialogState, setCreateDriverManualEarningDialogState] = useState({ open: false });
    const [changeDriverManualEarningDialogState, setChangeDriverManualEarningDialogState] = useState({ open: false, driverManualEarningId: undefined });

    useEffect(() => {
        load();
    }, [filter?.from, filter?.to]);

    const load = async () => {
        setLoading(true);
        const response = await findDriverManualEarningsByDriverIdApi({ driverId, filter });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setVm(response.data);
    };

    const removeDriverManualEarnings = async (driverManualEarningIds: Array<DriverManualEarningId>) => {
        setLoading(true);
        for (const driverManualEarningId of driverManualEarningIds) {
            const response = await removeDriverManualEarningApi({ driverManualEarningId });
            if (!response.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(response);
                await load();
                return;
            }
        }
        await load();
    };

    const handleClickNew = () => {
        setCreateDriverManualEarningDialogState({ open: true });
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <CreateDriverManualEarningDialog
                open={createDriverManualEarningDialogState.open}
                onClose={() => setCreateDriverManualEarningDialogState({ open: false })}
                onDriverManualEarningCreated={load}
                driverId={driverId}
            />
            <ChangeDriverManualEarningDialog
                open={changeDriverManualEarningDialogState.open}
                driverManualEarningId={changeDriverManualEarningDialogState.driverManualEarningId}
                onClose={() => setChangeDriverManualEarningDialogState({ open: false, driverManualEarningId: undefined })}
                onChangeDriverManualEarning={load}
            />

            <MUIDataTable
                /* @ts-ignore */
                className={classes.table}
                title={translate('Driver Manual Earnings')}
                data={
                    vm.driverManualEarnings &&
                    vm.driverManualEarnings.map((driverManualEarning) => {
                        return {
                            driverManualEarningId: driverManualEarning.driverManualEarningId,
                            driverId: driverManualEarning.driverId,
                            title: driverManualEarning.title,
                            body: driverManualEarning.body,
                            comment: driverManualEarning.comment,
                            amount: driverManualEarning.amount,
                            reason: driverManualEarning.reason ?? 'N/A',
                            receivedAt: formatDateTimeString(driverManualEarning.receivedAt),
                            modifiedAt: formatDateTimeString(driverManualEarning.modifiedAt),
                        };
                    })
                }
                columns={[
                    {
                        name: 'driverManualEarningId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'driverId',
                        label: translate('driverId'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'title',
                        label: translate('Title'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'body',
                        label: translate('Body'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'comment',
                        label: translate('Comment'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'amount',
                        label: translate('Amount'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'reason',
                        label: translate('Reason'),
                        options: {
                            filter: false,
                            customBodyRender: (reason) => <div>{reason !== 'N/A' ? translate(`DriverManualEarningReasons.${reason}`) : 'N/A'}</div>,
                        },
                    },
                    {
                        name: 'receivedAt',
                        label: translate('ReceivedAt'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'modifiedAt',
                        label: translate('ModifiedAt'),
                        options: {
                            filter: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: !viewUser ? 'multiple' : 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                            <CreateToolbarButton onClick={handleClickNew} />
                        </>
                    ),
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedDriverManualEarningIds = selectedRowsData.map((d: any) => d[0]);
                        return (
                            <div className={classes.toolbar}>
                                {selectedDriverManualEarningIds.length === 1 && (
                                    <ChangeToolbarButton
                                        disabled={supportUser || operationsUser}
                                        onClick={() => {
                                            if (selectedDriverManualEarningIds.length === 1)
                                                setChangeDriverManualEarningDialogState({ open: true, driverManualEarningId: selectedDriverManualEarningIds[0] });
                                        }}
                                    />
                                )}
                                <RemoveToolbarButton
                                    disabled={supportUser || operationsUser}
                                    onClick={async () => {
                                        const remove = window.confirm(
                                            selectedDriverManualEarningIds.length === 1
                                                ? 'Are you sure you want to remove the selected driverManualEarnings'
                                                : `Are you sure you want to remove the selected ${selectedDriverManualEarningIds.length} driverManualEarnings`
                                        );
                                        if (remove) {
                                            await removeDriverManualEarnings(selectedDriverManualEarningIds);
                                        }
                                    }}
                                />
                            </div>
                        );
                    },
                    // filterList: [[], [], [], [], ['MEXICO']],
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

type Props = {
    driverId: DriverId;
    filter?: {
        from: Date;
        to: Date;
        paymentDate: Date;
    };
};
