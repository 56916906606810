/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { getFinanceRestaurantStatisticsApi, SalesByAppAndDay } from 'src/api/letseatmanager/restaurantDashboard/getFinanceRestaurantStatisticsApi';
import { Form } from 'src/components/form/Form';
import { FormSelect } from 'src/components/form/FormSelect';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { FilterMasterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterMasterReports';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { SalesByAppTable } from 'src/scenes/letseatmanager/restaurantDashboard/financeReport/SalesByAppTable';
import { SalesByDayTable } from 'src/scenes/letseatmanager/restaurantDashboard/financeReport/SalesByDayTable';
import { SalesByPaymentMethodTable } from 'src/scenes/letseatmanager/restaurantDashboard/financeReport/SalesByPaymentMethodTable';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { RestaurantId } from 'src/types/Id';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function FinanceReport(): React.ReactElement {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const classes = useStyles();
    const form = useForm();
    const { control } = form;

    const [salesByDayData, setSalesByDayData] = useState<any>([]);
    const [salesByPaymentMethodData, setSalesByPaymentMethodData] = useState<any>({
        paymentMethodWithMostSales: undefined,
        salesFromPaymentMethodWithMostSales: undefined,
        appFromPaymentMethodWithMostSales: undefined,
        salesByAppAndDay: [],
    });
    const [restaurantChannels, setRestaurantChannels] = useState<Array<string>>([]);
    const [hasInfoFound, setHasInfoFound] = useState(false);
    const [appWithMostSales, setAppWithMostSales] = useState<any>({ appWithMostSales: undefined, salesFromAppWithMostSales: undefined });
    const [showAsMaster, setShowAsMaster] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const currencyFormat = useSelector((state) => state.app.restaurant?.currencyFormat);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const currentRestaurantId = useSelector((state) => state.app2.filterReportsState.currentRestaurantId);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, reports] = useLoadApi(
        getFinanceRestaurantStatisticsApi,
        {
            report: showAsMaster ? 'asBrand' : 'perRestaurant',
            restaurantIds: brandOpened ? restaurantIds : [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period?.to)).toISOString(),
            salesType: salesType,
        },
        {
            dependencies: [period, showAsMaster, restaurantId, brandOpened, salesType],
        }
    );

    const selectedRestaurantChannel = useWatch<string>({ name: 'restaurantChannel', control });

    const hasSaleByDays = restaurantChannels.length > 0;
    const restaurantChannelsOptions = restaurantChannels.map((channel) => ({ label: translate(channel), value: channel }));

    useEffect(() => {
        if (reports) {
            if (showAsMaster || !brandOpened) return handleUpdateFinanceReports(reports[0]?.restaurantId);

            handleUpdateFinanceReports(currentRestaurantId ?? reports[0]?.restaurantId);
        }
    }, [reports, currentRestaurantId]);

    const handleUpdateFinanceReports = (newRestaurantId: RestaurantId) => {
        const currentRestaurantReportInfo = reports?.find((restaurant) => restaurant.restaurantId === newRestaurantId);

        if (!hasRestaurantReportInfo(currentRestaurantReportInfo)) {
            setHasInfoFound(false);
            return;
        }

        getSaleByDayItemFromReports(currentRestaurantReportInfo?.salesByAppAndDay);

        setSalesByDayData({
            dayWithMostSales: [currentRestaurantReportInfo?.dayWithMostSales ?? '', currentRestaurantReportInfo?.salesFromDayWithMostSales ?? 0],
            salesByDay: currentRestaurantReportInfo?.salesByDay ?? [],
        });
        setSalesByPaymentMethodData({
            paymentMethodWithMostSales: currentRestaurantReportInfo?.paymentMethodWithMostSales,
            salesFromPaymentMethodWithMostSales: currentRestaurantReportInfo?.salesFromPaymentMethodWithMostSales,
            appFromPaymentMethodWithMostSales: currentRestaurantReportInfo?.appFromPaymentMethodWithMostSales,
            salesByAppAndDay: currentRestaurantReportInfo?.salesByAppAndDay ?? [],
        });
        setAppWithMostSales({ appWithMostSales: currentRestaurantReportInfo?.appWithMostSales, salesFromAppWithMostSales: currentRestaurantReportInfo?.salesFromAppWithMostSales });
        setHasInfoFound(true);
    };

    const hasRestaurantReportInfo = (currentRestaurant: typeof reports[number] | undefined) => currentRestaurant && currentRestaurant?.salesByDay;

    const getSaleByDayItemFromReports = (salesByAppAndDay?: Array<SalesByAppAndDay>) => {
        if (!salesByAppAndDay) {
            setRestaurantChannels([]);
            return;
        }
        setRestaurantChannels(getRestaurantChannels(salesByAppAndDay ?? []));
    };

    const getRestaurantChannels = (salesByAppAndDay?: Array<SalesByAppAndDay>) => {
        const restaurantChannels = salesByAppAndDay?.map((saleByAppAndDay: SalesByAppAndDay) => saleByAppAndDay.app);
        return removeDuplicates(restaurantChannels) ?? [];
    };

    const getSalesByApp = () => {
        const salesByApp: Array<any> = [];

        if (!reports) return salesByApp;

        let restaurantReports = reports.find((report) => report.restaurantId === currentRestaurantId);

        if (!restaurantReports) restaurantReports = reports[0];

        for (const saleByAppAndDay of restaurantReports?.salesByAppAndDay ?? []) {
            const isCardSale = saleByAppAndDay.paymentMethod === PaymentMethods.CREDIT_CARD;
            const isCashSale = saleByAppAndDay.paymentMethod === PaymentMethods.CASH;

            const saleByApp = salesByApp.find((saleByApp) => saleByApp.createdAt === saleByAppAndDay.createdAt && saleByApp.channel === saleByAppAndDay.app);

            if (!saleByApp) {
                salesByApp.push({
                    createdAt: saleByAppAndDay.createdAt,
                    total: saleByAppAndDay.total,
                    channel: saleByAppAndDay.app,
                    creditCard: isCardSale ? saleByAppAndDay.total : '0',
                    cash: isCashSale ? saleByAppAndDay.total : '0',
                });
                continue;
            }

            saleByApp.total = BigNumber(saleByApp.total).plus(saleByAppAndDay.total).toNumber();
            if (isCardSale) saleByApp.creditCard = BigNumber(saleByApp.creditCard).plus(saleByAppAndDay.total).toNumber();
            if (isCashSale) saleByApp.cash = BigNumber(saleByApp.cash).plus(saleByAppAndDay.total).toNumber();
        }

        return salesByApp.filter((salesByApp) => salesByApp.channel === selectedRestaurantChannel);
    };

    const handleShowAsMaster = (value: any) => {
        setShowAsMaster(value);
    };

    return (
        <div>
            <FilterReports />
            <FilterMasterReports onChangeMaster={handleShowAsMaster} showAsMaster={showAsMaster} />
            {loading && <UpdatingContentProgress />}
            {hasInfoFound && (
                <div>
                    <SalesByDayTable loading={loading} salesByDayData={salesByDayData} />
                    <SalesByPaymentMethodTable loading={loading} salesByPaymentMethodData={salesByPaymentMethodData} />
                    <ReportSection title={translate('Sales by App')} loading={loading}>
                        <div className={classes.splitContainer}>
                            <div className={classes.subSplitContainer}>
                                <div className={classes.sectionHeadKpi}>
                                    <KpiCard
                                        classes={{
                                            container: classes.headingKpiContainer,
                                            title: classes.headingKpiTitleKpi,
                                            body: classes.headingKpiBody,
                                            color: classes.headingKpiColor,
                                            legend: classes.headingKpiLegendContainer,
                                        }}
                                        legend={translate(appWithMostSales.appWithMostSales)}
                                        title={translate('Most used app')}
                                    />
                                    <KpiCard
                                        classes={{
                                            container: classes.headingKpiContainer,
                                            title: classes.headingKpiTitleKpi,
                                            body: classes.headingKpiBody,
                                            color: classes.headingKpiColor,
                                            legend: classes.headingKpiLegendContainer,
                                        }}
                                        legend={formatAsCurrencyNumber(appWithMostSales.salesFromAppWithMostSales)}
                                        title={translate(`Sales with @app`, { app: translate(appWithMostSales.appWithMostSales) })}
                                    />
                                </div>
                                <div className={classes.menuSection}>
                                    <Form form={form}>
                                        <FormSelect name={'restaurantChannel'} options={restaurantChannelsOptions} defaultValue={restaurantChannelsOptions[0].value} />
                                    </Form>
                                </div>
                            </div>
                            <div className={classes.container}>
                                <ReportSection loading={loading}>
                                    {hasSaleByDays && <SalesByAppTable loading={loading} salesByApp={getSalesByApp()} title={translate(selectedRestaurantChannel)} />}
                                </ReportSection>
                            </div>
                        </div>
                    </ReportSection>
                </div>
            )}
            {!hasInfoFound && !loading && <ReportSectionEmptyState />}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    menuSection: {
        width: '100%',
    },
    splitContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',

        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'row-reverse',
            flexWrap: 'nowrap',
        },
    },
    subSplitContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.between('md', 'lg')]: {
            width: '22%',
            marginLeft: 10,
            flexDirection: 'column-reverse',
        },
    },
    sectionHeadKpi: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'column',
            width: '100%',
        },
    },
    headingKpiContainer: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '8px',
        margin: 5,
        textAlign: 'center',
        marginRight: '5%',
        marginBottom: '2%',
    },
    headingKpiTitleKpi: {
        fontFamily: theme.typography.regular,
        fontSize: 16,
        color: theme.palette.text.contrast,
    },
    headingKpiBody: {
        fontSize: 28,
        fontFamily: theme.typography.semiBold,
    },
    headingKpiColor: {
        width: 12,
        height: 12,
        borderRadius: 2,
    },
    headingKpiLegendContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.between('sm', 'lg')]: {
            justifyContent: 'left',
            textAlign: 'left',
            marginLeft: 40,
        },
    },
}));

export type ChannelReport = {
    day: string;
    total: number;
    channels: Array<ChannelPaymentMethod>;
};

export type ChannelPaymentMethod = {
    channel: string;
    totalChannel: number;
    data: Array<DataOfPaymentMethod>;
};

export type DataOfPaymentMethod = {
    id: string;
    totalSalesByPaymentMethod: number;
};
