/**
 * @prettier
 */
export const InternalReports = {
    GENERAL_OPERATIONS_REPORT: 'GENERAL_OPERATIONS_REPORT',
    GENERAL_SALES_REPORT: 'GENERAL_SALES_REPORT',
    LAS_ALITAS_SALES_REPORT: 'LAS_ALITAS_SALES_REPORT',
    BONUS_BILLING_REPORT: 'BONUS_BILLING_REPORT',
    OPS_ORDERS_AND_TIMES_REPORT: 'OPS_ORDERS_AND_TIMES_REPORT',
    OPS_RAPPI_1000_ORDERS_REPORT: 'OPS_RAPPI_1000_ORDERS_REPORT',
    OPS_DELIVERY_PROVIDERS_REPORT: 'OPS_DELIVERY_PROVIDERS_REPORT',
    ORDERS_BREAKDOWN_BY_PAYMENTS_PROVIDER: 'ORDERS_BREAKDOWN_BY_PAYMENTS_PROVIDER',
    KFC_DELIVERY_ORDERS_REPORT: 'KFC_DELIVERY_ORDERS_REPORT',
} as const;

export type InternalReport = typeof InternalReports[keyof typeof InternalReports];
