/**
 * @prettier
 */
import { Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import ReceiptIcon from '@material-ui/icons/Receipt';
import * as React from 'react';
import { useSelector } from 'src/utils/react/useSelector';

type Props = {
    label: string;
    tooltip: string;
    onClick: any;
    enabledForUserTypeView?: boolean;
};

export function CreateInvoiceButton({ label, tooltip, onClick, enabledForUserTypeView = false }: Props): React.ReactElement {
    const viewUser = useSelector((state) => state.authentication.viewUser);
    return (
        <Tooltip title={tooltip}>
            <Button onClick={onClick} startIcon={<ReceiptIcon />} disabled={viewUser && !enabledForUserTypeView}>
                {label}
            </Button>
        </Tooltip>
    );
}
