/**
 * @prettier
 */
import { usePosCustomerActions } from 'src/modules/pos/customer/posCustomerStore';

export function useSetCustomerEmail() {
    const setCustomerEmail = usePosCustomerActions((actions) => actions.setCustomerEmail);

    return setCustomerEmail;
}
