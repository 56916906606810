/**
 * @prettier
 */
import { Grid, makeStyles } from '@material-ui/core';
import { DownloadIcon } from '@pidedirecto/ui/icons';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { createInventoryTransactionReportApi, CreateInventoryTransactionReportApiRequest } from 'src/api/letseatmanager/inventory/createInventoryTransactionReportApi';
import { findSuppliesByRestaurantIdApi } from 'src/api/letseatmanager/supply/findSuppliesByRestaurantIdApi';
import { Button } from 'src/components/Button';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { ConfirmUpdateInventoryDialog } from 'src/components/dialog/inventory/ConfirmUpdateInventoryDialog';
import { Form } from 'src/components/form/Form';
import { FormHiddenField } from 'src/components/form/FormHiddenField';
import { FormTextField } from 'src/components/form/FormTextField';
import { Text } from 'src/components/Text';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { BuyUnitSupplyReport } from 'src/scenes/letseatmanager/inventory/inventory/BuyUnitSupplyReport';
import { useNotification } from 'src/services/notification/useNotification';
import { SupplyVm } from 'src/types/SupplyVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { objectsToCsv } from 'src/utils/csv/objectsToCsv';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';
import { requireValue } from 'src/utils/require/requireValue';

export function CreateInventoryReport({ goBack, goToInventoryDone }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        control,
        formState: { isSubmitting },
    } = form;
    const { fields, append } = useFieldArray({
        control,
        name: 'suppliesInventoried',
    });
    const [userHasRolePermission] = useUserHasRolePermission();
    const notification = useNotification();

    const [supplies, setSupplies] = useState<Array<SupplyVm>>([]);
    const [loading, setLoading] = useState(false);
    const [openConfirmUpdateInventoryDialog, setOpenConfirmUpdateInventoryDialog] = useState<{ open: boolean; onSuccess: ((updateInventory: boolean) => any) | undefined }>({
        open: false,
        onSuccess: undefined,
    });

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const updateInventoryForCountsEnabled = useSelector((state) => state.app.restaurant.updateInventoryForCountsEnabled);

    const suppliesInventoried = useWatch<Array<SupplyVm>>({ name: 'suppliesInventoried', control });

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (supplies.length && !suppliesInventoried?.length) {
            for (const supply of supplies) {
                append({
                    supplyId: supply.supplyId,
                    sku: supply.sku,
                    name: supply.name,
                });
            }
        }
    }, [supplies, suppliesInventoried]);

    const load = async () => {
        setLoading(true);
        const response = await findSuppliesByRestaurantIdApi({ restaurantId });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setSupplies(response.data.supplies);
    };

    const saveInventoryReport = async (updateInventory: boolean, form: { suppliesInventoried: CreateInventoryTransactionReportApiRequest['suppliesInventoried'] }) => {
        const filterSuppliesWithValidQuantity = form.suppliesInventoried.filter((supplyInventoried) => {
            return supplyInventoried.unitsInventoried.some((unitInventoried) => {
                return unitInventoried.quantity !== undefined && unitInventoried.quantity !== null && unitInventoried.quantity !== (false as any) && unitInventoried.quantity !== '';
            });
        });
        setLoading(true);
        const response = await createInventoryTransactionReportApi({
            restaurantId,
            suppliesInventoried: filterSuppliesWithValidQuantity,
            updateInventory,
        });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        handleCloseConfirmUpdateInventoryDialog();
        if (!userHasRolePermission(RolePermissions.UPDATE_INVENTORY)) return goBack();
        goToInventoryDone(response.data.inventoryTransactionReportId);
    };

    const handleCloseConfirmUpdateInventoryDialog = () => {
        setOpenConfirmUpdateInventoryDialog({ open: false, onSuccess: undefined });
    };

    const onSubmit = async (form: any) => {
        if (userHasRolePermission(RolePermissions.UPDATE_INVENTORY) && !updateInventoryForCountsEnabled) {
            setOpenConfirmUpdateInventoryDialog({
                open: true,
                onSuccess: (updateInventory) => saveInventoryReport(updateInventory, form),
            });
            return;
        }

        await saveInventoryReport(updateInventoryForCountsEnabled ?? false, form);
    };

    const downloadCsvTemplate = () => {
        if (!fields.length) {
            notification({ message: translate('There are no supplies resume to download') });
            return;
        }
        const csvRows: Array<{ SKU: string; NOMBRE: string; CANTIDAD: string; MEDIDA: string }> = [];
        fields.forEach((supplyInventoried) => {
            const supply = supplies.find((currentSupply) => currentSupply.supplyId === supplyInventoried.supplyId);
            if (!supply) {
                console.error('Supply not found for ID:', supplyInventoried.supplyId);
                return;
            }
            supply.buyUnits.forEach((buyUnit) => {
                const row = {
                    SKU: supplyInventoried.sku,
                    NOMBRE: supplyInventoried.name,
                    CANTIDAD: '',
                    MEDIDA: unitTranslated[buyUnit.unit],
                };
                csvRows.push(row);
            });
        });

        const csv = objectsToCsv(csvRows);
        downloadTextInFile(csv, 'inventory_count_template.csv');
    };

    return (
        <div className={classes.container}>
            <ConfirmUpdateInventoryDialog
                open={openConfirmUpdateInventoryDialog.open}
                onSuccess={openConfirmUpdateInventoryDialog.onSuccess}
                onClose={handleCloseConfirmUpdateInventoryDialog}
                loading={loading}
            />
            <div className={classes.form}>
                <GoBackButton goBack={goBack} classes={{ button: classes.goBackButton }} />
                <UpdatingContentProgress loading={loading || isSubmitting} top right />
                <section className={classes.topContainer}>
                    <Text title>{translate('Inventory Count')}</Text>
                    <Button icon classes={{ button: (classes as any).iconButton }} onClick={downloadCsvTemplate}>
                        <DownloadIcon />
                    </Button>
                </section>

                <Form form={form} onSubmit={onSubmit} className={classes.formContainer}>
                    <Grid container spacing={3}>
                        {fields.map((supplyInventoried, index) => {
                            const supply = requireValue(supplies.find((supply) => supply.supplyId === supplyInventoried.supplyId));

                            return (
                                <div key={supplyInventoried.id} className={classes.formRow}>
                                    <FormHiddenField name={`suppliesInventoried.${index}.supplyId`} defaultValue={supplyInventoried.supplyId} />
                                    <Grid item xs={2}>
                                        <FormTextField name={`suppliesInventoried.${index}.sku`} disabled defaultValue={supplyInventoried.sku} />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormTextField name={`suppliesInventoried.${index}.name`} disabled defaultValue={supplyInventoried.name} />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <BuyUnitSupplyReport index={index} supply={supply} />
                                    </Grid>
                                </div>
                            );
                        })}
                        <Grid item xs={12} className={classes.fixedButton}>
                            <Button primary type='submit'>
                                {loading || isSubmitting ? translate('Saving') : translate('Save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </div>
        </div>
    );
}

const unitTranslated = {
    KILOGRAM: 'Kilogramo',
    GRAM: 'Gramo',
    LITRE: 'Litro',
    MILLILITER: 'Mililitro',
    BOX: 'Caja',
    BAG: 'Bolsa',
    PIECE: 'Pieza',
    PACKAGE: 'Paquete',
    STICK: 'Barra',
    ROLL: 'Rollo',
    CAN: 'Lata',
    SLICE: 'Rebanada',
    TABLESPOON: 'Cucharada',
    QUART: 'Cuarto',
    POUND: 'Libra',
    OUNCE: 'Onza',
    GALLON: 'Galon',
    CUP: 'Taza',
    CURRENCY: 'Moneda',
};

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    goBackButton: {
        position: 'relative',
        padding: '8px 12px',
    },
    formContainer: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '65vh',
        borderRadius: 10,
        padding: 20,
        marginBottom: 80,
        position: 'relative',
    },
    form: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        gap: 12,
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
    },
    topContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
    },
    formRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
        '& .MuiGrid-item': {
            padding: 12,
        },
    },
    fixedButton: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: 'white',

        padding: '12px 0',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
    },
}));

type Props = {
    goBack: any;
    goToInventoryDone: any;
};
