/**
 * @prettier
 */
import * as React from 'react';
import { FormAutocomplete } from 'src/components/form/FormAutocomplete';
import type { Variant } from 'src/components/form/FormTypes';
import { Banks } from 'src/constants/Bank';
import { getBankClave } from 'src/utils/bank/getBankClave';
import { getBankLongName } from 'src/utils/bank/getBankLongName';

export function FormBankAutocomplete({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, InputProps, tooltip }: Props): React.ReactElement {
    const options = required
        ? Object.values(Banks).map((bank: any) => ({ label: `${bank} - ${getBankClave(bank) ?? ''} - ${getBankLongName(bank) ?? ''}`, value: bank }))
        : [{ label: '', value: null }, ...Object.values(Banks).map((bank: any) => ({ label: `${bank} - ${getBankClave(bank) ?? ''} - ${getBankLongName(bank) ?? ''}`, value: bank }))];

    return (
        <FormAutocomplete
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={required}
            variant={variant}
            InputProps={InputProps}
            tooltip={tooltip}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    tooltip?: string;
};
