/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { OrderId } from 'src/types/Id';
import type { OrderItemVm } from 'src/types/OrderVm';

export async function moveOrderItemsBetweenOrdersApi(request: MoveOrderItemsBetweenOrdersApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('pos/moveOrderItemsBetweenOrdersApi', request);
}

type MoveOrderItemsBetweenOrdersApiRequest = {
    orderItemsToMove: Array<OrderItemVm>;
    remainingOrderItems: Array<OrderItemVm>;
    toOrderId: OrderId;
    fromOrderId: OrderId;
};
