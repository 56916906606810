/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId, RestaurantTaxId } from 'src/types/Id';

export function changeRestaurantTaxesSortingApi(request: ChangeRestaurantTaxesSortingApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurantTax/changeRestaurantTaxesSortingApi', request);
}

export type ChangeRestaurantTaxesSortingApiRequest = {
    restaurantId: RestaurantId;
    restaurantTaxIds: Array<RestaurantTaxId>;
};
