/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'src/components/Button';

export function FormSubmitButton({ text, disabled, classes: classesProp }: Props): React.ReactElement {
    const classes = useStyles();
    const {
        formState: { isSubmitting },
    } = useFormContext();

    return (
        <Button type='submit' disabled={disabled || isSubmitting} classes={{ button: classesProp?.container }}>
            {text}
            <Loader loading={isSubmitting} size={12} classes={{ container: classes.buttonProgress }} />
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    buttonProgress: {
        position: 'absolute',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
}));

type Props = {
    text: string;
    disabled?: boolean;
    classes?: {
        container?: string;
    };
};
