/**
 * @prettier
 */
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { useRef, useState } from 'react';
import { findBannersApi } from 'src/api/letseatadmin/banner/findBannersApi';
import { removeBannerApi } from 'src/api/letseatadmin/banner/removeBannerApi';
import { Page } from 'src/components/Page';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ChangedBannerDialog } from 'src/scenes/letseatadmin/banner/ChangeBannerDialog';
import { CreateBannerDialog } from 'src/scenes/letseatadmin/banner/CreateBannerDialog';
import { RestaurantId, type BannerId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { transformImageUrl } from 'src/utils/image/transformImageUrl';
import { createFilteredOnDownload } from 'src/utils/mui-datatables/createFilteredOnDownload';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function BannersPage(): React.ReactElement {
    const table = useRef(); // TODO: fix correct type

    const classes = useStyles();

    const viewUser = useSelector((state) => state.authentication.viewUser);

    const [loading, setLoading] = useState(false);

    const [createBannerDialogState, setCreateBannerDialogState] = useState({ open: false });
    const [changedBannerDialogState, setChangedBannerDialogState] = useState({ open: false, bannerId: undefined });

    const [loadingBanners, { banners }, refreshBanners] = useLoadApi(findBannersApi, undefined, { initialValue: { banners: [] } });

    const removeMultiple = async (bannerIds: Array<BannerId>) => {
        setLoading(true);
        for (const bannerId of bannerIds) {
            const response = await removeBannerApi({ bannerId });
            if (!response.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(response);
                return await refreshBanners();
            }
        }
        await refreshBanners();
    };

    const handleClickNew = () => {
        setCreateBannerDialogState({ open: true });
    };

    if (loading && loadingBanners) return <Loader size={40} loading={true} />;
    return (
        <Page title={translate('Banners')}>
            <div>
                <UpdatingContentProgress loading={(loading || loadingBanners) && banners} />

                <CreateBannerDialog open={createBannerDialogState.open} onClose={() => setCreateBannerDialogState({ open: false })} onBannerCreated={refreshBanners} />
                <ChangedBannerDialog
                    open={changedBannerDialogState.open}
                    bannerId={changedBannerDialogState.bannerId}
                    onClose={() => setChangedBannerDialogState({ open: false, bannerId: undefined })}
                    onBannerChanged={refreshBanners}
                />

                <Table
                    loading={loadingBanners}
                    /* @ts-ignore */
                    ref={table}
                    className={classes.table}
                    data={banners.map((banner) => ({
                        bannerId: banner.bannerId,
                        imageUrl: banner.imageUrl,
                        bannerVisibility: banner.bannerVisibility,
                        deliveryCategories: banner.deliveryCategories?.join(', '),
                        enabled: banner.enabled ? translate('Enabled') : translate('Disabled'),
                        pinned: banner.pinned ? translate('Pinned') : '',
                        hours: banner.hours,
                        restaurants: banner.restaurants?.map((restaurant: { name: string; restaurantId: RestaurantId }) => restaurant.name)?.join(', '),
                        companyNames: banner.companyNames?.map((companyName: string) => companyName)?.join(', '),
                        country: banner.country,
                    }))}
                    columns={[
                        {
                            name: 'bannerId',
                            label: '',
                            options: {
                                display: 'excluded',
                                filter: false,
                            },
                        },
                        {
                            name: 'imageUrl',
                            label: translate('Banner'),
                            options: {
                                filter: false,
                                sort: false,
                                customBodyRender: (imageUrl) => {
                                    return (
                                        <div
                                            className={classes.container}
                                            style={{
                                                backgroundImage: `url("${transformImageUrl(imageUrl, 40, 75)}")`,
                                                height: 40,
                                                width: 75,
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center center',
                                            }}
                                        ></div>
                                    );
                                },
                            },
                        },
                        {
                            name: 'bannerVisibility',
                            label: translate('Visibility'),
                        },
                        {
                            name: 'deliveryCategories',
                            label: translate('Delivery Categories'),
                            options: {
                                searchable: false,
                                customBodyRender: (deliveryCategories) =>
                                    deliveryCategories?.split(', ')?.map((deliveryCategory: any, i: any) => <div key={`${deliveryCategory}-${i}`}>{deliveryCategory}</div>),
                            },
                        },
                        {
                            name: 'enabled',
                            label: translate('Enabled'),
                        },
                        {
                            name: 'pinned',
                            label: translate('Pinned'),
                        },
                        {
                            name: 'hours',
                            label: translate('Hours'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'restaurants',
                            label: translate('Restaurants'),
                            options: {
                                filter: false,
                                customBodyRender: (restaurants) => restaurants?.split(', ')?.map((restaurant: any, i: any) => <div key={`${restaurant}-${i}`}>{restaurant}</div>),
                            },
                        },
                        {
                            name: 'companyNames',
                            label: translate('Company Names'),
                            options: {
                                filter: false,
                                customBodyRender: (companyNames) => companyNames?.split(', ')?.map((companyName: any, i: any) => <div key={`${companyName}-${i}`}>{companyName}</div>),
                            },
                        },
                        {
                            name: 'country',
                            label: translate('Country'),
                            options: {
                                filter: false,
                            },
                        },
                    ]}
                    options={{
                        responsive: 'standard',
                        tableBodyMaxHeight: '500px',
                        selectableRows: !viewUser ? 'multiple' : 'none',
                        filterType: 'checkbox',
                        rowsPerPage: 100,
                        customToolbar: () => {
                            return (
                                <>
                                    <Tooltip title={translate('Refresh')}>
                                        <IconButton onClick={() => refreshBanners()}>
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                    {!viewUser && (
                                        <Tooltip title={translate('Create')}>
                                            <IconButton onClick={handleClickNew}>
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </>
                            );
                        },
                        customToolbarSelect: (selectedRows, displayData) => {
                            const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                            const selectedBannerIds = selectedRowsData.map((d: Array<BannerId>) => d[0]);
                            const handleClickEdit = () => {
                                if (selectedBannerIds.length !== 1) return;
                                setChangedBannerDialogState({ open: true, bannerId: selectedBannerIds[0] });
                            };
                            const handleClickRemove = async () => {
                                const remove = window.confirm(
                                    selectedBannerIds.length === 1
                                        ? translate('Are you sure you want to remove the selected banner')
                                        : translate(`Are you sure you want to remove the selected ${selectedBannerIds.length} banners`)
                                );
                                if (remove) {
                                    await removeMultiple(selectedBannerIds);
                                }
                            };
                            return (
                                <div className={classes.toolbar}>
                                    {selectedBannerIds.length === 1 && (
                                        <Tooltip title={translate('Change')}>
                                            <IconButton onClick={handleClickEdit}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title={translate('Remove')}>
                                        <IconButton onClick={handleClickRemove}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            );
                        },
                        onDownload: createFilteredOnDownload({ table: table.current }),
                    }}
                />
            </div>
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));
