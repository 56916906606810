/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { history } from 'src/config/history';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { ManagerUserId } from 'src/types/Id';
import type { PosBusinessDaySummaryVm } from 'src/types/PosBusinessDaySummaryVm';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function PosOpenedPosBusinessDaySummary({ posBusinessDaySummary }: Props): React.ReactElement {
    const classes = useStyles();
    const [userHasRolePermission] = useUserHasRolePermission();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const pinCodeUserSignedIn = useSelector((state) => state.authentication?.pinCodeUserSignedIn);
    const currentEmployeesClockedIn = useSelector((state) => state.app2.currentEmployeesClockedIn);

    const displaySalesInfo = userHasRolePermission(RolePermissions.MANAGE_CASH_REGISTERS);
    const employeeClockedIn = currentEmployeesClockedIn?.find((employee: { clockedInAt: Date; managerUserId: ManagerUserId }) => employee.managerUserId === pinCodeUserSignedIn.managerUserId);

    const handleOpenPosBusinessDayReports = () => {
        history.push({
            pathname: RoutePaths.RESTAURANT_POS_REPORT,
            search: history.location.search,
        });
    };

    return (
        <div className={classes.container}>
            <div className={classes.cashContainer}>
                <span className={classes.text}>{translate('Opening of the day')}</span>
                <span className={classes.cash}>{moment(posBusinessDaySummary?.openedAt).format('lll')}</span>
            </div>
            <div className={classes.cashContainer}>
                <span className={classes.text}>{translate('Initial Amount')}</span>
                <span className={classes.cash}>{formatAsCurrencyNumber(posBusinessDaySummary?.initialAmount)}</span>
            </div>
            {!!posBusinessDaySummary?.totalOrders && (
                <div className={classes.cashContainer}>
                    <span className={classes.text}>{translate('Orders')}</span>
                    <span className={classes.cash}>{posBusinessDaySummary.totalOrders}</span>
                </div>
            )}
            {!!posBusinessDaySummary?.totalSold && displaySalesInfo && (
                <div className={classes.cashContainer}>
                    <span className={classes.text}>{translate('Sales')}</span>
                    <span className={classes.cash}>{formatAsCurrencyNumber(posBusinessDaySummary.totalSold)}</span>
                </div>
            )}
            {employeeClockedIn && (
                <div className={classes.cashContainer}>
                    <span className={classes.text}>{translate('Clock in time')}</span>
                    <span className={classes.cash}>{moment(employeeClockedIn?.clockedInAt).format('lll')}</span>
                </div>
            )}
            <a className={classes.link} onClick={handleOpenPosBusinessDayReports}>
                {translate('Day End Reports')}
            </a>
            <div className={classes.line}></div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    text: {
        fontSize: 12,
        color: theme.palette.text.primary,
        fontWeight: 400,
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
    link: {
        fontSize: 14,
        textDecoration: 'underline',
        marginLeft: 'auto',
        fontFamily: theme.typography.regular,
        color: theme.palette.text.brand,
        cursor: 'pointer',
    },
    cash: {
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.text.primary,
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
    cashContainer: {
        margin: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    line: {
        width: '100%',
        height: 1,
        margin: '24px 0',
        borderTop: `1px solid ${theme.palette.border.primary}`,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: theme.typography.regular,
        margin: '20px 0',
        gap: 5,
    },
}));

type Props = {
    posBusinessDaySummary: PosBusinessDaySummaryVm;
};
