/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { ModifierGroupVm } from 'src/types/ModifierGroupVm';

export async function findModifierGroupsApi(request: FindModifierGroupsApiRequest): ApiSauceResponse<Array<ModifierGroupVm>> {
    return letseatmanagerApiMethod('modifierGroup/findModifierGroupsApi', request);
}

export type FindModifierGroupsApiRequest = {
    restaurantId: RestaurantId;
};
