/**
 * @prettier
 */
import { call, put, select } from 'redux-saga/effects';
import { deprecatedGetMenuApi } from 'src/api/letseatmanager/menu/deprecatedGetMenuApi';
import { deprecatedMoveMenuLeftApi } from 'src/api/letseatmanager/menu/deprecatedMoveMenuLeftApi';
import { deprecatedMoveMenuRightApi } from 'src/api/letseatmanager/menu/deprecatedMoveMenuRightApi';
import { deprecatedMoveMenuCategoryDownApi } from 'src/api/letseatmanager/menuCategory/deprecatedMoveMenuCategoryDownApi';
import { deprecatedMoveMenuCategoryUpApi } from 'src/api/letseatmanager/menuCategory/deprecatedMoveMenuCategoryUpApi';
import { deprecatedMoveMenuItemDownApi } from 'src/api/letseatmanager/menuItem/deprecatedMoveMenuItemDownApi';
import { deprecatedMoveMenuItemUpApi } from 'src/api/letseatmanager/menuItem/deprecatedMoveMenuItemUpApi';
import { hideMenuItemApi } from 'src/api/letseatmanager/menuItem/hideMenuItemApi';
import TheMenuActions from 'src/scenes/letseatmanager/deprecatedMenu/TheMenuReducer';
import { SentryService } from 'src/services/SentryService';
import { getErrorDescriptionFromResponse } from 'src/utils/error/getErrorDescriptionFromResponse';

export function* fetch(action: any): any {
    const state = yield select();
    if (state.app.restaurant.newMenuManagementEnabled) return;

    const request = { restaurantId: action.restaurantId } as const;
    const response = yield call(deprecatedGetMenuApi, request);
    if (response.ok) {
        SentryService.logInfoBreadcrumb('Successfully downloaded the restaurant menu view model', { request });
        const vm = response.data;
        const state = yield select();
        yield put(TheMenuActions.fetchSuccess(vm, state.authentication.adminUser));
    } else {
        SentryService.logError('Failed to download the restaurant menu view model', { request, response });
        const errorMessage = `Failed to load page cause of ${getErrorDescriptionFromResponse(response)}`;
        yield put(TheMenuActions.fetchFailure(errorMessage));
    }
}

export function* moveMenuLeft(action: any): any {
    const response = yield call(deprecatedMoveMenuLeftApi, action.moveMenuLeftRequest);
    if (response.ok) {
        SentryService.logInfoBreadcrumb('Successfully moved menu up', { moveMenuLeftRequest: action.moveMenuLeftRequest });
        yield put(TheMenuActions.moveMenuLeftSuccess());
        const state = yield select();
        yield put(TheMenuActions.fetch(state.app.restaurantId));
    } else {
        SentryService.logError('Failed to move menu up', { moveMenuLeftRequest: action.moveMenuLeftRequest, response });
        const errorMessage = `Failed to move menu up cause of ${getErrorDescriptionFromResponse(response)}`;
        alert(errorMessage);
        yield put(TheMenuActions.moveMenuLeftFailure(errorMessage));
    }
}

export function* moveMenuRight(action: any): any {
    const response = yield call(deprecatedMoveMenuRightApi, action.moveMenuRightRequest);
    if (response.ok) {
        SentryService.logInfoBreadcrumb('Successfully moved menu down', { moveMenuRightRequest: action.moveMenuRightRequest });
        yield put(TheMenuActions.moveMenuRightSuccess());
        const state = yield select();
        yield put(TheMenuActions.fetch(state.app.restaurantId));
    } else {
        SentryService.logError('Failed to move menu down', { moveMenuRightRequest: action.moveMenuRightRequest, response });
        const errorMessage = `Failed to move menu down cause of ${getErrorDescriptionFromResponse(response)}`;
        alert(errorMessage);
        yield put(TheMenuActions.moveMenuRightFailure(errorMessage));
    }
}

export function* moveMenuCategoryUp(action: any): any {
    const response = yield call(deprecatedMoveMenuCategoryUpApi, action.moveMenuCategoryUpRequest);
    if (response.ok) {
        SentryService.logInfoBreadcrumb('Successfully moved category up', { moveMenuCategoryUpRequest: action.moveMenuCategoryUpRequest });
        yield put(TheMenuActions.moveMenuCategoryUpSuccess());
        const state = yield select();
        yield put(TheMenuActions.fetch(state.app.restaurantId));
    } else {
        SentryService.logError('Failed to move category up', { moveMenuCategoryUpRequest: action.moveMenuCategoryUpRequest, response });
        const errorMessage = `Failed to move category up cause of ${getErrorDescriptionFromResponse(response)}`;
        yield put(TheMenuActions.moveMenuCategoryUpFailure(errorMessage));
    }
}

export function* moveMenuCategoryDown(action: any): any {
    const response = yield call(deprecatedMoveMenuCategoryDownApi, action.moveMenuCategoryDownRequest);
    if (response.ok) {
        SentryService.logInfoBreadcrumb('Successfully moved category down', { moveMenuCategoryDownRequest: action.moveMenuCategoryDownRequest });
        yield put(TheMenuActions.moveMenuCategoryDownSuccess());
        const state = yield select();
        yield put(TheMenuActions.fetch(state.app.restaurantId));
    } else {
        SentryService.logError('Failed to move category down', { moveMenuCategoryDownRequest: action.moveMenuCategoryDownRequest, response });
        const errorMessage = `Failed to move category down cause of ${getErrorDescriptionFromResponse(response)}`;
        yield put(TheMenuActions.moveMenuCategoryDownFailure(errorMessage));
    }
}

export function* moveMenuItemUp(action: any): any {
    const response = yield call(deprecatedMoveMenuItemUpApi, action.moveMenuItemUpRequest);
    if (response.ok) {
        SentryService.logInfoBreadcrumb('Successfully moved menu item up', { moveMenuItemUpRequest: action.moveMenuItemUpRequest });
        yield put(TheMenuActions.moveMenuItemUpSuccess());
        const state = yield select();
        yield put(TheMenuActions.fetch(state.app.restaurantId));
    } else {
        SentryService.logError('Failed to move menu item up', { moveMenuItemUpRequest: action.moveMenuItemUpRequest, response });
        const errorMessage = `Failed to move menu item up cause of ${getErrorDescriptionFromResponse(response)}`;
        yield put(TheMenuActions.moveMenuItemUpFailure(errorMessage));
    }
}

export function* moveMenuItemDown(action: any): any {
    const response = yield call(deprecatedMoveMenuItemDownApi, action.moveMenuItemDownRequest);
    if (response.ok) {
        SentryService.logInfoBreadcrumb('Successfully moved menu item down', { moveMenuItemDownRequest: action.moveMenuItemDownRequest });
        yield put(TheMenuActions.moveMenuItemDownSuccess());
        const state = yield select();
        yield put(TheMenuActions.fetch(state.app.restaurantId));
    } else {
        SentryService.logError('Failed to move menu item down', { moveMenuItemDownRequest: action.moveMenuItemDownRequest, response });
        const errorMessage = `Failed to move menu item down cause of ${getErrorDescriptionFromResponse(response)}`;
        yield put(TheMenuActions.moveMenuItemDownFailure(errorMessage));
    }
}

export function* hideMenuItem(action: any): any {
    const request = { menuItemId: action.menuItemId, hiddenUntil: action.hiddenUntil } as const;
    const response = yield call(hideMenuItemApi, request);
    if (response.ok) {
        SentryService.logInfoBreadcrumb('Successfully hidden menu item', { request });
        yield put(TheMenuActions.moveMenuItemDownSuccess());
        const state = yield select();
        yield put(TheMenuActions.fetch(state.app.restaurantId));
    } else {
        SentryService.logError('Failed to to hide menu item', { request, response });
        const errorMessage = `Failed to to hide menu item cause of ${getErrorDescriptionFromResponse(response)}`;
        yield put(TheMenuActions.moveMenuItemDownFailure(errorMessage));
    }
}

export function* unHideMenuItem(action: any): any {
    const request = { menuItemId: action.menuItemId } as const;
    const response = yield call(unHideMenuItem, request);
    if (response.ok) {
        SentryService.logInfoBreadcrumb('Successfully unhidden menu item', { request });
        yield put(TheMenuActions.moveMenuItemDownSuccess());
        const state = yield select();
        yield put(TheMenuActions.fetch(state.app.restaurantId));
    } else {
        SentryService.logError('Failed to unhide menu item', { request, response });
        const errorMessage = `Failed to unhide menu item cause of ${getErrorDescriptionFromResponse(response)}`;
        yield put(TheMenuActions.moveMenuItemDownFailure(errorMessage));
    }
}
