/**
 * @prettier
 */
import * as React from 'react';
import { useEffect } from 'react';
import { useSyncOfflineUiLogEvents } from 'src/utils/react/useSyncOfflineUiLogEvents';

export function SyncOfflineUiLogEvents(): React.ReactElement | null {
    const [syncOfflineUiLogEvents] = useSyncOfflineUiLogEvents();

    useEffect(() => {
        syncOfflineUiLogEvents();
    }, []);

    return null;
}
