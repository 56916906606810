/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { FromToFilter } from 'src/components/input/FromToFilter';
import { DriverBonusesBilling } from 'src/scenes/letseatadmin/driverBonusBilling/DriverBonusesBilling';

export function DriverBonusBillingPage(): React.ReactElement {
    const [filter, setFilter] = useState({
        from: moment().subtract(1, 'week').startOf('isoWeek').toDate(),
        to: moment().subtract(1, 'week').endOf('isoWeek').toDate(),
    });

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <FromToFilter filter={filter} onChangeFilter={setFilter} />
            </Grid>
            <Grid item xs={12}>
                <DriverBonusesBilling filter={filter} />
            </Grid>
        </Grid>
    );
}
