/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import { appColors } from 'src/theme/AppColors';

export function getTransactionColor(transactionAmount: string): string {
    if (BigNumber(transactionAmount).isGreaterThan(0)) {
        return appColors.table.green;
    } else if (BigNumber(transactionAmount).isLessThan(0)) {
        return appColors.table.red;
    } else {
        return appColors.table.transparent;
    }
}
