/**
 * @prettier
 */
import * as React from 'react';
import { memo } from 'react';
import TimeLineBarChart, {
    Color,
    PeriodScale,
    pushData,
    XAxisScale,
    xAxisTooltipFormat,
    yAxisFormat,
    yAxisLegend,
    YAxisScale,
    yAxisTooltipFormat,
} from 'src/scenes/letseatadmin/statistics/TimeLineBarChart';
import type { StatisticsVm } from 'src/scenes/letseatadmin/StatisticsPage';

type Props = {
    statistics?: StatisticsVm;
    period: PeriodScale;
    xAxis: XAxisScale;
    yAxis: YAxisScale;
};

export const RejectedChart: React.ComponentType<Props> = memo<Props>(function RejectedChart({ statistics, period, yAxis, xAxis }: Props): React.ReactElement | null {
    if (!statistics) {
        return null;
    }

    return (
        <TimeLineBarChart
            data={statisticsToData(statistics, period, xAxis, yAxis)}
            period={period}
            yAxis={yAxis}
            xAxis={xAxis}
            colors={[Color.BLUE, Color.BLUE_LIGHT, Color.RED, Color.RED_LIGHT, Color.GREY_LIGHT]}
            yAxisLegend={yAxisLegend(yAxis)}
            yAxisFormat={yAxisFormat(yAxis)}
            yAxisTooltipFormat={yAxisTooltipFormat(yAxis)}
            xAxisTooltipFormat={xAxisTooltipFormat(xAxis)}
        />
    );
});

function statisticsToData(statistics: StatisticsVm, period: PeriodScale, xAxis: XAxisScale, yAxis: YAxisScale) {
    return statistics.reduce(
        (chartData, statistic) => {
            pushData(chartData[0].data, statistic.date, statistic.order.rejected.soldOut, period, yAxis, xAxis);
            pushData(chartData[1].data, statistic.date, statistic.order.rejected.closingSoon, period, yAxis, xAxis);
            pushData(chartData[2].data, statistic.date, statistic.order.rejected.incorrectPrice, period, yAxis, xAxis);
            pushData(chartData[3].data, statistic.date, statistic.order.rejected.problemInRestaurant, period, yAxis, xAxis);
            pushData(chartData[4].data, statistic.date, statistic.order.rejected.other, period, yAxis, xAxis);
            return chartData;
        },
        [
            { id: 'Sold Out', data: [] },
            { id: 'Closing Soon', data: [] },
            { id: 'Incorrect Price', data: [] },
            { id: 'Problem in Restaurant', data: [] },
            { id: 'Other', data: [] },
        ]
    );
}
