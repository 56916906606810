/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { City } from 'src/constants/City';
import type { DriverBannerImageType } from 'src/constants/DriverBannerImageType';
import type { DriverBannerVm } from 'src/types/DriverBannerVm';

export async function createDriverBannerApi(request: createDriverBannerRequest): ApiSauceResponse<DriverBannerVm> {
    return letseatadminApiMethod('driverBanner/createDriverBannerApi', request);
}

export type createDriverBannerRequest = {
    text?: string;
    imageUrl?: string;
    linkUrl?: string;
    imageType: DriverBannerImageType;
    city?: City;
    enabled?: boolean;
};
