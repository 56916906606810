/**
 * @prettier
 */
import * as React from 'react';
import { FormTextField } from 'src/components/form/FormTextField';
import type { AutoCompleteInputProp, Variant } from 'src/components/form/FormTypes';
import { translate } from 'src/i18n/translate';
import { isRfc } from 'src/utils/string/isRfc';
import { removeSpaceCharacters } from 'src/utils/string/removeSpaceCharacters';
import { trim } from 'src/utils/string/trim';

export function FormRfcField({ name, label, placeholder, helperText, defaultValue, autoComplete = 'off', upperCase, disabled, required, variant, InputProps, inputProps }: Props): React.ReactElement {
    return (
        <FormTextField
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            autoComplete={autoComplete}
            upperCase={upperCase}
            disabled={disabled}
            required={required}
            validate={{
                rfc: (value: any) => ((trim(value)?.length ?? 0) < 1 || isRfc(removeSpaceCharacters(value)) ? true : translate('This field must contain 12-13 alphabetic or numeric characters')),
            }}
            variant={variant}
            InputProps={InputProps}
            inputProps={inputProps}
        />
    );
}

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    autoComplete?: AutoCompleteInputProp;
    upperCase?: boolean;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    inputProps?: any;
};
