/**
 * @prettier
 */
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { changePrinterApi } from 'src/api/letseatmanager/printer/changePrinterApi';
import { getPrinterApi } from 'src/api/letseatmanager/printer/getPrinterApi';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormAppAutocompleteMultiple } from 'src/components/form/FormAppAutocompleteMultiple';
import { FormDeviceSelect } from 'src/components/form/FormDeviceSelect';
import { FormDriverPrintersSelect } from 'src/components/form/FormDriverPrintersSelect';
import { FormPrinterBrandSelect } from 'src/components/form/FormPrinterBrandSelect';
import { FormPrinterContentSelect } from 'src/components/form/FormPrinterContentSelect';
import { FormPrinterPaperSizeSelect } from 'src/components/form/FormPrinterPaperSizeSelect';
import { FormPrinterTypeSelect } from 'src/components/form/FormPrinterTypeSelect';
import { FormTextField } from 'src/components/form/FormTextField';
import { PrinterBrand } from 'src/constants/PrinterBrand';
import { PrinterContent } from 'src/constants/PrinterContent';
import { PrinterPaperSize } from 'src/constants/PrinterPaperSize';
import { PrinterType, PrinterTypes } from 'src/constants/PrinterType';
import { translate } from 'src/i18n/translate';
import { getDeviceId } from 'src/services/device/getDeviceId';
import { WebUsbPrinter } from 'src/services/printer/printers/WebUsbPrinter';
import { useGetRestaurantChannels } from 'src/services/printer/useGetRestaurantChannels';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import type { PrinterId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isBluetoothPrinter } from 'src/utils/printer/isBluetoothPrinter';
import { isPaymentTerminalPrinter } from 'src/utils/printer/isPaymentTerminalPrinter';
import { useSelector } from 'src/utils/react/useSelector';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';
import { setRestaurantHasBluetoothPrintersInMobileApp } from 'src/utils/reactNative/setRestaurantHasBluetoothPrintersInMobileApp';
import { requireValue } from 'src/utils/require/requireValue';

export function ChangePrinterSection({ printerId, onPrinterChanged }: Props): React.ReactElement {
    const classes = useStyles();
    const channels = useGetRestaurantChannels();

    const form = useForm();
    const {
        formState: { isSubmitting },
        control,
    } = form;

    const { reloadRestaurant } = useReloadRestaurant();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const printerType: PrinterType | undefined = useWatch({ name: 'printerType', control });
    const connectedDeviceId = useWatch({ name: 'connectedDeviceId', control });
    const printerBrand: PrinterBrand | undefined = useWatch({ name: 'printerBrand', control });
    const printerPaperSize: PrinterPaperSize | undefined = useWatch({ name: 'printerPaperSize', control });
    const serialNumber: string | undefined = useWatch({ name: 'serialNumber', control });
    const printerContent: PrinterContent | undefined = useWatch({ name: 'printerContent', control });

    useEffect(() => {
        if (printerId) load();
    }, [printerId]);

    useEffect(() => {
        if (printerType === PrinterTypes.USB && printerBrand) {
            tryToConnectWithPrinter();
        }
    }, [printerType, printerBrand]);

    const tryToConnectWithPrinter = async () => {
        try {
            const serialNumber = await WebUsbPrinter.getPrinterSerialNumber(printerBrand!);
            form.setValue('serialNumber', serialNumber);
        } catch (e: any) {}
    };

    const load = async () => {
        setLoading(true);
        const response = await getPrinterApi({ restaurantId, printerId: requireValue(printerId) });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        form.reset({
            printerId: response.data.printerId ?? null,
            restaurantId: response.data.restaurantId ?? null,
            connectedDeviceId: response.data.connectedDeviceId ?? null,
            printerType: response.data.printerType ?? null,
            deviceName: response.data.deviceName ?? null,
            printerBrand: response.data.printerBrand ?? null,
            printerContent: response.data.printerContent ?? null,
            channels: response.data.channels ?? [],
            printerPaperSize: response.data.printerPaperSize ?? null,
            externalPrinterId: response.data.externalPrinterId ?? null,
            serialNumber: response.data.serialNumber ?? null,
            ipAddress: response.data.ipAddress ?? null,
            port: response.data.port ?? null,
        });
    };

    const handleDriverDownload = () => {
        window.open('https://habitue-img.s3.sa-east-1.amazonaws.com/driver+instalator.zip');
    };

    const handleChangeCheckbox = () => {
        form.setValue('deviceId', getDeviceId());
    };

    const onSubmit = async (form: any) => {
        const response = await changePrinterApi({
            printerId: requireValue(printerId),
            restaurantId: restaurantId,
            connectedDeviceId: form.connectedDeviceId,
            printerType: form.printerType,
            deviceName: form.deviceName,
            printerBrand: form.printerBrand,
            printerContent: form.printerContent === translate('Both') ? undefined : form.printerContent,
            channels: form.channels,
            printerPaperSize: form.printerPaperSize,
            externalPrinterId: form.externalPrinterId,
            serialNumber: form.serialNumber,
            ipAddress: form.ipAddress,
            port: form.port,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        reloadRestaurant(restaurantId);
        onPrinterChanged();

        if (isBluetoothPrinter(form.printerType)) setRestaurantHasBluetoothPrintersInMobileApp(true);
    };

    return (
        <Form form={form} onSubmit={onSubmit} className={classes.form}>
            <p className={classes.sectionTitle}>{translate('Edit Printer')}</p>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormDeviceSelect name='connectedDeviceId' label={translate('Device connected')} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormPrinterTypeSelect name='printerType' label={translate('Printer Type')} required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormPrinterBrandSelect name='printerBrand' label={translate('Printer Brand')} required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormPrinterPaperSizeSelect name='printerPaperSize' label={translate('Printer Paper Size')} defaultValue={printerPaperSize} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormPrinterContentSelect name='printerContent' label={translate('Printer Content')} defaultValue={printerContent} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormAppAutocompleteMultiple name='channels' label={translate('Channels')} helperText={translate('If this is blank will be all the channels')} filter={channels} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField name='deviceName' label={translate('Printer Name (Eg: Cocina)')} />
                    </Grid>

                    {isMobileApp() && printerType === PrinterTypes.WIFI && (
                        <Grid item xs={12}>
                            <FormTextField name='ipAddress' label={translate('Ip Address (Eg: 192.168.1.105)')} />
                        </Grid>
                    )}
                    {isMobileApp() && printerType === PrinterTypes.WIFI && (
                        <Grid item xs={12}>
                            <FormTextField name='port' label={translate('Port (Leave it empty if not known)')} />
                        </Grid>
                    )}
                    {isMobileApp() && printerType === PrinterTypes.BLUETOOTH && (
                        <Grid item xs={12}>
                            <DialogTitle>{translate('Make sure that the bluetooth printer is paired with your device')}</DialogTitle>
                        </Grid>
                    )}
                    {!isMobileApp() && (printerType === PrinterTypes.BLUETOOTH || printerType === PrinterTypes.WIFI) && (
                        <div className={classes.downloadDriversContainer}>
                            <p className={classes.sectionSubtitle}>{translate('Select a Printer')}</p>

                            <FormDriverPrintersSelect name='externalPrinterId' label={translate('Selected printer name:')} required />

                            <Button secondary onClick={handleDriverDownload} disabled={isSubmitting} classes={{ button: classes.secondaryButton }}>
                                {translate('Download Drivers')}
                            </Button>
                        </div>
                    )}
                    {(printerType === PrinterTypes.USB || isPaymentTerminalPrinter(printerType!)) && (
                        <Grid item xs={12}>
                            <FormTextField disabled={!isPaymentTerminalPrinter(printerType!)} name='serialNumber' label={translate('Serial Number')} defaultValue={serialNumber!} />
                        </Grid>
                    )}
                </Grid>
            </div>
            <div className={classes.buttonsContainer}>
                <Button primary type={'submit'} classes={{ button: classes.button }} disabled={loading}>
                    {isSubmitting ? translate('Updating') : translate('Update')}
                </Button>
            </div>
        </Form>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        maxWidth: 540,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        padding: '16px 8px',
    },
    dialogContent: {
        width: '100%',
        overflowY: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    inputDescription: {
        height: 170,
        alignItems: 'flex-start',
    },
    buttonsContainer: {
        display: 'flex',
        gap: 12,
        marginTop: 24,
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    sectionTitle: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        textAlign: 'center',
        fontSize: '20px',
        fontFamily: theme.typography.semiBold,
    },
    sectionSubtitle: {
        color: '#0D3037',
        fontSize: 16,
        fontFamily: theme.typography.semiBold,
        marginTop: 0,
    },
    button: {
        fontSize: 14,
        padding: '12px 20px',
        fontFamily: theme.typography.medium,
    },
    downloadDriversContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: 12,
    },
    secondaryButton: {
        marginTop: 12,
        alignSelf: 'end',
    },
}));

type Props = {
    onPrinterChanged: any;
    printerId?: PrinterId;
};
