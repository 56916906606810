/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, Text } from '@pidedirecto/ui';
import { useNotification } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { useState } from 'react';
import { translate } from 'src/i18n/translate';
import { moveOrderItemsBetweenOrdersApi } from 'src/modules/pos/order/api/moveOrderItemsBetweenOrdersApi';
import { OngoingEatHereOrdersList } from 'src/modules/pos/order/components/moveOrderItemsDialog/OngoingEatHereOrdersList';
import { useMoveOrderItemsBetweenOrdersActions, useMoveOrderItemsBetweenOrdersStore } from 'src/modules/pos/order/moveOrderItemsBetweenOrdersStore';
import { useClearPos } from 'src/services/pos/useClearPos';
import { CartItemVm } from 'src/types/CartItemVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { mapCartItemToOrderItem } from 'src/utils/pos/mapCartItemToOrderItem';
import { useSelector } from 'src/utils/react/useSelector';

export function SelectOrderToMoveOrderItemsDialog(): React.ReactElement {
    const classes = useStyles();
    const clearPos = useClearPos();
    const notification = useNotification();

    const [loading, setLoading] = useState(false);

    const orderItemsToMove = useMoveOrderItemsBetweenOrdersStore((state) => state.orderItemsToMove);
    const toOrderId = useMoveOrderItemsBetweenOrdersStore((state) => state.toOrderId);
    const remainingOrderItems = useMoveOrderItemsBetweenOrdersStore((state) => state.remainingOrderItems);

    const selectOrderToMoveOrderItemsDialogState = useMoveOrderItemsBetweenOrdersStore((state) => state.selectOrderToMoveOrderItemsDialogState);
    const clearMoveOrderItemsBetweenOrdersStore = useMoveOrderItemsBetweenOrdersActions((actions) => actions.clearMoveOrderItemsBetweenOrdersStore);
    const openMoveOrderItemsBetweenOrdersDialog = useMoveOrderItemsBetweenOrdersActions((actions) => actions.openMoveOrderItemsBetweenOrdersDialog);
    const closeSelectOrderToMoveOrderItemsDialog = useMoveOrderItemsBetweenOrdersActions((actions) => actions.closeSelectOrderToMoveOrderItemsDialog);

    const pendingOrder = useSelector((state) => state.pos.pendingOrder);

    const onCloseSelectOrderToMoveOrderItemsDialog = () => {
        closeSelectOrderToMoveOrderItemsDialog();
        openMoveOrderItemsBetweenOrdersDialog();
    };

    const moveOrderItems = async () => {
        if (!toOrderId || loading) return;
        setLoading(true);
        const response = await moveOrderItemsBetweenOrdersApi({
            orderItemsToMove: orderItemsToMove?.map((item: CartItemVm) => mapCartItemToOrderItem(item)),
            remainingOrderItems: remainingOrderItems?.map((item: CartItemVm) => mapCartItemToOrderItem(item)),
            toOrderId,
            fromOrderId: pendingOrder?.orderId,
        });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        notification({ message: translate('Orders updated') });
        clearPos();
        clearMoveOrderItemsBetweenOrdersStore();
    };

    return (
        <Dialog open={selectOrderToMoveOrderItemsDialogState.open} onClose={onCloseSelectOrderToMoveOrderItemsDialog} title={translate('Select target table')}>
            <DialogContent>
                <Text size='small' variant='subtitle' className={classes.subtitle}>
                    {translate('Step @step', { step: '2' })}
                </Text>
                <OngoingEatHereOrdersList />
            </DialogContent>
            <DialogActions>
                <Button variant='secondary' onClick={onCloseSelectOrderToMoveOrderItemsDialog} disabled={loading}>
                    {translate('Back')}
                </Button>
                <Button onClick={moveOrderItems} disabled={!toOrderId || loading}>
                    {translate('Move')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    subtitle: {
        textAlign: 'center',
        marginBottom: 10,
    },
}));
