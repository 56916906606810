/**
 * @prettier
 */
import { Typography } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import * as React from 'react';
import { googleMapsConfig } from 'src/config/googleMapsConfig';
import { translate } from 'src/i18n/translate';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';

export function GoogleMapsDriverPosition({ position }: { position: GeoJsonPoint }): React.ReactElement {
    const { isLoaded, loadError } = useLoadScript(googleMapsConfig);

    if (!isLoaded) {
        return <Loader size={20} loading={true} />;
    }

    if (loadError) {
        return <Typography>{translate('Failed to load google maps, please refresh page.')}</Typography>;
    }

    return (
        <GoogleMap
            id='circle-example'
            mapContainerStyle={mapContainerStyle}
            zoom={11}
            center={{
                lng: position.coordinates[0],
                lat: position.coordinates[1],
            }}
            options={{
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                gestureHandling: 'cooperative',
            }}
        >
            <Marker
                position={{
                    lng: position.coordinates[0],
                    lat: position.coordinates[1],
                }}
            />
        </GoogleMap>
    );
}

const mapContainerStyle = {
    height: '400px',
    width: '800px',
} as const;
