/**
 * @prettier
 */
import * as React from 'react';
import { findOrderLivePositionsByCityApi } from 'src/api/letseatadmin/orderLivePosition/findOrderLivePositionsByCityApi';
import { Cities } from 'src/constants/City';
import { SECONDS } from 'src/constants/TimeUnit';
import { RestaurantMarker } from 'src/scenes/letseatadmin/liveOrders/RestaurantMarker';
import { useInterval } from 'src/utils/react/useInterval';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantMarkers(): React.ReactElement {
    const cities = useSelector((state) => state.app2.cities);
    const deliveryStatus = useSelector((state) => state.liveDrivers.deliveryStatus);

    const [loading, restaurantPositions, refreshrestaurantPositions] = useLoadApi(
        findOrderLivePositionsByCityApi,
        { city: cities?.[0] ?? Cities.MONTERREY, deliveryStatus: deliveryStatus },
        { initialValue: [], dependencies: [cities, deliveryStatus] }
    );

    useInterval(refreshrestaurantPositions, 20 * SECONDS, [cities, deliveryStatus]);

    return (
        <>
            {restaurantPositions.map((restaurant) => (
                <RestaurantMarker key={restaurant.restaurantName} restaurant={restaurant} />
            ))}
        </>
    );
}
