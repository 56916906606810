/**
 * @prettier
 */
import type { CartItemVm, ModifierGroupVm as CartModifierGroupVm } from 'src/types/CartItemVm';
import type { ModifierGroupVm, OrderItemVm } from 'src/types/OrderVm';
import type { OrderItemToReturn } from 'src/utils/order/getOrderItemsFormattedToReturn';

export function mapCartItemToOrderItem(cartItem: CartItemVm | OrderItemToReturn): OrderItemVm {
    return {
        menuItemId: cartItem.menuItemId,
        menuCategoryId: cartItem.menuCategoryId,
        menuId: cartItem.menuId,
        name: cartItem.name,
        unitPrice: cartItem.unitPrice,
        promoUnitPrice: cartItem.promoUnitPrice,
        promoText: cartItem.promoText,
        modifierGroups: cartItem.modifierGroups.map(mapCartModifierGroupToOrderModifierGroup),
        note: cartItem.note,
        timesMoved: cartItem.timesMoved,
        orderItemKitchenStatus: cartItem.orderItemKitchenStatus,
        quantity: Number(cartItem.quantity),
        pickupTime: cartItem.pickupTime,
        addedAt: cartItem.addedAt,
        removedAt: cartItem.removedAt,
        itemsByCustomer: cartItem.itemsByCustomer,
        posDiscountPercentage: cartItem.posDiscountPercentage,
        posDiscount: cartItem.posDiscount,
        salesUnit: cartItem.salesUnit,
        currency: cartItem.currency,
        isSoldByWeight: cartItem.isSoldByWeight,
        returned: cartItem.returned,
    };
}

function mapCartModifierGroupToOrderModifierGroup(modifierGroup: CartModifierGroupVm | ModifierGroupVm): ModifierGroupVm {
    return {
        name: modifierGroup.name,
        showModifierGroupNameInCommand: modifierGroup.showModifierGroupNameInCommand,
        freeModifiersQuantity: modifierGroup.freeModifiersQuantity,
        modifiers: modifierGroup.modifiers,
    };
}
