/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { AddressId, DeliveryEstimateId, GooglePlaceId, OrderId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export async function changeDeliveryAddressApi(request: ChangeDeliveryAddressApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('order/changeDeliveryAddressApi', request);
}

export type ChangeDeliveryAddressApiRequest = {
    orderId: OrderId;
    deliveryEstimateId?: DeliveryEstimateId;
    address: {
        addressId?: AddressId;
        location: GeoJsonPoint;
        street: string;
        number: string;
        other?: string;
        instructions?: string;
        googlePlaceId?: GooglePlaceId;
        formattedAddress?: string;
    };
};
