/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import { useMemo, useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { returnOrderApi, ReturnOrderApiRequest } from 'src/api/letseatmanager/order/returnOrderApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormTextAreaField } from 'src/components/form/FormTextAreaField';
import { OrderReturnedType, OrderReturnedTypes } from 'src/constants/OrderReturnedType';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { alertKnownErrorOrSomethingWentWrongError } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrongError';
import { getAmountToReturn } from 'src/utils/order/getAmountToReturn';
import { getOrderItemsFormattedToReturn, OrderItemToReturn } from 'src/utils/order/getOrderItemsFormattedToReturn';
import { mapCartItemToOrderItem } from 'src/utils/pos/mapCartItemToOrderItem';

export function ReturnOrderReasonDialog({ onChange, open, openReturnOrderDialog, onClose, order, returnedOrderItems, orderReturnedType }: Props): React.ReactElement {
    const form = useForm();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [loading, setLoading] = useState(false);

    const amountPaidByCustomer = getAmountToReturn(returnedOrderItems);

    const orderItems = useMemo(() => getOrderItemsFormattedToReturn(order.orderItems), [order]);

    const getOrderItems = () => {
        return orderItems?.reduce((newOrderItems: Array<OrderItemToReturn>, currentOrderItem: OrderItemToReturn) => {
            const returnedOrderItem = returnedOrderItems.find((item) => item.key === currentOrderItem.key);

            if (!returnedOrderItem) return [...newOrderItems, currentOrderItem];

            const quantity = BigNumber(currentOrderItem.quantity).minus(returnedOrderItem.quantity).toNumber();

            if (!quantity) return [...newOrderItems];

            return [...newOrderItems, { ...currentOrderItem, quantity }];
        }, []);
    };

    const handleRefundOrder = async (form: any) => {
        if (!order.orderId) return;
        if (!orderReturnedType) return alertKnownErrorOrSomethingWentWrongError('Select return type');
        if (!returnedOrderItems?.length) return alertKnownErrorOrSomethingWentWrongError('Select any item');

        const request: ReturnOrderApiRequest = {
            orderId: order.orderId,
            orderReturnedReason: form.orderReturnedReason,
            orderReturnedType,
            orderItems: undefined,
            returnedOrderItems: undefined,
        };

        if (orderReturnedType === OrderReturnedTypes.PARTIAL) {
            request.orderItems = getOrderItems()?.map((item) => ({ ...mapCartItemToOrderItem(item), returned: true }));
            request.returnedOrderItems = returnedOrderItems?.map((item: OrderItemToReturn) => ({ ...mapCartItemToOrderItem(item), returned: true }));
        }

        setLoading(true);
        const response = await returnOrderApi(request);
        setLoading(false);

        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);

        onChange();
        onClose();
    };

    const handleBack = () => {
        openReturnOrderDialog();
        onClose();
    };

    return (
        <Dialog open={open} title={translate('Return of products')}>
            <Form onSubmit={handleRefundOrder} form={form}>
                <FormTextAreaField name={'orderReturnedReason'} label={translate('Describe return reason')} />
                <DialogActions>
                    <Button disabled={loading} secondary onClick={handleBack}>
                        {translate('Go Back')}
                    </Button>
                    <Button type={'submit'} disabled={loading}>
                        {translate('Return @amount', { amount: formatAsCurrencyNumber(amountPaidByCustomer) })}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    onChange: any;
    openReturnOrderDialog: any;
    onClose: any;
    returnedOrderItems: Array<OrderItemToReturn>;
    orderReturnedType?: OrderReturnedType;
    order: OrderVm;
};
