/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderId, PaymentLinkId, RestaurantId } from 'src/types/Id';

export async function changePaymentLinkApi(request: PaymentLinkRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('paymentLink/changePaymentLinkApi', request);
}

type PaymentLinkRequest = {
    amount: number;
    restaurantId: RestaurantId;
    paymentLinkId: PaymentLinkId;
    orderId?: OrderId;
};
