/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { RestaurantZoneVm } from 'src/types/RestaurantZoneVm';

export async function createRestaurantZoneApi(request: CreateRestaurantZoneApiRequest): ApiSauceResponse<RestaurantZoneVm> {
    return letseatmanagerApiMethod('pos/createRestaurantZoneApi', request);
}

type CreateRestaurantZoneApiRequest = {
    restaurantId: RestaurantId;
    name: string;
};
