/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { CashRegisterId, ManagerUserId } from 'src/types/Id';
import { PartialCashRegisterShiftReportVm } from 'src/types/PartialCashRegisterShiftReportVm';

export async function getCashRegisterSummaryApi(request: GetManagerUserTipSalesReportApiRequest): ApiSauceResponse<PartialCashRegisterShiftReportVm> {
    return letseatmanagerApiMethod('pos/getCashRegisterSummaryApi', request);
}

type GetManagerUserTipSalesReportApiRequest = {
    cashRegisterId: CashRegisterId;
    managerUserId: ManagerUserId;
};
