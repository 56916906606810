/**
 * @prettier
 */
export const UnitsOfMeasurement = {
    KILOGRAM: 'KILOGRAM',
    GRAM: 'GRAM',
    LITRE: 'LITRE',
    MILLILITER: 'MILLILITER',
    BOX: 'BOX',
    BAG: 'BAG',
    PIECE: 'PIECE',
    PACKAGE: 'PACKAGE',
    STICK: 'STICK',
    ROLL: 'ROLL',
    CAN: 'CAN',
    SLICE: 'SLICE',
    POUND: 'POUND',
    OUNCE: 'OUNCE',
    TABLESPOON: 'TABLESPOON',
    CUP: 'CUP',
    GALLON: 'GALLON',
    QUART: 'QUART',
    CURRENCY: 'CURRENCY',
} as const;

export type UnitOfMeasurement = typeof UnitsOfMeasurement[keyof typeof UnitsOfMeasurement];
