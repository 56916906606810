/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuItemId } from 'src/types/Id';
import type { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';

export async function getMenuItemApi(request: GetMenuItemApiRequest): ApiSauceResponse<ManagerMenuItemVm> {
    return letseatmanagerApiMethod('menuItem/getMenuItemApi', request);
}

export type GetMenuItemApiRequest = {
    menuItemId: MenuItemId;
};
