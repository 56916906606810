/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { DateSelectDialog } from 'src/components/dialog/DateSelectDialog';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Tabs } from 'src/components/Tabs';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { AbcReports } from 'src/scenes/letseatmanager/restaurantDashboard/abcReport/AbcReports';
import { FinanceReport } from 'src/scenes/letseatmanager/restaurantDashboard/financeReport/FinanceReport';
import { GeneralEcommerceReport } from 'src/scenes/letseatmanager/restaurantDashboard/generalEcommerceReport/GeneralEcommerceReport';
import { HeatMapDeliveryOrdersReport } from 'src/scenes/letseatmanager/restaurantDashboard/heatMapDeliveryOrdersReport/HeatMapDeliveryOrdersReport';
import { MenuReport } from 'src/scenes/letseatmanager/restaurantDashboard/menuReport/MenuReport';
import { DayReport } from 'src/scenes/letseatmanager/restaurantDashboard/salesPerDayReport/DayReport';
import { SmartBiReport } from 'src/scenes/letseatmanager/restaurantDashboard/smartBiReport/SmartBiReport';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';
import { isKitchenUser } from 'src/utils/user/isKitchenUser';

export function RestaurantSalesReportPage(): React.ReactElement {
    const classes = useStyles();

    const [userHasRolePermission] = useUserHasRolePermission();

    const webOrdersEnabled = useSelector((state) => state.app.restaurant.webOrdersEnabled);
    const heatMapDeliveryOrdersReportEnabled = useSelector((state) => state.app.restaurant.heatMapDeliveryOrdersReportEnabled);
    const appOrdersEnabled = useSelector((state) => state.app.restaurant.appOrdersEnabled);
    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);
    const uberEatsStoreId = useSelector((state) => state.app.restaurant.uberEatsStoreId);
    const rappiStoreId = useSelector((state) => state.app.restaurant.rappiStoreId);
    const didiFoodStoreId = useSelector((state) => state.app.restaurant.didiFoodStoreId);
    const userType = useSelector((state) => state.authentication.userType);

    const menuEnabled = isKitchenUser(userType) || posEnabled || webOrdersEnabled || appOrdersEnabled || rappiStoreId || uberEatsStoreId || didiFoodStoreId;

    const tabs = [
        {
            value: '1',
            label: translate('By day'),
            rolePermission: RolePermissions.SALES_BY_DAY_REPORT,
        },
        {
            value: '2',
            label: translate('Web menu'),
            hidden: !webOrdersEnabled,
            rolePermission: RolePermissions.WEB_ORDERS_CONVERSIONS_REPORT,
        },
        {
            value: '3',
            label: translate('By menu'),
            hidden: !menuEnabled,
            rolePermission: RolePermissions.SALES_BY_MENU_REPORT,
        },
        {
            value: '4',
            label: translate('ABC analysis'),
            hidden: !menuEnabled,
            rolePermission: RolePermissions.ABC_REPORT,
        },
        {
            value: '5',
            label: translate('Finance'),
            rolePermission: RolePermissions.FINANCE_REPORT,
        },
        {
            value: '6',
            label: translate('Smart BI'),
            rolePermission: RolePermissions.SMART_BI_REPORT,
        },
        {
            value: '7',
            label: translate('By zone'),
            hidden: !heatMapDeliveryOrdersReportEnabled,
            rolePermission: RolePermissions.HEAT_MAP_DELIVERY_ORDERS_REPORT,
        },
    ];

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.REPORTS_PAGE} title={translate('Sales reports')} className={classes.container}>
            <DateSelectDialog />
            <Tabs tabs={tabs}>
                {userHasRolePermission(RolePermissions.SALES_BY_DAY_REPORT) && <DayReport />}
                {webOrdersEnabled && userHasRolePermission(RolePermissions.WEB_ORDERS_CONVERSIONS_REPORT) && <GeneralEcommerceReport />}
                {menuEnabled && userHasRolePermission(RolePermissions.SALES_BY_MENU_REPORT) && <MenuReport />}
                {menuEnabled && userHasRolePermission(RolePermissions.ABC_REPORT) && <AbcReports />}
                {userHasRolePermission(RolePermissions.FINANCE_REPORT) && <FinanceReport />}
                <SmartBiReport />
                <HeatMapDeliveryOrdersReport />
            </Tabs>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
}));
