/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuItemId } from 'src/types/Id';

export async function deprecatedMoveMenuItemUpApi(request: MoveMenuItemUpApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menu/moveMenuItemUpApi', request);
}

type MoveMenuItemUpApiRequest = {
    menuItemId: MenuItemId;
};
