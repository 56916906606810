/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverId } from 'src/types/Id';

export async function updateDriverIdImageApi(request: UploadPrivateImageParams): ApiSauceResponse<void> {
    return letseatadminApiMethod('driver/updateDriverIdImageApi', request);
}

type UploadPrivateImageParams = {
    driverId: DriverId;
    idFrontImageId?: string;
    idBackImageId?: string;
};
