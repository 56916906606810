/**
 * @prettier
 */
import * as React from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { CountryCode, CountryCodes } from 'src/constants/CountryCode';
import { SmsPrio, SmsPrios } from 'src/constants/SmsPrio';
import { SmsServiceProvider, SmsServiceProviders } from 'src/constants/SmsServiceProvider';
import { translate } from 'src/i18n/translate';

export function FormSmsServiceProviderSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, countryCode, smsPrio }: Props): React.ReactElement {
    let smsServiceProviders: Array<SmsServiceProvider>;

    if (countryCode && smsPrio) {
        smsServiceProviders = Object.values(SmsServiceProviders).filter((smsServiceProvider) => smsServiceProviderSupportedInCountryAndForSmsPrio({ smsServiceProvider, countryCode, smsPrio }));
    } else {
        smsServiceProviders = Object.values(SmsServiceProviders);
    }
    const options = required
        ? smsServiceProviders.map((smsServiceProvider: any) => ({ label: translate(`SmsServiceProviders.${smsServiceProvider}`), value: smsServiceProvider }))
        : [{ label: '', value: null }, ...smsServiceProviders.map((smsServiceProvider: any) => ({ label: translate(`SmsServiceProviders.${smsServiceProvider}`), value: smsServiceProvider }))];

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={required}
            variant={variant}
        />
    );
}

function smsServiceProviderSupportedInCountryAndForSmsPrio({ countryCode, smsPrio, smsServiceProvider }: Params): boolean {
    switch (smsServiceProvider) {
        case SmsServiceProviders.AWS:
            return smsPrio !== SmsPrios.LOW_PRIO;
        case SmsServiceProviders.VOICES:
            return countryCode === CountryCodes.MX;
        case SmsServiceProviders.INFOBIP:
            return countryCode === CountryCodes.MX || countryCode === CountryCodes.UY;
        case SmsServiceProviders.MENSAJERO_DIRECTO:
            return countryCode === CountryCodes.UY && smsPrio !== SmsPrios.LOW_PRIO;
        case SmsServiceProviders.TELE_QUANTUM:
            return countryCode === CountryCodes.MX;
    }
}

type Params = {
    smsServiceProvider: SmsServiceProvider;
    countryCode: CountryCode;
    smsPrio: SmsPrio;
};

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: SmsServiceProvider;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    countryCode?: CountryCode;
    smsPrio?: SmsPrio;
};
