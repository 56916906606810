/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { XIcon } from 'src/icons/XIcon';

export function PosPaymentExtraItem({ label, subText, onRemove }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.posPaymentCard}>
            <div>
                <span className={classes.orderResumeText}>{label}</span>
                {subText && <div className={classes.paymentMethodLabel}>{subText}</div>}
            </div>
            {onRemove && (
                <Button icon onClick={onRemove} classes={{ button: classes.iconButton }}>
                    <XIcon title={'close icon'} width={16} height={16} />
                </Button>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    posPaymentCard: {
        fontFamily: theme.typography.regular,
        width: '100%',
        marginBottom: 12,
        borderRadius: 8,
        backgroundColor: '#F6F6F6',
        padding: '10px 20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    backButton: {
        padding: 0,
        width: 'fit-content',
        height: 'fit-content',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'transparent',
        },
    },
    orderResumeText: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#2E3748',
    },
    paymentMethodLabel: {
        color: '#87888B',
        fontSize: 12,
    },
    iconButton: {
        height: 'fit-content',
        width: 'fit-content',
    },
}));

type Props = {
    label: string;
    subText?: string;
    onRemove?: any;
};
