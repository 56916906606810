/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { app2 } from 'src/app2';
import { translate } from 'src/i18n/translate';
import BenefitPlans from 'src/scenes/letseatadmin/company/BenefitPlans';
import CompanyConnectedCustomers from 'src/scenes/letseatadmin/company/CompanyConnectedCustomers2';
import CompanyDetails from 'src/scenes/letseatadmin/company/CompanyDetails';
import CompanyGifts from 'src/scenes/letseatadmin/company/CompanyGifts';
import type { CompanyId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';
import { useParams } from 'src/utils/react/useParams';

export function CompanyPage(): React.ReactElement {
    const { companyId } = useParams<{
        companyId: CompanyId;
    }>();

    const setTitle = useAction(app2.actions.setTitle);

    const handleCompanyChange = (company: any) => {
        setTitle(`${translate('Companies')} > ${company.name}`);
    };

    return (
        <Grid container spacing={5} style={{ paddingBottom: 40 }}>
            <Grid item xs={12}>
                <CompanyDetails companyId={companyId} onCompanyChange={handleCompanyChange} />
            </Grid>
            <Grid item xs={12}>
                <CompanyConnectedCustomers companyId={companyId} />
            </Grid>
            <Grid item xs={12}>
                <BenefitPlans companyId={companyId} />
            </Grid>
            <Grid item xs={12}>
                <CompanyGifts companyId={companyId} />
            </Grid>
        </Grid>
    );
}
