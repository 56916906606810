/**
 * @prettier
 */
import Radio from '@material-ui/core/Radio';
import * as React from 'react';

export default (props: any): React.ReactElement => {
    const {
        input: { name, onChange, checked, ...restInput },
        ...rest
    } = props;

    return <Radio {...rest} name={name} inputProps={restInput} onChange={onChange} checked={checked} />;
};
