/**
 * @prettier
 */
import { makeStyles, Step, StepLabel, Stepper } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import type { CashRegisterPosBusinessDayReportVm } from 'src/types/CashRegisterPosBusinessDayReportVm';
import type { PosBusinessDayReportVm } from 'src/types/PosBusinessDayReportVm';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { classNames } from 'src/utils/react/classNames';

export function CashRegisterShiftsTimeLine({ posBusinessDay }: Props): React.ReactElement {
    const classes = useStyles();

    const [posBusinessDayEvents, setPosBusinessDayEvents] = useState<any>([]);

    useEffect(() => {
        if (posBusinessDay) fillPosBusinessDayEventsInfo();
    }, [posBusinessDay]);

    const fillPosBusinessDayEventsInfo = () => {
        const posBusinessDayEvents = [
            {
                label: translate('Opening'),
                date: posBusinessDay.businessDaySummary.openedAt,
                by: posBusinessDay.businessDaySummary.openedBy,
            },
            ...posBusinessDay.cashRegistersSummaries.map((cashRegister) => ({
                label: translate('Closure'),
                date: cashRegister.closedAt,
                by: cashRegister.closedBy,
            })),
        ];

        if (posBusinessDay.businessDaySummary.closedAt && posBusinessDay.businessDaySummary.closedBy) {
            posBusinessDayEvents.push({
                label: translate('Closing'),
                date: posBusinessDay.businessDaySummary.closedAt,
                by: posBusinessDay.businessDaySummary.closedBy,
            });
        }

        setPosBusinessDayEvents(posBusinessDayEvents);
    };

    return (
        <div className={classes.container}>
            <Stepper orientation='vertical' activeStep={posBusinessDayEvents.length - 1} classes={{ root: classes.stepper }}>
                {posBusinessDayEvents.map((posBusinessDayEvent: any, idx: number) => (
                    <Step key={idx}>
                        <StepLabel classes={{ label: classes.stepLabel }} StepIconComponent={StepIcon}>
                            <div className={classes.row}>
                                <span className={classes.text} style={{ fontSize: 12, marginRight: 5 }}>
                                    {posBusinessDayEvent.label}
                                </span>
                                <span className={classes.textBold} style={{ fontSize: 12 }}>
                                    {formatDateTimeString(posBusinessDayEvent.date)}
                                </span>
                            </div>
                            <span className={classes.text} style={{ fontSize: 12 }}>
                                {translate('by @username', { username: posBusinessDayEvent.by })}
                            </span>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}

function StepIcon({ active }: any) {
    const classes = useStyles();

    return (
        <div className={classNames(classes.stepIcon, active ? classes.stepIconActive : '')}>
            <div></div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        marginLeft: 50,
        minWidth: 200,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    stepper: {
        backgroundColor: 'transparent',
        padding: 0,
    },
    stepLabel: {
        display: 'flex',
        flexDirection: 'column',
    },
    stepIcon: {
        width: 15,
        height: 15,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid #BAC0C2`,
        marginLeft: 6,
        '& > div': {
            width: 7,
            height: 7,
            borderRadius: '50%',
            backgroundColor: '#BAC0C2',
        },
    },
    stepIconActive: {
        border: `1px solid ${theme.palette.primary.main}`,
        '& > div': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    textBold: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
}));

type Props = {
    posBusinessDay: PosBusinessDayReportVm | CashRegisterPosBusinessDayReportVm;
};
