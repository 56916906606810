/**
 * @prettier
 */
import moment from 'moment-timezone';
import type { GiftVm } from 'src/types/GiftVm';

export function isGiftValid(gift: GiftVm): boolean {
    const now = new Date();
    if (gift.validFrom && moment(gift.validFrom).isAfter(now)) {
        return false;
    }
    if (gift.validTo && moment(gift.validTo).isBefore(now)) {
        return false;
    }
    return true;
}
