/**
 * @prettier
 */
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { useSelector } from 'src/utils/react/useSelector';
import { openOrderPageInNewTab } from 'src/utils/window/openOrderPageInNewTab';

export function OpenOrderButton({ order, disabled }: Props): React.ReactElement | null {
    const internalUser = useSelector((state) => state.authentication.internalUser);

    const handleClick = () => openOrderPageInNewTab(order.orderId);

    if (!internalUser) return null;

    return (
        <Button secondary onClick={handleClick} disabled={disabled}>
            {translate('Open order')}
        </Button>
    );
}

type Props = {
    order: OrderVm;
    disabled?: boolean;
};
