/**
 * @prettier
 */
import * as React from 'react';
import { findBrandRestaurantsApi } from 'src/api/letseatmanager/brand/findBrandRestaurantsApi';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import type { RestaurantId } from 'src/types/Id';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function FormBrandRestaurantSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, filter, tooltip }: Props): React.ReactElement {
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, restaurants] = useLoadApi(findBrandRestaurantsApi, { restaurantId }, { initialValue: [] });

    const restaurantsById = () => {
        if (filter?.length) {
            return restaurants.filter((restaurant) => filter?.includes(restaurant.restaurantId));
        }
        return restaurants;
    };

    const options = required
        ? restaurantsById()?.map((restaurant: any) => ({ label: restaurant.name, value: restaurant.restaurantId }))
        : [{ label: '', value: null }, ...restaurantsById()?.map((restaurant: any) => ({ label: restaurant.name, value: restaurant.restaurantId }))];

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled || loading}
            required={required}
            variant={variant}
            tooltipText={tooltip}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    filter?: Array<RestaurantId>;
    onChange?: any;
    tooltip?: string;
};
