/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Dialog } from 'src/components/Dialog';
import type { DeliveryStatus } from 'src/constants/DeliveryStatus';
import type { OrderStatus } from 'src/constants/OrderStatus';
import type { TakeAwayOrderStatus } from 'src/constants/TakeAwayOrderStatus';
import { OrdersPageFilter } from 'src/scenes/letseatmanager/orders/OrdersPageFilter';

export function OrdersPageFilterDialog({ open, currentTab, defaultValues, onClose, onChange }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onClose}>
            <div className={classes.container}>
                <OrdersPageFilter onChange={onChange} currentTab={currentTab} defaultValues={defaultValues} />
            </div>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: 'fit-content',
    },
}));

type Props = {
    open: boolean;
    currentTab: string;
    defaultValues?: {
        from?: Date;
        to?: Date;
        orderStatuses?: Array<OrderStatus>;
        eatHereOrderStatuses?: Array<OrderStatus>;
        takeAwayOrderStatuses?: Array<TakeAwayOrderStatus>;
        deliveryStatuses?: Array<DeliveryStatus>;
    };
    onClose: any;
    onChange: any;
};
