/**
 * @prettier
 */
export const TakeAwayOrderStatuses = {
    ACCEPTED: 'ACCEPTED',
    READY_FOR_PICK_UP: 'READY_FOR_PICK_UP',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
    REJECTED: 'REJECTED',
} as const;

export type TakeAwayOrderStatus = typeof TakeAwayOrderStatuses[keyof typeof TakeAwayOrderStatuses];
