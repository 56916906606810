/**
 * @prettier
 */
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { Currency } from 'src/constants/Currency';
import { useSelector } from 'src/utils/react/useSelector';

export function useFormatAsRestaurantCurrencyNumber(): FormatFunction {
    const country = useSelector((state) => state.app.restaurant.country);

    const handleFormatAsCurrencyNumber = (number: string | number | null | undefined, options?: Options) => {
        return formatAsCurrencyNumber(number, {
            minimumFractionDigits: options?.minimumFractionDigits,
            maximumFractionDigits: options?.maximumFractionDigits,
            country: country,
            currency: options?.currency,
        });
    };

    return handleFormatAsCurrencyNumber;
}

type FormatFunction = (number: string | number | null | undefined, options?: Options) => string;

type Options = {
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    currency?: Currency;
};
