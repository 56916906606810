/**
 * @prettier
 */
import { createPaymentLinkApi } from 'src/api/letseatmanager/paymentLink/createPaymentLinkApi';
import type { OrderId, RestaurantId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { copyTextToClipboard } from 'src/utils/html/copyTextToClipboard';

export async function posPaymentCreatePaymentLink({ orderId, totalWithDeliveryCost, restaurantId }: Props): Promise<void> {
    const response = await createPaymentLinkApi({
        amount: parseFloat(totalWithDeliveryCost),
        restaurantId,
        orderId,
    });
    if (!response.ok) {
        alertKnownErrorOrSomethingWentWrong(response);
        return;
    }
    copyTextToClipboard(response.data.url);
}

type Props = {
    orderId: OrderId;
    restaurantId: RestaurantId;
    totalWithDeliveryCost: string;
};
