/**
 * @prettier
 */
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { Alert, AlertTitle } from '@material-ui/lab';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { PageContext } from 'src/components/Page';
import 'src/constants/LogEventType';
import { Moment } from 'moment';
import { translate } from 'src/i18n/translate';
import { createUserOpenedAlertDialogLogEvent } from 'src/services/logEvent/createUserOpenedAlertDialogLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';
import { useSelector } from 'src/utils/react/useSelector';

export function AlertDialog({ title, open, severity, children }: Props): React.ReactElement | null {
    const classes = useStyles();
    const [closedUntil, setClosedUntil] = useState<Moment>();
    const pageContext = useContext(PageContext);

    const signedIn = useSelector((state) => state.authentication.signedIn);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    useEffect(() => {
        if (!open) setClosedUntil(undefined);
        if (open) {
            createUserOpenedAlertDialogLogEvent({ title, pageContext });
            pageContext.clearStackTrace();
        }
    }, [open]);

    useEffect(() => {
        if (!signedIn) setClosedUntil(undefined);
    }, [signedIn]);

    const handleClickClosedByUser = () => {
        setClosedUntil(moment().add(1, 'days'));
    };

    const addAlertDialogToStackTrace = () => {
        pageContext.addElementToStackTrace(normalizeUiStackTrace(`alert_dialog_${title}`));
    };

    if (!open) return null;
    if (closedUntil && moment().isBefore(closedUntil)) return null;

    return (
        <Alert
            severity={severity}
            classes={{ root: classes.alertMessage, action: classes.actionIcon }}
            action={
                <IconButton onClick={handleClickClosedByUser} className={classes.iconButton}>
                    <ClearIcon className={classes.closeIcon} />
                </IconButton>
            }
        >
            <AlertTitle
                classes={{ root: classes.alertTitle }}
                /* @ts-ignore */
                action={<IconButton aria-label='close' color='inherit' size='small'></IconButton>}
            >
                {translate(title)}
            </AlertTitle>

            <div onClickCapture={addAlertDialogToStackTrace}>{children}</div>
        </Alert>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        '& h2': {
            fontFamily: theme.typography.semiBold,
            fontSize: 18,
        },
    },
    alertTitle: {
        fontFamily: theme.typography.semiBold,
    },
    alertMessage: {
        fontFamily: theme.typography.medium,
        width: 'fit-content',
        border: '1px solid #ddd',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
        zIndex: 10,
    },
    closeIcon: {
        '&:hover': {
            color: '#6A887D',
        },
    },
    actionIcon: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
    },
    iconButton: {
        padding: 6,
        margin: 0,
    },
}));

type Props = {
    title: string;
    children?: React.ReactNode;
    open: boolean;
    severity: 'warning' | 'info' | 'error' | 'success';
};
