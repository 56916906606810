/**
 * @prettier
 */
export const DynamicDeliveryEarningsReasons = {
    WEATHER: 'WEATHER',
    DISTANCE: 'DISTANCE',
    LONG_TIME_OUT_TO_ASSIGN: 'LONG_TIME_OUT_TO_ASSIGN',
    NOT_DRIVERS_IN_THE_ZONE: 'NOT_DRIVERS_IN_THE_ZONE',
} as const;

export type DynamicDeliveryEarningReason = typeof DynamicDeliveryEarningsReasons[keyof typeof DynamicDeliveryEarningsReasons];
