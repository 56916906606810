/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Input } from '@pidedirecto/ui';
import * as React from 'react';
import { memo } from 'react';
import { translate } from 'src/i18n/translate';
import { usePosCustomerActions, usePosCustomerStore } from 'src/modules/pos/customer/posCustomerStore';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { useSelector } from 'src/utils/react/useSelector';

export const PosCustomerFirstNameInput: React.ComponentType<any> = memo<any>(function PosCustomerFirstNameInput(): React.ReactElement {
    const classes = useStyles();

    const posCustomerNameRequiredOnTakeAwayOrdersEnabled = useSelector((state) => state.pos.context?.posCustomerNameRequiredOnTakeAwayOrdersEnabled);
    const orderType = useSelector((state) => state.pos.orderType);
    const requestCustomerNameOnCreateOrderEnabled = useSelector((state) => state.pos.context?.requestCustomerNameOnCreateOrderEnabled);

    const customerNameRequired = (posCustomerNameRequiredOnTakeAwayOrdersEnabled && isTakeAwayOrder(orderType)) || requestCustomerNameOnCreateOrderEnabled;

    const firstName = usePosCustomerStore((state) => state.firstName.value);
    const firstNameError = usePosCustomerStore((state) => state.firstName.error);

    const setCustomerFirstName = usePosCustomerActions((actions) => actions.setCustomerFirstName);
    const setError = usePosCustomerActions((actions) => actions.setError);

    const onChangeFirstName = (value: string) => {
        setCustomerFirstName(value);
        setError('firstName', customerNameRequired && !value ? translate('This field is required') : undefined);
    };

    return (
        <Input
            classes={{ container: classes.input }}
            name='firstName'
            required={customerNameRequired}
            label={translate('First Name')}
            value={firstName}
            onChange={onChangeFirstName}
            error={!!firstNameError}
        />
    );
});

const useStyles = makeStyles((theme) => ({
    input: {
        width: '100%',
    },
}));
