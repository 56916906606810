/**
 * @prettier
 */
import { IconButton, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Apps } from 'src/constants/App';
import { translate } from 'src/i18n/translate';
import { ClipboardIcon } from 'src/icons/ClipboardIcon';
import type { OrderVm } from 'src/types/OrderVm';
import { copyTextToClipboard } from 'src/utils/html/copyTextToClipboard';
import { formatAsInternationalPhoneNumber } from 'src/utils/string/formatAsInternationalPhoneNumber';

export function OrderCardCustomerInfo({ order }: Props): React.ReactElement | null {
    const classes = useStyles();

    const hasCustomerInfo = order.customerName || order.customerMobileNumber;
    const isExternalDeliveryOrderFromDidi = order.app === Apps.DIDI_FOOD && order.deliverExternalOrder;
    const showCustomerAddress = order.externalDelivery || order.manualOrder || isExternalDeliveryOrderFromDidi;

    if (!hasCustomerInfo) return null;

    return (
        <div>
            <Typography className={classes.text} style={{ fontWeight: 600, marginBottom: 12 }}>
                {translate('Client')}
            </Typography>
            {order.customerName && (
                <div className={classes.spaceBetween}>
                    <div className={classes.text}>{order.customerName}</div>
                    <IconButton style={{ padding: 4 }} onClick={() => copyTextToClipboard(order.customerName)}>
                        <ClipboardIcon title={'customerNameClip'} />
                    </IconButton>
                </div>
            )}
            {order.customerMobileNumber && (
                <div className={classes.spaceBetween}>
                    <div className={classes.text}>{formatAsInternationalPhoneNumber(order.customerMobileNumber)}</div>
                    <IconButton style={{ padding: 4 }} onClick={() => copyTextToClipboard(order.customerMobileNumber)}>
                        <ClipboardIcon />
                    </IconButton>
                </div>
            )}
            {order.customerNote && (
                <div className={classes.spaceBetween}>
                    <div>
                        <p className={classes.text}>{translate('Customer notes')}:</p>
                        <p className={classes.text}>{order.customerNote}</p>
                    </div>
                </div>
            )}
            {showCustomerAddress && (
                <div className={classes.row}>
                    <div className={classes.column}>
                        {order.address && (
                            <div className={classes.spaceBetween}>
                                <div className={classes.text}>
                                    {translate('Street')} {order.address?.street} #{order.address?.number}
                                </div>
                                <IconButton style={{ padding: 4 }} onClick={() => copyTextToClipboard(`${translate('Street')} ${order.address?.street ?? ''} ${order.address?.number ?? ''}`)}>
                                    <ClipboardIcon />
                                </IconButton>
                            </div>
                        )}
                        {!!order.address?.other && (
                            <span className={classes.text}>
                                {translate('Staircase/apartment/floor/other')}: {order.address?.other}
                            </span>
                        )}
                        {!!order.address?.instructions && (
                            <span className={classes.text}>
                                {translate('Instructions')}: {order.address?.instructions}
                            </span>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    separator: {
        height: '0px',
        width: '100%',
        border: '1px solid #F3F3F3',
        margin: '12px 0px',
    },

    text: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#454A4A',
    },

    spaceBetween: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
}));

type Props = {
    order: OrderVm;
};
