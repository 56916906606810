/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { SwipeDrawer } from 'src/components/SwipeDrawer';
import { MenuCategory } from 'src/scenes/letseatmanager/menu/menuCategory/MenuCategory';
import type { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export function MenuCategorySection({ menuCategory, onClose }: Props): React.ReactElement {
    const classes = useStyles();
    const isSmallScreen = useIsSmallScreen();

    if (isSmallScreen) {
        return (
            <SwipeDrawer open={true} onClose={onClose} avoidSwipeInDrawerContent classes={{ drawer: classes.drawer }}>
                <MenuCategory menuCategory={menuCategory} />
            </SwipeDrawer>
        );
    }

    return <MenuCategory menuCategory={menuCategory} />;
}

const useStyles = makeStyles((theme) => ({
    drawer: {
        height: '100%',
    },
}));

type Props = {
    menuCategory: MenuCategoryVm;
    onClose: any;
};
