/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button, DropDownItem, Text } from '@pidedirecto/ui';
import { RefreshIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { useSelector } from 'src/utils/react/useSelector';

export function RightToolBarUpdateChangesContextualMenuItem(): React.ReactElement {
    const classes = useStyles();

    const { reloadRestaurant } = useReloadRestaurant();
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const handleClickUpdateChanges = () => {
        reloadRestaurant(restaurantId);
    };

    return (
        <DropDownItem classes={{ container: classes.container }}>
            <Button variant='text' onClick={handleClickUpdateChanges} classes={{ button: classes.button }}>
                <div className={classes.iconContainer}>
                    <RefreshIcon size={24} style={{ flexShrink: 0 }} />
                </div>
                <Text>{translate('Update Changes')}</Text>
            </Button>
        </DropDownItem>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 42,
        minWidth: 250,
    },
    iconContainer: {
        minWidth: 50,
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        width: '100%',
        justifyContent: 'flex-start',
        textDecoration: 'none !important',
    },
}));
