/**
 * @prettier
 */
export const RestaurantStatuses = {
    LIVE: 'LIVE',
    COMING_SOON: 'COMING_SOON',
    INACTIVE: 'INACTIVE',
} as const;

export type RestaurantStatus = typeof RestaurantStatuses[keyof typeof RestaurantStatuses];
