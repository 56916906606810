/**
 * @prettier
 * @flow
 */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent } from '@pidedirecto/ui';
import { Form, FormTextField } from '@pidedirecto/ui/form';
import { useForm } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { changeCustomAlertDialogApi } from 'src/api/letseatadmin/customAlertDialog/changeCustomAlertDialogApi';
import { FormSecondsToAcceptCustomAlertSelect } from 'src/components/form/FormSecondsToAcceptCustomAlertSelect';
import { FormShowAlertTypeCustomAlertSelect } from 'src/components/form/FormShowAlertTypeCustomAlertSelect';
import { CustomAlertDialogType } from 'src/constants/CustomAlertDialogType';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export default function ChangeCustomAlertDialog({ open, onClose, customAlertDialog, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (customAlertDialog) {
            form.reset({
                title: customAlertDialog.title,
                text: customAlertDialog.text,
                secondsToAccept: customAlertDialog.secondsToAccept,
                customAlertDialogType: customAlertDialog.customAlertDialogType,
            });
        }
    }, [customAlertDialog]);

    const submitChangeCustomAlertDialog = async (form: FormType) => {
        setLoading(true);
        const response = await changeCustomAlertDialogApi({
            customAlertDialogId: customAlertDialog.customAlertDialogId,
            title: form.title,
            text: form.text,
            secondsToAccept: form.secondsToAccept,
            customAlertDialogType: form.customAlertDialogType,
        });
        setLoading(false);
        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        onSuccess();
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} loading={loading} classes={{ dialog: classes.dialog }} title={translate('Change Custom Alert Dialog')}>
            <Form onSubmit={submitChangeCustomAlertDialog} form={form}>
                <DialogContent className={classes.bodyText}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <FormTextField name='title' label={translate('Title')} disabled={loading} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='text' label={translate('Text')} disabled={loading} required />
                        </Grid>
                        <Grid item xs={12} className={classes.selectInput}>
                            <FormSecondsToAcceptCustomAlertSelect label={translate('Seconds to accept')} name='secondsToAccept' disabled={loading} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormShowAlertTypeCustomAlertSelect label={translate('Show alert type')} name='customAlertDialogType' disabled={loading} required />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.buttonsContainer}>
                    <Button variant={'secondary'} onClick={onClose} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {loading ? translate('Changing') : translate('Change')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    bodyText: {
        fontFamily: theme.typography.medium,
        color: theme.palette.secondary.contrastText,
        overflowY: 'scroll',
    },
    inputContainer: {
        height: '90%',
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dialog: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 12,
        width: '80%',
        maxWidth: '80%',
        maxHeight: '95%',
    },
    selectInput: {
        display: 'flex',
        width: '50%',
        height: '50%',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}));

type Props = {
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
    customAlertDialog: any;
};

type FormType = {
    title: string;
    text: string;
    secondsToAccept: string;
    customAlertDialogType: CustomAlertDialogType;
};
