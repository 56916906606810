/**
 * @prettier
 */
import { TicketSection, TicketSections } from 'src/constants/TicketSection';
import type { FormActiveTicketSections } from 'src/types/FormActiveTicketSections';

export function mapTicketSectionsToFormTicketSections(sections: Array<TicketSection>): FormActiveTicketSections {
    const ticketSections: Array<TicketSection> = Object.values(TicketSections);
    const mappedSections: Record<string, any> = {};

    for (const ticketKey of ticketSections) {
        if (sections?.includes(ticketKey)) {
            mappedSections[ticketKey] = true;
            continue;
        }
        mappedSections[ticketKey] = false;
    }

    return mappedSections;
}
