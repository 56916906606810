/**
 * @prettier
 */
import { RoutePaths } from 'src/constants/RoutePath';
import type { DriverId } from 'src/types/Id';
import { RegExps } from 'src/utils/RegExps';

export function openDriverPageInNewTab(driverId?: DriverId): void {
    if (!driverId) return;
    window.open(RoutePaths.ADMIN_DRIVER.replace(`:driverId(${RegExps.uuid})`, driverId), '_blank');
}
