/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Accordion } from 'src/components/Accordion';
import { Button } from 'src/components/Button';
import { FormTimePicker } from 'src/components/form/FormTimePicker';
import type { Day } from 'src/constants/Day';
import { translate } from 'src/i18n/translate';
import { CloseIcon } from 'src/icons/CloseIcon';
import { OpeningHoursContext } from 'src/scenes/letseatmanager/openingHours/OpeningHoursForm';
import { transformHoursToWeekOpeningHours } from 'src/utils/hours/transformHoursToWeekOpeningHours';
import { useSelector } from 'src/utils/react/useSelector';

export function OpeningHoursForDay({ day }: Props): React.ReactElement {
    const name = `openingHours${day}`;

    const classes = useStyles();
    const context = useContext(OpeningHoursContext);
    const hours = context.hours;
    const { control, watch } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
    });

    const hideOpeningHoursEditionEnabled = useSelector((state) => state.app.restaurant?.hideOpeningHoursEditionEnabled);
    const internalUser = useSelector((state) => state.authentication.internalUser);

    const editionDisabled = hideOpeningHoursEditionEnabled && !internalUser;

    const openingHours = watch(name);

    useEffect(() => {
        setTimeout(() => resetForm(), 100);
    }, [hours]);

    useEffect(() => {
        updateRestaurantOpeningHours();
    }, [day, openingHours]);

    const resetForm = () => {
        const restaurantOpeningHours = transformHoursToWeekOpeningHours(hours);
        const openingHours = restaurantOpeningHours.find((openingHours) => openingHours.day === day);
        if (!openingHours?.openingHours.length) return addOpeningHours();

        openingHours.openingHours.forEach(addOpeningHours);
    };

    const updateRestaurantOpeningHours = () => {
        const currentOpeningHours = openingHours ?? [];
        context.setDayOpeningHours(
            day,
            currentOpeningHours.map((openingHour: OpeningHours) => ({ openingTime: openingHour.openingTime || null, closingTime: openingHour.closingTime || null }))
        );
    };

    const addOpeningHours = (openingHours?: OpeningHours) => append({ openingTime: openingHours?.openingTime ?? null, closingTime: openingHours?.closingTime ?? null });

    return (
        <Accordion title={translate(`Days.${day}`)} defaultOpened>
            <div className={classes.container}>
                {fields.map((field, idx) => (
                    <div className={classes.openingHours} key={field.id}>
                        <FormTimePicker name={`${name}.${idx}.openingTime`} label={translate('Opening')} defaultValue={field.openingTime} disabled={editionDisabled} />
                        <FormTimePicker name={`${name}.${idx}.closingTime`} label={translate('Closing')} defaultValue={field.closingTime} disabled={editionDisabled} />
                        {idx > 0 && (
                            <Button icon onClick={() => remove(idx)} classes={{ button: classes.iconButton }} disabled={editionDisabled}>
                                <CloseIcon />
                            </Button>
                        )}
                        {idx === 0 && <div className={classes.emptySpace}></div>}
                    </div>
                ))}
                <div className={classes.addScheduleButton}>
                    <Button outlined onClick={addOpeningHours} disabled={editionDisabled}>
                        {translate('Add schedule')}
                    </Button>
                </div>
            </div>
        </Accordion>
    );
}

type Props = {
    day: Day;
};

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        marginTop: 20,
    },
    openingHours: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: 10,
        width: '100%',
    },
    addScheduleButton: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingRight: 36,
    },
    iconButton: {
        width: 26,
        height: 26,
        minHeight: 26,
        padding: 4,
        marginTop: 26,
        '& svg': {
            width: 22,
            height: 22,
        },
    },
    emptySpace: {
        width: 26,
        display: 'flex',
        flexShrink: 0,
    },
}));

type OpeningHours = {
    openingTime: string;
    closingTime: string;
};
