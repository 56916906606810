/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useNotification } from '@pidedirecto/ui/hooks';
import { LockIcon } from '@pidedirecto/ui/icons';
import { useState } from 'react';
import * as React from 'react';
import { isAbleToCloseCashRegisterPosBusinessDayApi } from 'src/api/letseatmanager/pos/isAbleToCloseCashRegisterPosBusinessDayApi';
import { isAbleToClosePosBusinessDayApi } from 'src/api/letseatmanager/pos/isAbleToClosePosBusinessDayApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { AssignCashRegisterForExternalMissingOrdersDialog } from 'src/components/pos/cashRegister/AssignCashRegisterForExternalMissingOrdersDialog';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import type { UnableToCloseCashRegisterPosBusinessDayReason } from 'src/constants/UnableToCloseCashRegisterPosBusinessDayReason';
import { UnableToClosePosBusinessDayReasons, type UnableToClosePosBusinessDayReason } from 'src/constants/UnableToClosePosBusinessDayReason';
import { translate } from 'src/i18n/translate';
import { CashRegisterShiftIcon } from 'src/icons/CashRegisterShiftIcon';
import { IncomingIcon } from 'src/icons/IncomingIcon';
import { WithdrawalIcon } from 'src/icons/WithdrawalIcon';
import { posReducer } from 'src/reducers/posReducer';
import { DepositCashRegisterDialog } from 'src/scenes/letseatmanager/posCashRegister/DepositCashRegisterDialog';
import { UnableToClosePosBusinessDayWithPendingOrdersDialog } from 'src/scenes/letseatmanager/posCashRegister/UnableToClosePosBusinessDayWithPendingOrdersDialog';
import { WithdrawCashRegisterDialog } from 'src/scenes/letseatmanager/posCashRegister/WithdrawCashRegisterDialog';
import { useFindOpenedUserCashRegisterPosBusinessDay } from 'src/services/cashRegister/useFindOpenedUserCashRegisterPosBusinessDay';
import type { CashRegisterVm } from 'src/types/CashRegisterVm';
import { CashRegisterPosBusinessDayVm } from 'src/types/PosBusinessDayVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PosCashRegisterActions({ cashRegister, refreshCashRegisterTransactions, refreshPosBusinessDaySummary }: Props): React.ReactElement {
    const classes = useStyles();
    const notification = useNotification();
    const { findUserCashRegisterPosBusinessDay } = useFindOpenedUserCashRegisterPosBusinessDay();

    const [depositCashRegisterDialogState, setDepositCashRegisterDialogState] = useState({ open: false });
    const [withdrawCashRegisterDialogState, setWithdrawCashRegisterDialogState] = useState({ open: false });
    const [unableToCloseCashRegisterDialogState, setUnableToCloseCashRegisterDialogState] = useState(false);
    const [assignCashRegisterForMissingOrdersDialogState, setAssignCashRegisterForMissingOrdersDialogState] = useState<{ open: boolean; onSuccess: any }>({ open: false, onSuccess: undefined });

    const online = useSelector((state) => state.app2.online);
    const openedCashRegister = useSelector((state) => state.pos.openedCashRegister);
    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const openedCashRegisterPosBusinessDay = useSelector((state) => state.pos.openedCashRegisterPosBusinessDay);
    const requestPasswordForClosePosBusinessDayEnabled = useSelector((state) => state.app.restaurant?.requestPasswordForClosePosBusinessDayEnabled);
    const assignCashRegisterForExternalMissingOrdersEnabled = useSelector((state) => state.app.restaurant?.assignCashRegisterForExternalMissingOrdersEnabled);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);

    const openCashRegisterReport = useAction(posReducer.actions.openCashRegisterReport);
    const openVerifyPosPasswordDialog = useAction(app2.actions.openVerifyPosPasswordDialog);

    const cashRegisterPosBusinessDay = findUserCashRegisterPosBusinessDay();
    const hasSomeCashRegisterPosBusinessDayOpened = openedPosBusinessDay?.cashRegisterPosBusinessDays?.some(
        (cashRegisterPosBusinessDay: CashRegisterPosBusinessDayVm) =>
            cashRegisterPosBusinessDay.opened && cashRegisterPosBusinessDay.cashRegisterPosBusinessDayId !== openedCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId
    );

    const handleOpenPosBusinessDayCloseReport = async () => {
        if (!openedPosBusinessDay || !cashRegister) {
            notification({ message: translate('Pos business day not found') });
            return;
        }

        if (posMultipleCashRegistersEnabled) {
            await handleValidatePosCashRegisterBusinessDay();
            return;
        }

        await handleValidatePosBusinessDay();
    };

    const handleValidatePosBusinessDay = async () => {
        const response = await isAbleToClosePosBusinessDayApi({
            posBusinessDayId: openedPosBusinessDay.posBusinessDayId,
        });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        if (!response.data.isAbleToClosePosBusinessDay) {
            showUnableAbleToClosePosBusinessDayNotification(response.data.unableToClosePosBusinessDayReason!);
            return;
        }

        validateToOpenPosBusinessDayCloseReport();
    };

    const handleValidatePosCashRegisterBusinessDay = async () => {
        const response = await isAbleToCloseCashRegisterPosBusinessDayApi({
            posBusinessDayId: openedPosBusinessDay.posBusinessDayId,
            cashRegisterId: openedCashRegister?.cashRegisterId,
            cashRegisterPosBusinessDayId: cashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId,
        });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        if (!response.data.isAbleToCloseCashRegisterPosBusinessDay) {
            showUnableAbleToClosePosBusinessDayNotification(response.data.unableToCloseCashRegisterPosBusinessDayReason!);
            return;
        }

        validateToOpenPosBusinessDayCloseReport();
    };

    const showUnableAbleToClosePosBusinessDayNotification = (unableToClosePosBusinessDayReason: UnableToClosePosBusinessDayReason | UnableToCloseCashRegisterPosBusinessDayReason) => {
        switch (unableToClosePosBusinessDayReason) {
            case UnableToClosePosBusinessDayReasons.THERE_ARE_ONGOING_INTEGRATION_ORDERS:
                setUnableToCloseCashRegisterDialogState(true);
                notification({ message: translate('You have pending integration orders') });
                break;
            case UnableToClosePosBusinessDayReasons.THERE_ARE_ONGOING_ORDERS_WITH_EXTERNAL_DELIVERY:
                setUnableToCloseCashRegisterDialogState(true);
                notification({ message: translate('You have pending deliveries') });
                break;
            case UnableToClosePosBusinessDayReasons.THERE_ARE_ONGOING_ORDERS:
                setUnableToCloseCashRegisterDialogState(true);
                break;
            case UnableToClosePosBusinessDayReasons.THERE_ARE_COMPLETED_ORDERS_NOT_INVOICED:
                notification({ message: translate('Is not possible to close day due to there are orders not invoiced.') });
                break;
            default:
                break;
        }
    };

    const validateToOpenPosBusinessDayCloseReport = () => {
        if (assignCashRegisterForExternalMissingOrdersEnabled && hasSomeCashRegisterPosBusinessDayOpened && openedCashRegisterPosBusinessDay?.isForExternalMissingOrders) {
            notification({
                message: translate('This cash register is assigned for auto accepting orders, select another cash register for auto accepting orders'),
            });
            setAssignCashRegisterForMissingOrdersDialogState({
                open: true,
                onSuccess: () => {
                    refreshPosBusinessDaySummary?.();
                    openPosBusinessDayCloseReport();
                },
            });
            return;
        }

        openPosBusinessDayCloseReport();
    };

    const openPosBusinessDayCloseReport = () => {
        if (!requestPasswordForClosePosBusinessDayEnabled) {
            openCashRegisterReport({ cashRegister, isEndOfDayReport: true });
            return;
        }

        openVerifyPosPasswordDialog({
            onAuthorized: () => openCashRegisterReport({ cashRegister, isEndOfDayReport: true }),
            onUnauthorized: () => alert(translate('Unauthorized to access.')),
        });
    };

    const closeCashRegister = () => {
        if (!cashRegister || !openedPosBusinessDay) {
            notification({ message: translate('Pos business day not found') });
            return;
        }
        openCashRegisterReport({ cashRegister, isEndOfDayReport: false });
    };

    const closeDepositCashRegisterDialog = () => {
        setDepositCashRegisterDialogState({ open: false });
        refreshCashRegisterTransactions();
    };

    const closeWithdrawCashRegisterDialog = () => {
        setWithdrawCashRegisterDialogState({ open: false });
        refreshCashRegisterTransactions();
    };

    return (
        <div className={classes.buttonsContainer}>
            <AssignCashRegisterForExternalMissingOrdersDialog
                open={assignCashRegisterForMissingOrdersDialogState.open}
                onSuccess={assignCashRegisterForMissingOrdersDialogState.onSuccess}
                onClose={() => setAssignCashRegisterForMissingOrdersDialogState({ open: false, onSuccess: undefined })}
            />
            <DepositCashRegisterDialog open={depositCashRegisterDialogState.open} cashRegisterId={cashRegister?.cashRegisterId} handleClose={closeDepositCashRegisterDialog} />
            <WithdrawCashRegisterDialog open={withdrawCashRegisterDialogState.open} cashRegisterId={cashRegister?.cashRegisterId} handleClose={closeWithdrawCashRegisterDialog} />
            <UnableToClosePosBusinessDayWithPendingOrdersDialog open={unableToCloseCashRegisterDialogState} onClose={() => setUnableToCloseCashRegisterDialogState(false)} />
            <SecuredContent rolePermission={RolePermissions.DEPOSIT_CASH_REGISTER}>
                <Button classes={{ button: classes.button }} secondary onClick={() => setDepositCashRegisterDialogState({ open: true })} disabled={!online}>
                    <div className={classes.iconContainer}>
                        <IncomingIcon />
                    </div>

                    <div className={classes.buttonText}>{translate('Deposit')}</div>
                </Button>
            </SecuredContent>
            <SecuredContent rolePermission={RolePermissions.WITHDRAW_CASH_REGISTER}>
                <Button classes={{ button: classes.button }} secondary onClick={() => setWithdrawCashRegisterDialogState({ open: true })} disabled={!online}>
                    <div className={classes.iconContainer}>
                        <WithdrawalIcon />
                    </div>

                    <div className={classes.buttonText}>{translate('Withdraw')}</div>
                </Button>
            </SecuredContent>
            <SecuredContent rolePermission={RolePermissions.CASH_REGISTER_SHIFT}>
                <Button classes={{ button: classes.button }} secondary onClick={closeCashRegister} disabled={!online}>
                    <div className={classes.iconContainer}>
                        <CashRegisterShiftIcon />
                    </div>

                    <div className={classes.buttonText}>{translate('Shift Report')}</div>
                </Button>
            </SecuredContent>
            <SecuredContent rolePermission={RolePermissions.CASH_REGISTER_CLOSE}>
                <Button classes={{ button: classes.button }} secondary onClick={handleOpenPosBusinessDayCloseReport} disabled={!online}>
                    <div className={classes.iconContainer}>
                        <LockIcon />
                    </div>

                    <div className={classes.buttonText}>{translate('Close Cash Register')}</div>
                </Button>
            </SecuredContent>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        display: 'flex',
        flexDirection: 'column',
        height: 140,
        width: '100%',
        borderRadius: 8,
        textTransform: 'none',
        backgroundColor: theme.palette.surface.primary,
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.surface.secondary}`,
        '&:hover': {
            border: `1px solid ${theme.palette.surface.brandContrast}`,
            backgroundColor: theme.palette.surface.brand,
        },
    },
    buttonText: {
        fontSize: 16,
        fontFamily: theme.typography.regular,
    },
    buttonsContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: 10,
        [theme.breakpoints.up('sm')]: {
            gap: 20,
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 64,
        height: 64,
        borderRadius: '50%',
        backgroundColor: theme.palette.surface.brandSecondary,
        color: theme.palette.text.brand,
    },
}));

type Props = {
    cashRegister: CashRegisterVm;
    refreshCashRegisterTransactions: any;
    refreshPosBusinessDaySummary?: any;
};
