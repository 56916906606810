/**
 * @prettier
 */
import { useState } from 'react';
import * as React from 'react';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { ReturnOrderDialog } from 'src/components/orderCard/returnOrder/ReturnOrderDialog';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { isMexico } from 'src/utils/country/isMexico';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isIntegrationOrder } from 'src/utils/order/isIntegrationOrder';
import { isPideDirectoDelivery } from 'src/utils/order/isPideDirectoDelivery';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function ReturnOrderButton({ order, onSuccess }: Props): React.ReactElement | null {
    const [returnOrderDialogState, setReturnOrderDialogState] = useState({ open: false });

    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);

    const openVerifyPosPasswordDialog = useAction(app2.actions.openVerifyPosPasswordDialog);

    const mexicanInvoicedOrder = isMexico(order.country) && order.invoiced;
    const shouldRenderButton = isPideDirectoDelivery(order) && !isIntegrationOrder(order) && isCompletedOrder(order.orderStatus) && !mexicanInvoicedOrder;

    const handleReturnOrder = () => {
        if (!posEnabled) {
            openReturnOrderDialog();
            return;
        }

        openVerifyPosPasswordDialog({
            onAuthorized: () => {
                openReturnOrderDialog();
            },
            onUnauthorized: () => alert(translate('Unauthorized to access.')),
        });
    };

    const openReturnOrderDialog = () => setReturnOrderDialogState({ open: true });

    const closeReturnOrderDialog = () => setReturnOrderDialogState({ open: false });

    if (!shouldRenderButton) return null;

    return (
        <div>
            <ReturnOrderDialog open={returnOrderDialogState.open} onClose={closeReturnOrderDialog} order={order} onChange={() => onSuccess?.(order)} openReturnOrderDialog={openReturnOrderDialog} />
            <Button secondary onClick={handleReturnOrder}>
                {translate('Return Order')}
            </Button>
        </div>
    );
}

type Props = {
    order: OrderVm;
    onSuccess?: any;
};
