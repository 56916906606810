/**
 * @prettier
 */
import * as React from 'react';

export function CampaignIcon({ title, color }: Props): React.ReactElement {
    return (
        <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                d='M12.3807 14.735C13.9334 14.5512 15.459 14.1847 16.9257 13.6433C15.6836 12.2673 14.9972 10.4787 14.9999 8.625V8.04167V8C14.9999 6.67392 14.4731 5.40215 13.5354 4.46447C12.5978 3.52678 11.326 3 9.99991 3C8.67383 3 7.40206 3.52678 6.46438 4.46447C5.52669 5.40215 4.99991 6.67392 4.99991 8V8.625C5.00242 10.4789 4.31573 12.2675 3.07324 13.6433C4.51741 14.1767 6.03991 14.5475 7.61908 14.735M12.3807 14.735C10.7991 14.9226 9.20075 14.9226 7.61908 14.735M12.3807 14.735C12.5008 15.1099 12.5307 15.5078 12.4679 15.8964C12.4051 16.285 12.2514 16.6533 12.0194 16.9713C11.7873 17.2893 11.4835 17.548 11.1326 17.7263C10.7816 17.9047 10.3936 17.9976 9.99991 17.9976C9.60626 17.9976 9.21818 17.9047 8.86725 17.7263C8.51632 17.548 8.21247 17.2893 7.98043 16.9713C7.74838 16.6533 7.59471 16.285 7.53192 15.8964C7.46913 15.5078 7.49899 15.1099 7.61908 14.735'
                stroke={color ?? '#154A61'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    color?: string;
};
