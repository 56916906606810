/**
 * @prettier
 */
import { Grid, makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import { useEffect, useState } from 'react';
import * as React from 'react';
import type { SalesByPaymentMethod } from 'src/api/letseatmanager/restaurantDashboard/getGeneralRestaurantStatisticsApi';
import { PieChartCanvas } from 'src/components/charts/PieChartCanvas';
import { App } from 'src/constants/App';
import { PaymentMethod } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { translatePaymentMethod } from 'src/utils/translate/translatePaymentMethod';

export function TotalSalesGeneralGraph({ colors, loading, salesByPaymentMethodData }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [salesByPaymentMethod, setSalesByPaymentMethod] = useState<Array<PieData>>([]);
    const [totalSale, setTotalSale] = useState(0);

    useEffect(() => {
        getPaymentMethodFromReports(salesByPaymentMethodData);
    }, [salesByPaymentMethodData]);

    const getPaymentMethodFromReports = (salesByPaymentMethod?: Array<SalesByPaymentMethod>) => {
        if (!salesByPaymentMethod) {
            setSalesByPaymentMethod([]);
            setTotalSale(0);
        }

        const totalSales = salesByPaymentMethod?.reduce((acc: number, current: SalesByPaymentMethod) => acc + current.total, 0) ?? 0;
        const paymentItems = getPaymentMethodReportData(salesByPaymentMethod ?? []);
        const paymentMethodDataSorted = paymentItems.sort((itemA, itemB) => itemB.value - itemA.value);
        const totalSalesData = BigNumber(totalSales).multipliedBy(100).div(100).toNumber();

        setSalesByPaymentMethod(paymentMethodDataSorted);
        setTotalSale(totalSalesData);
    };

    const getPaymentMethodReportData = (paymentMethodsData: Array<SalesByPaymentMethod>): Array<PieData> => {
        const paymentItems: Array<PieData> = [];
        paymentMethodsData.forEach((paymentItem) => {
            const isPaymentItem = paymentItems?.find((elem: PieData) => elem.id === translatePaymentMethod({ paymentMethod: paymentItem.paymentMethod as PaymentMethod, app: paymentItem.app as App }));
            if (!isPaymentItem) {
                paymentItems.push({
                    id: translatePaymentMethod({ paymentMethod: paymentItem.paymentMethod as PaymentMethod, app: paymentItem.app as App }),
                    label: translatePaymentMethod({ paymentMethod: paymentItem.paymentMethod as PaymentMethod, app: paymentItem.app as App }),
                    value: paymentItem.total,
                });
                return;
            }
            isPaymentItem.value = parseFloat(BigNumber(isPaymentItem.value).plus(paymentItem.total).toFixed(2));
        });

        return paymentItems;
    };

    const getKpisIndicators = () => {
        const kpiIndicators: Array<{ amount: string; title: string }> = [];
        for (const salesByPaymentMethod of salesByPaymentMethodData) {
            const salesByPaymentMethodTitle = translatePaymentMethod({ paymentMethod: salesByPaymentMethod.paymentMethod as PaymentMethod, app: salesByPaymentMethod.app as App });
            const kpiIndicator = kpiIndicators.find((kpiIndicator) => kpiIndicator.title === salesByPaymentMethodTitle);

            if (!kpiIndicator) {
                kpiIndicators.push({
                    amount: salesByPaymentMethod.total.toString(),
                    title: salesByPaymentMethodTitle,
                });
                continue;
            }

            kpiIndicator.amount = BigNumber(kpiIndicator.amount).plus(salesByPaymentMethod.total).toString();
        }
        return kpiIndicators?.sort((valueA, valueB) => Number(valueB.amount) - Number(valueA.amount));
    };

    return (
        <ReportSection title={translate('Total Sales')} loading={loading} total={formatAsCurrencyNumber(totalSale)}>
            <Grid container className={(classes as any).infoContainer}>
                <Grid item md={9} className={classes.graphContainer}>
                    <PieChartCanvas data={salesByPaymentMethod} valueFormat={(value) => formatAsCurrencyNumber(value)} />
                </Grid>
                <Grid item md={3} className={classes.kpisContainer}>
                    {getKpisIndicators()?.map((kpiIndicator, idx: number) => {
                        return <KpiCard color={colors[idx]} legend={formatAsCurrencyNumber(kpiIndicator.amount)} title={kpiIndicator.title} />;
                    })}
                </Grid>
            </Grid>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    graphContainer: {
        width: '100%',
        height: '45vh',
    },
    kpisContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '95%',
        height: 'auto',
        justifyContent: 'space-around',
        gap: 12,
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100%',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            flexWrap: 'nowrap',
        },
    },
}));

type Props = {
    loading: boolean;
    colors: Array<string>;
    salesByPaymentMethodData: Array<SalesByPaymentMethod>;
};

type PieData = {
    id: string;
    label: string;
    value: number;
};
