/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function findRestaurantPasswordApi(request: RestaurantPasswordApiRequest): ApiSauceResponse<string | undefined> {
    return letseatmanagerApiMethod('restaurant/findRestaurantPasswordApi', request);
}

export type RestaurantPasswordApiRequest = {
    restaurantId: RestaurantId;
};
