/**
 * @prettier
 */
import { OrderStatus, OrderStatuses } from 'src/constants/OrderStatus';
import { OrderType, OrderTypes } from 'src/constants/OrderType';
import { translate } from 'src/i18n/translate';

export function translateOrderStatus(orderStatus: OrderStatus, orderType: OrderType): string {
    switch (orderStatus) {
        case OrderStatuses.NEW: {
            return translate('Received');
        }
        case OrderStatuses.CANCELLED: {
            return translate('Cancelled');
        }
        case OrderStatuses.REJECTED: {
            return translate('Rejected');
        }
        case OrderStatuses.ACCEPTED: {
            return translate('Accepted');
        }
        case OrderStatuses.COMPLETE: // TODO: Remove OrderStatus.COMPLETE when changed to COMPLETED on server and db
        case OrderStatuses.COMPLETED: {
            switch (orderType) {
                case OrderTypes.TABLE_ORDER: {
                    return translate('Served');
                }
                case OrderTypes.SELF_SERVICE_ORDER: {
                    return translate('Served');
                }
                case OrderTypes.TAKE_AWAY_ORDER: {
                    return translate('Picked Up');
                }
                case OrderTypes.DELIVERY_ORDER: {
                    return translate('Delivered');
                }
                case OrderTypes.PICKUP_STATION_ORDER: {
                    return translate('Delivered');
                }
                case OrderTypes.ROOM_SERVICE_ORDER: {
                    return translate('Delivered');
                }
                default: {
                    return '';
                }
            }
        }
        default: {
            return '';
        }
    }
}
