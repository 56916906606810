/**
 * @prettier
 */
export const Cities = {
    MONTERREY: 'MONTERREY',
    MEXICO_CITY: 'MEXICO_CITY',
    GUADALAJARA: 'GUADALAJARA',
    MERIDA: 'MERIDA',
    SALTILLO: 'SALTILLO',
    TORREON: 'TORREON',
    PUEBLA: 'PUEBLA',
    QUERETARO: 'QUERETARO',
    ACAPULCO: 'ACAPULCO',
    ACUNA: 'ACUNA',
    AGUASCALIENTES: 'AGUASCALIENTES',
    ALTAMIRA: 'ALTAMIRA',
    ATLACOMULCO: 'ATLACOMULCO',
    ATLIXCO_PUEBLO_MAGICO: 'ATLIXCO_PUEBLO_MAGICO',
    BOGOTA: 'BOGOTA',
    CADEREYTA_DE_JIMENEZ: 'CADEREYTA_DE_JIMENEZ',
    CAMPECHE: 'CAMPECHE',
    CANCUN: 'CANCUN',
    CD_VICTORIA: 'CD_VICTORIA',
    CELAYA: 'CELAYA',
    CHETUMAL: 'CHETUMAL',
    CHIHUAHUA: 'CHIHUAHUA',
    CHILPANCINGO_DE_LOS_BRAVO: 'CHILPANCINGO_DE_LOS_BRAVO',
    CHIMALHUACAN: 'CHIMALHUACAN',
    CIUDAD_DEL_CARMEN: 'CIUDAD_DEL_CARMEN',
    CIUDAD_JUAREZ: 'CIUDAD_JUAREZ',
    CIUDAD_OBREGON: 'CIUDAD_OBREGON',
    COATZACOALCOS: 'COATZACOALCOS',
    COLIMA: 'COLIMA',
    COMITAN_DE_DOMINGUEZ: 'COMITAN_DE_DOMINGUEZ',
    CORDOBA: 'CORDOBA',
    COZUMEL: 'COZUMEL',
    CUAUHTEMOC: 'CUAUHTEMOC',
    CUAUTLA: 'CUAUTLA',
    CUERNAVACA: 'CUERNAVACA',
    CULIACAN: 'CULIACAN',
    DELICIAS: 'DELICIAS',
    DOLORES_HIDALGO: 'DOLORES_HIDALGO',
    DURANGO: 'DURANGO',
    ENSENADA: 'ENSENADA',
    FRESNILLO: 'FRESNILLO',
    GARCIA: 'GARCIA',
    GUANAJUATO: 'GUANAJUATO',
    GUASAVE: 'GUASAVE',
    GUAYMAS: 'GUAYMAS',
    HERMOSILLO: 'HERMOSILLO',
    IRAPUATO: 'IRAPUATO',
    LA_PAZ: 'LA_PAZ',
    LA_PIEDAD: 'LA_PIEDAD',
    LAZARO_CARDENAS: 'LAZARO_CARDENAS',
    LEON: 'LEON',
    LOS_CABOS: 'LOS_CABOS',
    LOS_MOCHIS: 'LOS_MOCHIS',
    MANZANILLO: 'MANZANILLO',
    MATAMOROS: 'MATAMOROS',
    MAZATLAN: 'MAZATLAN',
    MEXICALI: 'MEXICALI',
    MINATITLAN: 'MINATITLAN',
    MONCLOVA: 'MONCLOVA',
    MORELIA: 'MORELIA',
    NAVOJOA: 'NAVOJOA',
    NOGALES: 'NOGALES',
    NUEVO_LAREDO: 'NUEVO_LAREDO',
    OAXACA: 'OAXACA',
    PACHUCA: 'PACHUCA',
    PIEDRAS_NEGRAS: 'PIEDRAS_NEGRAS',
    PLAYA_DEL_CARMEN: 'PLAYA_DEL_CARMEN',
    POZA_RICA: 'POZA_RICA',
    PROGRESO: 'PROGRESO',
    PUERTO_VALLARTA: 'PUERTO_VALLARTA',
    REYNOSA: 'REYNOSA',
    ROSARITO: 'ROSARITO',
    SALAMANCA: 'SALAMANCA',
    SAN_CRISTOBAL_DE_LAS_CASAS: 'SAN_CRISTOBAL_DE_LAS_CASAS',
    SAN_JOSE: 'SAN_JOSE',
    SAN_JOSE_DEL_CABO: 'SAN_JOSE_DEL_CABO',
    SAN_JUAN_DEL_RIO: 'SAN_JUAN_DEL_RIO',
    SAN_LUIS_POTOSI: 'SAN_LUIS_POTOSI',
    SAN_LUIS_RIO_COLORADO: 'SAN_LUIS_RIO_COLORADO',
    SAN_MIGUEL_DE_ALLENDE: 'SAN_MIGUEL_DE_ALLENDE',
    SANTIAGO: 'SANTIAGO',
    SILAO: 'SILAO',
    STOCKHOLM: 'STOCKHOLM',
    TAMPICO: 'TAMPICO',
    TAPACHULA: 'TAPACHULA',
    TEHUACAN: 'TEHUACAN',
    TEPIC: 'TEPIC',
    TEXCOCO: 'TEXCOCO',
    TEZIUTLAN_Y_CHIGNAUTLA: 'TEZIUTLAN_Y_CHIGNAUTLA',
    TIJUANA: 'TIJUANA',
    TLAXCALA: 'TLAXCALA',
    TOLUCA: 'TOLUCA',
    TUXTLA_GUTIERREZ: 'TUXTLA_GUTIERREZ',
    URUAPAN: 'URUAPAN',
    VALLE_DE_BRAVO: 'VALLE_DE_BRAVO',
    VALLE_DE_CHALCO: 'VALLE_DE_CHALCO',
    VALLE_DE_SANTIAGO: 'VALLE_DE_SANTIAGO',
    VERACRUZ: 'VERACRUZ',
    VILLAHERMOSA: 'VILLAHERMOSA',
    XALAPA: 'XALAPA',
    ZACATECAS: 'ZACATECAS',
    ZAMORA: 'ZAMORA',
    UY_MONTEVIDEO: 'UY_MONTEVIDEO',
    UY_CANELONES: 'UY_CANELONES',
    UY_MALDONADO: 'UY_MALDONADO',
    UY_SALTO: 'UY_SALTO',
    UY_PAYSANDU: 'UY_PAYSANDU',
    UY_COLONIA: 'UY_COLONIA',
    UY_SANJOSE: 'UY_SANJOSE',
    UY_FLORIDA: 'UY_FLORIDA',
    AR_BUENOSAIRES: 'AR_BUENOSAIRES',
    AR_SANTEFE: 'AR_SANTEFE',
    AR_ROSARIO: 'AR_ROSARIO',
    AR_CORDOBA: 'AR_CORDOBA',
    AR_MENDOZA: 'AR_MENDOZA',
    AR_MISIONES: 'AR_MISIONES',
    AR_MARDELPLATA: 'AR_MARDELPLATA',
    AR_TUCUMAN: 'AR_TUCUMAN',
    AR_SALTA: 'AR_SALTA',
    AR_CORRIENTES: 'AR_CORRIENTES',
    BR_SAOPAULO: 'BR_SAOPAULO',
    BR_RIODEJANEIRO: 'BR_RIODEJANEIRO',
    BR_BRASILIA: 'BR_BRASILIA',
    BR_SALVADOR: 'BR_SALVADOR',
    BR_FORTALEZA: 'BR_FORTALEZA',
    BR_BELOHORIZONTE: 'BR_BELOHORIZONTE',
    BR_PORTOALEGRE: 'BR_PORTOALEGRE',
    BR_MANAUS: 'BR_MANAUS',
    BR_CURITIBA: 'BR_CURITIBA',
    BR_RECIFE: 'BR_RECIFE',
    BR_TORRES: 'BR_TORRES',
    PE_LIMA: 'PE_LIMA',
    PE_CUZCO: 'PE_CUZCO',
    PE_CALLAO: 'PE_CALLAO',
    PE_AREQUIPA: 'PE_AREQUIPA',
    PE_TRUJILLO: 'PE_TRUJILLO',
    PE_CHICLAYO: 'PE_CHICLAYO',
    PE_PIURA: 'PE_PIURA',
    PE_HUNCAYO: 'PE_HUNCAYO',
    CL_SANTIAGO: 'CL_SANTIAGO',
    CL_VALPARAISO: 'CL_VALPARAISO',
    CL_CONCEPCION: 'CL_CONCEPCION',
    CL_ANTOFAGASTA: 'CL_ANTOFAGASTA',
    CL_COQUIMBO: 'CL_COQUIMBO',
    CL_BIOBIO: 'CL_BIOBIO',
    PY_ASUNCION: 'PY_ASUNCION',
    PY_CIUDADDELESTE: 'PY_CIUDADDELESTE',
    PY_ENCARNACION: 'PY_ENCARNACION',
} as const;

export type City = typeof Cities[keyof typeof Cities];
