/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { changeManagerUserPasswordApi } from 'src/api/letseatmanager/managerUser/changeManagerUserPasswordApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormPasswordField } from 'src/components/form/FormPasswordField';
import { translate } from 'src/i18n/translate';
import { useNotification } from 'src/services/notification/useNotification';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { appLayout } from 'src/theme/AppLayout';
import type { AwsCognitoUsername } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangePasswordDialog({ open, onClose, username, onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { isSubmitting },
        watch,
    } = form;

    const { reloadRestaurant } = useReloadRestaurant();

    const notification = useNotification();

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        if (form.password !== form.confirmPassword) {
            notification({ message: translate('Passwords dont match') });
            return;
        }

        const response = await changeManagerUserPasswordApi({
            restaurantId: restaurantId,
            username: username,
            password: form.password,
            signOutFromDevices: form.signOutFromDevices,
        });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
        }

        await reloadRestaurant(restaurantId);
        await onSuccess?.();
        onClose();
    };

    if (!open) return null;

    return (
        <Dialog classes={{ dialog: classes.dialog }} title={translate('Enter the new password')} open={open} onClose={handleClose}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormPasswordField name='password' label={translate('Password')} placeholder='****' required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormPasswordField name='confirmPassword' label={translate('Confirm password')} placeholder='****' required />
                    </Grid>
                    <Grid item className={classes.checkBox} xs={12}>
                        <FormCheckbox name={'signOutFromDevices'} label={translate('Sign out from all devices')} />
                    </Grid>
                </Grid>
                <DialogActions className={classes.dialogActions}>
                    <Button classes={{ button: classes.button }} secondary onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button classes={{ button: classes.button }} type='submit' disabled={isSubmitting}>
                        {translate('Save')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: '50vw',
    },
    checkBox: {
        textAlign: 'center',
        paddingLeft: 60,
        marginTop: 20,
        marginBottom: 10,
    },
    dialogActions: {
        display: 'flex',
        marginTop: 20,
        width: '100%',
        justifyContent: 'center',
        gap: 20,
    },
    button: {
        width: 200,
    },
}));

type Props = {
    open: boolean;
    username: AwsCognitoUsername;
    onSuccess?: any;
    onClose: any;
};
