/**
 * @prettier
 */
export const CustomDiscountComments = {
    EMPLOYEE_DISCOUNT: 'EMPLOYEE_DISCOUNT',
    PARTNER_DISCOUNT: 'PARTNER_DISCOUNT',
    COURTESY: 'COURTESY',
    PROMOTION: 'PROMOTION',
} as const;

export type CustomDiscountComment = typeof CustomDiscountComments[keyof typeof CustomDiscountComments];
