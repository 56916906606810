/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import type { InstructionPrinter } from 'src/services/printer/printers/interfaces/InstructionPrinter';
import { getPrinterCharactersPerRow } from 'src/services/printer/printers/utils/getPrinterCharactersPerRow';
import { PrintColumn, type PrinterInstruction } from 'src/services/printer/types/PrinterInstruction';
import type { PrinterVm } from 'src/types/PrinterVm';
import { normalizeToAsciiCharacters } from 'src/utils/string/normalizeToAsciiCharacters';
import { repeatCharacter } from 'src/utils/string/repeatCharacter';

export class WindowsPrinter {
    static async print(printerInstructions: Array<PrinterInstruction>, printer: PrinterVm): Promise<void> {
        const windowsPrinter = new WindowsPrinterImplementation(printer);

        // Add all printer instructions
        for (const printerInstruction of printerInstructions) {
            try {
                (windowsPrinter as any)[printerInstruction.instructionType](...printerInstruction.params);
            } catch (e: any) {
                console.log(`instructionType ${printerInstruction.instructionType} not implemented by WindowsPrinterImplementation`);
                alert(`instructionType ${printerInstruction.instructionType} not implemented by WindowsPrinterImplementation`);
            }
        }

        const openCashbox = printerInstructions.some((pi) => pi.instructionType === 'openCashbox');
        await windowsPrinter.print(openCashbox);
    }

    // TODO: add return type ExternalPrinterId
    static async getInstalledPrinters(): Promise<Array<string>> {
        return WindowsPrinterImplementation.getInstalledPrinters();
    }
}

class WindowsPrinterImplementation implements InstructionPrinter {
    #printer;
    #url = PLUGIN_URL;
    #operations: Array<PrinterOperation> = [];
    #CHARACTER_PER_ROW = 42;
    static #printQueue: Record<string, Promise<any>> = {}; // have one printQueue per printer serial number to make sure that printers prints in parallel

    constructor(printer: PrinterVm) {
        this.#printer = printer;
        this.#CHARACTER_PER_ROW = getPrinterCharactersPerRow({
            printerBrand: printer.printerBrand,
            printerPaperSize: printer.printerPaperSize,
        });
        this.#setFontSize(1, 1);
    }

    // TODO: add return type ExternalPrinterId
    static async getInstalledPrinters(): Promise<any> {
        return fetch(PLUGIN_URL + '/impresoras').then((r) => r.json());
    }

    async print(openCashbox: boolean): Promise<void> {
        const prevQueue = WindowsPrinterImplementation.#printQueue[this.#printer.externalPrinterId!] ?? Promise.resolve();
        const printPromise = new Promise<void>(async (resolve, reject) => {
            try {
                await prevQueue;

                this.#operations.push({ accion: Operations.CUT, datos: '' });
                if (openCashbox) this.#operations.push({ accion: Operations.CASH, datos: '' });
                await fetch(this.#url + '/imprimir_en', {
                    method: 'POST',
                    body: JSON.stringify({
                        operaciones: this.#operations,
                        impresora: this.#printer.externalPrinterId,
                    }),
                }).then((r) => {
                    const jsonResponse = r.json();
                    console.log('WindowsPrinter print response = ', jsonResponse);
                    return jsonResponse;
                });

                if (WindowsPrinterImplementation.#printQueue[this.#printer.externalPrinterId!] === printPromise) {
                    WindowsPrinterImplementation.#printQueue[this.#printer.externalPrinterId!] = Promise.resolve();
                }
                resolve();
            } catch (e: any) {
                if (WindowsPrinterImplementation.#printQueue[this.#printer.externalPrinterId!] === printPromise) {
                    WindowsPrinterImplementation.#printQueue[this.#printer.externalPrinterId!] = Promise.resolve();
                }
                reject(e);
            }
        });
        WindowsPrinterImplementation.#printQueue[this.#printer.externalPrinterId!] = printPromise;
        await printPromise;
    }

    setLargerFont(): void {
        this.#setFontSize(2, 1);
        this.#CHARACTER_PER_ROW = 22;
    }

    setRegularFont(): void {
        this.#setFontSize(1, 1);
        this.#CHARACTER_PER_ROW = getPrinterCharactersPerRow({
            printerBrand: this.#printer.printerBrand,
            printerPaperSize: this.#printer.printerPaperSize,
        });
    }

    addText(text: string): void {
        this.#operations.push({ accion: Operations.WRITE, datos: this.#normalizeCharacters(`${text}\n`) });
    }

    addBoldText(text: string): void {
        if (!text) return;
        this.#operations.push({ accion: Operations.EMPHASIZE, datos: '1' });

        this.#operations.push({ accion: Operations.ALIGN, datos: Operations.ALIGN_LEFT });
        this.#operations.push({ accion: Operations.WRITE, datos: this.#normalizeCharacters(`${text}\n`) });

        this.#operations.push({ accion: Operations.EMPHASIZE, datos: '0' });
    }

    addCenteredText(text: string): void {
        this.#operations.push({ accion: Operations.ALIGN, datos: Operations.ALIGN_CENTER });
        this.#operations.push({ accion: Operations.WRITE, datos: this.#normalizeCharacters(`${text}\n`) });
        this.#operations.push({ accion: Operations.ALIGN, datos: Operations.ALIGN_LEFT });
    }

    addCenteredBoldText(text: string): void {
        this.#operations.push({ accion: Operations.EMPHASIZE, datos: '1' });

        this.#operations.push({ accion: Operations.ALIGN, datos: Operations.ALIGN_CENTER });
        this.#operations.push({ accion: Operations.WRITE, datos: this.#normalizeCharacters(`${text}\n`) });
        this.#operations.push({ accion: Operations.ALIGN, datos: Operations.ALIGN_LEFT });

        this.#operations.push({ accion: Operations.EMPHASIZE, datos: '0' });
    }

    addRightText(text: string): void {
        this.#operations.push({ accion: Operations.ALIGN, datos: Operations.ALIGN_RIGHT });
        this.#operations.push({ accion: Operations.WRITE, datos: this.#normalizeCharacters(`${text}\n`) });
        this.#operations.push({ accion: Operations.ALIGN, datos: Operations.ALIGN_LEFT });
    }

    addBoldRightText(text: string): void {
        this.#operations.push({ accion: Operations.EMPHASIZE, datos: '1' });

        this.#operations.push({ accion: Operations.ALIGN, datos: Operations.ALIGN_RIGHT });
        this.#operations.push({ accion: Operations.WRITE, datos: this.#normalizeCharacters(`${text}\n`) });
        this.#operations.push({ accion: Operations.ALIGN, datos: Operations.ALIGN_LEFT });

        this.#operations.push({ accion: Operations.EMPHASIZE, datos: '0' });
    }

    addSeparatedTexts(leftText: string, rightText: string): void {
        const spaceBetweenTexts = this.#CHARACTER_PER_ROW - leftText.length - rightText.length;
        const blankSpace = repeatCharacter(' ', spaceBetweenTexts);
        const text = `${leftText}${blankSpace}${rightText}\n`;

        this.#operations.push({ accion: Operations.ALIGN, datos: Operations.ALIGN_LEFT });
        this.#operations.push({ accion: Operations.WRITE, datos: this.#normalizeCharacters(text) });
    }

    addSeparatedBoldLeftTexts(leftText: string, rightText: string): void {
        this.#operations.push({ accion: Operations.EMPHASIZE, datos: '1' });

        this.#operations.push({ accion: Operations.ALIGN, datos: Operations.ALIGN_LEFT });
        this.#operations.push({ accion: Operations.WRITE, datos: this.#normalizeCharacters(`${leftText}`) });

        this.#operations.push({ accion: Operations.EMPHASIZE, datos: '0' });

        const spaceBetweenTexts = this.#CHARACTER_PER_ROW - leftText.length - rightText.length;
        const blankSpace = repeatCharacter(' ', spaceBetweenTexts);
        const text = `${blankSpace}${rightText}\n`;

        this.#operations.push({ accion: Operations.ALIGN, datos: Operations.ALIGN_LEFT });
        this.#operations.push({ accion: Operations.WRITE, datos: this.#normalizeCharacters(text) });
    }

    addBoldSeparatedTexts(leftText: string, rightText: string): void {
        this.#operations.push({ accion: Operations.EMPHASIZE, datos: '1' });

        const spaceBetweenTexts = this.#CHARACTER_PER_ROW - leftText.length - rightText.length;
        const blankSpace = repeatCharacter(' ', spaceBetweenTexts);
        const text = `${leftText}${blankSpace}${rightText}\n`;

        this.#operations.push({ accion: Operations.ALIGN, datos: Operations.ALIGN_LEFT });
        this.#operations.push({ accion: Operations.WRITE, datos: this.#normalizeCharacters(text) });
        this.#operations.push({ accion: Operations.EMPHASIZE, datos: '0' });
    }

    addColumns(columns: Array<PrintColumn>): void {
        this.#addRowTextColumnInfo(columns);
        const missingColumnsInfo = this.#getNextColumnsInfo(columns);
        const someColumnHasMissingInfo = missingColumnsInfo.some((column) => !!column.text);
        if (someColumnHasMissingInfo) this.addColumns(missingColumnsInfo);
    }

    addBoldColumns(columns: Array<PrintColumn>): void {
        this.addColumns(columns.map((column) => ({ ...column, fontWeight: 'bold' })));
    }

    addLogoImage(url: string): void {
        // TODO: implement if possible
    }

    addQrCode(url: string): void {
        this.#operations.push({ accion: Operations.QR, datos: url });
    }

    addNewLine(): void {
        this.#operations.push({ accion: Operations.WRITE, datos: this.#normalizeCharacters('\n') });
    }

    addCenteredUnderLine(): void {
        this.#operations.push({ accion: Operations.EMPHASIZE, datos: '1' });

        this.#operations.push({ accion: Operations.ALIGN, datos: Operations.ALIGN_CENTER });
        const underline = repeatCharacter('_', this.#CHARACTER_PER_ROW - 10);
        this.#operations.push({ accion: Operations.WRITE, datos: this.#normalizeCharacters(`${underline}\n`) });
        this.#operations.push({ accion: Operations.ALIGN, datos: Operations.ALIGN_LEFT });

        this.#operations.push({ accion: Operations.EMPHASIZE, datos: '0' });
    }

    addLineSeparator(): void {
        this.#operations.push({ accion: Operations.EMPHASIZE, datos: '0' });

        const separatedText = repeatCharacter('-', this.#CHARACTER_PER_ROW);
        this.#operations.push({ accion: Operations.WRITE, datos: this.#normalizeCharacters(`${separatedText}\n`) });
    }

    openCashbox(): void {
        // Ignored since its handled in the print method instead
    }

    #addRowTextColumnInfo(columns: Array<PrintColumn>): void {
        this.#operations.push({ accion: Operations.ALIGN, datos: Operations.ALIGN_LEFT });

        columns.forEach((column, idx) => {
            const charactersPerColumn = this.#getCharactersPerColumn(column);
            let rowText;

            if (column.text.length > charactersPerColumn) {
                rowText = `${column.text?.slice(0, charactersPerColumn - 1)} `;
            } else if (idx === columns.length - 1 && column.textAlign === 'right') {
                rowText = `${repeatCharacter(' ', charactersPerColumn - column.text.length)}${column.text}`;
            } else {
                rowText = `${column.text}${repeatCharacter(' ', charactersPerColumn - column.text.length)}`;
            }

            if (column.fontWeight === 'bold') {
                this.#operations.push({ accion: Operations.EMPHASIZE, datos: '1' });
                this.#operations.push({ accion: Operations.WRITE, datos: this.#normalizeCharacters(rowText) });
                this.#operations.push({ accion: Operations.EMPHASIZE, datos: '0' });
                return;
            }

            this.#operations.push({ accion: Operations.WRITE, datos: this.#normalizeCharacters(rowText) });
        });

        this.#operations.push({ accion: Operations.WRITE, datos: this.#normalizeCharacters(`\n`) });
    }

    #getNextColumnsInfo(columns: Array<PrintColumn>): Array<PrintColumn> {
        return columns.map((column) => {
            const charactersPerColumn = this.#getCharactersPerColumn(column);
            const isTextColumnLongerThanColumnSize = column.text.length > charactersPerColumn;
            const cutOffPoint = isTextColumnLongerThanColumnSize ? charactersPerColumn - 1 : charactersPerColumn;

            return { ...column, text: column.text?.slice(cutOffPoint) };
        });
    }

    #getCharactersPerColumn(column: PrintColumn): number {
        const charactersPerRow = BigNumber(this.#CHARACTER_PER_ROW).multipliedBy(column.percentageWidth).toNumber();
        return Math.floor(charactersPerRow);
    }

    #normalizeCharacters(text: string): string {
        // normalize spanish characters to ascii characters since it causes some issues on some printers,
        // in future try to set the character encoding on printers to add support for spanish characters
        return normalizeToAsciiCharacters(text);
    }

    #setFontSize(a: number, b: number): void {
        this.#operations.push({ accion: Operations.FONT_SIZE, datos: `${a},${b}` });
    }
}

const PLUGIN_URL = 'http://localhost:8000';

type PrinterOperation = {
    accion: Operation;
    datos: string;
};

const Operations = Object.freeze({
    WRITE: 'write',
    CUT: 'cut',
    CASH: 'cash',
    CUT_PARTIAL: 'cutpartial',
    ALIGN: 'align',
    FONT_SIZE: 'fontsize',
    FONT: 'font',
    EMPHASIZE: 'emphasize',
    FEED: 'feed',
    QR: 'qr',
    ALIGN_CENTER: 'center',
    ALIGN_RIGHT: 'right',
    ALIGN_LEFT: 'left',
    FONT_A: 'A',
    FONT_B: 'B',
    BARCODE_128: 'barcode128',
    BARCODE_39: 'barcode39',
    BARCODE_93: 'barcode93',
    BARCODE_EAN: 'barcodeEAN',
    BARCODE_TWO_OF_FIVE: 'barcodeTwoOfFive',
    BARCODE_TWO_OF_FIVE_INTERLEAVED: 'barcodeTwoOfFiveInterleaved',
    BARCODE_CODEBAR: 'barcodeCodabar',
    BARCODE_UPCA: 'barcodeUPCA',
    BARCODE_UPCE: 'barcodeUPCE',
    SIZE_80: '80',
    SIZE_100: '100',
    SIZE_156: '156',
    SIZE_200: '200',
    SIZE_300: '300',
    SIZE_350: '350',
});

export type Operation = typeof Operations[keyof typeof Operations];
