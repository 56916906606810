/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { ResponsiveLineCanvas } from 'src/components/charts/ResponsiveLineCanvas';
import { translate } from 'src/i18n/translate';
import { GraphEmptyState } from 'src/scenes/letseatmanager/posReports/GraphEmptyState';
import type { SupplyDaysReportVm } from 'src/types/SupplyHistoryReportVm';

export function SupplyConsumptionLineGraph({ supplyDaysReport }: Props): React.ReactElement {
    const classes = useStyles();

    const getHistoricalSalesLineChartData = () => {
        return [
            {
                id: `supplyConsumptionLineGraph`,
                data: supplyDaysReport.map((supplyDayReport) => ({
                    x: supplyDayReport.day,
                    y: supplyDayReport.consumption,
                })),
            },
        ];
    };

    if (!supplyDaysReport || !supplyDaysReport.length) {
        return <GraphEmptyState title={translate('Consumption')} />;
    }

    return (
        <article className={classes.container}>
            <h2 className={classes.title}>{translate('Consumption')}</h2>
            <ResponsiveLineCanvas
                data={getHistoricalSalesLineChartData()}
                tooltip={({ point }: { point: { data: { x: string; y: string } } }) => (
                    <div className={classes.tooltip}>
                        <span className={classes.tooltipText}>{translate(`Date: @date`, { date: point.data.x })}</span>
                        <span className={classes.tooltipText}>{translate(`Total: @total`, { total: point.data.y })}</span>
                    </div>
                )}
            />
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '40vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontFamily: theme.typography.medium,
        color: '#104353',
    },
    tooltip: {
        padding: 10,
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    supplyDaysReport: Array<SupplyDaysReportVm>;
};
