/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import * as React from 'react';
import { SimpleTable } from 'src/components/SimpleTable';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { CashRegisterTransactionReportCard } from 'src/scenes/letseatmanager/posBusinessDayReport/CashRegisterTransactionReportCard';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CashRegisterPosBusinessDayReportVm } from 'src/types/CashRegisterPosBusinessDayReportVm';
import { CashRegisterTransaction, type PosBusinessDayReportVm } from 'src/types/PosBusinessDayReportVm';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { translateCashRegisterDepositReasons } from 'src/utils/translate/translateCashRegisterDepositReasons';
import { translateCashRegisterWithdrawReasons } from 'src/utils/translate/translateCashRegisterWithdrawReasons';

export function CashRegisterMovements({ posBusinessDay }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);
    const openedCashRegister = useSelector((state) => state.pos?.openedCashRegister);

    const columns = [
        {
            id: 'movement',
            content: translate('Movement'),
        },
        {
            id: 'user',
            content: translate('User'),
        },
        {
            id: 'reason',
            content: translate('Reason'),
        },
        {
            id: 'amount',
            content: translate('Amount'),
        },
        {
            id: 'date',
            content: translate('Date'),
        },
        {
            id: 'comment',
            content: translate('Comment'),
            size: 1.5,
        },
    ];

    if (posMultipleCashRegistersEnabled && (posBusinessDay as any)?.cashRegisterPosBusinessDaysSummary) {
        columns.push({
            id: 'cashRegisterPosBusinessDayNumber',
            content: translate('Cash Register'),
        });
    }

    if (!posBusinessDay.cashRegisterTransactions?.length) return null;

    return (
        <div className={classes.container}>
            <Text title style={{ fontSize: 20 }}>
                {translate('Cash register movements')}
            </Text>
            <p className={classes.date}>{moment(openedCashRegister?.openedAt ?? openedPosBusinessDay?.openedAt).format('LLLL')}</p>
            <section className={classes.cardContainer}>
                {posBusinessDay.cashRegisterTransactions?.map((transaction: CashRegisterTransaction, idx: number) => (
                    <CashRegisterTransactionReportCard key={`${transaction.amount}-${idx}`} cashRegisterTransaction={transaction} />
                ))}
            </section>
            <SimpleTable
                classes={{ table: classes.table }}
                columns={columns}
                rows={
                    posBusinessDay?.cashRegisterTransactions?.map((transaction: CashRegisterTransaction) => {
                        const isDeposit = BigNumber(transaction?.amount ?? 0).isPositive();
                        const movement = isDeposit ? translate('Deposited') : translate('Withdrawed');
                        return {
                            user: transaction.managerUserName ?? '',
                            cashRegisterPosBusinessDayNumber: transaction.cashRegisterPosBusinessDayNumber ?? '',
                            date: moment(transaction.createdAt).format('lll'),
                            movement: <div className={classNames(classes.chipMovement, isDeposit ? classes.deposit : classes.withdraw)}>{movement}</div>,
                            reason: isDeposit ? translateCashRegisterDepositReasons(transaction.reason) : translateCashRegisterWithdrawReasons(transaction.reason),
                            amount: formatAsCurrencyNumber(transaction.amount),
                            comment: transaction.comment ?? '',
                        };
                    }) ?? []
                }
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        margin: '50px 0',
        display: 'flex',
        flexDirection: 'column',
        gap: 18,
    },
    button: {
        height: 140,
        backgroundColor: 'transparent',
        border: '1px solid #D9D9D9',
        borderRadius: 8,
        textTransform: 'none',
        '&:hover': {
            borderColor: '#06B7A2',
            backgroundColor: 'transparent',
        },
    },
    title: {
        color: '#198074',
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
    },
    date: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        color: '#616B79',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    table: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    buttonText: {
        fontSize: 16,
        fontFamily: theme.typography.regular,
        color: '#2E3748',
        [theme.breakpoints.up('sm')]: {
            fontSize: 20,
        },
    },
    chipMovement: {
        height: 30,
        width: '100%',
        borderRadius: 6,
        padding: '5px 0',
        textAlign: 'center',
        margin: '0 10px',
    },
    deposit: {
        color: '#024B49',
        backgroundColor: '#CBF7E9',
    },
    withdraw: {
        color: '#124289',
        backgroundColor: '#C9E2FF',
    },
    cardContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: 20,
        margin: '30px 0',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

type Props = {
    posBusinessDay: PosBusinessDayReportVm | CashRegisterPosBusinessDayReportVm;
};
