/**
 * @prettier
 */
import moment from 'moment';
import * as React from 'react';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function FilterPeriodText(): React.ReactElement | null {
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const displayPeriodByDateAndTimeEnabled = useSelector((state) => state.app.restaurant?.displayPeriodByDateAndTimeEnabled);

    if (!displayPeriodByDateAndTimeEnabled) return null;
    return <Text>{translate('Reports from @fromDate to @toDate', { fromDate: moment(period?.from).format('DD-MM-YYYY HH:mm'), toDate: moment(period?.to).format('DD-MM-YYYY HH:mm') })}</Text>;
}
