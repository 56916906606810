/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@pidedirecto/ui';
import * as React from 'react';
import type { PromoCodeVm } from 'src/api/letseatmanager/types/PromoCodeVm';
import { DiscountTypes } from 'src/constants/DiscountType';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { isProductsReward } from 'src/utils/promoCode/isProductsReward';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PromoCodeItem({ promoCode }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const menuItems = useSelector((state) => state.pos.posMenu?.menuItems) ?? [];
    const promoCodeSelected = useSelector((state) => state.pos.promoCode);

    const setPromoCodeSelected = useAction(posReducer.actions.setPromoCodeSelected);

    const isPromoCodeSelected = promoCode.promoCodeId === promoCodeSelected?.promoCodeId;

    const getPromoCodeSubText = () => {
        if (promoCode.freeDelivery) return translate('Free Delivery');
        if (promoCode.discountType === DiscountTypes.AMOUNT) return translate('@amount of total', { amount: formatAsCurrencyNumber(promoCode.discount) });
        if (isProductsReward(promoCode.rewardType)) {
            const validMenuItems = menuItems.filter((menuItem) => promoCode.menuItemIds?.includes(menuItem.menuItemId));
            const validMenuItemNames = validMenuItems.map((menuItem) => menuItem.name);

            return translate('@percent% of @menuItems', { percent: promoCode.discount, menuItems: validMenuItemNames.join(', ') });
        }
        return translate('@percent% of total', { percent: promoCode.discount });
    };

    return (
        <Button variant='outline' classes={{ button: classNames(classes.promoCodeItem, isPromoCodeSelected ? classes.promoCodeItemSelected : '') }} onClick={() => setPromoCodeSelected({ promoCode })}>
            <div>
                <div className={classes.promoCodeName}>{promoCode.code}</div>
                <p className={classes.subText}>{getPromoCodeSubText()}</p>
            </div>
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    promoCodeName: {
        margin: 0,
        padding: 0,
        width: '90%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
    },
    promoCodeItem: {
        width: '100%',
        borderRadius: 5,
        padding: '10px 0',
        textTransform: 'none',
    },
    promoCodeItemSelected: {
        border: 'none',
        backgroundColor: theme.palette.surface.brand,
    },
    subText: {
        fontSize: 12,
        margin: 0,
        padding: 0,
    },
}));

type Props = {
    promoCode: PromoCodeVm;
};
