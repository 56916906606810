/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { RestaurantId } from 'src/types/Id';

export async function importRestaurantCustomerApi(request: ImportRestaurantCustomerApiRequest): ApiSauceResponse<ImportRestaurantCustomerApiResponse> {
    return letseatmanagerApiMethod('restaurantCustomer/importRestaurantCustomerApi', request);
}

type ImportRestaurantCustomerApiRequest = {
    restaurantId: RestaurantId;
    name: string;
    mobileNumber?: string;
    email?: string;
    address?: {
        street: string;
        location: GeoJsonPoint;
    };
};

type ImportRestaurantCustomerApiResponse = {
    imported: number;
};
