/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { RestaurantMenuId, UberEatsStoreId } from 'src/types/Id';

export async function deprecatedCreateMenuApi(request: CreateMenuApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menu/deprecatedCreateMenuApi', request);
}

type CreateMenuApiRequest = {
    restaurantMenuId: RestaurantMenuId;
    uberEatsStoreId?: UberEatsStoreId;
    position?: number;
    name: string;
    hours?: string;
    companyName?: string;
    channels?: Array<App>;
};
