/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import type { EmailsVm } from 'src/scenes/letseatadmin/EmailsPage';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { createFilteredOnDownloadFunctional } from 'src/utils/mui-datatables/createFilteredOnDownloadFunctional';

export default function EmailsTable({ title, onClickRefresh }: Props): React.ReactElement {
    const classes = useStyles();
    const table = useRef(null);
    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState({ emails: [] } as EmailsVm);
    // const openCreateEmailDialog = useAction(app2.actions.openCreateEmailDialog);
    // const openChangeEmailDialog = useAction(app2.actions.openChangeEmailDialog);

    useEffect(() => {
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.admin.emails();
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        setVm(response.data);
    };

    const columns = [
        {
            name: 'emailId',
            label: ' ',
            options: {
                display: 'excluded' as any,
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'to',
            label: translate('To'),
            options: {
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'sentAt',
            label: translate('Sent At'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'deliveredAt',
            label: translate('Delivered At'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'bouncedAt',
            label: translate('Bounced At'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'bounceType',
            label: translate('Bounce Type'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'bounceSubType',
            label: translate('Bounce Sub Type'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'complainedAt',
            label: translate('Complained At'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'complaintType',
            label: translate('Complaint Type'),
            options: {
                filter: false,
                searchable: false,
            },
        },
    ];

    return (
        <div style={{ position: 'relative' }}>
            <UpdatingContentProgress loading={loading} />
            <MUIDataTable
                // @ts-ignore
                ref={table}
                className={classes.table}
                title={title || translate('Sent Emails')}
                data={vm.emails.map((email) => ({
                    emailId: email.emailId,
                    to: email.to,
                    sentAt: formatDateTimeStringReadable(email.sentAt, TimeZones.AMERICA_MONTERREY),
                    deliveredAt: formatDateTimeStringReadable(email.deliveredAt, TimeZones.AMERICA_MONTERREY),
                    bouncedAt: formatDateTimeStringReadable(email.bouncedAt, TimeZones.AMERICA_MONTERREY),
                    bounceType: email.bounceType ?? '',
                    bounceSubType: email.bounceSubType ?? '',
                    complainedAt: formatDateTimeStringReadable(email.complainedAt, TimeZones.AMERICA_MONTERREY),
                    complaintType: email.complaintType ?? '',
                }))}
                columns={columns}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    // onRowClick: (rowData: Array<string>, rowMeta: { dataIndex: number, rowIndex: number }) => {
                    //     const emailId = rowData[0];
                    //     openChangeEmailDialog({
                    //         emailId,
                    //         onSuccess: () => load(),
                    //     });
                    // },
                    // customToolbarSelect: (selectedRows, displayData) => {
                    //     return (
                    //         <CustomToolbarSelect>
                    //             <ChangeToolbarButton onClick={() => {
                    //                 // const selectedEmailIds = selectedRowsData.map(d => d[0]);
                    //                 // openChangeEmailsDialog({
                    //                 //     emailIds: selectedEmailIds,
                    //                 //     onChange: load,
                    //                 // });
                    //             }} />
                    //         </CustomToolbarSelect>
                    //     );
                    // },
                    filterType: 'multiselect',
                    rowsPerPage: 100,
                    customToolbar: () => {
                        return (
                            <>
                                <RefreshToolbarButton
                                    onClick={() => {
                                        load();
                                        onClickRefresh && onClickRefresh();
                                    }}
                                />
                                {/*<CreateToolbarButton onClick={() => {*/}
                                {/*    openCreateEmailDialog({*/}
                                {/*        onSuccess: () => load(),*/}
                                {/*    });*/}
                                {/*}} />*/}
                            </>
                        );
                    },
                    onDownload: createFilteredOnDownloadFunctional(table),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

type Props = {
    title?: string;
    onClickRefresh?: any;
};
