/**
 * @prettier
 */
import type { ProductCancellationReason } from 'src/constants/ProductCancellationReason';
import { posReducer } from 'src/reducers/posReducer';
import type { CartItemVm } from 'src/types/CartItemVm';
import type { CustomProductCancellationReason } from 'src/types/Id';
import { createCartItemKey } from 'src/utils/pos/createCartItemKey';
import { getItemFormatted } from 'src/utils/pos/getItemFormatted';
import { useAction } from 'src/utils/react/useAction';

export function useRemovePosItem(): {
    removeItem: RemoveItemFunction;
} {
    const removePosItem = useAction(posReducer.actions.removePosItem);

    const removeItem = ({ item, cancellationReason: reason }: RemoveItemParams): void => {
        const itemFormatted = getItemFormatted({ ...item, quantity: !!item.salesUnit ? item.quantity : 1 });

        const posItem = { ...itemFormatted, key: createCartItemKey(itemFormatted), cancellationReason: reason, removedAt: new Date() } as const;

        removePosItem(posItem);
    };

    return { removeItem };
}

type RemoveItemParams = {
    item: CartItemVm;
    cancellationReason?: CustomProductCancellationReason | ProductCancellationReason;
};
type RemoveItemFunction = (arg1: RemoveItemParams) => void;
