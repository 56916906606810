/**
 * @prettier
 */
export const ConsecutiveOrderIdRanges = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
    POS_BUSINESS_DAY: 'POS_BUSINESS_DAY',
    NOT_RESET: 'NOT_RESET',
} as const;

export type ConsecutiveOrderIdRange = typeof ConsecutiveOrderIdRanges[keyof typeof ConsecutiveOrderIdRanges];
