/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { app2 } from 'src/app2';
import { FilterState, FromToPaymentDateFilter } from 'src/components/input/FromToPaymentDateFilter';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import KitchenBillingData from 'src/scenes/letseatadmin/kitchenBilling/KitchenBillingData';
import KitchenBillingDataForKitchen from 'src/scenes/letseatadmin/kitchenBilling/KitchenBillingDataForKitchen';
import { useAction } from 'src/utils/react/useAction';

export function KitchenBillingPage(): React.ReactElement {
    const [filter, setFilter] = useState<FilterState>({
        from: moment().subtract(1, 'week').startOf('isoWeek').toDate(),
        to: moment().subtract(1, 'week').endOf('isoWeek').toDate(),
        paymentDate: moment().isoWeekday(2).startOf('day').toDate(), // tuesday
    });

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Kitchen Billing'));
    });

    const handleLoadKitchen = (kitchenName: any) => setTitle(`${translate('Kitchen Billing')} > ${kitchenName}`);

    const handleChangeFilter = (filter: { from: Date; paymentDate: Date; to: Date }) => setFilter(filter);

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <FromToPaymentDateFilter filter={filter} onChangeFilter={handleChangeFilter} />
            </Grid>
            <Grid item xs={12}>
                <Switch>
                    <Route
                        path={RoutePaths.ADMIN_KITCHEN_BILLING_FOR_KITCHEN}
                        render={(props) => <KitchenBillingDataForKitchen filter={filter} kitchenId={props.match.params.kitchenId} onLoad={handleLoadKitchen} />}
                    />
                    <Route
                        path={RoutePaths.ADMIN_KITCHEN_BILLING}
                        render={() => {
                            setTitle(translate('Kitchen Billing'));
                            return <KitchenBillingData filter={filter} />;
                        }}
                    />
                </Switch>
            </Grid>
        </Grid>
    );
}
