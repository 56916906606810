/**
 * @prettier
 */
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { changePrinterApi } from 'src/api/letseatmanager/printerDeprecated/changePrinterApi';
import { getPrinterApi } from 'src/api/letseatmanager/printerDeprecated/getPrinterApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormAppAutocompleteMultiple } from 'src/components/form/FormAppAutocompleteMultiple';
import { FormCategoryAutocompleteMultiple } from 'src/components/form/FormCategoryAutocompleteMultiple';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormDriverPrintersSelect } from 'src/components/form/FormDriverPrintersSelect';
import { FormPrinterBrandSelect } from 'src/components/form/FormPrinterBrandSelect';
import { FormPrinterContentSelect } from 'src/components/form/FormPrinterContentSelect';
import { FormPrinterPaperSizeSelect } from 'src/components/form/FormPrinterPaperSizeSelect';
import { FormPrinterTypeSelect } from 'src/components/form/FormPrinterTypeSelect';
import { FormTextField } from 'src/components/form/FormTextField';
import { Apps } from 'src/constants/App';
import { PrinterBrand, PrinterBrands } from 'src/constants/PrinterBrand';
import { PrinterContent, PrinterContents } from 'src/constants/PrinterContent';
import { PrinterType, PrinterTypes } from 'src/constants/PrinterType';
import { translate } from 'src/i18n/translate';
import { getDeprecatedPrintersInLocalStorage } from 'src/localStorage/getDeprecatedPrintersInLocalStorage';
import { WebUsbPrinter } from 'src/services/printer/printers/WebUsbPrinter';
import { useGetRestaurantChannels } from 'src/services/printer/useGetRestaurantChannels';
import type { PrinterId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isBluetoothPrinter } from 'src/utils/printer/isBluetoothPrinter';
import { isSunmiPrinter } from 'src/utils/printer/isSunmiPrinter';
import { useSelector } from 'src/utils/react/useSelector';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';
import { setRestaurantHasBluetoothPrintersInMobileApp } from 'src/utils/reactNative/setRestaurantHasBluetoothPrintersInMobileApp';
import { requireValue } from 'src/utils/require/requireValue';

export function ChangePrinterDialog({ open, printerId, onPrinterChanged, onClose }: Props): React.ReactElement {
    const classes = useStyles();

    const channels = useGetRestaurantChannels();

    const form = useForm();
    const {
        formState: { isSubmitting },
        control,
    } = form;

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const printerType = useWatch<PrinterType>({ name: 'type', control });
    const printerBrand = useWatch<PrinterBrand>({ name: 'printerBrand', control });
    const printerContent = useWatch<PrinterContent>({ name: 'printerContent', control });

    const supportedPrinterTypes: Array<PrinterType> = [PrinterTypes.USB, PrinterTypes.BLUETOOTH, PrinterTypes.WIFI];
    if (isMobileApp()) {
        supportedPrinterTypes.push(PrinterTypes.PAYMENT_TERMINAL_PRINTER);
        supportedPrinterTypes.push(PrinterTypes.SDK);
    }

    useEffect(() => {
        if (open) load();
    }, [open]);

    useEffect(() => {
        if (printerType === PrinterTypes.USB && printerBrand) {
            tryToConnectWithPrinter();
        }
    }, [printerType, printerBrand]);

    const tryToConnectWithPrinter = async () => {
        try {
            const serialNumber = await WebUsbPrinter.getPrinterSerialNumber(printerBrand!);
            form.setValue('serialNumber', serialNumber);
        } catch (e: any) {}
    };

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const load = async () => {
        setLoading(true);
        const response = await getPrinterApi({ restaurantId, printerId: requireValue(printerId) });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const printers = getDeprecatedPrintersInLocalStorage();
        form.reset({
            ...response.data,
            disabled: !printers.find((printer) => printer.printerId === response.data.printerId),
        });
    };

    const handleDriverDownload = () => {
        window.open('https://habitue-img.s3.sa-east-1.amazonaws.com/driver+instalator.zip');
    };

    const onSubmit = async (form: any) => {
        const response = await changePrinterApi({
            printerId: requireValue(printerId),
            restaurantId: restaurantId,
            type: form.type,
            menuCategoryIds: form.menuCategoryIds,
            printerContent: form.printerContent === translate('Both') ? undefined : form.printerContent,
            deviceName: form.deviceName,
            printerBrand: form.printerBrand,
            ipAddress: form.ipAddress,
            port: form.port,
            disabled: form.disabled,
            isTabletPrinter: form.isTabletPrinter,
            serialNumber: form.serialNumber,
            channels: form.channels,
            printerPaperSize: form.printerPaperSize,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onPrinterChanged();
        onClose();
        if (isBluetoothPrinter(form.type)) setRestaurantHasBluetoothPrintersInMobileApp(!form.disabled);
    };

    return (
        <Dialog open={open} classes={{ dialogContainer: classes.dialogContainer, dialog: classes.dialog }} title={translate('Edit Printer')}>
            <Form form={form} onSubmit={onSubmit} className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormPrinterTypeSelect name='type' label={translate('Printer Type')} required filter={supportedPrinterTypes} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormPrinterBrandSelect
                            name='printerBrand'
                            label={translate('Printer Brand')}
                            required
                            filter={isMobileApp() ? undefined : [PrinterBrands.STAR, PrinterBrands.EPSON, PrinterBrands.GHIA, PrinterBrands.PIDEDIRECTO, PrinterBrands.IMIN]}
                            helperText={
                                isSunmiPrinter(printerBrand) ? translate('Verify the printer is active in your device: Settings > Connected devices > Printer > Default printer service > Active') : ''
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormPrinterContentSelect name='printerContent' label={translate('Printer Content')} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormAppAutocompleteMultiple name='channels' label={translate('Channels')} helperText={translate('If this is blank will be all the channels')} filter={channels} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField name='deviceName' label={translate('Device Name (Eg: Cocina)')} />
                    </Grid>
                    {printerContent === PrinterContents.COMMAND && (
                        <Grid item xs={12}>
                            <FormCategoryAutocompleteMultiple name='menuCategoryIds' label={translate('Menu Categories')} menuChannels={[Apps.PIDEDIRECTOPOS, Apps.PIDEDIRECTO]} />
                        </Grid>
                    )}

                    {isMobileApp() && printerType === PrinterTypes.WIFI && (
                        <Grid item xs={12}>
                            <FormTextField name='ipAddress' label={translate('Ip Address (Eg: 192.168.1.105)')} />
                        </Grid>
                    )}
                    {isMobileApp() && printerType === PrinterTypes.WIFI && (
                        <Grid item xs={12}>
                            <FormTextField name='port' label={translate('Port (Leave it empty if not known)')} />
                        </Grid>
                    )}
                    {isMobileApp() && printerType === PrinterTypes.BLUETOOTH && (
                        <Grid item xs={12}>
                            <DialogTitle>{translate('Make sure that the bluetooth printer is paired with your device')}</DialogTitle>
                        </Grid>
                    )}
                    {!isMobileApp() && (printerType === PrinterTypes.BLUETOOTH || printerType === PrinterTypes.WIFI) && (
                        <div className={classes.downloadDriversContainer}>
                            <p className={classes.dialogSubtitle}>{translate('Select a Printer')}</p>

                            <FormDriverPrintersSelect name='driverprinterselected' label={translate('Selected printer name:')} required />

                            <Button onClick={handleDriverDownload} secondary disabled={isSubmitting} classes={{ button: classes.secondaryButton }}>
                                <div>{translate('Download Drivers')}</div>
                            </Button>
                        </div>
                    )}
                    {printerType === PrinterTypes.USB && (
                        <Grid item xs={12}>
                            <FormTextField disabled name='serialNumber' label={translate('Serial Number')} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormPrinterPaperSizeSelect required name='printerPaperSize' label={translate('Paper Size')} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormCheckbox name='disabled' label={translate('Disabled')} />
                    </Grid>
                    {printerType === PrinterTypes.BLUETOOTH && (
                        <Grid item xs={12} md={6}>
                            <FormCheckbox name='isTabletPrinter' label={translate('Paper Size 58MM')} />
                        </Grid>
                    )}
                </Grid>
                <DialogActions className={classes.dialogActions}>
                    <Button secondary onClick={handleClose} type={'button'} disabled={isSubmitting || loading} classes={{ button: classes.button }}>
                        {translate('Cancel')}
                    </Button>
                    <Button primary type={'submit'} classes={{ button: classes.button }}>
                        {isSubmitting ? translate('Updating') : translate('Update')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        zIndex: 999,
    },
    dialog: {
        maxWidth: '100%',
        padding: '24px 42px',
        overflowY: 'scroll',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '480px',
    },
    title: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    dialogContent: {
        width: '70%',
        overflowY: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    inputDescription: {
        height: 170,
        alignItems: 'flex-start',
    },
    dialogActions: {
        display: 'flex',
        gap: 12,
        marginTop: 24,
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    secondaryButton: {
        marginTop: 12,
        alignSelf: 'end',
    },
    downloadDriversContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: 12,
    },
    dialogSubtitle: {
        color: '#0D3037',
        fontSize: 16,
        fontFamily: theme.typography.semiBold,
        marginTop: 0,
    },
    button: {
        fontSize: 14,
        padding: '12px 20px',
        fontFamily: theme.typography.medium,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onPrinterChanged: any;
    printerId?: PrinterId;
};
