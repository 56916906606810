/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { exportDriversBillingApi } from 'src/api/letseatadmin/driverBilling/exportDriversBillingApi';
import { DriverBillingVm, getDriverBillingApi } from 'src/api/letseatadmin/driverBilling/getDriverBillingApi';
import { app2 } from 'src/app2';
import { ExportToolbarButton } from 'src/components/mui-datatables/ExportToolbarButton';
import { OpenInNewTabToolbarButton } from 'src/components/mui-datatables/OpenInNewTabToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { DriverBonusTable } from 'src/scenes/letseatadmin/driverBonus/DriverBonusTable';
import { DriverManualEarnings } from 'src/scenes/letseatadmin/driverManualEarning/DriverManualEarnings';
import { OrdersTable } from 'src/scenes/letseatadmin/order/OrdersTable';
import type { DriverId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { fullName } from 'src/utils/customer/fullName';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { RegExps } from 'src/utils/RegExps';

export function DriverBilling({ filter, driverId }: Props): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [driverBillingVm, setDriverBillingVm] = useState<DriverBillingVm | undefined>();

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Driver Billing'));
        load();
    }, [filter.from, filter.to]);

    const load = async () => {
        setLoading(true);
        const response = await getDriverBillingApi({
            driverId,
            from: moment(filter.from).format('YYYY-MM-DD'),
            to: moment(filter.to).format('YYYY-MM-DD'),
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setDriverBillingVm(response.data);
    };

    const exportDriverBilling = async () => {
        const response = await exportDriversBillingApi({
            from: moment(filter.from).format('YYYY-MM-DD'),
            to: moment(filter.to).format('YYYY-MM-DD'),
            paymentDate: moment(filter.paymentDate).format('YYYY-MM-DD'),
            driverIds: [driverId],
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        downloadTextInFile(response.data.transactionExport, `driver-billing-export-${moment(filter.from).format('YYYY-MM-DD')}--${moment(filter.to).format('YYYY-MM-DD')}.txt`);
        alert(
            [
                `exported: ${response.data.exported}`,
                `banned: ${response.data.banned}`,
                `removed: ${response.data.removed}`,
                `accountNotExported: ${response.data.accountNotExported}`,
                `nothingToTransfer: ${response.data.nothingToTransfer}`,
            ].join('\n')
        );
    };

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Card classes={{ root: classNames(classes.root, classes.cardContainer) }}>
                    <UpdatingContentProgress loading={loading} />
                    <CardHeader
                        title={
                            <Typography display='block' gutterBottom variant='h6'>
                                {fullName(driverBillingVm?.firstName, driverBillingVm?.lastName)}
                                {driverBillingVm?.banned && (
                                    <Box component='span' color='error.main'>
                                        {' '}
                                        (BANNED)
                                    </Box>
                                )}
                            </Typography>
                        }
                        action={
                            <div>
                                <ExportToolbarButton onClick={exportDriverBilling} tooltip={translate('Export As Bank Transaction')} />
                                <OpenInNewTabToolbarButton onClick={() => window.open(RoutePaths.ADMIN_DRIVER.replace(`:driverId(${RegExps.uuid})`, driverId), '_blank')} />
                                <RefreshToolbarButton onClick={load} />
                            </div>
                        }
                    />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Email
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.email}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Curp
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.curp}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    RFC
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.rfc}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    {translate('Clave Id')}
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.claveId}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    {translate('CLABE')}
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.clabe}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Total Sold
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.total}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Bonus
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.bonus}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Cash
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.cash}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    App
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.app}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Invoiced Deliveries
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.invoicedRestaurantDeliveryCost}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Total Sold
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.total}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Bonus
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.bonus}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Manual Earning
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.manualEarning}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Driver Referral Earnings
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.driverReferral}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Commission
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.commission}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    IVA
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.iva}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    ISR
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.isr}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Deposit
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.deposit}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Deliveries
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.deliveries}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Invoiced
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.invoicedRestaurantDeliveryCostDeliveries}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Delivered
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.delivered}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Cancelled
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.cancelled}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Bonuses
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.bonuses}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Bonuses
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.dynamicDeliveryEarning}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Delivery Cash Commission
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.deliveryCommission}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Manual Earnings
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.manualEarnings}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Driver Referrals Earnings
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {driverBillingVm?.driverReferrals}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <DriverBonusTable title='Bonuses' driverBonuses={driverBillingVm?.driverBonuses ?? []} loading={loading} onClickRefresh={load} />
            </Grid>
            <Grid item xs={12}>
                <DriverManualEarnings driverId={driverId} filter={filter} />
            </Grid>
            <Grid item xs={12}>
                <OrdersTable title='OrdersPage' orders={driverBillingVm?.orders ?? []} loading={loading} onClickRefresh={load} />
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        position: 'relative',
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
}));

type Props = {
    filter: {
        from: Date;
        to: Date;
        paymentDate: Date;
    };
    driverId: DriverId;
};
