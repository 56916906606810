/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent } from '@pidedirecto/ui';
import { Form, FormPercentNumberField } from '@pidedirecto/ui/form';
import { useForm, useNotification } from '@pidedirecto/ui/hooks';
import { useState } from 'react';
import * as React from 'react';
import { createRestaurantMenuPromotionApi } from 'src/api/letseatmanager/menu/createRestaurantMenuPromotionApi';
import { translate } from 'src/i18n/translate';
import type { MenuVm } from 'src/types/MenuVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function CreateRestaurantMenuPromotionDialog({ open, menu, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;
    const classes = useStyles();
    const notification = useNotification();

    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        if (isSubmitting || loading) return;
        onClose();
    };

    const onSubmit = async (form: FormType) => {
        if (!menu) return;

        setLoading(true);
        const response = await createRestaurantMenuPromotionApi({
            menuId: menu.menuId,
            promoDiscount: form.promoDiscount,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        notification({ message: translate(`Created ${form.promoDiscount * 100}% restaurant menu promotion`) });
        onSuccess?.();
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Create Restaurant Menu Promotion')} loading={loading}>
            <Form onSubmit={onSubmit} form={form}>
                <DialogContent>
                    <FormPercentNumberField
                        name='promoDiscount'
                        label={translate('Discount')}
                        helperText={translate('In percentage, e.g. type 20 to create a 20% discount promotion. To reset all promo prices type 0')}
                        required={{
                            value: true,
                            message: translate('This field is required'),
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='secondary' onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {loading ? translate('Creating') : translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

type FormType = {
    promoDiscount: number;
};

type Props = {
    open: boolean;
    menu?: MenuVm;
    onClose: any;
    onSuccess?: any;
};
