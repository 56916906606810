/**
 * @prettier
 */
import * as React from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { DefaultTips } from 'src/constants/DefaultTip';
import { formatAsPercentage } from 'src/utils/number/formatAsPercentage';

export function FormTipSelect({ name, label, placeholder, helperText, additionalOptions, defaultValue, disabled, required, variant, onChange }: Props): React.ReactElement {
    let options = required
        ? Object.values(DefaultTips).map((defaultTip: any) => ({ label: formatAsPercentage(defaultTip), value: defaultTip }))
        : [{ label: formatAsPercentage(0), value: null }, ...Object.values(DefaultTips).map((defaultTip: any) => ({ label: formatAsPercentage(defaultTip), value: defaultTip }))];

    if (additionalOptions && !Object.values(DefaultTips).includes(additionalOptions as any)) {
        const filteredAdditionalOptions = additionalOptions.filter((additionalOption) => !Object.values(DefaultTips).includes(additionalOption.value as any));
        options = [...options, ...filteredAdditionalOptions];
    }

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={required}
            variant={variant}
            onChange={onChange}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    additionalOptions?: Array<{
        value: number;
        label: string;
    }>;
    variant?: Variant;
    onChange?: any;
};
