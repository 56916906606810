/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { updateCurrencyApi } from 'src/api/letseatmanager/restaurant/updateCurrencyApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCurrencySelect } from 'src/components/form/FormCurrencySelect';
import { FormNumberStringField } from 'src/components/form/FormNumberStringField';
import { Currencies } from 'src/constants/Currency';
import { translate } from 'src/i18n/translate';
import { useNotification } from 'src/services/notification/useNotification';
import type { RestaurantCurrency } from 'src/types/RestaurantCurrency';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function UpdateCurrencyDialog({ open, restaurantCurrency, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();

    const notification = useNotification();

    const [loading, setLoading] = useState(false);

    const mainCurrency = useSelector((state) => state.app.restaurant?.currencyFormat?.currency);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const exchangeRate = form.watch('exchangeRate');

    useEffect(() => {
        if (restaurantCurrency) {
            form.reset({
                currency: restaurantCurrency.currency,
                exchangeRate: restaurantCurrency.exchangeRate,
            });
        }
    }, [restaurantCurrency]);

    const handleSubmit = async () => {
        if (!restaurantCurrency) return;
        if (!exchangeRate) return notification({ message: translate('There are missing fields') });

        setLoading(true);
        const response = await updateCurrencyApi({ restaurantId, restaurantCurrency: { currency: restaurantCurrency?.currency, exchangeRate } });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        onSuccess();
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} title={translate('Change currency')}>
            <Form form={form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormCurrencySelect name='currency' label={translate('Currency')} disabled={true} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormNumberStringField disabled={loading} name='exchangeRate' label={translate('Exchange type (@mainCurrency)', { mainCurrency: mainCurrency ?? Currencies.MXN })} required />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button onClick={onClose} disabled={loading} secondary>
                        {translate('Cancel')}
                    </Button>
                    <Button onClick={handleSubmit} disabled={loading}>
                        {translate('Change')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    restaurantCurrency?: RestaurantCurrency;
    onClose: any;
    onSuccess: any;
};
