/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function toggleDeliverOrdersFromDidiFoodApi(request: ToggleDeliverOrdersFromDidiFoodApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurantIntegration/toggleDeliverOrdersFromDidiFoodApi', request);
}

type ToggleDeliverOrdersFromDidiFoodApiRequest = {
    restaurantId: RestaurantId;
};
