/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { SelectItemsDialog } from 'src/components/dialog/SelectItemsDialog';
import { ModifierTypes } from 'src/constants/ModifierType';
import { translate } from 'src/i18n/translate';
import { useMenuItems } from 'src/services/menuItem/useMenuItems';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import type { ModifierVm } from 'src/types/ModifierVm';
import { removeNulls } from 'src/utils/removeNulls';

export function SelectModifiersDialog({ open, defaultSelectedModifiers, onClose, onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const { modifiers } = useMenuItems();

    const previousModifiers = defaultSelectedModifiers?.map((modifier: ModifierVm) => modifier.menuItemId);

    const handleDialogSuccess = (menuItemIds: any) => {
        const newModifierIds = menuItemIds?.filter((menuItemId: any) => !previousModifiers?.includes(menuItemId));
        const modifiersUpdated = defaultSelectedModifiers?.filter((modifier: ModifierVm) => menuItemIds?.includes(modifier.menuItemId));

        const newModifiers = removeNulls(
            newModifierIds?.map((menuItemId: any) => {
                const modifier = modifiers?.find((modifier: ManagerMenuItemVm) => modifier.menuItemId === menuItemId);
                if (!modifier) return null;
                return {
                    menuItemId: modifier?.menuItemId,
                    name: modifier?.name,
                    description: modifier?.description,
                    price: modifier?.modifierPrice ?? modifier?.price,
                    modifierType: ModifierTypes.SINGLE,
                };
            })
        );

        onSuccess?.([...(modifiersUpdated ?? []), ...(newModifiers ?? [])]);
    };

    const items = modifiers.map((modifier) => ({
        value: modifier.menuItemId,
        searchableBy: modifier.name,
        item: modifier,
    }));

    return (
        <SelectItemsDialog
            open={open}
            title={translate('Select your modifiers')}
            items={items}
            defaultSelectedItems={previousModifiers}
            renderItem={({ item: modifier }: { item: ManagerMenuItemVm }) => {
                const modifierUpdated = defaultSelectedModifiers?.find((modifiersSelected: ModifierVm) => modifiersSelected.menuItemId === modifier.menuItemId);
                return (
                    <div className={classes.modifierContainer}>
                        <div className={classes.modifierNameContainer}>
                            <span className={classes.text}>{modifier.name}</span>
                            {modifierUpdated?.price && <span className={classes.subText}> {translate(`Original price: @price`, { price: formatAsCurrencyNumber(modifier.price) })}</span>}
                        </div>
                        <span className={classes.text}>{formatAsCurrencyNumber(modifier?.modifierPrice ?? modifier.price)}</span>
                    </div>
                );
            }}
            onClose={onClose}
            onSuccess={handleDialogSuccess}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    modifierContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    modifierNameContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    subText: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        color: '#898B8F',
    },
}));

type Props = {
    open: boolean;
    defaultSelectedModifiers?: Array<ModifierVm>;
    onClose: any;
    onSuccess?: any;
};
