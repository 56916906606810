/**
 * @prettier
 */
import type { Zone } from 'src/types/Id';

export const KEY_NAME = 'zoneOptions';

export function getZoneOptionsInLocalStorage(): Array<ZoneOption> {
    const item = window.localStorage.getItem('zoneOptions');
    return item ? JSON.parse(item) : [];
}

export type ZoneOption = {
    label: Zone;
    value: Zone;
};
