/**
 * @prettier
 */
import * as React from 'react';

export function ClockOutlineIcon({ color, title }: Props): React.ReactElement {
    return (
        <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                d='M6.08313 2.66667V6H8.58313M11.0831 6C11.0831 6.65661 10.9538 7.30679 10.7025 7.91342C10.4513 8.52005 10.083 9.07124 9.61866 9.53553C9.15437 9.99983 8.60318 10.3681 7.99655 10.6194C7.38992 10.8707 6.73974 11 6.08313 11C5.42652 11 4.77634 10.8707 4.16971 10.6194C3.56308 10.3681 3.01189 9.99983 2.5476 9.53553C2.0833 9.07124 1.71501 8.52005 1.46373 7.91342C1.21246 7.30679 1.08313 6.65661 1.08313 6C1.08313 4.67392 1.60991 3.40215 2.5476 2.46447C3.48528 1.52678 4.75705 1 6.08313 1C7.40921 1 8.68098 1.52678 9.61866 2.46447C10.5563 3.40215 11.0831 4.67392 11.0831 6Z'
                stroke={color || 'currentColor'}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    color?: string;
    title?: string;
};
