/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { depositCashRegisterApi, DepositCashRegisterApiRequest } from 'src/api/letseatmanager/pos/depositCashRegisterApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCashRegisterDepositReasonSelect } from 'src/components/form/FormCashRegisterDepositReasonSelect';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { getPosDepositsOfflineInLocalStorage } from 'src/localStorage/getPosDepositsOfflineInLocalStorage';
import { setPosDepositsOfflineInLocalStorage } from 'src/localStorage/setPosDepositsOfflineInLocalStorage';
import { useFetchCashRegisterTransactions } from 'src/services/cashRegister/useFetchCashRegisterTransactions';
import { usePrintCashRegisterTransaction } from 'src/services/printer/usePrintCashRegisterTransaction';
import { useOpenCashRegister } from 'src/services/printer/utils/useOpenCashRegister';
import type { CashRegisterId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';
import { newUuid } from 'src/utils/uuid/newUuid';

export function DepositCashRegisterDialog({ open, cashRegisterId, handleClose }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const { fetchCashRegisterTransactions } = useFetchCashRegisterTransactions();
    const printCashRegisterTransaction = usePrintCashRegisterTransaction();
    const openCashRegister = useOpenCashRegister();

    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);
    const printCashRegisterTransactionsEnabled = useSelector((state) => state.app.restaurant?.printCashRegisterTransactionsEnabled);
    const openCashRegisterForTransactionsEnabled = useSelector((state) => state.app.restaurant?.openCashRegisterForTransactionsEnabled);

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (form: any) => {
        setLoading(true);
        const request: DepositCashRegisterApiRequest = {
            cashRegisterId,
            managerUserId: pinCodeUserSignedIn?.managerUserId,
            amount: form.amount,
            reason: form.reason,
            comment: form.comment,
        } as const;

        const response = await depositCashRegisterApi(request);

        if (!response.ok) {
            setLoading(false);
            if (response.problem === 'NETWORK_ERROR') {
                savePosDepositOffline(request);
                return handleClose();
            }
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        await fetchCashRegisterTransactions();
        setLoading(false);
        handleClose();

        if (openCashRegisterForTransactionsEnabled && !printCashRegisterTransactionsEnabled) await openCashRegister();
        if (printCashRegisterTransactionsEnabled) {
            await printCashRegisterTransaction(response.data, { openCashBox: openCashRegisterForTransactionsEnabled, forceToPrint: true });
        }
    };

    const savePosDepositOffline = (posDepositRequest: DepositCashRegisterApiRequest) => {
        const posDepositsOffline = getPosDepositsOfflineInLocalStorage();
        setPosDepositsOfflineInLocalStorage([...posDepositsOffline, { ...posDepositRequest, temporalCashRegisterTransactionId: newUuid(), createdAt: new Date() }]);
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Deposit')} classes={{ dialog: classes.dialog }}>
            <Form onSubmit={handleSubmit} form={form} className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormCurrencyNumberStringField name='amount' disabled={loading} label={translate('Amount')} required />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormCashRegisterDepositReasonSelect name='reason' label={translate('Deposit Reason')} required disabled={loading} />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name='comment' label={translate('Comment')} disabled={loading} maxLength={50} />
                    </Grid>
                </Grid>
                <DialogActions className={classes.buttonsContainer}>
                    <Button secondary onClick={handleClose} disabled={loading} classes={{ button: classes.button }}>
                        {translate('Cancel')}
                    </Button>
                    <Button primary type={'submit'} disabled={loading} classes={{ button: classes.button }}>
                        {loading ? translate('Depositing') : translate('Deposit')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        minWidth: 540,
        padding: '24px 48px',
        [theme.breakpoints.down('sm')]: {
            minWidth: 250,
            width: '80%',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 18,
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    button: {
        width: '50%',
        [theme.breakpoints.up('xs')]: {
            width: '100%',
        },
    },
}));

type Props = {
    open: boolean;
    cashRegisterId?: CashRegisterId;
    handleClose: any;
};
