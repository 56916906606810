/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStore } from '@pidedirecto/ui/hooks';
import moment from 'moment';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { PaymentTerminalPaymentResultMock } from 'src/api/letseatmanager/paymentTerminalPayment/pushPaymentTerminalPaymentApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { Form } from 'src/components/form/Form';
import { FormIntegerFieldWithIncrementalButtons } from 'src/components/form/FormIntegerFieldWithIncrementalButtons';
import { FormPaymentTerminalPaymentFailedReasonSelect } from 'src/components/form/FormPaymentTerminalPaymentFailedReasonSelect';
import { FormPaymentTerminalPaymentStatusSelect } from 'src/components/form/FormPaymentTerminalPaymentStatusSelect';
import { PaymentTerminalPaymentStatuses } from 'src/constants/PaymentTerminalPaymentStatus';
import { translate } from 'src/i18n/translate';

export function SelectPaymentTerminalPaymentResultMockDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: {},
        control,
    } = form;

    const open = useSelectPaymentTerminalPaymentResultMockDialogStore((state) => state.open);
    const onSelectMock = useSelectPaymentTerminalPaymentResultMockDialogStore((state) => state.onSelectMock);
    const onCloseDialog = useSelectPaymentTerminalPaymentResultMockDialogStore((state) => state.onCloseDialog);

    const closeDialog = useSelectPaymentTerminalPaymentResultMockDialogActions((actions) => actions.closeDialog);

    const paymentTerminalPaymentStatus = useWatch({ name: 'paymentTerminalPaymentStatus', control });

    const handleClose = () => {
        onCloseDialog();
        closeDialog();
    };

    const onSubmit = async (form: any) => {
        onSelectMock({
            paymentTerminalPaymentStatus: form.paymentTerminalPaymentStatus,
            paymentTerminalPaymentFailedReason: form.paymentTerminalPaymentFailedReason,
            respondAt: !form.responseTimeInSeconds ? undefined : moment().add(form.responseTimeInSeconds, 'seconds').toDate(),
        });
        handleClose();
    };

    return (
        <Dialog title={translate('Select mocked payment behavior')} open={open} onClose={handleClose} classes={{ dialogContainer: classes.dialogContainer }}>
            <Form form={form} onSubmit={onSubmit}>
                <div>
                    <Grid container spacing={3} style={{ padding: '20px 40px' }}>
                        <Grid item xs={12} md={12}>
                            <FormPaymentTerminalPaymentStatusSelect name='paymentTerminalPaymentStatus' label={translate('Payment Status')} required />
                        </Grid>
                    </Grid>
                    {paymentTerminalPaymentStatus === PaymentTerminalPaymentStatuses.FAILED && (
                        <Grid container spacing={3} style={{ padding: '20px 40px' }}>
                            <Grid item xs={12} md={12}>
                                <FormPaymentTerminalPaymentFailedReasonSelect label={translate('Payment Failed Reason')} name='paymentTerminalPaymentFailedReason' />
                            </Grid>
                        </Grid>
                    )}
                    {!!paymentTerminalPaymentStatus && (
                        <Grid container spacing={3} style={{ padding: '20px 40px' }}>
                            <Grid item xs={12} md={12}>
                                <FormIntegerFieldWithIncrementalButtons label={translate('Response Time')} text='secs' name='responseTimeInSeconds' defaultValue={0} />
                            </Grid>
                        </Grid>
                    )}
                </div>
                <div className={classes.buttonsContainer}>
                    <Button primary type={'submit'} classes={{ button: classes.button }}>
                        {translate('Create payment behavior')}
                    </Button>
                </div>
            </Form>
        </Dialog>
    );
}

export const [useSelectPaymentTerminalPaymentResultMockDialogStore, useSelectPaymentTerminalPaymentResultMockDialogActions] = createStore<State, Actions>({
    initialState: {
        open: false,
        onSelectMock: () => {},
        onCloseDialog: () => {},
    },
    actions: {
        openDialog: (state, params) => {
            state.open = true;
            state.onSelectMock = params.onSelectMock;
            state.onCloseDialog = params.onCloseDialog;
        },
        closeDialog: (state) => {
            state.open = false;
            state.onSelectMock = () => {};
            state.onCloseDialog = () => {};
        },
    },
});

type State = {
    open: boolean;
    onSelectMock: (mock: PaymentTerminalPaymentResultMock) => any;
    onCloseDialog: () => any;
};

type Actions = {
    openDialog: (params: { onSelectMock: State['onSelectMock']; onCloseDialog: State['onCloseDialog'] }) => void;
    closeDialog: () => void;
};

export function useOpenSelectPaymentTerminalPaymentResultMockDialog() {
    return useSelectPaymentTerminalPaymentResultMockDialogActions((actions) => actions.openDialog);
}

const useStyles = makeStyles((theme) => ({
    form: {
        maxWidth: 540,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        padding: '16px 8px',
    },
    dialogContainer: {
        zIndex: 1001,
    },
    buttonsContainer: {
        display: 'flex',
        gap: 12,
        marginTop: 24,
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    button: {
        fontSize: 14,
        padding: '12px 20px',
        fontFamily: theme.typography.medium,
    },
}));
