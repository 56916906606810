/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { EmailAddress, ManagerUserId, PinCode, RestaurantId, UserRoleId } from 'src/types/Id';
import type { UserVm } from 'src/types/UserVm';

export async function changeRestaurantUserApi(request: ChangeRestaurantUserApiRequest): ApiSauceResponse<UserVm> {
    return letseatmanagerApiMethod('userManagement/changeRestaurantUserApi', request);
}

type ChangeRestaurantUserApiRequest = {
    managerUserId?: ManagerUserId;
    pinCode?: PinCode;
    name: string;
    email: EmailAddress;
    userRoleId?: UserRoleId;
    restaurantIds?: Array<RestaurantId>;
};
