/**
 * @prettier
 */
import * as React from 'react';
import { usePosPaymentsSubscriptionUpdates } from 'src/services/pos/posPaymentMethods/usePosPaymentsSubscriptionUpdates';

export function PosPaymentsSubscriptionUpdates(): React.ReactElement | null {
    usePosPaymentsSubscriptionUpdates();

    return null;
}
