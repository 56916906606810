/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import { PosState } from 'src/reducers/posReducer';
import type { CartItemVm } from 'src/types/CartItemVm';
import { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import { refreshPosPaymentCustomerState } from 'src/utils/pos/refreshPosPaymentCustomerState';

export function addCustomerPosItem(state: PosState, item: CartItemVm): void {
    if (!state.customerNumberSelected) return;
    const customer = state.customers?.find((customer: PosCustomerOrderDetails) => customer.customerNumber === state.customerNumberSelected);

    if (!customer) return;

    const existingPosItem = customer.orderItems?.find((current: CartItemVm) => current.key === item.key);

    if (existingPosItem) {
        existingPosItem.quantity = BigNumber(existingPosItem.quantity).plus(item.quantity).toNumber();
    } else {
        customer.orderItems = [...customer.orderItems, item];
    }

    refreshPosPaymentCustomerState({ customer, payments: state.payments, promotions: state.promotions });
}
