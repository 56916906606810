/**
 * @prettier
 */
export const DriverManualEarningReasons = {
    BACK_TO_RESTAURANT: 'BACK_TO_RESTAURANT',
    KM_ADJUSTMENT: 'KM_ADJUSTMENT',
    REFUND: 'REFUND',
    RETURN_RESTAURANT: 'RETURN_RESTAURANT',
    UNPAID_DELIVERY: 'UNPAID_DELIVERY',
    WAIT_FOR_RESTAURANT: 'WAIT_FOR_RESTAURANT',
    DAILY_BONUS: 'DAILY_BONUS',
    QA_NOT_APPLIED: 'QA_NOT_APPLIED',
    NOT_APPLIED_DYNAMICS: 'NOT_APPLIED_DYNAMICS',
    ACCOUNT_UNBLOCKING: 'ACCOUNT_UNBLOCKING',
    EXCESSIVE_WEIGHT: 'EXCESSIVE_WEIGHT',
    FIXED: 'FIXED',
    NON_REFLECTED_BONUS: 'NON_REFLECTED_BONUS',
    NON_COMPLIANCE_IN_BONUS: 'NON_COMPLIANCE_IN_BONUS',
    DRIVER_REFERRAL: 'DRIVER_REFERRAL',
    OTHER: 'OTHER',
} as const;

export type DriverManualEarningReason = typeof DriverManualEarningReasons[keyof typeof DriverManualEarningReasons];
