/**
 * @prettier
 */
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import LaunchIcon from '@material-ui/icons/Launch';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { app2 } from 'src/app2';
import { Chip } from 'src/components/Chip';
import { AppIcon } from 'src/components/icon/AppIcon';
import { OrderCardDeprecated } from 'src/components/orderCard/OrderCardDeprecated';
import { OrderDetailsDialog } from 'src/components/orderCard/OrderDetailsDialog';
import { App } from 'src/constants/App';
import type { ExternalDeliveryProvider } from 'src/constants/ExternalDeliveryProviders';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { OrderTags } from 'src/constants/OrderTag';
import type { OrderType } from 'src/constants/OrderType';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { PickupTimeType, PickupTimeTypes } from 'src/constants/PickupTimeType';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import { useStyles } from 'src/scenes/letseatmanager/currentOrders/CurrentOrdersStyles';
import { LocalizationService } from 'src/services/LocalizationService';
import { OrderId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrongError } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrongError';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { isToday } from 'src/utils/date/isToday';
import { copyTextToClipboard } from 'src/utils/html/copyTextToClipboard';
import { getDeliveryProvider } from 'src/utils/order/getDeliveryProvider';
import { getOrderStatus } from 'src/utils/order/getOrderStatus';
import { sortOrdersByCreatedAt } from 'src/utils/order/sortOrdersByCreatedAt';
import { toHumanizedOrderId } from 'src/utils/order/toHumanizedOrderId';
import { useAction } from 'src/utils/react/useAction';
import { useInterval } from 'src/utils/react/useInterval';
import { useSelector } from 'src/utils/react/useSelector';

export function OrdersCarrousel({ orders, filterType }: Props): React.ReactElement {
    const classes = useStyles();
    const [orderDetailsDialogState, setOrderDetailsDialogState] = useState<{ open: boolean; order: OrderVm | undefined }>({ open: false, order: undefined });
    const [highlighted, setHighlighted] = useState(true);

    useInterval(() => toggleHighlight(), 1 * SECONDS, [highlighted]);

    const toggleHighlight = () => setHighlighted(!highlighted);

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const consecutiveOrderIdEnabled = useSelector((state) => state.app.restaurant?.consecutiveOrderIdEnabled);

    const updateOrder = useAction(app2.actions.updateOrder);

    const ordersWithoutDriverSorted = orders.filter((order) => !order.driverId && !order.driverName).sort((orderA, orderB) => sortOrdersByCreatedAt(orderA, orderB));
    const getPaymentMethod = {
        CASH: translate('💵 Cash'),
        PAYMENT_LINK: translate('💳 Payment Link'),
        CREDIT_CARD: translate('💳 Credit Card'),
    } as const;
    const ordersWithDriverSorted = orders
        .filter((order) => !ordersWithoutDriverSorted.some((orderWithoutDriver) => orderWithoutDriver.orderId === order.orderId))
        .sort((orderA, orderB) => sortOrdersByCreatedAt(orderA, orderB));

    const onChangeOrder = (order: OrderVm) => updateOrder({ order });

    const getOrderAmount = (order: any) => {
        const payWithCash = order.paymentMethod === PaymentMethods.CASH;
        return BigNumber(order.amountWithPromoPriceDiscount)
            .minus((payWithCash ? order.promoCodeDiscount : order.restaurantPromoCodeCost) ?? 0)
            .minus(order.restaurantDeliveryCost ?? 0)
            .toString();
    };

    const handleCloseOrderDetailsDialog = () => setOrderDetailsDialogState({ open: false, order: undefined });

    const copyOrderToClipboard = (order: any) => {
        const textOrder =
            `Desde: ${order.restaurant?.name} (${order.restaurant?.street})\n` +
            `Hasta: ${order.address.formattedAddress}\n` +
            `Precio de la orden: ${formatAsCurrencyNumber(getOrderAmount(order), { country: order.country as any })}\n` +
            `Ganacia: ${formatAsCurrencyNumber(order.deliveryCost, { country: order.country as any })}\n` +
            `Metodo de pago: ${order.paymentMethod === PaymentMethods.CASH ? translate('💵 Cash') : translate('💳 Credit Card')}`;
        copyTextToClipboard(textOrder);
    };

    const getOrderStatusClass = (order: any) => {
        const orderIsArrivedAtClient = moment(order.arrivedAtClientAt).add(5, 'minutes').isBefore(moment()) && highlighted;
        const orderIsCreatedAt = moment(order.createdAt).add(10, 'minutes').isBefore(moment()) && !order.driverId && !order.driverName;
        const orderIsPickedUp = moment(order.pickedUpAt).add(15, 'minutes').isBefore(moment()) && !order.arrivedAtClientAt && order.pickedUpAt;
        const orderIsAccepted = moment(order.deliveryAcceptedAt).add(15, 'minutes').isBefore(moment()) && !order.arrivedAtStoreAt && order.deliveryAcceptedAt;
        const newOrder = order.orderStatus === OrderStatuses.NEW && highlighted;

        if (orderIsArrivedAtClient) return (classes as any).red;
        if (orderIsCreatedAt) return (classes as any).red;
        if (orderIsPickedUp) return (classes as any).orange;
        if (orderIsAccepted) return (classes as any).yellow;
        if (newOrder) return (classes as any).highlighted;
    };

    if (internalUser) {
        const columns = [
            {
                name: 'channel',
                label: translate('Channel'),
                options: {
                    filter: false,
                    customBodyRender: (channel: App) => <AppIcon app={channel} />,
                },
            },
            {
                name: 'delivery',
                label: translate('Delivery'),
                options: {
                    filter: false,
                    customBodyRender: (delivery: App) => <AppIcon app={delivery} />,
                },
            },
            {
                name: 'restaurantName',
                label: translate('Restaurant'),
                options: {
                    searchable: true,
                    customBodyRender: (order: OrderVm) => {
                        const cashDeliveryProviders = order.restaurant?.cashDeliveryProviders?.map((cashDeliveryProvider: any) => translate(`DeliveryProviders.${cashDeliveryProvider}`)).join(', ');
                        const cardDeliveryProviders = order.restaurant?.cardDeliveryProviders?.map((cardDeliveryProvider: any) => translate(`DeliveryProviders.${cardDeliveryProvider}`)).join(', ');
                        return (
                            <div className={(classes as any).restaurantNameContainer}>
                                <span className={getOrderStatusClass(order)}>{order.restaurant?.name}</span>
                                {!!cashDeliveryProviders && <span className={(classes as any).deliveryProviderText}>{translate('Cash: @cashDeliveryProviders', { cashDeliveryProviders })}</span>}
                                {!!cardDeliveryProviders && <span className={(classes as any).deliveryProviderText}>{translate('Card: @cardDeliveryProviders', { cardDeliveryProviders })}</span>}
                            </div>
                        );
                    },
                },
            },
            {
                name: 'salesforceRestaurantType',
                label: translate('Salesforce'),
                options: {
                    searchable: true,
                    customBodyRender: (order: OrderVm) => <div>{order.restaurant?.salesforceRestaurantType && <Chip>{order.restaurant?.salesforceRestaurantType}</Chip>}</div>,
                },
            },
            {
                name: 'customerName',
                label: translate('Customer'),
                options: {
                    searchable: true,
                    customBodyRender: (order: OrderVm) => <span className={getOrderStatusClass(order)}>{order.customerName}</span>,
                },
            },
            {
                name: 'zone',
                label: translate('Zone'),
                options: {
                    searchable: true,
                    customBodyRender: (order: OrderVm) => <span className={getOrderStatusClass(order)}>{order?.zone}</span>,
                },
            },
            {
                name: 'driverName',
                label: translate('Driver'),
                options: {
                    searchable: true,
                    customBodyRender: (order: OrderVm) => <span className={getOrderStatusClass(order)}>{order.driverName}</span>,
                },
            },
            {
                name: 'orderId',
                label: translate('Service id'),
                options: {
                    searchable: true,
                    customBodyRender: (order: OrderVm) => {
                        const showConsecutiveOrderId = consecutiveOrderIdEnabled && order.consecutiveOrderId;

                        return <span className={getOrderStatusClass(order)}>{showConsecutiveOrderId ? order.consecutiveOrderId : toHumanizedOrderId(order.orderId)}</span>;
                    },
                },
            },
            {
                name: 'paymentMethod',
                label: translate('Payment method'),
                options: {
                    searchable: true,
                    customBodyRender: (order: OrderVm) => <span className={getOrderStatusClass(order)}>{getPaymentMethod[order.paymentMethod as keyof typeof getPaymentMethod]}</span>,
                },
            },
            {
                name: 'price',
                label: translate('Order price'),
                options: {
                    searchable: true,
                    customBodyRender: (order: OrderVm) => <span className={getOrderStatusClass(order)}>{formatAsCurrencyNumber(getOrderAmount(order), { country: order.country as any })}</span>,
                },
            },
            {
                name: 'deliveryCost',
                label: translate('Delivery cost'),
                options: {
                    searchable: true,
                    customBodyRender: (order: OrderVm) => <span className={getOrderStatusClass(order)}>{formatAsCurrencyNumber(order.deliveryCost, { country: order.country as any })}</span>,
                },
            },
            {
                name: 'cookingTime',
                label: translate('Cooking time'),
                options: {
                    filter: true,
                    // @ts-ignore
                    // TODO: preparationTimeInMinutes is not in OrderVm type should it be preparationTime?
                    customBodyRender: (order: OrderVm) => <span className={getOrderStatusClass(order)}>{order?.preparationTimeInMinutes || 15} min</span>,
                },
            },
            {
                name: 'orderStatus',
                label: translate('Order status'),
                options: {
                    searchable: true,
                    customBodyRender: (order: OrderVm) => {
                        return <span className={getOrderStatusClass(order)}>{getOrderStatus(order)}</span>;
                    },
                },
            },
            {
                name: 'createdAt',
                label: translate('Created at'),
                options: {
                    searchable: true,
                    customBodyRender: (order: OrderVm) => <span className={getOrderStatusClass(order)}>{formatDateTimeString(order.createdAt, order.timeZone)}</span>,
                },
            },
            {
                name: 'quickAction',
                label: translate('Quick Action'),
                options: {
                    searchable: true,
                    customBodyRender: (order: OrderVm) => {
                        const quickActions = order.tags?.filter((tag: any) => tag.type === OrderTags.QUICK_ACTION);
                        const hasQuickActions = quickActions && quickActions?.length > 0;
                        return (
                            hasQuickActions && (
                                <center>
                                    <span className={getOrderStatusClass(order)}>
                                        <CheckCircleIcon />
                                    </span>
                                </center>
                            )
                        );
                    },
                },
            },
            {
                name: 'pickedUpAt',
                label: translate('Picked up at'),
                options: {
                    searchable: true,
                    customBodyRender: (order: OrderVm) => (
                        <span className={getOrderStatusClass(order)}>
                            {order.pickedUpAt
                                ? LocalizationService.formatDateTimeAsTime(order.pickedUpAt, order.timeZone) +
                                  ' ' +
                                  (isToday(order.pickedUpAt, order.timeZone) ? LocalizationService.formatDateTimeAsCalendarDate(order.pickedUpAt) : '')
                                : ''}
                        </span>
                    ),
                },
            },
            {
                name: 'openOrder',
                label: translate('Show more'),
                options: {
                    filter: false,
                    customBodyRender: (orderId: OrderId) => (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Button
                                onClick={() =>
                                    setOrderDetailsDialogState({
                                        open: true,
                                        order: orders.find((order: OrderVm) => order.orderId === orderId),
                                    })
                                }
                            >
                                <div className={(classes as any).openOrderButton}>
                                    <LaunchIcon className={(classes as any).icon} />
                                    <span>{translate('Open order')}</span>
                                </div>
                            </Button>
                        </div>
                    ),
                },
            },
            {
                name: 'copyOrder',
                label: translate('Copy order'),
                options: {
                    filter: false,
                    customBodyRender: (order: OrderVm) => (
                        <IconButton onClick={() => copyOrderToClipboard(order)}>
                            <FileCopyOutlinedIcon className={(classes as any).copyIcon} />
                        </IconButton>
                    ),
                },
            },
        ];

        if (filterType === PickupTimeTypes.PLANNED) {
            columns.splice(11, 0, {
                name: 'deliveryAt',
                label: translate('Delivery at'),
                options: {
                    searchable: true,
                    customBodyRender: (order: OrderVm) => (
                        <span className={getOrderStatusClass(order)}>
                            {LocalizationService.formatDateTimeAsTime(order.deliveryTime, order.timeZone)}{' '}
                            {isToday(order.deliveryTime as any, order.timeZone) ? LocalizationService.formatDateTimeAsCalendarDate(order.deliveryTime) : ''}
                        </span>
                    ),
                },
            });
        }

        if (consecutiveOrderIdEnabled && internalUser) {
            columns.splice(5, 0, {
                name: 'adminOrderId',
                label: translate('Order Id'),
                options: {
                    searchable: true,
                    customBodyRender: (order: OrderVm) => <span className={getOrderStatusClass(order)}>{toHumanizedOrderId(order.orderId)}</span>,
                },
            });
        }

        return (
            <Grid container direction='row' justify='center'>
                {!!orderDetailsDialogState.order && <OrderDetailsDialog open={orderDetailsDialogState.open} onClose={handleCloseOrderDetailsDialog} order={orderDetailsDialogState.order} />}
                <Grid item xs={12} className={(classes as any).orderMaxWidth}>
                    <div className={(classes as any).tableContainer}>
                        <MUIDataTable
                            /* @ts-ignore */
                            className={(classes as any).table}
                            data={[...ordersWithoutDriverSorted, ...ordersWithDriverSorted].map((order) => ({
                                channel: order.app,
                                delivery: getDeliveryProvider(order),
                                restaurantName: order,
                                salesforceRestaurantType: order,
                                customerName: order,
                                driverName: order,
                                orderId: order,
                                paymentMethod: order,
                                price: order,
                                cookingTime: order,
                                deliveryCost: order,
                                orderStatus: order,
                                createdAt: order,
                                deliveryAt: order,
                                pickedUpAt: order,
                                support: toHumanizedOrderId(order.orderId),
                                quickAction: order,
                                openOrder: order.orderId,
                                copyOrder: order,
                                zone: order,
                                adminOrderId: order,
                            }))}
                            columns={columns}
                            options={{
                                responsive: 'standard',
                                tableBodyMaxHeight: '500px',
                                selectableRows: 'none',
                                filterType: 'checkbox',
                                rowsPerPage: 100,
                                download: false,
                                print: false,
                                filter: false,
                                search: false,
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container direction='row' justify='center'>
            <Grid item xs={12} className={(classes as any).orderMaxWidth}>
                <div className={(classes as any).ordersContainer}>
                    <TransitionGroup component={null}>
                        {orders.map((order) => (
                            <CSSTransition timeout={1000} classNames='current-order-animation' key={order.orderId}>
                                <div className={(classes as any).orderContainer}>
                                    <OrderCardDeprecated order={order} onChange={onChangeOrder as any} onError={alertKnownErrorOrSomethingWentWrongError} />
                                </div>
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                </div>
            </Grid>
        </Grid>
    );
}

type Props = {
    orders: Array<OrderVm>;
    filterType?: PickupTimeType | OrderType | ExternalDeliveryProvider;
};
