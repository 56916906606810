/**
 * @prettier
 */
import * as React from 'react';

export function PaymentCardIcon({ title, color }: Props): React.ReactElement {
    return (
        <svg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                d='M0.875 4.375H17.125M0.875 5H17.125M3.375 9.375H8.375M3.375 11.25H5.875M2.75 13.75H15.25C15.7473 13.75 16.2242 13.5525 16.5758 13.2008C16.9275 12.8492 17.125 12.3723 17.125 11.875V3.125C17.125 2.62772 16.9275 2.15081 16.5758 1.79917C16.2242 1.44754 15.7473 1.25 15.25 1.25H2.75C2.25272 1.25 1.77581 1.44754 1.42417 1.79917C1.07254 2.15081 0.875 2.62772 0.875 3.125V11.875C0.875 12.3723 1.07254 12.8492 1.42417 13.2008C1.77581 13.5525 2.25272 13.75 2.75 13.75Z'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    color?: string;
};
