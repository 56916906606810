/**
 * @prettier
 */
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import moment from 'moment/moment';
import { useState } from 'react';
import * as React from 'react';
import { ResponsiveLineCanvas } from 'src/components/charts/ResponsiveLineCanvas';
import { translate } from 'src/i18n/translate';
import { GraphIcon } from 'src/icons/GraphIcon';
import { TableIcon } from 'src/icons/TableIcon';
import { CashRegisterTransactionsByDayTableReport } from 'src/scenes/letseatmanager/posReports/cashRegisterTransactionsReport/CashRegisterTransactionsByDayTableReport';
import { GraphEmptyState } from 'src/scenes/letseatmanager/posReports/GraphEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CashRegisterTransactionsReportVm } from 'src/types/CashRegisterTransactionsReportVm';
import { sum } from 'src/utils/reduce/sum';

export function CashRegisterTransactionsByDayReport({ cashRegisterTransactionsReport }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [showTable, setShowTable] = useState(false);

    const getCashRegisterTransactionsByDayLineChartData = () => {
        return [
            {
                id: 'total',
                data: cashRegisterTransactionsReport.totalSummaryTransactionsByDay.map((cashRegisterTransaction) => ({
                    x: moment(cashRegisterTransaction.createdAt).format('YYYY-MM-DD HH:MM'),
                    y: cashRegisterTransaction.amount,
                })),
            },
        ];
    };

    const isEmptyReport = () => {
        const cashRegisterTransactions = cashRegisterTransactionsReport.totalSummaryTransactionsByDay.map((cashRegisterTransaction) => cashRegisterTransaction.amount);
        const totalTransactions = cashRegisterTransactions.reduce(sum, BigNumber(0));
        return totalTransactions.isZero();
    };

    if (isEmptyReport()) {
        return <GraphEmptyState title={translate('Transactions by day')} />;
    }

    return (
        <article className={classes.container}>
            <div className={classes.titleContainer}>
                <h2 className={classes.title}>{translate('Transactions by day')}</h2>
                <IconButton onClick={() => setShowTable(!showTable)} className={(classes as any).iconButton}>
                    {showTable && <GraphIcon />}
                    {!showTable && <TableIcon />}
                </IconButton>
            </div>
            {!showTable && (
                <ResponsiveLineCanvas
                    data={getCashRegisterTransactionsByDayLineChartData()}
                    tooltip={({ point }: { point: { data: { x: string; y: string } } }) => (
                        <div className={classes.tooltip}>
                            <span className={classes.tooltipText}>{translate(`Date: @date`, { date: point.data.x })}</span>
                            <span className={classes.tooltipText}>{translate(`Total: @total`, { total: formatAsCurrencyNumber(point.data.y) })}</span>
                        </div>
                    )}
                />
            )}
            {showTable && <CashRegisterTransactionsByDayTableReport cashRegisterTransactionsByDay={cashRegisterTransactionsReport.totalSummaryTransactionsByDay} />}
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '70vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
    },
    tooltip: {
        padding: 10,
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    cashRegisterTransactionsReport: CashRegisterTransactionsReportVm;
};
