/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RappiIntegrationStatus } from 'src/constants/RappiIntegrationStatus';
import type { RappiStoreId, RestaurantId } from 'src/types/Id';

export async function findRappiIntegrationsApi(): ApiSauceResponse<Array<FindRappiIntegrationsApiResponse>> {
    return letseatadminApiMethod('rappiIntegration/findRappiIntegrationsApi');
}

export type FindRappiIntegrationsApiResponse = {
    restaurantId: RestaurantId;
    name: string;
    rappiStoreId?: RappiStoreId;
    rappiIntegrationStatus?: RappiIntegrationStatus;
    rappiVersion: string;
};
