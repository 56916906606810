/**
 * @prettier
 */
import * as React from 'react';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { BannersTable } from 'src/scenes/letseatmanager/marketing/BannersTable';
import { CustomersTable } from 'src/scenes/letseatmanager/marketing/CustomersTable';
import { MarketingEmptyState } from 'src/scenes/letseatmanager/marketing/MarketingEmptyState';
import { ScheduledNotificationsTable } from 'src/scenes/letseatmanager/marketing/ScheduledNotificationsTable';
import { useSelector } from 'src/utils/react/useSelector';

export function MarketingPage(): React.ReactElement {
    const marketingEnabled = useSelector((state) => state.app.restaurant.marketingEnabled);

    if (!marketingEnabled) {
        return (
            <SecuredAndSubscribedPage title={translate('Marketing')} rolePermission={RolePermissions.MARKETING_PAGE}>
                <MarketingEmptyState />
            </SecuredAndSubscribedPage>
        );
    }

    return (
        <SecuredAndSubscribedPage title={translate('Marketing')} rolePermission={RolePermissions.MARKETING_PAGE}>
            <CustomersTable />
            <BannersTable />
            <ScheduledNotificationsTable />
        </SecuredAndSubscribedPage>
    );
}
