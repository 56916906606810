/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { clockOutRestaurantEmployeeApi } from 'src/api/letseatmanager/employeeDayShift/clockOutRestaurantEmployeeApi';
import { Accordion } from 'src/components/Accordion';
import { Button } from 'src/components/Button';
import { PaymentMethod } from 'src/constants/PaymentMethod';
import { RoutePaths } from 'src/constants/RoutePath';
import { TipType } from 'src/constants/TipType';
import { translate } from 'src/i18n/translate';
import { CircularCheckedIcon } from 'src/icons/CircularCheckedIcon';
import { CircularUnCheckedIcon } from 'src/icons/CircularUnCheckedIcon';
import { authReducer } from 'src/reducers/authReducer';
import { posReducer } from 'src/reducers/posReducer';
import { EatHereOngoingOrderCard } from 'src/scenes/letseatmanager/pos/employeeDayShift/EatHereOngoingOrderCard';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { CustomPaymentMethod } from 'src/types/Id';
import { OrderVm } from 'src/types/OrderVm';
import type { RestaurantEmployeeDayShiftVm } from 'src/types/RestaurantEmployeeDayShiftVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function ClockOutActions({ employeeDayShift }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const history = useHistory();

    const currentOrders = useSelector((state) => state.app2.currentOrders);
    const restaurantId = useSelector((state) => state.app.restaurant?.restaurantId);

    const pinCodeUserLogout = useAction(authReducer.actions.pinCodeUserLogout);
    const setOpenedCashRegister = useAction(posReducer.actions.setOpenedCashRegister);
    const setOpenedCashRegisterPosBusinessDay = useAction(posReducer.actions.setOpenedCashRegisterPosBusinessDay);

    const eatHereOngoingOrders = currentOrders?.filter((order: OrderVm) => isEatHereOrder(order.orderType));

    const hasEatHereOngoingOrders = !!eatHereOngoingOrders.length;

    const [isReviewOrdersStepInCompleted, setIsReviewOrdersStepInCompleted] = useState(hasEatHereOngoingOrders);
    const [isConciliateTipsStepInCompleted, setIsConciliateTipsStepInCompleted] = useState(!!employeeDayShift.tips?.length);
    const [loading, setLoading] = useState(false);

    const onReviewEatHereOrdersStepCompleted = (state: any) => setIsReviewOrdersStepInCompleted(state);
    const onConciliateTipsStepCompleted = (state: any) => setIsConciliateTipsStepInCompleted(state);

    const disabled = isConciliateTipsStepInCompleted || isReviewOrdersStepInCompleted;

    const handleClockOut = async () => {
        setLoading(true);
        const response = await clockOutRestaurantEmployeeApi({
            restaurantId,
            managerUserId: employeeDayShift.managerUserId,
            clockOutBy: employeeDayShift.managerUserId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        pinCodeUserLogout();
        setOpenedCashRegister();
        setOpenedCashRegisterPosBusinessDay();

        history.replace({
            pathname: RoutePaths.POS,
            search: history.location.search,
        });
    };

    return (
        <div className={classes.container}>
            <Accordion
                onChange={onReviewEatHereOrdersStepCompleted}
                classes={{ title: classes.title, container: classes.accordionContainer }}
                defaultOpened={isReviewOrdersStepInCompleted}
                title={translate('Open eat here orders')}
                subText={!hasEatHereOngoingOrders ? translate('You have not open eat here orders') : ''}
                renderIcon={(isAccordionOpened: boolean) => (isAccordionOpened ? <CircularUnCheckedIcon title={`orders-step-unChecked`} /> : <CircularCheckedIcon title={`orders-step-checked`} />)}
            >
                <div className={classes.container}>
                    {eatHereOngoingOrders?.map((order: OrderVm) => (
                        <EatHereOngoingOrderCard order={order} />
                    ))}
                </div>
            </Accordion>
            <Accordion
                onChange={onConciliateTipsStepCompleted}
                classes={{ title: classes.title, container: classes.accordionContainer }}
                defaultOpened={isConciliateTipsStepInCompleted}
                title={translate('Conciliate tips')}
                subText={translate('Make sure that the tips in the system match the cash and credit card with the cash and card')}
                renderIcon={(isAccordionOpened: boolean) => (isAccordionOpened ? <CircularUnCheckedIcon title={`tips-step-unChecked`} /> : <CircularCheckedIcon title={`orders-step-checked`} />)}
            >
                <div className={classes.container}>
                    <div className={classes.infoContainer}>
                        {employeeDayShift.tips?.map((tip: { paymentMethod: PaymentMethod | CustomPaymentMethod; tipAmount: string; tipType: TipType }) => (
                            <div className={classes.kpiContainer}>
                                <p className={classes.kpiValue}>{formatAsCurrencyNumber(tip.tipAmount)}</p>
                                <p className={classes.kpiLabel}>{translate(tip.paymentMethod)}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </Accordion>
            <Button onClick={handleClockOut} classes={{ button: classes.button }} disabled={disabled || loading}>
                {translate('Clock out')}
            </Button>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '90%',
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 500,
            margin: 'unset',
        },
    },
    tipsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '32px 0',
    },
    infoContainer: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: 'repeat(2, 1fr)',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
    },
    kpiContainer: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 140,
        minHeight: 70,
        alignContent: 'center',
        justifyContent: 'start',
    },
    kpiValue: {
        fontFamily: theme.typography.semiBold,
        margin: 0,
        fontSize: 18,
        marginBottom: 5,
        color: '#224753',
        [theme.breakpoints.up('sm')]: {
            fontSize: 24,
        },
    },
    kpiLabel: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        margin: 0,
        color: '#6D7B7F',
        [theme.breakpoints.up('sm')]: {
            fontSize: 16,
        },
    },
    button: {
        width: '100%',
        margin: '20px 0',
    },
    title: {
        fontFamily: theme.typography.semiBold,
        color: '#000',
        fontSize: 16,
        textAlign: 'start',
    },
    accordionContainer: {
        marginBottom: 30,
    },
}));

type Props = {
    employeeDayShift: RestaurantEmployeeDayShiftVm;
};
