/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { hideMenuItemApi } from 'src/api/letseatmanager/menuItem/hideMenuItemApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormDateTimePicker } from 'src/components/form/FormDateTimePicker';
import { FormRadio } from 'src/components/form/FormRadio';
import { FormRadioGroup } from 'src/components/form/FormRadioGroup';
import { translate } from 'src/i18n/translate';
import type { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function HideMenuItemDialog({ open, menuItem, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { isSubmitting },
        control,
    } = form;

    const [loading, setLoading] = useState(false);

    const option = useWatch({ name: 'option', control, defaultValue: FormOptions.JUST_TODAY });

    const handleClose = () => {
        if (loading && isSubmitting) return;
        onClose();
    };

    const handleSubmit = async (form: any) => {
        if (!menuItem) return;
        setLoading(true);
        const response = await hideMenuItemApi({ menuItemId: menuItem.menuItemId, hiddenUntil: getHiddenUntil(form) });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
        handleClose();
    };

    const getHiddenUntil = (form: any) => {
        if (form.option === FormOptions.JUST_TODAY) {
            const today = new Date();
            let tomorrow = moment(today).startOf('day');
            tomorrow = tomorrow.clone().add(1, 'days');

            return tomorrow.toDate();
        }
        if (form.option === FormOptions.SET_A_DAY) return form.date;

        return undefined;
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Hide @menuItem', { menuItem: menuItem && menuItem.name })}>
            <Form onSubmit={handleSubmit} form={form} className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item>
                        <h3 className={classes.subTitle}>{translate('Your customers are going to miss it. How long do you want this product to be unavailable?')}</h3>
                        <FormRadioGroup name='option' defaultValue={FormOptions.JUST_TODAY}>
                            <Grid item xs={12}>
                                <FormRadio label={translate('Just today (tomorrow will be available)')} value={FormOptions.JUST_TODAY} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormRadio label={translate('Set a date')} value={FormOptions.SET_A_DAY} />
                                {option === FormOptions.SET_A_DAY && (
                                    <Grid className={classes.dates} container direction='row' spacing={3} alignItems='center'>
                                        <Grid item>
                                            <FormDateTimePicker name='date' label={translate('Fecha')} />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <FormRadio label={translate('Undefined')} value={FormOptions.UNDEFINED} />
                            </Grid>
                        </FormRadioGroup>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {loading ? translate('Hiding...') : translate('Hide')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const FormOptions = Object.freeze({
    JUST_TODAY: 'JUST_TODAY',
    SET_A_DAY: 'SET_A_DAY',
    UNDEFINED: 'UNDEFINED',
});

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dates: {
        marginLeft: 20,
    },
    subTitle: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
    },
}));

type Props = {
    open: boolean;
    menuItem?: ManagerMenuItemVm;
    onClose: any;
    onSuccess?: any;
};
