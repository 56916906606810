/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button, Chip, Dialog, DialogActions, DialogContent } from '@pidedirecto/ui';
import { Form, FormCheckbox, FormCurrencyNumberStringField, FormPercentNumberField, FormTextField } from '@pidedirecto/ui/form';
import { useForm } from '@pidedirecto/ui/hooks';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { FormCustomDiscountCommentSelect } from 'src/components/form/FormCustomDiscountCommentSelect';
import { FormCustomerNumberSelect } from 'src/components/form/FormCustomerNumberSelect';
import { FormDiscountTypeSelect } from 'src/components/form/FormDiscountTypeSelect';
import { FormPosOrderItemsAutocompleteMultiple } from 'src/components/form/FormPosOrderItemsAutocompleteMultiple';
import { DiscountTypes } from 'src/constants/DiscountType';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { isAmountDiscount } from 'src/utils/promoCode/isAmountDiscount';
import { isPercentDiscount } from 'src/utils/promoCode/isPercentDiscount';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function AddDiscountDialog({ open, onClose }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { isSubmitting },
        control,
        watch,
    } = form;

    const customers = useSelector((state) => state.pos.customers);
    const payTotalTicketSelected = useSelector((state) => state.pos.payTotalTicketSelected);
    const customerNumberSelected = useSelector((state) => state.pos.customerNumberSelected);
    const posCustomDiscountCommentsEnabled = useSelector((state) => state.app.restaurant?.posCustomDiscountCommentsEnabled);
    const country = useSelector((state) => state.app.restaurant?.country);
    const acceptMultipleCurrenciesPaymentEnabled = useSelector((state) => state.pos.context?.acceptMultipleCurrenciesPaymentEnabled);
    const mainCurrency = useSelector((state) => state.app.restaurant?.currencyFormat?.currency);
    const restaurantTaxManagementEnabled = useSelector((state) => state.app.restaurant?.restaurantTaxManagementEnabled);

    const discountType = watch('discountType');
    const applyByProducts = watch('applyByProducts');
    const other = watch('other');

    const addPosDiscount = useAction(posReducer.actions.addPosDiscount);
    const addPosCustomerDiscount = useAction(posReducer.actions.addPosCustomerDiscount);

    const showOrderItemsInput = applyByProducts && discountType === DiscountTypes.PERCENT;
    const showCustomerNumberSelect = !!customers?.length && !payTotalTicketSelected;
    const showCustomDiscountCommentsSelect = posCustomDiscountCommentsEnabled && !other;
    const showDiscountCommentInput = !posCustomDiscountCommentsEnabled || other;
    const discountLabel = !acceptMultipleCurrenciesPaymentEnabled ? translate('Discount') : `${translate('Discount')} (${mainCurrency})`;

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSuccess = (discount: any, cartItemKeys: any) => {
        onClose();
        if (!discount?.customerNumber) return addPosDiscount({ discount, cartItemKeys });

        addPosCustomerDiscount(discount);
    };

    const handleSubmit = (form: any) => {
        const notes = posCustomDiscountCommentsEnabled && !other ? form.customDiscountComment : form.notes;
        if (!showCustomerNumberSelect) {
            return onSuccess({ discountType: form.discountType, discount: form.discount, discountPercentage: formatDeprecatedPercentage(form.discountPercentage), notes }, form.cartItemKeys);
        }

        onSuccess(
            { discountType: form.discountType, discount: form.discount, discountPercentage: formatDeprecatedPercentage(form.discountPercentage), notes, customerNumber: form.customerNumber },
            form.cartItemKeys
        );
    };

    const formatDeprecatedPercentage = (percentage: number | undefined) => {
        if (!percentage) return;
        return BigNumber(percentage).multipliedBy(100).toString();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Add Discount')}>
            <Form onSubmit={handleSubmit} form={form}>
                <DialogContent>
                    {restaurantTaxManagementEnabled && (
                        <Chip variant='warning' classes={{ chip: classes.chip }}>
                            {translate('When adding a discount to the order, it cannot be invoiced since the products include excise taxes')}
                        </Chip>
                    )}
                    {showCustomerNumberSelect && (
                        <FormCustomerNumberSelect
                            name='customerNumber'
                            label={translate('Select customer')}
                            required={showCustomerNumberSelect}
                            defaultValue={showCustomerNumberSelect ? customerNumberSelected?.toString() : '1'}
                        />
                    )}
                    <FormDiscountTypeSelect name='discountType' label={translate('Discount Type')} required />
                    {isPercentDiscount(discountType) && !customers?.length && <FormCheckbox name='applyByProducts' label={translate('By products')} />}
                    {showOrderItemsInput && <FormPosOrderItemsAutocompleteMultiple name='cartItemKeys' label={translate('Items')} />}
                    {(isAmountDiscount(discountType) || !discountType) && <FormCurrencyNumberStringField name='discount' label={discountLabel} required country={country} />}
                    {isPercentDiscount(discountType) && <FormPercentNumberField name='discountPercentage' label={discountLabel} required />}
                    {showCustomDiscountCommentsSelect && <FormCustomDiscountCommentSelect name='customDiscountComment' label={translate('Select an comment')} />}
                    {showDiscountCommentInput && <FormTextField name='notes' label={translate('Comment')} />}
                    {posCustomDiscountCommentsEnabled && <FormCheckbox name='other' label={translate('Other')} />}
                </DialogContent>
                <DialogActions>
                    <Button variant='secondary' onClick={handleClose}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit'>{translate('Add')}</Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 615,
    },
    chip: {
        maxWidth: '100%',
        height: 'fit-content',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
};
