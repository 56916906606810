/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerId, CustomerReturnId, OrderId, RestaurantId } from 'src/types/Id';

export async function getCustomerReturnsByRestaurantIdApi(request: GetCustomerReturnsByRestaurantIdApiRequest): ApiSauceResponse<CustomerReturnsVm> {
    return letseatadminApiMethod('customerReturn/getCustomerReturnsByRestaurantIdApi', request);
}

export type GetCustomerReturnsByRestaurantIdApiRequest = {
    restaurantId: RestaurantId;
};

export type CustomerReturnsVm = {
    customerReturns: Array<CustomerReturnVm>;
};

type CustomerReturnVm = {
    customerReturnId: CustomerReturnId;
    customerId: CustomerId;
    restaurantId: RestaurantId;
    orderId: OrderId;
    createdAt: Date;
    sendThanksForOrderingEmailAt?: Date;
    thanksForOrderingEmailSentAt?: Date;
    failedToSendThanksForOrderingEmail?: boolean;
    sendWelcomeEmailAt?: Date;
    welcomeEmailSentAt?: Date;
    failedToSendWelcomeEmail?: boolean;
    sendMenuHighlightEmailAt?: Date;
    menuHighlightEmailSentAt?: Date;
    failedToSendMenuHighlightEmail?: boolean;
    sendPromoCodeEmailAt?: Date;
    promoCodeEmailSentAt?: Date;
    promoCodeSent?: string;
    failedToSendPromoCodeEmail?: boolean;
    customerReturnedAt?: Date;
    customerReturnedOrderId?: OrderId;
    customerUsedLink?: boolean;
};
