/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DeviceVm } from 'src/types/DeviceVm';
import type { DeviceId } from 'src/types/Id';

export async function changeDeviceApi(request: ChangeDeviceApiRequest): ApiSauceResponse<DeviceVm> {
    return letseatmanagerApiMethod('device/changeDeviceApi', request);
}

type ChangeDeviceApiRequest = {
    deviceId: DeviceId;
    deviceName: string;
};
