/**
 * @prettier
 */
import * as React from 'react';
import { SECONDS } from 'src/constants/TimeUnit';
import { getIsPrintAutoAcceptedOrdersDeviceInLocalStorage } from 'src/localStorage/getIsPrintAutoAcceptedOrdersDeviceInLocalStorage';
import { PrintOrderWebSocketEventResponse, usePrintAutoAcceptedOrderSubscription } from 'src/services/order/usePrintAutoAcceptedOrderSubscription';
import { usePrintOrderCommand } from 'src/services/printer/usePrintOrderCommand';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import { OrderVm } from 'src/types/OrderVm';
import { WebSocketEvent } from 'src/types/WebSocketEvent';
import { useSelector } from 'src/utils/react/useSelector';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';
import { wait } from 'src/utils/wait';

export function PrintAutoAcceptedOrder(): React.ReactElement | null {
    const [printOrderTicket] = usePrintOrderTicket();
    const [printOrderCommand] = usePrintOrderCommand();

    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const brandOpened = useSelector((state) => state.app.brandOpened);

    const printAutoAcceptedOrders = getIsPrintAutoAcceptedOrdersDeviceInLocalStorage();

    const handlePrintOrderWebSocketEvent = async (event: WebSocketEvent<PrintOrderWebSocketEventResponse>) => {
        if (!event.data?.restaurantId) return;
        const isBrandRestaurantOrder = brandOpened ? restaurantIds.includes(event.data?.restaurantId) : event.data?.restaurantId === restaurantId;
        if (!isBrandRestaurantOrder || !printAutoAcceptedOrders) return;

        const order = event.data?.order;
        if (isMobileApp()) {
            await printOrderTicket(order);
            await printOrderCommand(order);
            return;
        }
        await printOrder(order);
        await printCommand(order);
    };

    const printOrder = async (order: OrderVm) => {
        let tries = 0;
        let ticketPrinted = false;
        do {
            if (!ticketPrinted) {
                try {
                    await printOrderTicket(order);
                    ticketPrinted = true;
                } catch (e: any) {
                    console.log(`Failed silently to print order, trying again in 5 seconds e=${e}`);
                    tries++;
                    await wait(5 * SECONDS);
                }
            }
        } while (!ticketPrinted && tries <= 5);
    };

    const printCommand = async (order: OrderVm) => {
        let tries = 0;
        let commandPrinted = false;
        do {
            if (!commandPrinted) {
                try {
                    await printOrderCommand(order);
                    commandPrinted = true;
                } catch (e: any) {
                    console.log(`Failed silently to print order items, trying again in 5 seconds e=${e}`);
                    tries++;
                    await wait(5 * SECONDS);
                }
            }
        } while (!commandPrinted && tries <= 5);
    };

    usePrintAutoAcceptedOrderSubscription(handlePrintOrderWebSocketEvent);

    return null;
}
