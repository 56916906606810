/**
 * @prettier
 */
import { useState } from 'react';
import { findOpenedCashRegistersPosBusinessDayByPosBusinessDayApi } from 'src/api/letseatmanager/pos/findOpenedCashRegistersPosBusinessDayByPosBusinessDayApi';
import { posReducer } from 'src/reducers/posReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function useLoadCashRegistersPosBusinessDay(): any {
    const [loading, setLoading] = useState(false);

    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant.posMultipleCashRegistersEnabled);
    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);

    const setOpenedCashRegistersPosBusinessDay = useAction(posReducer.actions.setOpenedCashRegistersPosBusinessDay);

    const loadCashRegistersPosBusinessDay = async () => {
        if (!openedPosBusinessDay || loading || !posMultipleCashRegistersEnabled) return;
        setLoading(true);
        const response = await findOpenedCashRegistersPosBusinessDayByPosBusinessDayApi({ posBusinessDayId: openedPosBusinessDay?.posBusinessDayId });
        setLoading(false);
        if (!response.ok) {
            return;
        }

        setOpenedCashRegistersPosBusinessDay(response.data);
    };

    return loadCashRegistersPosBusinessDay;
}
