/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';

export function ReportSection({ title, total, loading, children }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <section className={classes.container}>
            <h2 className={classes.title}>{title}</h2>
            <h2 className={classes.total}>{total}</h2>
            {children}
            <UpdatingContentProgress top left loading={loading} />
        </section>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        direction: 'column' as any,
        marginBottom: '20px',
        position: 'relative',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        marginTop: 12,
        marginBottom: 10,
        fontWeight: 500,
        color: theme.palette.text.secondary,
    },
    total: {
        fontWeight: 700,
        fontSize: 26,
        color: theme.palette.text.primary,
        margin: 0,
    },
}));

type Props = {
    title?: string;
    loading: boolean;
    children: React.ReactNode;
    total?: any;
    height?: string;
};
