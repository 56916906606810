/**
 * @prettier
 */
export const RefundMethods = {
    CREDITS: 'CREDITS',
    CREDIT_CARD: 'CREDIT_CARD',
    PAYROLL: 'PAYROLL',
} as const;

export type RefundMethod = typeof RefundMethods[keyof typeof RefundMethods];
