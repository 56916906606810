/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import { DiscountTypes } from 'src/constants/DiscountType';
import { useMapOrderItemsToCartItems } from 'src/services/pos/useMapOrderItemsToCartItems';
import type { CustomerOrderDetailsVm } from 'src/types/CustomerOrderDetailsVm';
import type { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import type { OrderItemVm, OrderVm } from 'src/types/OrderVm';
import type { PosPaymentVm } from 'src/types/PosPaymentVm';
import type { PosTip } from 'src/types/PosTip';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { createOrderItemKey } from 'src/utils/pos/createOrderItemKey';
import { createPaymentDistribution } from 'src/utils/pos/createPaymentDistribution';

export function useMergePosOrders(): {
    loading: boolean;
    mergeOrders: MergeOrdersFunction;
} {
    const mapOrderItemsToCartItems = useMapOrderItemsToCartItems();

    const [loading, setLoading] = useState(false);

    const handleMergeOrders = async (fromOrder?: OrderVm, toOrder?: OrderVm) => {
        return mergeOrders(fromOrder, toOrder);
    };

    const mergeOrders = (fromOrder?: OrderVm, toOrder?: OrderVm) => {
        if (!fromOrder) return toOrder;
        if (!toOrder) return fromOrder;

        const mergedOrder = mergeOrdersInfo(fromOrder, toOrder);

        const paymentDistribution = createPaymentDistribution({
            orderItems: mapOrderItemsToCartItems(mergedOrder.orderItems),
            country: mergedOrder.country,
            discount: mergedOrder.posDiscount
                ? {
                      discountType: mergedOrder.posDiscountType || DiscountTypes.AMOUNT,
                      discount: mergedOrder.posDiscount,
                      discountPercentage: mergedOrder.posDiscountPercentage,
                      notes: mergedOrder.posDiscountNotes,
                  }
                : undefined,
            tips: mergedOrder.tips?.map((tip: PosTip) => ({
                tipType: tip.tipType,
                tipPercentage: tip.tipPercentage,
                paymentMethod: tip.paymentMethod,
                customPaymentMethod: tip.customPaymentMethod,
                tipAmount: tip.tipAmount ?? '0',
                customerNumber: tip.customerNumber,
                paymentId: tip.paymentId,
            })),
            taxRate: mergedOrder.taxRate,
            orderType: mergedOrder.orderType,
            usedCustomerCredits: mergedOrder.usedCredits,
        });

        return {
            ...mergedOrder,
            posDiscount: paymentDistribution.posDiscount,
            posTipAmount: paymentDistribution?.posTip?.tipAmount,
            tips: paymentDistribution?.tips?.map((tip: PosTip) => ({
                tipType: tip.tipType,
                tipPercentage: tip.tipPercentage,
                paymentMethod: tip.paymentMethod,
                customPaymentMethod: tip.customPaymentMethod,
                tipAmount: tip.tipAmount ?? '0',
                customerNumber: tip.customerNumber,
            })),
            subtotal: paymentDistribution.subtotal,
            productDiscount: paymentDistribution.productDiscount,
            promoCodeDiscount: paymentDistribution.promoCodeDiscount,
            promoCodeCredits: paymentDistribution.promoCodeCredits,
            usedCredits: paymentDistribution.usedCredits,
            usedNonGiftCredits: paymentDistribution.usedNonGiftCredits,
            usedLetsEatCredits: paymentDistribution.usedLetsEatCredits,
            total: paymentDistribution.total,
        };
    };

    const mergeOrdersInfo = (fromOrder: OrderVm, toOrder: OrderVm) => {
        const numberOfCustomers = toOrder.numberOfCustomers || 0;
        return {
            ...toOrder,
            recipeIds: removeDuplicates([...(toOrder.recipeIds ?? []), ...(fromOrder.recipeIds ?? [])]),
            numberOfCustomers: BigNumber(toOrder.numberOfCustomers || 0)
                .plus(fromOrder.numberOfCustomers || 0)
                .toNumber(),
            orderItems: mergeOrderItems(fromOrder.orderItems, toOrder.orderItems, numberOfCustomers),
            cancelledItems: mergeOrderItems(fromOrder.cancelledItems ?? [], toOrder.cancelledItems ?? [], numberOfCustomers),
            customers: mergeDataByCustomerNumber<CustomerOrderDetailsVm>(fromOrder.customers || [], toOrder.customers || [], numberOfCustomers),
            payments: mergeDataByCustomerNumber<OrderPaymentVm>(fromOrder.payments || [], toOrder.payments || [], numberOfCustomers),
            posPayments: mergeDataByCustomerNumber<PosPaymentVm>(fromOrder.posPayments || [], toOrder.posPayments || [], numberOfCustomers),
            tips: mergeDataByCustomerNumber<PosTip>(fromOrder.tips || [], toOrder.tips || [], numberOfCustomers),
        };
    };

    const mergeOrderItems = (fromOrderItems: Array<OrderItemVm>, toOrderItems: Array<OrderItemVm>, numberOfCustomers: number) => {
        const mappedFromOrderItems: Array<OrderItemVm> = fromOrderItems.map((orderItem) => {
            return {
                ...orderItem,
                itemsByCustomer: orderItem.itemsByCustomer?.map((itemByCustomer: { customerNumber: number; itemsQuantity: number }) => {
                    const mainOrderHasSameCustomerNumber = orderItemsHasCustomerNumber(itemByCustomer.customerNumber, toOrderItems);
                    if (!mainOrderHasSameCustomerNumber) return itemByCustomer;

                    return {
                        customerNumber: BigNumber(itemByCustomer.customerNumber)
                            .plus(numberOfCustomers || 0)
                            .toNumber(),
                        itemsQuantity: itemByCustomer.itemsQuantity,
                    };
                }),
            };
        });
        const mergedOrderItem = [...toOrderItems, ...mappedFromOrderItems];
        return mergedOrderItem.reduce<Array<any>>((mergedOrderItems, orderItem) => {
            const addedOrderItems = mergedOrderItems.filter((mergedOrderItem) => mergedOrderItem.menuItemId === orderItem.menuItemId);
            if (!addedOrderItems.length) return [...mergedOrderItems, orderItem];

            const restOrderItems = mergedOrderItems.filter((mergedOrderItem) => mergedOrderItem.menuItemId !== orderItem.menuItemId);
            const addedOrderItemsUpdated = addedOrderItems.map((addedOrderItem) => {
                const addedOrderItemKey = createOrderItemKey(addedOrderItem);
                const orderItemKey = createOrderItemKey(orderItem);

                if (addedOrderItemKey !== orderItemKey) return addedOrderItem;

                return {
                    ...addedOrderItem,
                    quantity: BigNumber(addedOrderItem.quantity).plus(orderItem.quantity).toNumber(),
                };
            });
            return [...restOrderItems, ...addedOrderItemsUpdated];
        }, []);
    };

    function mergeDataByCustomerNumber<T>(from: Array<any>, to: Array<any>, numberOfCustomers: number): Array<T> {
        const mappedFrom =
            from.map((data) => {
                if (!data.customerNumber) return data;
                return {
                    ...data,
                    customerNumber: BigNumber(data.customerNumber).plus(numberOfCustomers).toNumber(),
                };
            }) ?? [];
        return [...to, ...mappedFrom];
    }

    const orderItemsHasCustomerNumber = (customerNumber: number, orderItems: Array<OrderItemVm>) => {
        return orderItems.some((orderItem) => orderItem.itemsByCustomer?.some((itemByCustomer: { customerNumber: number; itemsQuantity: number }) => itemByCustomer.customerNumber === customerNumber));
    };

    return { loading, mergeOrders: handleMergeOrders };
}

type MergeOrdersFunction = (arg1: OrderVm | undefined, arg2: OrderVm | undefined) => Promise<OrderVm | undefined>;
