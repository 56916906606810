/**
 * @prettier
 */
import { DeviceModel, DeviceModels } from 'src/constants/DeviceModel';

export function translateDeviceModel(deviceModel?: DeviceModel): string {
    if (!deviceModel) return '';
    switch (deviceModel) {
        case DeviceModels.FALCON:
            return 'Falcon';
        case DeviceModels.I2000:
            return 'i2000';
        case DeviceModels.I9000S:
            return 'i9000S';
        case DeviceModels.K1:
            return 'K1';
        case DeviceModels.SM_A515F:
            return 'Galaxy A51';
        case DeviceModels.D1:
            return 'D1';
        case DeviceModels.D2:
            return 'D2';
        case DeviceModels.D4:
            return 'D4';
        case DeviceModels.SWAN:
            return 'Swan';
        case DeviceModels.IPHONE:
            return 'iPhone';
        case DeviceModels.IPAD:
            return 'iPad';
        case DeviceModels.MACINTOSH:
            return 'Mac';
        default:
            return deviceModel ?? '';
    }
}
