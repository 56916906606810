/**
 * @prettier
 */
import { RoutePaths } from 'src/constants/RoutePath';
import type { OrderId } from 'src/types/Id';
import { RegExps } from 'src/utils/RegExps';

export function openOrderPageInNewTab(orderId: OrderId): void {
    window.open(RoutePaths.ADMIN_ORDER.replace(`:orderId(${RegExps.uuid})`, orderId), '_blank');
}
