/**
 * @prettier
 */
import { App, Apps } from 'src/constants/App';
import type { OrderVm } from 'src/types/OrderVm';
import { isDidiDelivery } from 'src/utils/order/isDidiDelivery';
import { isRappiDelivery } from 'src/utils/order/isRappiDelivery';
import { isUberDaasDelivery } from 'src/utils/order/isUberDaasDelivery';
import { isUberDelivery } from 'src/utils/order/isUberDelivery';

export function getDeliveryProvider(order: OrderVm): App {
    if (isDidiDelivery(order)) return Apps.DIDI_FOOD;
    if (isRappiDelivery(order)) return Apps.RAPPI;
    if (isUberDelivery(order)) return Apps.UBER_EATS;
    if (isUberDaasDelivery(order)) return Apps.UBER_DAAS;
    return Apps.PIDEDIRECTO;
}
