/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { CartIcon } from 'src/icons/CartIcon';
import { PosContext } from 'src/scenes/letseatmanager/pos/PosProvider';
import { useSelector } from 'src/utils/react/useSelector';

export function PosCartButton(): React.ReactElement {
    const classes = useStyles();

    const posContext = useContext(PosContext);

    const items = useSelector((state) => state.pos.items);

    return (
        <Button secondary classes={{ button: classes.cartMobile }} onClick={posContext.openMobileCart}>
            <CartIcon />
            <div className={classes.itemsCounter}>{items.length}</div>
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    cartMobile: {
        border: '1px solid #D9D9D9',
        padding: '8px 12px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
        display: 'none',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        },
    },
    itemsCounter: {
        display: 'grid',
        placeItems: 'center',
        width: 24,
        aspectRatio: 1,
        backgroundColor: theme.palette.surface.brandContrast,
        borderRadius: 4,
        color: theme.palette.text.invert,
        fontFamily: theme.typography.medium,
        fontSize: 14,
        marginLeft: 6,
    },
}));
