/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerId, RestaurantId } from 'src/types/Id';

export async function addCreditsToCustomerApi(request: AddCreditsToCustomersApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('marketing/addCreditsToCustomerApi', request);
}

type AddCreditsToCustomersApiRequest = {
    restaurantIds: Array<RestaurantId>;
    customerId: CustomerId;
    amount: string;
};
