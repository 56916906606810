/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { ExternalDeviceId } from 'src/types/Id';

export async function unBanDeviceApi(request: UnBanDeviceApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('driver/unBanDeviceApi', request);
}

export type UnBanDeviceApiRequest = {
    deviceId: ExternalDeviceId;
};
