/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DiscountType } from 'src/constants/DiscountType';
import type { OrderId } from 'src/types/Id';
import type { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import type { PosPayment } from 'src/types/PosPayment';
import { PosPaymentVm } from 'src/types/PosPaymentVm';
import type { PosTipVm } from 'src/types/PosTipVm';

export async function addTipToPosOrderApi(request: addTipToPosOrderApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('order/addTipToPosOrderApi', request);
}

export type addTipToPosOrderApiRequest = {
    orderId: OrderId;
    tips?: Array<PosTipVm>;
    posPayments: Array<PosPayment | PosPaymentVm>;
    payments: Array<PosPayment | OrderPaymentVm>;
    posDiscount?: {
        discountType: DiscountType;
        discount: string;
        discountPercentage?: string;
        notes?: string;
    };
};
