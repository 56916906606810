/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { SectionTitle } from 'src/components/title/SectionTitle';
import { PosBusinessDaySections } from 'src/constants/PosBusinessDaySections';
import { translate } from 'src/i18n/translate';

export function PosBusinessDayGeneralInfoSettings({ disabled }: Props): React.ReactElement {
    return (
        <section>
            <SectionTitle title={translate('General Information')} />
            <Grid container xs={12}>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={PosBusinessDaySections.DATE_OF_PRINT} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.DATE_OF_PRINT}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={PosBusinessDaySections.OPENING_DATE} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.OPENING_DATE}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={PosBusinessDaySections.CLOSING_DATE} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.CLOSING_DATE}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={PosBusinessDaySections.SALES} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.SALES}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={PosBusinessDaySections.TIP} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.TIP}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={PosBusinessDaySections.ORDERS} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.ORDERS}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={PosBusinessDaySections.DINNERS} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.DINNERS}`)} />
                </Grid>
            </Grid>
        </section>
    );
}

type Props = {
    disabled: boolean;
};
