/**
 * @prettier
 */
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { BarChartCanvas } from 'src/components/charts/BarChartCanvas';
import { translate } from 'src/i18n/translate';
import { GraphIcon } from 'src/icons/GraphIcon';
import { TableIcon } from 'src/icons/TableIcon';
import { GraphEmptyState } from 'src/scenes/letseatmanager/posReports/GraphEmptyState';
import { ManagerUserTipsTableReport } from 'src/scenes/letseatmanager/posReports/managerUserSalesReport/ManagerUserTipsTableReport';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { ManagerUserSalesReportVm } from 'src/types/ManagerUserSalesReportVm';
import { sum } from 'src/utils/reduce/sum';

export function ManagerUserTipsReport({ managerUserSalesReport }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [showTable, setShowTable] = useState(false);

    const getManagerUserTipsBarChartData = () => {
        return managerUserSalesReport.managerUserOrdersAndTips.reduce<Array<{ value: number; id: string }>>((managerUserOrdersAndTipsByUser, managerUserOrdersAndTip) => {
            const ordersAndTipsByUser = managerUserOrdersAndTipsByUser?.find((ordersAndTipsByUser) => ordersAndTipsByUser.id === managerUserOrdersAndTip.servedBy);

            if (ordersAndTipsByUser) {
                ordersAndTipsByUser.value = BigNumber(ordersAndTipsByUser.value).plus(managerUserOrdersAndTip.posTipAmount).toNumber();
                return managerUserOrdersAndTipsByUser;
            }

            return [
                ...managerUserOrdersAndTipsByUser,
                {
                    id: managerUserOrdersAndTip.servedBy,
                    value: managerUserOrdersAndTip.posTipAmount,
                },
            ];
        }, []);
    };

    const isEmptyReport = () => {
        const managerUserTips = managerUserSalesReport.managerUserOrdersAndTips.map((managerUserOrdersAndTips) => managerUserOrdersAndTips.posTipAmount);
        const totalTips = managerUserTips.reduce(sum, BigNumber(0));
        return totalTips.isZero();
    };

    if (isEmptyReport()) {
        return (
            <article className={classes.container}>
                <GraphEmptyState title={translate('Tips by waiter')} />
            </article>
        );
    }

    return (
        <article className={classes.container}>
            <div className={classes.titleContainer}>
                <h2 className={classes.title}>{translate('Tips by waiter')}</h2>
                <IconButton onClick={() => setShowTable(!showTable)} className={classes.iconButton}>
                    {showTable && <GraphIcon />}
                    {!showTable && <TableIcon />}
                </IconButton>
            </div>
            {!showTable && (
                <BarChartCanvas
                    data={getManagerUserTipsBarChartData()}
                    tooltip={(e: any) => {
                        const { value, id } = e.data;
                        return (
                            <div className={classes.tooltip}>
                                <span className={classes.tooltipTitle}>{id}</span>
                                <span className={classes.tooltipText}>{translate('Tips: @tips', { tips: formatAsCurrencyNumber(value) })}</span>
                            </div>
                        );
                    }}
                />
            )}
            {showTable && <ManagerUserTipsTableReport managerUsersOrdersAndTips={managerUserSalesReport.managerUserOrdersAndTips} />}
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 100%',
        overflowX: 'scroll',
        [theme.breakpoints.up('md')]: {
            flex: '1 1 45%',
            overflowX: 'unset',
        },
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
    iconButton: {
        color: theme.palette.icons.primary,
    },
    tooltip: {
        padding: 10,
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipTitle: {
        fontFamily: theme.typography.bold,
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    managerUserSalesReport: ManagerUserSalesReportVm;
};
