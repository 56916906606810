/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomAlertDialogId } from 'src/types/Id';

export async function disableCustomAlertDialogApi(request: DisableCustomAlertDialogApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('customAlertDialogs/disableCustomAlertDialogApi', request);
}

type DisableCustomAlertDialogApiRequest = {
    customAlertDialogId: CustomAlertDialogId;
};
