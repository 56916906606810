/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function getHeatMapDeliveryOrdersReportApi(request: GetHeatMapDeliveryOrdersReportApiRequest): ApiSauceResponse<GetHeatMapDeliveryOrdersReportApiResponse> {
    return pidedirectoReportsMethod('/getHeatMapDeliveryOrdersReportApi', request);
}

export type GetHeatMapDeliveryOrdersReportApiRequest = {
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
};

type GetHeatMapDeliveryOrdersReportApiResponse = {
    orderHeatmapIframe: string;
    orderHeatmapKpis: {
        totalCustomers: string;
        totalOrders: string;
        averageDeliveryTime: string;
    };
};
