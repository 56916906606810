/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { Card } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { findRestaurantPasswordApi } from 'src/api/letseatadmin/restaurant/findRestaurantPasswordApi';
import { removeRestaurantApi } from 'src/api/letseatadmin/restaurant/removeRestaurantApi';
import { changeTheRestaurantApi } from 'src/api/letseatadmin/theRestaurant/changeTheRestaurantApi';
import type { TheRestaurantVm } from 'src/api/letseatadmin/theRestaurant/getTheRestaurantApi';
import { Button } from 'src/components/Button';
import { Chip } from 'src/components/Chip';
import { ChangePasswordDialog } from 'src/components/dialog/ChangePasswordDialog';
import { Form } from 'src/components/form/Form';
import { FormCitySelect } from 'src/components/form/FormCitySelect';
import { FormCompaniesAutocompleteMultiple } from 'src/components/form/FormCompaniesAutocompleteMultiple';
import { FormCountrySelect } from 'src/components/form/FormCountrySelect';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormGoogleMapsAddressSearchAutocomplete } from 'src/components/form/FormGoogleMapsAddressSearchAutocomplete';
import { FormImageUpload } from 'src/components/form/FormImageUpload';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormPasswordField } from 'src/components/form/FormPasswordField';
import { FormPrefixPhoneNumberField } from 'src/components/form/FormPrefixPhoneNumberField';
import { FormRestaurantStatisticsReportEmails } from 'src/components/form/FormRestaurantStatisticsReportEmails';
import { FormRestaurantTypeSelect } from 'src/components/form/FormRestaurantTypeSelect';
import { FormServiceTypeSelect } from 'src/components/form/FormServiceTypeSelect';
import { FormStateSelect } from 'src/components/form/FormStateSelect';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormTimeZoneSelect } from 'src/components/form/FormTimeZoneSelect';
import { FormUrlField } from 'src/components/form/FormUrlField';
import { FormZoneAutocomplete } from 'src/components/form/FormZoneAutocomplete';
import { Spacing } from 'src/components/spacing/Spacing';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { SmsServiceProviderSetting } from 'src/scenes/letseatadmin/theRestaurant/restaurantGeneralSettings/SmsServiceProviderSetting';
import { getCountryCallingCode } from 'src/scenes/letseatmanager/phoneNumber/getCountryCallingCode';
import { CsdCertificateSettings } from 'src/scenes/letseatmanager/theRestaurant/csdCertificate/CsdCertificateSettings';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { appColors } from 'src/theme/AppColors';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getDirtyFormValues } from 'src/utils/form/getDirtyFormValues';
import { useAction } from 'src/utils/react/useAction';
import { useAskForPassword } from 'src/utils/react/useAskForPassword';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantGeneralSettings({ loading, restaurant, onChangeRestaurant, setIsDirtyForm }: Props): React.ReactElement {
    const classes = useStyles();
    const askForPassword = useAskForPassword();

    const form = useForm();
    const {
        formState: { dirtyFields },
        watch,
        setValue,
        formState,
    } = form;

    const password = watch('password');
    const eatHereEnabled = watch('eatHereEnabled');
    const location = watch('location');
    const posEnabled = watch('posEnabled');
    const roomServiceEnabled = watch('roomServiceEnabled');

    const [changePasswordDialogState, setChangePasswordDialogState] = useState(false);

    const appLoading = useSelector((state) => state.app.loading);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const adminUser = useSelector((state) => state.authentication.adminUser);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);
    const { reloadRestaurant } = useReloadRestaurant();

    useEffect(() => {
        if (!loading) {
            const phoneNumber = restaurant?.phoneNumber
                ? restaurant.phoneNumber.startsWith('+')
                    ? restaurant.phoneNumber
                    : `${getCountryCallingCode(restaurant.country)}${restaurant?.phoneNumber ?? ''} `
                : null;
            const secondaryPhoneNumber = restaurant?.secondaryPhoneNumber
                ? restaurant.secondaryPhoneNumber.startsWith('+')
                    ? restaurant.secondaryPhoneNumber
                    : `${getCountryCallingCode(restaurant.country)}${restaurant?.secondaryPhoneNumber ?? ''} `
                : null;

            form.reset({
                restaurantId: restaurant.restaurantId ?? null,
                restaurantType: restaurant.restaurantType ?? null,
                name: restaurant.name ?? null,
                street: restaurant.street ?? null,
                address: restaurant.address ?? null,
                location: {
                    longitude: restaurant.location?.coordinates?.[0] ?? '' ?? null,
                    latitude: restaurant.location?.coordinates?.[1] ?? '' ?? null,
                },
                phoneNumber: phoneNumber ?? null,
                secondaryPhoneNumber: secondaryPhoneNumber ?? null,
                info: restaurant.info ?? null,
                infoUrl: restaurant.infoUrl ?? null,
                companyIds: restaurant.companyIds ?? [],
                public: restaurant.public ?? null,
                country: restaurant.country ?? null,
                city: restaurant.city ?? null,
                state: restaurant.state ?? null,
                timeZone: restaurant.timeZone ?? null,
                eatHereEnabled: restaurant.eatHereEnabled ?? null,
                serviceType: restaurant.serviceType ?? null,
                takeAwayEnabled: restaurant.takeAwayEnabled ?? null,
                deliveryEnabled: restaurant.deliveryEnabled ?? null,
                plannedOrdersEnabled: restaurant.plannedOrdersEnabled ?? null,
                ordersWithoutIvaEnabled: restaurant.ordersWithoutIvaEnabled ?? null,
                restaurantTaxManagementEnabled: restaurant.restaurantTaxManagementEnabled ?? null,
                integrationsEnabled: restaurant.integrationsEnabled ?? null,
                appOrdersEnabled: restaurant.appOrdersEnabled ?? null,
                showOngoingOrdersInOrdersSalesReportEnabled: restaurant.showOngoingOrdersInOrdersSalesReportEnabled ?? null,
                username: restaurant.username ?? null,
                zone: restaurant.zone ?? null,
                contact: restaurant.contact ?? null,
                statisticsReportEmailRecipients: restaurant.statisticsReportEmailRecipients ?? [],
                roomServiceEnabled: restaurant.roomServiceEnabled ?? null,
                roomServiceCustomLabel: restaurant.roomServiceCustomLabel ?? null,
                roomServiceFixedDeliveryCost: restaurant.roomServiceFixedDeliveryCost ?? null,
                deliveryProviderSupportEnabled: restaurant.deliveryProviderSupportEnabled ?? null,
                displayPeriodByDateAndTimeEnabled: restaurant.displayPeriodByDateAndTimeEnabled ?? null,
                posSimpleView: restaurant.posSimpleView ?? false,
                posEnabled: restaurant.posEnabled ?? null,
                demoModeEnabled: restaurant.demoModeEnabled ?? null,
                csdCertificateRestaurantId: restaurant.csdCertificateRestaurantId ?? null,
                customerAuthenticationSmsServiceProvider: restaurant.customerAuthenticationSmsServiceProvider ?? null,
            });
        }
    }, [loading]);

    useEffect(() => {
        setIsDirtyForm(Object.keys(dirtyFields).length !== 0);
    }, [formState]);

    const onSubmit = async (form: any) => {
        const dirtyFormValues = getDirtyFormValues({ form, dirtyFields });

        const response = await changeTheRestaurantApi({
            restaurantId,
            csdCertificate: form.csdCertificate ?? undefined,
            csdCertificateKey: form.csdCertificateKey ?? undefined,
            csdCertificatePassword: form.csdCertificatePassword ?? undefined,
            csdCertificateRestaurantId: form.csdCertificateRestaurantId ?? undefined,
            ...dirtyFormValues,
        });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        await reloadRestaurant(restaurantId);
        await onChangeRestaurant();

        showSnackbar({ message: translate('Saved changes') });
    };

    const handleClickShowPassword = async () => {
        if (password) {
            return;
        }
        const response = await findRestaurantPasswordApi({ restaurantId: restaurantId });
        if (!response.ok) {
            alert(translate('Failed to download password'));
        }
        setValue('password', response.data || 'unknown');
    };

    const handleClickEditPassword = async () => {
        const rightPassword = await askForPassword({ password: '9988' });
        if (!rightPassword) return;
        setChangePasswordDialogState(true);
    };

    const handleCloseChangePasswordDialog = () => setChangePasswordDialogState(false);

    return (
        <Paper className={(classes as any).paper}>
            <ChangePasswordDialog open={changePasswordDialogState} username={restaurant.username} onClose={handleCloseChangePasswordDialog} onSuccess={onChangeRestaurant} />
            <UpdatingContentProgress loading={loading || appLoading} />
            <Form form={form} onSubmit={onSubmit} disabled={loading || appLoading}>
                <div className={(classes as any).formContent}>
                    <Grid container spacing={2}>
                        {restaurant.salesforceRestaurantType && (
                            <Grid item xs={12}>
                                <Chip>{restaurant.salesforceRestaurantType}</Chip>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', gap: 12 }}>
                                <FormRestaurantTypeSelect
                                    name='restaurantType'
                                    label={translate('Restaurant Type')}
                                    tooltipText={translate(
                                        'RESTAURANT: Default, most allies would have this value, KITCHEN: For lesteat, the restaurants that are of the KITCHEN type are going to be the locations that deliver the food, PICKUP_STATION: For letseat, the restaurants that are of this type are going to be the locations that receive the food from the KITCHEN-type restaurants.'
                                    )}
                                    required
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='name' label={translate('Name')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='street' label={translate('Street')} tooltip={translate('Street where the establishment is located')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormGoogleMapsAddressSearchAutocomplete
                                name={'address'}
                                label={translate('Google Street Address')}
                                autocompleteChange={(address) => {
                                    if (!address) return;
                                    setValue('location.latitude', address.location.coordinates[1]);
                                    setValue('location.longitude', address.location.coordinates[0]);
                                }}
                                tooltip={translate('Location of establishment on Google Maps')}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <FormNumberField
                                name='location.latitude'
                                label={translate('Location Latitude')}
                                required={!!location?.longitude}
                                min={-90}
                                max={90}
                                tooltip={translate('Latitude information obtained by setting the exact location of Google')}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <FormNumberField
                                name='location.longitude'
                                label={translate('Location Longitude')}
                                required={!!location?.latitude}
                                min={-180}
                                max={180}
                                tooltip={translate('Longitude information obtained by setting the exact location of Google')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <span className={(classes as any).label}>{translate('Phone Number')}</span>
                            <FormPrefixPhoneNumberField required name='phoneNumber' />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <span className={(classes as any).label}>{translate('Secondary Phone Number')}</span>
                            <FormPrefixPhoneNumberField name='secondaryPhoneNumber' />
                        </Grid>
                        <Grid item xs={12}>
                            <FormImageUpload name='logoImageUrl' label={translate('Logo Image')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                name='info'
                                label={translate('Info')}
                                tooltip={translate('Show in the white label app an information message below the restaurant information')}
                                helperText={translate('Info visible in app below opening hours when opening a restaurant (the top of the menu page)')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormUrlField
                                name='infoUrl'
                                label={translate('Info Url')}
                                tooltip={translate('Show the link of your website')}
                                helperText={translate('The url which the info box will redirect when the customer press on it')}
                            />
                        </Grid>
                    </Grid>
                    <Spacing units={3} />
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Card classes={{ cardContainer: classes.section }}>
                                <Grid item xs={12}>
                                    <h1>{translate('Services')}</h1>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch name='eatHereEnabled' label={translate('Eat Here')} tooltip={translate('Enables orders to eat inside the restaurant')} />
                                    {eatHereEnabled && <FormServiceTypeSelect name='serviceType' label={translate('ServiceType')} required />}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch name='takeAwayEnabled' label={translate('Take Away')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch name='posSimpleView' label={translate('POS Simple View')} tooltip={translate('Extra fast sale')} disabled={!posEnabled} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch name='deliveryEnabled' label={translate('Delivery')} tooltip={translate('Enable orders for home delivery')} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormSwitch name='demoModeEnabled' label={translate('Demo Mode')} tooltip={translate('Enable demo mode')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch name='roomServiceEnabled' label={translate('Room Service')} />
                                </Grid>
                                {roomServiceEnabled && (
                                    <Grid item xs={12}>
                                        <FormTextField
                                            name='roomServiceCustomLabel'
                                            label={translate('Service Type')}
                                            helperText={translate('This name will appear to identify the type of delivery e.g. room, classroom, living room etc.')}
                                        />
                                    </Grid>
                                )}
                                {roomServiceEnabled && (
                                    <Grid item xs={12}>
                                        <FormCurrencyNumberStringField
                                            name='roomServiceFixedDeliveryCost'
                                            label={translate('Fixed delivery cost')}
                                            helperText={translate('Set a fixed cost for room service deliveries')}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <FormSwitch name='plannedOrdersEnabled' label={translate('Planned orders')} tooltip={translate('Enable planned orders to ecommerce and white label app')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='appOrdersEnabled'
                                        label={translate('PideDirecto Marketplace App Orders Enabled')}
                                        tooltip={translate('Enable this option to receive orders from the marketplace app to your restaurant')}
                                    />
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card classes={{ cardContainer: classes.section }}>
                                <Grid item xs={12}>
                                    <h1>{translate('Management')}</h1>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch name='restaurantTaxManagementEnabled' label={translate('Taxes Management')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch name='deliveryProviderSupportEnabled' label={translate('Live Support')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch name='ordersWithoutIvaEnabled' label={translate('Orders without IVA')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch name='integrationsEnabled' label={translate('Enable integrations')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='showOngoingOrdersInOrdersSalesReportEnabled'
                                        label={translate('Show ongoing orders in orders sales report')}
                                        tooltip={translate('Enable this option to show the ongoing orders totals (not only completed) in orders sales report inside Orders and All orders page')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SmsServiceProviderSetting />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='displayPeriodByDateAndTimeEnabled'
                                        label={translate('Enable period by date and time')}
                                        tooltip={translate(
                                            'For businesses that work until after midnight. The client will be able to select what they want to view in their reports with day/time details'
                                        )}
                                    />
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Tooltip title={translate('Delete your restaurant information')}>
                                <Button
                                    text
                                    onClick={async () => {
                                        const password = window.prompt('Please enter password to remove Restaurant', 'Password');
                                        if (password === '9999') {
                                            const remove = window.confirm(
                                                'Are you sure you want to remove this restaurant? Restaurant will no longer be accessible in the manager. Contact developer to undo remove.'
                                            );
                                            if (remove) {
                                                const response = await removeRestaurantApi({ restaurantId: restaurantId });
                                                if (!response.ok) {
                                                    alert('Failed to remove restaurant');
                                                    return;
                                                }
                                                alert('Restaurant removed and will no longer be accessible from manager');
                                            }
                                        } else {
                                            alert('Wrong Password');
                                        }
                                    }}
                                    classes={{ button: (classes as any).deleteButton }}
                                >
                                    {translate('Remove Restaurant').toUpperCase()}
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            <FormCompaniesAutocompleteMultiple
                                name='companyIds'
                                label={translate('Company Names')}
                                helperText={`${translate(
                                    'Add companies separated by commas, e.g. Company Name 1, Company Name 2. Adding company names will make the restaurant visible only for customers with one of the company names.'
                                )}`}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSwitch name='public' label={translate('Public Pickup Station')} />
                            <FormHelperText>
                                {translate(
                                    'Makes this Pickup Station visible for all customers in the select pickup station list in app (And not just for customers with company name like the ones in field Company Names above)'
                                )}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                    <Spacing units={4} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormCountrySelect name='country' label={translate('Country')} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormCitySelect name='city' label={translate('City')} helperText={translate('Contact developer to add new cities')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormStateSelect name='state' label={translate('State')} />
                        </Grid>
                        <Grid></Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTimeZoneSelect name='timeZone' label={translate('Time Zone')} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormZoneAutocomplete name='zone' label={translate('Zone')} required />
                        </Grid>
                    </Grid>
                    <Spacing units={4} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='username' label={translate('Username')} disabled />
                        </Grid>
                        {!adminUser && (
                            <Grid item xs={12} sm={6}>
                                <FormPasswordField name='password' label={translate('Password')} onClickShowPassword={handleClickShowPassword} disabled />
                            </Grid>
                        )}
                        {adminUser && (
                            <Grid item xs={12} sm={6} className={(classes as any).passwordContainer}>
                                <div className={(classes as any).passwordInputContainer}>
                                    <FormPasswordField name='password' label={translate('Password')} onClickShowPassword={handleClickShowPassword} disabled />
                                </div>
                                <Button classes={{ button: (classes as any).changePasswordButton }} onClick={handleClickEditPassword}>
                                    {translate('Change password')}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <Spacing units={4} />
                    <Grid item xs={12}>
                        <FormRestaurantStatisticsReportEmails name={'statisticsReportEmailRecipients'} />
                    </Grid>
                    <Spacing units={4} />
                    <Grid container spacing={3}>
                        <CsdCertificateSettings onChangeRestaurant={onChangeRestaurant} form={form} />
                    </Grid>
                    <FormFixErrorHint />
                </div>
                <Grid container justify='center' spacing={3}>
                    <Grid item>
                        <FormSubmitButton text={translate('Change')} disabled={loading || appLoading} classes={{ container: (classes as any).submitButton }} />
                    </Grid>
                </Grid>
            </Form>
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
    },
    formContent: {
        padding: theme.spacing(3),
    },
    deleteButton: {
        color: appColors.text.dangerLight,
    },
    sectionTitleContainer: {
        backgroundColor: theme.palette.secondary.light,
        marginTop: 20,
    },
    sectionTitle: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
    },
    subtitlesubtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    cashRegisterCustomReasonItem: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        minWidth: 140,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #676060B2',
        borderRadius: 12,
        padding: '0 10px',
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
        color: theme.palette.secondary.contrastText,
        marginLeft: 10,
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    paymentMethod: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    kioskUserContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    kioskInfo: {
        display: 'flex',
        flexDirection: 'column',
    },
    integrationStoreStatusClosed: {
        fontFamily: theme.typography.semiBold,
        color: '#fa6684',
        fontSize: 13,
    },
    integrationStoreStatusOpen: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 13,
    },
    tooltip: {
        backgroundColor: 'white',
    },
    emails: {
        margin: '10px 0',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    label: {
        color: '#2E3748',
        fontSize: '14px',
        fontFamily: 'PoppinsLight',
        paddingBottom: '5px',
    },
    passwordContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        gap: 12,
    },
    passwordInputContainer: {
        flexGrow: '1' as any,
    },
    changePasswordButton: {
        height: 30,
    },
    submitButton: {
        zIndex: 1000,
    },
    section: {
        width: '100%',
        borderRadius: 5,
        border: `1px solid ${theme.palette.secondary.dark}`,
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        paddingTop: 5,
        gap: 20,
        marginTop: 20,
        marginBottom: 20,
    },
}));

type Props = {
    loading: boolean;
    restaurant: TheRestaurantVm;
    onChangeRestaurant: any;
    setIsDirtyForm: any;
};
