/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables';
import { useContext, useMemo, useRef } from 'react';
import * as React from 'react';
import { PageContext } from 'src/components/Page';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';
import { createFilteredOnDownloadFunctional } from 'src/utils/mui-datatables/createFilteredOnDownloadFunctional';
import { classNames } from 'src/utils/react/classNames';
import { isString } from 'src/utils/string/isString';

export function Table(props: Props): React.ReactElement {
    const classes = useStyles();

    const table = useRef<any>(null);

    const pageContext = useContext(PageContext);

    function customSortInTables(data: any[], colIndex: number, order: string): any[] {
        return data.sort((a, b) => {
            const firstValue = a.data[colIndex];
            const secondValue = b.data[colIndex];

            const isSpecialChar = (value: string) => /^[^\w\s]/.test(value);
            const startsWithNumber = (value: string) => /^\d/.test(value);

            if (isString(firstValue)) {
                if (isSpecialChar(firstValue) && !isSpecialChar(secondValue)) {
                    return order === 'desc' ? 1 : -1;
                } else if (!isSpecialChar(firstValue) && isSpecialChar(secondValue)) {
                    return order === 'desc' ? -1 : 1;
                } else if (startsWithNumber(firstValue) && startsWithNumber(secondValue)) {
                    const num1 = parseFloat(firstValue);
                    const num2 = parseFloat(secondValue);
                    return (num1 - num2) * (order === 'desc' ? -1 : 1);
                } else {
                    return firstValue.localeCompare(secondValue) * (order === 'desc' ? -1 : 1);
                }
            }

            return (firstValue < secondValue ? -1 : 1) * (order === 'desc' ? 1 : -1);
        });
    }
    const addTableToStackTrace = () => {
        pageContext.addElementToStackTrace(normalizeUiStackTrace(`table`));
    };

    return useMemo(
        () => (
            <div className={classNames(classes.container, props?.classes?.container)} onClickCapture={addTableToStackTrace}>
                <MUIDataTable
                    {...props}
                    /* @ts-ignore */
                    ref={table}
                    options={{
                        ...props.options,
                        customSort: (data, colIndex, order) => customSortInTables(data, colIndex, order),
                        onDownload: props.options?.onDownload ?? createFilteredOnDownloadFunctional(table),
                        customSearch: (searchQuery, currentRow, columns) => {
                            let matchFound = false;
                            columns.forEach((column, colIndex) => {
                                const columnValue = currentRow[colIndex];

                                if (columnValue?.toString().toLowerCase().includes(searchQuery.toLowerCase())) {
                                    matchFound = true;
                                }
                            });

                            return matchFound;
                        },
                    }}
                />
                <UpdatingContentProgress loading={!!props.loading} bottom />
            </div>
        ),
        [props]
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        width: '100%',
        '& .MuiPaper-root': {
            paddingTop: 0,
            '& .MuiTableCell-root': {
                fontFamily: theme.typography.regular,
                fontSize: 14,
                padding: '16px 16px',
            },
            boxShadow: '0px 0px',
            '& .MuiToolbar-root': {
                display: 'flex',
                gap: 8,
                marginRight: -4,
                backgroundColor: theme.palette.surface.primary,
                paddingRight: 0,
                paddingLeft: 0,
                '& button': {
                    backgroundColor: theme.palette.surface.primary,
                    color: theme.palette.surface.brandContrast,
                    display: 'inline-flex',
                    verticalAlign: 'middle',
                    borderRadius: 8,
                    marginRight: 4,
                    border: `1px solid ${theme.palette.border.primary}`,
                },
                '& button:hover': {
                    backgroundColor: theme.palette.surface.brandContrast,
                    color: theme.palette.text.invert,
                    border: `1px solid ${theme.palette.surface.brandContrast}`,
                },
            },
            '& .MuiTableFooter-root': {
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                border: `1px solid ${theme.palette.surface.secondary}`,
                borderTop: 0,
                borderCollapse: 'separate',
                '& p, div': {
                    fontFamily: theme.typography.regular,
                    fontSize: 13,
                },
                '& .MuiToolbar-root': {
                    backgroundColor: theme.palette.surface.primary,

                    '& .MuiButtonBase-root': {
                        backgroundColor: theme.palette.surface.primary,
                        borderRadius: 5,
                        border: `0px`,
                    },
                    '& .MuiButtonBase-root:hover': {
                        backgroundColor: theme.palette.surface.primary,
                    },
                },
                '& td': {
                    border: 0,
                },
                '& td:hover': {
                    backgroundColor: theme.palette.surface.primary,
                },
            },
            '& h6': {
                fontFamily: theme.typography.medium,
            },
        },
        '& .MuiPaper-root > div:nth-child(3)': {
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            border: `1px solid ${theme.palette.surface.tertiary}`,
            borderBottom: 0,
            '& th': {
                fontFamily: theme.typography.semiBold,
                backgroundColor: theme.palette.surface.tertiary,
                border: 0,
                fontSize: 15,
            },
            '& td, .MuiTypography-root': {
                fontFamily: theme.typography.regular,
                border: 0,
                fontSize: 13,
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiPaper-root': {
                backgroundColor: theme.palette.surface.primary,
                '& .MuiToolbar-root': {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '20px 0',
                    '& .MuiButtonBase-root & button': {
                        width: 'calc(85vw / 7)',
                        height: 'calc(85vw / 7)',
                    },
                    '& div:nth-child(2)': {
                        '& > .MuiButtonBase-root:first-child': {
                            marginLeft: '0 !important',
                        },
                    },
                },
                '& .MuiTable-root': {
                    width: '90%',
                    margin: '0 auto',
                    '& .MuiTableFooter-root': {
                        backgroundColor: theme.palette.surface.primary,
                        '& .MuiToolbar-root > p:nth-child(2)': {
                            display: 'none',
                        },
                    },
                },
            },
            '& .MuiPaper-root > div:nth-child(3)': {
                width: '90%',
                margin: '0 auto',
                backgroundColor: theme.palette.surface.secondary,
            },
        },
    },
    '.MUIDataTableHead-fixedHeader': {
        zIndex: '1 !important' as any,
    },
}));

type Props = Omit<MUIDataTableProps, 'title'> & {
    className?: string; // TODO: check if it can be removed, is it used?
    ref?: any; // TODO: check if it can be removed, does it even work?
    title?: React.ReactNode;
    loading?: boolean;
    classes?: {
        container?: string;
    };
    options?: MUIDataTableOptions;
};
