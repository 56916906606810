/**
 * @prettier
 */
import { useSelector } from 'src/utils/react/useSelector';

export function usePreparationTime(): number {
    const preparationTimeInMinutes = useSelector((state) => state.app.restaurant.preparationTime);
    const temporaryPreparationTime = useSelector((state) => state.app.restaurant.temporaryPreparationTime);

    return temporaryPreparationTime ?? preparationTimeInMinutes;
}
