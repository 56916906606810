/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { disintegrateRestaurantWithRappiApi } from 'src/api/letseatadmin/rappiIntegration/disintegrateRestaurantWithRappiApi';
import { findRappiIntegrationsApi, FindRappiIntegrationsApiResponse } from 'src/api/letseatadmin/rappiIntegration/findRappiIntegrationsApi';
import { app2 } from 'src/app2';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import type { RappiIntegrationStatus } from 'src/constants/RappiIntegrationStatus';
import { translate } from 'src/i18n/translate';
import { ChangeRappiIntegrationStatusDialog } from 'src/scenes/letseatadmin/rappiIntegration/ChangeRappiIntegrationStatusDialog';
import type { RestaurantId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RappiIntegrationsPage(): React.ReactElement {
    const classes = useStyles();
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState<Array<FindRappiIntegrationsApiResponse>>([]);
    const [changeRappiIntegrationDialogState, setChangeRappiIntegrationDialogState] = useState<{
        open: boolean;
        restaurantId?: RestaurantId;
        rappiIntegrationStatus?: RappiIntegrationStatus;
    }>({
        open: false,
        restaurantId: undefined,
        rappiIntegrationStatus: undefined,
    });

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Rappi Integrations'));
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await findRappiIntegrationsApi();
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        console.log(response.data);
        setVm(response.data);
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <ChangeRappiIntegrationStatusDialog
                open={changeRappiIntegrationDialogState.open}
                restaurantId={changeRappiIntegrationDialogState.restaurantId}
                rappiIntegrationStatus={changeRappiIntegrationDialogState.rappiIntegrationStatus}
                onClose={() => {
                    setChangeRappiIntegrationDialogState({ open: false, restaurantId: undefined, rappiIntegrationStatus: undefined });
                    load();
                }}
            />
            <MUIDataTable
                /* @ts-ignore */
                className={classes.table}
                data={
                    vm &&
                    vm.map((info) => ({
                        restaurantId: info.restaurantId,
                        restaurantName: info.name,
                        rappiStoreId: info.rappiStoreId,
                        rappiIntegrationStatus: info.rappiIntegrationStatus,
                        rappiVersion: info.rappiVersion,
                    }))
                }
                columns={[
                    {
                        name: 'restaurantId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'restaurantName',
                        label: translate('Restaurant Name'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'rappiStoreId',
                        label: translate('Rappi Store Id'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'rappiIntegrationStatus',
                        label: translate('Rappi Integration Status'),
                    },
                    {
                        name: 'rappiVersion',
                        label: translate('Rappi Version'),
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: !viewUser ? 'multiple' : 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                        </>
                    ),
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedRestaurantId = selectedRowsData.map((d: any) => d[0]);
                        const selectedRappiIntegrationStatus = selectedRowsData.map((d: any) => d[3]);
                        const handleClickEdit = () => {
                            if (selectedRestaurantId.length === 1)
                                setChangeRappiIntegrationDialogState({ open: true, restaurantId: selectedRestaurantId[0], rappiIntegrationStatus: selectedRappiIntegrationStatus[0] });
                        };
                        return (
                            <div className={classes.toolbar}>
                                {selectedRestaurantId.length === 1 && <ChangeToolbarButton onClick={handleClickEdit} />}
                                {selectedRestaurantId.length === 1 && (
                                    <RemoveToolbarButton
                                        onClick={async () => {
                                            const remove = window.confirm('Are you sure you want to disintegrate the selected integration');
                                            if (remove) {
                                                const response = await disintegrateRestaurantWithRappiApi({
                                                    restaurantId: selectedRestaurantId[0],
                                                });
                                                if (!response.ok) {
                                                    alertKnownErrorOrSomethingWentWrong(response);
                                                    return;
                                                }
                                            }
                                        }}
                                    />
                                )}
                            </div>
                        );
                    },
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));
