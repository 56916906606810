/**
 * @prettier
 */
import * as React from 'react';
import { findPromoCodesApi } from 'src/api/letseatmanager/promoCode/findPromoCodesApi';
import { FormSelect } from 'src/components/form/FormSelect';
import { ProductLayout } from 'src/constants/ProductLayouts';
import type { RestaurantId } from 'src/types/Id';
import { useLoadApi } from 'src/utils/react/useLoadApi';

export function FormPromoCodeSelect({ onChange, defaultValue, label, name, restaurantIds, disabled }: Props): React.ReactElement {
    const [loadingPromoCodes, promoCodes] = useLoadApi(findPromoCodesApi, { restaurantIds }, { initialValue: { promoCodes: [] }, dependencies: [restaurantIds] });

    const options = promoCodes?.promoCodes.map((promoCode: any) => ({ label: promoCode?.code, value: promoCode?.code }));

    return <FormSelect label={label} name={name} onChange={onChange} options={options ?? []} defaultValue={defaultValue} disabled={disabled || loadingPromoCodes} />;
}

type Props = {
    onChange?: any;
    name: string;
    label?: string;
    defaultValue?: ProductLayout;
    restaurantIds: Array<RestaurantId>;
    disabled?: boolean;
};
