/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Text } from '@pidedirecto/ui';
import { BigNumber } from 'bignumber.js';
import { memo, useMemo } from 'react';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { PosCartItemNote } from 'src/scenes/letseatmanager/pos/posMenu/PosCartItemNote';
import { PosCartItemQuantity } from 'src/scenes/letseatmanager/pos/posMenu/PosCartItemQuantity';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { ModifierGroupVm, ModifierVm, type CartItemVm } from 'src/types/CartItemVm';
import { roundDigits } from 'src/utils/number/roundDigits';
import { calculateOrderItemPriceWithPromo } from 'src/utils/order/calculateOrderItemPriceWithPromo';
import { isOrderItemPrinted } from 'src/utils/order/isOrderItemPrinted';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export const PosCartItem: React.ComponentType<Props> = memo<Props>(({ item }: Props) => {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const currencyFormat = useSelector((state) => state.app.restaurant.currencyFormat);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const menuItems = useSelector((state) => state.pos.posMenu?.menuItems);
    const promotions = useSelector((state) => state.pos.promotions);
    const usedPromotions = useSelector((state) => state.pos.payment?.usedPromotions);

    const isCancelledItem = !!item.removedAt;

    const usedOrderItemPromotion = usedPromotions?.find((usedPromotion) => usedPromotion.cartItemKey === item.key);
    const promotion = promotions.find((promotion) => usedOrderItemPromotion?.promotionId === promotion.promotionId);
    const quantityLabel = !!item.salesUnit ? `${item.quantity}${translate(`UnitsOfMeasurement.${item.salesUnit}`)}` : item.quantity;
    const totalPromoPrice = roundDigits(
        BigNumber(item?.unitPrice ?? 0)
            .multipliedBy(item.quantity)
            .toString()
    );

    const isItemSent = useMemo(() => (!!pendingOrder ? isOrderItemPrinted(item, pendingOrder?.orderItems, menuItems) : false), [pendingOrder, item]);

    const allModifiersHasQuantityEqualToOne = item.modifierGroups?.every((modifierGroup: ModifierGroupVm) => {
        return modifierGroup.modifiers?.every((modifier: ModifierVm) => modifier.quantity === 1);
    });

    return (
        <div className={classNames(classes.container, isItemSent && !isCancelledItem ? classes.itemPrinted : '')}>
            <div className={classes.itemNameContainer}>
                <div className={classes.spaceBetween}>
                    <div className={classes.nameContainer}>
                        <p className={classNames(classes.itemName)}>{item.name}</p>
                        {!!promotion && (
                            <Text className={classNames(classes.promotion)} size='small'>
                                {promotion.name}
                            </Text>
                        )}
                    </div>
                    {!isCancelledItem && (
                        <div className={classes.itemPriceContainer}>
                            <span className={classNames(classes.itemPrice, item.promoUnitPrice ? classes.itemPriceWithDiscount : '')}>{formatAsCurrencyNumber(totalPromoPrice)}</span>
                            {item.promoUnitPrice && <span className={classes.promoText}>{formatAsCurrencyNumber(calculateOrderItemPriceWithPromo(item))}</span>}
                        </div>
                    )}
                    {isCancelledItem && <span className={classes.itemPrice}>{quantityLabel}</span>}
                </div>
                <div className={classes.modifierGroups}>
                    {item.modifierGroups?.map((modifierGroup: ModifierGroupVm) => (
                        <div key={modifierGroup.name} className={classes.modifierGroups}>
                            {modifierGroup.showModifierGroupNameInCommand && <div className={classes.modifierGroupName}>{modifierGroup.name}</div>}
                            {modifierGroup.modifiers?.map((modifier: ModifierVm) => (
                                <div key={modifier.name} className={classes.spaceBetween}>
                                    <div className={classes.modifier}>
                                        {`${modifierGroup.showModifierGroupNameInCommand ? '* ' : ''}${allModifiersHasQuantityEqualToOne ? '' : 'x' + modifier.quantity} ${modifier.name}`}
                                    </div>
                                    {!BigNumber(modifier.price ?? 0).isZero() && <div className={classes.modifier}>{formatAsCurrencyNumber(modifier.price)}</div>}
                                </div>
                            ))}
                        </div>
                    ))}
                    {isItemSent && !isCancelledItem && <span className={classes.itemPrintedLabel}>{translate('Sent')}</span>}
                </div>
                <div className={classes.spaceBetween}>
                    <PosCartItemNote item={item} isItemSent={isItemSent} />
                    <PosCartItemQuantity item={item} />
                </div>
            </div>
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        borderRadius: 4,
    },
    itemNameContainer: {
        width: '100%',
        borderRadius: 12,
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
    },
    note: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    itemName: {
        fontFamily: theme.typography.medium,
        fontSize: 14,
        color: theme.palette.text.primary,
        margin: 0,
    },
    itemPrinted: {
        backgroundColor: theme.palette.surface.secondary,
        padding: '4px 8px',
    },
    itemPrintedLabel: {
        color: theme.palette.text.secondary,
        fontSize: 12,
        textDecoration: 'underline',
    },
    itemPrice: {
        fontFamily: theme.typography.medium,
        fontSize: 14,
        color: '#2E3748',
    },
    modifier: {
        fontFamily: theme.typography.regular,
        color: '#87888B',
        fontSize: 12,
    },
    modifierGroupName: {
        fontFamily: theme.typography.semiBold,
        fontSize: 12,
        margin: '5px 0',
    },
    itemPriceContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    itemPriceWithDiscount: {
        textDecoration: 'line-through',
        color: theme.palette.text.disabled,
    },
    promoText: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: theme.palette.primary.main,
        marginLeft: 5,
    },
    modifierGroups: {
        display: 'flex',
        flexDirection: 'column',
    },
    spaceBetween: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    nameContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    promotion: {
        color: theme.palette.text.brand,
    },
}));

type Props = {
    item: CartItemVm;
};
