/**
 * @prettier
 */
import * as React from 'react';

export function TicketReportIcon({ title }: Props): React.ReactElement {
    return (
        <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <circle cx='20' cy='20' r='20' fill='#F9E1D0' />
            <g clipPath='url(#clip0_1415_301)'>
                <path
                    d='M16 12C14.8969 12 14 12.8969 14 14V26C14 27.1031 14.8969 28 16 28H24C25.1031 28 26 27.1031 26 26V17H22C21.4469 17 21 16.5531 21 16V12H16ZM22 12V16H26L22 12ZM16 14.5C16 14.225 16.225 14 16.5 14H18.5C18.775 14 19 14.225 19 14.5C19 14.775 18.775 15 18.5 15H16.5C16.225 15 16 14.775 16 14.5ZM16 16.5C16 16.225 16.225 16 16.5 16H18.5C18.775 16 19 16.225 19 16.5C19 16.775 18.775 17 18.5 17H16.5C16.225 17 16 16.775 16 16.5ZM20 18.75C20.275 18.75 20.5 18.975 20.5 19.25V19.7906C20.7656 19.8281 21.0219 19.8875 21.2531 19.95C21.5188 20.0219 21.6781 20.2937 21.6062 20.5625C21.5344 20.8313 21.2625 20.9875 20.9938 20.9156C20.6469 20.8219 20.3063 20.7531 19.9906 20.75C19.7281 20.7469 19.4469 20.8062 19.2531 20.9219C19.075 21.0281 19 21.15 19 21.3219C19 21.4375 19.0406 21.525 19.2281 21.6375C19.4437 21.7656 19.7469 21.8594 20.1406 21.9781L20.1562 21.9812C20.5094 22.0875 20.9469 22.2188 21.2906 22.4375C21.6688 22.675 21.9906 23.0531 22 23.6313C22.0094 24.2344 21.7 24.6719 21.2844 24.9312C21.0438 25.0812 20.7719 25.1687 20.5 25.2156V25.75C20.5 26.025 20.275 26.25 20 26.25C19.725 26.25 19.5 26.025 19.5 25.75V25.1938C19.15 25.1281 18.8219 25.0156 18.5344 24.9156C18.4688 24.8937 18.4031 24.8719 18.3406 24.85C18.0781 24.7625 17.9375 24.4781 18.025 24.2188C18.1125 23.9594 18.3969 23.8156 18.6562 23.9031C18.7344 23.9281 18.8063 23.9531 18.8781 23.9781C19.3031 24.1219 19.6469 24.2406 20.0125 24.25C20.2969 24.2594 20.5719 24.1969 20.7531 24.0844C20.9125 23.9844 21 23.8563 20.9969 23.6469C20.9937 23.5031 20.9406 23.4031 20.7562 23.2844C20.5437 23.15 20.2406 23.0531 19.85 22.9344L19.8 22.9187C19.4563 22.8156 19.0406 22.6906 18.7125 22.4906C18.3375 22.2656 18.0063 21.9 18.0031 21.325C18 20.7188 18.3406 20.3 18.7469 20.0594C18.9812 19.9219 19.2406 19.8344 19.5 19.7875V19.25C19.5 18.975 19.725 18.75 20 18.75Z'
                    fill='#F66E0B'
                />
            </g>
            <defs>
                <clipPath id='clip0_1415_301'>
                    <rect width='12' height='16' fill='white' transform='translate(14 12)' />
                </clipPath>
            </defs>
        </svg>
    );
}

type Props = {
    title?: string;
};
