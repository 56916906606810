/**
 * @prettier
 */
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';

export function setSetDeveloperModeInMobileApp(value: boolean) {
    if (isMobileApp()) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: 'SET_DEVELOPER_MODE',
                data: value,
            })
        );
    }
}
