/**
 * @prettier
 */
export const ProductCancellationReasons = {
    CUSTOMER_DIDNT_LIKE_PRODUCT: 'CUSTOMER_DIDNT_LIKE_PRODUCT',
    DEFECTIVE_PRODUCT: 'DEFECTIVE_PRODUCT',
    CAPTURE_ERROR: 'CAPTURE_ERROR',
} as const;

export type ProductCancellationReason = typeof ProductCancellationReasons[keyof typeof ProductCancellationReasons];
