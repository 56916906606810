/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import { EditIcon } from '@pidedirecto/ui/icons';
import moment from 'moment';
import { MUIDataTableColumnDef } from 'mui-datatables';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { BrandCustomerVm, findBrandCustomersApi } from 'src/api/letseatmanager/brandCustomer/findBrandCustomersApi';
import { findRestaurantCustomersApi, RestaurantCustomerVm } from 'src/api/letseatmanager/restaurantCustomer/findRestaurantCustomersApi';
import { AddCreditsToCustomersDialog } from 'src/components/dialog/customer/AddCreditsToCustomersDialog';
import { AddCreditsToolbarButton } from 'src/components/mui-datatables/AddCreditsToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { history } from 'src/config/history';
import { App } from 'src/constants/App';
import { PaymentStatus, PaymentStatuses } from 'src/constants/PaymentStatus';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { ChangeRestaurantCustomerDialog } from 'src/scenes/letseatmanager/marketing/ChangeRestaurantCustomerDialog';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { appColors } from 'src/theme/AppColors';
import { CustomerId, RestaurantCustomerId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getClientFrequency } from 'src/utils/customer/getClientFrequency';
import { debounce } from 'src/utils/function/debounce';
import { sumValidGifts } from 'src/utils/gift/sumValidGifts';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useSelector } from 'src/utils/react/useSelector';
import { removeNulls } from 'src/utils/removeNulls';

export function CustomersInfo(): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [searchText, setSearchText] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const [addCreditsToCustomerDialogState, setAddCreditsToCustomerDialogState] = useState<{ open: boolean; customerIds: Array<CustomerId> | undefined }>({ open: false, customerIds: undefined });
    const [changeRestaurantCustomerDialogState, setChangeRestaurantCustomerDialogState] = useState<{ open: boolean; restaurantCustomerId: RestaurantCustomerId | undefined }>({
        open: false,
        restaurantCustomerId: undefined,
    });
    const [restaurantCustomers, setRestaurantCustomers] = useState<Array<RestaurantCustomerVm>>([]);
    const [brandCustomers, setBrandCustomers] = useState<Array<BrandCustomerVm>>([]);

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const addCreditsToCustomersEnabled = useSelector((state) => state.app.restaurant.addCreditsToCustomersEnabled);

    useEffect(() => {
        if (!brandOpened || !page) return;
        load();
    }, [restaurantId, brandOpened, restaurantIds, page]);

    useEffect(() => {
        if (page && !searchText) return;
        changeRowsPerPage();
        load();
    }, [rowsPerPage, searchText]);

    const openChangeRestaurantCustomerDialog = (restaurantCustomerId: RestaurantCustomerId) => setChangeRestaurantCustomerDialogState({ open: true, restaurantCustomerId });

    const closeChangeRestaurantCustomerDialog = () => setChangeRestaurantCustomerDialogState({ open: false, restaurantCustomerId: undefined });

    const load = () => {
        if (brandOpened) loadBrandCustomers();
        else loadRestaurantCustomers();
    };

    const loadRestaurantCustomers = async () => {
        setLoading(true);
        const response = await findRestaurantCustomersApi({
            restaurantId,
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        setRestaurantCustomers(response.data);
        setTotalItems(0);
        setBrandCustomers([]);
        return;
    };

    const loadBrandCustomers = async () => {
        setLoading(true);
        const response = await findBrandCustomersApi({
            restaurantIds,
            page,
            rowsPerPage,
            searchText,
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        setBrandCustomers(response.data?.brandCustomers);
        setTotalItems(response.data?.total ?? 0);
        setRestaurantCustomers([]);
        return;
    };

    const changePage = (page: number) => {
        setPage(page);
    };

    const changeRowsPerPage = (rowsPerPage?: number) => {
        if (rowsPerPage) setRowsPerPage(rowsPerPage);
        changePage(0);
    };

    const debouncedSearch = useRef(
        debounce((searchText: string) => {
            setSearchText(searchText);
        }, 700)
    );

    const createTableData = () => {
        const customers = brandOpened ? brandCustomers : restaurantCustomers;

        return customers?.map((customer) => {
            const clientFrequency =
                customer.clientFrequency ??
                getClientFrequency({
                    orders: customer.orders ?? 0,
                    lastOrder: customer.lastOrder,
                    secondLastOrder: customer.secondLastOrder,
                }) ??
                '';

            const data = removeNulls({
                customerId: customer.customerId,
                name: customer,
                hiddenName: customer.name,
                mobileNumber: customer.mobileNumber,
                email: customer.email,
                amount: formatAsCurrencyNumber(customer.amount),
                gift: sumValidGifts(customer.gifts),
                apps: customer.apps,
                orders: customer.orders,
                lastOrder: customer.lastOrder && moment(customer.lastOrder).format('YYYY/MM/DD HH:mm:ss'),
                clientFrequency: clientFrequency ? translate(`ClientFrequency.${clientFrequency}`) : '',
                orderedIn: undefined,
                brandCustomerId: undefined,
                restaurantCustomerId: undefined,
            });

            if ((customer as any).orderedIn) data.orderedIn = (customer as any).orderedIn;
            if ((customer as any).brandCustomerId) data.brandCustomerId = (customer as any).brandCustomerId;
            if ((customer as any).restaurantCustomerId) data.restaurantCustomerId = (customer as any).restaurantCustomerId;

            return data;
        });
    };

    const openAddCreditsToCustomerDialog = (customerIds: any) => setAddCreditsToCustomerDialogState({ open: true, customerIds });

    const closeAddCreditsToCustomerDialog = () => setAddCreditsToCustomerDialogState({ open: false, customerIds: undefined });

    const tableColumns: Array<MUIDataTableColumnDef> = [
        {
            name: 'customerId',
            label: '',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'restaurantCustomerId',
            label: '',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'brandCustomerId',
            label: '',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'name',
            label: translate('Name'),
            options: {
                filter: false,
                customBodyRender: (customer: RestaurantCustomerVm) => {
                    return (
                        <div className={classes.customerNameContainer}>
                            <span>{customer.name}</span>
                            {(!customer.mobileNumber || !customer.name || !customer.customerId) && (
                                <span className={classes.textError}>{translate('This customer is not able to receive credits')}</span>
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: 'hiddenName',
            options: {
                filter: false,
                display: 'none' as any,
            },
        },
        {
            name: 'mobileNumber',
            label: translate('Mobile Number'),
            options: {
                filter: false,
            },
        },
        {
            name: 'email',
            label: translate('Email'),
            options: {
                filter: false,
            },
        },
        {
            name: 'amount',
            label: translate('Total'),
            options: {
                filter: false,
                customBodyRender: (status: PaymentStatus) => <div style={{ color: status === PaymentStatuses.PAYED ? appColors.text.success : '' }}>{status}</div>,
            },
        },
        {
            name: 'gift',
            label: translate('Cashback'),
            options: {
                filter: false,
            },
        },
        {
            name: 'apps',
            label: translate('App'),
            options: {
                filter: false,
                customBodyRender: (apps: Array<App>) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {apps?.map((app: any) => (
                            <span key={app}>{translate(app)}</span>
                        ))}
                    </div>
                ),
            },
        },
        {
            name: 'orders',
            label: translate('Orders'),
            options: {
                filter: false,
            },
        },
        {
            name: 'lastOrder',
            label: translate('Last order'),
            options: {
                filter: false,
            },
        },
        {
            name: 'clientFrequency',
            label: translate('Client Frequency'),
            options: {
                filter: false,
            },
        },
    ];

    if (brandOpened) {
        tableColumns.push({
            name: 'orderedIn',
            label: translate('Ordered In'),
            options: {
                filter: false,
                customBodyRender: (orderedIn: Array<string>) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {orderedIn.map((restaurant) => (
                            <span key={restaurant}>{restaurant}</span>
                        ))}
                    </div>
                ),
            },
        });
    }

    return (
        <div>
            <AddCreditsToCustomersDialog open={addCreditsToCustomerDialogState.open} customerIds={addCreditsToCustomerDialogState.customerIds} onClose={closeAddCreditsToCustomerDialog} />
            <ChangeRestaurantCustomerDialog
                open={changeRestaurantCustomerDialogState.open}
                restaurantCustomerId={changeRestaurantCustomerDialogState.restaurantCustomerId}
                onClose={closeChangeRestaurantCustomerDialog}
            />
            <Table
                data={createTableData()}
                columns={tableColumns}
                loading={loading}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: addCreditsToCustomersEnabled ? 'multiple' : 'none',
                    filterType: 'checkbox',
                    rowsPerPageOptions: [10, 20, 50, 100],
                    rowsPerPage: rowsPerPage || 100,
                    count: totalItems || restaurantCustomers.length,
                    serverSide: brandOpened,
                    page: page,
                    onSearchChange: debouncedSearch.current,
                    onTableChange: (action, tableState) => {
                        switch (action) {
                            case 'changePage':
                                changePage(tableState.page);
                                break;
                            case 'changeRowsPerPage':
                                changeRowsPerPage(tableState.rowsPerPage);
                                break;
                            default:
                        }
                    },
                    onRowClick: (
                        rowData: Array<string>,
                        rowMeta: {
                            dataIndex: number;
                            rowIndex: number;
                        }
                    ) => {
                        const mobileNumber = brandOpened ? brandCustomers[rowMeta.dataIndex].mobileNumber : restaurantCustomers[rowMeta.dataIndex].mobileNumber;

                        if (!mobileNumber) return;

                        history.push({
                            pathname: RoutePaths.CUSTOMERS.replace(':mobileNumber', mobileNumber),
                            search: history.location.search,
                        });
                    },
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                        </>
                    ),
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedCustomerIds = selectedRowsData.map((d: Array<CustomerId>) => d[0]);
                        const selectedRestaurantCustomerIds: Array<RestaurantCustomerId> = selectedRowsData.map((d: Array<RestaurantCustomerId>) => d[1]);
                        const filteredCustomerIds = selectedCustomerIds.filter(Boolean);

                        if (selectedCustomerIds?.length === 0) return null;

                        return (
                            <div className={classes.toolbar}>
                                <AddCreditsToolbarButton onClick={() => openAddCreditsToCustomerDialog(filteredCustomerIds)} />
                                {selectedRestaurantCustomerIds.length === 1 && internalUser && (
                                    <Button variant='icon' onClick={() => openChangeRestaurantCustomerDialog(selectedRestaurantCustomerIds[0])}>
                                        <EditIcon size={20} title={translate('Edit customer')} />
                                    </Button>
                                )}
                            </div>
                        );
                    },
                    downloadOptions: { filename: 'customer info data .csv' },
                    onDownload: (buildHead, buildBody, columns, data) => {
                        const columnsToRemove: Array<number> = [];

                        const filteredColumns = columns.filter((column: { display: string }, index: number) => {
                            const isColumnShown = column.display === 'true';
                            if (!isColumnShown) columnsToRemove.push(index);
                            return isColumnShown;
                        });

                        const mappedData = data.map((row: any) => {
                            const isNameColumn = (idx: any) => filteredColumns.findIndex((column: { name: string }) => column.name === 'name') === idx;

                            const filteredData = row.data.filter((data: any, index: number) => !columnsToRemove.includes(index));
                            return { ...row, data: filteredData.map((data: any, index: number) => (isNameColumn(index) ? data.name : data)) };
                        });

                        return '\uFEFF' + buildHead(filteredColumns) + buildBody(mappedData);
                    },
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    customerNameContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
    },
    customerHiddenNameContainer: {
        display: 'none',
    },
    textError: {
        color: '#B40707',
        fontSize: 10,
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
        padding: '8px 6px',
    },
}));
