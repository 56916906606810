/**
 * @prettier
 */
import { store } from 'src/config/store';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';

export function setPinCodeUserInMobileApp() {
    const state = store.getState();
    const pinCodeUser = state.authentication.pinCodeUserSignedIn;
    if (isMobileApp()) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: 'SET_PIN_CODE_USER',
                data: pinCodeUser,
            })
        );
    }
}
