/**
 * @prettier
 * @flow
 */
export const MexicanIEPSTaxesPercentages = Object.freeze({
    '8': 0.08,
    '26.5': 0.265,
    '30': 0.3,
    '53': 0.53,
    '160': 1.6,
});

export type MexicanTaxesPercentage = typeof MexicanIEPSTaxesPercentages[keyof typeof MexicanIEPSTaxesPercentages];
