/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { translate } from 'src/i18n/translate';

export function ConfirmDeliveryAddressDialog({ open, onClose, loading, customerAddress, onConfirmAddress }: Props): React.ReactElement {
    const classes = useStyles();

    const { street, number } = customerAddress;

    return (
        <Dialog open={open} title={translate('Confirm the delivery address')} onClose={onClose}>
            <p className={classes.text}>{translate('You are about to send an order to:')}</p>
            <div className={classes.addressContainer}>
                <p className={classes.addressText}>
                    {translate('Street')}: <strong>{street}</strong>
                </p>
                <p className={classes.addressText}>
                    {translate('Number')}: <strong>{number}</strong>
                </p>
            </div>
            <DialogActions className={classes.buttonsContainer}>
                <Button onClick={onClose} secondary disabled={loading} classes={{ button: classes.button }}>
                    {translate('Edit')}
                </Button>
                <Button onClick={onConfirmAddress} disabled={loading} classes={{ button: classes.button }}>
                    {translate('Accept')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    text: {
        width: '100%',
        textAlign: 'center',
        fontSize: 14,
        fontFamily: theme.typography.medium,
    },
    addressContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    addressText: {
        width: '100%',
        textAlign: 'center',
        fontSize: 14,
        fontFamily: theme.typography.regular,
        margin: 0,
        color: '#616B79',
        '& strong': {
            color: '#232933',
        },
    },
    buttonsContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 24,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    button: {
        width: '100%',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onConfirmAddress?: any;
    loading: boolean;
    customerAddress: {
        street: string;
        number: string;
    };
};
