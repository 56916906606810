/**
 * @prettier
 */
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import type { CustomerId, EmployeeIdAtCompany, EmployeeNumberAtCompany } from 'src/types/Id';
import { createFilteredOnDownload } from 'src/utils/mui-datatables/createFilteredOnDownload';

export default withStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class PayrollPaymentsTable extends React.PureComponent<Props> {
        table: any; // TODO: fix correct type

        render() {
            const { classes } = this.props;
            return (
                <div style={{ position: 'relative' }}>
                    <UpdatingContentProgress loading={this.props.loading} />
                    <MUIDataTable
                        /* @ts-ignore */
                        ref={(ref) => (this.table = ref)}
                        className={classes.table}
                        title={this.props.title || translate('Customers')}
                        data={this.props.customers.map((customer) => ({
                            customerId: customer.customerId,
                            customerName: customer.customerName,
                            mobileNumber: customer.mobileNumber,
                            email: customer.email,
                            paidWithPayroll: customer.paidWithPayroll,
                            orders: customer.orders,
                        }))}
                        columns={[
                            {
                                name: 'customerId',
                                label: ' ',
                                options: {
                                    display: 'excluded',
                                    filter: false,
                                },
                            },
                            {
                                name: 'customerName',
                                label: translate('Name'),
                                options: {
                                    filter: false,
                                    searchable: true,
                                },
                            },
                            {
                                name: 'mobileNumber',
                                label: translate('Mobile Number'),
                                options: {
                                    filter: false,
                                    searchable: true,
                                },
                            },
                            {
                                name: 'email',
                                label: translate('Email'),
                                options: {
                                    filter: false,
                                    searchable: true,
                                },
                            },
                            {
                                name: 'employeeIdAtCompany',
                                label: translate('Employee Id'),
                                options: {
                                    filter: false,
                                    searchable: true,
                                },
                            },
                            {
                                name: 'employeeNumberAtCompany',
                                label: translate('Employee Number'),
                                options: {
                                    filter: false,
                                    searchable: true,
                                },
                            },
                            {
                                name: 'paidWithPayroll',
                                label: translate('Paid with Payroll'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'orders',
                                label: translate('Orders'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                        ]}
                        options={{
                            responsive: 'standard',
                            tableBodyMaxHeight: '500px',
                            selectableRows: 'none',
                            // setRowProps: (row) => {
                            //     return {
                            //         style: { backgroundColor: getOrderStatusColor(row[33], row[35]) },
                            //     };
                            // },
                            // onRowClick: (rowData: Array<string>, rowMeta: { dataIndex: number, rowIndex: number }) => {
                            //     history.push({
                            //         pathname: RoutePath.ADMIN_CUSTOMER.replace(`:customerId(${RegExps.uuid})`, rowData[0]),
                            //         search: history.location.search,
                            //     });
                            // },
                            filterType: 'multiselect',
                            rowsPerPage: 100,
                            customToolbar: () => {
                                return (
                                    <>
                                        <Tooltip title='Refresh'>
                                            <IconButton onClick={this.props.onClickRefresh}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                );
                            },
                            onDownload: createFilteredOnDownload(this),
                            // filterList: [[], [], [], [], ['MEXICO']],
                        }}
                    />
                </div>
            );
        }
    }
) as React.ComponentType<any>;

type Props = {
    loading: boolean;
    title?: string;
    customers: Array<{
        customerId: CustomerId;
        customerName: string;
        mobileNumber: string;
        email: string;
        employeeIdAtCompany?: EmployeeIdAtCompany;
        employeeNumberAtCompany?: EmployeeNumberAtCompany;
        paidWithPayroll: string;
        orders: number;
    }>;
    onClickRefresh: any;
    classes: any;
};
