/**
 * @prettier
 */
import { List, ListItem, makeStyles, useTheme } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { PrinterIcon, TerminalIcon, TrashCanIcon } from '@pidedirecto/ui/icons';
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { changeDeviceGroupApi } from 'src/api/letseatmanager/deviceGroup/changeDeviceGroupApi';
import type { DeviceGroupDetailsVm } from 'src/api/letseatmanager/deviceGroup/findDeviceGroupsApi';
import { Button } from 'src/components/Button';
import { ContextualMenu } from 'src/components/ContextualMenu';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { DeviceIcon } from 'src/icons/DeviceIcon';
import { EllipsisVerticalIcon } from 'src/icons/EllipsisVerticalIcon';
import { AddDeviceDialog } from 'src/scenes/letseatmanager/device/deviceGroups/AddDeviceDialog';
import { ChangeDeviceGroupNameDialog } from 'src/scenes/letseatmanager/device/deviceGroups/ChangeDeviceGroupNameDialog';
import { RemoveDeviceGroupDialog } from 'src/scenes/letseatmanager/device/deviceGroups/RemoveDeviceGroupDialog';
import { getDeviceId } from 'src/services/device/getDeviceId';
import { useConfirmDialog } from 'src/services/dialog/useConfirmDialog';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { DeviceId, PaymentTerminalId, PrinterId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';
import { toShortId } from 'src/utils/uuid/toShortId';

export function DeviceGroupCard({ deviceGroup, onChange }: Props): React.ReactElement | null {
    const classes = useStyles();
    const theme = useTheme();
    const confirmDialog = useConfirmDialog();
    const { reloadRestaurant } = useReloadRestaurant();

    const [changeDeviceGroupNameDialogState, setChangeDeviceGroupNameDialogState] = useState({ open: false });
    const [addDeviceDialogState, setAddDeviceDialogState] = useState({ open: false });
    const [removeDeviceGroupDialogState, setRemoveDeviceGroupDialogState] = useState({ open: false });

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const linkedDevices = BigNumber(deviceGroup.devices?.length || 0)
        .plus(deviceGroup.printers?.length || 0)
        .plus(deviceGroup.paymentTerminals?.length || 0)
        .toNumber();
    const deviceIds = deviceGroup.devices?.map((device: { deviceId: DeviceId; deviceName: string }) => device.deviceId);
    const printerIds = deviceGroup.printers?.map((printer: { deviceName: string; printerId: PrinterId }) => printer.printerId);
    const paymentTerminalIds = deviceGroup.paymentTerminals?.map((paymentTerminal: { deviceName: string; paymentTerminalId: PaymentTerminalId }) => paymentTerminal.paymentTerminalId);

    const handleRemoveDevice = async (deviceId: DeviceId) => {
        const device = deviceGroup.devices?.find((device: { deviceId: DeviceId; deviceName: string }) => device.deviceId === deviceId);

        const removeDevice = await confirmDialog({
            title: translate('Remove @deviceName', { deviceName: device?.deviceName }),
            content: translate('Are you sure you want to remove this device from the group?'),
            buttonText: translate('Yes, I am'),
            cancelButtonText: translate('Cancel'),
            variant: 'error',
        });

        if (!removeDevice) return;

        const filteredDeviceIds = deviceIds?.filter((currentDeviceId: DeviceId) => currentDeviceId !== deviceId);

        const response = await changeDeviceGroupApi({
            deviceGroupId: deviceGroup.deviceGroupId,
            deviceGroupName: deviceGroup.deviceGroupName,
            deviceIds: filteredDeviceIds,
            printerIds,
            paymentTerminalIds,
        });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        reloadRestaurant(restaurantId);
        onChange?.();
    };

    const handleRemovePrinter = async (printerId: PrinterId) => {
        const printer = deviceGroup.printers?.find((printer: { deviceName: string; printerId: PrinterId }) => printer.printerId === printerId);

        const removePrinter = await confirmDialog({
            title: translate('Remove @deviceName', { deviceName: printer?.deviceName }),
            content: translate('Are you sure you want to remove this device from the group?'),
            buttonText: translate('Yes, I am'),
            cancelButtonText: translate('Cancel'),
            variant: 'error',
        });

        if (!removePrinter) return;

        const filteredPrinterIds = printerIds?.filter((currentPrinterId: PrinterId) => currentPrinterId !== printerId);
        const response = await changeDeviceGroupApi({
            deviceGroupId: deviceGroup.deviceGroupId,
            deviceGroupName: deviceGroup.deviceGroupName,
            deviceIds,
            printerIds: filteredPrinterIds,
            paymentTerminalIds,
        });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        reloadRestaurant(restaurantId);
        onChange?.();
    };

    const handleRemovePaymentTerminal = async (paymentTerminalId: PaymentTerminalId) => {
        const paymentTerminal = deviceGroup.paymentTerminals?.find(
            (paymentTerminal: { deviceName: string; paymentTerminalId: PaymentTerminalId }) => paymentTerminal.paymentTerminalId === paymentTerminalId
        );

        const removePaymentTerminal = await confirmDialog({
            title: translate('Remove @deviceName', { deviceName: paymentTerminal?.deviceName }),
            content: translate('Are you sure you want to remove this device from the group?'),
            buttonText: translate('Yes, I am'),
            cancelButtonText: translate('Cancel'),
            variant: 'error',
        });

        if (!removePaymentTerminal) return;

        const filteredPaymentTerminalIds = paymentTerminalIds?.filter((currentPaymentTerminalId: PaymentTerminalId) => currentPaymentTerminalId !== paymentTerminalId);

        const response = await changeDeviceGroupApi({
            deviceGroupId: deviceGroup.deviceGroupId,
            deviceGroupName: deviceGroup.deviceGroupName,
            deviceIds,
            printerIds,
            paymentTerminalIds: filteredPaymentTerminalIds,
        });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        reloadRestaurant(restaurantId);
        onChange?.();
    };

    if (!deviceGroup) return null;

    return (
        <div className={classes.container}>
            <ChangeDeviceGroupNameDialog
                open={changeDeviceGroupNameDialogState.open}
                deviceGroupId={deviceGroup.deviceGroupId}
                onClose={() => setChangeDeviceGroupNameDialogState({ open: false })}
                onSuccess={onChange}
            />
            <AddDeviceDialog open={addDeviceDialogState.open} deviceGroupId={deviceGroup.deviceGroupId} onClose={() => setAddDeviceDialogState({ open: false })} onSuccess={onChange} />
            <RemoveDeviceGroupDialog open={removeDeviceGroupDialogState.open} deviceGroup={deviceGroup} onClose={() => setRemoveDeviceGroupDialogState({ open: false })} onSuccess={onChange} />
            <div className={classes.deviceGroupNameContainer}>
                <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h2 className={classes.title}>{deviceGroup.deviceGroupName}</h2>
                    <ContextualMenu
                        closeOnClick
                        customButton={
                            <Button secondary icon classes={{ button: classes.iconButton }}>
                                <EllipsisVerticalIcon title='menu toolbar icon' />
                            </Button>
                        }
                    >
                        <List className={classes.list}>
                            <ListItem button onClick={() => setChangeDeviceGroupNameDialogState({ open: true })}>
                                <Text>{translate('Change group name')}</Text>
                            </ListItem>
                            {linkedDevices >= 1 && (
                                <ListItem button onClick={() => setAddDeviceDialogState({ open: true })}>
                                    <Text>{translate('Add device')}</Text>
                                </ListItem>
                            )}
                            <ListItem button onClick={() => setRemoveDeviceGroupDialogState({ open: true })}>
                                <Text>{translate('Remove device group')}</Text>
                            </ListItem>
                        </List>
                    </ContextualMenu>
                </div>
                <Text style={{ marginTop: 12 }}>
                    <strong>{linkedDevices}</strong> {translate('Linked devices')}
                </Text>
            </div>
            {linkedDevices >= 1 && (
                <div className={classes.devicesInfo}>
                    {deviceGroup.devices?.map((device: { deviceId: DeviceId; deviceName: string }) => {
                        return (
                            <div key={device.deviceId} className={classes.deviceContainer}>
                                <span className={classes.device}>
                                    <DeviceIcon color={theme.palette.icons.primary} />
                                    {device.deviceName ?? toShortId(device.deviceId)}
                                    {getDeviceId() === device.deviceId && <Chip label={translate('This device')} className={classes.chip} color='primary' size='small' />}
                                </span>
                                <Button icon classes={{ button: classes.deleteButton }} onClick={() => handleRemoveDevice(device.deviceId)}>
                                    <TrashCanIcon color={theme.palette.text.danger} />
                                </Button>
                            </div>
                        );
                    })}
                    {deviceGroup.printers?.map((printer: { deviceName: string; printerId: PrinterId }) => {
                        return (
                            <div key={printer.printerId} className={classes.deviceContainer}>
                                <span key={printer.printerId} className={classes.device}>
                                    <PrinterIcon color={theme.palette.icons.primary} />
                                    {printer.deviceName ?? toShortId(printer.printerId)}
                                </span>
                                <Button icon classes={{ button: classes.deleteButton }} onClick={() => handleRemovePrinter(printer.printerId)}>
                                    <TrashCanIcon color={theme.palette.text.danger} />
                                </Button>
                            </div>
                        );
                    })}
                    {deviceGroup.paymentTerminals?.map((paymentTerminal: { deviceName: string; paymentTerminalId: PaymentTerminalId }) => {
                        return (
                            <div key={paymentTerminal.paymentTerminalId} className={classes.deviceContainer}>
                                <span key={paymentTerminal.paymentTerminalId} className={classes.device}>
                                    <TerminalIcon color={theme.palette.icons.primary} />
                                    {paymentTerminal.deviceName ?? toShortId(paymentTerminal.paymentTerminalId)}
                                </span>
                                <Button icon classes={{ button: classes.deleteButton }} onClick={() => handleRemovePaymentTerminal(paymentTerminal.paymentTerminalId)}>
                                    <TrashCanIcon color={theme.palette.text.danger} />
                                </Button>
                            </div>
                        );
                    })}
                </div>
            )}
            {linkedDevices === 0 && (
                <div style={{ width: '100%', display: 'grid', placeItems: 'center', padding: '12px 0' }}>
                    <Button onClick={() => setAddDeviceDialogState({ open: true })}>{translate('Add device')}</Button>
                </div>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        maxWidth: 350,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: 24,
        position: 'relative',
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        border: '1px solid #D9D9D9',
    },
    deviceGroupNameContainer: {
        borderBottom: '1px solid #D9D9D9',
        paddingBottom: 10,
        marginBottom: 10,
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        color: '#0D3037',
        margin: 0,
    },
    subtitle: {
        fontFamily: theme.typography.regular,
        color: '#232933',
        padding: '8px 12px',
        fontSize: 12,
    },
    device: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        fontFamily: theme.typography.regular,
        fontSize: 15,
        color: '#232933',
        marginTop: 8,
    },
    thisDevice: {
        marginLeft: 4,
    },
    linkedDevices: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        marginBottom: 16,
    },
    devicesInfo: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 50,
    },
    button: {
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        border: `1px solid ${theme.palette.border.primary}`,
    },
    chip: {
        backgroundColor: theme.palette.surface.brand,
        color: theme.palette.text.brand,
    },
    deviceContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    deleteButton: {
        '&:hover': {
            backgroundColor: theme.palette.surface.danger,
            border: 0,
            outline: 0,
        },
    },
    list: {
        padding: 0,
    },
    iconButton: {
        padding: 0,
    },
}));

type Props = {
    deviceGroup: DeviceGroupDetailsVm;
    onChange?: any;
};
