/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormDeliveryProviderSelect } from 'src/components/form/FormDeliveryProviderSelect';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { DeliveryProvider, DeliveryProviders } from 'src/constants/DeliveryProviders';
import { translate } from 'src/i18n/translate';

export function CreateDeliveryProviderDialog({ open, onClose, deliveryProviders, filterDeliveryProviders, onCreateDeliveryProvider }: Props): React.ReactElement | null {
    const classes = useStyles();
    const form = useForm();
    const { watch } = form;

    const deliveryProvider = watch('deliveryProvider');
    const deliveryTime = watch('deliveryTime');

    const allDeliveryProviders: Array<DeliveryProvider> = Object.keys(DeliveryProviders) as any;
    const selectedDeliveryProviders: Array<DeliveryProvider> =
        deliveryProviders?.map((deliveryProvider: { deliveryProvider: DeliveryProvider; waitTime: number }) => deliveryProvider.deliveryProvider) ?? [];
    const availableDeliveryProviders = (filterDeliveryProviders ?? allDeliveryProviders).filter((deliveryProvider) => !selectedDeliveryProviders.includes(deliveryProvider));

    const handleCreateCashDeliveryProvider = () => {
        if (!deliveryProvider || !deliveryTime) return;
        if (BigNumber(deliveryTime ?? 0).isLessThan(10)) {
            form.setError('deliveryTime', { message: translate('Waiting time must be greater than or equal to 10 seconds') });
            return;
        }

        onCreateDeliveryProvider?.(deliveryProvider, deliveryTime);
        onClose?.();
    };

    if (!open) return null;

    return (
        <Dialog title={translate('Add supplier')} onClose={onClose} open={open}>
            <Form form={form} className={classes.form}>
                <FormDeliveryProviderSelect filter={availableDeliveryProviders} name={'deliveryProvider'} label={translate('Delivery Provider')} required />
                <FormNumberField name={'deliveryTime'} label={translate('Waiting time (seconds)')} required />
                <DialogActions>
                    <Button outlined onClick={onClose}>
                        {translate('Cancel')}
                    </Button>
                    <Button onClick={handleCreateCashDeliveryProvider}>{translate('Save')}</Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
}));

type Props = {
    open: boolean;
    onClose?: any;
    deliveryProviders?: Array<{
        deliveryProvider: DeliveryProvider;
        waitTime: number;
    }>;
    filterDeliveryProviders?: Array<DeliveryProvider>;
    onCreateDeliveryProvider?: any;
};
