/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export async function changeOrderPreparationTimeApi(request: ChangeOrderPreparationTimeApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('order/changeOrderPreparationTimeApi', request);
}

export type ChangeOrderPreparationTimeApiRequest = {
    orderId: OrderId;
    preparationTime: string;
};
