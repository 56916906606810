/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import { memo, useContext, useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { PosContext } from 'src/scenes/letseatmanager/pos/PosProvider';
import { useClearPos } from 'src/services/pos/useClearPos';
import { usePrintOrderCommand } from 'src/services/printer/usePrintOrderCommand';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import { OrderVm } from 'src/types/OrderVm';
import { isMexico } from 'src/utils/country/isMexico';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { findItemsChangedInOrder } from 'src/utils/pos/findItemsChangedInOrder';
import { classNames } from 'src/utils/react/classNames';
import { useCompletePosOrder } from 'src/utils/react/useCompletePosOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { canRestaurantInvoice } from 'src/utils/restaurant/canRestaurantInvoice';

export const PosPaymentSubmitOrderButton: React.ComponentType<any> = memo<any>(() => {
    const classes = useStyles();
    const clearPos = useClearPos();

    const { menuOpenedAt } = useContext(PosContext);

    const { completeOrder } = useCompletePosOrder();
    const [printOrderTicket] = usePrintOrderTicket();
    const [printOrderCommand] = usePrintOrderCommand();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const [loading, setLoading] = useState(false);

    const total = useSelector((state) => state.pos.payment?.total);
    const posPendingOrdersEnabled = useSelector((state) => state.pos.context?.posPendingOrdersEnabled);
    const acceptManuallyAllOrdersEnabled = useSelector((state) => state.app.restaurant?.acceptManuallyAllOrdersEnabled);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const orderType = useSelector((state) => state.pos.orderType);
    const payments = useSelector((state) => state.pos.payments);
    const disabledPosPaymentActions = useSelector((state) => state.pos.disabledPosPaymentActions);
    const totalPaid = useSelector((state) => state.pos.totalPaid);
    const paying = useSelector((state) => state.pos.paying);
    const items = useSelector((state) => state.pos.items);
    const cancelledItems = useSelector((state) => state.pos.cancelledItems);
    const restaurant = useSelector((state) => state.app.restaurant);

    const hasPaymentMissing = BigNumber(totalPaid).isLessThan(total);
    const disabled = disabledPosPaymentActions || paying;

    const getButtonText = () => {
        if (disabledPosPaymentActions && hasPaymentMissing) return translate('Saving Order');
        if (disabledPosPaymentActions && !hasPaymentMissing) return translate('Paying Order');
        if (paying) return translate('Paying');
        if (!acceptManuallyAllOrdersEnabled && posPendingOrdersEnabled && payments?.length === 0 && !pendingOrder && hasPaymentMissing && !isDeliveryOrder(orderType))
            return translate('Save Without Charging');
        if (!acceptManuallyAllOrdersEnabled && hasPaymentMissing && !pendingOrder && !isDeliveryOrder(orderType)) return translate('Save Order');
        return translate('Charge');
    };
    const handleCompleteOrder = async () => {
        if (disabledPosPaymentActions || loading) return;
        setLoading(true);
        const itemsToPrint = findItemsChangedInOrder(pendingOrder?.orderItems ?? ([] as any), items ?? []);
        const removedItemsToPrint = findItemsChangedInOrder(pendingOrder?.cancelledItems ?? ([] as any), cancelledItems ?? []);
        const shouldPrintPendingOrderCommand =
            pendingOrder && moment(pendingOrder?.createdAt).isSame(menuOpenedAt) && pendingOrder.payments?.some((payment) => isCardPayment(payment.paymentMethod) && !payment.customPaymentMethod);

        let order: OrderVm | void = pendingOrder;
        if (!isCompletedOrder(pendingOrder?.orderStatus)) {
            order = await completeOrder();
        }

        if (!order) {
            setLoading(false);
            return;
        }
        setLoading(false);
        clearPos();

        if (!acceptManuallyAllOrdersEnabled) {
            await printOrderTicket(order);

            if (itemsToPrint?.length || (removedItemsToPrint?.length && !shouldPrintPendingOrderCommand)) {
                await printOrderCommand({ ...order, orderItems: itemsToPrint, cancelledItems: removedItemsToPrint } as any);
            }
            if (shouldPrintPendingOrderCommand) await printOrderCommand(order);
        }
    };

    return (
        <Button
            classes={{
                button: classNames(classes.button, posInterfaceLargerEnabled ? classes.largerButton : '', canRestaurantInvoice(restaurant) && !isMexico(restaurant.country) && classes.secondaryButton),
            }}
            onClick={handleCompleteOrder}
            disabled={disabled}
        >
            {getButtonText()}
        </Button>
    );
});

const useStyles = makeStyles((theme) => ({
    button: {
        height: 50,
        width: '100%',
    },
    secondaryButton: (props) => ({
        border: `1px solid ${(props as any).color || theme.palette.border.primary}`,
        backgroundColor: 'transparent',
        color: `${(props as any).color || theme.palette.text.secondary}`,
        padding: '8px 12px',
        '&:hover': {
            border: `1px solid ${(props as any).color || theme.palette.border.tertiary}`,
            backgroundColor: theme.palette.surface.tertiary,
        },
        '&:focus': {
            outline: `2px solid ${theme.palette.surface.brandSecondary}`,
        },
    }),
    largerButton: {
        order: 0,
        height: 52,
        fontSize: 15,
    },
}));
