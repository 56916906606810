/**
 * @prettier
 */
import { Typography, useTheme } from '@material-ui/core';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { getDriverPositionApi } from 'src/api/letseatmanager/driverPosition/getDriverPositionApi';
import { googleMapsConfig } from 'src/config/googleMapsConfig';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { DriverId, OrderId } from 'src/types/Id';
import { toLatLng } from 'src/utils/googlemaps/toLatLng';

export function GoogleMapsTrackDriverPosition({ restaurantLocation, customerLocation, driverId, orderId, hidden }: Props): React.ReactElement | null {
    const [driverPosition, setDriverPosition] = useState<GeoJsonPoint>();
    const [positionedAt, setPositionedAt] = useState<Date>();

    const { isLoaded, loadError } = useLoadScript(googleMapsConfig);

    useEffect(() => {
        if (hidden) {
            return;
        }

        const interval = setInterval(async () => {
            const update = await getDriverPositionApi({ orderId: orderId });
            if (!update.ok) return;

            setDriverPosition(update.data.position);
            setPositionedAt(update.data.positionedAt);
        }, 2 * SECONDS);

        return () => clearInterval(interval);
    }, [hidden, orderId]);

    if (!isLoaded || hidden) {
        return null;
    }
    if (loadError) {
        return <Typography>{translate('Failed to load google maps, please refresh page.')}</Typography>;
    }

    return (
        <>
            <label id='googleMapsTrackDriverPositionLabel' style={{ visibility: 'hidden', display: 'none' }}>
                googleMapsDriverPosition
            </label>
            <div style={{ position: 'relative', height: 250 }} aria-labelledby='googleMapsTrackDriverPositionLabel'>
                {/*<div style={{position: 'absolute', zIndex: 999999, top: 0, fontSize: 10, color: '#888'}}>{formatDateTimeStringReadable(positionedAt)}</div>*/}
                <Map restaurantLocation={restaurantLocation} customerLocation={customerLocation} driverPosition={driverPosition} />
            </div>
        </>
    );
}

function Map({ restaurantLocation, customerLocation, driverPosition }: { restaurantLocation: GeoJsonPoint; customerLocation?: GeoJsonPoint; driverPosition?: GeoJsonPoint }) {
    const [map, setMap] = useState<google.maps.Map>();
    const theme = useTheme();

    const onLoad = useCallback((map: google.maps.Map) => {
        setMap(map);
    }, []);

    useEffect(() => {
        if (customerLocation && restaurantLocation && driverPosition && map) {
            setTimeout(() => {
                const bounds = new window.google.maps.LatLngBounds();

                bounds.extend(toLatLng(restaurantLocation));
                bounds.extend(toLatLng(customerLocation));
                bounds.extend(toLatLng(driverPosition));
                map.fitBounds(bounds);
            }, 100);
        }
    }, [customerLocation, restaurantLocation, map]);
    const onUnmount = useCallback((map: google.maps.Map) => {
        setMap(undefined);
    }, []);

    if (!restaurantLocation || !customerLocation || !driverPosition) {
        return null;
    }

    return (
        <GoogleMap
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                styles: mapStyles,
                gestureHandling: 'cooperative',
            }}
            mapContainerStyle={mapContainerStyle}
        >
            <Marker
                label={{
                    text: 's',
                    color: 'white',
                    fontFamily: 'LetsEatIcons',
                    fontSize: '26px',
                }}
                position={toLatLng(restaurantLocation)}
            />
            {customerLocation && (
                <Marker
                    label={{
                        text: "'",
                        color: 'white',
                        fontFamily: 'LetsEatIcons',
                        fontSize: '34px',
                    }}
                    position={toLatLng(customerLocation)}
                />
            )}
            {driverPosition && (
                <Marker
                    icon={{
                        path: window.google.maps.SymbolPath.CIRCLE,
                        scale: 8,
                        strokeWeight: 16,
                        strokeColor: theme.palette.primary.dark,
                    }}
                    label={{
                        text: '"',
                        color: 'white',
                        fontFamily: 'LetsEatIcons',
                        fontSize: '30px',
                    }}
                    position={toLatLng(driverPosition)}
                />
            )}
        </GoogleMap>
    );
}

const mapContainerStyle = {
    height: '250px',
} as const;

const mapStyles = [
    {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.business',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
];

type Props = {
    restaurantLocation: GeoJsonPoint;
    customerLocation?: GeoJsonPoint;
    driverId: DriverId;
    orderId: OrderId;
    hidden?: boolean;
};
