/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Input } from '@pidedirecto/ui';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { usePosCustomerActions, usePosCustomerStore } from 'src/modules/pos/customer/posCustomerStore';

export function PosCustomerNoteInput(): React.ReactElement | null {
    const classes = useStyles();

    const customerNote = usePosCustomerStore((state) => state.customerNote.value);

    const setCustomerNote = usePosCustomerActions((actions) => actions.setCustomerNote);

    const onChangeCustomerNote = (value: string) => setCustomerNote(value);

    return <Input classes={{ container: classes.input }} name='customerNote' label={translate('Customer notes')} value={customerNote} onChange={onChangeCustomerNote} />;
}

const useStyles = makeStyles((theme) => ({
    input: {
        width: '100%',
    },
}));
