/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { BellIcon } from 'src/icons/BellIcon';
import { CircleCloseIcon } from 'src/icons/CircleCloseIcon';
import { classNames } from 'src/utils/react/classNames';

/**
 * @callback onActionClick
 */

/**
 * @callback onClose
 */

/**
 *
 * @param {React.Node} [icon] - icon to be shown in the upper left corner
 * @param {React.Node} [children] - element to be shown in the alert, if this is passed, the text, title and actionText will be ignored
 * @param {string} [text] - message of the alert
 * @param {string} [actionText] - shows button on the right side of the alert
 * @param {onActionClick} [onActionClick] - function to be called when the user clicks on the action button
 * @param {string} [title] - title of the alert
 * @param {('error'|'warning'|'success)} [type='success'] - type of the alert
 * @param {onClose} [onClose] - function to be called when the close icon on the alert is clicked, if you don't pass this parameter the icon won't be rendered
 * @returns {React.Node}
 */
export function Alert({ icon, children, text, actionText, onActionClick, title, type, onClose }: Props): React.ReactElement {
    const classes = useStyles();

    const alertType = type || 'success';

    const textClassName = {
        error: classes.textError,
        warning: classes.textWarning,
        success: '',
    } as const;

    const containerClassName = {
        error: classes.containerError,
        warning: classes.containerWarning,
        success: '',
    } as const;

    return (
        <div className={classNames(classes.container, containerClassName[alertType])} role='alert'>
            {!!children && <div className={classes.contentContainer}>{children}</div>}
            {!children && (
                <>
                    <div className={classes.contentContainer}>
                        <div className={classes.titleContainer}>
                            <div className={textClassName[alertType] || classes.textSuccess}>{icon || <BellIcon />}</div>
                            {title && <h4 className={classNames(classes.title, textClassName[alertType])}>{title}</h4>}
                        </div>
                        <p className={classNames(classes.text, textClassName[alertType])}>{text}</p>
                    </div>
                    {!!actionText && (
                        <button className={classNames(classes.actionButton, textClassName[alertType])} onClick={onActionClick}>
                            {actionText}
                        </button>
                    )}
                </>
            )}
            {!!onClose && (
                <button onClick={onClose} className={classNames(classes.closeButton, textClassName[alertType])}>
                    <CircleCloseIcon />
                </button>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        minHeight: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#EAF7FD',
        padding: '12px 18px',
        borderRadius: 8,
        boxSizing: 'border-box',
        border: '1px solid #B4CFE8',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    contentContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        color: '#154A61',
        margin: 0,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#154A61',
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    actionButton: {
        backgroundColor: 'transparent',
        cursor: 'pointer',
        border: 0,
        padding: 0,
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        color: '#154A61',
        margin: '0 30px',
        flexShrink: 0,
    },
    closeButton: {
        backgroundColor: 'transparent',
        cursor: 'pointer',
        border: 0,
        borderRadius: 24,
        width: 24,
        height: 24,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textError: {
        color: '#61190b',
    },
    textWarning: {
        color: '#663C00',
    },
    textSuccess: {
        color: '#154A61',
    },
    containerError: {
        backgroundColor: '#FDECEA',
        border: '1px solid #CDBCBA',
    },
    containerWarning: {
        backgroundColor: '#FFF4E5',
        border: '1px solid #CFC4B5',
    },
}));

type Props = {
    type?: 'error' | 'success' | 'warning';
    title?: string;
    text?: string;
    actionText?: string;
    icon?: React.ReactNode;
    children?: React.ReactNode;
    onActionClick?: any;
    onClose?: any;
};
