/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { DropDown } from 'src/components/DropDown';
import { DropDownItem } from 'src/components/DropDownItem';
import { translate } from 'src/i18n/translate';
import { useMenuCategories } from 'src/services/menuCategory/useMenuCategories';
import { useModifierGroups } from 'src/services/modifierGroup/useModifierGroups';
import type { MenuItemId } from 'src/types/Id';

export function ModifierGroupsInheritedMenuItemDropDown({ menuItemId }: Props): React.ReactElement | null {
    const classes = useStyles();

    const { menuCategories } = useMenuCategories();
    const { modifierGroups } = useModifierGroups();

    const menuCategoriesUsingMenuItem = menuCategories.filter((menuCategory) => menuCategory.menuItemIds.includes(menuItemId));
    const menuCategoriesWithModifierGroups = menuCategoriesUsingMenuItem.filter((menuCategory) => !!menuCategory.modifierGroupIds?.length);

    if (!menuCategoriesWithModifierGroups.length) return null;

    return (
        <DropDown label={translate('Modifier groups')} labelAsPlaceholder classes={{ button: classes.button, dropdown: classes.dropdown }}>
            {menuCategoriesWithModifierGroups.map((menuCategory) => {
                const modifierGroupsInherited = modifierGroups.filter((modifierGroup) => menuCategory.modifierGroupIds?.includes(modifierGroup.modifierGroupId));
                return (
                    <DropDownItem key={menuCategory.menuCategoryId} value={menuCategory.menuCategoryId} className={classes.dropdownItem}>
                        <div className={classes.infoContainer}>
                            <span className={classes.categoryName}>{menuCategory.name}</span>
                            <ul className={classes.list}>
                                {modifierGroupsInherited.map((modifierGroup) => (
                                    <li key={modifierGroup.modifierGroupId}>{modifierGroup.name}</li>
                                ))}
                            </ul>
                        </div>
                    </DropDownItem>
                );
            })}
        </DropDown>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        color: 'white',
        backgroundColor: theme.palette.surface.brandContrast,
        height: 24,
        minHeight: 20,
        padding: '2px 30px 2px 8px',
        borderRadius: 8,
        border: 0,
        fontSize: 12,
        '&:hover': {
            backgroundColor: `${theme.palette.surface.brandContrast}a1`,
            color: 'white',
            border: 0,
        },
    },
    dropdown: {
        backgroundColor: theme.palette.surface.primary,
        color: theme.palette.text.primary,
    },
    dropdownItem: {
        color: theme.palette.text.primary,
        fontSize: 14,
        marginLeft: 10,
        padding: '8px 8px 8px 18px',
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    list: {
        listStyle: 'none',
        paddingLeft: 10,
        marginTop: 5,
        marginBottom: 10,
    },
    categoryName: {
        fontFamily: theme.typography.medium,
    },
}));

type Props = {
    menuItemId: MenuItemId;
};
