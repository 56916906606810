/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { getCurrentRestaurantEmployeeDayShiftApi } from 'src/api/letseatmanager/employeeDayShift/getCurrentRestaurantEmployeeDayShiftApi';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { UserFilledIcon } from 'src/icons/UserFilledIcon';
import { ClockOutActions } from 'src/scenes/letseatmanager/pos/employeeDayShift/ClockOutActions';
import { PinCodeUserDaySummary } from 'src/scenes/letseatmanager/pos/employeeDayShift/PinCodeUserDaySummary';
import { PinCodeUserTimeLine } from 'src/scenes/letseatmanager/pos/employeeDayShift/PinCodeUserTimeLine';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function PinCodeUserResumeDayPage(): React.ReactElement | null {
    const classes = useStyles();

    const history = useHistory();

    const pinCodeUserManagerUserId = useSelector((state) => state.authentication.pinCodeUserSignedIn?.managerUserId);
    const restaurantId = useSelector((state) => state.app.restaurant?.restaurantId);

    const [loading, employeeDayShift] = useLoadApi(
        getCurrentRestaurantEmployeeDayShiftApi,
        {
            managerUserId: pinCodeUserManagerUserId,
            restaurantId,
        },
        { requiredValues: [pinCodeUserManagerUserId] }
    );

    const goBack = () => {
        history.push({
            pathname: RoutePaths.POS,
            search: history.location.search,
        });
    };
    if (loading) return <Loader size={40} loading={true} />;

    if (!employeeDayShift) return null;

    return (
        <SubscribedPage title={translate('Resume day')}>
            <div className={classes.container}>
                <GoBackButton goBack={goBack} label={translate('Return')} classes={{ button: classes.returnButton }} />
                <h1 className={classes.title}>{translate('Resume day')}</h1>
                <div className={classes.name}>
                    <UserFilledIcon height={24} width={24} />
                    <div className={classes.posUserName}>
                        <div>{employeeDayShift?.username}</div>
                        <div className={classes.caption}>{employeeDayShift?.roleName}</div>
                    </div>
                </div>
                <section className={classes.resume}>
                    <section className={classes.generalSection}>
                        <PinCodeUserDaySummary employeeDayShift={employeeDayShift} />
                        <ClockOutActions employeeDayShift={employeeDayShift} />
                    </section>
                    <section className={classes.timeLineSection}>
                        <PinCodeUserTimeLine employeeDayShift={employeeDayShift} />
                    </section>
                </section>
            </div>
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '40px 10px',
        fontFamily: theme.typography.regular,
        fontSize: 16,
        color: theme.palette.text.secondary,
        [theme.breakpoints.up('sm')]: {
            padding: '40px 20px',
        },
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 24,
        color: theme.palette.text.primary,
        margin: '32px 0',
    },
    caption: {
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    posUserName: {
        fontFamily: theme.typography.semiBold,
        fontSize: 18,
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        gap: 10,
    },
    returnButton: {
        top: 100,
        paddingLeft: 0,
        left: 20,
        [theme.breakpoints.up('sm')]: {
            left: 80,
        },
    },
    name: {
        display: 'flex',
        gap: 20,
    },
    resume: {
        marginTop: 40,
        display: 'flex',
        flexDirection: 'column-reverse',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    generalSection: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '65%',
        },
    },
    timeLineSection: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '35%',
        },
    },
}));
