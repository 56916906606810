/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { App } from 'src/constants/App';
import { translate } from 'src/i18n/translate';
import { AddAppUsedAtThePosBusinessDayReportDialog } from 'src/scenes/letseatmanager/theRestaurant/pos/AddAppUsedAtThePosBusinessDayReportDialog';
import { RemoveAppUsedAtThePosBusinessDayReportDialog } from 'src/scenes/letseatmanager/theRestaurant/pos/RemoveAppUsedAtThePosBusinessDayReportDialog';

export function AppsUsedAtThePosBusinessDayReportSettings({ onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    const { control } = useFormContext();

    const [addAppsContemplatedAtTheEndOfTheDayReportDialogState, setAddAppsContemplatedAtTheEndOfTheDayReportDialogState] = useState({ open: false });
    const [removeAppsContemplatedAtTheEndOfTheDayReportDialogState, setRemoveAppsContemplatedAtTheEndOfTheDayReportDialogState] = useState<{ open: boolean; app: App | undefined }>({
        open: false,
        app: undefined,
    });

    const appsContemplatedAtTheEndOfTheDayReports = useWatch<Array<App>>({ name: 'appsContemplatedAtTheEndOfTheDayReports', control });

    const openAddAppsContemplatedAtTheEndOfTheDayReportDialog = () => setAddAppsContemplatedAtTheEndOfTheDayReportDialogState({ open: true });

    const closeAddAppsContemplatedAtTheEndOfTheDayReportDialog = () => setAddAppsContemplatedAtTheEndOfTheDayReportDialogState({ open: false });

    const openRemoveAppsContemplatedAtTheEndOfTheDayReportDialog = (app: App) => setRemoveAppsContemplatedAtTheEndOfTheDayReportDialogState({ open: true, app });

    const closeRemoveAppsContemplatedAtTheEndOfTheDayReportDialog = () => setRemoveAppsContemplatedAtTheEndOfTheDayReportDialogState({ open: false, app: undefined });

    return (
        <>
            <AddAppUsedAtThePosBusinessDayReportDialog
                open={addAppsContemplatedAtTheEndOfTheDayReportDialogState.open}
                onClose={closeAddAppsContemplatedAtTheEndOfTheDayReportDialog}
                onSuccess={onSuccess}
            />
            <RemoveAppUsedAtThePosBusinessDayReportDialog
                open={removeAppsContemplatedAtTheEndOfTheDayReportDialogState.open}
                app={removeAppsContemplatedAtTheEndOfTheDayReportDialogState?.app}
                onSuccess={onSuccess}
                onClose={closeRemoveAppsContemplatedAtTheEndOfTheDayReportDialog}
            />
            <div className={classes.section}>
                <h2 className={classes.subtitle}>{translate('Apps contemplated at the end of the day')}</h2>
                <div className={classes.itemsContainer}>
                    {appsContemplatedAtTheEndOfTheDayReports?.map((appsContemplatedAtTheEndOfTheDayReport: any, idx: any) => (
                        <div className={classes.cashRegisterCustomReasonItem} key={idx}>
                            {translate(`Apps.${appsContemplatedAtTheEndOfTheDayReport}`)}
                            <Button icon onClick={() => openRemoveAppsContemplatedAtTheEndOfTheDayReportDialog(appsContemplatedAtTheEndOfTheDayReport)} classes={{ button: classes.iconButton }}>
                                <CloseIcon />
                            </Button>
                        </div>
                    ))}
                </div>
                <Button onClick={openAddAppsContemplatedAtTheEndOfTheDayReportDialog} outlined>
                    <AddIcon />
                    {translate('Add app')}
                </Button>
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
    },
    section: {
        width: '100%',
        borderRadius: 5,
        border: `1px solid ${theme.palette.secondary.dark}`,
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        gap: 20,
    },
    itemsContainer: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    cashRegisterCustomReasonItem: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        minWidth: 140,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #676060B2',
        borderRadius: 6,
        padding: '5px 10px',
        gap: 5,
        height: '100%',
    },
    iconButton: {
        width: 28,
        height: 28,
        borderRadius: 28,
        minHeight: 28,
    },
}));

type Props = {
    onSuccess: any;
};
