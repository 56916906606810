/**
 * @prettier
 */
import type { CourseOrderDetailsVm } from 'src/types/CourseOrderDetailsVm';
import type { PosCourseOrderDetails } from 'src/types/PosCourseOrderDetails';
import type { MenuItemVm } from 'src/types/PosMenuVm';
import type { PosPayment } from 'src/types/PosPayment';
import { mapOrderItemsToCartItems } from 'src/utils/pos/mapOrderItemsToCartItems';

export function mapOrderCoursesToPosCourses(orderCourses: Array<CourseOrderDetailsVm>, menuItems: Array<MenuItemVm>, posPayments: Array<PosPayment>): Array<PosCourseOrderDetails> {
    return orderCourses?.map((course: CourseOrderDetailsVm, idx: number) => {
        return {
            courseId: course.courseId,
            name: course.name,
            orderItems: mapOrderItemsToCartItems({ orderItems: course.orderItems ?? [], menuItems: menuItems }),
        };
    });
}
