/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SubscriptionId } from 'src/types/Id';

export async function updateSubscriptionAfterPaymentApi(request: UpdateSpeiInternalSubscriptionAfterPaymentApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('subscription/updateSubscriptionAfterPaymentApi', request);
}

export type UpdateSpeiInternalSubscriptionAfterPaymentApiRequest = {
    subscriptionId: SubscriptionId;
};
