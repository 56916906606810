/**
 * @prettier
 */
import { CashRegisterExpectedAmountVm } from 'src/api/letseatmanager/pos/getCashRegisterExpectedAmountsApi';
import { useExpectedAmountsStore } from 'src/services/posBusinessDay/businessReportExpectedAmountsStore';

export function useExpectedAmounts(): Array<CashRegisterExpectedAmountVm> {
    const expectedAmounts = useExpectedAmountsStore((actions) => actions.expectedAmounts);

    return expectedAmounts;
}
