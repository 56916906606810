/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@pidedirecto/ui';
import { CalendarIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { isPlannedPickupTime } from 'src/utils/pickupTimeType/isPlannedPickupTime';
import { useSelector } from 'src/utils/react/useSelector';

export function PosCartPickupTime(): React.ReactElement | null {
    const classes = useStyles();

    const pickupTimeType = useSelector((state) => state.pos.pickupTimeType);
    const pickupTime = useSelector((state) => state.pos.pickupTime);

    if (!isPlannedPickupTime(pickupTimeType)) return null;

    return (
        <Chip classes={{ chip: classes.chip }}>
            <CalendarIcon size={14} />
            {translate(formatDateTimeString(pickupTime))}
        </Chip>
    );
}

const useStyles = makeStyles((theme) => ({
    chip: {
        backgroundColor: theme.palette.surface.secondary,
        color: theme.palette.text.secondary,
        gap: 6,
        fontSize: 12,
        height: 30,
        borderRadius: 30,
        '& svg': {
            color: theme.palette.text.brand,
        },
    },
}));
