/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CashRegisterTransactionVm } from 'src/types/CashRegisterTransactionVm';
import type { CashRegisterId } from 'src/types/Id';

export async function findCashRegisterTransactionsApi(request: FindCashRegisterTransactionsApiRequest): ApiSauceResponse<Array<CashRegisterTransactionVm>> {
    return letseatmanagerApiMethod('pos/findCashRegisterTransactionsApi', request);
}

export type FindCashRegisterTransactionsApiRequest = {
    cashRegisterId: CashRegisterId;
};
