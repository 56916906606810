/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { SectionTitle } from 'src/components/title/SectionTitle';
import { TicketSections } from 'src/constants/TicketSection';
import { translate } from 'src/i18n/translate';

export function TicketFooterSettings({ disabled }: Props): React.ReactElement {
    const { control } = useFormContext();

    const customFooterText = useWatch({ name: TicketSections.CUSTOM_FOOTER_TEXT, control });

    return (
        <>
            <SectionTitle title={translate('Footer')} />
            {/* @ts-ignore */}
            <Grid item xs={12} columnSpacing={2}>
                <FormSwitch disabled={disabled} name={TicketSections.CUSTOM_FOOTER_TEXT} label={translate(`TicketSections.${TicketSections.CUSTOM_FOOTER_TEXT}`)} />
            </Grid>
            {customFooterText && (
                <Grid item xs={12}>
                    <FormTextField disabled={disabled} label={translate('Custom text')} name={'ticketCustomFooter'} placeholder={translate('Custom text')} required={!!customFooterText} />
                </Grid>
            )}
        </>
    );
}

type Props = {
    disabled: boolean;
};
