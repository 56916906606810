/**
 * @prettier
 */
import * as React from 'react';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Tabs } from 'src/components/Tabs';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { GlobalOrdersInvoices } from 'src/scenes/letseatmanager/invoice/GlobalOrdersInvoices';
import { GlobalOrdersInvoicesHistory } from 'src/scenes/letseatmanager/invoice/GlobalOrdersInvoicesHistory';

export function GlobalOrderInvoicesPage(): React.ReactElement {
    const tabs = [
        {
            label: translate('Global Order Invoice'),
            value: '1',
        },
        {
            label: translate('Record'),
            value: '2',
        },
    ];

    return (
        <SecuredAndSubscribedPage title={translate('Internal Invoice')} rolePermission={RolePermissions.ORDER_INVOICES_PAGE}>
            <Tabs tabs={tabs}>
                <GlobalOrdersInvoices />
                <GlobalOrdersInvoicesHistory />
            </Tabs>
        </SecuredAndSubscribedPage>
    );
}
