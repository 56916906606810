/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useEffect, useState } from 'react';
import * as React from 'react';
import type { SalesByPromoCode } from 'src/api/letseatmanager/restaurantDashboard/getPromoCodeRestaurantStatisticsApi';
import { PieChartCanvas } from 'src/components/charts/PieChartCanvas';
import { translate } from 'src/i18n/translate';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function PromoCodeMostUsedGraph({ colors, loading, salesByPromoCodeData }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [salesByPromoCode, setSalesByPromoCode] = useState<Array<PromoCodeBarGraphType>>([]);

    const hasPromoCodeUsed = salesByPromoCode.length > 0;

    useEffect(() => {
        getSaleByPromoCodeFromReports(salesByPromoCodeData);
    }, [salesByPromoCodeData]);

    const getSaleByPromoCodeFromReports = (promoCodesData: Array<SalesByPromoCode>) => {
        if (!promoCodesData) {
            setSalesByPromoCode([]);
            return;
        }
        const promoCodeItemSales = getPromoCodeReportData(promoCodesData);
        const salesByPromoCodeSorted = promoCodeItemSales.sort((itemA, itemB) => itemB.total - itemA.total);
        setSalesByPromoCode(salesByPromoCodeSorted);
    };

    const getPromoCodeReportData = (promoCodesData: Array<SalesByPromoCode>): Array<BarGraphData> => {
        const promoCodeItemSales: Array<BarGraphData> = [];
        promoCodesData
            .map((promoCodeItem) => {
                return {
                    name: promoCodeItem.promoCode,
                    count: promoCodeItem.orderVolume,
                    promoCodeDiscount: promoCodeItem.promoCodeDiscount,
                    total: promoCodeItem.total,
                };
            })
            .forEach((promoCode) => {
                const isPromoCodeItem = promoCodeItemSales?.find((elem: BarGraphData) => elem.name === promoCode.name);
                if (!isPromoCodeItem) {
                    promoCodeItemSales.push(promoCode);
                    return;
                }
                isPromoCodeItem.count = isPromoCodeItem.count + promoCode.count;
                isPromoCodeItem.promoCodeDiscount = isPromoCodeItem.promoCodeDiscount + promoCode.promoCodeDiscount;
                isPromoCodeItem.total = isPromoCodeItem.total + promoCode.total;
            });

        return promoCodeItemSales;
    };

    if (!hasPromoCodeUsed) return null;

    return (
        <ReportSection title={translate('Promo code most used')} loading={loading}>
            <Grid container className={(classes as any).infoContainer}>
                <Grid item md={9} className={classes.graphContainer}>
                    <PieChartCanvas
                        data={salesByPromoCode.map((promoCode) => ({
                            id: promoCode.name,
                            label: promoCode.name,
                            value: promoCode.count,
                        }))}
                    />
                </Grid>
                <Grid item md={3} className={classes.kpisContainer}>
                    <KpiCard color={colors[0]} legend={salesByPromoCode[0]?.name} title={translate('Promo Code most used')}></KpiCard>
                    <KpiCard color={colors[1]} legend={formatAsCurrencyNumber(salesByPromoCode[0]?.promoCodeDiscount)} title={translate('Total discount')}></KpiCard>
                    <KpiCard color={colors[2]} legend={formatAsCurrencyNumber(salesByPromoCode[0]?.total)} title={translate('Total income')}></KpiCard>
                </Grid>
            </Grid>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    graphContainer: {
        width: '100%',
        height: '45vh',
    },
    kpisContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '95%',
        height: '30%',
        justifyContent: 'space-around',
        gap: 12,
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100%',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            flexWrap: 'nowrap',
        },
    },
}));

type Props = {
    colors: Array<string>;
    loading: boolean;
    salesByPromoCodeData: Array<SalesByPromoCode>;
};

type PromoCodeBarGraphType = {
    name: string;
    count: number;
    promoCodeDiscount: number;
    total: number;
};

type BarGraphData = {
    name: string;
    count: number;
    promoCodeDiscount: number;
    total: number;
};
