/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { AssignWaiterDialog } from 'src/components/orderCard/AssignWaiterDialog';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { OrderVm } from 'src/types/OrderVm';
import { useAction } from 'src/utils/react/useAction';
import { useEditPendingOrder } from 'src/utils/react/useEditPendingOrder';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';
import { useUserIsAnAvailableWaiter } from 'src/utils/react/useUserIsAnAvailableWaiter';

export function EatHereOngoingOrderCard({ order }: Props): React.ReactElement {
    const classes = useStyles();
    const [userHasRolePermission] = useUserHasRolePermission();
    const [userIsAnAvailableWaiter] = useUserIsAnAvailableWaiter();
    const editPendingOrder = useEditPendingOrder();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const history = useHistory();

    const [assignWaiterDialogState, setAssignWaiterDialogState] = useState({ open: false });

    const openVerifyPosPasswordDialog = useAction(app2.actions.openVerifyPosPasswordDialog);
    const openVerifyPosPinCodeUserDialog = useAction(app2.actions.openVerifyPosPinCodeUserDialog);

    const closeAssignWaiterDialog = () => setAssignWaiterDialogState({ open: false });

    const handleEditPendingOrder = () => {
        editPendingOrder({ order });
        history.push({
            pathname: RoutePaths.POS,
            search: history.location.search,
        });
    };

    const handleAssignWaiter = async () => {
        if (!userHasRolePermission(RolePermissions.ASSIGN_ORDERS)) {
            return openVerifyPosPasswordDialog({
                onAuthorized: () => {
                    handleWaiterVerification();
                },
                onUnauthorized: () => {
                    alert(translate('Unauthorized to access.'));
                },
            });
        }
        handleWaiterVerification();
    };

    const handleWaiterVerification = async () => {
        if (userIsAnAvailableWaiter(order.servedBy)) {
            return openVerifyPosPinCodeUserDialog({
                managerUserId: order.servedBy,
                onAuthorized: () => {
                    setAssignWaiterDialogState({ open: true });
                },
                onUnauthorized: () => {
                    alert(translate('Unauthorized to access.'));
                },
            });
        }
        setAssignWaiterDialogState({ open: true });
    };

    return (
        <div className={classes.container}>
            <AssignWaiterDialog open={assignWaiterDialogState.open} orderId={order?.orderId ?? 'cf25a9dc-b7f9-4108-a360-e70bfa8fe1f4'} handleClose={closeAssignWaiterDialog} />
            <div>
                <span>{`${translate('Table')} ${order.table ?? ''}`}</span>
                <span>{` - ${formatAsCurrencyNumber(order.total)}`}</span>
            </div>
            <div className={classes.buttons}>
                <Button onClick={handleEditPendingOrder}>{translate('Edit')}</Button>
                <Button onClick={handleAssignWaiter} secondary classes={{ button: classes.secondaryButton }}>
                    {translate('Transfer')}
                </Button>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#232933',
        fontSize: 12,
        margin: '8px 0',
        borderRadius: 8,
        backgroundColor: '#E2E7EB',
        padding: '16px 12px',
    },
    buttons: {
        display: 'flex',
        gap: 10,
    },
    secondaryButton: {
        border: '1px solid transparent',
        '&:hover': {
            border: '1px solid transparent',
        },
    },
}));

type Props = {
    order: OrderVm;
};
