/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantPaymentMethodId } from 'src/types/Id';

export async function removeRestaurantPaymentMethodApi(request: RemoveRestaurantPaymentMethodApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurantPaymentMethod/removeRestaurantPaymentMethodApi', request);
}

export type RemoveRestaurantPaymentMethodApiRequest = { restaurantPaymentMethodId: RestaurantPaymentMethodId };
