/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { DemoRestaurantVm } from 'src/types/DemoRestaurantVm';

export async function findDemoRestaurantsApi(): ApiSauceResponse<Array<DemoRestaurantVm>> {
    return letseatadminApiMethod('demoRestaurants/findDemoRestaurantsApi');
}
