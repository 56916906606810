/**
 * @prettier
 */
import type { SubscriptionVm } from 'src/api/letseatadmin/subscription/getSubscriptionApi';
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SubscriptionPaymentMethod } from 'src/constants/SubscriptionPaymentMethod';
import type { SubscriptionPlan } from 'src/constants/SubscriptionPlan';
import type { SubscriptionStatus } from 'src/constants/SubscriptionStatus';
import type { BrandId, RestaurantId, SubscriptionId } from 'src/types/Id';

export async function changeSubscriptionApi(request: ChangeSubscriptionApiRequest): ApiSauceResponse<SubscriptionVm> {
    return letseatadminApiMethod('subscription/changeSubscriptionApi', request);
}

export type ChangeSubscriptionApiRequest = {
    subscriptionId: SubscriptionId;
    restaurantId: RestaurantId;
    restaurantIds: Array<RestaurantId>;
    brandId?: BrandId;
    managerMessage?: string;
    comment?: string;
    invoiceDescription?: string;
    subscriptionPaymentMethod: SubscriptionPaymentMethod;
    status: SubscriptionStatus;
    plan: SubscriptionPlan;
    manualOrders: boolean;
    webOrders: boolean;
    payPerUseRange1PaymentAmount?: string;
    payPerUseRange2PaymentAmount?: string;
    payPerUseRangeMaxPaymentAmount?: string;
    financeReferenceEnabled?: boolean;
    paymentAmount: string;
    previousPaymentAmount?: string;
    previousPaymentDate?: Date;
    previousPaymentPaidAt?: Date;
    nextPaymentAmount?: string;
    nextPaymentDate?: Date;
    cutServiceDate?: Date;
    startServiceDate?: Date;
    endServiceDate?: Date;
};
