/**
 * @prettier
 */
import * as React from 'react';
import { Page } from 'src/components/Page';
import { FreeSubscriptionPlanEndPage } from 'src/scenes/letseatmanager/FreeSubscriptionPlanEndPage';
import { SubscriptionPage } from 'src/scenes/letseatmanager/SubscriptionPage';
import { useSelector } from 'src/utils/react/useSelector';
import { isFreeTrialSubscription } from 'src/utils/subscriptionPlan/isFreeTrialSubscription';

export function SubscribedPage({ title, children, className }: Props): React.ReactElement {
    const subscriptionPlan = useSelector((state) => state.app2.subscriptionPlan);
    const remainingDays = useSelector((state) => state.app2.remainingDays);
    const subscriptionAccess = useSelector((state) => state.app2.subscriptionAccess);
    const subscriptionStatus = useSelector((state) => state.app2.subscriptionStatus);

    // const disabled = (subscriptionAccess && remainingDays === 0) || subscriptionStatus === SubscriptionStatuses.CANCELLED;
    const disabled = subscriptionAccess && remainingDays === 0;

    if (disabled) {
        if (isFreeTrialSubscription(subscriptionPlan)) return <FreeSubscriptionPlanEndPage />;
        else return <SubscriptionPage />;
    }

    return (
        <Page title={title} className={className}>
            {children}
        </Page>
    );
}

type Props = {
    title: string;
    children: React.ReactNode;
    className?: string;
};
