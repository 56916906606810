/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { PieChartCanvas } from 'src/components/charts/PieChartCanvas';
import { PaymentMethod } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { GraphEmptyState } from 'src/scenes/letseatmanager/posReports/GraphEmptyState';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { CashRegisterExpectedAmountReport, CashRegisterReportVm } from 'src/types/CashRegisterReportVm';
import type { CustomPaymentMethod } from 'src/types/Id';
import { sum } from 'src/utils/reduce/sum';
import { translatePaymentMethod } from 'src/utils/translate/translatePaymentMethod';

export function CashRegisterExpectedAmountsReport({ cashRegisterReport }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const colors = [
        '#2476C2',
        '#20B2AA',
        '#F1948A',
        '#FFD700',
        '#53C7D7',
        '#7B68EE',
        '#FF8C00',
        '#40E0D0',
        '#FFA07A',
        '#9370DB',
        '#1E90FF',
        '#FFB6C1',
        '#0DFFD3',
        '#A0D8F8',
        '#A7A0F8',
        '#6256E9',
        '#00FF7F',
        '#8A2BE2',
        '#5DE3BC',
        '#24A8C2',
        '#13647D',
    ];

    const getExpectedAmountsReport = () => {
        const expectedAmountsReport: Array<ExpectedAmountReport> = cashRegisterReport.cashRegisterReportByCashRegister.reduce<Array<any>>(groupUpExpectedAmountsByPaymentMethod, []);
        return expectedAmountsReport?.sort((a, b) => Number(b.amount) - Number(a.amount));
    };

    const getExpectedAmountsReportPieChartData = () => {
        const expectedAmountsReport: Array<ExpectedAmountReport> = getExpectedAmountsReport();

        return expectedAmountsReport.map((expectedAmountReport) => ({
            id: getExpectedAmountReportPieChartLabel(expectedAmountReport),
            value: BigNumber(expectedAmountReport.amount).toNumber(),
            label: getExpectedAmountReportPieChartLabel(expectedAmountReport),
        }));
    };

    const groupUpExpectedAmountsByPaymentMethod = (expectedAmountsReport: Array<ExpectedAmountReport>, cashRegisterExpectedAmountReport: CashRegisterExpectedAmountReport) => {
        const expectedAmountReport = expectedAmountsReport.find((expectedAmountReport) => expectedAmountReport.paymentMethod === cashRegisterExpectedAmountReport.paymentMethod);
        if (expectedAmountReport) {
            expectedAmountReport.amount = BigNumber(expectedAmountReport.amount).plus(cashRegisterExpectedAmountReport.amount).toString();
            expectedAmountReport.expectedAmount = BigNumber(expectedAmountReport.expectedAmount).plus(cashRegisterExpectedAmountReport.expectedAmount).toString();
            return expectedAmountsReport;
        }

        expectedAmountsReport.push({
            paymentMethod: cashRegisterExpectedAmountReport.paymentMethod,
            amount: BigNumber(cashRegisterExpectedAmountReport.amount).toString(),
            expectedAmount: BigNumber(cashRegisterExpectedAmountReport.expectedAmount).toString(),
        });
        return expectedAmountsReport;
    };

    const getExpectedAmountReportPieChartLabel = (expectedAmountReport: ExpectedAmountReport) => {
        let label = translatePaymentMethod({ paymentMethod: expectedAmountReport.paymentMethod as PaymentMethod }) + '\n';
        label += translate('Expected @expectedAmount', { expectedAmount: formatAsCurrencyNumber(expectedAmountReport.expectedAmount) }) + '\n';
        label += translate('Received @amount', { amount: formatAsCurrencyNumber(expectedAmountReport.amount) });
        return label;
    };

    const isEmptyReport = () => {
        const cashRegisterReports = cashRegisterReport.cashRegisterReportByCashRegister.map((cashRegisterReport) => cashRegisterReport.amount);
        const totalCashRegisterReport = cashRegisterReports.reduce(sum, BigNumber(0));
        return totalCashRegisterReport.isZero();
    };

    if (isEmptyReport()) {
        return <GraphEmptyState title={translate('Amounts received by payment method')} />;
    }

    return (
        <article>
            <h2 className={classes.title}>{translate('Amounts received by payment method')}</h2>
            <div style={{ display: 'flex', flexDirection: getExpectedAmountsReportPieChartData().length < 5 ? 'row' : 'column' }}>
                <div className={classes.chartContainer}>
                    <PieChartCanvas
                        data={getExpectedAmountsReportPieChartData()}
                        tooltip={(e: any) => {
                            const { color, label } = e.datum;
                            const labelRows = label.split(/\n/g);
                            const title = labelRows[0];
                            const infoRows = labelRows.slice(1);
                            return (
                                <div className={classes.tooltip}>
                                    <div className={classes.tooltipTitleContainer}>
                                        <span className={classes.tooltipColor} style={{ backgroundColor: color }}></span>
                                        <span className={classes.tooltipTitle}>{title}</span>
                                    </div>
                                    {infoRows.map((info: any) => (
                                        <span className={classes.tooltipText} key={info}>
                                            {info}
                                        </span>
                                    ))}
                                </div>
                            );
                        }}
                        sortByValue
                        padAngle={1}
                    />
                </div>
                <div>
                    <h3 className={classes.subtitle}>{translate('Total received')}</h3>
                    <div className={classes.kpisContainer}>
                        {getExpectedAmountsReport().map((expectedAmountReport, idx) => (
                            <KpiCard
                                key={idx}
                                color={colors[idx]}
                                legend={formatAsCurrencyNumber(expectedAmountReport.amount)}
                                title={translatePaymentMethod({ paymentMethod: expectedAmountReport.paymentMethod as PaymentMethod })}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
    },
    chartContainer: {
        height: '60vh',
        width: '100%',
    },
    tooltip: {
        padding: 10,
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    tooltipColor: {
        width: 10,
        height: 10,
    },
    tooltipTitle: {
        fontFamily: theme.typography.bold,
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
    kpisContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 140px))',
        gap: 12,
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        marginTop: 12,
        marginBottom: 10,
        fontWeight: 500,
        color: theme.palette.text.secondary,
    },
}));

type Props = {
    cashRegisterReport: CashRegisterReportVm;
};

type ExpectedAmountReport = {
    paymentMethod: PaymentMethod | CustomPaymentMethod;
    amount: string;
    expectedAmount: string;
};
