/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import {
    getMenuRestaurantStatisticsApi,
    SalesByMenuCategory,
    SalesByMenuCategoryFamily,
    SalesByMenuItem,
    SalesByModifier,
} from 'src/api/letseatmanager/restaurantDashboard/getMenuRestaurantStatisticsApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { FilterMasterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterMasterReports';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { SalesByMenuCategoryFamilyTable } from 'src/scenes/letseatmanager/restaurantDashboard/menuReport/SalesByMenuCategoryFamilyTable';
import { SalesByMenuCategoryTable } from 'src/scenes/letseatmanager/restaurantDashboard/menuReport/SalesByMenuCategoryTable';
import { SalesByMenuItemTable } from 'src/scenes/letseatmanager/restaurantDashboard/menuReport/SalesByMenuItemTable';
import { SalesByModifierTable } from 'src/scenes/letseatmanager/restaurantDashboard/menuReport/SalesByModifierTable';
import { TaxesItemTable } from 'src/scenes/letseatmanager/restaurantDashboard/menuReport/TaxesItemTable';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function MenuReport(): React.ReactElement {
    const classes = useStyles();

    const [showAsMaster, setShowAsMaster] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const currentRestaurantId = useSelector((state) => state.app2.filterReportsState.currentRestaurantId);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, reports] = useLoadApi(
        getMenuRestaurantStatisticsApi,
        {
            report: showAsMaster ? 'asBrand' : 'perRestaurant',
            restaurantIds: brandOpened ? restaurantIds : [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period?.to)).toISOString(),
            salesType,
        },
        {
            dependencies: [period, brandOpened, showAsMaster, restaurantId, salesType],
        }
    );

    const restaurantIdForReports = showAsMaster || !brandOpened ? reports?.[0]?.restaurantId : currentRestaurantId ?? reports?.[0]?.restaurantId;
    const restaurantReports = reports?.find((restaurant) => restaurant.restaurantId === restaurantIdForReports);

    const salesByMenuCategoryFamilies = restaurantReports?.salesByMenuCategoryFamily?.sort((itemA: SalesByMenuCategoryFamily, itemB: SalesByMenuCategoryFamily) => itemB.total - itemA.total) ?? [];
    const salesByMenuCategories = restaurantReports?.salesByMenuCategory?.sort((itemA: SalesByMenuCategory, itemB: SalesByMenuCategory) => itemB.total - itemA.total) ?? [];
    const salesByMenuItems = restaurantReports?.salesByItem?.sort((itemA: SalesByMenuItem, itemB: SalesByMenuItem) => itemB.total - itemA.total) ?? [];
    const salesByModifiers = restaurantReports?.salesByModifier?.sort((itemA: SalesByModifier, itemB: SalesByModifier) => itemB.total - itemA.total) ?? [];
    const hasInfoFound = !!restaurantReports?.salesByItem;

    const handleShowAsMaster = (value: any) => {
        setShowAsMaster(value);
    };

    return (
        <div>
            <FilterReports />
            <div className={classes.container}>
                <FilterMasterReports onChangeMaster={handleShowAsMaster} showAsMaster={showAsMaster} />
                {loading && <UpdatingContentProgress />}
                {hasInfoFound && (
                    <div>
                        <SalesByMenuCategoryFamilyTable loading={loading} salesByMenuCategoryFamilies={salesByMenuCategoryFamilies} />
                        <SalesByMenuCategoryTable loading={loading} salesByMenuCategories={salesByMenuCategories} />
                        <SalesByMenuItemTable loading={loading} salesByMenuItems={salesByMenuItems} />
                        <SalesByModifierTable loading={loading} salesByModifierData={salesByModifiers} />
                        <TaxesItemTable showAsMaster={showAsMaster} />
                    </div>
                )}
                {!hasInfoFound && !loading && <ReportSectionEmptyState />}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
}));
