/**
 * @prettier
 */
import { IconButton, makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import { BigNumber } from 'bignumber.js';
import { useEffect } from 'react';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import { PosPayment } from 'src/types/PosPayment';
import { PosTip } from 'src/types/PosTip';
import { exchangeCurrencyAmount } from 'src/utils/order/exchangeCurrencyAmount';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { sum } from 'src/utils/reduce/sum';

export function PosPaymentCustomerItem({ customerOrderDetails }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const customerNumberSelected = useSelector((state) => state.pos.customerNumberSelected);
    const payments = useSelector((state) => state.pos.payments);
    const promoCode = useSelector((state) => state.pos.promoCode);
    const useLetsEatCredits = useSelector((state) => state.pos.useLetsEatCredits);
    const selectedCurrency = useSelector((state) => state.pos.selectedCurrency);

    const setCustomerNumberSelected = useAction(posReducer.actions.setCustomerNumberSelected);
    const setNumericPadValue = useAction(posReducer.actions.setNumericPadValue);
    const setPayTotalTicketSelected = useAction(posReducer.actions.setPayTotalTicketSelected);
    const removePosCustomerDiscount = useAction(posReducer.actions.removePosCustomerDiscount);
    const removePosCustomerTip = useAction(posReducer.actions.removePosCustomerTip);

    const isCustomerSelected = customerNumberSelected === customerOrderDetails.customerNumber;
    const customerHasPendingPayment = BigNumber(customerOrderDetails.payment?.total ?? 0).isGreaterThan(customerOrderDetails.payment?.totalPaid ?? 0);
    const hasNoCustomerPayment = payments?.some((payment: PosPayment) => !payment.customerNumber);
    const customerPaymentDisabled = !customerHasPendingPayment || hasNoCustomerPayment || !!useLetsEatCredits || !!promoCode;
    const displayCustomerTotalPaid = !BigNumber(customerOrderDetails.payment?.totalPaid ?? 0).isZero();
    const totalCustomerTips = customerOrderDetails?.tips?.reduce((totalTips: BigNumber | number, currentTip: PosTip) => sum(totalTips, currentTip?.tipAmount), BigNumber(0));
    const missingCustomerPayment = BigNumber(customerOrderDetails.payment?.total ?? 0)
        .minus(customerOrderDetails.payment?.totalPaid ?? 0)
        .toNumber();
    const missingCustomerPaymentByCurrency = exchangeCurrencyAmount(missingCustomerPayment, selectedCurrency);

    const totalWithoutPosDiscountAndTip = BigNumber(customerOrderDetails.payment?.total ?? 0)
        .plus(customerOrderDetails.payment?.posDiscount ?? 0)
        .minus(totalCustomerTips ?? 0)
        .toString();
    const totalWithoutPosDiscountAndTipByCurrency = exchangeCurrencyAmount(totalWithoutPosDiscountAndTip, selectedCurrency);

    const displayMissingPayment = (displayCustomerTotalPaid || customerOrderDetails.tips || customerOrderDetails.discount) && !!missingCustomerPayment;

    useEffect(() => {
        if (isCustomerSelected) {
            if (BigNumber(customerOrderDetails.payment?.totalPaid ?? 0).isZero()) return setNumericPadValue(exchangeCurrencyAmount(customerOrderDetails.payment?.total ?? 0, selectedCurrency));

            setNumericPadValue(missingCustomerPaymentByCurrency);
        }
    }, [customerOrderDetails.payment?.total, customerNumberSelected]);

    const handleSelectPaymentCustomer = () => {
        if (customerPaymentDisabled) return;
        setCustomerNumberSelected(customerOrderDetails.customerNumber);
        setNumericPadValue(missingCustomerPaymentByCurrency ?? '0');
        setPayTotalTicketSelected(false);
    };

    const handleRemovePosCustomerTip = (tip: PosTip) => {
        if (!customerHasPendingPayment) return;
        removePosCustomerTip({
            tip: { tipType: tip.tipType, tipPercentage: tip.tipPercentage, paymentMethod: tip.paymentMethod, customPaymentMethod: tip.customPaymentMethod },
            customerNumber: customerOrderDetails.customerNumber,
        });
    };

    const handleRemovePosCustomerDiscount = () => {
        if (!customerHasPendingPayment) return;

        removePosCustomerDiscount({
            discount: customerOrderDetails.payment?.posDiscount ?? 0,
            customerNumber: customerOrderDetails.customerNumber,
        });
    };

    return (
        <div className={classNames(classes.container, isCustomerSelected ? classes.active : customerPaymentDisabled ? classes.disabled : '')} onClick={handleSelectPaymentCustomer}>
            <div className={classes.lineBlock}>
                <span>{translate('Customer @customerNumber', { customerNumber: customerOrderDetails.customerNumber })}</span>
                <span>{formatAsCurrencyNumber(totalWithoutPosDiscountAndTipByCurrency ?? 0, { currency: selectedCurrency?.currency })}</span>
            </div>
            {customerOrderDetails.tips?.map((tip: PosTip, index: number) => (
                <div className={classNames(classes.lineBlock, classes.subText)} key={index}>
                    <span>
                        {translate('Tip:')} {translate(tip?.paymentMethod)}
                    </span>
                    <div>
                        <span>{formatAsCurrencyNumber(tip?.tipAmount ?? 0)}</span>
                        <IconButton classes={{ root: classes.iconButtonStyle }} size={'small'} onClick={() => handleRemovePosCustomerTip(tip)}>
                            <CloseIcon classes={{ root: classes.iconStyle }} fontSize={'small'} />
                        </IconButton>
                    </div>
                </div>
            ))}
            {customerOrderDetails.payment?.posDiscount && (
                <div className={classNames(classes.lineBlock, classes.subText)}>
                    <span>{translate('Discount:')}</span>
                    <div>
                        <span>-{formatAsCurrencyNumber(customerOrderDetails.payment?.posDiscount ?? 0)}</span>
                        <IconButton classes={{ root: classes.iconButtonStyle }} size={'small'} onClick={handleRemovePosCustomerDiscount}>
                            <CloseIcon classes={{ root: classes.iconStyle }} fontSize={'small'} />
                        </IconButton>
                    </div>
                </div>
            )}
            {displayCustomerTotalPaid && (
                <div className={classNames(classes.lineBlock, classes.subText)}>
                    <span>{translate('Paid')}</span>
                    <span>-{formatAsCurrencyNumber(customerOrderDetails.payment?.totalPaid ?? 0)}</span>
                </div>
            )}
            {displayMissingPayment && (
                <div>
                    <Divider className={classes.dividerStyle} />
                    <div className={classNames(classes.lineBlock, classes.subText, classes.missingPaymentContent)}>
                        <span>{translate('Payment missing')}</span>
                        <span className={classes.missingPaymentStyle}>{formatAsCurrencyNumber(missingCustomerPayment < 0 ? 0 : missingCustomerPaymentByCurrency)}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        fontFamily: theme.typography.regular,
        fontSize: 14,
        padding: '8px 18px',
        borderRadius: 10,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.border.primary}`,
        margin: '5px 0',
    },
    lineBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    active: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.text.brand,
        border: `1px solid ${theme.palette.border.brand}`,
    },
    disabled: {
        backgroundColor: theme.palette.surface.secondary,
    },
    subText: {
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    iconButtonStyle: {
        marginLeft: 5,
        border: '1px solid #87888B',
    },
    iconStyle: {
        '&.MuiSvgIcon-fontSizeSmall': {
            fontSize: 12,
        },
    },
    dividerStyle: {
        margin: '5px 0',
    },
    missingPaymentStyle: {
        color: theme.palette.text.danger,
    },
    missingPaymentContent: {
        color: theme.palette.text.primary,
    },
}));

type Props = {
    customerOrderDetails: PosCustomerOrderDetails;
};
