/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function closePedidosYaRestaurantApi(request: ClosePedidosYaRestaurantApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('integration/closePedidosYaRestaurantApi', request);
}

type ClosePedidosYaRestaurantApiRequest = {
    restaurantId: RestaurantId;
};
