/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BrandId } from 'src/types/Id';

export async function removeBrandApi(request: RemoveBrandApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('brand/removeBrandApi', request);
}

export type RemoveBrandApiRequest = {
    brandId: BrandId;
};
