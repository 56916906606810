/**
 * @prettier
 */
import { Button } from '@pidedirecto/ui';
import { PrinterIcon } from '@pidedirecto/ui/icons';
import { useState } from 'react';
import * as React from 'react';
import { getCashRegisterSummaryApi } from 'src/api/letseatmanager/pos/getCashRegisterSummaryApi';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { usePrintPartialShiftCashRegisterReport } from 'src/services/printer/usePrintPartialShiftCashRegisterReport';
import { CashRegisterId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function PrintPartialShiftCashRegisterButton({ cashRegisterId }: Props): React.ReactElement {
    const printPartialShiftCashRegisterReport = usePrintPartialShiftCashRegisterReport();

    const [loading, setLoading] = useState(false);

    const managerUserId = useSelector((state) => state.authentication.pinCodeUserSignedIn?.managerUserId);

    const handlePrintPartialShiftCashRegisterReport = async () => {
        setLoading(true);
        const response = await getCashRegisterSummaryApi({ cashRegisterId, managerUserId });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        await printPartialShiftCashRegisterReport(response?.data, { forceToPrint: true });
    };

    return (
        <SecuredContent rolePermission={RolePermissions.PRINT_PARTIAL_SHIFT_CASH_REGISTER_REPORT}>
            <Button onClick={handlePrintPartialShiftCashRegisterReport} variant={'outline'} disabled={loading}>
                <PrinterIcon size={20} />
                {translate('Partial cut')}
            </Button>
        </SecuredContent>
    );
}

type Props = {
    cashRegisterId: CashRegisterId;
};
