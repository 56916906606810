/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { RestaurantId } from 'src/types/Id';

export async function removeAppUsedAtThePosBusinessDayReportApi(request: RemoveAppUsedAtThePosBusinessDayReportApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurant/removeChannelUsedAtThePosBusinessDayReportApi', request);
}

export type RemoveAppUsedAtThePosBusinessDayReportApiRequest = {
    restaurantId: RestaurantId;
    channel: App;
};
