/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesforceRestaurantType } from 'src/constants/SalesforceRestaurantType';
import type { BrandId, RestaurantId, Zone } from 'src/types/Id';

export async function getRestaurantsBillingApi(request: GetRestaurantsBillingApiRequest): ApiSauceResponse<RestaurantsBillingVm> {
    return letseatadminApiMethod('restaurantBilling/getRestaurantsBillingApi', request);
}

type GetRestaurantsBillingApiRequest = {
    from: Date;
    to: Date;
    brandId?: BrandId;
};

export type RestaurantsBillingVm = {
    restaurantsBilling: Array<RestaurantBillingVm>;
    webCard: number;
    webCash: number;
    webCardOnDelivery: number;
    whiteLabelAppCard: number;
    whiteLabelAppCash: number;
    whiteLabelAppCardOnDelivery: number;
    marketplaceAppCard: number;
    marketplaceAppCash: number;
    marketplaceAppCardOnDelivery: number;
    posCard: number;
    posCash: number;
    posCardOnDelivery: number;
    marketplaceServiceFeeCash: number;
    marketplaceServiceFeeCard: number;
    marketplaceServiceFeeCardOnDelivery: number;
    cashback: number;
    manualOrders: number;
    manualOrdersProtected: number;
    directoProtectionAmount: number;
    invoicedRestaurantDeliveryCost: number;
    deliveryCostPaidByRestaurant: number;
    total: number;
    commission: number;
    completed: number;
    restaurantPromoCodeCostApp: number;
    restaurantPromoCodeBalance: number;
    paymentLink: number;
    paymentLinks: number;
    iva: number;
    subscriptionPayPerUsePayment: number;
    subscriptionPayPerUsePayments: number;
    manualEarning: number;
    manualEarnings: number;
    debtSubscriptionPayPerUsePayment: number;
    debtManualEarning: number;
    subscriptionPayPerUsePaymentInvoice: number;
    manualEarningInvoice: number;
    deposit: number;
};

export type RestaurantBillingVm = {
    restaurantId: RestaurantId;
    brandId?: BrandId;
    brandName?: string;
    restaurantName: string;
    salesforceRestaurantType: SalesforceRestaurantType;
    restaurantZone?: Zone;
    completed: number;
    webCard: number;
    webCash: number;
    webCardOnDelivery: number;
    whiteLabelAppCard: number;
    whiteLabelAppCash: number;
    whiteLabelAppCardOnDelivery: number;
    marketplaceAppCard: number;
    marketplaceAppCash: number;
    marketplaceAppCardOnDelivery: number;
    posCard: number;
    posCash: number;
    posCardOnDelivery: number;
    marketplaceServiceFeeCash: number;
    marketplaceServiceFeeCard: number;
    marketplaceServiceFeeCardOnDelivery: number;
    cashback: number;
    manualOrders: number;
    manualOrdersProtected: number;
    directoProtectionAmount: number;
    rappiCargoWebCard: number;
    rappiCargoWebCash: number;
    rappiCargoAppCard: number;
    rappiCargoAppCash: number;
    rappiCargoManualOrder: number;
    rappiCargoPaymentLink: number;
    rappiCargoPosCard: number;
    rappiCargoPosCash: number;
    rappiCargoBringgCard: number;
    rappiCargoBringgCash: number;
    uberDaasWebCard: number;
    uberDaasWebCash: number;
    uberDaasAppCard: number;
    uberDaasAppCash: number;
    uberDaasManualOrder: number;
    uberDaasPaymentLink: number;
    uberDaasPosCard: number;
    uberDaasPosCash: number;
    uberDaasBringgCard: number;
    uberDaasBringgCash: number;
    invoicedRestaurantDeliveryCost: number;
    deliveryCostPaidByRestaurant: number;
    restaurantPromoCodeCostApp: number;
    restaurantPromoCodeBalance: number;
    paymentLink: number;
    paymentLinks: number;
    commissionWebCard: number;
    commissionWebCash: number;
    commissionWebCardOnDelivery: number;
    commissionAppCard: number;
    commissionAppCash: number;
    commissionAppCardOnDelivery: number;
    commissionManualOrders: number;
    commissionRappiCargoWebCard: number;
    commissionRappiCargoWebCash: number;
    commissionRappiCargoAppCard: number;
    commissionRappiCargoAppCash: number;
    commissionRappiCargoManualOrder: number;
    commissionRappiCargoPosCard: number;
    commissionRappiCargoPosCash: number;
    commissionUberDaasWebCard: number;
    commissionUberDaasWebCash: number;
    commissionUberDaasAppCard: number;
    commissionUberDaasAppCash: number;
    commissionUberDaasManualOrder: number;
    commissionUberDaasPosCard: number;
    commissionUberDaasPosCash: number;
    total: number;
    commission: number;
    iva: number;
    subscriptionPayPerUsePayment: number;
    subscriptionPayPerUsePayments: number;
    manualEarning: number;
    manualEarnings: number;
    debtSubscriptionPayPerUsePayment: number;
    debtManualEarning: number;
    subscriptionPayPerUsePaymentInvoice: number;
    manualEarningInvoice: number;
    deposit: number;
};
