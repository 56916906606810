/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { findScheduledNotificationsToCustomerApi, ScheduledNotificationVm } from 'src/api/letseatmanager/marketing/findScheduledNotificationsToCustomerApi';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { RemoveScheduledNotificationDialog } from 'src/scenes/letseatmanager/marketing/RemoveScheduledNotificationDialog';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function ScheduledNotificationsTable(): React.ReactElement | null {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [scheduledNotifications, setScheduledNotifications] = useState<Array<ScheduledNotificationVm>>([]);
    const [removeScheduledNotificationState, setRemoveScheduledNotificationState] = useState<{ open: boolean; scheduledTask: any }>({ open: false, scheduledTask: undefined });

    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const marketingActionsForRestaurantEnabled = useSelector((state) => state.app.restaurant.marketingActionsForRestaurantEnabled);

    useEffect(() => {
        load();
    }, [restaurantIds]);

    const load = async () => {
        setLoading(true);
        const response = await findScheduledNotificationsToCustomerApi({
            restaurantIds,
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        setScheduledNotifications(response.data ?? []);
        return;
    };

    const closeRemoveNotificationScheduledDialog = () => setRemoveScheduledNotificationState({ open: false, scheduledTask: undefined });

    const successRemoveNotificationScheduledDialog = () => {
        closeRemoveNotificationScheduledDialog();
        load();
    };

    return (
        <>
            <RemoveScheduledNotificationDialog
                open={removeScheduledNotificationState.open}
                scheduledTask={removeScheduledNotificationState.scheduledTask}
                onClose={closeRemoveNotificationScheduledDialog}
                onSuccess={successRemoveNotificationScheduledDialog}
            />
            <h1 className={classes.title}>{translate('Scheduled Notifications')}</h1>
            <UpdatingContentProgress loading={loading} />
            <Table
                className={classes.table}
                loading={loading}
                data={scheduledNotifications.map((scheduledNotification) => ({
                    scheduledTaskId: scheduledNotification.scheduledTaskId,
                    name: scheduledNotification.name,
                    scheduledAt: getScheduledAtScheduledNotification(scheduledNotification),
                    lastScheduledRunAt: formatDateTimeStringReadable(scheduledNotification.lastScheduledRunAt),
                }))}
                columns={[
                    {
                        name: 'scheduledTaskId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'name',
                        label: translate('Name'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'scheduledAt',
                        label: translate('Scheduled At'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'lastScheduledRunAt',
                        label: translate('Last Time Run'),
                        options: {
                            filter: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: marketingActionsForRestaurantEnabled ? 'multiple' : 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                        </>
                    ),
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const scheduledTaskIds = selectedRowsData.map((d: any) => d[0]);

                        if (scheduledTaskIds.length !== 1) return null;

                        return (
                            <div className={classes.toolbar}>
                                <RemoveToolbarButton
                                    onClick={() =>
                                        setRemoveScheduledNotificationState({
                                            open: true,
                                            scheduledTask: scheduledNotifications.find((scheduledNotification) => scheduledNotification.scheduledTaskId === scheduledTaskIds[0]),
                                        })
                                    }
                                />
                            </div>
                        );
                    },
                }}
            />
        </>
    );
}

function getScheduledAtScheduledNotification(scheduledNotification: ScheduledNotificationVm) {
    let text = translate(`ScheduleTaskIntervals.${scheduledNotification.interval}`);
    if (scheduledNotification.day) text += ` ${translate(scheduledNotification.day)}`;
    if (scheduledNotification.dayNumber) text += ` ${translate('day')} ${requireValue(scheduledNotification.dayNumber)?.toString()}`;
    text += ` ${scheduledNotification.hour}`;
    return text;
}

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
        marginBottom: '6em',
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 25,
        marginTop: '4em',
    },
    bannerThumbnail: {
        objectFit: 'cover',
        width: 200,
        height: 120,
        borderRadius: 20,
    },
}));
