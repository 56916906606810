/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { memo, useEffect, useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { enableRestaurantPaymentsApi } from 'src/api/letseatmanager/restaurant/enableRestaurantPaymentsApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { usePosPaymentMethodsDeprecated } from 'src/services/pos/posPaymentMethods/usePosPaymentMethodsDeprecated';
import { useSetPosPaymentMethod } from 'src/services/pos/posPaymentMethods/useSetPosPaymentMethod';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

/**
 * @deprecated
 */
export const EnableRestaurantPaymentsDialogDeprecated: React.ComponentType<any> = memo<any>(() => {
    const classes = useStyles();
    const posPaymentMethods = usePosPaymentMethodsDeprecated();
    const setPosPaymentMethod = useSetPosPaymentMethod();

    const form = useForm();

    const [loading, setLoading] = useState(false);
    const [openEnableRestaurantPaymentsDialog, setOpenEnableRestaurantPaymentsDialog] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const cashPaymentsEnabled = useSelector((state) => state.pos.context?.cashPaymentsEnabled);
    const creditCardPaymentsEnabled = useSelector((state) => state.pos.context?.creditCardPaymentsEnabled);
    const paymentLinksEnabled = useSelector((state) => state.pos.context?.paymentLinksEnabled);

    const { reloadRestaurant } = useReloadRestaurant();

    const hasAvailablePayments = !!cashPaymentsEnabled || !!creditCardPaymentsEnabled || !!paymentLinksEnabled;

    useEffect(() => {
        verifyRestaurantHasPaymentsMethods();
    }, [posPaymentMethods, hasAvailablePayments]);

    const verifyRestaurantHasPaymentsMethods = () => {
        if (posPaymentMethods === undefined) return;

        if (hasAvailablePayments || !!posPaymentMethods?.length) return selectDefaultPaymentMethod();

        setOpenEnableRestaurantPaymentsDialog(true);
    };

    const selectDefaultPaymentMethod = () => {
        if (cashPaymentsEnabled) return setPosPaymentMethod({ paymentMethod: PaymentMethods.CASH, customPaymentMethod: undefined });
        if (creditCardPaymentsEnabled) return setPosPaymentMethod({ paymentMethod: PaymentMethods.CREDIT_CARD, customPaymentMethod: undefined });

        if (!!posPaymentMethods?.length) return setPosPaymentMethod({ paymentMethod: posPaymentMethods?.[0].paymentMethod, customPaymentMethod: posPaymentMethods?.[0].name });
    };

    const handleSubmit = async (form: any) => {
        setLoading(true);
        const response = await enableRestaurantPaymentsApi({
            ...form,
            restaurantId,
        });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setLoading(false);
            return;
        }

        await reloadRestaurant(restaurantId);
        setLoading(false);
        setOpenEnableRestaurantPaymentsDialog(false);
    };

    return (
        <Dialog open={openEnableRestaurantPaymentsDialog} title={translate('Enable POS payment methods')}>
            <Form form={form} onSubmit={handleSubmit}>
                <div className={classes.contentDialog}>
                    <FormSwitch name='cashPaymentsEnabled' label={translate('Cash Payments')} defaultValue={true} />
                    <FormSwitch name='creditCardPaymentsEnabled' label={translate('Credit Card Payments')} defaultValue={true} />
                    <FormSwitch name='paymentLinksEnabled' label={translate('Payment Links')} />
                </div>
                <DialogActions className={classes.buttonsContainer}>
                    <Button type={'submit'} disabled={loading}>
                        {translate('Accept')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
});

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        borderRadius: 12,
        background: '#FFF',
        boxShadow: '4px 4px 7px rgba(0,0,0, 0.25)',
        width: 540,
        paddingInline: '4%',
    },
    contentDialog: {
        display: 'grid',
        gap: 15,
        padding: '0 3%',
        '&.first-child': {
            paddingTop: 0,
        },
    },
    title: {
        textAlign: 'center',
        fontSize: 20,
        color: '#0FA489',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    content: {
        width: '100%',
    },
    buttonsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: 10,
        gap: 10,
    },
    primaryButton: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        height: 40,
        width: 120,
        maxWidth: 120,
        borderRadius: 6,
        textTransform: 'none',
    },
}));
