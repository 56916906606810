/**
 * @prettier
 */
import { Collapse, List, makeStyles, useTheme } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useState } from 'react';
import * as React from 'react';
import { AppMenuLink } from 'src/components/app/appMenu/AppMenuLink';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermission, RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { BarChartIcon } from 'src/icons/BarChartIcon';
import { useGetReportRolePermissions } from 'src/utils/react/useGetReportRolePermissions';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function RestaurantReportsAppMenu(): React.ReactElement | null {
    const classes = useStyles();
    const theme = useTheme();

    const [userHasRolePermission] = useUserHasRolePermission();

    const [showRestaurantReportsAppMenu, setShowRestaurantReportsAppMenu] = useState(false);

    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);
    const inventoryEnabled = useSelector((state) => state.app.restaurant.inventoryEnabled);
    const externalOwnFleetEnabled = useSelector((state) => state.app.restaurant.externalOwnFleetEnabled);
    const ownFleetEnabled = useSelector((state) => state.app.restaurant.ownFleetEnabled);
    const externalDelivery = useSelector((state) => state.app.restaurant.externalDelivery);
    const userManagementEnabled = useSelector((state) => state.app.restaurant.userManagementEnabled);

    const posReportRolePermissions = useGetReportRolePermissions(RoutePaths.RESTAURANT_POS_REPORT);
    const generalReportRolePermissions = useGetReportRolePermissions(RoutePaths.RESTAURANT_GENERAL_REPORT);
    const inventoryReportRolePermissions = useGetReportRolePermissions(RoutePaths.INVENTORY_REPORT);
    const salesReportRolePermissions = useGetReportRolePermissions(RoutePaths.RESTAURANT_SALES_REPORT);

    const hasSomeGeneralReportPermission = generalReportRolePermissions?.some((permission: RolePermission) => userHasRolePermission(permission));
    const hasSomeSalesReportPermission = salesReportRolePermissions?.some((permission: RolePermission) => userHasRolePermission(permission));
    const hasSomePosReportPermission = posReportRolePermissions?.some((permission: RolePermission) => userHasRolePermission(permission));
    const hasSomeInventoryReportPermission = inventoryReportRolePermissions?.some((permission: RolePermission) => userHasRolePermission(permission));

    if (!hasSomeGeneralReportPermission && !hasSomeSalesReportPermission && !hasSomePosReportPermission && !hasSomeInventoryReportPermission && (!externalOwnFleetEnabled || !externalDelivery))
        return null;

    return (
        <SecuredContent rolePermission={RolePermissions.REPORTS_PAGE}>
            <ListItem button onClick={() => setShowRestaurantReportsAppMenu(!showRestaurantReportsAppMenu)} className={classes.listItem}>
                <ListItemIcon>
                    <BarChartIcon title={'bar chart icon'} color={theme.palette.icons.brand} />
                </ListItemIcon>
                <ListItemText classes={{ root: classes.listItemText }} primary={translate('Reports')} />
                <ListItemIcon>{showRestaurantReportsAppMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</ListItemIcon>
            </ListItem>
            <Collapse in={showRestaurantReportsAppMenu} data-testid='restaurantAppMenu.reportsLink'>
                <List>
                    {hasSomeGeneralReportPermission && (
                        <AppMenuLink
                            className={classes.menuLinkOption}
                            routePath={RoutePaths.RESTAURANT_GENERAL_REPORT}
                            text={translate('General')}
                            data-testid='restaurantAppMenu.restaurantReports.general'
                        />
                    )}
                    {hasSomeSalesReportPermission && (
                        <AppMenuLink
                            className={classes.menuLinkOption}
                            routePath={RoutePaths.RESTAURANT_SALES_REPORT}
                            text={translate('Sales reports')}
                            data-testid='restaurantAppMenu.restaurantReports.sales'
                        />
                    )}
                    {(posEnabled || userManagementEnabled) && hasSomePosReportPermission && (
                        <AppMenuLink className={classes.menuLinkOption} routePath={RoutePaths.RESTAURANT_POS_REPORT} text={translate('POS')} data-testid='restaurantAppMenu.restaurantReports.pos' />
                    )}
                    {inventoryEnabled && hasSomeInventoryReportPermission && (
                        <AppMenuLink
                            className={classes.menuLinkOption}
                            routePath={RoutePaths.INVENTORY_REPORT}
                            text={translate('Inventory')}
                            data-testid='restaurantAppMenu.inventoryReports.inventory'
                        />
                    )}
                    {(externalOwnFleetEnabled || ownFleetEnabled) && (
                        <AppMenuLink
                            className={classes.menuLinkOption}
                            routePath={RoutePaths.OWN_FLEET_DRIVERS_REPORT}
                            text={translate('Own Drivers Report')}
                            data-testid='restaurantAppMenu.restaurantReports.ownFleetDrivers'
                        />
                    )}
                </List>
            </Collapse>
        </SecuredContent>
    );
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        borderRadius: 6,
        '&:hover': {
            backgroundColor: theme.palette.surface.tertiary,
        },
    },
    listItemText: {
        '& span': {
            fontFamily: theme.typography.regular,
        },
    },
    menuLinkOption: {
        paddingLeft: 30,
    },
    icon: {
        color: theme.palette.surface.brandContrast,
        width: 30,
        height: 30,
    },
}));
