/**
 * @prettier
 */
import * as React from 'react';
import { MenuCategories } from 'src/scenes/letseatmanager/menu/menuCategory/MenuCategories';
import { MenuCategoriesEmptyState } from 'src/scenes/letseatmanager/menu/menuCategory/MenuCategoriesEmptyState';
import { useMenuCategories } from 'src/services/menuCategory/useMenuCategories';
import { useSelector } from 'src/utils/react/useSelector';

export function MenuCategoriesSection(): React.ReactElement {
    const { menuCategories } = useMenuCategories();

    const menuCategoriesLoaded = useSelector((state) => state.menu.menuCategoriesLoaded);

    const thereIsMenuCategories = menuCategories && menuCategories?.length > 0;

    if (!thereIsMenuCategories && menuCategoriesLoaded) {
        return <MenuCategoriesEmptyState />;
    }

    return <MenuCategories />;
}
