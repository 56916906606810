/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import { CashIcon, ChainIcon, CreditCardIcon, PaymentTerminalIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { MexicanPaymentMethodCode } from 'src/constants/MexicanPaymentMethodCode';
import { PaymentMethod } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { PaymentMethodIcon } from 'src/scenes/letseatmanager/theRestaurant/PaymentMethodIcon';
import { usePosPaymentMethods } from 'src/services/pos/posPaymentMethods/usePosPaymentMethods';
import type { CustomPaymentMethod } from 'src/types/Id';
import { PosPayment } from 'src/types/PosPayment';
import { RestaurantPaymentMethodVm } from 'src/types/RestaurantPaymentMethodVm';
import { isArgentina } from 'src/utils/country/isArgentina';
import { isUruguay } from 'src/utils/country/isUruguay';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { isTerminalPayment } from 'src/utils/paymentMethod/isTerminalPayment';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { isPidedirectoPayment } from 'src/utils/restaurantPaymentMethod/isPidedirectoPayment';

export function PosPaymentMethods({ disabled, onSelect, selectedPaymentMethod }: Props): React.ReactElement {
    const classes = useStyles();
    const posPaymentMethods = usePosPaymentMethods();

    const clabe = useSelector((state) => state.app.restaurant.clabe);
    const bank = useSelector((state) => state.app.restaurant.bank);
    const externalDelivery = useSelector((state) => state.app.restaurant.externalDelivery);
    const orderType = useSelector((state) => state.pos.orderType);
    const payments = useSelector((state) => state.pos.payments);
    const useLetsEatCredits = useSelector((state) => state.pos.useLetsEatCredits);
    const country = useSelector((state) => state.app.restaurant.country);
    const selectedCurrency = useSelector((state) => state.pos.selectedCurrency);
    const mainCurrency = useSelector((state) => state.app.restaurant?.currencyFormat?.currency);

    const isPaymentLinkMethodSelected = payments?.every((posPayment: PosPayment) => isPaymentLinkPayment(posPayment.paymentMethod));
    const disabledPaymentLinkMethod = disabled || !isPaymentLinkMethodSelected;
    const disabledCustomPaymentMethods = isDeliveryOrder(orderType) && !externalDelivery;
    const isUyOrArCountry = isArgentina(country) || isUruguay(country);
    const disabledCreditCardMethod = isUyOrArCountry ? disabled : disabled || isDeliveryOrder(orderType);
    const isMainCurrency = !!selectedCurrency?.currency ? mainCurrency === selectedCurrency?.currency : !!mainCurrency;

    const disabledPosCreditCardMethod = (paymentMethod: PaymentMethod) => disabled || useLetsEatCredits || (isCardPayment(paymentMethod) && isDeliveryOrder(orderType) && !externalDelivery);

    const handleSelect = async ({
        paymentMethod,
        customPaymentMethod,
        paymentReferenceEnabled,
        mexicanPaymentMethodCode,
    }: {
        paymentMethod: PaymentMethod;
        customPaymentMethod?: CustomPaymentMethod | undefined;
        paymentReferenceEnabled?: boolean;
        mexicanPaymentMethodCode?: MexicanPaymentMethodCode;
    }) => {
        await onSelect({ paymentMethod, customPaymentMethod, paymentReferenceEnabled, mexicanPaymentMethodCode });
    };
    const getPaymentMethodIcon = (paymentMethod: PaymentMethod) => {
        if (isCashPayment(paymentMethod)) return <CashIcon size={28} title={translate('Cash')} />;
        if (isCardPayment(paymentMethod)) return <CreditCardIcon title={translate('Card')} />;
        if (isTerminalPayment(paymentMethod)) return <PaymentTerminalIcon size={28} title={translate('Terminal')} />;
        if (isPaymentLinkPayment(paymentMethod)) return <ChainIcon title={translate('Chain')} />;
    };

    const isRestaurantPaymentMethodDisabled = (restaurantPaymentMethod: RestaurantPaymentMethodVm) => {
        if (!isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType)) {
            return (
                disabledPosCreditCardMethod(restaurantPaymentMethod.paymentMethod) ||
                disabledCustomPaymentMethods ||
                (isCardPayment(restaurantPaymentMethod.paymentMethod) && !isMainCurrency) ||
                !restaurantPaymentMethod.enabled
            );
        }
        if (isCardPayment(restaurantPaymentMethod.paymentMethod)) {
            return disabledCreditCardMethod || !isMainCurrency || !restaurantPaymentMethod.enabled || !restaurantPaymentMethod.enabled;
        }
        if (isPaymentLinkPayment(restaurantPaymentMethod.paymentMethod)) {
            return !clabe || !bank || !isDeliveryOrder(orderType) || disabledPaymentLinkMethod || !isMainCurrency;
        }
        return disabled || !restaurantPaymentMethod.enabled || !restaurantPaymentMethod.orderTypes?.includes(orderType);
    };

    return (
        <div className={classes.container}>
            <h2 style={{ marginTop: 0 }} className={classes.paymentMethodsTitle}>
                {translate('Payment Methods')}
            </h2>
            <div className={classes.buttonsContainer}>
                {posPaymentMethods?.length > 0 &&
                    posPaymentMethods?.map((posPaymentMethod: RestaurantPaymentMethodVm) => {
                        return (
                            <Button
                                variant={'outline'}
                                disabled={isRestaurantPaymentMethodDisabled(posPaymentMethod)}
                                classes={{ button: classNames(classes.paymentMethodButton, selectedPaymentMethod === posPaymentMethod.name ? classes.paymentMethodButtonSelected : '') }}
                                onClick={() =>
                                    handleSelect({
                                        paymentMethod: posPaymentMethod.paymentMethod,
                                        customPaymentMethod: posPaymentMethod.name as CustomPaymentMethod,
                                        paymentReferenceEnabled: posPaymentMethod.paymentReferenceEnabled,
                                        mexicanPaymentMethodCode: posPaymentMethod.mexicanPaymentMethodCode,
                                    })
                                }
                            >
                                <div className={classes.paymentMethodIconContainer}>
                                    {isPidedirectoPayment(posPaymentMethod.restaurantPaymentMethodType) ? (
                                        getPaymentMethodIcon(posPaymentMethod.paymentMethod)
                                    ) : (
                                        <PaymentMethodIcon icon={posPaymentMethod.icon} />
                                    )}
                                    {isPidedirectoPayment(posPaymentMethod.restaurantPaymentMethodType) && <div style={{ marginTop: 4 }}>{translate(posPaymentMethod.paymentMethod)}</div>}
                                    {!isPidedirectoPayment(posPaymentMethod.restaurantPaymentMethodType) && <div style={{ marginTop: 4 }}>{posPaymentMethod.name}</div>}
                                </div>
                            </Button>
                        );
                    })}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    paymentMethodsTitle: {
        fontFamily: theme.typography.regular,
        fontSize: 18,
        marginBottom: 12,
        color: theme.palette.text.primary,
    },
    buttonsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
        gap: 4,
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
            gap: 6,
        },
    },
    paymentMethodButton: {
        color: theme.palette.text.secondary,
        border: `1px solid ${theme.palette.border.primary}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        borderRadius: 4,
        height: 90,
        maxWidth: 130,
        width: '100%',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    paymentMethodButtonSelected: {
        color: theme.palette.text.brand,
        border: `1px solid ${theme.palette.text.brand}`,
    },
    paymentMethodIconContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    nameContainer: {
        fontSize: 13.5,
        marginTop: 4,
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
}));

type Props = {
    disabled?: boolean;
    onSelect: any;
    selectedPaymentMethod: PaymentMethod | CustomPaymentMethod | undefined;
};
