/**
 * @prettier
 */
import { makeStyles, useTheme } from '@material-ui/core';
import * as React from 'react';
import { Text } from 'src/components/Text';
import { APP_VERSION } from 'src/config/appVersion';
import { translate } from 'src/i18n/translate';
import { LightBulbOutlineIcon } from 'src/icons/LightBulbOutlineIcon';
import { useSelector } from 'src/utils/react/useSelector';

export function FeedBackSection(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();

    const pideDirectoAdminAppVersion = useSelector((state) => state.app.pideDirectoAdminAppVersion);

    return (
        <div className={classes.feedbackContainer}>
            <LightBulbOutlineIcon title={'feedback'} color={theme.palette.icons.brand} />
            <Text style={{ fontSize: 12, marginTop: 4 }}>{translate('Do you have ideas to improve Pide Directo?')}</Text>
            <a className={classes.feedbackLink} href='https://bit.ly/mejorapd' target={'_blank'} rel='noreferrer'>
                <Text style={{ fontSize: 10, color: theme.palette.text.brand }}>{translate('We want to hear you')}</Text>
            </a>
            <Text style={{ fontSize: 10, color: theme.palette.text.secondary }}>{translate('Version @version', { version: APP_VERSION })}</Text>
            {!!pideDirectoAdminAppVersion && <Text style={{ fontSize: 10, color: theme.palette.text.secondary }}>{translate('App Version @version', { version: pideDirectoAdminAppVersion })}</Text>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    feedbackContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
    },
    feedbackLink: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));
