/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { SectionTitle } from 'src/components/title/SectionTitle';
import { TicketSections } from 'src/constants/TicketSection';
import { translate } from 'src/i18n/translate';

export function TicketSalesInfoSettings({ disabled }: Props): React.ReactElement {
    return (
        <section>
            <SectionTitle title={translate('Sales Information')} />
            {/* @ts-ignore */}
            <Grid item xs={12} columnSpacing={2}>
                <FormSwitch disabled={disabled} name={TicketSections.SALES_INFO} label={translate(`TicketSections.${TicketSections.SALES_INFO}`)} />
                <FormSwitch disabled={disabled} name={TicketSections.CANCELLED_ITEMS} label={translate(`TicketSections.${TicketSections.CANCELLED_ITEMS}`)} />
                <FormSwitch
                    disabled={disabled}
                    name={TicketSections.SPLIT_GROUPED_ORDER_ITEMS}
                    label={translate(`TicketSections.${TicketSections.SPLIT_GROUPED_ORDER_ITEMS}`)}
                    tooltip={translate('Each item in your order will be printed individually.')}
                />
            </Grid>
        </section>
    );
}

type Props = {
    disabled: boolean;
};
