/**
 * @prettier
 */
import { makeStyles, useTheme } from '@material-ui/core';
import { DropDown, DropDownItem } from '@pidedirecto/ui';
import { BeakerIcon, ClosedEyeIcon, EditIcon, EllipsisVerticalIcon, EyeIcon, TrashCanIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { translate } from 'src/i18n/translate';
import { RemoveProductFromMenuCategoryDialog } from 'src/scenes/letseatmanager/menu/menuCategory/RemoveProductFromMenuCategoryDialog';
import { ChangeMenuItemDialog } from 'src/scenes/letseatmanager/menu/menuItem/ChangeMenuItemDialog';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import { useLoadMenuItems } from 'src/services/menuItem/useLoadMenuItems';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { MenuCategoryId, MenuId } from 'src/types/Id';
import type { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import { transformImageUrl } from 'src/utils/image/transformImageUrl';
import { useSelector } from 'src/utils/react/useSelector';

export function MenuItemInMenuCategory({ menuItem, menuCategoryId, menuId, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const theme = useTheme();

    const refreshMenuItems = useLoadMenuItems();

    const [changeMenuItemDialogState, setChangeMenuItemDialogState] = useState({ open: false });
    const [removeMenuItemDialogState, setRemoveMenuItemDialogState] = useState({ open: false });

    const currencyFormat = useSelector((state) => state.app.restaurant.currencyFormat);

    const menuProductInfo = menuItem?.menusProductInfo?.find((menu: { hidden?: boolean; menuId: MenuId; price: string; promoPrice?: string }) => menu.menuId === menuId);

    const openRemoveMenuItemDialog = () => setRemoveMenuItemDialogState({ open: true });

    const closeRemoveMenuItemDialog = () => setRemoveMenuItemDialogState({ open: false });

    const openChangeMenuItemDialog = () => setChangeMenuItemDialogState({ open: true });

    const closeChangeMenuItemDialog = () => setChangeMenuItemDialogState({ open: false });

    const handleUpdate = () => {
        refreshMenuItems();
        closeChangeMenuItemDialog();
        onSuccess?.();
    };

    return (
        <div className={classes.container}>
            <RemoveProductFromMenuCategoryDialog
                open={removeMenuItemDialogState.open}
                menuItem={menuItem}
                menuCategoryId={menuCategoryId}
                onClose={closeRemoveMenuItemDialog}
                onSuccess={handleUpdate}
            />
            <ChangeMenuItemDialog open={changeMenuItemDialogState.open} menuItemId={menuItem.menuItemId} onClose={closeChangeMenuItemDialog} onSuccess={handleUpdate} />
            <div className={classes.nameContainer}>
                {menuItem.imageUrl && <img className={classes.image} src={transformImageUrl(menuItem?.imageUrl, 320, 320)} alt={`${menuItem?.name} image`} />}
                {!menuItem.imageUrl && <div className={classes.noImage}></div>}
                <span className={classes.text}>{menuItem.name}</span>
            </div>
            <div className={classes.priceContainer}>
                {menuItem.modifierGroupIds && <BeakerIcon color={theme.palette.icons.brand} title={translate('This product has modifiers different from those of the category')} size={20} />}
                {menuItem.hidden && <ClosedEyeIcon title={translate('Hidden')} size={20} />}
                {!menuItem.hidden && <EyeIcon title={translate('Visible')} size={20} />}
                <span className={classes.text}>{formatAsCurrencyNumber(menuProductInfo?.price ?? menuItem.price)}</span>
                <DropDown disabled={isMenuEditionDisabled} variant='icon' content={<EllipsisVerticalIcon size={20} title='options' />} position='right' classes={{ dropdown: classes.dropdown }}>
                    <DropDownItem classes={{ container: classes.menuItem }} onClick={openChangeMenuItemDialog}>
                        <EditIcon size={14} />
                        {translate('Edit')}
                    </DropDownItem>
                    <DropDownItem classes={{ container: classes.menuItem }} onClick={openRemoveMenuItemDialog}>
                        <TrashCanIcon color={'#E37878'} title={'trashcan icon'} size={14} />
                        {translate('Remove')}
                    </DropDownItem>
                </DropDown>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    nameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    priceContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.palette.icons.primary,
        gap: 20,
    },
    image: {
        width: 40,
        height: 40,
        borderRadius: 10,
        objectFit: 'cover',
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    noImage: {
        width: 40,
        height: 40,
        borderRadius: 10,
        backgroundColor: '#E5E8ED',
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        marginRight: -10,
    },
    menuItem: {
        color: theme.palette.icons.primary,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
    },
    dropdown: {
        minWidth: 'unset',
    },
}));

type Props = {
    menuItem: ManagerMenuItemVm;
    menuCategoryId: MenuCategoryId;
    menuId?: MenuId;
    onSuccess?: any;
};
