/**
 * @prettier
 */
import { InputBase, makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import * as React from 'react';
import { useContext, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { FormContext } from 'src/components/form/Form';
import type { AutoCompleteInputProp, Variant } from 'src/components/form/FormTypes';
import { getError } from 'src/components/form/utils/getError';
import { transformFloatInput } from 'src/components/form/utils/transformFloatInput';
import { transformFloatOutput } from 'src/components/form/utils/transformFloatOutput';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';

export function FormNumberField({
    name,
    label,
    placeholder,
    helperText,
    defaultValue = null,
    autoComplete = 'off',
    thousandSeparator = 'on',
    disabled,
    required,
    min,
    max,
    variant,
    InputProps,
    inputProps,
    inputClasses,
    tooltip,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>();
    const classes = useStyles();
    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <div className={classes.container}>
                    <InputLabel htmlFor={`${name}-input`} classes={{ root: classes.label, error: classes.labelError }} error={!!getError(errors, name)} required={required as any}>
                        {label}
                        {tooltip && <Tooltip text={tooltip} />}
                    </InputLabel>
                    <InputBase
                        {...props}
                        {...InputProps}
                        id={`${name}-input`}
                        value={transformFloatInput(value)}
                        onChange={(value) => onChange(transformFloatOutput(value as any))}
                        placeholder={placeholder}
                        disabled={isSubmitting || disabled || formContext.disabled}
                        required={required}
                        error={!!getError(errors, name)}
                        variant={variant}
                        inputRef={inputRef}
                        inputComponent={NumberFormatCustom}
                        inputProps={{ autoComplete, ...inputProps }}
                        fullWidth
                        classes={{ root: classes.input, error: classes.inputError, ...inputClasses }}
                    />
                    {!!(getError(errors, name)?.message ?? helperText) && (
                        <FormHelperText classes={{ root: !!getError(errors, name) ? classes.helperTextError : classes.helperText }}>{getError(errors, name)?.message ?? helperText}</FormHelperText>
                    )}
                </div>
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
                min: {
                    value: (min as any)?.value ?? min,
                    message: (min as any)?.message ?? translate('Must be number equal to or larger than @number', { number: (min as any)?.value ?? min }),
                },
                max: {
                    value: (max as any)?.value ?? max,
                    message: (max as any)?.message ?? translate('Must be number equal to or less than @number', { number: (max as any)?.value ?? max }),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export function NumberFormatCustom({ inputRef, onChange, min, ...other }: { inputRef: any; name: string; onChange: any; min?: number }): React.ReactElement {
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => onChange(values.value)}
            // allowedDecimalSeparators={[',', '.']}
            thousandSeparator
            allowNegative={min === undefined ? true : min < 0}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        height: '42px',
        padding: 15,
        width: '100%',
        outline: '1px solid #D9D9D9',
        backgroundColor: 'transparent',
        borderRadius: '4px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        boxSizing: 'border-box',
        color: '#191919',
        fontFamily: theme.typography.light,
        border: 0,
    },
    inputError: {
        border: '2px solid red',
    },
    label: {
        fontFamily: theme.typography.light,
        color: '#2E3748',
        fontSize: 14,
        paddingBottom: 5,
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
    labelError: {
        color: 'red',
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: theme.palette.secondary.contrastText,
    },
    helperTextError: {
        fontFamily: theme.typography.light,
        color: 'red',
    },
}));

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: number | null | undefined;
    autoComplete?: AutoCompleteInputProp;
    thousandSeparator?: 'on' | 'off';
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    min?:
        | number
        | {
              value: number;
              message: string;
          };
    max?:
        | number
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    inputProps?: any;
    inputClasses?: any;
    tooltip?: string;
};
