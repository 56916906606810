/**
 * @prettier
 */
import { getBusinessContextApi } from 'src/api/letseatmanager/getBusinessContextApi';
import { appReducer } from 'src/reducers/appReducer';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function useOpenBusiness(): {
    openBusiness: any;
} {
    const userRestaurantIds = useSelector((state) => state.app.userRestaurantIds);

    const openBusiness = useAction(appReducer.actions.openBusiness);
    const setRestaurantIds = useAction(appReducer.actions.setRestaurantIds);

    const handleOpenBusiness = async () => {
        const response = await getBusinessContextApi();
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong();
            return;
        }
        openBusiness(response.data);
        setRestaurantIds(userRestaurantIds);
    };

    return { openBusiness: handleOpenBusiness };
}
