/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function createSupplyCategoryApi(request: CreateSupplyCategoryApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('supplyCategory/createSupplyCategoryApi', request);
}

export type CreateSupplyCategoryApiRequest = {
    restaurantId: RestaurantId;
    name: string;
};
