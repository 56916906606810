/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { app2 } from 'src/app2';
import { FilterState, FromToFilter } from 'src/components/input/FromToFilter';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import CompanyCreditsBillingData from 'src/scenes/letseatadmin/companyCreditsBilling/CompanyCreditsBillingData';
import CompanyCreditsBillingDataForBenefitPlan from 'src/scenes/letseatadmin/companyCreditsBilling/CompanyCreditsBillingDataForBenefitPlan';
import { useAction } from 'src/utils/react/useAction';

export function CompanyCreditsBillingPage(): React.ReactElement {
    const [filter, setFilter] = useState<FilterState>({
        from: moment().subtract(1, 'week').startOf('isoWeek').toDate(),
        to: moment().subtract(1, 'week').endOf('isoWeek').toDate(),
    });

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Company Credits Billing'));
    }, []);

    const handleLoadCompany = (companyName: any, benefitPlanName: any) => setTitle(`${translate('Company Credits Billing')} > ${companyName} - ${benefitPlanName}`);

    const handleChangeFilter = (filter: { from: Date; to: Date }) => setFilter(filter);

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <FromToFilter filter={filter} onChangeFilter={handleChangeFilter} />
            </Grid>
            <Grid item xs={12}>
                <Switch>
                    <Route
                        path={RoutePaths.ADMIN_COMPANY_CREDITS_BILLING_FOR_BENEFIT_PLAN}
                        render={(props) => <CompanyCreditsBillingDataForBenefitPlan filter={filter} benefitPlanId={props.match.params.benefitPlanId} onLoad={handleLoadCompany} />}
                    />
                    <Route
                        path={RoutePaths.ADMIN_COMPANY_CREDITS_BILLING}
                        render={() => {
                            setTitle(translate('Company Credits Billing'));
                            return <CompanyCreditsBillingData filter={filter} />;
                        }}
                    />
                </Switch>
            </Grid>
        </Grid>
    );
}
