/**
 * @prettier
 */
export const RestaurantPsps = {
    PLEXO: 'PLEXO',
    MERCADO_PAGO: 'MERCADO_PAGO',
    AUTO: 'AUTO',
} as const;

export type RestaurantPsp = typeof RestaurantPsps[keyof typeof RestaurantPsps];
