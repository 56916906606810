/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverId } from 'src/types/Id';

export async function sendSmsToDriverApi(request: DriverApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('driver/sendSmsToDriverApi', request);
}

type DriverApiRequest = {
    driverId: DriverId;
    smsText: string;
};
