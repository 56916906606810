/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function LokiAccessConfiguration({ disabled }: Props): React.ReactElement | null {
    const classes = useStyles();

    const lokiEnabled = useSelector((state) => state.app.restaurant?.lokiEnabled);

    if (!lokiEnabled) return null;

    return (
        <div className={classes.container}>
            <div className={classes.moduleContainer}>{translate('Loki')}</div>
            <div className={classes.permissionsContainer}>
                <FormSwitch
                    name={RolePermissions.LOKI}
                    label={translate('RolePermissions.LOKI')}
                    disabled={disabled}
                    helperText={translate('To use loki tool user has to have the sign in with password permission')}
                />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        borderBottom: `1px solid #c8c8c8`,
    },
    moduleContainer: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '25%',
    },
    permissionsContainer: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
    },
    homeContainer: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '25%',
    },
    radioButton: {
        color: `${theme.palette.primary.main} !important`,
    },
}));

type Props = {
    disabled?: boolean;
};
