/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { UserType } from 'src/constants/UserType';
import type { AwsCognitoUsername, ManagerUserId, RestaurantId } from 'src/types/Id';

export async function findManagerUsersApi(): ApiSauceResponse<ManagerUsersVm> {
    return letseatadminApiMethod('managerUser/findManagerUsersApi');
}

type ManagerUsersVm = {
    managerUsers: Array<ManagerUserVm>;
};

type ManagerUserVm = {
    managerUserId: ManagerUserId;
    username: AwsCognitoUsername;
    userType: UserType;
    restaurants?: Array<{
        restaurantId: RestaurantId;
        name: string;
        ordersCancelled: number;
    }>;
    createdAt: Date;
    modifiedAt: Date;
};
