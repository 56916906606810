/**
 * @prettier
 */
import * as React from 'react';
import { findCompaniesApi } from 'src/api/letseatadmin/company/findCompaniesApi';
import { FormAutocompleteMultiple } from 'src/components/form/FormAutocompleteMultiple';
import type { Variant } from 'src/components/form/FormTypes';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function FormCompaniesAutocompleteMultiple({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, InputProps }: Props): React.ReactElement {
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, companies] = useLoadApi(findCompaniesApi, { restaurantId }, { initialValue: { companies: [] } });

    const options = companies.companies.map((company) => ({ label: company.name, value: company.companyId }));

    return (
        <FormAutocompleteMultiple
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled || loading || !options.length}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: Array<string>;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
};
