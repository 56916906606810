/**
 * @prettier
 */
export const SmsServiceProviders = {
    AWS: 'AWS',
    VOICES: 'VOICES',
    INFOBIP: 'INFOBIP',
    MENSAJERO_DIRECTO: 'MENSAJERO_DIRECTO',
    TELE_QUANTUM: 'TELE_QUANTUM',
} as const;

export type SmsServiceProvider = typeof SmsServiceProviders[keyof typeof SmsServiceProviders];
