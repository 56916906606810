/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { RecipeVm } from 'src/types/RecipeVm';

export async function findRecipesByRestaurantIdApi(request: FindRecipesByRestaurantIdRequest): ApiSauceResponse<FindRecipesByRestaurantIdApiResponse> {
    return letseatmanagerApiMethod('recipe/findRecipesByRestaurantIdApi', request);
}

export type FindRecipesByRestaurantIdRequest = {
    restaurantId: RestaurantId;
};

export type FindRecipesByRestaurantIdApiResponse = {
    recipes: Array<RecipeVm>;
};
