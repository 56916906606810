/**
 * @prettier
 */
import { IconButton, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { changeTheRestaurantApi } from 'src/api/letseatmanager/theRestaurant/changeTheRestaurantApi';
import type { TheRestaurantVm } from 'src/api/letseatmanager/theRestaurant/getTheRestaurantApi';
import { findKioskUserApi } from 'src/api/letseatmanager/userManagement/findKioskUserApi';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { Form } from 'src/components/form/Form';
import { FormNumberStringField } from 'src/components/form/FormNumberStringField';
import { FormPinCode } from 'src/components/form/FormPinCode';
import { FormSoundSelect } from 'src/components/form/FormSoundSelect';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormUrlImageField } from 'src/components/form/FormUrlImageField';
import { getDirtyFormValues } from 'src/components/form/utils/getDirtyFormValues';
import { Tooltip } from 'src/components/Tooltip';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { BannerImageTypes } from 'src/constants/BannerImageType';
import { ImageTypes } from 'src/constants/ImageType';
import { Sounds } from 'src/constants/Sound';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { setPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/setPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { appReducer } from 'src/reducers/appReducer';
import { RestaurantCard } from 'src/scenes/letseatadmin/theRestaurant/RestaurantCard';
import { ManageCoursesButton } from 'src/scenes/letseatmanager/theRestaurant//ManageCoursesButton';
import { AppsUsedAtThePosBusinessDayReportSettings } from 'src/scenes/letseatmanager/theRestaurant/AppsUsedAtTheEndOfTheDayReportSettings';
import { CashRegisterDepositReasonsSettings } from 'src/scenes/letseatmanager/theRestaurant/CashRegisterDepositReasonsSettings';
import { CashRegisterWithdrawReasonsSettings } from 'src/scenes/letseatmanager/theRestaurant/CashRegisterWithdrawReasonsSettings';
import { DiscountCommentsSettings } from 'src/scenes/letseatmanager/theRestaurant/DiscountCommentsSettings';
import { CurrenciesSettings } from 'src/scenes/letseatmanager/theRestaurant/pos/CurrenciesSettings';
import { ProductCancellationReasonsSettings } from 'src/scenes/letseatmanager/theRestaurant/ProductCancellationReasonsSettings';
import { RestaurantInventorySettings } from 'src/scenes/letseatmanager/theRestaurant/RestaurantInventorySettings';
import { RestaurantUserManagerSettings } from 'src/scenes/letseatmanager/theRestaurant/RestaurantUserManagerSettings';
import { migrateImage } from 'src/services/image/migrateImage';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { SentryService } from 'src/services/SentryService';
import { ManagerUserVm } from 'src/types/ManagerUserVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getErrorDescriptionFromResponse } from 'src/utils/error/getErrorDescriptionFromResponse';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { playAlertSound } from 'src/utils/sound/playAlertSound';

export function RestaurantPosSettings({ loading, restaurant, onChangeRestaurant, setIsDirtyForm }: Props): React.ReactElement {
    const classes = useStyles();

    const { reloadRestaurant } = useReloadRestaurant();

    const form = useForm();
    const {
        setValue,
        control,
        formState,
        formState: { dirtyFields },
    } = form;

    const posRestaurantZonesEnabled = useWatch<boolean>({ name: 'posRestaurantZonesEnabled', control });
    const posInterfaceLargerEnabled = useWatch<boolean>({ name: 'posInterfaceLargerEnabled', control });
    const posTakeOrderByCourseEnabled = useWatch<boolean>({ name: 'posTakeOrderByCourseEnabled', control });
    const useQpayTerminalEnabled = useWatch<boolean>({ name: 'useQpayTerminalEnabled', control });
    const posCustomDiscountCommentsEnabled = useWatch<boolean>({ name: 'posCustomDiscountCommentsEnabled', control });
    const kioskBackgroundImageUrl = useWatch<string>({ name: 'kioskBackgroundImageUrl', control });
    const maximumCashAllowedInCashRegisterEnabled = useWatch<boolean>({ name: 'maximumCashAllowedInCashRegisterEnabled', control });

    const [showKioskUser, setShowKioskUser] = useState(false);
    const [kioskUser, setKioskUser] = useState<ManagerUserVm>();
    const [loadingKioskUser, setLoadingKioskUser] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);
    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant?.newMenuManagementEnabled);
    const appLoading = useSelector((state) => state.app.loading);
    const kioskEnabled = useSelector((state) => state.app.restaurant?.kioskEnabled);
    const kitchenDisplayScreenEnabled = useSelector((state) => state.app.restaurant?.kitchenDisplayScreenEnabled);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    useEffect(() => {
        const posInterfaceLargerEnabledFromLocalStorage = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

        if (!loading) {
            form.reset({
                restaurantId: restaurant.restaurantId ?? null,
                posRestaurantZonesEnabled: restaurant.posRestaurantZonesEnabled ?? false,
                posShowTablesEnabled: restaurant.posShowTablesEnabled ?? false,
                posTableRequiredEnabled: restaurant.posTableRequiredEnabled ?? false,
                posCustomersRequiredEnabled: restaurant.posCustomersRequiredEnabled ?? false,
                posCustomerNameRequiredOnTakeAwayOrdersEnabled: restaurant.posCustomerNameRequiredOnTakeAwayOrdersEnabled ?? false,
                posOnlyCashInCashRegisterReportEnabled: restaurant.posOnlyCashInCashRegisterReportEnabled ?? false,
                posPassword: restaurant.posPassword ?? null,
                supplyLimitAlertEnabled: restaurant.supplyLimitAlertEnabled ?? false,
                cashRegisterWithdrawReasons: restaurant.cashRegisterWithdrawReasons ?? null,
                cashRegisterDepositReasons: restaurant.cashRegisterDepositReasons ?? null,
                productCancellationReasons: restaurant.productCancellationReasons ?? null,
                logoImageUrl: restaurant.logoImageUrl ?? null,
                kioskBackgroundImageUrl: restaurant.kioskBackgroundImageUrl ?? null,
                supplyCategoryIds: restaurant.supplyCategoryIds ?? [],
                invoicesWithZeroTaxForTakeAwayOrdersEnabled: restaurant.invoicesWithZeroTaxForTakeAwayOrdersEnabled ?? false,
                posInterfaceLargerEnabled: posInterfaceLargerEnabledFromLocalStorage,
                posTakeOrderByCourseEnabled: restaurant.posTakeOrderByCourseEnabled ?? null,
                posUseMenuItemColorEnabled: restaurant.posUseMenuItemColorEnabled ?? null,
                authorizationToReprintCompletedOrderEnabled: restaurant.authorizationToReprintCompletedOrderEnabled ?? null,
                requestPasswordForClosePosBusinessDayEnabled: restaurant.requestPasswordForClosePosBusinessDayEnabled ?? null,
                moneyBillsPadEnabled: restaurant.moneyBillsPadEnabled ?? null,
                posCustomDiscountCommentsEnabled: restaurant.posCustomDiscountCommentsEnabled ?? null,
                useQpayTerminalEnabled: restaurant.useQpayTerminalEnabled ?? null,
                qpayTerminalCommission: restaurant.qpayTerminalCommission ?? null,
                customDiscountComments: restaurant.customDiscountComments ?? [],
                kioskPayWithCreditCardInCounterEnabled: restaurant.kioskPayWithCreditCardInCounterEnabled ?? null,
                inventoryShowNegative: restaurant.inventoryShowNegative ?? null,
                clockInEmployeeDayShiftEnabled: restaurant.clockInEmployeeDayShiftEnabled ?? null,
                clockInMessage: restaurant.clockInMessage ?? null,
                clockOutMessage: restaurant.clockOutMessage ?? null,
                hideFiscalInformationOnTicket: restaurant.hideFiscalInformationOnTicket ?? false,
                maximumCashAllowedInCashRegisterEnabled: restaurant.maximumCashAllowedInCashRegisterEnabled ?? false,
                maximumCashAllowedInCashRegister: restaurant.maximumCashAllowedInCashRegister ?? null,
                openCashRegisterButtonEnabled: restaurant.openCashRegisterButtonEnabled ?? null,
                currencies: restaurant.currencies ?? null,
                acceptMultipleCurrenciesPaymentEnabled: restaurant.acceptMultipleCurrenciesPaymentEnabled ?? null,
                kitchenDisplayScreenAlertSound: restaurant.kitchenDisplayScreenAlertSound ?? null,
                includeBreakdownIntegrationsInPosBusinessDayEnabled: restaurant.includeBreakdownIntegrationsInPosBusinessDayEnabled ?? null,
            });
        }
    }, [loading]);

    useEffect(() => {
        if (!posRestaurantZonesEnabled) {
            setValue('posShowTablesEnabled', false);
            setValue('posTableRequiredEnabled', false);
            setValue('posCustomersRequiredEnabled', false);
        }
    }, [posRestaurantZonesEnabled]);

    useEffect(() => {
        setIsDirtyForm(Object.keys(dirtyFields).length !== 0);
    }, [formState]);

    useEffect(() => {
        setPosInterfaceLargerEnabledRestaurantSettingInLocalStorage(posInterfaceLargerEnabled);
    }, [posInterfaceLargerEnabled]);

    const onSubmit = async (form: any) => {
        const dirtyFormValues = getDirtyFormValues({ form, dirtyFields });

        if (dirtyFormValues.kioskBackgroundImageUrl) {
            const kioskBackgroundImageUrl = await migrateImage({
                imageUrl: dirtyFormValues.kioskBackgroundImageUrl,
                imageType: ImageTypes.RESTAURANT,
            });

            if (kioskBackgroundImageUrl.error) {
                alert(`Failed to save cause of ${getErrorDescriptionFromResponse(kioskBackgroundImageUrl.migrateResponse)}`);
                return;
            }
        }
        if (dirtyFormValues.logoImageUrl) {
            const logoImageUrl = await migrateImage({
                imageUrl: dirtyFormValues.logoImageUrl,
                imageType: ImageTypes.RESTAURANT,
            });

            if (logoImageUrl.error) {
                alert(`Failed to save cause of ${getErrorDescriptionFromResponse(logoImageUrl.migrateResponse)}`);
                return;
            }
        }

        const response = await changeTheRestaurantApi({
            restaurantId,
            ...dirtyFormValues,
        });

        if (!response.ok) {
            SentryService.logError('Failed to save the restaurant', { response });
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        await reloadRestaurant(restaurantId);
        await onChangeRestaurant();
        showSnackbar({ message: translate('Saved changes') });
    };

    const handleShowKioskUser = async () => {
        setLoadingKioskUser(true);
        const response = await findKioskUserApi({ restaurantId });
        setLoadingKioskUser(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setKioskUser(response.data);
    };

    return (
        <Paper className={classes.paper}>
            <UpdatingContentProgress loading={loading || appLoading} />
            <Form form={form} onSubmit={onSubmit}>
                <div className={classes.formContent}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h1>{translate('Point of sales')}</h1>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} md={12}>
                            <section className={classes.section}>
                                <Grid item xs={6}>
                                    <h1 className={classes.subtitle}>{translate('General')}</h1>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item xs={6} md={6}>
                                        <FormSwitch
                                            name='posInterfaceLargerEnabled'
                                            label={translate('Increase the size of buttons and text at POS')}
                                            tooltip={translate('Enable option if you want increase the size of buttons and text at POS for this device')}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FormPinCode name='posPassword' label={translate('Pos Settings Password')} required={true} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormUrlImageField
                                            name='logoImageUrl'
                                            label={translate('POS login screen image')}
                                            helperText={translate('Enter an URL to an image or drag and drop your image in this field')}
                                        />
                                    </Grid>
                                </Grid>
                            </section>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <section className={classes.section}>
                                <Grid item xs={12}>
                                    <h1 className={classes.subtitle}>{translate('Administration')}</h1>
                                </Grid>
                                {newMenuManagementEnabled && (
                                    <Grid item xs={12} md={6}>
                                        <FormSwitch
                                            name='posUseMenuItemColorEnabled'
                                            label={translate('POS Use Menu Item colors')}
                                            tooltip={translate('Activate this option if you want to use the color of the product in the POS menu item card')}
                                        />
                                    </Grid>
                                )}
                                {useQpayTerminalEnabled && (
                                    <Grid item xs={12} md={6}>
                                        <DeprecatedFormPercentNumberField
                                            name='qpayTerminalCommission'
                                            label={translate('QPAY Terminal Commission')}
                                            tooltip={translate('Define the commission when paying with a Qpay terminal')}
                                            disabled={useQpayTerminalEnabled}
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <h1 className={classes.subtitle}>{translate('Tables')}</h1>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormSwitch
                                        name='posCustomersRequiredEnabled'
                                        label={translate('POS Number Of Customers Required Enabled')}
                                        tooltip={translate('Activate the option if you want the number of diners for the table to be required')}
                                        disabled={!posRestaurantZonesEnabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormSwitch
                                        name='posRestaurantZonesEnabled'
                                        label={translate('POS Restaurant Areas Enabled')}
                                        tooltip={translate('Activate this option if you want to create or edit areas and tables in the Orders to eat here section.')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormSwitch
                                        name='posShowTablesEnabled'
                                        label={translate('POS Show Tables When Order Enabled')}
                                        tooltip={translate('Enable the display of the selection of tables in the order creation process')}
                                        disabled={!posRestaurantZonesEnabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormSwitch
                                        name='posTableRequiredEnabled'
                                        label={translate('POS Table Required Enabled')}
                                        tooltip={translate('Activate the option if you want the table selection to be required, show the table selection when creating the order')}
                                        disabled={!posRestaurantZonesEnabled}
                                    />
                                </Grid>
                            </section>
                        </Grid>

                        <Grid item xs={6}>
                            <section className={classes.section}>
                                <Grid item xs={12}>
                                    <h1 className={classes.subtitle}>{translate('Cash Register')}</h1>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='openCashRegisterButtonEnabled'
                                        label={translate('Show button to open cash register')}
                                        tooltip={translate('Activate this option if you want to show a button to open the cash register')}
                                    />
                                </Grid>
                                {maximumCashAllowedInCashRegisterEnabled && (
                                    <Grid item xs={12}>
                                        <FormNumberStringField
                                            required={maximumCashAllowedInCashRegisterEnabled}
                                            name={'maximumCashAllowedInCashRegister'}
                                            label={translate('Maximum cash on cash register')}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <h1 className={classes.subtitle}>{translate('Closing of the day and cash cut')}</h1>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='requestPasswordForClosePosBusinessDayEnabled'
                                        label={translate('Request password to make closing day')}
                                        tooltip={translate('Activate this option if you require the POS password to be requested to closing day')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='posOnlyCashInCashRegisterReportEnabled'
                                        label={translate('POS Cash Count Only in Cash Register')}
                                        tooltip={translate('Activate the option if you want to count only the cash in boxes, without taking card payments into account')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name='includeBreakdownIntegrationsInPosBusinessDayEnabled'
                                        label={translate('Include integrations in cash cut  and day-end closing')}
                                        tooltip={translate(
                                            'The expected amounts for the integrations will be shown in the cash cut-off and closing of the day and will be displayed in the closing of the day report'
                                        )}
                                    />
                                </Grid>
                            </section>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <section className={classes.section}>
                                <Grid item xs={12}>
                                    <h1 className={classes.subtitle}>{translate('Restaurant')}</h1>
                                </Grid>

                                <Grid container spacing={3}>
                                    {posTakeOrderByCourseEnabled && (
                                        <Grid item xs={12} md={6}>
                                            <ManageCoursesButton />
                                        </Grid>
                                    )}
                                    <Grid item xs={6} md={6}>
                                        <FormSwitch name='moneyBillsPadEnabled' label={translate('Enable money bills numeric pad')} />
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <FormSwitch name='posCustomDiscountCommentsEnabled' label={translate('POS Customizable discount comments')} disabled={loading} />
                                    </Grid>

                                    <Grid item xs={6} md={6}>
                                        <FormSwitch
                                            name='hideFiscalInformationOnTicket'
                                            label={translate('Hide tax information on the ticket')}
                                            tooltip={translate('Hide company data such as telephone address etc. on the printed ticket')}
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={6}>
                                        <FormSwitch
                                            name='authorizationToReprintCompletedOrderEnabled'
                                            label={translate('Request authorization to reprint completed order')}
                                            tooltip={translate('Enable the option if you want to request a POS password to reprint an order that has already been completed')}
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={6}>
                                        <FormSwitch name='invoicesWithZeroTaxForTakeAwayOrdersEnabled' label={translate('Tax Free invoices for TakeAway orders')} />
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <FormSwitch
                                            name='posCustomerNameRequiredOnTakeAwayOrdersEnabled'
                                            label={translate('POS Request customer name on take away orders enabled')}
                                            tooltip={translate('Enable the option if you want the system to prompt for a name for takeout orders created at the POS')}
                                        />
                                    </Grid>
                                    {posCustomDiscountCommentsEnabled && (
                                        <Grid item xs={6} md={6}>
                                            <DiscountCommentsSettings onSuccess={onChangeRestaurant} />
                                        </Grid>
                                    )}
                                </Grid>
                            </section>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h1>{translate('Personalization')}</h1>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            {posEnabled && <CashRegisterDepositReasonsSettings onSuccess={() => onChangeRestaurant()} />}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {posEnabled && <CashRegisterWithdrawReasonsSettings onSuccess={() => onChangeRestaurant()} />}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ProductCancellationReasonsSettings onSuccess={() => onChangeRestaurant()} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AppsUsedAtThePosBusinessDayReportSettings onSuccess={() => onChangeRestaurant()} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <CurrenciesSettings onSuccess={onChangeRestaurant} />
                        </Grid>

                        {useQpayTerminalEnabled && (
                            <Grid item xs={12} md={6}>
                                <DeprecatedFormPercentNumberField
                                    name='qpayTerminalCommission'
                                    label={translate('QPAY Terminal Commission')}
                                    tooltip={translate('Define the commission when paying with a Qpay terminal')}
                                    disabled={useQpayTerminalEnabled}
                                />
                            </Grid>
                        )}

                        <RestaurantUserManagerSettings />
                        <RestaurantInventorySettings onChangeRestaurant={onChangeRestaurant} />

                        <Grid container spacing={3}>
                            {kioskEnabled && (
                                <>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <h1>{translate('Kiosk Settings')}</h1>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <RestaurantCard name={translate('Kiosk Background Image')} imageUrl={kioskBackgroundImageUrl} bannerImageType={BannerImageTypes.PREVIEW} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormSwitch
                                            name='kioskPayWithCreditCardInCounterEnabled'
                                            label={translate('Pay with credit card in the counter')}
                                            tooltip={translate('Enabled option to pay in the counter with card when restaurant has payment with credit card enabled for kiosk')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {showKioskUser && !loadingKioskUser && (
                                            <div className={classes.kioskInfo}>
                                                <span className={classes.text}>
                                                    {translate('Username')}: {kioskUser?.username}
                                                </span>
                                                <span className={classes.text}>
                                                    {translate('Password')}: {kioskUser?.password}
                                                </span>
                                            </div>
                                        )}
                                        {true && <Loader size={12} loading={true} />}
                                        {!showKioskUser && (
                                            <div className={classes.titleContainer}>
                                                <span className={classes.text}>{translate('Show kiosk User')}</span>
                                                <Tooltip text={translate('View hidden kiosk user credentials')} />
                                            </div>
                                        )}
                                        <IconButton
                                            onClick={() => {
                                                handleShowKioskUser();
                                                setShowKioskUser(!showKioskUser);
                                            }}
                                            style={{ marginLeft: 10 }}
                                        >
                                            {showKioskUser ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Grid container spacing={3}>
                            {kitchenDisplayScreenEnabled && (
                                <>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <h1>{translate('Kitchen Display Screen Settings')}</h1>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormSoundSelect
                                            name='kitchenDisplayScreenAlertSound'
                                            required={kitchenDisplayScreenEnabled}
                                            defaultValue={Sounds.PIDE_DIRECTO}
                                            label={translate('Alert Sound for KDS')}
                                            onChange={(sound) => playAlertSound(sound)}
                                            tooltip={translate('Select Alert sound for incoming orders when you are on kitchen display screen')}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </div>

                <Grid container justify='center' spacing={3}>
                    <Grid item>
                        <FormSubmitButton text={translate('Change')} disabled={loading || appLoading} classes={{ container: classes.submitButton }} />
                    </Grid>
                </Grid>
            </Form>
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
    },
    formContent: {
        padding: theme.spacing(3),
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    titleContainer: {
        display: 'flex',
    },
    sectionTitle: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
    },
    paymentMethod: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    secondaryButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        padding: '5px 10px',
    },
    certificateContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    kioskInfo: {
        display: 'flex',
        flexDirection: 'column',
    },
    submitButton: {
        zIndex: 1000,
    },
    section: {
        width: '100%',
        borderRadius: 5,
        border: `1px solid ${theme.palette.secondary.dark}`,
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        gap: 20,
        marginTop: 20,
        marginBottom: 20,
    },
}));

type Props = {
    loading: boolean;
    restaurant: TheRestaurantVm;
    onChangeRestaurant: any;
    setIsDirtyForm: any;
};
