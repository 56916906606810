/**
 * @prettier
 */
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Text } from 'src/components/Text';
import { SimpleTerminalIcon } from 'src/icons/SimpleTerminalIcon';
import type { PaymentTerminalVm } from 'src/types/PaymentTerminalVm';
import { classNames } from 'src/utils/react/classNames';

export function PaymentTerminalItem({ paymentTerminal, onSelectPaymentTerminal, isSelected }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();

    const handleClick = () => {
        onSelectPaymentTerminal(paymentTerminal);
    };

    return (
        <Button classes={{ button: classNames(classes.paymentTerminalItem, isSelected ? classes.paymentTerminalItemSelected : '') }} onClick={handleClick}>
            <div className={classes.paymentTerminalTitle}>
                <SimpleTerminalIcon />
                <Text size='large' variant='semiBold'>
                    {paymentTerminal?.deviceName}
                </Text>
            </div>
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    paymentTerminalItem: {
        backgroundColor: 'transparent',
        border: '1px solid #D9D9D9',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: 12,
        cursor: 'pointer',
        width: '100%',
        padding: '12px',
        height: 'fit-content',
        minHeight: 120,
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: `${theme.palette.primary.main}20`,
        },
    },
    paymentTerminalTitle: {
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        gap: 8,
    },
    icon: {
        height: 24,
        maxHeight: 24,
        width: 24,
        maxWidth: 24,
    },
    paymentTerminalItemSelected: {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: `${theme.palette.primary.main}20`,
    },
}));

type Props = {
    paymentTerminal: PaymentTerminalVm;
    onSelectPaymentTerminal: any;
    isSelected: boolean;
};
