/**
 * @prettier
 *
 */
import { makeStyles } from '@material-ui/core';
import { Button, Dialog, DialogActions, DialogContent, Text } from '@pidedirecto/ui';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';

export function CustomAlertDialog({ open, title, text, secondsToAccept, onClose }: Props): React.ReactElement {
    const classes = useStyles();

    const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
    const [secondsLeft, setSecondsLeft] = useState<number>(60);

    useEffect(() => {
        if (open) {
            setSecondsLeft(secondsToAccept);
        }
    }, [open]);

    useEffect(() => {
        if (secondsLeft === undefined) return;
        if (secondsLeft === 0) {
            setConfirmButtonDisabled(false);
            setSecondsLeft(0);
            return;
        }
        const timeout = setTimeout(() => {
            setSecondsLeft(secondsLeft - 1);
        }, 1 * SECONDS);
        return () => clearTimeout(timeout);
    }, [secondsLeft]);

    return (
        <Dialog classes={{ dialog: classes.dialog }} open={open}>
            <DialogContent className={classes.container}>
                <div className={classes.titleContainer}>
                    <Text variant='title'>{title}</Text>
                </div>
                <div className={classes.messageContainer}>
                    <Text className={classes.message}>{text}</Text>
                </div>
                <DialogActions className={classes.buttonsContainer}>
                    <div className={classes.buttonsContainer}>
                        <Button disabled={confirmButtonDisabled} onClick={onClose}>
                            {!!secondsLeft && ` ${secondsLeft}  `} {translate('Understood')}
                        </Button>
                    </div>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        borderRadius: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        maxWidth: '80%',
        maxHeight: '95%',
    },
    dialogContainer: {
        borderRadius: 12,
        width: '80%',
        maxWidth: '80%',
        maxHeight: '95%',
        height: 'fit-content',
    },
    titleContainer: {
        marginTop: 20,
        marginBottom: 20,

        justifyContent: 'center',
    },

    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '20vw',
        minHeight: '70vh',
    },

    message: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        textAlign: 'center',
    },
    messageContainer: {
        marginTop: 20,
        marginBottom: 20,

        justifyContent: 'center',
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        justifyContent: 'center',
        gap: 20,
    },
}));

type Props = {
    open: boolean;
    title: string;
    text: string;
    secondsToAccept: number;
    onClose: () => void;
};
