/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { CustomerVm } from 'src/types/CustomerVm';
import type { EmailAddress, RestaurantCustomerId, RestaurantId } from 'src/types/Id';

export async function createCustomerFromRestaurantCustomerApi(request: CreateCustomerFromRestaurantCustomerApiRequest): ApiSauceResponse<CustomerVm> {
    return letseatmanagerApiMethod('customer/createCustomerFromRestaurantCustomerApi', request);
}

type CreateCustomerFromRestaurantCustomerApiRequest = {
    restaurantCustomerId: RestaurantCustomerId;
    restaurantId: RestaurantId;
    mobileNumber: string;
    email?: EmailAddress;
    name?: string;
    lastName?: string;
};
