/**
 * @prettier
 */
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { DatePicker } from '@material-ui/pickers';
import { Button } from '@pidedirecto/ui';
import moment from 'moment-timezone';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { findDriverOrdersApi } from 'src/api/letseatadmin/driver/findDriverOrdersApi';
import { translate } from 'src/i18n/translate';
import { OrdersTable } from 'src/scenes/letseatadmin/order/OrdersTable';
import type { DriverId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function DriverOrders({ driverId }: Props): React.ReactElement {
    const pageLimit = 100;

    const [from, setFrom] = useState<Date>(moment().startOf('day').toDate());
    const [to, setTo] = useState<Date>(moment().endOf('day').toDate());
    const [orders, setOrders] = useState<Array<OrderVm>>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadDriverOrders();
    }, [driverId, from, to]);

    const loadDriverOrders = async () => {
        if (!driverId) return;

        setLoading(true);
        let currentDriverOrders: Array<OrderVm> = [];
        let page = 1;

        do {
            const response = await findDriverOrdersApi({
                from,
                to,
                driverId,
                page,
                pageLimit,
            });

            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }

            currentDriverOrders = currentDriverOrders.concat(response.data.orders);
            if (response.data.orders.length === pageLimit) page++;
            else break;
        } while (true);

        setLoading(false);
        setOrders(currentDriverOrders);
    };

    const handleFrom = (from: Date) => setFrom(from);

    const handleTo = (to: Date) => setTo(to);

    const handleSubmit = async () => await loadDriverOrders();

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Card style={{ padding: 24 }}>
                    <Grid container direction='row' spacing={3} alignItems='center'>
                        <Grid item>
                            <DatePicker variant='inline' label={translate('From')} value={from} onChange={handleFrom as any} format='ll' autoOk />
                        </Grid>
                        <Grid item>
                            <DatePicker variant='inline' label={translate('To')} value={to} onChange={handleTo as any} format='ll' autoOk />
                        </Grid>
                        <Grid item>
                            <Button type='submit' onClick={handleSubmit} disabled={loading}>
                                {loading ? translate('Searching').toUpperCase() : translate('Search').toUpperCase()}
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <OrdersTable orders={orders ?? []} totalOrders={orders.length ?? 0} loading={loading} onClickRefresh={loadDriverOrders} />
            </Grid>
        </Grid>
    );
}

type Props = {
    driverId: DriverId;
};
