/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { EmailTemplateId } from 'src/types/Id';

export async function removeEmailTemplateApi(request: RemoveEmailTemplateApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('emailTemplate/removeEmailTemplateApi', request);
}

export type RemoveEmailTemplateApiRequest = {
    emailTemplateId: EmailTemplateId;
};
