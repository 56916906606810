/**
 * @prettier
 */
import * as React from 'react';
import { usePosPaymentsSubscriptionUpdatesDeprecated } from 'src/services/pos/posPaymentMethods/usePosPaymentsSubscriptionUpdatesDeprecated';

/**
 * @deprecated
 */
export function PosPaymentsSubscriptionUpdatesDeprecated(): React.ReactElement | null {
    usePosPaymentsSubscriptionUpdatesDeprecated();

    return null;
}
