/**
 * @prettier
 */
import { RoutePaths } from 'src/constants/RoutePath';
import type { RestaurantId } from 'src/types/Id';
import { RegExps } from 'src/utils/RegExps';

export function openRestaurantBillingPageInNewTab(restaurantId?: RestaurantId): void {
    if (!restaurantId) return;
    window.open(`${RoutePaths.ADMIN_RESTAURANT_BILLING_FOR_RESTAURANT.replace(`:restaurantId(${RegExps.uuid})`, restaurantId)}?restaurant=${restaurantId}`, '_blank');
}
