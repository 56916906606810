/**
 * @prettier
 */
import * as React from 'react';
import { SwipeDrawer } from 'src/components/SwipeDrawer';
import { MenuItem } from 'src/scenes/letseatmanager/menu/menuItem/MenuItem';
import type { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export function MenuItemSection({ menuItem, onClose }: Props): React.ReactElement {
    const isSmallScreen = useIsSmallScreen();

    if (isSmallScreen) {
        return (
            <SwipeDrawer open={true} onClose={onClose} avoidSwipeInDrawerContent>
                <MenuItem menuItem={menuItem} />
            </SwipeDrawer>
        );
    }

    return <MenuItem menuItem={menuItem} />;
}

type Props = {
    menuItem: ManagerMenuItemVm;
    onClose?: any;
};
