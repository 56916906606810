/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { QuickActionId } from 'src/types/Id';

export async function enableQuickActionApi(request: EnableQuickActionApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('quickAction/enableQuickActionApi', request);
}

type EnableQuickActionApiRequest = {
    quickActionId: QuickActionId;
};
