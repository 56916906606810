/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { changeDeliveryZoneApi } from 'src/api/letseatmanager/deliveryZones/changeDeliveryZoneApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormColorPicker } from 'src/components/form/FormColorPicker';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import type { DeliveryZoneVm } from 'src/types/RestaurantVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function ChangeDeliveryZoneDialog({ open, onClose, deliveryZone }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const { reloadRestaurant } = useReloadRestaurant();

    useEffect(() => {
        if (open) form.reset(deliveryZone);
    }, [open]);

    const onSubmit = async (form: any) => {
        const response = await changeDeliveryZoneApi({
            ...requireValue(deliveryZone),
            restaurantId: restaurantId,
            name: form.name,
            price: form.price,
            color: form.color,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        await reloadRestaurant(restaurantId);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} title={translate('Change Delivery Zones')}>
            <Form className={classes.formContainer} form={form} onSubmit={onSubmit}>
                <FormTextField name='name' label={translate('Name')} required />
                <FormTextField name='price' label={translate('Price')} required />
                <div className={classes.colors}>
                    <FormColorPicker name='color' title={translate('Zone Color')} />
                </div>
                <DialogActions>
                    <Button secondary disabled={isSubmitting} onClick={onClose}>
                        {translate('Cancel')}
                    </Button>
                    <FormSubmitButton text={isSubmitting ? translate('Changing') : translate('Change')} />
                </DialogActions>
            </Form>
        </Dialog>
    );
}
const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    colors: {
        marginLeft: 16,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    deliveryZone?: DeliveryZoneVm;
};
