/**
 * @prettier
 */
import { useContext } from 'react';
import { PasswordDialogContext } from 'src/providers/PasswordDialogProvider';

export function useAskForPassword(): AskForPassword {
    const passwordDialogContext = useContext(PasswordDialogContext);

    return passwordDialogContext.askForPassword;
}

type AskForPassword = (arg1: Options) => Promise<boolean>;

type Options = {
    password?: string;
};
