/**
 * @prettier
 */
import * as React from 'react';

export function DidiCircleIcon({ title, width, height }: Props): React.ReactElement {
    return (
        <svg width={width || '40'} height={height || '40'} viewBox={`0 0 41 41`} fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <circle cx='20' cy='20' r='19.25' fill='#FF7A45' stroke='white' strokeWidth='1.5' />
            <path
                d='M27.2091 16.8527C27.2091 18.3112 26.9175 19.5907 26.3344 20.6912C25.7513 21.8049 24.9173 22.6601 23.8325 23.2568C22.7612 23.8667 21.5136 24.1716 20.0898 24.1716C18.6659 24.1716 17.4183 23.8667 16.347 23.2568C15.2893 22.6601 14.4689 21.8049 13.8858 20.6912C13.3027 19.5907 13.0111 18.3112 13.0111 16.8527V12H24.8089V14.7844H27.2091V16.8527ZM15.4317 16.7533C15.4317 18.2118 15.8385 19.3388 16.6521 20.1343C17.4658 20.9299 18.6117 21.3276 20.0898 21.3276C21.5679 21.3276 22.7205 20.9299 23.5477 20.1343C24.3885 19.3388 24.8089 18.2118 24.8089 16.7533V14.7844H15.4317V16.7533Z'
                fill='white'
            />
            <path
                d='M12.0299 25.8964C12.3395 25.8964 12.611 25.9593 12.8446 26.0852C13.081 26.211 13.2625 26.391 13.3891 26.6251C13.5185 26.8563 13.5833 27.1256 13.5833 27.4329C13.5833 27.7401 13.5185 28.0094 13.3891 28.2406C13.2625 28.4688 13.081 28.6459 12.8446 28.7717C12.611 28.8976 12.3395 28.9605 12.0299 28.9605H11V25.8964H12.0299ZM12.0088 28.4381C12.3184 28.4381 12.5576 28.3503 12.7264 28.1747C12.8952 27.9991 12.9797 27.7518 12.9797 27.4329C12.9797 27.1139 12.8952 26.8651 12.7264 26.6866C12.5576 26.5051 12.3184 26.4144 12.0088 26.4144H11.5909V28.4381H12.0088Z'
                fill='white'
            />
            <path
                d='M14.3253 26.2388C14.2212 26.2388 14.134 26.2052 14.0636 26.1379C13.9961 26.0676 13.9623 25.9813 13.9623 25.8789C13.9623 25.7764 13.9961 25.6916 14.0636 25.6243C14.134 25.554 14.2212 25.5189 14.3253 25.5189C14.4294 25.5189 14.5152 25.554 14.5828 25.6243C14.6531 25.6916 14.6883 25.7764 14.6883 25.8789C14.6883 25.9813 14.6531 26.0676 14.5828 26.1379C14.5152 26.2052 14.4294 26.2388 14.3253 26.2388ZM14.6166 26.5286V28.9605H14.0256V26.5286H14.6166Z'
                fill='white'
            />
            <path
                d='M15.0484 27.7357C15.0484 27.4899 15.0949 27.2719 15.1877 27.0817C15.2834 26.8914 15.4129 26.7451 15.5761 26.6427C15.7393 26.5403 15.9208 26.489 16.1206 26.489C16.2725 26.489 16.4175 26.5242 16.5554 26.5944C16.6932 26.6617 16.803 26.7524 16.8846 26.8666V25.7121H17.484V28.9605H16.8846V28.6005C16.8114 28.7205 16.7087 28.8171 16.5765 28.8903C16.4442 28.9634 16.2908 29 16.1164 29C15.9194 29 15.7393 28.9473 15.5761 28.842C15.4129 28.7366 15.2834 28.5888 15.1877 28.3986C15.0949 28.2055 15.0484 27.9845 15.0484 27.7357ZM16.8888 27.7445C16.8888 27.5953 16.8607 27.468 16.8044 27.3626C16.7481 27.2543 16.6721 27.1724 16.5765 27.1168C16.4808 27.0583 16.3781 27.029 16.2683 27.029C16.1586 27.029 16.0573 27.0568 15.9644 27.1124C15.8715 27.168 15.7956 27.2499 15.7365 27.3582C15.6802 27.4636 15.6521 27.5894 15.6521 27.7357C15.6521 27.8821 15.6802 28.0108 15.7365 28.122C15.7956 28.2303 15.8715 28.3137 15.9644 28.3723C16.0601 28.4308 16.1614 28.4601 16.2683 28.4601C16.3781 28.4601 16.4808 28.4323 16.5765 28.3767C16.6721 28.3181 16.7481 28.2362 16.8044 28.1308C16.8607 28.0225 16.8888 27.8938 16.8888 27.7445Z'
                fill='white'
            />
            <path
                d='M18.3608 26.2388C18.2567 26.2388 18.1695 26.2052 18.0991 26.1379C18.0316 26.0676 17.9978 25.9813 17.9978 25.8789C17.9978 25.7764 18.0316 25.6916 18.0991 25.6243C18.1695 25.554 18.2567 25.5189 18.3608 25.5189C18.4649 25.5189 18.5508 25.554 18.6183 25.6243C18.6887 25.6916 18.7238 25.7764 18.7238 25.8789C18.7238 25.9813 18.6887 26.0676 18.6183 26.1379C18.5508 26.2052 18.4649 26.2388 18.3608 26.2388ZM18.6521 26.5286V28.9605H18.0611V26.5286H18.6521Z'
                fill='white'
            />
            <path d='M20.9328 25.9008V26.2257H19.6538V27.2573H20.6922V27.5821H19.6538V28.9605H19.2697V27.4307V25.9008H20.9328Z' fill='white' />
            <path
                d='M22.4055 29C22.1888 29 21.9919 28.9488 21.8146 28.8464C21.6401 28.7439 21.5022 28.5991 21.4009 28.4118C21.3024 28.2215 21.2532 28.0021 21.2532 27.7533C21.2532 27.5075 21.3038 27.2909 21.4051 27.1036C21.5093 26.9134 21.65 26.7685 21.8272 26.669C22.0045 26.5666 22.2029 26.5154 22.4224 26.5154C22.6419 26.5154 22.8403 26.5666 23.0176 26.669C23.1948 26.7685 23.3341 26.9119 23.4354 27.0992C23.5396 27.2865 23.5916 27.5045 23.5916 27.7533C23.5916 28.0021 23.5382 28.2215 23.4312 28.4118C23.3271 28.5991 23.185 28.7439 23.0049 28.8464C22.8248 28.9488 22.625 29 22.4055 29ZM22.4055 28.6488C22.5434 28.6488 22.6728 28.6152 22.7939 28.5479C22.9149 28.4805 23.0119 28.3796 23.0851 28.245C23.1611 28.1103 23.1991 27.9465 23.1991 27.7533C23.1991 27.5602 23.1625 27.3963 23.0893 27.2617C23.0162 27.127 22.9205 27.0275 22.8023 26.9631C22.6841 26.8958 22.5561 26.8622 22.4182 26.8622C22.2775 26.8622 22.148 26.8958 22.0298 26.9631C21.9145 27.0275 21.8216 27.127 21.7513 27.2617C21.6809 27.3963 21.6457 27.5602 21.6457 27.7533C21.6457 27.9494 21.6795 28.1147 21.747 28.2493C21.8174 28.384 21.9103 28.4849 22.0256 28.5522C22.141 28.6166 22.2676 28.6488 22.4055 28.6488Z'
                fill='white'
            />
            <path
                d='M25.1055 29C24.8888 29 24.6918 28.9488 24.5145 28.8464C24.3401 28.7439 24.2022 28.5991 24.1009 28.4118C24.0024 28.2215 23.9531 28.0021 23.9531 27.7533C23.9531 27.5075 24.0038 27.2909 24.1051 27.1036C24.2092 26.9134 24.3499 26.7685 24.5272 26.669C24.7045 26.5666 24.9029 26.5154 25.1224 26.5154C25.3419 26.5154 25.5402 26.5666 25.7175 26.669C25.8948 26.7685 26.0341 26.9119 26.1354 27.0992C26.2395 27.2865 26.2916 27.5045 26.2916 27.7533C26.2916 28.0021 26.2381 28.2215 26.1312 28.4118C26.0271 28.5991 25.885 28.7439 25.7049 28.8464C25.5248 28.9488 25.325 29 25.1055 29ZM25.1055 28.6488C25.2434 28.6488 25.3728 28.6152 25.4938 28.5479C25.6148 28.4805 25.7119 28.3796 25.7851 28.245C25.861 28.1103 25.899 27.9465 25.899 27.7533C25.899 27.5602 25.8625 27.3963 25.7893 27.2617C25.7161 27.127 25.6204 27.0275 25.5023 26.9631C25.3841 26.8958 25.256 26.8622 25.1181 26.8622C24.9774 26.8622 24.848 26.8958 24.7298 26.9631C24.6144 27.0275 24.5216 27.127 24.4512 27.2617C24.3809 27.3963 24.3457 27.5602 24.3457 27.7533C24.3457 27.9494 24.3795 28.1147 24.447 28.2493C24.5174 28.384 24.6102 28.4849 24.7256 28.5522C24.841 28.6166 24.9676 28.6488 25.1055 28.6488Z'
                fill='white'
            />
            <path
                d='M26.6531 27.7489C26.6531 27.5031 26.701 27.288 26.7966 27.1036C26.8923 26.9163 27.0232 26.7715 27.1892 26.669C27.358 26.5666 27.5466 26.5154 27.7548 26.5154C27.9349 26.5154 28.1023 26.5593 28.2571 26.6471C28.4119 26.732 28.5301 26.8446 28.6117 26.9851V25.7121H29V28.9605H28.6117V28.5083C28.5357 28.6517 28.4231 28.7703 28.274 28.8639C28.1248 28.9546 27.9504 29 27.7506 29C27.5452 29 27.358 28.9473 27.1892 28.842C27.0232 28.7366 26.8923 28.5888 26.7966 28.3986C26.701 28.2084 26.6531 27.9918 26.6531 27.7489ZM28.6117 27.7533C28.6117 27.5719 28.5765 27.4138 28.5061 27.2792C28.4358 27.1446 28.3401 27.0422 28.2191 26.9719C28.1009 26.8988 27.9701 26.8622 27.8266 26.8622C27.683 26.8622 27.5522 26.8973 27.434 26.9675C27.3158 27.0378 27.2215 27.1402 27.1512 27.2748C27.0808 27.4094 27.0457 27.5675 27.0457 27.7489C27.0457 27.9333 27.0808 28.0942 27.1512 28.2318C27.2215 28.3664 27.3158 28.4703 27.434 28.5435C27.5522 28.6137 27.683 28.6488 27.8266 28.6488C27.9701 28.6488 28.1009 28.6137 28.2191 28.5435C28.3401 28.4703 28.4358 28.3664 28.5061 28.2318C28.5765 28.0942 28.6117 27.9347 28.6117 27.7533Z'
                fill='white'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    width?: number;
    height?: number;
};
