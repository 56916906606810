/**
 * @prettier
 */
import { RoutePaths } from 'src/constants/RoutePath';
import type { RestaurantId } from 'src/types/Id';

export function openRestaurantPageInNewTab(restaurantId?: RestaurantId): void {
    if (!restaurantId) return;
    window.open(`${RoutePaths.THE_RESTAURANT}?restaurant=${restaurantId}`, '_blank');
}
