/**
 * @prettier
 */
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function FromToPaymentDateFilter({ filter, onChangeFilter }: Props): React.ReactElement {
    const handleChangeFromDate = (date: any) => {
        onChangeFilter({
            ...filter,
            from: moment(date).toDate(),
        });
    };

    const handleChangeToDate = (date: any) => {
        onChangeFilter({
            ...filter,
            to: moment(date).toDate(),
        });
    };

    const handleChangePaymentDate = (date: any) => {
        onChangeFilter({
            ...filter,
            paymentDate: moment(date).toDate(),
        });
    };

    return (
        <Card style={{ padding: 24 }}>
            <Grid container direction='row' spacing={3} alignItems='center'>
                <Grid item>
                    <DatePicker variant='inline' label={translate('From')} value={filter.from} onChange={handleChangeFromDate} format='ll' autoOk />
                </Grid>
                <Grid item>
                    <DatePicker variant='inline' label={translate('To')} value={filter.to} onChange={handleChangeToDate} format='ll' autoOk />
                </Grid>
                <Grid item>
                    <DatePicker variant='inline' label={translate('Payment Date')} value={filter.paymentDate} onChange={handleChangePaymentDate} format='ll' autoOk />
                </Grid>
            </Grid>
        </Card>
    );
}

type Props = {
    filter: FilterState;
    onChangeFilter: (arg1: { from: Date; to: Date; paymentDate: Date }) => any;
};

export type FilterState = {
    from: Date;
    to: Date;
    paymentDate: Date;
};
