/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CardBrand } from 'src/constants/CardBrand';
import type { CardPriority } from 'src/constants/CardPriority';
import type { FacturamaPaymentForm } from 'src/constants/FacturamaPaymentForm';
import type { FacturamaPaymentMethod } from 'src/constants/FacturamaPaymentMethod';
import type { PaymentRejectReason } from 'src/constants/PaymentRejectReason';
import type { SubscriptionPaymentErrorType } from 'src/constants/SubscriptionPaymentErrorType';
import type { SubscriptionPaymentMethod } from 'src/constants/SubscriptionPaymentMethod';
import type { SubscriptionPlan } from 'src/constants/SubscriptionPlan';
import type { SubscriptionStatus } from 'src/constants/SubscriptionStatus';
import type { BrandId, CardId, FinanceReference, RestaurantId, SubscriptionId } from 'src/types/Id';

export async function getSubscriptionApi(request: GetSubscriptionApiRequest): ApiSauceResponse<SubscriptionVm> {
    return letseatadminApiMethod('subscription/getSubscriptionApi', request);
}

export type GetSubscriptionApiRequest = {
    subscriptionId: SubscriptionId;
};

export type SubscriptionVm = {
    subscriptionId: SubscriptionId;
    restaurantId: RestaurantId;
    restaurantIds: Array<RestaurantId>;
    brandId?: BrandId;
    brandName?: string;
    restaurantNames: Array<string>;
    rfc: string;
    businessName?: string;
    managerMessage?: string;
    comment?: string;
    invoiceDescription?: string;
    subscriptionPaymentMethod: SubscriptionPaymentMethod;
    status: SubscriptionStatus;
    plan: SubscriptionPlan;
    cards?: Array<{
        cardId: CardId;
        cardNumber: string;
        expiry: string;
        brand: CardBrand;
        cardPriority: CardPriority;
    }>;
    payPerUseRange1PaymentAmount: string;
    payPerUseRange2PaymentAmount: string;
    payPerUseRangeMaxPaymentAmount: string;
    financeReference?: FinanceReference;
    financeReferenceEnabled?: boolean;
    paymentAmount: string;
    fixedInvoiceValuesSubscription?: FixedInvoiceValues;
    fixedInvoiceValuesCommission?: FixedInvoiceValues;
    fixedInvoiceValuesApplication?: FixedInvoiceValues;
    fixedInvoiceValuesDeliveries?: FixedInvoiceValues;
    previousPaymentAmount?: string;
    previousPaymentDate?: Date;
    previousPaymentPaidAt?: Date;
    nextPaymentAmount?: string;
    nextPaymentDate?: Date;
    startServiceDate: Date;
    endServiceDate?: Date;
    paymentErrorType?: SubscriptionPaymentErrorType;
    paymentRejectReason?: PaymentRejectReason;
    createdAt: Date;
    modifiedAt: Date;
};

export type FixedInvoiceValues = {
    fixedPaymentAmount?: string;
    fixedInvoiceDescription?: string;
    fixedPaymentMethod?: FacturamaPaymentMethod;
    fixedPaymentForm?: FacturamaPaymentForm;
};
