/**
 * @prettier
 */
import { createStore } from '@pidedirecto/ui/hooks';
import { CartItemVm } from 'src/types/CartItemVm';
import { OrderId } from 'src/types/Id';

export const [useMoveOrderItemsBetweenOrdersStore, useMoveOrderItemsBetweenOrdersActions] = createStore<State, MoveOrderItemsWizardActions>({
    initialState: {
        orderItemsToMove: [],
        remainingOrderItems: [],
        toOrderId: undefined,
        moveOrderItemsBetweenOrdersDialogState: { open: false },
        selectOrderToMoveOrderItemsDialogState: { open: false },
    },
    actions: {
        serRemainingOrderItems: (state: State, remainingOrderItems) => {
            state.remainingOrderItems = remainingOrderItems;
        },
        setOrderItemsToMove: (state: State, orderItemsToMove) => {
            state.orderItemsToMove = orderItemsToMove;
        },
        setToOrderId: (state: State, toOrderId) => {
            state.toOrderId = toOrderId;
        },
        openMoveOrderItemsBetweenOrdersDialog: (state: State) => {
            state.moveOrderItemsBetweenOrdersDialogState.open = true;
        },
        closeMoveOrderItemsBetweenOrdersDialog: (state: State) => {
            state.moveOrderItemsBetweenOrdersDialogState.open = false;
        },
        openSelectOrderToMoveOrderItemsDialog: (state: State) => {
            state.selectOrderToMoveOrderItemsDialogState.open = true;
        },
        closeSelectOrderToMoveOrderItemsDialog: (state: State) => {
            state.selectOrderToMoveOrderItemsDialogState.open = false;
        },
        clearMoveOrderItemsBetweenOrdersStore: (state: State) => {
            state.remainingOrderItems = [];
            state.orderItemsToMove = [];
            state.toOrderId = undefined;
            state.moveOrderItemsBetweenOrdersDialogState.open = false;
            state.selectOrderToMoveOrderItemsDialogState.open = false;
        },
    },
});

export type State = {
    orderItemsToMove: Array<{ position: number } & CartItemVm>;
    remainingOrderItems: Array<CartItemVm>;
    toOrderId: OrderId | undefined;
    moveOrderItemsBetweenOrdersDialogState: { open: boolean; onClose?: () => void | undefined };
    selectOrderToMoveOrderItemsDialogState: { open: boolean; onClose?: () => void | undefined };
};

export type MoveOrderItemsWizardActions = {
    serRemainingOrderItems: (orderItems: Array<CartItemVm>) => void;
    setOrderItemsToMove: (orderItems: Array<{ position: number } & CartItemVm>) => void;
    setToOrderId: (toOrderId: OrderId) => void;
    clearMoveOrderItemsBetweenOrdersStore: () => void;
    openMoveOrderItemsBetweenOrdersDialog: () => void;
    closeMoveOrderItemsBetweenOrdersDialog: () => void;
    openSelectOrderToMoveOrderItemsDialog: () => void;
    closeSelectOrderToMoveOrderItemsDialog: () => void;
};
