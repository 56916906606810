/**
 * @prettier
 */
import { createStore } from '@pidedirecto/ui/hooks';
import { EmailAddress } from 'src/types/Id';

export const [usePosCustomerStore, usePosCustomerActions] = createStore<State, PosCustomerStoreActions>({
    initialState: {
        firstName: { value: undefined, error: undefined },
        lastName: { value: undefined, error: undefined },
        email: { value: undefined, error: undefined },
        mobileNumber: { value: undefined, error: undefined },
        numberOfCustomers: { value: undefined, error: undefined },
        customerNote: { value: undefined, error: undefined },
    },
    actions: {
        setCustomerFirstName: (state: State, firstName) => {
            state.firstName.value = firstName;
        },
        setCustomerLastName: (state: State, lastName) => {
            state.lastName.value = lastName;
        },
        setCustomerEmail: (state: State, email) => {
            state.email.value = email;
        },
        setCustomerMobileNumber: (state: State, mobileNumber) => {
            state.mobileNumber.value = mobileNumber;
        },
        setNumberOfCustomers: (state: State, numberOfCustomers) => {
            state.numberOfCustomers.value = numberOfCustomers;
        },
        setCustomerNote: (state: State, note) => {
            state.customerNote.value = note;
        },
        setError: (state: State, field, error) => {
            if (field in state && typeof state[field] === 'object') {
                state[field].error = error;
            }
        },
        clearCustomerInfo: (state: State) => {
            state.firstName = { value: undefined, error: undefined };
            state.lastName = { value: undefined, error: undefined };
            state.email = { value: undefined, error: undefined };
            state.mobileNumber = { value: undefined, error: undefined };
            state.numberOfCustomers = { value: undefined, error: undefined };
            state.customerNote = { value: undefined, error: undefined };
        },
    },
});

export type State = {
    firstName: ValueState;
    lastName: ValueState;
    email: ValueState;
    mobileNumber: ValueState;
    numberOfCustomers: NumberOfCustomerState;
    customerNote: ValueState;
};

type ValueState = {
    value: string | undefined;
    error: string | undefined;
};

type NumberOfCustomerState = {
    value: number | undefined;
    error: string | undefined;
};

export type PosCustomerStoreActions = {
    setCustomerFirstName: (value: string | undefined) => void;
    setCustomerLastName: (value: string | undefined) => void;
    setCustomerEmail: (value: EmailAddress | undefined) => void;
    setCustomerMobileNumber: (value: string | undefined) => void;
    setNumberOfCustomers: (value: number | undefined) => void;
    setCustomerNote: (value: string | undefined) => void;
    setError: SetError;
    clearCustomerInfo: () => void;
};

export type SetError = (field: keyof State, error: string | undefined) => void;
