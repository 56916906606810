/**
 * @prettier
 */
import OpeningHours from 'opening_hours';
import * as React from 'react';
import { useContext, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';
import type { AutoCompleteInputProp, Variant } from 'src/components/form/FormTypes';
import { TransformedTextField } from 'src/components/form/material-ui/TransformedTextField';
import { getError } from 'src/components/form/utils/getError';
import { translate } from 'src/i18n/translate';
import { isBlankString } from 'src/utils/string/isBlankString';

export function FormHoursField({
    name,
    label,
    placeholder,
    helperText,
    defaultValue = null,
    autoComplete = 'off',
    upperCase,
    disabled,
    required,
    variant,
    InputProps,
    inputProps,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>();

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <TransformedTextField
                    {...props}
                    value={value}
                    onChange={onChange}
                    label={label}
                    placeholder={placeholder}
                    helperText={getError(errors, name)?.message ?? helperText}
                    trim
                    disabled={isSubmitting || disabled || formContext.disabled}
                    required={required}
                    error={!!getError(errors, name)}
                    variant={variant}
                    inputRef={inputRef}
                    InputProps={InputProps}
                    inputProps={{ autoComplete, ...inputProps, style: { textTransform: upperCase ? 'uppercase' : 'none', ...inputProps?.style } }}
                    InputLabelProps={{ shrink: placeholder ? true : undefined }}
                    fullWidth
                />
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                validate: {
                    required: (value) => ((required ?? (required as any)?.value) && isBlankString(value) ? (required as any)?.message ?? translate('This field is required') : true),
                    pattern: (value) =>
                        isValidPattern(value) ? true : (required as any)?.message ?? translate('Must be a valid hours syntax, see https://wiki.openstreetmap.org/wiki/Key:opening_hours'),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

function isValidPattern(value?: string | null): boolean {
    if (!value || !value.trim()) return true;
    try {
        new OpeningHours(value);
    } catch (e: any) {
        return false;
    }
    return true;
}

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    autoComplete?: AutoCompleteInputProp;
    upperCase?: boolean;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    inputProps?: any;
};
