/**
 * @prettier
 */
import { PosPayment } from 'src/types/PosPayment';
import type { PosPaymentVm } from 'src/types/PosPaymentVm';
import { useSelector } from 'src/utils/react/useSelector';

/** @deprecated */
export function useMapPosPayments(): Array<PosPaymentVm> {
    const payments = useSelector((state) => state.pos.payments);
    const currency = useSelector((state) => state.app.restaurant?.currencyFormat?.currency);

    const mappedPayments = payments?.map((posPayment: PosPayment) => {
        const isMainCurrency = posPayment.restaurantCurrency?.currency === currency;
        return {
            amount: posPayment.amount,
            paymentMethod: posPayment.customPaymentMethod ?? posPayment.paymentMethod,
            customerNumber: posPayment.customerNumber,
            isTipPayment: posPayment.isTipPayment,
            isTipFromChange: posPayment.isTipFromChange,
            restaurantCurrencyAmount: !isMainCurrency ? posPayment.restaurantCurrencyAmount : undefined,
            restaurantCurrency: !isMainCurrency ? posPayment.restaurantCurrency : undefined,
            mexicanPaymentMethodCode: posPayment.mexicanPaymentMethodCode,
        };
    });

    return mappedPayments;
}
