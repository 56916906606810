/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuItemId, ModifierGroupId } from 'src/types/Id';

export async function showModifierInModifierGroupApi(request: ShowModifierInModifierGroupApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('modifierGroup/showModifierInModifierGroupApi', request);
}

type ShowModifierInModifierGroupApiRequest = {
    modifierGroupId: ModifierGroupId;
    menuItemId: MenuItemId;
};
