/**
 * @prettier
 */
import type { CartItemVm } from 'src/types/CartItemVm';
import type { MenuItemVm } from 'src/types/MenuItemVm';
import type { OrderItemVm } from 'src/types/OrderVm';
import type { MenuItemVm as PosMenuItemVm } from 'src/types/PosMenuVm';
import type { OrderItemToReturn } from 'src/utils/order/getOrderItemsFormattedToReturn';

export function isItemSoldByWeight(item: OrderItemVm | CartItemVm | MenuItemVm | OrderItemToReturn | PosMenuItemVm): boolean {
    return !!item.isSoldByWeight || !!item.salesUnit;
}
