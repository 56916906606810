/**
 * @prettier
 */
import moment from 'moment-timezone';
import { translate } from 'src/i18n/translate';
import type { TimeZoneName } from 'src/types/Id';
import { upperCaseFirstLetter } from 'src/utils/string/upperCaseFirstLetter';

export function formatDateStringReadable(value: string | Date | undefined, timeZone: TimeZoneName): string {
    if (!value) {
        return '';
    }

    return upperCaseFirstLetter(
        moment.tz(value, timeZone).calendar(null, {
            sameDay: `[${translate('Today')}]`,
            nextDay: `[${translate('Tomorrow')}]`,
            nextWeek: 'dddd',
            lastDay: `[${translate('Yesterday')}]`,
            // lastWeek: `[${translate('Last')}] dddd`, // problematic to translate, using L instead
            lastWeek: `L`,
            sameElse: 'L',
        })
    );
}
