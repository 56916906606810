/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import moment from 'moment-timezone';
import * as React from 'react';
import { getInventoryConsumptionsReportApi } from 'src/api/letseatmanager/inventory/getInventoryConsumptionsReportApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { CostTypes } from 'src/constants/CostType';
import { translate } from 'src/i18n/translate';
import { InventoryKpi } from 'src/scenes/letseatmanager/inventory/inventory/InventoryKpi';
import { InventoryConsumptionsReportTable } from 'src/scenes/letseatmanager/inventoryConsumptionsReport/InventoryConsumptionsReportTable';
import { FilterCostType } from 'src/scenes/letseatmanager/restaurantDashboard/FilterCostType';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { InventoryConsumptionReportVm } from 'src/types/InventoryConsumptionReportVm';
import { formatAsPercentage } from 'src/utils/number/formatAsPercentage';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function InventoryConsumptionsReport(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const costType = useSelector((state) => state.app2.filterReportsState.costType);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, inventoryConsumptionsReport, refreshInventoryConsumptionsReports] = useLoadApi(
        getInventoryConsumptionsReportApi,
        {
            restaurantIds: [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).add(1, 'day').toISOString(),
        },
        {
            initialValue: {
                inventoryConsumptionsReport: [],
            },
            dependencies: [restaurantId, period?.from, period?.to],
        }
    );

    const isEmptyReport = () => {
        if (!inventoryConsumptionsReport.inventoryConsumptionReport) return true;
    };

    const getKpiByCostType = (kpi: InventoryConsumptionReportVm['kpis']['totalCosts']) => {
        if (!kpi) return 0;

        switch (costType) {
            case CostTypes.COST_FIXED:
                return kpi.totalCostByFixedCost;
            case CostTypes.COST_BY_AVERAGE_WEIGHTED_COST:
                return kpi.totalCostByAverageWeightedCost;
            case CostTypes.COST_BY_LAST_PURCHASE_COST:
                return kpi.costPercentageByLastCost;
            default:
                return 0;
        }
    };

    const getKpiByPercentageType = (kpi: InventoryConsumptionReportVm['kpis']['costPercentages']) => {
        if (!kpi) return 0;
        switch (costType) {
            case CostTypes.COST_FIXED:
                return kpi.costPercentageByFixedCost || 0;
            case CostTypes.COST_BY_AVERAGE_WEIGHTED_COST:
                return kpi.costPercentageByAverageWeightedCost || 0;
            case CostTypes.COST_BY_LAST_PURCHASE_COST:
                return kpi.costPercentageByLastCost || 0;
            default:
                return 0;
        }
    };

    if (isEmptyReport()) {
        return (
            <div className={classes.container}>
                <UpdatingContentProgress loading={loading} top />
                <ReportSectionEmptyState />
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <div className={classes.filtersContainer}>
                <FilterReports />
                <FilterCostType position='right' />
            </div>
            <div className={classes.tableContainer}>
                <InventoryConsumptionsReportTable
                    loading={loading}
                    inventoryConsumptionsReport={inventoryConsumptionsReport?.inventoryConsumptionReport}
                    refresh={refreshInventoryConsumptionsReports}
                />
                {inventoryConsumptionsReport.kpis && (
                    <div className={classes.kpis}>
                        <InventoryKpi kpi={`${formatAsPercentage(getKpiByPercentageType(inventoryConsumptionsReport.kpis.costPercentages))}`} label={translate('Cost Percentage')} />
                        <InventoryKpi kpi={`${formatAsCurrencyNumber(getKpiByCostType(inventoryConsumptionsReport.kpis.totalCosts))}`} label={translate('Total Cost')} />
                        <InventoryKpi kpi={`${formatAsCurrencyNumber(inventoryConsumptionsReport.kpis.totalSold)}`} label={translate('Total Sold')} />
                    </div>
                )}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
    tableContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: 20,
        marginTop: 20,
    },
    kpis: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    filtersContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 10,
        width: '100%',
    },
}));
