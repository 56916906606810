/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';

// TODO: remove @ts-ignores and fix types

/* @ts-ignore */
export const TransformedTimePicker: React.ComponentType<any> = React.forwardRef<any, any>(function TransformedTextField({ value, onChange, ...props }, ref): React.ReactNode {
    const classes = useStyles();

    const [inputValue, setInputValue] = useState(transformToInput(value)); // Separate form value from input value

    useEffect(() => {
        const oldValue = transformToOutput(inputValue);
        if (value !== oldValue) setInputValue(transformToInput(value));
    }, [value]);

    return (
        <div className={classes.container}>
            <TimePicker
                {...props}
                className={classes.input}
                value={inputValue}
                onChange={(value: any) => {
                    setInputValue(value);
                    onChange(transformToOutput(value));
                }}
            />
        </div>
    );
});

function transformToInput(value?: string | null): Date | null | undefined {
    return value ? moment(value, 'HH:mm').toDate() : null;
}

export function transformToOutput(value?: Date | null | undefined | string): string | null | undefined {
    if (typeof value === 'string' && value !== '') return value;
    return !value ? null : moment(value).format('HH:mm');
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        '& .MuiFormControl-root': {
            border: '0px !important',
            padding: 0,
            '& label': {
                fontFamily: theme.typography.regular,
                color: theme.palette.secondary.contrastText,
                fontSize: 15,
                marginBottom: 10,
                transform: 'scale(1)',
            },
            '& .MuiInputBase-root': {
                backgroundColor: theme.palette.secondary.light,
                borderRadius: 5,
                fontFamily: theme.typography.regular,
                padding: `${theme.spacing(0.3)}px ${theme.spacing(1)}px`,
                border: '1px solid #D9D9D9',
                marginTop: 22,
            },
        },
    },
    input: {
        marginTop: 0,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 5,
        paddingRight: 5,
        paddingLeft: 5,
        '& > div': {
            '&::before': {
                display: 'none',
            },
            '&::after': {
                display: 'none',
            },
        },
        '& input': {
            fontFamily: theme.typography.medium,
            fontSize: 14,
            color: theme.palette.primary.main,
            textAlign: 'center',
        },
        '& label': {
            position: 'relative',
            marginBottom: '5px !important',
        },
        '& .MuiInputBase-root': {
            marginTop: '0px !important',
        },
    },
}));
