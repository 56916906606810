/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { assignCashRegisterPosBusinessDayForExternalMissingOrdersApi } from 'src/api/letseatmanager/pos/assignCashRegisterPosBusinessDayForExternalMissingOrdersApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { CashRegisterPosBusinessDayOption } from 'src/scenes/letseatmanager/pos/CashRegisterPosBusinessDayOption';
import { useConfirmDialog } from 'src/services/dialog/useConfirmDialog';
import { OpenedCashRegisterPosBusinessDayVm } from 'src/types/OpenedCashRegisterPosBusinessDayVm';
import { CashRegisterPosBusinessDayVm } from 'src/types/PosBusinessDayVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function AssignCashRegisterForExternalMissingOrdersDialog({ open, onClose, onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();

    const confirmDialog = useConfirmDialog();

    const [assigning, setAssigning] = useState(false);

    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const openedCashRegisterPosBusinessDay = useSelector((state) => state.pos.openedCashRegisterPosBusinessDay);
    const openedCashRegister = useSelector((state) => state.pos.openedCashRegister);
    const openedCashRegistersPosBusinessDay = useSelector((state) => state.pos.openedCashRegistersPosBusinessDay);

    const [cashRegisterPosBusinessDaySelected, setCashRegisterPosBusinessDaySelected] = useState<OpenedCashRegisterPosBusinessDayVm | undefined>(openedCashRegisterPosBusinessDay);

    const setOpenedCashRegisterPosBusinessDay = useAction(posReducer.actions.setOpenedCashRegisterPosBusinessDay);

    const openedCashRegisterPosBusinessDaysFiltered = openedCashRegistersPosBusinessDay?.filter((cashRegisterPosBusinessDay: OpenedCashRegisterPosBusinessDayVm) => {
        const currentCashRegisterPosBusinessDay = openedPosBusinessDay?.cashRegisterPosBusinessDays?.find(
            (cashRegister: CashRegisterPosBusinessDayVm) => cashRegister?.cashRegisterPosBusinessDayId === cashRegisterPosBusinessDay.cashRegisterPosBusinessDayId
        );

        return currentCashRegisterPosBusinessDay && !currentCashRegisterPosBusinessDay.isForExternalMissingOrders;
    });

    useEffect(() => {
        return () => {
            setCashRegisterPosBusinessDaySelected(undefined);
        };
    }, [open]);

    const handleAssignCashRegisterPosBusinessDayForExternalOrders = async () => {
        if (assigning || !cashRegisterPosBusinessDaySelected) return;

        const assignCashRegister = await confirmDialog({
            title: translate('Assign cash register for auto accepted orders'),
            content: translate('You have cash register @fromCashNumber assigned for auto accepted orders, are you sure you want to assign cahs register @toCashNumber now?', {
                fromCashNumber: openedCashRegister?.cashRegisterPosBusinessDayNumber,
                toCashNumber: cashRegisterPosBusinessDaySelected?.cashRegisterPosBusinessDayNumber,
            }),
            cancelButtonText: translate('Cancel'),
            buttonText: translate('Confirm'),
            variant: 'notification',
        });

        if (!assignCashRegister) {
            onClose?.();
            return;
        }

        setAssigning(true);
        const response = await assignCashRegisterPosBusinessDayForExternalMissingOrdersApi({
            fromCashRegisterPosBusinessDayId: openedCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId,
            toCashRegisterPosBusinessDayId: cashRegisterPosBusinessDaySelected?.cashRegisterPosBusinessDayId,
        });
        setAssigning(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            onClose?.();
            return;
        }

        setOpenedCashRegisterPosBusinessDay({ ...openedCashRegisterPosBusinessDay, isForExternalMissingOrders: undefined });
        onSuccess?.();
    };

    if (!openedCashRegisterPosBusinessDaysFiltered?.length) return null;

    return (
        <Dialog open={open} classes={{ dialog: classes.dialog }} title={translate('Assign cash register for external or auto accepted orders')} onClose={onClose}>
            <div className={classes.cardsContainer}>
                {openedCashRegisterPosBusinessDaysFiltered?.map((cashRegisterPosBusinessDay: OpenedCashRegisterPosBusinessDayVm) => {
                    const isSelected = cashRegisterPosBusinessDaySelected?.cashRegisterPosBusinessDayId === cashRegisterPosBusinessDay.cashRegisterPosBusinessDayId;
                    return (
                        <CashRegisterPosBusinessDayOption
                            isSelected={isSelected}
                            cashRegisterPosBusinessDay={cashRegisterPosBusinessDay}
                            onClick={(cashRegisterPosBusinessDay: OpenedCashRegisterPosBusinessDayVm) => setCashRegisterPosBusinessDaySelected(cashRegisterPosBusinessDay)}
                        />
                    );
                })}
            </div>
            <DialogActions className={classes.dialogActions}>
                <Button classes={{ button: classes.button }} secondary onClick={onClose}>
                    {translate('Return')}
                </Button>
                <Button classes={{ button: classes.button }} onClick={handleAssignCashRegisterPosBusinessDayForExternalOrders}>
                    {translate('Assign')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: 34,
        maxWidth: 780,
    },
    cardsContainer: {
        margin: '30px 0',
        display: 'grid',
        gap: 20,
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    },
    button: {
        minWidth: 110,
        alignSelf: 'end',
    },
    dialogActions: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'end',
    },
}));

type Props = {
    open: boolean;
    onClose?: any;
    onSuccess?: any;
};
