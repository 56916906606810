/**
 * @prettier
 */
import * as React from 'react';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { CostTypes } from 'src/constants/CostType';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { InventoryConsumptionReport } from 'src/types/InventoryConsumptionReportVm';
import { useSelector } from 'src/utils/react/useSelector';

export function InventoryConsumptionsReportTable({ loading, inventoryConsumptionsReport, refresh }: Props): React.ReactElement {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const costType = useSelector((state) => state.app2.filterReportsState.costType);

    const getInitialCost = () => {
        switch (costType) {
            case CostTypes.COST_FIXED:
                return 'costByFixedCost';
            case CostTypes.COST_BY_AVERAGE_WEIGHTED_COST:
                return 'costByAverageWeightedCost';
            case CostTypes.COST_BY_LAST_PURCHASE_COST:
                return 'costByLastPurchaseCost';
            default:
                return 'costByFixedCost';
        }
    };

    return (
        <Table
            loading={loading}
            data={inventoryConsumptionsReport}
            columns={[
                {
                    name: 'sku',
                    label: translate('SKU'),
                    options: {
                        filter: false,
                        customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                    },
                },
                {
                    name: 'category',
                    label: translate('Category'),
                    options: {
                        filter: true,
                        customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                    },
                },
                {
                    name: 'name',
                    label: translate('Name'),
                    options: {
                        filter: false,
                        customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                    },
                },
                {
                    name: 'orders',
                    label: translate('Orders'),
                    options: {
                        filter: false,
                        customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                    },
                },
                {
                    name: 'units',
                    label: translate('Units'),
                    options: {
                        filter: false,
                        customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                    },
                },
                {
                    name: getInitialCost(),
                    label: translate(`CostTypes.${costType}`),
                    options: {
                        filter: false,
                        customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{formatAsCurrencyNumber(value)}</div>,
                    },
                },
            ]}
            options={{
                responsive: 'standard',
                tableBodyMaxHeight: '500px',
                selectableRows: 'none',
                filterType: 'checkbox',
                rowsPerPage: 100,
                customToolbar: () => (
                    <>
                        <RefreshToolbarButton onClick={refresh} />
                    </>
                ),
            }}
        />
    );
}

export type Props = {
    loading: boolean;
    inventoryConsumptionsReport: Array<InventoryConsumptionReport>;
    refresh: any;
};
