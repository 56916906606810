/**
 * @prettier
 */
import * as React from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { Currencies, Currency } from 'src/constants/Currency';
import { CurrencyCode } from 'src/types/Id';

export function FormCurrencySelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, excludedCurrencies }: Props): React.ReactElement {
    const currenciesFiltered = excludedCurrencies ? Object.values(Currencies)?.filter((currency: unknown) => !excludedCurrencies?.includes(currency as CurrencyCode)) : Object.values(Currencies);

    const options = required
        ? currenciesFiltered?.map((currency: any) => ({ label: currency, value: currency }))
        : [{ label: '', value: null }, ...currenciesFiltered?.map((currency: any) => ({ label: currency, value: currency }))];

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    excludedCurrencies?: Array<Currency>;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
};
