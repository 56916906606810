/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { findPosPromoCodesApi } from 'src/api/letseatmanager/promoCode/findPosPromoCodesApi';
import type { PromoCodeVm } from 'src/api/letseatmanager/types/PromoCodeVm';
import { OrderTypes } from 'src/constants/OrderType';
import { RewardTypes } from 'src/constants/RewardType';
import { translate } from 'src/i18n/translate';
import { PromoCodeItem } from 'src/scenes/letseatmanager/pos/posPayment/posApplyPromoCodeDialog/PromoCodeItem';
import { CartItemVm } from 'src/types/CartItemVm';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function PromoCodeList(): React.ReactElement {
    const classes = useStyles();

    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const orderType = useSelector((state) => state.pos.orderType);
    const items = useSelector((state) => state.pos.items);
    const customerId = useSelector((state) => state.pos.customerId);

    const [, promoCodes] = useLoadApi(
        findPosPromoCodesApi,
        {
            restaurantIds,
            customerId,
        },
        { initialValue: [], dependencies: [restaurantIds, customerId] }
    );

    const filteredPromoCodesByOrderType = promoCodes?.filter((promoCode: PromoCodeVm) => {
        if (!promoCode.freeDelivery) return true;

        return orderType === OrderTypes.DELIVERY_ORDER;
    });

    const filteredPromoCodes = filteredPromoCodesByOrderType?.filter((promoCode: PromoCodeVm) => {
        if (promoCode.rewardType !== RewardTypes.PRODUCTS) return true;
        if (!items.length) return false;

        return items?.some((orderItem: CartItemVm) => promoCode.menuItemIds?.includes(orderItem.menuItemId));
    });

    if (!filteredPromoCodes?.length) {
        return (
            <div className={classes.container}>
                <h3>{translate('No Promo Codes found')}</h3>
            </div>
        );
    }

    return (
        <div>
            <h3 className={classes.promoCodeListTitle}>{translate('Available Promo Codes')}</h3>
            <div className={classes.promoCodeContainer}>
                {filteredPromoCodes.map((promoCode) => (
                    <PromoCodeItem key={promoCode.promoCodeId} promoCode={promoCode} />
                ))}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    promoCodeListTitle: {
        fontFamily: theme.typography.regular,
        color: theme.palette.text.primary,
        fontSize: 13,
    },
    container: {
        fontFamily: theme.typography.semiBold,
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
    },
    promoCodeContainer: {
        marginTop: 20,
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 150px ))',
        gap: 10,
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'start',
        },
    },
}));
