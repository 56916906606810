/**
 * @prettier
 */
import type { CommandSection } from 'src/constants/CommandSection';
import type { FormActiveCommandSections } from 'src/types/FormActiveCommandSections';
import { removeNulls } from 'src/utils/removeNulls';

export function getActiveCommandSections(formActiveSections: FormActiveCommandSections): Array<CommandSection> {
    const activeSectionsInForm = Object.keys(formActiveSections) as Array<CommandSection>;

    const activeSections = activeSectionsInForm.map((section) => {
        if (formActiveSections[section]) return section;
        return null as any;
    });

    return removeNulls(activeSections);
}
