/**
 * @prettier
 */
import { store } from 'src/config/store';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';

export function isPaymentTerminalDevice(): boolean {
    const state = store?.getState();
    const hasQpayPosTerminalDeviceId = !!state.app.qpayPosTerminalDeviceId;
    return isMobileApp() && hasQpayPosTerminalDeviceId;
}
