/**
 * @prettier
 */
import { RoutePaths } from 'src/constants/RoutePath';
import type { ManagerUserId } from 'src/types/Id';
import { RegExps } from 'src/utils/RegExps';

export function openManagerUserPageInNewTab(managerUserId: ManagerUserId): void {
    window.open(RoutePaths.ADMIN_MANAGER_USER.replace(`:managerUserId(${RegExps.uuid})`, managerUserId), '_blank');
}
