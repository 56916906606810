/**
 * @prettier
 */
import type { PosBusinessDaySummaryVm } from 'src/types/PosBusinessDaySummaryVm';

export const KEY_NAME = 'posBusinessDaySummary';

export function findPosBusinessDaySummaryFromLocalStorage(): PosBusinessDaySummaryVm | undefined {
    const item = window.localStorage.getItem(KEY_NAME);
    return item ? JSON.parse(item) : undefined;
}
