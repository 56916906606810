/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesType } from 'src/constants/SalesTypes';
import type { PosBusinessDayId } from 'src/types/Id';
import type { PosBusinessDayReportVm } from 'src/types/PosBusinessDayReportVm';

export async function getPosBusinessDayReportApi(request: GetPosBusinessDayReportApiRequest): ApiSauceResponse<PosBusinessDayReportVm> {
    return pidedirectoReportsMethod('/getPosBusinessDayReportApi', request);
}

export type GetPosBusinessDayReportApiRequest = {
    posBusinessDayReportId: PosBusinessDayId;
    salesType: SalesType;
};
