/**
 * @prettier
 */
/* eslint-disable */
// from "mui-datatables": "2.3.0", https://raw.githubusercontent.com/gregnb/mui-datatables/2.3.0/src/utils.js
function buildMap(rows: any): any {
    return rows.reduce((accum: any, { dataIndex }: any) => {
        accum[dataIndex] = true;
        return accum;
    }, {});
}

function getCollatorComparator(): any {
    if (!!Intl) {
        const collator: any = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
        return collator.compare;
    }

    const fallbackComparator = (a: any, b: any) => a.localeCompare(b);
    return fallbackComparator;
}

function sortCompare(order: any): any {
    return (a: any, b: any) => {
        if (a.data === null) a.data = '';
        if (b.data === null) b.data = '';
        return (typeof a.data.localeCompare === 'function' ? a.data.localeCompare(b.data) : (a.data as any) - (b.data as any)) * (order === 'asc' ? 1 : -1);
    };
}

function createCSVDownload(columns: any, data: any, options: any): any {
    const replaceDoubleQuoteInString = (columnData: any) => (typeof columnData === 'string' ? columnData.replace(/\"/g, '""') : columnData);

    const buildHead = (columns: any) => {
        return (
            columns.reduce((soFar: any, column: any) => (column.download ? soFar + '"' + replaceDoubleQuoteInString(column.name) + '"' + options.downloadOptions.separator : soFar), '').slice(0, -1) +
            '\r\n'
        );
    };
    const CSVHead = buildHead(columns);

    const buildBody = (data: any): any => {
        return data
            .reduce(
                (soFar: any, row: any): any =>
                    soFar +
                    '"' +
                    row.data
                        .filter((_: any, index: any) => columns[index].download)
                        .map((columnData: any) => replaceDoubleQuoteInString(columnData))
                        .join('"' + options.downloadOptions.separator + '"') +
                    '"\r\n',
                []
            )
            .trim();
    };
    const CSVBody = buildBody(data);

    const csv = options.onDownload ? options.onDownload(buildHead, buildBody, columns, data) : `${CSVHead}${CSVBody}`.trim();
    const blob = new Blob([csv], { type: 'text/csv' });

    /* taken from react-csv */
    if (navigator && (navigator as any).msSaveOrOpenBlob) {
        (navigator as any).msSaveOrOpenBlob(blob, options.downloadOptions.filename);
    } else {
        const dataURI = `data:text/csv;charset=utf-8,${csv}`;

        const URL = window.URL || window.webkitURL;
        const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);

        let link = document.createElement('a');
        link.setAttribute('href', downloadURI);
        link.setAttribute('download', options.downloadOptions.filename);
        (document.body as any).appendChild(link);
        link.click();
        (document.body as any).removeChild(link);
    }
}

export { buildMap, getCollatorComparator, sortCompare, createCSVDownload };
