/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { RestaurantZoneVm } from 'src/types/RestaurantZoneVm';

export async function findAvailableRestaurantZonesAndTablesApi(request: FindRestaurantZonesApiRequest): ApiSauceResponse<Array<RestaurantZoneVm>> {
    return letseatmanagerApiMethod('pos/findAvailableRestaurantZonesAndTablesApi', request);
}

type FindRestaurantZonesApiRequest = {
    restaurantId: RestaurantId;
};
