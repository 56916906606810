/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BrandVm } from 'src/types/BrandVm';
import type { RestaurantId } from 'src/types/Id';

export async function createBrandApi(request: CreateBrandApiRequest): ApiSauceResponse<BrandVm> {
    return letseatadminApiMethod('brand/createBrandApi', request);
}

export type CreateBrandApiRequest = {
    brandName: string;
    restaurantIds: Array<RestaurantId>;
    comment?: string;
    marketingActionsForRestaurantEnabled: boolean;
};
