/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Tooltip } from 'src/components/Tooltip';
import { RoutePaths } from 'src/constants/RoutePath';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import { PlusIcon } from 'src/icons/PlusIcon';
import { useSelector } from 'src/utils/react/useSelector';

export function NewOrderButton(): React.ReactElement {
    const classes = useStyles();
    const history = useHistory();

    const [openTooltip, setOpenTooltip] = useState(false);

    const brandOpened = useSelector((state) => state.app.brandOpened);

    const goToPos = () => {
        if (brandOpened) {
            setOpenTooltip(true);
            setTimeout(() => setOpenTooltip(false), 5 * SECONDS);
            return;
        }
        history.push({
            pathname: RoutePaths.POS,
            search: history.location.search,
        });
    };

    return (
        <Tooltip forceOpen={openTooltip} text={translate('It is not possible to take orders as Brand. Select one location in the manager to take the order.')} position={'bottom'}>
            <Button onClick={goToPos} classes={{ button: classes.button }}>
                {translate('New order')}
                <PlusIcon width={16} height={16} />
            </Button>
        </Tooltip>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        width: 'max-content',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));
