/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import * as React from 'react';
import { DropDownContext } from 'src/components/DropDown';
import { classNames } from 'src/utils/react/classNames';

/**
 * Renders each item of a dropdown, it has to be used as children of DropDown component
 * @param {React.Node} [children] - Element to be rendered as list item
 * @param {any} value - Value of the list item, this will be passed as parameter in the onChange prop of DropDown
 * @param {string} [className] - Class for list item container
 * @returns {React.Node}
 * @constructor
 */
export function DropDownItem({ children, value, className, label }: Props): React.ReactElement {
    const classes = useStyles();

    const context = useContext(DropDownContext);
    const { selectedValues, onClickItem } = context;

    const isSelected = selectedValues?.includes(value);

    const handleClick = () => {
        onClickItem?.({ label, value });
    };

    return (
        <div className={classNames(classes.container, className)} role='option' id={`option-${value}`} onClick={handleClick} aria-selected={isSelected}>
            {children}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        color: theme.palette.text.primary,
        fontFamily: theme.typography.regular,
        fontSize: 12,
        cursor: 'pointer',
    },
}));

type Props = {
    value: any;
    className?: string;
    children: React.ReactNode;
    label?: string;
};
