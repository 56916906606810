/**
 * @prettier
 */
import { makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { findCompoundSuppliesToProduceApi } from 'src/api/letseatmanager/supply/findCompoundSuppliesToProduceApi';
import { produceCompoundSuppliesApi } from 'src/api/letseatmanager/supply/produceCompoundSuppliesApi';
import { Alert } from 'src/components/Alert';
import { Button } from 'src/components/Button';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { Form } from 'src/components/form/Form';
import { Input } from 'src/components/Input';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { SimpleTable } from 'src/components/SimpleTable';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { WarningIcon } from 'src/icons/WarningIcon';
import { ProductionSummaryDialog } from 'src/scenes/letseatmanager/inventory/supply/ProductionSummaryDialog';
import { useNotification } from 'src/services/notification/useNotification';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { SupplyId } from 'src/types/Id';
import { BuyUnitVm } from 'src/types/SupplyVm';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateSupplyMassiveProductionPage(): React.ReactElement {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const form = useForm();
    const classes = useStyles();
    const history = useHistory();
    const showNotification = useNotification();

    const {
        formState: { isFormSubmitting },
    } = form as any; // TODO: try to remove isFormSubmitting since "as any" was added since isFormSubmitting does not exist in form

    const [productionSummaryDialogOpen, setProductionSummaryDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [ingredientsUsedInProduction, setIngredientsUsedInProduction] = useState<Array<any>>([]);
    const [quantitySuppliesToProduce, setQuantitySuppliesToProduce] = useState({});

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const pinCodeUserManagerUserId = useSelector((state) => state.authentication.pinCodeUserSignedIn?.managerUserId);

    const [loadingSupplies, { compoundSupplies, stockIngredients }, loadCompositeSupplies] = useLoadApi(
        findCompoundSuppliesToProduceApi,
        { restaurantId },
        { requiredValues: [restaurantId], dependencies: [restaurantId], initialValue: { compoundSupplies: [], stockIngredients: [] } }
    );

    const supplyTableColumns = [
        {
            id: 'sku',
            content: translate('SKU'),
            size: 1,
        },
        {
            id: 'name',
            content: translate('Supply'),
            size: 1.2,
        },
        {
            id: 'unitOfMeasurement',
            content: translate('Unit of Measurement'),
            size: 1,
        },
        {
            id: 'quantity',
            content: translate('Produce'),
            size: 0.5,
        },
    ];

    const handleProductionSubmit = async () => {
        setLoading(true);

        const productionApiResponse = await produceCompoundSuppliesApi({
            compoundSupplies: mapSuppliesToProduce(),
            restaurantId,
            managerUserId: pinCodeUserManagerUserId,
        });

        if (!productionApiResponse.ok) {
            showNotification({ message: translate('Something went wrong') });
            setLoading(false);
            return;
        }
        setLoading(false);
        showNotification({ message: translate('Production Created Successfully') });
        setProductionSummaryDialogOpen(false);

        history.push({
            pathname: RoutePaths.INVENTORY_PRODUCTION,
            search: history.location.search,
        });
    };

    const handleSubmit = async () => {
        setProductionSummaryDialogOpen(true);
    };

    const mapSuppliesToProduce = (): Array<{
        supplyId: SupplyId;
        quantity: string;
    }> => {
        return Object.keys(quantitySuppliesToProduce)
            .map((supplyId) => ({
                supplyId: supplyId as SupplyId,
                quantity: quantitySuppliesToProduce[supplyId as keyof typeof quantitySuppliesToProduce],
            }))
            .filter((item) => !!item.quantity);
    };

    const handleProductionQuantityChange = (quantity: any, supplyId: any) => {
        const newQuantitySuppliesToProduce = { ...quantitySuppliesToProduce, [supplyId]: quantity } as const;

        setQuantitySuppliesToProduce(newQuantitySuppliesToProduce);

        calculateIngredientQuantitiesForProduction(newQuantitySuppliesToProduce);
    };

    const calculateIngredientQuantitiesForProduction = (newQuantitySuppliesToProduce: any) => {
        const totalIngredientQuantities: Record<string, number> = {};

        compoundSupplies.forEach((supply) => {
            const supplyProductionQuantity = newQuantitySuppliesToProduce[supply.supplyId] || 0;
            if (supplyProductionQuantity === 0) {
                return;
            }

            supply.ingredients?.forEach((ingredient) => {
                const ingredientQuantity = Number(ingredient.quantity) * supplyProductionQuantity;
                const ingredientSupplyId = ingredient.supply.supplyId;

                if (totalIngredientQuantities[ingredientSupplyId as keyof typeof totalIngredientQuantities]) {
                    totalIngredientQuantities[ingredientSupplyId as keyof typeof totalIngredientQuantities] += ingredientQuantity;
                } else {
                    totalIngredientQuantities[ingredientSupplyId as keyof typeof totalIngredientQuantities] = ingredientQuantity;
                }
            });
        });

        const updatedIngredients = Object.keys(totalIngredientQuantities).map((supplyId) => {
            const quantity = totalIngredientQuantities[supplyId];
            const ingredientStock = stockIngredients.find((ingredientStock) => ingredientStock.supplyId === supplyId);
            const ingredientPrice = ingredientStock?.price || 0;
            const ingredientSellUnit = ingredientStock?.sellUnit || 'Unknown';
            const supplyName = ingredientStock?.supplyName || 'Unknown';
            const hasEnoughStock = ingredientStock && ingredientStock.quantity >= (quantity as any);
            const totalAmount = Number(quantity) * Number(ingredientPrice);

            return {
                supplyId,
                supplyName,
                quantity,
                hasEnoughStock,
                totalAmount,
                sellUnit: ingredientSellUnit,
            };
        });

        setIngredientsUsedInProduction(updatedIngredients);
    };

    const totalProductionCost = ingredientsUsedInProduction?.reduce((acc: number, ingredient) => acc + ingredient.totalAmount, 0);
    const disabledSaveButton = isFormSubmitting || compoundSupplies === undefined || compoundSupplies.length === 0;
    const someIngredientHasNotEnoughStock = ingredientsUsedInProduction?.some((ingredient) => !ingredient.hasEnoughStock);

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.PRODUCE_COMPOUND_SUPPLY} title={translate('Production')} className={classes.container}>
            <GoBackButton />
            <ProductionSummaryDialog
                open={productionSummaryDialogOpen}
                onClose={() => setProductionSummaryDialogOpen(false)}
                onSubmit={() => handleProductionSubmit()}
                suppliesProductionSummary={ingredientsUsedInProduction}
            />
            <h1 className={classes.title}>{translate('Produce composite supplies')}</h1>
            <p className={classes.text}>{translate('List of composite supplies')}</p>
            <div className={classes.container}>
                <Form form={form} onSubmit={handleSubmit} disabled={loadingSupplies}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell, table: classes.table }}
                                columns={supplyTableColumns}
                                rows={compoundSupplies?.map((compoundSupply: any) => ({
                                    sku: compoundSupply.sku ?? '',
                                    name: compoundSupply.name ?? '',
                                    unitOfMeasurement: compoundSupply.buyUnits.map((buyUnit: BuyUnitVm) => (buyUnit.isMainUnit ? buyUnit.unit : '')),
                                    quantity: (
                                        <Input
                                            type={'number'}
                                            name={'quantity'}
                                            defaultValue={0}
                                            onChange={(e: any) => handleProductionQuantityChange(e, compoundSupply.supplyId)}
                                            dataTestId={'quantity-input'}
                                        />
                                    ),
                                }))}
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.summaryContainer}>
                            <h2 className={classes.subtitle}>{translate('Base Supplies Used')}</h2>

                            <div className={classes.separator} />
                            {ingredientsUsedInProduction && (
                                <table className={classes.table}>
                                    <tbody className={(classes as any).tableBody}>
                                        {ingredientsUsedInProduction?.map((ingredient, index: number) => (
                                            <tr key={index} className={(classes as any).tableRow}>
                                                <td className={classes.summarySupplyQuantity}>
                                                    {ingredient.quantity} {translate(`UnitsOfMeasurement.${ingredient.sellUnit}`)}
                                                </td>
                                                <td className={classes.summarySupplyName}>{`${ingredient.supplyName}`}</td>
                                                <td className={classes.summarySupplyAmount}>
                                                    {formatAsCurrencyNumber(ingredient.totalAmount)} {!ingredient.hasEnoughStock ? <WarningIcon /> : ''}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            {someIngredientHasNotEnoughStock && (
                                <div className={classes.alertContainer}>
                                    <Alert type={'warning'} title={translate('Items with insufficient stock')} icon={<WarningIcon />} />
                                </div>
                            )}
                            <div className={classes.dottedSeparator} />
                            <div className={classes.totalContainer}>
                                <Typography variant='h6' className={classes.totalPrice}>
                                    {translate('Total')}
                                </Typography>
                                <Typography variant='h6' className={classes.totalPriceAmount}>
                                    {formatAsCurrencyNumber(totalProductionCost)}
                                </Typography>
                            </div>
                            <Grid container spacing={2} className={(classes as any).buttonsContainer}>
                                <Grid item xs={6}>
                                    <Button type='button' secondary classes={{ button: classes.button }} onClick={() => setProductionSummaryDialogOpen(false)}>
                                        {translate('Cancel')}
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button type='submit' disabled={loading || disabledSaveButton || ingredientsUsedInProduction.length === 0} primary classes={{ button: classes.button }}>
                                        {translate('Produce')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            </div>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        paddingTop: 60,
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 25,
        width: '100%',
        marginBottom: 0,
        martinTop: 0,
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        margin: 0,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        margin: 0,
    },
    separator: {
        border: `0.1px solid ${theme.palette.secondary.dark}`,
        width: '100%',
        margin: '10px 0',
    },
    dottedSeparator: {
        borderBottom: `3px dotted ${theme.palette.secondary.dark}`,
        width: '100%',
        height: 1,
        marginTop: 10,
    },
    alertContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        marginTop: 40,
        marginBottom: 20,
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        '& td': {
            padding: 1,
        },
        '& th': {
            backgroundColor: 'transparent',
            padding: 1,
        },
        border: 'none',
    },
    tableCell: {
        border: `none`,
        padding: 10,
        textAlign: 'left',
        alignItems: 'center',
    },
    totalContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginTop: 20,
    },
    totalPrice: {
        fontFamily: theme.typography.medium,
        fontSize: 16,
        width: '100%',
        display: 'block',
        marginTop: 20,
        fontWeight: 600,
    },
    totalPriceAmount: {
        fontFamily: theme.typography.medium,
        fontSize: 16,
        width: '100%',
        display: 'block',
        marginTop: 20,
        fontWeight: 600,
        textAlign: 'right',
    },
    summaryContainer: {
        border: `1px solid ${theme.palette.secondary.dark}`,
        borderRadius: 10,
        padding: '20px !important',
        height: '100%',
        maxHeight: '90vh',
        overflowY: 'auto',
    },
    summarySupplyQuantity: {
        textAlign: 'left',
        paddingRight: 10,
        fontWeight: 600,
    },
    summarySupplyAmount: {
        textAlign: 'right',
        paddingRight: 10,
        fontWeight: 600,
    },
    summarySupplyName: {
        textAlign: 'left',
        paddingRight: 10,
        textTransform: 'capitalize',
    },
    button: {
        width: '100%',
        marginTop: 20,
    },
}));
