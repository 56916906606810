/**
 * @prettier
 */
import { InputBase, InputLabel, makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { Checkbox } from '@pidedirecto/ui';
import * as React from 'react';
import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import type { Variant } from 'src/components/form/FormTypes';
import { translate } from 'src/i18n/translate';
import { debounce } from 'src/utils/function/debounce';
import { classNames } from 'src/utils/react/classNames';

export function CustomizableMultiselectableAutocomplete({
    label,
    name,
    placeholder,
    helperText,
    data,
    renderOption,
    getOptionDisabled,
    getOptionLabel,
    getOptionValue,
    onChange,
    disabled,
    error,
    selectAllOption,
    classes: classesProp,
    selectedItems,
}: Props): React.ReactElement {
    const classes = useStyles();
    const listboxContainerRef = useRef<HTMLDivElement>(null);

    const [inputValue, setInputValue] = useState('');
    const [showOptions, setShowOptions] = useState(false);

    const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
        id: name,
        options: data,
        getOptionLabel,
        getOptionDisabled,
    });

    const handleItem = (itemId: any) => {
        if (getOptionDisabled?.(itemId)) return;
        if (selectedItems.includes(itemId)) {
            const updateSelectedItems = selectedItems.filter((item) => item !== itemId);
            onChange([...updateSelectedItems]);
            return;
        }
        onChange([...selectedItems, itemId]);
    };

    const handleSelectAllItem = () => {
        const itemsId = data.map((itemId) => getOptionValue(itemId));
        if (selectedItems.length === data.length) {
            onChange([]);
            return;
        }
        onChange(itemsId);
    };

    const getListboxTopPosition = () => {
        if (!listboxContainerRef.current) return 0;

        const clientRect = listboxContainerRef.current.getBoundingClientRect();
        return clientRect.top + 10;
    };

    const getListboxLeftPosition = () => {
        if (!listboxContainerRef.current) return 0;

        const clientRect = listboxContainerRef.current.getBoundingClientRect();
        return clientRect.left;
    };

    const debouncedOnChange = debounce((getInputProps() as any).onChange, 700);

    return (
        <div>
            <div {...getRootProps()}>
                <div className={classes.headContainer}>
                    <InputLabel htmlFor={`use-autocomplete-customer`} classes={{ root: classes.label, error: classes.labelError }} error={!!error}>
                        {label}
                    </InputLabel>
                    {!showOptions && <span className={classes.numberItemsSelectedContainer}>{translate('@itemsSelected Products', { itemsSelected: selectedItems?.length ?? 0 })}</span>}
                </div>
                <InputBase
                    {...getInputProps()}
                    type='search'
                    classes={{ root: classes.input, error: classes.inputError }}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        debouncedOnChange(e);
                    }}
                    onBlur={(e) => {
                        setShowOptions(false);
                        (getInputProps() as any).onBlur(e);
                    }}
                    onFocus={(e) => {
                        setShowOptions(true);
                        (getInputProps() as any).onFocus(e);
                    }}
                    placeholder={placeholder}
                    inputProps={{ autoComplete: 'off' }}
                    disabled={disabled}
                />
                {!!helperText && <FormHelperText classes={{ root: classes.helperText }}>{helperText}</FormHelperText>}
            </div>
            <div ref={listboxContainerRef} style={{ width: '100%' }}>
                {groupedOptions.length > 0 &&
                    showOptions &&
                    createPortal(
                        <ul
                            className={classNames(classes.listbox, classesProp?.optionsContainer)}
                            {...getListboxProps()}
                            style={{ top: getListboxTopPosition(), left: getListboxLeftPosition(), width: listboxContainerRef.current?.offsetWidth }}
                        >
                            {selectAllOption && (
                                <li onClick={() => handleSelectAllItem()} className={classNames(classesProp?.optionContainer, classes.checkAllBoxRow)}>
                                    <Checkbox name={'selectAll'} label={translate('Select all')} value={'all'} checked={selectedItems.length === data.length} onChange={() => handleSelectAllItem()} />
                                    <span className={classes.numberItemsSelectedContainer}>{translate('@itemsSelected Products', { itemsSelected: selectedItems?.length ?? 0 })}</span>
                                </li>
                            )}
                            {groupedOptions.map((option: any, index: number) => (
                                <>
                                    <li
                                        {...getOptionProps({ option, index })}
                                        onClick={() => handleItem(getOptionValue(option))}
                                        className={classNames(classes.checkBoxRow, classesProp?.optionContainer)}
                                    >
                                        <Checkbox name={option.value} value={option.value || undefined} checked={selectedItems?.includes(getOptionValue(option))} onChange={() => handleItem(option)} />
                                        {renderOption(option)}
                                    </li>
                                </>
                            ))}
                        </ul>,
                        document.body
                    )}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    headContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 4,
    },
    label: {
        fontFamily: theme.typography.regular,
        color: theme.palette.secondary.contrastText,
        fontSize: 12,
        marginBottom: 8,
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
    numberItemsSelectedContainer: {
        fontFamily: theme.typography.regular,
        backgroundColor: '#E9FFF8',
        color: '#024B49',
        fontSize: 12,
        textAlign: 'center',
        padding: '4px 10px 4px 10px',
        minWidth: 93,
        minHeight: 26,
        marginRight: 12,
    },
    labelError: {
        color: 'red',
    },
    input: {
        width: '100%',
        backgroundColor: 'transparent',
        borderRadius: '4px',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontFamily: theme.typography.regular,
        border: `1px solid ${theme.palette.secondary.dark}`,
        height: '42px',
        alignItems: 'center',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    inputError: {
        border: '2px solid red',
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: 'red',
    },
    listbox: {
        width: 250,
        margin: 0,
        padding: 0,
        zIndex: 10000,
        position: 'absolute',
        listStyle: 'none',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        maxHeight: '40vh',
        border: '1px solid rgba(0,0,0,.25)',
        '& li[data-focus="true"]': {
            backgroundColor: '#4a8df6',
            color: 'white',
            cursor: 'pointer',
        },
        '& li:active': {
            backgroundColor: '#2977f5',
            color: 'white',
        },
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: 60,
        padding: '5px 10px',
    },
    mobileNumberContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    numberOfOrders: {
        color: theme.palette.primary.main,
        fontSize: 12,
        marginLeft: 5,
        fontFamily: theme.typography.regular,
    },
    itemText: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    checkBoxRow: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 4,
    },
    checkAllBoxRow: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 8,
        color: '#2f3e5d !important',
        fontFamily: theme.typography.regular,
        paddingBottom: 12,
        paddingTop: 12,
        marginLeft: 6,
        '&:hover': {
            color: '#2f3e5d !important',
        },
        borderBottom: '1px solid #D9D9D9',
    },
    arrowAlignCenter: {
        marginLeft: 6,
    },
}));

export type Props = {
    label?: string;
    name: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: Array<string>;
    data: Array<any>;
    getOptionValue: (arg1?: any) => any;
    getOptionLabel: (arg1?: any) => any;
    getOptionDisabled?: (arg1?: any) => boolean;
    renderOption: (arg1?: any) => React.ReactElement;
    disabled?: boolean;
    error?: boolean;
    selectAllOption?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    onChange: any;
    tooltip?: string;
    classes?: {
        optionsContainer?: string;
        optionContainer?: string;
    };
    selectedItems: Array<string>;
};
