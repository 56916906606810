/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function FromToFilter({ filter, onChangeFilter }: Props): React.ReactElement {
    const classes = useStyles();

    const handleChangeFromDate = (date: any) => {
        onChangeFilter({
            ...filter,
            from: moment(date).toDate(),
        });
    };

    const handleChangeToDate = (date: any) => {
        onChangeFilter({
            ...filter,
            to: moment(date).toDate(),
        });
    };

    return (
        <Card style={{ padding: 24, width: 'fit-content' }}>
            <Grid container direction='row' spacing={3} alignItems='center' className={classes.inputContainer}>
                <Grid item>
                    <DatePicker variant='inline' label={translate('From')} value={filter.from} onChange={handleChangeFromDate} format='ll' autoOk />
                </Grid>
                <Grid item>
                    <DatePicker variant='inline' label={translate('To')} value={filter.to} onChange={handleChangeToDate} format='ll' autoOk />
                </Grid>
            </Grid>
        </Card>
    );
}

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
        '& .MuiInputBase-root': {
            fontFamily: `${theme.typography.regular} !important`,
            fontSize: 14,
            backgroundColor: theme.palette.secondary.light,
            borderRadius: 5,
            padding: '5px 0 5px 15px',
        },
        '& .MuiInputBase-root::before': {
            display: 'none',
        },
        '& .MuiInputBase-root::after': {
            display: 'none',
        },
        '& .MuiFormControl-root:first-child': {
            marginRight: 10,
        },
        '& label': {
            fontFamily: theme.typography.regular,
            fontSize: 15,
            color: theme.palette.text.brand,
        },
    },
}));

type Props = {
    filter: FilterState;
    onChangeFilter: (arg1: { from: Date; to: Date }) => any;
};

export type FilterState = {
    from: Date;
    to: Date;
};
