/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '@pidedirecto/ui';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { closePosBusinessDayApi } from 'src/api/letseatmanager/pos/closePosBusinessDayApi';
import { CashRegisterExpectedAmountVm, getCashRegisterExpectedAmountsApi } from 'src/api/letseatmanager/pos/getCashRegisterExpectedAmountsApi';
import { getCashRegisterPosBusinessDayReportApi } from 'src/api/letseatmanager/pos/getCashRegisterPosBusinessDayReportApi';
import { getPosBusinessDayReportApi } from 'src/api/letseatmanager/pos/getPosBusinessDayReportApi';
import { closeCashRegisterPosBusinessDayApi } from 'src/api/letseatmanager/pos/restaurantPosBusinessDay/closeCashRegisterPosBusinessDayApi';
import { sendEndOfDayReportEmailApi } from 'src/api/letseatmanager/pos/sendEndOfDayReportEmailApi';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { posReducer } from 'src/reducers/posReducer';
import { CashRegisterExpectedAmountReport } from 'src/scenes/letseatmanager/posCashRegister/CashRegisterExpectedAmountReport';
import { useFindOpenedUserCashRegisterPosBusinessDay } from 'src/services/cashRegister/useFindOpenedUserCashRegisterPosBusinessDay';
import { useSetExpectedAmounts } from 'src/services/posBusinessDay/useSetExpectedAmounts';
import { usePrintPosBusinessDayReport } from 'src/services/printer/usePrintPosBusinessDayReport';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { RegExps } from 'src/utils/RegExps';
import { emptyStringToUndefined } from 'src/utils/string/emptyStringToUndefined';

export function ClosePosBusinessDayCashRegisterReport(): React.ReactElement {
    const classes = useStyles();

    const history = useHistory();
    const setExpectedAmounts = useSetExpectedAmounts();

    const [printPosBusinessDayReport] = usePrintPosBusinessDayReport();
    const { findUserCashRegisterPosBusinessDay } = useFindOpenedUserCashRegisterPosBusinessDay();

    const [loading, setLoading] = useState(false);

    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);
    const cashRegister = useSelector((state) => state.pos.cashRegister);
    const openedCashRegister = useSelector((state) => state.pos.openedCashRegister);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const openedCashRegisterPosBusinessDay = useSelector((state) => state.pos.openedCashRegisterPosBusinessDay);
    const salesTypeFilter = useSelector((state) => state.app.salesTypeFilter);
    const pinCodeUserSignedIn = useSelector((state) => state.authentication?.pinCodeUserSignedIn);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);
    const closeCashRegisterReport = useAction(posReducer.actions.closeCashRegisterReport);
    const setCashRegisterOpen = useAction(posReducer.actions.setCashRegisterOpen);
    const setOpenedPosBusinessDay = useAction(posReducer.actions.setOpenedPosBusinessDay);
    const setOpenedCashRegister = useAction(posReducer.actions.setOpenedCashRegister);
    const setOpenedCashRegisterPosBusinessDay = useAction(posReducer.actions.setOpenedCashRegisterPosBusinessDay);

    const hasCashRegisterPosBusinessDay = openedCashRegisterPosBusinessDay ?? findUserCashRegisterPosBusinessDay();

    const [loadingExpectedAmounts, expectedAmounts] = useLoadApi(
        getCashRegisterExpectedAmountsApi,
        {
            cashRegisterId: cashRegister?.cashRegisterId,
        },
        {
            initialValue: [],
        }
    );

    useEffect(() => {
        if (expectedAmounts) {
            setExpectedAmounts(expectedAmounts);
        }
        return () => {
            setExpectedAmounts([]);
        };
    }, [expectedAmounts]);

    const handleSubmit = async (form: any) => {
        const cashRegisterReport = [...(form.cashRegisterReport ?? []), ...(form.cashRegisterIntegrationsReport ?? [])];
        if (posMultipleCashRegistersEnabled && hasCashRegisterPosBusinessDay) {
            await closeCashRegisterPosBusinessDay({ cashRegisterReport });
            return;
        }

        await closePosBusinessDay({ cashRegisterReport });
    };

    const closeCashRegisterPosBusinessDay = async (form: any) => {
        if (!hasCashRegisterPosBusinessDay) return;

        setLoading(true);
        const response = await closeCashRegisterPosBusinessDayApi({
            restaurantId,
            managerUserId: hasCashRegisterPosBusinessDay?.assignedToManagerUserId,
            cashRegisterPosBusinessDayId: hasCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId,
            cashRegisterReport: form.cashRegisterReport?.map((report: any) => ({
                paymentMethod: report.paymentMethod,
                customPaymentMethod: report.customPaymentMethod,
                amount: report.amount,
                app: report.app,
                expectedAmount: expectedAmounts?.find(
                    (expectedAmount: CashRegisterExpectedAmountVm) =>
                        expectedAmount.paymentMethod === report.paymentMethod && emptyStringToUndefined(expectedAmount.customPaymentMethod) === emptyStringToUndefined(report.customPaymentMethod)
                )?.amount,
            })),
        });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setLoading(false);
            return;
        }

        const posBusinessDayReport = await getCashRegisterPosBusinessDayReportApi({
            cashRegisterPosBusinessDayId: response.data.cashRegisterPosBusinessDayId,
            salesType: salesTypeFilter,
        });

        if (!posBusinessDayReport?.ok) {
            setLoading(false);
            closeCashRegisterReport();
            setOpenedCashRegister(undefined);
            setOpenedCashRegisterPosBusinessDay(undefined);
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        setLoading(false);
        closeCashRegisterReport();
        setOpenedCashRegister(undefined);
        setOpenedCashRegisterPosBusinessDay(undefined);
        await printPosBusinessDayReport(posBusinessDayReport.data, true);
    };

    const closePosBusinessDay = async (form: any) => {
        setLoading(true);
        const response = await closePosBusinessDayApi({
            restaurantId,
            managerUserId: pinCodeUserSignedIn?.managerUserId,
            cashRegisterId: cashRegister?.cashRegisterId || openedCashRegister?.cashRegisterId,
            cashRegisterReport: form.cashRegisterReport?.map((report: any) => ({
                paymentMethod: report.paymentMethod,
                customPaymentMethod: report.customPaymentMethod,
                amount: report.amount,
                app: report.app,
                expectedAmount: expectedAmounts?.find(
                    (expectedAmount: CashRegisterExpectedAmountVm) =>
                        expectedAmount.paymentMethod === report.paymentMethod && emptyStringToUndefined(expectedAmount.customPaymentMethod) === emptyStringToUndefined(report.customPaymentMethod)
                )?.amount,
            })),
        });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setLoading(false);
            return;
        }

        setCashRegisterOpen(false);
        setOpenedPosBusinessDay(undefined);
        setOpenedCashRegister(undefined);
        setOpenedCashRegisterPosBusinessDay(undefined);

        const posBusinessDayReport = await getPosBusinessDayReportApi({
            posBusinessDayReportId: response.data.posBusinessDayReportId,
            salesType: salesTypeFilter,
        });

        if (!posBusinessDayReport.ok) {
            setLoading(false);
            closeCashRegisterReport();
            return;
        }

        const responseEmail = await sendEndOfDayReportEmailApi({ posBusinessDayId: response.data.posBusinessDayReportId });
        setLoading(false);

        if (!responseEmail.ok) {
            showSnackbar({ message: translate('Failed to send the report by email. Are you sure an email is already registered?') });
        } else {
            showSnackbar({ message: translate('Report successfully sent out via email') });
        }

        closeCashRegisterReport();
        await printPosBusinessDayReport(posBusinessDayReport.data, true);

        history.push({
            pathname: RoutePaths.POS_BUSINESS_DAY.replace(`:posBusinessDayId(${RegExps.uuid})`, response.data.posBusinessDayReportId),
            search: history.location.search,
        });
    };

    const getTitleText = () => {
        if (posMultipleCashRegistersEnabled) {
            return translate('Close business day of cash register @cashRegisterNumber', { cashRegisterNumber: cashRegister?.cashRegisterPosBusinessDayNumber });
        }
        return translate('Close Cash Register');
    };

    if (!expectedAmounts.length) {
        return (
            <div className={classes.loadingContainer}>
                <Loader loading={true} size={40} />
            </div>
        );
    }

    return <CashRegisterExpectedAmountReport title={getTitleText()} onSubmit={handleSubmit} loading={loading || loadingExpectedAmounts} />;
}

const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        width: '100',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
