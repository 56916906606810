/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { GroupedPosCartItems } from 'src/scenes/letseatmanager/pos/posMenu/GroupedPosCartItems';
import { CourseOrderDetailsAccordion } from 'src/scenes/letseatmanager/pos/posMenu/posCart/CourseOrderDetailsAccordion';
import { CustomerOrderDetailsAccordion } from 'src/scenes/letseatmanager/pos/posMenu/posCart/CustomerOrderDetailsAccordion';
import { PosCourseOrderDetails } from 'src/types/PosCourseOrderDetails';
import { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import { useSelector } from 'src/utils/react/useSelector';

export function PosCartItems(): React.ReactElement {
    const classes = useStyles();
    const menuItemsList = useRef<HTMLDivElement>(null);

    const items = useSelector((state) => state.pos.items);
    const cancelledItems = useSelector((state) => state.pos.cancelledItems);
    const customers = useSelector((state) => state.pos.customers);
    const takeOrderByCourse = useSelector((state) => state.pos.takeOrderByCourse);
    const ongoingCourses = useSelector((state) => state.pos.ongoingCourses);

    useEffect(() => {
        autoScrollItemsList();
    }, [items]);

    const autoScrollItemsList = () => {
        menuItemsList.current?.scroll({ top: menuItemsList.current.scrollHeight, behavior: 'smooth' });
    };

    return (
        <div ref={menuItemsList} className={classes.middleSection}>
            {!customers?.length && !ongoingCourses?.length && <GroupedPosCartItems orderItems={items} cancelledItems={cancelledItems} />}
            {!!customers?.length &&
                customers?.map((customer: PosCustomerOrderDetails) => (
                    <div key={customer.customerNumber}>
                        <CustomerOrderDetailsAccordion customerOrderDetails={customer}>
                            <GroupedPosCartItems orderItems={customer.orderItems} cancelledItems={customer.cancelledItems} />
                        </CustomerOrderDetailsAccordion>
                    </div>
                ))}
            {takeOrderByCourse &&
                ongoingCourses?.map((course: PosCourseOrderDetails) => (
                    <CourseOrderDetailsAccordion course={course}>
                        <GroupedPosCartItems orderItems={course.orderItems} cancelledItems={course.cancelledItems} />
                    </CourseOrderDetailsAccordion>
                ))}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    middleSection: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexGrow: 1,
        overflowY: 'auto',
        marginTop: 12,
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            height: '50svh',
        },
    },
}));
