/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { createPromoCodeApi } from 'src/api/letseatadmin/promoCode/createPromoCodeApi';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { DeprecatedFormDiscountTypeSelect } from 'src/components/form/DeprecatedFormDiscountTypeSelect';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { Form } from 'src/components/form/Form';
import { FormAdminCustomersAutocomplete } from 'src/components/form/FormAdminCustomersAutocomplete';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormCompaniesAutocomplete } from 'src/components/form/FormCompaniesAutocomplete';
import { FormCompanyNamesAutocomplete } from 'src/components/form/FormCompanyNamesAutocomplete';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormDateTimePicker } from 'src/components/form/FormDateTimePicker';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormIntegerField } from 'src/components/form/FormIntegerField';
import { FormOrderTypesAutocomplete } from 'src/components/form/FormOrderTypesAutocomplete';
import { FormPaymentMethodsAutocomplete } from 'src/components/form/FormPaymentMethodsAutocomplete';
import { FormPromoTypeSelect } from 'src/components/form/FormPromoTypeSelect';
import { FormRestaurantsAutocomplete } from 'src/components/form/FormRestaurantsAutocomplete';
import { FormRewardTypeSelect } from 'src/components/form/FormRewardTypeSelect';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isAmountDiscount } from 'src/utils/promoCode/isAmountDiscount';
import { isPercentDiscount } from 'src/utils/promoCode/isPercentDiscount';

export function CreatePromoCodeDialog({ open, onClose, onPromoCodeCreated }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { isSubmitting },
        watch,
        control,
    } = form;

    const discountType = watch('discountType');
    const excludeDeliveryCost = watch('excludeDeliveryCost');
    const freeDelivery = watch('freeDelivery');

    const appOnly = useWatch<boolean>({ name: 'appOnly', control });
    const webOnly = useWatch<boolean>({ name: 'webOnly', control });

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        const response = await createPromoCodeApi({
            code: form.code,
            description: form.description,
            promoType: form.promoType,
            rewardType: form.rewardType,
            discount: form.discount,
            excludeDeliveryCost: form.excludeDeliveryCost,
            freeDelivery: form.freeDelivery,
            appOnly: form.appOnly,
            webOnly: form.webOnly,
            discountType: form.discountType,
            countries: undefined,
            restaurantIds: form.restaurantIds,
            customerIds: form.customerIds,
            companyIds: form.companyIds,
            companyNames: form.companyNames,
            orderTypes: form.orderTypes,
            paymentMethods: form.paymentMethods,
            requireAtLeastOneCreditCard: form.requireAtLeastOneCreditCard,
            minOrderAmount: form.minOrderAmount,
            maxDiscountAmount: form.maxDiscountAmount,
            maxUsage: form.maxUsage,
            restaurantPaidPercentage: form.restaurantPaidPercentage,
            startsAt: form.startsAt,
            endsAt: form.endsAt,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onPromoCodeCreated();
        onClose();
    };

    return (
        <Dialog title={translate('Create Promo Code').toUpperCase()} open={open} onClose={handleClose}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name='code' label={translate('Code')} upperCase required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField
                            name='description'
                            label={translate('Description')}
                            helperText={translate('This description should describe what is included in the promo code and will be visible for the customer when they use the promo code.')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormIntegerField name='maxUsage' label={translate('Max Usage')} min={0} />
                    </Grid>
                    <Grid item xs={12}>
                        <DeprecatedFormPercentNumberField name='restaurantPaidPercentage' label={translate('Restaurant Paid Percentage')} min={0} max={100} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormPromoTypeSelect name='promoType' label={translate('Promo Type')} required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormRewardTypeSelect name='rewardType' label={translate('Reward Type')} required />
                    </Grid>
                    <Grid item xs={12}>
                        <DeprecatedFormDiscountTypeSelect name='discountType' label={translate('Discount Type')} required />
                    </Grid>
                    <Grid item xs={12}>
                        {isAmountDiscount(discountType) && <FormCurrencyNumberStringField name='discount' label={translate('Discount')} required />}
                        {isPercentDiscount(discountType) && <DeprecatedFormPercentNumberField name='discount' label={translate('Discount')} required />}
                    </Grid>
                    <Grid item xs={12}>
                        <FormSwitch name='excludeDeliveryCost' label={translate('Exclude delivery cost')} disabled={freeDelivery} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSwitch name='freeDelivery' label={translate('Free Delivery')} disabled={excludeDeliveryCost} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSwitch name='appOnly' label={translate('App Only')} disabled={webOnly} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSwitch name='webOnly' label={translate('Web Only')} disabled={appOnly} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCurrencyNumberStringField name='minOrderAmount' label={translate('Min Order Amount')} min={0} />
                    </Grid>
                    {isPercentDiscount(discountType) && (
                        <Grid item xs={12}>
                            <FormCurrencyNumberStringField name='maxDiscountAmount' label={translate('Max Discount Amount')} min={0} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormRestaurantsAutocomplete name='restaurantIds' label={translate('Restaurants')} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormAdminCustomersAutocomplete name='customerIds' label={translate('Customers')} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCompaniesAutocomplete name='companyIds' label={translate('Companies')} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCompanyNamesAutocomplete name='companyNames' label={translate('Company Names')} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormOrderTypesAutocomplete name='orderTypes' label={translate('Order Types')} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormPaymentMethodsAutocomplete name='paymentMethods' label={translate('Payment Methods')} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox name='requireAtLeastOneCreditCard' label={translate('Require at least one Credit Card')} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormDateTimePicker name='startsAt' label={translate('Starts')} required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormDateTimePicker name='endsAt' label={translate('Ends')} required />
                    </Grid>
                </Grid>
                <FormFixErrorHint />
                <DialogActions className={classes.buttonsContainer}>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Creating').toUpperCase() : translate('Create').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    buttonsContainer: {
        justifyContent: 'center',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onPromoCodeCreated: any;
};
