/**
 * @prettier
 */
import type { PageContextType } from 'src/components/Page';
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';

export function createUserClickedContextualMenuItemLogEvent({ pageContext, label }: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_CLICKED_CONTEXTUAL_MENU_ITEM,
        message: `contextual menu item ${label} clicked`,
        details: `${pageContext.stackTrace}/${normalizeUiStackTrace(`contextual_menu_item_${label}`)}`,
        data: {
            contextualMenuItemId: normalizeUiStackTrace(`contextual_menu_item_${label}`),
            pathId: `${pageContext.stackTrace}/${normalizeUiStackTrace(`contextual_menu_item_${label}`)}`,
            stackTrace: pageContext.stackTrace,
        },
    });
}

type Params = {
    label: string;
    pageContext: PageContextType;
};
