/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import { DiscountTypes } from 'src/constants/DiscountType';
import type { PosState } from 'src/reducers/posReducer';
import { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import { PosTip } from 'src/types/PosTip';
import { refreshPosPaymentState } from 'src/utils/pos/refreshPosPaymentState';

export function syncPaymentFromCustomerPayment(state: PosState): void {
    const totalCustomersDiscount = state.customers?.reduce((totalDiscount: number, customer: PosCustomerOrderDetails) => {
        const discount = BigNumber(totalDiscount)
            .plus(customer.payment?.posDiscount ?? 0)
            .toNumber();

        return discount;
    }, 0);

    const totalCustomersTip = state.customers?.reduce((totalTip: number, customer: PosCustomerOrderDetails) => {
        const tip = BigNumber(totalTip)
            .plus(
                customer.tips?.reduce(
                    (totalTip: number, tip: PosTip) =>
                        BigNumber(totalTip)
                            .plus(tip.tipAmount ?? 0)
                            .toNumber(),
                    0
                ) ?? 0
            )
            .toNumber();
        return tip;
    }, 0);

    if (BigNumber(totalCustomersDiscount ?? 0).isZero()) {
        state.discount = undefined;
    } else {
        state.discount = { discountType: DiscountTypes.AMOUNT, discount: totalCustomersDiscount?.toString() ?? '' };
    }

    if (BigNumber(totalCustomersTip ?? 0).isZero()) {
        state.tips = undefined;
    } else {
        state.tips = state.tips?.filter((tip: PosTip) => !tip.customerNumber) ?? [];

        state.customers?.forEach((customer: PosCustomerOrderDetails) => {
            if (customer.tips) {
                customer.tips?.forEach((tip: PosTip) => {
                    state.tips?.push({
                        tipType: tip.tipType,
                        paymentMethod: tip.paymentMethod,
                        customPaymentMethod: tip.customPaymentMethod,
                        tipAmount: tip.tipAmount ?? '0',
                        tipPercentage: tip.tipPercentage,
                        customerNumber: customer.customerNumber,
                        paymentId: tip.paymentId,
                    });
                });
            }
        });
    }

    refreshPosPaymentState(state);
}
