/**
 * @prettier
 */
import * as React from 'react';

export function CommissionsIcon({ title }: Props): React.ReactElement {
    return (
        <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title ?? <title>{title}</title>}
            <circle cx='20' cy='20' r='20' fill='#A2F5DD' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M20 13C18.7193 13 17.456 13.074 16.2133 13.218C15.2153 13.334 14.5 14.1913 14.5 15.1713V26.5C14.5 26.5817 14.5201 26.6622 14.5584 26.7344C14.5967 26.8066 14.6522 26.8683 14.7199 26.914C14.7876 26.9598 14.8655 26.9883 14.9467 26.997C15.028 27.0057 15.1101 26.9944 15.186 26.964L17.5 26.0387L19.8147 26.964C19.9337 27.0115 20.0663 27.0115 20.1853 26.964L22.5 26.0387L24.814 26.964C24.8899 26.9944 24.972 27.0057 25.0533 26.997C25.1345 26.9883 25.2124 26.9598 25.2801 26.914C25.3478 26.8683 25.4033 26.8066 25.4416 26.7344C25.4799 26.6622 25.5 26.5817 25.5 26.5V15.1713C25.5 14.1913 24.7847 13.334 23.7867 13.218C22.5297 13.0724 21.2654 12.9996 20 13ZM22.3533 17.8533C22.4417 17.7586 22.4897 17.6332 22.4875 17.5037C22.4852 17.3741 22.4327 17.2505 22.3411 17.1589C22.2495 17.0673 22.1259 17.0148 21.9963 17.0126C21.8668 17.0103 21.7415 17.0583 21.6467 17.1467L17.6467 21.1467C17.5975 21.1924 17.5581 21.2476 17.5308 21.309C17.5035 21.3703 17.4888 21.4365 17.4876 21.5037C17.4864 21.5708 17.4988 21.6375 17.5239 21.6997C17.5491 21.762 17.5865 21.8185 17.634 21.866C17.6815 21.9135 17.738 21.9509 17.8003 21.9761C17.8625 22.0012 17.9292 22.0136 17.9963 22.0124C18.0635 22.0112 18.1297 21.9965 18.191 21.9692C18.2524 21.9419 18.3076 21.9025 18.3533 21.8533L22.3533 17.8533ZM17.75 18C17.75 17.8011 17.829 17.6103 17.9697 17.4697C18.1103 17.329 18.3011 17.25 18.5 17.25C18.6989 17.25 18.8897 17.329 19.0303 17.4697C19.171 17.6103 19.25 17.8011 19.25 18C19.25 18.1989 19.171 18.3897 19.0303 18.5303C18.8897 18.671 18.6989 18.75 18.5 18.75C18.3011 18.75 18.1103 18.671 17.9697 18.5303C17.829 18.3897 17.75 18.1989 17.75 18ZM21.5 20.25C21.3011 20.25 21.1103 20.329 20.9697 20.4697C20.829 20.6103 20.75 20.8011 20.75 21C20.75 21.1989 20.829 21.3897 20.9697 21.5303C21.1103 21.671 21.3011 21.75 21.5 21.75C21.6989 21.75 21.8897 21.671 22.0303 21.5303C22.171 21.3897 22.25 21.1989 22.25 21C22.25 20.8011 22.171 20.6103 22.0303 20.4697C21.8897 20.329 21.6989 20.25 21.5 20.25Z'
                fill='#359478'
            />
        </svg>
    );
}

type Props = {
    title?: string;
};
