/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { CalculatorIcon } from 'src/icons/CalculatorIcon';

export function PerformCountingToolbarIcon({ tooltip, onClick, disabled }: Props): React.ReactElement {
    const classes = useStyles();
    return (
        <Button onClick={onClick} disabled={disabled} icon classes={{ button: classes.button }}>
            <Tooltip text={tooltip || translate('Refresh')}>
                <CalculatorIcon title={tooltip || 'Do Count'} />
            </Tooltip>
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        height: 50,
        width: 50,
    },
}));
type Props = {
    tooltip?: string;
    disabled?: boolean;
    onClick: any;
};
