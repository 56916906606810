/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SupplierId } from 'src/types/Id';

export async function removeSupplierApi(request: RemoveSupplierApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('supplier/removeSupplierApi', request);
}

export type RemoveSupplierApiRequest = {
    supplierId: SupplierId;
};
