/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { savePosOrderOffline } from 'src/localStorage/savePosOrderOffline';
import { posReducer } from 'src/reducers/posReducer';
import { useChargeOrder } from 'src/services/pos/useChargeOrder';
import { useClearPos } from 'src/services/pos/useClearPos';
import { useMapOrderItemsToCartItems } from 'src/services/pos/useMapOrderItemsToCartItems';
import { usePrintOrderCommand } from 'src/services/printer/usePrintOrderCommand';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { findItemsChangedInOrder } from 'src/utils/pos/findItemsChangedInOrder';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSaveNewPosOrder } from 'src/utils/react/useSaveNewPosOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { useUpdatePosPendingOrder } from 'src/utils/react/useUpdatePosPendingOrder';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function PosCartSecondaryButton(): React.ReactElement | null {
    const classes = useStyles();

    const mapOrderItemsToCartItems = useMapOrderItemsToCartItems();
    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();
    const [userHasRolePermission] = useUserHasRolePermission();

    const [printOrderCommand] = usePrintOrderCommand();
    const { updatePosPendingOrder } = useUpdatePosPendingOrder();
    const { saveNewPosOrder } = useSaveNewPosOrder();
    const { handlePay } = useChargeOrder();
    const history = useHistory();
    const clearPos = useClearPos();

    const [loading, setLoading] = useState(false);

    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const orderType = useSelector((state) => state.pos.orderType);
    const hasPendingOrder = useSelector((state) => !!state.pos.pendingOrder);
    const posPendingOrdersEnabled = useSelector((state) => state.app.restaurant.posPendingOrdersEnabled);
    const acceptManuallyAllOrdersEnabled = useSelector((state) => state.app.restaurant?.acceptManuallyAllOrdersEnabled);
    const total = useSelector((state) => state.pos.payment?.total);
    const items = useSelector((state) => state.pos.items);
    const cancelledItems = useSelector((state) => state.pos.cancelledItems);
    const orderOpenedFromTable = useSelector((state) => state.pos.orderOpenedFromTable);
    const disabledPosPaymentActions = useSelector((state) => state.pos.disabledPosPaymentActions);
    const table = useSelector((state) => state.pos.table);
    const numberOfCustomers = useSelector((state) => state.pos.numberOfCustomers);

    const setPendingOrder = useAction(posReducer.actions.setPendingOrder);
    const setItems = useAction(posReducer.actions.setItems);
    const updateOrder = useAction(app2.actions.updateOrder);

    const itemsToPrint = findItemsChangedInOrder(pendingOrder?.orderItems ?? ([] as any), items);
    const disabledButtons = loading || (!total && (!itemsToPrint?.length || !cancelledItems?.length));

    const handleSavePendingOrder = async () => {
        await saveNewPendingOrder(OrderStatuses.PENDING);

        if (orderOpenedFromTable) return history.goBack();
    };

    const updatePendingOrder = async (closePos?: boolean) => {
        if (loading || disabledPosPaymentActions) return;
        setLoading(true);
        const { response, request } = await updatePosPendingOrder({
            firstName: pendingOrder?.firstName,
            lastName: pendingOrder?.lastName,
            mobileNumber: pendingOrder.customerMobileNumber,
            email: pendingOrder.email,
            customerNote: pendingOrder.customerNote,
        });
        setLoading(false);

        const itemsToPrint = findItemsChangedInOrder(pendingOrder?.orderItems ?? ([] as any), request.orderItems ?? []);
        const removedItemsToPrint = findItemsChangedInOrder(pendingOrder?.cancelledItems ?? ([] as any), request.cancelledItems ?? []);

        if (!response.ok) {
            if (response.problem === 'NETWORK_ERROR') {
                savePosOrderOffline(request);
                await printOrderCommand({ ...request, orderItems: itemsToPrint, cancelledItems: removedItemsToPrint } as any);
                return;
            }
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        setPendingOrder(response.data);
        setItems(mapOrderItemsToCartItems(response.data.orderItems));
        updateOrder({ order: response.data });

        if (closePos) {
            clearPos();
        }

        await printOrderCommand({ ...response.data, orderItems: itemsToPrint, cancelledItems: removedItemsToPrint } as any);
    };

    const saveNewPendingOrder = async (savePendingOrderStatus?: undefined | string) => {
        if (loading || disabledPosPaymentActions) return;

        setLoading(true);
        const { response, request } = await saveNewPosOrder({
            numberOfCustomers: table?.numberOfCustomers ?? numberOfCustomers,
            orderStatus: savePendingOrderStatus as any,
        });
        setLoading(false);

        if (!response.ok) {
            if (response.problem === 'NETWORK_ERROR') {
                const orderCommand = { ...request, orderItems: request.orderItems, cancelledItems } as any;
                await printOrderCommand(orderCommand);
                savePosOrderOffline(request as any);
                return;
            }

            return alertKnownErrorOrSomethingWentWrong(response);
        }

        if (response.data.orderStatus !== OrderStatuses.COMPLETE) updateOrder({ order: response.data });

        setPendingOrder(response.data);
        setItems(mapOrderItemsToCartItems(response.data.orderItems));

        if (savePendingOrderStatus && isPendingOrder(savePendingOrderStatus as any)) {
            clearPos();
        }

        await printOrderCommand(response.data);
    };

    const handleUpdatePendingOrder = async () => {
        await updatePendingOrder(true);

        if (orderOpenedFromTable) return history.goBack();
    };

    const handlePrintCommandOrder = async () => {
        if (hasPendingOrder) {
            await updatePendingOrder();
            return;
        }
        await saveNewPendingOrder();
    };

    const getSecondaryButtonAction = () => {
        if (isEatHereOrder(orderType) && !hasPendingOrder && posPendingOrdersEnabled) return handlePrintCommandOrder();
        if (isEatHereOrder(orderType) && hasPendingOrder && posPendingOrdersEnabled && userHasRolePermission(RolePermissions.CHARGE_ORDERS)) return handlePay();
        if (isTakeAwayOrder(orderType) && !hasPendingOrder && posPendingOrdersEnabled) return handleSavePendingOrder();
        if (isTakeAwayOrder(orderType) && hasPendingOrder) return handleUpdatePendingOrder();
    };

    const getSecondaryButtonLabel = () => {
        if (acceptManuallyAllOrdersEnabled) return null;
        if (isTakeAwayOrder(orderType) && !userHasRolePermission(RolePermissions.CHARGE_ORDERS)) return null;

        if (isEatHereOrder(orderType) && !hasPendingOrder && posPendingOrdersEnabled) return translate('Send and follow command');
        if (isEatHereOrder(orderType) && hasPendingOrder && posPendingOrdersEnabled && userHasRolePermission(RolePermissions.CHARGE_ORDERS)) return translate('Charge and close');
        if (isTakeAwayOrder(orderType) && !hasPendingOrder && posPendingOrdersEnabled) return translate('Save Without Charging');
        if (isTakeAwayOrder(orderType) && hasPendingOrder) return translate('Save Without Charging');
        return null;
    };

    if (!getSecondaryButtonLabel()) return null;

    return (
        <Button
            secondary
            onClick={getSecondaryButtonAction}
            disabled={disabledButtons}
            classes={{ button: classNames(classes.basicButton, (classes as any).secondaryButton, posInterfaceLargerEnabled ? classes.largerButton : '') }}
        >
            {getSecondaryButtonLabel()}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    basicButton: {
        width: '100%',
        height: 50,
        color: theme.palette.text.secondary,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    largerButton: {
        fontSize: 15,
        height: 52,
    },
}));
