/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function changeRestaurantOpeningHoursApi(request: ChangeOpeningHoursApiRequest): ApiSauceResponse<ChangeRestaurantOpeningHoursApiResponse> {
    return letseatmanagerApiMethod('restaurant/changeRestaurantOpeningHoursApi', request);
}

export type ChangeOpeningHoursApiRequest = {
    restaurantId: RestaurantId;
    hours: string;
    pickupStationClosingTime?: string;
    pickupStationDeliveryTime?: string;
    sameOpeningHoursForAllDays?: boolean;
};

export type ChangeRestaurantOpeningHoursApiResponse = {
    didiFoodHoursSyncSucceeded?: {
        syncStatus: boolean;
        message: string;
    };
    uberEatsHoursSyncSucceeded?: {
        syncStatus: boolean;
        message: string;
    };
    rappiHoursSyncSucceeded?: {
        syncStatus: boolean;
        message: string;
    };
};
