/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { DraggableListContext } from 'src/components/DraggableList';
import { DragIcon } from 'src/icons/DragIcon';
import { classNames } from 'src/utils/react/classNames';

export function DraggableListItem({ children, value, iconColor, classes }: Props): React.ReactElement {
    const defaultClasses = useStyles();

    const itemRef = useRef<HTMLLIElement>(null);
    const context = useContext(DraggableListContext);
    const { onDragStart, onDragEnd, initializeItem, onUnmountItem } = context;

    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        return () => {
            onUnmountItem(value);
        };
    }, []);

    const handleMouseDown = (e: any) => {
        setIsFocused(true);
        onDragStart?.(itemRef.current, value, e);
    };

    const handleMouseUp = () => {
        if (!isFocused) return;

        setIsFocused(false);
        onDragEnd?.(itemRef.current);
    };

    const initializeRef = (ref: HTMLLIElement) => {
        initializeItem?.(ref, value);
        (itemRef as any).current = ref;
    };

    return (
        <li
            className={classNames(defaultClasses.container, isFocused ? defaultClasses.containerFocused : '', classes?.container)}
            ref={initializeRef}
            onMouseLeave={handleMouseUp}
            onMouseUp={handleMouseUp}
        >
            <div
                className={classNames(defaultClasses.iconContainer, classes?.iconContainer)}
                onTouchStart={handleMouseDown}
                onTouchEnd={handleMouseUp}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
            >
                <DragIcon color={iconColor} />
            </div>
            {children}
        </li>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        top: 0,
        display: 'flex',
        flexDirection: 'row',
        padding: '8px 16px',
        position: 'relative',
        left: 0,
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        minWidth: 100,
        backgroundColor: theme.palette.surface.primary,
        minHeight: 50,
        boxSizing: 'border-box',
        flexShrink: 0,
        width: '100%',
    },
    containerFocused: {
        borderBottom: '0px',
        zIndex: 2,
    },
    iconContainer: {
        placeItems: 'center',
        marginRight: 12,
        cursor: 'grab',
        display: 'flex',
    },
}));

type Props = {
    children: React.ReactNode;
    value: any;
    iconColor?: string;
    classes?: {
        container?: string;
        iconContainer?: string;
    };
};
