/**
 * @prettier
 */
import { CashRegisterExpectedAmountVm } from 'src/api/letseatmanager/pos/getCashRegisterExpectedAmountsApi';
import { useExpectedAmountsActions } from 'src/services/posBusinessDay/businessReportExpectedAmountsStore';

export function useSetExpectedAmounts(): (expectedAmounts: Array<CashRegisterExpectedAmountVm>) => void {
    const setExpectedAmounts = useExpectedAmountsActions((actions) => actions.setExpectedAmounts);

    return setExpectedAmounts;
}
