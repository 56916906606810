/**
 * @prettier
 */
import { FormSelect } from '@pidedirecto/ui/form';
import * as React from 'react';
import { FiscalEntities } from 'src/constants/FiscalEntity';
import { translate } from 'src/i18n/translate';

export function FormFiscalEntitySelect({ name, label, placeholder, helperText, defaultValue, disabled, required }: Props): React.ReactElement {
    const options = Object.values(FiscalEntities).map((fiscalEntity) => ({ label: translate(`FiscalEntities.${fiscalEntity}`), value: fiscalEntity }));
    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={{
                value: !!required,
                message: translate('This field is required'),
            }}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?: boolean;
};
