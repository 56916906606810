/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesType } from 'src/constants/SalesTypes';
import type { RestaurantId } from 'src/types/Id';

export async function getByDayRestaurantStatisticsApi(request: GetByDayRestaurantStatisticsRequest): ApiSauceResponse<GetByDayStatisticsApiResponse> {
    return pidedirectoReportsMethod('/byDayPageReportApi', request);
}

export type GetByDayRestaurantStatisticsRequest = {
    report: string;
    reportPage?: string;
    abcTableFilter?: string;
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
    salesType: SalesType;
};

export type GetByDayStatisticsApiResponse = Array<{
    name: string;
    restaurantId: RestaurantId;
    ordersHeatMap: OrdersHeatMap;
    salesByHour: Array<SalesByHour>;
    salesByWeekDay: Array<SalesByWeekDay>;
    maxValueForHeatMap: number;
}>;

export type OrdersHeatMap = {
    monday: Array<number>;
    tuesday: Array<number>;
    wednesday: Array<number>;
    thursday: Array<number>;
    friday: Array<number>;
    saturday: Array<number>;
    sunday: Array<number>;
    hour: Array<number>;
};

export type SalesByHour = {
    hourTime: string;
    total: number;
};

export type SalesByWeekDay = {
    orderVolume: number;
    total: number;
    weekDay: string;
    weekDayIndex: number;
};
