/**
 * @prettier
 */
import type { UserType } from 'src/constants/UserType';
import { useSelector } from 'src/utils/react/useSelector';

export function useUserType(): UserType {
    const userType = useSelector((state) => state.authentication.userType) ?? [];
    const pinCodeUser = useSelector((state) => state.authentication.pinCodeUserSignedIn);

    return pinCodeUser?.userType ?? userType;
}
