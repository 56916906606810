/**
 * @prettier
 */
import { FormAutocomplete } from '@pidedirecto/ui/form';
import { useLoadApi } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { findAllRestaurantInvoicesLegalInfoApi } from 'src/api/letseatmanager/invoice/findAllRestaurantInvoicesLegalInfoApi';
import type { InvoicesLegalInfoVm } from 'src/api/letseatmanager/types/InvoicesLegalInfoVm';
import { useSelector } from 'src/utils/react/useSelector';

export function FormRestaurantInvoiceFiscalIdAutocomplete({ name, label, placeholder, onSelect, onInputChange, required, helperText, validate, disabled }: Props): React.ReactElement {
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, options] = useLoadApi(findAllRestaurantInvoicesLegalInfoApi, { restaurantId }, { initialValue: [], dependencies: [restaurantId], requiredValues: [restaurantId] });

    const handleItem = (item: InvoicesLegalInfoVm) => {
        onSelect(item);
    };

    const renderOption = (option: InvoicesLegalInfoVm) => {
        return (
            <span onClick={() => handleItem(option)}>
                {option.fiscalId} - {option.businessName} - {option.address}
            </span>
        );
    };

    return (
        <FormAutocomplete
            name={name}
            data={options}
            getOptionLabel={(option) => option.fiscalId}
            getOptionValue={(option) => option.fiscalId}
            renderOption={renderOption}
            label={label}
            disabled={disabled}
            onInputChange={(value, e) => onInputChange(value, e)}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    validate?: any;
    required?: boolean;
    onSelect: (invoiceLegalInfo: InvoicesLegalInfoVm) => void;
    onInputChange: (value: string, e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
};
