/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { findCompoundSupplyReportsApi } from 'src/api/letseatmanager/production/findCompoundSupplyReportsApi';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { SecuredContent } from 'src/components/SecuredContent';
import { Table } from 'src/components/Table';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { CompoundSupplyProductionDetailsDialog } from 'src/scenes/letseatmanager/inventory/production/CompoundSupplyProductionDetailsDialog';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { CompoundSupplyReportId } from 'src/types/Id';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { toShortId } from 'src/utils/uuid/toShortId';

export function CompoundSupplyProductionTable(): React.ReactElement {
    const classes = useStyles();
    const history = useHistory();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [productionDetailsDialogState, setCompoundSupplyProductionDetailsDialogState] = useState<{
        open: boolean;
        compoundSupplyReportId: CompoundSupplyReportId | undefined;
        date: Date | undefined;
    }>({ open: false, compoundSupplyReportId: undefined, date: undefined });

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loadingProductions, compoundSupplyProductions, load] = useLoadApi(findCompoundSupplyReportsApi, { restaurantId }, { requiredValues: [restaurantId], dependencies: [restaurantId] });

    const goToCreateProductionPage = () => {
        history.push({
            pathname: RoutePaths.INVENTORY_CREATE_SUPPLY_MASSIVE_PRODUCTION,
            search: history.location.search,
        });
    };

    return (
        <div className={classes.container}>
            <CompoundSupplyProductionDetailsDialog
                open={productionDetailsDialogState.open}
                onClose={() => setCompoundSupplyProductionDetailsDialogState({ open: false, compoundSupplyReportId: undefined, date: undefined })}
                compoundSupplyReportId={productionDetailsDialogState.compoundSupplyReportId}
                date={productionDetailsDialogState.date as any}
            />

            <Table
                data={compoundSupplyProductions?.map((production: any) => {
                    return {
                        compoundSupplyReportId: production.compoundSupplyReportId,
                        createdAt: moment(production.createdAt).format('DD/MM/YYYY'),
                        createdSuppliesQuantity: production.totalCompoundSuppliesProduced,
                        createdBy: production.managerUserName,
                        total: formatAsCurrencyNumber(production.fixedCost),
                    };
                })}
                loading={loadingProductions}
                columns={[
                    {
                        name: '',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'compoundSupplyReportId',
                        label: translate('ID'),
                        options: {
                            filter: false,
                            customBodyRender: (value: CompoundSupplyReportId) => {
                                return <span># {toShortId(value)}</span>;
                            },
                        },
                    },
                    {
                        name: 'createdAt',
                        label: translate('Date'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'createdSuppliesQuantity',
                        label: translate('Supplies'),
                        options: {
                            filter: false,
                        },
                    },

                    {
                        name: 'createdBy',
                        label: translate('User'),
                        options: {
                            filter: false,
                            customBodyRender: (value: string, tableMeta: any) => {
                                return <span>{value || translate('No User')}</span>;
                            },
                        },
                    },
                    {
                        name: 'total',
                        label: translate('Total'),
                        options: {
                            filter: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    filter: false,
                    print: false,
                    viewColumns: false,
                    customToolbar: () => (
                        <>
                            <SecuredContent rolePermission={RolePermissions.PRODUCE_COMPOUND_SUPPLY}>
                                <CreateToolbarButton tooltip={translate('Produce')} onClick={goToCreateProductionPage} />
                            </SecuredContent>
                            <RefreshToolbarButton onClick={load} />
                        </>
                    ),
                    onRowClick: (rowData: any, rowMeta: any) => {
                        setCompoundSupplyProductionDetailsDialogState({
                            open: true,
                            compoundSupplyReportId: compoundSupplyProductions[rowMeta.dataIndex].compoundSupplyReportId!,
                            date: compoundSupplyProductions[rowMeta.dataIndex].createdAt!,
                        });
                    },
                    textLabels: {
                        body: {
                            noMatch: translate('Sorry, no matching records found'),
                            toolTip: translate('Sort'),
                            columnHeaderTooltip: (column: any) => `${translate('Sort by')} ${column.label}`,
                        },
                        pagination: {
                            next: translate('Next Page'),
                            previous: translate('Previous Page'),
                            rowsPerPage: translate('Rows per page:'),
                            displayRows: translate('of'),
                        },
                        toolbar: {
                            search: translate('Search'),
                            downloadCsv: translate('Download CSV'),
                            print: translate('Print'),
                        },
                    },
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        margin: 0,
        marginTop: 50,
        position: 'relative',
        zIndex: 5,
        width: 'fit-content',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 13,
    },
    status: {
        fontFamily: theme.typography.regular,
        fontSize: 13,
        padding: '7px 17px',
        borderRadius: 5,
    },
    pending: {
        backgroundColor: '#F6E3A7',
        color: '#5E4400',
    },
    completed: {
        backgroundColor: '#CBF7E9',
        color: '#024B49',
    },
    rejected: {
        backgroundColor: '#EB5757',
        color: '#5E0000',
    },
    sent: {
        backgroundColor: '#C9E2FF',
        color: '#124289',
    },
    cancelled: {
        backgroundColor: '#EB5757',
        color: '#5E0000',
    },
    contextualMenu: {
        '& .MuiPaper-root': {
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.10  )',
            borderRadius: 5,
            padding: 0,
        },
        '& .MuiMenuItem-root': {
            fontFamily: theme.typography.regular,
            fontSize: 15,
            color: '#6C7076',
            padding: '10px 20px',
            '&:hover': {
                backgroundColor: '#F2F2F2',
            },
        },
    },
}));
