/**
 * @prettier
 */
export const PaymentTerminalPaymentStatuses = {
    SENT_TO_TERMINAL: 'SENT_TO_TERMINAL',
    RECEIVED_BY_TERMINAL: 'RECEIVED_BY_TERMINAL',
    PAID: 'PAID',
    FAILED: 'FAILED',
} as const;

export type PaymentTerminalPaymentStatus = typeof PaymentTerminalPaymentStatuses[keyof typeof PaymentTerminalPaymentStatuses];
