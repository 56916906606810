/**
 * @prettier
 */
import { useEffect } from 'react';
import { appReducer } from 'src/reducers/appReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function useDeveloperMode(): boolean {
    const developerMode = useSelector((state) => state.app.developerMode);

    const setShowDeveloperModeAvailableLabel = useAction(appReducer.actions.setShowDeveloperModeAvailableLabel);

    useEffect(() => {
        setShowDeveloperModeAvailableLabel(true);
        return () => {
            setShowDeveloperModeAvailableLabel(false);
        };
    }, []);

    return developerMode;
}
