/**
 * @prettier
 */
import { Checkbox, Divider, FormControlLabel, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import moment from 'moment-timezone';
import * as React from 'react';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { hideMenuItemsApi } from 'src/api/letseatmanager/menuItem/hideMenuItemsApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { BasketIcon } from 'src/icons/BasketIcon';
import { BookIcon } from 'src/icons/BookIcon';
import { WarningIcon } from 'src/icons/WarningIcon';
import { getDontShowMinimumInventoryReachedAlertAgainInLocalStorage } from 'src/localStorage/getDontShowMinimumInventoryReachedAlertAgainInLocalStorage';
import { setDontShowMinimumInventoryReachedAlertAgainInLocalStorage } from 'src/localStorage/setDontShowMinimumInventoryReachedAlertAgainInLocalStorage';
import { MinimumSupplyLimit } from 'src/types/MinimumSupplyLimit';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';
import { isKioskUser } from 'src/utils/user/isKioskUser';

export function MinimumInventoryReachedAlert(): React.ReactElement | null {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const [userHasRolePermission] = useUserHasRolePermission();

    const [dontShowAgain, setDontShowAgain] = useState(false);

    const minimumSupplies = useSelector((state) => state.app2.minimumInventoryReachedAlertState.minimumSuppliesLimit);
    const open = useSelector((state) => state.app2.minimumInventoryReachedAlertState.open);
    const inventoryEnabled = useSelector((state) => state.app.restaurant?.inventoryEnabled);
    const userType = useSelector((state) => state.authentication.userType);

    const closeMinimumInventoryReachedAlert = useAction(app2.actions.closeMinimumInventoryReachedAlert);

    const userHasRolePermissionForUpgradeInventory = userHasRolePermission(RolePermissions.UPDATE_INVENTORY);
    const recipesAffectedByMinimumInventory = minimumSupplies?.map((item: MinimumSupplyLimit) => item.recipeIds).flat();
    const userHasNeededInventoryPermissions = userHasRolePermission(RolePermissions.INVENTORY_PAGE) || userHasRolePermission(RolePermissions.UPDATE_INVENTORY);

    const dontShowMinimumInventoryReachedAlertAgain = getDontShowMinimumInventoryReachedAlertAgainInLocalStorage();

    const handleTurnOffProducts = async () => {
        const menuItemIds = minimumSupplies.map((item) => item.menuItemIds).flat();
        await hideMenuItemsApi({ menuItemIds });
        handleCloseMinimumInventoryReachedAlert();
    };

    const handleCloseMinimumInventoryReachedAlert = () => {
        setDontShowMinimumInventoryReachedAlertAgainInLocalStorage(dontShowAgain);
        closeMinimumInventoryReachedAlert();
    };

    const handleGoToInventory = () => {
        history.replace({
            pathname: RoutePaths.INVENTORY,
            search: location.search,
        });
        handleCloseMinimumInventoryReachedAlert();
    };

    const handleDontShowAgain = () => {
        setDontShowAgain(!dontShowAgain);
    };

    if (dontShowMinimumInventoryReachedAlertAgain.dontShowMinimumInventoryReachedAlertAgain && moment().isBefore(moment(dontShowMinimumInventoryReachedAlertAgain.lastTimeOpened).add(1, 'hour'))) {
        return null;
    }

    if (!userHasNeededInventoryPermissions || !minimumSupplies || !open || isKioskUser(userType)) {
        return null;
    }

    return (
        <Alert classes={{ root: classes.alertContainer }} action={false} icon={false} onClose={handleCloseMinimumInventoryReachedAlert}>
            <AlertTitle classes={{ root: classes.headerContainer }}>
                <WarningIcon />
                <span className={classes.alertTitle}> {translate('Minimum inventory reached (@numberOfSupplies)', { numberOfSupplies: minimumSupplies.length })}</span>
            </AlertTitle>

            <List className={classes.listContainer}>
                {minimumSupplies?.map((item: MinimumSupplyLimit) => (
                    <ListItem className={classes.listItem} key={item.name}>
                        <ListItemIcon className={classes.ListItemIcon}>
                            <BasketIcon />
                        </ListItemIcon>
                        <ListItemText
                            className={classes.listItemText}
                            primary={
                                <Typography>
                                    <strong> {item.quantity} </strong>
                                    {translate('Pieces of @supplyItem', { supplyItem: item.name })}
                                </Typography>
                            }
                        />
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                <ListItem alignItems='flex-start' className={classes.listItem}>
                    <ListItemIcon className={classes.ListItemIcon}>
                        <BookIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography>
                                <Typography>
                                    <strong> {recipesAffectedByMinimumInventory.length} </strong>
                                    {translate('Recipes affected')}
                                </Typography>
                            </Typography>
                        }
                    />
                </ListItem>
            </List>
            <FormControlLabel
                classes={{ label: classNames(classes.checkBoxLabel), root: classNames(classes.checkBox) }}
                control={<Checkbox checked={dontShowAgain} onChange={handleDontShowAgain} name='dontShowAgain' color='primary' />}
                label={translate('Do not show again for 1 hour')}
            />
            <div className={classes.buttonsContainer}>
                {inventoryEnabled && (
                    <SecuredContent rolePermission={RolePermissions.INVENTORY_PAGE}>
                        <Button onClick={() => handleGoToInventory()} text>
                            {translate('Go to Inventory')}
                        </Button>
                    </SecuredContent>
                )}
                {userHasRolePermissionForUpgradeInventory && (
                    <Button onClick={handleTurnOffProducts} outlined>
                        {translate('Turn off products')}
                    </Button>
                )}
                <Button onClick={handleCloseMinimumInventoryReachedAlert}>{translate('Accept')}</Button>
            </div>
        </Alert>
    );
}

const useStyles = makeStyles((theme) => ({
    alertContainer: {
        padding: 16,
        fontFamily: theme.typography.medium,
        position: 'absolute',
        top: 120,
        background: 'white',
        borderRadius: 12,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 8px 20px -4px rgba(23, 24, 24, 0.12)',
        width: 'fit-content',
        zIndex: 50,
        [theme.breakpoints.up('sm')]: {
            minWidth: 300,
            right: 40,
        },
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding: '10px 15px',
        borderRadius: 10,
        backgroundColor: '#FBF3DA',
    },
    alertTitle: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        color: '#59491D',
        [theme.breakpoints.up('sm')]: {
            fontSize: 16,
        },
    },
    turnOffProductsButton: {
        color: '#63D6B3',
    },
    showInventoryButton: {
        fontSize: 12,
        textDecoration: 'underline',
        textTransform: 'none',
        border: 'none',
        height: 29,
        color: '#63D6B3',
        marginTop: 15,
        [theme.breakpoints.up('sm')]: {
            fontSize: 13,
        },
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    listContainer: {
        alignItems: 'center',

        borderRadius: 10,
        overflowY: 'scroll',
        maxHeight: 200,
    },
    listItem: {
        height: 40,
        hover: 'none',
    },
    listItemText: {
        fonTStyle: 'normal',
        fontSize: 14,
        fontFamily: theme.typography.medium,
        color: '#59491D',
        [theme.breakpoints.up('sm')]: {
            fontSize: 13,
        },
    },
    icon: {
        color: '#59491D',
        width: 20,
        minWidth: 20,
    },
    ListItemIcon: {
        color: '#59491D',
        width: 30,
        minWidth: 20,
    },
    checkBoxLabel: {
        fontSize: 8,
        color: '#59491D',
        [theme.breakpoints.up('sm')]: {
            fontSize: 13,
        },
    },
    checkBox: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    buttonsContainer: {
        display: 'flex',
        gap: 12,
    },
}));
