/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CashRegisterPosBusinessDayId } from 'src/types/Id';

export async function sendCashRegisterPosBusinessDayReportEmailApi(request: SendCashRegisterPosBusinessDayReportEmailApiRequest): ApiSauceResponse<void> {
    return pidedirectoReportsMethod('/sendCashRegisterPosBusinessDayReportEmailApi', request);
}

type SendCashRegisterPosBusinessDayReportEmailApiRequest = {
    cashRegisterPosBusinessDayId: CashRegisterPosBusinessDayId;
};
