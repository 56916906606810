/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useEffect } from 'react';
import { app2 } from 'src/app2';
import { NewActiveCustomersChart } from 'src/scenes/letseatadmin/statistics/customer/ActiveCustomersChangeChart';
import { ActiveInactiveChart } from 'src/scenes/letseatadmin/statistics/customer/ActiveInactiveChart';
import { ActivePercentageChart } from 'src/scenes/letseatadmin/statistics/customer/ActivePercentageChart';
import { CustomerActivityChart } from 'src/scenes/letseatadmin/statistics/customer/CustomerActivityChart';
import { CustomersChart } from 'src/scenes/letseatadmin/statistics/customer/CustomersChart';
import { NewInActiveCustomersChart } from 'src/scenes/letseatadmin/statistics/customer/InActiveCustomersChangeChart';
import { NewDownloadsChart } from 'src/scenes/letseatadmin/statistics/customer/NewDownloadsChart';
import { NewPayingCustomersChart } from 'src/scenes/letseatadmin/statistics/customer/NewPayingCustomersChart';
import { NewSignUpsChart } from 'src/scenes/letseatadmin/statistics/customer/NewSignUpsChart';
import type { PeriodScale, XAxisScale } from 'src/scenes/letseatadmin/statistics/TimeLineBarChart';
import type { StatisticsVm } from 'src/scenes/letseatadmin/StatisticsPage';
import { useAction } from 'src/utils/react/useAction';

type Props = {
    statistics: StatisticsVm;
    period: PeriodScale;
    xAxis: XAxisScale;
};

export function CustomerStatsPage({ statistics, period, xAxis }: Props): React.ReactElement {
    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle('Statistics > CustomersInfo');
    }, []);

    return (
        <div style={{ padding: 24, overflow: 'visible' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Customer Activity
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CustomerActivityChart statistics={statistics} period={period} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={6} />

                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Downloads / Sign Up / Paying
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CustomersChart statistics={statistics} period={period} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={6} />

                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Downloads Change
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <NewDownloadsChart statistics={statistics} period={period} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={6} />

                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Sign Ups Change
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <NewSignUpsChart statistics={statistics} period={period} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={6} />

                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Paying Customers Change
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <NewPayingCustomersChart statistics={statistics} period={period} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={6} />

                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Active / In-Active Customers
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <ActiveInactiveChart statistics={statistics} period={period} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <ActivePercentageChart statistics={statistics} period={period} xAxis={xAxis} />
                </Grid>

                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Active Customers Change
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <NewActiveCustomersChart statistics={statistics} period={period} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={6} />

                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        In-Active Customers Change
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <NewInActiveCustomersChart statistics={statistics} period={period} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={6} />
            </Grid>
        </div>
    );
}
