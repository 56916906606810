/**
 * @prettier
 */
import { Apps } from 'src/constants/App';
import { Countries } from 'src/constants/Country';
import { CustomerTypes } from 'src/constants/CustomerType';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { OrderTypes } from 'src/constants/OrderType';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { PaymentStatuses } from 'src/constants/PaymentStatus';
import { PickupTimeTypes } from 'src/constants/PickupTimeType';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import type { RestaurantVm } from 'src/types/RestaurantVm';
import { newUuid } from 'src/utils/uuid/newUuid';
import { toShortId } from 'src/utils/uuid/toShortId';

export function getOrderDemo(restaurant: RestaurantVm): OrderVm {
    return {
        orderId: newUuid(),
        email: 'soporte@restaurante.mx' as any,
        totalNumberOfOrders: {
            all: 10,
            completed: 10,
            rejected: 10,
            cancelled: 10,
        },
        shortOrderId: toShortId(newUuid()),
        consecutiveOrderId: '0001',
        restaurantId: restaurant?.restaurantId ?? newUuid(),
        table: '12',
        customerType: CustomerTypes.ACTIVE_1_TO_2,
        restaurantAddress: restaurant?.restaurantStreet ?? 'Av Bernardo Reyes, Ferrocarrilera, Monterrey, N.L., México',
        app: Apps.RAPPI,
        rappiOrderId: '2197699912' as any,
        orderType: OrderTypes.DELIVERY_ORDER,
        waiterName: 'Samantha',
        restaurant: {
            name: restaurant?.name,
            restaurantId: restaurant?.restaurantId,
            phoneNumber: restaurant?.phoneNumber,
        },
        driverTip: '15',
        customerNote: translate('Frequent client'),
        notes: translate('Extra napkins'),
        address: {
            location: { type: 'Point', coordinates: [-100.3316565, 25.7282178] as any },
            street: 'Av Bernardo Reyes',
            number: '1234',
            neighborhood: 'Ferrocarrilera',
            postalCode: '66400',
            other: '2A dos escaleras abajo',
            googlePlaceId: newUuid() as any,
            formattedAddress: 'Av Bernardo Reyes, Ferrocarrilera, Monterrey, N.L., México',
        },
        orderStatus: OrderStatuses.COMPLETE,
        paymentMethod: PaymentMethods.CASH,
        paymentStatus: PaymentStatuses.PAYED,
        customerName: 'Jesús Hernández',
        customerMobileNumber: '+528110135689',
        pickupTime: new Date('2018-01-01T12:15:00.000Z'),
        pickupTimeType: PickupTimeTypes.ASAP,
        manualOrder: true,
        posTipAmount: '50',
        orderItems: [
            {
                menuItemId: newUuid() as any,

                name: translate('Supreme Burger'),
                unitPrice: '200',
                promoUnitPrice: '150',
                promoText: '-25%',
                note: translate('No cheese'),
                quantity: 2,
                modifierGroups: [
                    {
                        name: translate('Extras'),
                        modifiers: [
                            {
                                name: translate('Extra meat'),
                                price: '60',
                                quantity: 1,
                            },
                        ],
                    },
                    {
                        name: translate('Choose your fries'),
                        modifiers: [
                            {
                                name: translate('French fries'),
                                price: '20',
                                quantity: 1,
                                subModifierGroups: [
                                    {
                                        name: translate('Choose your salsa'),
                                        subModifiers: [
                                            { name: translate('Chipotle dip'), price: '15', quantity: 1 },
                                            { name: translate('Red salsa'), price: '15', quantity: 1 },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                menuItemId: newUuid() as any,
                name: 'Coca Cola Zero',
                unitPrice: '40',
                quantity: 1,
                modifierGroups: [],
            },
        ],
        cancelledItems: [
            {
                menuItemId: newUuid() as any,
                removedAt: new Date(),
                name: translate('Supreme Burger'),
                unitPrice: '200',
                promoUnitPrice: '150',
                promoText: '-25%',
                note: translate('No cheese'),
                quantity: 2,
                modifierGroups: [
                    {
                        name: translate('Extras'),
                        modifiers: [
                            {
                                name: translate('Extra meat'),
                                price: '60',
                                quantity: 1,
                            },
                        ],
                    },
                    {
                        name: translate('Choose your fries'),
                        modifiers: [
                            {
                                name: translate('French fries'),
                                price: '20',
                                quantity: 1,
                                subModifierGroups: [
                                    {
                                        name: translate('Choose your salsa'),
                                        subModifiers: [
                                            { name: translate('Chipotle dip'), price: '15', quantity: 1 },
                                            { name: translate('Red salsa'), price: '15', quantity: 1 },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
        amount: '560',
        discount: '100',
        amountWithPromoPriceDiscount: '460',
        amountWithDiscount: '460',
        subtotalAfterDiscount: '460',
        subtotal: '460',
        productDiscount: '100',
        customerDeliveryCost: '35',
        total: '550',
        posChange: '50',
        restaurantTotal: '460',
        country: restaurant?.country ?? Countries.MX,
        currency: restaurant?.currencyFormat?.currency ?? Countries.MX,
        timeZone: restaurant?.timeZone ?? TimeZones.AMERICA_MONTERREY,
        createdAt: new Date(),
        firstOrderAt: new Date(),
        signedUpAt: new Date(),
        modifiedAt: new Date(),
    };
}
