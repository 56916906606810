/**
 * @prettier
 */
import { Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeDeviceApi } from 'src/api/letseatmanager/device/changeDeviceApi';
import { getDeviceApi } from 'src/api/letseatmanager/device/getDeviceApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { DeviceId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useLoadApi } from 'src/utils/react/useLoadApi';

export function ChangeDeviceDialog({ open, deviceId, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [loadingDevice, device] = useLoadApi(getDeviceApi, { deviceId: deviceId! }, { requiredValues: [deviceId] });

    useEffect(() => {
        if (device) {
            form.reset({
                deviceName: device.deviceName,
            });
        }
    }, [device]);

    const handleClose = () => {
        onClose();
    };

    const onSubmit = async (form: any) => {
        if (!deviceId) return;

        setLoading(true);
        const response = await changeDeviceApi({
            deviceId: deviceId,
            deviceName: form.deviceName,
        });
        setLoading(false);
        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        handleClose();
        onSuccess?.();
    };

    return (
        <Dialog title={translate('Change device')} open={open} onClose={handleClose}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name='deviceName' label={translate('Name')} disabled={loading || loadingDevice} />
                    </Grid>
                </Grid>
                <DialogActions className={classes.buttonsContainer}>
                    <Button secondary onClick={handleClose} disabled={loading || loadingDevice} classes={{ button: classes.button }}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading || loadingDevice} classes={{ button: classes.button }}>
                        {loading ? translate('Changing') : translate('Change')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    dialogContent: {
        overflowY: 'hidden',
    },
    buttonsContainer: {
        marginTop: 20,
    },
    button: {
        width: 200,
    },
}));

type Props = {
    open: boolean;
    deviceId?: DeviceId;
    onClose: any;
    onSuccess?: any;
};
