/**
 * @prettier
 */
import { OrderStatus } from 'src/constants/OrderStatus';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isNewOrder } from 'src/utils/order/isNewOrder';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';

export function isOngoingOrder(orderStatus: OrderStatus): boolean {
    return isAcceptedOrder(orderStatus) || isPendingOrder(orderStatus) || isNewOrder(orderStatus);
}
