/**
 * @prettier
 */
import type { CashRegisterVm } from 'src/types/CashRegisterVm';

export const KEY_NAME = 'posCashRegisterOffline';

export function findPosCashRegisterOfflineInLocalStorage(): CashRegisterVm | undefined {
    const item = window.localStorage.getItem('posCashRegisterOffline');
    return item ? JSON.parse(item) : undefined;
}
