/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';

export function withoutIva(value?: string): string | undefined {
    if (!value) {
        return;
    }
    return BigNumber(value).dividedBy(1.16).toFixed(2);
}
