/**
 * @prettier
 */
import moment from 'moment-timezone';
import type { TimeZone } from 'src/constants/TimeZone';

export function daysBetween(date1: string | Date | undefined, date2: string | Date | undefined, timeZone: TimeZone): number | undefined {
    if (!date1 || !date2) {
        return;
    }
    return moment.tz(date1, timeZone).startOf('day').diff(moment.tz(date2, timeZone).startOf('day'), 'days');
}
