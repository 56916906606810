/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomAlertDialogId } from 'src/types/Id';

export async function removeCustomAlertDialogApi(request: RemoveCustomAlertDialogApi): ApiSauceResponse<void> {
    return letseatadminApiMethod('customAlertDialogs/removeCustomAlertDialogApi', request);
}

type RemoveCustomAlertDialogApi = {
    customAlertDialogId: CustomAlertDialogId;
};
