/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { DropDownItem, Switch, Text } from '@pidedirecto/ui';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { RoutePaths } from 'src/constants/RoutePath';
import { SalesTypes } from 'src/constants/SalesTypes';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RightToolBarSalesContextualMenuItem(): React.ReactElement | null {
    const classes = useStyles();
    const location = useLocation();

    const isReportPage = reportPages.includes(location.pathname);

    const salesTypeFilter = useSelector((state) => state.app.salesTypeFilter);

    const setSalesTypeFilter = useAction(appReducer.actions.setSalesTypeFilter);

    const handleShowReportsByNetSales = () => {
        setSalesTypeFilter(salesTypeFilter === SalesTypes.NET_SALES ? SalesTypes.GROSS_SALES : SalesTypes.NET_SALES);
    };

    if (!isReportPage) return null;

    return (
        <DropDownItem classes={{ container: classes.container }}>
            <div className={classes.switchContainer}>
                <Switch
                    aria-label={translate('Net Sales')}
                    name='netSales'
                    onChange={handleShowReportsByNetSales}
                    checked={salesTypeFilter === SalesTypes.NET_SALES}
                    classes={{ switch: classes.switch }}
                />
            </div>
            <Text>{translate('Net Sales')}</Text>
        </DropDownItem>
    );
}

const reportPages = [RoutePaths.RESTAURANT_GENERAL_REPORT, RoutePaths.RESTAURANT_SALES_REPORT, RoutePaths.ORDERS, RoutePaths.RESTAURANT_POS_REPORT, RoutePaths.POS_BUSINESS_DAY];

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: 6,
        paddingBottom: 6,
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        color: theme.palette.text.secondary,
        height: 42,
        minWidth: 250,
    },
    switch: {
        margin: 0,
    },
    switchContainer: {
        minWidth: 50,
        display: 'flex',
        alignItems: 'center',
    },
}));
