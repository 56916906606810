/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function tribalExportFormatApi(request: TribalExportFormatApiRequest): ApiSauceResponse<TribalExportFormatResultVm> {
    return letseatadminApiMethod('restaurantBilling/tribalExportFormatApi', request);
}

type TribalExportFormatApiRequest = {
    from: Date;
    to: Date;
    restaurantIds: Array<RestaurantId>;
};

export type TribalExportFormatResultVm = {
    tribalExportFormat: string;
    exported: number;
    removed: number;
    nothingToTransfer: number;
};
