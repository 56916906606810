/**
 * @prettier
 */
import { useEffect, useState } from 'react';
import * as React from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { RolePermissions } from 'src/constants/RolePermission';
import type { Sound } from 'src/constants/Sound';
import { PinCodeUser } from 'src/types/PinCodeUser';
import { useSelector } from 'src/utils/react/useSelector';

export function FormAvailableWaiterSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, onChange }: Props): React.ReactElement {
    const [availableWaitersOptions, setAvailableWaitersOptions] = useState([]);

    const pinCodeUsers = useSelector((state) => state.authentication.pinCodeUsers);

    useEffect(() => {
        const waiters = getAvailableWaiters();
        setWaiterOptions(waiters);
    }, [required]);

    const getAvailableWaiters = () => {
        const waiters = pinCodeUsers?.filter((user: PinCodeUser) => user.userRolePermissions?.includes(RolePermissions.SERVE_ORDERS));
        return waiters ?? [];
    };

    const setWaiterOptions = (availableWaiters: Array<PinCodeUser>) => {
        const waiterOptions = availableWaiters.map((user) => ({ label: user.name, value: user.managerUserId }));
        if (required) return setAvailableWaitersOptions(waiterOptions as any);
        setAvailableWaitersOptions([{ label: '', value: null }, ...waiterOptions] as any);
    };

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={availableWaitersOptions}
            disabled={disabled}
            required={required}
            variant={variant}
            onChange={onChange}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    onChange?: (arg1: Sound) => any;
};
