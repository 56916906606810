/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { UserType } from 'src/constants/UserType';
import type { ManagerUserId, RestaurantId, UserRoleId } from 'src/types/Id';
import type { ManagerUserVm } from 'src/types/ManagerUserVm';

export async function changeManagerUserApi(request: ChangeManagerUserApiRequest): ApiSauceResponse<ManagerUserVm> {
    return letseatadminApiMethod('managerUser/changeManagerUserApi', request);
}

export type ChangeManagerUserApiRequest = {
    managerUserId: ManagerUserId;
    userRole?: UserRoleId;
    userType: UserType;
    restaurantIds?: Array<RestaurantId>;
};
