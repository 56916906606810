/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomPaymentMethodIcon } from 'src/constants/CustomPaymentMethodIcons';
import { MexicanPaymentMethodCode } from 'src/constants/MexicanPaymentMethodCode';
import type { OrderType } from 'src/constants/OrderType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { PaymentTerminalProvider } from 'src/constants/PaymentTerminalProvider';
import type { RestaurantPaymentMethodApp } from 'src/constants/RestaurantPaymentMethodApps';
import { RestaurantPaymentMethodTypes } from 'src/constants/RestaurantPaymentMethodType';
import type { RestaurantPsp } from 'src/constants/RestaurantPsp';
import type { RestaurantId } from 'src/types/Id';

export async function createRestaurantPaymentMethodApi(request: CreateRestaurantPaymentMethodApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurantPaymentMethod/createRestaurantPaymentMethodApi', request);
}

export type CreateRestaurantPaymentMethodApiRequest = {
    restaurantId: RestaurantId;
    restaurantPaymentMethodType: typeof RestaurantPaymentMethodTypes.RESTAURANT;
    paymentMethod: PaymentMethod;
    mexicanPaymentMethodCode?: MexicanPaymentMethodCode;
    psps?: Array<RestaurantPsp>;
    paymentTerminalProvider?: PaymentTerminalProvider;
    name: string;
    commission?: number;
    paymentReferenceEnabled?: boolean;
    channels?: Array<RestaurantPaymentMethodApp>;
    orderTypes?: Array<OrderType>;
    icon?: CustomPaymentMethodIcon;
};
