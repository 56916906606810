/**
 * @prettier
 */
import type { TableShape } from 'src/constants/TableShape';
import type { TableSize } from 'src/constants/TableSize';
import type { RestaurantId, RestaurantZoneId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export const KEY_NAME = 'posRestaurantZonesOffline';

export function getPosRestaurantZonesOfflineInLocalStorage(): Array<PosRestaurantZonesOffline> {
    const item = window.localStorage.getItem('posRestaurantZonesOffline');
    return item ? JSON.parse(item) : [];
}

export type PosRestaurantZonesOffline = {
    restaurantZoneId: RestaurantZoneId;
    restaurantId: RestaurantId;
    name: string;
    tables?: Array<{
        tableNumber: string;
        tableSize: TableSize;
        tableShape: TableShape;
        top: number;
        left: number;
        rotation: number;
        order?: OrderVm;
    }>;
};
