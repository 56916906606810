/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CashRegisterTransactionVm } from 'src/types/CashRegisterTransactionVm';
import type { PosBusinessDayId } from 'src/types/Id';

export async function findPosBusinessDayCashRegisterTransactionsApi(request: FindPosBusinessDayCashRegisterTransactionsApiRequest): ApiSauceResponse<Array<CashRegisterTransactionVm>> {
    return letseatmanagerApiMethod('pos/findPosBusinessDayCashRegisterTransactionsApi', request);
}

export type FindPosBusinessDayCashRegisterTransactionsApiRequest = {
    posBusinessDayId: PosBusinessDayId;
};
