/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import moment from 'moment/moment';
import * as React from 'react';
import { ResponsiveLineCanvas } from 'src/components/charts/ResponsiveLineCanvas';
import { translate } from 'src/i18n/translate';
import { GraphEmptyState } from 'src/scenes/letseatmanager/posReports/GraphEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CashRegisterReportVm } from 'src/types/CashRegisterReportVm';
import { sum } from 'src/utils/reduce/sum';

export function CashRegisterMaximumSalesReport({ cashRegisterReport }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const getMaximumSalesLineChartData = () => {
        return [
            {
                id: translate('Maximum ticket'),
                data: cashRegisterReport.historicalCashRegisterSales.map((historicalCashRegisterSales) => ({
                    x: moment(historicalCashRegisterSales.createdAt).format('YYYY-MM-DD HH:MM'),
                    y: BigNumber(historicalCashRegisterSales.maximumAmount).toString(),
                })),
            },
            {
                id: translate('Average ticket'),
                data: cashRegisterReport.historicalCashRegisterSales.map((historicalCashRegisterSales) => ({
                    x: moment(historicalCashRegisterSales.createdAt).format('YYYY-MM-DD HH:MM'),
                    y: BigNumber(historicalCashRegisterSales.averageAmount).toString(),
                })),
            },
            {
                id: translate('Minimum ticket'),
                data: cashRegisterReport.historicalCashRegisterSales.map((historicalCashRegisterSales) => ({
                    x: moment(historicalCashRegisterSales.createdAt).format('YYYY-MM-DD HH:MM'),
                    y: BigNumber(historicalCashRegisterSales.minimumAmount).toString(),
                })),
            },
        ];
    };

    const isEmptyReport = () => {
        const cashRegisterSales = cashRegisterReport.historicalCashRegisterSales.map((historicalCashRegisterSales) => historicalCashRegisterSales.averageAmount);
        const totalSales = cashRegisterSales.reduce(sum, BigNumber(0));
        return totalSales.isZero();
    };

    if (isEmptyReport()) {
        return <GraphEmptyState title={translate('Average ticket')} />;
    }

    return (
        <article>
            <h2 className={classes.title}>{translate('Average ticket')}</h2>
            <div className={classes.chartContainer}>
                <ResponsiveLineCanvas
                    data={getMaximumSalesLineChartData()}
                    tooltip={({ point }: { point: { data: { x: string; y: string } } }) => (
                        <div className={classes.tooltip}>
                            <span className={classes.tooltipText}>{translate(`Date: @date`, { date: point.data.x })}</span>
                            <span className={classes.tooltipText}>{translate(`Total: @total`, { total: formatAsCurrencyNumber(point.data.y) })}</span>
                        </div>
                    )}
                    legends={[
                        {
                            anchor: 'top-left',
                            direction: 'row',
                            justify: false,
                            translateX: 0,
                            translateY: -40,
                            itemsSpacing: 20,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 1,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        },
                    ]}
                />
            </div>
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    chartContainer: {
        height: '60vh',
        width: '100%',
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
    },
    tooltip: {
        padding: 10,
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    cashRegisterReport: CashRegisterReportVm;
};
