/**
 * @prettier
 */
import moment from 'moment/moment';
import OpeningHours from 'opening_hours';
import { Day, Days } from 'src/constants/Day';

export function transformHoursToWeekOpeningHours(hours?: string): WeekOpeningHours {
    if (!hours || hours.trim() === '' || hours.trim() === '24/7' || hours.trim() === '00:00-24:00' || hours.trim() === 'Mo-Su 00:00-24:00') {
        return Object.values(Days).map((day: any) => ({ day, openingHours: [{ openingTime: '00:00', closingTime: '24:00' }] }));
    }
    const openingHours = new OpeningHours(hours);
    const currentDate = moment();
    const currentWeekStart = currentDate.startOf('isoWeek').toDate();
    const currentWeekEnd = currentDate.endOf('isoWeek').toDate();
    const intervals = openingHours.getOpenIntervals(currentWeekStart, currentWeekEnd);
    const restaurantOpeningHours = Object.values(Days).map((day) => {
        const openingIntervals = intervals.filter((interval: [string]) => {
            const openingDate = interval[0];
            return DaysEquivalence[day] === new Date(openingDate).getDay();
        });

        if (!openingIntervals.length) return { day, openingHours: [] };
        return {
            day,
            openingHours: openingIntervals.map((interval: [string, string]) => {
                const openingDate = moment(interval[0]);
                const closingDate = moment(interval[1]);
                return {
                    openingTime: openingDate.format('HH:mm'),
                    closingTime: closingDate.format('HH:mm'),
                };
            }),
        };
    });

    const sundaySchedule = restaurantOpeningHours.find((item) => item.day === Days.SUNDAY);
    if (!sundaySchedule?.openingHours.length) return restaurantOpeningHours;

    const mondaySchedule = restaurantOpeningHours.find((item) => item.day === Days.MONDAY);
    const sundayClosingTime = sundaySchedule?.openingHours[sundaySchedule?.openingHours.length - 1].closingTime;
    const firstMondayOpeninHours = mondaySchedule?.openingHours[0];

    if (sundayClosingTime !== '23:59' || firstMondayOpeninHours?.openingTime !== '00:00' || moment(firstMondayOpeninHours?.closingTime, 'HH:mm').isAfter(moment('05:00', 'HH:mm'))) {
        return restaurantOpeningHours;
    }

    sundaySchedule.openingHours[sundaySchedule.openingHours.length - 1].closingTime = firstMondayOpeninHours.closingTime;
    mondaySchedule?.openingHours.shift();

    return restaurantOpeningHours;
}

export type WeekOpeningHours = Array<{
    day: Day;
    openingHours: Array<{
        openingTime: string;
        closingTime: string;
    }>;
}>;

const DaysEquivalence = {
    [Days.MONDAY]: 1,
    [Days.TUESDAY]: 2,
    [Days.WEDNESDAY]: 3,
    [Days.THURSDAY]: 4,
    [Days.FRIDAY]: 5,
    [Days.SATURDAY]: 6,
    [Days.SUNDAY]: 0,
} as const;
