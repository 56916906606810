/**
 * @prettier
 */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import Radio from 'src/components/deprecated/react-final-form/Radio';
import Select from 'src/components/deprecated/react-final-form/Select';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { AddCreditsToolbarButton } from 'src/components/mui-datatables/AddCreditsToolbarButton';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { OpenInNewTabToolbarButton } from 'src/components/mui-datatables/OpenInNewTabToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { SendEmailToolbarButton } from 'src/components/mui-datatables/SendEmailToolbarButton';
import { SendNotificationToolbarButton } from 'src/components/mui-datatables/SendNotificationToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import type { CustomerType } from 'src/constants/CustomerType';
import { Platform, Platforms } from 'src/constants/Platform';
import { RefundMethod, RefundMethods } from 'src/constants/RefundMethod';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import ChangeCustomerDialog, { CHANGE_CUSTOMER } from 'src/scenes/letseatadmin/customer/ChangeCustomerDialog';
import CustomerCreditsDialog, { ADD_CREDITS } from 'src/scenes/letseatadmin/customer/CustomerCreditsDialog';
import { CustomerSendDialog, SEND_NOTIFICATION } from 'src/scenes/letseatadmin/customer/CustomerSendDialog';
import CustomerSendEmailDialog, { SEND_EMAIL } from 'src/scenes/letseatadmin/customer/CustomerSendEmailDialog';
import type { CompanyId, CustomerId, EmployeeIdAtCompany, EmployeeNumberAtCompany, LanguageTag } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { fullName } from 'src/utils/customer/fullName';
import { formatDateTimeSecondsString } from 'src/utils/date/formatDateTimeSecondsString';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { setUrlQueryParameter } from 'src/utils/history/setUrlQueryParameter';
import { copyTextToClipboard } from 'src/utils/html/copyTextToClipboard';
import { useSelector } from 'src/utils/react/useSelector';
import { openCustomerPageInNewTab } from 'src/utils/window/openCustomerPageInNewTab';

export default function CustomerDetails({ customerId, onChangeCustomer }: Props): React.ReactElement {
    const classes = useStyles();
    const viewUser = useSelector((state) => state.authentication.viewUser);
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState(undefined as CustomerVm | undefined);

    useEffect(() => {
        if (!customerId) return;
        load();
    }, [customerId]);

    const load = async () => {
        if (!customerId) {
            return;
        }
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.admin.getCustomer({ customerId });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const customer: CustomerVm = response.data;
        setCustomer(customer);
        setLoading(false);
        onChangeCustomer && onChangeCustomer(customer);
    };

    return (
        <div style={{ position: 'relative' }}>
            <UpdatingContentProgress loading={loading} />
            <ChangeCustomerDialog onCustomerChanged={load} />
            <CustomerCreditsDialog onCreditsAddedOrRemoved={load} />
            <CustomerSendDialog />
            <CustomerSendEmailDialog />
            <Card classes={{ root: classes.root }}>
                <CardHeader
                    title={
                        <Typography display='block' gutterBottom variant='h6'>
                            {translate('Customer Details')}
                        </Typography>
                    }
                    action={
                        <div>
                            {customerId && !viewUser && (
                                <>
                                    <OpenInNewTabToolbarButton onClick={() => openCustomerPageInNewTab(customerId)} />
                                    <ChangeToolbarButton onClick={() => history.push({ search: setUrlQueryParameter(CHANGE_CUSTOMER, customerId) })} />
                                    <SendNotificationToolbarButton onClick={() => history.push({ search: setUrlQueryParameter(SEND_NOTIFICATION, customerId) })} />
                                    <SendEmailToolbarButton onClick={() => history.push({ search: setUrlQueryParameter(SEND_EMAIL, customerId) })} />
                                    <AddCreditsToolbarButton onClick={() => history.push({ search: setUrlQueryParameter(ADD_CREDITS, customerId) })} />
                                </>
                            )}
                            <RefreshToolbarButton onClick={load} />
                        </div>
                    }
                />
                <CardContent>
                    <Form
                        onSubmit={(form: any) => {}}
                        initialValues={
                            customer
                                ? {
                                      customer: {
                                          customerId: customer.customerId,
                                          name: fullName(customer.firstName, customer.lastName),
                                          customerType: customer.customerType || '',
                                          mobileNumber: customer.mobileNumber || '',
                                          email: customer.email || '',
                                          companyName: customer.companyName || '',
                                          companyId: customer.companyId ?? '',
                                          employeeIdAtCompany: customer.employeeIdAtCompany ?? '',
                                          employeeNumberAtCompany: customer.employeeNumberAtCompany ?? '',
                                          payrollPaymentsEnabled: customer.payrollPaymentsEnabled ? 'Yes' : 'No',
                                          creditCards: customer.creditCards || 0,
                                          credits: customer.credits || '0',
                                          refundMethod: customer.refundMethod || 'NOT SELECTED',
                                          language: customer.language || '',
                                          fcmPermission: customer.fcmPermission ? 'Yes' : 'No',
                                          banned: customer.banned ? 'true' : 'false',
                                          appVersion: customer.appVersion || '',
                                          platform: customer.platform || '',
                                          platformVersion: customer.platformVersion || '',
                                          downloadedAppAt: formatDateTimeStringReadable(customer.downloadedAppAt, TimeZones.AMERICA_MONTERREY) || 'Unknown',
                                          signedUpAt: formatDateTimeStringReadable(customer.signedUpAt, TimeZones.AMERICA_MONTERREY) || 'Unknown',
                                          addedFirstCreditCardAt: formatDateTimeStringReadable(customer.addedFirstCreditCardAt, TimeZones.AMERICA_MONTERREY) || 'Unknown',
                                          firstOrderAt: formatDateTimeStringReadable(customer.firstOrderAt, TimeZones.AMERICA_MONTERREY) || 'Has not ordered yet',
                                          lastOrderAt: formatDateTimeStringReadable(customer.lastOrderAt, TimeZones.AMERICA_MONTERREY) || 'Has not ordered yet',
                                      },
                                  }
                                : {}
                        }
                        render={({ handleSubmit, submitting, pristine, values }) => (
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Field name='customer.name' component={TextField} label={translate('Name')} fullWidth disabled />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name='customer.customerId'
                                        component={TextField}
                                        label={translate('Customer Id')}
                                        fullWidth
                                        onClick={(event: any) => copyTextToClipboard(event.target.value)}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <Field name='customer.customerType' component={TextField} label={translate('Customer Type')} fullWidth disabled />
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <Field name='customer.mobileNumber' component={TextField} label={translate('Mobile Number')} fullWidth disabled />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Field name='customer.email' component={TextField} label={translate('Email')} fullWidth disabled />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name='customer.companyName'
                                        component={TextField}
                                        label={customer?.companyId ? translate('Company') : translate('Company Name')}
                                        fullWidth
                                        disabled
                                        helperText={customer?.companyId ? translate('Connected to Company') : translate('Company Name entered by customer')}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Field name='customer.payrollPaymentsEnabled' component={TextField} label={translate('Payroll Payments Enabled')} fullWidth disabled />
                                </Grid>

                                {!!(customer?.employeeIdAtCompany || customer?.employeeNumberAtCompany) && (
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <Field name='customer.employeeIdAtCompany' component={TextField} label={translate('Employee Id (At Company)')} fullWidth disabled />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field name='customer.employeeNumberAtCompany' component={TextField} label={translate('Employee Number (At Company)')} fullWidth disabled />
                                        </Grid>
                                    </>
                                )}

                                <Grid item xs={12} sm={4}>
                                    <Field name='customer.credits' component={TextField} label={translate('Credits')} fullWidth disabled />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Field fullWidth name='customer.refundMethod' label={translate('Refund Method')} component={Select} formControlProps={{ fullWidth: true }} disabled>
                                        <MenuItem value='NOT SELECTED'>Not Selected</MenuItem>
                                        <MenuItem value={RefundMethods.CREDITS}>{RefundMethods.CREDITS}</MenuItem>
                                        <MenuItem value={RefundMethods.CREDIT_CARD}>{RefundMethods.CREDIT_CARD}</MenuItem>
                                    </Field>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Field name='customer.creditCards' component={TextField} label={translate('Credit Cards')} fullWidth disabled />
                                </Grid>

                                <Grid item xs={12}>
                                    <RadioGroup row>
                                        <FormControlLabel label='Not Banned' control={<Field name='customer.banned' component={Radio} type='radio' color='primary' value='false' disabled />} />
                                        <FormControlLabel label='Banned' control={<Field name='customer.banned' component={Radio} type='radio' color='primary' value='true' disabled />} />
                                    </RadioGroup>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Field name='customer.language' component={TextField} label={translate('Language')} fullWidth disabled />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Field name='customer.fcmPermission' component={TextField} label={translate('Push Notification')} fullWidth disabled />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Field name='customer.appVersion' component={TextField} label={translate('App Version')} fullWidth disabled />
                                </Grid>

                                {(!customer || customer.platform === Platforms.ANDROID) && <Grid item xs={12} sm={4}></Grid>}

                                <Grid item xs={12} sm={4}>
                                    <Field name='customer.platform' component={TextField} label={translate('Platform')} fullWidth disabled />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Field name='customer.platformVersion' component={TextField} label={translate('Platform Version')} fullWidth disabled />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Field
                                        name='customer.downloadedAppAt'
                                        component={TextField}
                                        label={translate('Downloaded App At')}
                                        tooltip={formatDateTimeSecondsString(customer?.downloadedAppAt, TimeZones.ETC_UTC)}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Field
                                        name='customer.signedUpAt'
                                        component={TextField}
                                        label={translate('Signed Up At')}
                                        tooltip={formatDateTimeSecondsString(customer?.signedUpAt, TimeZones.ETC_UTC)}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Field
                                        name='customer.addedFirstCreditCardAt'
                                        component={TextField}
                                        label={translate('Added First Credit Card At')}
                                        tooltip={formatDateTimeSecondsString(customer?.addedFirstCreditCardAt, TimeZones.ETC_UTC)}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Field
                                        name='customer.firstOrderAt'
                                        component={TextField}
                                        label={translate('First Order At')}
                                        tooltip={formatDateTimeSecondsString(customer?.firstOrderAt, TimeZones.ETC_UTC)}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Field
                                        name='customer.lastOrderAt'
                                        component={TextField}
                                        label={translate('Last Order At')}
                                        tooltip={formatDateTimeSecondsString(customer?.lastOrderAt, TimeZones.ETC_UTC)}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        )}
                    />
                </CardContent>
            </Card>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
}));

type Props = {
    customerId?: CustomerId;
    onChangeCustomer?: (arg1: CustomerVm) => any;
    showLinkToCustomer?: boolean;
};

export type CustomerVm = {
    customerId: CustomerId;
    customerType: CustomerType;
    mobileNumber?: string;
    email?: string;
    name?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    companyId?: CompanyId;
    employeeIdAtCompany?: EmployeeIdAtCompany;
    employeeNumberAtCompany?: EmployeeNumberAtCompany;
    payrollPaymentsEnabled?: boolean;
    creditCards?: number;
    credits?: string;
    refundMethod?: RefundMethod;
    language?: LanguageTag;
    fcmPermission?: boolean;
    banned?: boolean;
    appVersion?: string;
    platform?: Platform;
    platformVersion?: string;
    downloadedAppAt?: Date;
    signedUpAt?: Date;
    addedFirstCreditCardAt?: Date;
    firstOrderAt?: Date;
    lastOrderAt?: Date;
};
