/**
 * @prettier
 */
export const DeliveryProviders = {
    UBER_DAAS: 'UBER_DAAS',
    RAPPI_CARGO: 'RAPPI_CARGO',
    PIDEDIRECTO: 'PIDEDIRECTO',
} as const;

export type DeliveryProvider = typeof DeliveryProviders[keyof typeof DeliveryProviders];
