/**
 * @prettier
 */
import { useEffect } from 'react';
import * as React from 'react';
import { useFetchRealtimeUpdates } from 'src/utils/react/useFetchRealtimeUpdates';
import { useSelector } from 'src/utils/react/useSelector';

export function FetchRealtimeUpdates(): React.ReactElement | null {
    const [fetchRealtimeUpdates] = useFetchRealtimeUpdates({ callApiContinuously: true });

    const restaurantId = useSelector((state) => state.app.restaurantId);

    useEffect(() => {
        if (restaurantId) fetchRealtimeUpdates();
    }, [restaurantId]);

    return null;
}
