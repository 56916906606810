/**
 * @prettier
 */
import { translate } from 'src/i18n/translate';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import { csvToObjects } from 'src/utils/csv/csvToObjects';

export async function convertCsvToImportRestaurantCustomers(file: any): Promise<Array<ImportRestaurantCustomer> | undefined> {
    const rows = await csvToObjects<CsvRestaurantCustomers>(file);

    const updatedRows = rows.filter((row) => row.TELEFONO !== '' && row.EMAIL !== '' && row.NOMBRE !== '');

    if (updatedRows.some((row) => !row['NOMBRE'] && !row['TELEFONO'] && !row['EMAIL'])) {
        alert(translate('Please upload a csv file with columns named NOMBRE, TELEFONO, EMAIL'));
        return;
    }

    return updatedRows.map((row) => ({
        name: row['NOMBRE'],
        mobileNumber: row['TELEFONO'],
        email: row['EMAIL'],
        address: {
            street: row['DIRECCION'],
            location: { type: 'Point', coordinates: [row['LONGITUD'], row['LATITUD']] },
        },
    }));
}

export type ImportRestaurantCustomer = {
    name: string;
    mobileNumber: string;
    email: string;
    address?: {
        street: string;
        location: GeoJsonPoint;
    };
};

type CsvRestaurantCustomers = {
    NOMBRE: string;
    TELEFONO: string;
    EMAIL: string;
    DIRECCION: string;
    LONGITUD: number;
    LATITUD: number;
};
