/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import gql from 'graphql-tag';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { findDeliveryManager, findOrderDeliveries } from 'src/api/appsync/queries';
import { onChangeDeliveryByOrderId, onChangeDeliveryManager } from 'src/api/appsync/subscriptions';
import { app2 } from 'src/app2';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { appsyncClient } from 'src/config/appsyncClient';
import { DeliveryManagerStatus, DeliveryManagerStatuses } from 'src/constants/DeliveryManagerStatus';
import type { DeliveryProvider } from 'src/constants/DeliveryProviders';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { Delivery } from 'src/scenes/letseatadmin/deliveryManager/Delivery';
import { DriverSendDialog } from 'src/scenes/letseatadmin/driver/DriverSendDialog';
import type { AwsStepFunctionExecutionArn, OrderId } from 'src/types/Id';
import { formatDateTimeSecondsString } from 'src/utils/date/formatDateTimeSecondsString';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { formatTimeSecondsString } from 'src/utils/date/formatTimeSecondsString';
import { formatTimeString } from 'src/utils/date/formatTimeString';
import { useAction } from 'src/utils/react/useAction';
import { openOrderPageInNewTab } from 'src/utils/window/openOrderPageInNewTab';

export function DeliveryManagerComponent({ deliveryManager }: Props): React.ReactElement {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const openRestartDeliveryManagerDialog = useAction(app2.actions.openRestartDeliveryManagerDialog);
    const openCompleteDeliveryManagerDialog = useAction(app2.actions.openCompleteDeliveryManagerDialog);
    const openCancelDeliveryManagerDialog = useAction(app2.actions.openCancelDeliveryManagerDialog);
    return (
        <div style={{ position: 'relative' }}>
            <UpdatingContentProgress loading={loading} />
            <DriverSendDialog />
            <Card classes={{ root: classes.root }}>
                <CardHeader
                    title={
                        <Typography display='block' gutterBottom variant='h6'>
                            {translate('Delivery Manager')}
                            {deliveryManager.deliveryProviders &&
                                deliveryManager.deliveryProviders.map((deliveryProvider, index) => {
                                    return <Chip size='small' label={deliveryProvider} variant={index === 0 ? 'success' : ('default' as any)} />;
                                })}
                        </Typography>
                    }
                    action={
                        <div>
                            {deliveryManager.deliveryManagerStatus !== DeliveryManagerStatuses.WAITING && deliveryManager.deliveryManagerStatus !== DeliveryManagerStatuses.RUNNING && (
                                <Button color='primary' disabled={loading} onClick={() => openRestartDeliveryManagerDialog({ orderId: deliveryManager.orderId })}>
                                    {translate('Restart')}
                                </Button>
                            )}
                            {deliveryManager.deliveryManagerStatus === DeliveryManagerStatuses.RUNNING && (
                                <Button color='primary' disabled={loading} onClick={() => openCompleteDeliveryManagerDialog({ orderId: deliveryManager.orderId })}>
                                    {translate('Complete')}
                                </Button>
                            )}
                            {deliveryManager.deliveryManagerStatus === DeliveryManagerStatuses.RUNNING && (
                                <Button color='primary' disabled={loading} onClick={() => openCancelDeliveryManagerDialog({ orderId: deliveryManager.orderId })}>
                                    {translate('Cancel')}
                                </Button>
                            )}
                            <Button color='primary' disabled={loading} onClick={() => openOrderPageInNewTab(deliveryManager.orderId)}>
                                {translate('Open Order')}
                            </Button>
                        </div>
                    }
                />
                <CardContent>
                    <Form
                        onSubmit={() => {}}
                        initialValues={
                            deliveryManager
                                ? {
                                      deliveryManager: {
                                          deliveryManagerStatus: deliveryManager.deliveryManagerStatus,
                                          error: deliveryManager.error,
                                          cancelReason: deliveryManager.cancelReason,
                                          createdAt: formatDateTimeStringReadable(deliveryManager.createdAt),
                                          startAt: formatTimeSecondsString(deliveryManager.startAt),
                                          startedAt: formatTimeSecondsString(deliveryManager.startedAt),
                                          restartAt: formatTimeSecondsString(deliveryManager.restartAt),
                                          restartedAt: formatTimeSecondsString(deliveryManager.restartedAt),
                                          lastRunAt: formatTimeSecondsString(deliveryManager.lastRunAt),
                                          cancelledAt: formatTimeSecondsString(deliveryManager.cancelledAt),
                                          failedAt: formatTimeSecondsString(deliveryManager.failedAt),
                                          completedAt: formatTimeSecondsString(deliveryManager.completedAt),
                                      },
                                  }
                                : {}
                        }
                        render={({ handleSubmit, submitting, pristine, values }) => (
                            <>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={2}>
                                        <Field name='deliveryManager.deliveryManagerStatus' component={TextField} label={translate('Status')} disabled={loading} fullWidth />
                                    </Grid>

                                    {!!deliveryManager.createdAt && (
                                        <Grid item xs={12} sm={2}>
                                            <Field
                                                name='deliveryManager.createdAt'
                                                component={TextField}
                                                disabled={loading}
                                                label={translate('Created At')}
                                                tooltip={formatDateTimeSecondsString(deliveryManager.createdAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}

                                    {!!deliveryManager.startedAt && (
                                        <Grid item xs={12} sm={2}>
                                            <Field
                                                name='deliveryManager.startedAt'
                                                component={TextField}
                                                disabled={loading}
                                                label={translate('Started At')}
                                                helperText={`Set to start at ${formatTimeString(deliveryManager.startAt)}`}
                                                tooltip={formatTimeSecondsString(deliveryManager.startedAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}

                                    {!!deliveryManager.restartAt && (
                                        <Grid item xs={12} sm={2}>
                                            <Field
                                                name='deliveryManager.restartAt'
                                                component={TextField}
                                                disabled={loading}
                                                label={translate('Restart At')}
                                                tooltip={formatDateTimeSecondsString(deliveryManager.restartAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}

                                    {!!deliveryManager.restartedAt && (
                                        <Grid item xs={12} sm={2}>
                                            <Field
                                                name='deliveryManager.restartedAt'
                                                component={TextField}
                                                disabled={loading}
                                                label={translate('Restarted At')}
                                                tooltip={formatDateTimeSecondsString(deliveryManager.restartedAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}

                                    {!!deliveryManager.lastRunAt && (
                                        <Grid item xs={12} sm={2}>
                                            <Field
                                                name='deliveryManager.lastRunAt'
                                                component={TextField}
                                                disabled={loading}
                                                label={translate('Last Run At')}
                                                tooltip={formatDateTimeSecondsString(deliveryManager.lastRunAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}

                                    {!!deliveryManager.cancelledAt && (
                                        <Grid item xs={12} sm={2}>
                                            <Field
                                                name='deliveryManager.cancelledAt'
                                                component={TextField}
                                                disabled={loading}
                                                label={translate('Cancelled At')}
                                                tooltip={formatDateTimeSecondsString(deliveryManager.cancelledAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                            />
                                            {deliveryManager.cancelReason && (
                                                <Field name='deliveryManager.cancelReason' component={TextField} disabled={loading} label={translate('Cancel Reason')} fullWidth />
                                            )}
                                        </Grid>
                                    )}

                                    {!!deliveryManager.failedAt && (
                                        <Grid item xs={12} sm={2}>
                                            <Field
                                                name='deliveryManager.failedAt'
                                                component={TextField}
                                                disabled={loading}
                                                label={translate('Failed At')}
                                                tooltip={formatDateTimeSecondsString(deliveryManager.failedAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}

                                    {!!deliveryManager.completedAt && (
                                        <Grid item xs={12} sm={2}>
                                            <Field
                                                name='deliveryManager.completedAt'
                                                component={TextField}
                                                disabled={loading}
                                                label={translate('Completed At')}
                                                tooltip={formatDateTimeSecondsString(deliveryManager.completedAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </>
                        )}
                    />
                </CardContent>
            </Card>
        </div>
    );
}

export function DeliveryManager({ orderId }: { orderId?: OrderId }): React.ReactElement | null {
    const [deliveryManager, setDeliveryManager] = useState();
    const [deliveries, setDeliveries] = useState([]);

    useEffect(() => {
        if (!orderId) {
            return;
        }

        let subscription: any;
        let subscription2: any;
        let loadCancelled = false;

        async function load() {
            try {
                const response = await appsyncClient.query<any>({
                    query: gql(findDeliveryManager),
                    variables: { orderId: orderId },
                });
                // alert(JSON.stringify(response, null, 2));
                if (loadCancelled) return;

                // alert(JSON.stringify(response.data.findDeliveryManager, null, 2));
                setDeliveryManager(response.data.findDeliveryManager);

                subscription = appsyncClient
                    .subscribe({
                        query: gql(onChangeDeliveryManager),
                        variables: { orderId: orderId },
                    })
                    .subscribe({
                        next: (response) => {
                            console.log(`next` + JSON.stringify(response));
                            const deliveryManagerChange = response.data.onChangeDeliveryManager;
                            setDeliveryManager(deliveryManagerChange);
                        },
                        complete: () => {
                            alert('completed onChangeDeliveryManager subscription');
                            // alert(JSON.stringify(error, null, 2));
                        },
                        error: (error) => {
                            alert(error);
                            // alert(JSON.stringify(error, null, 2));
                        },
                    });
            } catch (e: any) {
                console.log(e);
                alert(e);
            }

            try {
                const response = await appsyncClient.query<any>({
                    query: gql(findOrderDeliveries),
                    variables: { orderId: orderId },
                });
                if (loadCancelled) return;

                // alert(JSON.stringify(response.data.findDeliveryManager, null, 2));
                setDeliveries(response.data.findOrderDeliveries);

                console.log('subscribing to = ', orderId);
                subscription2 = appsyncClient
                    .subscribe({
                        query: gql(onChangeDeliveryByOrderId),
                        variables: { orderId: orderId },
                    })
                    .subscribe({
                        next: (response) => {
                            console.log(`next` + JSON.stringify(response));
                            setDeliveries((deliveries: any) => {
                                const deliveryChange = response.data.onChangeDeliveryByOrderId;
                                if (deliveries.some((delivery: any) => delivery.deliveryId === deliveryChange.deliveryId)) {
                                    return deliveries.map((old: any) => (old.deliveryId === deliveryChange.deliveryId ? deliveryChange : old));
                                } else {
                                    return [...deliveries, deliveryChange];
                                }
                            });
                        },
                        complete: () => {
                            alert('completed onChangeDeliveryByOrderId subscription');
                            // alert(JSON.stringify(error, null, 2));
                        },
                        error: (error) => {
                            alert(error);
                            // alert(JSON.stringify(error, null, 2));
                        },
                    });
                // return () => subscription.unsubscribe();
            } catch (e: any) {
                console.log(e);
                alert(e);
            }
        }

        console.log('####loading');
        load();

        // const subscription = appsyncClient.subscribe({
        //     query: gql(onChangeDeliveryManager),
        //     variables: { orderId: null },
        // }).subscribe({
        //     next: response => {
        //         const delivery = response.data.onChangeDelivery;
        //         alert(JSON.stringify(delivery, null, 2));
        //         // if (delivery.deliveryStatus === DeliveryStatus.REJECTED ||
        //         //     delivery.deliveryStatus === DeliveryStatus.DELIVERED) {
        //         //     setOngoingDelivery(undefined);
        //         //     return;
        //         // }
        //         // setOngoingDelivery(delivery);
        //     },
        //     complete: error => {
        //         // SentryService.logError('onChangeDelivery complete', { ongoingDeliveryId, error });
        //         // alert(JSON.stringify(error, null, 2));
        //     },
        //     error: error => {
        //         // SentryService.logError('onChangeDelivery error', { ongoingDeliveryId, error });
        //         // alert(JSON.stringify(error, null, 2));
        //     },
        // });

        return () => {
            console.log('unsubscribing to = ', orderId);
            subscription && subscription.unsubscribe();
            subscription2 && subscription2.unsubscribe();
            loadCancelled = false;
        };

        // }
        //
        // if (ongoingDeliveryId) {
        //     const subscription = appsyncClient.subscribe({
        //         query: gql(onChangeDelivery),
        //         variables: { deliveryId: ongoingDeliveryId },
        //     }).subscribe({
        //         next: response => {
        //             animateEaseInEaseOut();
        //             const delivery = response.data.onChangeDelivery;
        //             if (delivery.deliveryStatus === DeliveryStatus.REJECTED ||
        //                 delivery.deliveryStatus === DeliveryStatus.DELIVERED) {
        //                 setOngoingDelivery(undefined);
        //                 return;
        //             }
        //             setOngoingDelivery(delivery);
        //         },
        //         complete: error => {
        //             SentryService.logError('onChangeDelivery complete', { ongoingDeliveryId, error });
        //             // alert(JSON.stringify(error, null, 2));
        //         },
        //         error: error => {
        //             SentryService.logError('onChangeDelivery error', { ongoingDeliveryId, error });
        //             // alert(JSON.stringify(error, null, 2));
        //         },
        //     });
        //     return () => subscription.unsubscribe();
        // }
    }, [orderId]);

    if (!orderId || !deliveryManager) {
        return null;
    }

    return (
        <>
            <DeliveryManagerComponent deliveryManager={deliveryManager} />
            {deliveries.map((delivery: any) => (
                <div style={{ marginTop: 16 }} key={delivery.deliveryId}>
                    <Delivery delivery={delivery} />
                </div>
            ))}
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
}));

type Props = {
    deliveryManager: DeliveryManagerVm;
};

export type DeliveryManagerVm = {
    orderId: OrderId;
    awsExecutionArn?: AwsStepFunctionExecutionArn;
    deliveryManagerStatus: DeliveryManagerStatus;
    triedToRequestUberDirectDeliveryAt?: Date;
    triedToRequestRappiCargoDeliveryAt?: Date;
    error?: string;
    deliveryProviders?: Array<DeliveryProvider>;
    dynamicDeliveryManager?: boolean;
    cyclicalDeliveryManager?: boolean;
    createdAt: Date;
    cancelReason: string;
    startAt: Date;
    startedAt?: Date;
    restartAt?: Date;
    restartedAt?: Date;
    lastRunAt?: Date;
    cancelledAt?: Date;
    failedAt?: Date;
    completedAt?: Date;
};
