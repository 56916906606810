/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { UserFilledIcon } from 'src/icons/UserFilledIcon';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function UserName(): React.ReactElement | null {
    const classes = useStyles();
    const theme = useTheme();

    const pinCodeUserSignedInName = useSelector((state) => state.authentication.pinCodeUserSignedIn?.name);
    const userManagementEnabled = useSelector((state) => state.app.restaurant?.userManagementEnabled);
    const pinCodeUserRoleName = useSelector((state) => state.authentication.pinCodeUserSignedIn?.roleName);
    const loggedUserName = useSelector((state) => state.authentication.name);
    const userRoleName = useSelector((state) => state.authentication.roleName);

    const roleName = pinCodeUserRoleName ?? userRoleName;
    const showPinCodeUserName = userManagementEnabled && !!pinCodeUserSignedInName;
    const showUsername = !!loggedUserName;
    const userName = pinCodeUserSignedInName ?? loggedUserName;

    if (!showPinCodeUserName && !showUsername) return null;

    return (
        <>
            <div className={classes.container}>
                <div className={classes.iconContainer}>
                    <UserFilledIcon width={24} height={24} title={translate('User')} />
                </div>
                <div className={classes.posUserName}>
                    <Text variant='semiBold'>{userName}</Text>
                    {!!roleName && <Text style={{ fontSize: 12, color: theme.palette.text.secondary }}>{roleName}</Text>}
                </div>
            </div>
            <div className={classNames(classes.divider, classes.desktopHidden)} />
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        gap: 6,
        borderLeft: `1px solid ${theme.palette.border.primary}`,
        padding: '0px 12px',
        [theme.breakpoints.down('sm')]: {
            borderLeft: 0,
            height: 42,
            padding: 0,
            width: '100%',
        },
    },
    posUserName: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    desktopHidden: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    divider: {
        borderTop: `1px solid ${theme.palette.border.primary}`,
        width: '100%',
        height: 1,
    },
    iconContainer: {
        [theme.breakpoints.down('sm')]: {
            minWidth: 50,
            display: 'flex',
            alignItems: 'center',
        },
    },
}));
