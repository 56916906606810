/**
 * @prettier
 */
import { useHistory } from 'react-router-dom';
import { OrderTypes } from 'src/constants/OrderType';
import { RoutePaths } from 'src/constants/RoutePath';
import { useClearCustomerInfo } from 'src/modules/pos/customer/useClearCustomerInfo';
import { posReducer } from 'src/reducers/posReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function useClearPos(): any {
    const history = useHistory();
    const clearCustomerInfo = useClearCustomerInfo();

    const posSimpleView = useSelector((state) => state.pos.context?.posSimpleView);
    const posShowTablesEnabled = useSelector((state) => state.pos.context?.posShowTablesEnabled);
    const posTablesTabAsDefaultEnabled = useSelector((state) => state.app.restaurant.posTablesTabAsDefaultEnabled);

    const clearPos = useAction(posReducer.actions.clearPos);
    const showPosTableSelect = useAction(posReducer.actions.showPosTableSelect);
    const selectPosOrderType = useAction(posReducer.actions.selectPosOrderType);

    const handleClearPos = () => {
        clearPos();
        clearCustomerInfo();

        if (!posSimpleView) return;
        if (posShowTablesEnabled) {
            return showPosTableSelect();
        }
        selectPosOrderType(OrderTypes.TABLE_ORDER);

        if (posTablesTabAsDefaultEnabled && history.location.pathname !== RoutePaths.POS_TABLES) {
            history.replace({
                pathname: RoutePaths.POS_TABLES,
                search: history.location.search,
            });
        }
    };

    return handleClearPos;
}
