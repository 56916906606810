/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PrinterId, RestaurantId } from 'src/types/Id';
import type { PrinterDeprecatedVm } from 'src/types/PrinterDeprecatedVm';

export async function getPrinterApi(request: GetPrinterApiRequest): ApiSauceResponse<PrinterDeprecatedVm> {
    return letseatmanagerApiMethod('printerDeprecated/getPrinterApi', request);
}

export type GetPrinterApiRequest = {
    restaurantId: RestaurantId;
    printerId: PrinterId;
};
