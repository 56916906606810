/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BusinessVm } from 'src/types/BusinessVm';

export async function findBusinessesApi(): ApiSauceResponse<Array<BusinessVm>> {
    return letseatadminApiMethod('business/findBusinessesApi');
}
