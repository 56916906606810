/**
 * @prettier
 */
import { ResponsiveLine } from '@nivo/line';
import * as React from 'react';

export function ResponsiveLineCanvas({ data, tooltip, legends, margin, colors }: Props): React.ReactElement {
    const defaultColors = ['#2476C2', '#53C7D7', '#5DE3BC', '#24A8C2', '#13647D', '#0DFFD3', '#A0D8F8', '#A7A0F8', '#6256E9'];
    return (
        <ResponsiveLine
            colors={colors ? colors : defaultColors}
            data={data}
            margin={margin ?? { top: 40, right: 40, bottom: 40, left: 50 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: false,
                reverse: false,
            }}
            yFormat=' >-.2f'
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -15,
                legendPosition: 'middle',
                legendOffset: -40,
            }}
            pointSize={5}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            tooltip={tooltip}
            legends={legends}
            enableArea={true}
            lineWidth={4}
            enableGridX={false}
        />
    );
}

type Props = {
    data: Array<{
        id: string;
        color?: string;
        data: Array<{
            x: string | number;
            y: string | number;
        }>;
    }>;
    margin?: {
        left: number;
        top: number;
        bottom: number;
        right: number;
    };
    tooltip?: React.ReactNode | any;
    legends?: React.ComponentProps<typeof ResponsiveLine>['legends'];
    colors?: Array<string>;
};
