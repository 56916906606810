/**
 * @prettier
 */
import { app2 } from 'src/app2';
import { PrintType } from 'src/constants/PrintType';
import { translate } from 'src/i18n/translate';
import { print } from 'src/services/printer/printers/print';
import type { Print } from 'src/services/printer/types/Print';
import type { PrinterInstruction } from 'src/services/printer/types/PrinterInstruction';
import type { PrinterVm } from 'src/types/PrinterVm';
import { useAction } from 'src/utils/react/useAction';

export function usePrintContent(): UsePrintContent {
    const openSelectPrinterDialog = useAction(app2.actions.openSelectPrinterDialog);

    const printContent = async (print: Print) => {
        await selectPrinterToPrint({
            printerInstructions: print.printerInstructions,
            printType: print.printType,
            numberOfPrints: print.numberOfPrints,
        });
    };

    const printContents = async (contentsToPrint: PrintContentsParams) => {
        const promises: Array<Promise<void>> = [];

        for (const contentToPrint of contentsToPrint) {
            const arrayContentToPrint = Array(contentToPrint.print.numberOfPrints ?? 1).fill(contentToPrint);
            for (const arrayContentToPrintElement of arrayContentToPrint) {
                promises.push(print(arrayContentToPrintElement.print, arrayContentToPrintElement.printer));
            }
        }

        const promiseResults = await Promise.allSettled(promises.flat());

        const failedPromises: any = promiseResults.filter((promiseResult) => promiseResult.status === 'rejected');

        for (const failedPromise of failedPromises) {
            await selectPrinterToPrint({
                header: translate(`Failed to print @printType on @deviceName`, {
                    printType: translate(`PrintTypes.${failedPromise.reason.data.print.printType}`),
                    deviceName: failedPromise.reason.data.printer.deviceName,
                }),
                error: `Error message: ${failedPromise.reason.cause.message}`,
                printerInstructions: failedPromise.reason.data.print.printerInstructions,
                printType: failedPromise.reason.data.print.printType,
            });
        }
    };

    const selectPrinterToPrint = async ({ header, error, printerInstructions, printType, numberOfPrints = 1 }: SelectPrinterToPrintParams) => {
        await new Promise<void>((resolve) => {
            openSelectPrinterDialog({
                header,
                error,
                printerInstructions,
                printType,
                onSelectPrinter: async (printer: PrinterVm) => {
                    for (let numberOfPrint = 0; numberOfPrint < numberOfPrints; numberOfPrint++) {
                        try {
                            await print(
                                {
                                    printType: printType,
                                    printerInstructions,
                                },
                                printer
                            );
                        } catch (e: any) {
                            await selectPrinterToPrint({
                                header: translate(`Failed to print @printType on @deviceName`, { printType: translate(`PrintTypes.${e.data.print.printType}`), deviceName: e.data.printer.deviceName }),
                                error: `Error message: ${e.cause}`,
                                printerInstructions: e.data.print.printerInstructions,
                                printType: e.data.print.printType,
                                numberOfPrints: numberOfPrints - numberOfPrint,
                            });
                            resolve();
                        }
                    }
                    resolve();
                },
            });
        });
    };

    return { printContent, printContents };
}

type UsePrintContent = {
    printContent: (print: Print) => Promise<void>;
    printContents: (params: PrintContentsParams) => Promise<void>;
};

type PrintContentsParams = Array<{
    print: Print;
    printer: PrinterVm;
}>;

type SelectPrinterToPrintParams = {
    header?: string;
    error?: string;
    printerInstructions: Array<PrinterInstruction>;
    printType: PrintType;
    numberOfPrints?: number;
};
