/**
 * @prettier
 */
import type { PromoCodeVm } from 'src/api/letseatmanager/types/PromoCodeVm';
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function findPromoCodesApi(request: FindPromoCodesApiRequest): ApiSauceResponse<PromoCodesVm> {
    return letseatmanagerApiMethod('promoCode/findPromoCodesApi', request);
}

export type PromoCodesVm = {
    promoCodes: Array<PromoCodeVm>;
};

export type FindPromoCodesApiRequest = {
    restaurantIds: Array<RestaurantId>;
};
