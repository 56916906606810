/**
 * @prettier
 */
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PowerIcon from '@material-ui/icons/Power';
import RefreshIcon from '@material-ui/icons/Refresh';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import type { BenefitPlanId, CustomerId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { fullName } from 'src/utils/customer/fullName';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { RegExps } from 'src/utils/RegExps';

export default withStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class UnconnectedCustomers extends React.Component<Props, State> {
        state: any = {
            loading: true,
            unconnectedCustomers: [],
        };

        static getDerivedStateFromProps(props: Props, state: State) {
            if (props.benefitPlanId !== state.benefitPlanId) {
                return {
                    benefitPlanId: props.benefitPlanId,
                };
            }
            return null;
        }

        async componentDidMount() {
            await this.load();
        }

        async componentDidUpdate(prevProps: Props, prevState: State) {
            if (this.state.benefitPlanId !== prevState.benefitPlanId) {
                await this.load();
            }
        }

        // shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
        //     return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
        // }

        load = async () => {
            if (!this.state.benefitPlanId) {
                return;
            }
            this.setState({ loading: true });
            const response = await letseatmanagerApiDeprecated.admin.getUnconnectedCustomers({ benefitPlanId: this.state.benefitPlanId || '' });
            this.setState({ loading: false });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            this.setState({ unconnectedCustomers: response.data.unconnectedCustomers });
        };

        handleClickConnect = () => {
            alert(translate('You must select at least one customer to connect.'));
        };

        connectMultiple = async (customerIds: Array<CustomerId>) => {
            this.setState({ loading: true });
            for (const customerId of customerIds) {
                const response = await letseatmanagerApiDeprecated.admin.connectCustomerToBenefitPlan({ customerId, benefitPlanId: this.state.benefitPlanId || '' });
                if (!response.ok) {
                    this.setState({ loading: false });
                    if (response.data && response.data.message) alert(response.data && response.data.message);
                    else alert('Something went wrong when connecting customers');
                    await this.load();
                    return;
                }
            }
            await this.load();
            this.props.onCustomersConnected();
        };

        render() {
            const { classes } = this.props;
            return (
                <div style={{ position: 'relative' }}>
                    <UpdatingContentProgress loading={this.state.loading} />
                    <MUIDataTable
                        /* @ts-ignore */
                        className={classes.table}
                        title={'Unconnected Customers'}
                        data={this.state.unconnectedCustomers.map((customer: any) => ({
                            customerId: customer.customerId,
                            name: fullName(customer.firstName, customer.lastName),
                            email: customer.email || '',
                            companyName: customer.companyName || '',
                            signedUpAt: formatDateTimeString(customer.signedUpAt) || translate('Unknown'),
                        }))}
                        columns={[
                            {
                                name: 'customerId',
                                label: ' ',
                                options: {
                                    display: 'excluded',
                                    filter: false,
                                },
                            },
                            {
                                name: 'name',
                                label: translate('Name'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'email',
                                label: translate('Email'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'companyName',
                                label: translate('Company Name'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'signedUpAt',
                                label: translate('Signed Up'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                        ]}
                        options={{
                            responsive: 'standard',
                            tableBodyMaxHeight: '500px',
                            selectableRows: 'multiple',
                            // setRowProps: (row) => {
                            //     return {
                            //         style: { backgroundColor: getCustomerstatusColor(row[30], row[32]) },
                            //     };
                            // },
                            onRowClick: (
                                rowData: Array<string>,
                                rowMeta: {
                                    dataIndex: number;
                                    rowIndex: number;
                                }
                            ) => {
                                history.push({
                                    pathname: RoutePaths.ADMIN_CUSTOMER.replace(`:customerId(${RegExps.uuid})`, rowData[0]),
                                    search: history.location.search,
                                });
                            },
                            filterType: 'multiselect',
                            rowsPerPage: 100,
                            customToolbar: () => {
                                return (
                                    <>
                                        <Tooltip title='Refresh'>
                                            <IconButton onClick={this.load}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Connect Customer'>
                                            <IconButton onClick={this.handleClickConnect}>
                                                <PowerIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                );
                            },
                            customToolbarSelect: (selectedRows, displayData) => {
                                const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                                const selectedCustomerIds = selectedRowsData.map((d: any) => d[0]);
                                const handleClickConnect = async () => {
                                    await this.connectMultiple(selectedCustomerIds);
                                };
                                return (
                                    <div className={classes.toolbar}>
                                        <Tooltip title='Connect Customer'>
                                            <IconButton onClick={handleClickConnect}>
                                                <PowerIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                );
                            },
                            // filterList: [[], [], [], [], ['MEXICO']],
                        }}
                    />
                </div>
            );
        }
    }
) as React.ComponentType<any>;

type Props = {
    benefitPlanId?: BenefitPlanId;
    classes: any;
    onCustomersConnected: any;
};

type State = {
    benefitPlanId?: BenefitPlanId;
    loading: boolean;
} & UnconnectedCustomersVm;

type UnconnectedCustomersVm = {
    unconnectedCustomers: Array<CustomerVm>;
};

type CustomerVm = {
    customerId: CustomerId;
    mobileNumber: string;
    email: string;
    firstName: string;
    lastName: string;
    companyName: string;
    signedUpAt: Date;
};
