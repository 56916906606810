/**
 * @prettier
 */
import * as React from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { PrinterPaperSize, PrinterPaperSizes } from 'src/constants/PrinterPaperSize';
import { translate } from 'src/i18n/translate';

export function FormPrinterPaperSizeSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, filter }: Props): React.ReactElement {
    let options = required
        ? Object.values(PrinterPaperSizes).map((printerPaperSize) => ({ label: translate(`PrinterPaperSizes.${printerPaperSize}`), value: printerPaperSize }))
        : [...Object.values(PrinterPaperSizes).map((printerPaperSize) => ({ label: translate(`PrinterPaperSizes.${printerPaperSize}`), value: printerPaperSize }))];

    if (filter) options = options.filter((option) => filter.includes(option.value));

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    filter?: Array<PrinterPaperSize>;
};
