/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderRejectReason } from 'src/constants/OrderRejectReason';
import type { OrderId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export async function changeOrderRejectReasonApi(request: ChangeOrderRejectReasonApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('order/changeOrderRejectReasonApi', request);
}

export type ChangeOrderRejectReasonApiRequest = {
    orderId: OrderId;
    orderRejectReason: OrderRejectReason;
};
