/**
 * @prettier
 */
import { usePosPaymentMethodsActions } from 'src/services/pos/posPaymentMethods/posPaymentMethodsStore';

export function useClearPosPaymentMethod(): () => void {
    const clearPosPaymentMethod = usePosPaymentMethodsActions((actions) => actions.clearPosPaymentMethod);

    return clearPosPaymentMethod;
}
