/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { FaqsVm } from 'src/scenes/letseatadmin/FaqsPage';

export async function getFaqsApi(): ApiSauceResponse<FaqsVm> {
    return letseatadminApiMethod('faq/getFaqsApi');
}
