/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderId } from 'src/types/Id';

export async function switchOrderDeliveryProviderToUberDaasApi(request: Request): ApiSauceResponse<string> {
    return letseatadminApiMethod('delivery/switchOrderDeliveryProviderToUberDaasApi', request);
}

export type Request = {
    orderId: OrderId;
};
