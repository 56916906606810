/**
 * @prettier
 */
import { InputBase, InputLabel } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { getError } from 'src/components/form/utils/getError';
import { transformStringInput } from 'src/components/form/utils/transformStringInput';
import { transformStringOutput } from 'src/components/form/utils/transformStringOutput';
import { translate } from 'src/i18n/translate';
import { isBlankString } from 'src/utils/string/isBlankString';

export function FormExpiryField({
    name,
    label,
    placeholder,
    helperText,
    value,
    onChange,
    variant = 'outlined',
    required,
    disabled,
    InputProps,
    rules,
    defaultValue = null,
}: Props): React.ReactElement {
    const classes = useStyles();

    const {
        errors,
        control,
        formState: { isSubmitting },
        watch,
    } = useFormContext();
    const inputRef = useRef<HTMLInputElement>();
    const cardNumber = watch(`${name}.cardNumber`);
    const cvc = watch(`${name}.cvc`);

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <div>
                    <InputLabel classes={{ root: classes.label, error: classes.labelError }} error={!!getError(errors, name)} required={required}>
                        {label}
                    </InputLabel>
                    <InputBase
                        {...props}
                        {...InputProps}
                        value={transformStringInput(value)}
                        onChange={(value) => onChange(transformStringOutput(value as any))}
                        label={label}
                        placeholder={translate('MM/YY')}
                        error={!!getError(errors, name)?.['cardNumber'] || !!getError(errors, name)?.['expiry'] || !!getError(errors, name)?.['cvc']?.message}
                        inputRef={inputRef}
                        inputComponent={NumberFormatCustom}
                        autoComplete={'cc-exp'}
                        inputProps={{ autoComplete: 'cc-exp', maxLength: 5 }}
                        InputLabelProps={{ shrink: placeholder ? true : undefined }}
                        variant={variant}
                        required={required}
                        disabled={isSubmitting || disabled}
                        fullWidth
                        classes={{ root: classes.input, error: classes.inputError }}
                    />
                    {!!helperText && <FormHelperText classes={{ root: !!getError(errors, name) ? classes.helperTextError : classes.helperText }}>{helperText}</FormHelperText>}
                </div>
            )}
            control={control}
            name={`${name}.expiry`}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: rules?.required || !isBlankString(cardNumber) || !isBlankString(cvc),
                    message: translate('Expiry is required'),
                },
                pattern: {
                    value: /(00|01|02|03|04|05|06|07|08|09|10|11|12)\d\d/,
                    message: translate('Expiry must be in format MM/YY'),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export function NumberFormatCustom(props: { inputRef: any; name: string; onChange: any }): React.ReactElement {
    const { inputRef, onChange, ...other } = props;

    return <NumberFormat {...other} getInputRef={inputRef} onValueChange={(values) => onChange(values.value)} isNumericString format='##/##' mask='' decimalScale={0} />;
}

const useStyles = makeStyles((theme) => ({
    input: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 5,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: 5,
        paddingBottom: 5,
        fontFamily: theme.typography.regular,
    },
    inputError: {
        border: '2px solid red',
    },
    label: {
        fontFamily: theme.typography.medium,
        color: theme.palette.secondary.contrastText,
        fontSize: 15,
        marginBottom: 5,
    },
    labelError: {
        color: 'red',
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: theme.palette.secondary.contrastText,
    },
    helperTextError: {
        fontFamily: theme.typography.light,
        color: 'red',
    },
}));

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    value?: string;
    onChange?: string;
    variant?: 'filled' | 'outlined' | 'standard';
    required?: boolean;
    disabled?: boolean;
    rules?: {
        required?: boolean;
    };
    InputProps?: any;
    defaultValue?: string | null | undefined;
};
