/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { UnitOfMeasurement, UnitsOfMeasurement } from 'src/constants/UnitOfMeasurement';
import { translate } from 'src/i18n/translate';
import type { SupplyVm } from 'src/types/SupplyVm';

export function FormUnitOfMeasurementSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, filterBySupply, filterByUnits }: Props): React.ReactElement {
    const classes = useStyles();

    const defaultOptions = required
        ? Object.keys(UnitsOfMeasurement).map((unit) => ({ label: translate(`UnitOfMeasurements.${unit}`), value: unit }))
        : [{ label: '', value: null }, ...Object.keys(UnitsOfMeasurement).map((unit) => ({ label: translate(`UnitOfMeasurements.${unit}`), value: unit }))];

    const [options, setOptions] = useState(defaultOptions);

    useEffect(() => {
        handleFilterBySupply();
    }, [filterBySupply, filterByUnits]);

    useEffect(() => {
        if (filterByUnits) {
            setOptions(defaultOptions.filter((option) => filterByUnits.includes(option.value as any)));
        }
    }, [filterByUnits]);

    const handleFilterBySupply = () => {
        if (filterByUnits) return;

        if (filterBySupply) {
            const newOptions = defaultOptions.filter(
                (option) =>
                    option.value === filterBySupply?.sellUnit ||
                    filterBySupply?.buyUnits.some((buyUnit: { conversionFactor: number; isMainUnit?: boolean; unit: UnitOfMeasurement }) => buyUnit.unit === option.value)
            );
            setOptions(newOptions);
            return;
        }

        setOptions(defaultOptions);
    };

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={required}
            variant={variant}
            classesProp={{ input: classes.input }}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    input: {
        boxSizing: 'border-box',
        height: '42px',
    },
}));

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    filterBySupply?: SupplyVm;
    filterByUnits?: Array<UnitOfMeasurement>;
};
