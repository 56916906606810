/**
 * @prettier
 */
export const DeviceTypes = {
    MACINTOSH: 'MACINTOSH',
    PC: 'PC',
    TABLET: 'TABLET',
    MOBILE: 'MOBILE',
    UNKNOWN: 'UNKNOWN',
} as const;

export type DeviceType = typeof DeviceTypes[keyof typeof DeviceTypes] | string;
