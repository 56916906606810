/**
 * @prettier
 */
import { app2 } from 'src/app2';
import type { OrderVm } from 'src/types/OrderVm';
import { isPreparedKitchenOrder } from 'src/utils/order/isPreparedKitchenOrder';
import { isPreparingKitchenOrder } from 'src/utils/order/isPreparingKitchenOrder';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function useUpdateKitchenOrder(): {
    updateKitchenOrder: (kitchenOrder: OrderVm) => void;
} {
    const preparingKitchenOrders = useSelector((state) => state.app2.preparingKitchenOrders);
    const preparedKitchenOrders = useSelector((state) => state.app2.preparedKitchenOrders);

    const setPreparingKitchenOrders = useAction(app2.actions.setPreparingKitchenOrders);
    const setPreparedKitchenOrders = useAction(app2.actions.setPreparedKitchenOrders);

    const updateKitchenOrder = (kitchenOrder: OrderVm) => {
        let newPreparingKitchenOrders;
        let newPreparedKitchenOrders;

        if (isPreparingKitchenOrder(kitchenOrder.kitchenStatus)) {
            newPreparingKitchenOrders = [...preparingKitchenOrders?.filter((currentOrder: OrderVm) => currentOrder.orderId !== kitchenOrder.orderId), kitchenOrder];
            newPreparedKitchenOrders = preparedKitchenOrders.filter((currentOrder) => currentOrder.orderId !== kitchenOrder.orderId);
        }

        if (isPreparedKitchenOrder(kitchenOrder.kitchenStatus)) {
            newPreparedKitchenOrders = [...preparedKitchenOrders.filter((currentOrder) => currentOrder.orderId !== kitchenOrder.orderId), kitchenOrder];
            newPreparingKitchenOrders = preparingKitchenOrders?.filter((currentOrder: OrderVm) => currentOrder.orderId !== kitchenOrder.orderId);
        }

        setPreparingKitchenOrders(newPreparingKitchenOrders);
        setPreparedKitchenOrders(newPreparedKitchenOrders);
    };

    return { updateKitchenOrder };
}
