/**
 * @prettier
 */
import { Marker } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { getDriverPositionApi } from 'src/api/letseatmanager/driverPosition/getDriverPositionApi';
import { SECONDS } from 'src/constants/TimeUnit';
import DriverMarker from 'src/images/DriverMarker.svg';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { OrderId } from 'src/types/Id';
import { toLatLng } from 'src/utils/googlemaps/toLatLng';
import { wait } from 'src/utils/wait';

let updatingPosition = false;
let unmounting = false;
export function DeliveryOrderDriverMarker({ orderId, stopUpdatingPosition }: Props): React.ReactElement | null {
    const [driverPosition, setDriverPosition] = useState<GeoJsonPoint | undefined>();

    useEffect(() => {
        handleUpdateDriverPosition();
        return () => {
            unmounting = true;
        };
    }, [stopUpdatingPosition, orderId]);

    const handleUpdateDriverPosition = async () => {
        if (stopUpdatingPosition || updatingPosition || unmounting) return;

        updatingPosition = true;
        const update = await getDriverPositionApi({ orderId: orderId });
        if (!update.ok) return;

        setDriverPosition(update.data.position);

        await wait(2 * SECONDS);
        updatingPosition = false;
        handleUpdateDriverPosition();
    };

    if (!driverPosition) return null;

    return <Marker icon={DriverMarker} position={toLatLng(driverPosition)} />;
}

type Props = {
    orderId: OrderId;
    stopUpdatingPosition?: boolean;
};
