/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import { OwnFleetDriverReportVm } from 'src/types/OwnFleetDriverReportVm';

export async function getOwnFleetReportApi(request: GetOwnFleetReportApiRequest): ApiSauceResponse<Array<OwnFleetDriverReportVm>> {
    return pidedirectoReportsMethod('/getOwnFleetReportApi', request);
}

export type GetOwnFleetReportApiRequest = {
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
};
