/**
 * @prettier
 */
export const PickupTimeTypes = {
    ASAP: 'ASAP',
    PLANNED: 'PLANNED',
    PLANNED_MENU_ITEMS: 'PLANNED_MENU_ITEMS',
} as const;

export type PickupTimeType = typeof PickupTimeTypes[keyof typeof PickupTimeTypes];
