/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useState } from 'react';
import * as React from 'react';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { OrderDetailsDialog } from 'src/components/orderCard/OrderDetailsDialog';
import { translate } from 'src/i18n/translate';
import { OrderVm } from 'src/types/OrderVm';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function IncomingMultipleOrdersAlert(): React.ReactElement | null {
    const classes = useStyles();

    const [orderDialogState, setOrderDialogState] = useState<{ open: boolean; order: OrderVm | undefined }>({ open: false, order: undefined });

    const open = useSelector((state) => state.app2.incomingOrdersAlertState.open);
    const newOrders = useSelector((state) => state.app2.incomingOrdersAlertState.newOrders);

    const closeIncomingOrdersAlert = useAction(app2.actions.closeIncomingOrdersAlert);

    const order = newOrders ? newOrders[0] : undefined;

    const closeOrderDetailsDialog = () => {
        setOrderDialogState({ open: false, order: undefined });
    };

    const handleSeeOrder = () => setOrderDialogState({ open: true, order });

    if (!open) return null;
    if (newOrders.length === 1) return null;
    if (!order) return null;

    return (
        <>
            {!!orderDialogState.order && <OrderDetailsDialog open={orderDialogState.open} onClose={closeOrderDetailsDialog} order={order} />}
            <Alert classes={{ root: classes.alertContainer }} icon={<NotificationsIcon color={'primary'} onClick={closeIncomingOrdersAlert} />}>
                <AlertTitle classes={{ root: classes.headerContainer }}>
                    <span className={classes.alertTitle}>{translate('You have @numberOfOrders new orders', { numberOfOrders: newOrders.length })}</span>
                </AlertTitle>
                <Button onClick={handleSeeOrder} secondary>
                    {translate('Show orders')}
                </Button>
            </Alert>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    alertContainer: {
        fontFamily: theme.typography.medium,
        position: 'absolute',
        top: 120,
        background: 'white',
        borderRadius: 10,
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
        width: 'fit-content',
        zIndex: 10,
        [theme.breakpoints.up('sm')]: {
            minWidth: 300,
            right: 40,
        },
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    alertTitle: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        color: '#313333',
        [theme.breakpoints.up('sm')]: {
            fontSize: 16,
        },
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));
