/**
 * @prettier
 */
import type { PaymentMethod } from 'src/api/appsyncApi';
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { App } from 'src/constants/App';
import type { CashRegisterId, CustomPaymentMethod } from 'src/types/Id';

export async function getCashRegisterExpectedAmountsApi(request: GetCashRegisterExpectedAmountsApiRequest): ApiSauceResponse<Array<CashRegisterExpectedAmountVm>> {
    return letseatmanagerApiMethod('pos/getCashRegisterExpectedAmountsApi', request);
}

export type GetCashRegisterExpectedAmountsApiRequest = {
    cashRegisterId: CashRegisterId;
};

export type CashRegisterExpectedAmountVm = {
    paymentMethod: PaymentMethod;
    amount: string;
    customPaymentMethod?: CustomPaymentMethod;
    app?: App;
};
