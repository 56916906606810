/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantVm } from 'src/types/RestaurantVm';

export async function findRestaurantsWithInventoryApi(): ApiSauceResponse<Array<RestaurantVm>> {
    return letseatmanagerApiMethod('inventory/findRestaurantsWithInventoryApi');
}
