/**
 * @prettier
 */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { RolePermission } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function useValidatePageAccess(rolePermission: RolePermission): [boolean] {
    const history = useHistory();
    const [userHasRolePermission] = useUserHasRolePermission();

    const [validatingPageAccess, setValidatingPageAccess] = useState(true);

    const initialPage = useSelector((state) => state.authentication.initialPage);
    const pinCodeUserInitialPage = useSelector((state) => state.authentication.pinCodeUserSignedIn?.initialPage);

    const userInitialPage = pinCodeUserInitialPage ?? initialPage;

    useEffect(() => {
        if (!userHasRolePermission(rolePermission)) redirectToInitialPage();
        if (userHasRolePermission(rolePermission)) setValidatingPageAccess(false);
    }, [rolePermission]);

    const redirectToInitialPage = () => {
        history.replace({
            pathname: userInitialPage ? RoutePaths[userInitialPage] : RoutePaths.ONGOING_ORDERS,
            search: history.location.search,
        });
    };

    return [validatingPageAccess];
}
