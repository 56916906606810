/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { type RestaurantId } from 'src/types/Id';
import { PromotionVm } from 'src/types/PromotionVm';

export async function findPromotionsApi(request: FindPromotionsApiRequest): ApiSauceResponse<Array<PromotionVm>> {
    return letseatmanagerApiMethod('promotion/findPromotionsApi', request);
}

export type FindPromotionsApiRequest = {
    restaurantIds: Array<RestaurantId>;
};
