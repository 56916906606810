/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { deprecatedRemoveMenuItemApi } from 'src/api/letseatmanager/menuItem/deprecatedRemoveMenuItemApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { translate } from 'src/i18n/translate';
import noImageIcon from 'src/images/icon-no-photo.png';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RemoveMenuItemDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const [loading, setLoading] = useState(false);

    const open = useSelector((state) => state.app2.removeMenuItemDialog.open);
    const menuItem = useSelector((state) => state.app2.removeMenuItemDialog.menuItem);
    const onSuccess = useSelector((state) => state.app2.removeMenuItemDialog.onSuccess);

    const closeRemoveMenuItemDialog = useAction(app2.actions.closeRemoveMenuItemDialog);

    const handleClose = () => {
        if (loading) return;
        closeRemoveMenuItemDialog();
    };

    const handleSubmit = async () => {
        setLoading(true);
        const response = await deprecatedRemoveMenuItemApi({
            menuItemId: menuItem?.menuItemId,
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        onSuccess();
        closeRemoveMenuItemDialog();
    };

    return (
        <Dialog title={translate('Are you sure you want to remove menu item?')} open={open} onClose={handleClose}>
            <Form onSubmit={handleSubmit} form={form}>
                <div className={classes.menuItem}>
                    {menuItem?.imageUrl && <img src={menuItem.imageUrl} alt={`image for ${menuItem.name}`} className={classes.image} />}
                    {!menuItem?.imageUrl && (
                        <div className={classes.noImageContainer}>
                            <img src={noImageIcon} alt='no image icon' className={classes.noImage} />
                        </div>
                    )}
                    <div className={classes.menuItemInfoContainer}>
                        <h4 className={classes.name}>{menuItem?.name}</h4>
                        <p className={classes.description}>{menuItem?.description}</p>
                    </div>
                </div>
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {loading ? translate('REMOVING') : translate('REMOVE')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    menuItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        width: 'fit-content',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    image: {
        borderRadius: 8,
        width: 70,
        height: 70,
        objectFit: 'cover',
        flexShrink: 0,
    },
    noImageContainer: {
        borderRadius: 8,
        width: 70,
        height: 70,
        backgroundColor: theme.palette.surface.secondary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
    },
    noImage: {
        width: 30,
        height: 30,
        objectFit: 'cover',
    },
    menuItemInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 20,
    },
    name: {
        fontFamily: theme.typography.semiBold,
        fontSize: 17,
        margin: 0,
    },
    description: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        margin: 0,
        marginTop: 5,
    },
}));
