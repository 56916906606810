/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import moment from 'moment-timezone';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getOrdersToInvoiceApi } from 'src/api/letseatmanager/order/getOrdersToInvoiceApi';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { translate } from 'src/i18n/translate';
import { CreateMexicanGlobalInvoiceSummaryDialog } from 'src/scenes/letseatmanager/invoice/CreateMexicanGlobalInvoiceSummaryDialog';
import { OrdersToInvoiceTable } from 'src/scenes/letseatmanager/invoice/OrdersToInvoiceTable';
import { RestaurantInvoiceInfo } from 'src/scenes/letseatmanager/invoice/RestaurantInvoiceInfo';
import { useNotification } from 'src/services/notification/useNotification';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { validateLimitOfRangeDate } from 'src/utils/date/validateLimitOfRangeDate';
import { classNames } from 'src/utils/react/classNames';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function GlobalOrdersInvoices(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const notification = useNotification();

    const [from, setFrom] = useState(moment().startOf('day').toDate());
    const [to, setTo] = useState(moment().endOf('day').toDate());
    const [hasRangeOfDateError, setHasRangeOfDateError] = useState(false);
    const [createGlobalInvoiceSummaryDialogState, setCreateGlobalInvoiceSummaryDialogState] = useState({ open: false });

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurant = useSelector((state) => state.app.restaurant);

    const [loading, ordersToInvoiceSummary, load] = useLoadApi(
        getOrdersToInvoiceApi,
        { restaurantId, to, from },
        { initialValue: { orders: [] }, dependencies: [restaurantId, from, to, hasRangeOfDateError || undefined] }
    );

    const limitDate = moment(from).add(30, 'days');
    const lastOrderToInvoice = ordersToInvoiceSummary?.ordersToInvoice?.[ordersToInvoiceSummary?.ordersToInvoice?.length - 1];
    const hasMissingRestaurantBusinessInfo = !restaurant?.businessName || !restaurant?.regimeFiscal || !restaurant?.rfc || !restaurant?.fiscalAddress;

    useEffect(() => {
        const hasError = validateDate(to);
        setHasRangeOfDateError(!!hasError);

        if (hasError) {
            notification({ message: hasError?.errorMessage });
        }
    }, [from, to]);

    const handleChangeFromDate = (date: any) => setFrom(moment(date)?.startOf('day').toDate());
    const handleChangeToDate = (date: any) => setTo(moment(date)?.endOf('day').toDate());

    const handleCreateGlobalInvoice = () => {
        if (hasMissingRestaurantBusinessInfo) {
            notification({ message: translate('There is pending information from the restaurant, please complete it in the settings') });
            return;
        }
        if (hasRangeOfDateError) {
            notification({ message: translate('The selected date cannot be greater than @limitDate', { limitDate: moment(limitDate).format('YYYY-MM-DD') }) });
            return;
        }

        setCreateGlobalInvoiceSummaryDialogState({ open: true });
    };

    const validateDate = (date: any) => {
        return validateLimitOfRangeDate(date, limitDate.toDate());
    };

    const closeCreateGlobalInvoiceSummaryDialog = () => {
        setCreateGlobalInvoiceSummaryDialogState({ open: false });
        load();
    };

    return (
        <div className={classes.container}>
            <CreateMexicanGlobalInvoiceSummaryDialog
                open={createGlobalInvoiceSummaryDialogState.open}
                from={lastOrderToInvoice?.completedAt ?? from}
                to={ordersToInvoiceSummary?.ordersToInvoice?.[0]?.completedAt ?? to}
                ordersToInvoiceSummary={ordersToInvoiceSummary}
                onClose={closeCreateGlobalInvoiceSummaryDialog}
            />
            <RestaurantInvoiceInfo />
            <div className={classNames(classes.separatedContent, classes.filterContent)}>
                <div className={classes.datesContainer}>
                    <Input name={'from'} type={'date'} label={translate('From')} onChange={handleChangeFromDate} />
                    <Input name={'to'} type={'date'} label={translate('To')} onChange={handleChangeToDate} validation={validateDate} />
                </div>
                <Button classes={{ button: classes.button }} disabled={loading || !ordersToInvoiceSummary?.ordersToInvoice?.length} onClick={handleCreateGlobalInvoice}>
                    {translate('Create Invoice')}
                </Button>
            </div>
            <OrdersToInvoiceTable ordersToInvoiceSummary={ordersToInvoiceSummary!} />
            <div className={classes.container}>
                <div className={classes.separatedContent}>
                    <div className={classes.kpiCard}>{translate('Total tickets: @totalOrders', { totalOrders: ordersToInvoiceSummary?.totalOrders ?? 0 })}</div>
                    <div>
                        <div className={classes.kpiCard}>{translate('Total sold: @totalSold', { totalSold: formatAsCurrencyNumber(ordersToInvoiceSummary?.totalSold ?? 0) })}</div>
                        <div className={classes.kpiCard}>{translate('Invoiced sales: @totalInvoiced', { totalInvoiced: formatAsCurrencyNumber(ordersToInvoiceSummary?.totalInvoiced ?? 0) })}</div>
                    </div>
                </div>
                <div className={classNames(classes.kpiCard, classes.rightContent)}>
                    {translate('Sales to invoice: @totalToInvoice', { totalToInvoice: formatAsCurrencyNumber(ordersToInvoiceSummary?.totalToInvoice ?? 0) })}
                </div>
            </div>
            <div className={classes.text}>{translate('*When creating the invoice only uninvoiced orders are considered ')}</div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        fontSize: 14,
        fontFamily: theme.typography.regular,
        color: '#232933',
    },
    separatedContent: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    filterContent: {
        paddingTop: 20,
    },
    button: {
        height: 42,
        marginTop: 'auto',
        width: 150,
    },
    datesContainer: {
        display: 'flex',
        justifyContent: 'start',
        flexDirection: 'column',
        gap: 10,
        margin: '10px 0',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            margin: 0,
        },
    },
    table: {
        margin: '24px 0',
        paddingBottom: 24,
        minHeight: '40vh',
        overflowY: 'scroll',
        overflowX: 'scroll',
    },
    kpiCard: {
        display: 'inline-block',
        fontFamily: theme.typography.semiBold,
        color: '#2E3748',
        border: '1px solid #D9D9D9',
        borderRadius: 4,
        minWidth: 260,
        padding: '10px 16px',
        textAlign: 'center',
    },
    rightContent: {
        [theme.breakpoints.up('sm')]: {
            marginTop: 10,
            marginLeft: 'auto',
        },
    },
    text: {
        color: '#232933',
        margin: '40px 0',
    },
}));
