/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import * as React from 'react';
import { classNames } from 'src/utils/react/classNames';

export function HeatMapCanvas({ data, maxValue }: Props): React.ReactElement {
    const classes = useStyles();

    const colors = ['#2476C2', '#53C7D7', '#5DE3BC', '#24A8C2', '#13647D', '#0DFFD3', '#A0D8F8', '#A7A0F8', '#6256E9', '#6256E9'];

    return (
        <div className={classNames(classes.container)}>
            <ResponsiveHeatMap
                data={data}
                margin={{ top: 25, right: 5, bottom: 40, left: 40 }}
                valueFormat='>-.2s'
                axisTop={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: 46,
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: -72,
                }}
                colors={{
                    type: 'sequential',
                    scheme: `purple_blue`,
                    minValue: 0,
                    maxValue: maxValue,
                }}
                emptyColor='#555555'
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '60vh',
    },
}));

type Props = {
    data: Array<ComparativeDayItemType>;
    maxValue: number;
};

type ComparativeDayItemType = {
    id: string;
    data: Array<{
        x: string | number;
        y: number;
    }>;
};
