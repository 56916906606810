/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import styles from 'src/scenes/letseatadmin/monitoring/driver-alarm/DriverAlarmStyles';
import { classNames } from 'src/utils/react/classNames';
import { openOrderPageInNewTab } from 'src/utils/window/openOrderPageInNewTab';

class DriverAlarm extends React.PureComponent<Props, State> {
    interval: NodeJS.Timer | undefined;

    constructor(props: Props) {
        super(props);
        this.state = {
            highlighted: false,
        };
    }

    toggleHighlight = () => {
        this.setState({
            highlighted: !this.state.highlighted,
        });
    };

    componentDidMount() {
        this.interval = setInterval(() => {
            this.toggleHighlight();
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    openOrders = async () => {
        try {
            const { driverAlarm } = this.props;
            for (const orderId of driverAlarm.orderIds) {
                openOrderPageInNewTab(orderId);
            }
        } catch (e: any) {
            console.log(e);
        }
    };

    render() {
        const { classes, driverAlarm } = this.props;
        return (
            <Paper className={classNames(classes.paper, { [classes.highlighted]: this.state.highlighted })}>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <Typography display='block' variant='caption'>
                            {translate('RESTAURANT')}
                        </Typography>
                        <Typography display='block' variant='h6'>
                            {driverAlarm.restaurantName}
                        </Typography>
                        <Typography variant='subtitle1'>{driverAlarm.restaurantStreet}</Typography>
                        <Typography variant='subtitle1'>{driverAlarm.restaurantPhoneNumber}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography display='block' variant='caption'>
                            {translate('NO DRIVERS FOUND')}
                        </Typography>
                        <Typography variant='h2'>{driverAlarm.notAcceptedInTime}</Typography>
                        <Button variant='contained' color='primary' fullWidth onClick={this.openOrders}>
                            {translate('Open')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography display='block' variant='caption'>
                            {translate('TIME SINCE SEARCH START')}
                        </Typography>
                        <Typography variant='h6'>{(moment.duration(moment().diff(moment(driverAlarm.noActionSince))) as any).format()}</Typography>
                        <Typography variant='subtitle1'>{moment(driverAlarm.noActionSince).calendar()}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

const DriverAlarmWithStyles: React.ComponentType<any> = withStyles(styles)(DriverAlarm);

export default DriverAlarmWithStyles;

type Props = {
    driverAlarm: any;
    classes: any;
    acceptDrivers: any;
};

type State = {
    highlighted: boolean;
};
