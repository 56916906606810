/**
 * @prettier
 */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { FormAutocompleteMultiple } from 'src/components/form/FormAutocompleteMultiple';
import type { Variant } from 'src/components/form/FormTypes';
import { getDriverOptionsInLocalStorage } from 'src/localStorage/getDriverOptionsInLocalStorage';
import { setDriverOptionsInLocalStorage } from 'src/localStorage/setDriverOptionsInLocalStorage';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function FormDriversAutocomplete({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, InputProps }: Props): React.ReactElement {
    const [options, setOptions] = useState(getDriverOptionsInLocalStorage());
    useEffect(() => {
        async function load() {
            const response = await letseatmanagerApiDeprecated.admin.getDriverOptions();
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            setDriverOptionsInLocalStorage(response.data);
            if (cancelled) return;
            setOptions(response.data);
        }

        let cancelled = false;
        load();
        return () => {
            cancelled = true;
        };
    }, []);

    return (
        <FormAutocompleteMultiple
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={required}
            variant={variant}
            InputProps={InputProps}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: Array<string>;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
};
