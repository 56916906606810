/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import * as React from 'react';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { MoveOrderItemsBetweenOrdersDialog } from 'src/modules/pos/order/components/MoveOrderItemsBetweenOrdersDialog';
import { SelectOrderToMoveOrderItemsDialog } from 'src/modules/pos/order/components/moveOrderItemsDialog/SelectOrderToMoveOrderItemsDialog';
import { useMoveOrderItemsBetweenOrdersActions } from 'src/modules/pos/order/moveOrderItemsBetweenOrdersStore';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function MoveOrderItemsBetweenOrdersButton(): React.ReactElement | null {
    const classes = useStyles();

    const openMoveOrderItemsBetweenOrdersDialog = useMoveOrderItemsBetweenOrdersActions((actions) => actions.openMoveOrderItemsBetweenOrdersDialog);

    const orderType = useSelector((state) => state.pos.orderType);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const items = useSelector((state) => state.pos.items);
    const payments = useSelector((state) => state.pos.payments);
    const ongoingCourses = useSelector((state) => state.pos.ongoingCourses);
    const customers = useSelector((state) => state.pos.customers);

    const shouldRenderMoveOrderItemsButton = !isDeliveryOrder(orderType) && pendingOrder && items?.length > 1 && !payments?.length && !ongoingCourses?.length && !customers?.length;

    if (!shouldRenderMoveOrderItemsButton) return null;

    return (
        <SecuredContent rolePermission={RolePermissions.MOVE_ORDER_ITEMS_BETWEEN_ORDERS}>
            <Button variant={'secondary'} classes={{ button: classes.basicButton }} onClick={openMoveOrderItemsBetweenOrdersDialog}>
                {translate('Move product')}
            </Button>
            <MoveOrderItemsBetweenOrdersDialog />
            <SelectOrderToMoveOrderItemsDialog />
        </SecuredContent>
    );
}

const useStyles = makeStyles((theme) => ({
    basicButton: {
        width: '100%',
        height: 50,
        color: theme.palette.text.secondary,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
