/**
 * @prettier
 */
import * as React from 'react';

export function MenuBurgerIcon({ title, color }: Props): React.ReactElement {
    return (
        <svg width='42' height='43' viewBox='0 0 42 43' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <circle cx='21' cy='21.5' r='21' fill={color || 'currentColor'} />
            <path d='M12 27.76H29.64V25.8H12V27.76ZM12 22.86H29.64V20.9H12V22.86ZM12 16V17.96H29.64V16H12Z' fill='white' />
        </svg>
    );
}
type Props = {
    title?: string;
    color?: string;
};
