/**
 * @prettier
 */
import { IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { switchOrderDeliveryProviderToPideDirectoApi } from 'src/api/letseatadmin/delivery/switchOrderDeliveryProviderToPideDirectoApi';
import { switchOrderDeliveryProviderToRappiCargoApi } from 'src/api/letseatadmin/delivery/switchOrderDeliveryProviderToRappiCargoApi';
import { switchOrderDeliveryProviderToUberDaasApi } from 'src/api/letseatadmin/delivery/switchOrderDeliveryProviderToUberDaasApi';
import { switchOrderDeliveryProviderToUberDirectApi } from 'src/api/letseatadmin/delivery/switchOrderDeliveryProviderToUberDirectApi';
import { updatePaymentMethodApi } from 'src/api/letseatmanager/payment/updatePaymentMethodApi';
import { app2 } from 'src/app2';
import { ChangeCashOrderTotalDialog } from 'src/components/dialog/ChangeCashOrderTotalDialog';
import { ChangeDeliveryAddressDialog } from 'src/components/dialog/delivery/ChangeDeliveryAddressDialog';
import { CreateOrderIncidentDialog } from 'src/components/dialog/order/CreateOrderIncidentDialog';
import { OrderVerificationPhotosDialog } from 'src/components/dialog/order/OrderVerificationPhotosDialog';
import { PrintOrderCommandDialog } from 'src/components/dialog/order/PrintOrderCommandDialog';
import { RefundSuppliesOfCancelledOrderDialog } from 'src/components/dialog/order/RefundSuppliesOfCancelledOrderDialog';
import { AddTipToPosOrderContextualMenuItem } from 'src/components/orderCard/AddTipToPosOrderContextualMenuItem';
import { AssignWaiterContextualMenuOption } from 'src/components/orderCard/AssignWaiterContextualMenuOption';
import { AssignWaiterDialog } from 'src/components/orderCard/AssignWaiterDialog';
import { ChangePaymentMethodContextualMenuItem } from 'src/components/orderCard/ChangePaymentMethodContextualMenuItem';
import { InvoiceOrderContextualMenuItem } from 'src/components/orderCard/InvoiceOrderContextualMenuItem';
import { PrintTicketContextualMenuItem } from 'src/components/orderCard/PrintTicketContextualMenuItem';
import { ReturnOrderDialog } from 'src/components/orderCard/returnOrder/ReturnOrderDialog';
import { SecuredContent } from 'src/components/SecuredContent';
import { Countries } from 'src/constants/Country';
import { DeliveryStatuses } from 'src/constants/DeliveryStatus';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { AssignDriverDialog } from 'src/scenes/letseatadmin/driver/AssignDriverDialog';
import { ChangeDynamicDeliveryEarningsDialog } from 'src/scenes/letseatadmin/dynamicDeliveryEarnings/ChangeDynamicDeliveryEarningsDialog';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { alertResponseWithAlertKnowErrorOrSomethingWentWrong } from 'src/utils/alert/alertResponseWithAlertKnowErrorOrSomethingWentWrong';
import { isPosApp } from 'src/utils/app/isPosApp';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isCancelledOrder } from 'src/utils/order/isCancelledOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isIntegrationOrder } from 'src/utils/order/isIntegrationOrder';
import { isNewOrder } from 'src/utils/order/isNewOrder';
import { isOrderPriceChangeAvailable } from 'src/utils/order/isOrderPriceChangeAvailable';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { isPideDirectoDelivery } from 'src/utils/order/isPideDirectoDelivery';
import { isRappiDelivery } from 'src/utils/order/isRappiDelivery';
import { isRejectedOrder } from 'src/utils/order/isRejectedOrder';
import { isUberDaasDelivery } from 'src/utils/order/isUberDaasDelivery';
import { isUberDelivery } from 'src/utils/order/isUberDelivery';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { useHasPrintersToPrint } from 'src/utils/printer/useHasPrintersToPrint';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';
import { canRestaurantInvoice } from 'src/utils/restaurant/canRestaurantInvoice';
import { openOrderPageInNewTab } from 'src/utils/window/openOrderPageInNewTab';
import { openUberEatsOrderTrackingUrlInNewTab } from 'src/utils/window/openUberEatsOrderTrackingUrlInNewTab';

export function OrderCardContextualMenu({ order, updateLoadingOrder, disabled, onChange }: Props): React.ReactElement {
    const classes = useStyles();
    const [userHasRolePermission] = useUserHasRolePermission();
    const { hasPrintersToPrint } = useHasPrintersToPrint();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [assignDriverDialogState, setAssignDriverDialogState] = useState({ open: false });
    const [changeDynamicDeliveryEarningsDialogState, setChangeDynamicDeliveryEarningsDialogState] = useState({ open: false });
    const [changeCashOrderTotalDialogState, setChangeCashOrderTotalDialogState] = useState({ open: false });
    const [changeDeliveryAddressDialogState, setChangeDeliveryAddressDialogState] = useState({ open: false });
    const [refundSuppliesDialogState, setRefundSuppliesDialogState] = useState({ open: false });
    const [returnOrderDialogState, setReturnOrderDialogState] = useState({ open: false });
    const [createOrderIncidentDialogState, setCreateOrderIncidentDialogState] = useState({ open: false });
    const [orderVerificationPhotosDialogState, setOrderVerificationPhotosDialog] = useState(false);
    const [assignWaiterDialogState, setAssignWaiterDialogState] = useState({ open: false });
    const [printOrderCommandDialogState, setPrintOrderCommandDialogState] = useState({ open: false });

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const restaurant = useSelector((state) => state.app.restaurant);
    const userManagementEnabled = useSelector((state) => state.app.restaurant?.userManagementEnabled);
    const assignDriverEnabled = useSelector((state) => state.app.restaurant?.assignDriverEnabled);
    const viewUser = useSelector((state) => state.authentication.viewUser);
    const inventoryEnabled = useSelector((state) => state.app.restaurant?.inventoryEnabled);
    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);
    const externalDeliveryEnabled = useSelector((state) => state.app.restaurant?.externalDelivery);
    const authorizationToReprintCompletedOrderEnabled = useSelector((state) => state.app.restaurant.authorizationToReprintCompletedOrderEnabled);
    const uberDaasDeliveryVerificationPictureEnabled = useSelector((state) => state.app.restaurant?.uberDaasDeliveryVerificationPictureEnabled);

    const openRejectOrderDialog = useAction(app2.actions.openRejectOrderDialog);
    const openVerifyPosPasswordDialog = useAction(app2.actions.openVerifyPosPasswordDialog);

    const open = Boolean(anchorEl);
    const isOngoingEaterHereOrder = isEatHereOrder(order.orderType) && (isAcceptedOrder(order.orderStatus) || isPendingOrder(order.orderStatus));
    const renderSwitchToUberDirectButton = internalUser && !externalDeliveryEnabled && (isPideDirectoDelivery(order) || isRappiDelivery(order) || isUberDaasDelivery(order));
    const renderSwitchToRappiCargoButton = internalUser && !externalDeliveryEnabled && (isPideDirectoDelivery(order) || isUberDelivery(order) || isUberDaasDelivery(order));
    const renderSwitchToUberDaasButton = internalUser && !externalDeliveryEnabled && (isPideDirectoDelivery(order) || isUberDelivery(order) || isRappiDelivery(order));
    const renderChangeDynamicEarning = internalUser && isDeliveryOrder(order.orderType) && isPideDirectoDelivery(order);
    const renderSwitchToPdDeliveryButton = internalUser && !isPideDirectoDelivery(order);
    const isDeliveryOrderWithOutDriver = isDeliveryOrder(order.orderType) && !order.driverId;
    const isNotPickedUp = order.deliveryStatus !== DeliveryStatuses.PICKED_UP;
    const renderChangeDeliveryAddressButton =
        isPideDirectoDelivery(order) &&
        (isCashPayment(order.paymentMethod) || isCardPayment(order.paymentMethod) || isPaymentLinkPayment(order.paymentMethod)) &&
        !(isCompletedOrder(order.orderStatus) || isRejectedOrder(order.orderStatus) || isCancelledOrder(order.orderStatus)) &&
        isNotPickedUp;
    const renderChangeCashOrderButton = isOrderPriceChangeAvailable(restaurant, order);
    const renderAssignDriverButton = isDeliveryOrderWithOutDriver && !externalDeliveryEnabled && (internalUser || assignDriverEnabled) && isPideDirectoDelivery(order);
    const renderCancelOrderButton = isAcceptedOrder(order.orderStatus) || isPendingOrder(order.orderStatus) || (isPosApp(order.app) && isCompletedOrder(order.orderStatus));
    const mexicanInvoicedOrder = order.country === Countries.MX && order.invoiced;
    const renderReturnOrderOption = isPideDirectoDelivery(order) && !isIntegrationOrder(order) && isCompletedOrder(order.orderStatus) && !mexicanInvoicedOrder;
    const orderHasExternalDelivery = order.externalDelivery || order.ownFleet || order.externalOwnFleet;

    const [printOrderTicket] = usePrintOrderTicket();

    const handleClick = (event: any) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const handleOpenOrderVerificationPhotosDialogState = () => {
        setOrderVerificationPhotosDialog(true);
    };

    const handleCloseOrderVerificationPhotosDialogState = () => {
        setOrderVerificationPhotosDialog(false);
    };

    const handleClickSwitchToUberDirect = async () => {
        updateLoadingOrder(true);
        const response = await switchOrderDeliveryProviderToUberDirectApi({ orderId: order.orderId });
        alertResponseWithAlertKnowErrorOrSomethingWentWrong(response);
        onChange?.(order);
    };

    const handleEnableSwitchPaymentMethod = async (paymentMethod: any) => {
        const password = prompt(translate('Password'));
        if (password !== '1212') return alert(translate('Wrong Password'));

        await handleClickSwitchPaymentMethod(paymentMethod);
    };

    const handleClickSwitchPaymentMethod = async (paymentMethod: any) => {
        updateLoadingOrder(true);
        const response = await updatePaymentMethodApi({ orderId: order.orderId, paymentMethod });
        onChange(order);
        updateLoadingOrder(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
    };

    const handleClickSwitchToPdDrivers = async () => {
        updateLoadingOrder(true);
        const response = await switchOrderDeliveryProviderToPideDirectoApi({ orderId: order.orderId });
        alertResponseWithAlertKnowErrorOrSomethingWentWrong(response);
        onChange?.(order);
    };

    const handleClickSwitchToRappiCargo = async () => {
        updateLoadingOrder(true);
        const response = await switchOrderDeliveryProviderToRappiCargoApi({ orderId: order.orderId });
        alertResponseWithAlertKnowErrorOrSomethingWentWrong(response);
        onChange?.(order);
    };

    const handleClickSwitchToUberDaas = async () => {
        updateLoadingOrder(true);
        const response = await switchOrderDeliveryProviderToUberDaasApi({ orderId: order.orderId });
        alertResponseWithAlertKnowErrorOrSomethingWentWrong(response);
        onChange?.(order);
    };

    const handelChangeCashOrderSubTotalDialog = () => {
        updateLoadingOrder(true);
        onChange?.(order);
        setChangeCashOrderTotalDialogState({ open: false });
        updateLoadingOrder(false);
    };

    async function handleClickCancelOrRejectAcceptedOrder() {
        const rejectOrder = window.confirm(translate('Are you sure you want to reject this order? Please note that the customer has been notified that the order has been accepted already.'));
        if (!rejectOrder) return;

        if (order.recipeIds?.length && inventoryEnabled) {
            return openRefundSuppliesDialog();
        }

        if (!userHasRolePermission(RolePermissions.CANCEL_ORDERS)) {
            return openVerifyPosPasswordDialog({
                onAuthorized: () => {
                    openRejectOrderDialog({
                        orderId: order.orderId,
                        onReject: (order: OrderVm) => {
                            onChange?.(order);
                        },
                    });
                },
                onUnauthorized: () => alert(translate('Unauthorized to access.')),
            });
        }
        openRejectOrderDialog({
            orderId: order.orderId,
            onReject: (order: OrderVm) => {
                onChange?.(order);
            },
        });
    }

    const setManualDriverDialogOpen = () => {
        setAssignDriverDialogState({ open: true });
    };

    const setManualDriverDialogClose = () => setAssignDriverDialogState({ open: false });

    const setChangeDeliveryAddressDialogOpen = () => setChangeDeliveryAddressDialogState({ open: true });

    const setChangeDeliveryAddressDialogClose = () => setChangeDeliveryAddressDialogState({ open: false });
    const openReturnOrderDialog = () => setReturnOrderDialogState({ open: true });
    const closeReturnOrderDialog = () => {
        handleClose();
        setReturnOrderDialogState({ open: false });
    };

    const handleReturnOrder = () => {
        if (!posEnabled) {
            openReturnOrderDialog();
            return;
        }

        openVerifyPosPasswordDialog({
            onAuthorized: () => {
                openReturnOrderDialog();
            },
            onUnauthorized: () => alert(translate('Unauthorized to access.')),
        });
    };

    const openRefundSuppliesDialog = () => setRefundSuppliesDialogState({ open: true });

    const closeRefundSuppliesDialog = () => setRefundSuppliesDialogState({ open: false });

    const openAssignWaiterDialog = () => setAssignWaiterDialogState({ open: true });

    const closeAssignWaiterDialog = () => setAssignWaiterDialogState({ open: false });

    const openCreateOrderIncidentDialog = () => setCreateOrderIncidentDialogState({ open: true });

    const closeCreateOrderIncidentDialog = () => setCreateOrderIncidentDialogState({ open: false });

    const openPrintOrderCommandDialog = () => setPrintOrderCommandDialogState({ open: true });

    const closePrintOrderCommandDialog = () => setPrintOrderCommandDialogState({ open: false });

    return (
        <div>
            <AssignWaiterDialog open={assignWaiterDialogState.open} orderId={order.orderId} handleClose={closeAssignWaiterDialog} />
            <RefundSuppliesOfCancelledOrderDialog order={order} open={refundSuppliesDialogState.open} onClose={closeRefundSuppliesDialog} onSuccess={onChange} />
            <AssignDriverDialog open={assignDriverDialogState.open} onClose={setManualDriverDialogClose} orderId={order.orderId} disabled={order.demoOrder} />
            <ChangeDeliveryAddressDialog onClose={setChangeDeliveryAddressDialogClose} open={changeDeliveryAddressDialogState.open} order={order} />
            <ReturnOrderDialog open={returnOrderDialogState.open} onClose={closeReturnOrderDialog} order={order} onChange={onChange} openReturnOrderDialog={openReturnOrderDialog} />
            <OrderVerificationPhotosDialog order={order} open={orderVerificationPhotosDialogState} onClose={handleCloseOrderVerificationPhotosDialogState} />
            <ChangeDynamicDeliveryEarningsDialog
                open={changeDynamicDeliveryEarningsDialogState.open}
                orderId={order.orderId}
                onClose={() => setChangeDynamicDeliveryEarningsDialogState({ open: false })}
            />
            <CreateOrderIncidentDialog open={createOrderIncidentDialogState.open} order={order} onClose={closeCreateOrderIncidentDialog} onSuccess={onChange} />
            <PrintOrderCommandDialog open={printOrderCommandDialogState.open} order={order} onClose={closePrintOrderCommandDialog} />
            <ChangeCashOrderTotalDialog open={changeCashOrderTotalDialogState.open} orderId={order.orderId} total={order.subtotal} onClose={handelChangeCashOrderSubTotalDialog} />
            <IconButton
                aria-label='menuButton'
                id='basic-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                role='button'
            >
                <MoreVert />
            </IconButton>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className={classes.contextualMenu}
            >
                {internalUser && <MenuItem onClick={() => openOrderPageInNewTab(order.orderId)}>{translate('Open order')}</MenuItem>}
                {renderChangeDeliveryAddressButton && (
                    <MenuItem disabled={disabled} onClick={setChangeDeliveryAddressDialogOpen}>
                        {translate('Change the delivery address')}
                    </MenuItem>
                )}
                {renderChangeDynamicEarning && <MenuItem onClick={() => setChangeDynamicDeliveryEarningsDialogState({ open: true })}>{translate('Change Dynamic Earning')}</MenuItem>}
                {renderSwitchToUberDirectButton && (
                    <MenuItem disabled={disabled} onClick={handleClickSwitchToUberDirect}>
                        {translate('Switch To Uber Direct')}
                    </MenuItem>
                )}
                {renderSwitchToPdDeliveryButton && (
                    <MenuItem disabled={disabled} onClick={handleClickSwitchToPdDrivers}>
                        {translate('Switch To Pd Drivers')}
                    </MenuItem>
                )}
                {renderSwitchToRappiCargoButton && (
                    <MenuItem disabled={disabled} onClick={handleClickSwitchToRappiCargo}>
                        {translate('Switch To Rappi Cargo')}
                    </MenuItem>
                )}
                {renderSwitchToUberDaasButton && (
                    <MenuItem disabled={disabled} onClick={handleClickSwitchToUberDaas}>
                        {translate('Switch To Uber Daas')}
                    </MenuItem>
                )}
                {renderChangeCashOrderButton && (
                    <MenuItem disabled={disabled} onClick={() => setChangeCashOrderTotalDialogState({ open: true })}>
                        {translate('Change Cash Total')}
                    </MenuItem>
                )}
                {renderAssignDriverButton && (
                    <MenuItem disabled={disabled} onClick={setManualDriverDialogOpen}>
                        {translate('Assign a Driver')}
                    </MenuItem>
                )}
                {renderCancelOrderButton && (
                    <SecuredContent rolePermission={RolePermissions.CANCEL_ORDERS}>
                        <MenuItem disabled={disabled} onClick={handleClickCancelOrRejectAcceptedOrder}>
                            {isNewOrder(order.orderStatus) ? translate('Reject order') : translate('Cancel order')}
                        </MenuItem>
                    </SecuredContent>
                )}
                {renderReturnOrderOption && (
                    <MenuItem disabled={disabled} onClick={handleReturnOrder}>
                        {translate('Return Order')}
                    </MenuItem>
                )}
                {isOngoingEaterHereOrder && userManagementEnabled && (
                    <MenuItem>
                        <AssignWaiterContextualMenuOption disabled={disabled} order={order} openAssignWaiterDialog={openAssignWaiterDialog} />
                    </MenuItem>
                )}
                <ChangePaymentMethodContextualMenuItem order={order} disabled={disabled} onChange={onChange} />
                {order.uberEatsOrderTrackingUrl && (
                    <MenuItem onClick={() => openUberEatsOrderTrackingUrlInNewTab(order.uberEatsOrderTrackingUrl)} style={{ textTransform: 'none' }} disabled={viewUser}>
                        {translate('Track Uber Order')}
                    </MenuItem>
                )}
                {order.uberDaasOrderTrackingUrl && (
                    <MenuItem onClick={() => openUberEatsOrderTrackingUrlInNewTab(order.uberDaasOrderTrackingUrl)} style={{ textTransform: 'none' }} disabled={viewUser}>
                        {translate('Track Uber Daas')}
                    </MenuItem>
                )}
                {order.rappiCargoDeliveryTrackingUrl && (
                    <MenuItem onClick={() => openUberEatsOrderTrackingUrlInNewTab(order.rappiCargoDeliveryTrackingUrl)} style={{ textTransform: 'none' }} disabled={viewUser}>
                        {translate('Track Rappi Order')}
                    </MenuItem>
                )}
                {(isUberDaasDelivery(order) || uberDaasDeliveryVerificationPictureEnabled) && (
                    <MenuItem onClick={handleOpenOrderVerificationPhotosDialogState} style={{ textTransform: 'none' }} disabled={viewUser}>
                        {translate('Verification photos')}
                    </MenuItem>
                )}
                {isDeliveryOrder(order.orderType) && internalUser && !orderHasExternalDelivery && (
                    <MenuItem onClick={openCreateOrderIncidentDialog} style={{ textTransform: 'none' }} disabled={viewUser}>
                        {translate('Register incident')}
                    </MenuItem>
                )}
                {hasPrintersToPrint && (
                    <div>
                        <PrintTicketContextualMenuItem order={order} />
                        <SecuredContent rolePermission={RolePermissions.REPRINT_ORDER_COMMAND}>
                            <MenuItem onClick={openPrintOrderCommandDialog} style={{ textTransform: 'none' }}>
                                {translate('Reprint Command')}
                            </MenuItem>
                        </SecuredContent>
                    </div>
                )}
                {!order.invoiced && canRestaurantInvoice(restaurant) && <InvoiceOrderContextualMenuItem order={order} onChange={onChange} />}
                <AddTipToPosOrderContextualMenuItem order={order} onChange={onChange} />
            </Menu>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    contextualMenu: {
        boxShadow: '0px 8px 20px -4px rgba(23, 24, 24, 0.12), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
}));

type Props = {
    order: OrderVm;
    onChange: any;
    updateLoadingOrder: any;
    disabled: boolean;
};
