/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { DriverBannerVm } from 'src/types/DriverBannerVm';

export async function findDriverBannersApi(): ApiSauceResponse<Array<DriverBannerVm>> {
    return letseatadminApiMethod('driverBanner/findDriverBannersApi');
}
