/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { ClientFrequency } from 'src/constants/ClientFrequency';
import { GiftVm } from 'src/types/GiftVm';
import type { CustomerId, EmailAddress, RestaurantCustomerId, RestaurantId } from 'src/types/Id';

export async function findRestaurantCustomersApi(request: FindRestaurantCustomersApiRequest): ApiSauceResponse<Array<RestaurantCustomerVm>> {
    return letseatmanagerApiMethod('restaurantCustomer/findRestaurantCustomersApi', request);
}

type FindRestaurantCustomersApiRequest = {
    restaurantId: RestaurantId;
};

export type RestaurantCustomerVm = {
    restaurantCustomerId: RestaurantCustomerId;
    customerId?: CustomerId;
    name: string;
    firstName?: string;
    lastName?: string;
    mobileNumber?: string;
    email?: EmailAddress;
    note?: string;
    amount: number;
    cashback?: string; //TODO: Remove this when its safe, when all the apps were updated successfully, credits in the customer will be deprecated
    credits?: string; //TODO: Remove this when its safe, when all the apps were updated successfully, credits in the customer will be deprecated
    gifts?: Array<GiftVm>;
    apps: Array<App>;
    clientFrequency?: ClientFrequency;
    orders: number;
    lastOrder?: Date;
    secondLastOrder?: Date;
};
