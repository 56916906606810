/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId, RestaurantZoneId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export async function findPendingTableOrderApi(request: FindPendingTableOrderApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('order/findTableOrderByRestaurantIdAndRestaurantZoneIdAndTableApi', request);
}

export type FindPendingTableOrderApiRequest = {
    restaurantId: RestaurantId;
    restaurantZoneId?: RestaurantZoneId;
    table?: string;
};
