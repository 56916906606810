/**
 * @prettier
 */
import type { CountryCode } from 'src/types/Id';

export const Countries = {
    MX: 'MX' as CountryCode,
    SE: 'SE' as CountryCode,
    US: 'US' as CountryCode,
    UY: 'UY' as CountryCode,
    AR: 'AR' as CountryCode,
    BR: 'BR' as CountryCode,
    PE: 'PE' as CountryCode,
    CL: 'CL' as CountryCode,
    PY: 'PY' as CountryCode,
} as const;

export type Country = typeof Countries[keyof typeof Countries];
