/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import moment from 'moment/moment';
import { useCallback } from 'react';
import { completePendingOrderApi, CompletePendingOrderApiRequest } from 'src/api/letseatmanager/pos/completePendingOrderApi';
import { KitchenStatuses } from 'src/constants/KitchenStatus';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { OrderTypes } from 'src/constants/OrderType';
import { PaymentMethod, PaymentMethods } from 'src/constants/PaymentMethod';
import { PickupTimeTypes } from 'src/constants/PickupTimeType';
import { translate } from 'src/i18n/translate';
import { savePosOrderOffline } from 'src/localStorage/savePosOrderOffline';
import { useClearCustomerInfo } from 'src/modules/pos/customer/useClearCustomerInfo';
import { useGetCustomerInfo } from 'src/modules/pos/customer/useGetCustomerInfo';
import { useHasCustomerInfoAnyError } from 'src/modules/pos/customer/useHasCustomerInfoAnyError';
import { useSetCustomerInfoError } from 'src/modules/pos/customer/useSetCustomerInfoError';
import { appReducer } from 'src/reducers/appReducer';
import { posReducer } from 'src/reducers/posReducer';
import { useNotification } from 'src/services/notification/useNotification';
import { usePosPaymentMethods } from 'src/services/pos/posPaymentMethods/usePosPaymentMethods';
import { usePosPaymentMethodsDeprecated } from 'src/services/pos/posPaymentMethods/usePosPaymentMethodsDeprecated';
import { useClearPos } from 'src/services/pos/useClearPos';
import { usePreparationTime } from 'src/services/restaurant/usePreparationTime';
import { CartItemVm } from 'src/types/CartItemVm';
import { OrderVm } from 'src/types/OrderVm';
import { PosPayment } from 'src/types/PosPayment';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isPreparedKitchenOrder } from 'src/utils/order/isPreparedKitchenOrder';
import { isPreparingKitchenOrder } from 'src/utils/order/isPreparingKitchenOrder';
import { mapCustomerToOrderCustomer } from 'src/utils/order/mapCustomerToOrderCustomer';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { mapCartItemToOrderItem } from 'src/utils/pos/mapCartItemToOrderItem';
import { mapPosCourseToOrderCourse } from 'src/utils/pos/mapPosCourseToOrderCourse';
import { posPaymentCreatePaymentLink } from 'src/utils/pos/posPaymentCreatePaymentLink';
import { useAction } from 'src/utils/react/useAction';
import { useMappedOrderPaymentsVm } from 'src/utils/react/useMappedOrderPaymentsVm';
import { useMapPosPayments } from 'src/utils/react/useMapPosPayments';
import { useMapPosTips } from 'src/utils/react/useMapPosTips';
import { useSaveNewPosOrder } from 'src/utils/react/useSaveNewPosOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { sum } from 'src/utils/reduce/sum';
import { requirePaymentMethod } from 'src/utils/require/requirePaymentMethod';
import { requireValue } from 'src/utils/require/requireValue';

export function useCompletePosOrder(): {
    completeOrder: (params: CompleteOrderParams | void) => Promise<OrderVm | void>;
} {
    const preparationTime = usePreparationTime();
    const { saveNewPosOrder } = useSaveNewPosOrder();

    const clearPos = useClearPos();
    const hasAnyError = useHasCustomerInfoAnyError();
    const setError = useSetCustomerInfoError();
    const clearCustomerInfo = useClearCustomerInfo();

    const getCustomerInfo = useGetCustomerInfo();
    const notification = useNotification();
    const mappedPosPayments = useMapPosPayments();
    const mappedPayments = useMappedOrderPaymentsVm();
    const mappedPosTips = useMapPosTips();
    const posRestaurantPaymentMethods = usePosPaymentMethods();
    const posPaymentMethods = usePosPaymentMethodsDeprecated();

    const restaurantPaymentMethodsEnabled = useSelector((state) => state.pos.context?.restaurantPaymentMethodsEnabled);
    const total = useSelector((state) => state.pos.payment?.total);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const table = useSelector((state) => state.pos.table);
    const orderType = useSelector((state) => state.pos.orderType);
    const driverRequest = useSelector((state) => state.pos.driverRequest);
    const posPendingOrdersEnabled = useSelector((state) => state.pos.context?.posPendingOrdersEnabled);
    const items = useSelector((state) => state.pos.items);
    const cancelledItems = useSelector((state) => state.pos.cancelledItems);
    const pickupTimeType = useSelector((state) => state.pos.pickupTimeType);
    const pickupTime = useSelector((state) => state.pos.pickupTime);
    const discount = useSelector((state) => state.pos.discount);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const payments = useSelector((state) => state.pos.payments);
    const disabledPosPaymentActions = useSelector((state) => state.pos.disabledPosPaymentActions);
    const totalPaid = useSelector((state) => state.pos.totalPaid);
    const notes = useSelector((state) => state.pos.notes);
    const posCustomersRequiredEnabled = useSelector((state) => state.pos.context?.posCustomersRequiredEnabled);
    const promoCode = useSelector((state) => state.pos.promoCode);
    const customerId = useSelector((state) => state.pos.customerId);
    const useLetsEatCredits = useSelector((state) => state.pos.useLetsEatCredits);
    const customers = useSelector((state) => state.pos.customers);
    const courses = useSelector((state) => state.pos.ongoingCourses);
    const posNumberOfCustomers = useSelector((state) => state.pos.numberOfCustomers);
    const acceptManuallyAllOrdersEnabled = useSelector((state) => state.app.restaurant?.acceptManuallyAllOrdersEnabled);
    const usedPromotions = useSelector((state) => state.pos.payment?.usedPromotions);

    const setDisabledPosPaymentActions = useAction(posReducer.actions.setDisabledPosPaymentActions);
    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const hasPaymentMissing = BigNumber(totalPaid).isLessThan(total);
    const isOnlyPaidInCash = payments?.every((payment: PosPayment) => isCashPayment(payment.paymentMethod) && !payment.customPaymentMethod);
    const isOnlyPaidInCard = payments?.every((payment: PosPayment) => isCardPayment(payment.paymentMethod) && !payment.customPaymentMethod);

    const getPaymentMethod = useCallback(() => {
        if (hasPaymentMissing) return PaymentMethods.PENDING;
        if (payments.length === 1 && Object.keys(PaymentMethods).includes(payments[0].paymentMethod) && !payments[0].customPaymentMethod) return requirePaymentMethod(payments[0].paymentMethod);
        if (payments.length === 1) {
            if (restaurantPaymentMethodsEnabled) {
                const posPaymentMethod = posRestaurantPaymentMethods.find((customPaymentMethod) => customPaymentMethod.name === payments[0].customPaymentMethod);
                return requireValue(posPaymentMethod?.paymentMethod);
            }
            const posPaymentMethod = posPaymentMethods.find((customPaymentMethod) => customPaymentMethod.name === payments[0].customPaymentMethod);
            return requireValue(posPaymentMethod?.paymentMethod);
        }
        if (payments.some((payment) => isCardPayment(payment.paymentMethod))) return PaymentMethods.CREDIT_CARD;

        if (!hasPaymentMissing && useLetsEatCredits) return PaymentMethods.CREDIT_CARD;

        return PaymentMethods.CASH;
    }, [hasPaymentMissing, payments, posPaymentMethods, useLetsEatCredits]);

    const completeOrder = async (params: CompleteOrderParams | void) => {
        if (hasAnyError) {
            notification({ message: translate('There is pending data or an error in the customer data') });
            return;
        }

        return pendingOrder ? await completePendingOrder(params?.uruguayanRucInvoice) : await saveOrder(params?.uruguayanRucInvoice);
    };

    const completePendingOrder = async (uruguayanRucInvoice?: boolean) => {
        if (disabledPosPaymentActions) return;

        if (hasPaymentMissing) {
            alert(translate('There is pending balance.'));
            return;
        }
        const { firstName, lastName, mobileNumber, numberOfCustomers, email, customerNote } = getCustomerInfo();

        if (posCustomersRequiredEnabled && !numberOfCustomers && pendingOrder.table) {
            setError('numberOfCustomers', translate('This field is required'));
            return;
        }

        if (orderType === OrderTypes.DELIVERY_ORDER) requireValue(driverRequest);

        setDisabledPosPaymentActions(true);

        const paymentMethod = getPaymentMethod();
        const now = new Date();

        const request: CompletePendingOrderApiRequest = {
            orderId: pendingOrder?.orderId,
            restaurantId,
            customerId,
            promoCodeId: promoCode?.promoCodeId,
            orderType,
            promotionIds: removeDuplicates(usedPromotions?.map((promotion) => promotion.promotionId)),
            orderStatus: OrderStatuses.COMPLETE,
            firstName,
            mobileNumber: !!mobileNumber ? mobileNumber : undefined,
            email,
            customerNote,
            paymentMethod: paymentMethod as PaymentMethod,
            posPayments: mappedPosPayments,
            payments: mappedPayments,
            pickupTimeType: getPickupTimeType(),
            pickupTime: getPickupTime(),
            orderItems: items?.map((item: CartItemVm) => ({
                ...mapCartItemToOrderItem(item),
                addedAt: item?.addedAt ?? now,
                orderItemKitchenStatus:
                    isPreparedKitchenOrder(pendingOrder?.kitchenStatus) && !isPreparingKitchenOrder(item.orderItemKitchenStatus) ? KitchenStatuses.PARTIALLY_PREPARED : item.orderItemKitchenStatus,
            })),
            cancelledItems: cancelledItems?.map(mapCartItemToOrderItem),
            posDiscount: discount,
            tips: mappedPosTips,
            useLetsEatCredits,
            customers: customers?.map(mapCustomerToOrderCustomer),
            courses: courses?.map(mapPosCourseToOrderCourse),
            notes,
            uruguayanRucInvoice,
        };

        if (!isOnlyPaidInCash || !isOnlyPaidInCard) {
            request.partiallyPaidByCash = true;
            request.partiallyPaidByCashAmount = getPartialCashAmountPaid(payments);
        }

        const response = await completePendingOrderApi(request);
        setDisabledPosPaymentActions(false);

        if (!response.ok) {
            if (response.problem === 'NETWORK_ERROR') {
                savePosOrderOffline(request);
                clearPos();
                clearCustomerInfo();

                return;
            }
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        if (isPaymentLinkPayment(paymentMethod)) {
            await posPaymentCreatePaymentLink({ orderId: response.data?.orderId, restaurantId, totalWithDeliveryCost: total });
            showSnackbar({ message: translate('Payment Link copied to clipboard') });
        }

        showSnackbar({ message: translate('Order completed') });

        return response.data;
    };

    const saveOrder = async (uruguayanRucInvoice?: boolean) => {
        if (disabledPosPaymentActions) return;

        if (hasPaymentMissing && (!posPendingOrdersEnabled || acceptManuallyAllOrdersEnabled || isDeliveryOrder(orderType))) {
            alert(translate('There is pending balance.'));
            return;
        }

        const { firstName, lastName, mobileNumber, numberOfCustomers, email, customerNote } = getCustomerInfo();

        if (posCustomersRequiredEnabled && !numberOfCustomers && pendingOrder?.table) {
            setError('numberOfCustomers', translate('This field is required'));
            return;
        }

        const paymentMethod = getPaymentMethod();

        const { response, request } = await saveNewPosOrder({
            firstName,
            lastName,
            mobileNumber,
            email,
            customerNote,
            uruguayanRucInvoice,
            numberOfCustomers: parseFloat(numberOfCustomers as any) ?? parseFloat(table?.numberOfCustomers as any) ?? parseFloat(posNumberOfCustomers as any),
        });

        if (!response.ok) {
            if (response.problem === 'NETWORK_ERROR') {
                savePosOrderOffline(request as any);
                clearPos();
                clearCustomerInfo();
                return;
            }
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        if (isPaymentLinkPayment(paymentMethod)) {
            await posPaymentCreatePaymentLink({ orderId: response.data?.orderId, restaurantId, totalWithDeliveryCost: total });
            showSnackbar({ message: translate('Payment Link copied to clipboard') });
        }

        showSnackbar({ message: translate('Order saved') });

        return response.data;
    };

    const getPartialCashAmountPaid = (posPayments: Array<PosPayment>) => {
        const cashPayments = posPayments.filter((posPayment) => isCashPayment(posPayment.paymentMethod) && !posPayment.customPaymentMethod);
        const cashPaymentsAmounts = cashPayments.map((cashPayment) => cashPayment.amount);
        return cashPaymentsAmounts.reduce(sum, BigNumber(0)).toString();
    };

    const getPickupTime = () => {
        if (isDeliveryOrder(orderType)) {
            if (driverRequest.pickupTime) return driverRequest.pickupTime;
            return moment().add(preparationTime, 'minute').toDate();
        }
        return moment(pickupTime).toDate();
    };

    const getPickupTimeType = () => {
        if (isDeliveryOrder(orderType)) {
            return driverRequest.pickupTimeType;
        }
        return pickupTimeType ?? PickupTimeTypes.ASAP;
    };

    return {
        completeOrder,
    };
}

type CompleteOrderParams = {
    uruguayanRucInvoice?: boolean;
};
