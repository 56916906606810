/**
 * @prettier
 */
import { useTheme } from '@material-ui/core';
import * as React from 'react';

export function EllipsisVerticalIcon({ title, color }: Props): React.ReactElement {
    const theme = useTheme();

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            className='icon icon-tabler icon-tabler-dots-vertical'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke={color || theme.palette.icons.brand}
            fill='none'
            strokeLinecap='round'
            strokeLinejoin='round'
        >
            {title && <title>{title}</title>}
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0' />
            <path d='M12 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0' />
            <path d='M12 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0' />
        </svg>
    );
}

type Props = {
    title?: string;
    color?: string;
};
