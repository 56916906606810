/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { FaqId } from 'src/types/Id';

export async function getFaqApi(request: GetFaqApiRequest): ApiSauceResponse<FaqVm> {
    return letseatadminApiMethod('faq/getFaqApi', request);
}

export type GetFaqApiRequest = {
    faqId: FaqId;
};

export type FaqVm = {
    faqId: FaqId;
    question: string;
    answer: string;
    imageUrl: string;
    position: number;
    articleUrl?: string;
};
