/**
 * @prettier
 */
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import 'moment/locale/es';
import 'moment/locale/sv';

momentDurationFormatSetup(moment as any);
moment.locale(navigator.language || (navigator as any).userLanguage);
