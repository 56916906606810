/**
 * @prettier
 */
import { Cities, City } from 'src/constants/City';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';

export function getCenterOfCity(value: City): GeoJsonPoint {
    if (!value) {
        return undefined as any;
    }
    switch (value) {
        case Cities.GUADALAJARA:
            return {
                type: 'Point',
                coordinates: [-103.35965500821003, 20.677824970938417],
            } as any;
        case Cities.LEON:
            return {
                type: 'Point',
                coordinates: [-101.68243, 21.121062],
            } as any;
        case Cities.MONTERREY:
            return {
                type: 'Point',
                coordinates: [-100.28113349107028, 25.714322666594946],
            } as any;
        case Cities.MEXICO_CITY:
            return {
                type: 'Point',
                coordinates: [-99.13324734990078, 19.434553808274757],
            } as any;
        case Cities.PUEBLA:
            return {
                type: 'Point',
                coordinates: [-98.22167656444411, 19.05672008707803],
            } as any;
        case Cities.STOCKHOLM:
            return {
                type: 'Point',
                coordinates: [18.066979999131025, 59.32478077550334],
            } as any;
        case Cities.SALTILLO:
            return {
                type: 'Point',
                coordinates: [-101, 25.433333],
            } as any;
        case Cities.TORREON:
            return {
                type: 'Point',
                coordinates: [-103.41898, 25.54389],
            } as any;
        case Cities.MERIDA:
            return {
                type: 'Point',
                coordinates: [-89.62, 20.97],
            } as any;
        case Cities.QUERETARO:
            return {
                type: 'Point',
                coordinates: [-100.391, 20.591],
            } as any;
        default:
            return undefined as any;
    }
}
