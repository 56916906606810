/**
 * @prettier
 */
import { Countries, Country } from 'src/constants/Country';

export function getCountryCallingCode(value: Country): string {
    if (value === undefined || value === null) return value as any;
    if (value === Countries.MX) return '+52';
    if (value === Countries.US) return '+1';
    if (value === Countries.SE) return '+46';
    if (value === Countries.UY) return '+598';
    if (value === Countries.AR) return '+54';
    if (value === Countries.BR) return '+55';
    if (value === Countries.PE) return '+51';
    if (value === Countries.CL) return '+56';
    if (value === Countries.PY) return '+595';

    throw Error(`Unsupported country=${value}`);
}
