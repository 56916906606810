/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuCategoryId, MenuId } from 'src/types/Id';

export async function removeMenuCategoryFromMenuApi(request: RemoveMenuCategoryFromMenuApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menuCategory/removeMenuCategoryFromMenuApi', request);
}

type RemoveMenuCategoryFromMenuApiRequest = {
    menuId: MenuId;
    menuCategoryId: MenuCategoryId;
};
