/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useContext, useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { PageContext } from 'src/components/Page';
import { translate } from 'src/i18n/translate';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';
import { classNames } from 'src/utils/react/classNames';

/**
 * Create a simple table, does not have any other feature like sorting or anything, just a styled table, if you want the styled MUI table you are looking for Table component
 * @param {boolean} hideHeaders - hide table headers
 * @param {Object[]} columns - array of columns
 * @param {string} columns[].id - id of the column, this will be used as object key for the row object later
 * @param {React.Node} columns[].content - content of the column, can be a string or React Element
 * @param {number} [columns[].size] - size of the content, if every column has the same number each column will be the same size (default behavior), if they have different values, the size will indicate how much the column will grow
 * @param {Object[]} rows - array of rows, the keys of the object must be the id of the column and the value of each key can be either string or React Element
 * @param {Object} [classes] - classes object where you can style the table
 * @param {string} [classes.table] - class for the table
 * @param {string} [classes.cell] - class for the cells
 * @returns {React.Node}
 */
export function SimpleTable({ columns, rows, hideHeaders, rowsPerPage, searchable, classes: classesProp, onRowClick }: Props): React.ReactElement {
    const classes = useStyles();
    const pageContext = useContext(PageContext);

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');

    const addTableToStackTrace = () => {
        pageContext.addElementToStackTrace(normalizeUiStackTrace(`table`));
    };

    const getTotalPages = () => Math.ceil(rows.length / (rowsPerPage ?? 1)) || 1;

    const previousPage = () => {
        if (page === 1) return;
        setPage(page - 1);
    };

    const nextPage = () => {
        if (page === getTotalPages()) return;
        setPage(page + 1);
    };

    const getRowsToShow = () => {
        if (!!search) {
            return rows.filter((row) => (row as any).onSearch?.(search));
        }
        if (!!rowsPerPage) {
            return rows.slice((page - 1) * rowsPerPage, page * rowsPerPage);
        }
        return rows;
    };

    return (
        <div className={classNames(classes.container, classesProp?.container)}>
            {searchable && (
                <Input
                    name={'search'}
                    type={'search'}
                    placeholder={translate('Search')}
                    value={search}
                    onChange={(value: string) => setSearch(value)}
                    classes={{ inputContainer: classes.inputContainer }}
                />
            )}
            <table className={classNames(classes.table, classesProp?.table)} onClickCapture={addTableToStackTrace}>
                {!hideHeaders && (
                    <thead className={classes.headerRow}>
                        <tr className={classes.row}>
                            {columns.map((column, idx) => {
                                const columnSize = column.size || 1;
                                const width = 100 * columnSize;
                                return (
                                    <th key={idx} className={classNames(classes.header, classesProp?.header)} style={{ width: `${width}%` }}>
                                        {column.content}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                )}
                <tbody>
                    {getRowsToShow().map((row, idx) => {
                        const isLastRow = idx === rows.length - 1;
                        return (
                            <tr onClick={() => onRowClick?.(row)} key={idx} className={classNames(classes.row, !isLastRow ? classes.borderedRow : undefined, (row as any)?.className)}>
                                {columns.map((column) => {
                                    const columnSize = column.size || 1;
                                    const width = 100 * columnSize;
                                    return (
                                        <td key={column.id} className={classNames(classes.cell, classesProp?.cell)} style={{ width: `${width}%` }}>
                                            {/* @ts-ignore */}
                                            {row[column.id]}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    {rows.length === 0 && (
                        <tr className={classes.row} style={{ justifyContent: 'center' }}>
                            <td className={classNames(classes.cell, classesProp?.cell)}>{translate('There is no data to show')}</td>
                        </tr>
                    )}
                </tbody>
                {!!rowsPerPage && (
                    <tfoot>
                        <tr className={classes.row}>
                            <td style={{ width: '100%' }}>
                                <div className={classes.paginationButtonsContainer}>
                                    <span>
                                        {page} / {getTotalPages()}
                                    </span>
                                    <Button icon onClick={previousPage} classes={{ button: classes.buttons }} disabled={page === 1 || !!search}>
                                        {'<'}
                                    </Button>
                                    <Button icon onClick={nextPage} classes={{ button: classes.buttons }} disabled={page === getTotalPages() || !!search}>
                                        {'>'}
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                )}
            </table>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    table: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
        border: '1px solid #D9D9D9',
        overflow: 'hidden',
    },
    headerRow: {
        backgroundColor: '#EEF2F5',
        display: 'flex',
        width: '100%',
    },
    header: {
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        color: '#0D3037',
        margin: 0,
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
    },
    row: {
        width: '100%',
        display: 'flex',
        padding: '12px 24px',
        boxSizing: 'border-box',
    },
    borderedRow: {
        borderBottom: '1px solid #D9D9D9',
    },
    cell: {
        fontFamily: theme.typography.regular,
        color: '#232933',
        display: 'flex',
    },
    paginationButtonsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 10,
        fontFamily: theme.typography.regular,
    },
    buttons: {
        fontSize: 25,
    },
    inputContainer: {
        maxWidth: 400,
    },
    '@keyframes colorized': {
        '0%': {
            backgroundColor: ' transparent',
        },
        '50%': {
            backgroundColor: ' #ffbcc1',
        },
        '100%': {
            backgroundColor: 'transparent',
        },
    },
}));

type Props = {
    hideHeaders?: boolean;
    columns: Array<{
        id: string;
        content: React.ReactNode;
        size?: number;
    }>;
    rows: Array<
        | {
              className?: string;
              onSearch?: (query: string) => boolean;
          }
        | { [key: string]: React.ReactNode }
    >;
    classes?: {
        table?: string;
        cell?: string;
        header?: string;
        container?: string;
    };
    searchable?: boolean;
    rowsPerPage?: number;
    onRowClick?: any;
};
