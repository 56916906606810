/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function removeRestaurantApi(request: RemoveRestaurantApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('restaurant/removeRestaurantApi', request);
}

export type RemoveRestaurantApiRequest = {
    restaurantId: RestaurantId;
};
