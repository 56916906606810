/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { PickupTimeType } from 'src/constants/PickupTimeType';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { DeliveryEstimateId, GooglePlaceId, RestaurantId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export async function createDriverRequestOrderApi(request: CreateDriverRequestOrderV2ApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('order/createDriverRequestOrderApi', request);
}

export type CreateDriverRequestOrderV2ApiRequest = {
    restaurantId: RestaurantId;
    firstName?: string;
    lastName?: string;
    phoneNumber: string;
    address: {
        location: GeoJsonPoint;
        street: string;
        number: number;
        neighborhood: string;
        postalCode?: number;
        other?: string;
        instructions?: string;
        googlePlaceId: GooglePlaceId;
        formattedAddress?: string;
        name?: string; // sent for debugging reasons
    };
    price: string;
    deliveryCost?: string;
    deliveryEstimateId?: DeliveryEstimateId;
    deliveryPaidByRestaurant?: boolean;
    deliveryPaidByPideDirecto?: boolean;
    posDriverRequest?: boolean;
    pickupTimeType?: PickupTimeType;
    pickupTime?: Date;
    appVersion?: string;
    paymentMethod: PaymentMethod;
    isBigOrder?: boolean;
    isManualOrderProtected?: boolean;
    externalCardPayment?: boolean;
    directoProtectionPaidByCustomer?: boolean;
};
