/**
 * @prettier
 */
import type { RolePermission } from 'src/constants/RolePermission';
import { useSelector } from 'src/utils/react/useSelector';

export function useUserHasRolePermission(): [(arg1: RolePermission) => boolean] {
    const userRolePermissions = useSelector((state) => state.authentication.userRolePermissions);
    const pinCodeUserRolePermissions = useSelector((state) => state.authentication.pinCodeUserSignedIn?.userRolePermissions);

    const currentUserRolePermissions = pinCodeUserRolePermissions || userRolePermissions;

    const useUserHasRolePermission = (rolePermission: RolePermission) => {
        if (!currentUserRolePermissions) return true;

        return currentUserRolePermissions.includes(rolePermission);
    };

    return [useUserHasRolePermission];
}
