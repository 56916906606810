/**
 * @prettier
 */
import { useSelector } from 'src/utils/react/useSelector';

export function useUserHasConfiguredRolePermissions(): boolean {
    const userRolePermissions = useSelector((state) => state.authentication.userRolePermissions);
    const pinCodeUserRolePermissions = useSelector((state) => state.authentication.pinCodeUserSignedIn?.userRolePermissions);

    return !!pinCodeUserRolePermissions?.length || !!userRolePermissions?.length;
}
