/**
 * @prettier
 */

import { usePosCartModifiersStore } from 'src/services/pos/posModifiersCart/posCartModifiersStore';

export function usePosCartModifiersQuantity(): number {
    const quantity = usePosCartModifiersStore((state) => state.quantity);

    return quantity;
}
