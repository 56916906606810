/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverId, RestaurantManualEarningId } from 'src/types/Id';

export async function getRestaurantManualEarningApi(request: GetRestaurantManualEarningApiRequest): ApiSauceResponse<RestaurantManualEarningVm> {
    return letseatadminApiMethod('restaurantManualEarning/getRestaurantManualEarningApi', request);
}

export type GetRestaurantManualEarningApiRequest = {
    restaurantManualEarningId: RestaurantManualEarningId;
};

export type RestaurantManualEarningVm = {
    restaurantManualEarningId: RestaurantManualEarningId;
    driverId: DriverId;
    title: string;
    body?: string;
    comment?: string;
    amount: string;
    receivedAt: Date;
    modifiedAt: Date;
};
