/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId, RestaurantZoneId } from 'src/types/Id';
import type { PosMenuVm } from 'src/types/PosMenuVm';

export async function getPosMenuApi(request: GetPosMenuApiRequest): ApiSauceResponse<PosMenuVm> {
    return letseatmanagerApiMethod('pos/getPosMenuApiV2', request);
}

export type GetPosMenuApiRequest = {
    restaurantId: RestaurantId;
    restaurantZoneId?: RestaurantZoneId;
    table?: string;
};
