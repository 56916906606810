/**
 * @prettier
 */
import * as React from 'react';
import { DeliveryOrderDriverMarker } from 'src/components/googlemaps/deliveryOrdersMap/DeliveryOrderDriverMarker';
import type { OrderVm } from 'src/types/OrderVm';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function DeliveryOrdersDriverMarkers({ stopUpdatingPositions }: Props): React.ReactElement {
    const currentOrders = useSelector((state) => state.app2.currentOrders);

    const deliveryOrders: Array<OrderVm> = currentOrders.filter((order) => isDeliveryOrder(order.orderType));
    const ordersWithDrivers = deliveryOrders.filter((order) => !!order.driverId);

    return (
        <>
            {ordersWithDrivers.map((order) => (
                <DeliveryOrderDriverMarker key={order.orderId} orderId={order.orderId} stopUpdatingPosition={stopUpdatingPositions} />
            ))}
        </>
    );
}

type Props = {
    stopUpdatingPositions?: boolean;
};
