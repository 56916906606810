/**
 * @prettier
 */
import * as React from 'react';

export function XIcon({ title, width, height }: Props): React.ReactElement {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            className={'icon icon-tabler icon-tabler-x'}
            width={width ?? '24'}
            height={height ?? '24'}
            viewBox='0 0 24 24'
            strokeWidth='2'
            stroke='currentColor'
            fill='none'
            strokeLinecap='round'
            strokeLinejoin='round'
        >
            {title && <title>{title}</title>}
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M18 6l-12 12' />
            <path d='M6 6l12 12' />
        </svg>
    );
}

type Props = {
    title?: string;
    width?: number;
    height?: number;
};
