/**
 * @prettier
 */
import type { TimeZone } from 'src/constants/TimeZone';
import { daysBetween } from 'src/utils/date/daysBetween';
import { lessThanZeroToUndefined } from 'src/utils/number/lessThanZeroToUndefined';

export function countdownDays(dueDate: string | Date | undefined, date: string | Date | undefined, timeZone: TimeZone): number | undefined {
    if (!dueDate || !date) {
        return;
    }
    return lessThanZeroToUndefined(daysBetween(dueDate, date, timeZone));
}
