/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import { ModifierTypes } from 'src/constants/ModifierType';
import { PosItemQuantitySpinner } from 'src/scenes/letseatmanager/pos/posAddMenuItem/PosItemQuantitySpinner';
import { usePosCartModifiers } from 'src/services/pos/posModifiersCart/usePosCartModifiers';
import { useSetMissingSectionName } from 'src/services/pos/posModifiersCart/useSetMissingSectionName';
import { useSetPosCartModifiers } from 'src/services/pos/posModifiersCart/useSetPosCartModifiers';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { DeprecatedModifierGroupVm, DeprecatedModifierVm, ModifierGroupVm, ModifierVm } from 'src/types/PosMenuVm';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export const PosModifier: React.ComponentType<Props> = memo<Props>(({ modifierGroup, modifier }: Props) => {
    const [quantity, setQuantity] = useState(0);
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const posCartModifiers = usePosCartModifiers();

    const setPosCartModifiers = useSetPosCartModifiers();
    const setMissingSectionName = useSetMissingSectionName();

    const posUseMenuItemColorEnabled = useSelector((state) => state.app.restaurant?.posUseMenuItemColorEnabled);

    const selected = posCartModifiers?.[modifierGroup?.name]?.some((_modifier) => _modifier.name === modifier.name);
    const classes = useStyles({
        color: posUseMenuItemColorEnabled && modifier.color ? modifier.color : undefined,
    });

    useEffect(() => {
        if (!selected) setQuantity(0);
    }, [selected]);

    const addModifier = useCallback(
        (quantity: number) => {
            const _modifierGroup = requireValue(modifierGroup);
            let updatedModifierGroup = [...(posCartModifiers?.[_modifierGroup.name] ?? [])];
            const modifiers = getTotalModifiers(modifierGroup);

            const updatedModifier = updatedModifierGroup.find((_modifier) => _modifier.name === modifier.name);

            if (modifierGroup?.requiredMax && modifiers + (quantity ?? 1) > modifierGroup?.requiredMax) {
                setMissingSectionName(modifierGroup.name);
                return;
            }

            if (!updatedModifier || modifier.modifierType === ModifierTypes.SINGLE) {
                updatedModifierGroup.push({
                    menuItemId: modifier.menuItemId!,
                    name: modifier.name,
                    price: modifier.price,
                    modifierType: modifier.modifierType,
                    quantity: 1,
                });
            } else {
                const newQuantity = updatedModifier.quantity + (quantity ?? 1);
                updatedModifierGroup = updatedModifierGroup.filter((_modifier) => _modifier.name !== modifier.name);
                updatedModifierGroup?.push({
                    ...updatedModifier,
                    quantity: newQuantity,
                });
            }

            const updatedForm = {
                ...posCartModifiers,
                [_modifierGroup.name]: updatedModifierGroup,
            };

            setPosCartModifiers(updatedForm);
        },
        [posCartModifiers, modifierGroup, modifier]
    );

    const removeModifier = useCallback(
        (quantity: number) => {
            const _modifierGroup = requireValue(modifierGroup);

            let updatedModifierGroup = [...(posCartModifiers?.[_modifierGroup.name] ?? [])];

            if (modifier?.modifierType === ModifierTypes.SINGLE || !quantity) {
                updatedModifierGroup = updatedModifierGroup.filter((_modifier) => _modifier.name !== modifier.name);
            } else if (modifier.modifierType === ModifierTypes.MULTIPLE) {
                const updatedModifier = updatedModifierGroup.find((_modifier) => _modifier.name === modifier.name);
                if (!updatedModifier) return;

                const newQuantity = updatedModifier.quantity - (quantity ?? 1);
                updatedModifierGroup = updatedModifierGroup.filter((_modifier) => _modifier.name !== modifier.name);

                if (newQuantity > 0) {
                    updatedModifierGroup?.push({
                        ...updatedModifier,
                        quantity: newQuantity,
                    });
                }
            }

            const updatedForm = {
                ...posCartModifiers,
                [_modifierGroup.name]: updatedModifierGroup,
            };

            setPosCartModifiers(updatedForm);
        },
        [posCartModifiers, modifierGroup, modifier]
    );

    const getTotalModifiers = useCallback(
        (modifierGroup: ModifierGroupVm | DeprecatedModifierGroupVm) => {
            if (!modifierGroup?.requiredMax) return 0;
            return (
                posCartModifiers[modifierGroup.name]?.reduce((totalItems, modifier) => {
                    totalItems += modifier.quantity;
                    return totalItems;
                }, 0) ?? 0
            );
        },
        [posCartModifiers]
    );

    const modifiersInGroup = getTotalModifiers(modifierGroup);

    const handleAddQuantity = () => {
        if (modifierGroup.requiredMax && modifiersInGroup === modifierGroup.requiredMax) return;
        setQuantity((currentQuantity) => currentQuantity + 1);
        addModifier(1);
    };

    const handleRemoveQuantity = () => {
        if (quantity - 1 < 0) return;
        setQuantity(quantity - 1);
        removeModifier(1);
    };

    const handleClickModifier = () => {
        if (modifier.modifierType === ModifierTypes.MULTIPLE) return;

        if (selected) removeModifier(1);
        else addModifier(1);
    };

    return (
        <div className={classNames(classes.modifier, selected ? classes.modifierSelected : '')} onClick={handleClickModifier}>
            <span className={classes.modifierName}>{modifier.name}</span>
            {BigNumber(modifier.price ?? 0).isGreaterThan(0) && <span className={classes.modifierPrice}>+{formatAsCurrencyNumber(modifier.price)}</span>}
            {modifier.modifierType === ModifierTypes.MULTIPLE && <PosItemQuantitySpinner onAdd={handleAddQuantity} onSubtract={handleRemoveQuantity} quantity={quantity} />}
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    modifier: {
        width: 170,
        borderRadius: 8,
        minHeight: 70,
        border: (props: any) => `${props.color ? '2px' : '1px'} solid ${props.color ? `${props.color}55` : theme.palette.border.primary}`,
        flexShrink: 0,
        margin: 10,
        marginLeft: 0,
        padding: 10,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    modifierSelected: {
        backgroundColor: 'transparent',
        border: `2px solid ${theme.palette.border.brand} !important`,
        '& span': {
            color: theme.palette.text.secondary,
        },
    },
    modifierName: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        color: theme.palette.text.secondary,
        marginBottom: 10,
    },
    modifierPrice: {
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginBottom: 10,
    },
    modifierMultiple: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 20,
    },
}));

type Props = {
    modifierGroup: ModifierGroupVm | DeprecatedModifierGroupVm;
    modifier: ModifierVm | DeprecatedModifierVm;
};
