/**
 * @prettier
 */
import { useEffect } from 'react';
import * as React from 'react';
import { app2 } from 'src/app2';
import { IncomingMultipleOrdersAlert } from 'src/components/alertDialog/order/IncomingMultipleOrdersAlert';
import { IncomingSingleOrderAlert } from 'src/components/alertDialog/order/IncomingSingleOrderAlert';
import { RolePermissions } from 'src/constants/RolePermission';
import { OrderVm } from 'src/types/OrderVm';
import { isNewOrder } from 'src/utils/order/isNewOrder';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function IncomingOrdersAlert(): React.ReactElement | null {
    const [userHasRolePermission] = useUserHasRolePermission();

    const currentOrders = useSelector((state) => state.app2.currentOrders);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const brandOpened = useSelector((state) => state.app.brandOpened);

    const openIncomingOrdersAlert = useAction(app2.actions.openIncomingOrdersAlert);
    const closeIncomingOrdersAlert = useAction(app2.actions.closeIncomingOrdersAlert);

    useEffect(() => {
        const newOrders = brandOpened
            ? currentOrders?.filter((order: OrderVm) => isNewOrder(order.orderStatus))
            : currentOrders?.filter((order: OrderVm) => isNewOrder(order.orderStatus) && order.restaurant?.restaurantId === restaurantId);

        if (newOrders?.length) {
            openIncomingOrdersAlert({ newOrders });
        } else closeIncomingOrdersAlert();
    }, [currentOrders, restaurantId, brandOpened]);

    if (!userHasRolePermission(RolePermissions.VIEW_INCOMING_ORDERS_ALERT)) return null;

    return (
        <>
            <IncomingMultipleOrdersAlert />
            <IncomingSingleOrderAlert />
        </>
    );
}
