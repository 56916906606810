/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState } from 'react';
import { changeMenuCategoryApi } from 'src/api/letseatmanager/menuCategory/changeMenuCategoryApi';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { MenuItemsInMenuCategory } from 'src/scenes/letseatmanager/menu/menuCategory/MenuItemsInMenuCategory';
import { ModifierGroupsInMenuCategory } from 'src/scenes/letseatmanager/menu/menuCategory/ModifierGroupsInMenuCategory';
import { SelectProductsDialog } from 'src/scenes/letseatmanager/menu/menuCategory/SelectProductsDialog';
import { SelectModifierGroupsDialog } from 'src/scenes/letseatmanager/menu/menuItem/SelectModifierGroupsDialog';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import { useLoadMenus } from 'src/services/menu/useLoadMenus';
import { useLoadMenuCategories } from 'src/services/menuCategory/useLoadMenuCategories';
import type { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { classNames } from 'src/utils/react/classNames';

export function MenuCategory({ menuCategory }: Props): React.ReactElement {
    const classes = useStyles();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();

    const [showModifierGroups, setShowModifierGroups] = useState(false);
    const [selectProductsDialogState, setSelectProductsDialogState] = useState({ open: false });
    const [selectModifierGroupsDialogState, setSelectModifierGroupsDialogState] = useState({ open: false });

    const refreshMenuCategories = useLoadMenuCategories();
    const refreshMenus = useLoadMenus();

    const menuCategoryHasProducts = menuCategory.menuItemIds.length > 0;
    const menuCategoryHasModifierGroups = menuCategory.modifierGroupIds && menuCategory.modifierGroupIds?.length > 0;

    const updateMenuItemsInMenuCategory = async (menuItemIds: any) => {
        const response = await changeMenuCategoryApi({
            menuCategoryId: menuCategory.menuCategoryId,
            menuItemIds: menuItemIds,
            modifierGroupIds: menuCategory.modifierGroupIds,
            name: menuCategory.name,
            hidden: menuCategory.hidden,
            promotion: menuCategory.promotion,
            imageUrl: menuCategory.imageUrl,
            printerNames: menuCategory.printerNames,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        refreshMenuCategory();
    };

    const updateModifierGroupsInMenuCategory = async (modifierGroupIds: any) => {
        const response = await changeMenuCategoryApi({
            menuCategoryId: menuCategory.menuCategoryId,
            menuItemIds: menuCategory.menuItemIds,
            modifierGroupIds,
            name: menuCategory.name,
            hidden: menuCategory.hidden,
            promotion: menuCategory.promotion,
            imageUrl: menuCategory.imageUrl,
            printerNames: menuCategory.printerNames,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        refreshMenuCategory();
    };

    const openSelectProductDialog = () => {
        setSelectProductsDialogState({ open: true });
    };

    const closeOpenSelectProductDialog = () => {
        setSelectProductsDialogState({ open: false });
    };

    const openSelectModifierGroupsDialog = () => {
        setSelectModifierGroupsDialogState({ open: true });
    };

    const closeSelectModifierGroupsDialog = () => {
        setSelectModifierGroupsDialogState({ open: false });
    };

    const refreshMenuCategory = () => {
        refreshMenuCategories();
        refreshMenus();
    };

    return (
        <div className={classNames(classes.container)}>
            <SelectProductsDialog
                open={selectProductsDialogState.open}
                onClose={closeOpenSelectProductDialog}
                defaultSelectedProducts={menuCategory.menuItemIds}
                onSuccess={updateMenuItemsInMenuCategory}
            />
            <SelectModifierGroupsDialog
                open={selectModifierGroupsDialogState.open}
                onClose={closeSelectModifierGroupsDialog}
                defaultSelectedModifierGroups={menuCategory.modifierGroupIds}
                onSuccess={updateModifierGroupsInMenuCategory}
            />
            <div className={classes.switch}>
                <Button classes={{ button: classes.switchButton }} text={showModifierGroups} onClick={() => setShowModifierGroups(false)}>
                    {translate('Products')}
                </Button>
                <Button classes={{ button: classes.switchButton }} text={!showModifierGroups} onClick={() => setShowModifierGroups(true)}>
                    {translate('Modifier groups')}
                </Button>
            </div>
            <div className={classes.header}>
                <h3 className={classes.title}>{menuCategory.name}</h3>
                {menuCategoryHasProducts && !showModifierGroups && (
                    <Button onClick={openSelectProductDialog} disabled={isMenuEditionDisabled}>
                        {translate('Add Product +')}
                    </Button>
                )}
                {menuCategoryHasModifierGroups && showModifierGroups && (
                    <Button onClick={openSelectModifierGroupsDialog} disabled={isMenuEditionDisabled}>
                        {translate('Add modifier groups +')}
                    </Button>
                )}
            </div>
            <div className={classes.menuItemsContainer}>
                {!showModifierGroups && (
                    <MenuItemsInMenuCategory menuCategory={menuCategory} menuItemIds={menuCategory.menuItemIds ?? []} onAddProduct={openSelectProductDialog} onSuccess={refreshMenuCategory} />
                )}
                {showModifierGroups && (
                    <ModifierGroupsInMenuCategory
                        menuCategory={menuCategory}
                        modifierGroupIds={menuCategory.modifierGroupIds ?? []}
                        onAddModifierGroup={openSelectModifierGroupsDialog}
                        onSuccess={refreshMenuCategory}
                    />
                )}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 17,
        margin: 0,
    },
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    menuItemsContainer: {
        width: '100%',
    },
    switch: {
        padding: 10,
        borderRadius: 15,
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        backgroundColor: theme.palette.surface.secondary,
        width: 'fit-content',
        margin: '0 auto',
        marginBottom: 20,
    },
    switchButton: {
        width: 200,
        [theme.breakpoints.down('sm')]: {
            width: 150,
        },
    },
}));

type Props = {
    menuCategory: MenuCategoryVm;
};
