/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function GlobalInvoiceReceiverInfo({ from, to, paymentMethod }: Props): React.ReactElement {
    const classes = useStyles();

    const period = `${moment(from).format('l')}-${moment(to).format('l')}`;

    return (
        <div className={classes.container}>
            <h3 className={classes.subTitle}>{translate('Invoiced to')}</h3>
            <div className={classes.separatedText}>
                <span>{translate('Client')}</span>
                <span className={classes.data}>{translate('General public')}</span>
            </div>
            <div className={classes.separatedText}>
                <span>{translate('RFC')}</span>
                <span className={classes.data}>{'XAXX010101000'}</span>
            </div>
            <div className={classes.separatedText}>
                <span>{translate('Regime fiscal')}</span>
                <span className={classes.data}>{'616 - Sin obligaciones fiscales'}</span>
            </div>
            <div className={classes.separatedText}>
                <span>{translate('Period')}</span>
                <span className={classes.data}>{period}</span>
            </div>
            <div className={classes.separatedText}>
                <span>{translate('Payment Form')}</span>
                <span className={classes.data}>{`PUE-${translate(paymentMethod)}`}</span>
            </div>
            <div className={classes.separatedText}>
                <span>{translate('Invoice usage')}</span>
                <span className={classes.data}>{'S01'}</span>
            </div>
            <div className={classes.separatedText}>
                <span>{translate('Description')}</span>
                <span className={classes.data}>{'Global sales'}</span>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gap: 8,
        fontSize: 14,
        fontFamily: theme.typography.regular,
        color: '#232933',
        paddingBottom: 12,
        borderBottom: '1px solid #D9D9D9',
        marginBottom: 10,
    },
    separatedText: {
        '& span': {
            display: 'block',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    data: {
        fontFamily: theme.typography.semiBold,
    },
    subTitle: {
        color: '#198073',
        margin: 0,
        marginTop: 10,
    },
}));

type Props = {
    from: Date;
    to: Date;
    paymentMethod: string;
};
