/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerVm } from 'src/scenes/letseatadmin/customer/CustomerDetails';

export async function findCustomersApi(): ApiSauceResponse<Array<CustomerVm>> {
    return letseatadminApiMethod('customer/findCustomersApi');
}
