/**
 * @prettier
 */
import type { PaymentLinkVm } from 'src/api/letseatmanager/paymentLink/createPaymentLinkApi';
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderId, RestaurantId } from 'src/types/Id';

export async function findPaymentLinkByOrderIdApi(request: PaymentLinkRequest): ApiSauceResponse<PaymentLinkVm | any> {
    return letseatmanagerApiMethod('paymentLink/findPaymentLinkByOrderIdApi', request);
}

type PaymentLinkRequest = {
    restaurantId: RestaurantId;
    orderId: OrderId;
};
