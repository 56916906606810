/**
 * @prettier
 */
import { createStore } from '@pidedirecto/ui/hooks';

export const [usePosUserLockScreenStore, usePosUserLockScreenActions] = createStore<State, Actions>({
    initialState: {
        isWrongPassword: false,
        tryingClockIn: false,
        tryingClockOut: false,
        hasClockedIn: false,
    },
    actions: {
        setPosUserLockScreenIsWrongPassword: (state, isWrongPassword: boolean) => {
            state.isWrongPassword = isWrongPassword;
        },

        setTryingClockIn: (state, tryingClockIn: boolean) => {
            state.tryingClockIn = tryingClockIn;
        },

        setTryingClockOut: (state, tryingClockOut: boolean) => {
            state.tryingClockOut = tryingClockOut;
        },

        setHasClockedIn: (state, hasClockedIn: boolean) => {
            state.hasClockedIn = hasClockedIn;
        },
    },
});

type State = {
    isWrongPassword: boolean;
    tryingClockIn: boolean;
    tryingClockOut: boolean;
    hasClockedIn: boolean;
};

export type Actions = {
    setPosUserLockScreenIsWrongPassword: (isWrongPassword: boolean) => void;
    setTryingClockIn: (tryingClockIn: boolean) => void;
    setTryingClockOut: (tryingClockOut: boolean) => void;
    setHasClockedIn: (hasClockedIn: boolean) => void;
};
