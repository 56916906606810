/**
 * @prettier
 */
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getRestaurantSalesApi, RestaurantSalesVm } from 'src/api/letseatadmin/restaurantSales/getRestaurantSalesApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { RestaurantSalesChart } from 'src/scenes/letseatadmin/restaurantSales/RestaurantSalesChart';
import type { RestaurantId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { requireValue } from 'src/utils/require/requireValue';

export function RestaurantSalesDialog({ open, restaurantId, onClose }: Props): React.ReactElement {
    const [loading, setLoading] = useState(false);
    const [restaurantName, setRestaurantName] = useState('');
    const [data, setData] = useState<RestaurantSalesVm>({ restaurantName: '', weeksData: [] });

    useEffect(() => {
        if (open) load();
    }, [open]);

    const handleClose = () => {
        onClose();
    };

    const handleExited = () => {
        setLoading(false);
    };

    const load = async () => {
        setLoading(true);
        const response = await getRestaurantSalesApi({ restaurantId: requireValue(restaurantId) });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            onClose();
            return;
        }
        setRestaurantName(response.data.restaurantName);
        setData(response.data);
        setLoading(false);
    };

    if (loading) {
        return <UpdatingContentProgress loading={loading} />;
    } else {
        return (
            <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} onExited={handleExited}>
                <DialogTitle>{translate(restaurantName).toUpperCase()}</DialogTitle>
                <DialogContent>
                    <RestaurantSalesChart data={data} />
                </DialogContent>
            </Dialog>
        );
    }
}

type Props = {
    open: boolean;
    restaurantId?: RestaurantId;
    onClose: any;
};
