/**
 * @prettier
 */
import * as React from 'react';
import { useState } from 'react';
import { MenuHasChangeAlert } from 'src/scenes/letseatmanager/menu/MenuHasChangeAlert';
import { SyncMenuErrorAlert } from 'src/scenes/letseatmanager/menu/SyncMenuErrorAlert';

export function MenuAlerts(): React.ReactElement {
    const [syncMenuErrorState, setSyncMenuErrorState] = useState(false);
    const [syncMenuErrors, setSyncMenuErrors] = useState([]);

    const onError = (messages: any) => {
        if (messages.length === 0) {
            setSyncMenuErrorState(false);
            return;
        }
        setSyncMenuErrorState(true);
        setSyncMenuErrors(messages);
    };

    const onCloseSyncMenuErrors = () => {
        setSyncMenuErrors([]);
        setSyncMenuErrorState(false);
    };

    return (
        <>
            <MenuHasChangeAlert onError={onError} />
            <SyncMenuErrorAlert open={syncMenuErrorState} syncMenuErrors={syncMenuErrors} onClose={onCloseSyncMenuErrors} />
        </>
    );
}
