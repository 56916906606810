/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { Day } from 'src/constants/Day';
import type { ScheduleTaskInterval } from 'src/constants/ScheduleTaskInterval';
import type { RestaurantId, ScheduledTaskId } from 'src/types/Id';

export async function findScheduledNotificationsToCustomerApi(request: FindScheduledNotificationsToCustomerApiRequest): ApiSauceResponse<Array<ScheduledNotificationVm>> {
    return letseatmanagerApiMethod('marketing/findScheduledNotificationsToCustomerApi', request);
}

type FindScheduledNotificationsToCustomerApiRequest = {
    restaurantIds: Array<RestaurantId>;
};

export type ScheduledNotificationVm = {
    scheduledTaskId: ScheduledTaskId;
    restaurantIds: Array<RestaurantId>;
    name: string;
    interval: ScheduleTaskInterval;
    day?: Day;
    dayNumber?: number;
    hour: string; // HH:mm,
    lastScheduledRunAt?: Date;
};
