/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Loader } from '@pidedirecto/ui';
import { useLoadApi } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { findRestaurantVideosApi } from 'src/api/letseatmanager/restaurantVideo/findRestaurantVideosApi';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { translate } from 'src/i18n/translate';
import { VideosElement } from 'src/scenes/letseatmanager/howDoesItWork/VideosElement';
import { useSelector } from 'src/utils/react/useSelector';

export function HowDoesItWorkPage(): React.ReactElement {
    const classes = useStyles();

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, { restaurantVideos }] = useLoadApi(
        findRestaurantVideosApi,
        { restaurantId: restaurantId },
        { dependencies: [restaurantId], requiredValues: [restaurantId], initialValue: { restaurantVideos: [] } }
    );

    return (
        <SubscribedPage title={translate('How Does It Work?')} className={classes.container}>
            {loading && (
                <div className={classes.contentContainer}>
                    <Loader size={40} loading={true} />
                </div>
            )}
            {!loading && restaurantVideos.length === 0 && (
                <div className={classes.contentContainer}>
                    <Typography variant='h3' align='center'>
                        {translate('No Videos')}
                    </Typography>
                </div>
            )}
            {!loading && (
                <div className={classes.videosContainer}>
                    {restaurantVideos.map((vid, idx) => {
                        return <VideosElement video={vid} />;
                    })}
                </div>
            )}
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '70%',
        minHeight: '90%',
        backgroundColor: 'white',
        borderRadius: 15,
        border: `1px solid ${theme.palette.secondary.dark}`,
        margin: '0 auto',
        marginTop: 20,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '20px 40px',
        marginBottom: 20,
    },
    contentContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    videosContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        fontSize: '2em',
    },
}));
