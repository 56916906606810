/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId, SupplyId } from 'src/types/Id';
import type { SupplyVm } from 'src/types/SupplyVm';

export async function findCompoundSuppliesToProduceApi(request: GetSupplyApiRequest): ApiSauceResponse<FindCompoundSuppliesToProduceApiResponse> {
    return letseatmanagerApiMethod('supply/findCompoundSuppliesToProduceApi', request);
}

export type GetSupplyApiRequest = {
    restaurantId: RestaurantId;
};

export type FindCompoundSuppliesToProduceApiResponse = {
    compoundSupplies: Array<SupplyVm>;
    stockIngredients: Array<{
        supplyId: SupplyId;
        supplyName: string;
        quantity: string;
        price: string;
        sellUnit: string;
    }>;
};
