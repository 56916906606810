/**
 * @prettier
 */
import * as React from 'react';
import { memo } from 'react';
import TimeLineBarChart, { Color, PeriodScale, pushDataPercentageValue, XAxisScale, xAxisTooltipFormat } from 'src/scenes/letseatadmin/statistics/TimeLineBarChart';
import type { StatisticsVm } from 'src/scenes/letseatadmin/StatisticsPage';
import { formatAsNumber } from 'src/utils/number/formatAsNumber';

type Props = {
    statistics?: StatisticsVm;
    period: PeriodScale;
    xAxis: XAxisScale;
};

export const ActivePercentageChart: React.ComponentType<Props> = memo<Props>(function ActiveInactiveRatioChart({ statistics, period, xAxis }: Props): React.ReactElement | null {
    if (!statistics) {
        return null;
    }

    return (
        <TimeLineBarChart
            data={statisticsToData(statistics, period, xAxis)}
            period={period}
            colors={[Color.GREEN, Color.GREY_LIGHT]}
            yAxisLegend={''}
            yAxisFormat={(value) => formatAsNumber(`${value}`) + '%'}
            yAxisTooltipFormat={(value) => formatAsNumber(`${value}`) + '%'}
            xAxisTooltipFormat={xAxisTooltipFormat(xAxis)}
        />
    );
});

function statisticsToData(statistics: StatisticsVm, period: PeriodScale, xAxis: XAxisScale) {
    return statistics.reduce(
        (chartData, statistic) => {
            let i = 0;
            pushDataPercentageValue(chartData[i++].data, statistic.date, statistic.customer.active, statistic.customer.paying, period, xAxis);
            return chartData;
        },
        [{ id: '% (Active/Paying)', data: [] }]
    );
}
