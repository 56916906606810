/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { TableShape } from 'src/constants/TableShape';
import type { TableSize } from 'src/constants/TableSize';
import type { RestaurantZoneId } from 'src/types/Id';
import type { RestaurantZoneVm } from 'src/types/RestaurantZoneVm';

export async function changeRestaurantZoneApi(request: ChangeRestaurantZoneApiRequest): ApiSauceResponse<RestaurantZoneVm> {
    return letseatmanagerApiMethod('pos/changeRestaurantZoneApi', request);
}

type ChangeRestaurantZoneApiRequest = {
    restaurantZoneId?: RestaurantZoneId;
    name?: string;
    tables?: Array<{
        tableNumber: string;
        tableSize: TableSize;
        tableShape: TableShape;
        top?: number;
        left?: number;
        rotation?: number;
    }>;
};
