/**
 * @prettier
 */
export const ProductLayouts = {
    LARGE: 'LARGE',
    MEDIUM: 'MEDIUM',
    SMALL: 'SMALL',
} as const;

export type ProductLayout = typeof ProductLayouts[keyof typeof ProductLayouts];
