/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { CustomAlertDialogVm } from 'src/types/CustomAlertDialogVm';

export async function findCustomAlertDialogsApi(): ApiSauceResponse<Array<CustomAlertDialogVm>> {
    return letseatadminApiMethod('customAlertDialogs/findCustomAlertDialogsApi');
}
