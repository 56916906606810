/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId, RestaurantVideoId } from 'src/types/Id';

export async function findRestaurantVideosApi(request: FindRestaurantVideosApiRequest): ApiSauceResponse<RestaurantVideosVm> {
    return letseatmanagerApiMethod('restaurantVideo/findRestaurantVideosApi', request);
}

export type RestaurantVideosVm = {
    restaurantVideos: Array<RestaurantVideoVm>;
};

export type RestaurantVideoVm = {
    restaurantVideoId: RestaurantVideoId;
    name: string;
    videoUrl: string;
    createdAt: Date;
    modifiedAt: Date;
};

export type FindRestaurantVideosApiRequest = {
    restaurantId: RestaurantId;
};
