/**
 * @prettier
 */
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import { distanceInKm } from 'src/utils/geojson/distanceInKm';

export function isWithinDistance(location1: GeoJsonPoint | null | undefined, location2: GeoJsonPoint | null | undefined, distance: number): boolean {
    if (!location1 || !location2) return false;
    const distanceBetweenLocations = distanceInKm(location1, location2);
    return (distanceBetweenLocations as any) <= distance;
}
