/**
 * @prettier
 */
import { CartItemVm } from 'src/types/CartItemVm';
import type { CustomerOrderDetailsVm } from 'src/types/CustomerOrderDetailsVm';
import type { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import { PosTip } from 'src/types/PosTip';
import { mapCartItemToOrderItem } from 'src/utils/pos/mapCartItemToOrderItem';

export function mapCustomerToOrderCustomer(customerState: PosCustomerOrderDetails): CustomerOrderDetailsVm {
    const now = new Date();

    return {
        customerNumber: customerState.customerNumber,
        tips: customerState.tips?.map((tip: PosTip) => ({
            tipType: tip.tipType,
            tipPercentage: tip.tipPercentage,
            paymentMethod: tip.paymentMethod,
            customPaymentMethod: tip.customPaymentMethod,
            tipAmount: tip.tipAmount ?? '0',
            paymentId: tip.paymentId,
            restaurantCurrencyAmount: tip.restaurantCurrencyAmount,
            restaurantCurrency: tip.restaurantCurrency,
        })),
        posDiscount: customerState.payment?.posDiscount,
        posDiscountType: customerState.discount?.discountType,
        discountPercentage: customerState.discount?.discountPercentage,
        posDiscountNotes: customerState.discount?.notes,
        orderItems: customerState.orderItems.map((item) => ({ ...mapCartItemToOrderItem(item), addedAt: item?.addedAt ?? now })),
        cancelledItems: customerState.cancelledItems?.map((item: CartItemVm) => ({ ...mapCartItemToOrderItem(item), removedAt: item?.removedAt ?? now })),
    };
}
