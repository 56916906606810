/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export async function findDriverOrdersApi(request: FindDriverOrdersApiRequest): ApiSauceResponse<FindDriverOrdersApiResponse> {
    return letseatadminApiMethod('driver/findDriverOrdersApi', request);
}

type FindDriverOrdersApiRequest = {
    driverId: DriverId;
    from: Date;
    to: Date;
    page: number;
    pageLimit: number;
};

export type FindDriverOrdersApiResponse = {
    orders: Array<OrderVm>;
};
