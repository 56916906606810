/**
 * @prettier
 */
import { makeStyles, useTheme } from '@material-ui/core';
import { useNotification } from '@pidedirecto/ui/hooks';
import { useState } from 'react';
import * as React from 'react';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { CashRegisterIsClosedDialog } from 'src/components/dialog/cashRegister/CashRegisterIsClosedDialog';
import { InformationAlertDialog } from 'src/components/dialog/InformationAlertDialog';
import { AddCustomerNameDialog } from 'src/components/dialog/pos/AddCustomerNameDialog';
import { OrderType, OrderTypes } from 'src/constants/OrderType';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { BagIcon } from 'src/icons/BagIcon';
import { DriverIcon } from 'src/icons/DriverIcon';
import { MenuIcon } from 'src/icons/MenuIcon';
import { posReducer } from 'src/reducers/posReducer';
import { PosPendingOrders } from 'src/scenes/letseatmanager/pos/posOrderTypeSelect/PosPendingOrders';
import { PosRouter } from 'src/scenes/letseatmanager/pos/PosRouter';
import { useHasOpenedCashRegister } from 'src/services/cashRegister/useHasOpenedCashRegister';
import { useManageOpenCashRegister } from 'src/services/cashRegister/useManageOpenCashRegister';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function PosOrderTypeSelect(): React.ReactElement {
    const theme = useTheme();
    const classes = useStyles();

    const hasOpenedCashRegister = useHasOpenedCashRegister();
    const notification = useNotification();
    const [userHasRolePermission] = useUserHasRolePermission();
    const { manageOpenCashRegister } = useManageOpenCashRegister();

    const [addCustomerNameDialogState, setAddCustomerNameDialogState] = useState<{ open: boolean; onSuccess: any }>({ open: false, onSuccess: undefined });

    const [openInformationAlertDialog, setOpenInformationAlertDialog] = useState(false);
    const [openCashRegisterClosedDialog, setOpenCashRegisterClosedDialog] = useState(false);

    const online = useSelector((state) => state.app2.online);
    const posShowTablesEnabled = useSelector((state) => state.pos.context?.posShowTablesEnabled);
    const requestCustomerNameOnCreateOrderEnabled = useSelector((state) => state.pos.context?.requestCustomerNameOnCreateOrderEnabled);
    const restaurantLocation = useSelector((state) => state.app.restaurant.location);
    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);
    const posCustomerNameRequiredOnTakeAwayOrdersEnabled = useSelector((state) => state.pos.context?.posCustomerNameRequiredOnTakeAwayOrdersEnabled);
    const kitchensUser = useSelector((state) => state.authentication.kitchensUser);
    const restaurant = useSelector((state) => state.app.restaurant);
    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);
    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const posPendingOrdersEnabled = useSelector((state) => state.pos.context?.posPendingOrdersEnabled);

    const selectPosOrderType = useAction(posReducer.actions.selectPosOrderType);
    const showPosTableSelect = useAction(posReducer.actions.showPosTableSelect);
    const setOpenedCashRegister = useAction(posReducer.actions.setOpenedCashRegister);
    const setCashRegisterOpen = useAction(posReducer.actions.setCashRegisterOpen);
    const setOpenedCashRegisterPosBusinessDay = useAction(posReducer.actions.setOpenedCashRegisterPosBusinessDay);
    const openCreateManualOrderDeliveryDialog = useAction(app2.actions.openCreateManualOrderDeliveryDialog);
    const setSelectOpenedCashRegisterDialogState = useAction(posReducer.actions.setSelectOpenedCashRegisterDialogState);
    const openedCashRegistersPosBusinessDay = useSelector((state) => state.pos.openedCashRegistersPosBusinessDay);

    const showDeliveryOrderOption = !kitchensUser && restaurant?.manualOrdersEnabled;
    const needOpenPersonalCashRegister = posEnabled && !hasOpenedCashRegister && userHasRolePermission(RolePermissions.OPEN_PERSONAL_CASH_REGISTER);
    const needOpenCashRegister = posEnabled && !hasOpenedCashRegister && !posMultipleCashRegistersEnabled && !openedPosBusinessDay;
    const needSelectCashRegister = !hasOpenedCashRegister && !needOpenPersonalCashRegister && !needOpenCashRegister && pinCodeUserSignedIn && posMultipleCashRegistersEnabled;

    const openSelectCashRegisterDialog = async (orderType: string) => {
        if (openedCashRegistersPosBusinessDay?.length > 1) {
            setSelectOpenedCashRegisterDialogState({ open: true });
            return;
        }

        await autoSelectCashRegister(orderType);
    };

    const autoSelectCashRegister = async (orderType: string) => {
        if (!openedCashRegistersPosBusinessDay?.length) return notification({ message: translate('In order to create a new order, you must first open the cash register') });

        setOpenedCashRegister(openedCashRegistersPosBusinessDay?.[0]?.cashRegister);
        setCashRegisterOpen(true);
        setOpenedCashRegisterPosBusinessDay(openedCashRegistersPosBusinessDay?.[0]);
        selectPosOrderType(orderType);
    };

    const handleEatHere = async () => {
        if (!canCreateOrder(OrderTypes.TABLE_ORDER)) {
            notification({ message: translate('Not authorized to charge orders') });
            return;
        }

        if (needOpenCashRegister || needOpenPersonalCashRegister) {
            setOpenCashRegisterClosedDialog(true);
            return;
        }

        if (needSelectCashRegister) {
            await openSelectCashRegisterDialog(OrderTypes.TABLE_ORDER);
            return;
        }

        if (requestCustomerNameOnCreateOrderEnabled) {
            setAddCustomerNameDialogState({
                open: true,
                onSuccess: () => {
                    if (!posShowTablesEnabled) return selectPosOrderType(OrderTypes.TABLE_ORDER);
                    showPosTableSelect();
                },
            });
            return;
        }

        if (!posShowTablesEnabled) return selectPosOrderType(OrderTypes.TABLE_ORDER);
        showPosTableSelect();
    };

    const handleTakeAway = async () => {
        if (!canCreateOrder(OrderTypes.TAKE_AWAY_ORDER)) {
            notification({ message: translate('Not authorized to charge orders') });
            return;
        }
        if (needOpenCashRegister || needOpenPersonalCashRegister) {
            setOpenCashRegisterClosedDialog(true);
            return;
        }

        if (needSelectCashRegister) {
            await openSelectCashRegisterDialog(OrderTypes.TAKE_AWAY_ORDER);
            return;
        }

        if (posCustomerNameRequiredOnTakeAwayOrdersEnabled || requestCustomerNameOnCreateOrderEnabled) {
            setAddCustomerNameDialogState({ open: true, onSuccess: () => selectPosOrderType(OrderTypes.TAKE_AWAY_ORDER) });
            return;
        }
        selectPosOrderType(OrderTypes.TAKE_AWAY_ORDER);
    };

    const handleDeliveryPosOrderType = async () => {
        if (!canCreateOrder(OrderTypes.DELIVERY_ORDER)) {
            notification({ message: translate('Not authorized to charge orders') });
            return;
        }

        if (needOpenCashRegister || needOpenPersonalCashRegister) {
            setOpenCashRegisterClosedDialog(true);
            return;
        }

        if (needSelectCashRegister) {
            await openSelectCashRegisterDialog(OrderTypes.DELIVERY_ORDER);
            return;
        }

        if (!restaurantLocation) {
            setOpenInformationAlertDialog(true);
            return;
        }
        selectPosOrderType(OrderTypes.DELIVERY_ORDER);
        openCreateManualOrderDeliveryDialog({ isForPos: true });
    };

    const canCreateOrder = (orderType: OrderType) => {
        if (userHasRolePermission(RolePermissions.CHARGE_ORDERS)) return true;

        return !isDeliveryOrder(orderType) && posPendingOrdersEnabled;
    };

    const handleCloseCashRegisterIsClosedDialog = () => {
        setOpenCashRegisterClosedDialog(false);

        manageOpenCashRegister({ forceOpenCashRegister: true });
    };

    return (
        <div className={classes.container}>
            <CashRegisterIsClosedDialog open={openCashRegisterClosedDialog} handleClose={handleCloseCashRegisterIsClosedDialog} />
            <InformationAlertDialog
                openInformationAlert={openInformationAlertDialog}
                handleClose={() => setOpenInformationAlertDialog(false)}
                title={translate('Location not found')}
                dialogContent={translate('Restaurant location not found, please verify that you have set your address correctly')}
            />
            <AddCustomerNameDialog
                open={addCustomerNameDialogState.open}
                handleClose={() => setAddCustomerNameDialogState({ open: false, onSuccess: undefined })}
                onSuccess={addCustomerNameDialogState.onSuccess}
            />
            <PosRouter />
            <h1 className={classes.title}>{translate('Choose a order type')}</h1>
            <div className={classes.buttonsContainer}>
                <Button classes={{ button: classes.buttonContainer }} secondary onClick={handleTakeAway}>
                    <div className={classes.iconContainer}>
                        <BagIcon title={'pos take away order icon'} height={40} width={40} color={theme.palette.icons.brand} />
                    </div>
                    <div className={classes.buttonText}>{translate('Take Away')}</div>
                </Button>
                <Button secondary classes={{ button: classNames(classes.buttonContainer, classes.eatHereButton) }} onClick={handleEatHere}>
                    <div className={classes.iconContainer}>
                        <MenuIcon title={'pos eat here order icon'} height={35} width={35} color={theme.palette.icons.brand} />
                    </div>
                    <div className={classes.buttonText}>{translate('Tables')}</div>
                </Button>
                {showDeliveryOrderOption && (
                    <Button secondary classes={{ button: classes.buttonContainer }} onClick={handleDeliveryPosOrderType} disabled={!online}>
                        <div className={classes.iconContainer}>
                            <DriverIcon title={'pos delivery order icon'} height={42} width={42} color={theme.palette.icons.brand} />
                        </div>
                        <div className={classes.buttonText}>{translate('Delivery')}</div>
                    </Button>
                )}
            </div>
            <PosPendingOrders />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: 0,
        [theme.breakpoints.down('sm')]: {
            height: '90vh',
            paddingTop: 0,
        },
    },
    eatHereButton: {
        [theme.breakpoints.down('sm')]: {
            order: -1,
        },
    },
    title: {
        color: '#2C374A',
        fontFamily: theme.typography.regular,
        fontSize: 20,
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
            fontSize: 30,
        },
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        padding: 24,
        backgroundColor: theme.palette.surface.brand,
        color: theme.palette.text.primary,
    },
    buttonContainer: {
        fontSize: 14,
        borderRadius: 12,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 5,
        backgroundColor: theme.palette.surface.primary,
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.surface.secondary}`,
        '&:hover': {
            border: `1px solid ${theme.palette.surface.brandContrast}`,
            backgroundColor: theme.palette.surface.brand,
        },
        [theme.breakpoints.up('sm')]: {
            width: 250,
            height: 250,
            margin: 12,
        },
    },
    buttonsContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    buttonText: {
        fontSize: 20,
        fontFamily: theme.typography.regular,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        marginLeft: 5,
    },
    backButtonContainer: {
        position: 'absolute',
        left: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    titleContainer: {
        width: '100%',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
}));
