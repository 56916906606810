/**
 * @prettier
 */
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CancelDeliveryDialog } from 'src/components/dialog/delivery/CancelDeliveryDialog';
import { CancelDeliveryManagerDialog } from 'src/components/dialog/delivery/CancelDeliveryManagerDialog';
import { CompleteDeliveryManagerDialog } from 'src/components/dialog/delivery/CompleteDeliveryManagerDialog';
import { RestartDeliveryManagerDialog } from 'src/components/dialog/delivery/RestartDeliveryManagerDialog';
import ChangePickupStationsDialog from 'src/components/dialog/restaurant/ChangePickupStationsDialog';
import { RoutePaths } from 'src/constants/RoutePath';
import { AdminLogEventsPage } from 'src/scenes/letseatadmin/AdminLogEventsPage';
import { BrandPage } from 'src/scenes/letseatadmin/BrandPage';
import { BrandsPage } from 'src/scenes/letseatadmin/BrandsPage';
import { AdminCurrentOrders } from 'src/scenes/letseatadmin/currentOrders/AdminCurrentOrders';
import { CustomerPage } from 'src/scenes/letseatadmin/CustomerPage';
import { CustomersPage } from 'src/scenes/letseatadmin/CustomersPage';
import { DeliveryMonitoringPage } from 'src/scenes/letseatadmin/DeliveryMonitoringPage';
import { DriverBillingPage } from 'src/scenes/letseatadmin/DriverBillingPage';
import { DriverBonusBillingPage } from 'src/scenes/letseatadmin/DriverBonusBillingPage';
import { DriverBonusOffersPage } from 'src/scenes/letseatadmin/DriverBonusOffersPage';
import { DriverPage } from 'src/scenes/letseatadmin/DriverPage';
import { DriverReferralsPage } from 'src/scenes/letseatadmin/DriverReferralsPage';
import { DriversPage } from 'src/scenes/letseatadmin/DriversPage';
import ChangeEmailTemplateDialog from 'src/scenes/letseatadmin/emailTemplates/ChangeEmailTemplateDialog';
import CreateEmailTemplateDialog from 'src/scenes/letseatadmin/emailTemplates/CreateEmailTemplateDialog';
import { LiveDriversPage } from 'src/scenes/letseatadmin/LiveDriversPage';
import { OngoingDriverBonusesPage } from 'src/scenes/letseatadmin/OngoingDriverBonusesPage';
import { OrderIncidentsPage } from 'src/scenes/letseatadmin/OrderIncidentsPage';
import { OrderPage } from 'src/scenes/letseatadmin/OrderPage';
import { OrdersPage } from 'src/scenes/letseatadmin/OrdersPage';
import { PickupStationsPage } from 'src/scenes/letseatadmin/PickupStationsPage';
import { QuickActionsPage } from 'src/scenes/letseatadmin/QuickActionsPage';
import { RestaurantBillingPage } from 'src/scenes/letseatadmin/RestaurantBillingPage';
import { RestaurantBillingPageUy } from 'src/scenes/letseatadmin/RestaurantBillingPageUy';
import { RestaurantReferralsPage } from 'src/scenes/letseatadmin/RestaurantReferralsPage';
import { RestaurantSalesPage } from 'src/scenes/letseatadmin/RestaurantSalesPage';
import { ChangeRestaurantDetailsDialog } from 'src/scenes/letseatadmin/restaurantsDetails/change-restaurant-details-dialog/ChangeRestaurantDetailsDialog';
import { RestaurantsDetails } from 'src/scenes/letseatadmin/restaurantsDetails/RestaurantsDetails';
import { RestaurantsPage } from 'src/scenes/letseatadmin/RestaurantsPage';
import { SettingsPage } from 'src/scenes/letseatadmin/SettingsPage';
import { SupportPage } from 'src/scenes/letseatadmin/SupportPage';
import { SurveysPage } from 'src/scenes/letseatadmin/SurveysPage';
import { ThirdPartyLogisticsCoveragePage } from 'src/scenes/letseatadmin/ThirdPartyLogisticsCoveragePage';
import { CopyRestaurantMenuDialog } from 'src/scenes/letseatmanager/deprecatedMenu/CopyRestaurantMenuDialog';
import { CreateRestaurantMenuPromotionDialog } from 'src/scenes/letseatmanager/deprecatedMenu/CreateRestaurantMenuPromotionDialog';
import { ImportRestaurantMenuDialog } from 'src/scenes/letseatmanager/deprecatedMenu/ImportRestaurantMenuDialog';
import { SuspiciousOrdersPage } from 'src/scenes/letseatmanager/SuspiciousOrdersPage';

export function AppRouteSupport(): React.ReactElement {
    return (
        <>
            <CancelDeliveryDialog />
            <RestartDeliveryManagerDialog />
            <CompleteDeliveryManagerDialog />
            <CancelDeliveryManagerDialog />
            <ChangePickupStationsDialog />
            <CreateEmailTemplateDialog />
            <ChangeEmailTemplateDialog />
            <ChangeRestaurantDetailsDialog />
            <CopyRestaurantMenuDialog />
            <ImportRestaurantMenuDialog />
            <CreateRestaurantMenuPromotionDialog />
            <Route path={RoutePaths.ADMIN_QUICK_ACTIONS} component={QuickActionsPage} />
            <Route path={RoutePaths.ADMIN_SUPPORT} component={SupportPage} />
            <Route path={RoutePaths.ADMIN_RESTAURANT_BILLING} component={RestaurantBillingPage} />
            <Route path={RoutePaths.ADMIN_RESTAURANT_BILLING_UY} component={RestaurantBillingPageUy} />
            <Route path={RoutePaths.ADMIN_RESTAURANT_REFERRALS} component={RestaurantReferralsPage} />
            <Route path={RoutePaths.ADMIN_DRIVER_REFERRALS} component={DriverReferralsPage} />
            <Route path={RoutePaths.ADMIN_RESTAURANT_SALES} component={RestaurantSalesPage} />
            <Route path={RoutePaths.ADMIN_DELIVERY_MONITORING} component={DeliveryMonitoringPage} />
            <Switch>
                <Route path={RoutePaths.ADMIN_SURVEYS_RESTAURANTS} exact component={SurveysPage} />
            </Switch>
            <Route path={RoutePaths.ADMIN_ONGOING_ORDERS} component={AdminCurrentOrders} />
            <Switch>
                <Route path={RoutePaths.ADMIN_ORDERS} exact component={OrdersPage} />
                <Route path={RoutePaths.ADMIN_ORDER} component={OrderPage} />
            </Switch>
            <Route path={RoutePaths.ADMIN_ORDER_INCIDENTS} component={OrderIncidentsPage} />
            <Route path={RoutePaths.ADMIN_SUSPICIOUS_ORDERS} component={SuspiciousOrdersPage} />
            <Route path={RoutePaths.ADMIN_PICKUP_STATIONS} component={PickupStationsPage} />
            <Switch>
                <Route path={RoutePaths.ADMIN_BRAND} component={BrandPage} />
                <Route path={RoutePaths.ADMIN_BRANDS} component={BrandsPage} />
            </Switch>
            <Route path={RoutePaths.ADMIN_RESTAURANTS} component={RestaurantsPage} />
            <Route path={RoutePaths.ADMIN_RESTAURANTS_DETAILS} component={RestaurantsDetails} />
            <Switch>
                <Route path={RoutePaths.ADMIN_CUSTOMER} component={CustomerPage} />
                <Route path={RoutePaths.ADMIN_CUSTOMERS} component={CustomersPage} />
            </Switch>
            <Switch>
                <Route path={RoutePaths.ADMIN_DRIVER} component={DriverPage} />
                <Route path={RoutePaths.ADMIN_DRIVERS} component={DriversPage} />
            </Switch>
            <Route path={RoutePaths.ADMIN_DRIVER_BONUS_OFFERS} component={DriverBonusOffersPage} />
            <Route path={RoutePaths.ADMIN_ONGOING_DRIVER_BONUSES} component={OngoingDriverBonusesPage} />
            <Route path={RoutePaths.ADMIN_DRIVER_BILLING} component={DriverBillingPage} />
            <Route path={RoutePaths.ADMIN_DRIVER_BONUS_BILLING} component={DriverBonusBillingPage} />
            <Route path={RoutePaths.ADMIN_LIVE_DRIVERS} component={LiveDriversPage} />
            <Route path={RoutePaths.ADMIN_THIRD_PARTY_LOGISTICS_COVERAGE} component={ThirdPartyLogisticsCoveragePage} />
            <Route path={RoutePaths.ADMIN_LOG_EVENTS} component={AdminLogEventsPage} />
            <Route path={RoutePaths.ADMIN_SETTINGS} component={SettingsPage} />
        </>
    );
}
