/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { ClientFrequency } from 'src/constants/ClientFrequency';
import { GiftVm } from 'src/types/GiftVm';
import type { BrandCustomerId, CustomerId, EmailAddress, RestaurantId } from 'src/types/Id';

export async function findBrandCustomersApi(request: FindBrandCustomersApiRequest): ApiSauceResponse<FindBrandCustomersApiResponse> {
    return letseatmanagerApiMethod('brandCustomer/findBrandCustomersApi', request);
}

type FindBrandCustomersApiRequest = {
    restaurantIds: Array<RestaurantId>;
    page?: number;
    rowsPerPage?: number;
    searchText?: string;
};

type FindBrandCustomersApiResponse = {
    brandCustomers: Array<BrandCustomerVm>;
    total?: number;
};

export type BrandCustomerVm = {
    brandCustomerId: BrandCustomerId;
    customerId?: CustomerId;
    orderedIn: Array<string>;
    name?: string;
    firstName?: string;
    lastName?: string;
    mobileNumber?: string;
    email?: EmailAddress;
    amount?: number;
    gifts?: Array<GiftVm>;
    cashback?: string;
    apps: Array<App>;
    clientFrequency?: ClientFrequency;
    orders?: number;
    lastOrder?: Date;
    secondLastOrder?: Date;
};
