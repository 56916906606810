/**
 * @prettier
 */
import type { PaymentTerminalVm } from 'src/types/PaymentTerminalVm';
import { useSelector } from 'src/utils/react/useSelector';

export function usePaymentTerminalsAvailable(): Array<PaymentTerminalVm> {
    const paymentTerminals = useSelector((state) => state.app.paymentTerminals);
    const deviceGroup = useSelector((state) => state.app.deviceGroup);

    const paymentTerminalsAvailable = paymentTerminals?.filter((paymentTerminal) => deviceGroup?.paymentTerminalIds?.includes(paymentTerminal.paymentTerminalId)) ?? [];

    return paymentTerminalsAvailable;
}
