/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import * as React from 'react';
import { getManagerUserSalesReportApi } from 'src/api/letseatmanager/cashRegister/getManagerUserSalesReportApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { DiscountType } from 'src/constants/DiscountType';
import { translate } from 'src/i18n/translate';
import { ManagerUserCanceledOrdersByWaiterReport } from 'src/scenes/letseatmanager/posReports/managerUserSalesReport/ManagerUserCanceledOrdersByWaiterReport';
import { ManagerUserHistoricalSalesReport } from 'src/scenes/letseatmanager/posReports/managerUserSalesReport/ManagerUserHistoricalSalesReport';
import { ManagerUserSalesByPaymentMethodReport } from 'src/scenes/letseatmanager/posReports/managerUserSalesReport/ManagerUserSalesByPaymentMethodReport';
import { ManagerUserServedOrdersReport } from 'src/scenes/letseatmanager/posReports/managerUserSalesReport/ManagerUserServedOrdersReport';
import { ManagerUserTipsReport } from 'src/scenes/letseatmanager/posReports/managerUserSalesReport/ManagerUserTipsReport';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { ManagerUserOrdersAndTips } from 'src/types/ManagerUserSalesReportVm';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { sum } from 'src/utils/reduce/sum';

export function ManagerUserSalesReport(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, managerUserSalesReport] = useLoadApi(
        getManagerUserSalesReportApi,
        {
            restaurantIds,
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).toISOString(),
            salesType,
        },
        { dependencies: [restaurantIds, period, salesType] }
    );

    const getTotalOrders = () => {
        const managerUserTotalOrders = managerUserSalesReport?.managerUserHistoricalSales.map(
            (managerUserHistoricalSales: { createdAt: string; orderVolume: number; servedBy: string; total: number }) => managerUserHistoricalSales.orderVolume
        );
        if (!managerUserTotalOrders) return '0';
        return managerUserTotalOrders.reduce(sum, BigNumber(0)).toString();
    };

    const getTotalSalesAmount = () => {
        const managerUserTotalSalesAmount = managerUserSalesReport?.managerUserHistoricalSales.map(
            (managerUserHistoricalSales: { createdAt: string; orderVolume: number; servedBy: string; total: number }) => managerUserHistoricalSales.total
        );
        if (!managerUserTotalSalesAmount) return '0';
        return managerUserTotalSalesAmount.reduce(sum, BigNumber(0)).toString();
    };

    const getTotalTipsAmount = () => {
        const managerUserTipsAmount = managerUserSalesReport?.managerUserOrdersAndTips.map((managerUserOrdersAndTips: ManagerUserOrdersAndTips) => managerUserOrdersAndTips.posTipAmount);
        if (!managerUserTipsAmount) return '0';
        return managerUserTipsAmount.reduce(sum, BigNumber(0)).toString();
    };

    const getTotalDiscountsAmount = () => {
        const managerUserDiscountsAmount = managerUserSalesReport?.managerUserSalesByDiscount.map(
            (managerUserSalesByDiscount: { orderVolume: number; posDiscount: number; posDiscountType: DiscountType; servedBy: string }) => managerUserSalesByDiscount.posDiscount
        );
        if (!managerUserDiscountsAmount) return '0';
        return managerUserDiscountsAmount.reduce(sum, BigNumber(0)).toString();
    };

    const isEmptyReport = () => {
        if (!managerUserSalesReport) return true;
        return (
            managerUserSalesReport.managerUserHistoricalSales.length === 0 &&
            managerUserSalesReport.managerUserOrdersAndTips.length === 0 &&
            managerUserSalesReport.managerUserOrdersByPaymentMethod.length === 0 &&
            managerUserSalesReport.managerUserSalesByDiscount.length === 0 &&
            managerUserSalesReport.managerUserSalesByItem.length === 0 &&
            BigNumber(managerUserSalesReport.posTipAmountPercentage).isZero()
        );
    };

    if (isEmptyReport()) {
        return (
            <div className={classes.container}>
                <UpdatingContentProgress loading={loading} top />
                <ReportSectionEmptyState />
            </div>
        );
    }

    return (
        <section className={classes.container}>
            <UpdatingContentProgress loading={loading} top />
            <article className={classes.kpiHeading}>
                <KpiCard
                    classes={{
                        container: classes.headingKpiContainer,
                        title: classes.headingKpiTitleKpi,
                        body: classes.headingKpiBody,
                        color: classes.headingKpiColor,
                        legend: classes.headingKpiLegendContainer,
                    }}
                    legend={managerUserSalesReport.managerUserSalesKpis?.posTotalOrders ?? 0}
                    title={translate('Served orders')}
                />
                <KpiCard
                    classes={{
                        container: classes.headingKpiContainer,
                        title: classes.headingKpiTitleKpi,
                        body: classes.headingKpiBody,
                        color: classes.headingKpiColor,
                        legend: classes.headingKpiLegendContainer,
                    }}
                    legend={formatAsCurrencyNumber(managerUserSalesReport.managerUserSalesKpis?.posTotalIncome)}
                    title={translate('Income received')}
                />
                <KpiCard
                    classes={{
                        container: classes.headingKpiContainer,
                        title: classes.headingKpiTitleKpi,
                        body: classes.headingKpiBody,
                        color: classes.headingKpiColor,
                        legend: classes.headingKpiLegendContainer,
                    }}
                    legend={formatAsCurrencyNumber(managerUserSalesReport.managerUserSalesKpis?.posTotalTips)}
                    title={translate('Tips received')}
                />
                <KpiCard
                    classes={{
                        container: classes.headingKpiContainer,
                        title: classes.headingKpiTitleKpi,
                        body: classes.headingKpiBody,
                        color: classes.headingKpiColor,
                        legend: classes.headingKpiLegendContainer,
                    }}
                    legend={formatAsCurrencyNumber(managerUserSalesReport.managerUserSalesKpis?.posTotalDiscounts)}
                    title={translate('Total discounts')}
                />
            </article>
            <div className={classes.reportsContainer}>
                <ManagerUserServedOrdersReport managerUserSalesReport={managerUserSalesReport} />
                <ManagerUserTipsReport managerUserSalesReport={managerUserSalesReport} />
                <ManagerUserSalesByPaymentMethodReport managerUserSalesReport={managerUserSalesReport} />
            </div>
            <ManagerUserHistoricalSalesReport managerUserSalesReport={managerUserSalesReport} />
            <ManagerUserCanceledOrdersByWaiterReport managerUserSalesReport={managerUserSalesReport} />
        </section>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        paddingBottom: 100,
    },
    kpiHeading: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        flexWrap: 'nowrap',
        [theme.breakpoints.between('xs', 'sm')]: {
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            flexWrap: 'nowrap',
        },
    },
    headingKpiContainer: {
        backgroundColor: 'transparent',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '8px',
        margin: 5,
        marginRight: '5%',
        marginBottom: '2%',
    },
    headingKpiTitleKpi: {
        fontFamily: theme.typography.regular,
        fontSize: 16,
        color: theme.palette.text.contrast,
        width: '100%',
        textAlign: 'center',
    },
    headingKpiBody: {
        fontSize: 28,
        fontFamily: theme.typography.semiBold,
    },
    headingKpiColor: {
        width: 12,
        height: 12,
        borderRadius: 2,
    },
    headingKpiLegendContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    reportsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}));
