/**
 * @prettier
 */
import { Switch, Table } from '@pidedirecto/ui';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { DeliveryProvidersInfoVm } from 'src/api/letseatadmin/theRestaurant/getTheRestaurantApi';
import { DeliveryProvider } from 'src/constants/DeliveryProviders';
import { translate } from 'src/i18n/translate';
import { PideDirectoCircleIcon } from 'src/icons/PideDirectoCircleIcon';
import { RappiCircleIcon } from 'src/icons/RappiCircleIcon';
import { UberDaasCircleIcon } from 'src/icons/UberDaasCircleIcon';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { isRappiDeliveryProvider } from 'src/utils/order/isRappiDeliveryProvider';
import { isUberDaasDeliveryProvider } from 'src/utils/order/isUberDaasDeliveryProvider';

export function DeliveryProvidersEnabledList({
    deliveryProvidersInfo,
    cashDeliveryProvidersEnabled,
    cardDeliveryProvidersEnabled,
    updateCashDeliveryProviders,
    updateCardDeliveryProviders,
}: Props): React.ReactElement {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const deliveryProvidersColumns = [
        {
            id: 'deliveryProvider',
            content: translate('Provider'),
        },
        {
            id: 'averageCost',
            content: translate('Average cost'),
        },
        {
            id: 'cashDeliveryProviderStatus',
            content: translate('Cash status'),
        },
        {
            id: 'cardDeliveryProviderStatus',
            content: translate('Card status'),
        },
    ];

    const getDeliverProvidersIcon = (deliveryProvider: DeliveryProvider) => {
        if (isUberDaasDeliveryProvider(deliveryProvider)) return <UberDaasCircleIcon />;
        if (isRappiDeliveryProvider(deliveryProvider)) return <RappiCircleIcon />;
        return <PideDirectoCircleIcon />;
    };

    return (
        <Table
            title={translate('Delivery tabulator')}
            columns={deliveryProvidersColumns}
            rows={deliveryProvidersInfo?.map((deliveryProviderInfo: DeliveryProvidersInfoVm) => ({
                deliveryProvider: getDeliverProvidersIcon(deliveryProviderInfo.deliveryProvider),
                averageCost: BigNumber(deliveryProviderInfo.averageCost).isGreaterThan(0) ? formatAsCurrencyNumber(deliveryProviderInfo.averageCost) : translate('Not calculated value'),
                cashDeliveryProviderStatus: (
                    <Switch
                        checked={!!cashDeliveryProvidersEnabled?.includes(deliveryProviderInfo.deliveryProvider)}
                        onChange={() => updateCashDeliveryProviders(deliveryProviderInfo.deliveryProvider)}
                    />
                ),
                cardDeliveryProviderStatus: (
                    <Switch
                        checked={!!cardDeliveryProvidersEnabled?.includes(deliveryProviderInfo.deliveryProvider)}
                        onChange={() => updateCardDeliveryProviders(deliveryProviderInfo.deliveryProvider)}
                    />
                ),
            }))}
        />
    );
}

type Props = {
    deliveryProvidersInfo: Array<DeliveryProvidersInfoVm>;
    cashDeliveryProvidersEnabled: Array<DeliveryProvider>;
    cardDeliveryProvidersEnabled: Array<DeliveryProvider>;
    updateCashDeliveryProviders: (deliveryProvider: DeliveryProvider) => void;
    updateCardDeliveryProviders: (deliveryProvider: DeliveryProvider) => void;
};
