/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { QpayTerminalPaymentInstructionResult } from 'src/constants/QpayTerminalPaymentInstructionResult';
import type { QpayTerminalDeviceId, QpayTerminalInstructionId } from 'src/types/Id';

export async function deprecatedPushQpayPaymentTerminalInstructionApi(request: PushTerminalPaymentInstructionApiRequest): ApiSauceResponse<PushTerminalPaymentInstructionApiResponse> {
    return letseatmanagerApiMethod('pos/pushTerminalPaymentInstructionApi', request);
}

export type PushTerminalPaymentInstructionApiRequest = {
    qpayTerminalDeviceId: QpayTerminalDeviceId;
    amount: string;
    mockQpayTerminalInstructionResult?: QpayTerminalPaymentInstructionResult;
    apphook: string | undefined;
};

export type PushTerminalPaymentInstructionApiResponse = {
    qpayTerminalInstructionId: QpayTerminalInstructionId;
    qpayTerminalPaymentInstructionResult?: QpayTerminalPaymentInstructionResult;
};
