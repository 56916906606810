/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeManagerUserApi } from 'src/api/letseatadmin/managerUser/changeManagerUserApi';
import { getManagerUserApi } from 'src/api/letseatadmin/managerUser/getManagerUserApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormRestaurantsAutocomplete } from 'src/components/form/FormRestaurantsAutocomplete';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormUserTypeSelect } from 'src/components/form/FormUserTypeSelect';
import { PasswordRequiredContent } from 'src/components/PasswordRequiredContent';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { ManagerUserId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { requireValue } from 'src/utils/require/requireValue';

export function ChangeManagerUserDialog({ open, managerUserId, onClose, onChangeManagerUser }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();
    const {
        formState: { isSubmitting },
    } = form;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) load();
    }, [open]);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const handleExited = () => {
        setLoading(false);
    };

    const load = async () => {
        setLoading(true);
        const response = await getManagerUserApi({ managerUserId: requireValue(managerUserId) });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            onClose();
            return;
        }
        const managerUser = response.data;
        form.reset(managerUser);
        setLoading(false);
    };

    const onSubmit = async (form: any) => {
        const response = await changeManagerUserApi({
            managerUserId: requireValue(managerUserId),
            userType: form.userType,
            restaurantIds: form.restaurantIds,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onChangeManagerUser();
        onClose();
    };

    return (
        <Dialog classes={{ dialog: classes.dialog }} title={translate('Change Manager User')} open={open} onClose={handleClose}>
            <PasswordRequiredContent customPassword={'0523'}>
                <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField name='username' label={translate('Username')} disabled />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='password' label={translate('Password')} disabled />
                        </Grid>
                        <Grid item xs={12}>
                            <FormUserTypeSelect name='userType' label={translate('User Type')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormRestaurantsAutocomplete name='restaurantIds' label={translate('Restaurants')} />
                        </Grid>
                    </Grid>
                    <DialogActions className={classes.dialogActions}>
                        <Button classes={{ button: classes.button }} secondary onClick={handleClose} disabled={isSubmitting}>
                            {translate('Cancel')}
                        </Button>
                        <Button classes={{ button: classes.button }} type='submit' disabled={loading || isSubmitting}>
                            {isSubmitting ? translate('Changing') : translate('Change')}
                        </Button>
                    </DialogActions>
                </Form>
            </PasswordRequiredContent>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: '50vw',
    },
    button: {
        width: 200,
    },
    dialogActions: {
        marginTop: 20,
    },
}));

type Props = {
    open: boolean;
    managerUserId: ManagerUserId | undefined;
    onClose: any;
    onChangeManagerUser: any;
};
