/**
 * @prettier
 */
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState } from 'react';
import { sendEndOfDayReportEmailApi } from 'src/api/letseatmanager/pos/sendEndOfDayReportEmailApi';
import { Button } from 'src/components/Button';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { CashRegisterMovements } from 'src/scenes/letseatmanager/posBusinessDayReport/CashRegisterMovements';
import { CashRegisterPosBusinessDaysReport } from 'src/scenes/letseatmanager/posBusinessDayReport/CashRegisterPosBusinessDaysReport';
import { CashRegisterShifts } from 'src/scenes/letseatmanager/posBusinessDayReport/CashRegisterShifts';
import { CashRegisterShiftsTimeLine } from 'src/scenes/letseatmanager/posBusinessDayReport/CashRegisterShiftsTimeLine';
import { PosBusinessDayPaymentMethodsSummary } from 'src/scenes/letseatmanager/posBusinessDayReport/PosBusinessDayPaymentMethodsSummary';
import { PosBusinessDaySummary } from 'src/scenes/letseatmanager/posBusinessDayReport/PosBusinessDaySummary';
import { PosBusinessDaySalesResume } from 'src/scenes/letseatmanager/restaurantDashboard/posBusinessDay/PosBusinessDaySalesResume';
import { PosBusinessSales } from 'src/scenes/letseatmanager/restaurantDashboard/posBusinessDay/PosBusinessSales';
import { useNotification } from 'src/services/notification/useNotification';
import { usePrintPosBusinessDayReport } from 'src/services/printer/usePrintPosBusinessDayReport';
import { CashRegisterPosBusinessDayReportVm } from 'src/types/CashRegisterPosBusinessDayReportVm';
import type { PosBusinessDayReportVm } from 'src/types/PosBusinessDayReportVm';

export function PosBusinessDayReportSummary({ posBusinessDayReport }: Props): React.ReactElement | null {
    const classes = useStyles();

    const notification = useNotification();
    const [printPosBusinessDayReport] = usePrintPosBusinessDayReport();

    const [loadingSendEmail, setLoadingSendEmail] = useState(false);

    const sendEmail = async () => {
        if (!posBusinessDayReport) return;
        setLoadingSendEmail(true);
        const response = await sendEndOfDayReportEmailApi({ posBusinessDayId: (posBusinessDayReport as any)?.posBusinessDayReportId });
        if (!response.ok) {
            notification({ message: translate('Failed to send the report by email. Are you sure an email is already registered?') });
        }
        setLoadingSendEmail(false);
        notification({ message: translate('Report successfully sent out via email') });
    };

    const printReport = async () => {
        if (!posBusinessDayReport) return;
        await printPosBusinessDayReport(posBusinessDayReport);
    };

    if (!posBusinessDayReport) return null;

    return (
        <div>
            <div className={classes.container}>
                <div style={{ display: 'flex' }}>
                    <div className={classes.cashRegisterSummary}>
                        <div className={classes.row}>
                            <Text title>{translate('Cash Register Summary')}</Text>
                            <div style={{ display: 'flex', gap: 12 }}>
                                <Button outlined onClick={sendEmail} disabled={loadingSendEmail}>
                                    {translate('Send Email')}
                                </Button>
                                <Button primary onClick={printReport}>
                                    {translate('Print report')}
                                </Button>
                            </div>
                        </div>
                        <PosBusinessDaySummary posBusinessDay={posBusinessDayReport} />
                        {!(posBusinessDayReport as any)?.cashRegisterPosBusinessDaysSummary && <PosBusinessDayPaymentMethodsSummary posBusinessDay={posBusinessDayReport} />}
                    </div>
                    <CashRegisterShiftsTimeLine posBusinessDay={posBusinessDayReport} />
                </div>
                <PosBusinessDaySalesResume posBusinessDayReport={posBusinessDayReport} />
                <CashRegisterPosBusinessDaysReport cashRegisterPosBusinessDayReportsSummary={(posBusinessDayReport as any)?.cashRegisterPosBusinessDaysSummary} />
                <CashRegisterMovements posBusinessDay={posBusinessDayReport} />
                <CashRegisterShifts posBusinessDay={posBusinessDayReport} />
                <Divider variant={'fullWidth'} style={{ margin: '40px 0' }} />
                <PosBusinessSales posBusinessDayReport={posBusinessDayReport} />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 24,
            paddingRight: 24,
        },
    },
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
    },
    title: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 20,
        margin: 0,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
    topSection: {
        width: '100%',
        marginTop: 24,
    },
    infoContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        rowGap: 10,
        columnGap: 20,
        marginBottom: 20,
    },
    article: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cashRegisterSummary: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    goBackButton: {
        position: 'relative',
    },
    summaryContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    switchContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'end',
    },
}));

type Props = {
    posBusinessDayReport?: PosBusinessDayReportVm | CashRegisterPosBusinessDayReportVm;
};
