/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { OrderIncidentVm } from 'src/types/OrderIncidentVm';

export async function findOrderIncidentsApi(request: FindOrderIncidentsApiRequest): ApiSauceResponse<Array<OrderIncidentVm>> {
    return letseatadminApiMethod('orderIncident/findOrderIncidentsApi', request);
}

export type FindOrderIncidentsApiRequest = {
    from: Date;
    to: Date;
};
