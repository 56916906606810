/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CashRegisterPosBusinessDayId, ManagerUserId, RestaurantId } from 'src/types/Id';

export async function openCashRegisterApi(request: DepositCashRegisterApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('pos/openCashRegisterApi', request);
}

export type DepositCashRegisterApiRequest = {
    restaurantId: RestaurantId;
    initialAmount: string;
    cashRegisterPosBusinessDayId?: CashRegisterPosBusinessDayId;
    managerUserId?: ManagerUserId;
};
