/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { SalesType } from 'src/constants/SalesTypes';
import type { RestaurantId } from 'src/types/Id';

export async function getGeneralReportsRestaurantStatisticsApi(request: GetGeneralRestaurantStatisticsRequest): ApiSauceResponse<GetGeneralReportsStatisticsApiResponse> {
    return pidedirectoReportsMethod(`/generalPageReportApi`, request);
}

type GetGeneralRestaurantStatisticsRequest = {
    report: string;
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
    salesType: SalesType;
};

export type GetGeneralReportsStatisticsApiResponse = Array<{
    averageRating?: number;
    averageTicket?: number;
    name?: string;
    restaurantId: RestaurantId;
    salesByApp?: Array<SalesByApp>;
    salesByMenuCategory?: Array<SalesByMenuCategory>;
    salesByOrderType?: Array<SalesByOrderType>;
    salesByPaymentMethod?: Array<SalesByPaymentMethod>;
    salesPerDay?: Array<SalesPerDay>;
    totalOrders?: number;
    totalSales?: number;
}>;

export type SalesByApp = {
    app: App;
    orderVolume: number;
    total: number;
};

export type SalesByMenuCategory = {
    amount: number;
    categoryType: string;
    discount: number;
    total: number;
    percentageOfSales: number;
    orderVolume: number;
};

export type SalesByOrderType = {
    amount: number;
    discount: number;
    orderType: string;
    orderVolume: number;
    total: number;
    orderTypesData: string;
};

export type SalesByPaymentMethod = {
    amount: number;
    app: string;
    discount: number;
    orderVolume: number;
    paymentMethod: string;
    total: number;
};

export type SalesPerDay = {
    createdAt: string;
    orderVolume: number;
    total: number;
};

export type SalesByDeliveryType = {
    amount: number;
    deliveryMethod: string;
    discount: number;
    orderVolume: number;
    total: number;
};
