/**
 * @prettier
 */
/* eslint-disable react/prop-types, react/jsx-handler-names */
import * as React from 'react';
import SelectTypeahead from 'src/components/deprecated/react-final-form/SelectTypeahead';
import { Bank, Banks } from 'src/constants/Bank';
import { getBankClave } from 'src/utils/bank/getBankClave';
import { getBankLongName } from 'src/utils/bank/getBankLongName';

export default (props: any): React.ReactElement => {
    const banks: Array<Bank> = Object.values(Banks) as any;

    return <SelectTypeahead {...props} options={banks.map((bank) => ({ label: `${bank} - ${getBankClave(bank) ?? ''} - ${getBankLongName(bank) ?? ''}`, value: bank }))} />;
};
