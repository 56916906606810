/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import type { OrderVm } from 'src/types/OrderVm';
import { isIntegrationOrder } from 'src/utils/order/isIntegrationOrder';

export function getBaseOrderCost(order: OrderVm): string {
    if (isIntegrationOrder(order)) return order.total;

    const chargeAmount = BigNumber(order.amountWithPromoPriceDiscount)
        .plus(order.paymentMethod === PaymentMethods.CASH ? order.marketplaceServiceFee ?? 0 : 0)
        .plus(order.deliveryPaidByRestaurant ? 0 : order.deliveryCost ?? 0)
        .plus(order.externalDelivery ? order.driverTip ?? 0 : 0)
        .plus(order.isManualOrderProtected && order.directoProtectionPaidByCustomer ? order.pideDirectoProtectionForManualOrderAmount ?? 0 : 0)
        .minus(order.posDiscount ?? 0)
        .minus((order.paymentMethod === PaymentMethods.CASH ? order.promoCodeDiscount : order.restaurantPromoCodeCost) ?? 0)
        .minus(order.restaurantDeliveryCost ?? 0)
        .minus(order.usedCredits ?? 0)
        .minus(order.deliveryCostBonus ?? 0)
        .minus(order.promotionsDiscount ?? 0)
        .toString();

    return chargeAmount;
}
