/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { changeTheRestaurantApi } from 'src/api/letseatadmin/theRestaurant/changeTheRestaurantApi';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { getDirtyFormValues } from 'src/components/form/utils/getDirtyFormValues';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { appColors } from 'src/theme/AppColors';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { isPickupStationRestaurant } from 'src/utils/restaurant/isPickupStationRestaurant';

export function RestaurantAppsSettings({ loading, restaurant, onChangeRestaurant, setIsDirtyForm }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { dirtyFields },
        watch,
        formState,
    } = form;

    const appOrdersCashbackEnabled = watch('appOrdersCashbackEnabled');

    const appLoading = useSelector((state) => state.app.loading);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);
    const { reloadRestaurant } = useReloadRestaurant();

    useEffect(() => {
        if (!loading)
            form.reset({
                restaurantId: restaurant.restaurantId ?? null,
                activated: restaurant.activated ?? null,
                wansoftIntegrationEnabled: restaurant.wansoftIntegrationEnabled ?? null,
                wansoftStoreId: restaurant.wansoftStoreId ?? null,
                wansoftPassword: restaurant.wansoftPassword ?? null,
                rappiStoreCheckInCodeEnabled: restaurant.rappiStoreCheckInCodeEnabled ?? null,
                whiteLabelOrdersEnabled: restaurant.whiteLabelOrdersEnabled ?? null,
                appOrdersCashbackEnabled: restaurant.appOrdersCashbackEnabled ?? null,
                appOrdersCashback: restaurant.appOrdersCashback ?? null,
                pideDirectoApiKey: restaurant.pideDirectoApiKey ?? null,
                sendAppTrackingUrl: restaurant.sendAppTrackingUrl ?? null,
                askToTrackInApp: restaurant.askToTrackInApp ?? null,
                oneOrderPerDayEnabled: restaurant.oneOrderPerDayEnabled ?? null,
            });
    }, [loading]);

    useEffect(() => {
        setIsDirtyForm(Object.keys(dirtyFields).length !== 0);
    }, [formState]);

    const onSubmit = async (form: any) => {
        const dirtyFormValues = getDirtyFormValues({ form, dirtyFields });
        const response = await changeTheRestaurantApi({ restaurantId, ...dirtyFormValues });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        await reloadRestaurant(restaurantId);
        await onChangeRestaurant();
        showSnackbar({ message: translate('Saved changes') });
    };

    return (
        <Paper className={classes.paper}>
            <UpdatingContentProgress loading={loading || appLoading} />
            <Form form={form} onSubmit={onSubmit} disabled={loading || appLoading}>
                <div className={classes.formContent}>
                    <Grid item xs={12} sm={6}>
                        <FormSwitch name='activated' label={translate('Activated (Show in App)')} tooltip={translate('Activate the restaurant in the marketplace')} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSwitch name='whiteLabelOrdersEnabled' label={translate('White-label App Orders Enabled')} tooltip={translate('With this option you enable the Orders in the WLA')} />
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='appOrdersCashbackEnabled'
                                label={translate('App Orders Cashback Enabled')}
                                tooltip={translate('Enable the option if you want the user to be awarded cashback points after they make a purchase.')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DeprecatedFormPercentNumberField
                                name='appOrdersCashback'
                                label={translate('App Orders Cashback %')}
                                disabled={!appOrdersCashbackEnabled}
                                tooltip={translate('Define the percentage of cashback points for orders placed in the app')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormSwitch name='wansoftIntegrationEnabled' label={translate('Enable Wansoft Integration')} tooltip={translate('Activate this option for integration with Wansoft')} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormTextField name='wansoftStoreId' label={translate('Wansoft Store Id')} tooltip={translate('Please provide the Store ID for Wansoft setup successfully.')} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormTextField name='wansoftPassword' label={translate('Wansoft Password')} tooltip={translate('Provide the password to configure Wansoft correctly.')} />
                        </Grid>
                    </Grid>

                    {restaurant.rappiStoreId && (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormSwitch name='rappiStoreCheckInCodeEnabled' label={translate('Enable Rappi Check In Code')} tooltip={translate('Activate this option for Rappi check in Code')} />
                            </Grid>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <FormTextField
                            name='pideDirectoApiKey'
                            label={translate('Pidedirecto Api Key')}
                            helperText={translate(
                                'Key needed by external systems (Restaurant app or POS) to access the Pidedirecto API. Contact a developer to generate this key so that the API is enabled.'
                            )}
                            tooltip={translate(
                                'key required by external systems (Restaurant App or POS) to access Pidedirecto API. Contact a developer to generate this key for the API to be enabled.'
                            )}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSwitch name='sendAppTrackingUrl' label={translate('Track In App')} tooltip={translate('Track the delivery man (letseat)')} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSwitch name='askToTrackInApp' label={translate('Ask To Track In App')} />
                    </Grid>
                    {isPickupStationRestaurant(restaurant.restaurantType) && (
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='oneOrderPerDayEnabled' label={translate('Restrict users to order a single time per day')} />
                        </Grid>
                    )}
                    <FormFixErrorHint />
                </div>

                <Grid container justify='center' spacing={3}>
                    <Grid item>
                        <FormSubmitButton text={translate('Change')} disabled={loading || appLoading} classes={{ container: classes.submitButton }} />
                    </Grid>
                </Grid>
            </Form>
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
    },
    formContent: {
        padding: theme.spacing(3),
    },
    deleteButton: {
        color: appColors.text.dangerLight,
    },
    section: {
        width: '100%',
        borderRadius: 5,
        margin: '20px auto',
        border: `1px solid ${theme.palette.secondary.dark}`,
    },
    sectionTitleContainer: {
        backgroundColor: theme.palette.secondary.light,
        marginTop: 20,
    },
    sectionTitle: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
    },
    subtitlesubtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    cashRegisterCustomReasonItem: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        minWidth: 140,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #676060B2',
        borderRadius: 12,
        padding: '0 10px',
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
        color: theme.palette.secondary.contrastText,
        marginLeft: 10,
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    paymentMethod: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    secondaryButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        padding: '5px 10px',
    },
    kioskUserContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    kioskInfo: {
        display: 'flex',
        flexDirection: 'column',
    },
    integrationStoreStatusClosed: {
        fontFamily: theme.typography.semiBold,
        color: '#fa6684',
        fontSize: 13,
    },
    integrationStoreStatusOpen: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 13,
    },
    submitButton: {
        zIndex: 1000,
    },
}));

type Props = {
    loading: boolean;
    restaurant: any;
    onChangeRestaurant: any;
    setIsDirtyForm: any;
};
