/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { CashBoxIcon } from '@pidedirecto/ui/icons';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { findOpenedCashRegisterInCashRegisterPosBusinessDayApi } from 'src/api/letseatmanager/pos/restaurantPosBusinessDay/findOpenedCashRegisterInCashRegisterPosBusinessDayApi';
import { Button } from 'src/components/Button';
import { Chip } from 'src/components/Chip';
import { Tooltip } from 'src/components/Tooltip';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { UserFilledIcon } from 'src/icons/UserFilledIcon';
import { posReducer } from 'src/reducers/posReducer';
import { useManageOpenCashRegister } from 'src/services/cashRegister/useManageOpenCashRegister';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { ManagerUserId } from 'src/types/Id';
import type { CashRegisterPosBusinessDaySummary } from 'src/types/PosBusinessDaySummaryVm';
import { CashRegisterPosBusinessDayVm } from 'src/types/PosBusinessDayVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function CashRegisterPosBusinessDayCard({ cashRegisterPosBusinessDaySummary }: Props): React.ReactElement {
    const classes = useStyles();
    const [userHasRolePermission] = useUserHasRolePermission();
    const { manageOpenCashRegister } = useManageOpenCashRegister();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [loading, setLoading] = useState(false);

    const openedCashRegisterPosBusinessDay = useSelector((state) => state.pos.openedCashRegisterPosBusinessDay);
    const openedCashRegister = useSelector((state) => state.pos.openedCashRegister);
    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const currentEmployeesClockedIn = useSelector((state) => state.app2.currentEmployeesClockedIn);

    const setOpenedCashRegister = useAction(posReducer.actions.setOpenedCashRegister);
    const setOpenedCashRegisterPosBusinessDay = useAction(posReducer.actions.setOpenedCashRegisterPosBusinessDay);

    const isCashRegisterOpen = cashRegisterPosBusinessDaySummary.opened;
    const isCashRegisterPosBusinessDaySelected = openedCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId === cashRegisterPosBusinessDaySummary?.cashRegisterPosBusinessDayId;
    const cashRegisterPosBusinessDay = openedPosBusinessDay?.cashRegisterPosBusinessDays?.find(
        (cashRegisterPosBusinessDay: CashRegisterPosBusinessDayVm) => cashRegisterPosBusinessDay.cashRegisterPosBusinessDayId === cashRegisterPosBusinessDaySummary.cashRegisterPosBusinessDayId
    );
    const employeeClockInAtTime = currentEmployeesClockedIn?.find(
        (employeeManagerUser: { clockedInAt: Date; managerUserId: ManagerUserId }) => employeeManagerUser.managerUserId === cashRegisterPosBusinessDaySummary.assignedToManagerUserId
    );

    useEffect(() => {
        handleOpenCashRegister();
    }, [openedCashRegisterPosBusinessDay, openedCashRegister]);

    const handleOpenCashRegister = () => {
        if (isCashRegisterPosBusinessDaySelected && !openedCashRegister) {
            manageOpenCashRegister({ forceOpenCashRegister: false });
        }
    };

    const handleSelectCashRegisterPosBusinessDay = async () => {
        if (!isCashRegisterOpen) return;
        if (loading) return;
        if (!userHasRolePermission(RolePermissions.MANAGE_CASH_REGISTERS)) return;
        if (isCashRegisterPosBusinessDaySelected && !openedCashRegister) return handleOpenCashRegister();

        setLoading(true);
        const response = await findOpenedCashRegisterInCashRegisterPosBusinessDayApi({
            cashRegisterPosBusinessDayId: cashRegisterPosBusinessDaySummary.cashRegisterPosBusinessDayId,
        });

        setLoading(false);

        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        setOpenedCashRegister(response.data);
        setOpenedCashRegisterPosBusinessDay(cashRegisterPosBusinessDay ?? cashRegisterPosBusinessDaySummary);
    };

    return (
        <Button
            secondary
            classes={{ button: classNames(classes.cardContainer, userHasRolePermission(RolePermissions.MANAGE_CASH_REGISTERS) ? classes.hasAction : '') }}
            onClick={handleSelectCashRegisterPosBusinessDay}
        >
            <div className={classes.separatedText}>
                <h1 className={classes.cashRegisterName}>
                    {translate('Cash register @cashRegisterNumber', { cashRegisterNumber: cashRegisterPosBusinessDaySummary?.cashRegisterPosBusinessDayNumber || '' })}
                </h1>
                <Chip classes={{ chip: classNames(classes.chip, { [classes.closeCashRegisterChip]: !isCashRegisterOpen }) }}>{isCashRegisterOpen ? translate('Opened') : translate('Closed')}</Chip>
            </div>
            <div className={classes.contentText}>
                {cashRegisterPosBusinessDaySummary.isForExternalMissingOrders && (
                    <Tooltip text={translate('Cash registers for external or auto accepted orders')}>
                        <CashBoxIcon title={translate('Cash registers for external or auto accepted orders')} />
                    </Tooltip>
                )}
                <UserFilledIcon height={18} width={18} />
                <div className={classes.subText}>{cashRegisterPosBusinessDaySummary?.openedBy}</div>
            </div>
            {employeeClockInAtTime && (
                <div className={classes.separatedText}>
                    <div className={classes.label}>{translate('Clock in time')}</div>
                    <div className={classes.summaryText}>{moment(employeeClockInAtTime?.clockedInAt).format('lll')}</div>
                </div>
            )}
            <div className={classes.separatedText}>
                <div>{translate('Orders')}</div>
                <div className={classes.summaryText}>{cashRegisterPosBusinessDaySummary.totalOrders ?? 0}</div>
            </div>
            <div className={classes.separatedText}>
                <div>{translate('Initial Amount')}</div>
                <div className={classes.summaryText}>{formatAsCurrencyNumber(cashRegisterPosBusinessDaySummary.initialAmount ?? 0)}</div>
            </div>
            {userHasRolePermission(RolePermissions.MANAGE_CASH_REGISTERS) && (
                <div className={classes.separatedText}>
                    <div>{translate('Total Sales')}</div>
                    <div className={classes.summaryText}>{formatAsCurrencyNumber(cashRegisterPosBusinessDaySummary.totalSold)}</div>
                </div>
            )}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '18px 24px',
        fontFamily: theme.typography.regular,
        fontWeight: 400,
        fontSize: 12,
        borderRadius: 8,
        justifyContent: 'unset',
        alignItems: 'unset',
        width: 'unset',
        backgroundColor: theme.palette.surface.primary,
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.surface.secondary}`,
        '&:hover': {
            border: `1px solid ${theme.palette.surface.brandContrast}`,
            backgroundColor: theme.palette.surface.brand,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
    chip: {
        height: 30,
        fontSize: 12,
        backgroundColor: '#CBF7E9',
        padding: '10px 20px',
        borderRadius: 8,
        color: '#024B49',
        fontFamily: theme.typography.regular,
    },
    hasAction: {
        cursor: 'pointer',
    },
    separatedText: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    contentText: {
        display: 'flex',
        alignItems: 'center',
        margin: '10px 0',
    },
    cashRegisterName: {
        fontSize: 18,
        fontWeight: 600,
        [theme.breakpoints.up('sm')]: {
            fontSize: 20,
        },
    },
    label: {
        textAlign: 'left',
    },
    summaryText: {
        fontWeight: 600,
        textAlign: 'right',
    },
    subText: {
        marginLeft: 10,
    },
    closeCashRegisterChip: {
        backgroundColor: '#FBE1E9',
        color: '#DB3956',
    },
}));

type Props = {
    cashRegisterPosBusinessDaySummary: CashRegisterPosBusinessDaySummary;
};
