/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuItemId, ModifierGroupId } from 'src/types/Id';

export async function hideModifierInModifierGroupApi(request: HideModifierInModifierGroupApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('modifierGroup/hideModifierInModifierGroupApi', request);
}

type HideModifierInModifierGroupApiRequest = {
    modifierGroupId: ModifierGroupId;
    menuItemId: MenuItemId;
};
