/**
 * @prettier
 */

import { usePosCartModifiersActions } from 'src/services/pos/posModifiersCart/posCartModifiersStore';

export function useSetMissingSectionName(): SetMissingSectionName {
    const setMissingSectionName = usePosCartModifiersActions((actions) => actions.setMissingSectionName);

    return setMissingSectionName;
}

type SetMissingSectionName = (missingSectionName: string) => void;
