/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { ManagerUserId, RestaurantId } from 'src/types/Id';
import { RestaurantEmployeeDayShiftVm } from 'src/types/RestaurantEmployeeDayShiftVm';

export async function clockOutRestaurantEmployeeApi(request: ClockOutRestaurantEmployeeApiRequest): ApiSauceResponse<RestaurantEmployeeDayShiftVm> {
    return letseatmanagerApiMethod('restaurantEmployeeDayShift/clockOutRestaurantEmployeeApi', request);
}

type ClockOutRestaurantEmployeeApiRequest = {
    restaurantId: RestaurantId;
    managerUserId: ManagerUserId;
    clockOutBy: ManagerUserId;
    clockedOutAt?: Date;
};
