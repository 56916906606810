/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderType } from 'src/constants/OrderType';
import type { PickupTimeType } from 'src/constants/PickupTimeType';
import type { OrderId, RestaurantId } from 'src/types/Id';

export async function changeOrderTypeApi(request: ChangeOrderTypeApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('order/changeOrderTypeApi', request);
}

export type ChangeOrderTypeApiRequest = {
    restaurantId: RestaurantId;
    orderId: OrderId;
    orderType: OrderType;
    restaurantZoneId?: string;
    table?: string;
    numberOfCustomers?: number;
    pickupTimeType?: PickupTimeType;
    pickupTime?: Date;
};
