/**
 * @prettier
 */
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function UploadCsvToolbarButton({ tooltip, onClick, disabled }: Props): React.ReactElement {
    return (
        <Tooltip title={tooltip || translate('Upload')}>
            <IconButton onClick={onClick} disabled={disabled}>
                <CloudUploadIcon />
            </IconButton>
        </Tooltip>
    );
}

type Props = {
    tooltip?: string;
    disabled?: boolean;
    onClick: any;
};
