/**
 * @prettier
 */
export const QpayTerminalPaymentInstructionResults = {
    UNKNOWN: 'UNKNOWN',
    TERMINAL_NOT_FOUND: 'TERMINAL_NOT_FOUND',
    TERMINAL_BUSY: 'TERMINAL_BUSY',
    INSTRUCTION_RECEIVED: 'INSTRUCTION_RECEIVED',
} as const;

export type QpayTerminalPaymentInstructionResult = typeof QpayTerminalPaymentInstructionResults[keyof typeof QpayTerminalPaymentInstructionResults];
