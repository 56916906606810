/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DeliveryZoneId, RestaurantId } from 'src/types/Id';

export async function disableDeliveryZoneApi(request: DisableDeliveryZoneApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('deliveryZone/disableDeliveryZoneApi', request);
}

type DisableDeliveryZoneApiRequest = {
    restaurantId: RestaurantId;
    deliveryZoneId: DeliveryZoneId;
};
