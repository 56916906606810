/**
 * @prettier
 */
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { PageContext } from 'src/components/Page';
import { PosOrderSteps } from 'src/constants/PosOrderStep';
import { useSelector } from 'src/utils/react/useSelector';
import { newUuid } from 'src/utils/uuid/newUuid';

export function PosTrackSession({ children }: Props): React.ReactElement {
    const { setPageSessionId } = useContext(PageContext);

    const orderStep = useSelector((state) => state.pos.orderStep);
    const posSimpleView = useSelector((state) => state.pos.context?.posSimpleView);

    useEffect(() => {
        if (orderStep === PosOrderSteps.SELECT_ORDER_TYPE && !posSimpleView) {
            setPageSessionId(newUuid());
        }
    }, [orderStep, posSimpleView, setPageSessionId]);

    return children;
}

type Props = {
    children: React.ReactElement;
};
