/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { createMenuItemApi } from 'src/api/letseatmanager/menuItem/createMenuItemApi';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormColorButtons } from 'src/components/form/FormColorButtons';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormCurrencySelect } from 'src/components/form/FormCurrencySelect';
import { FormImageUpload } from 'src/components/form/FormImageUpload';
import { FormNumberStringField } from 'src/components/form/FormNumberStringField';
import { FormPercentNumberField } from 'src/components/form/FormPercentNumberField';
import { FormPrinterNamesAutocomplete } from 'src/components/form/FormPrinterNamesAutocomplete';
import { FormRecipeAutocomplete } from 'src/components/form/FormRecipeAutocomplete';
import { FormRestaurantTaxAutocomplete } from 'src/components/form/FormRestaurantTaxAutocomplete';
import { FormRestaurantTaxAutocompleteMultiple } from 'src/components/form/FormRestaurantTaxAutocompleteMultiple';
import { FormSupplyCategorySelect } from 'src/components/form/FormSupplyCategorySelect';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormUnitOfMeasurementSelect } from 'src/components/form/FormUnitOfMeasurementSelect';
import { SecuredContent } from 'src/components/SecuredContent';
import { Tooltip } from 'src/components/Tooltip';
import { MenuItemSizes } from 'src/constants/MenuItemSize';
import { RolePermissions } from 'src/constants/RolePermission';
import { UnitsOfMeasurement } from 'src/constants/UnitOfMeasurement';
import { translate } from 'src/i18n/translate';
import { AddRoundedIcon } from 'src/icons/AddRoundedIcon';
import { DeleteRoundedIcon } from 'src/icons/DeleteRoundedIcon';
import { SelectMenuCategoriesDialog } from 'src/scenes/letseatmanager/menu/menu/SelectMenuCategoriesDialog';
import { SelectModifierGroupsDialog } from 'src/scenes/letseatmanager/menu/menuItem/SelectModifierGroupsDialog';
import { CreateRestaurantTaxDialog } from 'src/scenes/letseatmanager/taxes/CreateRestaurantTaxDialog';
import { useMenuCategories } from 'src/services/menuCategory/useMenuCategories';
import { useModifierGroups } from 'src/services/modifierGroup/useModifierGroups';
import { MenuCategoryId, ModifierGroupId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isUruguay } from 'src/utils/country/isUruguay';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateMenuItem({ onClose, onSuccess, isProductSelected }: Props): React.ReactElement | null {
    const form = useForm();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [selectedModifierGroupIds, setSelectedModifierGroupIds] = useState([]);
    const [selectedMenuCategoryIds, setSelectedMenuCategoryIds] = useState<Array<MenuCategoryId>>([]);
    const [addModifierGroupsDialogState, setAddModifierGroupsDialogState] = useState({ open: false });
    const [selectMenuCategoriesDialogState, setSelectMenuCategoriesDialogState] = useState({ open: false });
    const [openAddTaxDialog, setOpenAddTaxDialog] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const adminUser = useSelector((state) => state.authentication.adminUser);
    const supportUser = useSelector((state) => state.authentication.supportUser);
    const operationsUser = useSelector((state) => state.authentication.operationsUser);
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);
    const inventoryEnabled = useSelector((state) => state.app.restaurant?.inventoryEnabled);
    const restaurantTaxManagementEnabled = useSelector((state) => state.app.restaurant?.restaurantTaxManagementEnabled);
    const restaurantCountry = useSelector((state) => state.app.restaurant?.country);

    const { modifierGroups } = useModifierGroups();
    const { menuCategories } = useMenuCategories();

    const imageUrl = form.watch('imageUrl');
    const isSoldByWeight = form.watch('isSoldByWeight');
    const isSupply = form.watch('isSupply');
    const isCommissionEnabled = useWatch<boolean>({ name: 'commissionEnabled', control: form.control }) ?? false;
    const isProduct = useWatch({ name: 'isProduct', control: form.control, defaultValue: !!isProductSelected });
    const isModifier = useWatch({ name: 'isModifier', control: form.control, defaultValue: !isProductSelected });
    const restaurantTaxIds = useWatch({ name: 'restaurantTaxIds', control: form.control });
    const salesUnit = form.watch('salesUnit');

    const hasModifierGroupIds = selectedModifierGroupIds.length > 0;
    const hasMenuCategories = selectedMenuCategoryIds.length > 0;
    const salesUnitByWeight = [UnitsOfMeasurement.GRAM, UnitsOfMeasurement.KILOGRAM, UnitsOfMeasurement.MILLILITER, UnitsOfMeasurement.LITRE, UnitsOfMeasurement.CURRENCY];
    const hasProductoOrModifierSelected = isProduct || isModifier;

    const handleSubmit = async (form: any) => {
        setLoading(true);

        const response = await createMenuItemApi({
            restaurantId,
            modifierGroupIds: selectedModifierGroupIds,
            menuCategoryIds: form.isProduct ? selectedMenuCategoryIds : [],
            recipeId: form.recipeId,
            externalProductId: form.externalProductId,
            name: form.name,
            description: form.description,
            contains: form.contains,
            //Todo: Remove remove conditional when it is safe
            price: isModifier && !isProduct ? form.price ?? form.modifierPrice : form.price,
            modifierPrice: form.modifierPrice,
            promoText: form.promoText,
            promoPrice: form.promoPrice,
            kitchenPrice: form.kitchenPrice,
            size: MenuItemSizes.MEDIUM,
            imageUrl: form.imageUrl,
            color: form.color,
            printerNames: form.printerNames,
            isModifier: form.isModifier,
            isProduct: form.isProduct,
            isSoldByWeight: form.isSoldByWeight,
            salesUnit: form.salesUnit,
            currency: form.currency,
            minimumSalesQuantity: form.minimumSalesQuantity,
            isSupply: form.isSupply,
            supplyCategoryId: form.supplyCategoryId,
            commissionEnabled: form.commissionEnabled,
            restaurantTaxIds: isUruguay(restaurantCountry) ? (form.restaurantTaxId ? [form.restaurantTaxId] : []) : form.restaurantTaxIds,
            commissionPercentage: form.commissionPercentage,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
    };

    const handleRemoveModifierGroup = (modifierGroupId: ModifierGroupId) => {
        const newModifierGroups = selectedModifierGroupIds.filter((selectedModifierGroupId) => selectedModifierGroupId !== modifierGroupId);
        setSelectedModifierGroupIds(newModifierGroups);
    };

    const handleDeleteMenuCategory = (menuCategoryId: MenuCategoryId) => {
        const newMenuCategoryIds = selectedMenuCategoryIds.filter((selectedMenuCategoryId) => selectedMenuCategoryId !== menuCategoryId);
        setSelectedMenuCategoryIds(newMenuCategoryIds);
    };

    const openAddModifierGroupDialog = () => setAddModifierGroupsDialogState({ open: true });

    const closeAddModifierGroupDialog = () => setAddModifierGroupsDialogState({ open: false });

    const openSelectMenuCategoriesDialog = () => setSelectMenuCategoriesDialogState({ open: true });

    const closeSelectMenuCategoriesDialog = () => setSelectMenuCategoriesDialogState({ open: false });

    const handleSelectModifierGroups = (modifierGroupIds: any) => setSelectedModifierGroupIds(modifierGroupIds);

    return (
        <>
            <SelectModifierGroupsDialog open={addModifierGroupsDialogState.open} onClose={closeAddModifierGroupDialog} onSuccess={handleSelectModifierGroups} />
            <SelectMenuCategoriesDialog
                open={selectMenuCategoriesDialogState.open}
                onClose={closeSelectMenuCategoriesDialog}
                onSuccess={(menuCategoryIds: Array<MenuCategoryId>) => setSelectedMenuCategoryIds(menuCategoryIds)}
            />
            <CreateRestaurantTaxDialog open={openAddTaxDialog} onClose={() => setOpenAddTaxDialog(false)} onSuccess={() => setOpenAddTaxDialog(false)} />
            <Form onSubmit={handleSubmit} form={form} className={classes.form}>
                <h2 className={classes.title}>{translate('Create Menu Item')}</h2>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormImageUpload name={'imageUrl'} label={translate('Image')} disabled={loading} />
                    </Grid>
                    {!imageUrl && (
                        <Grid item xs={12}>
                            <FormColorButtons name='color' label={translate('Color')} disabled={loading} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormTextField name='name' label={translate('Name')} tooltip={translate('This is the name of the product')} required disabled={loading} minLength={3} maxLength={40} />
                    </Grid>
                    <Grid item xs={6}>
                        <FormCheckbox name='isProduct' label={translate('Is it a product?')} defaultValue={true} disabled={loading} required={hasProductoOrModifierSelected} />
                    </Grid>
                    <Grid item xs={6}>
                        <FormCheckbox name='isModifier' label={translate('Is it a modifier?')} disabled={loading} required={hasProductoOrModifierSelected} />
                    </Grid>
                    {isProduct && (
                        <Grid item xs={12} md={isModifier ? 6 : 12}>
                            <FormCurrencyNumberStringField name='price' label={translate('Price')} required disabled={loading} classes={{ input: classes.inputSpacing }} />
                        </Grid>
                    )}
                    {isModifier && (
                        <Grid item xs={12} md={6}>
                            <FormCurrencyNumberStringField name='modifierPrice' label={translate('Modifier price')} required disabled={loading} />
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <FormCheckbox name='isSoldByWeight' label={translate('Is it sold by weight?')} required disabled={loading} minLength={3} maxLength={40} />
                        {isSoldByWeight && (
                            <Grid item xs={12} md={6} className={classes.inputSpacing}>
                                <FormUnitOfMeasurementSelect filterByUnits={salesUnitByWeight} name='salesUnit' label={translate('Unit of sale')} required={isSoldByWeight} disabled={loading} />
                            </Grid>
                        )}
                        {salesUnit === UnitsOfMeasurement.CURRENCY && (
                            <Grid item xs={12} md={6} className={classes.inputSpacing}>
                                <FormCurrencySelect name={'currency'} label={translate('Currency')} required />
                            </Grid>
                        )}
                        {isSoldByWeight && (
                            <Grid item xs={12} md={6} className={classes.inputSpacing}>
                                <FormNumberStringField name='minimumSalesQuantity' label={translate('Minimum quantity to sell')} disabled={loading} />
                            </Grid>
                        )}
                        {inventoryEnabled && (
                            <Grid item xs={12}>
                                <FormCheckbox name='isSupply' label={translate('Is it also a supply?')} disabled={loading} />
                            </Grid>
                        )}
                        {isSupply && inventoryEnabled && (
                            <Grid item xs={12}>
                                <FormSupplyCategorySelect name='supplyCategoryId' label={translate('Category')} required={isSupply && inventoryEnabled} />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <FormCurrencyNumberStringField name='promoPrice' label={translate('Promo Price')} disabled={loading} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <FormTextField name='promoText' label={translate('Promo Text')} disabled={loading} />
                    </Grid>
                    {(adminUser || supportUser || operationsUser) && (
                        <Grid item xs={12} md={8}>
                            <FormTextField name='realKitchenPrice' label={translate('Real Kitchen Price')} disabled={loading} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormTextField name='description' label={translate('Description')} classes={{ input: classes.inputDescription }} multiline disabled={loading} />
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.label}>
                            {translate('Modifier Groups')} <Tooltip text={translate('This menuItem will contain this modifier groups')} />
                        </div>
                        {hasModifierGroupIds && (
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div className={classes.modifierGroups}>
                                    {selectedModifierGroupIds.map((modifierGroupId) => {
                                        const modifierGroup = modifierGroups.find((modifierGroup) => modifierGroup.modifierGroupId === modifierGroupId);
                                        if (!modifierGroup) return null;

                                        return (
                                            <div className={classes.modifierGroup} key={modifierGroupId}>
                                                <p style={{ margin: 0 }}>{modifierGroup.name}</p>
                                                <div onClick={() => handleRemoveModifierGroup(modifierGroupId)} style={{ display: 'grid', placeItems: 'center', cursor: 'pointer' }}>
                                                    <DeleteRoundedIcon />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <Button icon onClick={openAddModifierGroupDialog}>
                                    <AddRoundedIcon />
                                </Button>
                            </div>
                        )}
                        {!hasModifierGroupIds && (
                            <Button outlined onClick={openAddModifierGroupDialog}>
                                {translate('Select modifier groups')}
                            </Button>
                        )}
                    </Grid>
                    {isProduct && (
                        <Grid item xs={12}>
                            <div className={classes.label}>
                                {translate('Categories')} <Tooltip text={translate('This item will be added to the next categories')} />
                            </div>
                            {hasMenuCategories && (
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                    <div className={classes.modifierGroups}>
                                        {selectedMenuCategoryIds.map((menuCategoryId) => {
                                            const menuCategory = menuCategories.find((menuCategory) => menuCategory.menuCategoryId === menuCategoryId);
                                            if (!menuCategory) return null;

                                            return (
                                                <div className={classes.modifierGroup} key={menuCategoryId}>
                                                    <p style={{ margin: 0 }}>{menuCategory.name}</p>
                                                    <div onClick={() => handleDeleteMenuCategory(menuCategoryId)} style={{ display: 'grid', placeItems: 'center', cursor: 'pointer' }}>
                                                        <DeleteRoundedIcon />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <Button icon onClick={openSelectMenuCategoriesDialog}>
                                        <AddRoundedIcon />
                                    </Button>
                                </div>
                            )}
                            {!hasMenuCategories && (
                                <Button outlined onClick={openSelectMenuCategoriesDialog}>
                                    {translate('Select categories')}
                                </Button>
                            )}
                        </Grid>
                    )}
                    {!isSupply && (
                        <Grid item xs={12}>
                            <FormRecipeAutocomplete name='recipeId' label={translate('Recipe')} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormTextField name='externalProductId' label={translate('External Product Id')} disabled={loading} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormCheckbox name='commissionEnabled' label={translate('Enable comissions')} defaultValue={false} disabled={loading} />
                    </Grid>
                    {isCommissionEnabled && (
                        <Grid item xs={12} md={6}>
                            <FormPercentNumberField name='commissionPercentage' disabled={loading} placeholder={translate('Commission')} />
                        </Grid>
                    )}
                    {newDeviceManagementEnabled && (
                        <Grid item xs={12} md={12}>
                            <FormPrinterNamesAutocomplete name='printerNames' label={translate('Select a Printer')} />
                        </Grid>
                    )}
                    {restaurantTaxManagementEnabled && (
                        <SecuredContent rolePermission={RolePermissions.TAXES_PAGE}>
                            <div className={classes.taxesContainer}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h2>{translate('Taxes')}</h2>
                                    <Button onClick={() => setOpenAddTaxDialog(true)}>{translate('Add')}</Button>
                                </div>

                                {isUruguay(restaurantCountry) ? (
                                    <FormRestaurantTaxAutocomplete name='restaurantTaxId' label={translate('Taxes')} />
                                ) : (
                                    <FormRestaurantTaxAutocompleteMultiple name='restaurantTaxIds' label={translate('Taxes')} />
                                )}
                            </div>
                        </SecuredContent>
                    )}

                    <div className={classes.buttonsContainer}>
                        {onClose && (
                            <Button onClick={onClose} secondary disabled={loading}>
                                {translate('Cancel')}
                            </Button>
                        )}
                        <Button type={'submit'} disabled={loading || (!isModifier && !isProduct)}>
                            {loading ? translate('Creating') : translate('Create')}
                        </Button>
                    </div>
                </Grid>
            </Form>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: theme.typography.bold,
        color: theme.palette.text.brand,
        textAlign: 'center',
    },
    subtitle: {
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
        color: '#0FA489',
        textAlign: 'center',
    },

    editMenuItemSection: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        margin: '0 auto',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    label: {
        fontSize: 14,
        color: theme.palette.text.primary,
        fontFamily: theme.typography.regular,
        whiteSpace: 'nowrap',
        margin: 0,
        marginBottom: 6,
        display: 'flex',
        alignItems: 'center',
        gap: 6,
    },
    buttonsContainer: {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 20,
        width: '100%',
        '& button': {
            width: 200,
        },
    },
    displayNone: {
        display: 'none',
    },
    modifierGroups: {
        display: 'flex',
        gap: 12,
        width: '100%',
        marginTop: 8,
    },
    modifierGroup: {
        backgroundColor: theme.palette.surface.secondary,
        padding: '4px 12px',
        color: theme.palette.text.secondary,
        borderRadius: 4,
        fontFamily: theme.typography.regular,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
        flexWrap: 'wrap',
    },
    inputDescription: {
        height: '15vh',
        alignItems: 'flex-start',
        overflowY: 'auto',
    },
    taxesContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        width: '100%',
        border: `1px solid #D9D9D9`,
        padding: 16,
        borderRadius: 4,
    },
    inputSpacing: {
        marginBottom: 12,
    },
}));

type Props = {
    onClose?: any;
    onSuccess?: any;
    isProductSelected?: boolean;
};
