/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import * as React from 'react';
import { RestaurantContext } from 'src/api/letseatmanager/getAppContextApi';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { posReducer } from 'src/reducers/posReducer';
import { useOpenRestaurant } from 'src/services/restaurant/useOpenRestaurant';
import { useUserRestaurantIds } from 'src/services/restaurant/useUserRestaurantIds';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantSelect(): React.ReactElement | null {
    const classes = useStyles();

    const userRestaurantIds = useUserRestaurantIds();

    const restaurant = useSelector((state) => state.app.restaurant);
    const restaurants = useSelector((state) => state.app.restaurants) ?? [];
    const currentOrders = useSelector((state) => state.app2.currentOrders) ?? [];

    const clearCashRegisterBusinessDay = useAction(posReducer.actions.clearCashRegisterBusinessDay);

    const { openRestaurant } = useOpenRestaurant();

    const newOrders = currentOrders.filter((order) => order.orderStatus === OrderStatuses.NEW);
    const filteredRestaurants = restaurants?.filter((restaurant: RestaurantContext) => userRestaurantIds?.includes(restaurant.restaurantId));

    const handleRestaurantsSelect = (e: any) => {
        clearCashRegisterBusinessDay();
        openRestaurant(e.target.value);
    };

    if (!restaurant.restaurantId) return null;

    return (
        <Select id='select' value={restaurant.restaurantId} onChange={handleRestaurantsSelect} fullWidth>
            {filteredRestaurants.map((restaurant) => {
                const newOrdersForRestaurant = newOrders.filter((order) => order.restaurant?.restaurantId === restaurant.restaurantId).length;
                return (
                    <MenuItem value={restaurant.restaurantId} className={classes.restaurantSelectItem} key={restaurant.restaurantId}>
                        <span className={classes.restaurantSelectItemSpan}>{restaurant.name}</span>
                        <Badge className={classes.restaurantSelectItemBadge} badgeContent={newOrdersForRestaurant} classes={{ badge: classes.badge }} />
                    </MenuItem>
                );
            })}
        </Select>
    );
}

const useStyles = makeStyles((theme) => ({
    restaurantSelectItem: {
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
    },
    restaurantSelectItemSpan: {
        width: '100%',
        fontFamily: theme.typography.regular,
    },
    restaurantSelectItemBadge: {
        marginLeft: '2vw',
    },
    badge: {
        backgroundColor: theme.palette.surface.brand,
        color: 'white',
    },
}));
