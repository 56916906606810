/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getByDayRestaurantStatisticsApi, SalesByHour, SalesByWeekDay } from 'src/api/letseatmanager/restaurantDashboard/getByDayRestaurantStatisticsApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { FilterMasterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterMasterReports';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { HeatMapDayReport } from 'src/scenes/letseatmanager/restaurantDashboard/salesPerDayReport/HeatMapDayReport';
import { SalesByHourGraph } from 'src/scenes/letseatmanager/restaurantDashboard/salesPerDayReport/SalesByHourGraph';
import { SalesByWeekGraph } from 'src/scenes/letseatmanager/restaurantDashboard/salesPerDayReport/SalesByWeekGraph';
import { RestaurantId } from 'src/types/Id';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function DayReport(): React.ReactElement {
    const classes = useStyles();

    const [heatMapReportData, setHeatMapReportData] = useState<any>();
    const [salesByHourReportData, setSalesByHourReportData] = useState<Array<SalesByHour>>([]);
    const [salesByWeekReportData, setSalesByWeekReportData] = useState<Array<SalesByWeekDay>>([]);
    const [hasInfoFound, setHasInfoFound] = useState(false);
    const [showAsMaster, setShowAsMaster] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const currentRestaurantId = useSelector((state) => state.app2.filterReportsState.currentRestaurantId);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, reports] = useLoadApi(
        getByDayRestaurantStatisticsApi,
        {
            report: showAsMaster ? 'asBrand' : 'perRestaurant',
            restaurantIds: brandOpened ? restaurantIds : [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period?.to)).toISOString(),
            salesType,
        },
        {
            dependencies: [period, showAsMaster, brandOpened, restaurantId, salesType],
        }
    );

    useEffect(() => {
        if (reports) {
            if (showAsMaster || !brandOpened) return handleUpdateByDayReports(reports[0]?.restaurantId);

            handleUpdateByDayReports(currentRestaurantId ?? reports[0]?.restaurantId);
        }
    }, [reports, currentRestaurantId]);

    const handleUpdateByDayReports = (newRestaurantId: RestaurantId) => {
        const currentRestaurantReportInfo = reports?.find((restaurant) => restaurant.restaurantId === newRestaurantId);

        if (!hasRestaurantReportInfo(currentRestaurantReportInfo)) {
            setHasInfoFound(false);
            return;
        }

        setHeatMapReportData({ heatMapData: currentRestaurantReportInfo?.ordersHeatMap, maxValueForHeatMap: currentRestaurantReportInfo?.maxValueForHeatMap ?? 0 });
        setSalesByHourReportData(currentRestaurantReportInfo?.salesByHour ?? []);
        setSalesByWeekReportData(currentRestaurantReportInfo?.salesByWeekDay ?? []);
        setHasInfoFound(true);
    };

    const hasRestaurantReportInfo = (currentRestaurant: typeof reports[number] | undefined) => currentRestaurant && currentRestaurant?.ordersHeatMap;

    const handleShowAsMaster = (value: any) => {
        setShowAsMaster(value);
    };

    return (
        <div>
            <FilterReports />
            <div className={classes.container}>
                <FilterMasterReports onChangeMaster={handleShowAsMaster} showAsMaster={showAsMaster} />
                {loading && <UpdatingContentProgress />}
                {hasInfoFound && (
                    <div>
                        <HeatMapDayReport loading={loading} heatMapReportData={heatMapReportData} />
                        <Grid container>
                            <Grid item md={6} className={classes.container}>
                                <SalesByHourGraph loading={loading} salesByHourReportData={salesByHourReportData} />
                            </Grid>
                            <Grid item sm={12} md={6} className={classes.container}>
                                <SalesByWeekGraph loading={loading} salesByWeekReportData={salesByWeekReportData} />
                            </Grid>
                        </Grid>
                    </div>
                )}
                {!hasInfoFound && !loading && <ReportSectionEmptyState />}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    graphContainer: {
        width: '100%',
        height: '55vh',
        marginBottom: '15%',
    },
    heatMapContainer: {
        width: '100%',
        height: '60vh',
    },
}));
