/**
 * @prettier
 */
export const BannerImageTypes = {
    RESTAURANT: 'RESTAURANT',
    GENERAL: 'GENERAL',
    CUSTOM: 'CUSTOM',
    PREVIEW: 'PREVIEW',
} as const;

export type BannerImageType = typeof BannerImageTypes[keyof typeof BannerImageTypes];
