/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SubscriptionHistoryId } from 'src/types/Id';

export async function changeSubscriptionHistoryPaymentStatusApi(request: changeSubscriptionHistoryPaymentStatusApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('subscriptionHistory/changeSubscriptionHistoryPaymentStatusApi', request);
}

export type changeSubscriptionHistoryPaymentStatusApiRequest = {
    subscriptionHistoryId: SubscriptionHistoryId;
    payedSubscription: boolean;
};
