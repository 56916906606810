/**
 * @prettier
 */
import { Collapse, List, makeStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NotificationsIcon from '@material-ui/icons/Notifications';
import * as React from 'react';
import { useState } from 'react';
import { AppMenuLink } from 'src/components/app/appMenu/AppMenuLink';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { GearIcon } from 'src/icons/GearIcon';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantConfigurationAppMenu(): React.ReactElement | null {
    const classes = useStyles();

    const [showRestaurantConfigurationAppMenu, setShowRestaurantConfigurationAppMenu] = useState(false);

    const brandOpened = useSelector((state) => state.app.brandOpened);
    const businessOpened = useSelector((state) => state.app.businessOpened);
    const alertStoresClosed = useSelector((state) => state.app2.alertStoresClosed);
    const restaurantHasSubscription = useSelector((state) => !!state.app.restaurant?.subscriptionId);
    const userManagementEnabled = useSelector((state) => state.app.restaurant?.userManagementEnabled);
    const deliveryZonesEnabled = useSelector((state) => state.app.restaurant?.deliveryZonesEnabled);
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);
    const restaurantTaxManagementEnabled = useSelector((state) => state.app.restaurant?.restaurantTaxManagementEnabled);

    if (brandOpened || businessOpened) return null;

    return (
        <>
            <ListItem button onClick={() => setShowRestaurantConfigurationAppMenu(!showRestaurantConfigurationAppMenu)} className={classes.listItem}>
                <ListItemIcon>
                    <GearIcon className={classes.icon} title={translate('Settings')} />
                </ListItemIcon>
                <ListItemText classes={{ root: classes.listItemText }} primary={translate('Configuration')} />
                <ListItemIcon>{showRestaurantConfigurationAppMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</ListItemIcon>
            </ListItem>
            <Collapse in={showRestaurantConfigurationAppMenu} data-testid='restaurantAppMenu.configurationLink'>
                <List>
                    {restaurantHasSubscription && (
                        <SecuredContent rolePermission={RolePermissions.SUBSCRIPTION_PAGE}>
                            <AppMenuLink
                                className={classes.menuLinkOption}
                                routePath={RoutePaths.SUBSCRIPTION}
                                text={translate('Subscription')}
                                data-testid='restaurantAppMenu.configuration.subscriptionLink'
                            />
                        </SecuredContent>
                    )}
                    <SecuredContent rolePermission={RolePermissions.RESTAURANT_PAGE}>
                        <AppMenuLink
                            className={classes.menuLinkOption}
                            routePath={RoutePaths.THE_RESTAURANT}
                            text={translate('The Restaurant')}
                            data-testid='restaurantAppMenu.configuration.theRestaurantLink'
                        />
                    </SecuredContent>
                    <SecuredContent rolePermission={RolePermissions.TICKET_COMMAND_PAGE}>
                        <AppMenuLink
                            className={classes.menuLinkOption}
                            routePath={RoutePaths.CUSTOMIZE_PRINTS}
                            text={translate('Prints')}
                            data-testid='restaurantAppMenu.configuration.ticket_and_commandLink'
                        />
                    </SecuredContent>
                    <SecuredContent rolePermission={RolePermissions.OPENING_HOURS_PAGE}>
                        <AppMenuLink
                            className={classes.menuLinkOption}
                            routePath={RoutePaths.OPENING_HOURS}
                            text={translate('Opening Hours')}
                            data-testid='restaurantAppMenu.configuration.openingHoursLink'
                        />
                    </SecuredContent>
                    {userManagementEnabled && (
                        <SecuredContent rolePermission={RolePermissions.USER_MANAGEMENT_PAGE}>
                            <AppMenuLink
                                className={classes.menuLinkOption}
                                routePath={RoutePaths.USER_MANAGEMENT}
                                text={translate('User Management')}
                                data-testid='restaurantAppMenu.configuration.userManagementLink'
                            />
                        </SecuredContent>
                    )}

                    {newDeviceManagementEnabled && (
                        <SecuredContent rolePermission={RolePermissions.DEVICES_PAGE}>
                            <AppMenuLink className={classes.menuLinkOption} routePath={RoutePaths.DEVICES} text={translate('Devices')} data-testid='restaurantAppMenu.configuration.devicesLink' />
                        </SecuredContent>
                    )}

                    {!newDeviceManagementEnabled && (
                        <SecuredContent rolePermission={RolePermissions.PRINTERS_PAGE}>
                            <AppMenuLink className={classes.menuLinkOption} routePath={RoutePaths.PRINTERS} text={translate('Printers')} data-testid='restaurantAppMenu.configuration.printersLink' />
                        </SecuredContent>
                    )}

                    {deliveryZonesEnabled && (
                        <SecuredContent rolePermission={RolePermissions.DELIVERY_ZONES_PAGE}>
                            <AppMenuLink
                                className={classes.menuLinkOption}
                                routePath={RoutePaths.DELIVERY_ZONES}
                                text={translate('Delivery Zones')}
                                data-testid='restaurantAppMenu.configuration.deliveryZonesLink'
                            />
                        </SecuredContent>
                    )}
                    <SecuredContent rolePermission={RolePermissions.INTEGRATIONS_PAGE}>
                        <AppMenuLink
                            className={classes.menuLinkOption}
                            routePath={RoutePaths.INTEGRATIONS}
                            text={translate('Integrations')}
                            data-testid='restaurantAppMenu.configuration.integrationsLink'
                        >
                            {alertStoresClosed && (
                                <ListItemIcon>
                                    <NotificationsIcon style={{ width: 24, height: 24, color: '#fa6684' }} />
                                </ListItemIcon>
                            )}
                        </AppMenuLink>
                    </SecuredContent>
                    {restaurantTaxManagementEnabled && (
                        <SecuredContent rolePermission={RolePermissions.TAXES_PAGE}>
                            <AppMenuLink
                                className={classes.menuLinkOption}
                                routePath={RoutePaths.TAXES}
                                text={translate('Taxes')}
                                data-testid='restaurantAppMenu.configuration.taxesLink'
                            ></AppMenuLink>
                        </SecuredContent>
                    )}
                </List>
            </Collapse>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    menuLinkOption: {
        paddingLeft: 30,
    },
    listItem: {
        borderRadius: 6,
        '&:hover': {
            backgroundColor: theme.palette.surface.tertiary,
        },
    },
    listItemText: {
        '& span': {
            fontFamily: theme.typography.regular,
        },
    },
    icon: {
        color: theme.palette.icons.brand,
        width: 30,
        height: 30,
    },
}));
