/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormTextField } from 'src/components/form/FormTextField';
import { Spacing } from 'src/components/spacing/Spacing';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { RestaurantVm } from 'src/types/RestaurantsVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { normalizeToUsername } from 'src/utils/restaurant/normalizeToUsername';

export function RegisterRestaurantDialog({ open, restaurants, onClose, onRestaurantRegistered }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;
    const [registrationResult, setRegistrationResult] = useState<{ username: string; password: string } | undefined>(undefined);

    const restaurantName = form.watch('restaurantName');
    const existingRestaurant = restaurantName ? restaurants?.find((restaurant: RestaurantVm) => normalizeToUsername(restaurant.name) === normalizeToUsername(restaurantName)) : undefined;

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        const response = await letseatmanagerApiDeprecated.admin.registerRestaurant({
            restaurantName: form.restaurantName,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setRegistrationResult({
            username: response.data.username,
            password: response.data.password,
        });
        onRestaurantRegistered();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Register Restaurant').toUpperCase()}</DialogTitle>
                <DialogContent>
                    {!registrationResult && <FormContent existingRestaurant={existingRestaurant} />}
                    {registrationResult && (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                Username: {registrationResult.username}
                            </Grid>
                            <Grid item xs={12}>
                                Password: {registrationResult.password}
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    {!registrationResult && (
                        <>
                            <Button onClick={handleClose} disabled={isSubmitting}>
                                {translate('Cancel').toUpperCase()}
                            </Button>
                            <Button color='primary' type='submit' disabled={isSubmitting || !!existingRestaurant}>
                                {isSubmitting ? translate('Registering').toUpperCase() : translate('Register').toUpperCase()}
                            </Button>
                        </>
                    )}
                    {registrationResult && (
                        <Button onClick={handleClose} disabled={isSubmitting}>
                            {translate('Done')}
                        </Button>
                    )}
                </DialogActions>
            </Form>
        </Dialog>
    );
}

function FormContent({ existingRestaurant }: { existingRestaurant?: RestaurantVm }) {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormTextField name='restaurantName' label={translate('Restaurant Name')} required />
                </Grid>
                {existingRestaurant && (
                    <Grid container justify='space-around'>
                        <Grid item>
                            <Spacing units={8} />
                            <Typography display='block' color='error'>
                                {translate('Restaurant with username @username already exists', { username: normalizeToUsername(existingRestaurant.name) })}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <FormFixErrorHint />
        </>
    );
}

type Props = {
    open: boolean;
    restaurants?: Array<RestaurantVm>;
    onClose: any;
    onRestaurantRegistered: any;
};
