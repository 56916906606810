/**
 * @prettier
 */
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@pidedirecto/ui';
import { TrashCanIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { changeMenuItemApi } from 'src/api/letseatmanager/menuItem/changeMenuItemApi';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import { useLoadMenuItems } from 'src/services/menuItem/useLoadMenuItems';
import { useMenuItems } from 'src/services/menuItem/useMenuItems';
import { ModifierGroupId, type MenuItemId } from 'src/types/Id';
import { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import type { ModifierGroupVm } from 'src/types/ModifierGroupVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { classNames } from 'src/utils/react/classNames';

export function ModifierGroupInMenuItem({ modifierGroup, menuItemId }: Props): React.ReactElement | null {
    const classes = useStyles();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();

    const [loading, setLoading] = useState(false);

    const { menuItems } = useMenuItems();
    const refreshMenuItems = useLoadMenuItems();

    const removeModifierGroupFromMenuItem = async () => {
        const menuItem = menuItems?.find((menuItem: ManagerMenuItemVm) => menuItem.menuItemId === menuItemId);
        if (!menuItem) return null;

        setLoading(true);
        const response = await changeMenuItemApi({
            ...menuItem,
            modifierGroupIds: menuItem?.modifierGroupIds?.filter((modifierGroupId: ModifierGroupId) => modifierGroupId !== modifierGroup.modifierGroupId) ?? [],
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        refreshMenuItems();
    };

    return (
        <Grid className={classNames(classes.modifierGroup, classes.isFullWidth, classes.spaceBetween)}>
            <div className={classes.rightContainer}>
                <p className={classes.semiBold}>{modifierGroup.name}</p>
            </div>
            <div style={{ display: 'flex' }}>
                <Button variant='icon' disabled={loading || isMenuEditionDisabled} onClick={removeModifierGroupFromMenuItem}>
                    <TrashCanIcon color={'#E37878'} title={'trashcan'} size={20} />
                </Button>
            </div>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    modifierGroup: {
        listStyleType: 'none',
        display: 'flex',
        alignItems: 'center',
    },
    rightContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
    },
    semiBold: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.text.primary,
        fontSize: 16,
        margin: 0,
    },
    isFullWidth: {
        width: '100%',
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

type Props = {
    modifierGroup: ModifierGroupVm;
    menuItemId: MenuItemId;
};
