/**
 * @prettier
 */
import * as React from 'react';
import { MenuItems } from 'src/scenes/letseatmanager/menu/menuItem/MenuItems';
import { MenuItemsEmptyState } from 'src/scenes/letseatmanager/menu/menuItem/MenuItemsEmptyState';
import { useMenuItems } from 'src/services/menuItem/useMenuItems';
import { useSelector } from 'src/utils/react/useSelector';

export function MenuItemsSection(): React.ReactElement {
    const { menuItems } = useMenuItems();

    const menuItemsLoaded = useSelector((state) => state.menu.menuItemsLoaded);

    const thereIsMenuItems = menuItems && menuItems?.length > 0;

    if (!thereIsMenuItems && menuItemsLoaded) {
        return <MenuItemsEmptyState />;
    }

    return <MenuItems />;
}
