/**
 * @prettier
 */
import { useSelector } from 'src/utils/react/useSelector';

export function useHasMultiplePosCurrencies(): boolean {
    const selectedCurrency = useSelector((state) => state.pos.selectedCurrency);
    const acceptMultipleCurrenciesPaymentEnabled = useSelector((state) => state.pos.context?.acceptMultipleCurrenciesPaymentEnabled);

    return selectedCurrency && acceptMultipleCurrenciesPaymentEnabled;
}
