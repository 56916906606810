/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { KardexReportVm } from 'src/types/KardexReportVm';

export async function getKardexReportApi(request: GetKardexReportApiRequest): ApiSauceResponse<Array<KardexReportVm>> {
    return pidedirectoReportsMethod('/getKardexReportApi', request);
}

type GetKardexReportApiRequest = {
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
};
