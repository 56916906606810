/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import { EditIcon, TrashCanIcon } from '@pidedirecto/ui/icons';
import { MUIDataTableColumn } from 'mui-datatables';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { findRecipesByRestaurantIdApi } from 'src/api/letseatmanager/recipe/findRecipesByRestaurantIdApi';
import { removeRecipeApi } from 'src/api/letseatmanager/recipe/removeRecipeApi';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ChangeRecipeDialog } from 'src/scenes/letseatmanager/inventory/recipe/ChangeRecipeDialog';
import { CreateRecipeDialog } from 'src/scenes/letseatmanager/inventory/recipe/CreateRecipeDialog';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { RecipeId } from 'src/types/Id';
import { RecipeVm } from 'src/types/RecipeVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function RecipesTable(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [recipes, setRecipes] = useState<Array<RecipeVm>>([]);
    const [loading, setLoading] = useState(false);
    const [changeRecipeDialogState, setChangeRecipeDialogState] = useState<{ open: boolean; recipeId: RecipeId | undefined }>({ open: false, recipeId: undefined });
    const [createRecipeDialogState, setCreateRecipeDialogState] = useState({ open: false });
    const inventoryLinkedToRestaurantId = useSelector((state) => state.app.restaurant?.inventoryLinkedToRestaurantId);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    useEffect(() => {
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await findRecipesByRestaurantIdApi({ restaurantId });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setRecipes(response.data.recipes);
    };

    const removeRecipe = async (recipeId: RecipeId) => {
        const recipe = recipes.find((recipe) => recipe.recipeId === recipeId);
        const remove = window.confirm(translate('Are you sure you want to remove the recipe @recipeName?', { recipeName: recipe?.name }));

        if (!remove) return;

        const response = await removeRecipeApi({
            recipeId,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        load();
    };

    const columns: Array<MUIDataTableColumn> = [
        {
            name: 'recipeId',
            label: '',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'name',
            label: translate('Name'),
            options: {
                filter: false,
            },
        },
        {
            name: 'supplies',
            label: translate('Supplies'),
            options: {
                filter: false,
            },
        },

        {
            name: 'estimatedPrice',
            label: translate('Estimated'),
            options: {
                filter: false,
            },
        },
    ];

    if (!inventoryLinkedToRestaurantId) {
        columns.push(
            {
                name: 'edit',
                label: ' ',
                options: {
                    filter: false,
                    customBodyRender: (recipeId: RecipeId) => (
                        <Button variant='icon' onClick={() => setChangeRecipeDialogState({ open: true, recipeId })}>
                            <EditIcon size={20} />
                        </Button>
                    ),
                },
            },
            {
                name: 'delete',
                label: ' ',
                options: {
                    filter: false,
                    customBodyRender: (recipeId) => (
                        <Button variant='icon' onClick={() => removeRecipe(recipeId)}>
                            <TrashCanIcon size={20} />
                        </Button>
                    ),
                },
            }
        );
    }

    return (
        <div className={classes.container}>
            <CreateRecipeDialog open={createRecipeDialogState.open} onClose={() => setCreateRecipeDialogState({ open: false })} onSuccess={load} />
            <ChangeRecipeDialog
                open={changeRecipeDialogState.open}
                recipeId={changeRecipeDialogState.recipeId}
                onClose={() => setChangeRecipeDialogState({ open: false, recipeId: undefined })}
                onChangeRecipe={load}
            />
            <UpdatingContentProgress loading={loading} bottom />
            <Table
                data={recipes?.map((recipe: RecipeVm) => {
                    return {
                        recipeId: recipe.recipeId,
                        name: recipe.name,
                        estimatedPrice: formatAsCurrencyNumber(recipe.estimatedPrice),
                        supplies: recipe.ingredients.length,
                        edit: recipe.recipeId,
                        delete: recipe.recipeId,
                    };
                })}
                columns={columns}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    filter: false,
                    print: false,
                    viewColumns: false,
                    customToolbar: () => (
                        <>
                            {!inventoryLinkedToRestaurantId && <CreateToolbarButton onClick={() => setCreateRecipeDialogState({ open: true })} titleAccess='Create recipe' />}
                            <RefreshToolbarButton onClick={load} />
                        </>
                    ),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));
