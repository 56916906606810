/**
 * @prettier
 */
import * as React from 'react';
import { Select } from 'src/components/Select';
import { SystemUserType, SystemUserTypes } from 'src/constants/SystemUserType';
import { translate } from 'src/i18n/translate';

export function LogEventUserTypesSelect({ labelAsPlaceholder, multiselectable, onChange }: Props): React.ReactElement {
    const options = Object.values(SystemUserTypes).map((systemUserType: any) => ({ label: translate(systemUserType), value: systemUserType }));

    return (
        <Select
            label={translate('User Types')}
            name={'systemUserTypes'}
            labelAsPlaceholder={labelAsPlaceholder}
            multiselectable={multiselectable}
            options={options}
            onChange={(systemUserTypes: Array<SystemUserType>) => onChange(systemUserTypes)}
        />
    );
}

type Props = {
    labelAsPlaceholder?: boolean;
    multiselectable?: boolean;
    onChange: (systemUserTypes: Array<SystemUserType>) => any;
};
