/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CashRegisterDepositReason } from 'src/constants/CashRegisterDepositReason';
import { CashRegisterTransactionVm } from 'src/types/CashRegisterTransactionVm';
import type { CashRegisterCustomReason, CashRegisterId, ManagerUserId } from 'src/types/Id';

export async function depositCashRegisterApi(request: DepositCashRegisterApiRequest): ApiSauceResponse<CashRegisterTransactionVm> {
    return letseatmanagerApiMethod('pos/depositCashRegisterApi', request);
}

export type DepositCashRegisterApiRequest = {
    cashRegisterId?: CashRegisterId;
    managerUserId?: ManagerUserId;
    amount: string;
    reason: CashRegisterDepositReason | CashRegisterCustomReason;
    comment?: string;
    createdAt?: Date;
};
