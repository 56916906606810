/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { FormMultiselectableAutocomplete } from '@pidedirecto/ui/form';
import * as React from 'react';
import { Text } from 'src/components/Text';
import { RestaurantPaymentMethodApp, RestaurantPaymentMethodApps } from 'src/constants/RestaurantPaymentMethodApps';
import { translate } from 'src/i18n/translate';

export function FormChannelsMultiselectableAutocomplete({ name, label, defaultValue, disabled, exclude }: Props): React.ReactElement {
    const classes = useStyles();
    const restaurantChannels: Array<RestaurantPaymentMethodApp> = Object.values(RestaurantPaymentMethodApps);

    const filteredRestaurantChannels = restaurantChannels.filter((restaurantChannel) => !exclude?.includes(restaurantChannel));

    const restaurantChannelOptions = filteredRestaurantChannels.map((RestaurantPaymentMethodApp: RestaurantPaymentMethodApp) => ({
        label: translate(`RestaurantPaymentMethodApps.${RestaurantPaymentMethodApp}`),
        value: RestaurantPaymentMethodApp,
    }));

    return (
        <FormMultiselectableAutocomplete
            name={name}
            data={restaurantChannelOptions}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option.value}
            defaultValue={defaultValue}
            disabled={disabled}
            label={label}
            variant={'detailed'}
            helperText={translate('If left blank it will be all channels')}
            renderOption={(option) => (
                <div className={classes.containerOption}>
                    <Text>{option?.label}</Text>
                </div>
            )}
        />
    );
}

const useStyles = makeStyles(() => ({
    containerOption: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: Array<string>;
    exclude?: Array<RestaurantPaymentMethodApp>;
    disabled?: boolean;
};
