/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import * as React from 'react';
import { useRef } from 'react';
import { getFacturamaInvoiceUri } from 'src/api/letseatmanager/subscription/getFacturamaInvoiceUri';
import { sendInvoiceApi } from 'src/api/letseatmanager/subscription/sendInvoiceApi';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import type { PaymentRejectReason } from 'src/constants/PaymentRejectReason';
import type { PaymentStatus } from 'src/constants/PaymentStatus';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { PaymentId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { createFilteredOnDownloadFunctional } from 'src/utils/mui-datatables/createFilteredOnDownloadFunctional';
import { translatePaymentRejectReasonCompact } from 'src/utils/translate/translatePaymentRejectReasonCompact';
import { translatePaymentStatus } from 'src/utils/translate/translatePaymentStatus';

export function PaymentsTable({ payments = [], loading, title, onClickRefresh }: Props): React.ReactElement {
    const classes = useStyles();
    const table = useRef(null);
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const createDownload = async (payment: any, documentType: 'xml' | 'pdf') => {
        const invoiceData = await getFacturamaInvoiceUri({ paymentId: payment.paymentId, documentType });
        if (!invoiceData.ok) {
            alertKnownErrorOrSomethingWentWrong(invoiceData);
            return;
        }
        const downloadAction = document.createElement('a');
        downloadAction.href = invoiceData.data.invoiceUri;
        downloadAction.download = `${translate('Subscription')}_${payment.createdAt}.${documentType}`;
        document.body?.appendChild(downloadAction);
        downloadAction.click();
        document.body?.removeChild(downloadAction);
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <Table
                className={classes.table}
                ref={table}
                title={title || translate('Payments')}
                data={payments.map((payment) => ({
                    paymentId: payment.paymentId,
                    amount: formatAsCurrencyNumber(payment.amount),
                    paymentStatus: `${translatePaymentStatus(payment.paymentStatus)}${payment.paymentRejectReason ? `: ${translatePaymentRejectReasonCompact(payment.paymentRejectReason)}` : ''}`,
                    createdAt: formatDateTimeStringReadable(payment.createdAt),
                    sendInvoice: (
                        <Button
                            size='small'
                            type='submit'
                            onClick={async () => {
                                await sendInvoiceApi({ paymentId: payment.paymentId });
                            }}
                        >
                            {' '}
                            {translate('Resend')}{' '}
                        </Button>
                    ),
                    downloadInvoicePDF: (
                        <Button
                            size='small'
                            type='submit'
                            onClick={async () => {
                                await createDownload(payment, 'pdf');
                            }}
                        >
                            {' '}
                            {translate('Download')}{' '}
                        </Button>
                    ),
                    downloadInvoiceXML: (
                        <Button
                            size='small'
                            type='submit'
                            onClick={async () => {
                                await createDownload(payment, 'xml');
                            }}
                        >
                            {' '}
                            {translate('Download')}{' '}
                        </Button>
                    ),
                }))}
                columns={[
                    {
                        name: 'paymentId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'amount',
                        label: translate('Total'),
                        options: {
                            filter: true,
                        },
                    },
                    {
                        name: 'paymentStatus',
                        label: translate('Status'),
                        options: {
                            filter: true,
                        },
                    },
                    {
                        name: 'createdAt',
                        label: translate('Date'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'sendInvoice',
                        label: translate('Resend Invoice'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'downloadInvoicePDF',
                        label: translate('Download Invoice PDF'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'downloadInvoiceXML',
                        label: translate('Download Invoice XML'),
                        options: {
                            filter: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={onClickRefresh} />
                        </>
                    ),
                    onDownload: createFilteredOnDownloadFunctional(table),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        width: '90%',
        margin: '0 auto',
    },
    table: {
        whiteSpace: 'nowrap',
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

type Props = {
    payments: Array<{
        paymentId: PaymentId;
        amount: string;
        paymentStatus: PaymentStatus;
        paymentRejectReason?: PaymentRejectReason;
        createdAt: Date;
    }>;
    loading: boolean;
    title?: string;
    onClickRefresh: any;
};
