/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { OrderCardContactSupportInfo } from 'src/components/orderCard/OrderCardContactSupportInfo';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { isKioskApp } from 'src/utils/app/isKioskApp';
import { isExternalOrder } from 'src/utils/order/isExternalOrder';

export function OrderCardIdsInfo({ order }: Props): React.ReactElement | null {
    const classes = useStyles();

    const orderHasIdToShow = () => {
        return !!order.externalDeliveryProvider || isKioskApp(order.app) || isExternalOrder(order) || !!order.deliverySecurityCode;
    };

    if (!orderHasIdToShow()) return null;

    return (
        <div style={{ width: '100%' }}>
            {order.rappiOrderId && (
                <p className={classes.id}>
                    {translate('Rappi OrderId:')} {order.rappiOrderId}
                </p>
            )}
            {order.didiFoodOrderId && (
                <p className={classes.id}>
                    {translate('Didi Food OrderId:')} {order.didiFoodOrderId}
                </p>
            )}
            {order.uberEatsOrderId && (
                <p className={classes.id}>
                    {translate('Uber Eats OrderId:')} {order.uberEatsOrderId}
                </p>
            )}
            {order.pedidosYaShortOrderId && (
                <p className={classes.id}>
                    {translate('PedidosYa Order Code Id:')} {order.pedidosYaShortOrderId}
                </p>
            )}
            {isKioskApp(order.app) && (
                <p className={classes.id}>
                    {translate('Kiosk Id:')} #{order.dailyOrderId}
                </p>
            )}
            {order.uberDirectDeliveryOrderId && <p className={classes.id}>UberId:{order.uberDirectDeliveryOrderId?.slice(-6)}</p>}
            {order.rappiCargoDeliveryId && <p className={classes.id}>RappiId:{order.rappiCargoDeliveryId?.slice(-6)}</p>}
            {order.uberDaasDeliveryOrderId && <p className={classes.id}>UberDaasId: {order.uberDaasDeliveryOrderId?.slice(-5)}</p>}
            {order.deliverySecurityCode && (
                <div className={classes.spaceBetween}>
                    <div className={classes.text}>{translate('Delivery security code:')}</div>
                    <div className={classes.text}>{order.deliverySecurityCode}</div>
                </div>
            )}
            <OrderCardContactSupportInfo order={order} />
            <div className={classes.separator}></div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    separator: {
        height: '0px',
        width: '100%',
        border: '1px solid #F3F3F3',
        margin: '12px 0px',
    },

    id: {
        fontFamily: theme.typography.medium,
        color: '#6A7575',
        fontSize: 14,
        margin: 0,
        width: '100%',
        height: 'fit-content',
        textAlign: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#454A4A',
    },
    spaceBetween: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
}));

type Props = {
    order: OrderVm;
};
