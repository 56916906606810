/**
 * @prettier
 */
import { App } from 'src/constants/App';
import { isEcommerceApp } from 'src/utils/app/isEcommerceApp';
import { isLetsEatApp } from 'src/utils/app/isLetsEatApp';
import { isPideDirectoApp } from 'src/utils/app/isPideDirectoApp';

export function isOnlineApp(app: App | undefined): boolean {
    return isPideDirectoApp(app) || isLetsEatApp(app) || isEcommerceApp(app);
}
