/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { app2 } from 'src/app2';
import { Chip } from 'src/components/Chip';
import { FilterReportTypes } from 'src/constants/FilterReportType';
import { translate } from 'src/i18n/translate';
import { FilterPeriodText } from 'src/scenes/letseatmanager/restaurantDashboard/FilterPeriodText';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function FilterReports(): React.ReactElement {
    const classes = useStyles();

    const currentFilterReportType = useSelector((state) => state.app2.filterReportsState.filterReportType);

    const setFilterReportType = useAction(app2.actions.setFilterReportType);
    const setPeriodOfReports = useAction(app2.actions.setPeriodOfReports);
    const openDateSelectDialog = useAction(app2.actions.openDateSelectDialog);

    const handleChange = (filterReportType: 'DAY' | 'MONTH' | 'PERIOD' | 'WEEK' | 'YESTERDAY') => {
        setFilterReportType(filterReportType);
        if (filterReportType === FilterReportTypes.PERIOD) return;
        let fromPeriod = moment().startOf('day');
        if (filterReportType === FilterReportTypes.WEEK) fromPeriod = fromPeriod.subtract(7, 'days');
        if (filterReportType === FilterReportTypes.MONTH) fromPeriod = fromPeriod.subtract(30, 'days');
        if (filterReportType === FilterReportTypes.YESTERDAY) {
            fromPeriod = fromPeriod.subtract(1, 'days');
            setPeriodOfReports({
                from: fromPeriod.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                to: fromPeriod.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            });
            return;
        }

        setPeriodOfReports({
            from: fromPeriod.format('YYYY-MM-DD HH:mm:ss'),
            to: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        });
    };

    const handleOpenDialog = () => {
        openDateSelectDialog({
            open: true,
        });
    };

    const handleChangeFilterType = (newFilterType: 'DAY' | 'MONTH' | 'PERIOD' | 'WEEK' | 'YESTERDAY') => {
        if (newFilterType === FilterReportTypes.PERIOD) handleOpenDialog();
        handleChange(newFilterType);
    };

    return (
        <div>
            <div className={classes.container}>
                {Object.values(FilterReportTypes).map((filterReportType) => {
                    const isSelected = currentFilterReportType === filterReportType;

                    return (
                        <Chip key={filterReportType} classes={{ chip: classNames(classes.filterChip, { [classes.selected]: isSelected }) }} onClick={() => handleChangeFilterType(filterReportType)}>
                            {translate(`FilterReportTypes.${filterReportType}`)}
                        </Chip>
                    );
                })}
            </div>
            <FilterPeriodText />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: 'auto',
        marginBottom: 12,
        display: 'flex',
        flexWrap: 'wrap',
        gap: 12,
    },
    filterChip: {
        height: 32,
        fontSize: 12,
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: 4,
        padding: 12,
        transition: '300ms',
        '&:hover': {
            border: `1px solid ${theme.palette.border.brandContrast}`,
            color: theme.palette.surface.brandContrast,
            backgroundColor: 'transparent',
        },
    },
    selected: {
        border: `1px solid ${theme.palette.border.brandContrast}`,
        color: theme.palette.text.invert,
        borderRadius: 4,
        backgroundColor: theme.palette.surface.brandContrast,
    },
}));
