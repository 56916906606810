/**
 * @prettier
 */
export const ScreenSizes = {
    SMALL_SCREEN: 600,
    MEDIUM_SCREEN: 960,
    LARGE_SCREEN: 1280,
} as const;

export type ScreenSize = typeof ScreenSizes[keyof typeof ScreenSizes];
