/**
 * @prettier
 */
import { useEffect } from 'react';
import { wait } from 'src/utils/wait';

export function useTimeoutInterval(callback: () => Promise<any>, ms: number, deps?: Array<any>) {
    useEffect(() => {
        let cancelled = false;
        const asyncWrapper = async () => {
            if (cancelled) return;
            await callback();
            if (cancelled) return;
            await wait(ms);
            await asyncWrapper();
        };
        asyncWrapper();
        return () => {
            cancelled = true;
        };
    }, deps);
}
