/**
 * @prettier
 */
import { DriverArrivesAtStoreTime, DriverArrivesAtStoreTimes } from 'src/constants/DriverArrivesAtStoreTime';

export function getDriverArrivesAtStoreTimeColor(driverArrivesAtStoreTime: DriverArrivesAtStoreTime): string | undefined {
    switch (driverArrivesAtStoreTime) {
        case DriverArrivesAtStoreTimes.TIME_30_40_MIN:
            return 'rgb(245, 212, 66)';
        case DriverArrivesAtStoreTimes.TIME_40_50_MIN:
            return 'rgb(245, 135, 66)';
        case DriverArrivesAtStoreTimes.TIME_50_60_MIN:
            return 'red';
        default:
            return;
    }
}
