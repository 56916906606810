/**
 * @prettier
 */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getBrandApi } from 'src/api/letseatadmin/brand/getBrandApi';
import { removeBrandApi } from 'src/api/letseatadmin/brand/removeBrandApi';
import { Form } from 'src/components/form/Form';
import { FormDateTimePicker } from 'src/components/form/FormDateTimePicker';
import { FormRestaurantsAutocomplete } from 'src/components/form/FormRestaurantsAutocomplete';
import { FormTextField } from 'src/components/form/FormTextField';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ChangeBrandDialog } from 'src/scenes/letseatadmin/brand/ChangeBrandDialog';
import type { BrandVm } from 'src/types/BrandVm';
import type { BrandId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { requireValue } from 'src/utils/require/requireValue';

export function BrandDetails({ brandId, onChangeBrand }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const [loading, setLoading] = useState(false);
    const [changeBrandDialogState, setChangeBrandDialogState] = useState<{ open: boolean; brandId: BrandId | undefined }>({ open: false, brandId: undefined });

    useEffect(() => {
        if (brandId) load();
    }, [brandId]);

    const load = async () => {
        setLoading(true);
        const response = await getBrandApi({ brandId: requireValue(brandId) });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const brand = response.data;
        form.reset(brand);
        onChangeBrand && onChangeBrand(brand);
    };

    const removeBrand = async (brandId: BrandId) => {
        setLoading(true);
        const response = await removeBrandApi({ brandId });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            await load();
            return;
        }
        await load();
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <ChangeBrandDialog
                open={changeBrandDialogState.open}
                brandId={changeBrandDialogState.brandId}
                onClose={() => setChangeBrandDialogState({ open: false, brandId: undefined })}
                onChangeBrand={load}
            />
            <Card classes={{ root: classes.root }}>
                <CardHeader
                    title={
                        <Typography display='block' gutterBottom variant='h6'>
                            {translate('Brand Details')}
                        </Typography>
                    }
                    action={
                        <div>
                            <ChangeToolbarButton onClick={() => setChangeBrandDialogState({ open: true, brandId })} tooltip={translate('Change')} />
                            <RemoveToolbarButton
                                onClick={async () => {
                                    const remove = window.confirm('Are you sure you want to remove the brand?');
                                    if (remove) await removeBrand(brandId);
                                }}
                            />
                            <RefreshToolbarButton onClick={load} />
                        </div>
                    }
                />
                <CardContent>
                    <Form form={form} onSubmit={() => {}}>
                        <FormContent />
                    </Form>
                </CardContent>
            </Card>
        </div>
    );
}

function FormContent() {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <FormTextField name='brandName' label={translate('Brand Name')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormRestaurantsAutocomplete name='restaurantIds' label={translate('Restaurants')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormTextField name='comment' label={translate('Comment')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormDateTimePicker name='createdAt' label={translate('Created')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormDateTimePicker name='modifiedAt' label={translate('Modified')} disabled />
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
}));

type Props = {
    brandId: BrandId;
    onChangeBrand?: (arg1: BrandVm) => any;
};
