/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import UpdateIcon from '@material-ui/icons/Update';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { findSupportUserDetailsApi } from 'src/api/letseatadmin/supportUserDetails/findSupportUserDetailsApi';
import { app2 } from 'src/app2';
import { Input } from 'src/components/Input';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { openManagerUserPageInNewTab } from 'src/utils/window/openManagerUserPageInNewTab';

export function SupportPage(): React.ReactElement {
    const classes = useStyles();

    const [from, setFrom] = useState(moment().subtract('week').startOf('isoWeek').toDate());
    const [to, setTo] = useState(moment().subtract('week').endOf('isoWeek').toDate());

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Support'));
    }, []);

    const [loading, supportUserDetails, load] = useLoadApi(
        findSupportUserDetailsApi,
        {
            from: moment(from).format('YYYY-MM-DD'),
            to: moment(to).format('YYYY-MM-DD'),
        },
        { initialValue: [] }
    );

    const supportUserDetailsColumns = [
        {
            name: 'managerUserId',
            label: '',
            options: {
                display: 'excluded' as any,
                filter: false,
            },
        },
        {
            name: 'name',
            label: translate('Name'),
            options: {
                filter: false,
            },
        },
        {
            name: 'manualAssignments',
            label: translate('Manual Assignments'),
            options: {
                filter: false,
            },
        },
        {
            name: 'cancellations',
            label: translate('Cancellations'),
            options: {
                filter: false,
            },
        },
        {
            name: 'manualEarnings',
            label: translate('Manual Earnings'),
            options: {
                filter: false,
            },
        },
    ];

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Card style={{ padding: 24 }}>
                    <Grid container direction='row' spacing={3} alignItems='center'>
                        <Grid item>
                            <Input name={'from'} type={'date'} label={translate('From')} value={from} onChange={setFrom} maxDate={to} />
                        </Grid>
                        <Grid item>
                            <Input name={'to'} type={'date'} label={translate('To')} value={to} onChange={setTo} minDate={from} />
                        </Grid>
                        <Grid item direction='column' alignItems='center'>
                            <Button className={classes.refreshButton} disabled={loading} onClick={load as any} startIcon={<UpdateIcon />}>
                                {translate('REFRESH')}
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <UpdatingContentProgress loading={loading} />
                <MUIDataTable
                    /* @ts-ignore */
                    className={classes.table}
                    data={supportUserDetails.map((supportUserDetail) => ({
                        managerUserId: supportUserDetail.managerUserId,
                        name: supportUserDetail.username,
                        manualAssignments: supportUserDetail.manualAssignments,
                        cancellations: supportUserDetail.cancellations,
                        manualEarnings: supportUserDetail.manualEarnings,
                    }))}
                    columns={supportUserDetailsColumns}
                    options={{
                        responsive: 'standard',
                        tableBodyMaxHeight: '500px',
                        selectableRows: 'none',
                        filter: false,
                        filterType: 'checkbox',
                        rowsPerPage: 100,
                        onRowClick: (rowData: Array<string>) => {
                            const managerUserId = rowData[0] as any;
                            openManagerUserPageInNewTab(managerUserId);
                        },
                        // filterList: [[], [], [], [], ['MEXICO']],
                    }}
                />
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        display: 'none',
    },
    refreshButton: {
        width: '120%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontFamily: theme.typography.medium,
        textTransform: 'none',
        marginTop: 20,
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main}aa`,
        },
    },
}));

type FilterState = {
    from: Date;
    to: Date;
};
