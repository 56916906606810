/**
 * @prettier
 */
import { createActions, createReducer } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators }: any = createActions(
    {
        setLocale: ['locale'],
    },
    { prefix: 'LocaleReducer/' }
);

export const LocaleTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
    currentLocale: 'en',
} as const;

/* ------------- Reducers ------------- */
export const setLocale = (state: any, { locale }: any): any => {
    return {
        ...state,
        currentLocale: locale,
    };
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer: any = createReducer(INITIAL_STATE, {
    [Types.SET_LOCALE]: setLocale,
});
