/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import { CsvMenuItem } from 'src/utils/csv/convertCsvToImportedMenu';

export async function createMenuFromImportedMenuApi(request: CreateMenuFromImportedMenuApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menu/createMenuFromImportedMenuApi', request);
}

export type CreateMenuFromImportedMenuApiRequest = {
    restaurantId: RestaurantId;
    importedMenu: Array<CsvMenuItem>;
};
