/**
 * @prettier
 */
import { IconButton, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { createSupplyApi } from 'src/api/letseatmanager/supply/createSupplyApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormNumberStringField } from 'src/components/form/FormNumberStringField';
import { FormSupplierAutocomplete } from 'src/components/form/FormSupplierAutocomplete';
import { FormSupplyCategorySelect } from 'src/components/form/FormSupplyCategorySelect';
import { FormSupplyTypeSelect } from 'src/components/form/FormSupplyTypeSelect';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormUnitOfMeasurementSelect } from 'src/components/form/FormUnitOfMeasurementSelect';
import { SupplyType } from 'src/constants/SupplyType';
import { translate } from 'src/i18n/translate';
import { CompoundSupplyIngredients } from 'src/scenes/letseatmanager/inventory/supply/CompoundSupplyIngredients';
import { useNotification } from 'src/services/notification/useNotification';
import { SupplierId } from 'src/types/Id';
import { Ingredient } from 'src/types/RecipeVm';
import type { SupplierVm } from 'src/types/SupplierVm';
import { BuyUnitVm } from 'src/types/SupplyVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { calculateProductionFixedCost } from 'src/utils/inventory/supply/calculateProductionFixedCost';
import { isCompoundSupply } from 'src/utils/inventory/supply/isCompoundSupply';
import { isNormalSupply } from 'src/utils/inventory/supply/isNormalSupply';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateSupplyDialog({ open, onClose, onSupplyCreated }: Props): React.ReactElement {
    const form = useForm();
    const notification = useNotification();
    const {
        formState: { isSubmitting },
        control,
    } = form;
    const classes = useStyles();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'buyUnits',
    });

    const [suppliers, setSuppliers] = useState<Array<SupplierVm>>([]);
    const [currentSupplier, setCurrentSupplier] = useState<SupplierVm | undefined>(undefined);
    const [ingredients, setIngredients] = useState<Array<Ingredient>>([]);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const supplies = useSelector((state) => state.inventory.supplies);

    const supplyLimitAlertEnabled = form.watch('supplyLimitAlertEnabled');
    const buyUnits = form.watch('buyUnits');
    const produceAfterSave = form.watch('produceAfterSave');
    const supplyType = useWatch<SupplyType>({ name: 'type', control });

    const mainUnit = buyUnits?.find((buyUnit: BuyUnitVm) => buyUnit.isMainUnit);
    const validateUniqueSkuOnCreated = (sku: string) => supplies.some((supplyItem) => supplyItem.sku === sku?.trim());
    const productionFixedCost = calculateProductionFixedCost(ingredients ?? []);

    useEffect(() => {
        append({ unit: null, isMainUnit: true, conversionFactor: undefined });
    }, []);

    useEffect(() => {
        form.setValue('productionFixedCost', productionFixedCost);
    }, [ingredients]);

    const handleClose = () => {
        if (isSubmitting) return;
        setCurrentSupplier(undefined);
        setSuppliers([]);
        onClose();
    };

    const onSubmit = async (form: any) => {
        const response = await createSupplyApi({
            restaurantId,
            supplierIds: suppliers.map((supplier) => supplier.supplierId),
            name: form.name,
            type: form.type,
            sellUnit: form.sellUnit,
            buyUnits: form.buyUnits,
            supplyCategoryId: form.supplyCategoryId,
            sku: form.sku,
            waste: form.waste,
            fixedCost: form.fixedCost ?? productionFixedCost,
            minimumQuantity: form.minimumQuantity,
            ingredients: ingredients?.map((ingredient) => ({
                supplyId: ingredient.supply.supplyId,
                quantity: ingredient.quantity,
            })),
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        onClose();
        notification({ message: translate('The supply was successfully created') });
        onSupplyCreated(produceAfterSave, response.data.supplyId);
    };

    const addSupplier = () => {
        if (!currentSupplier || suppliers.some((supplier) => supplier.supplierId === currentSupplier?.supplierId)) return;
        setSuppliers([...suppliers, currentSupplier]);
    };

    const removeSupplier = (supplierId: SupplierId) => {
        setSuppliers(suppliers.filter((supplier) => supplier.supplierId !== supplierId));
    };

    const removeBuyUnit = (index: any) => {
        if (fields.length === 1) return;
        remove(index);
    };

    const handleChangeCheckbox = (idx: any) => {
        const newBuyUnits = [...buyUnits].map((buyUnit, index) => ({
            ...buyUnit,
            isMainUnit: index === idx,
        }));
        form.setValue('buyUnits', newBuyUnits);
    };

    const onChangeSupplyIngredients = (ingredients: any) => {
        setIngredients(ingredients);
    };

    return (
        <Dialog open={open} onClose={handleClose} classes={{ dialog: classes.dialogContainer }}>
            <Form form={form} onSubmit={onSubmit}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={12}>
                        <h1 className={classes.title}>{translate('Create Supply')}</h1>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormTextField name='name' label={translate('Name')} required />
                            </Grid>
                            <Grid item xs={6}>
                                <FormSupplyCategorySelect name='supplyCategoryId' label={translate('Category')} required />
                            </Grid>
                            <Grid item xs={6}>
                                <FormSupplyTypeSelect name='type' label={translate('Type')} required />
                            </Grid>
                            <Grid item xs={isNormalSupply(supplyType) ? 6 : 12}>
                                <FormTextField
                                    name='sku'
                                    label={translate('SKU')}
                                    validate={{
                                        sku: (value: string) => (!validateUniqueSkuOnCreated(value) ? true : translate('This field must be unique')),
                                    }}
                                />
                            </Grid>

                            {isNormalSupply(supplyType) && (
                                <Grid item xs={6}>
                                    <FormCurrencyNumberStringField
                                        name='fixedCost'
                                        label={translate('Price @unit', { unit: mainUnit?.unit ? translate(`UnitOfMeasurements.${mainUnit?.unit}`) : translate('Main Unit') })}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={6}>
                                <FormCheckbox name='supplyLimitAlertEnabled' label={translate('Minimum inventory warning')} />
                            </Grid>
                            {supplyLimitAlertEnabled && (
                                <Grid item xs={6}>
                                    <FormNumberStringField name='minimumQuantity' label={translate('Minimum inventory limit')} />
                                </Grid>
                            )}
                        </Grid>
                        {isNormalSupply(supplyType) && (
                            <Grid container spacing={2}>
                                <Grid item xs={12} className={classes.addSupplierContainer}>
                                    <Grid item xs={8} spacing={2}>
                                        <FormSupplierAutocomplete name='supplierId' label={translate('Supplier')} onChange={setCurrentSupplier} />
                                    </Grid>

                                    <Grid item xs={2} spacing={2}>
                                        <Button onClick={addSupplier} classes={{ button: classes.addButton }}>
                                            {translate('Add')}
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    {suppliers.map((supplier) => {
                                        return (
                                            <div className={classes.supplierContainer} key={supplier.supplierId}>
                                                <span className={classes.supplier}>{supplier.businessName}</span>
                                                <IconButton onClick={() => removeSupplier(supplier.supplierId)}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </div>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        )}
                        {isCompoundSupply(supplyType) && (
                            <Grid item xs={12}>
                                <CompoundSupplyIngredients onChangeSupplyIngredients={onChangeSupplyIngredients} />

                                <FormCurrencyNumberStringField
                                    name='productionFixedCost'
                                    label={translate('Estimated cost per unit of production', {
                                        unit: mainUnit?.unit ? translate(`UnitOfMeasurements.${mainUnit?.unit}`) : translate('Main Unit'),
                                    })}
                                    disabled
                                />
                            </Grid>
                        )}
                    </Grid>

                    <Grid item xs={1} md={1} spacing={2}>
                        <hr className={classes.verticalSeparator} />
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <h1 className={classes.title}>{translate('Units')}</h1>
                        <Grid item xs={6} spacing={2}>
                            <FormUnitOfMeasurementSelect name='sellUnit' label={translate('Recipe unit')} required helperText={translate('This is the unit that will be used to sell the supply')} />
                        </Grid>
                        <Grid container spacing={2} className={classes.unitsContainer}>
                            {fields.map((buyUnit, idx) => (
                                <React.Fragment key={buyUnit.id}>
                                    <Grid item xs={4}>
                                        <FormUnitOfMeasurementSelect name={`buyUnits.${idx}.unit`} label={translate('Buy Unit')} required />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormNumberField name={`buyUnits.${idx}.conversionFactor`} label={translate('Conversion Factor')} required />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormCheckbox name={`buyUnits.${idx}.isMainUnit`} label={translate('Main Unit')} onChange={handleChangeCheckbox} idx={idx} defaultValue={buyUnit.isMainUnit} />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => removeBuyUnit(idx)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </React.Fragment>
                            ))}
                            {isNormalSupply(supplyType) && (
                                <Button onClick={() => append({ buyUnit: undefined, conversionFactor: undefined })} classes={{ button: classes.addButton }}>
                                    {translate('Add')}
                                </Button>
                            )}
                        </Grid>
                        {isCompoundSupply(supplyType) && (
                            <div className={classes.produceAfterSave}>
                                <FormCheckbox name='produceAfterSave' label={translate('Create and Produce')} helperText={translate('Create and produce this supply after saving')} />
                            </div>
                        )}
                    </Grid>
                </Grid>

                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Creating') : translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        width: '85%',
        maxWidth: '85%',

        height: 'fit-content',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
    },
    cancelButton: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        color: theme.palette.primary.main,
        textTransform: 'none',
        backgroundColor: 'white',
        borderRadius: 6,
        border: `1.5px solid ${theme.palette.primary.main}`,
        width: 225.5,
        height: 50,
        padding: '16px 32px 16px 32px',
        gap: 10,
    },
    acceptButton: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        color: 'white',
        textTransform: 'none',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 6,
        width: 225.5,
        height: 50,
        padding: '16px 32px 16px 32px',
        gap: 10,
    },
    addButton: {
        width: 78,
        height: 34,
        padding: 8,
        minHeight: 34,
        borderRadius: 8,
        gap: 10,
        marginLeft: 10,
    },
    supplierContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 15px',
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
    supplier: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    separator: {
        border: `0.1px solid ${theme.palette.secondary.dark}`,
        width: '100%',
    },
    verticalSeparator: {
        borderLeft: `1px solid ${theme.palette.secondary.dark}`,
        height: '100%',
        width: '1px',
        margin: '0 20px',
    },
    addSupplierContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    addButtonContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    unitsContainer: {
        marginTop: 20,
    },
    fixedCost: {
        marginTop: 20,
    },
    produceAfterSave: {
        marginTop: 40,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSupplyCreated: any;
};
