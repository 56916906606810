/**
 * @prettier
 */
import hash from 'object-hash';
import type { CartItemVm } from 'src/types/CartItemVm';

export function createCartItemKey(posItem: CartItemVm): string {
    if (!!posItem.salesUnit) {
        const { key, ...keyData } = posItem;
        return hash(keyData);
    }
    const { quantity, key, ...keyData } = posItem;
    return hash(keyData);
}
