/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { findRestaurantEmployeeDayShiftsOverviewApi } from 'src/api/letseatmanager/employeeDayShift/findRestaurantEmployeeDayShiftsOverviewApi';
import { SimpleTable } from 'src/components/SimpleTable';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { ManagerUserId } from 'src/types/Id';
import type { RestaurantEmployeeDayShiftOverviewVm } from 'src/types/RestaurantEmployeeDayShiftOverviewVm';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { RegExps } from 'src/utils/RegExps';

export function EmployeesDayShiftReport(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const history = useHistory();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const period = useSelector((state) => state.app2.filterReportsState.period);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, employeeDayShiftsReport] = useLoadApi(
        findRestaurantEmployeeDayShiftsOverviewApi,
        {
            restaurantId,
            from: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            to: moment(formatDateToRestaurantTimeZone(period.to)).toISOString(),
        },
        { dependencies: [restaurantId, period], initialValue: [] }
    );

    const openEmployeeReport = (managerUserId: ManagerUserId) => {
        history.push({
            pathname: RoutePaths.POS_EMPLOYEE_EVENTS.replace(`:managerUserId(${RegExps.uuid})`, managerUserId),
            search: history.location.search,
        });
    };

    if (!employeeDayShiftsReport?.length) {
        return (
            <div className={classes.container}>
                <UpdatingContentProgress loading={loading} top />
                <ReportSectionEmptyState />
            </div>
        );
    }

    return (
        <section className={classes.container}>
            <UpdatingContentProgress loading={loading} top />
            <div className={classes.tableContainer}>
                <SimpleTable
                    columns={[
                        {
                            id: 'username',
                            content: translate('Name'),
                        },
                        {
                            id: 'roleName',
                            content: translate('Role'),
                        },
                        {
                            id: 'totalWorkedHours',
                            content: translate('Hours'),
                        },
                        {
                            id: 'totalOrders',
                            content: translate('Orders'),
                        },
                        {
                            id: 'totalTips',
                            content: translate('Tips'),
                        },
                    ]}
                    rows={employeeDayShiftsReport?.map((employeeDayShift: RestaurantEmployeeDayShiftOverviewVm) => ({
                        username: <div onClick={() => openEmployeeReport(employeeDayShift.managerUserId)}>{employeeDayShift.name ?? ''}</div>,
                        roleName: employeeDayShift.roleName,
                        totalWorkedHours: employeeDayShift.totalWorkedHours,
                        totalOrders: employeeDayShift.totalOrders,
                        totalTips: formatAsCurrencyNumber(employeeDayShift.totalTips),
                    }))}
                />
            </div>
        </section>
    );
}

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        width: '100%',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '40px 0',
    },
}));
