/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { CashRegisterPosBusinessDayVm } from 'src/types/CashRegisterPosBusinessDayVm';
import { CashRegisterPosBusinessDayId, CustomPaymentMethod, ManagerUserId, RestaurantId } from 'src/types/Id';

export async function closeCashRegisterPosBusinessDayApi(request: CloseCashRegisterPosBusinessDayApiRequest): ApiSauceResponse<CashRegisterPosBusinessDayVm> {
    return letseatmanagerApiMethod('pos/closeCashRegisterPosBusinessDayApi', request);
}

export type CloseCashRegisterPosBusinessDayApiRequest = {
    cashRegisterPosBusinessDayId: CashRegisterPosBusinessDayId;
    restaurantId: RestaurantId;
    managerUserId?: ManagerUserId;
    cashRegisterReport: Array<{
        paymentMethod: PaymentMethod;
        customPaymentMethod?: CustomPaymentMethod;
        amount: string;
        expectedAmount: string;
    }>;
};
