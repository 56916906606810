/**
 * @prettier
 */
export const TableShapes = {
    SQUARE: 'SQUARE',
    CIRCLE: 'CIRCLE',
    RECTANGLE: 'RECTANGLE',
} as const;

export type TableShape = typeof TableShapes[keyof typeof TableShapes];
