/**
 * @prettier
 */
import { Auth } from 'aws-amplify';
import { AppContextVm, getAppContextApi } from 'src/api/letseatmanager/getAppContextApi';
import { getPosContextApi } from 'src/api/letseatmanager/getPosContextApi';
import { app2 } from 'src/app2';
import { history } from 'src/config/history';
import { OrderTypes } from 'src/constants/OrderType';
import { PosOrderSteps } from 'src/constants/PosOrderStep';
import { PublicRoutePaths } from 'src/constants/PublicRoutePath';
import { appReducer } from 'src/reducers/appReducer';
import { authReducer } from 'src/reducers/authReducer';
import { menuReducer } from 'src/reducers/menuReducer';
import { posReducer } from 'src/reducers/posReducer';
import TheMenuActions from 'src/scenes/letseatmanager/deprecatedMenu/TheMenuReducer';
import { useSetPosPaymentMethods } from 'src/services/pos/posPaymentMethods/useSetPosPaymentMethods';
import { useSetPosPaymentMethodsDeprecated } from 'src/services/pos/posPaymentMethods/useSetPosPaymentMethodsDeprecated';
import { SentryService } from 'src/services/SentryService';
import type { RestaurantId } from 'src/types/Id';
import { CashRegisterPosBusinessDayVm } from 'src/types/PosBusinessDayVm';
import type { PosContextVm } from 'src/types/PosContextVm';
import { RestaurantVm } from 'src/types/RestaurantVm';
import { getErrorDescriptionFromResponse } from 'src/utils/error/getErrorDescriptionFromResponse';
import { isBluetoothPrinter } from 'src/utils/printer/isBluetoothPrinter';
import { useAction } from 'src/utils/react/useAction';
import { useFetchKitchenOrdersUpdates } from 'src/utils/react/useFetchKitchenOrdersUpdates';
import { useSelector } from 'src/utils/react/useSelector';
import { setRestaurantHasBluetoothPrintersInMobileApp } from 'src/utils/reactNative/setRestaurantHasBluetoothPrintersInMobileApp';

export function useOpenRestaurant(): any {
    const { fetchKitchenOrdersUpdates } = useFetchKitchenOrdersUpdates();
    const setPosPaymentMethodsDeprecated = useSetPosPaymentMethodsDeprecated();
    const setPosPaymentMethods = useSetPosPaymentMethods();

    const isAdminUser = useSelector((state) => state.authentication.adminUser);
    const isKioskUser = useSelector((state) => state.authentication.kioskUser);
    const kioskEnabled = useSelector((state) => state.app.restaurant?.kioskEnabled);
    const shouldResetMenuActions = useSelector((state) => state.app.restaurantId);
    const restaurant = useSelector((state) => state.app.restaurant);
    const posOrderStep = useSelector((state) => state.pos.orderStep);

    const closeRestaurant = useAction(appReducer.actions.closeRestaurant);
    const reset = useAction(appReducer.actions.reset);
    const setRestaurantIds = useAction(appReducer.actions.setRestaurantIds);
    const setUserRestaurantIds = useAction(appReducer.actions.setUserRestaurantIds);
    const openRestaurant = useAction(appReducer.actions.openRestaurant);
    const setPinCodeUsers = useAction(authReducer.actions.setPinCodeUsers);
    const setCashRegisterOpen = useAction(posReducer.actions.setCashRegisterOpen);
    const openRestaurantFailure = useAction(appReducer.actions.openRestaurantFailure);
    const app2CloseRestaurant = useAction(app2.actions.closeRestaurant);
    const resetMenuActions = useAction(TheMenuActions.reset);
    const setPosContext = useAction(posReducer.actions.setPosContext);
    const setOpenedPosBusinessDay = useAction(posReducer.actions.setOpenedPosBusinessDay);
    const setDeviceGroup = useAction(appReducer.actions.setDeviceGroup);
    const setDeviceGroups = useAction(appReducer.actions.setDeviceGroups);
    const setPrinters = useAction(appReducer.actions.setPrinters);
    const setDevices = useAction(appReducer.actions.setDevices);
    const showPosTableSelect = useAction(posReducer.actions.showPosTableSelect);
    const selectPosOrderType = useAction(posReducer.actions.selectPosOrderType);
    const setPaymentTerminals = useAction(appReducer.actions.setPaymentTerminals);
    const setCurrentEmployeesClockedIn = useAction(app2.actions.setCurrentEmployeesClockedIn);
    const resetMenus = useAction(menuReducer.actions.resetMenus);
    const resetPosMenu = useAction(posReducer.actions.resetPosMenu);
    const clearPos = useAction(posReducer.actions.clearPos);

    const handleOpenRestaurant = async (restaurantId: RestaurantId): Promise<RestaurantVm | undefined> => {
        if (shouldResetMenuActions) {
            resetMenuActions();
            if (isAdminUser) {
                history.push({ search: '' });
            }
        }

        if (isAdminUser) history.push({ search: `?restaurant=${restaurantId}` });
        resetMenus();
        resetPosMenu();
        clearPos();

        const request = { restaurantId: restaurantId } as const;
        const response = await getAppContextApi(request);

        if (!response.ok) {
            SentryService.logError('Failed to download the app view model', { request, response });
            const errorMessage = `Failed to download app view model cause of ${getErrorDescriptionFromResponse(response)}`;
            openRestaurantFailure(errorMessage);
            return;
        }

        processDataRestaurant(response.data, restaurantId);
        if (isKioskUser && !kioskEnabled) await signOut();

        SentryService.logInfoBreadcrumb('Successfully downloaded the app view model', { request });

        if (response.data.restaurant.posEnabled) {
            processPosContext(restaurantId);
        }
        fetchKitchenOrdersUpdates();

        return response.data.restaurant;
    };

    const processDataRestaurant = (data: AppContextVm, restaurantId: RestaurantId) => {
        const { pinCodeUsers, restaurant, restaurants, brands, business, deviceGroup, deviceGroups, printers, devices, paymentTerminals, currentEmployeesClockedIn } = data;
        const { cashRegisterOpened } = restaurant;
        const restaurantIds = restaurants.map((restaurant) => restaurant.restaurantId);

        const hasBluetoothPrinters = !!printers?.some((printer) => !printer.disabled && isBluetoothPrinter(printer.printerType));

        setCurrentEmployeesClockedIn(currentEmployeesClockedIn);
        setPinCodeUsers(pinCodeUsers);
        setCashRegisterOpen(cashRegisterOpened);
        openRestaurant({ restaurant, restaurants, brands, business, deviceGroup, deviceGroups, printers, devices, paymentTerminals });
        setRestaurantIds([restaurantId]);
        setUserRestaurantIds(restaurantIds);
        setDeviceGroup(deviceGroup);
        setDeviceGroups(deviceGroups);
        setPrinters(printers);
        setDevices(devices);
        setPaymentTerminals(paymentTerminals);
        setRestaurantHasBluetoothPrintersInMobileApp(hasBluetoothPrinters);
    };

    const processPosContext = async (restaurantId: RestaurantId) => {
        const request = { restaurantId } as const;
        const posResponse = await getPosContextApi(request);

        if (!posResponse.ok) {
            SentryService.logError('Failed to fetch POS context', { request, posResponse });
            return;
        }

        setCashRegisterOpen(posResponse.data?.cashRegisterOpened);
        setOpenedPosBusinessDay(posResponse?.data?.posBusinessDay);

        if (!!posResponse?.data?.posBusinessDay && !!restaurant?.posMultipleCashRegistersEnabled) {
            const openedPosBusinessDay = posResponse?.data?.posBusinessDay;
            const hasCashRegisterOpen = openedPosBusinessDay?.cashRegisterPosBusinessDays?.some((cashRegisterPosBusinessDay: CashRegisterPosBusinessDayVm) => cashRegisterPosBusinessDay.opened);

            setCashRegisterOpen(hasCashRegisterOpen);
        }

        if (posResponse?.data?.posPaymentMethods && !posResponse?.data?.restaurantPaymentMethods) {
            setPosPaymentMethodsDeprecated(posResponse?.data?.posPaymentMethods);
        }
        if (posResponse?.data?.restaurantPaymentMethods) {
            setPosPaymentMethods(posResponse?.data?.restaurantPaymentMethods);
        }
        setPosContext(posResponse.data);
        setPosInitialStep(posResponse.data);
    };

    const setPosInitialStep = (posContext: PosContextVm) => {
        if (!posContext.posSimpleView) return;
        if (posOrderStep !== PosOrderSteps.CREATE_ORDER && posOrderStep !== PosOrderSteps.SELECT_ORDER_TYPE) return;
        const shouldShowTablesForPosSimpleView = posOrderStep === PosOrderSteps.SELECT_ORDER_TYPE && posContext.posShowTablesEnabled;
        if (shouldShowTablesForPosSimpleView) {
            return showPosTableSelect();
        }
        selectPosOrderType(OrderTypes.TABLE_ORDER);
    };

    const signOut = async () => {
        await Auth.signOut();
        closeRestaurant();
        reset();
        history.push(PublicRoutePaths.HOME);
        SentryService.clearUsername();
        app2CloseRestaurant();
    };

    return { openRestaurant: handleOpenRestaurant };
}
