/**
 * @prettier
 */
import * as React from 'react';
import { ModifierGroups } from 'src/scenes/letseatmanager/menu/modifierGroup/ModifierGroups';
import { ModifierGroupsEmptyState } from 'src/scenes/letseatmanager/menu/modifierGroup/ModifierGroupsEmptyState';
import { useModifierGroups } from 'src/services/modifierGroup/useModifierGroups';
import { useSelector } from 'src/utils/react/useSelector';

export function ModifierGroupsSection(): React.ReactElement {
    const { modifierGroups } = useModifierGroups();

    const modifierGroupsLoaded = useSelector((state) => state.menu.modifierGroupsLoaded);

    const thereIsModifierGroups = modifierGroups && modifierGroups?.length > 0;

    if (!thereIsModifierGroups && modifierGroupsLoaded) {
        return <ModifierGroupsEmptyState />;
    }

    return <ModifierGroups />;
}
