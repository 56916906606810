/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverId } from 'src/types/Id';

export async function getTotalCancelledDeliveriesForDriverApi(request: GetTotalCancelledDeliveriesForDriverApiRequest): ApiSauceResponse<number> {
    return letseatadminApiMethod('driver/getTotalCancelledDeliveriesForDriverApi', request);
}

export type GetTotalCancelledDeliveriesForDriverApiRequest = {
    driverId: DriverId;
};
