/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantZoneId } from 'src/types/Id';

export async function removeRestaurantZoneApi(request: RemoveRestaurantZoneApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('pos/removeRestaurantZoneApi', request);
}

type RemoveRestaurantZoneApiRequest = {
    restaurantZoneId: RestaurantZoneId;
};
