/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { ReloadIcon } from 'src/icons/ReloadIcon';

export function RefreshToolbarButton({ tooltip, titleAccess, onClick, disabled }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <Button outlined classes={{ button: classes.button }} icon onClick={onClick} disabled={disabled}>
            <Tooltip text={tooltip || translate('Refresh')}>
                <ReloadIcon title={titleAccess ?? 'refresh toolbar button'} />
            </Tooltip>
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        verticalAlign: 'center',
        display: 'inline-flex',
        borderRadius: 8,
        border: '1px solid #D9D9D9',
        height: 50,
        width: 50,
    },
}));

type Props = {
    tooltip?: string;
    titleAccess?: string;
    disabled?: boolean;
    onClick: any;
};
