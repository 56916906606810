/**
 * @prettier
 */
import { usePosPaymentMethodsStore } from 'src/services/pos/posPaymentMethods/posPaymentMethodsStore';
import type { DeprecatedRestaurantPaymentMethodVm } from 'src/types/DeprecatedRestaurantPaymentMethodVm';

/**
 * @deprecated
 */
export function usePosPaymentMethodsDeprecated(): Array<DeprecatedRestaurantPaymentMethodVm> {
    const posPaymentMethods = usePosPaymentMethodsStore((state) => state.posPaymentMethodsDeprecated);

    return posPaymentMethods;
}
