/**
 * @prettier
 */
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { addAppUsedAtThePosBusinessDayReportApi } from 'src/api/letseatmanager/restaurant/addAppUsedAtThePosBusinessDayReportApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormAppAutocomplete } from 'src/components/form/FormAppAutocomplete';
import { Apps } from 'src/constants/App';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function AddAppUsedAtThePosBusinessDayReportDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();

    const { watch } = form;
    const app = watch('app');

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const onSubmit = async () => {
        if (loading) return;
        if (!app) return;
        setLoading(true);
        const response = await addAppUsedAtThePosBusinessDayReportApi({ restaurantId, channel: app });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess();
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} title={translate('Add app contemplated')}>
            <Form form={form}>
                <FormAppAutocomplete
                    disabled={loading}
                    name='app'
                    required
                    label={translate('Service')}
                    filter={[Apps.RAPPI, Apps.UBER_EATS, Apps.DIDI_FOOD, Apps.PIDEDIRECTO, Apps.RAPPI, Apps.PEDIDOS_YA, Apps.PIDEDIRECTOKIOSK, Apps.PIDEDIRECTOPOS, Apps.PIDEDIRECTO]}
                />
                <DialogActions>
                    <Button onClick={onClose} disabled={loading} secondary>
                        {translate('Cancel')}
                    </Button>
                    <Button onClick={onSubmit} disabled={loading}>
                        {loading ? translate('Adding') : translate('Add')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    onClose: any;
    onSuccess: any;
};
