/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantReferralId } from 'src/types/Id';

export async function changeRestaurantReferralResponsibleApi(request: ChangeRestaurantReferralResponsibleApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('restaurantReferral/changeRestaurantReferralResponsibleApi', request);
}

export type ChangeRestaurantReferralResponsibleApiRequest = {
    restaurantReferralId?: RestaurantReferralId;
    responsible: string;
};
