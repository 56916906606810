/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { LogEventVm } from 'src/api/letseatmanager/types/LogEventVm';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { LogEventLevel } from 'src/constants/LogEventLevel';
import type { LogEventType } from 'src/constants/LogEventType';
import type { SystemUserType } from 'src/constants/SystemUserType';
import type { RestaurantId } from 'src/types/Id';

export async function searchLogEventsApi(request: SearchLogEventsApiRequest): ApiSauceResponse<Array<LogEventVm>> {
    return letseatadminApiMethod('logEvent/searchLogEventsApi', request);
}

export type SearchLogEventsApiRequest = {
    logEventTypes?: Array<LogEventType>;
    logEventLevels?: Array<LogEventLevel>;
    systemUserTypes?: Array<SystemUserType>;
    restaurantIds?: Array<RestaurantId>;
    relatedItems?: Array<string>;
    to?: Date;
    from?: Date;
};
