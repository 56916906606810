/**
 * @prettier
 * @flow
 */
export const MexicanIVATaxesPercentages = Object.freeze({
    '0': 0,
    '8': 0.08,
    '16': 0.16,
});

export type MexicanTaxesPercentage = typeof MexicanIVATaxesPercentages[keyof typeof MexicanIVATaxesPercentages];
