/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { ModifierType } from 'src/constants/ModifierType';
import type { MenuItemId, ModifierGroupId } from 'src/types/Id';

export async function changeModifierInModifierGroupApi(request: ChangeModifierInModifierGroupApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('modifierGroup/changeModifierInModifierGroupApi', request);
}

type ChangeModifierInModifierGroupApiRequest = {
    modifierGroupId: ModifierGroupId;
    menuItemId: MenuItemId;
    price: string;
    modifierType: ModifierType;
};
