/**
 * @prettier
 */
export const CostTypes = {
    COST_BY_AVERAGE_WEIGHTED_COST: 'COST_BY_AVERAGE_WEIGHTED_COST',
    COST_BY_LAST_PURCHASE_COST: 'COST_BY_LAST_PURCHASE_COST',
    COST_FIXED: 'COST_FIXED',
} as const;

export type CostType = typeof CostTypes[keyof typeof CostTypes];
