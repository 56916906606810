/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { PedidosYaIntegrationStatus } from 'src/constants/PedidosYaIntegrationStatus';
import type { RappiIntegrationStatus } from 'src/constants/RappiIntegrationStatus';
import { DidiFoodStoreId, PedidosYaRestaurantId, RappiStoreId, RestaurantId, UberEatsStoreId } from 'src/types/Id';

export async function findRestaurantIntegrationsApi(request: FindRestaurantsIntegrationsApiRequest): ApiSauceResponse<RestaurantIntegrationsStatus> {
    return letseatmanagerApiMethod('restaurantIntegration/findRestaurantIntegrationsApi', request);
}

export type FindRestaurantsIntegrationsApiRequest = {
    restaurantId: RestaurantId;
};

export type RestaurantIntegrationsStatus = {
    uberEatsStoreId?: UberEatsStoreId;
    rappiStoreId?: RappiStoreId;
    rappiIntegrationStatus?: RappiIntegrationStatus;
    didiFoodStoreId?: DidiFoodStoreId;
    deliverOrdersFromDidiFood?: boolean;
    deliverOrdersFromUberEats?: boolean;
    deliverOrdersFromRappi?: boolean;
    pedidosYaRestaurantId?: PedidosYaRestaurantId;
    pedidosYaIntegrationStatus?: PedidosYaIntegrationStatus;
    pedidosYaRestaurantOnline?: boolean;
    pedidosYaSalesCommissionPercentage?: number;
    uberEatsSalesCommissionPercentage?: number;
    rappiSalesCommissionPercentage?: number;
    didiFoodSalesCommissionPercentage?: number;
};
