/**
 * @prettier
 */
export const UberEatsStoreOfflineReasons = {
    OUT_OF_MENU_HOURS: 'OUT_OF_MENU_HOURS',
    INVISIBLE: 'INVISIBLE',
    PAUSED_BY_UBER: 'PAUSED_BY_UBER',
    PAUSED_BY_RESTAURANT: 'PAUSED_BY_RESTAURANT',
} as const;

export type UberEatsStoreOfflineReason = typeof UberEatsStoreOfflineReasons[keyof typeof UberEatsStoreOfflineReasons];
