/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { findManagerUsersApi } from 'src/api/letseatadmin/managerUser/findManagerUsersApi';
import { removeManagerUserApi } from 'src/api/letseatadmin/managerUser/removeManagerUserApi';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { Page } from 'src/components/Page';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ChangeManagerUserDialog } from 'src/scenes/letseatadmin/managerUser/ChangeManagerUserDialog';
import { CreateManagerUserDialog } from 'src/scenes/letseatadmin/managerUser/CreateManagerUserDialog';
import type { ManagerUserId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useAskForPassword } from 'src/utils/react/useAskForPassword';
import { useLoadApi } from 'src/utils/react/useLoadApi';

export function ManagerUsersPage(): React.ReactElement {
    const classes = useStyles();
    const askForPassword = useAskForPassword();

    const [loading, setLoading] = useState(false);
    const [createManagerUserDialogState, setCreateManagerUserDialogState] = useState({ open: false });
    const [changeManagerUserDialogState, setChangeManagerUserDialogState] = useState({ open: false, managerUserId: undefined });

    const [loadingManagerUsers, managerUsers, refreshManagerUsers] = useLoadApi(findManagerUsersApi);

    const removeManagerUsers = async (managerUserIds: Array<ManagerUserId>) => {
        setLoading(true);
        for (const managerUserId of managerUserIds) {
            const response = await removeManagerUserApi({ managerUserId });
            if (!response.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(response);
                await refreshManagerUsers();
                return;
            }
        }
        await refreshManagerUsers();
    };

    const handleClickNew = async () => {
        const rightPassword = await askForPassword({ password: '3489' });
        if (!rightPassword) return;

        setCreateManagerUserDialogState({ open: true });
    };

    const handleRemove = async (selectedManagerUserIds: any) => {
        const remove = window.confirm(
            selectedManagerUserIds.length === 1
                ? 'Are you sure you want to remove the selected Manager Users'
                : `Are you sure you want to remove the selected ${selectedManagerUserIds.length} Manager User`
        );

        const rightPassword = await askForPassword({ password: '9834' });
        if (!rightPassword) return;

        if (remove) {
            await removeManagerUsers(selectedManagerUserIds);
        }
    };

    return (
        <Page title={translate('Manager Users')} className={classes.container}>
            <UpdatingContentProgress loading={loading || loadingManagerUsers} />
            <CreateManagerUserDialog open={createManagerUserDialogState.open} onClose={() => setCreateManagerUserDialogState({ open: false })} onManagerUserCreated={refreshManagerUsers} />
            <ChangeManagerUserDialog
                open={changeManagerUserDialogState.open}
                managerUserId={changeManagerUserDialogState.managerUserId}
                onClose={() => setChangeManagerUserDialogState({ open: false, managerUserId: undefined })}
                onChangeManagerUser={refreshManagerUsers}
            />

            <Table
                className={classes.table}
                data={
                    managerUsers?.managerUsers &&
                    managerUsers.managerUsers.map((managerUser) => {
                        return {
                            managerUserId: managerUser.managerUserId,
                            username: managerUser.username,
                            userType: managerUser.userType,
                            restaurants: managerUser.restaurants?.map((restaurant: any) => restaurant.name)?.join(', '),
                        };
                    })
                }
                columns={[
                    {
                        name: 'managerUserId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'username',
                        label: translate('Username'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'userType',
                        label: translate('Type'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'restaurants',
                        label: translate('Restaurants'),
                        options: {
                            filter: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'multiple',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={refreshManagerUsers} />
                            <CreateToolbarButton onClick={handleClickNew} />
                        </>
                    ),
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedManagerUserIds = selectedRowsData.map((d: any) => d[0]);
                        return (
                            <div className={classes.toolbar}>
                                {selectedManagerUserIds.length === 1 && (
                                    <ChangeToolbarButton
                                        enabledForUserTypeView={true}
                                        onClick={() => {
                                            if (selectedManagerUserIds.length === 1) setChangeManagerUserDialogState({ open: true, managerUserId: selectedManagerUserIds[0] });
                                        }}
                                    />
                                )}
                                <RemoveToolbarButton onClick={() => handleRemove(selectedManagerUserIds)} />
                            </div>
                        );
                    },
                    // filterList: [[], [], [], [], ['MEXICO']],
                }}
            />
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));
