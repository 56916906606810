/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import RadioGroup from '@material-ui/core/RadioGroup';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { app2 } from 'src/app2';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import Radio from 'src/components/deprecated/react-final-form/Radio';
import SelectKitchenTypeahead from 'src/components/deprecated/react-final-form/SelectKitchenTypeahead';
import { RestaurantStatuses } from 'src/constants/RestaurantStatus';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { AdminOrderVm } from 'src/types/AdminOrderVm';
import type { PickupStationId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export default function ChangePickupStationsDialog(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [kitchenIdsChange, setKitchenIdsChange] = useState(false);
    const [statusChange, setStatusChange] = useState(false);
    const open = useSelector((state) => state.app2.changePickupStationsDialog.open);
    const pickupStationIds = useSelector((state) => state.app2.changePickupStationsDialog.pickupStationIds);
    const onSuccess = useSelector((state) => state.app2.changePickupStationsDialog.onSuccess);
    const close = useAction(app2.actions.closeChangePickupStationsDialog);

    useEffect(() => {
        if (open) {
            setKitchenIdsChange(false);
            setStatusChange(false);
        }
    }, [open]);

    return (
        <Dialog fullWidth aria-labelledby='ChangePickupStationsDialog-title' scroll='paper' open={open} onClose={close}>
            <Form
                onSubmit={async (form: any) => {
                    if (loading) return;
                    if (kitchenIdsChange && !form.kitchenIds && !window.confirm('You have selected to remove the kitchens. Are you sure you want that?')) {
                        return;
                    }
                    setLoading(true);
                    const response = await letseatmanagerApiDeprecated.admin.changePickupStations({
                        pickupStationIds,
                        kitchenIds: kitchenIdsChange ? form.kitchenIds ?? [] : undefined,
                        status: statusChange ? form.status : undefined,
                    });
                    if (!response.ok) {
                        setLoading(false);
                        alertKnownErrorOrSomethingWentWrong(response);
                        return;
                    }
                    setLoading(false);
                    close();
                    const order: AdminOrderVm = response.data;
                    onSuccess && onSuccess(order);
                }}
                render={({ handleSubmit, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                        <DialogTitle id='ChangePickupStationsDialog-title'>{translate('Change Pickup Stations').toUpperCase()}</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item xs={1}>
                                    <Checkbox
                                        name='kitchenIdsChange'
                                        checked={kitchenIdsChange}
                                        onChange={(event, checked) => setKitchenIdsChange(checked)}
                                        color='primary'
                                        style={{ padding: 0, paddingTop: 24 }}
                                    />
                                </Grid>
                                <Grid item xs={11}>
                                    <Field name='kitchenIds' label={translate('Kitchens')} component={SelectKitchenTypeahead} selectMultiple fullWidth disabled={!kitchenIdsChange || loading} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={1}>
                                    <Checkbox
                                        name='statusChange'
                                        checked={statusChange}
                                        onChange={(event, checked) => setStatusChange(checked)}
                                        color='primary'
                                        style={{ padding: 0, paddingTop: 24 }}
                                    />
                                </Grid>
                                <RadioGroup row>
                                    <FormControlLabel
                                        label='Live'
                                        control={<Field name='status' component={Radio} type='radio' color='primary' value={RestaurantStatuses.LIVE} disabled={!statusChange || loading} />}
                                    />
                                    <FormControlLabel
                                        label='Inactive'
                                        control={<Field name='status' component={Radio} type='radio' color='primary' value={RestaurantStatuses.INACTIVE} disabled={!statusChange || loading} />}
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    {renderReceivers(pickupStationIds)}
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={close} disabled={loading}>
                                {translate('Cancel').toUpperCase()}
                            </Button>
                            <Button color='primary' type='submit' disabled={loading}>
                                {loading ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                            </Button>
                        </DialogActions>
                        {loading && <LinearProgress className={classes.linearProgress} />}
                    </form>
                )}
                initialValues={{}}
            />
        </Dialog>
    );
}

function renderReceivers(pickupStationIds: Array<PickupStationId>) {
    if (!pickupStationIds?.length) {
        return '';
    }
    return `This change will affect ${pickupStationIds.length} pickup stations`;
}

const useStyles = makeStyles((theme) => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
}));
