/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@pidedirecto/ui';
import { Form } from '@pidedirecto/ui/form';
import { useForm } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { App } from 'src/constants/App';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { CashRegisterExpectedAmountTable } from 'src/scenes/letseatmanager/posCashRegister/CashRegisterExpectedAmountTable';
import { CashRegisterIntegrationExpectedAmountsTable } from 'src/scenes/letseatmanager/posCashRegister/CashRegisterIntegrationExpectedAmountsTable';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CashRegisterExpectedAmountReport({ title, loading, onSubmit }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const usedAppsAtThePosBusinessDayReports = useSelector((state) => state.pos.context?.usedChannelsAtThePosBusinessDayReport);

    const closeCashRegisterReport = useAction(posReducer.actions.closeCashRegisterReport);

    return (
        <Form form={form} onSubmit={onSubmit} classes={{ form: classes.content }}>
            <section className={classes.titleContainer}>
                <h1 className={classes.title}>{title}</h1>
                <span className={classes.subTitle}>{translate("Remember once you closed the cash register you won't be able to create orders in the pos.")}</span>
                <span className={classes.subTitle}>{translate('Channels included in the count:')}</span>
                <span className={classes.subTitle}>
                    <strong>{usedAppsAtThePosBusinessDayReports ? usedAppsAtThePosBusinessDayReports?.map((app: App) => translate(`Apps.${app}`)).join(', ') : translate('All')}</strong>
                </span>
                <div className={classes.buttonsContainer}>
                    <Button variant='secondary' disabled={loading} onClick={closeCashRegisterReport}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {translate('Accept')}
                    </Button>
                </div>
            </section>
            <section className={classes.tableContainer}>
                <CashRegisterExpectedAmountTable disabled={loading} />
                <CashRegisterIntegrationExpectedAmountsTable disabled={loading} />
            </section>
        </Form>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 22,
        color: theme.palette.text.brand,
        [theme.breakpoints.up('sm')]: {
            fontSize: 26,
        },
    },
    subTitle: {
        fontFamily: theme.typography.regular,
        color: theme.palette.text.secondary,
        fontSize: 16,
        [theme.breakpoints.up('sm')]: {
            fontSize: 18,
        },
        display: 'block',
        marginBottom: 10,
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px 0',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    button: {
        minWidth: 280,
        [theme.breakpoints.up('sm')]: {
            minWidth: 'unset',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
        },
    },
    titleContainer: {
        width: '100%',
        padding: 20,
        [theme.breakpoints.up('sm')]: {
            width: '80%',
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            width: '40%',
        },
    },
    tableContainer: {
        width: '90%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        [theme.breakpoints.up('sm')]: {
            width: '65%',
        },
    },
}));

type Props = {
    title: string;
    loading: boolean;
    onSubmit: any;
};
