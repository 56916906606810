/**
 * @prettier
 */
import * as React from 'react';
import { useMenuSubscriptionUpdates } from 'src/services/menu/useMenuSubscriptionUpdates';
import { usePosMenuSubscriptionUpdates } from 'src/services/menu/usePosMenuSubscriptionUpdates';
import { useMenuCategorySubscriptionUpdates } from 'src/services/menuCategory/useMenuCategorySubscriptionUpdates';
import { useMenuItemSubscriptionUpdates } from 'src/services/menuItem/useMenuItemSubscriptionUpdates';
import { useModifierGroupSubscriptionUpdates } from 'src/services/modifierGroup/useModifierGroupSubscriptionUpdates';

export function MenuSubscriptionUpdates(): React.ReactElement | null {
    usePosMenuSubscriptionUpdates();

    useMenuSubscriptionUpdates();
    useMenuItemSubscriptionUpdates();
    useMenuCategorySubscriptionUpdates();
    useModifierGroupSubscriptionUpdates();

    return null;
}
