/**
 * @prettier
 */
import { useEffect } from 'react';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { print } from 'src/services/printer/printers/print';
import type { Print } from 'src/services/printer/types/Print';
import type { PrinterVm } from 'src/types/PrinterVm';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function useRemotePrinter() {
    useEffect(() => {
        const remotePrintWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.PRINT_WITH_REMOTE_PRINTER, handleRemotePrint);
        return () => {
            remotePrintWebSocketEvent.remove();
        };
    }, []);

    const handleRemotePrint = (
        webSocketEvent: WebSocketEvent<{
            print: Print;
            printer: PrinterVm;
        }>
    ) => {
        const remotePrintVm = webSocketEvent.data;
        print(remotePrintVm.print, remotePrintVm.printer);
    };
}
