/**
 * @prettier
 */
import type { PageContextType } from 'src/components/Page';
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';

export function createUserClickedSwitchLogEvent({ pageContext, label, name }: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_CLICKED_SWITCH,
        message: `switch ${label || name} clicked`,
        details: `${pageContext.stackTrace}/${normalizeUiStackTrace(`switch_${label || name}`)}`,
        data: {
            switchId: normalizeUiStackTrace(`switch_${label || name}`),
            pathId: `${pageContext.stackTrace}/${normalizeUiStackTrace(`switch_${label || name}`)}`,
            stackTrace: pageContext.stackTrace,
        },
    });
}

type Params = {
    label?: string;
    name: string;
    pageContext: PageContextType;
};
