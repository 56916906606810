/**
 * @prettier
 */
export const BannerVisibilities = {
    EVERYWHERE: 'EVERYWHERE',
    DELIVERY: 'DELIVERY',
    PICK_UP: 'PICK_UP',
    COMPANY: 'COMPANY',
} as const;

export type BannerVisibility = typeof BannerVisibilities[keyof typeof BannerVisibilities];
