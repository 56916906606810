/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { ContextualMenu } from 'src/components/ContextualMenu';
import { ChangeOrderTypeContextualMenuItem } from 'src/scenes/letseatmanager/pos/posMenu/posCart/ChangeOrderTypeContextualMenuItem';
import { ChargeContextualMenuItem } from 'src/scenes/letseatmanager/pos/posMenu/posCart/ChargeContextualMenuItem';
import { PrintBillContextualMenuItem } from 'src/scenes/letseatmanager/pos/posMenu/posCart/PrintBillContextualMenuItem';
import { PrintCommandContextualMenuItem } from 'src/scenes/letseatmanager/pos/posMenu/posCart/PrintCommandContextualMenuItem';
import { TakeOrderByCustomerContextualMenuItem } from 'src/scenes/letseatmanager/pos/posMenu/posCart/TakeOrderByCustomerContextualMenuItem';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function PosCartContextualMenu({ disabled }: Props): React.ReactElement | null {
    const classes = useStyles();

    const hasPendingOrder = useSelector((state) => !!state.pos.pendingOrder);
    const orderType = useSelector((state) => state.pos.orderType);

    const renderPosCartContextualMenu = hasPendingOrder || isEatHereOrder(orderType);

    if (!renderPosCartContextualMenu) return null;

    return (
        <ContextualMenu classes={{ button: classes.contextualMenu }} variant='square'>
            <ChargeContextualMenuItem disabled={disabled} />
            <TakeOrderByCustomerContextualMenuItem disabled={disabled} />
            <ChangeOrderTypeContextualMenuItem />
            <PrintBillContextualMenuItem disabled={disabled} />
            <PrintCommandContextualMenuItem disabled={disabled} />
        </ContextualMenu>
    );
}

const useStyles = makeStyles((theme) => ({
    contextualMenu: {
        height: 42,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

type Props = {
    disabled?: boolean;
};
