/**
 * @prettier
 */
export function stringToBoolean(value?: string | null): boolean | null | undefined | undefined {
    if (value === 'true') {
        return true;
    }
    if (value === 'false') {
        return false;
    }
    return value as any;
}
