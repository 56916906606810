/**
 * @prettier
 */
export const DeliveryManagerStatuses = {
    WAITING: 'WAITING',
    RUNNING: 'RUNNING',
    CANCELLED: 'CANCELLED',
    FAILED: 'FAILED',
    COMPLETED: 'COMPLETED',
    THREW_ERROR: 'THREW_ERROR',
} as const;

export type DeliveryManagerStatus = typeof DeliveryManagerStatuses[keyof typeof DeliveryManagerStatuses];
