/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { Country } from 'src/constants/Country';
import type { DiscountType } from 'src/constants/DiscountType';
import type { PromoType } from 'src/constants/PromoType';
import type { RewardType } from 'src/constants/RewardType';
import type { PromoCodeId } from 'src/types/Id';

export async function findPromoCodesApi(): ApiSauceResponse<PromoCodesVm> {
    return letseatadminApiMethod('promoCode/findPromoCodesApi');
}

export type PromoCodesVm = {
    promoCodes: Array<PromoCodeVm>;
};

type PromoCodeVm = {
    promoCodeId: PromoCodeId;
    code: string;
    description?: string;
    promoType: PromoType;
    rewardType: RewardType;
    discount: string;
    excludeDeliveryCost?: boolean;
    freeDelivery?: boolean;
    appOnly?: boolean;
    webOnly?: boolean;
    discountType: DiscountType;
    countries?: Array<Country>;
    restaurants?: number;
    customers?: number;
    minOrderAmount?: string;
    maxDiscountAmount?: string;
    restaurantPaidPercentage?: string;
    usage?: number;
    maxUsage?: number;
    removed: boolean;
    startsAt: Date;
    endsAt: Date;
    createdAt: Date;
};
