/**
 * @prettier
 */
import type { BannerVm } from 'src/api/letseatmanager/banner/findBannersApi';
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { BannerId, RestaurantId } from 'src/types/Id';

export async function changeBannerApi(request: ChangeBannerApiRequest): ApiSauceResponse<BannerVm> {
    return letseatmanagerApiMethod('banner/changeBannerApi', request);
}

export type ChangeBannerApiRequest = {
    restaurantId: RestaurantId;
    bannerId?: BannerId;
    hours?: string;
    imageUrl: string;
    enabled: boolean;
    pinned: boolean;
    channels?: Array<App>;
    promoCode?: string;
};
