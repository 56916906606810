/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import * as React from 'react';
import { app2 } from 'src/app2';
import { AppMenu } from 'src/AppMenu';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export const AppDrawer: React.ComponentType<any> = React.memo(function (): React.ReactElement | null {
    const classes = useStyles();

    const signedIn = useSelector((state) => state.authentication.signedIn);
    const appMenuOpen = useSelector((state) => state.app2.appMenuOpen);

    const closeAppMenu = useAction(app2.actions.closeAppMenu);

    if (!signedIn) return null;

    return (
        <Drawer
            variant='temporary'
            anchor={'left'}
            open={appMenuOpen}
            classes={{ paper: classes.drawerPaper }}
            onClose={closeAppMenu}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
        >
            <AppMenu />
        </Drawer>
    );
});

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        borderRight: 0,
        width: 290,
        height: '100vh',
        overflowY: 'hidden',
        backgroundColor: 'white',
        '&:hover': {
            overflowY: 'auto',
        },
        [theme.breakpoints.up('md')]: {
            width: 300,
            position: 'relative',
        },
    },
}));
