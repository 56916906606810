/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import { DropDown, DropDownItem } from '@pidedirecto/ui';
import { EllipsisVerticalIcon } from '@pidedirecto/ui/icons';
import { useState } from 'react';
import * as React from 'react';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import type { SupplyType } from 'src/constants/SupplyType';
import { translate } from 'src/i18n/translate';
import { SupplyId } from 'src/types/Id';

export function SuppliesTableContextualMenu({ supplyId, disabled, onEdit, onRemove, onProduce, supplyType }: Props): React.ReactElement {
    const classes = useStyles();
    const [contextualMenuOpened, setContextualMenuOpened] = useState(false);

    const handleEditSupply = () => {
        onEdit(supplyId);
        setContextualMenuOpened(false);
    };

    const handleRemoveSupply = () => {
        onRemove(supplyId);
        setContextualMenuOpened(false);
    };

    const handleProducedCompoundSupply = () => {
        onProduce(supplyId);
        setContextualMenuOpened(false);
    };

    return (
        <DropDown variant='icon' content={<EllipsisVerticalIcon size={20} />} position='right'>
            <SecuredContent rolePermission={RolePermissions.PRODUCE_COMPOUND_SUPPLY}>
                {supplyType === 'COMPOUND' && <DropDownItem onClick={() => handleProducedCompoundSupply()}>{translate('Produce')}</DropDownItem>}
            </SecuredContent>
            <DropDownItem onClick={() => handleEditSupply()}>{translate('Edit')}</DropDownItem>
            <DropDownItem onClick={() => handleRemoveSupply()}>{translate('Delete')}</DropDownItem>
        </DropDown>
    );
}

const useStyles = makeStyles((theme) => ({
    menuItem: {
        display: 'flex',
        alignItems: 'center',
    },
    menuIcon: {
        marginRight: 8,
    },
}));
type Props = {
    supplyId: SupplyId;
    disabled: boolean;
    onEdit: any;
    onRemove: any;
    onProduce: any;
    supplyType?: SupplyType;
};
