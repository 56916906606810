/**
 * @prettier
 */
import IconButton from '@material-ui/core/IconButton';
import ReplyIcon from '@material-ui/icons/Reply';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { findRestaurantSelfRegistrationsApi, RestaurantSelfRegistration } from 'src/api/letseatadmin/restaurantSelfRegistration/findRestaurantSelfRegistrationsApi';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Page } from 'src/components/Page';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import type { RestaurantId } from 'src/types/Id';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { getRemainDaysColor } from 'src/utils/selfRegistration/getRemainDaysColor';
import { openRestaurantPageInNewTab } from 'src/utils/window/openRestaurantPageInNewTab';

export function RestaurantSelfRegistrationsPage(): React.ReactElement {
    const [loading, restaurants, refreshRestaurants] = useLoadApi(findRestaurantSelfRegistrationsApi, undefined, { initialValue: [] });

    const [selfRegistrationDataColumns, setSelfRegistrationDataColumns] = useState<Array<RestaurantStructureType>>([]);

    useEffect(() => {
        setSelfRegistrationInfoInTable(restaurants);
    }, [restaurants]);

    const hasRestaurantInfo = (restaurantInfo: Array<RestaurantSelfRegistration>) => restaurantInfo.length > 0;

    const setSelfRegistrationInfoInTable = (restaurants: Array<RestaurantSelfRegistration>) => {
        if (!hasRestaurantInfo(restaurants)) return;

        restaurants.sort((a, b) => (a?.createdAt as any) - (b?.createdAt as any));
        const restaurantRestructured = restaurants.map((restaurant) => ({
            restaurantSelfRegistrationId: restaurant.restaurantSelfRegistrationId,
            restaurantId: restaurant.restaurantId,
            restaurant: restaurant.name,
            contact: restaurant.contact,
            email: restaurant.email,
            contactNumber: restaurant.contactNumber,
            createdAt: moment(restaurant.createdAt).format('YYYY/MM/DD'),
            registrationStatus: translate(`RegistrationStatuses.${restaurant.selfRegistrationStatus ?? ''}`),
            registrationStatusBD: restaurant.selfRegistrationStatus,
            freeTrialDaysLeft: restaurant.freeTrialDaysLeft,
            city: restaurant.city,
            openRestaurant: restaurant.restaurantId,
            countCompleteOrders: restaurant.countCompleteOrders,
            countRejectedOrders: restaurant.countRejectedOrders,
        }));
        setSelfRegistrationDataColumns(restaurantRestructured);
    };

    const selfRegistrationStructureTable = [
        {
            name: 'restaurantSelfRegistrationId',
            label: '',
            options: {
                display: 'excluded' as any,
                filter: false,
            },
        },
        {
            name: 'restaurant',
            label: translate('Restaurant'),
            options: {
                filter: false,
            },
        },
        {
            name: 'contact',
            label: translate('Contact Name'),
            options: {
                filter: false,
            },
        },
        {
            name: 'email',
            label: translate('Email'),
            options: {
                filter: false,
            },
        },
        {
            name: 'contactNumber',
            label: translate('Contact Number'),
            options: {
                filter: false,
            },
        },
        {
            name: 'countCompleteOrders',
            label: translate('Completed orders'),
            options: {
                filter: false,
            },
        },
        {
            name: 'countRejectedOrders',
            label: translate('Canceled orders'),
            options: {
                filter: false,
            },
        },
        {
            name: 'registrationStatus',
            label: translate('Registration status'),
            options: {
                filter: false,
            },
        },
        {
            name: 'registrationStatusBD',
            label: '',
            options: {
                display: 'excluded' as any,
                filter: false,
            },
        },
        {
            name: 'freeTrialDaysLeft',
            label: translate('Free Trial Days Left'),
            options: {
                filter: false,
            },
        },
        {
            name: 'city',
            label: translate('City'),
            options: {
                filter: true,
            },
        },
        {
            name: 'openRestaurant',
            label: translate('Open restaurant'),
            options: {
                filter: false,
                customBodyRender: (restaurantId: RestaurantId) => {
                    return (
                        <IconButton color='primary' onClick={() => openRestaurantPageInNewTab(restaurantId)}>
                            <ReplyIcon fontSize='small' />
                        </IconButton>
                    );
                },
            },
        },
        {
            name: 'createdAt',
            label: translate('Registered At'),
            options: {
                filter: false,
            },
        },
    ];

    return (
        <Page title={translate('Restaurants Self-Registered')}>
            <Table
                loading={loading}
                data={selfRegistrationDataColumns}
                columns={selfRegistrationStructureTable}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    filter: false,
                    print: false,
                    viewColumns: false,
                    customToolbar: () => (
                        <span>
                            <RefreshToolbarButton onClick={refreshRestaurants} />
                        </span>
                    ),
                    setRowProps: (row) => {
                        return {
                            style: { backgroundColor: getRemainDaysColor(row[12], row[8], row[9]) },
                        };
                    },
                }}
            />
        </Page>
    );
}

type RestaurantStructureType = {
    restaurantSelfRegistrationId: string;
    restaurantId?: RestaurantId;
    restaurant?: string;
    contact?: string;
    email?: string;
    contactNumber?: string;
    createdAt: string;
    registrationStatus: string;
    registrationStatusBD: string;
    freeTrialDaysLeft?: number;
    city?: string;
    openRestaurant?: RestaurantId;
    countCompleteOrders?: number;
    countRejectedOrders?: number;
};
