/**
 * @prettier
 */
import { Button } from '@pidedirecto/ui';
import * as React from 'react';
import { PickupTimeTypes } from 'src/constants/PickupTimeType';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { isPlannedPickupTime } from 'src/utils/pickupTimeType/isPlannedPickupTime';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function UndoPlannedPosOrderButton(): React.ReactElement | null {
    const pickupTimeType = useSelector((state) => state.pos.pickupTimeType);

    const setPickupTimeType = useAction(posReducer.actions.setPickupTimeType);
    const setPickupTime = useAction(posReducer.actions.setPickupTime);

    const undoSchedule = () => {
        setPickupTimeType(PickupTimeTypes.ASAP);
        setPickupTime(undefined);
    };

    if (!isPlannedPickupTime(pickupTimeType)) return null;

    return (
        <Button variant='secondary' onClick={undoSchedule}>
            {translate('Undo schedule')}
        </Button>
    );
}
