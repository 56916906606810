/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { translate } from 'src/i18n/translate';
import { OpeningHoursForAllDays } from 'src/scenes/letseatmanager/openingHours/OpeningHoursForAllDays';
import { OpeningHoursForDays } from 'src/scenes/letseatmanager/openingHours/OpeningHoursForDays';

export function OpeningHours(): React.ReactElement {
    const classes = useStyles();

    const { control } = useFormContext();

    const sameOpeningHoursForAllDays = useWatch({ control, name: 'sameOpeningHoursForAllDays' });

    return (
        <section className={classes.container}>
            <>
                <header>
                    <h2 className={classes.title}>{translate('Opening hours')}</h2>
                    <FormSwitch name={'sameOpeningHoursForAllDays'} label={translate('Same hours every day')} />
                </header>
                {sameOpeningHoursForAllDays && <OpeningHoursForAllDays />}
                {!sameOpeningHoursForAllDays && <OpeningHoursForDays />}
            </>
        </section>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        color: theme.palette.text.primary,
        fontSize: '20px',
        fontFamily: theme.typography.regular,
        margin: 0,
    },
}));
