/**
 * @prettier
 */
export const KitchenStatuses = {
    PREPARING: 'PREPARING',
    PREPARED: 'PREPARED',
    PARTIALLY_PREPARED: 'PARTIALLY_PREPARED',
} as const;

export type KitchenStatus = typeof KitchenStatuses[keyof typeof KitchenStatuses];
