/**
 * @prettier
 */
export const States = {
    AGUASCALIENTES: 'AGUASCALIENTES',
    BAJA_CALIFORNIA: 'BAJA_CALIFORNIA',
    BAJA_CALIFORNIA_SUR: 'BAJA_CALIFORNIA_SUR',
    CAMPECHE: 'CAMPECHE',
    CDMX: 'CDMX',
    CHIAPAS: 'CHIAPAS',
    CHIHUAHUA: 'CHIHUAHUA',
    COAHUILA: 'COAHUILA',
    COLIMA: 'COLIMA',
    DURANGO: 'DURANGO',
    GUANAJUATO: 'GUANAJUATO',
    GUERRERO: 'GUERRERO',
    HIDALGO: 'HIDALGO',
    JALISCO: 'JALISCO',
    MICHOACAN: 'MICHOACAN',
    MORELOS: 'MORELOS',
    ESTADO_DE_MEXICO: 'ESTADO_DE_MEXICO',
    NAYARIT: 'NAYARIT',
    NUEVO_LEON: 'NUEVO_LEON',
    OAXACA: 'OAXACA',
    PUEBLA: 'PUEBLA',
    QUERETARO: 'QUERETARO',
    QUINTANA_ROO: 'QUINTANA_ROO',
    SAN_LUIS_POTOSI: 'SAN_LUIS_POTOSI',
    SINALOA: 'SINALOA',
    SONORA: 'SONORA',
    TABASCO: 'TABASCO',
    TAMAULIPAS: 'TAMAULIPAS',
    TLAXCALA: 'TLAXCALA',
    VERACRUZ: 'VERACRUZ',
    YUCATAN: 'YUCATAN',
    ZACATECAS: 'ZACATECAS',
} as const;

export type State = typeof States[keyof typeof States];
