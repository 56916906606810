/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { createSupplyCategoryApi } from 'src/api/letseatmanager/supply/createSupplyCategoryApi';
import { findSupplyCategoriesByRestaurantIdApi } from 'src/api/letseatmanager/supply/findSupplyCategoriesByRestaurantIdApi';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { CloseIcon } from 'src/icons/CloseIcon';
import { RemoveSupplyCategoryDialog } from 'src/scenes/letseatmanager/inventory/inventory/RemoveSupplyCategoryDialog';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { alertKnownErrorOrSomethingWentWrongError } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrongError';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { trim } from 'src/utils/string/trim';
import { upperCase } from 'src/utils/string/upperCase';
import { getSupplyCategoryName } from 'src/utils/supplyCategory/getSupplyCategoryName';

export function RestaurantSupplyCategoriesSection(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const { watch } = form;
    const supplyCategoryName = watch('supplyCategoryName');

    const [removeSupplyCategoryDialogState, setRemoveSupplyCategoryDialogState] = useState({ open: false, supplyCategory: undefined });
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loadingSupplyCategories, supplyCategories, loadSupplyCategories] = useLoadApi(
        findSupplyCategoriesByRestaurantIdApi,
        { restaurantId },
        { initialValue: [], requiredValues: [], dependencies: [] }
    );

    const onSubmit = async () => {
        if (!supplyCategoryName) return;

        const supplyCategoryExists = supplyCategories?.some((supplyCategory: any) => trim(upperCase(supplyCategory.name)) === trim(upperCase(supplyCategoryName)));

        if (supplyCategoryExists) return alertKnownErrorOrSomethingWentWrongError(translate('There is already a category with that name'));

        setLoading(true);
        const response = await createSupplyCategoryApi({ restaurantId, name: supplyCategoryName.toString().toUpperCase() });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        form.reset({ supplyCategoryName: '' });

        loadSupplyCategories();
    };

    const closeRemoveSupplyCategory = () => setRemoveSupplyCategoryDialogState({ open: false, supplyCategory: undefined });

    return (
        <div>
            <RemoveSupplyCategoryDialog
                open={removeSupplyCategoryDialogState.open}
                onSuccess={loadSupplyCategories}
                supplyCategory={removeSupplyCategoryDialogState.supplyCategory}
                onClose={closeRemoveSupplyCategory}
            />
            <Grid container className={classes.section}>
                <Grid className={classes.titleContainer}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                        <h2 className={classes.subtitle}>{translate('Manage Categories')}</h2>
                        <Tooltip text={translate('Create your categories for inventory supplies (frozen drinks, perishables, etc.)')} />
                    </div>
                </Grid>
                <Form form={form} className={classes.formContainer}>
                    <FormTextField disabled={loading} name='supplyCategoryName' label={translate('Name')} required classes={{ input: classes.input }} />
                    <Button onClick={onSubmit} classes={{ button: classes.acceptButton }} primary disabled={loading}>
                        {loading ? translate('Adding') : translate('Add')}
                    </Button>
                </Form>

                {!!supplyCategories && (
                    <div className={classes.container}>
                        {supplyCategories?.map((supplyCategory: any, idx: any) => (
                            <div className={classes.supplyCategoryItem} key={`${supplyCategory}-${idx}`}>
                                {getSupplyCategoryName(supplyCategory.name)}
                                <Button icon onClick={() => setRemoveSupplyCategoryDialogState({ open: true, supplyCategory })} classes={{ button: classes.removeButton }} name='Remove'>
                                    <CloseIcon title='Remove' />
                                </Button>
                            </div>
                        ))}
                    </div>
                )}
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
        color: theme.palette.secondary.contrastText,
        marginLeft: 10,
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    input: {
        width: '100%',
        minHeight: 42,
    },
    container: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
        gap: 12,
    },
    formContainer: {
        display: 'flex',
        marginBottom: 20,
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: 260,
        [theme.breakpoints.up('sm')]: {
            width: 500,
            height: 'auto',
        },
    },

    acceptButton: {
        gap: 10,
        minHeight: 42,
        justifyContent: 'center',
        padding: '8px 12px',
        minWidth: 150,
        textTransform: 'none',
        marginTop: 20,
        marginLeft: 10,
    },
    removeButton: {
        margin: '5px',
        width: 10,
        height: 20,
        minHeight: 20,
        padding: '12px 10px',
    },
    section: {
        width: '100%',
        marginTop: 24,
    },
    supplyCategoryItem: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #676060B2',
        borderRadius: 12,
        padding: '0 10px',
    },

    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 16,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 12,
        },
    },
}));
