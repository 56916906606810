/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { GeneralInventoryReportVm } from 'src/types/GeneralInventoryReportVm';
import type { RestaurantId } from 'src/types/Id';

export async function getGeneralInventoryReportApi(request: GetGeneralInventoryReportApiRequest): ApiSauceResponse<GeneralInventoryReportVm> {
    return pidedirectoReportsMethod('/getGeneralInventoryReportApi', request);
}

type GetGeneralInventoryReportApiRequest = {
    restaurantIds: Array<RestaurantId>;
};
