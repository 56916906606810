/**
 * @prettier
 */
import { getPosMenuApi } from 'src/api/letseatmanager/pos/getPosMenuApi';
import { findPosMenuOfflineInLocalStorage } from 'src/localStorage/findPosMenuOfflineInLocalStorage';
import { setPosMenuOfflineInLocalStorage } from 'src/localStorage/setPosMenuOfflineInLocalStorage';
import { posReducer } from 'src/reducers/posReducer';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

let callingApi = false;

export function useLoadPosMenus(): () => Promise<void> {
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const setPosMenu = useAction(posReducer.actions.setPosMenu);

    const loadPosMenu = async () => {
        if (callingApi) return;

        callingApi = true;
        const response = await getPosMenuApi({ restaurantId });
        if (!response.ok) {
            if (response.problem === 'NETWORK_ERROR') {
                return setPosMenu(findPosMenuOfflineInLocalStorage());
            }
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setPosMenuOfflineInLocalStorage(response.data);
        setPosMenu(response.data);
        callingApi = false;
    };

    return loadPosMenu;
}
