/**
 * @prettier
 */
import { makeStyles, useTheme } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { KitchenOrderItem } from 'src/scenes/letseatmanager/kitchenDisplayScreen/kitchenOrder/KitchenOrderItem';
import { primitiveColors } from 'src/theme/AppTheme';
import { OrderItemVm, type OrderVm } from 'src/types/OrderVm';
import { getTimeElapsed } from 'src/utils/date/getTimeElapsed';
import { isCancelledOrder } from 'src/utils/order/isCancelledOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isPreparedKitchenOrder } from 'src/utils/order/isPreparedKitchenOrder';
import { isRejectedOrder } from 'src/utils/order/isRejectedOrder';
import { toHumanizedOrderId } from 'src/utils/order/toHumanizedOrderId';
import { classNames } from 'src/utils/react/classNames';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';

export function KitchenOrderCard({ order, onClick }: Props): React.ReactElement | null {
    const classes = useStyles();
    const theme = useTheme();

    const orderItems =
        isCancelledOrder(order.orderStatus) || isRejectedOrder(order.orderStatus) ? order.orderItems?.map((item: OrderItemVm) => ({ ...item, removedAt: new Date() })) : order.orderItems;
    const orderItemsGroupKitchenTime = orderItems[0]?.addedAt ?? order.createdAt;
    const minutes = moment().diff(orderItemsGroupKitchenTime, 'minutes', true);

    const getOrderTypeText = () => {
        if (isCancelledOrder(order.orderStatus) || isRejectedOrder(order.orderStatus)) return translate('Cancelled');

        if (isEatHereOrder(order.orderType) && !!order.table) return translate('Table @table', { table: order.table });

        return translate(`OrderTypes.${order.orderType}`);
    };

    const getHeaderColor = () => {
        if (isCancelledOrder(order.orderStatus) || isRejectedOrder(order.orderStatus)) return theme.palette.surface.heavyContrast;
        if (isPreparedKitchenOrder(order.kitchenStatus)) return primitiveColors.blue500;
        if (BigNumber(minutes).isLessThanOrEqualTo(7)) return primitiveColors.green600;
        if (BigNumber(minutes).isLessThanOrEqualTo(15)) return primitiveColors.orange400;

        return '#C7413A';
    };

    if (!order) return null;

    return (
        <div className={classes.container}>
            <div className={classNames(classes.headerCard)} style={{ backgroundColor: getHeaderColor() }} onClick={!isMobileApp() ? onClick : null} onDoubleClick={isMobileApp() ? onClick : null}>
                <div>
                    <div>{getTimeElapsed(orderItemsGroupKitchenTime)}</div>
                    <div className={classes.headerSubText}>{order?.waiterName}</div>
                </div>
                <div>
                    <div>{getOrderTypeText()}</div>
                    <div className={classes.headerSubText}>{`#${toHumanizedOrderId(order.orderId) || order.shortOrderId}`}</div>
                </div>
            </div>
            <div className={classes.bodyCard}>
                {order.notes && (
                    <>
                        <div>{translate('Comment:')}</div>
                        <div>{order.notes}</div>
                    </>
                )}
                {orderItems?.map((orderItem, idx: number) => (
                    <KitchenOrderItem key={`${orderItem.menuItemId}-${idx}`} orderId={order.orderId} orderItem={orderItem} />
                ))}
                {order.cancelledItems?.map((orderItem: OrderItemVm, idx: number) => (
                    <KitchenOrderItem key={`${orderItem.menuItemId}-${idx}`} orderId={order.orderId} orderItem={orderItem} />
                ))}
                {order.customerNote && (
                    <>
                        <div>{translate('Note of customer')}</div>
                        <div>{order.customerNote}</div>
                    </>
                )}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        minWidth: 255,
        borderRadius: 4,
        color: theme.palette.text.invert,
        overflow: 'hidden',
    },
    headerCard: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 14,
        fontFamily: theme.typography.regular,
        fontWeight: 600,
        fontSize: 18,
        [theme.breakpoints.up('sm')]: {
            fontSize: 16,
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    headerSubText: {
        fontWeight: 400,
        fontSize: 12,
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
    bodyCard: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.surface.tertiary,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '14px 18px',
        borderRadius: '0 0 4px 4px',
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
}));

type Props = {
    order: OrderVm;
    onClick?: any;
};
