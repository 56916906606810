/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';

export function CashRegisterIcon({ color, width, height, title, strokeWidth }: Props): React.ReactElement {
    const theme = useTheme();
    const classes = useStyles();

    return (
        <svg className={classes.icon} width={width ?? '26'} height={height ?? '20'} viewBox='0 0 26 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                d='M10.5 8.25C10.0858 8.25 9.75 8.58579 9.75 9C9.75 9.41421 10.0858 9.75 10.5 9.75V8.25ZM16.5 9.75C16.9142 9.75 17.25 9.41421 17.25 9C17.25 8.58579 16.9142 8.25 16.5 8.25V9.75ZM2.80392 6.75H19.1961V5.25H2.80392V6.75ZM18.6324 6.17105V15.2368H20.1324V6.17105H18.6324ZM3.36765 15.2368V6.17105H1.86765V15.2368H3.36765ZM19.1961 6.75C18.9462 6.75 18.6324 6.5497 18.6324 6.17105H20.1324C20.1324 5.60346 19.6517 5.25 19.1961 5.25V6.75ZM2.80392 5.25C2.3483 5.25 1.86765 5.60346 1.86765 6.17105H3.36765C3.36765 6.54971 3.05379 6.75 2.80392 6.75V5.25ZM19.75 15.7368V18.8289H21.25V15.7368H19.75ZM20.3137 18.25H1.68627V19.75H20.3137V18.25ZM2.25 18.8289V15.7368H0.75V18.8289H2.25ZM1.68627 18.25C1.93614 18.25 2.25 18.4503 2.25 18.8289H0.75C0.75 19.3965 1.23066 19.75 1.68627 19.75V18.25ZM19.75 18.8289C19.75 18.4503 20.0639 18.25 20.3137 18.25V19.75C20.7693 19.75 21.25 19.3965 21.25 18.8289H19.75ZM20 14.4868H19.3824V15.9868H20V14.4868ZM2.61765 14.4868H2V15.9868H2.61765V14.4868ZM2.61765 15.9868H19.3824V14.4868H2.61765V15.9868ZM2.25 15.7368C2.25 15.8749 2.13807 15.9868 2 15.9868V14.4868C1.30964 14.4868 0.75 15.0465 0.75 15.7368H2.25ZM21.25 15.7368C21.25 15.0465 20.6904 14.4868 20 14.4868V15.9868C19.8619 15.9868 19.75 15.8749 19.75 15.7368H21.25ZM10 1.75H18V0.25H10V1.75ZM17.75 1.5V3.57692H19.25V1.5H17.75ZM10.25 3.57692V1.5H8.75V3.57692H10.25ZM18 3.32692H14V4.82692H18V3.32692ZM14 3.32692H10V4.82692H14V3.32692ZM13.25 4.07692V6H14.75V4.07692H13.25ZM8.75 3.57692C8.75 4.26728 9.30964 4.82692 10 4.82692V3.32692C10.1381 3.32692 10.25 3.43885 10.25 3.57692H8.75ZM17.75 3.57692C17.75 3.43885 17.8619 3.32692 18 3.32692V4.82692C18.6904 4.82692 19.25 4.26728 19.25 3.57692H17.75ZM18 1.75C17.8619 1.75 17.75 1.63807 17.75 1.5H19.25C19.25 0.809644 18.6904 0.25 18 0.25V1.75ZM10 0.25C9.30964 0.25 8.75 0.809646 8.75 1.5H10.25C10.25 1.63807 10.1381 1.75 10 1.75V0.25ZM10.5 9.75H16.5V8.25H10.5V9.75Z'
                stroke={color || 'currentColor'}
                fill={color || 'currentColor'}
                strokeWidth={strokeWidth ?? '0.3'}
            />
        </svg>
    );
}

const useStyles = makeStyles((theme) => ({
    icon: {
        maxWidth: 26,
        maxHeight: 20,
    },
}));

type Props = {
    width?: number;
    height?: number;
    color?: string;
    title?: string;
    strokeWidth?: number;
};
