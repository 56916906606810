/**
 * @prettier
 */
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomizableAutocomplete } from 'src/components/CustomizableAutocomplete';
import type { Variant } from 'src/components/form/FormTypes';
import { getError } from 'src/components/form/utils/getError';
import { translate } from 'src/i18n/translate';

export function FormCustomizableAutocomplete({
    name,
    label,
    placeholder,
    helperText,
    defaultValue = null,
    data,
    renderOption,
    getOptionValue,
    getOptionLabel,
    getOptionDisabled,
    disabled,
    required,
    classes: classesProp,
}: Props): React.ReactElement {
    const { errors, control } = useFormContext();

    return (
        <Controller
            render={({ value, onChange, ...props }) => {
                return (
                    <CustomizableAutocomplete
                        defaultValue={defaultValue}
                        label={label}
                        helperText={getError(errors, name)?.message ?? helperText}
                        error={!!getError(errors, name)}
                        classes={classesProp}
                        data={data}
                        renderOption={renderOption}
                        getOptionValue={getOptionValue}
                        getOptionLabel={getOptionLabel}
                        getOptionDisabled={getOptionDisabled}
                        disabled={disabled}
                        placeholder={placeholder}
                        onChange={onChange}
                        name={name}
                    />
                );
            }}
            control={control}
            defaultValue={''}
            name={name}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
            }}
        />
    );
}

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    data: Array<any>;
    getOptionValue: (arg1?: any) => any;
    getOptionLabel: (arg1?: any) => any;
    getOptionDisabled?: (arg1?: any) => boolean;
    renderOption: (arg1?: any) => React.ReactElement;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    tooltip?: string;
    classes?: {
        optionsContainer?: string;
        optionContainer?: string;
    };
};
