/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { RestaurantEmployeeDayShiftOverviewVm } from 'src/types/RestaurantEmployeeDayShiftOverviewVm';

export async function findRestaurantEmployeeDayShiftsOverviewApi(request: FindRestaurantEmployeeDayShiftsOverviewApiRequest): ApiSauceResponse<Array<RestaurantEmployeeDayShiftOverviewVm>> {
    return letseatmanagerApiMethod('restaurantEmployeeDayShift/findRestaurantEmployeeDayShiftsOverviewApi', request);
}

type FindRestaurantEmployeeDayShiftsOverviewApiRequest = {
    restaurantId: RestaurantId;
    from: string;
    to: string;
};
