/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { app2 } from 'src/app2';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { AdminOrderVm } from 'src/types/AdminOrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { createRRule } from 'src/utils/rrule/createRRule';
import { nextFiveRRuleDates } from 'src/utils/rrule/nextFiveRRuleDates';
import { rRuleStringToText } from 'src/utils/rrule/rRuleStringToText';
import { combine, required, rrule, rruleStartDate } from 'src/utils/Validator';

export default function ChangeScheduledTaskDialog(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [scheduledTask, setScheduledTask] = useState();
    const open = useSelector((state) => state.app2.changeScheduledTaskDialog.open);
    const scheduledTaskId = useSelector((state) => state.app2.changeScheduledTaskDialog.scheduledTaskId);
    const onSuccess = useSelector((state) => state.app2.changeScheduledTaskDialog.onSuccess);
    const close = useAction(app2.actions.closeChangeScheduledTaskDialog);

    useEffect(() => {
        if (!scheduledTaskId) {
            return;
        }
        async function load() {
            const response = await letseatmanagerApiDeprecated.admin.scheduledTask({ scheduledTaskId });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            setScheduledTask(response.data);
        }

        let cancelled = false;
        load();
        return () => {
            cancelled = true;
        };
    }, [scheduledTaskId]);

    return (
        <Dialog
            fullWidth
            aria-labelledby='ChangeScheduledTaskDialog-title'
            scroll='paper'
            open={open}
            // onClose={close}
        >
            <Form
                onSubmit={async (form: any) => {
                    if (loading) return;
                    setLoading(true);
                    const response = await letseatmanagerApiDeprecated.admin.changeScheduledTask({
                        scheduledTaskId,
                        name: form.scheduledTask.name,
                        schedule: form.scheduledTask.schedule,
                    });
                    if (!response.ok) {
                        setLoading(false);
                        alertKnownErrorOrSomethingWentWrong(response);
                        return;
                    }
                    setLoading(false);
                    close();
                    const order: AdminOrderVm = response.data;
                    onSuccess && onSuccess(order);
                }}
                initialValues={{
                    scheduledTask: scheduledTask,
                }}
                render={({ handleSubmit, values }: { handleSubmit: any; values: any }) => {
                    const nextFiveRuns = nextFiveRRuleDates(createRRule(values.schedule));
                    return (
                        <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                            <DialogTitle id='ChangeScheduledTaskDialog-title'>{translate('Change Scheduled Task').toUpperCase()}</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Field name='scheduledTask.name' label={translate('Name')} component={TextField} fullWidth disabled={loading} required validate={required} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name='scheduledTask.schedule'
                                            label={translate('Schedule')}
                                            component={TextField}
                                            multiline
                                            fullWidth
                                            disabled={loading}
                                            required
                                            validate={combine(required, rrule, rruleStartDate)}
                                            helperText={rRuleStringToText(values.schedule) || 'Create it on http://jakubroztocil.github.io/rrule/'}
                                        />
                                        {nextFiveRuns.length > 0 && (
                                            <div>
                                                <div>{translate('Next five runs')}</div>
                                                {nextFiveRuns.map((date, i) => {
                                                    const text = formatDateTimeStringReadable(date, TimeZones.AMERICA_MONTERREY);
                                                    return <div key={i}>{text}</div>;
                                                })}
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={close} disabled={loading}>
                                    {translate('Cancel').toUpperCase()}
                                </Button>
                                <Button color='primary' type='submit' disabled={loading}>
                                    {loading ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                                </Button>
                            </DialogActions>
                            {loading && <LinearProgress className={classes.linearProgress} />}
                        </form>
                    );
                }}
            />
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
}));
