/**
 * @prettier
 */
import * as React from 'react';

export function CloseIcon({ title, color, size }: Props): React.ReactElement {
    return (
        <svg focusable='false' width={size || '24'} height={size || '24'} viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <path d='M8.60658 1.46446L1.53551 8.53553M8.60658 8.53553L1.53551 1.46446' stroke={color || 'currentColor'} strokeWidth='1' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
}

type Props = {
    title?: string;
    color?: string;
    size?: number;
};
