/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { CustomPaymentMethod, OrderId } from 'src/types/Id';
import type { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import type { OrderVm } from 'src/types/OrderVm';

export async function updatePaymentMethodApi(request: updatePaymentMethod): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('order/updatePaymentMethodApi', request);
}

export type updatePaymentMethod = {
    orderId: OrderId;
    paymentMethod?: PaymentMethod;
    posPayments?: Array<{
        amount: string;
        paymentMethod: CustomPaymentMethod | PaymentMethod;
        customerNumber?: number;
    }>;
    payments?: Array<OrderPaymentVm>;
};
