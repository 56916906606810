/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';
import moment from 'moment-timezone';

export function getTimeElapsed(date: string | Date): string {
    if (!date) return '';
    const format = 'YYYY-MM-DDTHH:mm:ss';

    const minutes = Math.abs(moment(new Date(), format).diff(date, 'minutes'));

    if (minutes >= 60) return getHours(minutes);

    return `${minutes} min`;
}

function getHours(minutes: number) {
    const hours = BigNumber(minutes).dividedBy(60).toFixed(0);

    const remainingMinutes = minutes % BigNumber(hours).toNumber();

    return `${hours}:${remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes} hrs`;
}
