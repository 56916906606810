/**
 * @prettier
 */
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

type Props = {
    tooltip?: string;
    onClick: any;
};

export function DuplicateToolbarButton({ tooltip, onClick }: Props): React.ReactElement {
    return (
        <Tooltip title={tooltip || translate('Duplicate')}>
            <IconButton onClick={onClick}>
                <FileCopyIcon />
            </IconButton>
        </Tooltip>
    );
}
