/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import type { SalesPerDay } from 'src/api/letseatmanager/restaurantDashboard/getGeneralRestaurantStatisticsApi';
import { ResponsiveLineCanvas } from 'src/components/charts/ResponsiveLineCanvas';
import { translate } from 'src/i18n/translate';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function SalesByDayGeneralGraph({ loading, salesByDayData }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const salesByDay = [
        {
            id: 'salesPerDay',
            color: `hsl(162, 58%, 61%)`,
            data: salesByDayData.map((item) => ({
                x: item.createdAt,
                y: item.total,
            })),
        },
    ];

    return (
        <ReportSection title={translate('Sales per Day')} loading={loading}>
            <div className={classes.graphContainer}>
                <ResponsiveLineCanvas
                    data={salesByDay}
                    tooltip={({ point }: { point: { data: { x: string; y: string } } }) => (
                        <div className={classes.tooltip}>
                            <span className={classes.tooltipText}>{translate(`Date: @date`, { date: point.data.x })}</span>
                            <span className={classes.tooltipText}>{translate(`Total: @total`, { total: formatAsCurrencyNumber(point.data.y) })}</span>
                        </div>
                    )}
                />
            </div>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    graphContainer: {
        width: '100%',
        height: '45vh',
    },
    kpisContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '95%',
        height: '30%',
        justifyContent: 'space-around',
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100%',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            flexWrap: 'nowrap',
        },
    },
    tooltip: {
        color: `#0D3037`,
        padding: 5,
        textTransform: 'capitalize',
        borderRadius: '10px',
        backgroundColor: 'whitesmoke',
    },
    tooltipText: {
        textTransform: 'uppercase',
        display: 'flex',
        flexDirection: 'column',
    },
}));

type Props = {
    loading: boolean;
    salesByDayData: Array<SalesPerDay>;
};
