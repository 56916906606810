/**
 * @prettier
 */
import { Grid, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { createBusinessApi } from 'src/api/letseatadmin/business/createBusinessApi';
import { Form } from 'src/components/form/Form';
import { FormBrandAutocompleteMultiple } from 'src/components/form/FormBrandAutocompleteMultiple';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormPhoneNumberField } from 'src/components/form/FormPhoneNumberField';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function CreateBusinessDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        onClose();
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        const response = await createBusinessApi({
            name: form.name,
            brandIds: form.brandIds,
            street: form.street,
            phoneNumber: form.phoneNumber,
            email: form.email,
        });
        setLoading(false);
        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        handleClose();
        onSuccess?.();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Create business')}</DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField name='name' label={translate('Name')} disabled={loading} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormBrandAutocompleteMultiple name='brandIds' label={translate('Brands')} disabled={loading} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='street' label={translate('Address')} disabled={loading} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormPhoneNumberField name='phoneNumber' label={translate('Phone')} disabled={loading} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormEmailField name='email' label={translate('Email')} disabled={loading} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.buttonsContainer}>
                    <Button onClick={handleClose} disabled={loading} classes={{ root: classes.cancelButton }}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading} classes={{ root: classes.acceptButton }}>
                        {loading ? translate('Creating') : translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    dialogContent: {
        overflowY: 'hidden',
    },
    buttonsContainer: {
        margin: '10px 0',
        justifyContent: 'center',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        marginLeft: '0px !important',
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess?: any;
};
