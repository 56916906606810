/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment-timezone';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { app2 } from 'src/app2';
import SelectRestaurantKitchensAndDeliveryStationsTypeahead from 'src/components/deprecated/react-final-form/SelectRestaurantKitchensAndDeliveryStationsTypeahead';
import { DownloadCsvToolbarButton } from 'src/components/mui-datatables/DownloadCsvToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import type { OrderStatus } from 'src/constants/OrderStatus';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import { RoutePaths } from 'src/constants/RoutePath';
import { MINUTES } from 'src/constants/TimeUnit';
import { TimeZone, TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import ChangeCustomerDialog from 'src/scenes/letseatadmin/customer/ChangeCustomerDialog';
import CustomerCreditsDialog from 'src/scenes/letseatadmin/customer/CustomerCreditsDialog';
import CustomersChangeDialog from 'src/scenes/letseatadmin/customer/CustomersChangeDialog';
import CustomersCreditsDialog from 'src/scenes/letseatadmin/customer/CustomersCreditsDialog';
import { CustomerSendDialog } from 'src/scenes/letseatadmin/customer/CustomerSendDialog';
import CustomersSendDialog from 'src/scenes/letseatadmin/customer/CustomersSendDialog';
import OfficeDeliveryItemsTable from 'src/scenes/letseatadmin/officeDelivery/OfficeDeliveryItemsTable';
import OfficeDeliveryStats from 'src/scenes/letseatadmin/officeDelivery/OfficeDeliveryStats';
import type { CustomerId, OrderId, RestaurantId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { objectsToCsv } from 'src/utils/csv/objectsToCsv';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { isoWeekdayToName } from 'src/utils/day/isoWeekdayToName';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';
import { useAction } from 'src/utils/react/useAction';
import { useInterval } from 'src/utils/react/useInterval';
import { useSelector } from 'src/utils/react/useSelector';

export function OfficeDeliveryPage(): React.ReactElement {
    const viewUser = useSelector((state) => state.authentication.viewUser);
    const [loading, setLoading] = useState(false);
    const [lastUpdate, setLastUpdate] = useState<Date | undefined>(undefined);
    const [year, setYear] = useState<number | undefined>(moment.tz(TimeZones.AMERICA_MONTERREY).year());
    const [week, setWeek] = useState<number | undefined>(moment.tz(TimeZones.AMERICA_MONTERREY).week());
    const [restaurantIds, setRestaurantIds] = useState(undefined);
    const [today, setToday] = useState<string | undefined>(undefined);
    const [unknown, setUnknown] = useState<Day>({
        officeDeliveryItems: [],
        stats: {},
    });
    const [monday, setMonday] = useState<Day>({
        officeDeliveryItems: [],
        stats: {},
    });
    const [tuesday, setTuesday] = useState<Day>({
        officeDeliveryItems: [],
        stats: {},
    });
    const [wednesday, setWednesday] = useState<Day>({
        officeDeliveryItems: [],
        stats: {},
    });
    const [thursday, setThursday] = useState<Day>({
        officeDeliveryItems: [],
        stats: {},
    });
    const [friday, setFriday] = useState<Day>({
        officeDeliveryItems: [],
        stats: {},
    });

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Office Delivery'));
    }, []);

    useEffect(() => {
        load();
    }, [year, week, restaurantIds]);

    useInterval(
        () => {
            load();
        },
        2 * MINUTES,
        [year, week, restaurantIds]
    );

    const load = async (skipAlert?: boolean) => {
        if (!year || !week || loading) return;
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.admin.officeDelivery({
            year,
            week,
            restaurantIds,
        });
        setLoading(false);
        if (!response.ok) {
            if (!skipAlert) return alertKnownErrorOrSomethingWentWrong(response);
        }
        const currentYear = moment.tz(TimeZones.AMERICA_MONTERREY).year();
        const currentWeek = moment.tz(TimeZones.AMERICA_MONTERREY).week();

        setLastUpdate(new Date());
        setToday(currentYear === year && currentWeek === week ? isoWeekdayToName(moment.tz(TimeZones.AMERICA_MONTERREY).isoWeekday()) : undefined);
        setUnknown(response.data.unknown);
        setMonday(response.data.monday);
        setTuesday(response.data.tuesday);
        setWednesday(response.data.wednesday);
        setThursday(response.data.thursday);
        setFriday(response.data.friday);
    };

    const getStateOfDay = (day: string): Day => {
        if (day === 'monday') return monday;
        else if (day === 'tuesday') return tuesday;
        else if (day === 'wednesday') return wednesday;
        else if (day === 'thursday') return thursday;
        else return friday;
    };

    const downloadWeekToCSV = () => {
        const weekDays = [monday, tuesday, wednesday, thursday, friday];
        const weekDaysString = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

        const csvObject = weekDays.flatMap((day, i) => day.officeDeliveryItems.map((deliveryItem) => ({ day: weekDaysString[i], ...deliveryItem })));
        const csv = objectsToCsv(csvObject);
        downloadTextInFile(csv, 'weeklyOrders.csv');
    };

    return (
        <>
            <ChangeCustomerDialog onCustomerChanged={load} />
            <CustomersChangeDialog onCustomersChanged={load} path={RoutePaths.ADMIN_OFFICE_DELIVERY_CHANGE_CUSTOMERS} />
            <CustomerSendDialog template={notificationTemplate} />
            <CustomersSendDialog template={notificationTemplate} path={RoutePaths.ADMIN_OFFICE_DELIVERY_SEND} />
            <CustomerCreditsDialog onCreditsAddedOrRemoved={load} />
            <CustomersCreditsDialog onCreditsAddedOrRemoved={load} path={RoutePaths.ADMIN_OFFICE_DELIVERY_CREDITS} />

            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <TextField
                            label={translate('Year')}
                            value={year}
                            onChange={({ target: { value } }) => setYear(parseInt(value) || undefined)}
                            onKeyPress={({ key }) => {
                                if (key === 'Enter') load();
                            }}
                            style={{ width: 280 }}
                        />
                        <TextField
                            label={translate('Week')}
                            value={week}
                            onChange={({ target: { value } }) => setWeek(parseInt(value) || undefined)}
                            onKeyPress={({ key }) => {
                                if (key === 'Enter') load();
                            }}
                            style={{ width: 280 }}
                        />
                        <DownloadCsvToolbarButton onClick={downloadWeekToCSV} />
                        <RefreshToolbarButton onClick={load} disabled={!year || !week} />
                        <Typography component='span' variant='body1' color='textSecondary'>
                            Autoupdating enabled
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ width: '100%', display: 'inline-block', zIndex: 9999 }}>
                            <SelectRestaurantKitchensAndDeliveryStationsTypeahead
                                input={{
                                    onChange: (value: any) => setRestaurantIds(value),
                                    value: restaurantIds,
                                }}
                                placeholder={translate('Filter by Kitchen or Station')}
                                selectMultiple
                                fullWidth
                            />
                        </div>
                    </Grid>
                </Grid>
                {!!unknown?.officeDeliveryItems?.length && (
                    <Grid item xs={12}>
                        <OfficeDeliveryItemsTable
                            title={translate('INVESTIGATE!!! Pickup is missing on the following office deliveries')}
                            disable={viewUser}
                            officeDeliveryItems={unknown.officeDeliveryItems}
                            loading={loading}
                            onClickRefresh={load}
                        />
                    </Grid>
                )}
                {today && getStateOfDay(today.toLowerCase())?.officeDeliveryItems && (
                    <>
                        <Grid item xs={12}>
                            <OfficeDeliveryItemsTable
                                title={`${translate('Today')} (${translate(today)})`}
                                disable={viewUser}
                                officeDeliveryItems={getStateOfDay(today.toLowerCase()).officeDeliveryItems}
                                loading={loading}
                                onClickRefresh={load}
                            />
                            <OfficeDeliveryStats stats={getStateOfDay(today.toLowerCase()).stats} officeDeliveryItems={getStateOfDay(today.toLowerCase()).officeDeliveryItems} />
                            <br />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <OfficeDeliveryItemsTable title={translate('Monday')} officeDeliveryItems={monday.officeDeliveryItems} disable={viewUser} loading={loading} onClickRefresh={load} />
                    <OfficeDeliveryStats stats={monday.stats} officeDeliveryItems={monday.officeDeliveryItems} />
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <OfficeDeliveryItemsTable title={translate('Tuesday')} officeDeliveryItems={tuesday.officeDeliveryItems} disable={viewUser} loading={loading} onClickRefresh={load} />
                    <OfficeDeliveryStats stats={tuesday.stats} officeDeliveryItems={tuesday.officeDeliveryItems} />
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <OfficeDeliveryItemsTable title={translate('Wednesday')} officeDeliveryItems={wednesday.officeDeliveryItems} loading={loading} disable={viewUser} onClickRefresh={load} />
                    <OfficeDeliveryStats stats={wednesday.stats} officeDeliveryItems={wednesday.officeDeliveryItems} />
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <OfficeDeliveryItemsTable title={translate('Thursday')} officeDeliveryItems={thursday.officeDeliveryItems} loading={loading} disable={viewUser} onClickRefresh={load} />
                    <OfficeDeliveryStats stats={thursday.stats} officeDeliveryItems={thursday.officeDeliveryItems} />
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <OfficeDeliveryItemsTable title={translate('Friday')} officeDeliveryItems={friday.officeDeliveryItems} loading={loading} disable={viewUser} onClickRefresh={load} />
                    <OfficeDeliveryStats stats={friday.stats} officeDeliveryItems={friday.officeDeliveryItems} />
                    <br />
                </Grid>
            </Grid>
            <Snackbar open={lastUpdate && moment(lastUpdate).isBefore(moment().subtract(5, 'minutes'))}>
                <Alert variant='filled' severity='error'>{`Failed to auto update orders since ${formatDateTimeStringReadable(lastUpdate)}. Check your connection!`}</Alert>
            </Snackbar>
        </>
    );
}

const notificationTemplate = {
    title: '¡Buen provecho, @name!',
    body: 'Tu comida te espera. 😋',
} as const;

type Day = {
    officeDeliveryItems: Array<OfficeDeliveryItemVm>;
    stats: Stats;
};

export type OfficeDeliveryItemVm = {
    orderId: OrderId;
    restaurantId: RestaurantId;
    customerId: CustomerId;
    customerName: string;
    customerMobileNumber: string;
    companyName?: string;
    pickupStation: string;
    name: string;
    modifiers: Array<string>;
    note?: string;
    promoCode?: string;
    pickupTime?: Date;
    paymentMethod?: PaymentMethod;
    timeZone: TimeZone;
    createdAt: Date;
    orderStatus: OrderStatus;
    pickupStationClosingTime: string;
};

export type Stats = {
    [key: string]: {
        pickupStationStats: {
            [key: string]: number;
        };
        officeDeliveryItemStats: {
            [key: string]: number;
        };
    };
};
