/**
 * @prettier
 */
import { DropDown, DropDownItem } from '@pidedirecto/ui';
import { EllipsisVerticalIcon } from '@pidedirecto/ui/icons';
import { useState } from 'react';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { ChangeModifierInModifierGroupDialog } from 'src/scenes/letseatmanager/menu/modifierGroup/ChangeModifierInModifierGroupDialog';
import type { ModifierGroupId } from 'src/types/Id';
import type { ModifierVm } from 'src/types/ModifierVm';

export function ModifierInModifierGroupContextualMenu({ modifierGroupId, modifier, onSuccess, disabled }: Props): React.ReactElement {
    const [changeModifierInModifierGroupDialogState, setChangeModifierInModifierGroupDialogState] = useState({ open: false });

    const openModifierInModifierGroupDialog = () => setChangeModifierInModifierGroupDialogState({ open: true });

    const closeModifierInModifierGroupDialog = () => setChangeModifierInModifierGroupDialogState({ open: false });

    const handleEditModifier = () => {
        openModifierInModifierGroupDialog();
    };

    return (
        <div style={{ position: 'relative' }}>
            <ChangeModifierInModifierGroupDialog
                open={changeModifierInModifierGroupDialogState.open}
                modifierGroupId={modifierGroupId}
                modifier={modifier}
                onClose={closeModifierInModifierGroupDialog}
                onSuccess={onSuccess}
            />
            <DropDown variant='icon' content={<EllipsisVerticalIcon size={20} title='options' />} disabled={disabled} position='right'>
                <DropDownItem onClick={handleEditModifier}>{translate('Edit modifier')}</DropDownItem>
            </DropDown>
        </div>
    );
}

type Props = {
    modifierGroupId: ModifierGroupId;
    modifier: ModifierVm;
    onSuccess?: any;
    disabled?: boolean;
};
