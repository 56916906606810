/**
 * @prettier
 */
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverId, RestaurantReferralId } from 'src/types/Id';

export async function getRestaurantReferralsApi(): ApiSauceResponse<RestaurantReferralsVm> {
    return letseatadminApiMethod('restaurantReferral/getRestaurantReferralsApi');
}

export type RestaurantReferralsVm = {
    restaurantReferralMessageTitle?: string;
    restaurantReferralMessageBody?: string;
    restaurantReferrals: Array<RestaurantReferralVm>;
};

type RestaurantReferralVm = {
    restaurantReferralId: RestaurantReferralId;
    driverId: DriverId;
    driverName: string;
    responsible?: string;
    businessName: string;
    contactNumber: string;
    contactName: string;
    referralMessage: string;
};
