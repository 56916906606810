/**
 * @prettier
 */
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { CREATE_BENEFIT_PLAN } from 'src/scenes/letseatadmin/company/CreateBenefitPlanDialog';
import type { CompanyId, CustomerId, EmployeeIdAtCompany, EmployeeNumberAtCompany } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { fullName } from 'src/utils/customer/fullName';
import { setUrlQueryParameter } from 'src/utils/history/setUrlQueryParameter';
import { RegExps } from 'src/utils/RegExps';

export default withStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class CompanyConnectedCustomers extends React.Component<Props, State> {
        state = {
            loading: false,
            customers: [],
        };

        async componentDidMount() {
            await this.load();
        }

        // shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
        //     return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
        // }

        load = async () => {
            this.setState({ loading: true });
            const response = await letseatmanagerApiDeprecated.admin.getCompanyConnectedCustomers({ companyId: this.props.companyId });
            this.setState({ loading: false });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            this.setState({ customers: response.data.customers });
        };

        handleClickNew = () => {
            history.push({
                search: setUrlQueryParameter(CREATE_BENEFIT_PLAN, ''),
            });
        };

        render() {
            const { classes } = this.props;
            return (
                <div style={{ position: 'relative' }}>
                    <UpdatingContentProgress loading={this.state.loading} />
                    <MUIDataTable
                        /* @ts-ignore */
                        className={classes.table}
                        title={translate('Connected Customers')}
                        data={this.state.customers.map((customer: any) => ({
                            customerId: customer.customerId,
                            name: fullName(customer.firstName, customer.lastName) ?? '',
                            mobileNumber: customer.mobileNumber ?? '',
                            email: customer.email ?? '',
                            employeeIdAtCompany: customer.employeeIdAtCompany ?? '',
                            employeeNumberAtCompany: customer.employeeNumberAtCompany ?? '',
                            payrollPaymentsEnabled: customer.payrollPaymentsEnabled ? 'Enabled' : '',
                        }))}
                        columns={[
                            {
                                name: 'customerId',
                                label: ' ',
                                options: {
                                    display: 'excluded',
                                    filter: false,
                                },
                            },
                            {
                                name: 'name',
                                label: translate('Name'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'mobileNumber',
                                label: translate('Mobile Number'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'email',
                                label: translate('Email'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'employeeIdAtCompany',
                                label: translate('Employee Id'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'employeeNumberAtCompany',
                                label: translate('Employee Number'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'payrollPaymentsEnabled',
                                label: translate('Payroll Payments Enabled'),
                                options: {
                                    searchable: false,
                                },
                            },
                        ]}
                        options={{
                            responsive: 'standard',
                            tableBodyMaxHeight: '500px',
                            selectableRows: 'none',
                            // setRowProps: (row) => {
                            //     return {
                            //         style: { backgroundColor: getCustomerstatusColor(row[30], row[32]) },
                            //     };
                            // },
                            onRowClick: (
                                rowData: Array<string>,
                                rowMeta: {
                                    dataIndex: number;
                                    rowIndex: number;
                                }
                            ) => {
                                history.push({
                                    pathname: RoutePaths.ADMIN_CUSTOMER.replace(`:customerId(${RegExps.uuid})`, rowData[0]),
                                    search: history.location.search,
                                });
                            },
                            filterType: 'multiselect',
                            rowsPerPage: 100,
                            customToolbar: () => {
                                return (
                                    <>
                                        <RefreshToolbarButton onClick={this.load} />
                                        {/*<Tooltip title="Create Benefit Plan">*/}
                                        {/*    <IconButton onClick={this.handleClickNew}>*/}
                                        {/*        <AddIcon />*/}
                                        {/*    </IconButton>*/}
                                        {/*</Tooltip>*/}
                                    </>
                                );
                            },
                            // filterList: [[], [], [], [], ['MEXICO']],
                        }}
                    />
                </div>
            );
        }
    }
) as React.ComponentType<any>;

type Props = {
    companyId: CompanyId;
    classes: any;
};

type State = {
    loading: boolean;
} & CompanyConnectedCustomersVm;

type CompanyConnectedCustomersVm = {
    customers: Array<CustomerVm>;
};

type CustomerVm = {
    customerId: CustomerId;
    firstName?: string;
    lastName?: string;
    mobileNumber?: string;
    email?: string;
    employeeIdAtCompany?: EmployeeIdAtCompany;
    employeeNumberAtCompany?: EmployeeNumberAtCompany;
    payrollPaymentsEnabled?: boolean;
};
