/**
 * @prettier
 */
import { BigNumber } from 'bignumber.js';

export function zeroStringToUndefined(value?: string | null): string | undefined {
    if (BigNumber(value || 0).isZero()) {
        return;
    }
    return value as any;
}
