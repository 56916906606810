/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { app2 } from 'src/app2';
import EmailsTable from 'src/scenes/letseatadmin/email/EmailsTable';
import type { AwsMessageId, CustomerId, DriverId, EmailAddress, EmailId, EmailTemplateId, ScheduledTaskId, ScheduledTaskRunId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';

export function EmailsPage(): React.ReactElement {
    const classes = useStyles();

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle('Sent Emails');
    }, []);

    return (
        <div className={classes.container}>
            <EmailsTable />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
}));

export type EmailsVm = {
    emails: Array<EmailVm>;
};

export type EmailVm = {
    emailId: EmailId;
    to: EmailAddress;
    awsMessageId: AwsMessageId;
    customerId?: CustomerId;
    driverId?: DriverId;
    scheduledTaskId?: ScheduledTaskId;
    scheduledTaskName?: string;
    scheduledTaskRunId?: ScheduledTaskRunId;
    emailTemplateId?: EmailTemplateId;
    emailTemplateName?: string;
    subject?: string;
    bodyHtml?: string;
    bodyText?: string;
    sentAt: Date;
    deliveredAt?: Date;
    bouncedAt?: Date;
    bounceType?: string;
    bounceSubType?: string;
    complainedAt?: Date;
    complaintType?: string;
    modifiedAt: Date;
};
