/**
 * @prettier
 */
export const CreatePrinterSteps = {
    PRINTER_TYPE: 'PRINTER_TYPE',
    PRINTER_NAME: 'PRINTER_NAME',
    SERIAL_NUMBER: 'SERIAL_NUMBER',
    IP_ADDRESS: 'IP_ADDRESS',
    PORT: 'PORT',
    DOWNLOAD_DRIVERS: 'DOWNLOAD_DRIVERS',
    PRINTER_BRAND: 'PRINTER_BRAND',
    CHANNELS: 'CHANNELS',
    PRINTER_PAPER_SIZE: 'PRINTER_PAPER_SIZE',
    PRINTER_CONTENT: 'PRINTER_CONTENT',
    CONFIRMATION: 'CONFIRMATION',
} as const;

export type CreatePrinterStep = typeof CreatePrinterSteps[keyof typeof CreatePrinterSteps];
