/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useState } from 'react';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { CashRegisterCustomReasons } from 'src/api/letseatmanager/theRestaurant/getTheRestaurantApi';
import { Button } from 'src/components/Button';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { CloseIcon } from 'src/icons/CloseIcon';
import { AddCashRegisterDepositCustomReasonDialog } from 'src/scenes/letseatmanager/theRestaurant/cashRegister/AddCashRegisterDepositCustomReasonDialog';
import { RemoveCashRegisterDepositCustomReasonDialog } from 'src/scenes/letseatmanager/theRestaurant/cashRegister/RemoveCashRegisterDepositCustomReasonDialog';
import { CashRegisterCustomReason } from 'src/types/Id';

export function CashRegisterDepositReasonsSettings({ onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    const { control } = useFormContext();

    const cashRegisterDepositReasons = useWatch<CashRegisterCustomReasons>({ name: 'cashRegisterDepositReasons', control });

    const [addCashRegisterDepositCustomReasonDialogState, setAddCashRegisterDepositCustomReasonDialogState] = useState({ open: false });
    const [removeCashRegisterDepositReasonState, setRemoveAddCashRegisterDepositReasonState] = useState<{ open: boolean; reason: CashRegisterCustomReason | undefined }>({
        open: false,
        reason: undefined,
    });

    const openAddCashRegisterDepositReasonDialog = () => setAddCashRegisterDepositCustomReasonDialogState({ open: true });

    const closeAddCashRegisterDepositReasonDialog = () => setAddCashRegisterDepositCustomReasonDialogState({ open: false });

    const closeRemoveCashRegisterDepositReasonDialog = () => setRemoveAddCashRegisterDepositReasonState({ open: false, reason: undefined });

    const openRemoveCashRegisterDepositReasonDialog = (reason: any) => setRemoveAddCashRegisterDepositReasonState({ open: true, reason });

    return (
        <>
            <AddCashRegisterDepositCustomReasonDialog open={addCashRegisterDepositCustomReasonDialogState.open} onClose={closeAddCashRegisterDepositReasonDialog} onSuccess={onSuccess} />
            <RemoveCashRegisterDepositCustomReasonDialog
                open={removeCashRegisterDepositReasonState.open}
                onSuccess={onSuccess}
                reason={removeCashRegisterDepositReasonState.reason}
                onClose={closeRemoveCashRegisterDepositReasonDialog}
            />
            <div className={classes.section}>
                <h2 className={classes.subtitle}>
                    {translate('Custom Deposit Reasons')}
                    <Tooltip
                        text={translate(
                            'Define the personalized deposit reasons, (for example, cash, customer accounts receivable, etc.) these will be used later when making a deposit in boxes, you can remove and add new ones'
                        )}
                    />
                </h2>
                <div className={classes.itemsContainer}>
                    {cashRegisterDepositReasons?.map((depositReason, index) => (
                        <div className={classes.cashRegisterCustomReasonItem} key={index}>
                            {depositReason.reason}
                            <Button icon onClick={() => openRemoveCashRegisterDepositReasonDialog(depositReason.reason)} classes={{ button: classes.iconButton }}>
                                <CloseIcon size={14} title={translate('Remove deposit reason')} />
                            </Button>
                        </div>
                    ))}
                </div>
                <Button outlined onClick={openAddCashRegisterDepositReasonDialog}>
                    <AddIcon />
                    {translate('Add Deposit Reason')}
                </Button>
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
    },
    section: {
        width: '100%',
        borderRadius: 5,
        border: `1px solid ${theme.palette.secondary.dark}`,
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        gap: 20,
    },
    itemsContainer: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    cashRegisterCustomReasonItem: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        minWidth: 140,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #676060B2',
        borderRadius: 6,
        padding: '5px 10px',
        gap: 5,
        height: '100%',
        '&:hover': {
            backgroundColor: '#D9D9D933',
        },
    },
    iconButton: {
        width: 'fit-content',
        height: 'fit-content',
        borderRadius: '50%',
        minHeight: 26,
        padding: 0,
    },
}));

type Props = {
    onSuccess: any;
};
