/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuId, MenuItemId } from 'src/types/Id';
import type { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';

export async function changeMenuProductInfoApi(request: ChangeMenuProductInfoApiRequest): ApiSauceResponse<Array<ManagerMenuItemVm>> {
    return letseatmanagerApiMethod('menuItem/changeMenuProductInfoApi', request);
}

type ChangeMenuProductInfoApiRequest = {
    menuItemId: MenuItemId;
    menuId: MenuId;
    price: number;
    promoPrice?: number;
    hidden?: boolean;
};
