/**
 * @prettier
 */
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RemoveIcon from '@material-ui/icons/Remove';
import * as React from 'react';
import { useState } from 'react';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function ManageMenu({
    onClickMoveLeft,
    onClickMoveRight,
    onClickMoveUp,
    onClickMoveDown,
    onClickMoveToMenu,
    onClickChange,
    onClickDuplicate,
    onClickCreate,
    onClickRemove,
}: Props): React.ReactElement {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const handleClickOpen = (event: any) => setAnchorEl(event.currentTarget);

    const handleClickMenu = (callback: any) => {
        return (event: any) => {
            callback();
            setAnchorEl(null);
        };
    };

    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);

    return (
        <div>
            <IconButton aria-label='More' aria-owns={open ? 'long-menu' : undefined} aria-haspopup='true' onClick={handleClickOpen} disabled={viewUser}>
                <MoreVertIcon />
            </IconButton>
            <Menu id='long-menu' anchorEl={anchorEl} open={open} onClose={handleClose}>
                {onClickMoveLeft && (
                    <MenuItem onClick={handleClickMenu(onClickMoveLeft)}>
                        <ListItemIcon>
                            <ArrowBackIcon />
                        </ListItemIcon>
                        <ListItemText classes={{ root: classes.item }} primary={translate('Move Left')} />
                    </MenuItem>
                )}
                {onClickMoveRight && (
                    <MenuItem onClick={handleClickMenu(onClickMoveRight)}>
                        <ListItemIcon>
                            <ArrowForwardIcon />
                        </ListItemIcon>
                        <ListItemText classes={{ root: classes.item }} primary={translate('Move Right')} />
                    </MenuItem>
                )}
                {onClickMoveUp && (
                    <MenuItem onClick={handleClickMenu(onClickMoveUp)}>
                        <ListItemIcon>
                            <ArrowUpwardIcon />
                        </ListItemIcon>
                        <ListItemText classes={{ root: classes.item }} primary={translate('Move Up')} />
                    </MenuItem>
                )}
                {onClickMoveDown && (
                    <MenuItem onClick={handleClickMenu(onClickMoveDown)}>
                        <ListItemIcon>
                            <ArrowDownwardIcon />
                        </ListItemIcon>
                        <ListItemText classes={{ root: classes.item }} primary={translate('Move Down')} />
                    </MenuItem>
                )}
                {onClickMoveToMenu && (
                    <MenuItem onClick={handleClickMenu(onClickMoveToMenu)}>
                        <ListItemIcon>
                            <ArrowForwardIcon />
                        </ListItemIcon>
                        <ListItemText classes={{ root: classes.item }} primary={translate('Move To Menu')} />
                    </MenuItem>
                )}
                {onClickChange && (
                    <MenuItem onClick={handleClickMenu(onClickChange)}>
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <ListItemText classes={{ root: classes.item }} primary={translate('Change')} />
                    </MenuItem>
                )}
                {onClickDuplicate && (
                    <MenuItem onClick={handleClickMenu(onClickDuplicate)}>
                        <ListItemIcon>
                            <FileCopyIcon />
                        </ListItemIcon>
                        <ListItemText classes={{ root: classes.item }} primary={translate('Duplicate')} />
                    </MenuItem>
                )}
                {onClickCreate && (
                    <MenuItem onClick={handleClickMenu(onClickCreate)}>
                        <ListItemIcon>
                            <AddIcon />
                        </ListItemIcon>
                        <ListItemText classes={{ root: classes.item }} primary={translate('Create')} />
                    </MenuItem>
                )}
                {onClickRemove && (
                    <MenuItem onClick={handleClickMenu(onClickRemove)}>
                        <ListItemIcon>
                            <RemoveIcon />
                        </ListItemIcon>
                        <ListItemText classes={{ root: classes.item }} primary={translate('Remove')} />
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    item: {
        '& span': {
            fontFamily: theme.typography.regular,
        },
    },
}));

type Props = {
    onClickMoveLeft?: any;
    onClickMoveRight?: any;
    onClickMoveUp?: any;
    onClickMoveDown?: any;
    onClickMoveToMenu?: any;
    onClickChange?: any;
    onClickDuplicate?: any;
    onClickCreate?: any;
    onClickRemove?: any;
};
