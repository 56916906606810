/**
 * @prettier
 */
export function createFilteredOnDownload(that: any): any {
    if (!that.table) return;
    return (buildHead: any, buildBody: any, columns: any) => {
        if (!that.table.state.selectedRows.data.length) {
            return buildHead(columns) + buildBody(that.table.state.displayData);
        }
        const selectedRowsDataIndexes = that.table.state.selectedRows.data.map((d: any) => d.dataIndex);
        const selectedData = that.table.state.displayData.filter((d: any) => selectedRowsDataIndexes.includes(d.dataIndex));
        return buildHead(columns) + buildBody(selectedData);
    };
}
