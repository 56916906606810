/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { GlobalInvoiceSummaryVm } from 'src/types/GlobalInvoiceSummaryVm';
import type { RestaurantId } from 'src/types/Id';

export async function findRestaurantGlobalInvoicesApi(request: FindRestaurantGlobalInvoicesApiRequest): ApiSauceResponse<GlobalInvoicesSummary> {
    return letseatmanagerApiMethod('invoice/findRestaurantGlobalInvoicesApi', request);
}

export type FindRestaurantGlobalInvoicesApiRequest = {
    restaurantId: RestaurantId;
    from: Date;
    to: Date;
};

export type GlobalInvoicesSummary = {
    invoices: Array<GlobalInvoiceSummaryVm>;
};
