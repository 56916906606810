/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { InvoiceId } from 'src/types/Id';

export async function getInvoiceUriApi(request: GetInvoiceUriApiRequest): ApiSauceResponse<GetInvoiceUriApiResponse> {
    return letseatmanagerApiMethod('invoice/getInvoiceUriApi', request);
}

export type GetInvoiceUriApiRequest = {
    invoiceId: InvoiceId;
    documentType: 'xml' | 'pdf';
};

type GetInvoiceUriApiResponse = {
    uri: string;
};
