/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuId } from 'src/types/Id';

export async function createRestaurantMenuPromotionApi(request: CreateRestaurantMenuPromotionApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menu/createRestaurantMenuPromotionApi', request);
}

export type CreateRestaurantMenuPromotionApiRequest = {
    menuId: MenuId;
    promoDiscount: number;
};
