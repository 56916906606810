/**
 * @prettier
 */
const styles = (theme: any): any => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonContainer: {
        paddingTop: '30px',
    },
    yearHeader: {
        position: 'relative',
        textAlign: 'center',
    },
    monthHeader: {
        position: 'relative',
        textAlign: 'center',
    },
    emptyDescription: {
        paddingTop: '200px',
        position: 'absolute',
        textAlign: 'center',
        width: '100%',
        maxWidth: 700,
    },
    positionAbsoluteTopRight: {
        position: 'absolute',
        right: 0,
        top: -8,
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
        },
    },
    card: {
        width: 420,
        height: 'fit-content',
        border: `1px solid ${theme.palette.secondary.dark}`,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(90% - 20px)',
        },
    },
    ordersContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
    },
    orderContainer: {
        paddingBottom: theme.spacing(2),
        display: 'flex',
        flexShrink: 0,
        margin: '0 10px',
        height: 'fit-content',
        width: 'calc(50% - 20px)',
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 20px)',
            justifyContent: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(90% - 20px)',
        },
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
        '& .MuiInputBase-root': {
            fontFamily: `${theme.typography.regular} !important`,
            fontSize: 14,
            backgroundColor: theme.palette.secondary.light,
            borderRadius: 5,
            padding: '5px 0 5px 15px',
        },
        '& .MuiInputBase-root::before': {
            display: 'none',
        },
        '& .MuiInputBase-root::after': {
            display: 'none',
        },
        '& .MuiFormControl-root:first-child': {
            marginRight: 10,
        },
        '& label': {
            fontFamily: theme.typography.regular,
            fontSize: 15,
            color: theme.palette.primary.main,
        },
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '70%',
        marginBottom: 5,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    textBold: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
    },
    button: {
        width: '70%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontFamily: theme.typography.medium,
        textTransform: 'none',
        marginTop: 20,
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main}aa`,
        },
    },
    buttonSecondary: {
        width: '70%',
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        fontFamily: theme.typography.medium,
        textTransform: 'none',
        marginTop: 20,
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    menuTabText: {
        fontFamily: theme.typography.medium,
        textTransform: 'none',
    },
});

export default styles;
