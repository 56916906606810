/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerOrderDetailsVm } from 'src/types/CustomerOrderDetailsVm';
import type { OrderId, RestaurantId } from 'src/types/Id';

export async function updateNumberOfCustomersApi(request: UpdateNumberOfCustomersApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('order/updateNumberOfCustomersApi', request);
}

export type UpdateNumberOfCustomersApiRequest = {
    restaurantId: RestaurantId;
    orderId: OrderId;
    numberOfCustomers: number;
    newCustomers?: Array<CustomerOrderDetailsVm>;
};
