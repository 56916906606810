/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { PedidosYaRestaurantId, PedidosYaStoreId, RestaurantId } from 'src/types/Id';

export async function requestPedidosYaIntegrationApi(request: RequestPedidosYaIntegrationApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurantIntegration/requestPedidosYaIntegrationApi', request);
}

export type RequestPedidosYaIntegrationApiRequest = {
    restaurantId: RestaurantId;
    pedidosYaRestaurantId: PedidosYaRestaurantId;
    pedidosYaStoreId: PedidosYaStoreId;
};
