/**
 * @prettier
 */
import { IconButton, makeStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import moment from 'moment-timezone';
import * as React from 'react';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { searchLogEventsApi } from 'src/api/letseatadmin/logEvent/searchLogEventsApi';
import { LogEventVm } from 'src/api/letseatmanager/types/LogEventVm';
import { Button } from 'src/components/Button';
import { DateSelectDialog } from 'src/components/dialog/DateSelectDialog';
import { Form } from 'src/components/form/Form';
import { FormDatePicker } from 'src/components/form/FormDatePicker';
import { FormRestaurantsAutocomplete } from 'src/components/form/FormRestaurantsAutocomplete';
import { FormTextField } from 'src/components/form/FormTextField';
import { translateLogEventType } from 'src/components/form/utils/translateLogEventType';
import { Page } from 'src/components/Page';
import { AppSelect } from 'src/components/select/AppSelect';
import { LogEventLevelsSelect } from 'src/components/select/logEvent/LogEventLevelsSelect';
import { LogEventTypesSelect } from 'src/components/select/logEvent/LogEventTypesSelect';
import { LogEventUserTypesSelect } from 'src/components/select/logEvent/LogEventUserTypesSelect';
import { SimpleTable } from 'src/components/SimpleTable';
import { Tooltip } from 'src/components/Tooltip';
import { App } from 'src/constants/App';
import { LogEventLevel } from 'src/constants/LogEventLevel';
import { LogEventType } from 'src/constants/LogEventType';
import { SystemUserType } from 'src/constants/SystemUserType';
import { translate } from 'src/i18n/translate';
import { SearchIcon } from 'src/icons/SearchIcon';
import { DetailsDialog } from 'src/scenes/letseatadmin/logEvents/DetailsDialog';
import type { Uuid } from 'src/types/Id';
import { formatDateTimeSecondsString } from 'src/utils/date/formatDateTimeSecondsString';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { isBlankString } from 'src/utils/string/isBlankString';
import { trim } from 'src/utils/string/trim';
import { openRestaurantPageInNewTab } from 'src/utils/window/openRestaurantPageInNewTab';

export function AdminLogEventsPage(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const { control } = form;

    const [detailsDialogState, setDetailsDialogState] = useState<{ open: boolean; logEvent: LogEventVm | undefined }>({ open: false, logEvent: undefined });
    const [logEventTypes, setLogEventTypes] = useState<Array<LogEventType>>([]);
    const [logEventLevels, setLogEventLevels] = useState<Array<LogEventLevel>>([]);
    const [systemUserTypes, setSystemUserTypes] = useState<Array<SystemUserType>>([]);
    const [apps, setApps] = useState<Array<App>>([]);

    const defaultFrom = moment().toDate();
    const defaultTo = moment().toDate();
    const from = useWatch({ name: 'from', control, defaultValue: defaultFrom });
    const to = useWatch({ name: 'to', control, defaultValue: defaultTo });

    const [loading, logEvents, refreshLogEvents] = useLoadApi(searchLogEventsApi, mapFormToRequest(form.getValues(), from, to), { initialValue: [] });

    const columns = [
        {
            id: 'logEventLevel',
            content: translate('Level'),
            size: 1.5,
        },
        {
            id: 'logEventType',
            content: translate('Type'),
        },
        {
            id: 'message',
            content: translate('Message'),
            size: 3,
        },
        {
            id: 'details',
            content: translate('Details'),
        },
        {
            id: 'user',
            content: translate('User'),
        },
        {
            id: 'systemUserTypes',
            content: translate('User Type'),
        },
        {
            id: 'ipAddress',
            content: translate('IP'),
        },
        {
            id: 'restaurantName',
            content: translate('Restaurant Name'),
        },
        {
            id: 'restaurantId',
            content: translate('Open Restaurant in New Tab'),
        },
        {
            id: 'occurredAt',
            content: translate('Date'),
        },
    ];

    function mapFormToRequest(form: any, from: any, to: any): any {
        return {
            logEventTypes: logEventTypes ?? [],
            logEventLevels: logEventLevels ?? [],
            systemUserTypes: systemUserTypes ?? [],
            restaurantIds: form.restaurantIds ?? [],
            relatedItems: parseFormRelatedItems(form.relatedItems),
            apps: apps ?? [],
            from: moment(from).startOf('day'),
            to: moment(to).endOf('day'),
        };
    }

    const onSubmit = async (form: any) => {
        await refreshLogEvents({ request: mapFormToRequest(form, form.from, form.to) });
    };
    return (
        <Page title={translate('Log Events')}>
            <DateSelectDialog />
            <Form form={form} onSubmit={onSubmit}>
                <div className={classes.formContent}>
                    <div style={{ display: 'flex', alignItems: 'flex-end', gap: 24, justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-end', gap: 24 }}>
                            <FormDatePicker name='from' label={translate('From')} defaultValue={defaultFrom} />
                            <FormDatePicker name='to' label={translate('To')} defaultValue={defaultTo} />
                        </div>
                        <FormTextField
                            name='relatedItems'
                            placeholder={translate('Search by ids, usernames, phone numbers or any detail')}
                            classes={{ input: classes.input }}
                            startAdornment={<SearchIcon color={'#616B79'} />}
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-end', gap: 12, justifyContent: 'space-between' }}>
                        <div style={{ width: 450 }}>
                            <FormRestaurantsAutocomplete name='restaurantIds' label={translate('Restaurants')} placeholder={translate('All')} />
                        </div>
                        <div style={{ display: 'flex', gap: 12, width: 'fit-content' }}>
                            <LogEventTypesSelect labelAsPlaceholder multiselectable onChange={(logEventTypes) => setLogEventTypes(logEventTypes)} />
                            <LogEventLevelsSelect labelAsPlaceholder multiselectable onChange={(logEventLevels) => setLogEventLevels(logEventLevels)} />
                            <LogEventUserTypesSelect labelAsPlaceholder multiselectable onChange={(systemUserTypes) => setSystemUserTypes(systemUserTypes)} />
                            <AppSelect labelAsPlaceholder multiselectable onChange={(apps) => setApps(apps)} />
                        </div>
                        <Button primary type={'submit'}>
                            {translate('Refresh')}
                        </Button>
                    </div>
                </div>
            </Form>
            <DetailsDialog open={detailsDialogState.open} logEvent={detailsDialogState.logEvent} onClose={() => setDetailsDialogState({ open: false, logEvent: undefined })} />
            <SimpleTable
                columns={columns}
                rows={logEvents?.map((logEvent) => {
                    return {
                        logEventLevel: logEvent.logEventLevel,
                        logEventType: translateLogEventType(logEvent.logEventType),
                        message: logEvent.message,
                        details: (
                            <Tooltip text={translate('See log details')} classes={{ text: classes.tooltipText }}>
                                <IconButton onClick={() => setDetailsDialogState({ open: true, logEvent })}>
                                    <HelpOutlineIcon fontSize={'small'} color={'primary'} />
                                </IconButton>
                            </Tooltip>
                        ),
                        restaurantName: logEvent.restaurant?.name ?? '',
                        restaurantId: logEvent.restaurant?.restaurantId && (
                            <Tooltip text={translate('Open restaurant')} classes={{ text: classes.tooltipText }}>
                                <IconButton onClick={() => openRestaurantPageInNewTab(logEvent?.restaurant?.restaurantId)}>
                                    <HelpOutlineIcon fontSize={'small'} color={'primary'} />
                                </IconButton>
                            </Tooltip>
                        ),
                        user: logEvent?.systemUser?.name ?? '',
                        systemUserTypes: logEvent.systemUser?.systemUserType ?? '',
                        ipAddress: logEvent.ipAddress ?? '',
                        occurredAt: formatDateTimeSecondsString(logEvent.occurredAt),
                    };
                })}
                classes={{ table: classes.table, cell: (classes as any).cell }}
            />
        </Page>
    );
}

function parseFormRelatedItems(relatedItems: string): Array<Uuid> | undefined {
    if (isBlankString(relatedItems)) {
        return;
    }
    return relatedItems.split(',').map((relatedItem) => trim(relatedItem)) as Array<Uuid>;
}

const useStyles = makeStyles((theme) => ({
    formContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
    },
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
    table: {
        margin: '24px 0',
        paddingBottom: 24,
        minHeight: '150vh',
        overflowY: 'scroll',
        overflowX: 'scroll',
    },
    tooltipText: {
        backgroundColor: '#616b79',
        left: '50%',
        top: '80%',
        transform: 'translateX(-50%)',
    },
    advancedFiltersContainer: {
        padding: '24px 42px',
        display: 'flex',
        gap: 42,
    },
    accordion: {
        overflow: 'visible',
        marginBottom: 24,
    },
    input: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        color: '#191919',
        width: '100%',
        minWidth: 550,
        [theme.breakpoints.down('sm')]: {
            minWidth: 300,
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: 200,
        },
        fontFamily: theme.typography.light,
        backgroundColor: 'transparent',
        outline: 0,
        border: '1px solid #D9D9D9',
        borderRadius: 8,
        height: 42,
        '&::placeholder': {
            fontSize: 10,
        },
        '&:focus': {
            outline: '2px solid #1A73F9',
        },
    },
    dateSelect: {
        minWidth: 200,
    },
    userNameInput: {
        minWidth: 300,
    },
}));
