/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { ResponsiveBar } from '@nivo/bar';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { classNames } from 'src/utils/react/classNames';

export function BarChartStackedStyleCanvas({ data, keys, indexBy, tooltip, classes }: Props): React.ReactElement {
    const defaultClasses = useStyles();

    return (
        <div className={classNames(defaultClasses.container, classes?.container)}>
            <ResponsiveBar
                data={data}
                keys={keys}
                indexBy={indexBy}
                margin={{
                    top: 70,
                    right: 0,
                    bottom: 30,
                    left: 60,
                }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'nivo' }}
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: translate('Amount'),
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -15,
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                }}
                tooltip={tooltip}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
    },
}));

type Props = {
    data: Array<any>;
    keys: Array<string>;
    indexBy: string;
    labelY: string;
    labelX?: string;
    tooltip?: any;
    classes?: {
        container?: string;
    };
};
