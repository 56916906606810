/**
 * @prettier
 */
import Grid from '@material-ui/core/Grid';
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { createRestaurantZoneApi } from 'src/api/letseatmanager/pos/createRestaurantZoneApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateRestaurantZoneDialog(): React.ReactElement {
    const form = useForm();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const open = useSelector((state) => state.pos.createRestaurantZoneDialog.open);
    const onSuccess = useSelector((state) => state.pos.createRestaurantZoneDialog.onSuccess);

    const close = useAction(posReducer.actions.closeCreateRestaurantZoneDialog);

    const handleSubmit = async (form: any) => {
        setLoading(true);
        const response = await createRestaurantZoneApi({
            restaurantId,
            name: form.name,
        });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        onSuccess?.();
    };

    return (
        <Dialog open={open} onClose={close} title={translate('Create Restaurant Zone')}>
            <Form onSubmit={handleSubmit} form={form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name='name' disabled={loading} label={translate('Name')} required />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button secondary onClick={close} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {loading ? translate('Creating') : translate('Create')}{' '}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}
