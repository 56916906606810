/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormTableShapeSelect } from 'src/components/form/FormTableShapeSelect';
import { FormTableSizeSelect } from 'src/components/form/FormTableSizeSelect';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateTableDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const open = useSelector((state) => state.pos.createTableDialog.open);
    const restaurantZoneId = useSelector((state) => state.pos.createTableDialog.restaurantZoneId);
    const onSuccess = useSelector((state) => state.pos.createTableDialog.onSuccess);

    const close = useAction(posReducer.actions.closeCreateTableDialog);

    const handleSubmit = (form: any) => {
        onSuccess({
            restaurantZoneId,
            tableNumber: form.tableNumber.toString(),
            tableSize: form.tableSize,
            tableShape: form.tableShape,
        });
    };

    return (
        <Dialog title={translate('Create Table')} open={open} onClose={close}>
            <Form onSubmit={handleSubmit} form={form} className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name='tableNumber' label={translate('Table number')} required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTableSizeSelect name='tableSize' label={translate('Size')} required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTableShapeSelect name='tableShape' label={translate('Shape')} required />
                    </Grid>
                </Grid>
                <DialogActions className={classes.buttonsContainer}>
                    <Button secondary type='button' onClick={close}>
                        {translate('Cancel')}
                    </Button>
                    <Button primary type='submit'>
                        {translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}));
