/**
 * @prettier
 */
import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { InventorySuppliersReportVm } from 'src/types/InventorySuppliersReportVm';

export async function getInventorySuppliersReportApi(request: GetInventorySuppliersReportApiRequest): ApiSauceResponse<Array<InventorySuppliersReportVm>> {
    return pidedirectoReportsMethod('/getInventorySuppliersReportApi', request);
}

type GetInventorySuppliersReportApiRequest = {
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
};
