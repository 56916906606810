/**
 * @prettier
 */
import * as React from 'react';
import { ContextualMenuItem } from 'src/components/ContextualMenuItem';
import { translate } from 'src/i18n/translate';
import { useChargeOrder } from 'src/services/pos/useChargeOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { findItemsChangedInOrder } from 'src/utils/pos/findItemsChangedInOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function ChargeContextualMenuItem({ disabled }: Props): React.ReactElement | null {
    const { handlePay } = useChargeOrder();

    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const orderType = useSelector((state) => state.pos.orderType);
    const hasPendingOrder = useSelector((state) => !!state.pos.pendingOrder);
    const posPendingOrdersEnabled = useSelector((state) => state.app.restaurant.posPendingOrdersEnabled);
    const total = useSelector((state) => state.pos.payment?.total);
    const items = useSelector((state) => state.pos.items);
    const cancelledItems = useSelector((state) => state.pos.cancelledItems);

    const itemsToPrint = findItemsChangedInOrder(pendingOrder?.orderItems ?? ([] as any), items);
    const showChargeAndCloseOption = isEatHereOrder(orderType) && !hasPendingOrder && posPendingOrdersEnabled;
    const disabledButtons = disabled || (!total && (!itemsToPrint?.length || !cancelledItems.length));

    if (!showChargeAndCloseOption) return null;

    return <ContextualMenuItem label={translate('Charge and close')} disabled={disabledButtons} onClick={handlePay} />;
}

type Props = {
    disabled?: boolean;
};
