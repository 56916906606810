/**
 * @prettier
 */
import { DeviceType, DeviceTypes } from 'src/constants/DeviceType';

export function translateDeviceType(deviceType?: DeviceType): string {
    if (!deviceType) return '';
    switch (deviceType) {
        case DeviceTypes.MACINTOSH:
            return 'Macintosh';
        case DeviceTypes.PC:
            return 'PC';
        case DeviceTypes.TABLET:
            return 'Tablet';
        case DeviceTypes.MOBILE:
            return 'Mobile';
        default:
            return deviceType ?? '';
    }
}
