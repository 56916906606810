/**
 * @prettier
 */
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions } from '@pidedirecto/ui';
import { BigNumber } from 'bignumber.js';
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'src/components/form/Form';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormPhoneNumberCustomerAutocomplete } from 'src/components/form/FormPhoneNumberCustomerAutocomplete';
import { translate } from 'src/i18n/translate';
import { useSetCustomerEmail } from 'src/modules/pos/customer/useSetCustomerEmail';
import { useSetCustomerFirstName } from 'src/modules/pos/customer/useSetCustomerFirstName';
import { useSetCustomerMobileNumber } from 'src/modules/pos/customer/useSetCustomerMobileNumber';
import { posReducer } from 'src/reducers/posReducer';
import { GiftVm } from 'src/types/GiftVm';
import type { RestaurantCustomerVm } from 'src/types/RestaurantCustomerVm';
import { sumValidGifts } from 'src/utils/gift/sumValidGifts';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function ApplyCustomerCreditsDialog({ open, onClose }: Props): React.ReactElement {
    const classes = useStyles();

    const form = useForm();
    const { setValue } = form;

    const setCustomerFirstName = useSetCustomerFirstName();
    const setCustomerEmail = useSetCustomerEmail();
    const setCustomerMobileNumber = useSetCustomerMobileNumber();

    const [restaurantCustomer, setRestaurantCustomer] = useState<RestaurantCustomerVm | any>(undefined);

    const total = useSelector((state) => state.pos.payment?.total);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const driverRequest = useSelector((state) => state.pos.driverRequest);
    const totalPaid = useSelector((state) => state.pos.totalPaid);

    const applyCustomerCredits = useAction(posReducer.actions.applyCustomerCredits);
    const setCustomerId = useAction(posReducer.actions.setCustomerId);

    const availableGifts = restaurantCustomer?.gifts?.filter((gift: GiftVm) => {
        if (!gift.restrictedToDelivery && !gift.restaurantIds) return true;
        if (!gift.restaurantIds) return true;
        return gift.restaurantIds?.includes(restaurantId);
    });
    const totalWithOutDeliveryCost = BigNumber(total)
        .minus(driverRequest?.deliveryPaidByRestaurant ? 0 : driverRequest?.deliveryCost ?? 0)
        .toString();
    const totalCredits = BigNumber(restaurantCustomer?.credits ?? 0)
        .plus(sumValidGifts(availableGifts))
        .toString();

    const customerHasCredits = !BigNumber(totalCredits).isEqualTo(0);
    const availableCredits = BigNumber(totalCredits).isGreaterThanOrEqualTo(totalWithOutDeliveryCost) ? totalWithOutDeliveryCost : totalCredits;

    const remainingPayment = BigNumber(totalWithOutDeliveryCost).minus(BigNumber(totalPaid).toNumber());
    const getMaximumCreditsToUse = useCallback(() => {
        const availableCreditsNumber = BigNumber(availableCredits).toNumber();
        const remainingPaymentNumber = BigNumber(remainingPayment).toNumber();
        return Math.min(availableCreditsNumber, remainingPaymentNumber);
    }, [availableCredits, remainingPayment]);

    useEffect(() => {
        setValue('creditsToUse', getMaximumCreditsToUse());
    }, [getMaximumCreditsToUse, restaurantCustomer, setValue]);

    useEffect(() => {
        return () => {
            if (!open) setRestaurantCustomer(undefined);
        };
    }, [open]);

    const handleSelectRestaurantCustomerMobileNumber = async (restaurantCustomerSelected: any) => setRestaurantCustomer(restaurantCustomerSelected);

    const applyCredits = async (form: any) => {
        applyCustomerCredits({
            useLetsEatCredits: true,
            usedCustomerCredits: form.creditsToUse,
        });

        setCustomerId(restaurantCustomer.customerId);

        if (restaurantCustomer) {
            setCustomerFirstName(restaurantCustomer.name);
            setCustomerEmail(restaurantCustomer.email);
            setCustomerMobileNumber(restaurantCustomer.mobileNumber);
        }

        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} title={translate('Identify Customer')} classes={{ dialog: classes.dialog }}>
            <Form form={form} className={classes.container} onSubmit={applyCredits}>
                <FormPhoneNumberCustomerAutocomplete required={true} onSelect={handleSelectRestaurantCustomerMobileNumber} name='restaurantCustomerPhoneNumber' label={translate('Phone Number')} />
                <FormNumberField
                    name='creditsToUse'
                    placeholder={translate('@creditsToUse applicable credits', { creditsToUse: getMaximumCreditsToUse() })}
                    label={translate('Credits to use')}
                    min={1}
                    max={getMaximumCreditsToUse()}
                    required
                />
                {customerHasCredits && <Typography className={classes.creditsText}>{translate('Total Credits: @customerCredits', { customerCredits: totalCredits })}</Typography>}
                {!!restaurantCustomer && !customerHasCredits && (
                    <Typography className={classNames(classes.creditsText, classes.noCreditsFound)}>{translate('Customer not has available credits')}</Typography>
                )}
                <DialogActions className={classes.dialogActions}>
                    <Button variant={'secondary'} classes={{ button: classes.button }} onClick={onClose}>
                        {translate('Cancel')}
                    </Button>
                    <Button disabled={!customerHasCredits} classes={{ button: classes.button }} type='submit'>
                        {translate('Accept')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        marginBottom: 20,
    },
    button: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    creditsText: {
        margin: 0,
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        color: '#38bcb2',
    },
    noCreditsFound: {
        color: '#fa6684',
    },
    dialog: {
        overflow: 'visible',
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: '30vw',
        },
    },
    dialogActions: {
        gap: 12,
        marginTop: 12,

        justifyContent: 'flex-start',
        '& button': {
            width: '100%',
        },
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess?: any;
};
