/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { SupplyDetailsBySupplier } from 'src/types/InventorySuppliersReportVm';
import { translateUnits } from 'src/utils/translate/translateUnits';

export function InventorySuppliersReportDetailDialog({ supplyDetailsBySupplier, businessName, open, onClose }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    return (
        <div className={classes.container}>
            <Dialog open={open} title={businessName}>
                <Table
                    data={
                        supplyDetailsBySupplier?.map((supplyDetailBySupplier: SupplyDetailsBySupplier) => {
                            return {
                                sku: supplyDetailBySupplier.sku,
                                name: supplyDetailBySupplier.name,
                                units: translateUnits(supplyDetailBySupplier.units, supplyDetailBySupplier.buyUnit),
                                supplyPrice: formatAsCurrencyNumber(supplyDetailBySupplier.supplyPrice),
                                totalCost: formatAsCurrencyNumber(supplyDetailBySupplier.totalCost),
                            };
                        }) ?? []
                    }
                    columns={[
                        {
                            name: 'sku',
                            label: translate('SKU'),
                            options: {
                                filter: false,
                                customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                            },
                        },
                        {
                            name: 'name',
                            label: translate('Supply'),
                            options: {
                                filter: false,
                                customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                            },
                        },
                        {
                            name: 'units',
                            label: translate('Units'),
                            options: {
                                filter: false,
                                customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                            },
                        },
                        {
                            name: 'supplyPrice',
                            label: translate('Unit Price'),
                            options: {
                                filter: false,
                                customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                            },
                        },
                        {
                            name: 'totalCost',
                            label: translate('Total Cost'),
                            options: {
                                filter: false,
                                customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                            },
                        },
                    ]}
                    options={{
                        responsive: 'standard',
                        tableBodyMaxHeight: '500px',
                        selectableRows: 'none',
                        filterType: 'none' as any,
                        rowsPerPage: 100,
                        search: false,
                        print: false,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        pagination: false,
                    }}
                />
                <DialogActions>
                    <Button secondary classes={{ button: (classes as any).button }} onClick={onClose}>
                        {translate('Close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
}));

export type Props = {
    supplyDetailsBySupplier?: Array<SupplyDetailsBySupplier>;
    businessName: string;
    open: boolean;
    onClose: any;
};
