/**
 * @prettier
 */
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { useContext, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';
import type { Variant } from 'src/components/form/FormTypes';
import { TransformedAutocompleteString, transformToOutput } from 'src/components/form/material-ui/TransformedAutocompleteString';
import { getError } from 'src/components/form/utils/getError';
import { translate } from 'src/i18n/translate';
import { isBlankString } from 'src/utils/string/isBlankString';

export function FormAutocompleteString({
    name,
    label,
    placeholder,
    helperText,
    defaultValue = null,
    trim = true,
    upperCase,
    options,
    freeSolo,
    disabled,
    required,
    variant,
    InputProps,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>();

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <TransformedAutocompleteString
                    /* @ts-ignore */
                    renderInput={({ InputProps: ParamsInputProps, InputLabelProps: ParamsInputLabelProps, inputProps, ...params }) => (
                        <TextField
                            {...params}
                            label={label}
                            placeholder={isBlankString((props as any).value) ? placeholder : undefined}
                            helperText={getError(errors, name)?.message ?? helperText}
                            variant={variant}
                            inputRef={inputRef}
                            InputProps={{
                                ...ParamsInputProps,
                                ...InputProps,
                            }}
                            inputProps={{ ...inputProps, style: { textTransform: upperCase ? 'uppercase' : 'none', ...inputProps?.style } }}
                            InputLabelProps={{
                                ...ParamsInputLabelProps,
                                ...{ shrink: placeholder ? true : undefined },
                            }}
                            error={!!getError(errors, name)}
                        />
                    )}
                    freeSolo={freeSolo}
                    autoSelect={true}
                    options={options}
                    getOptionLabel={(data: any) => data}
                    getOptionSelected={(option: any, value: any) => {
                        // console.log('getOptionSelected option = ', option);
                        // console.log('getOptionSelected value = ', value);
                        return option === value;
                    }}
                    value={value}
                    onChange={onChange}
                    trim={trim}
                    upperCase={upperCase}
                    required={required}
                    disabled={isSubmitting || disabled || formContext.disabled}
                    fullWidth
                    {...props}
                />
            )}
            control={control}
            name={name}
            defaultValue={transformToOutput(defaultValue, trim, upperCase)}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    trim?: boolean;
    upperCase?: boolean;
    options: Array<string | null | undefined>;
    freeSolo?: boolean;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
};
