/**
 * @prettier
 */
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SubscriptionId } from 'src/types/Id';

export async function createManualInvoiceForSubscriptionApi(request: CreateManualInvoiceForSubscriptionApiRequest): ApiSauceResponse<CreateManualInvoiceForSubscriptionApiResponse> {
    return letseatmanagerApiMethod('subscription/createManualInvoiceForSubscriptionApi', request);
}

export type CreateManualInvoiceForSubscriptionApiRequest = {
    subscriptionId: SubscriptionId;
    from: string; // YYYY-MM-DD,
    to: string; // YYYY-MM-DD,
    noDateInvoiceConcept?: boolean;
};

export type CreateManualInvoiceForSubscriptionApiResponse =
    | undefined
    | {
          errorMessage: string;
      };
