/**
 * @prettier
 */
import { DeviceBrand, DeviceBrands } from 'src/constants/DeviceBrand';

export function translateDeviceBrand(deviceBrand?: DeviceBrand): string {
    if (!deviceBrand) return '';
    switch (deviceBrand) {
        case DeviceBrands.APPLE:
            return 'Apple';
        case DeviceBrands.IMIN:
            return 'iMin';
        case DeviceBrands.QPAY:
            return 'QPay';
        case DeviceBrands.SAMSUNG:
            return 'Samsung';
        case DeviceBrands.LG:
            return 'LG';
        default:
            return deviceBrand ?? '';
    }
}
