/**
 * @prettier
 */
import { useState } from 'react';
import * as React from 'react';
import { markOrderReadyForPickUpApi } from 'src/api/letseatmanager/order/markOrderReadyForPickupApi';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { SentryService } from 'src/services/SentryService';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isKioskApp } from 'src/utils/app/isKioskApp';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isIntegrationOrder } from 'src/utils/order/isIntegrationOrder';
import { isRappiApp } from 'src/utils/order/isRappiApp';
import { isRoomServiceOrder } from 'src/utils/order/isRoomServiceOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { isUberEatsApp } from 'src/utils/order/isUberEatsApp';
import { useSelector } from 'src/utils/react/useSelector';

export function MarkOrderReadyForPickUpButton({ order, disabled, onSuccess, primary, outlined, classes: classesProp }: Props): React.ReactElement | null {
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const markReadyForPickupButtonEnabled = useSelector((state) => state.app.restaurant.markReadyForPickupButtonEnabled);

    const isKioskOrderAndHasNotBeenPickedUp = isKioskApp(order.app) && !order.markedReadyForPickupAt;

    const isIntegrationOrderAndHasNotBeenPickedUp = isIntegrationOrder(order) && !isUberEatsApp(order.app) && !isRappiApp(order.app) && !order.markedReadyForPickupAt;

    const hasOrderNotBeenMarkedAsPickedUp =
        markReadyForPickupButtonEnabled && (isTakeAwayOrder(order.orderType) || isEatHereOrder(order.orderType)) && isAcceptedOrder(order.orderStatus) && !order.markedReadyForPickupAt;

    const shouldRenderButton =
        (isIntegrationOrderAndHasNotBeenPickedUp || isKioskOrderAndHasNotBeenPickedUp || hasOrderNotBeenMarkedAsPickedUp || (isRoomServiceOrder(order.orderType) && !order.markedReadyForPickupAt)) &&
        isAcceptedOrder(order.orderStatus);

    const handleClick = async (event: any) => {
        event.stopPropagation();
        setLoading(true);
        const response = await markOrderReadyForPickUpApi({
            restaurantId,
            orderId: order.orderId,
        });
        setLoading(false);
        if (!response.ok) {
            SentryService.logError('Failed to markOrderReadyForPickup', { markOrderReadyForPickupRequest: { orderId: order.orderId }, response });
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        SentryService.logInfoBreadcrumb('Successfully markedOrderReadyForPickup', { markOrderReadyForPickupRequest: { orderId: order.orderId } });
        onSuccess?.();
    };

    if (!shouldRenderButton) return null;

    return (
        <Button primary={primary} outlined={outlined} onClick={handleClick} disabled={loading || disabled} classes={{ button: classesProp?.button }}>
            {isRoomServiceOrder(order.orderType) ? translate('Mark order ready') : translate('Order ready for pickup')}
        </Button>
    );
}

type Props = {
    order: OrderVm;
    disabled?: boolean;
    onSuccess?: any;
    primary?: boolean;
    outlined?: boolean;
    classes?: {
        button?: string;
    };
};
